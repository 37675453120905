import React from "react";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFileOutlined";

export const Icons = {
  Machine: (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5144 0.442383H2.48565C2.09148 0.442818 1.7136 0.599209 1.43504 0.877198C1.15647 1.15519 1 1.53204 1 1.92496V14.8494C1.00054 15.242 1.15724 15.6184 1.43574 15.896C1.71423 16.1736 2.0918 16.3298 2.48565 16.3304H8.58676L8.06794 18.6667H7.13529C7.02609 18.6667 6.92135 18.7099 6.84413 18.7869C6.76691 18.8639 6.72353 18.9683 6.72353 19.0772C6.72353 19.186 6.76691 19.2904 6.84413 19.3674C6.92135 19.4444 7.02609 19.4876 7.13529 19.4876H15.8647C15.9739 19.4876 16.0786 19.4444 16.1559 19.3674C16.2331 19.2904 16.2765 19.186 16.2765 19.0772C16.2765 18.9683 16.2331 18.8639 16.1559 18.7869C16.0786 18.7099 15.9739 18.6667 15.8647 18.6667H14.9321L14.4132 16.3337H20.5144C20.9088 16.3331 21.2868 16.1765 21.5654 15.8981C21.844 15.6198 22.0003 15.2426 22 14.8494V1.92496C22 1.53204 21.8435 1.15519 21.565 0.877198C21.2864 0.599209 20.9085 0.442818 20.5144 0.442383ZM2.48565 1.2633H20.5144C20.6014 1.26335 20.6876 1.28051 20.7681 1.31381C20.8485 1.3471 20.9215 1.39586 20.983 1.45731C21.0445 1.51876 21.0932 1.5917 21.1264 1.67194C21.1596 1.75218 21.1766 1.83816 21.1765 1.92496V12.7294H1.82353V1.92496C1.82337 1.83816 1.84037 1.75218 1.87357 1.67194C1.90676 1.5917 1.9555 1.51876 2.017 1.45731C2.07849 1.39586 2.15153 1.3471 2.23195 1.31381C2.31236 1.28051 2.39857 1.26335 2.48565 1.2633ZM14.0888 18.6667H8.91124L9.43047 16.3337H13.5695L14.0888 18.6667ZM20.5144 15.5127H2.48565C2.39845 15.5126 2.31213 15.4954 2.23163 15.462C2.15113 15.4286 2.07804 15.3796 2.01653 15.318C1.95503 15.2564 1.90632 15.1833 1.8732 15.1029C1.84008 15.0225 1.8232 14.9364 1.82353 14.8494V13.5503H21.1765V14.8494C21.1766 14.9362 21.1595 15.0222 21.1263 15.1024C21.0931 15.1826 21.0444 15.2555 20.9829 15.317C20.9214 15.3784 20.8484 15.4272 20.768 15.4605C20.6876 15.4938 20.6014 15.511 20.5144 15.5111V15.5127Z"
        fill="#4BBFA5"
        stroke="#4BBFA5"
        stroke-width="0.3"
      />
    </svg>
  ),
  FolderMachine: (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.55 9.04961H13.95C13.7564 9.04961 13.6 8.89281 13.6 8.69961C13.6 8.50641 13.7564 8.34961 13.95 8.34961H19.55C19.7435 8.34961 19.9 8.50641 19.9 8.69961C19.9 8.89281 19.7435 9.04961 19.55 9.04961Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.55 10.45H16.75C16.5565 10.45 16.4 10.2932 16.4 10.1C16.4 9.9068 16.5565 9.75 16.75 9.75H19.55C19.7436 9.75 19.9 9.9068 19.9 10.1C19.9 10.2932 19.7436 10.45 19.55 10.45Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.7 6.25H21.3V4.5C21.3 4.11395 20.986 3.8 20.6 3.8H8.53235C8.30975 3.8 8.10185 3.70025 7.9629 3.5263L6.6063 1.83055C6.5398 1.7476 6.44075 1.7 6.33435 1.7H2.4C2.01395 1.7 1.7 2.01395 1.7 2.4V6.25ZM21.65 6.95H1.35C1.15645 6.95 1 6.7932 1 6.6V2.4C1 1.6279 1.6279 1 2.4 1H6.33435C6.65425 1 6.9528 1.1435 7.15265 1.3934L8.50925 3.0888L20.6 3.1C21.3721 3.1 22 3.7279 22 4.5V6.6C22 6.7932 21.8435 6.95 21.65 6.95Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.55 10.7996C3.05905 10.7996 1.7 11.4933 1.7 11.8496C1.7 12.2059 3.05905 12.8996 5.55 12.8996C8.04095 12.8996 9.4 12.2059 9.4 11.8496C9.4 11.4933 8.04095 10.7996 5.55 10.7996ZM5.55 13.5996C3.289 13.5996 1 12.9987 1 11.8496C1 10.7006 3.289 10.0996 5.55 10.0996C7.811 10.0996 10.1 10.7006 10.1 11.8496C10.1 12.9987 7.811 13.5996 5.55 13.5996Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.7 17.0275V18.1492C1.7 18.5055 3.05905 19.1992 5.55 19.1992C8.04095 19.1992 9.4 18.5055 9.4 18.1492V17.0275C7.70005 18.046 3.39995 18.046 1.7 17.0275ZM5.55 19.8992C3.289 19.8992 1 19.2983 1 18.1492V16.0492C1 15.856 1.15645 15.6992 1.35 15.6992C1.54355 15.6992 1.7 15.856 1.7 16.0492C1.7 16.4055 3.05905 17.0992 5.55 17.0992C8.04095 17.0992 9.4 16.4055 9.4 16.0492C9.4 15.856 9.55645 15.6992 9.75 15.6992C9.94355 15.6992 10.1 15.856 10.1 16.0492V18.1492C10.1 19.2983 7.811 19.8992 5.55 19.8992Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75 16.3996C9.55645 16.3996 9.4 16.2428 9.4 16.0496V14.9279C7.70005 15.9464 3.39995 15.9464 1.7 14.9279V16.0496C1.7 16.2428 1.54355 16.3996 1.35 16.3996C1.15645 16.3996 1 16.2428 1 16.0496V13.9496C1 13.7564 1.15645 13.5996 1.35 13.5996C1.54355 13.5996 1.7 13.7564 1.7 13.9496C1.7 14.3059 3.05905 14.9996 5.55 14.9996C8.04095 14.9996 9.4 14.3059 9.4 13.9496C9.4 13.7564 9.55645 13.5996 9.75 13.5996C9.94355 13.5996 10.1 13.7564 10.1 13.9496V16.0496C10.1 16.2428 9.94355 16.3996 9.75 16.3996Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75002 14.3C9.55647 14.3 9.40002 14.1432 9.40002 13.95V11.85C9.40002 11.6568 9.55647 11.5 9.75002 11.5C9.94357 11.5 10.1 11.6568 10.1 11.85V13.95C10.1 14.1432 9.94357 14.3 9.75002 14.3Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.35 14.3C1.15645 14.3 1 14.1432 1 13.95V11.85C1 11.6568 1.15645 11.5 1.35 11.5C1.54355 11.5 1.7 11.6568 1.7 11.85V13.95C1.7 14.1432 1.54355 14.3 1.35 14.3Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6 18.5H9.75C9.55645 18.5 9.4 18.3432 9.4 18.15C9.4 17.9568 9.55645 17.8 9.75 17.8H20.6C20.986 17.8 21.3 17.486 21.3 17.1V4.5C21.3 4.11395 20.986 3.8 20.6 3.8H8.53235C8.30975 3.8 8.10185 3.70025 7.9629 3.5263L6.6063 1.83055C6.5398 1.7476 6.44075 1.7 6.33435 1.7H2.4C2.01395 1.7 1.7 2.01395 1.7 2.4V11.85C1.7 12.0432 1.54355 12.2 1.35 12.2C1.15645 12.2 1 12.0432 1 11.85V2.4C1 1.6279 1.6279 1 2.4 1H6.33435C6.65425 1 6.9528 1.1435 7.15265 1.3934L8.50925 3.0888L20.6 3.1C21.3721 3.1 22 3.7279 22 4.5V17.1C22 17.8721 21.3721 18.5 20.6 18.5Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        stroke-width="0.2"
      />
    </svg>
  ),
  FolderMUI: <FolderIcon />,
  Folder: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6842 5.85023C17.3642 5.42659 16.884 5.18451 16.3639 5.18451H15.7838V3.83291C15.7838 2.92511 15.0636 2.19888 14.1634 2.19888H8.90213C8.68208 2.19888 8.50203 2.01732 8.50203 1.79542V1.63403C8.50203 0.726235 7.78186 0 6.88165 0H1.62039C0.720172 0 0 0.726235 0 1.63403V13.5564C0 14.0809 0.260062 14.5651 0.640153 14.8677C0.92022 15.0896 1.28031 15.2308 1.6604 15.2308L14.3434 15.2106C15.0636 15.2106 15.7038 14.7264 15.9238 14.0405L17.9243 7.28253C18.0843 6.7782 17.9843 6.25369 17.6842 5.85023ZM1.62039 1.23057H6.88165C7.1017 1.23057 7.28174 1.41212 7.28174 1.63403V1.79542C7.28174 2.70321 8.00191 3.42944 8.90213 3.42944H14.1634C14.3834 3.42944 14.5635 3.611 14.5635 3.83291V5.18451H3.96095C3.26078 5.18451 2.62063 5.62832 2.40057 6.29404L1.22029 9.80418V1.63403C1.22029 1.41212 1.40034 1.23057 1.62039 1.23057ZM16.764 6.91941L14.7635 13.6774C14.7035 13.859 14.5435 13.98 14.3434 13.98L1.6604 14.0002C1.52036 14.0002 1.38033 13.9397 1.30031 13.8186C1.2603 13.7581 1.20029 13.6371 1.2403 13.4555L3.52084 6.6975C3.58086 6.53612 3.74089 6.41508 3.92094 6.41508H16.3239C16.4639 6.41508 16.584 6.4756 16.664 6.59664C16.764 6.63698 16.824 6.75802 16.764 6.91941Z"
        fill="#4CBFA5"
      />
    </svg>
  ),
  Database: (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 21.5676C14.2218 21.5676 19 20.5097 19 18.4865V3.08108C19 1.05784 14.2218 0 9.5 0C4.77824 0 0 1.05784 0 3.08108V18.4865C0 20.5097 4.77824 21.5676 9.5 21.5676ZM9.5 1.02703C15.0639 1.02703 17.973 2.36473 17.973 3.08108C17.973 3.79743 15.0639 5.13514 9.5 5.13514C3.93608 5.13514 1.02703 3.79743 1.02703 3.08108C1.02703 2.36473 3.93608 1.02703 9.5 1.02703ZM1.02703 4.55743C2.68311 5.61527 6.10824 6.16216 9.5 6.16216C12.8918 6.16216 16.3169 5.61527 17.973 4.55743V8.21622C17.973 8.93257 15.0639 10.2703 9.5 10.2703C3.93608 10.2703 1.02703 8.93257 1.02703 8.21622V4.55743ZM1.02703 9.69257C2.68311 10.7504 6.10824 11.2973 9.5 11.2973C12.8918 11.2973 16.3169 10.7504 17.973 9.69257V13.3514C17.973 14.0677 15.0639 15.4054 9.5 15.4054C3.93608 15.4054 1.02703 14.0677 1.02703 13.3514V9.69257ZM1.02703 14.8277C2.68311 15.8855 6.10824 16.4324 9.5 16.4324C12.8918 16.4324 16.3169 15.8855 17.973 14.8277V18.4865C17.973 19.2028 15.0639 20.5405 9.5 20.5405C3.93608 20.5405 1.02703 19.2028 1.02703 18.4865V14.8277Z"
        fill="#4CBFA5"
      />
      <path
        d="M16.0784 17.9463C16.0784 18.3329 15.765 18.6463 15.3784 18.6463C14.9918 18.6463 14.6784 18.3329 14.6784 17.9463C14.6784 17.5597 14.9918 17.2463 15.3784 17.2463C15.765 17.2463 16.0784 17.5597 16.0784 17.9463Z"
        stroke="#4CBFA5"
        strokeWidth="0.6"
      />
      <path
        d="M16.0784 12.8105C16.0784 13.1971 15.765 13.5105 15.3784 13.5105C14.9918 13.5105 14.6784 13.1971 14.6784 12.8105C14.6784 12.4239 14.9918 12.1105 15.3784 12.1105C15.765 12.1105 16.0784 12.4239 16.0784 12.8105Z"
        stroke="#4CBFA5"
        strokeWidth="0.6"
      />
      <path
        d="M16.0784 7.67578C16.0784 8.06238 15.765 8.37578 15.3784 8.37578C14.9918 8.37578 14.6784 8.06238 14.6784 7.67578C14.6784 7.28918 14.9918 6.97578 15.3784 6.97578C15.765 6.97578 16.0784 7.28918 16.0784 7.67578Z"
        stroke="#4CBFA5"
        strokeWidth="0.6"
      />
    </svg>
  ),
  RightArrow: (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10L6 5L-4.37114e-07 0"
        fill="#8F8F8F"
      />
    </svg>
  ),
  Volume: (
    <svg
      width="25"
      height="25"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.43333 4.6821H13.5667C13.8602 4.6821 14.05 4.43715 14.05 4.15647V1.43239C14.05 1.29128 14.0026 1.16982 13.9162 1.08351C13.8297 0.997223 13.708 0.95 13.5667 0.95H1.43333C1.292 0.95 1.17033 0.997223 1.08385 1.08351C0.997361 1.16982 0.95 1.29128 0.95 1.43239V4.19971C0.95 4.34082 0.997361 4.46229 1.08385 4.54859C1.17033 4.63488 1.292 4.6821 1.43333 4.6821ZM13.0833 1.91479V3.71732H1.91667V1.91479H13.0833Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M1.43333 9.35176H13.5667C13.708 9.35176 13.8297 9.30453 13.9162 9.21824C14.0026 9.13194 14.05 9.01048 14.05 8.86936V6.14528C14.05 6.00417 14.0026 5.88271 13.9162 5.79641C13.8297 5.71011 13.708 5.66289 13.5667 5.66289H1.43333C1.292 5.66289 1.17033 5.71011 1.08385 5.79641C0.997361 5.88271 0.95 6.00417 0.95 6.14528V8.86936C0.95 9.01048 0.997361 9.13194 1.08385 9.21824C1.17033 9.30453 1.292 9.35176 1.43333 9.35176ZM13.0833 6.62768V8.38697H1.91667V6.62768H13.0833Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M2.95796 3.15327C3.00425 3.19946 3.05253 3.23609 3.10843 3.26088C3.16454 3.28576 3.22579 3.2976 3.29661 3.2976C3.36743 3.2976 3.42869 3.28576 3.4848 3.26088C3.5407 3.23609 3.58898 3.19946 3.63526 3.15327C3.68155 3.10709 3.71826 3.05891 3.74312 3.0031C3.76808 2.94707 3.77995 2.88591 3.77995 2.81521C3.77995 2.7445 3.76808 2.68334 3.74312 2.62731C3.71826 2.5715 3.68155 2.52332 3.63526 2.47714C3.44241 2.2847 3.15081 2.2847 2.95796 2.47714C2.91168 2.52332 2.87496 2.5715 2.85011 2.62731C2.82515 2.68334 2.81328 2.7445 2.81328 2.81521C2.81328 2.88591 2.82515 2.94707 2.85011 3.0031C2.87496 3.05891 2.91168 3.10709 2.95796 3.15327Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M4.63988 2.33281C4.49855 2.33281 4.37687 2.38004 4.29039 2.46633C4.2039 2.55263 4.15654 2.67409 4.15654 2.81521C4.15654 2.95632 4.2039 3.07778 4.29039 3.16408C4.37687 3.25038 4.49855 3.2976 4.63988 3.2976C4.93059 3.2976 5.12321 3.05582 5.12321 2.81521C5.12321 2.67409 5.07585 2.55263 4.98936 2.46633C4.90288 2.38004 4.78121 2.33281 4.63988 2.33281Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M2.95796 7.8232C3.00425 7.86939 3.05253 7.90601 3.10843 7.9308C3.16454 7.95568 3.22579 7.96752 3.29661 7.96752C3.36743 7.96752 3.42869 7.95568 3.4848 7.9308C3.5407 7.90601 3.58898 7.86939 3.63526 7.8232C3.68155 7.77701 3.71826 7.72883 3.74312 7.67302C3.76808 7.61699 3.77995 7.55583 3.77995 7.48513C3.77995 7.41442 3.76808 7.35326 3.74312 7.29723C3.71826 7.24142 3.68155 7.19324 3.63526 7.14706C3.44241 6.95463 3.15081 6.95463 2.95796 7.14706C2.91168 7.19325 2.87496 7.24142 2.85011 7.29723C2.82515 7.35326 2.81328 7.41442 2.81328 7.48513C2.81328 7.58401 2.86129 7.72674 2.95796 7.8232Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M4.63988 7.00273C4.49855 7.00273 4.37687 7.04996 4.29039 7.13625C4.2039 7.22255 4.15654 7.34401 4.15654 7.48513C4.15654 7.62624 4.2039 7.74771 4.29039 7.83401C4.37687 7.9203 4.49855 7.96752 4.63988 7.96752C4.93058 7.96752 5.12321 7.72574 5.12321 7.48513C5.12321 7.34401 5.07585 7.22255 4.98936 7.13625C4.90288 7.04996 4.78121 7.00273 4.63988 7.00273Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
    </svg>
  ),
  FileMUI: <FileIcon />,
  File: (
    <svg
      width="25"
      height="25"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M768 853.333h-512v-682.667h324.267l187.733 192v490.667zM298.667 810.667h426.667v-430.933l-162.133-166.4h-264.533v597.333z"
        fill="rgb(151, 151, 151)"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M554.667 213.333h42.667v174.933h-42.667z"
        fill="rgb(151, 151, 151)"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
      <path
        d="M554.667 426.667v-42.667h213.333v42.667z"
        fill="rgb(151, 151, 151)"
        stroke="#4CBFA5"
        strokeWidth="0.1"
      />
    </svg>
  ),
  DbFile: (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5077 4.99077L12.0612 0.497531C11.7762 0.209016 11.2769 0 10.8737 0H2.84888C1.2781 0 0 1.29155 0 2.87886V18.8685C0 20.4555 1.2781 21.7473 2.84888 21.7473H14.1511C15.7219 21.7473 17 20.4555 17 18.8685V6.19099C17 5.78359 16.7932 5.2788 16.5077 4.99077ZM11.9139 2.02951L14.9916 5.13938H12.456C12.1571 5.13938 11.9139 4.89388 11.9139 4.59183V2.02951ZM15.8233 18.8687C15.8233 19.8005 15.0729 20.5587 14.1511 20.5587H2.84888C1.92683 20.5587 1.17671 19.8005 1.17671 18.8687V2.87886C1.17671 1.94687 1.92683 1.18885 2.84888 1.18885H10.737V4.59207C10.737 5.54968 11.5084 6.32872 12.4558 6.32872H15.8231V18.8687H15.8233Z"
        fill="#4CBFA5"
      />
      <path
        d="M5 10.7588V15.7122C5 16.8848 6.73209 17.4952 8.44221 17.4952C10.1523 17.4952 11.8844 16.8814 11.8844 15.7122V10.7588C11.8844 8.41373 5 8.41373 5 10.7588ZM11.0713 15.7122C11.0713 16.0306 10.1434 16.6462 8.44302 16.6462C6.74267 16.6462 5.81477 16.0272 5.81477 15.7122V14.425C6.62985 14.8482 7.53209 15.0561 8.44221 15.0304C9.3526 15.0562 10.2551 14.8484 11.0705 14.425L11.0713 15.7122ZM11.0713 13.2449C11.0713 13.5633 10.1434 14.1788 8.44302 14.1788C6.74267 14.1788 5.81477 13.5599 5.81477 13.2449V11.9399C6.62982 12.3631 7.53208 12.571 8.44221 12.5452C9.35261 12.5711 10.2552 12.3632 11.0705 11.9399L11.0713 13.2449ZM8.44221 11.6962C6.86233 11.6962 5.81395 11.1316 5.81395 10.7622C5.81395 10.4438 6.74186 9.82825 8.44221 9.82825C10.1426 9.82825 11.0705 10.4472 11.0705 10.7622C11.0705 11.1316 10.0221 11.6962 8.44221 11.6962Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
    </svg>
  ),
  DbFolder: (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.55 9.04961H13.95C13.7564 9.04961 13.6 8.89281 13.6 8.69961C13.6 8.50641 13.7564 8.34961 13.95 8.34961H19.55C19.7435 8.34961 19.9 8.50641 19.9 8.69961C19.9 8.89281 19.7435 9.04961 19.55 9.04961Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.55 10.45H16.75C16.5565 10.45 16.4 10.2932 16.4 10.1C16.4 9.9068 16.5565 9.75 16.75 9.75H19.55C19.7436 9.75 19.9 9.9068 19.9 10.1C19.9 10.2932 19.7436 10.45 19.55 10.45Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7 6.25H21.3V4.5C21.3 4.11395 20.986 3.8 20.6 3.8H8.53235C8.30975 3.8 8.10185 3.70025 7.9629 3.5263L6.6063 1.83055C6.5398 1.7476 6.44075 1.7 6.33435 1.7H2.4C2.01395 1.7 1.7 2.01395 1.7 2.4V6.25ZM21.65 6.95H1.35C1.15645 6.95 1 6.7932 1 6.6V2.4C1 1.6279 1.6279 1 2.4 1H6.33435C6.65425 1 6.9528 1.1435 7.15265 1.3934L8.50925 3.0888L20.6 3.1C21.3721 3.1 22 3.7279 22 4.5V6.6C22 6.7932 21.8435 6.95 21.65 6.95Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55 10.7996C3.05905 10.7996 1.7 11.4933 1.7 11.8496C1.7 12.2059 3.05905 12.8996 5.55 12.8996C8.04095 12.8996 9.4 12.2059 9.4 11.8496C9.4 11.4933 8.04095 10.7996 5.55 10.7996ZM5.55 13.5996C3.289 13.5996 1 12.9987 1 11.8496C1 10.7006 3.289 10.0996 5.55 10.0996C7.811 10.0996 10.1 10.7006 10.1 11.8496C10.1 12.9987 7.811 13.5996 5.55 13.5996Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7 17.0275V18.1492C1.7 18.5055 3.05905 19.1992 5.55 19.1992C8.04095 19.1992 9.4 18.5055 9.4 18.1492V17.0275C7.70005 18.046 3.39995 18.046 1.7 17.0275ZM5.55 19.8992C3.289 19.8992 1 19.2983 1 18.1492V16.0492C1 15.856 1.15645 15.6992 1.35 15.6992C1.54355 15.6992 1.7 15.856 1.7 16.0492C1.7 16.4055 3.05905 17.0992 5.55 17.0992C8.04095 17.0992 9.4 16.4055 9.4 16.0492C9.4 15.856 9.55645 15.6992 9.75 15.6992C9.94355 15.6992 10.1 15.856 10.1 16.0492V18.1492C10.1 19.2983 7.811 19.8992 5.55 19.8992Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 16.3996C9.55645 16.3996 9.4 16.2428 9.4 16.0496V14.9279C7.70005 15.9464 3.39995 15.9464 1.7 14.9279V16.0496C1.7 16.2428 1.54355 16.3996 1.35 16.3996C1.15645 16.3996 1 16.2428 1 16.0496V13.9496C1 13.7564 1.15645 13.5996 1.35 13.5996C1.54355 13.5996 1.7 13.7564 1.7 13.9496C1.7 14.3059 3.05905 14.9996 5.55 14.9996C8.04095 14.9996 9.4 14.3059 9.4 13.9496C9.4 13.7564 9.55645 13.5996 9.75 13.5996C9.94355 13.5996 10.1 13.7564 10.1 13.9496V16.0496C10.1 16.2428 9.94355 16.3996 9.75 16.3996Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75002 14.3C9.55647 14.3 9.40002 14.1432 9.40002 13.95V11.85C9.40002 11.6568 9.55647 11.5 9.75002 11.5C9.94357 11.5 10.1 11.6568 10.1 11.85V13.95C10.1 14.1432 9.94357 14.3 9.75002 14.3Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.35 14.3C1.15645 14.3 1 14.1432 1 13.95V11.85C1 11.6568 1.15645 11.5 1.35 11.5C1.54355 11.5 1.7 11.6568 1.7 11.85V13.95C1.7 14.1432 1.54355 14.3 1.35 14.3Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6 18.5H9.75C9.55645 18.5 9.4 18.3432 9.4 18.15C9.4 17.9568 9.55645 17.8 9.75 17.8H20.6C20.986 17.8 21.3 17.486 21.3 17.1V4.5C21.3 4.11395 20.986 3.8 20.6 3.8H8.53235C8.30975 3.8 8.10185 3.70025 7.9629 3.5263L6.6063 1.83055C6.5398 1.7476 6.44075 1.7 6.33435 1.7H2.4C2.01395 1.7 1.7 2.01395 1.7 2.4V11.85C1.7 12.0432 1.54355 12.2 1.35 12.2C1.15645 12.2 1 12.0432 1 11.85V2.4C1 1.6279 1.6279 1 2.4 1H6.33435C6.65425 1 6.9528 1.1435 7.15265 1.3934L8.50925 3.0888L20.6 3.1C21.3721 3.1 22 3.7279 22 4.5V17.1C22 17.8721 21.3721 18.5 20.6 18.5Z"
        fill="#4CBFA5"
        stroke="#4CBFA5"
        strokeWidth="0.2"
      />
    </svg>
  )
};
