import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getNotifications } from "state/selectors/csr";
import { clearNotifications } from "state/actions/csr";

import CsrNotifications from "./CsrNotifications";

export default connect(
  state => ({
    notifications: getNotifications(state)
  }),
  dispatch => bindActionCreators({ clearNotifications }, dispatch)
)(CsrNotifications);
