import menuTab from "./MenuTab";
import menuTabMUI from "./MenuTabMUI";
import tab from "./Tab";
import muitab from "./MUITab";
import wizardTab from "./WizardTab";
import wizardTabMUI from "./WizardTabMUI";
import gridTab from "./GridTab";
import gridTabMUI from "./GridTabMUI";
import iVMForm from "./IVMForm";

export const MenuTab = menuTab;
export const MenuTabMUI = menuTabMUI;
export const Tab = tab;
export const WizardTab = wizardTab;
export const WizardTabMUI = wizardTabMUI;
export const GridTab = gridTab;
export const GridTabMUI = gridTabMUI;
export const MUITab = muitab;
export const IVMForm = iVMForm;
