import { combineReducers } from "redux";
import { LOAD_SITES, LOAD_SITES_SUCCESS } from "state/actions/actionTypes";

function sitesResponse(state = null, action) {
  switch (action.type) {
    case LOAD_SITES:
      return null;
    case LOAD_SITES_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({ sitesResponse });
