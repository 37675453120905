// import { createTheme } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";

const FONT_FAMILY_LIGHT =
  "'Sans Source Pro Light','Roboto','Helvetica','Arial',sans-serif"; // font-weight:300, font-style: normal
const FONT_FAMILY_REGULAR =
  "'Sans Source Pro Regular','Roboto','Helvetica','Arial',sans-serif"; // font-weight:400, font-style: normal
const FONT_FAMILY_MEDIUM =
  "'Sans Source Pro Medium','Roboto','Helvetica','Arial',sans-serif"; // font-weight:600, font-style: normal

export const customTypography = {
  typography: {
    fontFamily: FONT_FAMILY_REGULAR,
    // fontWeight: 400,
    // fontSize: 13,

    h1: {
      fontFamily: FONT_FAMILY_LIGHT
    },
    h2: {
      fontFamily: FONT_FAMILY_LIGHT
    },
    h3: {
      fontFamily: FONT_FAMILY_REGULAR
    },
    h4: {
      fontFamily: FONT_FAMILY_REGULAR
    },
    h5: {
      fontFamily: FONT_FAMILY_REGULAR
    },
    h6: {
      fontFamily: FONT_FAMILY_MEDIUM
    },
    subtitle1: {
      fontFamily: FONT_FAMILY_REGULAR
    },
    subtitle2: {
      fontFamily: FONT_FAMILY_MEDIUM
    },
    body1: {
      fontFamily: FONT_FAMILY_REGULAR
    },
    body2: {
      fontFamily: FONT_FAMILY_REGULAR
    },
    button: {
      fontFamily: FONT_FAMILY_MEDIUM
    },
    formFieldLabel: {
      fontFamily: FONT_FAMILY_REGULAR,
      fontSize: ".8125rem"
    },
    formFieldText: {
      fontFamily: FONT_FAMILY_REGULAR,
      fontSize: "1rem"
    },
    toolTipText: {
      fontFamily: FONT_FAMILY_REGULAR,
      fontSize: ".8125rem"
    }
  }
};

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#F00024"
      // dark: "#3700B3",
      // light: "#BB86FC",
      // background: "#F2EBFD",
      // border: "#6200EE",
      // contrastText: "#FFFFFF"
    }
    // secondary: {
    //   main: "#03DAC5",
    //   dark: "#018786",
    //   light: "#70EFDE",
    //   background: "#EBFCFA",
    //   border: "#12DD99",
    //   contrastText: "#000000"
    // },
    // other: {
    //   stroke: "rgba(0,0,0,23)",
    //   divider: "#E0E0E0",
    //   backdrop: "rgba(0,0,0,50)",
    //   snackbar: "#323232",
    //   activeRating: "#FFD500"
    // }
  },
  ...customTypography
});

export const defaultTheme = {
  palette: {
    primary: {
      main: "#6200EE"
    }
  },
  ...customTypography
};

export default theme;
