import * as C from "state/constants";
import _ from "lodash";

const notifications = (state = [], action) => {
  switch (action.type) {
    case C.ADD_NOTIFICATIONS:
      return _.uniq([...state, ...action.payload.notifications]);
    case C.CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

export default notifications;
