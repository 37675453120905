import React from "react";
import PropTypes from "prop-types";
import FormattedMessage from "components/common/formatted-message";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import Chip from "components/common/chip";

/**
 * Component to render tags
 */

const Tag = ({
  showTagsWithCategory,
  advSearchOptions,
  onTagClose,
  tags,
  disableClose,
  tagToDisable
}) => {
  /**
   * Function to get object of current selected options from array
   * obj = {
   *  <tagCategory>: {
   *    display: Array,
   *    value: Array
   *  }
   * }
   */
  const getCurrentSelectionObj = data => {
    let currentSelectionObj = {};
    data.forEach(item => {
      if (!currentSelectionObj[item.tagCategory]) {
        currentSelectionObj[item.tagCategory] = {
          display: [],
          value: [],
          key: []
        };
      }
      currentSelectionObj[item.tagCategory].display.push(item.display);
      currentSelectionObj[item.tagCategory].value.push(item.value);
      currentSelectionObj[item.tagCategory].isSearchedText =
        item.isSearchedText;
      item.key
        ? currentSelectionObj[item.tagCategory].key.push(item.key)
        : currentSelectionObj[item.tagCategory].key.push("name");
    });
    return currentSelectionObj;
  };

  /**
   * Render tags element
   * @param {array} data
   * @return html for tags input
   */
  const renderTags = data => {
    if (showTagsWithCategory) {
      let dataToDisplay = getCurrentSelectionObj(data);
      return (
        dataToDisplay &&
        Object.keys(dataToDisplay).map((item, index) => {
          let keyIndex = index;
          let labelKey =
            advSearchOptions &&
            advSearchOptions.length &&
            advSearchOptions.filter(
              node => node.key === dataToDisplay[item].key[0]
            )[0];
          if (!labelKey) {
            labelKey = {
              name: dataToDisplay[item].key[0]
            };
          }
          return (
            <div
              className="tags d-flex align-items-center"
              key={keyIndex}
              id={keyIndex}
            >
              <Chip
                size="small"
                label={
                  <label>
                    <FormattedMessage
                      id={labelKey.name}
                      defaultMessage={labelKey.name}
                    />{" "}
                    :{" "}
                    {labelKey.name === "name" ||
                    dataToDisplay[item].isSearchedText ? (
                      <span>{dataToDisplay[item].display[0]}</span>
                    ) : (
                      <FormattedBrandMessage
                        id={dataToDisplay[item].display}
                        defaultMessage={dataToDisplay[item].display}
                      />
                    )}
                  </label>
                }
                onDelete={
                  disableClose && item === tagToDisable
                    ? null
                    : () =>
                        onTagClose({
                          category: item,
                          key: dataToDisplay[item].key,
                          value: dataToDisplay[item].value
                        })
                }
                className="search-tag"
              />
            </div>
          );
        })
      );
    } else {
      return data.map((item, index) => {
        let keyIndex = index;
        return (
          <div
            className="tags d-flex align-items-center"
            key={keyIndex}
            id={keyIndex}
          >
            <Chip
              size="small"
              label={item.label}
              onDelete={() => onTagClose({ value: item.value })}
              className="search-tag"
            />
          </div>
        );
      });
    }
  };

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  return renderTags(tags);
};

Tag.defaultProps = {
  showTagsWithCategory: true,
  advSearchOptions: []
};

Tag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onTagClose: PropTypes.func.isRequired,
  showTagsWithCategory: PropTypes.bool,
  advSearchOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default React.memo(Tag);
