import { createSelector } from "reselect";
import { intersection, filter } from "lodash";
import { getLogin } from "state/selectors";
import {
  SPOG_GRID_PROPERTIES,
  SOURCE_PRODUCT,
  SECONDARY_NAVIGATION_KEYS
} from "../../utils/appConstants";
import _ from "lodash";
// selectors
const columnData = state => state.reducer.columnData;
//Protect Reducer
const sourceData = state =>
  state.protectReducer.sourceData && state.protectReducer.sourceData.data
    ? state.protectReducer.sourceData.data
    : null;
const UIVersion = state => state.reducer.uiVersion;
const APIVersion = state => state.reducer.apiVersion;
const sourceLicenseData = state =>
  state.protectReducer.sourceLicenses &&
  state.protectReducer.sourceLicenses.data
    ? state.protectReducer.sourceLicenses.data
    : null;
const sourceConfigurationData = state =>
  state.protectReducer.sourceConfiguration &&
  state.protectReducer.sourceConfiguration.data
    ? state.protectReducer.sourceConfiguration.data
    : {};

const sourceGridData = state => state.protectReducer.data.data;
const OracleSourcesData = state => state.protectReducer.oracleSource.data;
const sourceGridLocalData = state => state.protectReducer.localData.data;
const sourceGridPaginationData = state => state.protectReducer.data.pagination;
const machineDetails = state => state.protectReducer.machineDetails;
const policyList = state => state.protectReducer.policyList;
const selectedPolicyForAssignPolicy = state =>
  state.protectReducer.selectedPolicyForAssignPolicy;
const discoverSourceName = state => state.protectReducer.discoverSourceName;

//Monitor Reducer
const alertDetailsModalObj = state => state.monitorReducer.alertDetailsModalObj;
const showModal = state => state.monitorReducer.showModal;

//Reducer
const showRecoveryPointModal = state => state.reducer.showRecoveryPointModal;
const showDownloadRecoveryPointModal = state =>
  state.reducer.showDownloadRecoveryPointModal;
const contextualAction = state => state.reducer.contextualAction;
const advancedSearchParam = state => state.reducer.advancedSearchParam;
const contextualBulkAction = state => state.reducer.contextualBulkAction;
const showConfirmationModal = state => state.reducer.showConfirmationModal;
const showInformationModal = state => state.reducer.showInformationModal;
export const advancedSearchOptions = state => state.reducer.options;
const currentSearchOptions = state => state.reducer.currentSearchOptions;
export const searchText = state => state.reducer.searchText;
const searchOption = state => state.reducer.searchOption;
const showCurrentSearch = state => state.reducer.showCurrentSearch;
const userSearchData = state => state.reducer.userSearchData;
const optionsForTags = state => state.reducer.optionsForTags;
const selectedSavedSearch = state => state.reducer.selectedSavedSearch;
const selectedSavedSearchName = state => state.reducer.selectedSavedSearchName;
const selectedSavedSearchId = state => state.reducer.selectedSavedSearchId;
const showAlertMessage = state => state.reducer.showAlertMessage;
const disableLogExport = state => state.reducer.disableLogExport;
// datagrid
const checkboxSelection = state => state.reducer.selection;
const checkboxAllSelected = state => state.reducer.selectAll;
const checkboxSelectedFromAllPages = state =>
  state.reducer.selectedRowsFromAllPages;
const apiResponse = state => state.reducer.apiResponse;
const dateTimeRangeReset = state => state.reducer.resetDateTimeRangePicker;
const showDestinationModal = state => state.reducer.showDestinationModal;
const collapseSideBar = state => state.reducer.collapseSideBar;
const storageArrayList = state => state.configureReducer.storageArrayList;
const cloudAccountList = state =>
  state.destinations.CloudAccount.cloudAccountList;
const replicationTasksForPolicy = state =>
  state.protectReducer.getReplicationTasksForPolicy;
const replicationTasksForSource = state =>
  state.protectReducer.getReplicationTasksForSource;
const bulkAction = state => {
  let checkboxArray = checkboxSelection(state) || [];
  let isCloudDirect = false;
  let isUDP = false;
  if (
    [
      SECONDARY_NAVIGATION_KEYS.SOURCES,
      SECONDARY_NAVIGATION_KEYS.PHYSICAL_MACHINES,
      SECONDARY_NAVIGATION_KEYS.VIRTUAL_MACHINES,
      SECONDARY_NAVIGATION_KEYS.UNC_NFS_PATHS
    ].includes(_.get(state, "reducer.secondaryNavigation", "")) &&
    checkboxArray &&
    checkboxArray.length
  ) {
    if (
      _.map(checkboxArray, "product_type").includes(SOURCE_PRODUCT.CLOUD_DIRECT)
    ) {
      isCloudDirect = true;
    }
    if (_.map(checkboxArray, "product_type").includes(SOURCE_PRODUCT.UDP)) {
      isUDP = true;
    }
  }
  let actionsArray = checkboxArray.reduce((accumulator, currentValue) => {
    accumulator.push(
      currentValue[SPOG_GRID_PROPERTIES.ACTION_KEY.AVAILABLE_ACTIONS]
    );
    return accumulator;
  }, []);
  if (isCloudDirect && isUDP && actionsArray && actionsArray.length) {
    actionsArray = actionsArray.map(actions =>
      actions.filter(
        action => action !== SPOG_GRID_PROPERTIES.BULK_ACTION.START_BACKUP
      )
    );
  }
  return filter(
    intersection(...actionsArray),
    object =>
      SPOG_GRID_PROPERTIES.BULK_ACTION_UNSUPPORTED.indexOf(object) === -1
  );
};

export const makeContextualBulkActionState = () =>
  createSelector(
    [contextualBulkAction],
    contextualBulkAction => contextualBulkAction
  );

//datagrid header
const showSaveSearchModal = state => state.reducer.showSaveSearchModal;
const showSaveSearchClearAllOnDefault = state =>
  state.reducer.showSaveSearchClearAllOnDefault;
const showAdvancedSearch = state => state.reducer.showAdvancedSearch;
const selectedRows = state => state.reducer.selectedRows;

//saved searches
const systemDefinedSearchData = state => state.reducer.systemSearchData;
const successSaveSearch = state => state.reducer.successSaveSearch;

export const getStartFailback = state => state.reducer.startFailback;

//Manage saved search
const columnConfiguredData = state => state.reducer.columnData;
export const columnSourceConfiguredData = (state, props) => {
  return state.ccDataGrid &&
    state.ccDataGrid[props.secondaryNavigation] &&
    state.ccDataGrid[props.secondaryNavigation].api
    ? state.ccDataGrid[props.secondaryNavigation].api.data
    : [];
};
const showManagedSearchModal = state => state.reducer.showManagedSearchModal;
const manageSettingColumn = state => state.reducer.columnData;
const licenseDetails = state => state.configureReducer.licenseDetails;
export const makeLicenseDetailsState = () =>
  createSelector([licenseDetails], licenseDetails => licenseDetails);

export const browseRemoteConsoleLoading = state =>
  state.reducer.browseRemoteConsole
    ? state.reducer.browseRemoteConsole.isLoading
    : [];

const browseRemoteConsoleData = state =>
  state.reducer.browseRemoteConsole && state.reducer.browseRemoteConsole.data
    ? state.reducer.browseRemoteConsole.data.data
    : [];
export const makeBrowseRemoteConsoleState = () =>
  createSelector(
    [browseRemoteConsoleData],
    browseRemoteConsoleData => browseRemoteConsoleData
  );
//form
const form = state => state.form;

export const roleId = state => getLogin(state).role_id;

// reselect functions
export const makeCollapseSideBarState = () =>
  createSelector([collapseSideBar], collapseSideBar => collapseSideBar);
export const makeAlertDetailsModalObjState = () =>
  createSelector(
    [alertDetailsModalObj],
    alertDetailsModalObj => alertDetailsModalObj
  );
export const makeRoleIdState = () => createSelector([roleId], roleId => roleId);
export const makeSelectedPolicyForAssignPolicyState = () =>
  createSelector(
    [selectedPolicyForAssignPolicy],
    selectedPolicyForAssignPolicy => selectedPolicyForAssignPolicy
  );
export const makePolicyListState = () =>
  createSelector([policyList], policyList => policyList);
export const makeMachineDetailsState = () =>
  createSelector([machineDetails], machineDetails => machineDetails);
export const makeSourceGridDataState = () =>
  createSelector([sourceGridData], sourceGridData => sourceGridData);
export const makeOracleSourceData = () =>
  createSelector([OracleSourcesData], OracleSourcesData => OracleSourcesData);
export const makeSourceGridLocalDataState = () =>
  createSelector(
    [sourceGridLocalData],
    sourceGridLocalData => sourceGridLocalData
  );
export const makeSourceGridPaginationDataState = () =>
  createSelector(
    [sourceGridPaginationData],
    sourceGridPaginationData => sourceGridPaginationData
  );
export const makeShowModalState = () =>
  createSelector([showModal], showModal => showModal);
export const makeShowRecoveryPointModalState = () =>
  createSelector(
    [showRecoveryPointModal],
    showRecoveryPointModal => showRecoveryPointModal
  );
export const makeShowDownloadRecoveryPointModalState = () =>
  createSelector(
    [showDownloadRecoveryPointModal],
    showDownloadRecoveryPointModal => showDownloadRecoveryPointModal
  );
export const makeContextualActionState = () =>
  createSelector([contextualAction], contextualAction => contextualAction);
export const makeAdvancedSearchParamState = () =>
  createSelector(
    [advancedSearchParam],
    advancedSearchParam => advancedSearchParam
  );
export const makeSourceDataState = () =>
  createSelector([sourceData], sourceData => sourceData);
export const makeSourceLicenseDataState = () =>
  createSelector([sourceLicenseData], sourceLicenseData => sourceLicenseData);
export const makeFormState = () => createSelector([form], form => form);
export const makeShowAlertMessageState = () =>
  createSelector([showAlertMessage], showAlertMessage => showAlertMessage);
export const makeShowInformationModalState = () =>
  createSelector(
    [showInformationModal],
    showInformationModal => showInformationModal
  );
export const makeConfirmationModalDisplayState = () =>
  createSelector(
    [showConfirmationModal],
    showConfirmationModal => showConfirmationModal
  );
export const makeColumnDataState = () =>
  createSelector([columnData], columnData => columnData);
export const makeSearchOptionState = () =>
  createSelector([searchOption], searchOption => searchOption);
export const makeShowCurrentSearchState = () =>
  createSelector([showCurrentSearch], showCurrentSearch => showCurrentSearch);
export const makeUserSearchDataState = () =>
  createSelector([userSearchData], userSearchData => userSearchData);
export const makeSavedSearchDataState = () =>
  createSelector([userSearchData], userSearchData => userSearchData);
export const makeOptionsForTagsState = () =>
  createSelector([optionsForTags], optionsForTags => optionsForTags);
export const makeSelectedSavedSearchState = () =>
  createSelector(
    [selectedSavedSearch],
    selectedSavedSearch => selectedSavedSearch
  );
export const makeSelectedSavedSearchNameState = () =>
  createSelector(
    [selectedSavedSearchName],
    selectedSavedSearchName => selectedSavedSearchName
  );
export const makeSelectedSavedSearchIdState = () =>
  createSelector(
    [selectedSavedSearchId],
    selectedSavedSearchId => selectedSavedSearchId
  );
export const makeSearchTextState = () =>
  createSelector([searchText], searchText => searchText);
export const makeShowDestinationModalState = () =>
  createSelector(
    [showDestinationModal],
    showDestinationModal => showDestinationModal
  );
export const makeAdvancedSearchOptionsState = () =>
  createSelector(
    [advancedSearchOptions],
    advancedSearchOptions => advancedSearchOptions
  );
export const makeCurrentSearchOptionsState = () =>
  createSelector(
    [currentSearchOptions],
    currentSearchOptions => currentSearchOptions
  );

export const makeCheckboxSelectionState = () =>
  createSelector([checkboxSelection], checkboxSelection => checkboxSelection);
export const makeCheckboxAllSelectedState = () =>
  createSelector(
    [checkboxAllSelected],
    checkboxAllSelected => checkboxAllSelected
  );
export const makeCheckboxSelectedFromAllPagesState = () =>
  createSelector(
    [checkboxSelectedFromAllPages],
    checkboxSelectedFromAllPages => checkboxSelectedFromAllPages
  );
//datagrid header
export const makeShowSaveSearchModalState = () =>
  createSelector(
    [showSaveSearchModal],
    showSaveSearchModal => showSaveSearchModal
  );
export const makeClearAllOnDefaultState = () =>
  createSelector(
    [showSaveSearchClearAllOnDefault],
    showSaveSearchClearAllOnDefault => showSaveSearchClearAllOnDefault
  );
export const makeShowAdvancedSearchState = () =>
  createSelector(
    [showAdvancedSearch],
    showAdvancedSearch => showAdvancedSearch
  );
export const makeSelectedRowsState = () =>
  createSelector([selectedRows], selectedRows => selectedRows);
//saved searches
export const makeSystemDefinedSearchState = () =>
  createSelector(
    [systemDefinedSearchData],
    systemDefinedSearchData => systemDefinedSearchData
  );
export const makeSuccessSaveSearchState = () =>
  createSelector([successSaveSearch], successSaveSearch => successSaveSearch);
export const makeColumnConfiguredDataState = () =>
  createSelector(
    [columnConfiguredData],
    columnConfiguredData => columnConfiguredData
  );

export const makeColumnSourceConfiguredDataState = () =>
  createSelector(
    [columnSourceConfiguredData],
    columnSourceConfiguredData => columnSourceConfiguredData
  );

export const makeShowManagedSearchModalState = () =>
  createSelector(
    [showManagedSearchModal],
    showManagedSearchModal => showManagedSearchModal
  );
export const makeManageSettingColumnState = () =>
  createSelector(
    [manageSettingColumn],
    manageSettingColumn => manageSettingColumn
  );
export const makeBulkActionState = () =>
  createSelector([bulkAction], bulkAction => bulkAction);
export const makeApiResponse = () =>
  createSelector([apiResponse], apiResponse => apiResponse);

export const getUIVersion = () =>
  createSelector([UIVersion], UIVersion => UIVersion);

export const getAPIVersion = () =>
  createSelector([APIVersion], APIVersion => APIVersion);

export const makeSourceConfigurationDataState = () =>
  createSelector(
    [sourceConfigurationData],
    sourceConfigurationData => sourceConfigurationData
  );

export const makeDateTimeRangeResetState = () =>
  createSelector(
    [dateTimeRangeReset],
    dateTimeRangeReset => dateTimeRangeReset
  );

export const makeDisableLogExportState = () =>
  createSelector([disableLogExport], disableLogExport => disableLogExport);
export const getDisoverSourceName = () =>
  createSelector(
    [discoverSourceName],
    discoverSourceName => discoverSourceName
  );

export const getIngramOrgFail = state => state.reducer.ingramOrgFailure;
export const getIngramModalState = state => state.reducer.showOrgModal;

export const makeStorageArrayList = () =>
  createSelector([storageArrayList], storageArrayList => storageArrayList);

export const hypervisorTypes = state => {
  let hypervLists = _.get(state, "protectReducer.hypervisorsList", []);
  return hypervLists.map(hyperv => hyperv.hypervisor_type);
};

export const hypervisors = state => {
  let hypervLists = _.get(state, "protectReducer.hypervisorsList", []);
  return hypervLists;
};

export const getOracleHostCount = state => {
  let sourceCounts = _.get(state, "reducer.sourceCounts", []);
  let counts = 0;
  let filteredSources =
    sourceCounts?.length > 0
      ? sourceCounts.filter(ele => {
          if (
            ["windows_oracle_host", "linux_oracle_host"].includes(
              ele.source_type
            )
          )
            counts = counts + ele.amount;
          return ele;
        })
      : 0;
  return counts;
};

export const getCloudAccountList = createSelector(
  [cloudAccountList],
  cloudAccountList => cloudAccountList
);

export const getReplicationTasksForPolicy = createSelector(
  [replicationTasksForPolicy],
  replicationTasksForPolicy => replicationTasksForPolicy
);

export const getReplicationTasksForSource = createSelector(
  [replicationTasksForSource],
  replicationTasksForSource => replicationTasksForSource
);
export const columnsAPIInProgressStatus = state =>
  state.reducer.columnsAPIInProgress;
export const rowsAPIInProgressStatus = state => state.reducer.rowsAPIInProgress;
