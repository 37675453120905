import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import IconMUI from "components/common/icon/IconMUI";
import DownloadIcon from "@mui/icons-material/Download";
import { getLogin, getOriginalLogin } from "state/selectors";
import { isReadOnly } from "utils";
import { GridTabMUI } from "components/common/tab";
import vpnwindowmove from "../../assets/images/vpn-windows-1-move.png";
import vpnwindowconnect from "../../assets/images/vpn-windows-2-connect.png";
import vpnwindowpassword from "../../assets/images/vpn-windows-3-password.png";
import vpnwindowmac from "../../assets/images/vpn-mac-tunnelblick-menu.png";
import vpnwindowtunnel from "../../assets/images/vpn-mac-tunnelblick-password.png";
import URLS from "./ViewInstructionConstants";
import { getVPNDetails } from "../../state/actions/actions";

class ViewInstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: {
        windows: false,
        mac: false,
        linux: false
      },
      activeTabIndex: 0
    };
    this.showPasswordWindow = this.showPasswordWindow.bind(this);
    this.showPasswordMac = this.showPasswordMac.bind(this);
    this.showPasswordLinux = this.showPasswordLinux.bind(this);
    this.downloadConfiguration = this.downloadConfiguration.bind(this);
  }

  componentDidMount() {
    this.props.getVPNDetails();
  }

  componentWillUnmount() {}

  showPasswordWindow() {
    this.setState({
      showPassword: { windows: !this.state.showPassword.windows }
    });
  }

  showPasswordMac() {
    this.setState({
      showPassword: { mac: !this.state.showPassword.mac }
    });
  }

  showPasswordLinux() {
    this.setState({
      showPassword: { linux: !this.state.showPassword.linux }
    });
  }

  downloadConfiguration(isWindows) {
    const { login, organization_id } = this.props;

    const myHeaders = {
      authorization: `Bearer ${login.cdtoken}`
    };

    fetch(
      `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/cloudvpn/openvpnconfig?windows=${isWindows}&org_id=${organization_id}`,
      { headers: myHeaders }
    )
      .then(response => response.blob())
      .then(blob => {
        const fileName = "VPN_Configuration.zip";

        if (window.navigator.msSaveOrOpenBlob)
          // IE10+
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        else {
          // Other browsers
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);

          document.body.appendChild(link);
          link.click();
          setTimeout(function() {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }, 0);
        }
      })
      .catch(e => console.log("openvpnconfig failed", e));
  }

  render() {
    const VPNPassword =
      this.props.VPNDetails && this.props.VPNDetails.password
        ? this.props.VPNDetails.password
        : "";
    let tabOptions = [];
    tabOptions.push(
      {
        tab: "Windows",
        tabkey: "windows",
        testId: "2knx9s",
        content: (
          <div
            className="tab-pane active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <h2 className="section-title">
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.section-title" />
            </h2>
            <a
              href={URLS.downloadVpnWindows}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.windows" />
            </a>{" "}
            <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.install-it" />
            <h2 className="section-title">
              <FormattedMessage id="vpn-p2s-view-instructions.deploy.section-title" />
            </h2>
            <ol>
              <li>
                {
                  // eslint-disable-next-line
                  <a
                    // eslint-disable-next-line no-script-url
                    href="javascript:void(null)"
                    onClick={() => this.downloadConfiguration(true)}
                    style={{
                      pointerEvents: isReadOnly(this.props.originalRoleId)
                        ? "none"
                        : "all"
                    }}
                  >
                    <IconMUI icon={DownloadIcon} />
                    <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows" />
                  </a>
                }
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.move" />{" "}
                <code>C:\Program Files\OPenVPN\config</code>
                <br />
                <img
                  className="instructions-image"
                  src={vpnwindowmove}
                  alt="Screenshot showing where to move the configuration folder to"
                />
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.right-click" />
                <br />
                <img
                  className="instructions-image"
                  src={vpnwindowconnect}
                  alt="Screenshot showing how to connect to our VPN"
                />
              </li>
              <li>
                <span
                  style={{
                    display: "inline-block"
                  }}
                  className="viewInstructionPassword"
                >
                  <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw" />{" "}
                  {this.state.showPassword.windows ? VPNPassword : "**********"}{" "}
                  {
                    // eslint-disable-next-line
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(null)"
                      onClick={this.showPasswordWindow}
                    >
                      {this.state.showPassword.windows ? (
                        <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw.hide" />
                      ) : (
                        <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw.show" />
                      )}
                    </a>
                  }
                </span>
                <br />
                <img
                  className="instructions-image"
                  src={vpnwindowpassword}
                  alt="Screenshot showing were to input the password"
                />
              </li>
            </ol>
          </div>
        )
      },
      {
        tab: "Mac",
        tabkey: "mac",
        testId: "JHZids",
        content: (
          <div
            className="tab-pane"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <h2 className="section-title">
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.section-title" />
            </h2>
            <a
              href={URLS.downloadTunnelblick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.mac" />
            </a>{" "}
            <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.install-it" />
            <p className="help-block">
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.mac.helpblock" />{" "}
              <a
                href={URLS.support}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: "4px" }}
              >
                <FormattedMessage id="vpn-p2s-view-instructions.support" />
              </a>
              <FormattedMessage id="vpn-p2s-view-instructions.for-help" />
            </p>
            <h2 className="section-title">
              <FormattedMessage id="vpn-p2s-view-instructions.deploy.section-title" />
            </h2>
            <ol>
              <li>
                {
                  // eslint-disable-next-line
                  <a
                    // eslint-disable-next-line no-script-url
                    href="javascript:void(null)"
                    onClick={() => this.downloadConfiguration(false)}
                    style={{
                      pointerEvents: isReadOnly(this.props.originalRoleId)
                        ? "none"
                        : "all"
                    }}
                  >
                    <IconMUI icon={DownloadIcon} />
                    <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.mac" />
                  </a>
                }
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.unzip" />
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.click" />
                <code>vpn_user_XXXXX.ovpn</code>{" "}
                <FormattedMessage id="vpn-p2s-view-instructions.file" />
                <br />
                <span className="text-muted">
                  <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.restart" />
                </span>
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.follow" />
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.click-tunnelblick" />
                <code style={{ marginLeft: "4px" }}>
                  Connect vpn_user_XXXXX
                </code>
                .<br />
                <img
                  className="instructions-image"
                  src={vpnwindowmac}
                  alt="Screenshot showing what Tunnelblick looks like in the menu bar"
                />
              </li>
              <li>
                <span
                  style={{
                    display: "inline-block"
                  }}
                  className="viewInstructionPassword"
                >
                  <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw" />{" "}
                  {this.state.showPassword.mac ? VPNPassword : "**********"}{" "}
                  {
                    // eslint-disable-next-line
                    <a
                      //eslint-disable-next-line  no-script-url
                      href="javascript:void(null)"
                      onClick={this.showPasswordMac}
                    >
                      {this.state.showPassword.mac ? (
                        <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw.hide" />
                      ) : (
                        <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw.show" />
                      )}
                    </a>
                  }
                </span>
                <br />
                <img
                  className="instructions-image"
                  src={vpnwindowtunnel}
                  alt="Screenshot describing where to put the password into Tunnelblick"
                />
              </li>
            </ol>
          </div>
        )
      },
      {
        tab: "Linux",
        tabkey: "linux",
        testId: "cbjhsx",
        content: (
          <div
            className="tab-pane"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <h2 className="section-title">
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.section-title" />
            </h2>
            <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.debian" />
            <br />
            <code>sudo apt-get install openvpn</code>
            <br />
            <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.redhat" />
            <br />
            <code>sudo yum install openvpn</code>
            <hr />
            <a
              href={URLS.ubuntuDocumentation}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.ubuntu-docs" />
            </a>{" "}
            |{" "}
            <a
              href={URLS.debianDocumentation}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="vpn-p2s-view-instructions.download-openvpn.debian-docs" />
            </a>
            <br />
            <FormattedMessage id="vpn-p2s-view-instructions.you-can-also" />
            <a
              href={URLS.buildSource}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "4px" }}
            >
              <FormattedMessage id="vpn-p2s-view-instructions.build-it" />
            </a>
            .
            <h2 className="section-title">
              <FormattedMessage id="vpn-p2s-view-instructions.deploy.section-title" />
            </h2>
            <ol>
              <li>
                {
                  // eslint-disable-next-line
                  <a
                    // eslint-disable-next-line no-script-url
                    href="javascript:void(null)"
                    onClick={() => this.downloadConfiguration(false)}
                    style={{
                      pointerEvents: isReadOnly(this.props.originalRoleId)
                        ? "none"
                        : "all"
                    }}
                  >
                    <IconMUI icon={DownloadIcon} />
                    <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.linux" />
                  </a>
                }
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.linux.uncompress" />
                <code>/etc/openvpn</code>
              </li>
              <li>
                <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.linux.start-vpn" />
                <br />
                <code>sudo openvpn /etc/openvpn/vpn_user_XXXXX.ovpn</code>
              </li>
              <li>
                <span
                  style={{
                    display: "inline-block"
                  }}
                  className="viewInstructionPassword"
                >
                  <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw" />{" "}
                  {this.state.showPassword.linux ? VPNPassword : "**********"}{" "}
                  {
                    // eslint-disable-next-line
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(null)"
                      onClick={this.showPasswordLinux}
                    >
                      {this.state.showPassword.linux ? (
                        <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw.hide" />
                      ) : (
                        <FormattedMessage id="vpn-p2s-view-instructions.deploy-download.windows.input-pw.show" />
                      )}
                    </a>
                  }
                </span>
              </li>
            </ol>
          </div>
        )
      }
    );
    return (
      <div className="user-insturction">
        {/*<div className="head" /> Removing this div from here to the gray color that appears at the top of the page*/}
        <div className="site-container">
          <div className="js-page-target">
            <div className="u-medium">
              <h1 className="page-title">
                <FormattedMessage id="vpn-p2s-view-instructions.title" />
              </h1>
              <div>
                <p className="lead">
                  <FormattedMessage id="vpn-p2s-view-instructions.lead" />
                </p>
                <GridTabMUI
                  tabOptions={tabOptions}
                  tabHandleChange={val => {
                    this.setState({ activeTabIndex: val });
                  }}
                  paperMuiStyleAsPerIndex={{ height: "100%" }}
                  defaultTab={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */

  const mapStateToProps = state => {
    const login = getLogin(state);

    return {
      /**
       * Use selector function instance to update Props.
       */
      login,
      originalRoleId: getOriginalLogin(state).role_id,
      organization_id: login.organization_id,
      VPNDetails: state.configureReducer.VPNDetails,
      hostname: login.apis.cloud_console
    };
  };
  return mapStateToProps;
};

/**
 * Bind the dispatch to the function for Redux action dispatcher
 * @param dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getVPNDetails
    },
    dispatch
  );

/**
 * Default properties of component
 * @type {{}}
 */
ViewInstruction.defaultProps = {};

ViewInstruction.propTypes = {
  login: PropTypes.shape().isRequired,
  organization_id: PropTypes.string.isRequired
};

const connected = withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(ViewInstruction)
);

export default connected;
