import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { injectIntl } from "react-intl";

const Legends = ({ intl, data, config, colors, order }) => {
  const sortedData = !order
    ? data
    : order
        .filter(o => data.find(d => d.name === o))
        .map(o => data.find(d => d.name === o));
  return (
    <div className="recharts-legends-container container">
      {sortedData.map((d, i) => {
        let legendTitle = null;

        if (get(config, `${d.name}.titleId`, false)) {
          if (
            d.quantity !== 1 &&
            get(config, `${d.name}.pluralTitleId`, false)
          ) {
            legendTitle = intl.formatMessage({
              id: config[d.name].pluralTitleId
            });
          } else {
            legendTitle = intl.formatMessage({
              id: config[d.name].titleId
            });
          }
        } else {
          legendTitle = d.name;
        }
        const legendHeader = intl.formatMessage(
          { id: "ccGraph.legendHeader" },
          { value: d.quantity, title: legendTitle }
        );

        const background = colors[i];

        let label = null;

        if (get(config, `${d.name}.labelId`, null)) {
          label = intl.formatMessage({
            id: config[d.name].labelId
          });
        } else {
          label = d.name;
        }

        return (
          <div key={d.id} className="recharts-legends">
            <h2>{legendHeader}</h2>
            <div>
              <span className="label">
                <span className="circle" style={{ background }} />
                <span>{label}</span>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Legends.propTypes = {
  intl: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.isRequired,
      name: PropTypes.string.isRequired,
      quanity: PropTypes.number
    })
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.shape(),
  order: PropTypes.arrayOf(PropTypes.string)
};

Legends.defaultProps = {
  config: null,
  order: null
};

export default injectIntl(Legends);
