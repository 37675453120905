import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { FormattedHTMLMessage } from "components/common/formatted-message";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const errorIcon = {
  infoToaster: "info",
  warningToaster: "warning",
  successToaster: "success",
  errorToaster: "error"
};
class CCToastNotification extends PureComponent {
  constructor(props) {
    super(props);

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleDismiss() {
    const { id, handleDismiss } = this.props;
    handleDismiss(id);
  }

  handleKeyUp(e) {
    e.keyCode === 13 && this.handleDismiss();
  }

  render() {
    const { id, icon, namespace, messageId, messageValues } = this.props;

    let myMessageId = messageId;

    if (namespace) {
      myMessageId = `${namespace}.${myMessageId}`;
    }

    return (
      <Alert
        id={id}
        severity={errorIcon[icon]}
        iconMapping={{
          success: <CheckCircleOutlineIcon fontSize="inherit" />
        }}
        // TODO refactor toast messages
        style={{
          width:
            messageId ===
            "protect.policies.save_cd_hypervisor_policy_more_than_fifteen_vms_warning"
              ? "1000px"
              : "auto",
          marginBottom: "10px"
        }}
        action={
          <Button
            id={`${id}_button`}
            role="button"
            color="inherit"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onKeyUp={this.handleKeyUp}
            onClick={this.handleDismiss}
          >
            <CloseIcon />
          </Button>
        }
        className="alert-show fadeOut d-flex align-items-center"
      >
        <div className="cc-alert-content d-flex align-items-center">
          {myMessageId !== "" && (
            <FormattedHTMLMessage id={myMessageId} values={messageValues} />
          )}
        </div>
      </Alert>
    );
  }
}

CCToastNotification.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  messageId: PropTypes.string.isRequired,
  messageValues: PropTypes.string,
  handleDismiss: PropTypes.func.isRequired,
  dismiss: PropTypes.number
};

CCToastNotification.defaultProps = {
  namespace: "",
  messageValues: "",
  dismiss: null
};

export default CCToastNotification;
