import React from "react";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { ID_PREFIX } from "configs/IdConfig";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import {
  GROUPED_JOB_MENU_ITEMS,
  GROUPED_MANAGE_MENU_ITEMS,
  GROUPED_ADMINISTER_MENU_ITEMS,
  GROUPED_ACTION_MENU_ITEMS
} from "utils/GroupedMenuItems";
import DropdownButton from "../dropdown-button-mui";
import Overlay from "../overlay-mui";

const LoadingDots = testId => {
  return (
    <div id={testId} className="loading-dropdown-dots">
      {" "}
      &nbsp;
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
    </div>
  );
};

const ActionDropdown = ({
  showDateRange,
  onMenuItemClick,
  optionIndex,
  render,
  hasi18nSupport,
  disabledOptions,
  skipDefaultOption,
  defaultOptionKey,
  intl,
  defaultOptionValue,
  color,
  endIcon,
  variant,
  pullRight,
  dropup,
  loading,
  size,
  customButtonStyle,
  id,
  testId,
  keyAsTestId,
  groupMenuItems,
  ...props
}) => {
  /**
   * stop event propagation to table header cell
   */
  const handlebuttonClicked = e => {
    const _elemToStop = e.currentTarget.parentNode.parentNode;
    if (_elemToStop.id === "grid-header-dropdown-wrapper") {
      e.stopPropagation();
    }
  };

  const handleOnSelect = eventKey => {
    let event = Object.assign(
      {},
      {
        ...eventKey,
        showPicker: showDateRange,
        key: "single_select"
      }
    );
    if (onMenuItemClick) {
      render
        ? onMenuItemClick(optionIndex, [{ ...event }])
        : onMenuItemClick(eventKey);
    }
  };

  const processMenuItem = (
    item,
    optionIndex,
    isDisabled = false,
    menuid,
    keyAsTestId
  ) => {
    const customKey = item.value
      ? item.value.toString()
      : item.key
      ? item.key.toString()
      : item.toString();

    if (item?.showTooltip) {
      return (
        <Overlay
          content={
            <FormattedBrandMessage
              id={item?.tooltipId}
              defaultMessage={item?.tooltipDefaultMsg}
            />
          }
          key={customKey.split(".").pop()}
          placement="right"
          classes={{
            popper: "available_action_tooltip"
          }}
        >
          <span>
            {menuItem({
              item,
              optionIndex,
              isDisabled,
              menuid,
              keyAsTestId,
              customKey
            })}
          </span>
        </Overlay>
      );
    }
    return item
      ? menuItem({
          item,
          optionIndex,
          isDisabled,
          menuid,
          keyAsTestId,
          customKey
        })
      : false;
  };

  const menuItem = ({
    item,
    optionIndex,
    isDisabled = false,
    menuid,
    keyAsTestId,
    customKey
  }) => (
    <MenuItem
      id={
        menuid
          ? `${menuid}_${
              keyAsTestId ? customKey.split(".").pop() : optionIndex
            }`
          : ""
      }
      key={customKey.split(".").pop()}
      onClick={() => handleOnSelect(item)}
      disabled={isDisabled}
      style={{ fontSize: 14 }}
    >
      {hasi18nSupport ? (
        <FormattedBrandMessage
          id={
            item.value
              ? item.value.toString()
              : item.key
              ? item.key.toString()
              : item.toString()
          }
          defaultMessage={
            item.value
              ? item.value.toString()
              : item.key
              ? item.key.toString()
              : item.toString()
          }
        />
      ) : (
        <span>{typeof item === "object" ? item.key : item}</span>
      )}
    </MenuItem>
  );

  const groupItem = ({ groupId, firstGroupItem }) => (
    <ListSubheader
      id={groupId}
      key={groupId}
      style={{
        lineHeight: "unset",
        backgroundColor: "unset",
        paddingLeft: 5,
        paddingTop: !firstGroupItem && 10
      }}
    >
      <FormattedBrandMessage id={groupId} defaultMessage={groupId} />
    </ListSubheader>
  );

  /**
   * to render menu items for dropdown
   */
  const renderMenuItem = (menuid, keyAsTestId) => {
    if (props.options && props.options.length + disabledOptions.length === 0)
      return <div />;
    const options = cloneDeep(props.options);
    if (!skipDefaultOption) {
      options.unshift({
        key: hasi18nSupport
          ? defaultOptionKey
          : intl.formatMessage({
              id: defaultOptionKey,
              defaultMessage: defaultOptionKey
            }),
        value: defaultOptionValue
      });
    }
    const processedMenuItems =
      options &&
      options
        .map((item, index) =>
          processMenuItem(item, index, false, menuid, keyAsTestId)
        )
        .concat(
          disabledOptions.map((item, index) =>
            processMenuItem(item, index, true, menuid, keyAsTestId)
          )
        );
    const groupedProcessedMenuItems = [];
    if (groupMenuItems && processedMenuItems?.length) {
      const groupedJobMenuItems = [];
      const groupedManageMenuItems = [];
      const groupedAdministerMenuItems = [];
      const groupedActionMenuItems = [];
      const groupedOtherMenuItems = [];
      processedMenuItems.forEach(processedMenuItem => {
        if (GROUPED_JOB_MENU_ITEMS.includes(processedMenuItem.key)) {
          groupedJobMenuItems.push(processedMenuItem);
        } else if (GROUPED_MANAGE_MENU_ITEMS.includes(processedMenuItem.key)) {
          groupedManageMenuItems.push(processedMenuItem);
        } else if (
          GROUPED_ADMINISTER_MENU_ITEMS.includes(processedMenuItem.key)
        ) {
          groupedAdministerMenuItems.push(processedMenuItem);
        } else if (GROUPED_ACTION_MENU_ITEMS.includes(processedMenuItem.key)) {
          groupedActionMenuItems.push(processedMenuItem);
        } else {
          groupedOtherMenuItems.push(processedMenuItem);
        }
      });
      if (groupedJobMenuItems?.length) {
        const processedGroupItem = groupItem({
          groupId: "Job",
          firstGroupItem: true
        });
        groupedProcessedMenuItems.push(
          processedGroupItem,
          ...groupedJobMenuItems
        );
      }
      if (groupedManageMenuItems?.length) {
        const processedGroupItem = groupItem({
          groupId: "manage",
          firstGroupItem: !groupedJobMenuItems?.length && true
        });
        groupedProcessedMenuItems.push(
          processedGroupItem,
          ...groupedManageMenuItems
        );
      }
      if (groupedAdministerMenuItems?.length) {
        const processedGroupItem = groupItem({
          groupId: "administer",
          firstGroupItem:
            !(groupedJobMenuItems?.length || groupedManageMenuItems?.length) &&
            true
        });
        groupedProcessedMenuItems.push(
          processedGroupItem,
          ...groupedAdministerMenuItems
        );
      }
      if (groupedActionMenuItems?.length) {
        const processedGroupItem = groupItem({
          groupId: "common.jobs.available_actions",
          firstGroupItem:
            !(
              groupedJobMenuItems?.length ||
              groupedManageMenuItems?.length ||
              groupedAdministerMenuItems?.length
            ) && true
        });
        groupedProcessedMenuItems.push(
          processedGroupItem,
          ...groupedActionMenuItems
        );
      }
      if (groupedOtherMenuItems?.length) {
        const processedGroupItem = groupItem({
          groupId: "ccGraph.names.Other",
          firstGroupItem:
            !(
              groupedJobMenuItems?.length ||
              groupedManageMenuItems?.length ||
              groupedAdministerMenuItems?.length ||
              groupedActionMenuItems?.length
            ) && true
        });
        groupedProcessedMenuItems.push(
          processedGroupItem,
          ...groupedOtherMenuItems
        );
      }
      return groupedProcessedMenuItems;
    }
    return processedMenuItems;
  };

  const dropdownClass = props.title ? get(props, "className", "") : "no-text";

  const title = props.title ? (
    typeof props.title === "string" ? (
      <FormattedBrandMessage id={props.title} defaultMessage={props.title} />
    ) : (
      props.title
    )
  ) : (
    ""
  );

  const menuid = testId ? `${ID_PREFIX}${testId}` : id ? id : "";

  return (
    <>
      <DropdownButton
        title={title}
        color={color}
        variant={variant || "default"}
        endIcon={endIcon}
        pullRight={pullRight}
        menuAlign={get(props, "menuAlign", "left")}
        dropup={dropup}
        className={dropdownClass}
        onClick={handlebuttonClicked}
        customButtonStyle={customButtonStyle}
        size={size}
        disabled={
          props.disabled
            ? props.disabled
            : props.options && !props.options.length
        }
        id={id}
        testId={testId}
      >
        {renderMenuItem(menuid ? `${menuid}_menu` : "", keyAsTestId)}
      </DropdownButton>
      {/* Show loading dots on dropdown */}
      {loading && (
        <LoadingDots testId={menuid ? `${menuid}_loadingdots` : ""} />
      )}
    </>
  );
};

ActionDropdown.defaultProps = {
  dropup: false,
  pullRight: true,
  disabled: false,
  hasi18nSupport: true,
  options: [],
  title: "",
  optionIndex: 0,
  render: false,
  showDateRange: false,
  skipDefaultOption: true,
  defaultOptionKey: "select",
  defaultOptionValue: null,
  onMenuItemClick: () => {},
  disabledOptions: [],
  className: "",
  loading: false,
  groupMenuItems: false
};

ActionDropdown.propTypes = {
  defaultOptionKey: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  dropup: PropTypes.bool,
  pullRight: PropTypes.bool,
  menuAlign: PropTypes.string,
  title: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  disabledOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  onMenuItemClick: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  hasi18nSupport: PropTypes.bool,
  optionIndex: PropTypes.number,
  render: PropTypes.bool,
  showDateRange: PropTypes.bool,
  skipDefaultOption: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  groupMenuItems: PropTypes.bool
};

export default injectIntl(React.memo(ActionDropdown));
