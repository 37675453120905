import React from "react";
import PropTypes from "prop-types";
import { Tab, Nav } from "react-bootstrap";

const GridTab = ({
  detailsTab,
  tabIndex,
  defaultTab,
  vertical,
  tabOptions,
  animation,
  ...props
}) => {
  /**
   * Handle tab click
   * @param {String} tab
   */
  const handleTabClick = tab => {
    if (props.handleTabClick) {
      props.handleTabClick(tab);
    }
  };

  /**
   * to render tabs content
   * @param {array of object} data
   * @return html for tab content
   */
  const renderTabContent = data => {
    return data.map((item, index) => {
      let key = `tabPane_${index}`;
      return (
        <Tab.Pane eventKey={index} key={key}>
          {item.content}
        </Tab.Pane>
      );
    });
  };

  /**
   * to render tab items
   * @param {array of object} data
   * @return html for tabs
   */
  const renderTabItem = data => {
    return data.map((item, index) => {
      let key = `navItem_${index}`;
      return (
        <Nav.Item
          key={key}
          // disabled={item.disabled}
          onClick={() => handleTabClick(item)}
        >
          <Nav.Link eventKey={index} disabled={item.disabled}>
            {item.tab}
            {detailsTab && <span>{item.details}</span>}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  let attrs = {};
  tabIndex && (attrs["activeKey"] = tabIndex);
  const className = props.className || "";
  return (
    <Tab.Container defaultActiveKey={defaultTab} {...attrs}>
      <div
        className={
          vertical
            ? "clearfix m-0 tab-wrapper"
            : "clearfix m-0 tab-wrapper horizontal-tab-wrapper"
        }
      >
        <Nav variant="tabs" stacked={+vertical} className={className}>
          {renderTabItem(tabOptions)}
        </Nav>
        <Tab.Content animation={+animation}>
          {renderTabContent(tabOptions)}
        </Tab.Content>
      </div>
    </Tab.Container>
  );
};

GridTab.defaultProps = {
  vertical: false,
  animation: true,
  detailsTab: false,
  defaultTab: 0,
  tabIndex: 0,
  handleTabClick: () => {}
};

GridTab.propTypes = {
  vertical: PropTypes.bool,
  tabOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  animation: PropTypes.bool,
  detailsTab: PropTypes.bool,
  handleTabClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  defaultTab: PropTypes.number
};

export default React.memo(GridTab);
