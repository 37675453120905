import DataGridConstants from "components/common/data-grid/DataGridConstants";

const HeaderTypes = DataGridConstants.GridColumnHeaderTypes;
const CellTypes = DataGridConstants.GridColumnCellTypes;

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "protect.RecoveredResources.virtual_standbys.".concat(identifier);
};

/**
 * Holdes constants definitions for MountedRPS
 */
export const virtualStandbyAttributes = {
  ID: "recoveredresource_id",
  SOURCE: "source_name",
  TARGET_VM: "recoveredresource_name",
  OS: "operating_system",
  STATE: "state",
  VSB_STATE: "vsb_job_status",
  SOURCE_STATE: "source_heartbeat_status",
  RECOVERY_POINT_TS: "recoverypoint_ts",
  PROVISIONED_ON: "provision_ts",
  RECENT_SNAPSHOT: "latest_snapshot_ts",
  IP_ADDRESS: "ip_address",
  SITE: "site_name",
  POLICY: "policy_name",
  HYPERVISOR: "hypervisor_name",
  CONVERTER: "name",
  RAM: "allocated_ram",
  CPU: "allocated_vcpu",
  ACTIONS: "available_actions"
};

export const VSB_FILTER_ATTRIBUTES = {
  SOURCE_STATE: "source_state",
  VSB_STATE: "vsb_state"
};

export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: virtualStandbyAttributes.TARGET_VM,
  CLICKABLE_CELL_TO_VIEW_SOURCE: virtualStandbyAttributes.SOURCE,
  CLICKABLE_CELL_TO_VIEW_POLICY: virtualStandbyAttributes.POLICY
};

export const VSB_IDENTIFIERS = {
  [VSB_FILTER_ATTRIBUTES.SOURCE_STATE]:
    "protect.RecoveredResources.virtual_standbys.source_state.",
  [VSB_FILTER_ATTRIBUTES.VSB_STATE]:
    "protect.RecoveredResources.virtual_standbys.vsb_state."
};

const COLUMN_DEFS = {
  columns: [
    {
      accessor: virtualStandbyAttributes.ACTIONS,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.ACTIONS),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: {
        type: CellTypes.ACTION_DROPDOWN_CELL_MUI,
        actionDropdown: true
      },
      settings: { sortable: false, width: 80, minWidth: 80, maxWidth: 80 }
    },
    {
      accessor: virtualStandbyAttributes.SOURCE,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.SOURCE),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LINK_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.TARGET_VM,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.TARGET_VM
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LINK_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.OS,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.OS),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.ICON_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.STATE,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.STATE),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.VSB_STATE,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.VSB_STATE
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.SOURCE_STATE,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.SOURCE_STATE
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.RECENT_SNAPSHOT,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.RECENT_SNAPSHOT
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.DATE_TIME_CELL_MUI },
      settings: {}
    },
    {
      accessor: virtualStandbyAttributes.RECOVERY_POINT_TS,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.RECOVERY_POINT_TS
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.DATE_TIME_CELL_MUI },
      settings: {}
    },
    {
      accessor: virtualStandbyAttributes.PROVISIONED_ON,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.PROVISIONED_ON
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.DATE_TIME_CELL_MUI },
      settings: {}
    },
    {
      accessor: virtualStandbyAttributes.SITE,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.SITE),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.POLICY,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.POLICY),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LINK_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.HYPERVISOR,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.HYPERVISOR
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.CONVERTER,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.CONVERTER
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.RAM,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.RAM),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.BYTE_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.CPU,
      displayIdentifier: getDisplayIdentifier(virtualStandbyAttributes.CPU),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: virtualStandbyAttributes.IP_ADDRESS,
      displayIdentifier: getDisplayIdentifier(
        virtualStandbyAttributes.IP_ADDRESS
      ),
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LONG_TEXT_CELL_MUI },
      settings: { sortable: false }
    }
  ]
};

export default COLUMN_DEFS;
