import React from "react";
import PropTypes from "prop-types";

const Body = ({ className, children }) => (
  <div className={`card-render-area${className ? ` ${className}` : ""}`}>
    {children}
  </div>
);

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

Body.defaultProps = {
  className: null
};

export default Body;
