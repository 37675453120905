import {
  select,
  all,
  call,
  put,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getLogin } from "state/selectors";
import { makeOptionsState } from "state/selectors/AnalyzeSelector";
import _ from "lodash";
import * as actionType from "state/actions/actionTypes";
import * as ccToast from "state/actions/ccToast";
import {
  showReportList,
  submitAdvancedSearch,
  updateCurrentSearchFromSaved,
  searchEnterEvent,
  addAPIresponse,
  setResourceName,
  searchList,
  updateReport,
  updateBackupJobsTopSources,
  updateColumnData,
  updateJobsAndDestinationJobsSummary,
  updateJobsAndDestinationTopSources,
  updateJobsAndDestinationTopDestination,
  updateSelectedDateRangeValue,
  fromNavigationApi,
  initializeEmailSelector,
  updateReportTypeForNavingation,
  startLoader,
  stopLoader,
  fetchAnalyzeAlerts,
  updateAccountDetailsData,
  updateSourceProtectionSourcesSummary,
  stopGridLoaderById,
  startGridLoaderById,
  showGridLoader,
  closeReportModal,
  Loader,
  setLoading,
  resetLoading
} from "../actions/actions";
import { analyzeApi, protectApi, monitorApi } from "../api";
import * as util from "../../utils/SpogDataGridUtil";
import {
  REPORTS_SEARCH_NAME_PARAM,
  REPORT_FILTER_TIME_FIELD,
  ReportsAttributes
} from "../../components/analyze/reports/ReportsConstants";
import * as constant from "../../utils/appConstants";
import * as API_URL from "../api/apiUrlConstants";
import REPORT_COLUMNS from "../columns/analyzeReportsColumns";
import { makeToastData } from "./sagautils";

const getReducer = state => state.reducer;
const getAnalyzeReducer = state => state.analyzeReducer;
const getProtectReducer = state => state.protectReducer;

let queryParams = "";
let advanceSearch = {};

/* 
Prevents overwrite of current page's report data when the previous page's
 data is loading but the page(navigation) has changed
*/
export function* verifyCurrentNavigation(navigation) {
  const navigationAfter = yield select(getReducer);
  const analyzeSubReportNavigationEqual =
    constant.ANALYZE_REPORTS_SUBREPORTS.includes(
      navigationAfter.secondaryNavigation
    ) && navigationAfter.secondaryNavigation === navigation;
  const notAnalyzeSubReport = !constant.ANALYZE_REPORTS_SUBREPORTS.includes(
    navigationAfter.secondaryNavigation
  );
  return analyzeSubReportNavigationEqual || notAnalyzeSubReport;
}

export function* receiveReportList(data, reportId) {
  try {
    const stateReducer = yield select(getReducer);
    const protectReducer = yield select(getProtectReducer);
    const analyzeReducer = yield select(getAnalyzeReducer);
    const stateLogin = yield select(getLogin);
    if (
      !constant.SOURCES_GRID_LOCALLOAD_PAGES.includes(
        stateReducer.secondaryNavigation
      ) &&
      //eslint-disable-next-line eqeqeq
      stateReducer.secondaryNavigation !=
        constant.SECONDARY_NAVIGATION_TYPE.POLICIES
    )
      yield put(startLoader());
    let stateReducerOptions = [];
    let navigation = stateReducer.secondaryNavigation;

    if (
      data &&
      data.payload &&
      data.payload.advancedSearchOptions &&
      data.payload.advancedSearchOptions.length > 0
    ) {
      stateReducerOptions = data.payload.advancedSearchOptions;
    } else if (
      stateReducer.selectedFilterOptions &&
      stateReducer.selectedFilterOptions.length > 0
    ) {
      stateReducerOptions = stateReducer.selectedFilterOptions;
    } else if (
      stateReducer.currentSearchOptions &&
      stateReducer.currentSearchOptions.length > 0
    ) {
      stateReducerOptions = stateReducer.currentSearchOptions;
    }

    if (data && data.payload && data.payload.filter) {
      navigation = data.payload.filter;
    }

    let sortedDataSet = [];
    if (data && data.payload && data.payload.sortedData) {
      sortedDataSet = data.payload.sortedData;
    } else if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedDataSet = stateReducer.sortedColumns;
    } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
      sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
    }

    let stateReducersearchText =
      data && data.payload && data.payload.searchText
        ? data.payload.searchText
        : "";

    let stateReducersearchOption = stateReducer.searchOption.selectedOption;
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    const gridCurrentPage =
      data && data.payload && data.payload.gridCurrentPage
        ? data.payload.gridCurrentPage
        : stateReducer.gridCurrentPage;
    const gridPageSize =
      data && data.payload && data.payload.gridPageSize
        ? data.payload.gridPageSize
        : stateReducer.gridPageSize;
    let apiResponse = null;
    let filterType = "";
    let queryString = "";
    let makeQueryString = util.getQueryStringByAdvanceSearchOptions(
      queryString,
      stateReducerOptions
    );
    let getSelectedFiltersOnly = makeQueryString;

    if (makeQueryString && makeQueryString.length > 0) {
      yield put(submitAdvancedSearch());
      const selectedOptions = stateReducerOptions;
      const searchString = stateReducersearchOption;
      // update the state options only when doNotUpdateOptions is not passed or is true
      if (data && data.payload && !data.payload.doNotUpdateOptions) {
        yield put(
          updateCurrentSearchFromSaved({ selectedOptions, searchString })
        );
      }
    }

    if (
      stateReducersearchText &&
      stateReducersearchText.length > 0 &&
      data &&
      data.payload &&
      !data.payload.doNotUpdateOptions
    ) {
      yield put(searchEnterEvent(data.payload));
    }

    if (gridCurrentPage && gridPageSize) {
      if (makeQueryString && makeQueryString.length > 0) {
        makeQueryString += `&`;
      }
      makeQueryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
    }

    // if (organizationId) {
    //   if (makeQueryString && makeQueryString.length > 0) {
    //     makeQueryString += `&`;
    //   }
    //   if (makeQueryString.includes("organization_id")) {
    //     makeQueryString = util.replaceAll(
    //       makeQueryString,
    //       "organization_id",
    //       "report_for_organization"
    //     );
    //   }
    // }

    if (navigation !== constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS) {
      if (makeQueryString && makeQueryString.length > 0) {
        makeQueryString += `&`;
      }
      makeQueryString += `organization_id=${organizationId}`;
    }

    if (
      navigation === constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT
    ) {
      const separator = encodeURIComponent("|");
      makeQueryString += `&destination_type=datastore${separator}cloud_direct_volume`;
      if (stateLogin.organization_type === "msp") {
        makeQueryString += "&stored_data=true";
      }
    }
    // else if (
    //   navigation === constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT
    // ) {
    //   if (makeQueryString && makeQueryString.length > 0) {
    //     makeQueryString += `&`;
    //   }
    //   makeQueryString += `product_type=cloud_direct`;
    // }

    let searchNameParam = REPORTS_SEARCH_NAME_PARAM[navigation] || "";
    if (
      reportId ||
      (stateReducer.resourceId && stateReducer.resourceId !== "")
    ) {
      if (makeQueryString && makeQueryString.length > 0) {
        makeQueryString += `&`;
      }
      makeQueryString += `report_id=${
        reportId ? reportId : stateReducer.resourceId
      }`;

      if (sortedDataSet.length) {
        makeQueryString = util.getQueryStringFromSortedData(
          makeQueryString,
          sortedDataSet
        );
      }

      queryString = makeQueryString;
    } else {
      if (sortedDataSet.length) {
        makeQueryString = util.getQueryStringFromSortedData(
          makeQueryString,
          sortedDataSet
        );
      }
      yield put(showLoading());

      if (
        stateLogin.organization_type === "msp" &&
        (navigation ===
          constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT ||
          navigation ===
            constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT ||
          navigation ===
            constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT) //verify
      ) {
        searchNameParam = ReportsAttributes.REPORTS_PROP_ORGANIZATION;
      }

      queryString = util.getQueryStringBySearchNameText(
        makeQueryString,
        stateReducersearchText,
        stateReducersearchOption,
        searchNameParam
      );
      queryString = util.replaceAll(
        queryString,
        "date_range",
        REPORT_FILTER_TIME_FIELD[navigation]
      );
    }
    //eslint-disable-next-line default-case
    switch (navigation) {
      //TODO move alerts out of this.....as alerts are not part of reports
      case constant.SECONDARY_NAVIGATION_TYPE.ALERTS:
        yield put(startGridLoaderById(constant.DATAGRID_IDS["alerts_grid"]));
        apiResponse = yield call(
          analyzeApi.fetchAlertsList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (apiResponse) {
          if (apiResponse.status === constant.HTTP_STATUS.OK) {
            if (data.payload && data.payload.resourceID) {
              yield put(
                setResourceName(
                  analyzeReducer.selectedResource.report_name.label
                )
              );
            }
            yield put(showReportList(apiResponse, filterType));
            yield put(fromNavigationApi(false));
          } else {
            yield put(addAPIresponse(apiResponse));
          }
        }
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["alerts_grid"]));
        yield put(hideLoading());
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.REPORTS:
      case constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.REPORT_DETAIL:
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT:
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT_TYPE:
        if (
          navigation ===
          constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT
        ) {
          if (
            !data.payload.advancedSearchOptions &&
            Object.keys(protectReducer.selectedSavedSearch).length > 0
          ) {
            let selectedQuery = util.getQueryStringBySavedSearch(
              protectReducer.selectedSavedSearch,
              makeQueryString
            );
            makeQueryString = `${makeQueryString}&${selectedQuery}`;
          }
        }
        if (getDGId(navigation)) {
          yield put(startGridLoaderById(getDGId(navigation)));
        }
        if (navigation === constant.SECONDARY_NAVIGATION_TYPE.REPORTS) {
          queryString = util.replaceAll(
            queryString,
            "schedule_time",
            "schedule_frequency"
          );
          queryString = util.replaceAll(
            queryString,
            "range_type",
            "date_range_type"
          );
        } else if (
          navigation ===
          constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
        ) {
          getSelectedFiltersOnly = util.getQueryStringBySearchNameText(
            getSelectedFiltersOnly,
            stateReducersearchText,
            stateReducersearchOption,
            searchNameParam
          );
          // yield call(
          //   getSourceProtectionSourcesSummary,
          //   data,
          //   getSelectedFiltersOnly
          // );
        }
        apiResponse = yield call(
          analyzeApi.fetchReportsList,
          queryString,
          stateLogin.token,
          navigation,
          data.pollingAction
        );
        if (apiResponse) {
          if (apiResponse.status === constant.HTTP_STATUS.OK) {
            if (data.payload && data.payload.resourceID) {
              yield put(
                setResourceName(
                  analyzeReducer.selectedResource.report_name.label
                )
              );
            }

            const verifyNavigation = yield call(
              verifyCurrentNavigation,
              navigation
            );
            if (verifyNavigation) {
              yield put(showReportList(apiResponse, filterType));
            }
            yield put(fromNavigationApi(false));
          } else {
            yield put(addAPIresponse(apiResponse));
          }
        }
        yield put(hideLoading());
        yield put(stopLoader());
        if (getDGId(navigation)) {
          yield put(stopGridLoaderById(getDGId(navigation)));
        }
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS:
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS:
        queryString += "&content=dynamic";
        const selectedFilters =
          getSelectedFiltersOnly.length > 0
            ? `${getSelectedFiltersOnly}&pagination=false`
            : "pagination=false";
        yield call(getAccountDetailsData, queryString, selectedFilters);
        yield put(hideLoading());
        yield put(stopLoader());
        break;
    }
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["alerts_grid"]));
  } finally {
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
    yield put(hideLoading());
  }
}

function getDGId(navigation) {
  switch (navigation) {
    case constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT:
      return constant.DATAGRID_IDS["backup_jobs_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.REPORTS:
      return constant.DATAGRID_IDS["reports_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT:
      return constant.DATAGRID_IDS["datatransfer_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT:
      return constant.DATAGRID_IDS["capcaity_usage_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT:
      return constant.DATAGRID_IDS["recovery_point_report_grid"];
    case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT:
      return constant.DATAGRID_IDS["source_protection_report_grid"];
    default:
      return "";
  }
}

export function* receiveReportSchedules(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const getOptionsState = makeOptionsState();
    const advancedSearchOptions = yield select(getOptionsState);
    const stateReducersearchOption = stateReducer.searchOption.selectedOption;
    let sortedColumns = [];
    if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedColumns = stateReducer.sortedColumns;
    }

    yield put(showLoading());
    yield put(showGridLoader(constant.DATAGRID_IDS["manage_schedules_grid"]));

    let params = [];

    params.push(`organization_id=${stateLogin.organization_id}`);
    if (advancedSearchOptions !== undefined) {
      const allSelected = advancedSearchOptions.map(option => ({
        name: option.name,
        filter: option.selectedOptions.map(so => so.value).join("%7C")
      }));

      allSelected
        .map(o => `${o.name}=${o.filter}`)
        .forEach(param => params.push(param));
    }

    if (stateReducer.searchText) {
      params.push(`report_name=${stateReducer.searchText}`);

      //TODO refactor this
      //maybe integrate searchWithText with advancedSearching
      //so its no need to fire it here explicitly
      yield put(searchEnterEvent(data.payload));
    } else if (stateReducersearchOption) {
      params.push(`report_name=${stateReducersearchOption}`);
    }

    if (
      (data.payload.gridCurrentPage !== undefined ||
        data.payload.gridCurrentPageValue !== undefined) &&
      data.payload.gridPageSize !== undefined
    ) {
      let currPage = data.payload.gridCurrentPage
        ? data.payload.gridCurrentPage
        : data.payload.gridCurrentPageValue;
      params.push(`page=${currPage}&page_size=${data.payload.gridPageSize}`);
    }

    if (sortedColumns.length > 0) {
      const sortString = sortedColumns
        .map(col => `${col.desc ? "-" : ""}${col.id}`)
        .join(",");
      params.push(`sort=${sortString}`);
    }
    const apiResponse = yield call(
      analyzeApi.getReportSchedules,
      stateLogin.token,
      params.join("&")
    );

    yield put(showReportList(apiResponse, ""));
    yield put(hideLoading());
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["manage_schedules_grid"])
    );
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* deleteScheduleReportForManageSchedules(action) {
  try {
    const stateLogin = yield select(getLogin);
    const { ids, deleteInstances } = action.payload;
    let queryString = "";

    yield put(showLoading());

    if (deleteInstances) {
      queryString = "?delete_report_instance=true";
    }
    const apiResponse = yield call(
      analyzeApi.deleteScheduleReport,
      ids,
      queryString,
      stateLogin.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      let toastData =
        constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_SUCCESS;
      if (action.dgId) {
        toastData = {
          messageId:
            constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_SUCCESS,
          testId: `${action.dgId}_delete_schedule_report`
        };
      }
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_SUCCESS;
      yield put(ccToast.addSuccessNotification(toastData));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
    yield call(receiveReportSchedules, {
      payload: action.payload.fetchOptions
    });
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* deleteAnalyzeAlert(action) {
  try {
    const stateLogin = yield select(getLogin);
    const { ids } = action.payload;
    yield put(showLoading());

    for (let i = 0; i < ids.length; ++i) {
      const apiResponse = yield call(
        analyzeApi.deleteAnalyzeAlert,
        ids[i],
        stateLogin.token,
        { showCommonError: true, testId: "Dwzcij" }
      );

      if (constant.HTTP_STATUS.OK === apiResponse.status) {
        const toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.DELETE_ANALYZE_ALERT_SUCCESS,
          testId: "Dwzcij"
        };
        // apiResponse.context =
        //   constant.API_RESPONSE_CONTEXT.DELETE_ANALYZE_ALERT_SUCCESS;
        yield put(ccToast.addSuccessNotification(toastData));
      }
      // } else {
      //   apiResponse.context =
      //     constant.API_RESPONSE_CONTEXT.DELETE_ANALYZE_ALERT_FAILURE;
      // }
      yield put(addAPIresponse(apiResponse));
    }
    yield put(fetchAnalyzeAlerts());
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* updateReportSchedule(action) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.CREATE_REPORT));
    yield put(showLoading());

    const apiResponse = yield call(
      analyzeApi.updateReportSchedule,
      action.payload.report.formValues.schedule_id,
      action.payload.report.formValues,
      stateLogin.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      let toastData =
        constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS;
      if (action.dgId) {
        toastData = {
          messageId:
            constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS,
          testId: `${action.dgId}_update_schedule_report`
        };
      }
      yield put(ccToast.addSuccessNotification(toastData));
      yield put(closeReportModal());
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS;
    }
    yield put(addAPIresponse(apiResponse));
    yield call(receiveReportSchedules, {
      payload: action.payload.fetchOptions
    });
    yield put(resetLoading(Loader.CREATE_REPORT));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* deleteReportByID(payload) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());
    const apiResponse = yield call(
      analyzeApi.deleteReport,
      payload.id,
      stateLogin.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(searchList());
      let toastData = constant.API_RESPONSE_CONTEXT.DELETE_REPORT_SUCCESS;
      if (payload.dgId) {
        toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.DELETE_REPORT_SUCCESS,
          testId: `${payload.dgId}_delete_report`
        };
      }
      yield put(ccToast.addSuccessNotification(toastData));
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.DELETE_REPORT_SUCCESS;
    } else {
      let toastData = constant.API_RESPONSE_CONTEXT.DELETE_REPORT_FAILURE;
      if (payload.dgId) {
        toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.DELETE_REPORT_FAILURE,
          testId: `${payload.dgId}_delete_report`
        };
      }
      yield put(ccToast.addErrorNotification(toastData));
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.DELETE_REPORT_FAILURE;
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* deleteScheduleReport(payload) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());
    const apiResponse = yield call(
      analyzeApi.deleteScheduleReport,
      payload.id,
      stateLogin.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(searchList());
      let toastData =
        constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_SUCCESS;
      if (payload.dgId) {
        toastData = {
          messageId:
            constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_SUCCESS,
          testId: `${payload.dgId}_delete_schedule_report`
        };
      }
      yield put(ccToast.addSuccessNotification(toastData));
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_SUCCESS;
    } else {
      let toastData =
        constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_FAILURE;
      if (payload.dgId) {
        toastData = {
          messageId:
            constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_FAILURE,
          testId: `${payload.dgId}_delete_schedule_report`
        };
      }
      yield put(ccToast.addErrorNotification(toastData));
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.DELETE_SCHEDULE_REPORT_FAILURE;
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* runReportNow(action) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());
    const apiResponse = yield call(
      analyzeApi.runReportNow,
      action.data.id,
      stateLogin.token,
      { showCommonError: true, testId: "eHR1lb" },
      action.data.isBulk
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      if (action.data.isBulk) {
        const toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.RUN_REPORTS_SUCCESS,
          testId: "eHR1lb"
        };
        yield put(ccToast.addSuccessNotification(toastData));
      } else {
        const toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.RUN_REPORT_SUCCESS,
          testId: "eHR1lb"
        };
        yield put(ccToast.addSuccessNotification(toastData));
      }
      // apiResponse.context = action.data.isBulk
      //   ? constant.API_RESPONSE_CONTEXT.RUN_REPORTS_SUCCESS
      //   : constant.API_RESPONSE_CONTEXT.RUN_REPORT_SUCCESS;
    }
    yield call(receiveReportSchedules, {
      payload: action.data.fetchOptions
    });
    yield put(addAPIresponse(apiResponse));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* renderReportOverlay(action) {
  try {
    const loginReducer = yield select(getLogin);
    let report = {};
    yield put(showLoading());
    switch (action.trigger) {
      //eslint-disable-next-line no-lone-blocks
      case constant.REPORT_OVERLAY_TRIGGER_SOURCE.EDIT_ALERTS_SETTINGS:
        //eslint-disable-next-line  no-lone-blocks
        {
          report["alert_name"] = action.data.alert_name;
          report["alert_type"] = action.data.alert_type;
        }
        report = Object.assign({}, action.data);
        yield put(initializeEmailSelector(action.data.recipient));
        break;
      case constant.REPORT_OVERLAY_TRIGGER_SOURCE.EDIT_REPORT_SETTINGS:
        //eslint-disable-next-line  no-lone-blocks
        {
          //TODO:  wraper to transform data for redux form
          report["report_id"] = action.data.report_id;
          report["report_name"] = action.data.report_name;
          report["report_type"] = action.data.report_type;
          if (action.data.report_for && action.data.report_for.type) {
            report["report_for"] = { type: action.data.report_for.type };
          }
          action.data.report_schedule
            ? (report["report_schedule"] = action.data.report_schedule)
            : void 0;
          action.data.schedule
            ? (report["schedule"] = action.data.schedule)
            : void 0;
          action.data.schedule_frequency
            ? (report["schedule_frequency"] = action.data.schedule_frequency)
            : void 0;
        }
        report = Object.assign({}, action.data);
        yield put(initializeEmailSelector(report.recipient_mail));
        break;
      default:
        break;
    }
    let sourceGroups = [];
    let orgList = [];
    if (loginReducer.organization_type === "msp") {
      let orgResp = yield call(
        protectApi.fetchCustomerAccountsResult,
        "pagination=false",
        loginReducer.token,
        loginReducer.organization_id
      );
      orgList = util.multiDropdownValues(
        orgResp.data.filter(org => !org.sub_msp),
        "organization_id",
        "organization_name"
      );
    } else {
      let sourceGroupsResp = yield call(
        protectApi.fetchSourcesGroups,
        `organization_id=${loginReducer.organization_id}&pagination=false`,
        loginReducer.token
      );
      sourceGroups = util.multiDropdownValues(
        sourceGroupsResp.data,
        "group_id",
        "group_name"
      );
    }

    yield put(
      updateReport({
        report,
        sourceGroups,
        orgList,
        trigger_source: action.trigger
      })
    );
    yield put(hideLoading());
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
    yield put(hideLoading());
  }
}

export function* createReport(payload) {
  let apiResponse = null;
  yield put(setLoading(Loader.CREATE_REPORT));
  try {
    const loginReducer = yield select(getLogin);
    const dataWithOrganizationId = {
      ...payload.data,
      organization_id: loginReducer.organization_id
    };
    apiResponse = yield call(
      analyzeApi.createReport,
      dataWithOrganizationId,
      loginReducer.token
    );
    if (
      apiResponse.status === constant.HTTP_STATUS.OK ||
      apiResponse.status === constant.HTTP_STATUS.CREATED
    ) {
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.CREATE_REPORT_SUCCESS;
      yield put(searchList());
      if (apiResponse.data && apiResponse.data.schedule_id !== null) {
        let toastData =
          constant.API_RESPONSE_CONTEXT.CREATE_REPORT_SCHEDULE_SUCCESS;
        if (payload.dgId) {
          toastData = {
            messageId:
              constant.API_RESPONSE_CONTEXT.CREATE_REPORT_SCHEDULE_SUCCESS,
            testId: `${payload.dgId}_create_schedule_report`
          };
        }
        yield put(ccToast.addSuccessNotification(toastData));
        yield put(resetLoading(Loader.CREATE_REPORT));
        yield put(closeReportModal());
      } else {
        let toastData = constant.API_RESPONSE_CONTEXT.CREATE_REPORT_SUCCESS;
        if (payload.dgId) {
          toastData = {
            messageId: constant.API_RESPONSE_CONTEXT.CREATE_REPORT_SUCCESS,
            testId: `${payload.dgId}_create_report`
          };
        }
        yield put(ccToast.addSuccessNotification(toastData));
        yield put(resetLoading(Loader.CREATE_REPORT));
        yield put(closeReportModal());
      }
    } else {
      yield put(resetLoading(Loader.CREATE_REPORT));
    }
  } catch (error) {
    yield put(
      ccToast.addErrorNotification(
        makeToastData(apiResponse.errors[0].message, `pwLIX5`)
      )
    );
    yield put(resetLoading(Loader.CREATE_REPORT));
  }
}

export function* updateAnalyzeAlert(payload) {
  try {
    const stateLogin = yield select(getLogin);

    yield put(showLoading());
    let putBody = {
      alert_type: payload.data.alert_type,
      alert_name: payload.data.alert_name,
      organization_id: payload.data.organization_id,
      recipient: payload.data.recipient,
      report_for: payload.data.report_for
    };
    const apiResponse = yield call(
      analyzeApi.updateAnalyzeAlert,
      payload.data.alert_email_recipients_id,
      putBody,
      stateLogin.token,
      { showCommonError: true, testId: "Dwzcij" }
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.UPDATE_ALERT_SETTINGS_SUCCESS,
        testId: "Dwzcij"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield put(fetchAnalyzeAlerts());
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS;
    }
    // else {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_FAILURE;
    // }
    yield put(addAPIresponse(apiResponse));

    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* handleFetchAnalyzeAlerts() {
  try {
    const stateReducer = yield select(getReducer);

    const loginReducer = yield select(getLogin);
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : loginReducer.organization_id;

    const alertsResponse = yield call(
      analyzeApi.fetchAlertsList,
      `organization_id=${organizationId}`,
      loginReducer.token,
      null
    );
    if (alertsResponse.status === constant.HTTP_STATUS.OK) {
      yield put(showReportList(alertsResponse, null));
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* createAnalyzeAlert(payload) {
  try {
    const loginReducer = yield select(getLogin);

    const dataWithOrganizationId = {
      ...payload.data,
      organization_id: loginReducer.organization_id
    };
    yield put(showLoading());
    let apiResponse = yield call(
      analyzeApi.createAlert,
      dataWithOrganizationId,
      loginReducer.token,
      { showCommonError: true, testId: "Dwzcij" }
    );
    if (
      apiResponse.status === constant.HTTP_STATUS.OK ||
      apiResponse.status === constant.HTTP_STATUS.CREATED
    ) {
      if (
        apiResponse.data &&
        apiResponse.data.alert_email_recipients_id !== null
      ) {
        const toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.CREATE_ANALYZE_ALERT_SUCCESS,
          testId: "Dwzcij"
        };
        yield put(ccToast.addSuccessNotification(toastData));
      }
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.CREATE_ANALYZE_ALERT_SUCCESS;
      yield put(fetchAnalyzeAlerts());
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* updateReportSettings(payload) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse;
    if (
      payload.data &&
      payload.data.report_schedule &&
      payload.data.report_schedule === "generate_now"
    ) {
      apiResponse = yield call(
        analyzeApi.updateReportSettingsForGenerateNow,
        payload.data.schedule_id,
        payload.data,
        loginReducer.token
      );
    } else {
      apiResponse = yield call(
        analyzeApi.updateReportSettings,
        payload.data.schedule_id,
        payload.data,
        loginReducer.token
      );
    }
    if (
      apiResponse.status === constant.HTTP_STATUS.OK ||
      apiResponse.status === constant.HTTP_STATUS.CREATED
    ) {
      yield put(searchList());
      let toastData =
        constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS;
      if (payload.dgId) {
        toastData = {
          messageId:
            constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS,
          testId: `${payload.dgId}_update_report`
        };
      }
      yield put(ccToast.addSuccessNotification(toastData));
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_SUCCESS;
    }
    // else {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.UPDATE_REPORT_SETTINGS_FAILURE;
    // }
    yield put(addAPIresponse(apiResponse));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* exportReportAsCSV(payload) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);

    let stateReducerOptions = [];
    let navigation = stateReducer.secondaryNavigation;

    if (
      payload &&
      payload.data &&
      payload.data.advancedSearchOptions &&
      payload.data.advancedSearchOptions.length
    ) {
      stateReducerOptions = payload.data.advancedSearchOptions;
    } else if (
      stateReducer.selectedFilterOptions &&
      stateReducer.selectedFilterOptions.length
    ) {
      stateReducerOptions = stateReducer.selectedFilterOptions;
    } else if (
      stateReducer.currentSearchOptions &&
      stateReducer.currentSearchOptions.length
    ) {
      stateReducerOptions = stateReducer.currentSearchOptions;
    }

    let sortedDataSet = [];
    if (payload.data && payload.data.sortedData) {
      sortedDataSet = payload.data.sortedData;
    } else if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedDataSet = stateReducer.sortedColumns;
    } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
      sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
    }

    let stateReducersearchText =
      payload.data && payload.data.searchText
        ? payload.data.searchText
        : stateReducer.searchText;

    let stateReducersearchOption = stateReducer.searchOption.selectedOption;
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;

    let apiResponse = null;
    let queryString = "";
    let makeQueryString = util.getQueryStringByAdvanceSearchOptions(
      queryString,
      stateReducerOptions
    );

    if (payload.data && payload.data.filter) {
      navigation = payload.data.filter;
    }

    if (organizationId) {
      if (makeQueryString && makeQueryString.length > 0) {
        makeQueryString += `&`;
      }
      makeQueryString += `organization_id=${organizationId}&export=csv`;
    }

    if (
      navigation === constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT
    ) {
      const separator = encodeURIComponent("|");
      makeQueryString += `&destination_type=datastore${separator}cloud_direct_volume`;
      if (stateLogin.organization_type === "msp") {
        makeQueryString += "&stored_data=true";
      }
    }
    // else if (
    //   navigation === constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT
    // ) {
    //   makeQueryString += "&product_type=cloud_direct";
    // }

    if (sortedDataSet.length) {
      makeQueryString = util.getQueryStringFromSortedData(
        makeQueryString,
        sortedDataSet
      );
    }
    // required for report details
    if (payload.data && payload.data.reportId) {
      makeQueryString += `&report_id=${payload.data.reportId}`;
    }

    if (navigation === constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS) {
      let page = stateReducer.gridCurrentPage;
      let pageSize = stateReducer.gridPageSize;
      if (page && pageSize) {
        makeQueryString = `page=${page}&page_size=${pageSize}&content=dynamic&export=csv&account_details_export=true`;
      }
      makeQueryString = util.getQueryStringFromSortedData(
        makeQueryString,
        sortedDataSet
      );
    }

    let searchNameParam = REPORTS_SEARCH_NAME_PARAM[navigation] || "";

    if (
      stateLogin.organization_type === "msp" &&
      navigation === constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT
    ) {
      searchNameParam = ReportsAttributes.REPORTS_PROP_ORGANIZATION;
    }

    if (
      stateLogin.organization_type === "msp" &&
      navigation === constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
    ) {
      searchNameParam = ReportsAttributes.REPORTS_PROP_ORGANIZATION;
    }

    queryString = util.getQueryStringBySearchNameText(
      makeQueryString,
      stateReducersearchText,
      stateReducersearchOption,
      searchNameParam
    );
    queryString = util.replaceAll(
      queryString,
      "date_range",
      REPORT_FILTER_TIME_FIELD[navigation]
    );

    apiResponse = yield call(
      analyzeApi.exportReportsListAsCSV,
      queryString,
      stateLogin.token,
      navigation,
      organizationId
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      let toastData = constant.API_RESPONSE_CONTEXT.ANALYZE_CSV_REPORT_SUCCESS;
      if (payload.dgId) {
        toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.ANALYZE_CSV_REPORT_SUCCESS,
          testId: `${payload.dgId}_csv_export_report`
        };
      }
      yield put(ccToast.addSuccessNotification(toastData));
      // apiResponse.context = "analyze.csv.export";
      yield put(addAPIresponse(apiResponse));
    }

    /*if (
      apiResponse &&
      apiResponse.response &&
      apiResponse.response.type.includes("text/csv")
    ) {
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(apiResponse.response);
        return;
      }
      // Other browsers
      // Create a link pointing to the ObjectURL containing the blob
      const blobURL = window.URL.createObjectURL(apiResponse.response);
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.setAttribute("download", apiResponse.filename);
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      util.eventFire(tempLink, "click");
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
      }, 100);

    } */
  } catch (e) {
    window.console.log(e);
  }
}

//TODO graphs clean up
export function* getBackupJobsData(data, reportId) {
  try {
    const stateReducer = yield select(getReducer);
    getQueryParams(data, stateReducer);
    advanceSearch = data;
    yield put(showLoading());

    let selectedValue = selectedDateRangeValue(advanceSearch);
    //Assinging last_24_hours default as It will be blank in case advanced search remover search items.
    selectedValue === "" ? (selectedValue = constant.last_24_hours) : void 0;
    yield put(updateSelectedDateRangeValue(selectedValue));
  } catch (e) {
    window.console.error(e);
  }
}

export function* filterBackupJobsTopSourcesData(data, reportId) {
  try {
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let response;
    getQueryParams(data, stateReducer);
    advanceSearch = data;
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    let filterParams = `${constant.FILTER_TYPE}=${data.filterType}`;

    let queryString =
      queryParams && queryParams.length
        ? `${filterParams}&${queryParams}&organization_id=${organizationId}`
        : `${filterParams}&organization_id=${organizationId}`;

    if (
      reportId ||
      (stateReducer.resourceId && stateReducer.resourceId !== "")
    ) {
      queryString += `&report_id=${reportId || stateReducer.resourceId}`;
    } else {
      queryString = util.replaceAll(
        queryString,
        "date_range",
        REPORT_FILTER_TIME_FIELD["backup_jobs"]
      );
    }
    queryString += `&time_zone=${util.getTimezone()}`;
    response = yield call(
      analyzeApi.getBackupJobsData,
      `${API_URL.FETCH_BACKUP_JOBS_TOP_SOURCES}`,
      queryString,
      stateLogin.token
    );
    yield put(hideLoading());
    yield put(updateBackupJobsTopSources(response));
  } catch (e) {
    window.console.error(e);
  }
}

//TODO graphs clean up
export function* refreshBackupJobsTopSourcesData(data) {
  // let filterType =
  //   data.data.filterType !== "" && data.data.filterType !== undefined
  //     ? `filter_type=${data.data.filterType}`
  //     : "";
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    advanceSearch = data;
    yield put(showLoading());

    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    let queryString =
      queryParams && queryParams.length
        ? `${queryParams}&organization_id=${organizationId}`
        : `organization_id=${organizationId}`;

    // filterType = filterType !== "" ? (queryString += "&" + filterType) : "";
    queryString = util.replaceAll(
      queryString,
      "date_range",
      REPORT_FILTER_TIME_FIELD["backup_jobs"]
    );
    queryString += `&time_zone=${util.getTimezone()}`;
    const response = yield call(
      analyzeApi.getBackupJobsData,
      `${API_URL.FETCH_BACKUP_JOBS_TOP_SOURCES}`,
      queryString,
      stateLogin.token
    );

    yield put(hideLoading());
    yield put(updateBackupJobsTopSources(response));
    yield put(fromNavigationApi(false));
  } catch (e) {
    window.console.error(e);
  }
}

function* getAllColumnsData() {
  try {
    yield put(updateColumnData(REPORT_COLUMNS));
  } catch (e) {
    window.console.log(e);
  }
}

export function* jobsAndDestinationAdvancedSearch(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    getQueryParams(data, stateReducer);
    let selectedValue = selectedDateRangeValue(data);
    yield put(showLoading());
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    let queryString =
      queryParams && queryParams.length
        ? `${queryParams}&organization_id=${organizationId}`
        : `organization_id=${organizationId}`;
    queryString = util.replaceAll(
      queryString,
      "date_range",
      REPORT_FILTER_TIME_FIELD["jobs_and_destinations"]
    );
    queryString += `&time_zone=${util.getTimezone()}`;
    const toSource = yield call(
      analyzeApi.getBackupJobsData,
      `${API_URL.FETCH_JOBS_AND_DESTINATION_TREND_TOP_SOURCE}`,
      queryString,
      stateLogin.token
    );
    const topDestination = yield call(
      analyzeApi.getBackupJobsData,
      `${API_URL.FETCH_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION}`,
      queryString,
      stateLogin.token
    );
    const topJobsSummary = yield call(
      analyzeApi.getBackupJobsData,
      `${API_URL.FETCH_JOBS_AND_DESTINATIONS_JOBS_SUMMARY}`,
      queryString,
      stateLogin.token
    );
    const donutData = processData(topJobsSummary, selectedValue, "task_type");
    //  yield put(updateSelectedDateRangeValue(selectedValue));
    yield put(updateJobsAndDestinationJobsSummary(donutData.data));
    yield put(updateJobsAndDestinationTopDestination(topDestination));
    yield put(updateJobsAndDestinationTopSources(toSource));
    yield put(hideLoading());
  } catch (e) {
    window.console.error(e);
  }
}

function getQueryParams(data, stateReducer) {
  let stateReducerOptions = [];
  let navigation = stateReducer.secondaryNavigation;
  let searchNameParam = REPORTS_SEARCH_NAME_PARAM[navigation] || "";
  let stateReducersearchText = "";
  if (data && data.payload && data.payload.searchText) {
    stateReducersearchText = data.payload.searchText;
  } else if (stateReducer) {
    stateReducersearchText = stateReducer.searchText;
  }
  let stateReducersearchOption = "";
  if (stateReducer && stateReducer.searchOption.selectedOption) {
    stateReducersearchOption = stateReducer.searchOption.selectedOption;
  }
  if (
    data &&
    data.payload &&
    data.payload.advancedSearchOptions &&
    data.payload.advancedSearchOptions.length > 0
  ) {
    stateReducerOptions = data.payload.advancedSearchOptions;
  } else {
    stateReducerOptions = stateReducer.currentSearchOptions;
  }

  let queryString = "";
  let makeQueryString = util.getQueryStringByAdvanceSearchOptions(
    queryString,
    stateReducerOptions
  );
  queryParams = util.getQueryStringBySearchNameText(
    makeQueryString,
    stateReducersearchText,
    stateReducersearchOption,
    searchNameParam
  );
}

export function* fetchSingleReportData(action) {
  try {
    yield put(
      updateReportTypeForNavingation({
        report_id: action.data.report_id,
        report_type: action.data.report_type,
        report_name: action.data.report_name,
        date_range_type: action.data.date_range_type
      })
    );
    if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT,
          sortedData: [
            {
              id: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
              desc: false
            }
          ]
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT,
          sortedData: [
            { desc: false, id: ReportsAttributes.REPORTS_PROP_JOB_STATUS }
          ]
        }
      };
      yield call(getBackupJobsData, undefined, action.data.report_id);
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT,
          sortedData: [
            { desc: false, id: ReportsAttributes.REPORTS_PROP_JOB_STATUS }
          ]
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT,
          sortedData: [
            {
              id: ReportsAttributes.REPORTS_PROP_DESTINATION_NAME,
              desc: false
            }
          ]
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT,
          sortedData: [
            { desc: false, id: ReportsAttributes.REPORTS_PROP_JOB_STATUS }
          ]
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT,
          sortedData: []
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT_TYPE
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT_TYPE,
          sortedData: [
            {
              id: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
              desc: false
            }
          ]
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    } else if (
      action.data.report_type ===
      constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS
    ) {
      let data = {
        payload: {
          filter: constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS,
          sortedData: []
        }
      };
      yield call(receiveReportList, data, action.data.report_id);
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.error(e);
  } finally {
    yield put(hideLoading());
  }
}
// function generateSummaryData(data) {
//   let tempArray = [];
//   let obj = {
//     averagedata: [],
//     dataByTopic: data.data_transfer_summary
//   };
//   let KeyMappingDataSummary = {
//     processed_bytes: "data_processed",
//     transferred_bytes: "data_transferred",
//     written_bytes: "data_written",
//     total_dedupe_saving: "dedupe_savings",
//     total_source_data: "source_data"
//   };
//   let legends = [];
//   // Generate dynamic object to render legends
//   for (let node in data) {
//     if (typeof data[node] !== "object") {
//       legends.push({
//         label: KeyMappingDataSummary[node],
//         value: util.formatBytes(data[node])
//       });
//     }
//   }

//   data.data_transfer_summary &&
//     data.data_transfer_summary.forEach(node => {
//       Object.assign(node, {
//         topicName: constant.ConvertText[node.topic_name],
//         dates: node.date_value
//       });

//       obj.averagedata.push(
//         _.find(legends, item => item.label === node.topic_name)
//       );
//       delete node.topic_name;
//       delete node.date_value;
//     });

//   tempArray.push(obj);
//   return tempArray;
// }
// todo to make generic
// function generateDedupeData(data, arrayKey = "dedupe_savings_summary") {
//   let tempArray = [];
//   let obj = {
//     averagedata: [],
//     dataByTopic: data.dedupe_savings_summary
//   };
//   let KeyMappingDataSummary = {
//     processed_bytes: "data_processed",
//     transferred_bytes: "data_transferred",
//     written_bytes: "data_written",
//     total_dedupe_saving: "dedupe_savings",
//     total_source_data: "source_data"
//   };
//   let legends = [];
//   let labels = [];
//   for (let node in data) {
//     if (typeof data[node] !== "object") {
//       // delete data.total_dedupe_saving;
//       if (
//         arrayKey === "dedupe_savings_summary" ||
//         arrayKey === "total_dedupe_saving"
//       ) {
//         labels.push(data[node]);
//       }
//       legends.push({
//         label: KeyMappingDataSummary[node],
//         value: util.formatBytes(data[node])
//       });
//     }
//   }

//   data.dedupe_savings_summary.forEach(node => {
//     Object.assign(node, {
//       topicName: constant.ConvertText[node.topic_name],
//       dates: node.date_value
//     });

//     obj.averagedata.push(
//       _.find(legends, item => item.label === node.topic_name)
//     );
//     delete node.topic_name;
//     delete node.date_value;
//   });

//   tempArray.push(obj);
//   return tempArray;
// }

/**
 * To get the selected Date range value
 * @param data - advance search data
 */
function selectedDateRangeValue(data) {
  let filterByValue = "";
  if (data !== undefined && Object.keys(data).length) {
    if (
      data &&
      data.payload &&
      data.payload.advancedSearchOptions &&
      data.payload.advancedSearchOptions.length
    ) {
      data.payload.advancedSearchOptions.forEach(res => {
        if (res.name === "date_range" && res.selectedOptions.type) {
          filterByValue = res.selectedOptions.type;
        }
      });
      return filterByValue;
    }
  } else {
    return filterByValue;
  }
}

/**
 * To process records to change it according to chart
 */
function processData(records, selectedValue, key) {
  if (Array.isArray(records.data)) {
    if (selectedValue === constant.last_24_hours || selectedValue === "") {
      let initialValue = 0;
      let calculateCount = records.data.reduce(function(r, o) {
        if (r[o[key]]) {
          ++r[o[key]].count;
          r[o[key]].job_count += Number(o.job_count);
        } else {
          r[o[key]] = { count: 1, job_count: Number(o.job_count) };
        }
        return r;
      }, {});
      let finalRes = [calculateCount].reduce((acc, cv) => {
        let o = Object.keys(cv);
        for (let i = 0; i < o.length; i++) {
          acc.push({ key: o[i], job_count: cv[o[i]].job_count });
        }
        return acc;
      }, []);
      let sum = records.data.reduce(function(accumulator, currentValue) {
        return accumulator + currentValue.job_count;
      }, initialValue);
      // Modified data for donut chart
      finalRes.forEach((ele, i) => {
        if (ele.job_count || ele.job_count === 0) {
          let transformName;
          ele.key.indexOf("_") > 0
            ? (transformName = ele.key.split("_").join(" "))
            : (transformName = ele.key);
          let obj = Object.assign(ele, {
            percentage: ((ele.job_count / sum) * 100).toFixed(2),
            id: i,
            quantity: ele.job_count,
            name:
              constant.ConvertText[transformName] !== undefined
                ? constant.ConvertText[transformName]
                : transformName
          });
          delete obj["job_count"];
          delete obj[key];

          return obj;
        }
      });
      records.data = [];
      records.data = [...finalRes];
      return records || [];
    } else {
      if (records && records.data.length) {
        records.data.forEach(ele => {
          let time = ele.time_instance;
          if (ele.time_instance.indexOf(",")) {
            let date = ele.time_instance.split(",");
            time = new Date(date[0], date[1] - 1, date[2]);
          }

          let obj = Object.assign(ele, {
            date: time,
            value: ele.job_count,
            name: _.upperFirst(ele.job_status)
          });

          delete obj["job_count"];
          delete obj["job_status"];
          delete obj["time_instance"];

          return obj;
        });
        return records || [];
      }
    }
  } else {
    return records;
  }
}

function* getAccountDetailsData(queryString, selectedFilters) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let subOrganizationId = "";
    if (selectedFilters.includes("sub_organization_id=")) {
      subOrganizationId = selectedFilters
        .split("sub_organization_id=")[1]
        .split("&")[0]
        .split("%7C")[0];
    } else {
      subOrganizationId = loginReducer.organization_id;
    }
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["account_details_grid"])
    );

    const gridDataResponse = yield call(
      analyzeApi.fetchAccountDetailsData,
      organizationId,
      queryString,
      loginReducer.token
    );

    const jobStatusResponse = yield call(
      monitorApi.fetchJobStatus,
      `organization_id=${subOrganizationId}`,
      loginReducer.token
    );

    const sourceTypesResponse = yield call(
      analyzeApi.fetchAccountSummaryData,
      organizationId,
      selectedFilters,
      loginReducer.token
    );

    const sourceProtectionResponse = yield call(
      monitorApi.fetchSourceProtectionSummary,
      `organization_id=${subOrganizationId}`,
      loginReducer.token
    );

    const graphDataResponse = {
      data: {
        backup_status: _.isEmpty(_.get(jobStatusResponse, "data", {}))
          ? {}
          : { ...jobStatusResponse.data },
        num_sources: _.isEmpty(
          _.get(sourceTypesResponse, "data.num_sources", {})
        )
          ? {}
          : { ...sourceTypesResponse.data.num_sources },
        source_status: _.isEmpty(_.get(sourceProtectionResponse, "data", {}))
          ? {}
          : { ...sourceProtectionResponse.data }
      }
    };

    if (
      sourceTypesResponse.status === constant.HTTP_STATUS.OK ||
      jobStatusResponse.status === constant.HTTP_STATUS.OK ||
      sourceProtectionResponse.status === constant.HTTP_STATUS.OK
    ) {
      yield put(
        updateAccountDetailsData({
          gridDataResponse,
          graphData: graphDataResponse
        })
      );
    }
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["account_details_grid"])
    );
  } catch (error) {
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["account_details_grid"])
    );
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/**
 * Fetch Analyze > Reports > Source Protection Report Sources Summary widget data
 */
export function* getSourceProtectionSourcesSummary(data, queryString = "") {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    const apiResponse = yield call(
      analyzeApi.fetchSourceProtectionSourcesSummary,
      organizationId,
      queryString,
      loginReducer.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(updateSourceProtectionSourcesSummary(apiResponse));
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/**
 *  Handling async actions dispatched in `analyze` state
 */
export default function* analyzeSaga() {
  yield all([
    takeLatest(actionType.GET_REPORT_COLUMN_DATA, getAllColumnsData),
    takeLatest(actionType.ADVANCED_SEARCH_TRIGGERED, receiveReportList),
    takeLatest(actionType.GET_CURRENT_ACTION_CALLED, receiveReportList),
    takeLatest(actionType.GET_REPORT_LIST, receiveReportList),
    takeLatest(actionType.GET_REPORT_SCHEDULES, receiveReportSchedules),
    takeEvery(
      actionType.DELETE_SCHEDULED_REPORT_FOR_MANAGE_SCHEDULES,
      deleteScheduleReportForManageSchedules
    ),
    takeEvery(actionType.DELETE_ANALYZE_ALERT, deleteAnalyzeAlert),
    takeEvery(actionType.UPDATE_REPORT_SCHEDULE, updateReportSchedule),
    //takeLatest(actionType.GET_REPORT_SCHEDULES, manageSchedulesAdvancedSearch),
    takeLatest(actionType.DELETE_REPORT_BY_ID, deleteReportByID),
    takeLatest(actionType.DELETE_SCHEDULE_REPORT, deleteScheduleReport),
    takeLatest(actionType.RUN_REPORT_NOW, runReportNow),
    takeLatest(actionType.SHOW_REPORT_OVERLAY, renderReportOverlay),
    takeLatest(actionType.CREATE_REPORT, createReport),
    takeLatest(actionType.UPDATE_ANALYZE_ALERT, updateAnalyzeAlert),
    takeLatest(actionType.CREATE_ANALYZE_ALERT, createAnalyzeAlert),
    takeLatest(actionType.FETCH_ANALYZE_ALERTS, handleFetchAnalyzeAlerts),
    takeLatest(actionType.EXPORT_REPORT_AS_CSV, exportReportAsCSV),
    takeLatest(actionType.UPDATE_REPORT_SETTINGS, updateReportSettings),
    takeLatest(actionType.FETCH_BACKUP_JOBS_TOP_SOURCES, getBackupJobsData),
    takeLatest(
      actionType.FILTER_BACKUP_JOBS_TOP_SOURCES,
      filterBackupJobsTopSourcesData
    ),
    // takeLatest(actionType.FETCH_BACKUP_JOBS, getBackupJobsData),
    // takeLatest(
    //   actionType.REFRESH_BACKUP_TOP_SOURCES,
    //   refreshBackupJobsTopSourcesData
    // ),
    takeLatest(
      actionType.JOBS_AND_DESTINATION_ADVANCE_SEARCH,
      jobsAndDestinationAdvancedSearch
    ),
    takeLatest(actionType.FETCH_SINGLE_REPORT_DETAILS, fetchSingleReportData)
  ]);
}
