import * as React from "react";
import Tooltip from "@mui/material/Tooltip";

const TooltipComponent = props => {
  return (
    <Tooltip
      PopperProps={{ className: `${props.popperClassName || ""}` }}
      title={props.content}
      arrow
      className={`${props.className || ""}`}
      placement={props.placement || "right"}
      classes={{
        tooltip: "tooltip-title",
        tooltipArrow: `tooltip-arrow${props.red ? "-red" : ""}`
      }}
      open={props?.open}
    >
      {props.dropdown ? props?.children : <span>{props?.children} </span>}
    </Tooltip>
  );
};

export default TooltipComponent;
