const REPORT_COLUMNS = [
  {
    name: "reports",
    columnData: [
      {
        identifier: "report_name",
        show: true,
        sort: true
      },
      {
        identifier: "range_type",
        show: true,
        sort: true
      },
      {
        identifier: "report_for_type",
        show: true,
        sort: true
      },
      {
        identifier: "create_ts",
        show: true,
        sort: true
      },
      {
        identifier: "schedule_time",
        show: true,
        sort: true
      },
      {
        identifier: "recipient_mail",
        show: true,
        sort: true
      },
      {
        identifier: "report_type",
        show: true,
        sort: true
      },
      {
        identifier: "create_user",
        show: true,
        sort: true
      },
      {
        identifier: "available_actions",
        show: true
      }
    ]
  },
  {
    name: "report_detail",
    columnData: [
      {
        identifier: "source_name",
        show: true,
        sort: true
      },
      {
        identifier: "job_status",
        show: true,
        sort: true
      },
      {
        identifier: "destination_name",
        show: true,
        sort: true
      },
      {
        identifier: "policy",
        show: true,
        sort: true
      },
      {
        identifier: "start_time_ts",
        show: true,
        sort: true
      },
      {
        identifier: "end_time_ts",
        show: true,
        sort: true
      },
      {
        identifier: "duration",
        show: true,
        sort: true
      },
      {
        identifier: "warnings",
        show: true,
        sort: true
      },
      {
        identifier: "errors",
        show: true,
        sort: true
      },
      {
        identifier: "data_transferred",
        show: true,
        sort: true
      }
    ]
  },
  {
    name: "backup_jobs",
    columnData: [
      {
        identifier: "source_name",
        show: true,
        sort: false
      },
      {
        identifier: "job_status",
        show: true,
        sort: true
      },
      {
        identifier: "duration",
        show: true,
        sort: false
      },
      {
        identifier: "source_group",
        show: true,
        sort: false
      },
      {
        identifier: "destination_name",
        show: true,
        sort: false
      },
      {
        identifier: "policy",
        show: true,
        sort: false
      },
      {
        identifier: "start_time_ts",
        show: true,
        sort: true
      },
      {
        identifier: "end_time_ts",
        show: true,
        sort: false
      },
      {
        identifier: "warnings",
        show: true,
        sort: false
      },
      {
        identifier: "errors",
        show: true,
        sort: false
      },
      {
        identifier: "processed_bytes",
        show: false,
        sort: false
      },
      {
        identifier: "written_bytes",
        show: false,
        sort: false
      },
      {
        identifier: "transferred_bytes",
        show: true,
        sort: false
      }
    ]
  },
  {
    name: "policy_tasks",
    columnData: [
      {
        identifier: "source_name",
        show: true,
        sort: false
      },
      {
        identifier: "policy_name",
        show: true,
        sort: false
      },
      {
        identifier: "destination_name",
        show: true,
        sort: false
      },
      {
        identifier: "job_name",
        show: true,
        sort: false
      },
      {
        identifier: "job_type",
        show: true,
        sort: false
      },
      {
        identifier: "task",
        show: true,
        sort: false
      },
      {
        identifier: "severity",
        show: true,
        sort: false
      },
      {
        identifier: "start_time_ts",
        show: true,
        sort: false
      },
      {
        identifier: "end_time_ts",
        show: true,
        sort: false
      }
    ]
  },
  {
    name: "restore_jobs",
    columnData: [
      {
        identifier: "source_name",
        show: true,
        sort: false
      },
      {
        identifier: "job_status",
        show: true,
        sort: true
      },
      {
        identifier: "destination_name",
        show: true,
        sort: false
      },
      {
        identifier: "source_path",
        show: true,
        sort: false
      },
      {
        identifier: "destination_path",
        show: true,
        sort: false
      },
      {
        identifier: "start_time_ts",
        show: true,
        sort: true
      },
      {
        identifier: "end_time_ts",
        show: true,
        sort: false
      },
      {
        identifier: "duration",
        show: true,
        sort: false
      },
      {
        identifier: "warnings",
        show: true,
        sort: false
      },
      {
        identifier: "errors",
        show: false,
        sort: false
      },
      {
        identifier: "policy",
        show: false,
        sort: false
      }
    ]
  },
  {
    name: "capacity_usage",
    columnData: [
      {
        identifier: "day_utc",
        show: true,
        sort: false
      },
      {
        identifier: "organization_name",
        show: true,
        sort: false
      },
      {
        identifier: "cloud_direct_usage",
        show: true,
        sort: false
      },
      {
        identifier: "udp_usage",
        show: true,
        sort: false
      },
      {
        identifier: "total_usage",
        show: true,
        sort: false
      },
      {
        identifier: "destination_name",
        show: true,
        sort: false
      },
      {
        identifier: "destination_type",
        show: true,
        sort: false
      },
      {
        identifier: "usage",
        show: true,
        sort: false
      }
    ]
  }
];

export default REPORT_COLUMNS;
