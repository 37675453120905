import DataGridConstants from "components/common/data-grid/DataGridConstants";
import { sortByOrder } from "utils/SpogDataGridUtil";

const HeaderTypes = DataGridConstants.GridColumnHeaderTypes;
const CellTypes = DataGridConstants.GridColumnCellTypes;

export const getActionId = name => `protect.destination.ds.action.${name}`;

export const Action = {
  REFRESH: getActionId("refresh"),
  MODIFY: getActionId("modify"),
  DELETE: getActionId("detach_from_rps"),
  START: getActionId("start"),
  STOP: getActionId("stop"),
  BROWSE_RECOVERY_POINTS: getActionId("browse_recovery_points"),
  RPS_JUMPSTART: getActionId("rps_jump_start"),
  MODIFY_CLOUD_DATASTORE:
    "protect.destination.ds.action.modify_cloud_datastore",
  MODIFY_LOCAL_DATASTORE:
    "protect.destination.ds.action.modify_local_datastore",
  DISABLE_SHARE: getActionId("disable_share")
};

const COLUMN_DEFS = {
  columns: [
    {
      accessor: "available_actions",
      displayIdentifier: "protect.desitnation.ds.grid.action",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: {
        type: CellTypes.ACTION_DROPDOWN_CELL_MUI,
        actionDropdown: true
      },
      settings: { width: 80 }
    },
    {
      accessor: "ds_icons",
      displayIdentifier: "",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.IMAGE_CELL_MUI },
      settings: { maxWidth: 55, minWidth: 55, width: 55 }
    },
    {
      accessor: "datastore_name",
      displayIdentifier: "protect.desitnation.ds.grid.name",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LINK_CELL_MUI },
      settings: {
        width: 180,
        sortType: sortByOrder
      }
    },
    {
      accessor: "server_name",
      displayIdentifier: "protect.desitnation.ds.grid.rps",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { width: 100 }
    },
    {
      accessor: "status",
      displayIdentifier: "protect.desitnation.ds.grid.status",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.ICON_CELL_MUI },
      settings: { width: 100 }
    },
    {
      accessor: "site_name",
      displayIdentifier: "protect.desitnation.ds.grid.site_name",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { width: 120 }
    },
    {
      accessor: "policies",
      displayIdentifier: "protect.desitnation.ds.grid.policies",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: {
        type: CellTypes.RENDER_POLICY_OVERLAY_CELL_MUI
      },
      settings: { width: 150 }
    },
    {
      accessor: "stored_data",
      displayIdentifier: "protect.desitnation.ds.grid.stored_data",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.BYTE_CELL_MUI },
      settings: { width: 120 }
    },
    {
      accessor: "deduplication",
      displayIdentifier: "protect.desitnation.ds.grid.deduplication",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { width: 120 }
    },
    {
      accessor: "compression",
      displayIdentifier: "protect.desitnation.ds.grid.compression",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { width: 120 }
    },
    {
      accessor: "data_reduction",
      displayIdentifier: "protect.desitnation.ds.grid.data_reduction",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { width: 180 }
    },
    {
      accessor: "space_occupied",
      displayIdentifier: "protect.desitnation.ds.grid.space_occupied",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.BYTE_CELL_MUI },
      settings: { width: 150 }
    }
  ],
  columnData: [
    {
      id: "available_actions",
      accessor: "available_actions",
      identifier: "available_actions",
      Header: "Action",
      show: true,
      sort: false
    },
    {
      id: "ds_icons",
      accessor: "ds_icons",
      identifier: "ds_icons",
      Header: "",
      show: true,
      sort: false
    },
    {
      id: "name",
      accessor: "name",
      identifier: "name",
      Header: "Name",
      show: true,
      sort: true
    },
    {
      id: "rps",
      accessor: "rps",
      identifier: "rps",
      Header: "RPS",
      show: true,
      sort: true
    },
    {
      id: "status",
      accessor: "status",
      identifier: "status",
      Header: "Status",
      show: true,
      sort: true
    },
    {
      id: "site_name",
      accessor: "site_name",
      identifier: "site_name",
      Header: "Site Name",
      show: true,
      sort: true
    },
    {
      id: "policies",
      accessor: "policies",
      identifier: "policies",
      Header: "Policy",
      show: true,
      sort: false
    },
    {
      id: "stored_data",
      accessor: "stored_data",
      identifier: "stored_data",
      Header: "Stored Data",
      show: true,
      sort: false
    },
    {
      id: "deduplication",
      accessor: "deduplication",
      identifier: "deduplication",
      Header: "Deduplication",
      show: true,
      sort: false
    },
    {
      id: "compression",
      accessor: "compression",
      identifier: "compression",
      Header: "Compression",
      show: true,
      sort: false
    },
    {
      id: "data_reduction",
      accessor: "data_reduction",
      identifier: "data_reduction",
      Header: "Overall Data Reduction",
      show: true,
      sort: false
    },
    {
      id: "space_occupied",
      accessor: "space_occupied",
      identifier: "space_occupied",
      Header: "Space Occupied",
      show: true,
      sort: false
    }
  ]
};

export default COLUMN_DEFS;
