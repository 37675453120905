import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { List } from "react-virtualized";
import { ID_PREFIX } from "configs/IdConfig";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;
  let heightValue =
    itemCount <= 0 ? itemSize : itemCount <= 5 ? itemSize * itemCount : 200;
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={heightValue}
          width={250}
          rowHeight={props => {
            let actualValue = children[props.index].key;
            let len = children[props.index].key.length;
            let valueHeight =
              actualValue.includes(" ") && len > itemSize
                ? Math.ceil(len / itemSize) * itemSize
                : itemSize;
            return valueHeight;
          }}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizedSelectDropdownManagePermissions({
  options,
  label,
  onChange,
  customStyle,
  placeholder,
  id,
  testId,
  defaultValue = "",
  disabled = false
}) {
  let virtualizedSelectDropdownDropdownId = testId
    ? `${ID_PREFIX}${testId}`
    : id
    ? id
    : "";
  const textFieldClassname = label
    ? "multiselectDropdown"
    : "multiselectDropdown spog-mui-textfield";

  return (
    <Autocomplete
      sx={{
        "& .MuiInputBase-root": customStyle,
        "& .MuiOutlinedInput-root.Mui-disabled": {
          backgroundColor: "rgba(0,0,0,0.12)",
          color: "rgba(0,0,0,0.26)"
        },
        "& .MuiOutlinedInput-input": {
          marginRight: "50px"
        }
      }}
      id={virtualizedSelectDropdownDropdownId || "virtualize-demo"}
      className="spog-autocomplete-dropdown"
      style={customStyle}
      disableListWrap
      disabled={disabled}
      ListboxComponent={ListboxComponent}
      options={options}
      onChange={onChange}
      openText={null}
      closeText={null}
      defaultValue={defaultValue}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          className={textFieldClassname}
          placeholder={placeholder}
        />
      )}
    />
  );
}
