import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { List } from "react-virtualized";
import { ID_PREFIX } from "configs/IdConfig";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import FormattedMessage from "../formatted-message";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, width, ...other } = props;
  const height =
    Array.isArray(children) && children.length < 6 ? children.length * 40 : 250;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={height}
          width={width ? width : 250}
          rowHeight={itemSize}
          style={{ padding: "8px 0px" }}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizedSelectDropdown({
  options,
  label,
  onChange,
  customStyle,
  placeholder,
  id,
  testId,
  defaultValue,
  disabled,
  loading,
  key,
  meta: { error }
}) {
  let virtualizedSelectDropdownDropdownId = testId
    ? `${ID_PREFIX}${testId}`
    : id
    ? id
    : "";
  const textFieldClassname = label
    ? "multiselectDropdown"
    : "multiselectDropdown spog-mui-textfield";

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      overflow: "hidden",
      padding: "0px"
    }
  });

  return (
    <Autocomplete
      key={key}
      sx={{
        "& .MuiInputBase-root": customStyle,
        "& .MuiOutlinedInput-root.Mui-disabled": {
          backgroundColor: "rgba(0,0,0,0.12)",
          color: "rgba(0,0,0,0.26)"
        },
        "& .MuiInputBase-input": {
          fontSize: "13px",
          height: "auto"
        },
        "& .MuiOutlinedInput-input": {
          marginRight: "50px"
        }
      }}
      noOptionsText={
        loading ? (
          <span>
            <FormattedMessage id="loading" defaultMessage="Loading" />
            ...
          </span>
        ) : (
          <FormattedMessage id="no_options" defaultMessage="No options" />
        )
      }
      PopperComponent={StyledPopper}
      id={virtualizedSelectDropdownDropdownId || "virtualize-demo"}
      className="spog-autocomplete-dropdown virtualize-select-dropdown"
      style={customStyle}
      disableListWrap
      ListboxComponent={props => (
        <ListboxComponent {...props} width={customStyle?.width} />
      )}
      options={options}
      onChange={onChange}
      disabled={disabled}
      openText={null}
      closeText={null}
      defaultValue={defaultValue}
      isOptionEqualToValue={(option, value) =>
        option.label === (value.label ? value.label : value)
      }
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          label={label}
          className={`${textFieldClassname}`}
          placeholder={placeholder}
        />
      )}
    />
  );
}

VirtualizedSelectDropdown.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string
};

VirtualizedSelectDropdown.defaultProps = {
  label: null,
  placeholder: undefined,
  testId: "",
  disabled: false,
  defaultValue: "",
  key: ""
};
