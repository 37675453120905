import { createSelector } from "reselect";

const getCsr = state => state.csr;
const gridPageSize = state => state.reducer.gridPageSize;
const gridCurrentPage = state => state.reducer.gridCurrentPage;

const getEntities = state => getCsr(state).entities;
const getUi = state => getCsr(state).ui;

export const getConfirmations = state => getUi(state).confirmations;
export const getNotifications = state => getUi(state).notifications;

export const getDisplayLoginModal = state =>
  getUi(state).misc.displayLoginModal;

export const getCsrRedirect = state => getUi(state).misc.redirect;
export const getCsrSelectedDataCenter = state =>
  getUi(state).misc.selectedDataCenter;
export const getCsrSelectedOrgId = state => getUi(state).misc.selectedOrgId;

// org-search
const getOrganizationSearchUi = state => getUi(state).organizationSearch;
const getOrganizationSearchEntities = state =>
  getEntities(state).organizationSearch;

// this is for organizations search
export const getOrganizations = state =>
  getOrganizationSearchEntities(state).organizations.data;

export const getOrganizationsPagination = state =>
  getOrganizationSearchEntities(state).organizations.pagination;

export const makeGridPageSizeState = () =>
  createSelector([gridPageSize], gridPageSize => gridPageSize);

export const makeGridCurrentPageState = () =>
  createSelector([gridCurrentPage], gridCurrentPage => gridCurrentPage);

export const isOrganizationsLoading = state =>
  getOrganizationSearchEntities(state).isLoading;

export const isOrganizationsSearchInitialForm = state =>
  getOrganizationSearchUi(state).isInitialForm;

export const getOrganizationsSearchString = state =>
  getOrganizationSearchUi(state).searchString;

export const getOrganizationsSortString = state =>
  getOrganizationSearchUi(state).sortString;

// this is for a single org
export const getOrganization = state =>
  getEntities(state).organizations.organization;
export const isOrganizationLoading = state =>
  getEntities(state).organizations.isLoading;

export const getOrganizationSupport = state =>
  getEntities(state).organizations.support;
export const isOrganizationSupportLoading = state =>
  getEntities(state).organizations.isLoadingSupport;

export const getOrganizationFreetrialCloudDirect = state =>
  getEntities(state).organizations.freetrialCloudDirect;

export const getOrganizationProperties = state =>
  getEntities(state).organizations.properties || {};
export const isOrganizationFreetrialCloudDirectLoading = state =>
  getEntities(state).organizations.isLoadingFreetrialCloudDirect;

export const getOrganizationFreetrialCloudHybrid = state =>
  getEntities(state).organizations.freetrialCloudHybrid;
export const isOrganizationFreetrialCloudHybridLoading = state =>
  getEntities(state).organizations.isLoadingFreetrialCloudHybrid;

export const getOrganizationEntitlements = state =>
  getEntities(state).organizations.entitlements || {};
export const isOrganizationEntitlementsLoading = state =>
  getEntities(state).organizations.isLoadingEntitlements;

// user-search
const getUsersSearchUi = state => getUi(state).usersSearch;

export const getUsersSearchString = state =>
  getUsersSearchUi(state).searchString;
export const getUsersSortString = state => getUsersSearchUi(state).sortString;

const getUsersSearchEntities = state => getEntities(state).usersSearch;

export const getUsers = state => getUsersSearchEntities(state).users.data;
export const getUsersPagination = state =>
  getUsersSearchEntities(state).users.pagination;

export const isUsersLoading = state => getUsersSearchEntities(state).isLoading;

export const isUserLoading = state => getEntities(state).users.isLoading;
export const getUser = state => getEntities(state).users.user;

export const getCurrentRecoveryPointServer = state =>
  getEntities(state).recoveryPointServers.current.recoveryPointServer;

export const getCurrentLinuxBackupServer = state =>
  getEntities(state).linuxBackupServers.current.linuxBackupServer;

// audit-trail
const getAuditTrailSearchUi = state => getUi(state).auditTrail;
const getAuditTrailEntities = state => getEntities(state).auditTrail;
export const getAuditCodes = state => {
  const codes = getAuditTrailEntities(state).auditTrailCodes.auditCodes.data;
  return [
    {
      code_id: -1,
      code_name: "-- ALL CODES --",
      description: "ALL CODES"
    },
    ...codes
  ];
};

const getAuditTrailSearchEntities = state =>
  getAuditTrailEntities(state).auditTrailSearch;

export const getAuditTrailSearchValues = state =>
  getAuditTrailSearchUi(state).searchValues;

export const isAuditTrailSearchLoading = state =>
  getAuditTrailSearchEntities(state).isLoading;

export const makeGetAuditTrailSearch = () =>
  createSelector(
    [getAuditCodes, getAuditTrailSearchEntities],
    (codes, auditTrialSearchEntities) => {
      const data = auditTrialSearchEntities.auditTrail.data || [];
      return data.map(d => ({
        ...d,
        code_name: codes.find(code => code.code_id === d.code_id).code_name
      }));
    }
  );

export const getAuditTrailSearchPagination = state =>
  getAuditTrailSearchEntities(state).auditTrail.pagination;
export const isRecoveryPointServersLoading = state =>
  getEntities(state).recoveryPointServers.isLoading;
export const getRecoveryPointServers = state =>
  getEntities(state).recoveryPointServers.recoveryPointServers.data;
export const getRecoveryPointServersPagination = state =>
  getEntities(state).recoveryPointServers.recoveryPointServers.pagination;

export const isCloudHybridStoresLoading = state =>
  getEntities(state).cloudHybridStores.isLoadingCloudHybridStores;
export const isCloudHybridStoreLoading = (state, rpsServerId) =>
  getEntities(state).cloudHybridStores.isLoadingCloudHybridStores[rpsServerId];
export const isCloudHybridStoreError = state =>
  getEntities(state).cloudHybridStores.addDataStore;
export const getCloudHybridStores = state =>
  getEntities(state).cloudHybridStores.cloudHybridStores;

export const isLinuxBackupServersLoading = state =>
  getEntities(state).linuxBackupServers.isLoading;
export const getLinuxBackupServers = state =>
  getEntities(state).linuxBackupServers.linuxBackupServers.data;
export const getLinuxBackupServersPagination = state =>
  getEntities(state).linuxBackupServers.linuxBackupServers.pagination;

export const isDataStoresLoading = state =>
  getEntities(state).dataStores.isLoading;
export const getDataStores = state =>
  getEntities(state).dataStores.dataStores.data;
export const getDataStoresPagination = state =>
  getEntities(state).dataStores.dataStores.pagination;
export const getDataStoresSortString = state =>
  getUi(state).cloudHybrid.dataStoresSortString;

export const getCHDataStoresSortString = state =>
  getUi(state).cloudHybrid.chDataStoresSortString;

export const isDataStoreLoading = state =>
  getEntities(state).dataStores.isLoadingDataStore;
export const getDataStore = state => getEntities(state).dataStores.dataStore;

export const getDataCenters = state =>
  getEntities(state).dataCenters.dataCenters;

export const getSites = state => getEntities(state).sites.sites;

export const isDestinationsLoading = state =>
  getEntities(state).destinations.isLoading;

export const getDestinations = state =>
  getEntities(state).destinations.destinations.data;
export const getDestinationsPagination = state =>
  getEntities(state).destinations.destinations.pagination;

export const isBIReportTypesLoading = state =>
  getEntities(state).biReporting.isTypesLoading;
export const getBIReportTypes = state => getEntities(state).biReporting.types;

export const isBIReportLoading = state =>
  getEntities(state).biReporting.isReportLoading;
export const getBIReport = state => getEntities(state).biReporting.report;

export const makeGetBIReportColumns = () =>
  createSelector([getBIReport], reportData => {
    const [first] = reportData;
    return first ? Object.keys(first) : [];
  });

export const isBIReportRecipientsLoading = state =>
  getEntities(state).biReporting.isRecipientsLoading;
export const getBIReportRecipients = state =>
  getEntities(state).biReporting.recipients;
