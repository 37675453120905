import { ENCRYPT, VALIDATED } from "utils/appConstants";
import { getLocaleDate } from "utils/SpogDataGridUtil";
import {
  RPS_DATA_STORE_LIST,
  RPS_DATA_STORE_LIST_SUCCESS,
  RPS_DATA_STORE_SOURCE_LIST,
  RPS_DATA_STORE_SOURCE_LIST_SUCCESS,
  RPS_DATA_STORE_SOURCE_LIST_FAILURE,
  CLEAR_POLICY_LIST,
  GET_POLICY_LIST_SUCCESS,
  ON_SEARCH_FILTER,
  ON_POLICY_CHANFE_JUMPSTART,
  ON_CLEAR_SOURCE_LIST,
  ON_CLEAR_FORM,
  TARGETED_DATA_STORE_LIST,
  SET_DATA_STORE_LIST,
  TARGETED_RPS_LIST,
  CONNECT_SHARED_FOLDER,
  CONNECT_SHARED_FOLDER_SUCCESS,
  CONNECT_SHARED_FOLDER_FAILURE,
  SAVE_SHARED_FOLDER_DETAILS,
  ON_SESSION_PASSWORD_APPLY_SUCCESS,
  SAVE_JUMP_START,
  SAVE_JUMP_START_FAILURE
} from "../../actions/actionTypes";

const initialState = {
  dataStoreList: [],
  dataStoreSourceList: [],
  policiesList: [],
  dataStoreSourceListOrginal: [],
  targetRpsList: [],
  targetDataStoreList: [],
  shareFolderDetails: {},
  isShareFolder: false,
  isConnected: false,
  savejumpstartError: "",
  sourceListError: ""
};

const JumpStart = (state = initialState, action) => {
  switch (action.type) {
    case RPS_DATA_STORE_LIST:
      return {
        ...state,
        dataStoreList: [],
        targetDataStoreList: []
      };
    case RPS_DATA_STORE_LIST_SUCCESS:
      return {
        ...state,
        dataStoreList: action.data,
        targetDataStoreList: action.data
      };
    case RPS_DATA_STORE_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        dataStoreSourceList: processData(action.data, true),
        dataStoreSourceListOrginal: processData(action.data, true),
        isShareFolder: false
      };
    case RPS_DATA_STORE_SOURCE_LIST_FAILURE:
      return {
        ...state,
        sourceListError: action.data[0].message
      };
    case RPS_DATA_STORE_SOURCE_LIST:
      return {
        ...state,
        sourceListError: ""
      };
    case GET_POLICY_LIST_SUCCESS:
      return {
        ...state,
        policiesList: action.data.policies
      };
    case CLEAR_POLICY_LIST:
      return {
        ...state,
        policiesList: []
      };
    case ON_SEARCH_FILTER:
      return {
        ...state,
        dataStoreSourceList: onSearch(
          action.data,
          state.dataStoreSourceListOrginal
        )
      };
    case ON_POLICY_CHANFE_JUMPSTART:
      return {
        ...state,
        dataStoreSourceList: onFilter(
          action.data,
          state.dataStoreSourceListOrginal
        )
      };
    case ON_CLEAR_SOURCE_LIST:
      return {
        ...state,
        dataStoreSourceList: [],
        dataStoreSourceListOrginal: []
      };
    case ON_CLEAR_FORM:
      return {
        ...state,
        dataStoreList: [],
        dataStoreSourceList: [],
        dataStoreSourceListOrginal: [],
        isShareFolder: false,
        savejumpstartError: "",
        isConnected: false,
        sourceListError: ""
      };
    case SET_DATA_STORE_LIST:
      return {
        ...state,
        dataStoreList: action.data || []
      };
    case TARGETED_DATA_STORE_LIST:
      return {
        ...state,
        targetDataStoreList: filterDataStore(
          state.dataStoreList,
          action.selectedDataStore
        )
      };
    case TARGETED_RPS_LIST:
      return {
        ...state,
        targetRpsList: action.rspList
      };
    case CONNECT_SHARED_FOLDER_SUCCESS:
      return {
        ...state,
        dataStoreSourceList: processData(action.data, false),
        dataStoreSourceListOrginal: processData(action.data, false),
        isConnected: true
      };
    case ON_SESSION_PASSWORD_APPLY_SUCCESS:
      return {
        ...state,
        dataStoreSourceList: processSessionData(
          action.data,
          action.resData,
          state.dataStoreSourceList
        ),
        dataStoreSourceListOrginal: processSessionData(
          action.data,
          action.resData,
          state.dataStoreSourceList
        )
      };
    case CONNECT_SHARED_FOLDER_FAILURE:
      return {
        ...state,
        errorConnectSharedFolder:
          action.data[0].message || action.data[0].detailMessage,
        isConnected: false
      };
    case CONNECT_SHARED_FOLDER:
      return {
        ...state,
        errorConnectSharedFolder: ""
      };
    case SAVE_SHARED_FOLDER_DETAILS:
      return {
        ...state,
        shareFolderDetails: action.data,
        isShareFolder: true
      };
    case SAVE_JUMP_START_FAILURE:
      return {
        ...state,
        savejumpstartError: action.data[0].message
      };
    case SAVE_JUMP_START:
      return {
        ...state,
        savejumpstartError: ""
      };
    default:
      return state;
  }
};

const onSearch = (key, dataStoreSourceList) =>
  dataStoreSourceList.filter(list =>
    list.source_name.toLowerCase().includes(key.toLowerCase())
  );

const onFilter = (key, dataStoreSourceList) => {
  if (key.includes("All Policies")) {
    return dataStoreSourceList;
  }
  //eslint-disable-next-line array-callback-return
  return dataStoreSourceList.filter(list => {
    if (list.policy && list.policy.policy_name) {
      return list.policy.policy_name.toLowerCase() === key.toLowerCase();
    }
  });
};

const filterDataStore = (dataStoreList, selectedDataStore) => {
  let filteredList = dataStoreList.filter(
    list =>
      list.datastore_name !== selectedDataStore &&
      !list.datastore_properties.read_only
  );
  const selectedDS = dataStoreList.filter(
    list => list.datastore_name === selectedDataStore
  );

  if (selectedDS.length && selectedDS[0].dedupe_enabled) {
    filteredList = filteredList.filter(list => list.dedupe_enabled === true);
  }
  if (selectedDS.length && selectedDS[0].encryption_enabled) {
    return filteredList.filter(list => list.encryption_enabled === true);
  } else {
    return filteredList;
  }
};

const processData = (dataStoreSourceList, isDS) => {
  let sources = [];
  if (isDS) {
    //eslint-disable-next-line array-callback-return
    dataStoreSourceList.map(list => {
      //eslint-disable-next-line array-callback-return
      list.sources.map(source => {
        sources.push(source);
      });
    });
  } else {
    sources = dataStoreSourceList;
  }
  return sources.map(list => {
    return {
      ...list,
      id: list.source_id,
      encrypt: list.encrypt_password_hash ? ENCRYPT.YES : ENCRYPT.NO,
      validated: VALIDATED.NO,
      most_recent_recovery_point_formated: list.most_recent_recovery_point
        ? getLocaleDate(list.most_recent_recovery_point, "lll")
        : "",
      first_recovery_point_formated: list.first_recovery_point
        ? getLocaleDate(list.first_recovery_point, "lll")
        : ""
    };
  });
};
const processSessionData = (sessionData, result, sources) => {
  // return list;
  let appliedSources = sessionData.session_passwords.map((list, index) => {
    let initial = sources.find(x => x.source_id === list.source_uuid);
    return {
      ...initial,
      validated: result[index].validate_result ? VALIDATED.YES : VALIDATED.NO
    };
  });
  let validatedSource = sources.map(list => {
    let initial = appliedSources.find(x => x.source_id === list.source_id);
    if (initial) {
      return {
        ...initial
      };
    }
    return {
      ...list
    };
  });
  return validatedSource;
};

export default JumpStart;
