import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clearNotification, clearNotifications } from "state/actions/ccToast";
import { getNotifications } from "state/selectors/ccToast";

import CCToast from "./CCToast";

export default withRouter(
  connect(
    state => ({
      notifications: getNotifications(state)
    }),
    dispatch =>
      bindActionCreators({ clearNotification, clearNotifications }, dispatch)
  )(CCToast)
);
