import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { Sector } from "recharts";

const RenderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    intl,
    config
  } = props;
  const [customOuterRadius, setCustomOuterRadius] = useState(outerRadius);

  useEffect(() => {
    let timer = null;
    timer = setInterval(() => {
      setCustomOuterRadius(prev => {
        if (prev < outerRadius + 15) {
          return prev + 1;
        } else {
          clearInterval(timer);
          return prev;
        }
      });
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        dy={8}
        textAnchor="middle"
        fontSize={28}
        className="pie-chart-label"
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
      <text
        x={cx}
        y={cy + 20}
        dy={8}
        textAnchor="middle"
        fontSize={16}
        className="pie-chart-label"
      >
        {intl.formatMessage({
          id: get(config, `${payload.name}.labelId`, null),
          defaultMessage: payload.name
        })}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={customOuterRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default RenderActiveShape;
