import { call } from "redux-saga/effects";
import * as API_URL from "./apiUrlConstants";
import { getData, postData, putData, deleteData } from "./apiMethods";
import {
  SEARCH_FILTERS,
  SAVED_SEARCH_FILTERS_KEYS_MAPPING,
  EXCLUDE_USER_DEFINED_SEARCH,
  SYSTEM_SEARCH_FILTERS_KEYS_MAPPING,
  EXCLUDE_SYSTEM_DEFINED_SEARCH
} from "../../utils/appConstants";

export function* fetchColumnsById(queryString, token) {
  try {
    return yield call(getData, API_URL.FETCH_COLUMNS, queryString, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* putColumnsById(id, data, token) {
  try {
    const url = `${API_URL.FETCH_COLUMNS}/${id}`;
    return yield call(putData, url, data, token);
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to update widgets location
 */
export function* getOrganization(token) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = `${url}/organization`;
    return yield call(getData, url, "", token);
  } catch (error) {
    window.console.log(error);
  }
}

export function* cleanStaleJobs(id, token) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.CLEAN_STALE_JOBS.replace("{id}", id)}`,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateOrganizationType(data, token) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = `${url}/organization/${data}`;
    return yield call(putData, url, null, token);
  } catch (error) {
    window.console.log(error);
  }
}
/**
 * To fetch list of Jobs
 * @param {string} queryString
 * @param {string} token
 */
export function* fetchJobsList(queryString, token) {
  try {
    // return yield call(getData, API_URL.FETCH_JOBS, queryString, token);
    return yield call(
      getData,
      queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
        ? API_URL.FETCH_STORAGE_JOBS
        : API_URL.FETCH_JOBS,
      queryString,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchJobsForSourceList(
  id,
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_V2_SOURCES}/${id}/jobs`,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchLogList(queryString, token, pollingAction = false) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_LOG,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchLogForSourceList(
  id,
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_V2_SOURCES}/${id}/logs`,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchCSVLogList(queryString, token) {
  try {
    const data = yield call(getData, API_URL.FETCH_LOG, queryString, token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchCSVLogForSourceList(id, queryString, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_V2_SOURCES}/${id}/logs`,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchCSVLogForSelectedJob(id, queryString, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_JOBS}/${id}/logs`,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseRemoteConsole(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_DESTINATIONS}/sharefolders/browse`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* setLastViewedApp(data, token, showCommonError = true) {
  try {
    // user/${userName}/lastviewapp
    return yield call(
      postData,
      `${API_URL.SET_LAST_VIEWED_APP}user/${data.data}/lastviewapp`,
      { app_name: "Cloud Console" },
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseRemoteConsoleLinux(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_DESTINATIONS}/sharefolders/linux/browse`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseResourcePool(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.BROWSE_VMWARE_RESOURCE_POOL}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseResourcePoolV2(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.BROWSE_VMWARE_RESOURCE_POOL_V2}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshHypervisor(
  data,
  queryString,
  token,
  showCommonError = false
) {
  try {
    let url = `${API_URL.REFRESH_HYPERVISOR.replace(
      "{hypervisorId}",
      data.hypervisor_id
    )}`;
    url = url.replace("{syncType}", data.syncType) + queryString;
    return yield call(postData, url, {}, token, showCommonError);
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshCDHypervisor(
  data,
  queryString,
  token,
  showCommonError = false
) {
  try {
    let url = `${API_URL.REFRESH_CD_HYPERVISOR.replace(
      "{hypervisorId}",
      data.hypervisor_id
    )}`;
    url = `${url}?${queryString}`;
    return yield call(postData, url, {}, token, showCommonError);
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshCloudAccount(
  data,
  queryString,
  token,
  showCommonError = false
) {
  try {
    let url = `${API_URL.REFRESH_CLOUD_ACCOUNT.replace(
      "{cloudaccounts}",
      data.cloud_account_id
    )}`;
    url = url.replace("{syncType}", data.syncType) + queryString;
    return yield call(postData, url, {}, token, showCommonError);
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseHyperV(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.BROWSE_HYPERV}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseHyperVV2(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.BROWSE_HYPERV_V2}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get advanced search options for the Protect tab
 * @param type
 * @returns {*}
 */
export function* getAdvancedSearch() {
  try {
    const data = yield call(getData, API_URL.FETCH_ADVANCE_SEARCH);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * edit post saved search data to API
 *
 * @returns {Promise<any>}
 */
export function* updateSavedSearchData(
  isAdd,
  token,
  data,
  filter_id,
  showCommonError = true
) {
  try {
    let url = `${API_URL.FETCH_FILTERS}`;

    if (!isAdd) {
      url = `${url}/${filter_id}`;
      return yield call(putData, url, data, token, showCommonError);
    } else {
      return yield call(postData, url, data, token, showCommonError);
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get default search data list from the API
 *
 * @returns {Promise<any>}
 */

export function* getSystemSearchData(
  user_id,
  token,
  navigation,
  organizationID
) {
  try {
    if (!SEARCH_FILTERS[navigation]) {
      return { data: [] };
    }
    if (EXCLUDE_SYSTEM_DEFINED_SEARCH.indexOf(navigation) >= 0)
      return { data: [] };
    let url = `${API_URL.FETCH_FILTERS}`;
    let queryString = `is_default=true&filter_type=${SYSTEM_SEARCH_FILTERS_KEYS_MAPPING[navigation]}&organization_id=${organizationID}&user_id=${user_id}`;
    return yield call(getData, url, queryString, token);
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get default search data list from the API
 *
 * @returns {Promise<any>}
 */

export function* getDefaultSearchData(
  user_id,
  token,
  navigation,
  organizationID
) {
  try {
    if (!SEARCH_FILTERS[navigation]) return { data: [] };

    if (EXCLUDE_USER_DEFINED_SEARCH.indexOf(navigation) >= 0)
      return { data: [] };
    let url = `${API_URL.FETCH_FILTERS}`;
    let queryString = `filter_type=${SAVED_SEARCH_FILTERS_KEYS_MAPPING[navigation]}&user_id=${user_id}&organization_id=${organizationID}&pagination=false`;
    return yield call(getData, url, queryString, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSystemAndDefaultSearchData(
  user_id,
  token,
  navigation,
  organizationID
) {
  try {
    if (!SEARCH_FILTERS[navigation]) return { data: [] };

    if (EXCLUDE_USER_DEFINED_SEARCH.indexOf(navigation) >= 0)
      return { data: [] };
    let url = `${API_URL.FETCH_FILTERS_V2}`;
    let queryString = `filter_type=${SAVED_SEARCH_FILTERS_KEYS_MAPPING[navigation]}%7C${SYSTEM_SEARCH_FILTERS_KEYS_MAPPING[navigation]}&user_id=${user_id}&organization_id=${organizationID}&pagination=false`;
    return yield call(getData, url, queryString, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* addSavedSearchData(postDataBody) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SAVEDSEARCHES}`,
      postDataBody
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSavedSearchData(
  token,
  filter_id,
  showCommonError = false
) {
  try {
    let url = `${API_URL.FETCH_FILTERS}/${filter_id}`;
    const data = yield call(deleteData, url, token, {}, showCommonError);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch selected saved search data
 */
export function* fetchSelectedSaved(token, filter_id, queryString) {
  let url = `${API_URL.FETCH_FILTERS}/${filter_id}`;
  const data = yield call(getData, url, queryString, token);
  return data;
}

export function* fetchLogForSelectedJob(
  id,
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_JOBS}/${id}/logs`,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* cancelJobById(id, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_v1_JOBS}/${id}/canceljob`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteJobById(id, token, showCommonError = true) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.DELETE_v1_JOBS}/${id}`,
      token,
      {},
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* cancelBackupJobById(
  jobID,
  sourceID,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${sourceID}/jobs/${jobID}/cancelbackup`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchJobDataById(id, token) {
  try {
    const data = yield call(getData, `${API_URL.FETCH_JOBS}/${id}`, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchJobDetailsById(id, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_v1_JOBS}/${id}/data`,
      "",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchMonitorJobDetailsById(id, token, showCommonError = true) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_v1_JOBS}/${id}/monitor`,
      "",
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To fetch destination details (to be removed once BE provides destination_type in response)
 * @param {*} id
 * @param {*} token
 */
export function* fetchDestinationDetailsById(id, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_DESTINATIONS}/${id}`,
      "",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

// For User Support Data
export function* fetchUserSupportDetailsById(token, orgId) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATION_ID}/${orgId}/support`,
      "",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchOrganizationById(token, id) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_ORGANIZATIONS_DETAILS}/${id}`,
      "",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action start backup
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionStartBackup(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/startbackup?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionStartBackupV2(data, token, showCommonError = false) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_V2_SOURCES}/startbackup`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action cancel backup
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionCancelBackup(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/cancelbackup?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action cancel recovery
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionCancelRecovery(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/cancelrecovery?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionStartReplication(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/replicate`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action cancel replication
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionCancelReplicationin(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/cancelreplicationin?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionStartReplicationOut(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/replicate?${query}`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action to remove policies
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionRemovePolicies(
  data,
  token,
  query,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/removepolicies?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionEnablePolicies(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/enable`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionDisablePolicies(
  data,
  token,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/disable`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionBackupPolicies(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/backup`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}
export function* bulkActionReplicatePolicies(
  data,
  token,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/replicate`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * To handle bulk action to assign policy to sources
 * @param policy
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionAssignPolicy(
  policy,
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.UPDATE_ASSINGN_POLICY}/${policy}/sources?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action to remove
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionRemove(data, token, query, showCommonError = false) {
  try {
    return yield call(
      deleteData,
      `${API_URL.FETCH_SOURCES}/remove?${query}`,
      token,
      data,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action to delete
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionDelete(data, token, query) {
  try {
    return yield call(
      deleteData,
      `${API_URL.FETCH_SOURCES}?${query}`,
      token,
      data
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action to provision
 * @param resourceType
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionProvision(
  resourceType,
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.HOST_BE_URL}${resourceType}/provision?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* bulkActionDeploy(data, token, query) {
  try {
    return yield call(
      postData,
      `${API_URL.HOST_BE_URL}deploy?${query}`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action for recovered resources by post method
 * @param resourceType
 * @param data
 * @param token
 * @param query
 * @param action
 */
export function* bulkActionForResourcePostData(
  resourceType,
  data,
  token,
  query,
  action,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.HOST_BE_URL}${resourceType}/${action}?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action for recovered resources by delete method
 * @param resourceType
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionForResourceDeleteData(
  resourceType,
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      deleteData,
      `${API_URL.HOST_BE_URL}${resourceType}?${query}`,
      token,
      data,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle bulk action for refresh
 * @param resourceType
 * @param data
 * @param token
 * @param query
 */
export function* bulkActionForRefreshData(
  resourceType,
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.HOST_BE_URL}${resourceType}/refresh?${query}`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

//" /organizations/{organizationId}/freetrial/cloudhybrid/request"
export function* requestTrial(
  trialType,
  token,
  organizationId,
  trialApi,
  data
) {
  try {
    return yield call(
      postData,
      `${API_URL.HOST_BE_URL}organizations/${organizationId}/freetrial/${trialApi}`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* requestUDPTrial(token, organizationId) {
  return yield call(
    postData,
    `${API_URL.HOST_BE_URL}organizations/${organizationId}/freetrial/udp`,
    undefined,
    token
  );
}

/**
 * To handle export customer account by get method
 * @param organizationId
 * @param token
 */
export function* exportCustomAccount(organizationId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.HOST_BE_URL}organizations/${organizationId}/accounts?page=1&page_size=25&sort=organization_name&content=dynamic&export=csv`,
      "",
      token,
      false,
      true
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* exportAuditLogs(token, queryString = "") {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_AUDIT_LOGS,
      queryString,
      token,
      false,
      true
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* connectAddSourceHypervisor(
  data,
  queryString,
  token,
  showCommonError = true
) {
  try {
    let url = API_URL.CONNECT_HYPERVISOR_VMWARE;
    if (data.hypervType === "hyperv") {
      url = API_URL.CONNECT_HYPERVISOR_HYPERV;
    } else if (data.hypervType === "nutanix") {
      url = API_URL.CONNECT_HYPERVISOR_NUTANIX;
    }
    return yield call(postData, url, data, token, showCommonError);
  } catch (e) {
    window.console.log(e);
  }
}
