import CloudRWEncrypted from "assets/images/DSIcons/Cloud_RW_Encrypted.svg";
import CloudRWNotEncrypted from "assets/images/DSIcons/Cloud_RW_NotEncrypted.svg";
import CloudReadOnlyEncrypted from "assets/images/DSIcons/Cloud_ReadOnly_Encrypted.svg";
import CloudReadOnlyNotEncrypted from "assets/images/DSIcons/Cloud_ReadOnly_NotEncrypted.svg";
import LocalRWEncrypted from "assets/images/DSIcons/Local_RW_Encrypted.svg";
import LocalRWNotEncrypted from "assets/images/DSIcons/Local_RW_NotEncrypted.svg";
import LocalReadOnlyEncrypted from "assets/images/DSIcons/Local_ReadOnly_Encrypted.svg";
import LocalReadOnlyNotEncrypted from "assets/images/DSIcons/Local_ReadOnly_NotEncrypted.svg";
import RemoteShareRWEncrypted from "assets/images/DSIcons/RemoteShare_RW_Encrypted.svg";
import RemoteShareRWNotEncrypted from "assets/images/DSIcons/RemoteShare_RW_NotEncrypted.svg";
import RemoteShareReadOnlyEncrypted from "assets/images/DSIcons/RemoteShare_ReadOnly_Encrypted.svg";
import RemoteShareReadOnlyNotEncrypted from "assets/images/DSIcons/RemoteShare_ReadOnly_NotEncrypted.svg";

const DataStoresIcons = dataStoreInfo => {
  if (dataStoreInfo.datastoreProperties.deduplication?.cloud_info) {
    if (dataStoreInfo.read_only) {
      if (dataStoreInfo.encryption_enabled) {
        return {
          icon: CloudReadOnlyEncrypted,
          tooltipId: "protect.destination.datastore.cloud_readonly_encrypted",
          tooltipDefaultMsg: "Encrypted, read-only, cloud data store"
        };
      }
      return {
        icon: CloudReadOnlyNotEncrypted,
        tooltipId: "protect.destination.datastore.cloud_readonly_nonencrypted",
        tooltipDefaultMsg: "Non-encrypted, read-only, cloud data store"
      };
    }
    if (dataStoreInfo.encryption_enabled) {
      return {
        icon: CloudRWEncrypted,
        tooltipId: "protect.destination.datastore.cloud_rw_encrypted",
        tooltipDefaultMsg: "Encrypted, cloud data store"
      };
    }
    return {
      icon: CloudRWNotEncrypted,
      tooltipId: "protect.destination.datastore.cloud_rw_nonencrypted",
      tooltipDefaultMsg: "Non-encrypted, cloud data store"
    };
  }
  if (dataStoreInfo.is_remote) {
    if (dataStoreInfo.read_only) {
      if (dataStoreInfo.encryption_enabled) {
        return {
          icon: RemoteShareReadOnlyEncrypted,
          tooltipId: "protect.destination.datastore.remote_readonly_encrypted",
          tooltipDefaultMsg: "Encrypted, read-only, remote data store"
        };
      }
      return {
        icon: RemoteShareReadOnlyNotEncrypted,
        tooltipId: "protect.destination.datastore.remote_readonly_nonencrypted",
        tooltipDefaultMsg: "Non-encrypted, read-only, remote data store"
      };
    }
    if (dataStoreInfo.encryption_enabled) {
      return {
        icon: RemoteShareRWEncrypted,
        tooltipId: "protect.destination.datastore.remote_rw_encrypted",
        tooltipDefaultMsg: "Encrypted, remote data store"
      };
    }
    return {
      icon: RemoteShareRWNotEncrypted,
      tooltipId: "protect.destination.datastore.remote_rw_nonencrypted",
      tooltipDefaultMsg: "Non-encrypted, remote data store"
    };
  }
  if (dataStoreInfo.read_only) {
    if (dataStoreInfo.encryption_enabled) {
      return {
        icon: LocalReadOnlyEncrypted,
        tooltipId: "protect.destination.datastore.local_readonly_encrypted",
        tooltipDefaultMsg: "Encrypted, read-only, local data store"
      };
    }
    return {
      icon: LocalReadOnlyNotEncrypted,
      tooltipId: "protect.destination.datastore.local_readonly_nonencrypted",
      tooltipDefaultMsg: "Non-encrypted, read-only, local datastore"
    };
  }
  if (dataStoreInfo.encryption_enabled) {
    return {
      icon: LocalRWEncrypted,
      tooltipId: "protect.destination.datastore.local_rw_encrypted",
      tooltipDefaultMsg: "Encrypted, local data store"
    };
  }
  return {
    icon: LocalRWNotEncrypted,
    tooltipId: "protect.destination.datastore.local_rw_nonencrypted",
    tooltipDefaultMsg: "Non-encrypted, local data store"
  };
};

export default DataStoresIcons;
