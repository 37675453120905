const URLS = {
  downloadVpnWindows:
    "https://openvpn.net/index.php/open-source/downloads.html",
  downloadTunnelblick: "https://tunnelblick.net/downloads.html",
  support: "/user_support",
  ubuntuDocumentation: "https://help.ubuntu.com/community/OpenVPN",
  debianDocumentation: "https://wiki.debian.org/OpenVPN",
  buildSource: "https://openvpn.net/index.php/open-source/downloads.html"
};
export default URLS;
