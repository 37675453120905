import {
  SHOW_CLOUD_ACCOUNTS_LIST,
  SHOW_DETAILS_VIEW,
  CLEAR_DETAILS_VIEW,
  UPDATE_COLUMN_CONFIG_DATA,
  UPDATE_REORDER_COLUMN_DATA,
  UPDATE_VM_DETAILS,
  UPDATE_HYPERVISOR_INFORMATION,
  UPDATE_DESTINATIONS_FOR_DROPDOWNS,
  SHOW_ARCSERVE_CLOUD_DETAILS,
  UPDATE_ARCSERVE_CLOUD_FILTER,
  SHOW_ARCSERVE_CLOUD_VOLUME_DETAILS,
  UPDATE_USERS,
  UPDATE_USERS_V2,
  DELETE_UPDATE_USER_ACCOUNTS,
  DELETE_UPDATE_USER_ACCOUNTS_V2,
  SHOW_ADD_USER,
  SHOW_DIRECT_USER_PERMISSION,
  SHOW_MSP_USER_PERMISSION,
  UPDATE_USER_ROLES,
  ADD_UPDATE_USERS,
  ADD_UPDATE_USERS_V2,
  SET_SELECTED_USER,
  SET_ADD_MODE,
  EDIT_UPDATE_USERS,
  EDIT_UPDATE_USERS_V2,
  SET_SELECTED_ROLE,
  SHOW_ROLE_DETAILS,
  SHOW_LICENSE_DETAILS,
  SHOW_HIDE_ACTIVATE_ORDER_MODAL,
  CLEAR_LICENSE_DETAILS_VIEW,
  CLEAR_STATE_USER_ROLES,
  CLEAR_STATE,
  UPDATE_CREATE_GROUP_FLAG,
  UPDATE_SOURCES,
  DELETE_UPDATE_SOURCES,
  DELETE_UPDATE_SOURCE_GROUP,
  SET_SELECTED_SOURCE_GROUP,
  UPDATE_BRAND_LOGO,
  SHOW_BRANDING_DETAILS,
  GET_BRAND_DETAILS_ON_LOGIN,
  UPDATE_BRAND_INFORMATION,
  SHOW_EMAILER_DETAILS,
  UPDATE_EMAILER_INFORMATION,
  CHANGE_URL,
  UPDATE_SELECTED_RESOURCE,
  CLEAR_SELECTED_RESOURCE,
  UPDATE_LOGO_IMG_URL,
  UPDATE_LOGIN_IMG_URL,
  UPDATE_BRANDING_FROM,
  SHOW_HYPERVISOR_LIST,
  UPDATE_IS_BRANDING_LOGO,
  SHOW_NETWORK_CONFIG_VIEW,
  UPDATE_CUSTOMER_LIST,
  UPDATE_MSP_CHILD_ACCOUNTS,
  REMOVE_ASSIGNED_CUSTOMER,
  CLEAR_ADD_USER_STATE,
  UPDATE_ASSIGNED_CUSTOMERS,
  SHOW_HYPERVISOR_DETAILS,
  RESET_IMAGE_UPLOAD_SUCCESS_FLAG,
  SHOW_NETWORK_PORT_FORWARD,
  UPDATE_NETWORK_CONFIG_LIST,
  NETWORK_CONFIG_LIST,
  UPDATE_DELETED_NETWORK_CONFIG_LIST,
  SHOW_ADDED_NETWORK_CONFIG_LIST,
  NEW_RULE_NETWORK_CONFIG_LIST,
  DOWNLOAD_NETWORK_SESSION_LOGS,
  SHOW_LOGIN_TAB,
  UPDATE_VPN_DETAILS,
  STATE_DELETE_NETWORK_CONFIG_LIST,
  CLEAR_BRAND_INFORMATION,
  SHOW_STORAGE_ARRAYS,
  GET_PROXIES_DATA
} from "../actions/actionTypes";
import * as constant from "../../utils/appConstants";
import { splitPhoneNumber } from "../../utils/SpogDataGridUtil";

export const initialState = {
  arcserveCloud: {},
  arcserveCloudFilter: {
    checkbox: [],
    date: "1Y"
  },
  roleDetails: [],
  showUpdateBrandLogoModal: false,
  showUpdateLoginModal: false,
  brandData_requesting: false,
  brandData: {
    organization_name: "",
    portal_url: "",
    logo_img_url: "",
    primary_color: "",
    secondary_color: "",
    brandingLogo: false,
    login_img_url: "",
    branding_msg: "",
    copyright: ""
  },
  loginImgData: {
    image_url: "",
    logo_img_path: ""
  },
  logoImgData: {
    image_url: "",
    logo_img_path: ""
  },
  brandingFrom: "",
  assignedCustomers: [],
  selectedRole: {},
  selectedUser: {},
  configuredVms: [],
  portDetails: [
    {
      organization_id: "",
      enabled: true,
      datacenter: {
        id: "",
        name: ""
      },
      ram_capacity: 10,
      active_directory: true,
      network: [
        {
          id: "",
          ip_range: "",
          default_rounter: "",
          public_ip: []
        }
      ]
    }
  ],
  previousConfiguredVms: [],
  showLoginTab: true,
  hypervisorDetails: {},
  customerList: [],
  mspChildAccounts: [],
  userRoles: [],
  sources: [],
  paginationSources: {},
  proxies: []
};

/**
 * Reducer to manage Configure state
 * @param state
 * @param action
 * @returns {*}
 */
const configureReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_IS_BRANDING_LOGO: {
      let objToReturn = {};
      if (action.data) {
        objToReturn = { ...state, brandingLogo: true, logoImgData: null };
      } else {
        objToReturn = { ...state, brandingLogo: false };
      }
      return objToReturn;
    }
    case UPDATE_SELECTED_RESOURCE:
      return { ...state, selectedResource: action.data };

    case CLEAR_SELECTED_RESOURCE:
      return { ...state, selectedResource: null };

    case CLEAR_STATE_USER_ROLES:
      return { ...state, roleDetails: undefined };

    // Set the requesting flag and append a message to be shown
    case SHOW_CLOUD_ACCOUNTS_LIST: {
      let data = action.data;
      return { ...state, data: data };
    }

    case SHOW_HYPERVISOR_DETAILS: {
      return { ...state, hypervisorDetails: action.data };
    }

    case SHOW_DETAILS_VIEW: {
      return {
        ...state,
        detailsData: action.data.details,
        virtualMachines: action.data.vms
      };
    }
    case SHOW_ARCSERVE_CLOUD_DETAILS: {
      return {
        ...state,
        arcserveCloud: action.data.details
      };
    }
    case SHOW_ARCSERVE_CLOUD_VOLUME_DETAILS: {
      return {
        ...state,
        arcserveCloudVolume: action.data.details
      };
    }
    case UPDATE_ARCSERVE_CLOUD_FILTER: {
      return { ...state, arcserveCloudFilter: action.data };
    }

    case UPDATE_VM_DETAILS: {
      return { ...state, virtualMachines: action.data };
    }

    case SHOW_HYPERVISOR_LIST: {
      return { ...state, hypervisorList: action.data };
    }

    case SHOW_STORAGE_ARRAYS:
      return { ...state, storageArrayList: action.data };

    case UPDATE_HYPERVISOR_INFORMATION: {
      return {
        ...state,
        hypervisorDetails: updateObject(
          state.hypervisorDetails,
          action.data,
          "hypervisor_id"
        )
      };
    }

    case UPDATE_DESTINATIONS_FOR_DROPDOWNS: {
      return {
        ...state,
        drVolumeData: action.data.filter(
          item =>
            item.cloud_direct_volume &&
            item.cloud_direct_volume.volume_type &&
            item.cloud_direct_volume.volume_type === "zero_copy"
        ),
        backupVolumeData: action.data.filter(
          item =>
            item.cloud_direct_volume &&
            item.cloud_direct_volume.volume_type &&
            item.cloud_direct_volume.volume_type === "normal"
        )
      };
    }

    case CLEAR_STATE:
      return {
        ...state,
        data: { data: [] },
        hypervisorList: [],
        selectedSavedSearch: {},
        selectedSavedSearchId: "",
        selectedSavedSearchName: "",
        sources: []
      };

    case CLEAR_DETAILS_VIEW: {
      return {
        ...state,
        roleDetails: {},
        arcserveCloud: {},
        hypervisorDetails: {}
      };
    }

    case RESET_IMAGE_UPLOAD_SUCCESS_FLAG: {
      return { ...state, uploadImageSuccess: false };
    }

    case UPDATE_COLUMN_CONFIG_DATA: {
      let columnReducerData = action.data;
      //eslint-disable-next-line default-case
      switch (action.colunmType) {
        case constant.COLUMN_API_TYPES.STATIC: {
          let columnReducerDataFiltered = columnReducerData.map(obj => {
            obj.columnData = getReorderColumns(
              obj.columnData,
              action.colunmType
            );
            return obj;
          });
          return {
            ...state,
            columnData: columnReducerDataFiltered
          };
        }
        case constant.COLUMN_API_TYPES.API: {
          let columnReducerDataFiltereds = getReorderColumns(
            columnReducerData,
            action.colunmType
          );
          columnReducerData.columnData = columnReducerDataFiltereds;
          return { ...state, columnData: [columnReducerData] };
        }
      }
      break;
    }
    case UPDATE_REORDER_COLUMN_DATA: {
      let emptyObj = [];
      let stateData = emptyObj.concat(action.data);
      let tempObj = stateData.splice(action.oldItem.y + 2, 1); // first element removed
      stateData.splice(action.newItem.y + 2, 0, tempObj[0]);

      let columnConfigureData = JSON.parse(
        JSON.stringify(Object.assign([], action.stateColumnData))
      );
      let columnDataConfig = columnConfigureData.map(obj => {
        if (obj.name === action.stateFiltertype) {
          obj.columnData = Object.assign([], stateData); //stateData;
        }
        return obj;
      });
      return { ...state, columnData: columnDataConfig };
    }

    /**
     * update list of users fetched from back end
     */
    case UPDATE_USERS: {
      return {
        ...state,
        users: action.data || []
      };
    }

    case UPDATE_USERS_V2: {
      return {
        ...state,
        usersV2: action.data || []
      };
    }

    /**
     * update list of users after deleting a user
     */
    case DELETE_UPDATE_USER_ACCOUNTS: {
      return {
        ...state,
        users: removeItemFromMaster(state.users, action.data) //  remove the deleted user from the users array
      };
    }

    case DELETE_UPDATE_USER_ACCOUNTS_V2: {
      return {
        ...state,
        usersV2: removeItemFromMaster(state.usersV2, action.data) //  remove the deleted user from the usersV2 array
      };
    }

    /**
     * updates the showUserModal flag,
     * which is used to show/hide the 'Add User' modal form in Configure -> User Accounts
     */
    case SHOW_ADD_USER: {
      return {
        ...state,
        showUserModal: action.data
      };
    }

    /**
     * updates the showDirectUserPermissionModal flag,
     * which is used to show/hide the 'User Permission' modal form in Configure -> User Accounts
     */
    case SHOW_DIRECT_USER_PERMISSION: {
      return {
        ...state,
        showDirectUserPermissionModal: action.data
      };
    }
    /*
     * updates the showMSPUserPermissionModal flag,
     * which is used to show/hide the 'MSP User Permission' modal form in MSP -> Configure -> User Accounts
     */
    case SHOW_MSP_USER_PERMISSION: {
      return {
        ...state,
        showMSPUserPermissionModal: action.data
      };
    }

    /**
     * update list of users fetched from back end
     */
    case UPDATE_USER_ROLES: {
      return {
        ...state,
        userRoles: action.data
      };
    }

    /**
     * update list of users after creating a new user
     */
    case ADD_UPDATE_USERS: {
      state.users.push(action.data);
      return {
        ...state,
        users: [...state.users]
      };
    }

    case ADD_UPDATE_USERS_V2: {
      state.usersV2.push(action.data);
      return {
        ...state,
        usersV2: [...state.usersV2]
      };
    }

    /**
     * set the selected user in state
     */
    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.data
      };
    }

    /**
     * set the form mode
     * isAddMode = true for add mode
     * isAddMode = false for edit mode
     */
    case SET_ADD_MODE: {
      return {
        ...state,
        isAddMode: action.isAddMode
      };
    }

    /**
     * update users list after a user is updated
     */
    case EDIT_UPDATE_USERS: {
      return {
        ...state,
        users: editUpdateData(state.users, action.data, "user_id")
      };
    }

    case EDIT_UPDATE_USERS_V2: {
      return {
        ...state,
        usersV2: editUpdateData(state.usersV2, action.data, "user_id")
      };
    }

    case SHOW_ROLE_DETAILS: {
      return {
        ...state,
        roleDetails: updateRoleDetails(
          state.userRoles,
          action.data && action.data.id
        )
      };
    }

    /**
     * update selected role in dropdown
     */
    case SET_SELECTED_ROLE: {
      return {
        ...state,
        selectedRole: action.data
      };
    }

    /**
     * Add license details to state
     */
    case SHOW_LICENSE_DETAILS: {
      return {
        ...state,
        licenseDetails: action.data
      };
    }

    case SHOW_HIDE_ACTIVATE_ORDER_MODAL: {
      return {
        ...state,
        activateOrderModal: action.data
      };
    }

    /**
     * clear license details view from state
     */
    case CLEAR_LICENSE_DETAILS_VIEW: {
      return {
        ...state,
        licenseDetails: undefined,
        activateOrderModal: undefined
      };
    }
    case UPDATE_BRAND_LOGO: {
      return {
        ...state,
        showUpdateBrandLogoModal: action.data.brand,
        showUpdateLoginModal: action.data.login
      };
    }
    /**
     * show brand details
     * brandData- {} obj
     */
    case SHOW_BRANDING_DETAILS: {
      return {
        ...state,
        brandData: action.data,
        brandingLogo: action.data.brandingLogo,
        brandingFrom: action.data.branding_from,
        logoImgData: {
          image_url: action.data.logo_img_url,
          logo_img_path: action.data.logo_img_path
        },
        loginImgData: {
          image_url: action.data.login_img_url,
          logo_img_path: action.data.login_img_path
        },
        brandData_requesting: false
      };
    }

    /**
     * fetch brand details
     * brandData- {} obj
     */
    case GET_BRAND_DETAILS_ON_LOGIN: {
      return {
        ...state,
        brandData_requesting: true
      };
    }

    /**
     * to update brand details object
     * brandData- {} obj
     */

    case UPDATE_BRAND_INFORMATION: {
      return {
        ...state,
        brandData: action.data,
        brandingLogo: action.data.brandingLogo,
        uploadImageSuccess: false,
        brandingFrom: action.data.branding_from
      };
    }

    /**
     * to clear brand details object
     * brandData- {} obj
     */
    case CLEAR_BRAND_INFORMATION: {
      return { ...state, brandData: undefined, uploadImageSuccess: false };
    }

    /**
     * show emailer details
     * emailerData- {} obj
     */

    case SHOW_EMAILER_DETAILS: {
      return { ...state, emailerData: updateEmailerData(action.data) };
    }

    /**
     * to update emailer details
     * emailerData- {} obj
     */

    case UPDATE_EMAILER_INFORMATION: {
      return { ...state, emailerData: updateEmailerData(action.data) };
    }

    case UPDATE_LOGO_IMG_URL: {
      return { ...state, logoImgData: action.data, uploadImageSuccess: true };
    }

    case UPDATE_LOGIN_IMG_URL: {
      return { ...state, loginImgData: action.data, uploadImageSuccess: true };
    }
    case UPDATE_BRANDING_FROM: {
      return { ...state, brandingFrom: action.data };
    }
    /**
     * show/hide Create Group modal
     */
    case UPDATE_CREATE_GROUP_FLAG: {
      return {
        ...state,
        showCreateGroupModal: action.data
      };
    }

    /**
     * update sources list
     */
    case UPDATE_SOURCES: {
      return {
        ...state,
        sources: action.data,
        paginationSources: action.paginationData
      };
    }

    /**
     * update source list after source/s are deleted from back end for selected source group
     */
    case DELETE_UPDATE_SOURCES: {
      let sourcesArray = [];
      if (state.sources && Array.isArray(state.sources)) {
        sourcesArray = state.sources;
      } else if (
        state.sources &&
        state.sources.data &&
        Array.isArray(state.sources.data)
      ) {
        sourcesArray = state.sources.data;
      }
      return {
        ...state,
        sources: {
          data: removeItemsByKey(
            sourcesArray,
            action.sourceIdArray,
            "source_id"
          )
        }
      };
    }

    /**
     * remove all the sources after a source group has been deleted
     */
    case DELETE_UPDATE_SOURCE_GROUP: {
      return {
        ...state,
        sources: [],
        paginationSources: {}
      };
    }

    /**
     * set selected source group info
     */
    case SET_SELECTED_SOURCE_GROUP: {
      return {
        ...state,
        selectedSourceGroup: action.data
      };
    }

    /**
     * update the 'change url' flag
     */
    case CHANGE_URL: {
      return {
        ...state,
        changeURL: action.data
      };
    }

    /**
     * get the network configuration vms
     */
    case SHOW_NETWORK_CONFIG_VIEW: {
      return {
        ...state,
        configuredVms: action.data,
        previousConfiguredVms: action.data
      };
    }

    case UPDATE_CUSTOMER_LIST: {
      return {
        ...state,
        customerList: action.data.map(item => (item.msp ? item.msp : item))
      };
    }

    case UPDATE_MSP_CHILD_ACCOUNTS: {
      return {
        ...state,
        mspChildAccounts: action.data
      };
    }

    case REMOVE_ASSIGNED_CUSTOMER: {
      return {
        ...state,
        assignedCustomers:
          state &&
          state.assignedCustomers &&
          state.assignedCustomers.filter(item => item.value !== action.id)
      };
    }

    case UPDATE_ASSIGNED_CUSTOMERS: {
      return {
        ...state,
        assignedCustomers: getArrayIntersection(
          state.assignedCustomers,
          action.data
        )
      };
    }

    case CLEAR_ADD_USER_STATE: {
      return {
        ...state,
        assignedCustomers: [],
        selectedRole: {},
        selectedUser: {},
        customerList: []
      };
    }

    /**
     * get network configuration port forwading list
     */
    case SHOW_NETWORK_PORT_FORWARD: {
      return {
        ...state,
        portDetails: action.data
      };
    }

    /**
     * update network configuration port forwading grid list
     */

    case UPDATE_NETWORK_CONFIG_LIST: {
      return {
        ...state,
        configuredVms: updateNetworkListObject(
          state.configuredVms,
          action.data,
          "recoveredresource_id"
        )
      };
    }

    /**
     * to store network configuration port forwading grid list
     */

    case NETWORK_CONFIG_LIST: {
      return {
        ...state,
        configuredVms: action.data
      };
    }

    /**
     * delete network configuration port forwading grid list
     */

    case UPDATE_DELETED_NETWORK_CONFIG_LIST: {
      return {
        ...state,
        configuredVms: removeItemByKeyForNetWorkConfiguration(
          state.configuredVms,
          action.data.id,
          action.data.typeId,
          action.data.rowId
        )
      };
    }

    /**
     * to add network configuration port forwading grid list
     */

    case SHOW_ADDED_NETWORK_CONFIG_LIST: {
      return {
        ...state,
        configuredVms: updateNetworkListObject(
          state.configuredVms,
          action.data,
          "row_id"
        )
      };
    }

    case NEW_RULE_NETWORK_CONFIG_LIST: {
      return {
        ...state,
        configuredVms: addObjectInArray(
          state.configuredVms,
          action.data,
          action.data.id
        )
      };
    }

    case STATE_DELETE_NETWORK_CONFIG_LIST: {
      return {
        ...state,
        configuredVms: removeItemByKeyForNetWorkConfiguration(
          state.configuredVms,
          action.data.id,
          "id",
          action.data.row_id
        )
      };
    }
    /**
     * to download session logs for network configuration
     */

    case DOWNLOAD_NETWORK_SESSION_LOGS: {
      return {
        ...state,
        sessionLogs: action.data
      };
    }

    case SHOW_LOGIN_TAB: {
      return {
        ...state,
        showLoginTab: action.data
      };
    }

    case UPDATE_VPN_DETAILS: {
      return {
        ...state,
        VPNDetails: action.data
      };
    }

    case GET_PROXIES_DATA: {
      return {
        ...state,
        proxies: action.data
      };
    }

    default:
      return state;
  }

  /**
   * Handle insert/add array
   *
   * @param {array} stateArray
   * @param {array} actionArray current inserted item
   */
  function addObjectInArray(array, action) {
    return array && [...array.slice(0), action];
  }

  /**
   * Handle update Object for network configuration
   *
   * @param {item} stateObject
   * @param {action} actionObject current options in reducer state
   */
  function updateNetworkListObject(masterData, newRecord, key) {
    const arrayIndex = masterData.findIndex(rec => {
      return rec[key] === newRecord[key];
    });
    //masterData[arrayIndex].is_add_row = false;
    masterData[arrayIndex].obj = { ...newRecord };
    return [...masterData];
  }

  /**
   * to delete network configuration list
   */
  function removeItemByKeyForNetWorkConfiguration(array, action, type, rowId) {
    if (array && array.length > 0) {
      const data = array && array.slice();
      const updatedData =
        data &&
        data.filter(
          item => item.obj[type] !== action || item.obj["row_id"] !== rowId
        );
      return updatedData && [...updatedData];
    } else {
      return [];
    }
  }

  function getArrayIntersection(oldArray, newArray) {
    let arrToReturn = [];
    for (let i in oldArray) {
      let shared = false;
      for (let j in newArray)
        if (newArray[j].value === oldArray[i].value) {
          shared = true;
          break;
        }
      if (!shared) arrToReturn.push(oldArray[i]);
    }
    return arrToReturn.concat(newArray).sort(function(a, b) {
      return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
    });
  }

  /**
   * this is a generic code and can be used for all other modules
   * this will update the master data after a record was updated at the back end
   */
  function editUpdateData(masterData, newRecord, key) {
    const arrayIndex = masterData?.data?.findIndex(rec => {
      return rec[key] === newRecord[key];
    });
    if (arrayIndex > -1) {
      masterData.data[arrayIndex] = { ...newRecord };
    }
    return { ...masterData };
  }
  /**
   * remove the user which was deleted from the users array
   */
  function removeItemFromMaster(userArray, userId) {
    let data = userArray.slice();
    const updatedData = data.filter(item => item.user_id !== userId);
    return [...updatedData];
  }

  /**
   * generic method for deleting array elements
   * Note: This method expects actionArray to be an array
   */
  function removeItemsByKey(array, actionArray, key) {
    let data = array.slice();
    const updatedData = data.filter(item => !actionArray.includes(item[key]));
    return [...updatedData];
  }

  /**
   * Handle processing of reorder columns for showing in settings
   *
   * @param {array} columns
   */
  function getReorderColumns(columns, columnType) {
    let showData = [];
    let hideData = [];
    let columnDataSorted = [];
    let available_actions = [];
    //eslint-disable-next-line default-case
    switch (columnType) {
      case constant.COLUMN_API_TYPES.STATIC:
        columns.forEach(obj => {
          if (
            obj.identifier ===
              constant.SPOG_GRID_PROPERTIES.ACTION_KEY.ACTION ||
            obj.identifier ===
              constant.SPOG_GRID_PROPERTIES.ACTION_KEY.AVAILABLE_ACTIONS
          ) {
            available_actions.push(obj);
          } else {
            if (obj.show) {
              showData.push(obj);
            } else {
              hideData.push(obj);
            }
          }
        });
        columnDataSorted = [...showData, ...available_actions, ...hideData];
        return columnDataSorted;
      case constant.COLUMN_API_TYPES.API: {
        let column = columns.columnData;
        column.forEach(obj => {
          if (
            obj.identifier ===
              constant.SPOG_GRID_PROPERTIES.ACTION_KEY.ACTION ||
            obj.identifier ===
              constant.SPOG_GRID_PROPERTIES.ACTION_KEY.AVAILABLE_ACTIONS
          ) {
            available_actions.push(obj);
          } else {
            if (obj.show === true) {
              showData.push(obj);
            } else {
              hideData.push(obj);
            }
          }
        });
        columnDataSorted = [...showData, ...available_actions, ...hideData];
        return columnDataSorted;
      }
    }
  }

  /**
   * Handle update Object
   *
   * @param {any} stateObject
   * @param {any} actionObject current options in reducer state
   */
  function updateObject(item, action, key) {
    if (key) {
      if (item[key] !== action[key]) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
    } else {
      if (item.id !== action.id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action
    };
  }

  /**
   * filter the role details based on the role
   */
  function updateRoleDetails(roles, roleId) {
    const userRoles = roles.data;
    const selectedRole = userRoles.filter(role => {
      return role.id === roleId || role.role_id === roleId;
    });
    return selectedRole && selectedRole.length
      ? selectedRole[0].permissions
      : [];
  }

  /**
   * sepereate suport call into isd and number
   */
  function updateEmailerData(emailerData) {
    let emailerDataClone = Object.assign({}, emailerData);

    if (emailerDataClone.support_call) {
      const phoneObj = splitPhoneNumber(
        emailerDataClone.support_call.replace(/[^+0-9]/g, "")
      );
      emailerDataClone.support_call = phoneObj.phoneNumber;
      emailerDataClone.isd = phoneObj.isd;
    }
    return emailerDataClone;
  }
};

export default configureReducer;
