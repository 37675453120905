import {
  select,
  all,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  fork
} from "redux-saga/effects";
import { reset, getFormValues, change } from "redux-form";
import { delay } from "redux-saga";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getLogin,
  getPolicy,
  getLoginToken,
  getOrgId,
  getCDLoginToken,
  getContextualAction,
  getFeatureFlag
} from "state/selectors";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import * as ccToast from "state/actions/ccToast";
import localeData from "assets/i18n/locales/data.json"; // i18n translated strings
import * as constant from "utils/appConstants";
import * as actionType from "../actions/actionTypes";
import * as ccDataGridSelectors from "state/selectors/ccDataGrid";
import * as ccDataGridActions from "state/actions/ccDataGrid";
import moment from "moment";
import { RECOVERED_VMS_ACTION } from "components/protect/recoveredResources/RecoveredResourcesConstant";
import {
  updateCurrentSearchFromSaved,
  updateProtectListCount,
  showSourcesList,
  showOracleSources,
  showSourcesLocalList,
  searchEnterEvent,
  showDestinationList,
  showPoliciesList,
  showPoliciesLocalList,
  fetchProxiesIVMSuccess,
  //updateColumnData,
  showDestinationDetails,
  filteredRecoveryList,
  showVMsList,
  showSourceDetails,
  showSelectedSourceDetails,
  updatePolicyPageDropdownsAndProtectionCard,
  showDataCenters,
  addAPIresponse,
  updateResourceInList,
  updateOracleHostsInList,
  updateSourceGroupList,
  updatedDestinationList,
  deletedDestinationList,
  addSourceGroupList,
  showCustomerAccountsList,
  showCustomerAccountDetails,
  showSelectedResourceDetails,
  setResourceName,
  showProtectCustomerList,
  editUpdateSourceConfiguration,
  removeUnassignAdminFromCustomerList,
  deleteUpdateSourceGroups,
  redirectToPolicyGrid,
  getCustomerAccountsList,
  changeURLFlag,
  getProtectCustomerList,
  updateFileBrowserData,
  updateFileBrowserDataGridData,
  updateFileBrowserDataGridPaginationData,
  updateSelectedRecoveredVM,
  updateRecoveryImageFormats,
  updateRecoveryTargetsData,
  updateDestinationCD,
  updatePolicySource,
  updateCustomerAccountCD,
  updateRecoveryPointsDetails,
  showBrowseRecoveryPoint,
  submitAdvancedSearch,
  showHideSaveSearchNClearAll,
  fromNavigationApi,
  updateDeleteSourceInList,
  updateDeleteOracleHostsInList,
  updateConfigurationLicenses,
  updateMenuCountsOnAddRemoveActions,
  updateSelectedResource,
  updateSourceCount,

  // policy stuff
  fetchPolicy,
  fetchPolicySuccess,
  fetchPolicyFailure,
  fetchHypervisor,
  fetchHypervisorSuccess,
  fetchHypervisorFailure,
  updateHypervisor,
  updateHypervisorSuccess,
  updateHypervisorFailure,
  fetchRemotePoliciesSuccess,
  fetchRemotePoliciesFailure,
  fetchEntitlementsProducts,
  fetchAllDestinations,
  fetchAllDestinationsSuccess,
  fetchAllDestinationsFailure,
  fetchFailbackDestinationsSuccess,
  fetchFailbackDestinationsFailure,
  // end policy stuff
  updateSelectedPolicyType,
  getCurrentActionCalled,
  startLoader,
  stopLoader,
  updateParticularStateInRecoveredVms,
  updateRemotePolicies,
  updateRemotePolicyError,
  showLicenseDetails,
  updateDeleteRecoveredVMInList,
  showConfirmation,
  startFailbackFailure,
  startFailbackLoading,
  startFailbackSuccess,
  getDisoverSourceName,
  addSourcesHypervisorConnectSuccess,
  addSourcesHypervisorVmwareConnectSuccess,
  addSourcesHypervisorHyperVConnectSuccess,
  addSourcesHypervisorNutanixConnectSuccess,
  clearHyperVisorForm,
  addSourcesSetHypervisorId,
  linuxBackupServerSourceSaveToListError,
  linuxBackupServerSourceSaveToListSuccess,
  discoverSourcesAddSuccess,
  discoverSourcesAddFailure,
  changeActiveDirectoryPanel,
  addSourcesFromActiveDirectory,
  hideActiveDirectoryAuthenticationModal,
  hideDialog,
  Dialog,
  windowsSourceSaveToListSuccess,
  windowsSourceSaveToListError,
  clearWindowsSourcesError,
  linuxSourceSaveToListSuccess,
  clearlinuxSourcesError,
  linuxSourceSaveToListError,
  setLoading,
  resetLoading,
  setInitialPath,
  Loader,
  addSourcesHypervisorConnectError,
  getSitesAction,
  loadSitesSuccess,
  getProtectListCount,
  getConfigureListCount,
  remoteConsoleList,
  getActiveDirectories,
  adIncrementAuthCount,
  adWinSourceAuthSuccess,
  adWinSourceAuthFailure,
  adInitAuthCount,
  loadRegisteredSitesSuccess,
  getSourcesList,
  addFetchADSourcesError,
  clearAdData,
  loadBackupProxySuccess,
  loadVsbMonitorSuccess,
  loadLBSSuccess,
  loadLinuxSuccess,
  loadLinuxLBSSuccess,
  getSelectedProxy,
  getSelectedLBS,
  loadRecoveryPointServersSuccess,
  loadRPSDataStoresSuccess,
  loadNetworkForBackupTrafficRPSIDSuccess,
  loadNetworkForRestoreTrafficRPSIDSuccess,
  loadNetworkForRestoreTrafficProxyIDSuccess,
  addSourcesHyperSaveToList,
  addSourcesHypervisorSaveToList,
  addSourcesHypervisorAddSourcePolicyEsx,
  addSourcesHypervisorAddSourcePolicyHV,
  resetAddSourcesHypervisor,
  UncOrNfsClearPathListError,
  UncOrNfsPathSaveToListSuccess,
  UncOrNfsPathSaveToListError,
  clearStateSelectedRows,
  updateRemotePoliciesData,
  updateSourceDetailsInStore,
  getSharedPolicySuccess,
  loadSNBTrafficSuccess,
  loadSelectedLBSNetworkSuccess,
  clearSharedPolicy,
  listHypervisorsSuccess,
  updateMountedRPS,
  getAllHypervisorsSuccess,
  getAllProxiesForPolicySuccess,
  getHypervisorConfigurationByIDSuccess,
  resetRecovery,
  storeSQLSummaryData,
  storeSQLDbDetails,
  switchAddIVMTab,
  storeFnFSummaryData,
  storeVolumeSummaryData,
  storeVMSummaryData,
  showDialog,
  fetchMountedRPS,
  getPendingSessions,
  getStandbyVMSnapshot,
  getIsNetworkConfigured,
  handleContextualAction,
  storeFnFLinuxSummaryData,
  validateScheduleFnFLinux,
  updateFolderTreeData,
  hideFolderStructureLoader,
  updateFnFGridData,
  updateFnFGridDataPagination,
  updateGridCurrentPageNumber,
  updateFnFPagination,
  fetchItemsForRP,
  saveRPPassword,
  storeSelectedRecoveryPoint,
  validateRecoveryPoint,
  browseSharedFolders,
  getNetworkData,
  setBackupPasswordsData,
  loadDNSData,
  policyVsbFetchVmwareNodes,
  ec2CloudProxyValidationResponse,
  GCPCloudProxyValidationResponse,
  createHyperVForIVMSuccess,
  createNutanixForIVMSuccess,
  createVmwareForIVMSuccess,
  getHypervisorsListByType,
  searchList,
  fetchResourcePool,
  getSelectedRecoveredVM as selectedRecoveredVMAction,
  getHypervList as getHypervListOptions,
  storeValidateConfigdata,
  fetchAgentlessVMSources,
  browseRemoteConsole,
  getVSBHypervisorsSuccess,
  getVSBHypervisorsFailed,
  getCloudAccountListByTypeSuccess,
  disableSavePolicyButton,
  saveContainerPoliciesdata,
  setRecoveryNextLoading,
  setFromMonitorPage,
  setNewVMInstanceUID,
  storeSelectedItems,
  fetchDestinationPath,
  fetchDestinationPathFailure,
  fetchDestinationPathSuccess,
  transactionDetailsSucceess,
  updateGridCurrentPageSize,
  stopGridLoaderById,
  startGridLoaderById,
  showGridLoader,
  clearContextualAction,
  clearRecoveryRestoreMountData,
  onSourceARTDataGetSuccess,
  clearSourceARTTaskDetails,
  clearOracleSourcesError,
  clearOracleDBWindowsSourcesError,
  oracleSourceSaveToListSuccess,
  oracleSourceSaveToListError,
  orcaleWindowsDatabaseOptionsSuccess,
  orcaleWindowsDatabaseOptionsError,
  oracleWindowsDBSaveToListSuccess,
  oracleWindowsDBSourceSaveToListError,
  clearOracleDBWindowsSourcesList,
  getRecoveryOracleDBConfig,
  addLinuxBackupFlag,
  clearAddSourcesHyperSavedList,
  browseRemoteConsoleLoading,
  browseRemoteConsoleSuccess,
  browseRemoteConsoleFailure,
  nutanixClusterConfigurationForPolicySuccess,
  storeSourceIdForResumeVSB
} from "../actions/actions";
import { protectApi, monitorApi, destinationApis } from "../api";
import * as util from "../../utils/SpogDataGridUtil";
import SourceConstants from "../../components/protect/source/SourceConstants";
import { DestinationAttributes } from "../../components/protect/destination/DestinationConstants";
import { PoliciesAttributes } from "../../components/protect/policy/PolicyConstants";
import * as SourceGroupConstants from "../../components/protect/source/source-group/SourceGroupConstants";
import {
  SourcesRecoveryAttributesCloudDirect,
  CD_RECOVERY_POINTS_COLUMN_TYPE
} from "../../components/common/recovery-points/RecoveryPointsConstants";
import { AddSourceAttributes } from "../../components/protect/source/add-source/AddSourceConstants";
import {
  POLICY_TYPES,
  ADD_REMOTE_CONSOLE,
  CREATE_CLOUD_ACCOUNT,
  TASK_TYPES
} from "../../components/protect/policy/create-policy/destinations/DestinationConstants";
import * as API_URL from "../api/apiUrlConstants";
import {
  getSelectedVms,
  getSelectedSite,
  getHypervisorId
} from "state/selectors/addSources";
import * as protectAction from "../actions/ProtectActions";
import { getBackupProxy } from "state/api/protectApis";
import {
  showErrToastFromResponse,
  showToastFromResponse,
  getFormattedMessage,
  makeToastData
} from "./sagautils";
import {
  recoveryType,
  fnfLinuxSummaryData,
  fnfSummaryData,
  volumeSummaryData,
  sqlSummaryData,
  sqlDbDetails,
  selectedRecoveryPoint,
  selectedSource,
  folderTreeData,
  currSelectedTableData,
  FnfGridData,
  selectedItemForRecovery,
  selectOracleDBSettings,
  selectOracleDBTableSettings,
  hypervisorInfo,
  validVMData,
  validLinuxVMData,
  rpEncryptionPassword,
  recoverVMPayload,
  sharedPathCredentials,
  selectedDestination,
  sourcePagination,
  selectedDestinationData,
  selectedHypervisorType,
  selectedHypervisorIdInRecovery,
  getHypervVMDisk,
  isSourceRecoveryEncryptionEnabled,
  destinationsData,
  updatedLbs,
  isSQLServerWriterEnabled,
  oracleSummaryData,
  selectedRpsListWithPassword
} from "state/selectors/destinations/recovery";
import { selectedDestinationDataIVM } from "state/selectors/ivm";
import {
  ORACLE_RESTORE_TYPES,
  RecoveryTypes
} from "components/protect/destination/data-store/browse/recovery-points/Constants";
import * as DCs from "components/protect/policy/create-policy/destinations/DestinationConstants";
import { fetchData } from "state/sagas/ccDataGrid";
import { getRpsHashMemory } from "state/api/destinationApis";

const getReducer = state => state.reducer;
const getProtectReducer = state => state.protectReducer;
const getProtectReducerPolicyType = state =>
  state.protectReducer.selectedPolicyType;
const getCloudAccountPagination = state => state.destinations.CloudAccount;
const getApi = state => state.api;
const getLinuxScripts = state => state.protectReducer.getLinuxScripts;
const getSharedFolderPagination = state =>
  state?.destination?.sharedFolder?.sharedFolderListPagination;

export function* receiveProtectList(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    let stateReducerOptions = [];
    let stateReducersearchText = "";
    let navigation = constant.SECONDARY_NAVIGATION_TYPE.SOURCES;
    let resourceId = "";
    if (stateReducer && stateReducer.secondaryNavigation) {
      navigation = stateReducer.secondaryNavigation;
    }
    if (data.payload && data.payload.filter) {
      navigation = data.payload.filter;
    }

    if (data.payload && data.payload.resourceId) {
      resourceId = data.payload.resourceId;
    }

    if (
      data.payload &&
      data.payload.advancedSearchOptions &&
      data.payload.advancedSearchOptions.length > 0
    ) {
      stateReducerOptions = data.payload.advancedSearchOptions;
    } else if (
      stateReducer.selectedFilterOptions &&
      stateReducer.selectedFilterOptions.length > 0
    ) {
      stateReducerOptions = stateReducer.selectedFilterOptions;
    } else if (
      stateReducer.currentSearchOptions &&
      stateReducer.currentSearchOptions.length > 0
    ) {
      stateReducerOptions = stateReducer.currentSearchOptions;
    }

    if (
      data.type !== "ADVANCED_SEARCH_TRIGGERED" &&
      stateReducer.pageReloaded &&
      stateReducer.currentSearchOptions &&
      stateReducer.currentSearchOptions.length > 0
    ) {
      stateReducerOptions = stateReducer.currentSearchOptions;
    }
    if (data.payload && data.payload.searchText) {
      stateReducersearchText = data.payload.searchText;
    } else if (stateReducer) {
      stateReducersearchText = stateReducer.searchText;
    }
    let sortedDataSet = [];
    if (data.payload && data.payload.sortedData) {
      sortedDataSet = data.payload.sortedData;
    } else if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedDataSet = stateReducer.sortedColumns;
    } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
      sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
    }
    let stateReducersearchOption = "";
    if (stateReducer && stateReducer.searchOption.selectedOption) {
      stateReducersearchOption = stateReducer.searchOption.selectedOption;
    }
    const gridCurrentPage =
      data.payload && data.payload.gridCurrentPage
        ? data.payload.gridCurrentPage
        : stateReducer.gridCurrentPage;
    const gridPageSize =
      data.payload && data.payload.gridPageSize
        ? data.payload.gridPageSize
        : stateReducer.gridPageSize;

    let protectList = [];
    let filterType = "";
    let makeQueryString = "";
    let queryString = "";
    let fromPolicySources = false;
    let fromAddSources = _.get(data, "payload.fromAddSources", false);
    let licenseDetails = {};

    makeQueryString = util.getQueryStringByAdvanceSearchOptions(
      queryString,
      stateReducerOptions
    ); //, stateReducersearchText, stateReducersearchOption, 'source_name'

    if (makeQueryString !== "") {
      // yield put(submitAdvancedSearch());
      // const selectedSavedSearchName = '';
      const selectedOptions = stateReducerOptions;
      const searchString = stateReducersearchOption;

      // update the state options only when doNotUpdateOptions is not passed or is true
      if (data.payload && !data.payload.doNotUpdateOptions) {
        yield put(
          updateCurrentSearchFromSaved({ selectedOptions, searchString })
        );
      }
    }
    if (
      //eslint-disable-next-line eqeqeq
      stateReducersearchText != undefined &&
      //eslint-disable-next-line eqeqeq
      stateReducersearchText != "" &&
      data.payload &&
      !data.payload.doNotUpdateOptions
    ) {
      yield put(searchEnterEvent(data.payload));
    }
    let selectedPolicyType = yield select(getProtectReducerPolicyType);
    if (gridCurrentPage && gridPageSize) {
      //eslint-disable-next-line eqeqeq
      if (makeQueryString != "") {
        makeQueryString += `&`;
      }
      makeQueryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
    }
    let advanceQueryParam =
      (stateReducer.secondaryNavigation ===
        constant.SECONDARY_NAVIGATION_TYPE.SOURCES ||
        stateReducer.secondaryNavigation ===
          constant.SECONDARY_NAVIGATION_TYPE.POLICIES) &&
      ((!stateReducer.showCurrentSearch &&
        !stateReducer.fromMonitorPage &&
        !stateReducer.hyperLink) ||
        (data.type === "ADVANCED_SEARCH_TRIGGERED" &&
          data.payload.searches &&
          data.payload.searches.length === 0))
        ? undefined
        : stateReducer.advancedSearchParam;
    if (stateReducer.fromMonitorPage) setFromMonitorPage(false);
    if (advanceQueryParam && advanceQueryParam.criteria) {
      makeQueryString += `&${advanceQueryParam.criteria}=${advanceQueryParam.value}`;
    }
    if (
      stateReducer.secondaryNavigation ===
        constant.SECONDARY_NAVIGATION_TYPE.POLICIES &&
      data.payload &&
      data.payload.filter !== constant.SECONDARY_NAVIGATION_TYPE.POLICIES
    ) {
      makeQueryString += "&is_deleted=false";
      fromPolicySources = true;
    }

    if (
      navigation === constant.SECONDARY_NAVIGATION_TYPE.SOURCES &&
      data.payload &&
      data.payload.sourceGroupId
    ) {
      //eslint-disable-next-line eqeqeq
      if (makeQueryString != "") {
        makeQueryString += `&`;
      }
      makeQueryString += `&group_id=!${data.payload.sourceGroupId}`;
    }
    if (
      stateLogin.organization_id &&
      //  organization_id is not required in case of Customer Accounts
      navigation !== constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
    ) {
      //eslint-disable-next-line eqeqeq
      if (makeQueryString != "") {
        makeQueryString += `&`;
      }
      makeQueryString += `organization_id=${stateLogin.organization_id}`;
    }

    // add sorting columns to the query
    if (sortedDataSet.length) {
      makeQueryString = util.getQueryStringFromSortedData(
        makeQueryString,
        sortedDataSet
      );
    }

    //for recovery targets data
    let updatedQueryString = "";
    let targetQueryString =
      data.payload && data.payload.recovery_target_querystring
        ? data.payload.recovery_target_querystring
        : "";
    let recoverTargetSourceId =
      data.payload && data.payload.recover_target_source_id
        ? data.payload.recover_target_source_id
        : resourceId;
    let constructUrlPoint = "";

    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.VM_HYPERVISOR:
        yield put(showLoading());
        yield put(startLoader());
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          AddSourceAttributes.ADD_SOURCE_PROP_VM_NAME
        );
        yield put(showLoading());
        apiResponse = yield call(
          protectApi.fetchVMsList,
          data.payload.hypervisorID,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (apiResponse.status !== constant.HTTP_STATUS.OK) {
          apiResponse.context = constant.API_RESPONSE_CONTEXT.SOURCES;
          yield put(addAPIresponse(apiResponse));
        }
        yield put(showVMsList(apiResponse));
        yield put(fromNavigationApi(false));
        yield put(hideLoading());
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCES:
      case constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES:
      case constant.SECONDARY_NAVIGATION_TYPE.MACHINES:
      case constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS:
      case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES:
      case constant.SECONDARY_NAVIGATION_TYPE.SHARE_POINT:
      case constant.SECONDARY_NAVIGATION_TYPE.OFFICE_365:
      case constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM:
      case constant.SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER:
      case constant.SECONDARY_NAVIGATION_TYPE.UDP_LINUX:
      case constant.SECONDARY_NAVIGATION_TYPE.UDP_ORACLE:
        // yield put(showLoading());
        // yield put(startLoader());
        if (fromPolicySources && selectedPolicyType) {
          yield put(
            startGridLoaderById(constant.DATAGRID_IDS["sources_policy_grid"])
          );
        } else {
          yield put(
            startGridLoaderById(constant.DATAGRID_IDS["sources_datagrid"])
          );
        }
        const getConfigureReducer = state => state.configureReducer;
        const configureReducer = yield select(getConfigureReducer);
        if (
          !data.pollingAction &&
          configureReducer.licenseDetails === undefined
        ) {
          licenseDetails = yield call(
            protectApi.fetchEntitlements,
            stateLogin.organization_id,
            stateLogin.token,
            data.pollingAction
          );
          if (licenseDetails.status === constant.HTTP_STATUS.OK) {
            yield put(showLicenseDetails(licenseDetails.data));
          }
        }
        const getProtectReducerSource = state => state.protectReducer;
        const protectReducerSource = yield select(getProtectReducerSource);
        const getReducerSource = state => state.reducer;
        const reducerSource = yield select(getReducerSource);
        makeQueryString = util.getQueryStringByType(
          makeQueryString,
          navigation,
          SourceConstants.SourceAttributes.SOURCE_PROP_TYPE
        );
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          SourceConstants.SourceAttributes.SOURCE_PROP_NAME
        );
        queryString = util.stripslashes(queryString);
        if (fromPolicySources) {
          constructUrlPoint = `${stateLogin.organization_id}/policies/types/${selectedPolicyType}/sources`;
          queryString += `&pagination=false`;
          if (selectedPolicyType === POLICY_TYPES.HYPERVISOR) {
            const policy = yield select(getPolicy);
            queryString += `&hypervisor_id=${policy.hypervisor_id}`;
          }
        }

        let callAPISource = true;

        //local sort and filter if less than or equal to 50 objects
        let expectedItems = fromPolicySources
          ? []
          : reducerSource.menu
              .find(ele => ele.key === "Protect")
              .subMenu.find(
                ele =>
                  ele.key === navigation ||
                  (navigation === "machine" && ele.key === "physical_machines")
              ).amount;

        let sourceGroups =
          reducerSource.primaryNavigation === "Configure" &&
          reducerSource.secondaryNavigation === "source_groups";
        if (expectedItems <= 50 && !sourceGroups && !fromPolicySources) {
          queryString = queryString.replace("page_size=25", "page_size=50");
        }
        let reRunSearch =
          data.type === "ADVANCED_SEARCH_TRIGGERED" &&
          data.payload.clear === undefined &&
          protectReducerSource.data.data.length < expectedItems &&
          expectedItems > 0;
        // protectList = yield call(
        //   fromPolicySources && selectedPolicyType
        //     ? protectApi.fetchSourcesListForCDPolicy
        //     : protectApi.fetchSourcesList,
        //   queryString,
        //   queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
        //     ? stateLogin.cdtoken
        //     : stateLogin.token,
        //   constructUrlPoint,
        //   data.pollingAction
        // );
        // let callAPISource = true;
        // //local sort and filter if less than or equal to 50 objects
        // if (
        //   protectReducerSource.data.data.length <= 50 &&
        //   protectReducerSource.data.data.length > 0 &&
        //   data.pollingAction === false
        // ) {
        //   callAPISource = false;
        //   //filtering
        let clear =
          data.type === "ADVANCED_SEARCH_TRIGGERED" &&
          data.payload.clear === true &&
          data.payload.advancedSearchOptions.length === 0;

        if (
          protectReducerSource.data.pagination &&
          protectReducerSource.data.pagination.total_size <= 50 &&
          protectReducerSource.data.pagination.total_size > 0 &&
          protectReducerSource.data.data.length > 0 &&
          (data.type === "ADVANCED_SEARCH_TRIGGERED" ||
            data.type === "GET_SOURCE_LIST") &&
          !reRunSearch &&
          expectedItems <= 50 &&
          expectedItems > 0 &&
          !fromAddSources
        ) {
          callAPISource = false;
          //filtering
          let filtered = [...protectReducerSource.data.data];
          if (!clear) {
            if (queryString.includes("source_name=")) {
              let sO = queryString.split("source_name=")[1].split("&")[0];
              filtered = [
                ...filtered.filter(ele => {
                  // sO.includes(_.get(ele, "source_name"))
                  return _.get(ele, "source_name").match(sO) !== null &&
                    _.get(ele, "source_name").match(sO).length > 0
                    ? true
                    : false;
                })
              ];
            }
            if (queryString.includes("last_job=")) {
              let sO = queryString
                .split("last_job=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "last_job[0].status"))
                )
              ];
            }
            if (queryString.includes("policy_id=")) {
              let sO = queryString
                .split("policy_id=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "policy[0].policy_id"))
                )
              ];
            }
            if (queryString.includes("site_id=")) {
              let sO = queryString
                .split("site_id=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "site.site_id"))
                )
              ];
            }
            if (queryString.includes("protection_status=")) {
              let sO = queryString
                .split("protection_status=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "protection_status"))
                )
              ];
            }
            if (queryString.includes("is_deleted=")) {
              let sO = queryString
                .split("is_deleted=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "is_deleted").toString())
                )
              ];
            }
            if (queryString.includes("group_id=")) {
              let sO = queryString
                .split("group_id=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.some(
                    e =>
                      !_.get(ele, "source_group", []).findIndex(
                        obj => obj.group_id === e
                      )
                  )
                )
              ];
            }
            if (queryString.includes("connection_status=")) {
              let sO = queryString
                .split("connection_status=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "connection_status"))
                )
              ];
            }
            if (queryString.includes("operating_system=")) {
              let sO = queryString
                .split("operating_system=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "operating_system.os_major"))
                )
              ];
            }
          }

          //sorting section
          if (
            queryString.split("&sort=").length > 1 &&
            protectReducerSource.data.data.length > 1
          ) {
            let sortv = queryString.split("&sort=")[1];
            if (sortv.includes("-")) {
              if (sortv.includes("operating_system")) {
                filtered.sort((a, b) =>
                  a[sortv]["os_major"] < b[sortv]["os_major"] ? 1 : -1
                );
              } else {
                filtered.sort((a, b) => (a[sortv] < b[sortv] ? 1 : -1));
              }
            } else {
              if (sortv.includes("operating_system")) {
                filtered.sort((a, b) =>
                  a[sortv]["os_major"] > b[sortv]["os_major"] ? 1 : -1
                );
              } else {
                filtered.sort((a, b) => (a[sortv] > b[sortv] ? 1 : -1));
              }
            }
          }
          protectList = {
            data: filtered,
            errors: protectReducerSource.data.errors,
            pagination: protectReducerSource.data.pagination,
            status: 200
          };
        } else {
          if (
            stateReducer.secondaryNavigation ===
            constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS
          ) {
            const { rman: isRMANVisible } = yield select(getFeatureFlag);
            if (isRMANVisible) {
              queryString += `&source_type=${constant.CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS.sources}`;
            } else {
              queryString += `&source_type=${constant.CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS.sourcesWithoutOracleDB}`;
            }
          }
          protectList = yield call(
            fromPolicySources && selectedPolicyType
              ? protectApi.fetchSourcesListForCDPolicy
              : protectApi.fetchSourcesList,
            queryString,
            queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
              ? stateLogin.cdtoken
              : stateLogin.token,
            constructUrlPoint,
            data.pollingAction
          );
        }
        if (protectList.status !== constant.HTTP_STATUS.OK) {
          protectList.context = constant.API_RESPONSE_CONTEXT.SOURCES_ERROR;
          yield put(addAPIresponse(protectList));
        } else {
          const sharedFolderPaginationResponse = yield select(
            getSharedFolderPagination
          );
          let isSharedFolder =
            data?.payload?.filter ===
            constant.SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER;
          let pageSize = isSharedFolder
            ? _.get(sharedFolderPaginationResponse, "page_size", 25)
            : Number(protectList.pagination.page_size);
          yield put(updateGridCurrentPageSize(pageSize));
        }
        if (callAPISource) {
          yield put(showSourcesList(protectList, filterType));
          yield put(showSourcesLocalList(protectList, filterType));
        } else {
          yield put(showSourcesLocalList(protectList, filterType));
        }

        yield put(hideLoading());
        yield put(fromNavigationApi(false));

        if (fromPolicySources && selectedPolicyType) {
          yield put(
            stopGridLoaderById(constant.DATAGRID_IDS["sources_policy_grid"])
          );
        } else {
          yield put(
            stopGridLoaderById(constant.DATAGRID_IDS["sources_datagrid"])
          );
        }
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD:
      case constant.DESTINATION_TYPE.CLOUD_DEDUPE_STORE:
      case constant.DESTINATION_TYPE.CLOUD_DIRECT_VOLUME:
        yield put(startLoader());
        if (constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD) {
          yield put(
            startGridLoaderById(constant.DATAGRID_IDS["destination_datagrid"])
          );
        }
        makeQueryString = util.getQueryStringByType(
          makeQueryString,
          navigation,
          DestinationAttributes.DESTINATION_PROP_TYPE
        );
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          DestinationAttributes.DESTINATION_PROP_NAME
        );
        if (queryString !== "") {
          queryString += `&pagination=false`;
        } else {
          queryString = `pagination=false`;
        }
        protectList = yield call(
          protectApi.fetchDestinationsList,
          queryString,
          stateLogin.token,
          data.pollingAction
        );
        if (protectList.status !== constant.HTTP_STATUS.OK) {
          protectList.context = constant.API_RESPONSE_CONTEXT.DESTINATIONS;
          yield put(addAPIresponse(protectList));
        }
        yield put(showDestinationList(protectList));
        yield put(getProtectListCount());
        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        if (constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD) {
          yield put(
            stopGridLoaderById(constant.DATAGRID_IDS["destination_datagrid"])
          );
        }
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.POLICIES:
        // yield put(startLoader());
        // yield put(showLoading());
        yield put(showGridLoader(constant.DATAGRID_IDS["policy_grid"]));
        const getProtectReducer = state => state.protectReducer;
        const protectReducer = yield select(getProtectReducer);
        const getReducer = state => state.reducer;
        const reducer = yield select(getReducer);
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          PoliciesAttributes.POLICIES_PROP_NAME
        );

        //remove pagination
        queryString = queryString.replace(
          "page=1&page_size=25",
          "pagination=false"
        );
        let callAPI = true;
        let expectedItemsp = reducer.menu
          .find(ele => ele.key === "Protect")
          .subMenu.find(ele => ele.key === navigation).amount;
        let reRunSearchP =
          data.type === "ADVANCED_SEARCH_TRIGGERED" &&
          data.payload.clear === undefined &&
          protectReducer.data.data.length < expectedItemsp;
        let clearP =
          data.type === "ADVANCED_SEARCH_TRIGGERED" &&
          data.payload.clear === true &&
          data.payload.advancedSearchOptions.length === 0;
        //local sort and filter
        if (
          protectReducer.data.data.length > 0 &&
          data.type === "ADVANCED_SEARCH_TRIGGERED" &&
          !reRunSearchP
        ) {
          //filtering
          let filtered = [...protectReducer.data.data];
          if (queryString.includes("policy_name=")) {
            let sO = queryString.split("policy_name=")[1].split("&")[0];
            filtered = [
              ...filtered.filter(ele => {
                return _.get(ele, "policy_name").match(sO) !== null &&
                  _.get(ele, "policy_name").match(sO).length > 0
                  ? true
                  : false;
              })
            ];
          }
          if (
            !clearP &&
            _.get(data, "payload.advancedSearchOptions.length") > 0
          ) {
            callAPI = false;
            if (queryString.includes("status=")) {
              let sO = queryString
                .split("status=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.includes(_.get(ele, "policy_status"))
                )
              ];
            }
            if (queryString.includes("group_id=")) {
              let sO = queryString
                .split("group_id=")[1]
                .split("&")[0]
                .split("%7C");
              filtered = [
                ...filtered.filter(ele =>
                  sO.some(
                    e =>
                      !_.get(ele, "source_group.original", []).findIndex(
                        obj => obj.group_id === e
                      )
                  )
                )
              ];
            }
          }

          //sorting section
          if (
            queryString.split("&sort=").length > 1 &&
            protectReducer.data.data.length > 1
          ) {
            let sortv = queryString.split("&sort=")[1];
            if (sortv.includes("-")) {
              filtered.sort((a, b) => (a[sortv] < b[sortv] ? 1 : -1));
            } else {
              filtered.sort((a, b) => (a[sortv] > b[sortv] ? 1 : -1));
            }
          }
          protectList = {
            data: filtered,
            errors: protectReducer.data.errors,
            pagination: protectReducer.data.pagination,
            status: 200
          };
        } else {
          protectList = yield call(
            protectApi.fetchPoliciesSourceResult,
            queryString,
            stateLogin.token,
            data.pollingAction
          );
        }
        if (protectList.status !== constant.HTTP_STATUS.OK) {
          protectList.context = constant.API_RESPONSE_CONTEXT.POLICIES;
          yield put(addAPIresponse(protectList));
        }

        if (callAPI) {
          yield put(showPoliciesList(protectList));
          yield put(showPoliciesLocalList(protectList));
        } else {
          yield put(showPoliciesLocalList(protectList));
        }
        yield put(hideLoading());

        yield put(fromNavigationApi(false));
        yield put(stopLoader());
        yield put(stopGridLoaderById(constant.DATAGRID_IDS["policy_grid"]));
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS:
        yield* receiveCustomerAccountsList(
          data,
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption
        );
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.RECOVERY_TARGETS:
        yield put(startLoader());
        yield put(showLoading());
        // yield delay(2000);
        // makeQueryString = util.getQueryStringByType(
        //   makeQueryString,
        //   navigation,
        //   SourceConstants.SourceAttributes.SOURCE_PROP_TYPE
        // );
        queryString = util.getQueryStringBySearchNameText(
          makeQueryString,
          stateReducersearchText,
          stateReducersearchOption,
          SourceConstants.SourceAttributes.SOURCE_PROP_NAME
        );

        if (targetQueryString !== "") {
          updatedQueryString =
            queryString !== ""
              ? `${queryString}&${targetQueryString}`
              : `${targetQueryString}`;
        } else {
          updatedQueryString = queryString;
        }
        if (data.payload.advancedSearchClicked) {
          yield put(submitAdvancedSearch());
          yield put(showHideSaveSearchNClearAll(false));
        }
        if (
          !data.payload.advancedSearchOptions &&
          Object.keys(stateReducer.selectedSavedSearch).length > 0
        ) {
          let selectedQuery = util.getQueryStringBySavedSearch(
            stateReducer.selectedSavedSearch,
            updatedQueryString
          );
          updatedQueryString = `${updatedQueryString}&${selectedQuery}`;
        }
        yield put(
          startGridLoaderById(constant.DATAGRID_IDS["recovery_target_grid"])
        );
        protectList = yield call(
          protectApi.getRecoveryTargetsAPI,
          recoverTargetSourceId,
          updatedQueryString,
          stateLogin.token,
          data.pollingAction
        ); //getQueryString(navigation, makeQueryString)
        if (protectList.status !== constant.HTTP_STATUS.OK) {
          protectList.context = constant.API_RESPONSE_CONTEXT.SOURCES;
          yield put(addAPIresponse(protectList));
        } else {
          yield put(updateRecoveryTargetsData(protectList));
        }
        yield put(hideLoading());
        yield put(stopLoader());
        yield put(
          stopGridLoaderById(constant.DATAGRID_IDS["recovery_target_grid"])
        );
        break;

      case CD_RECOVERY_POINTS_COLUMN_TYPE.RECOVERY_POINT_CV:
      case CD_RECOVERY_POINTS_COLUMN_TYPE.RECOVERY_POINT_SOURCES_CV:
        yield put(startLoader());
        yield put(showLoading());
        if (navigation !== CD_RECOVERY_POINTS_COLUMN_TYPE.RECOVERY_POINT_CV) {
          data.payload.fromType = constant.SECONDARY_NAVIGATION_TYPE.SOURCES;
          data.payload.source_id = data.payload.resourceId;
          data.payload.filterTypeRP = "cloud_direct";
        } else {
          data.payload.fromType =
            constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS;
          data.payload.destination_id = data.payload.resourceId;
          data.payload.filterTypeRP = "cloud_hybrid";
        }
        yield call(
          getCDSourcesDestinationsRecoveryPointsList,
          data,
          data.pollingAction
        );

        yield put(hideLoading());
        yield put(stopLoader());
        break;
      case constant.SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS:
        makeQueryString = `pagination=false`;
        const getOracleHostsState = state => state.protectReducer.oracleSource;
        const oracleHostsState = yield select(getOracleHostsState);
        const oracleHostsPagination =
          oracleHostsState && oracleHostsState.pagination;
        yield put(
          startGridLoaderById(constant.DATAGRID_IDS["oracle_hosts_grid"])
        );
        yield put(setLoading(Loader.ORACLE_DB_SOURCE_OPTIONS));
        if (
          oracleHostsPagination?.curr_page &&
          oracleHostsPagination?.page_size
        ) {
          makeQueryString = `page=${oracleHostsPagination.curr_page}&page_size=${oracleHostsPagination.page_size}`;
        }
        if (data?.payload?.gridCurrentPage && data?.payload?.gridPageSize) {
          makeQueryString = `page=${data.payload.gridCurrentPage}&page_size=${data.payload.gridPageSize}`;
        }
        if (stateLogin.organization_id) {
          if (makeQueryString != "") {
            makeQueryString += `&`;
          }
          makeQueryString += `organization_id=${stateLogin.organization_id}`;
        }
        queryString = util.getQueryStringByType(
          makeQueryString,
          navigation,
          SourceConstants.SourceAttributes.SOURCE_PROP_TYPE
        );
        let apiResponse = yield call(
          protectApi.fetchSourcesList,
          queryString,
          queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
            ? stateLogin.cdtoken
            : stateLogin.token,
          constructUrlPoint,
          data.pollingAction
        );
        if (apiResponse.status === constant.HTTP_STATUS.OK) {
          yield put(resetLoading(Loader.ORACLE_DB_SOURCE_OPTIONS));
          yield put(showOracleSources(apiResponse, filterType));
          yield put(
            stopGridLoaderById(constant.DATAGRID_IDS["oracle_hosts_grid"])
          );
        } else {
          yield put(resetLoading(Loader.ORACLE_DB_SOURCE_OPTIONS));
          yield put(protectAction.clearOracleSources());
          yield put(
            stopGridLoaderById(constant.DATAGRID_IDS["oracle_hosts_grid"])
          );
        }
      default:
        break;
    }
    if (
      navigation === constant.SOURCE_TYPE_WINDOWS_ORACLE_HOST ||
      navigation === constant.SOURCE_TYPE_LINUX_ORACLE_HOST
    ) {
      makeQueryString = `pagination=false`;
      if (data?.payload?.gridCurrentPage && data?.payload?.gridPageSize) {
        makeQueryString = `page=${data.payload.gridCurrentPage}&page_size=${data.payload.gridPageSize}`;
      }
      if (stateLogin.organization_id) {
        if (makeQueryString != "") {
          makeQueryString += `&`;
        }
        makeQueryString += `organization_id=${stateLogin.organization_id}`;
      }
      queryString = util.getQueryStringByType(
        makeQueryString,
        navigation,
        SourceConstants.SourceAttributes.SOURCE_PROP_TYPE
      );
      let apiResponse = yield call(
        protectApi.fetchSourcesList,
        queryString,
        queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
          ? stateLogin.cdtoken
          : stateLogin.token,
        constructUrlPoint,
        data.pollingAction
      );
      if (apiResponse.status === constant.HTTP_STATUS.OK) {
        yield put(showOracleSources(apiResponse, filterType));
      }
    }
  } catch (e) {
    yield put(hideLoading());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["recovery_target_grid"])
    );
    //    yield put(showSourcesList(e));
  } finally {
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
    yield put(hideLoading());
    //yield put(stopLoader());
  }
}

export function* receiveCustomerAccountsList(
  data,
  makeQueryString,
  stateReducersearchText,
  stateReducersearchOption
) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(startLoader());
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["customer_account_grid"])
    );
    let queryString = util.getQueryStringBySearchNameText(
      makeQueryString,
      stateReducersearchText,
      stateReducersearchOption,
      constant.MOCK_API_KEYS.CUSTOMER_ACCOUNTS
    );
    let staticCustomerAccountList = yield call(
      protectApi.fetchCustomerAccountsResult,
      `${queryString}&content=static`,
      stateLogin.token,
      stateLogin.organization_id,
      data.pollingAction
    );
    let suspendedCustomerAccountList = yield call(
      protectApi.fetchSuspendedCustomerAccountsResult,
      stateLogin.token,
      stateLogin.organization_id,
      data.pollingAction
    );

    if (suspendedCustomerAccountList.status !== constant.HTTP_STATUS.OK) {
      //TODO fix the apiContext
      suspendedCustomerAccountList.context =
        constant.API_RESPONSE_CONTEXT.SOURCES;
      yield put(addAPIresponse(suspendedCustomerAccountList));
    }

    if (staticCustomerAccountList.status !== constant.HTTP_STATUS.OK) {
      //TODO fix the apiContext
      staticCustomerAccountList.context = constant.API_RESPONSE_CONTEXT.SOURCES;
      yield put(addAPIresponse(staticCustomerAccountList));
    } else {
      let data = staticCustomerAccountList.data.map(account => {
        account.status = "loading-status";
        account.suspend_status = "loading-status";
        account.source_status_loading = "loading-status";
        account.cloud_direct_usage = "loading-status";
        account.udp_usage = "loading-status";
        return account;
      });
      let response = {
        ...staticCustomerAccountList,
        data: data,
        suspendedCustomerData:
          suspendedCustomerAccountList && suspendedCustomerAccountList.data
            ? suspendedCustomerAccountList.data
            : 0
      };
      yield put(showCustomerAccountsList(response));
      // yield put(updateCustomersToAssign(protectList.data));
    }
    let dynamicCustomerAccountList = yield call(
      protectApi.fetchCustomerAccountsResult,
      `${queryString}&content=dynamic`,
      stateLogin.token,
      stateLogin.organization_id,
      data.pollingAction
    );
    if (dynamicCustomerAccountList.status !== constant.HTTP_STATUS.OK) {
      dynamicCustomerAccountList.context =
        constant.API_RESPONSE_CONTEXT.SOURCES;
      yield put(addAPIresponse(staticCustomerAccountList));
    } else {
      staticCustomerAccountList.data = staticCustomerAccountList.data.map(
        (staticCustomer, index) => {
          return {
            ...staticCustomer,
            ...dynamicCustomerAccountList.data[index],
            suspend_status:
              dynamicCustomerAccountList.data[index]["suspend_status"],
            source_status_loading: false
          };
        }
      );
      staticCustomerAccountList.suspendedCustomerData =
        suspendedCustomerAccountList && suspendedCustomerAccountList.data
          ? suspendedCustomerAccountList.data
          : 0;
      yield put(showCustomerAccountsList(staticCustomerAccountList));
    }
    yield put(hideLoading());
    yield put(fromNavigationApi(false));
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["customer_account_grid"])
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * Retrieve list of VMs based on Hypervisor
 * @param {*} data
 */
export function* receiveVMsList(payload) {
  yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
  try {
    const loginReducer = yield select(getLogin);
    // const stateReducer = yield select(getReducer);
    let queryString = "";
    // const gridCurrentPage = payload.gridCurrentPage
    //   ? payload.gridCurrentPage
    //   : stateReducer.gridCurrentPage;
    // const gridPageSize = payload.gridPageSize
    //   ? payload.gridPageSize
    //   : stateReducer.gridPageSize;
    const organizationId = loginReducer.organization_id;

    if (organizationId) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `organization_id=${organizationId}`;
    }

    // if (gridCurrentPage && gridPageSize) {
    //   if (queryString && queryString.length > 0) {
    //     queryString += `&`;
    //   }
    //   queryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
    // }

    queryString += `&pagination=false`;
    let apiResponse = yield call(
      protectApi.fetchVMsList,
      payload.id,
      queryString,
      loginReducer.token
    );

    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(addSourcesHypervisorConnectSuccess(apiResponse.data));
      yield put(addSourcesSetHypervisorId(payload.id));
    } else {
      yield put(addSourcesHypervisorConnectError(apiResponse.errors || []));
    }
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Add new source
 * @param {*} data
 */
function* getRemotePolicies(action) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());
    const organizationId = stateLogin.organization_id;
    let apiResponse = yield call(
      protectApi.getRemotePolicies,
      stateLogin.token,
      action.payload,
      organizationId
    );
    if (apiResponse.status === 200) {
      yield put(updateRemotePoliciesData(apiResponse.data));
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(hideLoading());
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

function* addNewSource(payload) {
  try {
    let postBody = Object.assign({}, payload.data);
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.addNewSources,
      postBody,
      stateLogin.token,
      payload.id
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put({
        type: actionType.CLEAR_STATE_SELECTED_ROWS
      });
      apiResponse.context = "protect.sources.add.new.source";
      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.SOURCES
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = postBody.vm_ids.length;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(hideLoading());
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

function* getProtectListTotalCounts() {
  try {
    const stateReducerForMenu = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    // const page = 1;
    // const pageSize = 25;

    // sources count
    let sourcesCount = yield call(
      protectApi.getSourcesCounts,
      stateLogin.token,
      `organization_id=${organizationId}`,
      false
    );
    sourcesCount.data = sourcesCount.data.filter(
      source =>
        source.source_type !== constant.SOURCE_TYPE_WINDOWS_ORACLE_HOST &&
        source.source_type !== constant.SOURCE_TYPE_LINUX_ORACLE_HOST
    );

    sourcesCount.type = "Sources";
    const sourceApiCount = { sourcesCount };
    if (sourcesCount.status === constant.HTTP_STATUS.OK && sourcesCount.data) {
      yield put(updateSourceCount(sourcesCount.data));
    }
    // destination count
    let destinationCount = yield call(
      protectApi.getDestinationsCounts,
      stateLogin.token,
      `organization_id=${organizationId}&product_type=udp%7ccloud_direct`,
      false
    );
    destinationCount.type = "Destination";
    const destinationApiCount = { destinationCount };

    // policies count
    let data = yield call(
      protectApi.fetchLightWeightPolicies,
      `pagination=false&organization_id=${organizationId}`,
      stateLogin.token,
      false
    );
    let policiesCount = Object.assign(
      {},
      {
        // policies: data.pagination.total_size
        policies: data && data.data ? data.data.length : 0
      }
    );

    // destination count
    /* let recoveredVMCount = 0;
    let mountedRPSCount = 0;
    let virtualStandbyCount = 0;
    let recoveredVMSCount = yield call(
      protectApi.getRecoveredVMSCount,
      stateLogin.token,
      `organization_id=${organizationId}`
    );
    if (
      recoveredVMSCount.status === constant.HTTP_STATUS.OK &&
      Array.isArray(recoveredVMSCount.data) &&
      recoveredVMSCount.data.length
    ) {
      if (
        recoveredVMSCount.data[0]["recoveredresource_type"] == "recovered_vm"
      ) {
        recoveredVMCount = recoveredVMSCount.data[0].amount || 0;
      } else if (
        recoveredVMSCount.data[0]["recoveredresource_type"] ==
        "mounted_recovery_points"
      ) {
        mountedRPSCount = recoveredVMSCount.data[0].amount || 0;
      } else if (
        recoveredVMSCount.data[0]["recoveredresource_type"] == "virtual_standby"
      ) {
        virtualStandbyCount = recoveredVMSCount.data[0].amount || 0;
      }

      if (recoveredVMSCount.data.length > 2) {
        if (
          recoveredVMSCount.data[0]["recoveredresource_type"] == "recovered_vm"
        ) {
          recoveredVMCount = recoveredVMSCount.data[0].amount || 0;
        } else if (
          recoveredVMSCount.data[1]["recoveredresource_type"] ==
          "mounted_recovery_points"
        ) {
          mountedRPSCount = recoveredVMSCount.data[1].amount || 0;
        } else if (
          recoveredVMSCount.data[2]["recoveredresource_type"] ==
          "virtual_standby"
        ) {
          recoveredVMSCount.data[2]["recoveredresource_type"] =
            "virtual_standbys";
          virtualStandbyCount = recoveredVMSCount.data[2].amount || 0;
        }
      }
    }
    const recoveredVMSApiCount = {
      recovered_vms: recoveredVMCount,
      mounted_recovery_points: mountedRPSCount,
      virtual_standbys: virtualStandbyCount
    }; */

    // Disaster Recovery counts
    let recoveredResourcesCount = yield call(
      protectApi.getRecoveredVMSCount,
      stateLogin.token,
      `organization_id=${organizationId}`,
      false
    );
    recoveredResourcesCount.type = "Disaster Recovery";
    const recoveredResourcesApiCount = { recoveredResourcesCount };

    const totalCount = Object.assign(
      {},
      sourceApiCount,
      destinationApiCount,
      policiesCount,
      recoveredResourcesApiCount
    );
    yield put(
      updateProtectListCount(
        totalCount,
        stateReducerForMenu.menu,
        stateReducerForMenu.primaryNavigation
      )
    );
  } catch (e) {
    window.console.log(e);
    //    yield put(showSourcesList(e));
  }
}

/**
 * To get detail of a particular destination
 * @param action
 */
export function* getSourceDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const stateProtectReducer = yield select(getProtectReducer);
    yield put(startGridLoaderById(constant.DATAGRID_IDS["cd_source_rps_grid"]));
    let details = yield call(
      protectApi.fetchSourceById,
      action.data.id,
      stateLogin.token
    );
    let stateReducergroup = "false";
    if (action.data.group) {
      stateReducergroup = action.data.group;
    } else if (stateProtectReducer) {
      stateReducergroup = stateProtectReducer.recoveryPointsGroup;
    }
    if (details.status !== constant.HTTP_STATUS.OK) {
      details.context = constant.API_RESPONSE_CONTEXT.SOURCES;
      yield put(addAPIresponse(details));
    } else {
      yield put(setResourceName(details.data.source_name));
      if (details && details.data) {
        details.data.id = details.data.source_id;
      }
      yield put(updateSelectedResource(details.data));
      if (
        details.data &&
        details.data.enable_draas &&
        details.data.product_type === "cloud_direct"
      ) {
        yield* getSourceConfiguration();
      }
    }

    yield put(showSourceDetails({ details, recoveryPoints: [] }));
    if (stateReducer.fromNavigationApi === false) {
      const organizationId = stateLogin.organization_id;
      let queryString = `organization_id=${organizationId}&source_id=${action.data.id}&group=${stateReducergroup}`;
      if (
        details.data.product_type !== constant.DESTINATION_TYPE.CLOUD_DIRECT
      ) {
        queryString = "";
      }

      let responseRecoveryPoints = [];
      if (details.data.product_type === "cloud_direct") {
        let recoveryPoints = yield call(
          protectApi.getRecoveryPointsSources,
          queryString,
          details.data.product_type === constant.DESTINATION_TYPE.CLOUD_DIRECT
            ? stateLogin.cdtoken
            : stateLogin.token,
          details.data.product_type,
          action.data.id
        );
        if (recoveryPoints && recoveryPoints.data) {
          responseRecoveryPoints = recoveryPoints.data;
        } else {
          responseRecoveryPoints = recoveryPoints;
        }
        if (responseRecoveryPoints && responseRecoveryPoints.length > 0) {
          yield put(
            showSourceDetails({
              details,
              recoveryPoints: responseRecoveryPoints
            })
          );
        }
      }
      yield put(
        stopGridLoaderById(constant.DATAGRID_IDS["cd_source_rps_grid"])
      );
    }
  } catch (e) {
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["cd_source_rps_grid"]));
    window.console.log(e);
  }
}

/**
 * To get detail of a particular destination
 * @param action
 */
export function* getSelectedSourceDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    let details = yield call(
      protectApi.fetchSourceById,
      action.data.id,
      stateLogin.token
    );
    if (details.status !== constant.HTTP_STATUS.OK) {
      details.context = constant.API_RESPONSE_CONTEXT.SOURCES;
      yield put(addAPIresponse(details));
    }
    yield put(showSelectedSourceDetails({ details }));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get detail of a particular destination
 * @param action
 */
function* getDestinationDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const stateProtectReducer = yield select(getProtectReducer);
    const organizationId = stateLogin.organization_id;
    let stateReducergroup = "false";
    yield put(showLoading());
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["destination_rps_grid"])
    );
    if (action.data.group) {
      stateReducergroup = action.data.group;
    } else if (stateProtectReducer) {
      stateReducergroup = stateProtectReducer.recoveryPointsGroup;
    }
    let details = yield call(
      protectApi.fetchDestinationsById,
      action.data.id,
      stateLogin.token
    );
    yield put(
      showDestinationDetails({ details: details.data, recoveryPoints: [] })
    );
    yield put(setResourceName(details.data.destination_name));
    if (stateReducer.fromNavigationApi === false) {
      let queryString = `organization_id=${organizationId}&destination_id=${action.data.id}&group=${stateReducergroup}`;
      if (
        details.data.destination_type !==
        constant.DESTINATION_TYPE.CLOUD_DIRECT_VOLUME
      ) {
        queryString = "";
      }
      let recoveryPoints = yield call(
        protectApi.getRecoveryPoints,
        queryString,
        action.data.id,
        details.data.destination_type ===
          constant.DESTINATION_TYPE.CLOUD_DIRECT_VOLUME
          ? stateLogin.cdtoken
          : stateLogin.token,
        details.data.destination_type
      );
      if (recoveryPoints.data && recoveryPoints.data.length > 0) {
        yield put(
          showDestinationDetails({
            details: details.data,
            recoveryPoints: recoveryPoints.data
          })
        );
      }
    }
    yield put(hideLoading());
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["destination_rps_grid"])
    );
  } catch (e) {
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["destination_rps_grid"])
    );
    window.console.log(e);
  }
}

/**
 * To get the details of selected Resource
 */
function* getRecoveryPointsDestinationDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    const stateProtectReducer = yield select(getProtectReducer);
    let stateReducergroup = "false";
    if (action.data.group) {
      stateReducergroup = action.data.group;
    } else if (stateProtectReducer) {
      stateReducergroup = stateProtectReducer.recoveryPointsGroup;
    }
    yield put(showLoading());
    let queryString = `organization_id=${organizationId}&destination_id=${action.data.destination_id}&group=${stateReducergroup}`;
    let recoveryPoints = yield call(
      protectApi.getRecoveryPoints,
      queryString,
      action.data.destination_id,
      action.data.filter === constant.DESTINATION_TYPE.CLOUD_DIRECT_VOLUME
        ? stateLogin.cdtoken
        : stateLogin.token,
      action.data.filter
    );
    // yield put(setResourceName(details[0].resource_name));
    yield put(
      showSelectedResourceDetails({ recoveryPoints: recoveryPoints.data })
    );
    yield put(hideLoading());
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * To update status of Recovery Point
 * @param action
 */
function* updateRecoveryStatus(action) {
  try {
    let recoveryPointObj = {};
    // let actionFrom = "";
    const stateLogin = yield select(getLogin);
    const stateProtectReducer = yield select(getProtectReducer);
    const organizationId = stateLogin.organization_id;
    let stateReducergroup = "false";
    if (stateProtectReducer) {
      stateReducergroup = stateProtectReducer.recoveryPointsGroup;
    }
    if (!action.data.data) {
      recoveryPointObj = action.data;
    } else {
      recoveryPointObj = action.data.data;
      // actionFrom = action.data.update;
    }
    let pinUnpinStatus = recoveryPointObj.pinned === true ? "unpin" : "pin";
    let details = yield call(
      protectApi.updateRecoveryPinnedUnpinned,
      organizationId,
      recoveryPointObj.recovery_point_id,
      stateLogin.cdtoken,
      pinUnpinStatus
    );
    if (constant.HTTP_STATUS.SUCCESS === details.status) {
      // if (util.isEmpty(details)) {
      if (stateReducergroup !== "false") {
        yield put({
          type: actionType.UPDATED_RECOVERY_SUBCOMPONENT_DETAILS,
          data: recoveryPointObj
        });
      } else {
        yield put({
          type: actionType.UPDATED_RECOVERY_DETAILS,
          data: recoveryPointObj
        });
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * To update detail of a particular destination
 * @param action
 */
export function* editUpdateDestinationInformation(action) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.INFORMATION_EDIT_CLOUD_VOLUME));
    yield put(showLoading());
    let details = yield call(
      protectApi.updateDestinationInformation,
      action.data,
      action.data.destination_id,
      stateLogin.token,
      { showCommonError: true, testId: "CBfG4t" }
    );
    yield put(hideLoading());
    if (details.status !== constant.HTTP_STATUS.OK) {
      details.context = constant.API_RESPONSE_CONTEXT.DESTINATIONS;
    } else {
      yield put(setResourceName(action.data.destination_name));
      details.contextualAction = {
        type: constant.API_RESPONSE_CONTEXT.UPDATE_DESTINATIONS,
        data: action.data
      };
      if (constant.HTTP_STATUS.OK === details.status) {
        let name =
          details.data && details.data["destination_name"]
            ? details.data["destination_name"]
            : "";
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              `protect.destination.${details.contextualAction.type}.success`,
              "epMGSH"
            ),
            {
              name: name
            }
          )
        );
      }
    }
    yield put({
      type: actionType.UPDATED_DESTINATION_DETAILS,
      data: details.data
    });
    if (constant.HTTP_STATUS.UNAUTHORIZED === details.status) {
      // yield put(
      //   ccToast.addErrorNotification(_.get(details, "errors[0].message", ""))
      // );
    } else {
      yield put(addAPIresponse(details));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.INFORMATION_EDIT_CLOUD_VOLUME));
  }
}

export function* receivePolicyDetails(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let policy = null;

    // getBasicTabDetails // <-- don't think I need
    yield put(fetchAllDestinations());
    if (action.isNew) {
      yield put(fetchEntitlementsProducts(organizationId));
    }

    if (!action.isNew) {
      yield put(fetchPolicy(action.data.id));

      yield take([
        actionType.FETCH_POLICY_SUCCESS,
        actionType.FETCH_POLICY_FAILURE
      ]);

      policy = yield select(getPolicy);

      if (policy.policy_type === "cloud_direct_hypervisor") {
        yield put(fetchHypervisor(policy.hypervisor_id));

        yield take([
          actionType.FETCH_HYPERVISOR_SUCCESS,
          actionType.FETCH_HYPERVISOR_FAILURE
        ]);
      }

      // TODO: Error Checking Here

      yield put(updateSelectedPolicyType(policy.policy_type));
      yield put(setResourceName(policy.policy_name));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * To remove source by source id
 * @param action
 */
export function* removeSource(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());

    let apiResponse = {};

    if (action.isHardDelete) {
      apiResponse = yield call(
        protectApi.hardRemoveSourceById,
        action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
        loginReducer.token,
        {},
        { showCommonError: true, testId: "bsAGnk" }
      );
    } else {
      apiResponse = yield call(
        protectApi.removeSourceById,
        action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
        loginReducer.token,
        {},
        { showCommonError: true, testId: "ygJm3K" }
      );
    }

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const isOracleHost = [
        constant.SOURCE_TYPE_WINDOWS_ORACLE_HOST,
        constant.SOURCE_TYPE_LINUX_ORACLE_HOST
      ].includes(action?.data?.source_type);
      if (action.isHardDelete) {
        if (!isOracleHost) {
          yield put(
            updateDeleteSourceInList(
              action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID]
            )
          );
          yield put(
            ccDataGridActions.deleteSourceInCCDataGrid(
              stateReducer.secondaryNavigation,
              action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID]
            )
          );
          const mainType = "Protect";
          const sendTypesArray = _.uniq([
            stateReducer.secondaryNavigation,
            constant.SECONDARY_NAVIGATION_TYPE.SOURCES
          ]);
          const tempUpdateMenuKeys = {
            types: sendTypesArray,
            action: "remove"
          };
          const payloadLength = 1;
          yield put(
            updateMenuCountsOnAddRemoveActions({
              type: mainType,
              updateMenuKeys: tempUpdateMenuKeys,
              count: payloadLength
            })
          );
          yield put(getProtectListCount());
        } else {
          yield put(
            updateDeleteOracleHostsInList(
              action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID]
            )
          );
          const mainType = "Configure";
          const sendTypesArray = _.uniq([
            stateReducer.secondaryNavigation,
            constant.SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS
          ]);
          const tempUpdateMenuKeys = {
            types: sendTypesArray,
            action: "remove"
          };
          const payloadLength = 1;
          yield put(
            updateMenuCountsOnAddRemoveActions({
              type: mainType,
              updateMenuKeys: tempUpdateMenuKeys,
              count: payloadLength
            })
          );
          yield put(getConfigureListCount());
        }
        // yield put(getSourcesList());
        // if (stateReducer.secondaryNavigation === "sources") {
        //   yield* fetchData({
        //     ccDataGridId: stateReducer.secondaryNavigation
        //   });
        // }
        // yield* fetchData({
        //   ccDataGridId: stateReducer.secondaryNavigation
        // });
      } else {
        let details = yield call(
          protectApi.fetchSourceById,
          action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
          loginReducer.token
        );
        let source = details.data;
        if (!isOracleHost) {
          yield put(
            updateResourceInList(
              source,
              SourceConstants.SourceAttributes.SOURCE_PROP_ID
            )
          );
          yield put(
            ccDataGridActions.UpdateSourceInCCGrid(
              stateReducer.secondaryNavigation,
              source,
              SourceConstants.SourceAttributes.SOURCE_PROP_ID
            )
          );
          yield put(getProtectListCount());
        } else {
          yield put(
            updateOracleHostsInList(
              source,
              SourceConstants.SourceAttributes.SOURCE_PROP_ID
            )
          );
          yield put(getConfigureListCount());
        }

        // to remove source from oracleSource
        // const protectReducer = yield select(getProtectReducer);
        // console.log(action.data);
        // if (
        //   [
        //     constant.SOURCE_TYPE_WINDOWS_ORACLE_HOST,
        //     constant.SOURCE_TYPE_LINUX_ORACLE_HOST
        //   ].includes(action?.data?.source_type)
        // ) {
        //   let oracleApiData = protectReducer.oracleSource;
        //   console.log("oraclesourcetype", oracleApiData);
        //   let oracleHost = oracleApiData?.data ? oracleApiData.data : [];
        //   if (oracleHost.length > 0) {
        //     let filteredData = [
        //       ...oracleHost.filter(e => e.source_id !== action.data.source_id)
        //     ];
        //     oracleApiData.data = filteredData;
        //     yield put(
        //       showOracleSources(oracleApiData, stateReducer.secondaryNavigation)
        //     );
        //   }
        // }

        if (
          ![
            constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
            constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
            constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
            constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
          ].includes(stateReducer.secondaryNavigation)
        ) {
          yield put(getSourcesList());
          // yield put(ccDataGridActions.fetchData(stateReducer.secondaryNavigation,init,polling))
        }
      }
      const name =
        action.data[SourceConstants.SourceAttributes.SOURCE_PROP_NAME];
      const toastData = {
        messageId: action.isHardDelete
          ? "protect.sources.remove.success"
          : "protect.sources.delete.success",
        testId: action.isHardDelete ? "KJKmER" : "JczsBL"
      };
      yield put(ccToast.addSuccessNotification(toastData, { name }));

      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    } else if (
      apiResponse.errors &&
      constant.SHOW_FORCE_DELETE_SOURCE_ERROR_CODES.includes(
        apiResponse.errors[0].code
      ) &&
      !action.isHardDelete
    ) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            apiResponse.errors[0].message,
            `H2JP4H_${apiResponse?.status}`
          )
        )
      );
      yield put(showDialog(Dialog.FORCE_DELETE_SOURCE));
    }
    yield put(hideLoading());
    // apiResponse.context = constant.API_RESPONSE_CONTEXT.SOURCES;

    apiResponse.contextualAction = {
      data: action.data,
      type: action.action
    };
    yield put(addAPIresponse(apiResponse));
    // yield put(getCurrentActionCalled());
  } catch (e) {
    window.console.log(e);
  }
}

export function* forceDeleteSource({ id }) {
  try {
    let apiResponse;
    const login = yield select(getLogin);
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    if (id && id?.length === 1) {
      apiResponse = yield call(
        protectApi.forceDeleteSource,
        login.token,
        id,
        true /* force_delete */
      );
    }

    if (apiResponse && apiResponse.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: "protect.sources.force.delete.success",
        testId: "1xqgVh"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      let details = yield call(
        protectApi.fetchSourceById,
        id[0],
        loginReducer.token
      );
      let source = details.data;
      if (
        stateReducer.secondaryNavigation !==
        constant.SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS
      ) {
        yield put(
          updateResourceInList(
            source,
            SourceConstants.SourceAttributes.SOURCE_PROP_ID
          )
        );
        yield put(
          ccDataGridActions.UpdateSourceInCCGrid(
            stateReducer.secondaryNavigation,
            source,
            SourceConstants.SourceAttributes.SOURCE_PROP_ID
          )
        );
        yield put(getProtectListCount());
      } else {
        yield put(
          updateOracleHostsInList(
            source,
            SourceConstants.SourceAttributes.SOURCE_PROP_ID
          )
        );
        yield put(getConfigureListCount());
      }
      if (
        ![
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        yield put(getSourcesList());
      }
    }
    if (apiResponse && apiResponse.errors.length > 0) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            apiResponse.errors[0].message,
            `I9I8R4_${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(clearStateSelectedRows());
    yield put(stopLoader());
    yield put(hideDialog(Dialog.FORCE_DELETE_SOURCE));
  }
}

/***
 * To remove policy by source id
 * @param action
 */
export function* removePolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.deletePolicyDetailsById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      loginReducer.token,
      { showCommonError: true, testId: "tB7wae" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      // yield put(
      //   updateDeletePolicyInList(
      //     action.data[PoliciesAttributes.POLICIES_PROP_ID]
      //   )
      // );
      yield put(
        ccDataGridActions.deletePolicyInCCDataGrid(
          stateReducer.secondaryNavigation,
          action.data[PoliciesAttributes.POLICIES_PROP_ID]
        )
      );
      // create new function action to ccDataGrid - < no
      let mainType = "Protect";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.POLICIES];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      const name = _.get(action, "data.policy_name", "");
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(constant.API_RESPONSE_CONTEXT.POLICY_DELETE, "vDcFwf"),
          { name }
        )
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* disablePolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.disablePolicyDetailsById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      {},
      loginReducer.token,
      { showCommonError: true, testId: "Ioekfz" }
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(hideLoading());
      const name = action.data[PoliciesAttributes.POLICIES_PROP_NAME];
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.policy.disable.success", "ac8Oay"),
          {
            name: name
          }
        )
      );
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.POLICY_DISABLE;
      // apiResponse.contextualAction = {
      //   data: action.data,
      //   type: action.action
      // };
      // yield put(getCurrentActionCalled());
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* enablePolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.enablePolicyDetailsById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      {},
      loginReducer.token,
      { showCommonError: true, testId: "6cpmHB" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(hideLoading());
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.POLICY_ENABLE;
      // apiResponse.contextualAction = {
      //   data: action.data,
      //   type: action.action
      // };
      const name = action.data[PoliciesAttributes.POLICIES_PROP_NAME];
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.policy.enable.success", "92tvEL"),
          {
            name: name
          }
        )
      );
      // yield put(getCurrentActionCalled());
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* deployPolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.deployPolicyById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      loginReducer.token,
      { showCommonError: true, testId: "msIAA6" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(hideLoading());
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.POLICY_DEPLOY;
      // apiResponse.contextualAction = {
      //   data: action.data,
      //   type: action.action
      // };
      const name = action.data[PoliciesAttributes.POLICIES_PROP_NAME];
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.sources.deploy.success", "E8GIHf"),
          {
            name: name
          }
        )
      );

      // yield put(getCurrentActionCalled());
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshPolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.refreshPolicyDetailsById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      loginReducer.token,
      { showCommonError: true, testId: "nzyWcM" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(hideLoading());
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.POLICY_REFRESH;
      // apiResponse.contextualAction = {
      //   data: action.data,
      //   type: action.action
      // };
      const name = action.data[PoliciesAttributes.POLICIES_PROP_NAME];
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.policy.refresh.success", "j126BR"),
          {
            name: name
          }
        )
      );
      // yield put(getCurrentActionCalled());
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* backupPolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.backupPolicyById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      "",
      loginReducer.token,
      { showCommonError: true, testId: "nXgUZ8" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.POLICY_BACKUP_SUCCESS,
            "Zqk0Pq"
          )
        )
      );
      // yield put(getCurrentActionCalled());
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* replicatePolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.replicatePolicyById,
      action.data[PoliciesAttributes.POLICIES_PROP_ID],
      "",
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("alerts.details.policy.replication_success", "RzXOzV")
        )
      );
      // yield put(getCurrentActionCalled());
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * To remove source by source id
 * @param action
 */
export function* handleCreateSourceGroup(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    action.data.organization_id = organizationId;
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.createNewSourceGroup,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "wxtajX" }
    );
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      apiResponse.context = "sourceGroup.created";
      let sourceGroup = _.cloneDeep(apiResponse.data);
      sourceGroup[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        sourceGroup[SourceGroupConstants.Attributes.SOURCE_GROUP_PROP_ID];
      yield put(addSourceGroupList(sourceGroup, action.checkPagination));
      // const filter = "source_groups";
      //   yield put(getCurrentActionCalled({ filter }));
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.SOURCE_GROUP });
      let mainType = "Configure";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      let name = apiResponse.data && apiResponse.data.group_name;
      const toastData = {
        messageId: "configure.sourceGroup.created",
        testId: "339XOV"
      };
      yield put(
        ccToast.addSuccessNotification(toastData, {
          name
        })
      );
    } else if (apiResponse.status >= 400) {
      apiResponse.context =
        constant.API_RESPONSE_CONTEXT.SOURCE_GROUP_CONFIG_ERROR;
      yield put(addAPIresponse(apiResponse));
    }

    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To remove a destination
 */
export function* deleteDestination(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.deleteDestination,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "a4TDPV" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(deletedDestinationList(action.data));
      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      let name = action.name;
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.cloud.volume.remove.success", "Wu6Z3P"),
          {
            name: name
          }
        )
      );
      yield put(clearContextualAction());
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    } else if (constant.HTTP_STATUS.UNAUTHORIZED === apiResponse.status) {
      // yield put(
      //   ccToast.addErrorNotification(
      //     _.get(apiResponse, "errors[0].message", "")
      //   )
      // );
    } else {
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Upgrade Agent by source id
 * @param action
 */
export function* upgradeAgentBySourceId(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.upgradeAgentBySourceId,
      action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
      loginReducer.token,
      { showCommonError: true, testId: "sPRZlp" }
    );
    yield put(hideLoading());
    yield put(hideDialog(Dialog.UPGRADE_CLOUD_DIRECT));
    apiResponse.contextualAction = {
      data: action.data,
      type: action.action ? action.action : action.data.action
    };
    // apiResponse.context = constant.API_RESPONSE_CONTEXT.SOURCES;
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const name =
        apiResponse.contextualAction.data[
          SourceConstants.SourceAttributes.SOURCE_PROP_NAME
        ];
      const toastData = {
        messageId: `protect.sources.${apiResponse.contextualAction.type}.success`,
        testId: "4ycp3a"
      };

      yield put(ccToast.addSuccessNotification(toastData, { name }));
    }
    if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
      });
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Toggle backup now by source ID (startbackup, stopbackup)
 * @param action
 */
export function* handleToggleBackupBySourceId(action) {
  try {
    const stateReducer = yield select(getReducer);
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let messageID = "protect.sources.startbackup.success";
    let backupAction = action.backupAction;
    if (action.backupAction === SourceConstants.CONTEXTUAL_ACTION.STOP_BACKUP) {
      backupAction = SourceConstants.CONTEXTUAL_ACTION.CANCEL_BACKUP;
      messageID = "protect.sources.cancelbackup.success";
    }
    if (action.backupAction === SourceConstants.CONTEXTUAL_ACTION.CANCEL_BACKUP)
      messageID = "protect.sources.cancelbackup.success";

    let apiResponse = yield call(
      protectApi.toggleBackupBySourceId,
      action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
      backupAction,
      loginReducer.token,
      { showCommonError: true, testId: "I2WQ87" }
    );

    apiResponse.contextualAction = {
      data: action.data,
      type: backupAction
    };
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const name =
        apiResponse.contextualAction.data[
          SourceConstants.SourceAttributes.SOURCE_PROP_NAME
        ];
      const toastData = {
        messageId: messageID,
        testId: "kaC5YS"
      };
      yield put(ccToast.addSuccessNotification(toastData, { name }));

      let details = yield call(
        protectApi.fetchSourceById,
        action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
        loginReducer.token
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      // if (action.gridInfo === constant.CCDATAGRID_ID.SOURCES) {
      //   yield* fetchData({
      //     ccDataGridId: constant.CCDATAGRID_ID.SOURCES
      //   });
      // }
      let source = details.data;
      source[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        source[SourceConstants.SourceAttributes.SOURCE_PROP_ID];
      yield put(updateResourceInList(source));
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source
        )
      );
    }

    yield put(hideLoading());
    // apiResponse.context = constant.API_RESPONSE_CONTEXT.SOURCES;

    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Toggle backup now by source ID (startbackup, stopbackup)
 * @param action
 */
export function* handleToggleBackupBySourceIdWithPayload(action) {
  try {
    const stateReducer = yield select(getReducer);
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let backupAction = action.backupAction;
    if (action.backupAction === SourceConstants.CONTEXTUAL_ACTION.STOP_BACKUP) {
      backupAction = SourceConstants.CONTEXTUAL_ACTION.CANCEL_BACKUP;
    }
    let backupOptionsValues = yield select(getFormValues("backupOptions"));
    let payload = {
      backup_name: _.get(backupOptionsValues, "backup_name"),
      backup_type: _.get(backupOptionsValues, "backup_type"),
      schedule_type: _.get(backupOptionsValues, "schedule_type")
    };
    if (!payload?.schedule_type) {
      delete payload.schedule_type;
    }
    let apiResponse = yield call(
      protectApi.toggleBackupBySourceIdWithPayload,
      action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
      backupAction,
      payload,
      loginReducer.token,
      { showCommonError: true, testId: "iqHXJ0" }
    );

    apiResponse.contextualAction = {
      data: action.data,
      type: backupAction
    };
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const name =
        apiResponse.contextualAction.data[
          SourceConstants.SourceAttributes.SOURCE_PROP_NAME
        ];
      const toastData = {
        messageId: "protect.sources.startbackup.success",
        testId: "IeaZpQ"
      };
      yield put(ccToast.addSuccessNotification(toastData, { name }));
      let details = yield call(
        protectApi.fetchSourceById,
        action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
        loginReducer.token
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      let source = details.data;
      source[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        source[SourceConstants.SourceAttributes.SOURCE_PROP_ID];
      yield put(updateResourceInList(source));
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source
        )
      );
    }
    yield put(hideLoading());
    // apiResponse.context = constant.API_RESPONSE_CONTEXT.SOURCES;

    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Cancel Recovery by source ID
 * @param action
 */
export function* handleCancelRecoveryBySourceId(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());

    //  if isImpersonationView, get organizationId of Customer or get parent organizationId
    const organizationId = loginReducer.organization_id;

    let apiResponse = yield call(
      protectApi.cancelRecoveryBySourceId,
      action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
      `organization_id=${organizationId}`,
      loginReducer.token,
      { showCommonError: true, testId: "zQjaQe" }
    );

    apiResponse.contextualAction = {
      data: action.data,
      type: "cancelrecovery"
    };
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const name =
        apiResponse.contextualAction.data[
          SourceConstants.SourceAttributes.SOURCE_PROP_NAME
        ];
      const toastData = {
        messageId: "protect.sources.cancelrecovery.success",
        testId: "yFneRu"
      };
      yield put(ccToast.addSuccessNotification(toastData, { name }));
      let details = yield call(
        protectApi.fetchSourceById,
        action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
        loginReducer.token
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      let source = details.data;
      source[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        source[SourceConstants.SourceAttributes.SOURCE_PROP_ID];
      yield put(updateResourceInList(source));
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source
        )
      );
    }
    yield put(hideLoading());
    // apiResponse.context = constant.API_RESPONSE_CONTEXT.SOURCES;

    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get source group list
 * @param action
 */
export function* handleGetSourceGroupList(payload) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const organizationId = loginReducer.organization_id;
    const navigation = constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS;
    const searchKey = constant.SEARCH_TEXT_MAPPING[navigation];
    const reducerData = {
      searchText: stateReducer.searchText,
      searchKey: searchKey ? searchKey : "",
      sortedColumns: stateReducer.sortedColumns,
      gridPageSize: stateReducer.gridPageSize,
      gridCurrentPage: stateReducer.gridCurrentPage
    };
    let query = util.prepareGridCriteria(payload, reducerData);
    if (query.length) {
      query += "&";
    }
    query += `organization_id=${organizationId}`;
    yield put(showLoading());
    let sourceGroups = yield call(
      protectApi.fetchSourcesGroups,
      query,
      loginReducer.token
    );
    if (sourceGroups.status === constant.HTTP_STATUS.OK) {
      yield put(updateSourceGroupList(sourceGroups));
      yield put(fromNavigationApi(false));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To assign and unassign source group
 * @param action
 */
export function* handleAssignUnassignSourceGroup(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let body = {
      sources: [
        {
          source_id: action.data.id
        }
      ]
    };

    if (action.assign.length > 0) {
      for (let i = 0; i < action.assign.length; i++) {
        yield call(
          protectApi.assignSourcesGroups,
          action.assign[i].group_id,
          body,
          loginReducer.token
        );
      }
    }
    if (action.unassign.length > 0) {
      for (let i = 0; i < action.unassign.length; i++) {
        yield call(
          protectApi.unassignSourcesGroups,
          action.unassign[i].group_id,
          body,
          loginReducer.token
        );
      }
    }

    //To update source object
    let details = yield call(
      protectApi.fetchSourceById,
      action.data[SourceConstants.SourceAttributes.SOURCE_PROP_ID],
      loginReducer.token
    );
    let source = details.data;
    source[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
      source[SourceConstants.SourceAttributes.SOURCE_PROP_ID];
    yield put(updateResourceInList(source));
    yield put(
      ccDataGridActions.UpdateSourceInCCGrid(
        stateReducer.secondaryNavigation,
        source
      )
    );
    if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
      });
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* receivePolicyDetailsDropdownAndProtectionType() {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    // yield put(showLoading());
    let basic = yield call(
      protectApi.getBasicTabDetails,
      loginReducer.token,
      `organization_id=${organizationId}`
    );
    let dropdownOptions = yield call(protectApi.getDestinationsDropdown);
    yield put(
      updatePolicyPageDropdownsAndProtectionCard({
        basic: basic.data,
        dropdownOptions
      })
    );
    // yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* updatePolicyDetails(action) {
  try {
    const loginReducer = yield select(getLogin);
    let policydetails = {};

    if (action.data.policy_id && action.data.policy_id !== "temp_create_id") {
      yield put(showLoading());
      policydetails = yield call(
        protectApi.updatePolicyDetailsById,
        action.data.policy_id,
        action.data,
        loginReducer.token
      );
      if (constant.HTTP_STATUS.OK === policydetails.status) {
        yield put(ccToast.addInfoNotification("policy-has-changed"));
        // policydetails = convertFromAPIData(resp.data);
        yield put(redirectToPolicyGrid());
      } else {
        yield put(addAPIresponse(policydetails));
      }
      yield put(hideLoading());
    } else {
      let policy = action.data;
      policy.policy_id = null;
      const organizationId = loginReducer.organization_id;
      policy.organization_id = organizationId;
      yield put(showLoading());
      let resp = yield call(
        protectApi.createPolicyDetails,
        action.data,
        loginReducer.token
      );
      yield put(hideLoading());
      if (constant.HTTP_STATUS.CREATED === resp.status) {
        // policydetails = convertFromAPIData(resp.data);
        yield put(redirectToPolicyGrid());
        let mainType = "Protect";
        let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.POLICIES];
        let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
        let payloadLength = 1;
        yield put(
          updateMenuCountsOnAddRemoveActions({
            type: mainType,
            updateMenuKeys: tempUpdateMenuKeys,
            count: payloadLength
          })
        );
      } else {
        yield put(addAPIresponse(resp));
      }
    }
    // let basic = yield call(protectApi.getBasicTabDetails);
    // let dropdownOptions = yield call(protectApi.getDestinationsDropdown);
    // yield put(showPolicyDetails({ policydetails, basic, dropdownOptions }));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getTransactionDetails(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(startLoader());
    let transactionDetails = yield call(
      protectApi.transactionDetails,
      action.data.data,
      action.data.id,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === transactionDetails.status) {
      yield put(transactionDetailsSucceess(transactionDetails.data));
    } else {
      yield put(transactionDetailsSucceess([]));
    }
    yield put(stopLoader());
  } catch (e) {
    yield put(transactionDetailsSucceess([]));
    window.console.log(e);
    yield put(stopLoader());
  }
}

/**
 * Fetch the list of datacenters
 */
export function* getDataCentersList() {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let dataCenterList = yield call(
      protectApi.fetchDataCentersList,
      loginReducer.token,
      organizationId ? `organization_id=${organizationId}` : ""
    );
    yield put(showDataCenters(dataCenterList.data));
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * Add destinations
 */
export function* addDestination(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.addDestination,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "VHPIQL" }
    );
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      let destination = apiResponse.data;
      destination[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        destination[DestinationAttributes.DESTINATION_PROP_ID];
      // apiResponse.context = "destinationAdded";
      // apiResponse.name = destination.destination_name;
      let name = destination.destination_name;
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("add.destination.success.msg", "5drnw7"),
          {
            name: name
          }
        )
      );

      yield put(updatedDestinationList(destination));
      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    } else {
      // in case error changing erro status 400 to 888 as 400 is already used for grid filter
      apiResponse.status = 888;
      // apiResponse.context = "destinationError";
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
  }
}

/**
 * To remove a customer accnt
 */
export function* deleteCustomerAccount(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.deleteCustomerAccountApi,
      action.data.id,
      loginReducer.token,
      loginReducer.organization_id,
      { showCommonError: true, testId: "wCTmtf" }
    );
    yield put(
      getCustomerAccountsList(
        constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
      )
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_DELETE;
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_DELETE,
            "5iUKXY"
          ),
          {
            name: action.data.name
          }
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* suspendCustomerAccount(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.suspendCustomerAccountApi,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "QpPspj" }
    );
    yield put(
      getCustomerAccountsList(
        constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
      )
    );
    yield put(hideLoading());
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_SUSPEND;
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_SUSPEND,
            "udRgNo"
          )
        )
      );
    }
    // if (apiResponse.status >= 400) {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ERROR;
    // }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* enableCustomerAccount(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.enableCustomerAccountApi,
      action.data,
      loginReducer.token,
      { showCommonError: true, testId: "ucLbZZ" }
    );
    yield put(
      getCustomerAccountsList(
        constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
      )
    );
    yield put(hideLoading());
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ENABLE;
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ENABLE,
            "VijROG"
          )
        )
      );
    }
    // else if (apiResponse.status >= 400) {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ERROR;
    // }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get detail of a particular customer account
 * @param action
 */
export function* getCustomerAccntDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;

    const jobStatusResponse = yield call(
      monitorApi.fetchJobStatus,
      `organization_id=${action.data.id}`,
      stateLogin.token
    );

    const sourceProtectionResponse = yield call(
      monitorApi.fetchSourceProtectionSummary,
      `organization_id=${action.data.id}`,
      stateLogin.token
    );

    let details = yield call(
      protectApi.fetchCustomerAccntDetailsById,
      organizationId,
      action.data.id,
      stateLogin.token
    );

    if (details.status === constant.HTTP_STATUS.OK) {
      _.isEmpty(_.get(jobStatusResponse, "data", {}))
        ? (details.data.backup_status = {})
        : (details.data.backup_status = { ...jobStatusResponse.data });
      _.isEmpty(_.get(sourceProtectionResponse, "data", {}))
        ? (details.data.source_status = {})
        : (details.data.source_status = { ...sourceProtectionResponse.data });
      yield put(setResourceName(details.data.organization_name));
      yield put(showCustomerAccountDetails(details.data));
    } else {
      yield put(addAPIresponse(details));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * Add customer accnt data
 */
export function* addCustomerAccountData(action) {
  try {
    let apiResponse;
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(showLoading());
    if (action.data.submsp) {
      apiResponse = yield call(
        protectApi.addCustomerAsSubMspAccount,
        action.data,
        loginReducer.token,
        organizationId,
        { showCommonError: true, testId: "erHhpt" }
      );
    } else {
      apiResponse = yield call(
        protectApi.addCustomerAccount,
        action.data,
        loginReducer.token,
        organizationId,
        { showCommonError: true, testId: "aBgxYl" }
      );
    }

    // if (constant.HTTP_STATUS.CREATED == apiResponse.status) {
    // let details = yield call(protectApi.fetchDestinationsById, destination.data.destination_id, loginReducer.token);
    // let custData = apiResponse;
    // custData.data[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
    //   custData.data[CustomerAccountsAttributes.CUSTOMER_ACCOUNTS_ID];
    // // custData[CustomerAccountsAttributes.CUSTOMER_ACCOUNTS_ID] = custData[constant.SPOG_GRID_PROPERTIES.ID_KEY];
    // yield put(updatedCustomerAccountList(custData));
    yield put(
      getCustomerAccountsList(
        constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
      )
    );
    // }
    yield put(hideLoading());
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      // apiResponse.context = "protect.customer_account.customer_added";
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.customer_account.customer_added", "aMYApE"),
          {
            name: apiResponse.data.organization_name
          }
        )
      );
    }
    // } else {
    //   apiResponse.context = "protect.customer_account.error";
    // }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * destination mock data API call
 */

/*export function* getDestinationMock() {
    try {
        const loginReducer = yield select(getLogin);
        const stateReducer = yield select(getReducer);
        //   let data = stateReducer.selectedJob;
        let details = yield call(protectApi.getDestMock, loginReducer.token);
        //   let obj = Object.assign({}, data, details.data);
        yield put(showDestinationListMock(details));
    } catch (e) {
        window.console.log(e);
    }
}*/

/***
 * Protect customer list mock data API call
 */

export function* getCustomersAssignedAdmin(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    const assignId = action.payload.assign_id;
    // const querystring = `assign_id=${assignId}`;
    //   const stateReducer = yield select(getReducer);
    //   let data = stateReducer.selectedResource;
    let details = yield call(
      protectApi.getCustomersAssignedAdmin,
      "",
      loginReducer.token,
      assignId,
      organizationId
    );
    //   let obj = Object.assign({}, data, details.data);
    yield put(showProtectCustomerList(details.data));
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get the list of avaliable retention options
 */
export function* getAdminDetailsToAssign(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    const assignId = action.payload.assign_id;

    let assignAdmins = yield call(
      protectApi.getCustomersAssignedAdmin,
      "",
      loginReducer.token,
      assignId,
      organizationId,
      true,
      true
    );

    let existUserIds = [];
    if (
      assignAdmins &&
      assignAdmins.status === constant.HTTP_STATUS.OK &&
      assignAdmins.data &&
      assignAdmins.data.length
    ) {
      //eslint-disable-next-line array-callback-return
      assignAdmins.data.map(accnt => {
        existUserIds.push(accnt.user_id);
      });
    }

    let responseData = yield call(
      protectApi.fetchAdminDetailsToAssign,
      "",
      loginReducer.token,
      organizationId
    );

    let updatedResponseData = [];
    if (
      responseData &&
      responseData.status === constant.HTTP_STATUS.OK &&
      responseData.data &&
      responseData.data.length
    ) {
      updatedResponseData = responseData.data.filter(item => {
        if (
          !constant.ROLES_THAT_CAN_BE_ASSIGNED_ORGS.includes(item.role_id) ||
          existUserIds.includes(item.user_id)
        ) {
          return false;
        }
        return true;
      });
    }
    yield put({
      type: actionType.UPDATE_ADMIN_DETAILS_TO_ASSIGN,
      data: updatedResponseData
    });
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* resendEnrollEmail(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = action.organizationId;

    let responseData = yield call(
      protectApi.resendEnrollEmail,
      "",
      loginReducer.token,
      organizationId
    );

    if (responseData.status !== constant.HTTP_STATUS.OK) {
      yield put(addAPIresponse(responseData));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Edit Customer Accounts data - set usage threshold
 */
export function* editSetUsageThreshold(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.setUsageThreshold,
      action.selectedCustomer,
      loginReducer.token,
      organizationId,
      { showCommonError: true, testId: "o9gQWY" }
    );
    // apiResponse.data.id = action.selectedCustomer.id;
    // yield put(updateSetUsageThreshold(apiResponse));
    yield put(
      getCustomerAccountsList(
        constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
      )
    );
    yield put(hideLoading());
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context = "protect.customer_account.threshold_set";
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_SET_THRESHOLD,
            "QVYJOB"
          )
        )
      );
    }
    // if (apiResponse.status >= 400) {
    //   apiResponse.context = "protect.customer_account.error";
    // }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * Add assigned admins to customer
 */
export function* addAssignedDataToCustomer(action) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    let assignedData = action.data;
    let addDataSets = { users: [] };
    //eslint-disable-next-line array-callback-return
    assignedData.map(assignDetails => {
      addDataSets.users.push({ user_id: assignDetails.user_id });
    });
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.addAssignedDataToCustomer,
      protectReducer.customerData.parent_id,
      protectReducer.customerData.organization_id,
      addDataSets,
      loginReducer.token,
      { showCommonError: true, testId: "mOvoOD" }
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context = "protect.customer_account.admins_assigned";
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ASSIGN_ADMINS,
            "LyIu4E"
          )
        )
      );
      yield put(
        getProtectCustomerList({
          assign_id: protectReducer.customerData.organization_id
        })
      );
    }
    // if (apiResponse.status >= 400) {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ERROR;
    // }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * change customer information name
 * @param action
 */
export function* updateCustomerAccntDetails(action) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.editCustomerInformation,
      action.data.post_data,
      action.data.data.organization_id,
      stateLogin.token,
      action.data.data.parent_id,
      { showCommonError: true, testId: "4tNeQg" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context = "protect.customer_account.account_updated";
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_MODIFY,
            "NSIfp7"
          )
        )
      );
      yield put(showCustomerAccountDetails(apiResponse.data));
      yield put(
        getCustomerAccountsList(
          constant.SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
        )
      );
    }
    // if (apiResponse.status >= 400) {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ERROR;
    // }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Unassign admin from customer
 * @param action
 */
export function* unassignAdminFromCustomer(action) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    let customerData = action.data;
    let addDataSets = { users: [] };
    addDataSets.users.push({ user_id: customerData.user_id });
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.unassignAdminFromCustomer,
      addDataSets,
      protectReducer.customerData.organization_id,
      loginReducer.token,
      protectReducer.customerData.parent_id,
      { showCommonError: true, testId: "koUmgM" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context = "protect.customer_account.admins_unassigned";
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_UNASSIGN_ADMINS,
            "vD79xX"
          )
        )
      );
    }
    // if (apiResponse.status >= 400) {
    //   apiResponse.context =
    //     constant.API_RESPONSE_CONTEXT.CUSTOMER_ACCOUNT_ERROR;
    // }
    yield put(
      removeUnassignAdminFromCustomerList({ data: apiResponse, keyType: "id" })
    );
    yield put(
      getProtectCustomerList({
        assign_id: protectReducer.customerData.organization_id
      })
    );
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get recovery points
 */
export function* getCDSourcesDestinationsRecoveryPointsList(
  data,
  pollingAction = false
) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const stateProtectReducer = yield select(getProtectReducer);
    let stateReducerOptions = [];
    let stateReducersearchText = "";
    let stateReducergroup = "false";
    if (
      data.payload.advancedSearchOptions &&
      data.payload.advancedSearchOptions.length > 0
    ) {
      stateReducerOptions = data.payload.advancedSearchOptions;
    } else if (stateReducer) {
      stateReducerOptions = stateReducer.currentSearchOptions;
    }
    if (data.payload.searchText) {
      stateReducersearchText = data.payload.searchText;
    } else if (stateReducer) {
      stateReducersearchText = stateReducer.searchText;
    }
    //eslint-disable-next-line eqeqeq
    if (stateReducersearchText != undefined && stateReducersearchText != "") {
      yield put(searchEnterEvent(data.payload));
    }
    if (data.payload.group) {
      stateReducergroup = data.payload.group;
    } else if (stateProtectReducer) {
      stateReducergroup = stateProtectReducer.recoveryPointsGroup;
    }
    // if (stateReducersearchText !== undefined && stateReducersearchText !== "") {
    if (
      stateReducer.searchText ||
      _.get(stateReducer, "options[0].selectedOptions.length")
    ) {
      yield put(submitAdvancedSearch());
    }

    let protectList = {};
    let makeQueryString = "";
    let queryString = `organization_id=${loginReducer.organization_id}&group=${stateReducergroup}`;
    if (
      data.payload.fromType !== constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS
    ) {
      queryString += `&source_id=${data.payload.source_id}`;
    } else {
      queryString += `&destination_id=${data.payload.destination_id}`;
    }

    let stateReducersearchOption = "";
    if (stateReducer && stateReducer.searchOption.selectedOption) {
      stateReducersearchOption = stateReducer.searchOption.selectedOption;
    }

    makeQueryString = util.getQueryStringByAdvanceSearchOptions(
      queryString,
      stateReducerOptions
    );

    queryString = util.getQueryStringBySearchNameText(
      makeQueryString,
      stateReducersearchText,
      stateReducersearchOption,
      SourcesRecoveryAttributesCloudDirect.RECOVERY_CONTENTS
    );

    yield put(showLoading());
    //  if required add a condition below to use getRecoveryPoints / getRecoveryPointsSources
    if (
      data.payload.fromType !== constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS
    ) {
      yield put(
        startGridLoaderById(constant.DATAGRID_IDS["cd_source_rps_grid"])
      );
      protectList = yield call(
        protectApi.getRecoveryPointsSources,
        queryString,
        loginReducer.cdtoken,
        data.payload.filterTypeRP,
        data.payload.source_id,
        data.pollingAction
      );
      yield put(
        stopGridLoaderById([constant.DATAGRID_IDS["cd_source_rps_grid"]])
      );
    } else {
      protectList = yield call(
        protectApi.getRecoveryPoints,
        queryString,
        data.payload.destination_id,
        loginReducer.cdtoken,
        data.payload.filterTypeRP,
        data.pollingAction
      );
    }
    yield put(filteredRecoveryList(protectList.data));
    yield put(hideLoading());
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["cd_source_rps_grid"]));
  }
}

/**
 * update the Source -> Configuration data
 */
export function* getSourceConfiguration() {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    // yield put(showLoading());
    const organizationId = loginReducer.organization_id;
    const sourceId = stateReducer.selectedResource.source_id;
    let queryString = `organization_id=${organizationId}&source_id=${sourceId}`;
    const apiResponse = yield call(
      protectApi.fetchSourceConfiguration,
      queryString,
      loginReducer.cdtoken
    );
    if (apiResponse.status === "SUCCESS") {
      yield put(editUpdateSourceConfiguration(apiResponse));
    } else {
      yield put(editUpdateSourceConfiguration(null));
    }
    yield put(addAPIresponse(apiResponse));
    //  yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * update the Source -> Configuration data
 */
export function* updateSourceConfiguration(action) {
  try {
    yield put(setLoading(Loader.UPDATE_SOURCE_CONFIGURATION));
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());

    const apiResponse = yield call(
      protectApi.editSourceConfiguration,
      action.payload,
      stateReducer.selectedResource.source_id,
      loginReducer.cdtoken
    );
    if (apiResponse.status === "ERROR") {
      if (apiResponse.code === 8371) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "error.source.configuration.update.failure.8371",
              `QHN8Ia_${apiResponse.code}`
            )
          )
        );
      } else if (apiResponse.code === 2001) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "error.source.configuration.update.failure.2001",
              `QHN8Ia_${apiResponse.code}`
            )
          )
        );
      } else {
        yield put(
          ccToast.addErrorNotification(
            makeToastData("apiResponse.message"),
            `QHN8Ia_${apiResponse.code ? apiResponse.code : apiResponse.status}`
          )
        );
      }
    } else {
      yield put(editUpdateSourceConfiguration(apiResponse));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.SOURCE_CONFIG_UPDATE_SUCCESS,
            "YXqhfc"
          )
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.UPDATE_SOURCE_CONFIGURATION));
  }
}

/**
 * delete a source group
 */
export function* deleteSourceGroup(data) {
  try {
    const loginReducer = yield select(getLogin);
    const sourceGroupId = data.id ? data.id : null;
    const contextualAction = yield select(getContextualAction);
    yield put(showLoading());
    const apiResponse = yield call(
      protectApi.deleteSourceGroup,
      sourceGroupId,
      loginReducer.token,
      { showCommonError: true, testId: "Xv6pJ4" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //Success Notification
      const name = contextualAction.additionalProperties.item.group_name.label;
      const toastData = {
        messageId: "configure.sourceGroups.delete.success",
        testId: "IBB4iH"
      };
      yield put(ccToast.addSuccessNotification(toastData, { name }));
      let id = "source_group";
      let page = yield select(ccDataGridSelectors.getPage(id));
      let pageSize = yield select(ccDataGridSelectors.getPages(id));
      if (page > 1 && pageSize === page) {
        const entities = yield select(
          ccDataGridSelectors.getEntities(
            constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS
          )
        );
        if (entities.length <= 1) page = page - 1;
        yield put(ccDataGridActions.updatePage(id, page, false, true));
      }
      yield put(deleteUpdateSourceGroups(data.id));
      if (data.isDetailPage) {
        yield put(changeURLFlag(true));
      }
      let mainType = "Configure";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    } else if (apiResponse.status >= 400) {
      apiResponse.context =
        constant.API_RESPONSE_CONTEXT.SOURCE_GROUP_CONFIG_ERROR;
    }
    yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.SOURCE_GROUP });
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch the selected recovered vm
 */
export function* getSelectedRecoveredVM(action) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.getRecoveredVMById,
      action.data && action.data.id,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(updateSelectedRecoveredVM(apiResponse.data));
      yield put(setResourceName(apiResponse.data.recoveredresource_name));
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch the selected recovered vm using source id
 */
export function* getSelectedRecoveredVMUsingSourceId(action) {
  try {
    const loginReducer = yield select(getLogin);
    const apiResponse = yield call(
      protectApi.getRecoveredVMBySourceId,
      action.data && action.data.id,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(updateSelectedRecoveredVM(apiResponse.data));
      yield put(setResourceName(apiResponse.data.recoveredresource_name));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(apiResponse, "errors[0].message", ""),
            `leEy46_${apiResponse?.status}`
          )
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * get file browser mock data API call
 */

export function* getFileBrowserDataSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const stateProtectReducer = yield select(getProtectReducer);
    yield put(showLoading());
    let treeData = stateProtectReducer.fileBrowsertreeData;
    let queryPostData = { full_path: "", display_full_path: "" };
    if (
      action.data.recoveryPointId &&
      action.data.sourceId &&
      action.data.from
    ) {
      if (action.data.vol_guid) {
        queryPostData.vol_guid = action.data.vol_guid;
      }
      if (action.data.full_path) {
        queryPostData.full_path = action.data.full_path;
      }
      if (action.data.display_full_path) {
        queryPostData.display_full_path = action.data.display_full_path;
      }
      if (action.data.parent_id) {
        queryPostData.parent_id = action.data.parent_id;
      }
      if (action.data.catalog_path) {
        queryPostData.catalog_path = action.data.catalog_path;
      }
      if (action.data.include_file) {
        queryPostData.include_file = action.data.include_file;
      }
      if (action.data.protected_password) {
        queryPostData.encrypt_password = action.data.protected_password;
      }
      if (action.data.type) {
        queryPostData.type = action.data.type;
      }
      let verifyPassword = false;
      if (action.data.verifyPassword) {
        verifyPassword = true;
      }
      let sortedData = (action.data && action.data.sortedData) || [];
      let makeQueryString = "";
      let gridCurrentPage = "";
      let gridPageSize = "";
      if (action.data.include_file === "true") {
        gridCurrentPage =
          action.data && action.data.gridCurrentPage
            ? action.data.gridCurrentPage
            : stateReducer.gridCurrentPage;
        gridPageSize =
          action.data && action.data.gridPageSize
            ? action.data.gridPageSize
            : stateReducer.gridPageSize;
        queryPostData.page = gridCurrentPage;
        queryPostData.page_size = gridPageSize;
        // makeQueryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
      }

      if (sortedData.length > 0) {
        makeQueryString = util.getQueryStringFromSortedData(
          makeQueryString,
          sortedData
        );
      }
      let response = yield call(
        protectApi.getFileBrowserDataAPI,
        action.data.recoveryPointId,
        action.data.sourceId,
        action.data.from,
        queryPostData,
        loginReducer.token,
        action.data.destination_source_id,
        verifyPassword,
        makeQueryString
      );
      let responseData = response.data;
      let paginationData = response.pagination;
      if (constant.HTTP_STATUS.OK === response.status) {
        if (verifyPassword !== true) {
          if (action.data.include_file === "true") {
            yield put(startLoader());
            responseData &&
              responseData.forEach(item => {
                // let item = actionData[0];
                // item.id = item.vol_guid;
                item.id = item.full_path;
                item.title = item.name;
              });
            yield put(updateFileBrowserDataGridData(responseData));
            yield put(updateFileBrowserDataGridPaginationData(paginationData));
          } else {
            let tempResonseTreeData = [];
            let newResonseTreeData = [];
            newResonseTreeData.push(treeData);
            if (responseData && responseData.length === 0) {
              if (action.data.rowinfo) {
                let tempNewResonseTreeData = Object.assign(
                  [],
                  newResonseTreeData[newResonseTreeData.length - 1]
                );
                tempNewResonseTreeData = util.getChildrensWithoutEmptyObject(
                  tempNewResonseTreeData,
                  tempNewResonseTreeData[0].vol_guid
                );
                newResonseTreeData.push(tempNewResonseTreeData);
              }
            }
            responseData &&
              responseData.forEach(item => {
                // let item = actionData[0];
                // item.id = item.vol_guid;
                item.id = item.full_path;
                item.title = item.name;
                item.expanded = false;
                // if (item.has_folder && !item.children) {
                //   item.children = []; //{ title: "" }
                // } else if (item.has_folder === false) {
                //   item.children = [];
                // }

                if (action.data.rowinfo) {
                  let tempNewResonseTreeData = Object.assign(
                    [],
                    newResonseTreeData[newResonseTreeData.length - 1]
                  );
                  // if (item.children && item.children[0].default) {
                  //   tempNewResonseTreeData = util.removeNode(
                  //     newResonseTreeData[newResonseTreeData.length - 1],
                  //     action.data.rowinfo
                  //   );
                  // }
                  tempNewResonseTreeData = util.getChildrensWithoutEmptyObject(
                    tempNewResonseTreeData,
                    action.data.vol_guid
                  );
                  tempResonseTreeData = util.addNode(
                    tempNewResonseTreeData,
                    action.data.rowinfo,
                    item
                  );
                  newResonseTreeData.push(tempResonseTreeData);
                }
                if (action.data.foundParents) {
                  item.parentId = item.vol_guid;
                  // item.className = "parent-node";
                }
              });

            if (action.data.rowinfo) {
              yield put(
                updateFileBrowserData(
                  newResonseTreeData[newResonseTreeData.length - 1]
                )
              );
            } else {
              yield put(updateFileBrowserData(responseData));
            }
            if (action.data.showBrowseRP) {
              yield put(showBrowseRecoveryPoint(true));
            }
          }
        } else {
          if (responseData && responseData.verification_result) {
            yield put(showBrowseRecoveryPoint(true));
          }
        }
        yield put(hideLoading());
        yield put(stopLoader());
      } else {
        yield put(hideLoading());
        yield put(stopLoader());
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(response, "errors[0].message", ""),
              `nAzMJF_${responseData?.status}`
            )
          )
        );
        yield put(addAPIresponse(response));
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* unmountRPS({
  sourceId,
  mountPath,
  mountDiskSignature,
  mountedSourceID
}) {
  try {
    const login = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    const apiResponse = yield call(
      protectApi.unmountRPS,
      {
        mounted_volume_info: {
          mount_path: mountPath,
          mount_disk_signature: mountDiskSignature
        },
        mount_server: { server_id: sourceId }
      },
      login.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(fetchMountedRPS());
      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.UNMOUNT_RPS_SUCCESS,
            "gz7A6o"
          )
        )
      );
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* unmountRPSSQL({ data }) {
  try {
    const login = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    const apiResponse = yield call(
      destinationApis.postDismountDatabase,
      data,
      login.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(fetchMountedRPS());
      const mainType = "Protect";
      const sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ]);
      const tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      const payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.UNMOUNT_RPS_SQL_SUCCESS,
            "gz7A6o"
          )
        )
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

function* recieveMountedRecoveryPointsList() {
  try {
    const loginReducer = yield select(getLogin);
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["mounted_recoverypoint_gid"])
    );
    // yield put(startLoader());

    let apiResponse = yield call(
      protectApi.getMountedRecoveryPoints,
      `organization_id=${loginReducer.organization_id}`,
      loginReducer.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(updateMountedRPS(apiResponse));
      yield put(fromNavigationApi(false));
      // yield put(stopLoader());
      yield put(
        stopGridLoaderById(constant.DATAGRID_IDS["mounted_recoverypoint_gid"])
      );
    } else {
      // yield put(stopLoader());
      yield put(
        stopGridLoaderById(constant.DATAGRID_IDS["mounted_recoverypoint_gid"])
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteRecoveredVM(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    const apiResponse = yield call(
      protectApi.deleteRecoveredVM,
      action.data,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.context = "recovered_vms_action_message.delete";
      yield put(updateDeleteRecoveredVMInList(action.data));
      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(`recovered_vms_action_message.delete`, "iOMXW2"),
          {
            name: action?.values?.name
          }
        )
      );
    }

    // recovered_vms_action_message.delete
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* startFailback(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(startFailbackLoading(true));
    const apiResponse = yield call(
      protectApi.handleRecoveredVMActions,
      action.data.id,
      loginReducer.token,
      "startfailback",
      action.data.payload
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.RECOVERED_RESOURCES_ACTION_SUCCESS;
      const title = action.data.title;
      const name = action.data.name;
      yield* showToastMessageForRecoveredResources(title, name);

      yield* startFailbackSuccessSaga(apiResponse);
    } else {
      apiResponse.context = "failback";

      yield put(startFailbackFailure(apiResponse));
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* showToastMessageForRecoveredResources(title, name) {
  try {
    const convertedText = RECOVERED_VMS_ACTION[title]
      ? getFormattedMessage(`recovered_vms_action_message.${title}`)
      : title;
    if (title) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.RecoveredResources.action.success", "GLrjnO"),
          { name, action: convertedText }
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* startFailbackSuccessSaga(apiResponse) {
  try {
    yield put(showConfirmation(false));
    yield put(startFailbackSuccess());
    yield put(addAPIresponse(apiResponse));
    yield put(updateParticularStateInRecoveredVms(apiResponse.data));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle stop failback contextual action
 */
export function* stopFailback(action) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.handleRecoveredVMActions,
      action.data,
      loginReducer.token,
      "stopfailback"
    );
    if (apiResponse && apiResponse.status === constant.HTTP_STATUS.OK) {
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.RECOVERED_RESOURCES_ACTION_SUCCESS;

      const title = action.values.title;
      const name = action.values.name;

      yield* showToastMessageForRecoveredResources(title, name);
      yield put(updateParticularStateInRecoveredVms(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle stop failback contextual action
 */
export function* startFinalFailbackStep(action) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.handleRecoveredVMActions,
      action.data,
      loginReducer.token,
      "startfinalfailbackstep"
    );
    if (apiResponse && apiResponse.status === constant.HTTP_STATUS.OK) {
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.RECOVERED_RESOURCES_ACTION_SUCCESS;
      const title = action.values.title;
      const name = action.values.name;
      yield* showToastMessageForRecoveredResources(title, name);
      yield put(updateParticularStateInRecoveredVms(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle contextual actions
 */
export function* recoveredVMActionHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.handleRecoveredVMActions,
      action.id,
      loginReducer.token,
      action.actionType
    );
    if (apiResponse && apiResponse.status === constant.HTTP_STATUS.OK) {
      const title = action.actionType;
      const name = action.name;
      yield* showToastMessageForRecoveredResources(title, name);
      yield put(updateParticularStateInRecoveredVms(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle contextual actions
 */
export function* recoveredVMDetailsActionHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.handleRecoveredVMActions,
      action.id,
      loginReducer.token,
      action.actionType
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      if (apiResponse.data) {
        yield put(updateSelectedRecoveredVM(apiResponse.data));
      }
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            `protect.RecoveredResources.instant_vm.${action.actionType}.confirmed_message`,
            "TbfNYL"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To set cookieauth fore restore CD action browse
 * @param {*} data
 */
function* setCDRestoreCookieauth(data) {
  try {
    const stateLogin = yield select(getLogin);
    const sourceId = data.data;
    yield call(protectApi.setRestoreCookieauth, sourceId, stateLogin.cdtoken);
    // yield put(updateRestoreCookieAuthFlagAction(true));
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * get recovery image formats
 */

export function* getRecoveryImageFormatsSaga(payload) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let response = yield call(
      protectApi.getRecoveryImageFormatsAPI,
      payload.data.source_id,
      `organization_id=${organizationId}`,
      loginReducer.token
    );
    yield put(updateRecoveryImageFormats(response.data));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get download links for Download Agent action
 */
export function* getDownloadLinksHandler() {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let apiResponse = yield call(
      protectApi.getDownloadLinks,
      organizationId,
      loginReducer.token
    );
    apiResponse.contextualAction = {
      type: SourceConstants.CONTEXTUAL_ACTION.DOWNLOAD_AGENT
    };
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get chart data for selected destination - CD
 */
export function* getDestinationCDHandler(action) {
  try {
    yield put(showLoading());
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : loginReducer.organization_id;
    const destinationId = action.data || "";
    let queryString = `destination_id=${destinationId}`;
    if (action.filter && action.filter === "custom") {
      let { filter, startTs, endTs } = action;
      queryString = `${queryString}&range=${filter}&start_ts=${startTs}&end_ts=${endTs}`;
    } else if (action.filter) {
      let { filter } = action;
      queryString = `${queryString}&range=${filter}`;
    }
    queryString += `&organization_id=${organizationId}`;
    queryString += `&time_zone=${util.getTimezone()}`;
    let apiResponse = yield call(
      protectApi.getDestinationCD,
      queryString,
      loginReducer.cdtoken
    );
    if (constant.HTTP_STATUS.SUCCESS === apiResponse.status) {
      yield put(updateDestinationCD(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get chart data for selected Customer Account - CD
 */
export function* getCustomerAccountCDHandler(action) {
  try {
    yield put(showLoading());
    const loginReducer = yield select(getLogin);
    const destinationId = action.data || "";
    let queryString = `organization_id=${destinationId}`;
    if (action.filter) {
      queryString = `${queryString}&range=${action.filter}`;
      if (action.filter === constant.DATE_RANGE_OPTIONS.CUSTOM) {
        const startTs = action.startTs;
        const endTs = action.endTs;
        queryString = `${queryString}&start_ts=${startTs}`;
        queryString = `${queryString}&end_ts=${endTs}`;
      }
    }
    let apiResponse = yield call(
      protectApi.getCustomerAccountCD,
      queryString,
      loginReducer.cdtoken
    );
    if (constant.HTTP_STATUS.SUCCESS === apiResponse.status) {
      yield put(updateCustomerAccountCD(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get chart data for selected Customer Account - CH
 */
export function* getCustomerAccountCHHandler(action) {
  try {
    yield put(showLoading());
    // const loginReducer = yield select(getLogin);
    // const destinationId = action.data || "";
    // let queryString = `organization_id=${destinationId}`;

    // queryString = `${queryString}&time_zone=${action.timeZone}`;
    const startTs = action.startTs;
    const endTs = action.endTs;
    const timeResolution = action.timeResolution;
    if (startTs && endTs && timeResolution)
      // queryString = `${queryString}&create_ts=%3E${startTs}&create_ts=%3C${endTs}&time_resolution=${action.timeResolution}`;

      // let apiResponse = yield call(
      //   protectApi.getCustomerAccountCH,
      //   queryString,
      //   loginReducer.token
      // );
      // if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //   yield put(updateCustomerAccountCH(apiResponse.data));
      // }
      // yield put(addAPIresponse(apiResponse));
      yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * post restore recover source/destination
 * @param action
 */
export function* postRestoreRecoverData(action) {
  try {
    const stateLogin = yield select(getLogin);
    let postActionData = action.data;
    let source_id = action.data.from_source_id;
    yield put(showLoading());
    let apiResponse;
    if (postActionData.destination_data_store) {
      apiResponse = yield call(
        protectApi.restoreRecovervCenterApi,
        source_id,
        postActionData,
        stateLogin.token,
        { showCommonError: true, testId: "t9LXIe" }
      );
    } else {
      apiResponse = yield call(
        protectApi.restoreRecoverMachineApi,
        source_id,
        postActionData,
        stateLogin.token,
        { showCommonError: true, testId: "t9LXIe" }
      );
    }

    if (
      [constant.HTTP_STATUS.CREATED, constant.HTTP_STATUS.OK].indexOf(
        apiResponse.status
      ) > -1
    ) {
      apiResponse.status = 201;
      let toastData = "protect.restore.recover.success";
      if (action.dgId) {
        toastData = {
          messageId: "protect.restore.recover.success",
          testId: `${action.dgId}_recover`
        };
      }
      yield put(ccToast.addSuccessNotification(toastData));
      // apiResponse.context = "protect.restore.recover.success";
    }
    // else {
    //   const toastData = {
    //     messageId: apiResponse.errors[0].message,
    //     testId: `t9LXIe_${apiResponse.status}`
    //   };
    //   yield put(ccToast.addErrorNotification(toastData));
    //   // apiResponse.context = "protect.restore.recover.error";
    // }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * get file browser mock data API call
 */
export function* fetchPolicyListForAssignPolicy(action) {
  try {
    const loginReducer = yield select(getLogin);
    let source_id = "";
    let sourceArray = [];
    // const organizationId = loginReducer.organization_id;
    if (action.data.sourceArray) {
      let tempSourceArray = action.data.sourceArray;
      if (tempSourceArray && tempSourceArray.length > 0) {
        //eslint-disable-next-line array-callback-return
        tempSourceArray.map(source => {
          sourceArray.push(source.source_id);
        });
      }
    }
    if (action.data.source_id) {
      source_id = action.data.source_id;
    }
    yield put(showLoading());
    let response = yield call(
      protectApi.fetchPoliciesBySourceType,
      // `organization_id=${organizationId}&pagination=false`,
      loginReducer.token,
      source_id,
      sourceArray
    );
    if (constant.HTTP_STATUS.OK === response.status) {
      yield put(updatePolicySource(response));
    }
    // yield put(addAPIresponse(response));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* assignPolicyToSource(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    const stateReducer = yield select(getReducer);
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.setPolicyToSource,
      action.payload.id,
      action.payload.source_id,
      loginReducer.token,
      { showCommonError: true, testId: "EDRrtY" }
    );
    let policy = action.payload.id;
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      let details = yield call(
        protectApi.fetchSourceById,
        action.payload.source_id[0],
        loginReducer.token,
        `organization_id=${organizationId}`
      );
      let source = details.data;
      yield put(
        updateResourceInList(
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      apiResponse.policy = policy;
      apiResponse.contextualAction = {
        type: SourceConstants.CONTEXTUAL_ACTION.ASSIGN_POLICY
      };
      yield put(ccToast.addInfoNotification("policy-has-changed"));
      if (apiResponse && _.get(apiResponse, "data.policy_name")) {
        const toastData = {
          messageId: "protect.sources.assignPolicy.success",
          testId: "IBB4iH"
        };
        yield put(
          ccToast.addSuccessNotification(toastData, {
            name: _.get(apiResponse, "data.policy_name")
          })
        );
      }
      if (action.payload.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* unAssignPolicyToSource(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    const stateReducer = yield select(getReducer);
    let sources = [action.data.source];
    let policy =
      action.data.policy &&
      action.data.policy.additionalOperations &&
      action.data.policy.additionalOperations.length > 0 &&
      action.data.policy.additionalOperations[0];
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.unSetPolicyToSource,
      policy.policyId,
      loginReducer.token,
      sources,
      { showCommonError: true, testId: "iGFlie" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const name = policy ? policy.policyName : "";
      const toastData = {
        messageId: "protect.sources.unAssignPolicy.success",
        testId: "ecmw5K"
      };
      yield put(ccToast.addSuccessNotification(toastData, { name }));
      let details = yield call(
        protectApi.fetchSourceById,
        action.data.source,
        loginReducer.token,
        `organization_id=${organizationId}`
      );
      let source = details.data;
      yield put(
        updateResourceInList(
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      apiResponse.contextualAction = {
        type: SourceConstants.CONTEXTUAL_ACTION.REMOVE_POLICY
      };
      apiResponse.policy = policy;
      if (action.data.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/***
 * get ch recovery points API call
 */
export function* getChRpOnDestinationDetails(payload) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    if (payload.data.source_id && payload.data.destination_id) {
      yield put(showLoading());
      let response = yield call(
        protectApi.getChRpOnDestDetailsApi,
        loginReducer.token,
        payload.data.destination_id,
        payload.data.source_id,
        `organization_id=${organizationId}`
      );
      if (constant.HTTP_STATUS.OK === response.status) {
        yield put(updateRecoveryPointsDetails(response.data));
      }
      yield put(addAPIresponse(response));
      yield put(hideLoading());
    }
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * post restore recover source/destination for CH
 * generate And Download Link RP
 * @param action
 */
export function* generateAndDownloadLinkRP(action) {
  try {
    const stateLogin = yield select(getLogin);
    let postActionData = action.data.postData;
    let source_id = action.data.source_id;
    let destination_source_id = action.data.destination_source_id;
    let recovery_point_id = action.data.recovery_point_id;
    yield put(showLoading());
    let apiResponse = yield call(
      protectApi.getGenerateDownloadRPApi,
      postActionData,
      source_id,
      recovery_point_id,
      destination_source_id,
      stateLogin.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.context = "protect.restore.ch.generatedownloadlink.success";
      let link = document.createElement("a");
      link.setAttribute("href", apiResponse.data.download_task_url);
      link.setAttribute("download", "test");
      document.body.appendChild(link);
      link.click();
      setTimeout(function() {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(apiResponse.data.download_task_url);
      }, 0);
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle provision action for selected source
 */
export function* sourceProvisionHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let postData = {};
    if (action.data.recoverypoint_id) {
      postData = { recoverypoint_id: action.data.recoverypoint_id };
    }
    if (action.data.source_id) {
      let apiResponse = yield call(
        protectApi.sourceProvision,
        action.data.source_id,
        postData,
        loginReducer.token,
        { showCommonError: true, testId: "CupGaR" }
      );

      apiResponse.contextualAction = {
        type: SourceConstants.CONTEXTUAL_ACTION.PROVISION
      };
      if (
        apiResponse &&
        apiResponse.errors &&
        apiResponse.errors.length === 0
      ) {
        const name =
          apiResponse.data &&
          apiResponse.data.source &&
          apiResponse.data.source.source_name;
        const toastAction =
          SourceConstants.SUCCESS_MSG[apiResponse.contextualAction.type];
        if (action.data.recoverypoint_id) {
          let values = {};
          let testId;
          if (
            stateReducer.secondaryNavigation ===
            constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD
          ) {
            testId = "GNnyG7";
            values = {
              name: getFormattedMessage(
                "protect.recoveryPoint.action.recovery"
              ),
              action: getFormattedMessage("provision")
            };
          } else {
            testId = "t9LXIe";
            values = {
              name: getFormattedMessage(
                "protect.recoveryPoint.action.success.name"
              ),
              action: getFormattedMessage("provisioning")
            };
          }
          const toastData = {
            messageId: `protect.recoveryPoint.action.success`,
            testId: testId
          };
          yield put(ccToast.addSuccessNotification(toastData, values));
          // apiResponse.context = "protect.recoveryPoint.action.success";
        } else if (action.data.fromType) {
          const toastData = {
            messageId: `protect.${action.data.fromType}.action.success`,
            testId: "QoXmGN"
          };
          yield put(
            ccToast.addSuccessNotification(toastData, {
              name,
              action: toastAction
            })
          );
          // apiResponse.context = `protect.${action.data.fromType}.action.success`;
        } else {
          const toastData = {
            messageId: "protect.recoveryPoint.action.success",
            testId: "E6w5aD"
          };
          yield put(
            ccToast.addSuccessNotification(toastData, {
              name,
              action: toastAction
            })
          );
          // apiResponse.context = "protect.RecoveredResources.action.success";
        }
      }
      yield put(addAPIresponse(apiResponse));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle csncel replication  action for selected source
 */
export function* sourceCancelReplicationHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.sourceCancelReplication,
      action.data,
      null,
      loginReducer.token,
      { showCommonError: true, testId: "QnYgYr" }
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: "toast-success.cancel-replication-in",
        testId: "FY8wDV"
      };
      yield put(ccToast.addSuccessNotification(toastData));
    }
    if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
      });
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getConfigurationLicenses() {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let apiResponse = yield call(
      protectApi.fetchLicenses,
      loginReducer.isImpersonationView
        ? stateReducer.customerOrgId
        : loginReducer.organization_id,
      loginReducer.cdtoken
    );
    if (apiResponse.status === "SUCCESS")
      yield put(updateConfigurationLicenses(apiResponse));
  } catch (e) {
    window.console.log(e);
  }
}

function* fetchAgentlessVMSaga() {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    const sourcesData = yield call(
      protectApi.fetchAllSources,
      loginReducer.token
    );
    if (sourcesData.status === constant.HTTP_STATUS.OK) {
      yield put(fetchAgentlessVMSources(sourcesData.data));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

function* fetchPolicySaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      protectApi.fetchPolicy,
      action.payload.policyId,
      loginReducer.token
    );
    if (response.status === 200) {
      // if (
      //   response &&
      //   response.data &&
      //   response.data.policy_type &&
      //   response.data.policy_type === "udp_agentless_vm_backup"
      // ) {
      //   let sourcesData = yield call(
      //     protectApi.fetchAllSources,
      //     loginReducer.token
      //   );

      //   // API /api/sources?source_type=agentless_vm
      //   if (sourcesData.status === constant.HTTP_STATUS.OK) {
      //     yield put(fetchAgentlessVMSources(sourcesData.data));
      //   }
      // }
      if (response?.data?.policy_type === "udp_agent_based_linux") {
        let destination =
          response?.data.destinations[0].udp_linux_backup.destination;
        let udp_linux_backup =
          response?.data?.destinations?.[0]?.udp_linux_backup;
        let lbs_network_traffic = udp_linux_backup?.lbs_network_traffic;
        let network_traffic =
          udp_linux_backup?.destination?.datastore?.network_traffic;

        //Modify Share Folder Fields
        if (destination.backup_destination_type === "share_folder") {
          let share_folder = destination.share_folder;
          destination["local_disk"] = {
            local_path: response?.data?.destinations[0].destination_name,
            encryption_algorithm: share_folder?.encryption_algorithm,
            enable_compression: share_folder?.enable_compression
          };
        }

        ///Modify LBS and RPS designated Backup Network
        if (lbs_network_traffic && lbs_network_traffic.network_address) {
          lbs_network_traffic.use_selected_network = true;
        }
        if (network_traffic && network_traffic.network_address) {
          network_traffic.use_selected_network = true;
        }
      }

      const SET_DESTINATION_ID_FOR_POLICY_TYPES = [
        DCs.TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
        DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY
      ];
      // if task type is copy_to_remote_site - update it to udp_replication because UI has just one
      // copy to data store related task_type
      //eslint-disable-next-line array-callback-return
      response.data.destinations.map(destination => {
        let policyType = _.get(response, "data.policy_type", null);
        if (destination.task_type === "copy_to_remote_site") {
          destination.udp_replication = destination.copy_to_remote_site;
          destination.task_type = "udp_replication";
          destination.copy_to_remote_site = undefined;
          response.data.schedules
            .filter(schedule => destination.task_id === schedule.task_id)
            .map(schedule => (schedule.task_type = "udp_replication"));
          response.data.throttles
            .filter(throttle => destination.task_id === throttle.task_id)
            .map(schedule => (schedule.task_type = "udp_replication"));
        }
        if (destination.task_type === DCs.TASK_TYPES.UDP_ASSURED_RECOVERY) {
          destination.task_type = DCs.TASK_TYPES.UDP_ASSURED_RECOVERY_TEST;
          setDestinationId(destination);
          setHypervSelectedRowsData(destination, policyType);
          if (
            destination?.udp_assured_recovery_test
              ?.instant_virtual_machine_option?.vm_setting
          ) {
            let vmSettings =
              destination.udp_assured_recovery_test
                .instant_virtual_machine_option.vm_setting;
            //Convert Memory Size
            let memorySize = Number(vmSettings.memory_size);
            let memorySizeInGB = memorySize / 1024;
            vmSettings.memory_size = memorySizeInGB;
          }
        } else if (
          destination.task_type === DCs.TASK_TYPES.UDP_ORACLE_DATABASE
        ) {
          modifyDataForOracleTaskTypeAfterFetch(destination);
        }
        if (
          SET_DESTINATION_ID_FOR_POLICY_TYPES.includes(destination.task_type)
        ) {
          setDestinationId(destination);
        }

        let task_type = _.get(destination, "task_type", null);
        if (
          task_type &&
          _.get(destination, `${task_type}.protected_volumes`, false)
        ) {
          if (
            _.get(destination, `${task_type}.protected_volumes`, null)[0] ===
            "*"
          ) {
            response.data.driveSelection = "backupAllVolumes";
          } else {
            response.data.driveSelection = "backupSelectedVolumes";
          }
        }

        if (policyType === POLICY_TYPES.HYPERVISOR)
          response.data.selectedTaskId = _.get(
            response,
            "data.destinations[0].task_id"
          );
      });
      // eslint-disable-next-line array-callback-return, consistent-return
      response.data.schedules &&
        response.data.schedules.map(schedule => {
          if (
            schedule?.schedule_type === "hourly" &&
            schedule?.schedule?.hourly_schedule
          ) {
            if (
              !constant.hourlyScheduleOptions.includes(
                schedule?.schedule?.hourly_schedule?.repeat_interval
              )
            ) {
              schedule.schedule.hourly_schedule.repeat_interval_custom =
                schedule?.schedule?.hourly_schedule?.repeat_interval;
              schedule.schedule.hourly_schedule.repeat_interval_dropdown =
                "custom";
            }
            if (
              constant.hourlyScheduleOptions.includes(
                schedule?.schedule?.hourly_schedule?.repeat_interval
              )
            ) {
              schedule.schedule.hourly_schedule.repeat_interval_dropdown =
                schedule?.schedule?.hourly_schedule?.repeat_interval;
            }
          }
          if (
            schedule.schedule_type === "minute" &&
            schedule?.schedule?.minute_schedule
          ) {
            if (
              !constant.minuteScheduleOptions.includes(
                schedule?.schedule?.minute_schedule?.repeat_interval
              )
            ) {
              schedule.schedule.minute_schedule.repeat_interval_custom =
                schedule?.schedule?.minute_schedule?.repeat_interval;
              schedule.schedule.minute_schedule.repeat_interval_dropdown =
                "custom";
            }
            if (
              constant.minuteScheduleOptions.includes(
                schedule?.schedule?.minute_schedule?.repeat_interval
              )
            ) {
              schedule.schedule.minute_schedule.repeat_interval_dropdown =
                schedule?.schedule?.minute_schedule?.repeat_interval;
            }
          }
          if (schedule.task_type === DCs.TASK_TYPES.UDP_ASSURED_RECOVERY)
            schedule.task_type = DCs.TASK_TYPES.UDP_ASSURED_RECOVERY_TEST;
        });

      const { destinations = [], schedules = [] } = response.data;
      let newDestinations = destinations;

      if (schedules && schedules.length > 0) {
        const [first] = schedules;
        const idx = destinations.findIndex(d => d.task_id === first.task_id);
        const firstDest = destinations[idx];
        newDestinations = [
          firstDest,
          ...destinations.filter(d => d.task_id !== first.task_id)
        ];
      }

      let finalContainers = [];
      let containers =
        response && response.data && response.data.containers
          ? response.data.containers
          : [];
      containers &&
        containers.length > 0 &&
        //eslint-disable-next-line array-callback-return
        containers.map(item => {
          const isVM =
            item.entity_type === "VM-StandAlone" ||
            item.entity_type === "VM-Cluster" ||
            item.entity_type === "VirtualMachine";
          finalContainers.push({
            source_id: isVM ? item.entity_id : item.ref_id || item.entity_name,
            source_type: "agentless_vm",
            source_name: isVM ? item.entity_name : item.path,
            source_group: [],
            entity_type: item.entity_type,
            hypervisor_id: item.hypervisor_id,
            treeFields: item
          });
        });
      if (finalContainers.length > 0) {
        response.data.sources.push(...finalContainers);
      }

      yield put(
        fetchPolicySuccess({
          ...response.data,
          destinations: newDestinations
        })
      );
    } else {
      yield put(fetchPolicyFailure(response.errors));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

function setDestinationId(destination) {
  switch (destination.task_type) {
    case DCs.TASK_TYPES.UDP_ASSURED_RECOVERY_TEST:
      const testType = destination?.udp_assured_recovery_test?.test_type;
      switch (testType) {
        case "instant_virtual_disk":
          if (
            destination?.udp_assured_recovery_test?.instant_virtual_disk_option
          ) {
            destination.udp_assured_recovery_test.instant_virtual_disk_option.proxy_id =
              destination.destination_id;
          }
          break;
        case "instant_virtual_machine":
          if (
            destination?.udp_assured_recovery_test
              ?.instant_virtual_machine_option
          ) {
            destination.udp_assured_recovery_test.instant_virtual_machine_option.hypervisor_id =
              destination.destination_id;
          }
          break;
        default:
          break;
      }
    case DCs.TASK_TYPES.UDP_ASSURED_SECURITY_SCAN:
      if (destination?.udp_assured_security_scan?.instant_virtual_disk_option) {
        destination.udp_assured_security_scan.instant_virtual_disk_option.proxy_id =
          destination.destination_id;
        destination.udp_assured_security_scan.instant_option =
          "instant_virtual_disk";
      }
      break;
    case DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY:
      if (destination?.udp_vsb?.virtualization_server) {
        destination.udp_vsb.virtualization_server.target_id =
          destination.destination_id;
      }
      break;
    default:
      break;
  }
}

function setHypervSelectedRowsData(destination, policyType) {
  let selectedRowHostName = "";
  let hypervisorType =
    destination?.udp_assured_recovery_test?.instant_virtual_machine_option
      ?.hypervisor?.hypervisor_type;
  if (hypervisorType === "hyperv") {
    if (policyType === POLICY_TYPES.UDP_AGENT_BASED_LINUX) {
      selectedRowHostName = destination?.udp_assured_recovery_test
        ?.instant_virtual_machine_option.hypervisor?.hyperv?.cluster_node_id
        ? destination?.udp_assured_recovery_test?.instant_virtual_machine_option
            .hypervisor?.hyperv?.cluster_node_name
        : destination?.destination_name;
    } else {
      selectedRowHostName = destination?.udp_assured_recovery_test
        ?.instant_virtual_machine_option.hypervisor?.hyperv?.cluster_node_id
        ? destination?.udp_assured_recovery_test?.instant_virtual_machine_option
            .hypervisor?.hyperv?.cluster_node_name
        : destination?.udp_assured_recovery_test?.instant_virtual_machine_option
            ?.windows_proxy?.proxy_name;
    }
    if (selectedRowHostName) {
      let selectedRow = [
        {
          host: selectedRowHostName,
          id: selectedRowHostName
        }
      ];
      destination.udp_assured_recovery_test.instant_virtual_machine_option.hypervisor.selectedHypervRows = [
        ...selectedRow
      ];
    }
  }
}

function checkForTestTypeAR(destination) {
  const testType = destination?.udp_assured_recovery_test?.test_type;
  if (
    testType === "instant_virtual_disk" &&
    destination?.udp_assured_recovery_test?.instant_virtual_disk_option
  ) {
    destination.udp_assured_recovery_test.instant_virtual_machine_option = null;
    destination.destination_id =
      destination.udp_assured_recovery_test.instant_virtual_disk_option.proxy_id;
    delete destination.udp_assured_recovery_test.instant_virtual_disk_option
      .proxy_id;
  } else if (testType === "instant_virtual_machine") {
    destination.destination_id =
      destination.udp_assured_recovery_test.instant_virtual_machine_option.hypervisor_id;
    delete destination.udp_assured_recovery_test.instant_virtual_machine_option
      .hypervisor_id;
    destination.udp_assured_recovery_test.instant_virtual_disk_option = null;
  }
}
function checkForPolicyTypeAR(destination, policyType) {
  if (policyType === POLICY_TYPES.UDP_AGENT_BASED_LINUX) {
    delete destination?.udp_assured_recovery_test
      ?.instant_virtual_machine_option?.windows_proxy;
  } else if (policyType === POLICY_TYPES.UDP_AGENT_BASED_WINDOWS) {
    delete destination?.udp_assured_recovery_test
      ?.instant_virtual_machine_option?.linux_proxy;
  }
}

function deleteSelectedHypervRowsFromPayload(destination) {
  if (
    destination?.udp_assured_recovery_test?.instant_virtual_machine_option
      ?.hypervisor?.selectedHypervRows
  ) {
    delete destination.udp_assured_recovery_test?.instant_virtual_machine_option
      ?.hypervisor?.selectedHypervRows;
  }
}

export function* fetchHypervisorSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      protectApi.fetchHypervisor,
      action.payload.hypervisorId,
      loginReducer.token
    );
    if (response.status === 200) {
      yield put(fetchHypervisorSuccess(response.data));
    } else {
      yield put(fetchHypervisorFailure(response.errors));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateHypervisorSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      protectApi.updateHypervisor,
      action.payload.hypervisor_id,
      action.payload,
      loginReducer.token
    );
    if (response.status === 200) {
      yield put(updateHypervisorSuccess(response.data));
    } else {
      yield put(updateHypervisorFailure(response.errors));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* hypervisorUpgradeAgentSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      protectApi.hypervisorUpgradeAgent,
      action.payload.hypervisorId,
      loginReducer.token
    );
    if (response.status === 200) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.hypervisor.upgrade-agent", `uMcybI`)
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            "toast-errors.internal-error",
            `T6C9vM_${response?.status}`
          )
        )
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* hypervisorRefreshVmsSaga(action) {
  try {
    yield put(setLoading(Loader.CD_REFRESH_HYPERV));
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      protectApi.hypervisorRefreshVms,
      action.payload.hypervisorId,
      loginReducer.token
    );
    if (response.status === 200) {
      yield put(fetchHypervisor(action.payload.hypervisorId));
      yield take([
        actionType.FETCH_HYPERVISOR_SUCCESS,
        actionType.FETCH_HYPERVISOR_FAILURE
      ]);
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.hypervisor.refresh-vms", "AEwvmB")
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `VhAJID_${response?.status}`
          )
        )
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CD_REFRESH_HYPERV));
  }
}

function* fetchRemotePoliciesSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let err = null;
    let response = yield call(
      protectApi.postRemotePoliciesApi,
      {
        organization_id: loginReducer.organization_id,
        ...action.payload // can override
      },
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(fetchRemotePoliciesSuccess(response.data));
    } else if (response.status === constant.HTTP_STATUS.BAD_REQUEST) {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        for (let i = 0; i < response.errors.length; ++i) {
          err = response.errors[i];
          if (localeData.en.hasOwnProperty(`toast-errors.cc.${err.code}`)) {
            yield put(
              ccToast.addErrorNotification(
                makeToastData(err.code, `DOigpK_${response?.status}`),
                undefined,
                "toast-errors.cc"
              )
            );
          } else {
            yield put(
              ccToast.addErrorNotification(
                makeToastData("apiErrorResponse", `ISBJvg_${response?.status}`),
                {
                  message: err.message
                }
              )
            );
          }
        }
      }
      yield put(fetchRemotePoliciesFailure(response.errors));
    } else {
      yield put(ccToast.addInfoNotification("toast-errors.internal-error"));
      yield put(fetchRemotePoliciesFailure(response.errors));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

function* updatePolicySaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    const api = yield select(getApi);
    let response = null;
    let err = null;
    // let destinationID = "";

    yield put(setLoading(Loader.CREATE_POLICY));
    yield put(showLoading());

    if (action.payload.policy_type === "cloud_direct_hypervisor") {
      yield put(updateHypervisor(action.payload.hypervisor_api));
      yield take([
        actionType.UPDATE_HYPERVISOR_SUCCESS,
        actionType.UPDATE_HYPERVISOR_FAILURE
      ]);
    }
    // if task type is udp_replication and remote_policy_id is present then update it to copy_remote_site
    // copy to data store related task_type
    let tempAction = JSON.parse(JSON.stringify(action));
    //eslint-disable-next-line array-callback-return
    tempAction.payload.destinations.map(destination => {
      if (
        destination.task_type === "udp_replication" &&
        //eslint-disable-next-line eqeqeq
        destination.udp_replication.remote_policy_id != undefined
      ) {
        destination.copy_to_remote_site = destination.udp_replication;
        destination.task_type = "copy_to_remote_site";
        destination.udp_replication = undefined;
        if (tempAction?.payload?.schedules?.length > 0)
          tempAction.payload.schedules
            .filter(schedule => destination.task_id === schedule.task_id)
            .map(schedule => (schedule.task_type = "copy_to_remote_site"));
        // this is a patch fix, implement remove schedule in a better way for remote site
        if (tempAction?.payload?.schedules?.length > 0)
          tempAction.payload.schedules = tempAction.payload.schedules.filter(
            schedule =>
              !(
                destination.task_id === schedule.task_id &&
                schedule.schedule_type === "merge"
              )
          );
        tempAction.payload.throttles &&
          tempAction.payload.throttles
            .filter(throttle => destination.task_id === throttle.task_id)
            .map(throttle => (throttle.task_type = "copy_to_remote_site"));
      } else if (
        destination.task_type === DCs.TASK_TYPES.UDP_ASSURED_RECOVERY_TEST
      ) {
        checkForTestTypeAR(destination);
        deleteSelectedHypervRowsFromPayload(destination);
        checkForPolicyTypeAR(destination, action.payload.policy_type);
        destination.task_type = DCs.TASK_TYPES.UDP_ASSURED_RECOVERY;
        // destinationID = destination.destination_id;
        if (
          destination.udp_assured_recovery_test?.instant_virtual_machine_option
            ?.vm_setting
        ) {
          let vmSettings =
            destination.udp_assured_recovery_test.instant_virtual_machine_option
              .vm_setting;
          //Convert Memiory Size
          let memorySize = Number(vmSettings.memory_size);
          let memorySizeConverted = memorySize * 1024;
          vmSettings.memory_size = memorySizeConverted;
          //Clear avail size
          if (vmSettings.avail_size) {
            delete vmSettings.avail_size;
          }
        }
      } else if (destination.task_type === DCs.TASK_TYPES.UDP_ORACLE_DATABASE) {
        modifyDataForOracleTaskTypeBeforeUpdate(destination);
      }
    });

    if (tempAction?.payload?.schedules?.length > 0)
      // eslint-disable-next-line array-callback-return
      tempAction.payload.schedules.map(schedule => {
        if (schedule.task_type === DCs.TASK_TYPES.UDP_ASSURED_RECOVERY_TEST) {
          const destinationArr = tempAction.payload.destinations;
          for (let i = 0; i < destinationArr.length; i++) {
            if (schedule.task_id === destinationArr[i].task_id) {
              schedule.task_type = DCs.TASK_TYPES.UDP_ASSURED_RECOVERY;
              schedule.destination_id = destinationArr[i].destination_id;
              break;
            }
          }
        }
      });

    if (tempAction.payload.policy_id) {
      response = yield call(
        protectApi.updatePolicy,
        tempAction.payload.policy_id,
        {
          ...tempAction.payload,
          organization_id: loginReducer.organization_id
        },
        loginReducer.token
      );
    } else {
      response = yield call(
        protectApi.createPolicy,
        {
          ...tempAction.payload,
          organization_id: loginReducer.organization_id
        },
        loginReducer.token
      );
    }
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(redirectToPolicyGrid());
      yield put(ccToast.addInfoNotification("policy-has-changed"));
      if (
        util.showWarningForPolicyWithLargeAmountOfVms(
          action.payload.policy_type,
          action.payload.sources
        )
      ) {
        yield put(
          ccToast.addWarningNotification(
            "protect.policies.save_cd_hypervisor_policy_more_than_fifteen_vms_warning"
          )
        );
      }
    } else if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(redirectToPolicyGrid());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("policy.create.successful", "ay6hVp"),
          {
            name: action.payload.policy_name
          }
        )
      );
      let mainType = "Protect";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.POLICIES];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    } else if (response.errors.length > 0) {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        for (let i = 0; i < response.errors.length; ++i) {
          err = response.errors[i];
          if (localeData.en.hasOwnProperty(`toast-errors.cc.${err.code}`)) {
            yield put(
              ccToast.addErrorNotification(
                makeToastData(err.code, `pkZ3FO_${response?.status}`),
                undefined,
                "toast-errors.cc"
              )
            );
          } else {
            let cdTrialExpired =
              _.get(
                api,
                "entitlementsProducts.entitlementsProducts.clouddirect.trial_start"
              ) &&
              _.get(
                api,
                "entitlementsProducts.entitlementsProducts.clouddirect.state"
              ) === "trial" &&
              parseInt(
                _.get(
                  api,
                  "entitlementsProducts.entitlementsProducts.clouddirect.trial_start"
                ),
                10
              ) < Date.now() &&
              err.code === "00E00059";
            let modify = tempAction.payload.policy_id ? true : false;

            yield put(
              ccToast.addErrorNotification(
                makeToastData(
                  cdTrialExpired && modify
                    ? "policy.cdtrialexpired_errormessage.update"
                    : cdTrialExpired && !modify
                    ? "policy.cdtrialexpired_errormessage.create"
                    : "apiErrorResponse",
                  `2vgEt4_${response?.status}`
                ),
                {
                  message: err.message
                }
              )
            );
          }
        }
      } else {
        yield put(ccToast.addInfoNotification("toast-errors.internal-error"));
      }
    }
    yield put(resetLoading(Loader.CREATE_POLICY));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

function modifyDataForOracleTaskTypeBeforeUpdate(destination) {
  if (destination.udp_oracle_database_backup.prerequisites) {
    delete destination.udp_oracle_database_backup.prerequisites;
  }
  destination[DCs.TASK_TYPES.ORACLE_DATABASE_BACKUP] = {
    ...destination.udp_oracle_database_backup
  };
  delete destination.udp_oracle_database_backup;
}

function modifyDataForOracleTaskTypeAfterFetch(destination) {
  if (destination?.oracle_database_backup) {
    destination.oracle_database_backup.prerequisites = true;
    destination[DCs.TASK_TYPES.UDP_ORACLE_DATABASE] = {
      ...destination.oracle_database_backup
    };
    delete destination.oracle_database_backup;
  }
}

export function* fetchAllDestinationsSaga() {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(showLoading());
    let response = yield call(
      protectApi.fetchDestinationsList,
      `organization_id=${organizationId}&pagination=false`,
      loginReducer.token
    );
    if (response.status === 200) {
      yield put(fetchAllDestinationsSuccess(response.data));
    } else {
      yield put(fetchAllDestinationsFailure(response.errors));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchFailbackDestinationsSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(startFailbackLoading(true));
    let response = yield call(
      protectApi.fetchFailbackDestinationsList,
      action.payload.recoveredResourceId,
      `organization_id=${organizationId}&pagination=false`,
      loginReducer.token
    );
    if (response.status === 200) {
      yield put(fetchFailbackDestinationsSuccess(response.data));
    } else {
      yield put(fetchFailbackDestinationsFailure(response.errors));
    }
    yield put(startFailbackLoading(false));
  } catch (e) {
    window.console.log(e);
  }
}

function* postRemotePoliciesSaga(action) {
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.postRemotePoliciesApi,
      action.data,
      loginReducer.token
    );

    let remotePolicyDropdownOptions = util.dropdownValues(
      response.data,
      "remote_plan_rpspolicy_uuid",
      "remote_plan_rpspolicy_name"
    );

    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        updateRemotePolicies({
          data: response.data,
          dropdowndata: remotePolicyDropdownOptions
        })
      );
    } else {
      yield put(updateRemotePolicyError(response));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle cancel replication out action for selected source
 */
export function* sourceCancelReplicationOutHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const apiResponse = yield call(
      protectApi.sourceCancelReplicationOut,
      action.data,
      null,
      loginReducer.token,
      { showCommonError: true, testId: "mEObs1" }
    );

    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: "toast-success.cancel-replication-out",
        testId: "a6hI6V"
      };
      yield put(ccToast.addSuccessNotification(toastData));
    }

    if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
      });
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * handle start replication out action for selected source
 */
export function* startReplicationOutHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.startReplicationOut,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: "toast-success.replication-out",
        testId: "Ea6rwj"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    } else if (response.status === constant.HTTP_STATUS.BAD_REQUEST) {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        for (let i = 0; i < response.errors.length; ++i) {
          let error = response.errors[i];
          if (localeData.en.hasOwnProperty(`toast-errors.cc.${error.code}`)) {
            yield put(
              ccToast.addErrorNotification(
                { messageId: error.code, testId: `a0jYEW_${error.code}` },
                undefined,
                "toast-errors.cc"
              )
            );
          } else {
            yield put(
              ccToast.addErrorNotification(
                {
                  messageId: "apiErrorResponse",
                  testId: `a0jYEW_${response.status}`
                },
                {
                  message: error.message
                }
              )
            );
          }
        }
      }
    } else {
      yield put(ccToast.addInfoNotification("toast-errors.internal-error"));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* startReplicationHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let response = yield call(
      protectApi.startReplication,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.replication-out", "9caIdG")
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      let details = yield call(
        protectApi.fetchSourceById,
        action.data,
        loginReducer.token
      );
      let source = details.data;
      source[constant.SPOG_GRID_PROPERTIES.ID_KEY] =
        source[SourceConstants.SourceAttributes.SOURCE_PROP_ID];
      yield put(updateResourceInList(source));
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source
        )
      );
    } else if (response.status >= 400) {
      yield put(hideLoading());
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", "error.spog.500"),
            `HSnnmB_${response?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* heartbeatPauseHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    const recoveredresourceId = _.get(
      protectReducer,
      "selectedRecoveredVM.recoveredresource_id"
    );

    let response = yield call(
      protectApi.heartbeatPause,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.HEARTBEAT_PAUSE_SUCCESS,
            "F9o98w"
          )
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      if (recoveredresourceId) {
        yield put(selectedRecoveredVMAction({ id: recoveredresourceId }));
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* heartbeatResumeHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    const recoveredresourceId = _.get(
      protectReducer,
      "selectedRecoveredVM.recoveredresource_id"
    );

    let response = yield call(
      protectApi.heartbeatResume,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.HEARTBEAT_RESUME_SUCCESS,
            "s2L4xq"
          )
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      if (recoveredresourceId) {
        yield put(selectedRecoveredVMAction({ id: recoveredresourceId }));
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* standByVMSnapshot(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(startLoader());
    let response = yield call(
      protectApi.standByVM,
      action.data,
      null,
      loginReducer.token
    );
    // let response = {
    //   "status": 200,
    //   "error": [],
    //   "data": {
    //     "current_running_snapshot": "string",
    //     "vm_name": "string",
    //     "vm_uuid": "string",
    //     "vm_state": "string",
    //     "snapshots": [
    //       {
    //         "snapshot_id": "1111",
    //         "snapshot_name": "snapshot name details",
    //         "snapshot_number": 1,
    //         "description": "aaaa",
    //         "timestamp": 1599137284,
    //         "status": "active"
    //       },
    //     {
    //         "snapshot_id": "2222",
    //         "snapshot_name": "snapshot name details",
    //         "snapshot_number": 2,
    //         "description": "bbbb",
    //         "timestamp": 1602736810,
    //         "status": "active"
    //       },
    //     {
    //         "snapshot_id": "3333",
    //         "snapshot_name": "snapshot name details",
    //         "snapshot_number": 3,
    //         "description": "cccc",
    //         "timestamp": 1602736810,
    //         "status": "inactive"
    //       },
    //     {
    //         "snapshot_id": "4444",
    //         "snapshot_name": "snapshot name details",
    //         "snapshot_number": 4,
    //         "description": "dddd",
    //         "timestamp": 1599137284,
    //         "status": "inactive"
    //       }
    //     ]
    //   }
    // };
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(getStandbyVMSnapshot(response.data));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}
export function* standByVMNetworkConfiguration(action) {
  try {
    yield put(getIsNetworkConfigured(null));
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.snapshotStandByVMNetworkConfiguration,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(getIsNetworkConfigured(response.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* shutdownVMHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.STANDBY_SHUTDOWN_VM));
    let response = yield call(
      protectApi.shutdownVM,
      action.source_id,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.STANDBY_VM_WIZARD));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.STANDBY_VM_SHUTDOWN,
            "guGpfa"
          )
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      yield put(hideDialog(Dialog.STANDBY_VM_SHUTDOWN_CONFIRMATION));
      yield put(hideDialog(Dialog.STANDBY_VM_WIZARD));
    }
    yield put(resetLoading(Loader.STANDBY_SHUTDOWN_VM));
  } catch (e) {
    window.console.log(e);
  }
}
export function* powerOnVMHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.STANDBY_POWER_ON));
    let response = yield call(
      protectApi.powerOnVM,
      action.source_id,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.STANDBY_VM_WIZARD));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.STANDBY_VM_POWERON,
            "S99tJx"
          )
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      yield put(hideDialog(Dialog.STANDBY_VM_POWERON_CONFIRMATION));
      yield put(hideDialog(Dialog.STANDBY_VM_WIZARD));
    }
    yield put(resetLoading(Loader.STANDBY_POWER_ON));
  } catch (e) {
    window.console.log(e);
  }
}

export function* virtualStandbyPauseHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    const recoveredresourceId = _.get(
      protectReducer,
      "selectedRecoveredVM.recoveredresource_id"
    );

    let response = yield call(
      protectApi.virtualStandbyPause,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VIRTUAL_STANDBY_PAUSE_SUCCESS,
            "0McDGm"
          )
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      yield put(getCurrentActionCalled());
      if (recoveredresourceId) {
        yield put(selectedRecoveredVMAction({ id: recoveredresourceId }));
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* virtualStandbyResumeHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.virtualStandbyResume,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      if (response.data.size > 1) {
        yield put(showDialog(Dialog.VIRTAUL_STANDBY_VM_RESUME_WIZARD));
        yield put(getPendingSessions(response.data));
      } else {
        yield* virtualStandbyResumeSmartCopyHandler({
          source_id: action.data,
          data: { force_smart_copy: true }
        });
        if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
          yield* fetchData({
            ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
          });
        }
      }
    } else {
      yield put(handleContextualAction("", {}, false));
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* virtualStandbyResumeSmartCopyHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    const recoveredresourceId = _.get(
      protectReducer,
      "selectedRecoveredVM.recoveredresource_id"
    );

    yield put(setLoading(Loader.VIRTUAL_STANDBY_RESUME));
    let response = yield call(
      protectApi.virtualStandbyResumeSmartCopy,
      action.source_id,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.VIRTAUL_STANDBY_VM_RESUME_WIZARD));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VIRTUAL_STANDBY_RESUME_SUCCESS,
            "paLoV4"
          )
        )
      );
      if (recoveredresourceId) {
        yield put(selectedRecoveredVMAction({ id: recoveredresourceId }));
      }
      yield put(storeSourceIdForResumeVSB(null));
    }
    if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
      });
    }
    yield put(getCurrentActionCalled());
    yield put(resetLoading(Loader.VIRTUAL_STANDBY_RESUME));
  } catch (e) {
    window.console.log(e);
  }
}
export function* standByVMNetworkConfigurationHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.standByVMNetworkConfiguration,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .STANDBY_VM_NETWORK_CONFIGURATION_SUCCESS,
            "McnuHz"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* postNetworkData(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.POST_VSB_SETTINGS));
    let response = yield call(
      protectApi.standByVMPostNetworkConfiguration,
      action.id,
      action.data,
      loginReducer.token
    );
    yield put(resetLoading(Loader.POST_VSB_SETTINGS));
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(getNetworkData(null));
      yield put(hideDialog(Dialog.STANDBY_VM_NETWORK_CONFIGURATION)); //hide dialog
      yield put(loadDNSData(null)); //clear redux values
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("source.VSB.network.success", "8EKBks")
        )
      ); //TODO verify correct Success message
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* loadNetworkData(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(startLoader());
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["network_adapter_seetings_grid"]
      )
    );
    let response = yield call(
      protectApi.standByVMGetNetworkConfiguration,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      let responseData = response.data;
      responseData.network_adapter_settings.forEach(ele => {
        if (ele.ip_settings) {
          if (ele.ip_settings.dns_addresses) {
            let dns = ele.ip_settings.dns_addresses;
            let dns_addresses_list = [];
            dns.forEach(ele2 => {
              dns_addresses_list.push({ dns_address: ele2 });
            });
            ele.dns_addresses_list = dns_addresses_list;
          }
          if (ele.ip_settings.wins_addresses) {
            let wins = ele.ip_settings.wins_addresses;
            let wins_addresses_list = [];
            wins.forEach(ele2 => {
              wins_addresses_list.push({ wins_address: ele2 });
            });
            ele.wins_addresses_list = wins_addresses_list;
          }
          if (ele.ip_settings.gateway_addresses) {
            let gateway = ele.ip_settings.gateway_addresses;
            let gateway_addresses_list = [];
            //eslint-disable-next-line array-callback-return
            gateway.map(ele2 => {
              gateway_addresses_list.push({ gateway_address: ele2 });
            });
            ele.gateway_addresses_list = gateway_addresses_list;
          }
        }
      });
      yield put(getNetworkData(responseData));
    } else {
      yield put(hideDialog(Dialog.STANDBY_VM_NETWORK_CONFIGURATION));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["network_adapter_seetings_grid"])
    );
  }
}

export function* loadBackupPasswordsData(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(startLoader());
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["setBackupPasswordGrid"])
    );
    let response = yield call(
      protectApi.backupPasswordsList,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      let responseData = response.data;
      yield put(setBackupPasswordsData(responseData));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["setBackupPasswordGrid"])
    );
  }
}

export function* backupVMsHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.convertToAgentless,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.convert_to_agentless", "qDm6vY")
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    } else if (response.status === constant.HTTP_STATUS.BAD_REQUEST) {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        for (let i = 0; i < response.errors.length; ++i) {
          let error = response.errors[i];
          if (localeData.en.hasOwnProperty(`toast-errors.cc.${error.code}`)) {
            yield put(
              ccToast.addErrorNotification(
                makeToastData(error.code, `LjdAPT_${response?.status}`),
                undefined,
                "toast-errors.cc"
              )
            );
          } else {
            yield put(
              ccToast.addErrorNotification(
                makeToastData("apiErrorResponse", `YYGEaZ_${response?.status}`),
                {
                  message: error.message
                }
              )
            );
          }
        }
      }
    } else {
      yield put(ccToast.addInfoNotification("toast-errors.internal-error"));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* deployHandler(action) {
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      protectApi.deploy,
      action.data,
      null,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("protect.sources.deploy.success", "Gabkfx")
        )
      );
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
    } else if (response.status === constant.HTTP_STATUS.BAD_REQUEST) {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        for (let i = 0; i < response.errors.length; ++i) {
          let error = response.errors[i];
          if (localeData.en.hasOwnProperty(`toast-errors.cc.${error.code}`)) {
            yield put(
              ccToast.addErrorNotification(
                makeToastData(error.code, `Jh45TF_${response?.status}`),
                undefined,
                "toast-errors.cc"
              )
            );
          } else {
            yield put(
              ccToast.addErrorNotification(
                makeToastData("apiErrorResponse", `17UrSI_${response?.status}`),
                {
                  message: error.message
                }
              )
            );
          }
        }
      }
    } else if (response.status === constant.HTTP_STATUS.NOT_FOUND) {
      yield put(
        ccToast.addInfoNotification(_.get(response, "errors[0].message))", ""))
      );
    } else {
      yield put(ccToast.addInfoNotification("toast-errors.internal-error"));
    }
  } catch (e) {
    window.console.log(e);
  }
}

//TODO fix this
// eslint-disable-next-line require-yield
export function* connectSourcePopupShow(data) {
  return fetch(API_URL.O365_POPUP_HOST_URL + API_URL.O365_POPUP_CONNECT_URL, {
    headers: new Headers(),
    method: "POST",
    body: JSON.stringify({
      username: data.username,
      password: data.password,
      site_id: "uuid",
      organization_id: "uuid"
    })
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {
      // if (response.status === 200) {
      //   this.setState({
      //     addSourceConnectPopup: true
      //   });
      // }
    })
    .catch(error => {
      // eslint-disable-next-line no-throw-literal
      throw { error };
    });
}
export function* addO365Source(data) {
  const stateLogin = yield select(getLogin);
  return fetch(API_URL.O365_POPUP_HOST_URL + API_URL.O365_ADD_SOURCE_URL, {
    headers: new Headers(),
    method: "POST",
    body: JSON.stringify({
      sourcename: "",
      username: "",
      password: "password",
      sourcetype: "office365",
      application: data.sourceType,
      site_id: "uuid",
      organization_id: stateLogin.organization_id
    })
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {})
    .catch(error => {
      // eslint-disable-next-line no-throw-literal
      throw { error };
    });
}

export function* windowsSourceSaveToList(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(clearWindowsSourcesError());
    yield put(setLoading(Loader.WINDOWS_SOURCE_SAVE_TO_LIST));
    let response = yield call(
      protectApi.postWindowsSourceList,
      data.windowsConfigObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const windowsSourceData = {
        ...data.windowsConfigObj,
        ...response.data,
        uniqueId: uuidv4()
      };
      yield put(windowsSourceSaveToListSuccess(windowsSourceData));
      yield put(clearWindowsSourcesError());
      yield put(reset(constant.WINDOWS_SOURCES));
      const site = {
        key: data?.windowsConfigObj?.site_id || "",
        value: data?.windowsConfigObj?.site_name || ""
      };
      yield put(change(constant.WINDOWS_SOURCES, "site", site));
    } else {
      yield put(windowsSourceSaveToListError(response.errors[0]));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.WINDOWS_SOURCE_SAVE_TO_LIST));
  }
}

export function* oracleWindowsSourceSaveToList(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(clearOracleSourcesError());
    yield put(setLoading(Loader.ORACLE_SOURCE_SAVE_TO_LIST));
    let response = yield call(
      protectApi.postOracleWindowsSourceList,
      data.oracleWindowsConfigObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const oracleWindowsSourceData = {
        ...data.oracleWindowsConfigObj,
        ...response.data,
        uniqueId: uuidv4()
      };
      yield put(oracleSourceSaveToListSuccess(oracleWindowsSourceData));
      yield put(clearOracleSourcesError());
      yield put(reset(constant.ORACLE_WINDOWS_SOURCE_FORM));
      const site = {
        key: data?.oracleWindowsConfigObj?.site_id || "",
        value: data?.oracleWindowsConfigObj?.site_name || ""
      };
      yield put(change(constant.ORACLE_WINDOWS_SOURCE_FORM, "site", site));
      yield put(change(constant.ORACLE_LINUX_SOURCE_FORM, "site", site));
      yield put(
        change(
          constant.ORACLE_WINDOWS_SOURCE_FORM,
          "oracle_source_type",
          data?.oracleWindowsConfigObj?.oracle_source_type || ""
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData("apiErrorResponse", `KUS934_${response?.status}`),
          {
            message: response.errors[0].message
          }
        )
      );
      yield put(oracleSourceSaveToListError(response.errors[0]));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ORACLE_SOURCE_SAVE_TO_LIST));
  }
}
export function* oracleLinuxSourceSaveToList(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(clearOracleSourcesError());
    yield put(setLoading(Loader.ORACLE_SOURCE_SAVE_TO_LIST));
    let response = yield call(
      protectApi.postOracleLinuxSourceList,
      data.oracleLinuxConfigObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const oracleWindowsSourceData = {
        ...data.oracleLinuxConfigObj,
        ...response.data,
        uniqueId: uuidv4()
      };
      yield put(oracleSourceSaveToListSuccess(oracleWindowsSourceData));
      yield put(clearOracleSourcesError());
      yield put(reset(constant.ORACLE_LINUX_SOURCE_FORM));
      const site = {
        key: data?.oracleLinuxConfigObj?.site_id || "",
        value: data?.oracleLinuxConfigObj?.site_name || ""
      };
      yield put(change(constant.ORACLE_LINUX_SOURCE_FORM, "site", site));
      yield put(change(constant.ORACLE_WINDOWS_SOURCE_FORM, "site", site));
      yield put(change(constant.ORACLE_LINUX_SOURCE_FORM, "sshPort", 22));
      yield put(
        change(
          constant.ORACLE_LINUX_SOURCE_FORM,
          "oracle_source_type",
          data?.oracleLinuxConfigObj?.oracle_source_type || ""
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData("apiErrorResponse", `vdokDL_${response?.status}`),
          {
            message: response.errors[0].message
          }
        )
      );
      yield put(oracleSourceSaveToListError(response.errors[0]));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ORACLE_SOURCE_SAVE_TO_LIST));
  }
}

export function* getOracleWindowsDBOptions(data) {
  // console.log('getOracleWindowsDBOptions',data)
  try {
    yield put(orcaleWindowsDatabaseOptionsError());
    yield put(setLoading(Loader.ORACLE_WINDOWS_DB_OPTIONS));
    const stateLogin = yield select(getLogin);
    let response = yield call(
      protectApi.getOracleWindowsDBList,
      data.sourceId,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(orcaleWindowsDatabaseOptionsSuccess(response.data));
    } else {
      yield put(orcaleWindowsDatabaseOptionsError());
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ORACLE_WINDOWS_DB_OPTIONS));
  }
}

export function* orcaleWindowsDBSaveToList(payload) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(clearOracleDBWindowsSourcesError());
    const selectedSource = payload?.selectedSource;
    const dbsettings = payload?.DbSettings;
    yield put(setLoading(Loader.ORACLE_WINDOWS_DB_SAVE_TO_LIST));
    let response = yield call(
      protectApi.postOracleWindowsDBList,
      payload.oracleWindowsConfigObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      let oracleDB =
        response?.data?.databases.length > 0 &&
        response?.data?.databases.map(data => {
          let obj = {
            os_major: selectedSource?.operating_system?.os_major,
            source_name: data.source_name,
            source_product: constant.PRODUCT_TYPE.UDP,
            product_type: selectedSource?.product_type
              ? selectedSource.product_type
              : constant.PRODUCT_TYPE.UDP,
            site_id: selectedSource?.site?.site_id,
            source_type: constant.SECONDARY_NAVIGATION_TYPE.UDP_ORACLE,
            connection_status: selectedSource?.connection_status,
            udp_oracle: {
              ...data,
              username: response?.data?.username,
              parent_id: response?.data?.host_id,
              password: payload?.oracleWindowsConfigObj?.password,
              delete_archive_logs: dbsettings?.delete_archive_logs,
              enable_block_tracking: dbsettings?.enable_block_tracking
            },
            uniqueId: uuidv4()
          };
          if (dbsettings?.channels > 0)
            obj.udp_oracle.channels = dbsettings.channels;

          return obj;
        });
      yield put(reset(constant.ORACLE_WINDOWS_DB_SOURCES));
      yield put(
        change(
          constant.ORACLE_WINDOWS_DB_SOURCES,
          "host_id",
          response?.data?.host_id
        )
      );
      yield put(oracleWindowsDBSaveToListSuccess(oracleDB[0]));
      // yield put(orcaleWindowsDatabaseOptionsError());
    } else {
      let errors = _.get(response, "errors[0]");
      yield put(oracleWindowsDBSourceSaveToListError(response.errors[0]));
      yield put(
        ccToast.addErrorNotification(
          makeToastData("apiErrorResponse", `w3FBNe_${response?.status}`),
          {
            message: errors.message
          }
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ORACLE_WINDOWS_DB_SAVE_TO_LIST));
  }
}

export function* updateAdDetails(data) {
  try {
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    data.editedAdDetails.organization_id = organizationId;
    yield put(setLoading(Loader.ADD_ACTIVE_DIRECTORY));
    let response = yield call(
      protectApi.updateAdDetailsByID,
      data.editedAdDetails,
      stateLogin.token,
      data.editedAdDetails.entry_id
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(getActiveDirectories());
      yield put(changeActiveDirectoryPanel(actionType.ADD_DISCOVER_LIST_AD));
      yield put(reset("addSourcesActiveDirectory"));
    } else {
      yield put(addAPIresponse(response));
      yield put(discoverSourcesAddFailure(response.errors));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_ACTIVE_DIRECTORY));
  }
}

export function* updatedSitesData() {
  try {
    const stateLogin = yield select(getLogin);
    let response = yield call(protectApi.getSite, stateLogin.token);
    if (response && response.length) {
      yield put(getSitesAction(response));
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* updateSiteName(data) {
  try {
    yield put(setLoading(Loader.UPDATE_SITE_NAME));
    const stateLogin = yield select(getLogin);
    // const page = yield select(gridCurrentPage);
    // const pageSize = yield select(gridPageSize);
    let response = yield call(
      protectApi.updateSiteDetailsById,
      data.sitesConfig.site_id,
      data.sitesConfig,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.SITE_NAME_UPDATE_SUCCESS,
            "1QbCO8"
          )
        )
      );
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.SITES });
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.UPDATE_SITE_NAME));
    yield put(hideDialog(Dialog.EDIT_SITE));
  }
}

export function* deleteSite(action) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.DELETE_SITE));
    // const page = yield select(gridCurrentPage);
    // const pageSize = yield select(gridPageSize);
    let response = yield call(
      protectApi.deleteSiteById,
      action.siteId,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      let id = constant.CCDATAGRID_ID.SITES;
      let page = yield select(ccDataGridSelectors.getPage(id));
      let pageSize = yield select(ccDataGridSelectors.getPages(id));
      // if totalpages is equal to current page then we will reduce pageSize by 1.
      if (page > 1 && pageSize === page) {
        const entities = yield select(ccDataGridSelectors.getEntities(id));
        if (entities.length <= 1) page = page - 1;
        yield put(ccDataGridActions.updatePage(id, page, false, true));
      }
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.SITE_DELETE_SUCCESS,
            "4rYLwp"
          )
        )
      );
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.SITES });
      yield put(getConfigureListCount());
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `mKJRrI_${response?.status}`
          )
        )
      );
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(resetLoading(Loader.DELETE_SITE));
    yield put(hideDialog(Dialog.DELETE_SITE));
  }
}

export function* addRemoteConsoleDestinations(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.ADD_REMOTE_CONSOLE));
    let response = yield call(
      protectApi.addRemoteConsoleData,
      data.addRemoteConsoleObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(remoteConsoleList());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.REMOTE_CONSOLE_ADD_SUCCESS,
            "Gt6Kzj"
          )
        )
      );
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(reset(ADD_REMOTE_CONSOLE));
    yield put(resetLoading(Loader.ADD_REMOTE_CONSOLE));
    yield put(hideDialog(Dialog.ADD_REMOTE_CONSOLE));
  }
}
export function* updateRemoteConsoleDestinations(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.ADD_REMOTE_CONSOLE));
    let response = yield call(
      protectApi.updateRemoteConsoleDetailsById,
      data.addRemoteConsoleObj.id,
      data.addRemoteConsoleObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(remoteConsoleList());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.REMOTE_CONSOLE_UPDATE_SUCCESS,
            "tbpYeP"
          )
        )
      );
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(reset(ADD_REMOTE_CONSOLE));
    yield put(resetLoading(Loader.ADD_REMOTE_CONSOLE));
    yield put(hideDialog(Dialog.ADD_REMOTE_CONSOLE));
  }
}

export function* addSourcesWindowsSource(data) {
  try {
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(setLoading(Loader.ADD_WINDOWS_SOURCES));
    let response = yield call(
      protectApi.addSources,
      data.sourcesList,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.WINDOWS_SOURCE_ADD_SUCCESS,
            "tyGZAv"
          )
        )
      );
      // yield put(getSourcesList());
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = { useApi: true };
        yield put(
          ccDataGridActions.fetchData(
            stateReducer.secondaryNavigation,
            init,
            polling,
            additionalParmeters
          )
        );
      }
      yield put(getProtectListCount());
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_WINDOWS_SOURCES));
    yield put(hideDialog(Dialog.ADD_WINDOWS_SOURCES));
  }
}
export function* addSourcesOracleSource(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.ADD_ORACLE_SOURCES));
    let response = yield call(
      protectApi.addSources,
      data.sourcesList,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.WINDOWS_SOURCE_ADD_SUCCESS,
            "hbdsLO"
          )
        )
      );
      yield put(
        getSourcesList({
          fromAddSources: true,
          filter: constant.SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS
        })
      );
      yield put(getProtectListCount());
      yield put(getConfigureListCount());
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_ORACLE_SOURCES));
    yield put(hideDialog(Dialog.ADD_ORACLE_SOURCE_MODAL));
  }
}

export function* addOracleDB(data) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.ADD_ORACLE_DB));
    let response = yield call(
      protectApi.addSources,
      data.sourcesList,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.WINDOWS_SOURCE_ADD_SUCCESS,
            "kaI7SU"
          )
        )
      );
      // yield put(getSourcesList({ fromAddSources: true }));
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = { useApi: true };
        yield put(
          ccDataGridActions.fetchData(
            stateReducer.secondaryNavigation,
            init,
            polling,
            additionalParmeters
          )
        );
        // yield put(ccDataGridActions.fetchData(filter))
      }

      yield put(getProtectListCount());
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(clearOracleDBWindowsSourcesList());
    yield put(resetLoading(Loader.ADD_ORACLE_DB));
    yield put(hideDialog(Dialog.ADD_ORACLE_DB));
  }
}

export function* addADSaveSource(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.ADD_ACTIVE_DIRECTORY_SOURCES));
    let response = yield call(
      protectApi.addSources,
      data.sourcesList,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.WINDOWS_SOURCE_ADD_SUCCESS,
            "lhMc0j"
          )
        )
      );
      // yield put(getSourcesList());
      const stateReducer = yield select(getReducer);
      // if (stateReducer.secondaryNavigation === "sources") {
      //   yield* fetchData({
      //     ccDataGridId: stateReducer.secondaryNavigation
      //   });
      // }
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
      yield put(getProtectListCount());
      yield put(clearAdData());
    } else {
      ccToast.addErrorNotification(
        makeToastData(
          constant.API_RESPONSE_CONTEXT.SOURCES_ADD_FAILURE,
          `9FFdSM_${response?.status}`
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_ACTIVE_DIRECTORY_SOURCES));
    yield put(hideDialog(Dialog.DISCOVER_SOURCES_FROM_AD));
  }
}

export function* addLinuxSources(data) {
  try {
    yield put(setLoading(Loader.LINUX_ADD_SOURCES));
    const stateLogin = yield select(getLogin);
    let response = yield call(
      protectApi.addSources,
      data.sourcesList,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.WINDOWS_SOURCE_ADD_SUCCESS,
            "vQbp44"
          )
        )
      );
      yield put(getSourcesList());
      const stateReducer = yield select(getReducer);
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = { useApi: true };
        yield put(
          ccDataGridActions.fetchData(
            stateReducer.secondaryNavigation,
            init,
            polling,
            additionalParmeters
          )
        );
      }
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.LINUX_ADD_SOURCES));
    yield put(hideDialog(Dialog.ADD_LINUX));
  }
}

export function* linuxSourcesSaveToList(data) {
  try {
    yield put(setLoading(Loader.LINUX_SOURCE_SAVE_TO_LIST));
    const stateLogin = yield select(getLogin);
    yield put(clearlinuxSourcesError());
    let response = yield call(
      protectApi.postLinuxSourceList,
      data.linuxConfigObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      if (response?.data?.warning_message) {
        const toastData = response.data.warning_message;
        yield put(
          ccToast.addWarningNotification(makeToastData(toastData, `rJboJZ`))
        );
      }
      const linuxSourceData = {
        ...data.linuxConfigObj,
        ...response.data,
        uniqueId: uuidv4()
      };
      yield put(linuxSourceSaveToListSuccess(linuxSourceData));
      yield put(clearlinuxSourcesError());
      yield put(reset(constant.LINUX_SOURCES));
      const site = {
        key: data?.linuxConfigObj?.site_id || "",
        value: data?.linuxConfigObj?.site_name || ""
      };
      yield put(change(constant.LINUX_SOURCES, "site", site));
    } else {
      yield put(linuxSourceSaveToListError(response.errors[0]));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.LINUX_SOURCE_SAVE_TO_LIST));
  }
}

export function* linuxBackupServerSourceSaveToList({ data }) {
  try {
    const stateLogin = yield select(getLogin);
    const requestBody = {
      source_name: data.source_name,
      source_type: constant.SOURCE_TYPE_LINUX_BACKUP_SERVER,
      username: data.username,
      password: data.password,
      protocol: data.protocol,
      port: data.port,
      site_id: data.site_id,
      organization_id: stateLogin.organization_id
    };
    yield put(setLoading(Loader.ADD_LBS_SAVE_TO_LIST));
    const apiResponse = yield call(
      protectApi.postLinuxBackupServerSourceSaveToList,
      requestBody,
      stateLogin.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(
        linuxBackupServerSourceSaveToListSuccess({
          ...apiResponse.data,
          ...requestBody,
          uuid: uuidv4()
        })
      );
      //Reset the form on success
      yield put(reset("addLbsSource"));
      const site = {
        key: data?.site_id || "",
        value: data?.site_name || ""
      };
      yield put(change("addLbsSource", "site", site));
    } else {
      yield put(linuxBackupServerSourceSaveToListError(apiResponse.errors[0]));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_LBS_SAVE_TO_LIST));
  }
}

export function* linuxBackupServerSourceAddSource({ data }) {
  try {
    const stateLogin = yield select(getLogin);
    let sources = data.map(source => ({
      organization_id: stateLogin.organization_id,
      source_id: source.source_id,
      source_name: source.source_name,
      site_id: source.site_id,
      source_product: constant.SOURCE_PRODUCT.UDP,
      source_type: constant.SOURCE_TYPE_LINUX_BACKUP_SERVER,
      product_type: constant.PRODUCT_TYPE.UDP,
      os_major: constant.OS_MAJOR.LINUX,
      connection_status: constant.CONNECTION_STATUS.ONLINE,
      udp_agent: {
        username: source.username,
        password: source.password,
        d2d_protocol: source.protocol,
        d2d_port_number: source.port,
        os_description: _.get(source, "os_name", "")
      }
    }));

    yield put(setLoading(Loader.ADD_LBS_ADD_SOURCE));
    const apiResponse = yield call(
      protectApi.postLinuxBackupServerSourceAddSource,
      sources,
      stateLogin.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.CREATED) {
      // Close the dialog
      yield put(hideDialog(Dialog.ADD_LINUX_BACKUP_SERVER));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .LINUX_BACKUP_SERVER_SOURCE_ADD_SUCCESS,
            "7IL1lc"
          )
        )
      );
      const stateReducer = yield select(getReducer);
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = { useApi: true };
        yield put(
          ccDataGridActions.fetchData(
            stateReducer.secondaryNavigation,
            init,
            polling,
            additionalParmeters
          )
        );
      } else {
        yield put(getSourcesList());
      }
    } else {
      // Show the toast message
      for (const error of apiResponse.errors) {
        if (
          localeData.en.hasOwnProperty(
            `error.${constant.API_RESPONSE_CONTEXT.LINUX_BACKUP_SERVER_SOURCE_ADD}.${error.code}`
          )
        ) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                `error.${constant.API_RESPONSE_CONTEXT.LINUX_BACKUP_SERVER_SOURCE_ADD}.${error.code}`,
                `hTmiW0_${apiResponse?.status}`
              )
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "apiErrorResponse",
                `8Via6q_${apiResponse?.status}`
              ),
              {
                message: error.message
              }
            )
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_LBS_ADD_SOURCE));
  }
}

export function* discoverSourcesAdd(data) {
  try {
    const stateLogin = yield select(getLogin);
    const { activeDirectory } = data;
    const requestBody = {
      username: activeDirectory.userName,
      password: activeDirectory.password,
      computer_name_filter: activeDirectory.computerFilter,
      site_id: activeDirectory.site.key,
      organization_id: stateLogin.organization_id,
      domain_controller: activeDirectory.domainController
    };
    yield put(setLoading(Loader.ADD_ACTIVE_DIRECTORY));
    const apiResponse = yield call(
      protectApi.postAddActiveDirectoryToList,
      requestBody,
      stateLogin.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(getActiveDirectories());
      yield put(changeActiveDirectoryPanel(actionType.ADD_DISCOVER_LIST_AD));
      yield put(reset("addSourcesActiveDirectory"));
      const site = {
        key: activeDirectory?.site.key || "",
        value: activeDirectory?.site.value || ""
      };
      yield put(change("addSourcesActiveDirectory", "site", site));
    } else {
      yield put(discoverSourcesAddFailure(apiResponse.errors));
      yield put(addAPIresponse(apiResponse));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_ACTIVE_DIRECTORY));
  }
}

export function* authenticateADWindows(source, data, token) {
  try {
    const payload = {
      site_id: data.site_id,
      source_name: data.source_name,
      username: data.username,
      password: data.password,
      source_type: data.source_type
    };
    let resp = yield call(protectApi.postWindowsSourceList, payload, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        adWinSourceAuthSuccess({
          ...source,
          site_id: data.site_id,
          username: data.username,
          password: data.password,
          ...resp.data
        })
      );
    } else {
      yield put(
        adWinSourceAuthFailure({
          source,
          error_message: resp.errors[0].message
        })
      );
    }
  } catch (error) {
    yield put(
      adWinSourceAuthFailure({
        ...source,
        site_id: data.site_id,
        username: data.username,
        password: data.password
      })
    );
    window.console.log(error);
  } finally {
    yield put(adIncrementAuthCount());
  }
}

export function* authenticateADWindowsSources(data) {
  try {
    const stateLogin = yield select(getLogin);
    const { selectedList, authConfig, siteId } = data;
    let selectedListWithCredentials = [];
    let apiCallStack = [];
    selectedList.forEach(selectedItem => {
      let windowsConfigObj = { ...selectedItem };
      windowsConfigObj.site_id = siteId;
      windowsConfigObj.username = authConfig.username;
      windowsConfigObj.source_name = selectedItem.node_name;
      windowsConfigObj.source_type = "udp_windows";
      windowsConfigObj.password = authConfig.password;
      selectedListWithCredentials.push(windowsConfigObj);
      apiCallStack.push(
        fork(
          authenticateADWindows,
          selectedItem,
          windowsConfigObj,
          stateLogin.token
        )
      );
    });
    yield put(setLoading(Loader.ACTIVE_DIRECTORY_SOURCES_AUTHENTICATION));
    yield put(adInitAuthCount(apiCallStack.length));
    for (let forkCall of apiCallStack) {
      yield forkCall;
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideActiveDirectoryAuthenticationModal());
    yield put(resetLoading(Loader.ACTIVE_DIRECTORY_SOURCES_AUTHENTICATION));
    yield put(clearStateSelectedRows());
  }
}

export function* browseActiveDirectories(data) {
  try {
    const stateLogin = yield select(getLogin);
    const { activeDirectories } = data;
    yield put(startLoader());
    const apiResponse = yield call(
      protectApi.postBrowseActiveDirectoriesToList,
      activeDirectories[0],
      stateLogin.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      const adSourcesWithAuthVal = apiResponse.data.map(obj => ({
        ...obj,
        id: uuidv4(),
        showErrorIcon: false
      }));
      yield put(addSourcesFromActiveDirectory(adSourcesWithAuthVal));
      yield put(
        changeActiveDirectoryPanel(actionType.ADD_DISCOVER_BROWSE_AD_SOURCES)
      );
    } else {
      yield put(addAPIresponse(apiResponse));
      if (apiResponse.errors.length) {
        yield put(addFetchADSourcesError(apiResponse.errors[0]));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* deleteActiveDirectory({ data }) {
  try {
    yield put(setLoading(Loader.DELETE_AD_SOURCE));
    const stateLogin = yield select(getLogin);
    yield put(startLoader());
    const apiResponse = yield call(
      protectApi.deleteActiveDirectoryByID,
      data,
      stateLogin.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(getActiveDirectories());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DELETE_AD_SUCCESS,
            "7IL1lc"
          )
        )
      );
    } else if (apiResponse && apiResponse.errors.length > 0) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            apiResponse.errors[0]
              ? apiResponse.errors[0].message
              : apiResponse.errors,
            `NXkfVZ__${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.DELETE_AD_SOURCE));
    yield put(hideDialog(Dialog.DELETE_AD_SOURCE));
  }
}

export function* discoverSourcesSourceNames() {
  try {
    const stateLogin = yield select(getLogin);
    const apiResponse = yield call(
      protectApi.getDisoverSourceName,
      stateLogin.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK)
      yield put(getDisoverSourceName(apiResponse.sourceName));
    else
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DISCOVER_GET_SOURCE_NAME_FAILURE,
            `hmSGQ2_${apiResponse?.status}`
          )
        )
      );
  } catch (e) {
    window.console.log(e);
  }
}
export function* verifyCredential(data) {
  try {
    const apiResponse = yield call(protectApi.postVerifyCredential, data.data);
    if (
      apiResponse.status !== constant.HTTP_STATUS.OK &&
      apiResponse.password !== data.password
    ) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DISCOVER_SOURCE_CONNECT_FAILURE,
            `kgSQxU_${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* discoverSourceAddSource(sourcesData) {
  try {
    const data = sourcesData.data;
    const stateLogin = yield select(getLogin);
    let sources = [];
    for (let i = 0; i < data.length; i++) {
      sources = [
        ...sources,
        {
          source_id: constant.SOURCE_ID,
          source_name: data[i].sourceName,
          source_type: constant.SOURCE_TYPE_LINUX,
          username: data[i].userName,
          password: data[i].password,
          site_id: data[i].siteId,
          organization_id: stateLogin.organization_id
        }
      ];
    }
    const apiResponse = yield call(
      protectApi.postDiscoverSourceAddSource,
      sources
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK && data.length > 0) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .LINUX_BACKUP_SERVER_SOURCE_ADD_SUCCESS,
            "rK5NWL"
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .LINUX_BACKUP_SERVER_SOURCE_ADD_FAILURE,
            `6v5L7I_${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* filterAddedVMs(data) {
  if (data.children && data.children.length > 0) {
    // data.children = data.children.filter(
    //   item =>
    //     _.get(item, "vm_info.is_source_exists") === false ||
    //     _.get(item, "vm_info") === undefined
    // );

    for (let i = 0; i < data.children.length; i++) {
      data.children[i] = yield call(filterAddedVMs, data.children[i]);
    }
  }
  return data;
}

/**
 * Saga to connect to VMware vCenter / ESX
 * and update the redux state
 * @param {*} data
 */
export function* connectHypervisorVmware({ data }) {
  try {
    yield put(startLoader());
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    yield put(setLoading(Loader.ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON));
    const token = yield select(getLoginToken);
    const stateReducer = yield select(getReducer);
    const isSource =
      stateReducer &&
      [
        constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
        constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
        constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
        constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
      ].includes(stateReducer.secondaryNavigation);
    let resp = null;
    if (isSource) {
      resp = yield call(
        protectApi.postHypervisorVmwareConnect,
        data,
        token,
        true
      );
    } else {
      resp = yield call(
        // protectApi.postHypervisorVmwareConnectV2,
        protectApi.postHypervisorVmwareConnectV3,
        data,
        token,
        true
      );
    }
    if (resp.status === constant.HTTP_STATUS.OK) {
      // resp.data = yield call(filterAddedVMs, resp.data);
      resp.data.rootNode = true; // to know which node is the root node and to prevent the whole conatiner to be selected when only its children were selected
      resp.data.identifier_id = resp.data.ref_id;
      resp.data.height = 0;
      resp.data.hidePrevButton = data.hidePrevButton ? true : false;
      const sourceTabFormValues = yield select(getFormValues("sourceTabForm"));
      let existingSourceData = [];
      if (sourceTabFormValues && sourceTabFormValues.sources) {
        existingSourceData = sourceTabFormValues.sources.filter(
          s => s.hypervisor_id === data.hypervisor_id
        );
      }
      existingSourceData = existingSourceData.map(source => source.source_id);
      const mapTreeAndSetParent = (data, identifier_id, parentList, height) => {
        return data.map(node => {
          node.parent = identifier_id;
          node.ancestors = parentList;
          node.height = height;
          //if the source is present in current hypervisor , then it must be selected in tree
          let exist = false;
          if (
            node.vm_info &&
            existingSourceData.includes(node.vm_info.vm_instance_uuid)
          )
            exist = true;
          node.exist = exist;
          if (!node.children) return { ...node, identifier_id: node.ref_id };
          node.identifier_id = node.ref_id;
          return {
            ...node,
            children: mapTreeAndSetParent(
              node.children,
              node.ref_id,
              parentList + "," + node.ref_id,
              height + 1
            )
          };
        });
      };
      let modifiedResponse = resp.data;
      modifiedResponse.children =
        resp.data &&
        resp.data.children &&
        mapTreeAndSetParent(
          resp.data.children,
          resp.data.identifier_id,
          resp.data.identifier_id,
          1
        );
      yield put(addSourcesHypervisorVmwareConnectSuccess(modifiedResponse));
      yield put(addSourcesSetHypervisorId(resp.data.hypervisor_id));
      yield put(protectAction.setSelectedSite(resp.data.site_id));
      // If the request data had only hypervisor_id then on success response display the tree modal popup.
      if (data.hypervisor_id) {
        yield put(
          change("addSourceFromHyper", "hypervisor_type", "vcenter_esx")
        );
        yield put(change("addSourceFromHyper", "protocol", "https"));
        yield put(change("addSourceFromHyper", "port", 443));
        yield put(showDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_POLICY));
      }
    } else {
      yield put(addSourcesHypervisorConnectError(resp.errors || []));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    yield put(resetLoading(Loader.ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON));
    yield put(stopLoader());
  }
}

export function* browseSharedFoldersDestinations({
  data,
  namespace,
  formName
}) {
  try {
    const token = yield select(getLoginToken);
    let resp = yield call(protectApi.browseSharedFolders, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      const maxVal = _.maxBy(resp.data, "total_size"); //set the disk with max size as default path
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.vm_settings.storage_settings.vm_datastore_path`,
          `${maxVal.path}udpvm`
        )
      );
      yield put(setInitialPath(`${maxVal.path}udpvm`));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
  }
}

/**
 * Add Sources from Hypervisor
 */
export function* addSourcesFromHypervisor() {
  try {
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
    const login = yield select(getLogin);
    const vms = yield select(getSelectedVms);
    const site = yield select(getSelectedSite);
    const hypervisorId = yield select(getHypervisorId);
    const data = vms.map(vm => {
      let vmData = {
        source_id: vm.vm_instance_uuid,
        source_name: !vm.vm_hostname ? vm.vm_name : vm.vm_hostname,
        site_id: site.id,
        organization_id: login.organization_id,
        source_product: constant.SOURCE_PRODUCT.UDP,
        source_type: constant.SOURCE_TYPE_AGENTLESS_VM,
        product_type: constant.PRODUCT_TYPE.UDP,
        connection_status: "online",
        os_major: vm.windows_os ? "windows" : "linux",
        hypervisor_id: hypervisorId,
        udp_vm: {
          hypervisor_id: hypervisorId,
          username: vm.vm_info && vm.vm_info.username,
          password: vm.vm_info && vm.vm_info.password,
          vm_name: vm.vm_name,
          vm_uuid: vm.vm_uuid,
          vm_hostname: vm.vm_hostname,
          vm_instance_uuid: vm.vm_instance_uuid,
          vm_esx_host: vm.vm_esx_host,
          vm_esx_socket_count: vm.vm_esx_socket_count,
          vm_xpath: vm.vm_xpath,
          vm_guest_os: vm.vm_guest_os,
          vm_ip: vm.vm_ip,
          is_running: true,
          // windows_os: true, // remove for linux
          global_id_for_webservice: vm.global_id_for_webservice
        }
      };

      // Add windows os only if its windows
      if (vmData.os_major === "windows") {
        vmData.udp_vm.windows_os = true;
      }

      // Requested to be added on 975190
      if (!vmData.udp_vm.vm_guest_os) {
        vmData.os_major = "";
      }

      // Add user credentials only if its given
      if (vmData.username) {
        vmData.udp_vm.username = vmData.username;
        vmData.udp_vm.password = vmData.password;
      }

      return vmData;
    });
    const apiResponse = yield call(
      protectApi.postHypervisorAddSources,
      data,
      login.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.CREATED) {
      // Close the dialog
      yield put(hideDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR));
      //Clear hypervisor form
      yield put(clearHyperVisorForm());
      yield put(getSourcesList());
      const stateReducer = yield select(getReducer);
      if (stateReducer.secondaryNavigation === "sources") {
        yield* fetchData({
          ccDataGridId: stateReducer.secondaryNavigation
        });
      }
      yield put(resetAddSourcesHypervisor());
      // Show the toast message
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES_SUCCESS,
            "jHU5iL"
          )
        )
      );
      yield put(getProtectListCount());
    } else {
      // Show the toast message
      for (const error of apiResponse.errors) {
        if (
          localeData.en.hasOwnProperty(
            `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`
          )
        ) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`,
                `FWu6bP_${apiResponse?.status}`
              )
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "apiErrorResponse",
                `ekqPrs_${apiResponse?.status}`
              ),
              {
                message: error.message
              }
            )
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  }
}

/**
 * Add sources for Agentless Policy Contianer Discovery
 */
export function* addSourcesFromHypervisorPolicyEsx(VMsList) {
  try {
    // yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
    const login = yield select(getLogin);
    // const vms = yield select(getSelectedVms);
    const vms = VMsList && VMsList.data ? VMsList.data : VMsList;
    const siteState = yield select(getSelectedSite);
    const site = _.get(VMsList && VMsList.data[0], "site_id");
    const hypervisorId = yield select(getHypervisorId);
    const hypervisor_inventory_type = yield select(
      getFormValues("addSourceFromHyper")
    );
    // const data = vms.map(vm => {
    //   let vmData = {
    //     hypervisor_id: hypervisorId,
    //     site_id: site.id,
    //     organization_id: login.organization_id,
    //     hypervisor_inventory_type: hypervisor_inventory_type.inventory.key,
    //     discovery_entity_info: {
    //       vm_info: {
    //         os_major: vm.windows_os ? "windows" : "linux",
    //         vm_name: vm.vm_name,
    //         vm_uuid: vm.vm_uuid,
    //         vm_hostname: vm.vm_hostname,
    //         vm_instance_uuid: vm.vm_instance_uuid,
    //         vm_esx_host: vm.vm_esx_host,
    //         vm_esx_socket_count: vm.vm_esx_socket_count,
    //         vm_xpath: vm.vm_xpath,
    //         vm_guest_os: vm.vm_guest_os,
    //         vm_ip: vm.vm_ip,
    //         is_running: true,
    //         global_id_for_webservice: vm.global_id_for_webservice
    //       }
    //     }
    //   };
    //   return vmData;
    // });
    const data = {
      hypervisor_id: hypervisorId,
      site_id: site || (siteState && siteState.id),
      organization_id: login.organization_id,
      hypervisor_inventory_type:
        (hypervisor_inventory_type.inventory_type &&
          _.get(hypervisor_inventory_type, "inventory_type.key", "")) ||
        (hypervisor_inventory_type.edit_inventory_type &&
          _.get(hypervisor_inventory_type, "edit_inventory_type.key", "")),
      discovery_entity_info: vms && vms.length ? vms[0] : {}
    };
    const api_Response = yield call(
      protectApi.postHypervisorAddSourcesPoliciesContainer,
      data,
      login.token
    );
    const state = yield select();
    const policydetails = _.get(state, "api.policy.policy", {});
    const policy_id = policydetails.policy_id;
    if (api_Response && api_Response.data && api_Response.data.length > 0) {
      let data = api_Response.data;
      if (policy_id)
        data = data.filter(
          ele => ele.policy && ele.policy.policy_id !== policy_id
        );
      yield put(saveContainerPoliciesdata(api_Response.data));
      if (data && data.length > 0) {
        data = data.map(r => ({
          ...r,
          id: uuidv4()
        }));
        data = data.map(r => ({
          ...r,
          _rowId: r["id"]
        }));
        api_Response.data = data;
        yield put(
          ccDataGridActions.fetchDataSuccess(
            constant.CCDATAGRID_ID.CONTAINER_POLICY,
            api_Response,
            data
          )
        );
        yield put(
          showDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_CONTAINER_POLICY)
        );
        yield take([actionType.CONTINUE_CONTAINERS_SAGA]);
      }
    }
    const apiResponse = yield call(
      protectApi.postHypervisorAddSourcesContainer,
      data,
      login.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      if (apiResponse.data && apiResponse.data.length > 0) {
        const sourceTabFormValues = yield select(
          getFormValues("sourceTabForm")
        );
        let existingSourceData = [];
        if (sourceTabFormValues && sourceTabFormValues.sources) {
          existingSourceData = sourceTabFormValues.sources.filter(
            s => s.hypervisor_id !== hypervisorId
          );
        }
        // Segregate the response and stored into sources and container data object from the policy data object.
        let apiSourceData = [];
        let sourceData = [];
        //eslint-disable-next-line array-callback-return
        apiResponse.data.map(item => {
          const isVM = item.entity_type === "VirtualMachine";
          apiSourceData.push({
            source_id: isVM ? item.entity_id : item.ref_id || item.entity_name,
            source_type: "agentless_vm",
            source_name: isVM ? item.entity_name : item.path,
            source_group: [],
            operating_system: {
              os_major: item.vm_guest_os
                ? item.vm_guest_os.includes("Windows")
                  ? "windows"
                  : item.vm_guest_os.includes("Linux")
                  ? "linux"
                  : ""
                : ""
            },
            entity_type: item.entity_type,
            hypervisor_id: item.hypervisor_id,
            treeFields: item
          });
        });
        if (apiSourceData && apiSourceData.length > 0) {
          if (existingSourceData && existingSourceData.length > 0) {
            sourceData = existingSourceData.concat(apiSourceData);
          } else {
            sourceData = apiSourceData;
          }
        } else {
          sourceData = existingSourceData;
        }

        if (sourceData && sourceData.length > 0) {
          yield put(change("sourceTabForm", "sources", sourceData));
          yield put(change("sourceTabForm", "hiddenSources", sourceData));
        }
      }
      // Close the dialog
      yield put(hideDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_POLICY));
      //Clear hypervisor form
      yield put(clearHyperVisorForm());
      yield put(getSourcesList());
      const stateReducer = yield select(getReducer);
      if (stateReducer.secondaryNavigation === "sources") {
        yield* fetchData({
          ccDataGridId: stateReducer.secondaryNavigation
        });
      }
      yield put(resetAddSourcesHypervisor());
      yield put(reset("addSourceFromHyper"));
      // Show the toast message
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES_SUCCESS,
            "8rmqEO"
          )
        )
      );
      yield put(getProtectListCount());
    } else {
      // Show the toast message
      for (const error of apiResponse.errors) {
        if (
          localeData.en.hasOwnProperty(
            `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`
          )
        ) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`,
                `zhIQil_${apiResponse?.status}`
              )
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "apiErrorResponse",
                `H8sxzM_${apiResponse?.status}`
              ),
              {
                message: error.message
              }
            )
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  }
}

export function* addSourcesFromHypervisorPolicyHV(VMsList) {
  try {
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
    const login = yield select(getLogin);
    const vms = VMsList && VMsList.data ? VMsList.data : VMsList;
    const siteState = yield select(getSelectedSite);
    const site = _.get(VMsList && VMsList.data[0], "site_id");
    const hypervisorId = yield select(getHypervisorId);
    const hypervisor_inventory_type = yield select(
      getFormValues("addSourceFromHyper")
    );
    const data = {
      hypervisor_id: hypervisorId,
      site_id: site || (siteState && siteState.id),
      organization_id: login.organization_id,
      hypervisor_inventory_type:
        (hypervisor_inventory_type.inventory_type &&
          hypervisor_inventory_type.inventory_type.key) ||
        hypervisor_inventory_type.edit_inventory_type.key,
      discovery_entity_info: vms && vms.length ? vms[0] : {}
    };
    const api_Response = yield call(
      protectApi.postHypervisorAddSourcesPoliciesContainer,
      data,
      login.token
    );
    const state = yield select();
    const policydetails = _.get(state, "api.policy.policy", {});
    const policy_id = policydetails.policy_id;
    if (api_Response && api_Response.data && api_Response.data.length > 0) {
      let data = api_Response.data;
      if (policy_id)
        data = data.filter(
          ele => ele.policy && ele.policy.policy_id !== policy_id
        );
      yield put(saveContainerPoliciesdata(api_Response.data));
      if (data && data.length > 0) {
        data = data.map(r => ({
          ...r,
          id: uuidv4()
        }));
        data = data.map(r => ({
          ...r,
          _rowId: r["id"]
        }));
        api_Response.data = data;
        yield put(
          ccDataGridActions.fetchDataSuccess(
            constant.CCDATAGRID_ID.CONTAINER_POLICY,
            api_Response,
            data
          )
        );
        yield put(
          showDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_CONTAINER_POLICY)
        );
        yield take([actionType.CONTINUE_CONTAINERS_SAGA]);
      }
    }
    const apiResponse = yield call(
      protectApi.postHypervisorAddSourcesContainer,
      data,
      login.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      if (apiResponse.data && apiResponse.data.length > 0) {
        const sourceTabFormValues = yield select(
          getFormValues("sourceTabForm")
        );
        let existingSourceData = [];
        if (sourceTabFormValues && sourceTabFormValues.sources) {
          existingSourceData = sourceTabFormValues.sources.filter(
            s => s.hypervisor_id !== hypervisorId
          );
        }
        // Segregate the response and stored into sources and container data object from the policy data object.
        let apiSourceData = [];
        let sourceData = [];
        //eslint-disable-next-line array-callback-return
        apiResponse.data.map(item => {
          const isVM =
            item.entity_type === "VM-StandAlone" ||
            item.entity_type === "VM-Cluster";
          apiSourceData.push({
            source_id: isVM ? item.entity_id : item.ref_id || item.entity_name,
            source_type: "agentless_vm",
            source_name: isVM ? item.entity_name : item.path,
            source_group: [],
            operating_system: {
              os_major: item.vm_guest_os
                ? item.vm_guest_os.includes("Windows")
                  ? "windows"
                  : item.vm_guest_os.includes("Linux")
                  ? "linux"
                  : ""
                : ""
            },
            entity_type: item.entity_type,
            hypervisor_id: item.hypervisor_id,
            treeFields: item
          });
        });

        if (apiSourceData && apiSourceData.length > 0) {
          if (existingSourceData && existingSourceData.length > 0) {
            sourceData = existingSourceData.concat(apiSourceData);
          } else {
            sourceData = apiSourceData;
          }
        } else {
          sourceData = existingSourceData;
        }

        if (sourceData && sourceData.length > 0) {
          yield put(change("sourceTabForm", "sources", sourceData));
          yield put(change("sourceTabForm", "hiddenSources", sourceData));
        }
      }
      // Close the dialog
      yield put(hideDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_POLICY));
      //Clear hypervisor form
      yield put(clearHyperVisorForm());
      yield put(getSourcesList());
      const stateReducer = yield select(getReducer);
      if (stateReducer.secondaryNavigation === "sources") {
        yield* fetchData({
          ccDataGridId: stateReducer.secondaryNavigation
        });
      }
      yield put(resetAddSourcesHypervisor());
      yield put(reset("addSourceFromHyper"));
      // Show the toast message
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES_SUCCESS,
            "MtrJxt"
          )
        )
      );
      yield put(getProtectListCount());
    } else {
      // Show the toast message
      for (const error of apiResponse.errors) {
        if (
          localeData.en.hasOwnProperty(
            `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`
          )
        ) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`,
                `3JH5av_${apiResponse?.status}`
              )
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "apiErrorResponse",
                `GclDFJ_${apiResponse?.status}`
              ),
              {
                message: error.message
              }
            )
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  }
}

/**
 * Add Sources from Hypervisor
 */
export function* addHypervisorSources() {
  try {
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
    const login = yield select(getLogin);
    let vms = yield select(getSelectedVms);
    vms = {
      vm_ids: vms.map(item => ({
        source_id: item.source_id,
        vm_name: item.vm_name,
        vm_major_os: item.vm_major_os,
        vm_guest_os: item.vm_guest_os
      }))
    };
    let postBody = Object.assign({}, vms);
    const hypervisorId = yield select(getHypervisorId);

    const apiResponse = yield call(
      protectApi.addNewSources,
      postBody,
      login.token,
      hypervisorId
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      // Close the dialog
      yield put(hideDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR));
      //Clear hypervisor form
      yield put(clearHyperVisorForm());
      yield put(getSourcesList());
      const stateReducer = yield select(getReducer);
      if (stateReducer.secondaryNavigation === "sources") {
        yield* fetchData({
          ccDataGridId: stateReducer.secondaryNavigation
        });
      }
      // Show the toast message
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES_SUCCESS,
            "eTloFH"
          )
        )
      );
    } else {
      // Show the toast message
      for (const error of apiResponse.errors) {
        if (
          localeData.en.hasOwnProperty(
            `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`
          )
        ) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                `error.${constant.API_RESPONSE_CONTEXT.HYPERVISOR_ADD_SOURCES}.${error.code}`,
                `x3q80W_${apiResponse?.status}`
              )
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "apiErrorResponse",
                `RQtGaV_${apiResponse?.status}`
              ),
              {
                message: error.message
              }
            )
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  }
}

export function* connectHypervisorHyperV({ data }) {
  try {
    yield put(startLoader());
    yield put(clearHyperVisorForm([]));
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    yield put(setLoading(Loader.ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON));
    const token = yield select(getLoginToken);
    const stateReducer = yield select(getReducer);
    const isSource =
      stateReducer &&
      [
        constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
        constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
        constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
        constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
      ].includes(stateReducer.secondaryNavigation);
    let resp = null;
    if (isSource) {
      //resp = yield call(protectApi.postHypervisorHyperVConnect, data, token);
      resp = yield call(protectApi.postHypervisorHyperVConnectV3, data, token);
    } else {
      //resp = yield call(protectApi.postHypervisorHyperVConnectV2, data, token);
      // resp = yield call(protectApi.postHypervisorHyperVConnectV3, data, token);
      resp = yield call(protectApi.postHypervisorHyperVConnectV4, data, token);
    }

    if (resp.status === constant.HTTP_STATUS.OK) {
      const hypervisorID = resp.data.hypervisor_id;
      resp.data = yield call(filterAddedVMs, resp.data.hypervisor_info);
      if (isSource) {
        resp.data.navigation = "sources";
      }
      resp.data.hypervisor_id = hypervisorID;
      if (resp.data.vm_info) {
        resp.data.vm_info = resp.data.vm_info.filter(
          item => _.get(item, "is_source_exists") === false
        );
      }
      resp.data.rootNode = true; // to know which node is the root node and to prevent the whole conatiner to be selected when only its children were selected
      resp.data.identifier_id = resp.data.ref_id;
      resp.data.hidePrevButton = data.hidePrevButton ? true : false;
      resp.data.height = 0;
      const sourceTabFormValues = yield select(getFormValues("sourceTabForm"));
      let existingSourceData = [];
      if (sourceTabFormValues && sourceTabFormValues.sources) {
        existingSourceData = sourceTabFormValues.sources.filter(
          s => s.hypervisor_id === data.hypervisor_id
        );
      }
      existingSourceData = existingSourceData.map(source => source.source_id);
      const mapTreeAndSetParent = (data, identifier_id, parentList, height) => {
        return data.map(node => {
          node.parent = identifier_id;
          node.ancestors = parentList;
          node.height = height;
          //if the source is present in current hypervisor , then it must be selected in tree
          let exist = false;
          if (
            node.vm_info &&
            existingSourceData.includes(
              node.vm_info.vm_instance_uuid.toLowerCase()
            )
          )
            exist = true;
          node.exist = exist;
          if (!node.children) return { ...node, identifier_id: node.ref_id };
          node.identifier_id = node.ref_id;
          return {
            ...node,
            children: mapTreeAndSetParent(
              node.children,
              node.ref_id,
              parentList + "," + node.ref_id,
              height + 1
            )
          };
        });
      };
      let modifiedResponse = resp.data;
      modifiedResponse.children =
        resp.data &&
        resp.data.children &&
        mapTreeAndSetParent(
          resp.data.children,
          resp.data.identifier_id,
          resp.data.identifier_id,
          1
        );
      yield put(addSourcesHypervisorHyperVConnectSuccess(modifiedResponse));
      yield put(addSourcesSetHypervisorId(resp.data.hypervisor_id));
      yield put(protectAction.setSelectedSite(resp.data.site_id));
      // If the request data had only hypervisor_id then on success response display the tree modal popup.
      if (data.hypervisor_id) {
        yield put(change("addSourceFromHyper", "hypervisor_type", "hyperv"));
        yield put(change("addSourceFromHyper", "protocol", ""));
        yield put(change("addSourceFromHyper", "port", ""));
        yield put(showDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_POLICY));
      }
    } else {
      yield put(addSourcesHypervisorConnectError(resp.errors || []));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    yield put(resetLoading(Loader.ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON));
    yield put(stopLoader());
  }
}

export function* connectHypervisorNutanix({ data }) {
  try {
    yield put(startLoader());
    yield put(clearHyperVisorForm([]));
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    yield put(setLoading(Loader.ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON));
    const token = yield select(getLoginToken);
    const stateReducer = yield select(getReducer);
    const isSource =
      stateReducer &&
      [
        constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
        constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
        constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
        constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
      ].includes(stateReducer.secondaryNavigation);
    let resp = yield call(protectApi.postHypervisorNutanixonnect, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      const hypervisorID = resp.data.hypervisor_id;
      resp.data = yield call(filterAddedVMs, resp.data);
      if (isSource) {
        resp.data.navigation = "sources";
      }
      resp.data.hypervisor_id = hypervisorID;
      resp.data.rootNode = true; // to know which node is the root node and to prevent the whole conatiner to be selected when only its children were selected
      resp.data.identifier_id = resp.data.ref_id;
      resp.data.hidePrevButton = data.hidePrevButton ? true : false;
      resp.data.height = 0;
      const sourceTabFormValues = yield select(getFormValues("sourceTabForm"));
      let existingSourceData = [];
      if (sourceTabFormValues && sourceTabFormValues.sources) {
        existingSourceData = sourceTabFormValues.sources.filter(
          s => s.hypervisor_id === data.hypervisor_id
        );
      }
      existingSourceData = existingSourceData.map(source => source.source_id);
      const mapTreeAndSetParent = (data, identifier_id, parentList, height) => {
        return data.map(node => {
          node.parent = identifier_id;
          node.ancestors = parentList;
          node.height = height;
          //if the source is present in current hypervisor , then it must be selected in tree
          let exist = false;
          if (
            node.vm_info &&
            existingSourceData.includes(
              node.vm_info.vm_instance_uuid.toLowerCase()
            )
          )
            exist = true;
          node.exist = exist;
          if (!node.children) return { ...node, identifier_id: node.ref_id };
          node.identifier_id = node.ref_id;
          return {
            ...node,
            children: mapTreeAndSetParent(
              node.children,
              node.ref_id,
              parentList + "," + node.ref_id,
              height + 1
            )
          };
        });
      };
      let modifiedResponse = resp.data;
      modifiedResponse.children =
        resp.data &&
        resp.data.children &&
        mapTreeAndSetParent(
          resp.data.children,
          resp.data.identifier_id,
          resp.data.identifier_id,
          1
        );
      yield put(addSourcesHypervisorNutanixConnectSuccess(modifiedResponse));
      yield put(addSourcesSetHypervisorId(resp.data.hypervisor_id));
      yield put(protectAction.setSelectedSite(resp.data.site_id));
      // If the request data had only hypervisor_id then on success response display the tree modal popup.
      if (data.hypervisor_id) {
        yield put(change("addSourceFromHyper", "hypervisor_type", "nutanix"));
        yield put(change("addSourceFromHyper", "protocol", ""));
        yield put(change("addSourceFromHyper", "port", ""));
        yield put(showDialog(Dialog.ADD_SOURCE_FROM_HYPERVISOR_POLICY));
      }
    } else {
      yield put(addSourcesHypervisorConnectError(resp.errors || []));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
    yield put(resetLoading(Loader.ADD_SOURCE_FROM_HYPERVISOR_NEXT_BUTTON));
    yield put(stopLoader());
  }
}

//TODO refactor loadSites and loadRegisterSites
export function* loadSitesData({ page, pageSize }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const stateReducer = yield select(getReducer);
    let resp = null;

    //We are not showing cloud direct sites on Configure sites page so only fetch udp sites
    if (
      stateReducer &&
      stateReducer.secondaryNavigation ===
        constant.SECONDARY_NAVIGATION_TYPE.SITES
    ) {
      resp = yield call(
        protectApi.getSites,
        page,
        pageSize,
        orgId,
        login.token,
        false,
        `site_type=gateway&product_type=udp`
      );
    } else {
      resp = yield call(
        protectApi.getSites,
        page,
        pageSize,
        orgId,
        login.token
      );
    }
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadSitesSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadActiveDirectoryList() {
  try {
    yield put(startLoader());
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["discover_sources_active_directory_grid"]
      )
    );
    yield put(clearStateSelectedRows());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(protectApi.getActiveDirectory, orgId, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(discoverSourcesAddSuccess(resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(
        constant.DATAGRID_IDS["discover_sources_active_directory_grid"]
      )
    );
  }
}

export function* loadMSPCDSites(resp) {
  const login = yield select(getLogin);
  const orgId = yield select(getOrgId);
  const stateReducer = yield select(getReducer);
  const getLoginCurrent = state => state.login;
  const currentLogin = yield select(getLoginCurrent);
  if (
    stateReducer.customerOrgType === constant.SUB_MSP_ORG_TYPE ||
    currentLogin.organization_type === constant.SUB_MSP_ORG_TYPE
  ) {
    const orgProperties = yield call(
      protectApi.fetchOrganizationInfo,
      orgId,
      login.token
    );
    if (orgProperties && _.get(orgProperties, "data.parent_id")) {
      const mspresp = yield call(
        protectApi.getSites,
        1,
        20,
        _.get(orgProperties, "data.parent_id"),
        login.token,
        false,
        "site_type=cloud_direct"
      );
      if (mspresp.data && resp.data) {
        resp = {
          ...resp,
          data: resp.data.concat(mspresp.data)
        };
      }
    }
  }
  return resp;
}

export function* loadAllHypervisors() {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    let query = `organization_id=${orgId ||
      login.organizationInfo
        .organization_id}&is_registered=true&pagination=false`;

    let resp = yield call(protectApi.fetchHypervisorList, query, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(listHypervisorsSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* loadRegisteredSites({ siteType }) {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    let query =
      siteType && siteType.length
        ? `organization_id=${orgId ||
            login.organizationInfo
              .organization_id}&is_registered=true&pagination=false&site_type=${siteType}`
        : `organization_id=${orgId ||
            login.organizationInfo
              .organization_id}&is_registered=true&pagination=false`;
    let resp = yield call(protectApi.getRegisteredSites, query, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadRegisteredSitesSuccess(resp.data));
    } else {
      if (resp.status >= 400) {
        if (resp.status === constant.HTTP_STATUS.INTERNAL_SERVER_ERROR) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData("error.spog.500", "yjYCfn")
            )
          );
        } else if (resp.status === constant.HTTP_STATUS.UNAUTHORIZED) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData("error.spog.500", "H3VRkz")
            )
          );
        } else if (resp.status === constant.HTTP_STATUS.FORBIDDEN) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData("error.spog.401", "CFlqdm")
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData("protect.sources.error", `cgBHMq_${resp.status}`),
              { message: _.get(resp, "errors[0].message") }
            )
          );
        }
      }
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Protect > Destinations > Cloud Account > Create Cloud Account
 * @param {*} data
 */
export function* createCloudAccount(data) {
  try {
    const cloudAccountPagination = yield select(getCloudAccountPagination);
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    yield put(setLoading());
    yield put(setLoading(Loader.CREATE_CLOUD_ACCOUNT));
    let response = null;
    response = yield call(
      protectApi.createCloudAccount,
      data.dataObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const page =
        cloudAccountPagination.cloudAccountPagination &&
        cloudAccountPagination.cloudAccountPagination.curr_page;
      const pageSize =
        cloudAccountPagination.cloudAccountPagination &&
        cloudAccountPagination.cloudAccountPagination.page_size;
      yield put(protectAction.cloudAccountList(page, pageSize));
      // yield put(
      //   protectAction.getCloudAccountListByType(data.dataObj.cloud_account_type)
      // );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CREATE_CLOUD_ACCOUNT_SUCCESS,
            "sCw2dR"
          )
        )
      );
      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.CLOUD_ACCOUNT
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      response.context =
        constant.API_RESPONSE_CONTEXT.CREATE_CLOUD_ACCOUNT_SUCCESS;
      yield put(addAPIresponse(response));
      yield put(stopLoader());
      yield put(reset(CREATE_CLOUD_ACCOUNT));
      yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
      yield put(hideDialog(Dialog.CREATE_CLOUD_ACCOUNT));
    } else {
      yield put(stopLoader());
      yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    yield put(reset(CREATE_CLOUD_ACCOUNT));
    yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
    yield put(hideDialog(Dialog.CREATE_CLOUD_ACCOUNT));
  } /*  finally {
    yield put(stopLoader());
    yield put(reset(CREATE_CLOUD_ACCOUNT));
    yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
    yield put(hideDialog(Dialog.CREATE_CLOUD_ACCOUNT));
  } */
}

export function* updateCloudAccount(data) {
  try {
    const cloudAccountPagination = yield select(getCloudAccountPagination);
    const stateLogin = yield select(getLogin);
    yield put(setLoading());
    yield put(setLoading(Loader.CREATE_CLOUD_ACCOUNT));
    let response = yield call(
      protectApi.updateCloudAccount,
      data.updatedDataObj.id,
      data.updatedDataObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const page = cloudAccountPagination.cloudAccountPagination.curr_page;
      const pageSize = cloudAccountPagination.cloudAccountPagination.page_size;
      yield put(protectAction.cloudAccountList(page, pageSize));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.UPDATE_CLOUD_ACCOUNT_SUCCESS,
            "YWPVmL"
          )
        )
      );
      response.context =
        constant.API_RESPONSE_CONTEXT.UPDATE_CLOUD_ACCOUNT_SUCCESS;
      yield put(addAPIresponse(response));
      yield put(stopLoader());
      yield put(reset(CREATE_CLOUD_ACCOUNT));
      yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
      yield put(hideDialog(Dialog.CREATE_CLOUD_ACCOUNT));
    } else {
      yield put(stopLoader());
      yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    yield put(reset(CREATE_CLOUD_ACCOUNT));
    yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
    yield put(hideDialog(Dialog.CREATE_CLOUD_ACCOUNT));
  } /*  finally {
    yield put(stopLoader());
    yield put(reset(CREATE_CLOUD_ACCOUNT));
    yield put(resetLoading(Loader.CREATE_CLOUD_ACCOUNT));
    yield put(hideDialog(Dialog.CREATE_CLOUD_ACCOUNT));
  } */
}

export function* getVmwareServerType() {
  try {
    let formValues = yield select(getFormValues("addSourceFromHypervisor"));
    if (!formValues) {
      formValues = yield select(getFormValues("addSourceFromHyper"));
    }
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_GET_SERVER_TYPE));
    if (
      formValues.hypervisor_type !== "vcenter_esx" &&
      formValues.site &&
      formValues.vcenter_esx_ip &&
      formValues.port &&
      formValues.username &&
      formValues.password
    ) {
      return;
    }
    const data = {
      site_id: formValues.site.key,
      server_name: formValues.vcenter_esx_ip,
      protocol: "https",
      port: formValues.port,
      username: formValues.username,
      password: formValues.password
    };
    let resp = yield call(
      protectApi.getVmwareServerType,
      data,
      stateLogin.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.vmwareServerTypeSuccess(resp.data));
    } else {
      yield put(addSourcesHypervisorConnectError(resp.errors || []));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_GET_SERVER_TYPE));
  }
}

export function* loadRecoveryPointServers() {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      protectApi.getRecoveryPointServers,
      `organization_id=${orgId}&server_type=customer&pagination=false`,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadRecoveryPointServersSuccess(resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadBackupTrafficUsingRPS({ rpsId }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getBackupTrafficUsingRPSID,
      rpsId,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadNetworkForBackupTrafficRPSIDSuccess(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `5xMSND_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadRestoreTrafficUsingRPS({ rpsId }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getRestoreTrafficUsingRPSID,
      rpsId,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadNetworkForRestoreTrafficRPSIDSuccess(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `W5pxWF_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadRestoreTrafficUsingProxy({ proxyId }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getRestoreTrafficUsingProxyID,
      proxyId,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadNetworkForRestoreTrafficProxyIDSuccess(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `72gjzi_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* getAllHypervisors({ rpsId }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(protectApi.getAllHypervisorsList, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getAllHypervisorsSuccess(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `boEkvl_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* getHypervisorConfigByID({ data, taskId }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getAllHypervisorConfiguration,
      data,
      data,
      login.token
    );
    if (resp && resp.data) {
      yield put(getHypervisorConfigurationByIDSuccess(resp.data));
    } else {
      yield put(getHypervisorConfigurationByIDSuccess({ errors: resp.errors }));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `GBiuin_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* getHypervisorVMConfigByID({ data, taskId }) {
  try {
    yield put(startLoader());
    yield put(startGridLoaderById(constant.DATAGRID_IDS.vmTree_policy));
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getAllVMHypervisorConfiguration,
      data,
      data,
      login.token
    );
    if (resp && resp.data) {
      yield put(getHypervisorConfigurationByIDSuccess(resp.data));
    } else {
      yield put(getHypervisorConfigurationByIDSuccess({ errors: resp.errors }));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `RpPTg5_${resp?.status}`
          )
        )
      );
    }
    yield put(stopGridLoaderById(constant.DATAGRID_IDS.vmTree_policy));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(stopGridLoaderById(constant.DATAGRID_IDS.vmTree_policy));
  }
}

export function* getAllPolicyProxies() {
  try {
    const orgId = yield select(getOrgId);
    yield put(startLoader());
    const login = yield select(getLogin);
    let queryString = `organization_id=${orgId}&pagination=false&server_role_types=ar_windows_proxy%7Cwindows_backup_server%7Cvsb_convertor%7Cvsb_monitor`;
    const resp = yield call(
      protectApi.getAllProxiesList,
      login.token,
      queryString
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getAllProxiesForPolicySuccess(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp.errors[0] ? resp.errors[0].message : resp.errors,
            `dQ8WiD_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadVsbMonitors({ siteId }) {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      protectApi.getBackupProxy,
      `organization_id=${orgId}&server_role_types=ar_windows_proxy%7Cwindows_backup_server%7Cvsb_convertor%7Cvsb_monitor&site_id=${siteId}`,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadVsbMonitorSuccess(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* loadRPSReadOnlyDataStores(data) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const rpsId = data ? data.rpsId : "";
    const resp = yield call(
      protectApi.getRPSDataStores,
      `organization_id=${orgId}&rps_server_id=${rpsId}&pagination=false&read_only=false`,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadRPSDataStoresSuccess(rpsId, resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadRPSAllDataStores(data) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const rpsId = data ? data.rpsId : "";
    const resp = yield call(
      protectApi.getRPSDataStores,
      `organization_id=${orgId}&rps_server_id=${rpsId}&pagination=false`,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        protectAction.loadRPSDataStoresForAdhocASSuccess(rpsId, resp.data)
      );
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadCHDataStores(id) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      protectApi.getDestinations,
      login.token,
      `organization_id=${orgId}&site_id=${id.site_id}&pagination=false`
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadRPSDataStoresSuccess("arcserve_cloud", resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadBackupProxy() {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      protectApi.getBackupProxy,
      `organization_id=${orgId}`,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadBackupProxySuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}
export function* loadLBS(data) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(protectApi.getLBS, orgId, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadLBSSuccess(resp));
      if (!_.get(resp, "data.length", 0) && data?.data?.required !== false) {
        yield put(ccToast.addInfoNotification("toast-errors.lbs-not-found"));
      }
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadLinuxLBS() {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(protectApi.getLinuxLBS, orgId, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      // yield put(loadLBSSuccess(resp));
      yield put(loadLinuxLBSSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadLinux() {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(protectApi.getLinux, orgId, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadLinuxSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadSelectedNetworkBackupProxy({ data }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getSelectedNetworkBackupProxy,
      data,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadSNBTrafficSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* loadSelectedLBSNetworks(action) {
  let lbsId = action?.data?.lbsId;
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getSelectedLBSNetworks,
      lbsId,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadSelectedLBSNetworkSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* createBackupProxy(data) {
  try {
    yield put(startLoader());
    yield put(setLoading(Loader.AR_ADD_PROXY));
    const login = yield select(getLogin);
    let payload = { ...data.data };
    delete payload.source;
    delete payload.destination;
    let resp = yield call(protectApi.createBackupProxy, payload, login.token);
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(getSelectedProxy(resp));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CREATE_BACKUP_PROXY_SUCCESS,
            "7rMQXx"
          )
        )
      );
      yield put(protectAction.loadBackupProxy());
      yield put(protectAction.getAllProxiesForPolicy());
      if (
        data.data.source === "policy.unc.label.backup_proxy_modal_title" &&
        data.data.destination
      )
        yield put(
          change(
            "NEW_POLICY_DESTINATIONS",
            `${data.data.destination}.udp_unc_backup.proxy_id`,
            resp.data.proxy_id
          )
        );
      yield put(protectAction.addBackupProxyFlag(false));
      yield put(hideDialog(Dialog.AR_ADD_PROXY));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.AR_ADD_PROXY));
  }
}

export function* createLinuxBackupProxy(data) {
  try {
    yield put(startLoader());
    yield put(setLoading(Loader.AR_LINUX_ADD_PROXY));
    const login = yield select(getLogin);
    let resp = yield call(
      protectApi.createLinuxBackupProxy,
      data.data,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CREATE_BACKUP_PROXY_SUCCESS,
            "FvULCs"
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `AYZy06_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.AR_LINUX_ADD_PROXY));
    yield put(hideDialog(Dialog.AR_ADD_LINUX_PROXY));
  }
}

export function* createLinuxBackupServer(data) {
  try {
    yield put(startLoader());
    yield put(setLoading(Loader.ADD_LBS_ADD_SOURCE));
    const login = yield select(getLogin);
    let resp = yield call(
      protectApi.createLinuxBackupServer,
      data.data,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getSelectedLBS(resp));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CREATE_LBS_SUCCESS,
            "UeX8tB"
          )
        )
      );
      resp.context = constant.API_RESPONSE_CONTEXT.CREATE_LBS_SUCCESS;
      yield put(addAPIresponse(resp));
      yield put(resetLoading(Loader.ADD_LBS_ADD_SOURCE));
      yield put(protectAction.loadLBS());
      yield put(addLinuxBackupFlag(false));
      yield put(hideDialog(Dialog.AR_ADD_LINUX_POLICY_PROXY));
    } else {
      yield put(resetLoading(Loader.ADD_LBS_ADD_SOURCE));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.ADD_LBS_ADD_SOURCE));
  }
}

export function* preflightValidationAndSave(action) {
  try {
    let site = yield select(getSelectedSite);
    let { username, password } = action.data && action.data[0];
    let reqObj = {
      site_id: site.id,
      username,
      password,
      organization_id: yield select(getOrgId),
      vms: action.data.map(vm => {
        vm.vm_info.username = username;
        vm.vm_info.password = password;
        return vm.vm_info;
      })
    };
    const login = yield select(getLogin);
    const formValues = yield select(getFormValues("addUserInfo"));
    if (formValues && formValues.hypervisor_userinfo) {
      yield put(setLoading(Loader.ADD_SOURCE_SAVE_TO_LIST_VMWARE));
      let resp = yield call(
        protectApi.preflightVmwareCheck,
        reqObj,
        login.token
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        let validatedUuids = resp.data
          .filter(vm => vm.passwrd_verified)
          .map(vm => vm.vm_uuid);
        let vmsValidated = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) >= 0
        );
        let vmsError = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) < 0
        );

        if (vmsError.length > 0) {
          let errorVmNames = vmsError.map(vm => vm.vm_name).join(",");
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                constant.API_RESPONSE_CONTEXT.SAVE_TO_LIST_VMWARE_ERROR,
                `GxIjje_${resp?.status}`
              ),
              {
                vm_names: errorVmNames
              }
            )
          );
        }
        yield put(addSourcesHyperSaveToList(vmsValidated));
        yield put(reset("addUserInfo"));
      } else {
        yield put(addAPIresponse(resp));
      }
    } else {
      yield put(addSourcesHyperSaveToList(action.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_SOURCE_SAVE_TO_LIST_VMWARE));
  }
}

export function* preflightValidationAndSaveHN(action) {
  try {
    yield put(setLoading(Loader.ADD_SOURCE_SAVE_TO_LIST_HYPERV));
    let site = yield select(getSelectedSite);
    let { username, password } = action.data && action.data[0];
    let reqObj = {
      site_id: site.id,
      username,
      password,
      organization_id: yield select(getOrgId),
      vms: action.data.map(vm => {
        vm.vm_info._uidd = uuidv4();
        return vm.vm_info;
      })
    };
    const login = yield select(getLogin);
    const formValues = yield select(getFormValues("addUserInfo"));
    if (formValues && formValues.hypervisor_userinfo) {
      let resp = yield call(
        protectApi.preflightVmwareCheck,
        reqObj,
        login.token
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        let validatedUuids = resp.data
          .filter(vm => vm.passwrd_verified)
          .map(vm => vm.vm_uuid);
        let vmsValidated = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) >= 0
        );
        let vmsError = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) < 0
        );

        if (vmsError.length > 0) {
          let errorVmNames = vmsError.map(vm => vm.vm_name).join(",");
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                constant.API_RESPONSE_CONTEXT.SAVE_TO_LIST_VMWARE_ERROR,
                `YpbkRy_${resp?.status}`
              ),
              {
                vm_names: errorVmNames
              }
            )
          );
        }
        yield put(addSourcesHypervisorSaveToList(vmsValidated));
        yield put(reset("addUserInfo"));
      } else {
        yield put(addAPIresponse(resp));
      }
    } else {
      yield put(addSourcesHypervisorSaveToList(action.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_SOURCE_SAVE_TO_LIST_HYPERV));
  }
}

export function* preflightValidationAndAddSourcePolicyEsx(action) {
  try {
    // let site = yield select(getSelectedSite);
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
    let site = _.get(action && action.data && action.data.list[0], "site_id");
    let { username, password } = action && action.data;
    let reqObj = {
      site_id: site,
      username,
      password,
      organization_id: yield select(getOrgId),
      vms: action && action.data && action.data.list ? action.data.list : []
    };
    const login = yield select(getLogin);
    const formValues = yield select(getFormValues("addUserInfo"));
    if (formValues && formValues.hypervisor_userinfo) {
      let resp = yield call(
        protectApi.preflightVmwareCheck,
        reqObj,
        login.token
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        let validatedUuids = resp.data
          .filter(vm => vm.passwrd_verified)
          .map(vm => vm.vm_uuid);
        let vmsValidated = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) >= 0
        );
        let vmsError = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) < 0
        );

        if (vmsError.length > 0) {
          let errorVmNames = vmsError.map(vm => vm.vm_name).join(",");
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                constant.API_RESPONSE_CONTEXT.SAVE_TO_LIST_VMWARE_ERROR,
                `YSxjoD_${resp?.status}`
              ),
              {
                vm_names: errorVmNames
              }
            )
          );
        }
        if (vmsValidated && vmsValidated.length > 0) {
          yield put(addSourcesHypervisorAddSourcePolicyEsx(vmsValidated));
        }
        yield put(reset("addUserInfo"));
      } else {
        yield put(addAPIresponse(resp));
      }
    } else {
      yield put(
        addSourcesHypervisorAddSourcePolicyEsx(
          action && action.data && action.data.list && action.data.list
        )
      );
    }
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  } finally {
    // loader is reset in addSourcesFromHypervisorPolicyEsx saga and in above block if error occure
    // yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  }
}

export function* preflightValidationAndAddSourcePolicyHV(action) {
  try {
    // let site = yield select(getSelectedSite);
    yield put(setLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
    let site = _.get(action && action.data && action.data.list[0], "site_id");
    let { username, password } = action && action.data;
    let reqObj = {
      site_id: site,
      username,
      password,
      organization_id: yield select(getOrgId),
      vms: action && action.data && action.data.list ? action.data.list : []
    };
    const login = yield select(getLogin);
    const formValues = yield select(getFormValues("addUserInfo"));
    if (formValues && formValues.hypervisor_userinfo) {
      let resp = yield call(
        protectApi.preflightVmwareCheck,
        reqObj,
        login.token
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        let validatedUuids = resp.data
          .filter(vm => vm.passwrd_verified)
          .map(vm => vm.vm_uuid);
        let vmsValidated = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) >= 0
        );
        let vmsError = action.data.filter(
          vm =>
            validatedUuids.findIndex(uuid => uuid === vm.vm_info.vm_uuid) < 0
        );

        if (vmsError.length > 0) {
          let errorVmNames = vmsError.map(vm => vm.vm_name).join(",");
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                constant.API_RESPONSE_CONTEXT.SAVE_TO_LIST_VMWARE_ERROR,
                `30Brxb_${resp?.status}`
              ),
              {
                vm_names: errorVmNames
              }
            )
          );
        }
        if (vmsValidated && vmsValidated.length > 0) {
          yield put(addSourcesHypervisorAddSourcePolicyHV(vmsValidated));
        }
        yield put(reset("addUserInfo"));
      } else {
        yield put(addAPIresponse(resp));
      }
    } else {
      yield put(
        addSourcesHypervisorAddSourcePolicyHV(
          action && action.data && action.data.list && action.data.list
        )
      );
    }
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  } finally {
    // loader is reset in addSourcesFromHypervisorPolicyEsx saga and in above block if error occure
    // yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_ADD_SOURCES_BTN));
  }
}

export function* addUncOrNfsPaths(data) {
  try {
    yield put(setLoading(Loader.ADD_UNC_NFS_SOURCES));
    const stateLogin = yield select(getLogin);
    let response = yield call(
      protectApi.addUncOrNfsPaths,
      data.pathsList,
      stateLogin.token
    );

    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.WINDOWS_SOURCE_ADD_SUCCESS,
            "GwpDuW"
          )
        )
      );
      // yield put(getSourcesList({ fromAddSources: true }));
      yield put(getProtectListCount());
      const stateReducer = yield select(getReducer);
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = {
          useApi: true
        };
        yield put(
          ccDataGridActions.fetchData(
            stateReducer.secondaryNavigation,
            init,
            polling,
            additionalParmeters
          )
        );
      }
      yield put(hideDialog(Dialog.UNC_NFS_CONFIG));
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_UNC_NFS_SOURCES));
  }
}

export function* UncOrNfsPathSaveToList(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(UncOrNfsClearPathListError());
    yield put(setLoading(Loader.UNC_OR_NFS_PATH_SAVE_TO_LIST));
    let response = yield call(
      protectApi.postUncOrNfsPathList,
      data.UncOrNfsConfigObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const UncOrNfsPathData = {
        ...data.UncOrNfsConfigObj,
        ...response.data,
        uniqueId: uuidv4()
      };
      yield put(UncOrNfsPathSaveToListSuccess(UncOrNfsPathData));
      yield put(UncOrNfsClearPathListError());
      yield put(reset(constant.UNC_OR_NFS_PATHS));
      const site = {
        key: data?.UncOrNfsConfigObj?.site_id || "",
        value: data?.UncOrNfsConfigObj?.site_name || ""
      };
      yield put(change(constant.UNC_OR_NFS_PATHS, "site", site));
    } else {
      yield put(UncOrNfsPathSaveToListError(response.errors[0]));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.UNC_OR_NFS_PATH_SAVE_TO_LIST));
  }
}

export function* installUpdateUpgradeAgent(data) {
  const stateReducer = yield select(getReducer);
  try {
    yield put(setLoading(Loader.ADD_RPS));
    const login = yield select(getLogin);
    const payload = data.data;
    const resp = yield call(
      protectApi.installUpdateUpgradeAgent,
      login.token,
      payload,
      data.id,
      { showCommonError: true, testId: "qIA8Cq" }
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      let details = yield call(
        protectApi.fetchSourceById,
        data.id,
        login.token
      );
      let source = details.data;
      yield put(
        updateResourceInList(
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      const toastData = {
        messageId: data?.data?.deployment?.schedule
          ? constant.API_RESPONSE_CONTEXT.UPGRADE_AGENT_SOURCE_SCHEDULE_SUCCESS
          : constant.API_RESPONSE_CONTEXT.UPGRADE_AGENT_SOURCE_SUCCESS,
        testId: "Trnnd8"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      if (data.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      yield put(hideDialog(Dialog.INSTALL_UPGRADE_SOURCE));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_RPS));
  }
}

export function* installUpdateUpgradeAgentBulk(data) {
  const stateReducer = yield select(getReducer);
  try {
    yield put(setLoading(Loader.ADD_RPS));
    const login = yield select(getLogin);
    const payload = data.data;
    const resp = yield call(
      protectApi.installUpdateUpgradeAgentBulk,
      login.token,
      payload,
      data?.id,
      { showCommonError: true, testId: "qIA8Cz" }
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      const polling = false;
      const init = false;
      const additionalParmeters = { useApi: true };
      yield put(
        ccDataGridActions.fetchData(
          stateReducer.secondaryNavigation,
          init,
          polling,
          additionalParmeters
        )
      );
      const toastData = {
        messageId: data?.data?.agent_deploy_request?.deployment?.schedule
          ? constant.API_RESPONSE_CONTEXT.UPGRADE_AGENT_SOURCE_SCHEDULE_SUCCESS
          : constant.API_RESPONSE_CONTEXT.UPGRADE_AGENT_SOURCE_SUCCESS,
        testId: "Trnnd8"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      if (data.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      yield put(hideDialog(Dialog.INSTALL_UPGRADE_SOURCE));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_RPS));
  }
}

export function* cancelSourceScheduledDeploy(action) {
  const stateReducer = yield select(getReducer);
  try {
    yield put(setLoading(Loader.CANCEL_SOURCE_SCHEDULED_DEPLOY));
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      protectApi.cancelSourceScheduledDeploy,
      action.data,
      null,
      loginReducer.token,
      { showCommonError: true, testId: "QnYgYq" }
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      let details = yield call(
        protectApi.fetchSourceById,
        action.data,
        loginReducer.token
      );
      let source = details.data;
      yield put(
        updateResourceInList(
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      yield put(
        ccDataGridActions.UpdateSourceInCCGrid(
          stateReducer.secondaryNavigation,
          source,
          SourceConstants.SourceAttributes.SOURCE_PROP_ID
        )
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CANCEL_SCHEDULED_DEPLOY_RPS_SUCCESS,
            "qhFmxz"
          )
        )
      );
    } else if (apiResponse.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            apiResponse.errors[0].message,
            `kX9Kea_${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CANCEL_SOURCE_SCHEDULED_DEPLOY));
  }
}

export function* getSharedPolicyDetails(data) {
  try {
    const login = yield select(getLogin);
    const resp = yield call(
      protectApi.getSharedPolicyDetails,
      login.token,
      data.id
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getSharedPolicySuccess(resp.data));
    } else {
      if (resp.errors) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `FrIIuu_${resp?.status}`)
          )
        );
      }
      window.console.log(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* sharePolicy(action) {
  try {
    yield put(setLoading(Loader.SHARE_POLICY_BTN));
    const loginReducer = yield select(getLogin);
    let resp = yield call(
      protectApi.sharePolicyDetailsById,
      action.id,
      action.data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.SHARE_POLICY_ACTION_SUCCESS,
            "YDyqYJ"
          )
        )
      );
      yield put(hideDialog(Dialog.SHARE_POLICY_ACTION));
      yield put(clearSharedPolicy());
    } else {
      if (resp.errors.length) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `OFAZI6_${resp?.status}`)
          )
        );
      }
      window.console.log(resp);
    }
    yield put(resetLoading(Loader.SHARE_POLICY_BTN));
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.SHARE_POLICY_BTN));
  }
}

export function* startARTest(action) {
  try {
    yield put(setLoading(Loader.RUN_ASSURED_RECOVERY_TEST_BTN));
    const loginReducer = yield select(getLogin);
    let resp = yield call(
      protectApi.startArTest,
      action.id,
      action.data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.RUN_ASSURED_RECOVERY_TEST_SUCCESS,
            "fCATvs"
          )
        )
      );
      yield put(hideDialog(Dialog.RUN_ASSURED_RECOVERY_TEST));
      yield put(clearRecoveryRestoreMountData());
    } else {
      if (resp.errors.length) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `NR51gt_${resp?.status}`)
          )
        );
      }
      window.console.log(resp);
    }
    yield put(resetLoading(Loader.RUN_ASSURED_RECOVERY_TEST_BTN));
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.RUN_ASSURED_RECOVERY_TEST_BTN));
  }
}

export function* startASTest(action) {
  try {
    yield put(setLoading(Loader.RUN_ASSURED_SECURITY_SCAN_BTN));
    const loginReducer = yield select(getLogin);
    let resp = yield call(
      protectApi.startAsTest,
      action.id,
      action.data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.RUN_ASSURED_SECURITY_SCAN_SUCCESS,
            "dph3Fz"
          )
        )
      );
      yield put(hideDialog(Dialog.RUN_ASSURED_SECURITY_SCAN));
      yield put(clearRecoveryRestoreMountData());
    } else {
      if (resp.errors.length) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `nE4jVe_${resp?.status}`)
          )
        );
      }
      window.console.log(resp);
    }
    yield put(resetLoading(Loader.RUN_ASSURED_SECURITY_SCAN_BTN));
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.RUN_ASSURED_SECURITY_SCAN_BTN));
  }
}

export function* sourceStartARTest(action) {
  try {
    yield put(setLoading(Loader.SOURCE_RUN_ASSURED_RECOVERY_TEST_BTN));
    const loginReducer = yield select(getLogin);
    let resp = yield call(
      protectApi.sourceStartArTest,
      action.id,
      action.data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.RUN_ASSURED_RECOVERY_TEST_SUCCESS,
            "JDSisd"
          )
        )
      );
      yield put(hideDialog(Dialog.SOURCE_RUN_ASSURED_RECOVERY_TEST));
      yield put(clearRecoveryRestoreMountData());
      yield put(clearSourceARTTaskDetails());
    } else {
      if (resp.errors.length) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `SDUH23_${resp?.status}`)
          )
        );
      }
      window.console.log(resp);
    }
    yield put(resetLoading(Loader.SOURCE_RUN_ASSURED_RECOVERY_TEST_BTN));
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.SOURCE_RUN_ASSURED_RECOVERY_TEST_BTN));
  }
}

export function* sourceStartASScan(action) {
  try {
    yield put(setLoading(Loader.SOURCE_RUN_ASSURED_SECURITY_SCAN_BTN));
    const loginReducer = yield select(getLogin);
    let resp = yield call(
      action.data?.task_id
        ? protectApi.sourceStartAsScan
        : protectApi.sourceStartAdhocAsScan,
      action.id,
      action.data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.RUN_ASSURED_SECURITY_SCAN_SUCCESS,
            "49SAP5"
          )
        )
      );
      yield put(hideDialog(Dialog.SOURCE_RUN_ASSURED_SECURITY_SCAN));
      yield put(clearRecoveryRestoreMountData());
      yield put(protectAction.clearSourceASSTaskDetails());
    } else {
      if (resp.errors.length) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `uUDNRe_${resp?.status}`)
          )
        );
      }
      window.console.log(resp);
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.SOURCE_RUN_ASSURED_SECURITY_SCAN_BTN));
  }
}

export function* unsharePolicy(action) {
  try {
    yield put(setLoading(Loader.SHARE_POLICY_BTN));
    const loginReducer = yield select(getLogin);
    let resp = yield call(
      protectApi.unsharePolicyDetailsById,
      action.id,
      action.data,
      loginReducer.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      if (action?.onlyUnshare) {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.UNSHARE_POLICY_ACTION_SUCCESS,
              "jFaTLa"
            )
          )
        );
      }
    }

    if (resp.errors.length) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `3afUv8_${resp?.status}`)
        )
      );
      window.console.log(resp);
    }
    yield put(resetLoading(Loader.SHARE_POLICY_BTN));
    yield put(hideDialog(Dialog.SHARE_POLICY_ACTION));
    yield put(clearSharedPolicy());
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.SHARE_POLICY_BTN));
  }
}

/**
 * It updates the source details such as name
 * @param {data} contains the form data for api call
 */
function* updateSourceSaga(action) {
  try {
    let login = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let { id, data } = action;
    if (!data.username) {
      data.username = "";
    }
    if (!data.password) {
      data.password = "";
    }
    yield put(setLoading(Loader.UPDATE_SOURCE));
    let resp = yield call(protectApi.putSource, id, data, login.token, {
      showCommonError: true,
      testId: "HNuyAU"
    });
    // TODO toast notification not appearing
    if (resp.status === constant.HTTP_STATUS.OK) {
      // resp.context = constant.API_RESPONSE_CONTEXT.UPDATE_SOURCE_SUCCESS;
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.UPDATE_SOURCE_SUCCESS,
        testId: "fzopV2"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield put(updateSourceDetailsInStore(id, resp.data));
      yield put(addAPIresponse(resp));
      if (action.gridInfo === constant.CCDATAGRID_ID.SOURCE_SEARCH) {
        yield* fetchData({
          ccDataGridId: constant.CCDATAGRID_ID.SOURCE_SEARCH
        });
      }
      if (
        [
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES,
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS
        ].includes(stateReducer.secondaryNavigation)
      ) {
        const polling = false;
        const init = false;
        const additionalParmeters = {
          useApi: true
        };
        yield put(
          ccDataGridActions.fetchData(
            stateReducer.secondaryNavigation,
            init,
            polling,
            additionalParmeters
          )
        );
      } else {
        yield put(getCurrentActionCalled());
      }
    }
  } catch (error) {
    window.console.log(error);
  } finally {
    yield put(resetLoading(Loader.UPDATE_SOURCE));
    yield put(hideDialog(Dialog.UPDATE_SOURCE));
  }
}

function* saveDestinatioHostSaga({ data }) {
  try {
    yield put(setLoading(Loader.ADD_DESTINATION_HOST));
    yield put(delay(1000));
    yield put(
      ccToast.addSuccessNotification(makeToastData("Success", "G2pjZ6")) //TODO
    );
    yield put(protectAction.getDestinationHosts());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_DESTINATION_HOST));
  }
}

function* getDestinatioHostsSaga() {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(getBackupProxy, "", token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getDestinationHostsSuccess(resp.data));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* addDestinationHostSaga({ data }) {
  yield put(setLoading(Loader.ADD_DESTINATION_HOST));
  try {
    const login = yield select(getLogin);
    const payload = {
      proxy_id: data.hostname,
      proxy_name: data.hostname,
      username: data.username,
      password: data.password,
      product_type: constant.PRODUCT_TYPE.UDP,
      site_id: data.site.key
    };
    const resp = yield call(
      protectApi.addDestinationHost,
      payload,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CREATE_DESTINATION_HOST_SUCCESS,
            "EUfU1a"
          )
        )
      );
      yield put(addAPIresponse(resp));
      yield put(protectAction.getSourcesWithWindowsFilter());
      yield put(hideDialog(Dialog.ADD_DESTINATION_HOST));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `QS6dPI_${resp?.status}`)
        )
      );
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_DESTINATION_HOST));
  }
}

export function* addDestinationHostLinuxSaga({ data }) {
  try {
    yield put(setLoading(Loader.ADD_DESTINATION_HOST_LINUX));
    const login = yield select(getLogin);
    const lbs = yield select(updatedLbs);
    const destinationData = yield select(destinationsData);
    const linuxBackupServerId =
      destinationData &&
      destinationData[0] &&
      destinationData[0].linux_server_id;
    const payload = {
      source_name: data.hostname,
      username: data.username,
      password: data.password,
      ssh_key_auth: false,
      linux_server_id: linuxBackupServerId || lbs
    };
    const resp = yield call(
      protectApi.addDestinationHostLinux,
      payload,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.CREATE_DESTINATION_HOST_SUCCESS,
            "BlTAd5"
          )
        )
      );
      yield put(addAPIresponse(resp));
      yield put(protectAction.loadLinuxLBS());
      yield put(hideDialog(Dialog.ADD_DESTINATION_HOST_LINUX));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `HFQRCB_${resp?.status}`)
        )
      );
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_DESTINATION_HOST_LINUX));
  }
}
export function* recoverySqlPage1Validation() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    let count = 0;
    let totalData = [];
    const selectedItems = yield select(selectedItemForRecovery);
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const rpPass = yield select(rpEncryptionPassword);
    const selectedDestination = yield select(selectedDestinationData);
    const isSQLServerWriterChecked = yield select(isSQLServerWriterEnabled);
    const volumeData = yield select(folderTreeData);
    let sqlDBDetails = yield select(sqlDbDetails);

    if (isSQLServerWriterChecked && volumeData && volumeData.length) {
      yield put(
        storeSelectedItems(
          volumeData.map(item => {
            return {
              title: item.title,
              id: item.id,
              key: item.key,
              children: item.children,
              catalog_path: item.catalog_file_path,
              full_path: item.volume_path,
              name: item.title,
              session_id: item.session_id,
              size: item.size,
              sub_session_id: item.sub_session_id,
              type: "sql_writer",
              volume_guid: item.volume_guid,
              volume_attribute: item.volume_attribute,
              subSession_id: item.isChild
                ? item.subSession_id
                : item.sub_session_id
            };
          })
        )
      );
    } else {
      for (var item of selectedItems) {
        if (item.type === "sql_logicalpath") {
          count++;
          const data = {
            volume_guid: item.volume_guid,
            full_path: item.full_path,
            treeNodeId: item.id,
            currPage: item.currPage ? item.currPage : 0
          };
          const volume = volumeData.filter(
            vol => vol.volume_guid === data.volume_guid
          );
          let selectedVolume = [],
            found = false;
          if (
            data.full_path &&
            volume &&
            volume[0].children &&
            volume[0].children.length
          ) {
            function findChild(list) {
              if ((!selectedVolume || !selectedVolume.length) && !found) {
                selectedVolume = list.filter(
                  item => item.full_path === data.full_path
                );
              }
              if (!selectedVolume || !selectedVolume.length) {
                list.forEach(item => {
                  if (item.children && item.children.length)
                    findChild(item.children);
                });
              } else {
                found = true;
              }
            }
            findChild(volume[0].children);
          } else {
            selectedVolume = volume;
          }
          let selectedVolumeName = selectedVolume[0].isChild
            ? selectedVolume[0].name
            : selectedVolume[0].volume_path;
          let selectedDbDetails;

          const resp = yield call(
            protectApi.getItemsForRecoveryPoint,
            {
              name: item.name,
              root_volume_path: item.root_volume_path,
              linux_server_id: "",
              destination_type: source.destination_type,
              destination_info: {
                destination_path: source.destination_path,
                destination_id: _.get(
                  selectedDestination,
                  "destination_id",
                  ""
                ),
                destination_type: _.get(
                  selectedDestination,
                  "destination_type",
                  ""
                ),
                datastore_id: source.datastore_id
              },
              session_info: {
                catalog_path: item.catalog_path,
                parent_id: item.parent_id,
                session_id: item.session_id,
                parent_path: item.parent_path,
                volume_guid: item.volume_guid,
                encrypted_password: recoveryPoint.encrypted ? rpPass : "",
                include_file: true,
                session_type: recoveryPoint.session_type
              },
              page:
                data.name === "Load Next"
                  ? data.currPage + 1
                  : data.name === "Load Prev"
                  ? data.currPage - 1
                  : data.currPage + 1,
              page_size: 25,
              from_datastore: source.from_datastore,
              from_destination: source.from_destination,
              proxy_id: source.from_destination
                ? source.rps_server_id
                : undefined
            },
            source.source_id,
            token
          );
          if (resp.status === constant.HTTP_STATUS.OK) {
            let sqlDBDetailsResp;
            if (
              // !sqlDBDetails &&
              selectedVolume[0] &&
              selectedVolume[0].volume_guid === "SqlServerWriter"
            ) {
              sqlDBDetailsResp = yield call(
                protectApi.getSQLDBDetailsForRecoveryPoint,
                {
                  backup_destination_info: {
                    destination_path: source.destination_path,
                    destination_id: _.get(
                      selectedDestination,
                      "destination_id",
                      ""
                    ),
                    destination_type: _.get(
                      selectedDestination,
                      "destination_type",
                      ""
                    )
                  },
                  session_id: selectedVolume[0].session_id,
                  sub_session_id: selectedVolume[0].sub_session_id
                },
                source.source_id,
                token
              );

              if (_.get(sqlDBDetailsResp, "status", null) === 200) {
                yield put(storeSQLDbDetails(sqlDBDetailsResp));
                sqlDBDetails = yield select(sqlDbDetails);
                selectedDbDetails = sqlDBDetails?.data
                  ? sqlDBDetails.data.filter(item => {
                      return item.name === selectedVolumeName;
                    })
                  : "";
              } else {
                return;
              }
            }
            const modifiedResp =
              resp &&
              resp.data &&
              //eslint-disable-next-line no-loop-func
              resp.data.map(item => ({
                title: item.name,
                id: uuidv4(),
                key: uuidv4(),
                children: [],
                can_export: item.can_export,
                catalog_path: item.catalog_path,
                display_full_path: item.display_full_path,
                full_path: item.full_path,
                has_folder: item.has_folder,
                modify_ts: item.modify_ts,
                name: item.name,
                parent_id: item.parent_id,
                parent_path: item.parent_path,
                root_volume_path: item.root_volume_path,
                session_id: item.session_id,
                size:
                  item.vol_guid === "SqlServerWriter" &&
                  item.type === "sql_component_selectable"
                    ? sqlDBDetails &&
                      sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                      selectedDbDetails[0]
                      ? util.sqlComponentSize(
                          selectedDbDetails[0].database_list,
                          item.name
                        ) === 0
                        ? null
                        : util.formatBytes(
                            util.sqlComponentSize(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          )
                      : 0
                    : item.size === 0
                    ? null
                    : util.formatBytes(item.size),
                instance_name:
                  sqlDBDetails &&
                  sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                  selectedDbDetails[0]
                    ? selectedDbDetails[0].name
                    : "",
                pit_enabled:
                  item.vol_guid === "SqlServerWriter" &&
                  item.type === "sql_component_selectable" &&
                  sqlDBDetails &&
                  sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                  selectedDbDetails[0]
                    ? util.sqlComponentPitEnabled(
                        selectedDbDetails[0].database_list,
                        item.name
                      )
                    : false,
                start_time_point:
                  item.vol_guid === "SqlServerWriter" &&
                  item.type === "sql_component_selectable" &&
                  sqlDBDetails &&
                  sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                  selectedDbDetails[0]
                    ? util.sqlComponentStartTimePoint(
                        selectedDbDetails[0].database_list,
                        item.name
                      )
                    : "",
                end_time_point:
                  item.vol_guid === "SqlServerWriter" &&
                  item.type === "sql_component_selectable" &&
                  sqlDBDetails &&
                  sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                  selectedDbDetails[0]
                    ? util.sqlComponentEndTimePoint(
                        selectedDbDetails[0].database_list,
                        item.name
                      )
                    : "",
                time_point_count:
                  item.vol_guid === "SqlServerWriter" &&
                  item.type === "sql_component_selectable" &&
                  sqlDBDetails &&
                  sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                  selectedDbDetails[0]
                    ? util.sqlComponentTimePointCount(
                        selectedDbDetails[0].database_list,
                        item.name
                      )
                    : 0,
                sub_session_id: item.sub_session_id,
                type: item.type,
                volume_guid: item.vol_guid,
                isChild: true,
                parent_volume_path: item.isChild
                  ? item.parent_volume_path
                  : item.volume_path,
                volume_attribute: item.volume_attribute,
                subSession_id: item.isChild
                  ? item.subSession_id
                  : item.sub_session_id,
                canDownload:
                  item.type === "sql_node" ||
                  item.type === "sql_writer" ||
                  item.type === "sql_component" ||
                  item.type === "sql_component_selectable" ||
                  item.type === "sql_logicalpath"
                    ? false
                    : true
              }));
            totalData.push(...modifiedResp);
          } else {
            if (resp.errors && resp.errors.length === 0)
              yield put(
                ccToast.addErrorNotification(
                  makeToastData(
                    _.get(resp, "errors[0].message", ""),
                    `GhV1n6_${resp?.status}`
                  )
                )
              );
          }
        }
      }
      if (count >= 1) {
        yield put(storeSelectedItems(totalData));
      }
    }

    yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* recoverySqlPage2Validation() {
  const values = yield select(getFormValues("recoverSQLForm"));
  const source = yield select(selectedSource);
  const selectedDestination = yield select(selectedDestinationData);
  const recoverItem = yield select(selectedItemForRecovery);
  const isSQLServerWriterChecked = yield select(isSQLServerWriterEnabled);
  const volumesData = yield select(folderTreeData);

  let selectedVolume;
  if (isSQLServerWriterChecked) {
    for (let item of volumesData && volumesData) {
      const title = item && item.title;
      const name = recoverItem && recoverItem[0] && recoverItem[0].name;
      name === title ? (selectedVolume = item) : (selectedVolume = {});
    }
  }

  let restoreSubItems = [];
  //eslint-disable-next-line array-callback-return
  recoverItem.map(item => {
    restoreSubItems.push({
      full_path: item.full_path,
      type: isSQLServerWriterChecked ? "sql_writer" : item.type
    });
  });
  // Do the validation for the password by calling the api
  // and if success trigger next page action
  if (isSQLServerWriterChecked) {
    yield put(
      storeSQLSummaryData({
        restore_destination_type: values.restore_destination_type,
        backup_destination: {
          destination_path: source.destination_path,
          destination_id: _.get(selectedDestination, "destination_id", ""),
          destination_type: _.get(selectedDestination, "destination_type", "")
        },
        restore_node: {
          session_number: selectedVolume.session_id,
          encryption_password: values.enc_password,
          restore_item: [
            {
              root_volume_path: selectedVolume.volume_path,
              volume_guid: selectedVolume.volume_guid,
              sub_session_number: selectedVolume.sub_session_id
            }
          ]
        },
        dump_path: values.dump_path ? values.dump_path : "",
        proxy: {
          proxy_id: values.destinationHost ? values.destinationHost : ""
        },
        destinationHost: values.destinationHost ? values.destinationHost : ""
      })
    );
  } else {
    yield put(
      storeSQLSummaryData({
        restore_destination_type: values.restore_destination_type,
        backup_destination: {
          destination_path: source.destination_path,
          destination_id: _.get(selectedDestination, "destination_id", ""),
          destination_type: _.get(selectedDestination, "destination_type", "")
        },
        restore_node: {
          session_number: recoverItem[0].session_id,
          encryption_password: values.enc_password,
          restore_item: [
            {
              root_volume_path: recoverItem[0].root_volume_path,
              volume_guid: recoverItem[0].volume_guid,
              sub_session_number: recoverItem[0].subSession_id,
              restore_sub_items: restoreSubItems
            }
          ]
        },
        dump_path: values.dump_path ? values.dump_path : "",
        proxy: {
          proxy_id: values.destinationHost ? values.destinationHost : ""
        },
        destinationHost: values.destinationHost ? values.destinationHost : ""
      })
    );
  }

  if (values.restore_destination_type === "dump_file") {
    yield put({ type: actionType.RECOVERY_NEXT_PAGE, count: 3 });
  } else {
    yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  }
}

export function* recoverySqlPage3Validation() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const loginReducer = yield select(getLogin);
    const values = yield select(getFormValues("recoverSQLJobOptions"));
    // const source = yield select(selectedSource);
    const recoverItem = yield select(selectedItemForRecovery);
    let sqlData = yield select(sqlSummaryData);
    let sqlDataWithSQLJobOptions = sqlData;
    const isSQLServerWriterChecked = yield select(isSQLServerWriterEnabled);

    //for job level options
    sqlDataWithSQLJobOptions.restore_node.restore_item[0].sql_job_options = {
      recovery_state: _.get(values, "recovery_state", "recovery"),
      database_consistency_check: {
        check_enabled: _.get(
          values,
          "database_consistency_check.check_enabled",
          false
        ),
        fail_job_if_db_check_fails: _.get(
          values,
          "database_consistency_check.fail_job_if_db_check_fails",
          false
        )
      },
      miscellaneous_info: {
        restrict_user_access: _.get(
          values,
          "miscellaneous_info.restrict_user_access,",
          false
        ),
        overwrite: _.get(values, "miscellaneous_info.overwrite", false)
      }
    };

    function findSelectedItemIndex(items, id) {
      let index = 0;
      // let item =
      items &&
        //eslint-disable-next-line array-callback-return
        items.find(item => {
          if (id === item.full_path) {
            return item;
          }
          index++;
        });
      return index;
    }

    //for item level options
    if (!isSQLServerWriterChecked) {
      sqlDataWithSQLJobOptions.restore_node.restore_item[0].restore_sub_items.forEach(
        item => {
          let index = findSelectedItemIndex(recoverItem, item.full_path);
          item.isPitDisabled = !recoverItem[index].pit_enabled;
          item.start_time_point = recoverItem[index].start_time_point;
          item.end_time_point = recoverItem[index].end_time_point;
          item.time_point_count = recoverItem[index].time_point_count;
          item.dbName = recoverItem[index].name;
          item.sql_options = {
            recovery_state: _.includes(
              ["master", "msdb", "model", "Resource", "tempdb"],
              recoverItem[index].name
            )
              ? "recovery"
              : _.get(values, "recovery_state", "recovery"),
            database_consistency_check: {
              check_enabled: _.get(
                values,
                "database_consistency_check.check_enabled",
                false
              ),
              fail_job_if_db_check_fails: _.get(
                values,
                "database_consistency_check.fail_job_if_db_check_fails",
                false
              )
            },
            miscellaneous_info: {
              restrict_user_access: _.includes(
                ["master"],
                recoverItem[index].name
              )
                ? false
                : _.get(
                    values,
                    "miscellaneous_info.restrict_user_access",
                    false
                  ),
              overwrite: _.get(values, "miscellaneous_info.overwrite", false)
            }
          };
        }
      );
    }

    /// Configure Database settings data
    if (sqlData.restore_destination_type === "alternate_location") {
      let dbDetails = yield select(sqlDbDetails);
      // let databaseDetails = dbDetails?.data[0]?.database_list;

      let allDBDetails = [];
      dbDetails &&
        dbDetails.data &&
        //eslint-disable-next-line array-callback-return
        dbDetails.data.map(item => {
          //eslint-disable-next-line array-callback-return
          item &&
            item.database_list &&
            //eslint-disable-next-line array-callback-return
            item.database_list.map(db => {
              //eslint-disable-next-line array-callback-return
              allDBDetails.push({
                ...db,
                instance_name: item.name
              });
            });
        });
      let databaseSettingsData = [];
      recoverItem &&
        //eslint-disable-next-line array-callback-return
        recoverItem.map(item => {
          //eslint-disable-next-line array-callback-return
          allDBDetails &&
            //eslint-disable-next-line array-callback-return
            allDBDetails.map(db => {
              if (
                item.title === db.name &&
                item.instance_name === db.instance_name
              ) {
                let row = {
                  db: item.title,
                  files: []
                };
                //eslint-disable-next-line array-callback-return
                db.files.map(file => {
                  row.files.push({
                    source_file_name: file.name,
                    source_file_path: file.path,
                    destination_file_name: file.name,
                    destination_file_path: file.path,
                    size: util.formatBytes(file.size)
                  });
                });
                databaseSettingsData.push(row);
              }
            });
        });
      sqlDataWithSQLJobOptions.databaseSettingsData = databaseSettingsData;
    }

    if (sqlData.restore_destination_type === "alternate_location") {
      const resp = yield call(
        protectApi.getSQLDBInstancesForRecoveryPoint,
        sqlData.proxy.proxy_id,
        loginReducer.token
      );
      if (resp.status === constant.HTTP_STATUS.OK && resp.data) {
        yield put(
          storeSQLSummaryData({
            ...sqlDataWithSQLJobOptions,
            sqlInstanceData: resp.data
          })
        );
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      } else {
        yield put(
          ccToast.addErrorNotification(
            // constant.API_RESPONSE_CONTEXT.SQL_INSTANCES_FAILED
            makeToastData(
              resp && resp.errors && resp.errors[0] && resp.errors[0].message,
              `Tb2bDo_${resp?.status}`
            )
          )
        );
      }
    } else {
      yield put(
        storeSQLSummaryData({
          ...sqlDataWithSQLJobOptions
        })
      );
      if (isSQLServerWriterChecked) {
        yield put({ type: actionType.RECOVERY_NEXT_PAGE, count: 2 });
      } else {
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
    }

    // Do the validation for the password by calling the api
    // and if success trigger next page action
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* recoverySqlPage4Validation() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const token = yield select(getLoginToken);
    // const source = yield select(selectedSource);
    const values = yield select(
      getFormValues("recoverSQLJobOptionsConfiguration")
    );
    const selectedItems = yield select(selectedItemForRecovery);
    let sqlData = yield select(sqlSummaryData);
    // Do the validation for the password by calling the api
    // and if success trigger next page action

    sqlData.restore_destination_type === "original_location" &&
      _.unset(sqlData, "dump_path");

    sqlData.restore_node.restore_item[0].restore_sub_items.forEach(item => {
      _.unset(item, "start_time_point");
      _.unset(item, "end_time_point");
      _.unset(item, "time_point_count");
      _.unset(item, "isPitDisabled");
    });

    if (sqlData.restore_destination_type === "alternate_location") {
      let updatedDBDetails = selectedItems.map(item => {
        return {
          instance_name: item.instance_name,
          db_name: item.name,
          db_path: item.full_path ? item.full_path : "c:\\",
          new_instance: item.new_instance
            ? item.new_instance
            : item.instance_name,
          new_db_name: _.isString(item.new_db_name)
            ? item.new_db_name
            : item.name,
          db_destination_path: values.db_destination_path
            ? values.db_destination_path
            : ""
        };
      });
      _.unset(sqlData, "dump_path");
      const sqlAlternateValues = sqlData.databaseSettingsData
        ? //eslint-disable-next-line array-callback-return
          updatedDBDetails.map(item => {
            //eslint-disable-next-line array-callback-return
            sqlData.databaseSettingsData.map(db => {
              if (item.db_name === db?.db) {
                item = { ...item, db_files_destination: db.files };
              } else {
                item = { ...item };
              }
            });
            return item;
          })
        : updatedDBDetails;

      let payloadForApi = sqlAlternateValues.map(item => {
        return {
          ...item,
          db_files_destination:
            item.db_files_destination &&
            item.db_files_destination.map(item => {
              return {
                ...item,
                size: item.size.split(" ")[0] * 1048576
              };
            })
        };
      });

      let resp = yield call(
        protectApi.validateSQLItems,
        payloadForApi,
        sqlData.proxy.proxy_id,
        token
      );

      if (resp.status !== constant.HTTP_STATUS.OK && resp.data) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              resp && resp.errors && resp.errors[0] && resp.errors[0].message,
              `81hSt4_${resp?.status}`
            )
          )
        );
      }

      yield put(
        storeSQLSummaryData({
          ...sqlData,
          sql_alternate_destinations: sqlAlternateValues,
          db_destination_path: values.db_destination_path
            ? values.db_destination_path
            : "",
          log_path: values.log_path ? values.log_path : ""
        })
      );
    } else {
      yield put(
        storeSQLSummaryData({
          ...sqlData
        })
      );
    }
    yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* recoveryOraclePage1Validation() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const formValues = yield select(getFormValues("recoverOracle"));
    const source = yield select(selectedSource);
    const recoverItem = yield select(selectedItemForRecovery);
    const sharedPathCred = yield select(sharedPathCredentials);
    const oracleDBSettings = yield select(selectOracleDBSettings);
    const oracleDBTableSettings = yield select(selectOracleDBTableSettings);
    const oracleDBConfigurationData = yield select(
      state => state.destinations.recovery.oracleDBConfigurationsData
    );

    let summaryData = {
      restore_type: formValues?.restore_type,
      db_name: source?.oracle_db_name,
      backup_type: recoverItem?.[0]?.name,
      encryption_password: recoverItem?.[0]?.encrypt_password || "",
      session_number: recoverItem?.[0]?.session_Id,
      backup_destination_info: {
        destination_id: source?.destination_id,
        destination_type: source?.destination_type,
        destination_path: source?.destination_path
      },
      oracleDBSettings,
      oracleDBTableSettings
    };
    if (formValues?.restore_type === ORACLE_RESTORE_TYPES.EXPORT_TO_DISK) {
      let exportDiskOptions = {
        path: formValues?.folder_location
      };
      if (sharedPathCred?.username && sharedPathCred?.password) {
        exportDiskOptions["username"] = sharedPathCred?.username;
        exportDiskOptions["password"] = sharedPathCred?.password;
      }
      summaryData["export_disk_options"] = {
        ...exportDiskOptions
      };
    }
    yield put(protectAction.storeOracleSummaryData({ ...summaryData }));
    if (
      !oracleDBConfigurationData ||
      Object.keys(oracleDBConfigurationData)?.length === 0
    ) {
      if (formValues?.restore_type === ORACLE_RESTORE_TYPES.RESTORE_DATABASE) {
        yield callRecoveryOracleConfigureApi();
      }
    }
    if (formValues?.restore_type === ORACLE_RESTORE_TYPES.EXPORT_TO_DISK) {
      yield put({ type: actionType.RECOVERY_NEXT_PAGE, count: 2 });
    } else {
      yield put({ type: actionType.RECOVERY_NEXT_PAGE });
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* callRecoveryOracleConfigureApi() {
  const rpSelected = yield select(state => state.reducer.selection);
  const rpEncryptionPass = yield select(rpEncryptionPassword);
  const sourceSelected = yield select(selectedSource);
  const treeFolderData = yield select(folderTreeData);
  const password =
    rpEncryptionPass && rpEncryptionPass.length > 0
      ? rpEncryptionPass
      : _.get(selectedRecoveryPoint, "encrypt_password", "");
  const payload = {
    backup_destination_info: {
      destination_path: sourceSelected.destination_path,
      destination_id: sourceSelected.datastore_id,
      destination_type: "datastore"
    },
    catalog_path: treeFolderData?.[0]?.catalog_file_path,
    volume_guid: treeFolderData?.[0]?.volume_guid,
    database_name: sourceSelected?.oracle_db_name,
    pdb_name: "",
    session_id: rpSelected?.[0]?.session_Id,
    encryption_password: password,
    source_id: sourceSelected.source_id,
    defaultApi: true
  };
  yield put(getRecoveryOracleDBConfig(payload, false));
}

export function* recoveryOraclePage2Validation() {
  try {
    let oracleData = yield select(oracleSummaryData);

    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const recoverOptionsValues = yield select(
      getFormValues("RecoverOracleSource")
    );
    const source = yield select(selectedSource);
    const alternate_node_id =
      recoverOptionsValues?.restore_destination === "original_location"
        ? source?.parent_id
        : recoverOptionsValues?.restore_to || "";
    if (recoverOptionsValues?.restore_location === "alternate") {
      const payloadData = {
        create_path: recoverOptionsValues?.create_path || false,
        db_name: source?.oracle_db_name || "",
        alternate_node_id,
        path: recoverOptionsValues?.alternate_path || ""
      };
      const token = yield select(getLoginToken);
      const resp = yield call(
        protectApi.validateOraclePath,
        token,
        source.source_id,
        payloadData
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        yield put(
          protectAction.storeOracleSummaryData({
            ...oracleData,
            ...recoverOptionsValues,
            alternate_node_id,
            folderName: resp?.data?.folder_name || ""
          })
        );
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
    } else {
      yield put(
        protectAction.storeOracleSummaryData({
          ...oracleData,
          ...recoverOptionsValues,
          alternate_node_id
        })
      );
      yield put({ type: actionType.RECOVERY_NEXT_PAGE });
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* recoveryVolumePage2Validation() {
  try {
    yield put(showDialog(Dialog.RECOVER_VOLUME_CONFIRM));
    let action = yield take([
      actionType.VOLUME_RECOVERY_CONFIRM_SUBMIT,
      actionType.VOLUME_RECOVERY_CONFIRM_CANCEL
    ]);

    if (action.type === actionType.VOLUME_RECOVERY_CONFIRM_CANCEL) {
      yield put(hideDialog(Dialog.RECOVER_VOLUME_CONFIRM));
    } else {
      yield put(setLoading(Loader.RECOVER_VOLUME_CONFIRM));
      const values = yield select(getFormValues("recoverVolumeOne"));
      const token = yield select(getLoginToken);
      const source = yield select(selectedSource);
      const selectedDestination = yield select(selectedDestinationData);
      const selectedItems = yield select(selectedItemForRecovery);
      const recoveryPoint = yield select(selectedRecoveryPoint);
      let isPassValid = true;
      let selectedVolumes = [];
      selectedItems &&
        //eslint-disable-next-line array-callback-return
        selectedItems.map(item => {
          const checkAvailablity =
            selectedVolumes &&
            selectedVolumes.filter(vol => vol.name === item.volume_path);
          if (!checkAvailablity || !checkAvailablity.length) {
            selectedVolumes.push({
              name: item.volume_path || "",
              volume_attribute: item.volume_attribute
            });
          }
        });
      const validateData = {
        proxy_id:
          values.restoreDestination === "restore_to"
            ? values.destinationHost || null
            : null,
        recovery_path:
          values.restoreDestination === "restore_to"
            ? `${values.folder_location}:\\` || null
            : null,
        selected_volumes: selectedVolumes,
        from_datastore: false,
        from_destination: source?.from_destination,
        datastore_id: source.datastore_id || "",
        block_level_restore: true
      };
      let resp = yield call(
        protectApi.validateRecoveryPointItems,
        validateData,
        source.source_id,
        token
      );

      if (resp.status === constant.HTTP_STATUS.OK) {
        yield put(addAPIresponse(resp));

        if (recoveryPoint.encrypted) {
          const passwordValidation = yield call(
            protectApi.verifyEncryptionPassword,
            {
              password: values.enc_password || "",
              recovery_point: {
                session_id: recoveryPoint.session_id, //req
                session_guid: recoveryPoint.session_guid,
                encryption_pswd_hashkey: recoveryPoint.encrypt_pswd_hash_key, // req
                encryption_type: recoveryPoint.encryption_type,
                name: recoveryPoint.name,
                hbbu_path: recoveryPoint.hbbu_path
              },
              destination_type: source.destination_type,
              destination_info: {
                destination_path: source.destination_path, // req
                // username: source.username,
                // password: source.password,
                destination_id: _.get(
                  selectedDestination,
                  "destination_id",
                  ""
                ),
                destination_type: _.get(
                  selectedDestination,
                  "destination_type",
                  ""
                ),
                datastore_id: source.datastore_id
              },
              from_datastore: source.from_datastore
            },
            source.source_id,
            token
          );
          if (
            passwordValidation.status === constant.HTTP_STATUS.OK &&
            passwordValidation.data.verification_result
          ) {
            isPassValid = true;
            yield put(addAPIresponse(resp));
          } else {
            isPassValid = false;
            if (
              passwordValidation.errors &&
              passwordValidation.errors.length === 0
            )
              yield put(
                ccToast.addErrorNotification(
                  makeToastData(
                    constant.API_RESPONSE_CONTEXT.PASSWORD_VERIFICATION_FAILED,
                    `a2SJh8_${resp?.status}`
                  )
                )
              );
          }
        }

        if (isPassValid) {
          yield put(
            storeVolumeSummaryData({
              // map components selected in 1st View of SQL recovery
              restoreDestination: values.restoreDestination,
              folder_location: values.folder_location
                ? values.folder_location
                : "",
              destinationHost: values.destinationHost
                ? values.destinationHost
                : [],
              enc_password: values.enc_password,
              network_traffic: values?.network_traffic || null
            })
          );
          yield put(hideDialog(Dialog.RECOVER_VOLUME_CONFIRM));
          yield put({ type: actionType.RECOVERY_NEXT_PAGE });
        }
      } else {
        yield put(addAPIresponse(resp));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_VOLUME_CONFIRM));
  }
}

export function* recoveryVolumePage1Validation() {
  const selectedItems = yield select(selectedItemForRecovery);
  if (selectedItems[0].title === "C:") {
    yield put(showDialog(Dialog.RECOVER_VOLUME_FULL_BMR));
  } else {
    yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  }
}

export function getRestoredItemForRecoverFnF(recoverItem) {
  //Get root volume path
  let rootVolumes = [];
  let restoredItem = [];
  //eslint-disable-next-line array-callback-return
  recoverItem.map(item => {
    if (!rootVolumes.includes(item.root_volume_path)) {
      rootVolumes.push(item.root_volume_path);
    }
  });
  //eslint-disable-next-line array-callback-return
  rootVolumes.map(item => {
    restoredItem.push({ root_volume_path: item });
  });

  for (let i = 0; i < restoredItem.length; i++) {
    let restoreSubItems = [];
    for (let j = 0; j < recoverItem.length; j++) {
      if (
        recoverItem[j].root_volume_path === restoredItem[i].root_volume_path
      ) {
        restoreSubItems.push({
          full_path: recoverItem[j].full_path,
          type: recoverItem[j].type
        });

        restoredItem[i]["volume_guid"] = recoverItem[j].volume_guid;
        restoredItem[i]["sub_session_number"] = recoverItem[j].subSession_id;
        restoredItem[i]["restore_sub_items"] = restoreSubItems;
      }
    }
  }
  return restoredItem;
}

export function* startRecoverySaga() {
  try {
    yield put(setLoading(Loader.START_RECOVERY_BTN));
    let token = yield select(getLoginToken);
    let recType = yield select(recoveryType);
    let selectedSourcePagination = yield select(sourcePagination);
    const source = yield select(selectedSource);
    const selectedDestination = yield select(selectedDestinationData);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const rpPass = yield select(rpEncryptionPassword);
    let fnFData = yield select(fnfSummaryData);
    let sqlData = yield select(sqlSummaryData);
    let volData = yield select(volumeSummaryData);
    const oracleData = yield select(oracleSummaryData);
    // const hypervisorData = yield select(hypervisorInfo);
    const validVMDetails = yield select(validVMData);
    const validLinuxVMDetails = yield select(validLinuxVMData);
    const fnfSummaryVal = yield select(getFormValues("recoverFnFSummary"));
    const fnfLinuxSummaryVal = yield select(
      getFormValues("RecoverFnFLinuxSummary")
    );
    const sqlSummaryVal = yield select(getFormValues("recoverSQLSummary"));
    const volumeSummaryVal = yield select(getFormValues("recoverVolSummary"));
    const recoverItem = yield select(selectedItemForRecovery);
    let linuxFnFData = yield select(fnfLinuxSummaryData);
    const time =
      linuxFnFData &&
      linuxFnFData.scheduletime &&
      linuxFnFData.scheduletime.split(":");
    let sharedPathCred = yield select(sharedPathCredentials);
    const lbs = yield select(updatedLbs);
    const destinationData = yield select(destinationsData);
    const linuxBackupServerId =
      destinationData &&
      destinationData[0] &&
      destinationData[0].linux_server_id;
    let payload = {};
    switch (recType) {
      case RecoveryTypes.SQL_DATABASES:
        if (sqlData.restore_destination_type === "dump_file") {
          const alternatePath = sqlData.dump_path;
          _.unset(sqlData, "dump_path");
          _.unset(sqlData, "destinationHost");
          payload = {
            job_name: sqlSummaryVal.job_name,
            ...sqlData,
            proxy: null,
            alternate_destination: {
              path: alternatePath
            }
          };
        } else if (sqlData.restore_destination_type === "original_location") {
          _.unset(sqlData, "proxy");
          _.unset(sqlData, "destinationHost");
          payload = {
            job_name: sqlSummaryVal.job_name,
            ...sqlData
          };
        } else if (sqlData.restore_destination_type === "alternate_location") {
          _.unset(sqlData, "databaseSettingsData");
          _.unset(sqlData, "database_list");
          _.unset(sqlData, "destinationHost");
          _.unset(sqlData, "log_path");
          _.unset(sqlData, "db_destination_path");
          _.unset(sqlData, "sqlInstanceData");
          sqlData &&
            sqlData.sql_alternate_destinations &&
            //eslint-disable-next-line array-callback-return
            sqlData.sql_alternate_destinations.map(item => {
              item &&
                item.db_files_destination &&
                //eslint-disable-next-line array-callback-return
                item.db_files_destination.map(item => {
                  _.unset(item, "size");
                });
            });
          payload = {
            job_name: sqlSummaryVal.job_name,
            ...sqlData
          };
        }
        break;
      case RecoveryTypes.FILES_AND_FOLDERS:
        //save payload for files and folder recovery
        if (
          source.os_major === "windows" ||
          (source.os_major === "" && !linuxFnFData?.sessionTypeLinux)
        ) {
          let isOriginalLoc = fnFData.restoreDestination === "original_loc";
          // let restoreSubItems = [];
          // recoverItem.map(item => {
          //   restoreSubItems.push({
          //     full_path: item.full_path,
          //     type: item.type
          //   });
          // });
          // fill below data in case of vm fnf recovery
          let hypervisorDetails = {};
          if (validVMDetails) {
            hypervisorDetails = {
              username: validVMDetails.username,
              password: validVMDetails.password,
              server_name: validVMDetails.server_name,
              protocol: validVMDetails.protocol,
              port: validVMDetails.port,
              type: validVMDetails.hypervisor_type,
              vm_settings: {
                username: validVMDetails.vm_username,
                password: validVMDetails.vm_password,
                vm_hostname: validVMDetails.vm_hostname
              }
            };
          }
          payload = {
            job_name: fnfSummaryVal.job_name,
            restore_destination_type: isOriginalLoc
              ? "original_location"
              : "alternate_location",
            backup_destination: {
              destination_path: source.destination_path,
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              ),
              datastore_id: source.datastore_id
            },
            network_traffic: fnFData.network_traffic,
            file_restore_options: {
              overwrite_existing_files: fnFData.conflicts === "overwrite_file",
              replace_active_files:
                fnFData.conflicts === "overwrite_file"
                  ? fnFData.replace_active_files
                  : false,
              rename_file: fnFData.conflicts === "rename_file",
              skip_existing_files: fnFData.conflicts === "skip_file",
              create_root_directory: fnFData.create_root_dir,
              skip_acl_files: fnFData.skip_acl_files
            },
            restore_node: {
              session_number: recoverItem[0].session_id,
              encryption_password: fnFData.enc_password,
              restore_item: getRestoredItemForRecoverFnF(recoverItem)
            },
            proxy: isOriginalLoc
              ? source.from_destination
                ? {
                    proxy_id: source?.rps_server_id
                  }
                : null
              : {
                  proxy_id: fnFData.destinationHost
                },
            alternate_destination: isOriginalLoc
              ? null
              : {
                  path: fnFData.folder_location,
                  username:
                    sharedPathCred &&
                    fnFData.folder_location.includes(sharedPathCred.path)
                      ? sharedPathCred.username
                      : "",
                  password:
                    sharedPathCred &&
                    fnFData.folder_location.includes(sharedPathCred.path)
                      ? sharedPathCred.password
                      : ""
                },
            hypervisor: hypervisorDetails,
            from_datastore: source.from_datastore,
            from_destination: source.from_destination || undefined
          };
        } else {
          let tagetMachineDetails = {};
          if (
            validLinuxVMDetails &&
            linuxFnFData.restoreDestination === "original_location"
          ) {
            tagetMachineDetails = {
              server_name: validLinuxVMDetails.server_name,
              username: validLinuxVMDetails.username,
              password: validLinuxVMDetails.password
            };
          }
          payload = {
            job_name: fnfLinuxSummaryVal.job_name,
            restore_destination_type:
              linuxFnFData.restoreDestination === "original_location"
                ? "original_location"
                : "alternate_location",
            backup_destination: {
              destination_path: source.destination_path,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              )
            },
            network_traffic: linuxFnFData.network_traffic,
            linux_file_restore_options: {
              overwrite_existing_files:
                linuxFnFData.conflicts === "overwrite_file",
              replace_active_files:
                linuxFnFData.conflicts === "overwrite_file"
                  ? linuxFnFData.replace_active_files
                  : false,
              rename_file: linuxFnFData.conflicts === "rename_file",
              skip_existing_files: linuxFnFData.conflicts === "skip_file",
              create_root_directory: linuxFnFData.create_root_dir
            },
            linux_backup_server_id: linuxBackupServerId || lbs,
            restore_catalog_items:
              recoverItem &&
              recoverItem.map(item => {
                return {
                  full_path: item.full_path,
                  type: item.type,
                  display_name: item.name
                  // size:+item.size
                };
              }),
            restore_type: "file",
            run_now:
              linuxFnFData && linuxFnFData.run_time === "runnow" ? true : false,
            start_date_time: {
              year:
                linuxFnFData &&
                linuxFnFData.scheduledate &&
                new Date(linuxFnFData.scheduledate).getFullYear() &&
                new Date(linuxFnFData.scheduledate).getFullYear().toString(),
              month:
                linuxFnFData &&
                linuxFnFData.scheduledate &&
                new Date(linuxFnFData.scheduledate).getMonth() + 1 &&
                (new Date(linuxFnFData.scheduledate).getMonth() + 1).toString(),
              day:
                linuxFnFData &&
                linuxFnFData.scheduledate &&
                new Date(linuxFnFData.scheduledate).getDate() &&
                new Date(linuxFnFData.scheduledate).getDate().toString(),
              minute: time[1],
              hour_of_day: time[0]
            },
            target_machine: {
              target_machine_id:
                linuxFnFData.destinationHost || (source && source.source_id),
              ...tagetMachineDetails,
              path: linuxFnFData.folder_location
            },
            // machine: "",
            // session_location: "",
            recovery_point: {
              session_id: recoveryPoint && recoveryPoint.session_id, //req
              encryption_pswd_hashkey:
                recoveryPoint && recoveryPoint.encrypt_pswd_hash_key, // req
              encryption_type: recoveryPoint && recoveryPoint.encryption_type,
              name: recoveryPoint && recoveryPoint.name,
              hbbu_path: recoveryPoint && recoveryPoint.hbbu_path
            },
            session_password: recoveryPoint && recoveryPoint.encrypted && rpPass
          };
        }
        break;
      case RecoveryTypes.VOLUME:
        //save payload for files and folder recovery
        if (source.os_major === "windows" || source.os_major === "") {
          let isOriginalLoc = volData.restoreDestination === "original_loc";
          let restoreSubItems = [];
          //eslint-disable-next-line array-callback-return
          recoverItem.map(item => {
            restoreSubItems.push({
              full_path: item.full_path,
              type: item.type
            });
          });
          // fill below data in case of vm fnf recovery
          let hypervisorDetails = {};
          if (validVMDetails) {
            hypervisorDetails = {
              username: validVMDetails.username,
              password: validVMDetails.password,
              server_name: validVMDetails.server_name,
              protocol: validVMDetails.protocol,
              port: validVMDetails.port,
              type: validVMDetails.hypervisor_type,
              vm_settings: {
                username: validVMDetails.vm_username,
                password: validVMDetails.vm_password,
                vm_hostname: validVMDetails.vm_hostname
              }
            };
          }
          payload = {
            job_name: volumeSummaryVal.job_name,
            restore_destination_type: isOriginalLoc
              ? "original_location"
              : "alternate_location",
            backup_destination: {
              destination_path: source.destination_path,
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              ),
              datastore_id: source.datastore_id
            },
            network_traffic: volData.network_traffic,
            file_restore_options: {
              overwrite_existing_files: false,
              replace_active_files: false,
              rename_file: false,
              skip_existing_files: false,
              create_root_directory: false,
              skip_acl_files: false,
              block_level_restore: true
            },
            restore_node: {
              session_number: recoverItem[0].session_id,
              encryption_password: volData.enc_password,
              restore_item: [
                {
                  root_volume_path: recoverItem[0].volume_path,
                  volume_guid: recoverItem[0].volume_guid,
                  sub_session_number: recoverItem[0].sub_session_id
                  // restore_sub_items: restoreSubItems
                }
              ]
            },
            proxy: isOriginalLoc
              ? source.from_destination
                ? {
                    proxy_id: source?.rps_server_id
                  }
                : null
              : {
                  proxy_id: volData.destinationHost
                },
            alternate_destination: isOriginalLoc
              ? null
              : {
                  path: `${volData.folder_location}:\\`,
                  username:
                    sharedPathCred &&
                    sharedPathCred.path === volData.folder_location
                      ? sharedPathCred.username
                      : "",
                  password:
                    sharedPathCred &&
                    sharedPathCred.path === volData.folder_location
                      ? sharedPathCred.password
                      : ""
                },
            hypervisor: hypervisorDetails,
            from_datastore: false,
            from_destination: source?.from_destination || undefined
          };
        } else {
          payload = {
            job_name: linuxFnFData.job_name,
            restore_destination_type:
              linuxFnFData.restoreDestination === "original_loc"
                ? "original"
                : "alternate",
            backup_destination: {
              destination_path: linuxFnFData.folder_location,
              username: linuxFnFData.hostUsername,
              password: linuxFnFData.hostPassword,
              datastore_id: ""
            },
            linux_file_restore_options: {
              overwrite_existing_files: false,
              replace_active_files: false,
              rename_file: false,
              skip_existing_files: false,
              create_root_directory: false,
              skip_acl_files: false,
              block_level_restore: true
            },
            linux_backup_server: "",
            restore_catalog_items: [
              {
                full_path: "",
                type: "volume",
                display_name: "",
                size: 0
              }
            ],
            restore_type: "file",
            backup_desintation_type: "",
            pre_post_scripts: {
              before_job_started: linuxFnFData.pre_script,
              after_job_completed: linuxFnFData.post_script
            },
            run_now: true,
            start_date_time: {
              year: "",
              month: "",
              day: "",
              minute: "",
              hour_of_day: ""
            },
            target_machine: {
              server_name: "",
              username: "",
              password: "",
              path: ""
            },
            machine: "",
            session_location: "",
            recovery_point: "",
            session_password: "",
            from_datastore: false
          };
        }
        break;
      case RecoveryTypes.ORACLE_DATABASES:
        if (oracleData?.restore_type === ORACLE_RESTORE_TYPES.EXPORT_TO_DISK) {
          delete oracleData?.backup_type;
          delete oracleData?.oracleDBSettings;
          delete oracleData?.oracleDBTableSettings;
          payload = {
            ...oracleData
          };
        } else {
          const oracleSummaryFormValue = yield select(
            getFormValues("RecoverOracleSourceSummary")
          );
          const recoverOracleSummaryData = yield select(oracleSummaryData);
          const selectedDestination = yield select(selectedDestinationData);
          const selectedOracleSource = yield select(selectedSource);
          payload = {
            job_name: oracleSummaryFormValue?.job_name,
            restore_type: recoverOracleSummaryData?.restore_type,
            db_name: selectedOracleSource?.oracle_db_name || "",
            session_number: recoverOracleSummaryData?.session_number || "",
            encryption_password:
              recoverOracleSummaryData?.encryption_password || "",
            backup_destination_info: {
              destination_path: selectedDestination?.destination_path || "",
              destination_id: selectedDestination?.datastore_id || "",
              destination_type: selectedDestination?.destination_type || ""
            },
            database_options: {
              restore_destination_type:
                recoverOracleSummaryData?.restore_destination || "",
              alternate_node_id: recoverOracleSummaryData?.alternate_node_id
            }
          };
          const {
            oracleDBSettings,
            oracleDBTableSettings
          } = recoverOracleSummaryData;
          let recovery_type_options;
          let restore_options;
          let browseOptions = {
            pdb_name:
              selectedOracleSource?.oracle_db_name ===
              oracleDBSettings?.formData?.selectedDB
                ? ""
                : oracleDBSettings?.formData?.selectedDB,
            restore_type:
              oracleDBSettings?.formData?.RestoreOptions === "Granualar Restore"
                ? "granular"
                : "full"
          };
          if (browseOptions.restore_type === "granular") {
            const tableSpace = oracleDBSettings?.TSEntites.reduce(
              (acc, entity) => {
                acc.push(entity.name);
                return acc;
              },
              []
            );
            // const dataFiles = oracleDBSettings?.DTEntites.reduce(
            //   (acc, entity) => {
            //     acc.push(entity.seqNo);
            //     return acc;
            //   },
            //   []
            // );
            let tableDetails = oracleDBTableSettings?.right || [];

            if (tableDetails.length > 0) {
              tableDetails = tableDetails.reduce((acc, table) => {
                acc.push({
                  name: table.tableName,
                  new_name: table.newName || ""
                });
                return acc;
              }, []);
            }
            browseOptions = {
              ...browseOptions,
              port: oracleDBSettings?.formData?.port,
              table_spaces: [...tableSpace],
              // data_files: [...dataFiles],
              tables: [...tableDetails]
            };
            if (
              oracleDBSettings?.formData?.GranularSubOptions === "Table Spaces"
            ) {
              delete browseOptions.tables;
            }
            if (oracleDBSettings?.formData?.GranularSubOptions === "Tables") {
              delete browseOptions.table_spaces;
            }
          }
          if (recoverOracleSummaryData?.restore_location === "alternate") {
            restore_options = {
              restore_option: recoverOracleSummaryData?.restore_location || "",
              folder_name: recoverOracleSummaryData?.folderName || "",
              path: recoverOracleSummaryData?.alternate_path || ""
            };
          } else {
            restore_options = {
              restore_option: recoverOracleSummaryData?.restore_location || ""
            };
          }
          if (recoverOracleSummaryData?.no_recovery) {
            recovery_type_options = {
              no_recovery: true,
              switch_database:
                recoverOracleSummaryData?.switch_database || false
            };
          } else {
            switch (recoverOracleSummaryData?.restore_type_options) {
              case "recover_all":
                recovery_type_options = {
                  recover_all: true
                };
                break;
              case "point_in_time_info":
                const timeArray =
                  recoverOracleSummaryData?.scheduletime &&
                  recoverOracleSummaryData?.scheduletime.length
                    ? recoverOracleSummaryData?.scheduletime.split(":")
                    : null;
                let fullDate =
                  timeArray && timeArray.length
                    ? moment(
                        recoverOracleSummaryData?.scheduledate,
                        "DD/MM/YYYY"
                      )
                        .hour(timeArray[0])
                        .minute(timeArray[1])
                        .second(timeArray[2])
                    : recoverOracleSummaryData?.scheduledate;
                recovery_type_options = {
                  point_in_time:
                    fullDate && fullDate._d
                      ? moment(fullDate._d).format("YYYY-MM-DD[T]HH:mm:ss.SSS")
                      : ""
                };
                break;
              case "scn_info":
                recovery_type_options = {
                  scn_num: recoverOracleSummaryData?.scn_num || ""
                };
                break;
              case "log_seq_info":
                recovery_type_options = {
                  log_seq_info: {
                    seq_num: recoverOracleSummaryData?.seq_num || "",
                    thread_num: recoverOracleSummaryData?.thread_num || ""
                  }
                };
                break;
              default:
                recovery_type_options = {};
            }
          }
          payload.database_options = {
            ...payload.database_options,
            restore_options: {
              ...restore_options
            },
            ...browseOptions,
            recovery_type_options: {
              ...recovery_type_options
            }
          };
        }
      default:
        break;
    }
    let apiUrl = protectApi.postRecovery;
    if (recType === RecoveryTypes.ORACLE_DATABASES) {
      apiUrl = protectApi.oraclePostRecovery;
    } else if (source.os_major === "linux" || linuxFnFData?.sessionTypeLinux) {
      apiUrl = protectApi.linuxPostRecovery;
    }
    let resp = yield call(apiUrl, source.source_id, payload, token);

    resp.context = constant.API_RESPONSE_CONTEXT.START_RECOVERY;

    //If no error then show success toast from response else error toast will be handle in api showCommonError
    if (!resp.errors || (resp.errors && !resp.errors.length)) {
      yield call(showToastFromResponse, resp);
      yield put(protectAction.setSQLServerWriter(false));
    }
    yield put(resetRecovery());
    yield put(hideDialog(Dialog.RESTORE_RECOVERY_POINT));
    yield put(searchList({ ...selectedSourcePagination }));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.START_RECOVERY_BTN));
  }
}

export function* validateRecoveryPointSaga({ data }) {
  try {
    yield put(setLoading(Loader.ADD_IVM));
    let token = yield select(getLoginToken);
    let payload = _.get(data, "payload", {});

    if (Object.keys(payload).length === 0) {
      return;
    }
    // let payload = {
    //   destination_path: _.get(
    //     data,
    //     "payload.destination_info.destination_path",
    //     ""
    //   ),
    //   username: _.get(data, "payload.destination_info.username", ""),
    //   session_number: _.get(data, "rp.session_Id", ""),
    //   password: _.get(data, "payload.destination_info.password", ""),
    //   backup_destination_type: _.get(data, "payload.destination_type", ""),
    //   datastore_id: _.get(data, "payload.destination_info.datastore_id", "")
    // };
    // const rpValidation = yield call(
    //   protectApi.validateRecoveryPoint,
    //   payload,
    //   data.id,
    //   token
    // );
    const rpValidation = yield call(
      protectApi.validateRecoveryPointConfiguration,
      payload,
      data.id,
      token
    );
    if (rpValidation.status === constant.HTTP_STATUS.OK) {
      if (!rpValidation?.data || Object.keys(rpValidation.data).length === 0)
        return;
      //eslint-disable-next-line eqeqeq
      if (rpValidation.data.has_boot_volume == false) {
        //eslint-disable-next-line eqeqeq
        if (rpValidation.data.has_system_volume == false) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "toast-errors.noBoot_noSystemVolume",
                `lGZUQQ_${rpValidation?.status}`
              )
            )
          );
          return;
        }
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "toast-errors.noBootVolume",
              `pYpxtO_${rpValidation?.status}`
            )
          )
        );
        return;
      }
      //eslint-disable-next-line eqeqeq
      if (rpValidation.data.has_system_volume == false) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "toast-errors.noSystemVolume",
              `HgZicX_${rpValidation?.status}`
            )
          )
        );
        return;
      }
      let configData = rpValidation.data;
      if (configData && configData.network_adapters)
        configData.network_adapters = configData.network_adapters.map(
          adapter => {
            adapter.key = uuidv4();
            return adapter;
          }
        );
      yield put(storeValidateConfigdata(configData));
      yield put(switchAddIVMTab(2));
    }
  } catch (e) {
    window.console.log(e);
    // yield put(stopLoader());
  } finally {
    yield put(resetLoading(Loader.ADD_IVM));
  }
}

/**
 * Create New IVM FLow
 */
export function* storeNewIVMRecoveryPoint({ data }) {
  try {
    yield put(setLoading(Loader.ADD_IVM));
    let token = yield select(getLoginToken);
    const passwordValidation = yield call(
      protectApi.verifyEncryptionPassword,
      data.payload,
      data.id,
      token
    );
    if (
      passwordValidation.status === constant.HTTP_STATUS.OK &&
      passwordValidation.data.verification_result
    ) {
      yield put(saveRPPassword(data.payload.password));
      data?.callbackFunction && data.callbackFunction();
      if (data.rp && data.verifyRPPayloadConfig) {
        yield put(storeSelectedRecoveryPoint(data.rp));
        yield put(
          validateRecoveryPoint({
            payload: data.verifyRPPayloadConfig,
            id: data.id
          })
        );
      }
      yield put(resetLoading(Loader.ADD_IVM));
    } else {
      if (passwordValidation.errors && passwordValidation.errors.length === 0) {
        if (data.showModalIfValidationFails) {
          yield put(showDialog(Dialog.ADD_RPA));
        } else {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                constant.API_RESPONSE_CONTEXT.PASSWORD_VERIFICATION_FAILED,
                `9yCXS6_${passwordValidation?.status}`
              )
            )
          );
          yield put(resetLoading(Loader.ADD_IVM));
        }
      }
    }
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.ADD_IVM));
  }
}

/**
 * Fetch Proxies for IVM Dialog
 * @param {*} data
 */
export function* fetchProxiesIvmSaga({
  data,
  srcId,
  srcType,
  destinationType
}) {
  try {
    let login = yield select(getLogin);
    let orgId = yield select(getOrgId);
    let resp = {};
    if (srcType === "udp_windows" && destinationType === "local_disk") {
      let respObj = yield call(protectApi.fetchSourceById, srcId, login.token);
      resp = { ...respObj };
      resp.pagination = {
        curr_page: 1,
        total_page: 1,
        page_size: 25,
        has_prev: false,
        has_next: false,
        total_size: 1
      };
      resp.data = [
        {
          ...respObj.data,
          proxy_id: respObj.data && respObj.data.source_id,
          proxy_name: respObj.data && respObj.data.source_name
        }
      ];
    } else {
      resp = yield call(
        getBackupProxy,
        `org_id=${orgId}&site_id=${data}&server_role_types=ar_windows_proxy%7Cwindows_backup_server%7Cvsb_convertor%7Cvsb_monitor`,
        login.token
      );
    }

    resp.context = constant.API_RESPONSE_CONTEXT.FETCH_PROXIES_IVM;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchProxiesIVMSuccess(resp));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (error) {
    window.console.log(error);
  }
}

/**
 * submitIvmSaga
 */
export function* submitIvmSaga({ data }) {
  //mark1
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.FINISH_IVM));
    if (data && data.finalPayload) {
      const resp = yield call(protectApi.createIVM, data.finalPayload, token);
      if (resp.status === constant.HTTP_STATUS.OK) {
        yield put(hideDialog("IVM_WIZARD"));
        if (data && data.history) {
          data.history.push(`/protect/recovered_resources/instant_vms_rr`);
        }

        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.CREATE_IVM_SUCCESS,
              "rmQDi9"
            )
          )
        );
        yield put(getProtectListCount());
      }
    }
  } catch (error) {
    window.console.log(error);
  } finally {
    yield put(resetLoading(Loader.FINISH_IVM));
  }
}

export function* recoveryFnFPage2Validation() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const values = yield select(getFormValues("recoverFnF"));
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const selectedDestination = yield select(selectedDestinationData);
    const selectedItems = yield select(selectedItemForRecovery);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    let isPassValid = true;
    let selectedVolumes = [];
    //eslint-disable-next-line array-callback-return
    selectedItems.map(item => {
      const checkAvailablity = selectedVolumes.filter(
        vol => vol.name === item.root_volume_path
      );
      if (!checkAvailablity || !checkAvailablity.length) {
        selectedVolumes.push({
          name: item.root_volume_path,
          volume_attribute: item.volume_attribute
        });
      }
    });
    const validateData = {
      proxy_id:
        values.restoreDestination === "restore_to"
          ? values.destinationHost || null
          : null,
      recovery_path:
        values.restoreDestination === "restore_to"
          ? values.folder_location || null
          : null,
      selected_volumes: selectedVolumes,
      from_datastore: source.from_datastore,
      datastore_id: source.datastore_id,
      from_destination: source.from_destination
    };
    let resp = yield call(
      protectApi.validateRecoveryPointItems,
      validateData,
      source.source_id,
      token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(addAPIresponse(resp));

      if (recoveryPoint.encrypted) {
        const passwordValidation = yield call(
          protectApi.verifyEncryptionPassword,
          {
            password: values.enc_password || "",
            recovery_point: {
              session_id: recoveryPoint.session_id, //req
              session_guid: recoveryPoint.session_guid,
              encryption_pswd_hashkey: recoveryPoint.encrypt_pswd_hash_key, // req
              encryption_type: recoveryPoint.encryption_type,
              name: recoveryPoint.name,
              hbbu_path: recoveryPoint.hbbu_path
            },
            destination_type: source.destination_type,
            destination_info: {
              destination_path: source.destination_path, // req
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              ),
              datastore_id: source.datastore_id
            },
            from_datastore: source.from_datastore,
            from_destination: source.from_destination,
            proxy_id: source.from_destination
              ? source?.rps_server_id
              : undefined
          },
          source.source_id,
          token
        );
        if (
          passwordValidation.status === constant.HTTP_STATUS.OK &&
          passwordValidation.data.verification_result
        ) {
          isPassValid = true;
          yield put(addAPIresponse(resp));
        } else {
          isPassValid = false;
          if (
            passwordValidation.errors &&
            passwordValidation.errors.length === 0
          )
            yield put(
              ccToast.addErrorNotification(
                makeToastData(
                  constant.API_RESPONSE_CONTEXT.PASSWORD_VERIFICATION_FAILED,
                  `VXADsP_${passwordValidation?.status}`
                )
              )
            );
        }
      }
      if (isPassValid) {
        yield put(
          storeFnFSummaryData({
            // map components selected in 1st View of SQL recovery
            restoreDestination: values.restoreDestination,
            folder_location: values.folder_location
              ? values.folder_location
              : "",
            destinationHost: values.destinationHost
              ? values.destinationHost
              : [],
            conflicts: values.conflicts ? values.conflicts : "",
            create_root_dir: values.create_root_dir ? true : false,
            replace_active_files: values.replace_active_files ? true : false,
            skip_acl_files: values.skip_acl_files ? true : false,
            enc_password: values.enc_password,
            network_traffic: values?.network_traffic || null
          })
        );
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* fetchEsxServers(data) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.fetchEsxServers, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchEsxServersSuccess(resp.data));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* startVSB(data) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.startVirtualStandby, data.data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VIRTUAL_STANDBY_START,
            "VxIGes"
          )
        )
      );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* pauseVSB(data) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.pauseVirtualStandby, data.data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VIRTUAL_STANDBY_PAUSE,
            "og5qdM"
          )
        )
      );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* resumeVSB(data) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.resumeVirtualStandby, data.data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VIRTUAL_STANDBY_RESUME,
            "d1temr"
          )
        )
      );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* policySharedFolderDestinationConnectSaga({
  data,
  formName,
  namespace
}) {
  try {
    let token = yield select(getLoginToken);
    yield put(
      setLoading(Loader.POLICY_SHARED_FOLDER_DESTINATION_CONNECT_DIALOG)
    );
    const resp = yield call(
      protectApi.postSharedFolderDestinationConnect,
      data,
      token
    );
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .SHARED_FOLDER_DESTINATION_CONNECT_SUCCESS,
            "MWOEW1"
          )
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.destination_id`,
          _.get(resp.data, "share_folder_id", "")
        )
      );
      yield put(
        change(formName, `${namespace}.destination_name`, data.sharefolder.path)
      );
      yield put(hideDialog(Dialog.CONNECT_SHARED_FOLDER_DESTINATION));
      yield put(fetchDestinationPath());
      let mainType = "Protect";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(
      resetLoading(Loader.POLICY_SHARED_FOLDER_DESTINATION_CONNECT_DIALOG)
    );
  }
}

export function* fetchDestinationPathSaga() {
  try {
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    yield put(setLoading(Loader.POLICY_FETCH_SHARED_FOLDER_DESTINATION_PATH));
    const response = yield call(protectApi.fetchDestinationPath, token, orgId);

    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(fetchDestinationPathSuccess(response.data));
    } else {
      yield put(fetchDestinationPathFailure(response.errors));
    }
    yield put(addAPIresponse(response));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.POLICY_FETCH_SHARED_FOLDER_DESTINATION_PATH));
  }
}

export function* policysharedFolderDestinationAuthenticateSaga({
  data,
  formName,
  namespace
}) {
  try {
    let token = yield select(getLoginToken);
    const stateProtectReducer = yield select(getProtectReducer);

    yield put(setLoading(Loader.POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE));
    const resp = yield call(
      protectApi.postAuthenticateSharedFolder,
      data,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .SHARED_FOLDER_DESTINATION_CONNECT_SUCCESS,
            "PvLIM5"
          )
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.destination_id`,
          stateProtectReducer.sharedFolderId
        )
      );
      yield put(
        change(formName, `${namespace}.destination_name`, data.sharefolder.path)
      );
      yield put(hideDialog(Dialog.CONNECT_SHARED_FOLDER_DESTINATION));
    } else {
      if (resp.errors && resp.errors.length === 0)
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(resp, "errors[0].message", ""),
              `JLgba2_${resp?.status}`
            )
          )
        );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(
      resetLoading(Loader.POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE)
    );
  }
}

export function* policyVsbConnectHyperVSaga({ data, formName, namespace }) {
  try {
    let token = yield select(getLoginToken);
    yield put(protectAction.setHypervConnectForm("loading"));
    let formValues = yield select(getFormValues("policyVsbHyperVConnect"));
    let payload = {
      // site_id: data.site_id,
      // username: formValues.username,
      // password: formValues.password,
      hypervisor_id: data.hypervisor_id,
      node_name: data.node_name || undefined,
      port: formValues.port,
      protocol: "https"
      // protocol: formValues.protocol
    };
    // Clearing monitor fields before api call
    yield put(
      change(
        formName,
        `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor`,
        undefined
      )
    );
    yield put(setLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
    const resp = yield call(protectApi.postPolicyHyperVConnect, payload, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      // yield put(protectAction.policyVsbFetchVmwares(data.site_id, "hyperv")); // Need to confirm with Rachana
      yield put(
        protectAction.setHypervConnectForm({
          ...formValues,
          server_name: data.hypervisor_id
        })
      );
      yield put(
        change(formName, `${namespace}.destination_id`, data.hypervisor_id)
      );
      // Setting the monitor fields after success
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor`,
          {
            id: resp.data.proxy_id,
            name: data.hyperv_host_name
          }
        )
      );
      yield put(
        protectAction.getHypervConfigurations({
          // hyperv: {
          //   username: formValues.username,
          //   password: formValues.password,
          //   host: data.hypervisor_id,
          //   site_id: data.site_id
          // }
          hypervisor_id: data.hypervisor_id,
          single_call: true
        })
      );
      yield put(
        browseSharedFolders(
          {
            source_id: resp.data.proxy_id
          },
          namespace,
          formName
        )
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS,
            "FEWjYf"
          )
        )
      );
    } else {
      yield put(protectAction.setHypervConnectForm(null));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog("vsbHyperVConnect"));
    yield put(resetLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
  }
}

export function* policyVsbFetchVmwareNodesSaga({ data, formName, namespace }) {
  try {
    // let token = yield select(getLoginToken);
    let formValues = yield select(getFormValues("policyVsbVmwareConnect"));
    // yield put(setLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
    // let payload = {
    //   site_id: data.site_id,
    //   vmware: {
    //     username: formValues.username,
    //     password: formValues.password,
    //     server_name: data.hostname,
    //     port: formValues.port,
    //     protocol: formValues.protocol
    //   }
    // };
    // const resp = yield call(protectApi.postPolicyVmwareConnect, payload, token);
    // if (resp.status === constant.HTTP_STATUS.OK) {
    yield put(
      protectAction.setVmwareConnectForm({
        ...formValues,
        server_name: data.hostname
      })
    );
    yield put(change(formName, `${namespace}.destination_id`, data.hostname));
    yield put(protectAction.policyVsbFetchVmwareConnectSuccess(true));
    // yield put(protectAction.policyVsbFetchVmwares(data.site_id));  // Need to confirm with Rachana
    // } else {
    //   return;
    // }
    let esxNodePayload = {
      // proxy_id: "string",
      // vmware: {
      //   username: formValues.username,
      //   password: formValues.password,
      //   server_name: data.hostname,
      //   protocol: formValues.protocol,
      //   port: formValues.port
      // },
      // site_id: data.site_id
      hypervisor_id: data.hostname
    };
    yield put(policyVsbFetchVmwareNodes(esxNodePayload));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog("vsbVmwareConnect"));
    yield put(resetLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
  }
}

export function* policyVsbFetchESXNodes({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_VMWARE_ESX_NODE));
    const esxResp = yield call(
      protectApi.postPolicyVmwareEsxNodes,
      data,
      token
    );
    if (esxResp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.policyVsbFetchVmwareNodesSuccess(esxResp.data));
      // Hide success message while modify policy
      if (data.vmware) {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS,
              "V1iALP"
            )
          )
        );
      }
    } else {
      return;
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_VMWARE_ESX_NODE));
  }
}

export function* policyVsbFetchVmwaresSaga({ siteId, hypervisor_type }) {
  try {
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    let hypervType =
      hypervisor_type === "hyperv" ? "hyperv" : "esxhost%7Cvcenter";
    const resp = yield call(
      protectApi.fetchHypervisorList,
      `product_type=udp&hypervisor_type=${hypervType}&org_id=${orgId}&site_id=${siteId}`,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.policyVsbFetchVmwaresSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* pauseVSBHeartBeat(data) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.pauseHeartbeat, data.data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VIRTUAL_STANDBY_HEARTBEAT_PAUSE,
            "yr92dp"
          )
        )
      );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* policyVsbVmwareMonitorConnectSaga({
  data,
  formName,
  namespace
}) {
  try {
    let token = yield select(getLoginToken);
    let formValues = yield select(
      getFormValues("policyVsbVmwareMonitorConnect")
    );
    yield put(setLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
    const payload = {
      // proxy_id: "string",
      proxy_name: data.proxy_name,
      username: formValues.username,
      password: formValues.password,
      site_id: data.site_id,
      product_type: constant.PRODUCT_TYPE.UDP,
      server_role_type: "vsb_monitor",
      server_port: formValues.port,
      server_protocol: formValues.protocol,
      checkExist: false
    };
    const resp = yield call(protectApi.createBackupProxy, payload, token);
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS,
            "74r6GC"
          )
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
          resp.data.proxy_id
        )
      );
      yield put(protectAction.policyVsbVmwareMonitorConnectSuccess(resp.data));
      yield put(disableSavePolicyButton(false));
      yield put(hideDialog("vsbVmwareMonitorConnect"));
    } else if (resp.status >= constant.HTTP_STATUS.BAD_REQUEST) {
      yield put(disableSavePolicyButton(true));
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.hypervisor.nutanix.driver_path`,
          null
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
          null
        )
      );
    }
    yield put(addAPIresponse(resp));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
  }
}
export function* recoveryFnFLinuxPage2Validation() {
  const values = yield select(getFormValues("recoverFnFLinux"));
  const lbs = yield select(updatedLbs);
  const destinationData = yield select(destinationsData);
  const linuxBackupServerId =
    destinationData && destinationData[0] && destinationData[0].linux_server_id;
  //need sample request
  if (
    values.run_time === "runat" &&
    values.scheduledate &&
    values.scheduletime
  ) {
    // validate selected date and time
    yield put(
      validateScheduleFnFLinux({
        date: values.scheduledate,
        time: values.scheduletime,
        linux_backup_server: linuxBackupServerId || lbs
      })
    );
  }

  yield put(
    storeFnFLinuxSummaryData({
      components: [],
      job_name: values.job_name,
      restoreDestination: values.restoreDestination,
      folder_location: values.folder_location ? values.folder_location : "",
      destinationHost: values.destinationHost ? values.destinationHost : "",
      conflicts: values.conflicts ? values.conflicts : "",
      run_time: values.run_time ? values.run_time : "",
      scheduledate: values.scheduledate ? values.scheduledate : "",
      scheduletime: values.scheduletime ? values.scheduletime : "",
      create_root_dir: values.create_root_dir ? true : false,
      estimate_file_size: values.estimate_file_size ? true : false,
      post_script: values.post_script,
      pre_script: values.pre_script,
      hostUsername: values.hostUsername ? values.hostUsername : "",
      hostPassword: values.hostPassword ? values.hostPassword : "",
      sessionTypeLinux: values.sessionTypeLinux,
      destinationHostName: values.destinationHostName
        ? values.destinationHostName
        : "",
      network_traffic: values?.network_traffic || null
    })
  );
  yield put({ type: actionType.RECOVERY_NEXT_PAGE });
}

function* validateLinuxScheduleSaga({ data }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const time = data.time.split(":");
    const date = moment(data.date).format("DD");
    const month = moment(data.date).format("M");
    const year = moment(data.date).format("YYYY");

    // Map backup server UUID value
    const payload = {
      linux_backup_server: data && data.linux_backup_server,
      restore_schedule_time: {
        year: +year,
        month: +month,
        day: +date,
        minute: +time[1],
        hour_of_day: +time[0]
      }
    };
    const resp = yield call(
      protectApi.validateLinuxSchedule,
      payload,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

function* fetchLinuxScriptsSaga({ data }) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    const data = yield select(getLinuxScripts);
    const payload = {
      linux_backup_server: data.linux_backup_server_id,
      type: data.type
    };
    const resp = yield call(protectApi.fetchLinuxScripts, payload, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getLinuxScriptsSuccess(resp.data));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog("vsbVmwareMonitorConnect"));
    yield put(resetLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
  }
}

export function* getHyperVConfiguration({ data }) {
  try {
    let token = yield select(getLoginToken);
    const resp = yield call(
      protectApi.getHyperVConfigurationDetails,
      data,
      token
    );
    yield put(startGridLoaderById(constant.DATAGRID_IDS["proxies_tab_grid"]));
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getHypervConfigurationsSuccess(resp.data));
    }
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["proxies_tab_grid"]));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getNutanixConfiguration({ data }) {
  try {
    let token = yield select(getLoginToken);
    const resp = yield call(
      protectApi.getNutanixConfigurationDetails,
      data,
      token
    );
    yield put(startGridLoaderById(constant.DATAGRID_IDS["proxies_tab_grid"]));
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getNutanixConfigurationsSuccess(resp.data));
    }
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["proxies_tab_grid"]));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getVMwareConfiguration({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(startGridLoaderById(constant.DATAGRID_IDS["proxies_tab_grid"]));
    const resp = yield call(
      protectApi.getVmwareConfigurationDetails,
      data,
      token
    );
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["proxies_tab_grid"]));
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getVmwareConfigurationsSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  }
}

export function* generateDownloadLinkSaga({ data }) {
  try {
    // map proper payload values and source id
    yield put(startLoader());
    const source = yield select(selectedSource);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const token = yield select(getLoginToken);
    const rpPass = yield select(rpEncryptionPassword);
    const fnfGridData = yield select(FnfGridData);
    const selectedItems = yield select(selectedItemForRecovery);
    const selectedDestination = yield select(selectedDestinationData);
    const row = fnfGridData.filter(item => item.full_path === data);
    const lbs = yield select(updatedLbs);
    const destinationData = yield select(destinationsData);
    const linuxBackupServerId =
      destinationData &&
      destinationData[0] &&
      destinationData[0].linux_server_id;
    let payload;
    if (selectedItems.length > 1) {
      payload = {
        linux_backup_server_id: linuxBackupServerId || lbs, // map for linux machine
        destination_info: {
          destination_path: source.destination_path,
          // username: source.username,
          // password: source.password,
          destination_id: _.get(selectedDestination, "destination_id", ""),
          destination_type: _.get(selectedDestination, "destination_type", ""),
          datastore_id: source.datastore_id
        },
        destination_type: source.destination_type,
        items_list:
          selectedItems &&
          selectedItems.map(item => {
            return {
              download_item_full_path: item.full_path,
              volume_guid: item.volume_guid,
              root_volume_path: item.root_volume_path,
              type: item.type,
              sub_session_id: item.sub_session_id
            };
          }),
        session_info: {
          sub_path: recoveryPoint.sub_path,
          encrypted: recoveryPoint.encrypted,
          encryption_password: recoveryPoint.encrypted ? rpPass : "",
          encryption_type: recoveryPoint.encryption_type
        },
        from_datastore: source.from_datastore
      };
    } else {
      payload = {
        linux_backup_server_id: linuxBackupServerId || lbs, // map for linux machine
        destination_info: {
          destination_path: source.destination_path,
          // username: source.username,
          // password: source.password,
          destination_id: _.get(selectedDestination, "destination_id", ""),
          destination_type: _.get(selectedDestination, "destination_type", ""),
          datastore_id: source.datastore_id
        },
        destination_type: source.destination_type,
        items_list: [
          {
            download_item_full_path: row[0].full_path,
            volume_guid: row[0].volume_guid,
            root_volume_path: row[0].root_volume_path,
            type: row[0].type,
            sub_session_id: row[0].sub_session_id
          }
        ],
        session_info: {
          sub_path: recoveryPoint.sub_path,
          encrypted: recoveryPoint.encrypted,
          encryption_password: recoveryPoint.encrypted ? rpPass : "",
          encryption_type: recoveryPoint.encryption_type
        },
        from_datastore: source.from_datastore
      };
    }

    const resp = yield call(
      _.get(source, "type", "") === "udp_linux" ||
        _.get(source, "os_major", "") === "linux" ||
        _.get(source, "obj.operatingSystem", "") === "linux"
        ? protectApi.generateDownloadLinkLinux
        : protectApi.generateDownloadLink,
      source.source_id,
      payload,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (resp.data && resp.data.download_task_url)
        window.open(resp.data.download_task_url, "_blank");
      yield put(addAPIresponse(resp));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* fetchVolumesForRecoveryPoint({ source }) {
  try {
    const login = yield select(getLogin);
    const sourceData = yield select(selectedSource);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    yield put(setLoading(Loader.AVAILABLE_VOLUMES));
    const resp = yield call(
      protectApi.getVolumesForRecoveryPoint,
      {
        destination_id: source.destination_id,
        destination_type: source.destination_type,
        destination_path: source.destination_path,
        sub_path: source.sub_path || recoveryPoint.sub_path,
        datastore_id: source.datastore_id,
        from_datastore: sourceData.from_datastore,
        volumes_filter: source.volumes_filter,
        proxy_id: source?.proxy_id || undefined,
        from_destination: source?.from_destination || undefined
      },
      source.source_id,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (resp.data) {
        if (source?.volumes_filter === "sqlwriter" && !resp.data?.length) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "toast-errors.sqlserver-not-found",
                `chS9nG_${resp?.status}`
              )
            )
          );
        }
        //initialData
        let dataList = [];
        //eslint-disable-next-line array-callback-return
        resp.data.map(vol => {
          dataList.push({
            title: vol.volume_path,
            size: util.formatBytes(vol.volume_size),
            type: "Volume",
            id: uuidv4(),
            key: uuidv4(),
            children: [],
            catalog_file_path: vol.catalog_file_path,
            mount_disk_signature: vol.mount_disk_signature,
            mount_flag: vol.mount_flag,
            mount_path: vol.mount_path,
            recovery_point_path: vol.recovery_point_path,
            server_id: vol.server_id,
            server_name: vol.server_name,
            session_guid: vol.session_guid,
            session_id: vol.session_id,
            sub_session_id: vol.sub_session_id,
            time: vol.time,
            volume_attribute: vol.volume_attribute,
            volume_guid: vol.volume_guid,
            volume_path: vol.volume_path,
            volume_size: vol.volume_size
          });
        });
        yield put(
          updateFolderTreeData(
            dataList.length ? _.sortBy(dataList, ["volume_path"]) : []
          )
        );
        // if (source.type === "oracleRecovery") {
        //   if (!sourceEncryption || (sourceEncryption && encryptPassword)) {
        //     let payload = {
        //       backup_destination_info: {
        //         destination_path: source.destination_path,
        //         destination_id: source.datastore_id,
        //         destination_type: "datastore"
        //       },
        //       catalog_path: dataList?.[0]?.catalog_file_path,
        //       volume_guid: dataList?.[0]?.volume_guid,
        //       database_name: source?.oracle_db_name,
        //       pdb_name: "",
        //       session_id: dataList?.[0]?.session_id,
        //       encryption_password: encryptPassword,
        //       source_id: source.source_id,
        //       defaultApi: true
        //     };
        //     yield put(getRecoveryOracleDBConfig(payload));
        //   }
        // }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.AVAILABLE_VOLUMES));
    yield put(hideFolderStructureLoader());
  }
}

export function* fetchItemsForRecoveryPoint({ data }) {
  try {
    yield put(setLoading(Loader.AVAILABLE_VOLUMES));
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const volumeData = yield select(folderTreeData);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const rpPass = yield select(rpEncryptionPassword);
    const selectedDestination = yield select(selectedDestinationData);
    const selectedRpsWithPasswordList = yield select(
      selectedRpsListWithPassword
    );
    let sqlDBDetails = yield select(sqlDbDetails);

    const selectedSessionID =
      recoveryPoint.session_Id || recoveryPoint.session_id;
    const selectedRPPassDetails = selectedRpsWithPasswordList?.length
      ? selectedRpsWithPasswordList.filter(
          item => item.session_Id === selectedSessionID
        )?.[0]
      : null;
    if (recoveryPoint?.encrypted && !selectedRPPassDetails?.encrypt_pass) {
      yield put(showDialog(Dialog.VERIFY_PASSWORD_DIALOG));
    } else {
      const volume = volumeData.filter(
        vol => vol.volume_guid === data.volume_guid
      );
      let selectedVolume = [],
        found = false;
      if (
        data.full_path &&
        volume &&
        volume[0].children &&
        volume[0].children.length
      ) {
        function findChild(list) {
          if ((!selectedVolume || !selectedVolume.length) && !found) {
            selectedVolume = list.filter(
              item => item.full_path === data.full_path
            );
          }
          if (!selectedVolume || !selectedVolume.length) {
            list.forEach(item => {
              if (item.children && item.children.length)
                findChild(item.children);
            });
          } else {
            found = true;
          }
        }
        findChild(volume[0].children);
      } else {
        selectedVolume = volume;
      }
      if (selectedVolume && selectedVolume.length) {
        let selectedVolumeName = selectedVolume[0].isChild
          ? selectedVolume[0].name
          : selectedVolume[0].volume_path;
        if (data.updateTable || source.from_destination) {
          yield put(startLoader());
        }
        const resp = yield call(
          protectApi.getItemsForRecoveryPoint,
          {
            name: selectedVolumeName,
            root_volume_path: selectedVolume[0].isChild
              ? selectedVolume[0].parent_volume_path
              : selectedVolume[0].volume_path, // full path for sub items
            linux_server_id: "",
            destination_type: source.destination_type,
            from_destination: source.from_destination || undefined,
            proxy_id: source.from_destination
              ? source?.rps_server_id
              : undefined,
            destination_info: {
              destination_path: source.destination_path, // req
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              ),
              datastore_id: source.datastore_id
            },
            session_info: {
              catalog_path: selectedVolume[0].isChild
                ? selectedVolume[0].catalog_path
                : selectedVolume[0].catalog_file_path, // req
              parent_id: selectedVolume[0].isChild
                ? selectedVolume[0].parent_id
                : -1, // req selectedVolume.parent_id for sub items
              session_id: selectedVolume[0].session_id, // req
              parent_path: selectedVolume[0].isChild
                ? selectedVolume[0].parent_path
                : "", // selectedVolume.parent_path for sub items
              volume_guid: selectedVolume[0].volume_guid,
              encrypted_password: recoveryPoint.encrypted ? rpPass : "",
              include_file: true,
              session_type: recoveryPoint.session_type
            },
            page:
              data.name === "Load Next"
                ? data.currPage + 1
                : data.name === "Load Prev"
                ? data.currPage - 1
                : data.currPage + 1,
            page_size: 25,
            from_datastore: source.from_datastore
          },
          source.source_id,
          token
        );
        if (resp.status === constant.HTTP_STATUS.OK) {
          let sqlDBDetailsResp;
          if (
            !sqlDBDetails &&
            selectedVolume[0] &&
            selectedVolume[0].volume_guid === "SqlServerWriter" &&
            selectedVolume[0].type === "sql_logicalpath"
          ) {
            sqlDBDetailsResp = yield call(
              protectApi.getSQLDBDetailsForRecoveryPoint,
              {
                backup_destination_info: {
                  destination_path: source.destination_path,
                  destination_id: _.get(
                    selectedDestination,
                    "destination_id",
                    ""
                  ),
                  destination_type: _.get(
                    selectedDestination,
                    "destination_type",
                    ""
                  )
                },
                session_id: selectedVolume[0].session_id,
                sub_session_id: selectedVolume[0].sub_session_id
              },
              source.source_id,
              token
            );
            if (_.get(sqlDBDetailsResp, "status", null) === 200) {
              yield put(storeSQLDbDetails(sqlDBDetailsResp));
              sqlDBDetails = yield select(sqlDbDetails);
            } else {
              return;
            }
          }
          let tempTreedata = [...volumeData];
          let childList = [],
            child;
          function addNodes(tempTreedata) {
            //eslint-disable-next-line array-callback-return
            tempTreedata.map(ele => {
              if (
                (ele.volume_guid === data.volume_guid &&
                  !ele.isChild &&
                  !data.full_path) ||
                (ele.isChild && ele.full_path === data.full_path)
              ) {
                let hasNextOrPrev = false;
                if (
                  ele &&
                  ele.children &&
                  ele.children.length &&
                  ele.children[0].name === "Load Prev" &&
                  ele.children[0].type === "loader"
                ) {
                  hasNextOrPrev = true;
                }
                if (
                  ele &&
                  ele.children &&
                  ele.children.length &&
                  ele.children[ele.children.length - 1].name === "Load Next" &&
                  ele.children[ele.children.length - 1].type === "loader"
                ) {
                  hasNextOrPrev = true;
                }

                if (
                  !ele.children ||
                  ele.children.length === 0 ||
                  hasNextOrPrev
                ) {
                  if (hasNextOrPrev) {
                    ele.children = [];
                    childList = [];
                  }
                  let selectedDbDetails = sqlDBDetails?.data
                    ? sqlDBDetails.data.filter(item => {
                        return item.name === selectedVolumeName;
                      })
                    : "";
                  //eslint-disable-next-line array-callback-return
                  resp.data.map(item => {
                    child = {
                      title: item.name,
                      id: uuidv4(),
                      key: uuidv4(),
                      children: [],
                      can_export: item.can_export,
                      catalog_path: item.catalog_path,
                      display_full_path: item.display_full_path,
                      full_path: item.full_path,
                      has_folder: item.has_folder,
                      modify_ts: item.modify_ts,
                      name: item.name,
                      parent_id: item.parent_id,
                      parent_path: item.parent_path,
                      root_volume_path: item.root_volume_path,
                      session_id: item.session_id,
                      size:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable"
                          ? sqlDBDetails &&
                            sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                            selectedDbDetails[0]
                            ? util.sqlComponentSize(
                                selectedDbDetails[0].database_list,
                                item.name
                              ) === 0
                              ? null
                              : util.formatBytes(
                                  util.sqlComponentSize(
                                    selectedDbDetails[0].database_list,
                                    item.name
                                  )
                                )
                            : 0
                          : item.size === 0
                          ? null
                          : util.formatBytes(item.size),
                      instance_name:
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? selectedDbDetails[0].name
                          : "",
                      pit_enabled:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentPitEnabled(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : false,
                      start_time_point:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentStartTimePoint(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : "",
                      end_time_point:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentEndTimePoint(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : "",
                      time_point_count:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentTimePointCount(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : 0,
                      sub_session_id: item.sub_session_id,
                      type: item.type,
                      volume_guid: item.vol_guid,
                      isChild: true,
                      parent_volume_path: ele.isChild
                        ? ele.parent_volume_path
                        : ele.volume_path,
                      volume_attribute: ele.volume_attribute,
                      subSession_id: ele.isChild
                        ? ele.subSession_id
                        : ele.sub_session_id,
                      canDownload:
                        item.type === "sql_node" ||
                        item.type === "sql_writer" ||
                        item.type === "sql_component" ||
                        item.type === "sql_component_selectable" ||
                        item.type === "sql_logicalpath"
                          ? false
                          : true
                    };

                    childList.push({ ...child });
                    ele.children.push({ ...child });
                    if (resp && resp.pagination) {
                      ele.pagination = { ...resp.pagination };
                    }
                  });
                  if (resp.pagination.has_prev) {
                    let prevLoad = {
                      title: "Load Prev",
                      id: uuidv4(),
                      key: uuidv4(),
                      children: [],
                      can_export: ele.can_export,
                      catalog_path: ele.catalog_path,
                      display_full_path: ele.display_full_path,
                      full_path: ele.full_path,
                      has_folder: ele.has_folder,
                      modify_ts: ele.modify_ts,
                      name: "Load Prev",
                      parent_id: ele.parent_id,
                      parent_path: ele.parent_path,
                      volume_guid: ele.volume_guid,
                      root_volume_path: ele.root_volume_path,
                      session_id: ele.session_id,
                      size: null,
                      sub_session_id: ele.sub_session_id,
                      type: "loader",
                      isChild: ele.isChild,
                      parent_volume_path: ele.isChild
                        ? ele.parent_volume_path
                        : ele.volume_path,
                      volume_attribute: ele.volume_attribute,
                      subSession_id: ele.subSession_id,
                      canDownload: false,
                      currPage: resp.pagination.curr_page
                    };
                    childList.unshift({ ...prevLoad });
                    ele.children.unshift({ ...prevLoad });
                  }
                  if (resp.pagination.has_next) {
                    let nextLoad = {
                      title: "Load Next",
                      id: uuidv4(),
                      key: uuidv4(),
                      children: [],
                      can_export: ele.can_export,
                      catalog_path: ele.catalog_path,
                      display_full_path: ele.display_full_path,
                      full_path: ele.full_path,
                      has_folder: ele.has_folder,
                      modify_ts: ele.modify_ts,
                      name: "Load Next",
                      parent_id: ele.parent_id,
                      parent_path: ele.parent_path,
                      volume_guid: ele.volume_guid,
                      root_volume_path: ele.root_volume_path,
                      session_id: ele.session_id,
                      size: null,
                      sub_session_id: ele.sub_session_id,
                      type: "loader",
                      isChild: ele.isChild,
                      parent_volume_path: ele.isChild
                        ? ele.parent_volume_path
                        : ele.volume_path,
                      volume_attribute: ele.volume_attribute,
                      subSession_id: ele.subSession_id,
                      canDownload: false,
                      currPage: resp.pagination.curr_page
                    };
                    childList.push({ ...nextLoad });
                    ele.children.push({ ...nextLoad });
                  }
                  return ele;
                } else {
                  return addNodes(ele.children);
                }
              }
              if (ele.children && ele.children.length) {
                return addNodes(ele.children);
              }
            });
          }
          addNodes(tempTreedata);
          yield put(updateFolderTreeData(tempTreedata));
          if (data.updateTable || source?.from_destination) {
            let pagination =
              resp && resp.pagination ? { ...resp.pagination } : {};
            let currSelectedData = { ...data };
            yield put(updateFnFGridData(childList));
            yield put(
              updateFnFGridDataPagination({ pagination, currSelectedData })
            );
            let currPage =
              pagination && pagination.curr_page ? pagination.curr_page : 1;
            yield put(updateGridCurrentPageNumber(currPage));
          }
        } else {
          if (resp.errors && resp.errors.length === 0)
            yield put(
              ccToast.addErrorNotification(
                makeToastData(
                  _.get(resp, "errors[0].message", ""),
                  `uXBzR1_${resp?.status}`
                )
              )
            );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.AVAILABLE_VOLUMES));
    yield put(hideFolderStructureLoader());
  }
}

export function* fetchItemsDataForRecoveryPointLinux({ data }) {
  try {
    yield put(setLoading(Loader.AVAILABLE_VOLUMES));
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const volumeData = yield select(folderTreeData);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const rpPass = yield select(rpEncryptionPassword);
    const lbs = yield select(updatedLbs);
    const destinationData = yield select(destinationsData);
    const linuxBackupServerId =
      destinationData &&
      destinationData[0] &&
      destinationData[0].linux_server_id;
    const selectedDestination = yield select(selectedDestinationData);
    let sqlDBDetails = yield select(sqlDbDetails);
    if (recoveryPoint.encrypted && !rpPass) {
      yield put(showDialog(Dialog.VERIFY_PASSWORD_DIALOG));
    } else {
      const volume = volumeData.filter(
        vol => vol.volume_guid === data.volume_guid
      );
      let selectedVolume = [],
        found = false;
      if (
        data.full_path &&
        volume &&
        volume[0].children &&
        volume[0].children.length
      ) {
        function findChild(list) {
          if ((!selectedVolume || !selectedVolume.length) && !found) {
            selectedVolume = list.filter(
              item => item.full_path === data.full_path
            );
          }
          if (!selectedVolume || !selectedVolume.length) {
            list.forEach(item => {
              if (item.children && item.children.length)
                findChild(item.children);
            });
          } else {
            found = true;
          }
        }
        findChild(volume[0].children);
      } else {
        selectedVolume = volume;
      }
      if (selectedVolume && selectedVolume.length) {
        let selectedVolumeName = selectedVolume[0].isChild
          ? selectedVolume[0].name
          : selectedVolume[0].volume_path;
        const resp = yield call(
          protectApi.getItemsForRecoveryPointLinux,
          {
            name: recoveryPoint && recoveryPoint.name,
            linux_server_id: linuxBackupServerId || lbs,
            destination_info: {
              destination_path: source.destination_path, // req
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              )
            },
            session_info: {
              catalog_path: data.full_path
                ? data.full_path
                : data.catalog_path
                ? data.catalog_path
                : "", // req
              parent_id: data.parent_id === 0 ? data.parent_id : -1, // req selectedVolume.parent_id for sub items
              session_id: recoveryPoint && recoveryPoint.session_id, // req
              parent_path: "", // selectedVolume.parent_path for sub items
              encrypted_password:
                recoveryPoint && recoveryPoint.encrypted ? rpPass : "",
              hbbu_path: recoveryPoint && recoveryPoint.hbbu_path,
              encrypt_pswd_hash_key:
                (recoveryPoint && recoveryPoint.encrypt_pswd_hash_key) || "",
              encryption_type: recoveryPoint && recoveryPoint.encryption_type,
              session_type: recoveryPoint.session_type
              // include_file: true
            },
            page:
              data.name === "Load Next"
                ? data.currPage + 1
                : data.name === "Load Prev"
                ? data.currPage - 1
                : data.currPage + 1,
            page_size: 25
          },
          source.source_id,
          token
        );
        if (resp.status === constant.HTTP_STATUS.OK) {
          let tempTreedata = [...volumeData];
          let childList = [],
            child;
          function addNodes(tempTreedata) {
            //eslint-disable-next-line array-callback-return
            tempTreedata.map(ele => {
              if (
                (ele.volume_guid === data.volume_guid &&
                  !ele.isChild &&
                  !data.full_path) ||
                (ele.isChild && ele.full_path === data.full_path)
              ) {
                let hasNextOrPrev = false;
                if (
                  ele &&
                  ele.children &&
                  ele.children.length &&
                  ele.children[0].name === "Load Prev" &&
                  ele.children[0].type === "loader"
                ) {
                  hasNextOrPrev = true;
                }
                if (
                  ele &&
                  ele.children &&
                  ele.children.length &&
                  ele.children[ele.children.length - 1].name === "Load Next" &&
                  ele.children[ele.children.length - 1].type === "loader"
                ) {
                  hasNextOrPrev = true;
                }

                if (
                  !ele.children ||
                  ele.children.length === 0 ||
                  hasNextOrPrev
                ) {
                  if (hasNextOrPrev) {
                    ele.children = [];
                    childList = [];
                  }
                  let selectedDbDetails = sqlDBDetails?.data
                    ? sqlDBDetails.data.filter(item => {
                        return item.name === selectedVolumeName;
                      })
                    : "";
                  //eslint-disable-next-line array-callback-return
                  resp.data.map(item => {
                    child = {
                      title: item.name,
                      id: uuidv4(),
                      key: uuidv4(),
                      children: [],
                      can_export: item.can_export,
                      catalog_path: item.catalog_path,
                      display_full_path: item.display_full_path,
                      full_path: item.full_path,
                      has_folder: item.has_folder,
                      modify_ts: item.modify_ts,
                      name: item.name,
                      parent_id: item.parent_id,
                      parent_path: item.parent_path,
                      root_volume_path: item.root_volume_path,
                      session_id: item.session_id,
                      size:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable"
                          ? sqlDBDetails &&
                            sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                            selectedDbDetails[0]
                            ? util.sqlComponentSize(
                                selectedDbDetails[0].database_list,
                                item.name
                              ) === 0
                              ? null
                              : util.formatBytes(
                                  util.sqlComponentSize(
                                    selectedDbDetails[0].database_list,
                                    item.name
                                  )
                                )
                            : 0
                          : item.size === 0
                          ? null
                          : util.formatBytes(item.size),
                      instance_name:
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? selectedDbDetails[0].name
                          : "",
                      pit_enabled:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentPitEnabled(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : false,
                      start_time_point:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentStartTimePoint(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : "",
                      end_time_point:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentEndTimePoint(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : "",
                      time_point_count:
                        item.vol_guid === "SqlServerWriter" &&
                        item.type === "sql_component_selectable" &&
                        sqlDBDetails &&
                        sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                        selectedDbDetails[0]
                          ? util.sqlComponentTimePointCount(
                              selectedDbDetails[0].database_list,
                              item.name
                            )
                          : 0,
                      sub_session_id: item.sub_session_id,
                      type: item.type,
                      volume_guid: item.vol_guid,
                      isChild: true,
                      parent_volume_path: ele.isChild
                        ? ele.parent_volume_path
                        : ele.volume_path,
                      volume_attribute: ele.volume_attribute,
                      subSession_id: ele.isChild
                        ? ele.subSession_id
                        : ele.sub_session_id,
                      canDownload:
                        item.type === "sql_node" ||
                        item.type === "sql_writer" ||
                        item.type === "sql_component" ||
                        item.type === "sql_component_selectable" ||
                        item.type === "sql_logicalpath"
                          ? false
                          : true
                    };

                    childList.push({ ...child });
                    ele.children.push({ ...child });
                    if (resp && resp.pagination) {
                      ele.pagination = { ...resp.pagination };
                    }
                  });
                  if (resp.pagination.has_prev) {
                    let prevLoad = {
                      title: "Load Prev",
                      id: uuidv4(),
                      key: uuidv4(),
                      children: [],
                      can_export: ele.can_export,
                      catalog_file_path: ele.isChild
                        ? ele.catalog_path
                        : ele.catalog_file_path,
                      display_full_path: ele.display_full_path,
                      full_path: ele.full_path,
                      has_folder: ele.has_folder,
                      modify_ts: ele.modify_ts,
                      name: "Load Prev",
                      parent_id: ele.parent_id,
                      parent_path: ele.parent_path,
                      volume_guid: ele.volume_guid,
                      root_volume_path: ele.root_volume_path,
                      session_id: ele.session_id,
                      size: null,
                      sub_session_id: ele.sub_session_id,
                      type: "loader",
                      isChild: ele.isChild,
                      parent_volume_path: ele.isChild
                        ? ele.parent_volume_path
                        : ele.volume_path,
                      volume_attribute: ele.volume_attribute,
                      subSession_id: ele.subSession_id,
                      canDownload: false,
                      currPage: resp.pagination.curr_page
                    };
                    childList.unshift({ ...prevLoad });
                    ele.children.unshift({ ...prevLoad });
                  }
                  if (resp.pagination.has_next) {
                    let nextLoad = {
                      title: "Load Next",
                      id: uuidv4(),
                      key: uuidv4(),
                      children: [],
                      can_export: ele.can_export,
                      catalog_file_path: ele.isChild
                        ? ele.catalog_path
                        : ele.catalog_file_path,
                      display_full_path: ele.display_full_path,
                      full_path: ele.full_path,
                      has_folder: ele.has_folder,
                      modify_ts: ele.modify_ts,
                      name: "Load Next",
                      parent_id: ele.parent_id,
                      parent_path: ele.parent_path,
                      volume_guid: ele.volume_guid,
                      root_volume_path: ele.root_volume_path,
                      session_id: ele.session_id,
                      size: null,
                      sub_session_id: ele.sub_session_id,
                      type: "loader",
                      isChild: ele.isChild,
                      parent_volume_path: ele.isChild
                        ? ele.parent_volume_path
                        : ele.volume_path,
                      volume_attribute: ele.volume_attribute,
                      subSession_id: ele.subSession_id,
                      canDownload: false,
                      currPage: resp.pagination.curr_page
                    };
                    childList.push({ ...nextLoad });
                    ele.children.push({ ...nextLoad });
                  }
                  return ele;
                } else {
                  return addNodes(ele.children);
                }
              }
              if (ele.children && ele.children.length) {
                return addNodes(ele.children);
              }
            });
          }
          addNodes(tempTreedata);
          yield put(updateFolderTreeData(tempTreedata));
          if (data.updateTable) {
            let pagination =
              resp && resp.pagination ? { ...resp.pagination } : {};
            let currSelectedData = { ...data };
            yield put(updateFnFGridData(childList));
            yield put(
              updateFnFGridDataPagination({ pagination, currSelectedData })
            );
            let currPage =
              pagination && pagination.curr_page ? pagination.curr_page : 1;
            yield put(updateGridCurrentPageNumber(currPage));
          }
        } else {
          if (resp.errors && resp.errors.length === 0)
            yield put(
              ccToast.addErrorNotification(
                makeToastData(
                  _.get(resp, "errors[0].message", ""),
                  `8wpdoN_${resp?.status}`
                )
              )
            );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.AVAILABLE_VOLUMES));
    yield put(hideFolderStructureLoader());
  }
}

export function* fetchItemsForRecoveryPointLinux({ data }) {
  try {
    yield put(setLoading(Loader.AVAILABLE_VOLUMES));
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    // const volumeData = yield select(folderTreeData);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const rpPass = yield select(rpEncryptionPassword);
    const lbs = yield select(updatedLbs);
    const selectedDestination = yield select(selectedDestinationData);
    // let sqlDBDetails = yield select(sqlDbDetails);
    if (recoveryPoint && recoveryPoint) {
      const resp = yield call(
        protectApi.getItemsForRecoveryPointLinux,
        {
          name: recoveryPoint && recoveryPoint.name,
          linux_server_id: data.linuxBackupServerId || lbs,
          destination_info: {
            destination_path: source.destination_path, // req
            // username: source.username,
            // password: source.password,
            destination_id: _.get(selectedDestination, "destination_id", ""),
            destination_type: _.get(selectedDestination, "destination_type", "")
          },
          session_info: {
            catalog_path: "", // req
            parent_id: -1, // req selectedVolume.parent_id for sub items
            session_id: recoveryPoint && recoveryPoint.session_id, // req
            parent_path: "", // selectedVolume.parent_path for sub items
            encrypted_password:
              recoveryPoint && recoveryPoint.encrypted ? rpPass : "",
            hbbu_path: recoveryPoint && recoveryPoint.hbbu_path,
            encrypt_pswd_hash_key:
              (recoveryPoint && recoveryPoint.encrypt_pswd_hash_key) || "",
            encryption_type: recoveryPoint && recoveryPoint.encryption_type,
            session_type: recoveryPoint.session_type
            // include_file: true
          },
          page: 1,
          page_size: 25
        },
        source.source_id,
        token,
        { showCommonError: true, testId: "py9NH1" }
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        if (resp.data) {
          //initialData
          let dataList = [];
          //eslint-disable-next-line array-callback-return
          resp.data.map(vol => {
            dataList.push({
              name: vol.name,
              title: vol.full_path,
              size: util.formatBytes(vol.size),
              type: vol.type,
              id: uuidv4(),
              key: uuidv4(),
              children: [],
              catalog_file_path: vol.catalog_path,
              session_id: vol.session_id,
              sub_session_id: vol.sub_session_id,
              volume_guid: vol.vol_guid,
              volume_size: vol.size,
              parent_id: vol.parent_id
            });
          });
          yield put(
            updateFolderTreeData(
              dataList.length ? _.sortBy(dataList, ["title"]) : []
            )
          );
        }
      } else {
        // if (resp.errors && resp.errors.length === 0)
        //   yield put(
        //     ccToast.addErrorNotification(
        //       makeToastData(
        //         _.get(resp, "errors[0].message", ""),
        //         `py9NH1_${resp?.status}`
        //       )
        //     )
        //   );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.AVAILABLE_VOLUMES));
    yield put(hideFolderStructureLoader());
  }
}

/**
 * This method is used to perform single action on VM (Disaster Recovery > Instant VMs)
 * @param {*} data
 */
function* singleActionOnVM(data) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    let id = _.get(data, "data.id", 0);
    let action = _.get(data, "data.action", null);

    const resp = yield call(
      protectApi.singleActionOnVM,
      id,
      action,
      login.token,
      { showCommonError: true, testId: "Xx3AS5" }
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getProtectListCount());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            `protect.RecoveredResources.instant_vm.${action}.confirmed_message`,
            "z6RToB"
          )
        )
      );
      yield put(getCurrentActionCalled({ onAction: true }));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

/**
 * This method is used to perform bulk action on the VM (Disaster Recovery > Instant VMs)
 * @param {*} data
 */
function* bulkActionOnVM(data) {
  try {
    yield put(startLoader());
    const login = yield select(getLogin);
    let ids = _.get(data, "data.ids", 0);
    let action = _.get(data, "data.action", null);

    const resp = yield call(
      protectApi.bulkActionOnVM,
      ids,
      action,
      login.token,
      { showCommonError: true, testId: "gbs65U" }
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getProtectListCount());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            `protect.RecoveredResources.instant_vm.${action}.confirmed_message`,
            "jgMwMV"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* updateFnFTableData({ data }) {
  yield put(startLoader());
  yield put(updateFnFGridData([]));
  const { volume_guid, full_path } = data;
  const volumeData = yield select(folderTreeData);
  const volume = volumeData.filter(vol => vol.volume_guid === volume_guid);
  let selectedVolume = [],
    found = false;
  if (
    full_path &&
    volume &&
    volume.length &&
    volume[0].children &&
    volume[0].children.length
  ) {
    function findChild(list) {
      if ((!selectedVolume || !selectedVolume.length) && !found) {
        selectedVolume = list.filter(item => item.full_path === data.full_path);
      }
      if (!selectedVolume || !selectedVolume.length) {
        list.forEach(item => {
          if (item.children && item.children.length) findChild(item.children);
        });
      } else {
        found = true;
      }
    }
    findChild(volume[0].children);
  } else {
    selectedVolume = volume;
  }
  if (
    selectedVolume &&
    selectedVolume[0] &&
    selectedVolume[0].children &&
    selectedVolume[0].children.length
  ) {
    let tableData = [];
    //eslint-disable-next-line array-callback-return
    selectedVolume[0].children.map(item => {
      tableData.push({
        title: item.name,
        id: uuidv4(),
        key: uuidv4(),
        children: [],
        can_export: item.can_export,
        catalog_path: item.catalog_path,
        display_full_path: item.display_full_path,
        full_path: item.full_path,
        has_folder: item.has_folder,
        modify_ts: item.modify_ts,
        name: item.name,
        parent_id: item.parent_id,
        parent_path: item.parent_path,
        root_volume_path: item.root_volume_path,
        session_id: item.session_id,
        size: item.size,
        instance_name: item.instance_name,
        pit_enabled: item.pit_enabled,
        start_time_point: item.start_time_point,
        end_time_point: item.end_time_point,
        time_point_count: item.time_point_count,
        sub_session_id: item.sub_session_id,
        type: item.type,
        volume_guid: item.volume_guid,
        volume_attribute: item.volume_attribute,
        subSession_id: item.isChild ? item.subSession_id : item.sub_session_id,
        canDownload:
          item.type === "sql_node" ||
          item.type === "sql_writer" ||
          item.type === "sql_component" ||
          item.type === "sql_component_selectable" ||
          item.type === "sql_logicalpath"
            ? false
            : true
      });
    });

    yield put(updateFnFGridData(tableData));
    let pagination = data && data.pagination ? { ...data.pagination } : {};
    let currSelectedData = { ...data };
    yield put(updateFnFGridDataPagination({ pagination, currSelectedData }));
    let currPage =
      pagination && pagination.curr_page ? pagination.curr_page : 1;
    yield put(updateGridCurrentPageNumber(currPage));
  } else {
    // yield put(startLoader());
    yield put(updateFnFGridData([]));
    let pagination = {};
    let currSelectedData = { ...data };
    yield put(updateFnFGridDataPagination({ pagination, currSelectedData }));
    yield put(fetchItemsForRP({ ...data, updateTable: true }));
    // yield call(delay, 40000);
    // yield put(stopLoader());
  }
  yield put(stopLoader());
}

export function* updateFnFTableDataLinux({ data }) {
  yield put(startLoader());
  yield put(updateFnFGridData([]));
  const { volume_guid, full_path } = data;
  const volumeData = yield select(folderTreeData);
  const volume = volumeData.filter(vol => vol.volume_guid === volume_guid);
  let selectedVolume = [],
    found = false;
  if (
    full_path &&
    volume &&
    volume.length &&
    volume[0].children &&
    volume[0].children.length
  ) {
    function findChild(list) {
      if ((!selectedVolume || !selectedVolume.length) && !found) {
        selectedVolume = list.filter(item => item.full_path === data.full_path);
      }
      if (!selectedVolume || !selectedVolume.length) {
        list.forEach(item => {
          if (item.children && item.children.length) findChild(item.children);
        });
      } else {
        found = true;
      }
    }
    findChild(volume[0].children);
  } else {
    selectedVolume = volume;
  }
  if (
    selectedVolume &&
    selectedVolume[0] &&
    selectedVolume[0].children &&
    selectedVolume[0].children.length
  ) {
    let tableData = [];
    //eslint-disable-next-line array-callback-return
    selectedVolume[0].children.map(item => {
      tableData.push({
        title: item.name,
        id: uuidv4(),
        key: uuidv4(),
        children: [],
        can_export: item.can_export,
        catalog_path: item.catalog_path,
        display_full_path: item.display_full_path,
        full_path: item.full_path,
        has_folder: item.has_folder,
        modify_ts: item.modify_ts,
        name: item.name,
        parent_id: item.parent_id,
        parent_path: item.parent_path,
        root_volume_path: item.root_volume_path,
        session_id: item.session_id,
        size: item.size,
        instance_name: item.instance_name,
        pit_enabled: item.pit_enabled,
        start_time_point: item.start_time_point,
        end_time_point: item.end_time_point,
        time_point_count: item.time_point_count,
        sub_session_id: item.sub_session_id,
        type: item.type,
        volume_guid: item.volume_guid,
        volume_attribute: item.volume_attribute,
        subSession_id: item.isChild ? item.subSession_id : item.sub_session_id,
        canDownload:
          item.type === "sql_node" ||
          item.type === "sql_writer" ||
          item.type === "sql_component" ||
          item.type === "sql_component_selectable" ||
          item.type === "sql_logicalpath"
            ? false
            : true
      });
    });

    yield put(updateFnFGridData(tableData));
    let pagination = data && data.pagination ? { ...data.pagination } : {};
    let currSelectedData = { ...data };
    yield put(updateFnFGridDataPagination({ pagination, currSelectedData }));
    let currPage =
      pagination && pagination.curr_page ? pagination.curr_page : 1;
    yield put(updateGridCurrentPageNumber(currPage));
  } else {
    yield put(startLoader());
    yield put(updateFnFGridData([]));
    let pagination = {};
    let currSelectedData = { ...data };
    yield put(updateFnFGridDataPagination({ pagination, currSelectedData }));
    yield put(
      protectAction.fetchItemsDataForRPLinux({ ...data, updateTable: true })
    );
    yield call(delay, 40000);
    yield put(stopLoader());
  }
  yield put(stopLoader());
}

export function* fetchFnFTableDataPagination({
  gridCurrentPage,
  gridPageSize
}) {
  try {
    yield put(updateFnFGridData([]));
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const volumeData = yield select(folderTreeData);
    const data = yield select(currSelectedTableData);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const rpPass = yield select(rpEncryptionPassword);
    let sqlDBDetails = yield select(sqlDbDetails);
    const lbs = yield select(updatedLbs);
    const selectedDestination = yield select(selectedDestinationData);
    const destinationData = yield select(destinationsData);
    const linuxBackupServerId =
      destinationData &&
      destinationData[0] &&
      destinationData[0].linux_server_id;
    const volume = volumeData.filter(
      vol => vol.volume_guid === data.volume_guid
    );
    let selectedVolume = [],
      found = false;
    if (
      data.full_path &&
      volume &&
      volume[0].children &&
      volume[0].children.length
    ) {
      function findChild(list) {
        if ((!selectedVolume || !selectedVolume.length) && !found) {
          selectedVolume = list.filter(
            item => item.full_path === data.full_path
          );
        }
        if (!selectedVolume || !selectedVolume.length) {
          list.forEach(item => {
            if (item.children && item.children.length) findChild(item.children);
          });
        } else {
          found = true;
        }
      }
      findChild(volume[0].children);
    } else {
      selectedVolume = volume;
    }

    if (selectedVolume && selectedVolume.length) {
      let resp;
      if (source && source.os_major === "linux") {
        resp = yield call(
          protectApi.getItemsForRecoveryPointLinux,
          {
            name: recoveryPoint && recoveryPoint.name,
            linux_server_id: linuxBackupServerId || lbs,
            destination_info: {
              destination_path: source.destination_path, // req
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              )
            },
            session_info: {
              catalog_path: selectedVolume[0].isChild
                ? selectedVolume[0].catalog_path
                : selectedVolume[0].catalog_file_path, // req
              parent_id: selectedVolume[0].isChild
                ? selectedVolume[0].parent_id
                : -1, // req selectedVolume.parent_id for sub items
              session_id: recoveryPoint && recoveryPoint.session_id, // req
              parent_path: selectedVolume[0].isChild
                ? selectedVolume[0].parent_path
                : "", // selectedVolume.parent_path for sub items
              encrypted_password: recoveryPoint.encrypted ? rpPass : "",
              hbbu_path: recoveryPoint && recoveryPoint.hbbu_path,
              encrypt_pswd_hash_key:
                (recoveryPoint && recoveryPoint.encrypt_pswd_hash_key) || "",
              encryption_type: recoveryPoint && recoveryPoint.encryption_type,
              include_file: true,
              session_type: recoveryPoint.session_type
            },
            page: gridCurrentPage ? gridCurrentPage : 1,
            page_size: gridPageSize ? gridPageSize : 25
          },
          source.source_id,
          token
        );
      } else {
        resp = yield call(
          protectApi.getItemsForRecoveryPoint,
          {
            name: selectedVolume[0].isChild
              ? selectedVolume[0].name
              : selectedVolume[0].volume_path,
            root_volume_path: selectedVolume[0].isChild
              ? selectedVolume[0].parent_volume_path
              : selectedVolume[0].volume_path, // full path for sub items
            linux_server_id: "",
            destination_type: source.destination_type,
            destination_info: {
              destination_path: source.destination_path, // req
              // username: source.username,
              // password: source.password,
              destination_id: _.get(selectedDestination, "destination_id", ""),
              destination_type: _.get(
                selectedDestination,
                "destination_type",
                ""
              ),
              datastore_id: source.datastore_id
            },
            session_info: {
              catalog_path: selectedVolume[0].isChild
                ? selectedVolume[0].catalog_path
                : selectedVolume[0].catalog_file_path, // req
              parent_id: selectedVolume[0].isChild
                ? selectedVolume[0].parent_id
                : -1, // req selectedVolume.parent_id for sub items
              session_id: selectedVolume[0].session_id, // req
              parent_path: selectedVolume[0].isChild
                ? selectedVolume[0].parent_path
                : "", // selectedVolume.parent_path for sub items
              volume_guid: selectedVolume[0].volume_guid,
              encrypted_password: recoveryPoint.encrypted ? rpPass : "",
              include_file: true,
              session_type: recoveryPoint.session_type
            },
            page: gridCurrentPage ? gridCurrentPage : 1,
            page_size: gridPageSize ? gridPageSize : 25,
            from_destination: source.from_destination,
            proxy_id: source.from_destination ? source.rps_server_id : undefined
          },
          source.source_id,
          token
        );
      }

      let selectedVolumeName = selectedVolume[0].isChild
        ? selectedVolume[0].name
        : selectedVolume[0].volume_path;
      if (resp.status === constant.HTTP_STATUS.OK) {
        let tempTreedata = [...volumeData];
        let childList = [],
          child;
        function addNodes(tempTreedata) {
          //eslint-disable-next-line array-callback-return
          tempTreedata.map(ele => {
            if (
              ((ele.volume_guid === data.volume_guid && !ele.isChild) ||
                (ele.isChild && ele.full_path === data.full_path)) &&
              !(ele.name === "Load Prev" || ele.name === "Load Next")
            ) {
              if (
                !ele.children ||
                ele.children.length === 0 ||
                ele.full_path === data.full_path
              ) {
                let selectedDbDetails = sqlDBDetails?.data
                  ? sqlDBDetails.data.filter(item => {
                      return item.name === selectedVolumeName;
                    })
                  : "";
                //eslint-disable-next-line array-callback-return
                resp.data.map(item => {
                  child = {
                    title: item.name,
                    id: uuidv4(),
                    key: uuidv4(),
                    children: [],
                    can_export: item.can_export,
                    catalog_path: item.catalog_path,
                    display_full_path: item.display_full_path,
                    full_path: item.full_path,
                    has_folder: item.has_folder,
                    modify_ts: item.modify_ts,
                    name: item.name,
                    parent_id: item.parent_id,
                    parent_path: item.parent_path,
                    root_volume_path: item.root_volume_path,
                    session_id: item.session_id,
                    // size: util.formatBytes(item.size),
                    size:
                      item.vol_guid === "SqlServerWriter" &&
                      item.type === "sql_component_selectable"
                        ? sqlDBDetails &&
                          sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                          selectedDbDetails[0]
                          ? util.sqlComponentSize(
                              selectedDbDetails[0].database_list,
                              item.name
                            ) === 0
                            ? null
                            : util.formatBytes(
                                util.sqlComponentSize(
                                  selectedDbDetails[0].database_list,
                                  item.name
                                )
                              )
                          : 0
                        : item.size === 0
                        ? null
                        : util.formatBytes(item.size),
                    instance_name:
                      sqlDBDetails &&
                      sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                      selectedDbDetails[0]
                        ? selectedDbDetails[0].name
                        : "",
                    pit_enabled:
                      item.vol_guid === "SqlServerWriter" &&
                      item.type === "sql_component_selectable" &&
                      sqlDBDetails &&
                      sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                      selectedDbDetails[0]
                        ? util.sqlComponentPitEnabled(
                            selectedDbDetails[0].database_list,
                            item.name
                          )
                        : false,
                    start_time_point:
                      item.vol_guid === "SqlServerWriter" &&
                      item.type === "sql_component_selectable" &&
                      sqlDBDetails &&
                      sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                      selectedDbDetails[0]
                        ? util.sqlComponentStartTimePoint(
                            selectedDbDetails[0].database_list,
                            item.name
                          )
                        : "",
                    end_time_point:
                      item.vol_guid === "SqlServerWriter" &&
                      item.type === "sql_component_selectable" &&
                      sqlDBDetails &&
                      sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                      selectedDbDetails[0]
                        ? util.sqlComponentEndTimePoint(
                            selectedDbDetails[0].database_list,
                            item.name
                          )
                        : "",
                    time_point_count:
                      item.vol_guid === "SqlServerWriter" &&
                      item.type === "sql_component_selectable" &&
                      sqlDBDetails &&
                      sqlDBDetails.status === constant.HTTP_STATUS.OK &&
                      selectedDbDetails[0]
                        ? util.sqlComponentTimePointCount(
                            selectedDbDetails[0].database_list,
                            item.name
                          )
                        : 0,
                    sub_session_id: item.sub_session_id,
                    type: item.type,
                    volume_guid: item.vol_guid,
                    isChild: true,
                    parent_volume_path: ele.isChild
                      ? ele.parent_volume_path
                      : ele.volume_path,
                    volume_attribute: ele.volume_attribute,
                    subSession_id: ele.isChild
                      ? ele.subSession_id
                      : ele.sub_session_id,
                    canDownload:
                      item.type === "sql_node" ||
                      item.type === "sql_writer" ||
                      item.type === "sql_component" ||
                      item.type === "sql_component_selectable" ||
                      item.type === "sql_logicalpath"
                        ? false
                        : true
                  };

                  childList.push({ ...child });
                });

                return ele;
              } else {
                return addNodes(ele.children);
              }
            }
            if (ele.children && ele.children.length) {
              return addNodes(ele.children);
            }
          });
        }
        addNodes(tempTreedata);

        let pagination = resp && resp.pagination ? { ...resp.pagination } : {};
        yield put(updateFnFGridData(childList));
        yield put(updateFnFPagination({ pagination }));
        let currPage =
          pagination && pagination.curr_page ? pagination.curr_page : 1;
        yield put(updateGridCurrentPageNumber(currPage));
        yield put(stopLoader());
      } else {
        if (resp.errors && resp.errors.length === 0)
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                _.get(resp, "errors[0].message", ""),
                `oIVaJw_${resp?.status}`
              )
            )
          );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.AVAILABLE_VOLUMES));
    yield put(hideFolderStructureLoader());
    yield put(stopLoader());
  }
}

export function* getBackupVmdisks({ id, data, payloadForGetVmNetworksList }) {
  try {
    let token = yield select(getLoginToken);
    // let orgId = yield select(getOrgId);
    const resp = yield call(protectApi.getBackupVmDisksList, id, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getBackupVmdisksSuccess(resp.data));
      yield put(
        protectAction.getNetworkAdapters(id, payloadForGetVmNetworksList)
      );
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    // yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  }
}

export function* getVMNetworkAdaptersList({ id, data }) {
  try {
    let token = yield select(getLoginToken);
    // let orgId = yield select(getOrgId);
    const resp = yield call(protectApi.getVMNetworksList, id, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getNetworkAdaptersSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    // yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  }
}
export function* getNutanixStorage({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.NUTANIX_STORAGE_LOADER));
    const resp = yield call(protectApi.getNutanixStorages, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getNutanixStorageSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
  } finally {
    yield put(resetLoading(Loader.NUTANIX_STORAGE_LOADER));
  }
}
export function* getNutanixNetworks({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.NUTANIX_NETWORK_LOADER));
    const resp = yield call(protectApi.getNutanixNetworks, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getNutanixNetworksSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
  } finally {
    yield put(resetLoading(Loader.NUTANIX_NETWORK_LOADER));
  }
}

export function* getHypervList({ typeData, productType }) {
  try {
    const loginReducer = yield select(getLogin);
    let orgId = yield select(getOrgId);
    yield put(showLoading());
    let product = productType ? `&product_type=${productType}` : "";
    typeData =
      typeData === "esxhost" || typeData === "vcenter"
        ? "esxhost%7Cvcenter"
        : typeData;
    let queryString = `hypervisor_type=${typeData}&pagination=false&organization_id=${orgId}${product}`;
    const resp = yield call(
      protectApi.getHypervisorsListWithFilter,
      queryString,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getHypervListSuccess(resp.data));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* getEsxList() {
  try {
    const loginReducer = yield select(getLogin);
    let orgId = yield select(getOrgId);
    yield put(showLoading());
    let queryString = `hypervisor_type=esxhost%7Cvcenter&organization_id=${orgId}`;
    const resp = yield call(
      protectApi.getHypervisorsListWithFilter,
      queryString,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getEsxVcenterListSuccess(resp.data));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* conectToHypervVMfolderPath({ data }) {
  try {
    let token = yield select(getLoginToken);
    const resp = yield call(protectApi.fetchHypervisorType, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.connectToHyperVSuccess(resp.data));
      yield put(addAPIresponse(resp));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS,
            "0q0RCe"
          )
        )
      );
      const networksResp = yield call(
        protectApi.fetchHypervNetworks,
        data,
        token
      );
      if (networksResp.status === constant.HTTP_STATUS.OK) {
        yield put(protectAction.storeHypervNetworksList(networksResp.data));
      }

      const vmFolderResp = yield call(
        protectApi.fetchDefaultVmFolder,
        data,
        token
      );
      if (vmFolderResp.status === constant.HTTP_STATUS.OK) {
        yield put(protectAction.storeDefaultVmPath(vmFolderResp.data));
      }

      const vhdFolderResp = yield call(
        protectApi.fetchDefaultVhdFolder,
        data,
        token
      );
      if (vhdFolderResp.status === constant.HTTP_STATUS.OK) {
        yield put(protectAction.storeDefaultVhdPath(vhdFolderResp.data));
      }
    }
    yield put(addAPIresponse(resp));
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervisorInfoSaga() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    let token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const hypervisorInfoData = yield select(hypervisorInfo);
    const selectedDestination = yield select(selectedDestinationData);
    if (!hypervisorInfoData) {
      const request = {
        destination_info: {
          destination_path: source.destination_path,
          // username: source.username,
          // password: source.password,
          destination_id: _.get(selectedDestination, "destination_id", ""),
          destination_type: _.get(selectedDestination, "destination_type", ""),
          datastore_id: source.datastore_id
        },
        from_datastore: source.from_datastore
      };
      const resp = yield call(
        protectApi.fetchHypervisorInfo,
        request,
        source.source_id,
        token
      );
      if (resp.status === constant.HTTP_STATUS.OK) {
        yield put(protectAction.fetchHypervisorInfoSuccess(resp.data));
        yield put(showDialog(Dialog.VALIDATE_VM_DIALOG));
      }
    } else {
      yield put(showDialog(Dialog.VALIDATE_VM_DIALOG));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* verifyPasswordAndServer({
  password,
  verifyServerCallback,
  verifyServerPayload
}) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    yield put(setRecoveryNextLoading(true));
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const sourceEncryption = yield select(isSourceRecoveryEncryptionEnabled);
    const source = yield select(selectedSource);
    const selectedDestination = yield select(selectedDestinationData);
    if (sourceEncryption) {
      const passwordValidation = yield call(
        protectApi.verifyEncryptionPassword,
        {
          password: password,
          recovery_point: {
            session_id: recoveryPoint.session_id,
            session_guid: recoveryPoint.session_guid,
            encryption_pswd_hashkey: recoveryPoint.encrypt_pswd_hash_key,
            encryption_type: recoveryPoint.encryption_type,
            name: recoveryPoint.name,
            hbbu_path: recoveryPoint.hbbu_path
          },
          destination_type: source.destination_type,
          destination_info: {
            destination_path: source.destination_path,
            // username: source.username,
            // password: source.password,
            destination_id: _.get(selectedDestination, "destination_id", ""),
            destination_type: _.get(
              selectedDestination,
              "destination_type",
              ""
            ),
            datastore_id: source.datastore_id
          },
          from_datastore: source.from_datastore
        },
        source.source_id,
        token
      );
      if (
        passwordValidation.status === constant.HTTP_STATUS.OK &&
        passwordValidation.data.verification_result
      ) {
        yield put(verifyServerCallback(verifyServerPayload));
      } else {
        yield put(setRecoveryNextLoading(false));
        yield put(resetLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
        yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
        if (passwordValidation.errors && passwordValidation.errors.length === 0)
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                constant.API_RESPONSE_CONTEXT.PASSWORD_VERIFICATION_FAILED,
                `MRZXCR_${passwordValidation?.status}`
              )
            )
          );
      }
    } else {
      yield put(verifyServerCallback(verifyServerPayload));
    }
  } catch (e) {
    window.console.log(e);
    yield put(setRecoveryNextLoading(false));
    yield put(resetLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  }
}

export function* validateDisk({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const resp = yield call(protectApi.validateDisk, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (resp.data.severity === "warning") {
        yield put(protectAction.showWarningSuccess(resp.data.message));
        yield put(showDialog(Dialog.CONFIRMATION_DIALOG));
      } else {
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
    }
    yield put(setRecoveryNextLoading(false));
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateHypervisorHyperv({ data }) {
  try {
    let token = yield select(getLoginToken);
    const formValues = yield select(getFormValues("recoverVMOne"));
    let vmDisks = yield select(getHypervVMDisk);
    yield put(setLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const resp = yield call(protectApi.validateHypervisorHyperv, data, token);

    if (resp.status === constant.HTTP_STATUS.OK) {
      if (resp.data.warning_message) {
        yield put(
          ccToast.addWarningNotification(
            "protect.sources.verify_vm_warning_message"
          )
        );
      }
      yield put(hideDialog(Dialog.SOURCE_VM_VALIDATE_DIALOG));
      if (data.recovery_destination_type !== "alternate_location") {
        yield put(setRecoveryNextLoading(false));
        yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
      if (data.recovery_destination_type === "alternate_location") {
        let proxyId = formValues.backup_proxy;
        let vmPath = formValues.vm_path;
        let isSameVDPath = formValues.vd_path === "for_all" ? true : false;
        let vmDiskPath = formValues.hyperv_vd_indiviual_path_0;
        let diskInfo = [];
        if (isSameVDPath) {
          vmDisks.forEach(item => {
            diskInfo.push({ size: item.size });
          });
        } else {
          vmDisks.forEach(item => {
            diskInfo.push({
              size: item.size,
              path: formValues[`hyperv_vd_indiviual_path_${item.disk_number}`]
            });
          });
        }
        let hypervId = _.get(data, "hypervisor_id", "");

        let payload = {
          proxy_id: proxyId,
          vm_path: vmPath,
          is_same_virtual_disk_path: isSameVDPath,
          disks_info: diskInfo,
          hypervisor_id: hypervId
        };
        if (isSameVDPath) {
          payload["vm_disk_path"] = vmDiskPath;
        }
        yield put(protectAction.validateDisk(payload));
      }
    } else {
      yield put(resetLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
      yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
      yield put(setRecoveryNextLoading(false));
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* getSelectedSourceHypervisorType({ data, sourceData }) {
  try {
    yield put({
      type: actionType.GET_SELECTED_SOURCE_HYPERVISOR_TYPE_LOADING
    });
    yield put(showLoading());
    let token = yield select(getLoginToken);
    let apiData = {
      destination_path: _.get(data, "destination_path", ""),
      destination_id: _.get(data, "destination_id", ""),
      destination_type: _.get(data, "destination_type", "")
    };
    const resp = yield call(
      protectApi.getSelectedSourceHypervisorType,
      sourceData.source_id,
      apiData,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put({
        type: actionType.GET_SELECTED_SOURCE_HYPERVISOR_TYPE_SUCCESS,
        data: resp.data
      });
    }
    yield put({
      type: actionType.GET_SELECTED_SOURCE_HYPERVISOR_TYPE_DONE
    });
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

export function* getHypervisorType() {
  try {
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    let token = yield select(getLoginToken);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const source = yield select(selectedSource);
    const selectedDestination = yield select(selectedDestinationData);
    let data;
    if (source?.hypervisor?.hypervisor_type === "nutanix") {
      data = {
        destination_path: source.destination_path,
        destination_id: _.get(selectedDestination, "destination_id", ""),
        destination_type: _.get(selectedDestination, "destination_type", "")
      };
    } else {
      data = {
        destination_path: source.destination_path,
        // username: source.userName,
        session_number: recoveryPoint.session_Id,
        // password: source.password,
        destination_id: _.get(selectedDestination, "destination_id", ""),
        destination_type: _.get(selectedDestination, "destination_type", ""),
        datastore_id: source.datastore_id,
        backup_destination_type: source.destination_type
      };
    }
    // yield put(setLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    // yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const resp = yield call(
      protectApi.getHypervisorType,
      source.source_id,
      data,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put({
        type: actionType.GET_HYPERVISOR_TYPE_SUCCESS,
        data: resp.data
      });
      yield put({ type: actionType.RECOVERY_NEXT_PAGE });
    }
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  } catch (e) {
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
    window.console.log(e);
  }
}

export function* validateHypervisorVmware({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const resp = yield call(protectApi.validateHypervisorVMware, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.SOURCE_VM_VALIDATE_DIALOG));
      if (resp.data.warning_message) {
        yield put(protectAction.showWarningSuccess(resp.data.warning_message));
        yield put(showDialog(Dialog.CONFIRMATION_DIALOG));
      } else {
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
    }
    yield put(setRecoveryNextLoading(false));
    yield put(resetLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateHypervisorNutanix({ data }) {
  try {
    let token = yield select(getLoginToken);
    yield put(setLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(setLoading(Loader.RECOVER_NEXT_BTN));
    const resp = yield call(protectApi.validateHypervisorNutanix, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.SOURCE_VM_VALIDATE_DIALOG));
      if (resp.data.warning_message) {
        yield put(protectAction.showWarningSuccess(resp.data.warning_message));
        yield put(showDialog(Dialog.CONFIRMATION_DIALOG));
      } else {
        yield put({ type: actionType.RECOVERY_NEXT_PAGE });
      }
    }
    yield put(setRecoveryNextLoading(false));
    yield put(resetLoading(Loader.SOURCE_VM_VALIDATE_DIALOG));
    yield put(resetLoading(Loader.RECOVER_NEXT_BTN));
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateRecoveryPointVMSaga({ data }) {
  try {
    yield put(setLoading(Loader.VALIDATE_VM_DIALOG));
    let token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    // const vmValidationData = yield select(getFormValues("vmValidationDialog"));
    const hypervisorInfoData = yield select(hypervisorInfo);
    const request = {
      vmInstanceUuid: "",
      username: data.username,
      password: data.password,
      server_name: data.serverName,
      port: data.port,
      protocol: hypervisorInfoData ? hypervisorInfoData.protocol : null,
      vm_name: data.vmName,
      vm_username: data.vmUsername,
      vm_password: data.vmPassword,
      vm_type:
        hypervisorInfoData && hypervisorInfoData.vm_settings
          ? hypervisorInfoData.vm_settings.vm_type
          : null,
      vm_instance_id:
        hypervisorInfoData && hypervisorInfoData.vm_settings
          ? hypervisorInfoData.vm_settings.vm_instanceid
          : null,
      vm_hostname: data.nodeName || null,
      from_datastore: source.from_datastore,
      datastore_id: source.datastore_id
    };
    let resp = yield call(
      protectApi.validateRecoveryPointVM,
      request,
      source.source_id,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK && resp.data) {
      if (resp.data.severity === "warning") {
        const toastData = resp.data.message;
        yield put(
          ccToast.addWarningNotification(makeToastData(toastData, `KzbBxB`))
        );
        yield put(
          protectAction.validateRecoveryPointVMSuccess({
            username: data.username,
            password: data.password,
            server_name: data.serverName,
            port: data.port,
            protocol: hypervisorInfoData.protocol,
            vm_name: data.vmName,
            vm_username: data.vmUsername,
            vm_password: data.vmPassword,
            vm_type: hypervisorInfoData.vm_type,
            hypervisor_type: hypervisorInfoData.hypervisor_type,
            vm_instance_id: hypervisorInfoData.vm_instanceid,
            vm_hostname: resp.data.vm_hostname ? resp.data.vm_hostname : null,
            isInvalid: true
          })
        );
      } else if (resp.data.severity === "error") {
        const toastData = resp.data.message;
        yield put(
          ccToast.addErrorNotification(makeToastData(toastData, `KidNqc`))
        );
      } else {
        yield put(
          protectAction.validateRecoveryPointVMSuccess({
            username: data.username,
            password: data.password,
            server_name: data.serverName,
            port: data.port,
            protocol: hypervisorInfoData.protocol,
            vm_name: data.vmName,
            vm_username: data.vmUsername,
            vm_password: data.vmPassword,
            vm_type: hypervisorInfoData.vm_type,
            hypervisor_type: hypervisorInfoData.hypervisor_type,
            vm_instance_id: hypervisorInfoData.vm_instanceid,
            vm_hostname: resp.data.vm_hostname,
            isInvalid: false
          })
        );
        yield put(hideDialog(Dialog.VALIDATE_VM_DIALOG));
        yield put({ type: actionType.FnF_PAGE_2_NEXT });
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VALIDATE_VM_DIALOG));
  }
}

export function* validateRecoveryPointLinuxVMSaga({ data }) {
  try {
    yield put(setLoading(Loader.VALIDATE_LINUX_VM_DIALOG));
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.validateRecoveryPointLinuxVM, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        protectAction.validateRecoveryPointLinuxVMSuccess({
          server_name: data.target_machine.server_name,
          username: data.target_machine.username,
          password: data.target_machine.password
        })
      );
      yield put(hideDialog(Dialog.VALIDATE_LINUX_VM_DIALOG));
      yield put({ type: actionType.FnF_LINUX_PAGE_2_NEXT });
    } else {
      if (resp.errors && resp.errors.length === 0)
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(resp, "errors[0].message", ""),
              `8oxg1i${resp?.status}`
            )
          )
        );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VALIDATE_LINUX_VM_DIALOG));
  }
}

export function* verifyEncryptionPassword({ data, callConfigureAPI }) {
  try {
    yield put(setLoading(Loader.VERIFY_PASSWORD_DIALOG));
    let token = yield select(getLoginToken);
    const recoveryPoint = yield select(selectedRecoveryPoint);
    const source = yield select(selectedSource);
    const selectedDestinationRecovery = yield select(selectedDestinationData);
    const selectedDestinationIVM = yield select(selectedDestinationDataIVM);
    const lbs = yield select(updatedLbs);
    const destinationData = yield select(destinationsData);
    const linuxBackupServerId =
      destinationData &&
      destinationData[0] &&
      destinationData[0].linux_server_id;
    const selectedDestination =
      selectedDestinationIVM || selectedDestinationRecovery;
    const passwordValidation = yield call(
      protectApi.verifyEncryptionPassword,
      {
        password: data.password,
        recovery_point: {
          session_id: recoveryPoint.session_id,
          session_guid: recoveryPoint.session_guid,
          encryption_pswd_hashkey: recoveryPoint.encrypt_pswd_hash_key,
          encryption_type: recoveryPoint.encryption_type,
          name: recoveryPoint.name,
          hbbu_path: recoveryPoint.hbbu_path
        },
        destination_type: source.destination_type,
        destination_info: {
          destination_path: source.destination_path,
          // username: source.username,
          // password: source.password,
          destination_id: source.from_datastore
            ? source.datastore_id
            : _.get(selectedDestination, "destination_id", ""),
          destination_type: source.from_datastore
            ? "datastore"
            : _.get(selectedDestination, "destination_type", ""),
          datastore_id: source.datastore_id
        },
        from_datastore: source.from_datastore,
        from_destination: source.from_destination || undefined,
        proxy_id: source.from_destination ? source?.rps_server_id : undefined,
        linux_server_id:
          source && source.os_major === "linux"
            ? linuxBackupServerId || lbs
            : ""
      },
      source.source_id,
      token
    );
    if (
      passwordValidation.status === constant.HTTP_STATUS.OK &&
      passwordValidation.data.verification_result
    ) {
      yield put(saveRPPassword(data.password));
      if (recoveryPoint) {
        yield saveSelectedRPIdWithPassword(recoveryPoint, data.password);
      }
      yield put(hideDialog(Dialog.VERIFY_PASSWORD_DIALOG));
      if (
        source?.source_type === constant.SECONDARY_NAVIGATION_TYPE.UDP_ORACLE &&
        source?.task_type === TASK_TYPES.UDP_ORACLE_DATABASE
      ) {
        const dataList = yield select(folderTreeData);
        if (callConfigureAPI) {
          let payload = {
            backup_destination_info: {
              destination_path: source.destination_path,
              destination_id: source.datastore_id,
              destination_type: "datastore"
            },
            catalog_path: dataList?.[0]?.catalog_file_path,
            volume_guid: dataList?.[0]?.volume_guid,
            database_name: source?.oracle_db_name,
            pdb_name: "",
            session_id: dataList?.[0]?.session_id,
            encryption_password: data.password,
            source_id: source.source_id,
            defaultApi: true
          };
          yield put(getRecoveryOracleDBConfig(payload));
        }
      }
    } else {
      if (passwordValidation.errors && passwordValidation.errors.length === 0)
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.PASSWORD_VERIFICATION_FAILED,
              `IEzyZh_${passwordValidation?.status}`
            )
          )
        );
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    // yield put({ type: actionType.RECOVERY_NEXT_PAGE });
  } finally {
    yield put(resetLoading(Loader.VERIFY_PASSWORD_DIALOG));
  }
}

function* saveSelectedRPIdWithPassword(selectedRP, password) {
  const selectedRpsWithPasswordList = yield select(selectedRpsListWithPassword);
  const list = selectedRpsWithPasswordList?.length
    ? [...selectedRpsWithPasswordList]
    : [];
  const sessionId = selectedRP?.session_Id || selectedRP?.session_id;

  if (sessionId) {
    list.push({
      session_Id: sessionId,
      encrypt_pass: password
    });
    yield put(protectAction.updateSelectedRpsWithPassword([...list]));
  }
}

export function* backupPasswordsData({ data }) {
  try {
    yield put(setLoading(Loader.BACKUP_PASSWORDS_DATA));
    let token = yield select(getLoginToken);
    const responseData = yield call(
      protectApi.backupPasswordsData,
      data,
      token
    );
    if (responseData.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.BACKUP_PASSWORDS_SUCCESS,
            "O8MHee"
          )
        )
      );
      yield put(hideDialog(Dialog.SET_BACKUP_PASSWORDS_MODAL));
    } else {
      if (responseData.errors && responseData.errors.length === 0)
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(responseData, "errors[0].message", ""),
              `0k9DdE_${responseData?.status}`
            )
          )
        );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.BACKUP_PASSWORDS_DATA));
  }
}

export function* collectDiagnosticDataApi({ data }) {
  try {
    yield put(setLoading(Loader.COLLECT_DIAGNOSTIC_DATA));
    let token = yield select(getLoginToken);
    const collectData = yield call(
      protectApi.collectDiagnosticData,
      {
        destination_path: data.destination_path,
        username: data.username,
        password: data.password,
        source_ids: data.source_ids,
        advanced_log_collection: data.collect_events || false
      },
      token
    );
    if (collectData.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.COLLECT_DATA_SUCCESS,
            "RalQaO"
          )
        )
      );
      yield put(hideDialog(Dialog.COLLECT_DIAGNOSTIC_DATA));
    } else {
      if (collectData.errors && collectData.errors.length === 0)
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(collectData, "errors[0].message", ""),
              `NY272e_${collectData?.status}`
            )
          )
        );
    }
  } catch (e) {
    window.console.log(e);
    yield put(stopLoader());
    // yield put(resetLoading(Loader.COLLECT_DIAGNOSTIC_DATA));
  } finally {
    yield put(resetLoading(Loader.COLLECT_DIAGNOSTIC_DATA));
  }
}

export function* startFullVMRecovery() {
  try {
    yield put(setLoading(Loader.START_RECOVERY_BTN));
    // const formValues = yield select(getFormValues("recoverVMOne"));
    // const summaryFormValues = yield select(getFormValues("recoverVMSummary"));
    let token = yield select(getLoginToken);
    // let recType = yield select(recoveryType);
    let selectedSourcePagination = yield select(sourcePagination);
    // const recoveryPoint = yield select(selectedRecoveryPoint);
    const source = yield select(selectedSource);
    const payload = yield select(recoverVMPayload);

    let resp = yield call(
      protectApi.fullVMRecovery,
      source.source_id,
      payload,
      token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.START_RECOVERY;
    if (resp.status === 200) yield call(showToastFromResponse, resp);
    yield put(resetRecovery());
    yield put(hideDialog(Dialog.RESTORE_RECOVERY_POINT));
    yield put(searchList({ ...selectedSourcePagination }));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.START_RECOVERY_BTN));
  }
}

export function* fetchVMwareDatastores({ data }) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.fetchVmwareDatastores, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchVmwareNetworks(data));
      yield put(fetchResourcePool(data));
      // yield put(protectAction.fetchVmwareStoragepolicies(data));
      let resp2 = yield call(
        protectApi.fetchVmwareStoragepolicies,
        token,
        data
      );
      if (resp2.status === constant.HTTP_STATUS.OK) {
        yield put(protectAction.fetchVmwareStoragepoliciesSuccess(resp2.data));
        yield put(protectAction.fetchVmwareDatastoresSuccess(resp.data));
      } else {
        showErrToastFromResponse(resp2);
      }
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchVMwareStoragePolicies({ data }) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.fetchVmwareStoragepolicies, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchVmwareStoragepoliciesSuccess(resp.data));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* connectToEsxVcenter({ data }) {
  try {
    yield put(setLoading(Loader.CONNECT_TO_ESX_VCENTER_SERVER));
    const token = yield select(getLoginToken);
    let resp = yield call(protectApi.postPolicyVmwareEsxNodes, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.connectToEsxVcenterSuccess(resp.data));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.ESX_VCENTER_CONNECT_SUCCESS,
            "6qgai7"
          )
        )
      );
    }
    yield put(addAPIresponse(resp));
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CONNECT_TO_ESX_VCENTER_SERVER));
  }
}

export function* mountPointModalInfo(data) {
  try {
    yield put(setLoading(Loader.MOUNTPOINT_INFO_LOADER));
    const token = yield select(getCDLoginToken);
    let resp = yield call(
      protectApi.getMountPointInfo,
      data.data.recovery_point_id,
      data.data.organization_id,
      data.data.source.id,
      token
    );
    if (resp.status === constant.HTTP_STATUS.SUCCESS) {
      yield put(protectAction.storeMountPointInfo(resp.data));
      yield put(protectAction.mountPointModal(true));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.MOUNTPOINT_INFO_LOADER));
  }
}

export function* onRecoverFullVmNextAction() {
  try {
    // let token = yield select(getLoginToken);
    // const recoveryPoint = yield select(selectedRecoveryPointData);
    const recoverVMFormValues = yield select(getFormValues("recoverVMOne"));
    const selectedDestinationObj = yield select(selectedDestination);
    const selectedHypervisortype = yield select(selectedHypervisorType);
    const selectedHypervisorId = yield select(selectedHypervisorIdInRecovery);
    const sourceData = selectedDestinationObj.sourceData;
    const encryption_password = recoverVMFormValues.encryption_password;
    const selectedRecoveryPoint = selectedDestinationObj.selectedRecoveryPoint;
    // const sourceEncryption = yield select(isSourceRecoveryEncryptionEnabled);
    // const source = yield select(selectedSource);
    const selectedDestination1 = yield select(selectedDestinationData);
    yield put(storeVMSummaryData(recoverVMFormValues));
    if (recoverVMFormValues && recoverVMFormValues.restoreDestination) {
      let newVMInstanceUID = uuidv4();
      yield put(setNewVMInstanceUID(newVMInstanceUID));
      if (recoverVMFormValues.restoreDestination === "original_loc") {
        if (selectedHypervisortype.hypervisor_type === "hyperv") {
          const data = {
            source_id: sourceData.source_id,
            vm_name: "",
            vm_instance_uuid: recoverVMFormValues.generate
              ? newVMInstanceUID
              : sourceData.source_id,
            override_existing_vm:
              recoverVMFormValues && recoverVMFormValues.overwrite
                ? recoverVMFormValues.overwrite
                : false,
            recovery_destination_type: "original_location",
            generate_new_vm_instance_uuid:
              recoverVMFormValues.generate || false,
            proxy_id: "",
            hypervisor_id: selectedHypervisorId,
            vm_backup_destination: {
              datastore_id:
                selectedDestinationObj.selectedDestination.datastore_id,
              backup_destination_type:
                selectedDestinationObj.selectedDestination.destination_type,
              destination_path:
                selectedDestinationObj.selectedDestination.destination_path,
              sub_path: selectedRecoveryPoint.sub_path,
              // username: selectedDestinationObj.selectedDestination.username,
              // password: selectedDestinationObj.selectedDestination.password
              session_number: selectedRecoveryPoint.session_Id,
              destination_id: _.get(selectedDestination1, "destination_id", ""),
              destination_type: _.get(
                selectedDestination1,
                "destination_type",
                ""
              )
            }
          };
          yield put(
            protectAction.verifyPasswordAndServer(
              encryption_password,
              protectAction.validateHyperVysorsHyperV,
              data
            )
          );
        } else if (selectedHypervisortype.hypervisor_type === "nutanix") {
          const nutanixObj = {
            source_id: sourceData?.source_id || "",
            vm_instance_uuid: recoverVMFormValues?.generate
              ? newVMInstanceUID
              : sourceData?.source_id,
            override_existing_vm: recoverVMFormValues?.overwrite || false,
            recovery_destination_type: "original_location",
            generate_new_vm_instance_uuid:
              recoverVMFormValues?.generate || false,
            vm_backup_destination: {
              datastore_id:
                selectedDestinationObj?.selectedDestination?.datastore_id || "",
              backup_destination_type:
                selectedDestinationObj?.selectedDestination?.destination_type ||
                "",
              destination_path:
                selectedDestinationObj?.selectedDestination?.destination_path ||
                "",
              sub_path: selectedRecoveryPoint?.sub_path || "",
              destination_id: _.get(selectedDestination1, "destination_id", ""),
              destination_type: _.get(
                selectedDestination1,
                "destination_type",
                ""
              ),
              session_number: selectedRecoveryPoint?.session_Id || ""
            },
            hypervisor_id: selectedHypervisorId
          };
          yield put(
            protectAction.verifyPasswordAndServer(
              encryption_password,
              protectAction.validateNutanixHyperVysor,
              nutanixObj
            )
          );
        } else {
          const vmwareDataObj = {
            source_id: sourceData.source_id,
            vm_name: "",
            vm_instance_uuid: recoverVMFormValues.generate
              ? newVMInstanceUID
              : sourceData.source_id,
            override_existing_vm:
              recoverVMFormValues && recoverVMFormValues.overwrite
                ? recoverVMFormValues.overwrite
                : false,
            recovery_destination_type: "original_location",
            generate_new_vm_instance_uuid:
              recoverVMFormValues.generate || false,
            proxy_id: recoverVMFormValues.backup_proxy,
            original_esx_server_name: selectedRecoveryPoint.esx_server,
            vm_backup_destination: {
              datastore_id:
                selectedDestinationObj.selectedDestination.datastore_id,
              backup_destination_type:
                selectedDestinationObj.selectedDestination.destination_type,
              destination_path:
                selectedDestinationObj.selectedDestination.destination_path,
              sub_path: selectedRecoveryPoint.sub_path,
              // username: selectedDestinationObj.selectedDestination.username,
              // password: selectedDestinationObj.selectedDestination.password,
              destination_id: _.get(selectedDestination1, "destination_id", ""),
              destination_type: _.get(
                selectedDestination1,
                "destination_type",
                ""
              ),
              session_number: selectedRecoveryPoint.session_Id
            },
            hypervisor_id: selectedHypervisorId
          };

          yield put(
            protectAction.verifyPasswordAndServer(
              encryption_password,
              protectAction.validateHyperVysorsVMware,
              vmwareDataObj
            )
          );
        }
      } else {
        if (selectedHypervisortype.hypervisor_type === "hyperv") {
          const hyperVdata = {
            source_id: sourceData.source_id,
            vm_name: recoverVMFormValues.vm_name,
            vm_instance_uuid: recoverVMFormValues.generate
              ? newVMInstanceUID
              : sourceData.source_id,
            esx_name: recoverVMFormValues.hyperv_dropdown,
            override_existing_vm:
              recoverVMFormValues && recoverVMFormValues.overwrite
                ? recoverVMFormValues.overwrite
                : false,
            recovery_destination_type: "alternate_location",
            generate_new_vm_instance_uuid:
              recoverVMFormValues.generate || false,
            proxy_id: recoverVMFormValues.backup_proxy,
            hypervisor_id: recoverVMFormValues.hyperv_dropdown,
            vm_backup_destination: {
              datastore_id:
                selectedDestinationObj.selectedDestination.datastore_id,
              backup_destination_type:
                selectedDestinationObj.selectedDestination.destination_type,
              destination_path:
                selectedDestinationObj.selectedDestination.destination_path,
              sub_path: selectedRecoveryPoint.sub_path,
              // username: selectedDestinationObj.selectedDestination.username,
              // password: selectedDestinationObj.selectedDestination.password
              session_number: selectedRecoveryPoint.session_Id,
              destination_id: _.get(selectedDestination1, "destination_id", ""),
              destination_type: _.get(
                selectedDestination1,
                "destination_type",
                ""
              )
            }
          };
          yield put(
            protectAction.verifyPasswordAndServer(
              encryption_password,
              protectAction.validateHyperVysorsHyperV,
              hyperVdata
            )
          );
        } else if (selectedHypervisortype.hypervisor_type === "nutanix") {
          const nutanixObj = {
            source_id: sourceData?.source_id || "",
            vm_name: recoverVMFormValues?.vm_name || "",
            vm_instance_uuid: recoverVMFormValues?.generate
              ? newVMInstanceUID
              : sourceData?.source_id,
            override_existing_vm: recoverVMFormValues?.overwrite || false,
            recovery_destination_type: "alternate_location",
            generate_new_vm_instance_uuid:
              recoverVMFormValues?.generate || false,
            proxy_id: recoverVMFormValues?.backup_proxy || "",
            vm_backup_destination: {
              datastore_id:
                selectedDestinationObj?.selectedDestination?.datastore_id || "",
              backup_destination_type:
                selectedDestinationObj?.selectedDestination?.destination_type ||
                "",
              destination_path:
                selectedDestinationObj?.selectedDestination?.destination_path ||
                "",
              sub_path: selectedRecoveryPoint?.sub_path || "",
              destination_id: _.get(selectedDestination1, "destination_id", ""),
              destination_type: _.get(
                selectedDestination1,
                "destination_type",
                ""
              ),
              session_number: selectedRecoveryPoint?.session_Id || ""
            },
            hypervisor_id: selectedHypervisorId
          };
          yield put(
            protectAction.verifyPasswordAndServer(
              encryption_password,
              protectAction.validateNutanixHyperVysor,
              nutanixObj
            )
          );
        } else {
          const vmwareDataObj = {
            source_id: sourceData.source_id,
            vm_name: recoverVMFormValues.vm_name,
            vm_instance_uuid: recoverVMFormValues.generate
              ? newVMInstanceUID
              : sourceData.source_id,
            override_existing_vm:
              recoverVMFormValues && recoverVMFormValues.overwrite
                ? recoverVMFormValues.overwrite
                : false,
            recovery_destination_type: "alternate_location",
            generate_new_vm_instance_uuid:
              recoverVMFormValues.generate || false,
            proxy_id: recoverVMFormValues.backup_proxy,
            original_esx_server_name: selectedRecoveryPoint.esx_server,
            alternate_esx_server_name: recoverVMFormValues.esx_server,
            vm_backup_destination: {
              datastore_id:
                selectedDestinationObj.selectedDestination.datastore_id,
              backup_destination_type:
                selectedDestinationObj.selectedDestination.destination_type,
              destination_path:
                selectedDestinationObj.selectedDestination.destination_path,
              sub_path: selectedRecoveryPoint.sub_path,
              // username: selectedDestinationObj.selectedDestination.username,
              // password: selectedDestinationObj.selectedDestination.password,
              destination_id: _.get(selectedDestination1, "destination_id", ""),
              destination_type: _.get(
                selectedDestination1,
                "destination_type",
                ""
              ),
              session_number: selectedRecoveryPoint.session_Id
            },
            hypervisor_id: recoverVMFormValues.vcenter_esx_dropdown
          };
          yield put(
            protectAction.verifyPasswordAndServer(
              encryption_password,
              protectAction.validateHyperVysorsVMware,
              vmwareDataObj
            )
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    // yield put(resetLoading(Loader.MOUNTPOINT_INFO_LOADER));
  }
}

export function* fetchVMwareNetworks({ data }) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.fetchVmwareNetworks, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.storeVmwareNetworksList(resp.data));
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* policyVsbValidateCloudProxy({ data, formName, namespace }) {
  try {
    yield put(setLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
    let token = yield select(getLoginToken);
    yield put(showLoading());
    const resp = yield call(protectApi.createBackupProxy, data, token);
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS,
            "Xu0xDH"
          )
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
          resp.data.proxy_id
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.name`,
          data.proxy_name
        )
      );
      yield put(ec2CloudProxyValidationResponse(resp.data));
      yield put(disableSavePolicyButton(false));
      yield put(hideDialog("vsbEc2MonitorConnect"));
    } else {
      yield put(addAPIresponse(resp));
      yield put(ec2CloudProxyValidationResponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
    yield put(resetLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
  }
}

export function* policyVsbValidateCloudProxyForGCP({
  data,
  formName,
  namespace
}) {
  try {
    yield put(setLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
    let token = yield select(getLoginToken);
    yield put(showLoading());
    const resp = yield call(protectApi.createBackupProxy, data, token);
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS,
            "4U1UKB"
          )
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
          resp.data.proxy_id
        )
      );
      yield put(
        change(
          formName,
          `${namespace}.${DCs.TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.name`,
          data.proxy_name
        )
      );
      yield put(GCPCloudProxyValidationResponse(resp.data));
      yield put(hideDialog("vsbGCPMonitorConnect"));
    } else {
      yield put(addAPIresponse(resp));
      yield put(GCPCloudProxyValidationResponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideLoading());
    yield put(resetLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
  }
}

export function* getProxyDetailsByID({ data }) {
  try {
    let token = yield select(getLoginToken);
    let resp = yield call(protectApi.getProxyById, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      // yield put(
      //   ccToast.addSuccessNotification(
      //     constant.API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS
      //   )
      // );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRpsHashMemorySaga({ data }) {
  try {
    yield put(protectAction.setRpsHashMemoryLoader(true));
    let token = yield select(getLoginToken);
    let rpsId = data.rpsId;
    let dataStoreId = data.dataStoreId;
    data = {
      datastore_id: dataStoreId
    };
    let resp = yield call(getRpsHashMemory, rpsId, data, token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.setRpsHashMemory(resp.data));
      yield put(protectAction.setRpsHashMemoryLoader(false));
    } else {
      if (resp.status >= 400) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(resp, "errors[0].message", ""),
              `Hr3cTb_${resp?.status}`
            )
          )
        );
        yield put(
          protectAction.setRpsHashMemoryError(
            _.get(resp, "errors[0].message", "")
          )
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(protectAction.setRpsHashMemoryLoader(false));
  }
}

export function* getHypervListWithQuery({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    let resp = "";
    const organizationId = loginReducer && loginReducer.organization_id;
    const csrOrganizationId = protectReducer.orgIdForSourceCSR;
    // TO display hyperV list according to org login, 'if condition' runs when login from CSR.
    if (csrOrganizationId) {
      resp = yield call(
        protectApi.getHypervisorsListWithFilter,
        `hypervisor_type=${data}&organization_id=${csrOrganizationId}`,
        loginReducer.token
      );
    } else {
      resp = yield call(
        protectApi.getHypervisorsListWithFilter,
        `hypervisor_type=${data}&organization_id=${organizationId}`,
        loginReducer.token
      );
    }
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getIVMHypervListSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* createVmwareForIVM({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
    yield put(hideDialog(Dialog.ADD_VMWARE));
    const resp = yield call(
      // protectApi.createVmwareForIVM,
      protectApi.createVmwareForIVMv2,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(createVmwareForIVMSuccess(resp));
      yield put(getHypervisorsListByType("esxhost%7Cvcenter"));
      if (formName) {
        yield put(change(formName, "esx_server_id", resp.data.hypervisor_id));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}

export function* createVmwareForPolicy({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    // yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
    // yield put(hideDialog(Dialog.ADD_VMWARE));
    const resp = yield call(
      // protectApi.createVmwareForIVM,
      protectApi.createVmwareForIVMv2,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.createVmwareForPolicySuccess(resp));
      // yield put(getHypervisorsListByType("esxhost%7Cvcenter"));
      // if (formName) {
      //   yield put(change(formName, "esx_server_id", resp.data.hypervisor_id));
      // }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    // yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}

export function* createVmwareForRecovery({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(hideDialog(Dialog.ADD_VMWARE));
    const resp = yield call(
      protectApi.createVmwareForIVM,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getHypervListOptions("esxhost"));
      if (formName) {
        //set the added esx as selected esx server
        yield put(
          change(formName, "vcenter_esx_dropdown", resp.data.hypervisor_name)
        );
        //empty the username and password fields
        yield put(change(formName, "username", ""));
        yield put(change(formName, "password", ""));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    //
  }
}

export function* createHyperVForIVM({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
    yield put(hideDialog(Dialog.ADD_HYPERV));
    const resp = yield call(
      // protectApi.createHyperVForIVM,
      protectApi.createHyperVForIVMv2,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(createHyperVForIVMSuccess(resp));
      yield put(getHypervisorsListByType("hyperv"));
      if (formName) {
        yield put(
          change(formName, "hyperv_cluster_id", resp.data.hypervisor_id)
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}

export function* createNutanixForIVM({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
    const resp = yield call(
      protectApi.createNutanixForIVM,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      resp.data.hypervisor_id = data.hypervisor_id;
      yield put(createNutanixForIVMSuccess(resp));
      yield put(getHypervisorsListByType("nutanix"));
      if (formName) {
        yield put(
          change(formName, "nutanix_cluster_id", resp.data.hypervisor_id)
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}

export function* createHyperVForPolicy({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(startGridLoaderById(constant.DATAGRID_IDS["hyperV_policy_grid"]));
    const resp = yield call(
      protectApi.createHyperVForIVMv2,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.createHyperVForPolicySuccess(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["hyperV_policy_grid"]));
  }
}

export function* createFileBrowseFolder({ data, props }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(browseRemoteConsoleLoading());
    const resp = yield call(
      protectApi.createFileBrowseFolder,
      data,
      loginReducer.token
    );
    if (
      resp.status === constant.HTTP_STATUS.OK ||
      resp.status === constant.HTTP_STATUS.CREATED
    ) {
      yield put(
        browseRemoteConsole(
          props.browseParameters,
          `${data.parent_path}`,
          props.browseType
        )
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("Folder_has_been_created_successfully", "baaJUk")
        )
      );
      yield put(browseRemoteConsoleSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
      yield put(browseRemoteConsoleFailure(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
  }
}

export function* createFileBrowseFolderHyperV({ data, props }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(browseRemoteConsoleLoading());
    const resp = yield call(
      protectApi.createFileBrowseFolderHyperVV2,
      data,
      loginReducer.token
    );
    if (
      resp.status === constant.HTTP_STATUS.OK ||
      resp.status === constant.HTTP_STATUS.CREATED
    ) {
      yield put(
        browseRemoteConsole(
          {
            ...props.browseParameters,
            parent_path: data.path
          },
          null,
          props.browseType
        )
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("Folder_has_been_created_successfully", "c81xPC")
        )
      );
      yield put(browseRemoteConsoleSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
      yield put(browseRemoteConsoleFailure(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
  }
}

export function* createFileBrowseFolderLinux({ data, props }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(browseRemoteConsoleLoading());
    const resp = yield call(
      protectApi.createFileBrowseFolderLinux,
      data,
      loginReducer.token
    );
    if (
      resp.status === constant.HTTP_STATUS.OK ||
      resp.status === constant.HTTP_STATUS.CREATED
    ) {
      yield put(
        browseRemoteConsole(
          {
            ...props.browseParameters,
            parent_path: data.parent_path
          },
          null,
          props.browseType
        )
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("Folder_has_been_created_successfully", "KBilu4")
        )
      );
      yield put(browseRemoteConsoleSuccess(resp));
    } else {
      yield put(addAPIresponse(resp));
      yield put(browseRemoteConsoleFailure(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
  }
}

export function* getHypervListOfType({ product_type, hyperv_type, siteId }) {
  try {
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    let hypervType = hyperv_type === "hyperv" ? "hyperv" : "esxhost%7Cvcenter";
    const resp = yield call(
      protectApi.fetchHypervisorList,
      `product_type=${product_type}&hypervisor_type=${hypervType}&org_id=${orgId}&site_id=${siteId}`,
      token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getHypervListOfTypeSuccess(resp.data));
    } else if (resp.status >= 400) {
      yield put(protectAction.getHypervListOfTypeFailed());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAllVSBHypervisors() {
  try {
    let token = yield select(getLoginToken);
    let orgId = yield select(getOrgId);
    let queryString = `organization_id=${orgId}`;

    const resp = yield call(
      protectApi.fetchAllVSBHypervisorList,
      queryString,
      token
    );
    // const resp = {
    //   status: 200,
    //   data: [
    //     {
    //       target_id: "e6df53bc-5c43-4fe3-bd5a-9f21e8644c46",
    //       target_name: "SPOG_User ec2",
    //       target_type: "ec2",
    //       site: {
    //         site_id: "f64ac3d8-9551-46a9-813f-51e8ee9f4e61",
    //         site_name: "ALL in ONE -ec2"
    //       },
    //       organization_id: "3aecc202-2067-416f-b51a-60d748cf9eab",
    //       cloud_server: {
    //         amazon_ec2: {
    //           access_key_id: "AKIAQXUKL6JUO3V7LPO5",
    //           access_key_secret:
    //             "FYZnSu9PRYDQ6xz4b33Yo935Wi/9CMq05QhFdOnBhRX6z5Q8WDWnUFPrjD0tHiXQ",
    //           use_proxy_server: false
    //         }
    //       },
    //       hypervisor: null
    //     },
    //     {
    //       target_id: "222222-5c43-4fe3-bd5a-9f21e8644c46",
    //       target_name: "Azure_cloud",
    //       target_type: "azure",
    //       site: {
    //         site_id: "f64ac3d8-9551-46a9-813f-51e8ee9f4e61",
    //         site_name: "ALL in ONE - azure"
    //       },
    //       organization_id: "3aecc202-2067-416f-b51a-60d748cf9eab",
    //       cloud_server: {
    //         azure_cc: {
    //           access_key_id: "AKIAQXUKL6JUO3V7LPO5",
    //           access_key_secret:
    //             "FYZnSu9PRYDQ6xz4b33Yo935Wi/9CMq05QhFdOnBhRX6z5Q8WDWnUFPrjD0tHiXQ",
    //           use_proxy_server: false
    //         }
    //       },
    //       hypervisor: null
    //     },
    //     {
    //       target_id: "3333333-5c43-4fe3-bd5a-9f21e8644c46",
    //       target_name: "Hypervisor_10.10.10.10",
    //       target_type: "hyperv",
    //       site: {
    //         site_id: "f64ac3d8-9551-46a9-813f-51e8ee9f4e61",
    //         site_name: "ALL in ONE - hyperv"
    //       },
    //       organization_id: "3aecc202-2067-416f-b51a-60d748cf9eab",
    //       hypervisor: {
    //         proxy_id: "99999999999999999"
    //       }
    //     },
    //     {
    //       target_id: "02f5838e-3840-49f0-a0c2-fb38ce7adb7c",
    //       target_name: "10.55.15.3_vmware",
    //       target_type: "vmware",
    //       site: {
    //         site_id: "f64ac3d8-9551-46a9-813f-51e8ee9f4e61",
    //         site_name: "ALL in ONE - vmware"
    //       },
    //       organization_id: "3aecc202-2067-416f-b51a-60d748cf9eab",
    //       cloud_server: null,
    //       hypervisor: {
    //         username: "root",
    //         proxy_id: null,
    //         status: "normal",
    //         create_ts: 1624456790,
    //         modify_ts: 1624456790,
    //         last_recovery_point_ts: null,
    //         policy: null,
    //         source: null,
    //         auto_discovery: false,
    //         protocol: "https",
    //         port: "443",
    //         last_refresh_ts: null,
    //         inventory_types: ["vsphere_hosts_clusters"],
    //         available_actions: [
    //           "startbackup",
    //           "cancelbackup",
    //           "upgradeagent",
    //           "delete",
    //           "update_vm_information",
    //           "update",
    //           "refresh"
    //         ]
    //       }
    //     }
    //   ],
    //   errors: []
    // };

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getVSBHypervisorsSuccess(resp.data));
      let response = resp.data;
      let cloudData = [];
      //eslint-disable-next-line array-callback-return
      response.map(item => {
        if (
          item.target_type === "amazon_ec2" ||
          item.target_type === "amazon_ec2_china" ||
          item.target_type === "ms_azure" ||
          item.target_type === "google_cloud_json" ||
          item.target_type === "amazon_s3" ||
          item.target_type === "gcp"
        ) {
          cloudData.push({
            cloud_account_id: item.target_id,
            cloud_account_name: item.target_name,
            cloud_account_type: item.target_type
          });
        }
      });
      yield put(getCloudAccountListByTypeSuccess(cloudData));
    } else if (resp.status >= 400) {
      yield put(getVSBHypervisorsFailed());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSourceDataById(action) {
  try {
    const login = yield select(getLogin);
    // yield put({
    //   type: actionType.CLEAR_BROWSE_SOURCES_DS
    // });
    yield put(startLoader());
    const resp = yield call(
      protectApi.getSourceARTData,
      action,
      login.token,
      false,
      { showCommonError: true, testId: "43hjbc" }
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (resp.data && resp.data.length === 0) {
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
          )
        );
      }
      yield put(onSourceARTDataGetSuccess(resp.data));
    } else {
      yield put(clearSourceARTTaskDetails());
      yield put(
        ccDataGridActions.resetCCDataGridLoader(
          constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
        )
      );
    }
    yield put({
      type: actionType.CLEAR_SOURCE_ART_LOADER
    });
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSourceDataForAssuredSecurityScanById(action) {
  try {
    const login = yield select(getLogin);
    yield put(startLoader());
    const resp = yield call(
      protectApi.getSourceASSData, //API details to be changed
      action,
      login.token,
      false,
      { showCommonError: true, testId: "OlPkay" }
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (resp.data && resp.data.length === 0) {
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
          )
        );
      }
      yield put(protectAction.onSourceASSDataGetSuccess(resp.data));
    } else {
      yield put(protectAction.clearSourceASSTaskDetails());
      yield put(
        ccDataGridActions.resetCCDataGridLoader(
          constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
        )
      );
    }
    yield put({
      type: actionType.CLEAR_SOURCE_ASS_LOADER
    });
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* clearSelectedVmsList(action) {
  // Saga function added to change rightPanel state when selected Vms array is emptied
  try {
    const vms = yield select(getSelectedVms);
    if (Array.isArray(vms) && vms.length === 0) {
      yield put(clearAddSourcesHyperSavedList());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getNutanixClusterForPolicy({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["nutanix_policy_grid"])
    );
    const resp = yield call(
      protectApi.getNutanixClusterForPolicy,
      data,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      resp.data.hypervisor_id = data.hypervisor_id;
      yield put(nutanixClusterConfigurationForPolicySuccess(resp?.data));
      yield put(getHypervisorsListByType("nutanix"));
      if (formName) {
        yield put(
          change(formName, "nutanix_cluster_id", resp.data.hypervisor_id)
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["nutanix_policy_grid"]));
  }
}

export function* terminateStandbyVM(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.TERMINATE_STANDBY_VM));
    let apiResponse = yield call(
      protectApi.terminateStandbyVM,
      action.id,
      loginReducer.token,
      { showCommonError: true, testId: "f4jyeK" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(resetLoading(Loader.TERMINATE_STANDBY_VM));
      yield put(hideDialog(Dialog.TERMINATE_STANDBY_VM));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.TERMINATE_STANBY_VM,
            "RZSJrF"
          )
        )
      );
    } else {
      yield put(resetLoading(Loader.TERMINATE_STANDBY_VM));
      yield put(hideDialog(Dialog.TERMINATE_STANDBY_VM));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDestinationInfo({ dataStoreId, sourceId }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(
      ccDataGridActions.clearCCDataGrid(
        constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
      )
    );
    yield put(protectAction.clearRecoveryPointDataForDestinationPath());
    const resp = yield call(
      protectApi.getDestinationInfo,
      dataStoreId,
      sourceId,
      loginReducer.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.getDestinationInfoSuccess(resp.data));
      if (!resp.data.destination_path) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "protect.source.adhoc_assured_security_scan.no_destination_path",
              `4LBQb4_${resp?.status}`
            )
          )
        );
      }
    } else {
      let errors = _.get(resp, "errors[0]");
      yield put(
        ccToast.addErrorNotification(
          makeToastData("apiErrorResponse", `YTceaz_${resp?.status}`),
          {
            message: errors.message
          }
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(
      ccDataGridActions.resetCCDataGridLoader(
        constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
      )
    );
  }
}

export default function* protectSaga() {
  yield all([
    takeLatest(actionType.GET_ALL_VSB_HYPERVISORS, getAllVSBHypervisors),
    takeLatest(actionType.GET_HYPERVISORS_OF_TYPE, getHypervListOfType),
    takeLatest(actionType.GET_SOURCE_LIST, receiveProtectList),
    takeLatest(actionType.FETCH_REMOTE_POLICIES, getRemotePolicies),
    takeLatest(actionType.GET_CURRENT_ACTION_CALLED, receiveProtectList),
    takeEvery(actionType.GET_SOURCE_DETAILS, getSourceDetails),
    takeEvery(actionType.GET_PROTECT_LIST_COUNT, getProtectListTotalCounts),
    takeLatest(actionType.ADVANCED_SEARCH_TRIGGERED, receiveProtectList),
    takeEvery(actionType.GET_POLICY_DETAILS, receivePolicyDetails),
    takeEvery(actionType.GET_DESTINATION_DETAILS, getDestinationDetails),
    takeEvery(
      actionType.EDIT_UPDATE_DESTINATION_DETAILS,
      editUpdateDestinationInformation
    ),
    takeEvery(actionType.UPDATE_RECOVERY_STATUS, updateRecoveryStatus),
    takeEvery(actionType.GET_VMS_LIST, receiveVMsList),
    takeEvery(actionType.ADD_NEW_SOURCE, addNewSource),
    takeEvery(actionType.UPDATE_POLICY_DETAILS, updatePolicyDetails),
    takeEvery(actionType.GET_TRANSACTION_DETAILS, getTransactionDetails),
    takeEvery(
      actionType.GET_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD,
      receivePolicyDetailsDropdownAndProtectionType
    ),
    takeEvery(actionType.GET_DATA_CENTERS, getDataCentersList),
    takeEvery(actionType.REMOVE_SOURCE, removeSource),
    takeEvery(actionType.FORCE_DELETE_SOURCE, forceDeleteSource),
    takeLatest(actionType.REMOVE_POLICY, removePolicy),
    takeLatest(actionType.DISABLE_POLICY, disablePolicy),
    takeLatest(actionType.ENABLE_POLICY, enablePolicy),
    takeLatest(actionType.DEPLOY_POLICY, deployPolicy),
    takeLatest(actionType.REFRESH_POLICY, refreshPolicy),
    takeLatest(actionType.BACKUP_POLICY, backupPolicy),
    takeLatest(actionType.REPLICATE_POLICY, replicatePolicy),
    takeLatest(actionType.GET_SHARED_POLICY_DETAILS, getSharedPolicyDetails),
    takeLatest(actionType.SHARE_POLICY, sharePolicy),
    takeLatest(actionType.RUN_ASSURED_RECOVERY_TEST, startARTest),
    takeLatest(actionType.RUN_ASSURED_SECURITY_SCAN, startASTest),
    takeLatest(actionType.SOURCE_RUN_ASSURED_RECOVERY_TEST, sourceStartARTest),
    takeLatest(actionType.SOURCE_RUN_ASSURED_SECURITY_SCAN, sourceStartASScan),
    takeLatest(actionType.UN_SHARE_POLICY, unsharePolicy),
    takeEvery(actionType.UPGRADE_AGENT, upgradeAgentBySourceId),
    takeEvery(actionType.DELETE_DESTINATION, deleteDestination),
    takeEvery(actionType.ADD_DESTINATIONS, addDestination),
    takeEvery(actionType.TOGGLE_BACKUP, handleToggleBackupBySourceId),
    takeEvery(
      actionType.TOGGLE_BACKUP_WITH_PAYLOAD,
      handleToggleBackupBySourceIdWithPayload
    ),
    takeEvery(actionType.CANCEL_RECOVERY, handleCancelRecoveryBySourceId),
    takeEvery(actionType.GET_SOURCE_GROUP, handleGetSourceGroupList),
    takeEvery(
      actionType.ASSIGN_UNASSIGN_SOURCE_GROUP,
      handleAssignUnassignSourceGroup
    ),
    takeEvery(actionType.CREATE_SOURCE_GROUP, handleCreateSourceGroup),
    takeLatest(actionType.GET_CUSTOMER_ACCOUNTS_LIST, receiveProtectList),
    takeEvery(actionType.DELETE_CUSTOMER_ACCOUNT, deleteCustomerAccount),
    takeEvery(actionType.SUSPEND_CUSTOMER_ACCOUNT, suspendCustomerAccount),
    takeEvery(actionType.ENABLE_CUSTOMER_ACCOUNT, enableCustomerAccount),
    takeEvery(actionType.GET_CUSTOMER_ACCOUNT_DETAILS, getCustomerAccntDetails),
    takeEvery(
      actionType.RECOVERY_POINS_DESTINATION_DETAILS,
      getRecoveryPointsDestinationDetails
    ),
    takeEvery(actionType.ADD_CUSTOMER_ACCOUNT_DATA, addCustomerAccountData),
    takeEvery(actionType.EDIT_SET_USAGE_THRESHOLD_MODAL, editSetUsageThreshold),
    takeEvery(actionType.GET_ADMIN_FOR_ASSIGN, getAdminDetailsToAssign),
    takeEvery(actionType.RESEND_ENROLL_EMAIL, resendEnrollEmail),
    takeEvery(actionType.GET_PROTECT_CUSTOMER_LIST, getCustomersAssignedAdmin),
    takeEvery(
      actionType.GET_RECOVERY_POINTS,
      getCDSourcesDestinationsRecoveryPointsList
    ),
    takeEvery(
      actionType.UPDATE_SOURCE_CONFIGURATION,
      updateSourceConfiguration
    ),
    takeEvery(actionType.ASSIGNED_ADMIN_POST_DATA, addAssignedDataToCustomer),
    takeLatest(
      actionType.EDIT_UPDATE_CUSTOMER_ACCOUNT_DETAILS,
      updateCustomerAccntDetails
    ),
    takeEvery(
      actionType.UNASSIGN_ADMIN_FROM_CUSTOMERS_LIST,
      unassignAdminFromCustomer
    ),
    takeEvery(actionType.DELETE_SOURCE_GROUP, deleteSourceGroup),
    takeEvery(actionType.DELETE_RECOVERED_VM, deleteRecoveredVM),
    takeEvery(actionType.UNMOUNT_RPS, unmountRPS),
    takeEvery(actionType.UNMOUNT_RPS_SQL, unmountRPSSQL),
    takeEvery(actionType.FETCH_MOUNTED_RPS, recieveMountedRecoveryPointsList),
    takeEvery(actionType.START_FAILBACK, startFailback),
    takeEvery(actionType.STOP_FAILBACK, stopFailback),
    takeEvery(actionType.START_FINAL_FAILBACK_STEP, startFinalFailbackStep),
    takeEvery(actionType.GET_FILE_BROWSER_DATA, getFileBrowserDataSaga),
    takeEvery(actionType.GET_FILE_BROWSER_GRID_DATA, getFileBrowserDataSaga),
    takeEvery(actionType.POST_PROTECTED_PASSWORD_DATA, getFileBrowserDataSaga),
    takeEvery(actionType.SET_RESTORE_COOKIE_AUTH, setCDRestoreCookieauth),
    takeEvery(actionType.GET_SELECTED_RECOVERED_VM, getSelectedRecoveredVM),
    takeEvery(
      actionType.GET_SELECTED_RECOVERED_VM_USING_SOURCE_ID,
      getSelectedRecoveredVMUsingSourceId
    ),
    takeEvery(
      actionType.GET_RECOVERY_IMAGE_FORMATS,
      getRecoveryImageFormatsSaga
    ),
    takeEvery(actionType.GET_RECOVERY_TARGETS_DATA, receiveProtectList),
    takeEvery(actionType.ACTION_FOR_RECOVERED_VM, recoveredVMActionHandler),
    takeEvery(
      actionType.ACTION_FOR_RECOVERED_VM_DETAILS,
      recoveredVMDetailsActionHandler
    ),
    takeEvery(actionType.GET_DOWNLOAD_LINKS, getDownloadLinksHandler),
    takeEvery(actionType.POST_RECOVER_DATA, postRestoreRecoverData),
    takeEvery(actionType.GET_DESTINATION_CD, getDestinationCDHandler),
    takeEvery(actionType.GET_CUSTOMER_ACCOUNT_CD, getCustomerAccountCDHandler),
    takeEvery(actionType.ASSIGN_POLICY_SOURCE, fetchPolicyListForAssignPolicy),
    takeEvery(actionType.UPDATE_ASSIGN_TO_POLICY_SOURCE, assignPolicyToSource),
    takeEvery(
      actionType.GET_CH_RECOVERY_POINTS_DESTINATION_DETAILS,
      getChRpOnDestinationDetails
    ),
    takeEvery(
      actionType.GENERATE_DOWNLOAD_LINK_FOR_RP,
      generateAndDownloadLinkRP
    ),
    takeEvery(actionType.UNASSIGN_POLICY_SOURCE, unAssignPolicyToSource),
    //TODO remove cleanup
    //takeEvery(actionType.GET_DESTINATION_CH, getDestinationCHHandler),
    takeEvery(actionType.GET_CUSTOMER_ACCOUNT_CH, getCustomerAccountCHHandler),
    takeEvery(actionType.SOURCE_PROVISION, sourceProvisionHandler),
    takeEvery(
      actionType.SOURCE_CANCEL_REPLICATION,
      sourceCancelReplicationHandler
    ),
    takeEvery(actionType.GET_SELECTED_SOURCE_DETAILS, getSelectedSourceDetails),
    takeEvery(actionType.GET_LICENSES, getConfigurationLicenses),
    takeEvery(actionType.FETCH_POLICY_REQUEST, fetchPolicySaga),
    takeEvery(actionType.FETCH_AGENTLESS_VM_REQUEST, fetchAgentlessVMSaga),
    takeLatest(actionType.FETCH_HYPERVISOR_REQUEST, fetchHypervisorSaga),
    takeEvery(actionType.UPDATE_HYPERVISOR_REQUEST, updateHypervisorSaga),
    takeEvery(
      actionType.HYPERVISOR_UPGRADE_AGENT_REQUEST,
      hypervisorUpgradeAgentSaga
    ),
    takeEvery(
      actionType.HYPERVISOR_REFRESH_VMS_REQUEST,
      hypervisorRefreshVmsSaga
    ),
    takeEvery(
      actionType.FETCH_REMOTE_POLICIES_REQUEST,
      fetchRemotePoliciesSaga
    ),
    takeLatest(actionType.UPDATE_POLICY_REQUEST, updatePolicySaga),
    takeEvery(
      actionType.FETCH_ALL_DESTINATIONS_REQUEST,
      fetchAllDestinationsSaga
    ),
    takeLatest(
      actionType.FETCH_FAILBACK_DESTINATIONS_REQUEST,
      fetchFailbackDestinationsSaga
    ),
    takeEvery(actionType.POST_REMOTE_POLICIES, postRemotePoliciesSaga),
    takeEvery(
      actionType.CANCEL_REPLICATION_OUT,
      sourceCancelReplicationOutHandler
    ),
    takeEvery(actionType.START_REPLICATION_OUT, startReplicationOutHandler),
    takeEvery(actionType.START_REPLICATION, startReplicationHandler),
    takeEvery(actionType.HEARTBEAT_PAUSE, heartbeatPauseHandler),
    takeEvery(actionType.HEARTBEAT_RESUME, heartbeatResumeHandler),
    takeEvery(actionType.STANDBY_VM_SNAPSHOT, standByVMSnapshot),
    takeEvery(
      actionType.SNAPSHOT_STANDBY_VM_NETWORK_CONFIGURATION,
      standByVMNetworkConfiguration
    ),
    takeEvery(actionType.SHUTDOWN_VM_CLICKED, shutdownVMHandler),
    takeEvery(actionType.POWERON_VM_CLICKED, powerOnVMHandler),
    takeEvery(actionType.VIRTUAL_STANDBY_PAUSE, virtualStandbyPauseHandler),
    takeEvery(actionType.VIRTUAL_STANDBY_RESUME, virtualStandbyResumeHandler),
    takeEvery(
      actionType.VIRTUAL_STANDBY_RESUME_SMART_COPY,
      virtualStandbyResumeSmartCopyHandler
    ),
    takeEvery(
      actionType.STANDBY_VM_NETWORK_CONFIGURATION,
      standByVMNetworkConfigurationHandler
    ),
    takeEvery(actionType.LOAD_NETWORK_DATA, loadNetworkData),
    takeLatest(actionType.POST_NETWORK_DATA, postNetworkData),
    takeEvery(actionType.LOAD_BACKUP_PASSWORDS_DATA, loadBackupPasswordsData),
    takeEvery(actionType.BACKUP_VMS, backupVMsHandler),
    takeEvery(actionType.DEPLOY, deployHandler),
    takeEvery(actionType.UPDATE_SOURCE, updateSourceSaga),
    takeEvery(actionType.CONNECT_O365, connectSourcePopupShow),
    takeEvery(actionType.ADDO365, addO365Source),
    takeLatest(actionType.SAVE_TO_LIST_WINDOWS_SOURCE, windowsSourceSaveToList),
    takeLatest(
      actionType.SAVE_TO_LIST_ORACLE_WINDOWS_SOURCE,
      oracleWindowsSourceSaveToList
    ),
    takeLatest(
      actionType.SAVE_TO_LIST_ORACLE_LINUX_SOURCE,
      oracleLinuxSourceSaveToList
    ),
    takeLatest(
      actionType.SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE,
      orcaleWindowsDBSaveToList
    ),
    takeLatest(
      actionType.GET_ORACLE_WINDOWS_DB_OPTIONS,
      getOracleWindowsDBOptions
    ),
    takeEvery(actionType.UPDATE_AD, updateAdDetails),
    takeEvery(actionType.UPDATE_SITES_DATA, updatedSitesData),
    takeEvery(actionType.UPDATE_SITE_NAME, updateSiteName),
    takeEvery(actionType.DELETE_SITE, deleteSite),
    takeEvery(actionType.SAVE_TO_LIST_LINUX_SOURCE, linuxSourcesSaveToList),
    takeEvery(actionType.ADD_SOURCES, addSourcesWindowsSource),
    takeEvery(actionType.ADD_ORACLE_SOURCES, addSourcesOracleSource),
    takeEvery(actionType.ADD_ORACLE_DB, addOracleDB),
    takeEvery(actionType.ADD_AD_SAVE_SOURCES, addADSaveSource),
    takeEvery(actionType.ADD_LINUX_SOURCES, addLinuxSources),
    // takeEvery(actionType.ADD_SOURCES_WINDOWS_SOURCE, windowsSourceAddSource),
    // takeEvery(actionType.ADD_SOURCES_LINUX_SOURCE, linuxSourceAddSource),
    takeEvery(
      actionType.SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE,
      linuxBackupServerSourceSaveToList
    ),
    takeEvery(
      actionType.ADD_SOURCES_LINUX_BACKUP_SERVER_SOURCE,
      linuxBackupServerSourceAddSource
    ),
    takeEvery(actionType.ADD_DISCOVER_SOURCES, discoverSourcesAdd),
    takeEvery(
      actionType.AUTHENTICATE_AD_WINDOWS_SOURCES,
      authenticateADWindowsSources
    ),
    takeEvery(actionType.BROWSE_ACTIVE_DIRECTORIES, browseActiveDirectories),
    takeEvery(actionType.DELETE_ACTIVE_DIRECTORY, deleteActiveDirectory),
    takeEvery(actionType.DISCOVER_SOURCE_NAME, discoverSourcesSourceNames),
    takeEvery(
      actionType.DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL,
      verifyCredential
    ),
    takeEvery(actionType.DISCOVER_SOURCES_ADD_SOURCES, discoverSourceAddSource),
    takeEvery(
      actionType.ADD_SOURCES_HYPER_VMWARE_CONNECT,
      connectHypervisorVmware
    ),
    takeEvery(
      actionType.ADD_SOURCES_HYPER_ADD_SOURCE,
      addSourcesFromHypervisor
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_ESX,
      addSourcesFromHypervisorPolicyEsx
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_HV,
      addSourcesFromHypervisorPolicyHV
    ),
    takeEvery(actionType.ADD_HYPERVISOR_SOURCES, addHypervisorSources),
    takeEvery(
      actionType.ADD_SOURCES_HYPER_HYPERV_CONNECT,
      connectHypervisorHyperV
    ),
    takeEvery(
      actionType.ADD_SOURCES_HYPER_NUTANIX_CONNECT,
      connectHypervisorNutanix
    ),
    takeLatest(actionType.GET_VMWARE_SERVER_TYPE, getVmwareServerType),
    takeEvery(actionType.ADD_REMOTE_CONSOLE, addRemoteConsoleDestinations),
    takeEvery(
      actionType.UPDATE_REMOTE_CONSOLE,
      updateRemoteConsoleDestinations
    ),
    takeEvery(actionType.LOAD_SITES, loadSitesData),
    takeEvery(actionType.GET_ACTIVE_DIRECTORIES, loadActiveDirectoryList),
    takeEvery(actionType.LOAD_REGISTERED_SITES, loadRegisteredSites),
    takeEvery(actionType.CREATE_CLOUD_ACCOUNT, createCloudAccount),
    takeEvery(actionType.UPDATE_CLOUD_ACCOUNT, updateCloudAccount),
    takeEvery(actionType.FETCH_BACKUP_PROXY, loadBackupProxy),
    takeEvery(actionType.FETCH_LBS, loadLBS),
    takeEvery(actionType.FETCH_LINUX_LBS, loadLinuxLBS),
    takeEvery(actionType.FETCH_LINUX, loadLinux),
    takeEvery(
      actionType.FETCH_SELECTED_NETWORK_BACKUP_PROXY,
      loadSelectedNetworkBackupProxy
    ),
    takeEvery(actionType.FETCH_SELECTED_LBS_NETWORKS, loadSelectedLBSNetworks),
    takeEvery(actionType.CREATE_BACKUP_PROXY, createBackupProxy),
    takeEvery(actionType.CREATE_LINUX_BACKUP_PROXY, createLinuxBackupProxy),
    takeEvery(actionType.CREATE_LINUX_BACKUP_SERVER, createLinuxBackupServer),
    takeEvery(actionType.LOAD_RECOVERY_POINT_SERVERS, loadRecoveryPointServers),
    takeEvery(
      actionType.LOAD_BACKUP_TRAFFIC_USING_RPS,
      loadBackupTrafficUsingRPS
    ),
    takeEvery(
      actionType.LOAD_RESTORE_TRAFFIC_USING_RPS,
      loadRestoreTrafficUsingRPS
    ),
    takeEvery(
      actionType.LOAD_RESTORE_TRAFFIC_USING_PROXY,
      loadRestoreTrafficUsingProxy
    ),
    takeEvery(actionType.LOAD_RPS_DATA_STORES, loadRPSReadOnlyDataStores),
    takeEvery(
      actionType.LOAD_RPS_DATA_STORES_FOR_ADHOC_AS,
      loadRPSAllDataStores
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_SAVE_TO_LIST_VMWARE,
      preflightValidationAndSave
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_ESX,
      preflightValidationAndAddSourcePolicyEsx
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_HV,
      preflightValidationAndAddSourcePolicyHV
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_SAVE_TO_LIST_HN,
      preflightValidationAndSaveHN
    ),
    takeEvery(actionType.ADD_PATHS, addUncOrNfsPaths),
    takeEvery(actionType.LIST_HYPERVISORS, loadAllHypervisors),
    takeLatest(actionType.UNC_OR_NFS_PATH_SAVE_TO_LIST, UncOrNfsPathSaveToList),
    takeLatest(
      actionType.INSTALL_UPDATE_UPGRADE_AGENT,
      installUpdateUpgradeAgent
    ),
    takeLatest(
      actionType.INSTALL_UPDATE_UPGRADE_AGENT_BULK,
      installUpdateUpgradeAgentBulk
    ),
    takeLatest(
      actionType.CANCEL_SOURCE_SCHEDULED_DEPLOY,
      cancelSourceScheduledDeploy
    ),
    takeEvery(actionType.GET_ALL_HYPERVISORS, getAllHypervisors),
    takeEvery(actionType.GET_ALL_POLICY_PROXIES, getAllPolicyProxies),
    takeEvery(actionType.GET_HYPERVISOR_CONFIGURATION, getHypervisorConfigByID),
    takeEvery(
      actionType.GET_VMWARE_CONFIGURATION_FOR_POLICY,
      getHypervisorVMConfigByID
    ),
    takeLatest(actionType.SAVE_DESTINATION_HOST, saveDestinatioHostSaga),
    takeLatest(actionType.GET_DESTINATION_HOSTS, getDestinatioHostsSaga),
    takeLatest(actionType.ADD_DESTINATION_HOST, addDestinationHostSaga),
    takeLatest(
      actionType.ADD_DESTINATION_HOST_LINUX,
      addDestinationHostLinuxSaga
    ),
    takeLatest(actionType.SQL_PAGE_1_NEXT, recoverySqlPage1Validation),
    takeLatest(actionType.SQL_PAGE_2_NEXT, recoverySqlPage2Validation),
    takeLatest(actionType.SQL_PAGE_3_NEXT, recoverySqlPage3Validation),
    takeLatest(actionType.SQL_PAGE_4_NEXT, recoverySqlPage4Validation),
    takeLatest(actionType.ORACLE_PAGE_1_NEXT, recoveryOraclePage1Validation),
    takeLatest(actionType.ORACLE_PAGE_2_NEXT, recoveryOraclePage2Validation),
    takeLatest(
      actionType.VOLUME_RECOVERY_PAGE2_NEXT,
      recoveryVolumePage2Validation
    ),
    takeLatest(
      actionType.VOLUME_RECOVERY_PAGE1_NEXT,
      recoveryVolumePage1Validation
    ),
    takeLatest(actionType.START_RECOVERY, startRecoverySaga),
    takeEvery(actionType.STORE_NEW_IVM_RP, storeNewIVMRecoveryPoint),
    takeEvery(actionType.BACKUP_PASSWORDS_DATA, backupPasswordsData),
    takeEvery(actionType.COLLECT_DIAGNOSTIC_DATA, collectDiagnosticDataApi),
    takeEvery(actionType.VALIDATE_RP, validateRecoveryPointSaga),
    takeLatest(actionType.FETCH_PROXIES_IVM, fetchProxiesIvmSaga),
    takeLatest(actionType.SUBMIT_IVM, submitIvmSaga),
    takeLatest(actionType.GET_ESX_SERVERS, fetchEsxServers),
    takeLatest(actionType.FnF_PAGE_2_NEXT, recoveryFnFPage2Validation),
    takeLatest(
      actionType.POLICY_SHARED_FOLDER_DESTINATION_CONNECT,
      policySharedFolderDestinationConnectSaga
    ),
    takeLatest(
      actionType.POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE,
      policysharedFolderDestinationAuthenticateSaga
    ),
    takeLatest(
      actionType.POLICY_VSB_CONNECT_HYPERV,
      policyVsbConnectHyperVSaga
    ),
    takeLatest(actionType.POLICY_VSB_FETCH_VMWARES, policyVsbFetchVmwaresSaga),
    takeLatest(
      actionType.POLICY_VSB_CONNECT_VMWARE,
      policyVsbFetchVmwareNodesSaga
    ),
    takeLatest(actionType.GET_HYPERV_CONFIGURATION, getHyperVConfiguration),
    takeLatest(actionType.GET_VMWARE_CONFIGURATION, getVMwareConfiguration),
    takeLatest(actionType.GET_NUTANIX_CONFIGURATION, getNutanixConfiguration),
    takeLatest(
      actionType.POLICY_VSB_CONNECT_VMWARE_MONITOR,
      policyVsbVmwareMonitorConnectSaga
    ),
    takeLatest(
      actionType.FnF_LINUX_PAGE_2_NEXT,
      recoveryFnFLinuxPage2Validation
    ),
    takeLatest(
      actionType.FnF_LINUX_VALIDATE_SCHEDULE,
      validateLinuxScheduleSaga
    ),
    takeLatest(actionType.FnF_LINUX_FETCH_SCRIPTS, fetchLinuxScriptsSaga),
    takeLatest(actionType.FnF_GENERATE_DOWNLOAD_LINK, generateDownloadLinkSaga),
    takeLatest(
      actionType.FnF_FETCH_VOLUMES_FOR_RP,
      fetchVolumesForRecoveryPoint
    ),
    takeLatest(actionType.FnF_FETCH_ITEMS_FOR_RP, fetchItemsForRecoveryPoint),
    takeEvery(
      actionType.FnF_FETCH_ITEMS_FOR_RP_LINUX,
      fetchItemsForRecoveryPointLinux
    ),
    takeEvery(
      actionType.FnF_FETCH_ITEMS_DATA_FOR_RP_LINUX,
      fetchItemsDataForRecoveryPointLinux
    ),
    takeLatest(actionType.UPDATE_FNF_GRID_DATA, updateFnFTableData),

    takeLatest(actionType.SINGLE_ACTION_ON_IVM, singleActionOnVM),
    takeLatest(actionType.BULK_ACTION_ON_IVM, bulkActionOnVM),
    takeLatest(
      actionType.FnF_FETCH_TABLE_PAGINATION,
      fetchFnFTableDataPagination
    ),
    takeLatest(actionType.GET_BACKUP_VM_DISKS, getBackupVmdisks),
    takeLatest(actionType.GET_HYPERV_LIST, getHypervList),
    takeLatest(actionType.GET_ESX_VCENTER_LIST, getEsxList),
    takeLatest(
      actionType.CONNECT_TO_HYPERV_VM_DEFAULT_PATH,
      conectToHypervVMfolderPath
    ),
    takeLatest(actionType.GET_NETWORK_ADAPTERS, getVMNetworkAdaptersList),
    takeLatest(actionType.GET_NUTANIX_STORAGE, getNutanixStorage),
    takeLatest(actionType.GET_NUTANIX_NETWORKS, getNutanixNetworks),
    takeLatest(actionType.FETCH_FNF_TABLE_DATA, updateFnFTableData),
    takeLatest(actionType.FETCH_FNF_TABLE_DATA_LINUX, updateFnFTableDataLinux),

    takeLatest(actionType.FETCH_HYPERVISOR_DATA, fetchHypervisorInfoSaga),
    takeLatest(actionType.VALIDATE_VM, validateRecoveryPointVMSaga),
    takeLatest(actionType.VALIDATE_LINUX_VM, validateRecoveryPointLinuxVMSaga),
    takeLatest(actionType.VERIFY_ENCRYPTION_PASSWORD, verifyEncryptionPassword),
    takeLatest(actionType.START_FULL_VM_RECOVERY, startFullVMRecovery),
    takeLatest(actionType.FETCH_VMWARE_DATASTORES, fetchVMwareDatastores),
    takeLatest(
      actionType.FETCH_VMWARE_STORAGE_POLCIES,
      fetchVMwareStoragePolicies
    ),
    takeLatest(actionType.CREATE_VMWARE_FOR_RECOVERY, createVmwareForRecovery),
    takeLatest(actionType.CONNECT_TO_ESX_VCENTER, connectToEsxVcenter),
    takeLatest(
      actionType.SHARED_FOLDERS_BROWSE,
      browseSharedFoldersDestinations
    ),
    takeLatest(actionType.VERIFY_PASSWORD_AND_SERVER, verifyPasswordAndServer),
    takeLatest(actionType.VALIDATE_HYPERVISOR_HYPERV, validateHypervisorHyperv),
    takeLatest(actionType.VALIDATE_HYPERVISOR_VMWARE, validateHypervisorVmware),
    takeLatest(
      actionType.VALIDATE_HYPERVISOR_NUTANIX,
      validateHypervisorNutanix
    ),
    takeLatest(actionType.GET_HYPERVISOR_TYPE, getHypervisorType),
    takeLatest(
      actionType.GET_SELECTED_SOURCE_HYPERVISOR_TYPE,
      getSelectedSourceHypervisorType
    ),
    takeLatest(actionType.VALIDATE_DISK, validateDisk),
    takeLatest(actionType.GET_MOUNTPOINT_INFO, mountPointModalInfo),
    takeLatest(actionType.RECOVER_FULL_VM_NEXT, onRecoverFullVmNextAction),
    takeLatest(
      actionType.POLICY_VSB_FETCH_VMWARES_NODES,
      policyVsbFetchESXNodes
    ),
    takeLatest(actionType.FETCH_VMWARE_NETWORK_LIST, fetchVMwareNetworks),
    takeLatest(
      actionType.VSB_VALIDATE_CLOUD_PROXY,
      policyVsbValidateCloudProxy
    ),
    takeLatest(
      actionType.VSB_VALIDATE_GCP_CLOUD_PROXY,
      policyVsbValidateCloudProxyForGCP
    ),
    takeLatest(actionType.GET_CH_DATASTORES, loadCHDataStores),
    takeLatest(actionType.GET_PROXY_BY_ID, getProxyDetailsByID),
    takeLatest(actionType.GET_PROXY_BY_ID, getProxyDetailsByID),
    takeLatest(actionType.GET_HYPERVISORS_LIST_BY_TYPE, getHypervListWithQuery),
    takeLatest(actionType.CREATE_VMWARE_FOR_IVM, createVmwareForIVM),
    takeLatest(actionType.CREATE_VMWARE_FOR_POLICY, createVmwareForPolicy),
    takeLatest(actionType.CREATE_HYPERV_FOR_IVM, createHyperVForIVM),
    takeLatest(actionType.CREATE_NUTANIX_FOR_IVM, createNutanixForIVM),
    takeLatest(actionType.CREATE_HYPERV_FOR_POLICY, createHyperVForPolicy),
    takeLatest(actionType.CREATE_FILE_BROWSE_FOLDER, createFileBrowseFolder),
    takeLatest(actionType.GET_SOURCE_DATA_BY_ID, getSourceDataById),
    takeLatest(
      actionType.GET_SOURCE_DATA_FOR_ASSURED_SECURITY_SCAN_BY_ID,
      getSourceDataForAssuredSecurityScanById
    ),
    takeLatest(
      actionType.CREATE_FILE_BROWSE_FOLDER_HYPERV,
      createFileBrowseFolderHyperV
    ),
    takeLatest(
      actionType.CREATE_FILE_BROWSE_FOLDER_LINUX,
      createFileBrowseFolderLinux
    ),
    takeLatest(actionType.LOAD_VSB_MONITOR, loadVsbMonitors),
    takeLatest(actionType.GET_RPS_HASHMEMORY, getRpsHashMemorySaga),
    // takeLatest(actionType.CREATE_FILE_BROWSE_FOLDER, createFileBrowseFolder)
    takeLatest(
      actionType.FETCH_DESTINATION_PATH_REQUEST,
      fetchDestinationPathSaga
    ),
    takeLatest(
      actionType.ADD_SOURCES_HYPER_DELETE_SELECTION,
      clearSelectedVmsList
    ),
    takeLatest(
      actionType.GET_NUTANIX_CLUSTER_DETAILS_FOR_POLICY,
      getNutanixClusterForPolicy
    ),
    takeLatest(actionType.TERMINATE_STANDBY_VM_INSTANCE, terminateStandbyVM),
    takeLatest(actionType.GET_DESTINATION_INFO, getDestinationInfo)
  ]);
}
