import { DataGridConstants } from "../../../common/data-grid";

export const NetworkAttributes = {
  NETWORK_VMS: "recoveredresource_id",
  NETWORK_VMS_OBJ: "recoveredresource",
  NETWORK_STATUS: "status",
  NETWORK_INTERNAL_IP: "internal_ip",
  NETWORK_INTERNAL_PORT: "internal_port",
  NETWORK_EXTERNAL_IP: "external_ip",
  NETWORK_EXTERNAL_PORT: "external_port",
  NETWORK_PROTOCOL: "protocol",
  NETWORK_DELETE: "delete",
  NETWORK_SAVE: "save"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "configure.network.configuration.".concat(identifier);
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_DELETE_DETAILS : to delete the details on row inline
 * @type {{CLICKABLE_CELL_TO_VIEW_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_DELETE_DETAILS: NetworkAttributes.NETWORK_DELETE,
  CLICKABLE_CELL_TO_SAVE_DETAILS: NetworkAttributes.NETWORK_SAVE
};

/**
 * Mapping for status type attribute to display appropriate icon
 */
export const StatusAttributes = {
  success: { label: "vm_active", icon: "success" },
  info: { label: "vm_deactive", icon: "information" }
};

/**
 * Options for Protocol column dropdown
 */

export const ProtocolOptions = [
  { key: "TCP", value: "TCP" },
  { key: "UDP", value: "UDP" }
];

const DataGridColumnCellTypes = DataGridConstants
  ? DataGridConstants.GridColumnCellTypes
  : {};
const DataGridColumnHeaderTypes = DataGridConstants
  ? DataGridConstants.GridColumnHeaderTypes
  : {};

export const COLUMN_DEFS = {
  /**
   * Defines the view rendering dependencies for each of the Infrastructure entity properties.
   */
  columnMappings: [
    {
      accessor: NetworkAttributes.NETWORK_VMS_OBJ,
      displayIdentifier: getDisplayIdentifier(NetworkAttributes.NETWORK_VMS),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL_MUI,
        editDropdown: true
      },
      settings: { sortable: false }
    },
    {
      accessor: NetworkAttributes.NETWORK_STATUS,
      displayIdentifier: getDisplayIdentifier(NetworkAttributes.NETWORK_STATUS),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.ICON_CELL_MUI
      },
      settings: {}
    },
    {
      accessor: NetworkAttributes.NETWORK_INTERNAL_IP,
      displayIdentifier: getDisplayIdentifier(
        NetworkAttributes.NETWORK_INTERNAL_IP
      ),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI
      },
      settings: {}
    },
    {
      accessor: NetworkAttributes.NETWORK_INTERNAL_PORT,
      displayIdentifier: getDisplayIdentifier(
        NetworkAttributes.NETWORK_INTERNAL_PORT
      ),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.PORT_INPUT_CELL_MUI,
        editInput: true
      },
      settings: {
        width: 130
      }
    },
    {
      accessor: NetworkAttributes.NETWORK_EXTERNAL_IP,
      displayIdentifier: getDisplayIdentifier(
        NetworkAttributes.NETWORK_EXTERNAL_IP
      ),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL_MUI,
        editDropdown: true
      },
      settings: { sortable: false }
    },
    {
      accessor: NetworkAttributes.NETWORK_EXTERNAL_PORT,
      displayIdentifier: getDisplayIdentifier(
        NetworkAttributes.NETWORK_EXTERNAL_PORT
      ),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.PORT_INPUT_CELL_MUI,
        editInput: true
      },
      settings: {
        width: 130
      }
    },
    {
      accessor: NetworkAttributes.NETWORK_PROTOCOL,
      displayIdentifier: getDisplayIdentifier(
        NetworkAttributes.NETWORK_PROTOCOL
      ),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.SINGLE_SELECT_DROPDOWN_CELL_MUI,
        editDropdown: true
      },
      settings: {
        width: 130,
        sortable: false
      }
    },
    {
      accessor: NetworkAttributes.NETWORK_SAVE,
      displayIdentifier: getDisplayIdentifier(NetworkAttributes.NETWORK_SAVE),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.ICON_CELL_MUI
      },
      settings: {
        width: 50,
        sortable: false
      }
    },
    {
      accessor: NetworkAttributes.NETWORK_DELETE,
      displayIdentifier: getDisplayIdentifier(NetworkAttributes.NETWORK_DELETE),
      renderHeader: {
        type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
      },
      renderRecord: {
        type: DataGridColumnCellTypes.ICON_CELL_MUI
      },
      settings: {
        width: 80,
        sortable: false
      }
    }
  ]
};

export const RECOVERY_USER_DISABLED_ACTIONS = [
  NetworkAttributes.NETWORK_DELETE,
  NetworkAttributes.NETWORK_SAVE
];

export const PORT_FORWORDING_ERR_CODES = [8314, 8312, 8303];
