import uuidv4 from "uuid/v4";

import * as C from "state/constants";

export const addNotifications = notifications => ({
  type: C.ADD_NOTIFICATIONS,
  payload: {
    notifications
  }
});

export const clearNotifications = () => ({ type: C.CLEAR_NOTIFICATIONS });

export const displayLoginModal = () => ({ type: C.DISPLAY_LOGIN_MODAL });
export const closeLoginModal = () => ({ type: C.CLOSE_LOGIN_MODAL });

export const addConfirmation = (
  text,
  action,
  title = "",
  buttonText = "confirm"
) => ({
  type: C.ADD_CONFIRMATION,
  payload: {
    confirmationId: uuidv4(),
    text,
    action,
    title,
    buttonText
  }
});

export const executeConfirmation = confirmationId => ({
  type: C.EXECUTE_CONFIRMATION,
  payload: {
    confirmationId
  }
});

export const cancelConfirmation = confirmationId => ({
  type: C.CANCEL_CONFIRMATION,
  payload: {
    confirmationId
  }
});

export const csrRedirect = to => ({
  type: C.CSR_REDIRECT,
  payload: { to }
});

export const setCsrOrgDatacenter = (datacenter, orgId) => ({
  type: C.CSR_ORG_DATA_CENTER,
  payload: { datacenter, orgId }
});

export const resetCsrOrgDatacenter = () => ({
  type: C.RESET_CSR_ORG_DATA_CENTER
});

export const csrClearRedirect = () => ({
  type: C.CSR_CLEAR_REDIRECT
});

// org search
export const setOrganizationsSearchString = searchString => ({
  type: C.SET_ORGANIZATIONS_SEARCH_STRING,
  payload: { searchString }
});

export const setOrganizationsSortString = sortString => ({
  type: C.SET_ORGANIZATIONS_SORT_STRING,
  payload: { sortString }
});

export const fetchOrganizationSearch = (
  searchString,
  page,
  pageSize,
  sort
) => ({
  type: C.FETCH_ORGANIZATION_SEARCH_REQUEST,
  payload: {
    searchString,
    page,
    pageSize,
    sort
  }
});

export const refreshOrgSearchOrEditOrg = (fromCsrSearch, orgId) => ({
  type: C.REFRESH_ORGANIZATION_SEARCH_OR_EDIT_ORG_REQUEST,
  payload: { fromCsrSearch, orgId }
});

export const fetchOrganizationSearchSuccess = (data, pagination) => ({
  type: C.FETCH_ORGANIZATION_SEARCH_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchOrganizationSearchFailure = errors => ({
  type: C.FETCH_ORGANIZATION_SEARCH_FAILURE,
  payload: {
    errors
  }
});

export const updateOrganizations = (orgId, organization) => ({
  type: C.UPDATE_ORGANIZATIONS_REQUEST,
  payload: {
    orgId,
    organization
  }
});

export const fetchOrganizations = orgId => ({
  type: C.FETCH_ORGANIZATIONS_REQUEST,
  payload: { orgId }
});

export const fetchOrganizationsSuccess = data => ({
  type: C.FETCH_ORGANIZATIONS_SUCCESS,
  payload: { data }
});

export const fetchOrganizationsFailure = errors => ({
  type: C.FETCH_ORGANIZATIONS_FAILURE,
  payload: { errors }
});

export const fetchOrganizationsSupport = orgId => ({
  type: C.FETCH_ORGANIZATIONS_SUPPORT_REQUEST,
  payload: { orgId }
});

export const fetchOrganizationsSupportSuccess = data => ({
  type: C.FETCH_ORGANIZATIONS_SUPPORT_SUCCESS,
  payload: { data }
});

export const fetchOrganizationsSupportFailure = errors => ({
  type: C.FETCH_ORGANIZATIONS_SUPPORT_FAILURE,
  payload: { errors }
});

export const fetchOrganizationsFreetrialCloudDirect = orgId => ({
  type: C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_REQUEST,
  payload: { orgId }
});

export const fetchOrganizationsFreetrialCloudDirectSuccess = data => ({
  type: C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_SUCCESS,
  payload: { data }
});

export const fetchOrganizationsFreetrialCloudDirectFailure = errors => ({
  type: C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_FAILURE,
  payload: { errors }
});

export const fetchOrganizationsFreetrialCloudHybrid = orgId => ({
  type: C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_REQUEST,
  payload: { orgId }
});

export const fetchOrganizationsFreetrialCloudHybridSuccess = data => ({
  type: C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_SUCCESS,
  payload: { data }
});

export const fetchOrganizationsFreetrialCloudHybridFailure = errors => ({
  type: C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_FAILURE,
  payload: { errors }
});

export const activateOrganizationFreetrialCloudDirect = (
  orgId,
  dataCenterId,
  fromCsrSearch
) => ({
  type: C.ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_DIRECT_REQUEST,
  payload: { orgId, dataCenterId, fromCsrSearch }
});

export const invalidateCache = orgId => ({
  type: C.INVALIDATE_CACHE,
  payload: { orgId }
});

export const enablePolicies = (orgId, fromCsrSearch) => ({
  type: C.ENABLE_POLICIES,
  payload: { orgId, fromCsrSearch }
});

export const disablePolicies = (orgId, fromCsrSearch) => ({
  type: C.DISABLE_POLICIES,
  payload: { orgId, fromCsrSearch }
});

export const enableOrg = orgId => ({
  type: C.ENABLE_ORG,
  payload: orgId
});

export const suspendOrg = orgId => ({
  type: C.SUSPEND_ORG,
  payload: orgId
});

export const handleProductStatus = (
  orgId,
  status,
  productType,
  fromCsrSearch
) => ({
  type: C.PRODUCT_STATUS,
  orgId,
  status,
  productType,
  fromCsrSearch
});

export const activateOrganizationFreetrialCloudHybrid = (
  orgId,
  fromCsrSearch
) => ({
  type: C.ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_HYBRID_REQUEST,
  payload: { orgId, fromCsrSearch }
});

export const activateOrganizationFreetrialUDP = (orgId, fromCsrSearch) => ({
  type: C.ACTIVATE_ORGANIZATION_FREETRIAL_UDP_REQUEST,
  payload: { orgId, fromCsrSearch }
});

export const fetchOrganizationsEntitlements = orgId => ({
  type: C.FETCH_ORGANIZATIONS_ENTITLEMENTS_REQUEST,
  payload: { orgId }
});

export const fetchOrganizationsEntitlementsSuccess = data => ({
  type: C.FETCH_ORGANIZATIONS_ENTITLEMENTS_SUCCESS,
  payload: { data }
});

export const fetchOrganizationsEntitlementsFailure = errors => ({
  type: C.FETCH_ORGANIZATIONS_ENTITLEMENTS_FAILURE,
  payload: { errors }
});

export const fetchOrganizationsProperties = orgId => ({
  type: C.FETCH_ORGANIZATIONS_PROPERTIES_REQUEST,
  payload: { orgId }
});

export const fetchOrganizationsPropertiesSuccess = data => ({
  type: C.FETCH_ORGANIZATIONS_PROPERTIES_SUCCESS,
  payload: { data }
});

export const fetchOrganizationsPropertiesFailure = errors => ({
  type: C.FETCH_ORGANIZATIONS_PROPERTIES_FAILURE,
  payload: { errors }
});

export const deleteOrganizations = orgId => ({
  type: C.DELETE_ORGANIZATIONS_REQUEST,
  payload: {
    orgId
  }
});

// user search
export const setUsersSearchString = searchString => ({
  type: C.SET_USERS_SEARCH_STRING,
  payload: { searchString }
});

export const setUsersSortString = sortString => ({
  type: C.SET_USERS_SORT_STRING,
  payload: { sortString }
});

export const fetchUsersSearch = (searchString, page, pageSize, sort) => ({
  type: C.FETCH_USERS_SEARCH_REQUEST,
  payload: {
    searchString,
    page,
    pageSize,
    sort
  }
});

export const fetchUsersSearchSuccess = (data, pagination) => ({
  type: C.FETCH_USERS_SEARCH_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchUsersSearchFailure = errors => ({
  type: C.FETCH_USERS_SEARCH_FAILURE,
  payload: {
    errors
  }
});

export const fetchUser = userId => ({
  type: C.FETCH_USER_REQUEST,
  payload: {
    userId
  }
});

export const fetchUserSuccess = data => ({
  type: C.FETCH_USER_SUCCESS,
  payload: {
    data
  }
});

export const fetchUserFailure = errors => ({
  type: C.FETCH_USER_FAILURE,
  payload: {
    errors
  }
});

export const updateUser = (
  userId,
  firstName,
  lastName,
  phoneNumber,
  email,
  roleId,
  blocked
) => ({
  type: C.UPDATE_USER_REQUEST,
  payload: {
    userId,
    firstName,
    lastName,
    phoneNumber,
    email,
    roleId,
    blocked
  }
});

export const updateUserSuccess = data => ({
  type: C.UPDATE_USER_SUCCESS,
  payload: {
    data
  }
});

export const updateUserFailure = errors => ({
  type: C.UPDATE_USER_FAILURE,
  payload: {
    errors
  }
});

export const sendResetPWEmail = userId => ({
  type: C.SEND_RESET_PW_EMAIL_REQUEST,
  payload: {
    userId
  }
});

export const sendVerificationEmail = userId => ({
  type: C.SEND_VERIFICATION_EMAIL_REQUEST,
  payload: {
    userId
  }
});

export const createUser = (
  organizationId,
  firstName,
  lastName,
  phoneNumber,
  email,
  roleId
) => ({
  type: C.CREATE_USER_REQUEST,
  payload: {
    organizationId,
    firstName,
    lastName,
    phoneNumber,
    email,
    roleId
  }
});

export const enrollIngramOrg = payload => ({
  type: C.CREATE_INGRAM_ORG_REQUEST,
  payload
});

export const deleteUser = userId => ({
  type: C.DELETE_USER_REQUEST,
  payload: {
    userId
  }
});

// audit trail
export const fetchAuditTrailSearch = (
  organizationId,
  userId,
  auditCodes,
  from,
  to,
  page,
  pageSize,
  sort
) => ({
  type: C.FETCH_AUDIT_TRAIL_SEARCH_REQUEST,
  payload: {
    organizationId,
    userId,
    auditCodes,
    from,
    to,
    page,
    pageSize,
    sort
  }
});

export const fetchDataCenters = () => ({
  type: C.FETCH_DATA_CENTERS_REQUEST
});

export const fetchDataCentersSuccess = data => ({
  type: C.FETCH_DATA_CENTERS_SUCCESS,
  payload: {
    data
  }
});

export const fetchDataCentersFailure = errors => ({
  type: C.FETCH_DATA_CENTERS_FAILURE,
  payload: {
    errors
  }
});

export const fetchRecoveryPointServer = id => ({
  type: C.FETCH_RECOVERY_POINT_SERVER_REQUEST,
  payload: {
    id
  }
});

export const fetchSites = payload => ({
  type: C.FETCH_SITES_REQUEST,
  payload
});

export const fetchSitesSuccess = payload => ({
  type: C.FETCH_SITES_SUCCESS,
  payload
});

export const fetchSitesFailure = errors => ({
  type: C.FETCH_SITES_FAILURE,
  payload: {
    errors
  }
});

export const resetCurrentRecoveryPointServer = () => ({
  type: C.RESET_CURRENT_RECOVERY_POINT_SERVER_REQUEST
});

export const fetchRecoveryPointServerSuccess = payload => ({
  type: C.FETCH_RECOVERY_POINT_SERVER_SUCCESS,
  payload
});

export const fetchRecoveryPointServerFailure = errors => ({
  type: C.FETCH_RECOVERY_POINT_SERVER_FAILURE,
  payload: {
    errors
  }
});

export const createRecoveryPointServer = payload => ({
  type: C.CREATE_RECOVERY_POINT_SERVER_REQUEST,
  payload
});

export const updateRecoveryPointServer = (payload, id) => ({
  type: C.UPDATE_RECOVERY_POINT_SERVER_REQUEST,
  payload: { payload, id }
});

export const deleteRecoveryPointServer = (id, pagination) => ({
  type: C.DELETE_RECOVERY_POINT_SERVER_REQUEST,
  payload: {
    id,
    pagination
  }
});

export const fetchRecoveryPointServers = (page, pageSize, sort) => ({
  type: C.FETCH_RECOVERY_POINT_SERVERS_REQUEST,
  payload: {
    page,
    pageSize,
    sort
  }
});

export const fetchLinuxBackupServer = id => ({
  type: C.FETCH_LINUX_BACKUP_SERVER_REQUEST,
  payload: {
    id
  }
});

export const resetCurrentLinuxBackupServer = () => ({
  type: C.RESET_CURRENT_LINUX_BACKUP_SERVER_REQUEST
});

export const fetchLinuxBackupServerSuccess = payload => ({
  type: C.FETCH_LINUX_BACKUP_SERVER_SUCCESS,
  payload
});

export const fetchLinuxBackupServerFailure = errors => ({
  type: C.FETCH_LINUX_BACKUP_SERVER_FAILURE,
  payload: {
    errors
  }
});

export const createLinuxBackupServer = payload => ({
  type: C.CREATE_LINUX_BACKUP_SERVER_REQUEST,
  payload
});

export const updateLinuxBackupServer = (payload, id) => ({
  type: C.UPDATE_LINUX_BACKUP_SERVER_REQUEST,
  payload: { payload, id }
});

export const deleteLinuxBackupServer = (id, pagination) => ({
  type: C.DELETE_LINUX_BACKUP_SERVER_REQUEST,
  payload: {
    id,
    pagination
  }
});

export const fetchAuditTrailSearchSuccess = (data, pagination) => ({
  type: C.FETCH_AUDIT_TRAIL_SEARCH_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchRecoveryPointServersSuccess = (data, pagination) => ({
  type: C.FETCH_RECOVERY_POINT_SERVERS_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchAuditTrailSearchFailure = errors => ({
  type: C.FETCH_AUDIT_TRAIL_SEARCH_SUCCESS,
  payload: {
    errors
  }
});

export const fetchRecoveryPointServersFailure = errors => ({
  type: C.FETCH_RECOVERY_POINT_SERVERS_FAILURE,
  payload: {
    errors
  }
});

export const fetchCloudHybridStores = (rpsServerId, page, pageSize, sort) => ({
  type: C.FETCH_CLOUD_HYBRID_STORES_REQUEST,
  payload: {
    rpsServerId,
    page,
    pageSize,
    sort
  }
});

export const fetchCloudHybridStoresSuccess = (
  rpsServerId,
  data,
  pagination
) => ({
  type: C.FETCH_CLOUD_HYBRID_STORES_SUCCESS,
  payload: {
    rpsServerId,
    data,
    pagination
  }
});

export const fetchCloudHybridStoresFailure = (rpsServerId, errors) => ({
  type: C.FETCH_CLOUD_HYBRID_STORES_FAILURE,
  payload: {
    rpsServerId,
    errors
  }
});

export const fetchAuditCodes = () => ({
  type: C.FETCH_AUDIT_CODES_REQUEST
});

export const fetchAuditCodesSuccess = (data, pagination) => ({
  type: C.FETCH_AUDIT_CODES_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchLinuxBackupServers = (page, pageSize, sort) => ({
  type: C.FETCH_LINUX_BACKUP_SERVERS_REQUEST,
  payload: {
    page,
    pageSize,
    sort
  }
});

export const fetchLinuxBackupServersSuccess = (data, pagination) => ({
  type: C.FETCH_LINUX_BACKUP_SERVERS_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchAuditCodesFailure = errors => ({
  type: C.FETCH_AUDIT_CODES_FAILURE,
  payload: {
    errors
  }
});

export const fetchLinuxBackupServersFailure = errors => ({
  type: C.FETCH_LINUX_BACKUP_SERVERS_FAILURE,
  payload: {
    errors
  }
});

export const setAuditTrailSortString = sortString => ({
  type: C.SET_AUDIT_TRAIL_SORT_STRING,
  payload: { sortString }
});

export const setAuditTrailSearchValues = (
  organizationId,
  userId,
  auditCodes,
  from,
  to
) => ({
  type: C.SET_AUDIT_TRAIL_SEARCH_VALUES,
  payload: {
    organizationId,
    userId,
    auditCodes,
    from,
    to
  }
});

export const fetchDataStores = (page, pageSize, sort) => ({
  type: C.FETCH_DATA_STORES_REQUEST,
  payload: {
    page,
    pageSize,
    sort
  }
});

export const fetchDataStoresSuccess = (data, pagination) => ({
  type: C.FETCH_DATA_STORES_SUCCESS,
  payload: {
    data,
    pagination
  }
});

export const fetchDataStoresFailure = errors => ({
  type: C.FETCH_DATA_STORES_FAILURE,
  payload: {
    errors
  }
});

export const setRecoveryPointServersSortString = sortString => ({
  type: C.SET_RECOVERY_POINT_SERVERS_SORT_STRING,
  payload: { sortString }
});

export const setLinuxBackupServersSortString = sortString => ({
  type: C.SET_LINUX_BACKUP_SERVERS_SORT_STRING,
  payload: { sortString }
});

export const createDataStore = ds => ({
  type: C.CREATE_DATA_STORE_REQUEST,
  payload: { ...ds }
});

export const createDataStoreFailure = (rpsServerId, response) => ({
  type: C.CREATE_DATA_STORE_FAILURE,
  payload: { rpsServerId, response }
});

export const createDataStoreSuccess = rpsServerId => ({
  type: C.CREATE_DATA_STORE_SUCCESS,
  payload: { rpsServerId }
});

export const fetchDataStore = dataStoreId => ({
  type: C.FETCH_DATA_STORE_REQUEST,
  payload: { dataStoreId }
});

export const fetchDataStoreSuccess = data => ({
  type: C.FETCH_DATA_STORE_SUCCESS,
  payload: { ...data }
});

export const fetchDataStoreFailure = errors => ({
  type: C.FETCH_DATA_STORE_FAILURE,
  payload: { errors }
});

export const updateDataStore = (dataStoreId, dataStore) => ({
  type: C.UPDATE_DATA_STORE_REQUEST,
  payload: {
    dataStoreId,
    dataStore
  }
});

export const destroyDataStore = (rpsServerId, dataStoreId) => ({
  type: C.DESTROY_DATA_STORE_REQUEST,
  payload: { rpsServerId, dataStoreId }
});

export const deleteDataStore = (rpsServerId, dataStoreId) => ({
  type: C.DELETE_DATA_STORE_REQUEST,
  payload: { rpsServerId, dataStoreId }
});

export const startDataStore = (rpsServerId, dataStoreId) => ({
  type: C.START_DATA_STORE_REQUEST,
  payload: { rpsServerId, dataStoreId }
});

export const stopDataStore = (rpsServerId, dataStoreId) => ({
  type: C.STOP_DATA_STORE_REQUEST,
  payload: { rpsServerId, dataStoreId }
});
export const fetchBIReportsTypes = () => ({
  type: C.FETCH_BI_REPORTS_TYPES_REQUEST
});

export const fetchBIReportsTypesSuccess = data => ({
  type: C.FETCH_BI_REPORTS_TYPES_SUCCESS,
  payload: { data }
});

export const fetchBIReportsTypesFailure = errors => ({
  type: C.FETCH_BI_REPORTS_TYPES_FAILURE,
  payload: { errors }
});

export const fetchBIReport = reportType => ({
  type: C.FETCH_BI_REPORT_REQUEST,
  payload: { reportType }
});

export const fetchBIReportSuccess = data => ({
  type: C.FETCH_BI_REPORT_SUCCESS,
  payload: { data }
});

export const fetchBIReportFailure = errors => ({
  type: C.FETCH_BI_REPORT_FAILURE,
  payload: { errors }
});

export const updateBIReportRecipients = (reportType, recipient) => ({
  type: C.UPDATE_BI_REPORT_RECIPIENTS_REQUEST,
  payload: { reportType, recipient }
});

export const fetchBIReportRecipients = reportType => ({
  type: C.FETCH_BI_REPORT_RECIPIENTS_REQUEST,
  payload: { reportType }
});

export const fetchBIReportRecipientsSuccess = data => ({
  type: C.FETCH_BI_REPORT_RECIPIENTS_SUCCESS,
  payload: data
});

export const fetchBIReportRecipientsFailure = errors => ({
  type: C.FETCH_BI_REPORT_RECIPIENTS_FAILURE,
  payload: { errors }
});

export const uploadFile = uploadData => ({
  type: C.UPLOAD_FILE,
  uploadData
});

export const uploadHelpLinkFile = uploadData => ({
  type: C.UPLOAD_HELP_LINK_FILE,
  uploadData
});

export const updateUserPassword = data => ({
  type: C.UPDATE_USER_PASSWORD,
  data
});
