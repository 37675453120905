export const CCDATAGRID_INITIALIZE = "CCDATAGRID/INITIALIZE";

export const CCDATAGRID_FETCH_DATA_REQUEST = "CCDATAGRID/FETCH_DATA_REQUEST";
export const CCDATAGRID_FETCH_DATA_SUCCESS = "CCDATAGRID/FETCH_DATA_SUCCESS";
export const CCDATAGRID_UPDATE_LOCAL_DATA = "CCDATAGRID/UPDATE_LOCAL_DATA";
export const CCDATAGRID_FETCH_DATA_FAILURE = "CCDATAGRID/FETCH_DATA_FAILURE";

export const CCDATAGRID_UPDATE_SORTED = "CCDATAGRID/UPDATE_SORTED";
export const CCDATAGRID_UPDATE_PAGE = "CCDATAGRID/UPDATE_PAGE";
export const CCDATAGRID_UPDATE_PAGE_LOCALLY = "CCDATAGRID/UPDATE_PAGE_LOCALLY";
export const CCDATAGRID_UPDATE_PAGE_SIZE = "CCDATAGRID/UPDATE_PAGE_SIZE";
export const CCDATAGRID_UPDATE_COLUMNS = "CCDATAGRID/UPDATE_COLUMNS";
export const CCDATAGRID_STORE_COLUMNS = "CCDATAGRID/STORE_COLUMNS";

export const CCDATAGRID_UNCHECK_ALL = "CCDATAGRID/UNCHECK_ALL";
export const CCDATAGRID_CHECK_ROWS = "CCDATAGRID/CHECK_ROWS";
export const CCDATAGRID_UNCHECK_ROW = "CCDATAGRID/UNCHECK_ROW";

export const CCDATAGRID_SHOW_SAVEDSEARCH_CLEARALL_LINK =
  "CCDATAGRID/SHOW_SAVEDSEARCH_CLEARALL_LINK";
export const CCDATAGRID_REMOVE_SEARCH_TAGS_RELOAD =
  "CCDATAGRID/REMOVE_SEARCH_TAGS_RELOAD";

export const CCDATAGRID_SHOW_ADVANCED_SEARCH_FORM =
  "CCDATAGRID/SHOW_ADVANCED_SEARCH_FORM";
export const CCDATAGRID_HIDE_ADVANCED_SEARCH_FORM =
  "CCDATAGRID/HIDE_ADVANCED_SEARCH_FORM";
export const CCDATAGRID_ADVANCED_SEARCH = "CCDATAGRID/ADVANCED_SEARCH";
export const CCDATAGRID_CLEAR_ADVANCED_SEARCH =
  "CCDATAGRID/CLEAR_ADVANCED_SEARCH";
export const CCDATAGRID_CLEAR_DATA = "CCDATAGRID/CCDATAGRID_CLEAR_DATA";
export const RESET_CCDATAGRID = "CCDATAGRID/RESET_CCDATAGRID_LOADER";
export const START_LOADER = "CCDATAGRID/START_LOADER";
export const CCDATAGRID_SHOW_CURRENT_SEARCH = "CCDATAGRID_SHOW_CURRENT_SEARCH";
export const CCDATAGRID_ADVANCED_SEARCH_OPTIONS_SELECTED =
  "CCDATAGRID_ADVANCED_SEARCH_OPTIONS_SELECTED";
export const CCDATAGRID_ADVANCED_SEARCH_OPTIONS =
  "CCDATAGRID_ADVANCED_SEARCH_OPTIONS";
export const CCDATAGRID_ADVANCED_SEARCH_OPTIONS_UNCHANGED =
  "CCDATAGRID_ADVANCED_SEARCH_OPTIONS_UNCHANGED";
export const CCDATAGRID_UPDATE_ADVANCED_SEARCH_OPTIONS =
  "CCDATAGRID_UPDATE_ADVANCED_SEARCH_OPTIONS";
export const CCDATAGRID_RESET_ADVANCED_SEARCH_OPTIONS =
  "CCDATAGRID_RESET_ADVANCED_SEARCH_OPTIONS";
export const CCDATAGRID_OPTIONS_FOR_TAGS = "CCDATAGRID_OPTIONS_FOR_TAGS";
export const CCDATAGRID_RESET_OPTIONS_FOR_TAGS =
  "CCDATAGRID_RESET_OPTIONS_FOR_TAGS";
export const CCDATAGRID_SELECTED_SAVED_SEARCH =
  "CCDATAGRID_SELECTED_SAVED_SEARCH";
export const CCDATAGRID_RESET_SELECTED_SAVED_SEARCH =
  "CCDATAGRID_RESET_SELECTED_SAVED_SEARCH";
export const CCDATAGRID_USER_SEARCH_DATA = "CCDATAGRID_USER_SEARCH_DATA";
export const CCDATAGRID_SYSTEM_SEARCH_DATA = "CCDATAGRID_SYSTEM_SEARCH_DATA";
export const CCDATAGRID_RESET_USER_SEARCH_DATA =
  "CCDATAGRID_RESET_USER_SEARCH_DATA";
export const CCDATAGRID_RESET_SYSTEM_SEARCH_DATA =
  "CCDATAGRID_RESET_SYSTEM_SEARCH_DATA";
export const CCDATAGRID_SHOW_SAVE_SEARCH_MODAL =
  "CCDATAGRID_SHOW_SAVE_SEARCH_MODAL";
export const CCDATAGRID_RESET_SHOW_SAVE_SEARCH_MODAL =
  "CCDATAGRID_RESET_SHOW_SAVE_SEARCH_MODAL";
export const CCDATAGRID_SET_SAVE_SEARCH_TEXT =
  "CCDATAGRID_SET_SAVE_SEARCH_TEXT";
export const CCDATAGRID_RESET_SAVE_SEARCH_TEXT =
  "CCDATAGRID_RESET_SAVE_SEARCH_TEXT";
export const CCDATAGRID_MANAGE_SAVE_SEARCH_OPTIONS =
  "CCDATAGRID_MANAGE_SAVE_SEARCH_OPTIONS";
export const CCDATAGRID_RESET_MANAGE_SAVE_SEARCH_OPTIONS =
  "CCDATAGRID_RESET_MANAGE_SAVE_SEARCH_OPTIONS";
export const CCDATAGRID_UPDATE_MANAGE_SAVE_SEARCH_OPTIONS =
  "CCDATAGRID_UPDATE_MANAGE_SAVE_SEARCH_OPTIONS";
export const CCDATAGRID_MANAGE_SELECTED_SAVE_SEARCH =
  "CCDATAGRID_MANAGE_SELECTED_SAVE_SEARCH";
export const CCDATAGRID_RESET_MANAGE_SELECTED_SAVE_SEARCH =
  "CCDATAGRID_RESET_MANAGE_SELECTED_SAVE_SEARCH";
export const CCDATAGRID_CONSIDER_ADVANCED_SEARCH_OPTIONS =
  "CCDATAGRID_CONSIDER_ADVANCED_SEARCH_OPTIONS";
export const CCDATAGRID_RESET_CONSIDER_ADVANCED_SEARCH_OPTIONS =
  "CCDATAGRID_RESET_CONSIDER_ADVANCED_SEARCH_OPTIONS";
export const CCDATAGRID_INVOKE_REORDER_COLUMN_DATA =
  "CCDATAGRID_INVOKE_REORDER_COLUMN_DATA";
export const CCDATAGRID_GRID_CHECKBOX_SETTINGS =
  "CCDATAGRID_GRID_CHECKBOX_SETTINGS";

export const CCDATAGRID_INVOKE_SAVED_SEARCH = "CCDATAGRID_INVOKE_SAVED_SEARCH";
export const CCDATAGRID_INVOKE_SELECTED_SAVED_SEARCH =
  "CCDATAGRID_INVOKE_SELECTED_SAVED_SEARCH";
export const CCDATAGRID_INVOKE_ADVANCED_SEARCH_OPTIONS =
  "CCDATAGRID_INVOKE_ADVANCED_SEARCH_OPTIONS";
export const CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH =
  "CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH";
export const CCDATAGRID_INVOKE_DELETE_SAVED_SEARCH =
  "CCDATAGRID_INVOKE_DELETE_SAVED_SEARCH";
export const CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH_OPTIONS =
  "CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH_OPTIONS";
export const CCDATAGRID_UPDATE_SOURCE_IN_CCDATAGRID =
  "CCDATAGRID_UPDATE_SOURCE_IN_CCDATAGRID";
export const CCDATAGRID_DELETE_SOURCE_IN_CCDATAGRID =
  "CCDATAGRID_DELETE_SOURCE_IN_CCDATAGRID";
export const CCDATAGRID_DELETE_POLICY_IN_CCDATAGRID =
  "CCDATAGRID_DELETE_POLICY_IN_CCDATAGRID";
export const CCDATAGRID_INVOKE_FETCH_COLUMNS =
  "CCDATAGRID_INVOKE_FETCH_COLUMNS";
