import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { ID_PREFIX } from "configs/IdConfig";
import ActionDropdown from "../../../action-dropdown-mui";
import FormattedMessage, {
  FormattedHTMLMessage
} from "../../../formatted-message";
import {
  updateGridCurrentPageNumber,
  updateGridCurrentPageSize
} from "../../../../../state/actions/actions";

const RowPerPageText = () => (
  <FormattedMessage id="rowsPerPageText" defaultMessage="Rows per page:" />
);

/**
 * Component to render custom pagination footer.
 */
const GridFooter = ({
  totalSize,
  page,
  setPageSize,
  gotoPage,
  testId,
  intl,
  pageSizeOptions
}) => {
  const footerId = testId ? `${ID_PREFIX}${testId}` : "";
  const dispatch = useDispatch();
  const gridPageSize = useSelector(state => state.reducer.gridPageSize);
  const [activePage, setActivePage] = useState(1);
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);

  useEffect(() => {
    setActivePage(page);
    dispatch(updateGridCurrentPageNumber(page));
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageSize(gridPageSize);
    if (activePage === 1) {
      setStartCount(1);
      gridPageSize >= totalSize
        ? setEndCount(totalSize)
        : setEndCount(gridPageSize);
    } else {
      setStartCount(gridPageSize * (activePage - 1));
      activePage * gridPageSize >= totalSize
        ? setEndCount(totalSize)
        : setEndCount(activePage * gridPageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, gridPageSize, totalSize]);

  /**
   * to handle page change
   * page change action dispatch to datagrid component
   */
  const handlePageSelect = newPage => {
    setActivePage(newPage);
    gotoPage(newPage - 1);
    dispatch(updateGridCurrentPageNumber(Number(newPage)));
  };

  /**
   * to handle page size change
   * dropdown menu item action dispatch to datagrid component
   */
  const handleDropdownMenuItemClick = e => {
    setActivePage(1);
    const currentPageSize = Number(e.value);
    setPageSize(currentPageSize);
    gotoPage(0);
    dispatch(updateGridCurrentPageSize(currentPageSize));
  };

  const labelDisplayedRows = (from, to, count) => (
    <span id={`${footerId}_pagination`}>
      <FormattedHTMLMessage
        id="gridPaginationOnlyFromAndToText"
        values={{
          from,
          to
        }}
      />
      <FormattedHTMLMessage id="gridPaginationOnlyOfText" />
      <FormattedHTMLMessage
        id="gridPaginationOnlyCountText"
        values={{
          count
        }}
      />
    </span>
  );

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0.7rem" }}
    >
      <div>{labelDisplayedRows(startCount, endCount, totalSize)}</div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center" style={{ paddingRight: 10 }}>
          <div style={{ paddingRight: 10 }}>
            <RowPerPageText />
          </div>
          <ActionDropdown
            testId={`${footerId}_per_page_menu`}
            title={`${gridPageSize}`}
            value={`${gridPageSize}`}
            options={pageSizeOptions.map(item => ({
              key: item,
              value: item
            }))}
            onMenuItemClick={handleDropdownMenuItemClick}
            hideOutline
            customStyle={{ width: 45 }}
          />
        </div>
        <Pagination
          count={Math.ceil(totalSize / gridPageSize) || 1}
          defaultPage={activePage}
          page={activePage}
          showFirstButton
          showLastButton
          color="primary"
          size="small"
          onChange={(event, newPage) => handlePageSelect(newPage)}
          renderItem={item => {
            switch (item.type) {
              case "first":
                return (
                  <PaginationItem
                    id={`${footerId}_pagination_first`}
                    aria-label={intl.formatMessage({
                      id: "goToFirstPage",
                      defaultMessage: "Go to first page"
                    })}
                    title={intl.formatMessage({
                      id: "goToFirstPage",
                      defaultMessage: "Go to first page"
                    })}
                    {...item}
                  />
                );
              case "previous":
                return (
                  <PaginationItem
                    id={`${footerId}_pagination_previous`}
                    aria-label={intl.formatMessage({
                      id: "goToPreviousPage",
                      defaultMessage: "Go to previous page"
                    })}
                    title={intl.formatMessage({
                      id: "goToPreviousPage",
                      defaultMessage: "Go to previous page"
                    })}
                    {...item}
                  />
                );
              case "page":
                return (
                  <PaginationItem
                    id={`${footerId}_pagination_${item.page}`}
                    aria-label={intl.formatMessage(
                      {
                        id: "goToParticularPage",
                        defaultMessage: `Go to page ${item.page}`
                      },
                      { pageNumber: item.page }
                    )}
                    title={intl.formatMessage(
                      {
                        id: "goToParticularPage",
                        defaultMessage: `Go to ${item.page} page`
                      },
                      { pageNumber: item.page }
                    )}
                    {...item}
                  />
                );
              case "next":
                return (
                  <PaginationItem
                    id={`${footerId}_pagination_next`}
                    aria-label={intl.formatMessage({
                      id: "goToNextPage",
                      defaultMessage: "Go to next page"
                    })}
                    title={intl.formatMessage({
                      id: "goToNextPage",
                      defaultMessage: "Go to next page"
                    })}
                    {...item}
                  />
                );
              case "last":
                return (
                  <PaginationItem
                    id={`${footerId}_pagination_last`}
                    aria-label={intl.formatMessage({
                      id: "goToLastPage",
                      defaultMessage: "Go to last page"
                    })}
                    title={intl.formatMessage({
                      id: "goToLastPage",
                      defaultMessage: "Go to last page"
                    })}
                    {...item}
                  />
                );
              default:
                return <PaginationItem {...item} />;
            }
          }}
        />
      </div>
    </div>
  );
};

GridFooter.defaultProps = {
  pageSizeOptions: [25, 50, 100]
};

GridFooter.propTypes = {
  totalSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number)
};

export default React.memo(injectIntl(GridFooter));
