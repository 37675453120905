import { combineReducers } from "redux";
import * as C from "state/constants/ccToast";

const byId = (state = {}, action) => {
  const rVal = { ...state };
  let sameToastCount = 0;

  switch (action.type) {
    case C.CCTOAST_ADD_NOTIFICATION:
      for (const notificationId in state) {
        if (notificationId.includes(action.notification.notificationId)) {
          sameToastCount++;
        }
      }
      if (sameToastCount) {
        // eslint-disable-next-line no-param-reassign
        action.notification.notificationId = `${action.notification.notificationId}_${sameToastCount}`;
      } else {
        // eslint-disable-next-line no-param-reassign
        action.notification.notificationId = `${action.notification.notificationId}_0`;
      }
      rVal[action.notification.notificationId] = action.notification;
      return rVal;
    case C.CCTOAST_CLEAR_NOTIFICATIONS:
      return {};
    case C.CCTOAST_CLEAR_NOTIFICATION:
      if (rVal.hasOwnProperty(action.notificationId)) {
        delete rVal[action.notificationId];
      }
      return rVal;
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case C.CCTOAST_ADD_NOTIFICATION:
      return state.includes(action.notification.notificationId)
        ? state
        : [action.notification.notificationId, ...state];
    case C.CCTOAST_CLEAR_NOTIFICATIONS:
      return [];
    case C.CCTOAST_CLEAR_NOTIFICATION:
      return state.filter(nId => nId !== action.notificationId);
    default:
      return state;
  }
};

const ccToast = combineReducers({
  byId,
  allIds
});

export default ccToast;
