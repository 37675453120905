import {
  CHANGE_CREATE_STANDBY_VM_TAB,
  CREATE_HYPERV_FOR_VM_SUCCESS,
  CLEAR_VM_LOCATION_DETAILS,
  CLEAR_CREATE_HYPERV_FOR_VM_SUCCESS,
  GET_HYPERVISORS_INFO,
  GET_ESX_INFO,
  GET_ADD_NW_INFO,
  GET_SELECTED_VMWARE_TREE_INFO,
  GET_VW_HYPER_INFO,
  VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS,
  GET_VMLOCATION_INFO,
  STORE_VALIDATE_CONFIG_DATA,
  INITIALIZE_APP,
  GET_HYPERV_CONFIGURATION_SUCCESS,
  GET_VMWARE_CONFIGURATION_SUCCESS,
  DESTINATIONS_DATA_GET_SUCCESS_FOR_VSB,
  SAVE_RECOVERY_SELECTED_DESTINATION_DATA_VSB,
  STORE_SOURCE_DATA_FOR_VSB,
  GET_RECOVERY_POINT_RESPONSE_FOR_VSB,
  RECOVERY_POINTS_BY_DESTINATION_PATH_GET_FOR_VSB_SUCCESS,
  SAVE_RP_PASSWORD_FOR_VSB,
  STORE_SELECTED_RECOVERY_POINT_FOR_VSB,
  STORE_VALIDATE_CONFIG_DATA_FOR_VSB,
  FETCH_PROXIES_VSB_SUCCESS,
  CLEAN_UP_VSB_STATE,
  STORE_CLICKED_SOURCE_DETAILS,
  SET_SELECTED_PROXY_FOR_VSB,
  VALIDATE_VSB_SUCCESS,
  SUBMIT_VM_SUCCESS,
  CLEAR_RECOVERY_POINTS_DATA_FOR_VSB,
  EC2_VALIDATE_CLOUD_PROXY_RESPONSE,
  HYPERV_SELECTED_ROW,
  CONNECT_MODAL_STATUS,
  CREATE_NUTANIX_FOR_IVM_SUCCESS,
  STORE_CREATE_STANDBY_VM_FINAL_PAYLOAD,
  SET_HYPERV_FOLDER_INITIAL_PATH,
  STORE_FORM_INVALID_INFO,
  FETCH_CLOUD_CONFIGURATIONS_VSB,
  STORE_CLOUD_CONFIGURATIONS,
  FETCH_CLOUD_SUBNET_AZURE,
  STORE_AZORE_CLOUD_SUBNET_VSB,
  GET_VSB_SUCCESS_HYPERVISORS_FOR_CREATE_STANDBY_VM,
  GET_REGIONS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS,
  CLEAR_CLOUD_ACCOUNTS_REGIONS_VSB,
  GET_RESOURCE_GROUPS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS,
  LOAD_VSB_MONITOR_SUCCESS,
  GET_VSB_NUTANIX_CONFIGURATION_SUCCESS,
  CREATE_VMWARE_FOR_VSB_SUCCESS,
  GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB,
  GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB,
  GCP_SECURITY_GROUPS_FETCH_SUCCESS_FOR_VSB,
  GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB,
  CLEAR_GCP_SECURITY_GROUPS_FOR_VSB
} from "state/constants/createStandbyVM";
import { sortNodesAndChildren } from "utils/VMListTreeUtils";

const initialState = {
  tabIndex: 1
};

function createStandbyVM(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CREATE_STANDBY_VM_TAB: {
      return {
        ...state,
        tabIndex: action.data
      };
    }
    case DESTINATIONS_DATA_GET_SUCCESS_FOR_VSB: {
      return {
        ...state,
        destinationsDataBySourceForVsb: [...action.data]
      };
    }
    case STORE_SOURCE_DATA_FOR_VSB: {
      return {
        ...state,
        selectedSourceForVsb: action.data
      };
    }
    case SAVE_RECOVERY_SELECTED_DESTINATION_DATA_VSB: {
      return {
        ...state,
        selectedDestinationDataVSB: action.data
      };
    }
    case GET_RECOVERY_POINT_RESPONSE_FOR_VSB: {
      return {
        ...state,
        getRecoveryPointResponse: action.data
      };
    }
    case RECOVERY_POINTS_BY_DESTINATION_PATH_GET_FOR_VSB_SUCCESS: {
      return {
        ...state,
        recoveryPointsDataForDestinationPath: [...action.data]
      };
    }
    case CLEAR_RECOVERY_POINTS_DATA_FOR_VSB: {
      return {
        ...state,
        recoveryPointsDataForDestinationPath: [],
        selectedVSBRecoveryPoint: []
      };
    }
    case SAVE_RP_PASSWORD_FOR_VSB: {
      return {
        ...state,
        rpEncryptionPassword: action.data
      };
    }
    case STORE_SELECTED_RECOVERY_POINT_FOR_VSB: {
      return {
        ...state,
        selectedVSBRecoveryPoint: action.data
      };
    }
    case STORE_VALIDATE_CONFIG_DATA_FOR_VSB: {
      return {
        ...state,
        validateConfigResp: action.data || null
      };
    }
    case FETCH_PROXIES_VSB_SUCCESS: {
      return {
        ...state,
        proxiesResp: action.data
      };
    }
    case STORE_CLICKED_SOURCE_DETAILS: {
      return {
        ...state,
        sourceData: action.data
      };
    }
    case SET_SELECTED_PROXY_FOR_VSB: {
      return {
        ...state,
        selectedProxy: action.data
      };
    }
    case VALIDATE_VSB_SUCCESS: {
      return {
        ...state,
        validateResponse: action.data
      };
    }
    case CLEAN_UP_VSB_STATE: {
      return {};
    }

    case CREATE_HYPERV_FOR_VM_SUCCESS:
      if (
        action.data.data.children &&
        action.data?.data?.children?.length > 0
      ) {
        sortNodesAndChildren(action.data.data.children);
      }
      return {
        ...state,
        createIVMHyperVResp: action.data
      };
    case CLEAR_CREATE_HYPERV_FOR_VM_SUCCESS:
      return {
        ...state,
        createIVMHyperVResp: null
      };
    case CLEAR_VM_LOCATION_DETAILS:
      return null;
    case GET_HYPERVISORS_INFO:
      return {
        ...state,
        storeHypervInfo: action.data
      };
    case GET_ESX_INFO:
      return {
        ...state,
        storeEsxInfo: action.data
      };
    case GET_ADD_NW_INFO:
      return {
        ...state,
        storeAddnwInfo: action.data
      };
    case GET_VMLOCATION_INFO:
      return {
        ...state,
        VMlocationDetails: action.data
      };
    case GET_VW_HYPER_INFO:
      return {
        ...state,
        selectedVmHyperv: action.data
      };
    case VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS:
      return {
        ...state,
        vmwareMonitorConnectResponse: action.data
      };
    case STORE_VALIDATE_CONFIG_DATA:
      return {
        ...state,
        validateConfigResp: action.data
      };
    case STORE_CREATE_STANDBY_VM_FINAL_PAYLOAD:
      return {
        ...state,
        createStandbyVMFinalPayload: action.data
      };
    case INITIALIZE_APP:
      return {
        ...state,
        validateConfigResp: null
      };
    case GET_HYPERV_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configResp: action.data || null
      };
    case GET_VMWARE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configResp: action.data || null
      };
    case GET_VSB_NUTANIX_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configResp: action.data || null
      };
    case SUBMIT_VM_SUCCESS: {
      return {
        ...state,
        vmResp: action.data
      };
    }
    case EC2_VALIDATE_CLOUD_PROXY_RESPONSE: {
      return {
        ...state,
        ec2CloudProxyValidationResponse: action.data
      };
    }
    case HYPERV_SELECTED_ROW: {
      return {
        ...state,
        selectedVmHyperv: action.data
      };
    }
    case CONNECT_MODAL_STATUS: {
      return {
        ...state,
        connectModalStatus: action.data
      };
    }
    case CREATE_NUTANIX_FOR_IVM_SUCCESS: {
      return {
        ...state,
        createIVMNutanixResp: action.data
      };
    }
    case SET_HYPERV_FOLDER_INITIAL_PATH:
      return {
        ...state,
        hyperv: {
          hypervBrowseFolderIntialPath: action.data
        }
      };
    case STORE_FORM_INVALID_INFO:
      return {
        ...state,
        createStandbyVMFormErrorValues: action.data
      };
    case FETCH_CLOUD_CONFIGURATIONS_VSB:
      return {
        ...state,
        cloudConfigurations: action.data,
        gcpCloudProxyLoading: true
      };
    case STORE_CLOUD_CONFIGURATIONS:
      return {
        ...state,
        storeCloudConfig: action.data,
        gcpCloudProxyLoading: false
      };
    case FETCH_CLOUD_SUBNET_AZURE:
      return {
        ...state,
        azurecloudSubnet: action.data
      };
    case STORE_AZORE_CLOUD_SUBNET_VSB:
      return {
        ...state,
        storeAzureSubnet: action.data
      };
    case GET_VSB_SUCCESS_HYPERVISORS_FOR_CREATE_STANDBY_VM:
      return {
        ...state,
        hypervVSBList: action.data
      };
    case GET_REGIONS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        cloudRegionsList: action.data
      };
    case GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB:
      return {
        ...state,
        gcpComputeRegions: action.data
      };
    case GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB:
      return {
        ...state,
        gcpComputeZones: action.data
      };
    case GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB:
      return {
        ...state,
        isLoadingSecurityGroups: true
      };
    case GCP_SECURITY_GROUPS_FETCH_SUCCESS_FOR_VSB:
      return {
        ...state,
        securityGroupsForSelectedNetwork: action.data,
        isLoadingSecurityGroups: false
      };
    case CLEAR_GCP_SECURITY_GROUPS_FOR_VSB:
      return {
        ...state,
        securityGroupsForSelectedNetwork: []
      };
    case CLEAR_CLOUD_ACCOUNTS_REGIONS_VSB:
      return {
        ...state,
        cloudRegionsList: null
      };
    case GET_RESOURCE_GROUPS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        azureResourceGroups: action.data
      };
    case LOAD_VSB_MONITOR_SUCCESS:
      return {
        ...state,
        monitors: action.data
      };
    case CREATE_VMWARE_FOR_VSB_SUCCESS:
      return {
        ...state,
        createVSBVmwareResp: action.data
      };
    case GET_SELECTED_VMWARE_TREE_INFO:
      return {
        ...state,
        getSelectedVmwareTreeData: action.data
      };
    default:
      return state;
  }
}
export default createStandbyVM;
