import _ from "lodash";
import {
  SHOW_ALERT_LIST,
  UPDATE_ALERT,
  UPDATE_ALERT_LIST_COUNT,
  RESET_ALERT_LIST_COUNT,
  SHOW_ALERT_DETAILS_MODAL,
  CLOSE_ALERT_LIST,
  UPDATE_WIDGETS,
  MANAGE_WIDGETS,
  EXPAND_WIDGET,
  MAXIMIZE,
  COLLAPSE,
  UPDATE_DASHBOARD_SUMMARY,
  UPDATE_SUMMARY_WIDGETS,
  LOG_OUT,
  CLEAR_WIDGET_DATA,
  UPDATE_SELECTED_FILTER_OPTIONS_WIDGET
} from "state/actions/actionTypes";
import * as monitorConst from "components/monitor/dashboard/DashboardConstants";
import * as util from "utils/SpogDataGridUtil";
import {
  CHART_DATA_TYPE,
  ColorCodes,
  ConvertText,
  WIDGETS_HIDDEN
} from "utils/appConstants";
import { GET_WIDGETS } from "../actions/actionTypes";

export const initialState = {
  widgetList: [], //monitorConst.WidgetsList,
  layout: [],
  layoutState: [],
  sourceSummary: {},
  policySummary: {},
  customerSummary: {},
  usageSummary: {},
  alertDetailsModalObj: [],
  showModal: false,
  alertsList: { data: [] },
  alertListCount: 0
};

/**
 * Reducer to manage Configure state
 * @param state
 * @param action
 * @returns {*}
 */
const monitorReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * show alert list
     * alertsList- {} obj
     */
    case SHOW_ALERT_LIST: {
      return { ...state, alertsList: action.data };
    }

    /**
     * update alert list count
     */
    case UPDATE_ALERT_LIST_COUNT: {
      const newCount = action.data + state.alertListCount;
      return { ...state, alertListCount: newCount };
    }

    /**
     * reset alert list count
     */
    case RESET_ALERT_LIST_COUNT: {
      return { ...state, alertListCount: 0 };
    }

    /**
     * delted alert
     * alertsList- {} obj
     */
    case UPDATE_ALERT: {
      return {
        ...state,
        alertsList: removeItemFromArray(
          state.alertsList,
          action.data,
          "source_id"
        )
      };
    }

    /**
     * to show alet details modal
     */

    case SHOW_ALERT_DETAILS_MODAL: {
      return {
        ...state,
        alertDetailsModalObj: action.data,
        showModal: true
      };
    }

    case CLOSE_ALERT_LIST: {
      return {
        ...state,
        alertDetailsModalObj: [],
        showModal: false
      };
    }

    case GET_WIDGETS: {
      return {
        ...state,
        widgetList: action.data,
        layout: getLayout(action.data)
      };
    }

    /**
     * To load dashboard widgets
     */
    case UPDATE_WIDGETS: {
      return {
        ...state,
        widgetList: generateData(
          state.widgetList,
          action.data.key,
          action.data.analysis,
          action.data.filter
        ),
        layout: state.layoutState.length
          ? state.layoutState
          : getLayout(state.widgetList, state.layout)
      };
    }
    /**
     * To load dashboard widgets
     */
    case UPDATE_SUMMARY_WIDGETS: {
      return {
        ...state,
        widgetList: generateSummaryData(
          state.widgetList,
          action.data.key,
          action.data.analysis,
          action.data.arrayKey,
          action.data.filter
        ),
        layout: state.layoutState.length
          ? state.layoutState
          : getLayout(state.widgetList)
      };
    }
    /**
     * To manage dashboard widgets
     */
    case MANAGE_WIDGETS: {
      return {
        ...state,
        widgetList: filterWidgets(action.widgetList, action.data),
        layout: getLayout(action.widgetList)
      };
    }

    case LOG_OUT: {
      return {
        ...state,
        widgetList: clearWidgetState(state.widgetList, LOG_OUT)
      };
    }

    case EXPAND_WIDGET: {
      return {
        ...state,
        layout: expandWidget(state.layout, action.index)
      };
    }

    case UPDATE_SELECTED_FILTER_OPTIONS_WIDGET: {
      return {
        ...state,
        widgetList: updateDropdownWidget(
          state.widgetList,
          action.chartType,
          action.data
        )
      };
    }

    case UPDATE_DASHBOARD_SUMMARY: {
      return {
        ...state,
        ...action.data
      };
    }

    case CLEAR_WIDGET_DATA: {
      return {
        ...state,
        widgetList: [],
        layout: [],
        layoutState: [],
        sourceSummary: {},
        policySummary: {},
        customerSummary: {},
        usageSummary: {},
        showModal: false
      };
    }

    default:
      return state;
  }

  /**
   * remove the item from array
   */
  function removeItemFromArray(array, id, key) {
    let data = array.slice();
    const updatedData = data.filter(item => item[key] !== id);
    return [...updatedData];
  }

  /**
   * generic method to filter array with another array
   */
  // function removeArrayFromArray(array, data) {
  //   const updatedData = array.filter(item => !data.includes(item));
  //   return [...updatedData];
  // }

  /**
   * Method to manage widgets according to users preference*/

  function filterWidgets(data, index) {
    const updatedData = data.filter(item => {
      item.type === index ? (item.show = !item.show) : ""; // eslint-disable-line no-unused-expressions
      return item;
    });
    return [...updatedData];
  }

  function updateDropdownWidget(data, chartType, action) {
    let updatedData = [];
    if (data && data.length) {
      updatedData = data.filter(item => {
        if (item.type === chartType) {
          item.options.selectedOption = action;
        }
        return item;
      });
    }

    return [...updatedData];
  }

  // function getCoordinates(order) {
  //   var x = 1 - (order % 2);
  //   var y = order / 2 - (1 - (order % 2));
  //   return x + "" + Math.floor(y);
  // }

  function adjustWidgetListforHiddenWidgets(widgetList) {
    var i;
    for (i = 0; i < widgetList.length; i++) {
      if (WIDGETS_HIDDEN.indexOf(widgetList[i].type) > -1) {
        widgetList.splice(i, 1);
      }
    }
    widgetList.sort((a, b) => (a.order_id > b.order_id ? 1 : -1));
    for (i = 0; i < widgetList.length; i++) {
      widgetList[i].order_id = i;
    }
    return widgetList;
  }

  /**
   * Method to generate widgets layout*/
  function getLayout(widgetList, slayout = []) {
    state.layoutState = [];
    widgetList = adjustWidgetListforHiddenWidgets(widgetList);
    let totalColumns = 2;
    let layout = [];
    let x = 0;
    let y = 0;
    let xIndex = 0;
    let yIndex = 0;
    for (let orderc = 0; orderc < widgetList.length; orderc++) {
      layout.push({
        i: "index_" + xIndex + yIndex,
        x: x,
        y: y,
        w: 1,
        // w:
        //   slayout.length > 0 &&
        //   slayout.length === widgetList.length &&
        //   slayout[orderc].w
        //     ? slayout[orderc].w
        //     : widgetList[orderc].is_expanded
        //     ? 2
        //     : 1,
        h: 1,
        isResizable: false,
        static: true
      });
      if (x < totalColumns - 1) {
        // if (widgetList[orderc].is_expanded) {
        //   y++;
        // } else {
        x++;
        //}
      } else {
        if (x >= totalColumns - 1) y++;
        x = 0;
      }

      if (xIndex < totalColumns - 1) {
        xIndex++;
      } else {
        xIndex = 0;
        yIndex++;
      }
    }
    state.layoutState = [...layout];
    return layout;
  }

  /**
   * To expand the widgets*/
  function expandWidget(data, index) {
    let obj;
    if (data.length) {
      if (data[index].w === 1) {
        obj = Object.assign(data[index], {
          w: 2,
          action: COLLAPSE
        });
        data[index] = { ...obj };
      } else {
        obj = Object.assign(data[index], {
          w: 1,
          action: MAXIMIZE
        });
        data[index] = { ...obj };
      }
    }
    state.layoutState.length
      ? (data[index] = { ...obj })
      : (state.layoutState = [...data]);

    return [...data];
  }

  /**
   * To map object specific data
   * @param key
   * @param data
   */
  function generateData(widgetsList, key, data, filter) {
    let widgets = widgetsList.map(obj => {
      if (obj.type === key) {
        obj.configuration.yAxisLabel = "";
        if (key === monitorConst.USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES) {
          obj.data = data;
        } else {
          data && data.length > 0
            ? key === monitorConst.RECENT_10_JOBS_IN_PROGRESS
              ? (obj.data = data)
              : dataWithConfiguration(obj, data)
            : (obj.data = []);
        }
        if (["job_duration"].indexOf(filter) !== -1) {
          let chartData = util.convertedDataForChart(
            obj.data,
            CHART_DATA_TYPE.DURATION
          );
          obj.data = chartData.data;
          obj.configuration.yAxisLabel = chartData.yAxisLabel;
        } else if (
          ["data_transferred", CHART_DATA_TYPE.BYTES].indexOf(filter) !== -1
        ) {
          let dataSet = obj.data;
          if (key === monitorConst.USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES) {
            dataSet = obj.data.usage;
          }

          let convertedData = obj.data;
          if (key === monitorConst.USAGE_TRENDS_FOR_CLOUD_DIRECT_VOLUMES) {
            let chartData = util.convertedDataForChart(
              dataSet,
              CHART_DATA_TYPE.BYTES
            );
            convertedData.usage = chartData.data;
            obj.configuration.yAxisLabel = chartData.yAxisLabel;
          } else {
            let chartData = util.convertedDataForChart(
              dataSet,
              CHART_DATA_TYPE.DT_BYTES
            );
            convertedData = chartData.data;
            obj.configuration.yAxisLabel = chartData.yAxisLabel;
          }
          obj.data = convertedData;
        }
      }
      return obj;
    });
    return widgets;
  }

  function dataWithConfiguration(obj, data) {
    let colorSchema = obj.configuration.colorSchema;
    if (!colorSchema) {
      colorSchema = util.colorSchema(data, ColorCodes, obj.key);
    }

    return Object.assign(obj, {
      data: util.translateTextFromConstants(data, obj.key),
      configuration: {
        ...obj.configuration,
        colorSchema: colorSchema
      }
    });
  }

  /**
   * Format summary data
   */
  function generateSummaryData(widgetsList, key, data, arrayKey, filter) {
    let tempArray = [];
    let yAxisLabel = "";
    let KeyMappingDataSummary = {
      processed_bytes: "data_processed",
      transferred_bytes: "data_transferred",
      written_bytes: "data_written",
      total_dedupe_saving: "dedupe_savings",
      total_source_data: "source_data"
    };
    let obj = {
      averagedata: [],
      dataByTopic: data[arrayKey]
    };
    let legends = [];
    // Generate dynamic object to render legends
    let labels = [];
    for (let node in data) {
      if (typeof data[node] !== "object") {
        // delete data.total_dedupe_saving;
        if (
          arrayKey === monitorConst.dedupe_savings_summary ||
          arrayKey === "total_dedupe_saving"
        ) {
          labels.push(data[node]);
        }
        legends.push({
          label: KeyMappingDataSummary[node],
          value: util.formatBytes(data[node])
        });
      }
    }

    /*if (arrayKey === monitorConst.dedupe_savings_summary) {
      if (labels.length) {
        let percentage = (
          Math.min(...labels) /
          Math.max(...labels) *
          100
        ).toFixed(2);
        obj.averagedata.push({
          value: isNaN(percentage) ? 0 + "%" : percentage + "%",
          label: monitorConst.dedupe_savings
        });
      }
    }*/

    data[arrayKey] &&
      //eslint-disable-next-line array-callback-return
      data[arrayKey].map((node, i) => {
        let dataSet = [];
        if ([CHART_DATA_TYPE.BYTES].indexOf(filter) !== -1) {
          let chartData = util.convertedDataForChart(
            node.date_value,
            CHART_DATA_TYPE.BYTES
          );
          dataSet = chartData.data;
          yAxisLabel = chartData.yAxisLabel;
        } else {
          dataSet = node.date_value;
        }
        Object.assign(node, {
          topicName: ConvertText[node.topic_name],
          dates: dataSet
        });

        obj.averagedata.push(
          _.find(legends, item => item.label === node.topic_name)
        );
      });

    tempArray.push(obj);

    let widgets = widgetsList.map(node => {
      if (node.type === key) {
        tempArray.length ? (node.data = tempArray) : []; // eslint-disable-line no-unused-expressions
        node.configuration.yAxisLabel = yAxisLabel;
      }
      return node;
    });

    return widgets;
  }
};

function clearWidgetState(list, key = "") {
  if (list && Array.isArray(list)) {
    //eslint-disable-next-line array-callback-return
    list.map(res => {
      if (key !== "" && key === LOG_OUT) {
        if (
          res.options &&
          res.options.filterOptions &&
          res.options.filterOptions.length > 0
        ) {
          res.options.selectedOption = res.options.filterOptions[0].value;
        }
        res.show = true;
      }
      res.data = [];
    });
    return [...list];
  }
}
export default monitorReducer;
