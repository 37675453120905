import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { ID_PREFIX } from "configs/IdConfig";
import { DARK_MODE } from "utils/theme";

/**
 * Reusable component to render button
 */
const ButtonComponent = props => {
  const {
    className,
    color,
    content,
    disabled,
    hidden,
    id,
    testId,
    onClick,
    style,
    type,
    variant,
    size,
    endIcon
  } = props;
  let buttonId = testId ? `${ID_PREFIX}${testId}` : id ? id : "";
  return (
    <Button
      className={`${className} ${
        size === "large"
          ? "button-large"
          : size === "small"
          ? "button-small"
          : "button-medium"
      }`}
      disabled={disabled}
      color={color}
      hidden={hidden}
      id={buttonId}
      onClick={onClick}
      style={{
        textTransform: "none",
        whiteSpace: "nowrap",
        ...style
      }}
      type={type}
      sx={{
        // Outline will not increase the width of button(unlike border)
        outline: theme =>
          theme?.palette?.mode === DARK_MODE &&
          disabled &&
          variant == "contained" &&
          color !== "secondary" // Not required for other varients
            ? "1px solid rgba(255,255,255,0.38)"
            : ""
      }}
      variant={color !== "secondary" ? variant : "outlined"}
      size={size}
      endIcon={endIcon}
    >
      {content}
    </Button>
  );
};

ButtonComponent.defaultProps = {
  className: "",
  disabled: false,
  hidden: false,
  style: {},
  type: "button",
  variant: "default",
  size: "medium"
};

ButtonComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  hidden: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string
};

export default ButtonComponent;
