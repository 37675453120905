import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class AsyncComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      Component: null
    };
  }

  componentDidMount() {
    if (!this.state.Component) {
      this.props
        .moduleProvider()
        .then(({ Component }) => this.setState({ Component }));
    }
  }

  render() {
    const { Component } = this.state;
    const Loader = () => <div className="bundle_loading" />;
    return (
      <div className="async-wrapper">
        {Component ? <Component {...this.props} /> : Loader()}
      </div>
    );
  }
}

AsyncComponent.propTypes = {
  moduleProvider: PropTypes.func
};
