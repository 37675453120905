import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import IconMUI from "components/common/icon/IconMUI";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CheckIcon from "@mui/icons-material/Check";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ID_PREFIX } from "configs/IdConfig";

function TabPanel(props) {
  const { children, value, index, style, ...other } = props;
  const tabContentStyle = children?.props?.tabContentStyle || {};
  return (
    <Paper
      elevation={0}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      style={tabContentStyle}
    >
      <Box
        style={
          style?.customTabComponentStyle
            ? {
                ...style.customTabComponentStyle,
                visibility: index === value ? "visible" : "hidden"
              }
            : {
                visibility: index === value ? "visible" : "hidden"
              }
        }
        p={3}
      >
        <Typography variant="div">{children}</Typography>
      </Box>
    </Paper>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const WizardTab = props => {
  const {
    vertical,
    tabOptions,
    headerButtons,
    disabled,
    selectedTab,
    routeLink,
    detailsTab,
    tabIndex,
    customStyle,
    useTabIndex = false,
    customStyleObject,
    history
  } = props;

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    let index = tabOptions.findIndex(tab => tab.tabkey === selectedTab);
    index = index < 0 ? 0 : index;
    setValue(index);
  }, [selectedTab, tabOptions.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabClick = tab => {
    if (props.handleTabClick) {
      props.handleTabClick(tab);
    }
  };

  const handleMUITabClick = (tabkey, searchKey) => {
    history.push(routeLink + "/" + tabkey.toLowerCase().replace(/\s/g, ""));
    handleTabClick(searchKey);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * to render tab items
   * @param {array of object} data
   * @return html for tabs
   */
  //TODO refactor NavLink for disabled and enabled
  const renderTabItem = data => {
    return data.map((item, index) => (
      <Tab
        id={`${ID_PREFIX}${item.testId}`}
        eventKey={item.tabkey}
        key={index}
        disabled={disabled || item.disabled}
        className={item.chevron && item.tabkey === selectedTab ? "active" : ""}
        onClick={() => handleMUITabClick(item.tabkey, item.searchKey)}
        label={
          !item.disabled ? (
            <>
              {item.tab}
              {item.icon && item.tabkey !== selectedTab ? (
                <IconMUI
                  icon={CheckIcon}
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: "10px",
                    margin: 0
                  }}
                />
              ) : (
                void 0
              )}
              {item.chevron && item.tabkey === selectedTab ? (
                <IconMUI
                  icon={ChevronRightOutlinedIcon}
                  sx={{
                    position: "absolute",
                    right: "10px",
                    margin: 0
                  }}
                />
              ) : (
                void 0
              )}
              {detailsTab ? <label>{item.details}</label> : ""}
            </>
          ) : (
            <>
              {item.tab}
              {item.icon && item.tabkey !== selectedTab ? (
                <IconMUI
                  icon={CheckIcon}
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: "10px",
                    margin: 0
                  }}
                />
              ) : (
                void 0
              )}
              {item.chevron && item.tabkey === selectedTab ? (
                <IconMUI
                  icon={ChevronRightOutlinedIcon}
                  sx={{
                    position: "absolute",
                    right: "10px",
                    margin: 0
                  }}
                />
              ) : (
                void 0
              )}
              {detailsTab ? <label>{item.details}</label> : ""}
            </>
          )
        }
        style={{ textTransform: "none" }}
      />
    ));
  };

  const renderTabContent = data => {
    return data.map((item, index) => {
      let key = `tabPane_${index}`;
      return (
        <TabPanel
          key={key}
          value={useTabIndex ? tabIndex : value}
          index={index}
          style={customStyleObject ? customStyleObject : customStyle}
        >
          {item.content}
        </TabPanel>
      );
    });
  };

  let attrs = {};
  tabIndex && (attrs["activeKey"] = tabIndex);
  const className = props.className || "";
  return (
    <Paper elevation={0} square style={props.paperContentStyle || {}}>
      <Tabs
        orientation={vertical ? "vertical" : "horizontal"}
        value={useTabIndex ? tabIndex : value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        // sx={{
        //   borderBottom: theme =>
        //     theme?.palette?.mode === DARK_MODE
        //       ? "1px solid rgba(255,255,255,0.1)"
        //       : "none"
        // }}
        className={`${className} mui-tab-header`}
      >
        {renderTabItem(tabOptions)}
        {headerButtons}
      </Tabs>
      {renderTabContent(tabOptions)}
    </Paper>
  );
};

WizardTab.defaultProps = {
  vertical: false,
  animation: true,
  detailsTab: false,
  disabled: false,
  headerButtons: null,
  defaultTab: 0,
  tabIndex: 0,
  customStyleObject: {},
  tabOptions: []
};

WizardTab.propTypes = {
  vertical: PropTypes.bool.isRequired,
  tabOptions: PropTypes.array.isRequired,
  animation: PropTypes.bool,
  detailsTab: PropTypes.bool,
  handleTabClick: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  routeLink: PropTypes.string,
  selectedTab: PropTypes.string,
  headerButtons: PropTypes.element
};

export default withRouter(WizardTab);
