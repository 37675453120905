import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import SpogBreadcrumb from "components/common/breadcrumb";
import {
  getAlertList,
  toggleAlertsPanel,
  selectMenuAction,
  setHasGoBackNavigation,
  updateAlertListCount,
  resetAlertListCount,
  unsetJWTToken
} from "state/actions/actions";
import IconMUI from "components/common/icon/IconMUI";
import * as constant from "utils/appConstants";
import { DARK_MODE } from "utils/theme";
import Alerts from "components/common/alerts";
import { getBrowserLocale } from "utils/SpogDataGridUtil";
import FormattedMessage from "components/common/formatted-message";
import _ from "lodash";
import { getLogin } from "state/selectors";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { isCSRUser } from "utils";
import { ID_PREFIX } from "configs/IdConfig";
import { handleLinkColor } from "state/selectors/ui";
import { PAGE_ROUTE } from "../../utils/appConstants";
import LeftNavBar from "../navigator-mui";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleCollapseSideBar = this.handleCollapseSideBar.bind(this);
    this.handleMSPHomeClick = this.handleMSPHomeClick.bind(this);
    this.handleToggleAlertsPanel = this.handleToggleAlertsPanel.bind(this);
    this.updateStateOnClick = this.updateStateOnClick.bind(this);
  }

  componentDidMount = () => {
    const { getAlertList } = this.props;
    getAlertList();
  };

  componentDidUpdate(prevProps) {
    const {
      alertsList,
      updateAlertListCount,
      isImpersonationView
    } = this.props;
    if (alertsList !== prevProps.alertsList) {
      clearTimeout(this.timeout);
      this.startPoll();
      const newlen = alertsList.data.length - prevProps.alertsList.data.length;
      updateAlertListCount(newlen);
    } else if (isImpersonationView !== prevProps.isImpersonationView) {
      prevProps.getAlertList();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleTabChange(event, newValue) {
    const { roleId, history } = this.props;
    if (newValue === 1) {
      if (roleId && roleId.includes("msp")) {
        history.push("/protect/customer-accounts/all");
      } else {
        history.push("/protect/sources/all");
      }
    } else if (newValue === 2) {
      history.push("/analyze/jobs/all");
    } else if (newValue === 3) {
      history.push("/configure/infrastructure/hypervisors");
    } else if (newValue === 0) {
      history.push("/");
    }
  }

  handleCollapseSideBar() {
    const { handleCollapseSideBar, collapseSideBar } = this.props;
    handleCollapseSideBar(collapseSideBar);
  }

  handleToggleAlertsPanel() {
    const {
      toggleAlertsPanel,
      toggleAlertsList,
      resetAlertListCount
    } = this.props;
    toggleAlertsPanel(toggleAlertsList);
    resetAlertListCount();
  }

  handleMSPHomeClick() {
    const { handleHomeMSPHomeClick } = this.props;
    handleHomeMSPHomeClick();
  }

  handleHelpClick = () => {
    const locale = getBrowserLocale();
    const url = `https://arcserve.zendesk.com/hc/${locale.toLowerCase()}`;
    window.open(url, "_blank").focus();
  };

  // eslint-disable-next-line class-methods-use-this
  updateStateOnClick(secondaryKey, secondaryNavigation) {
    // secondaryKey && this.handleSelectFilter(secondaryNavigation, secondaryKey);
    // this.updateResourceName(null);
  }

  startPoll() {
    const { getAlertList } = this.props;
    // poll for alerts every 5 mins.
    const pollingAction = true;
    this.timeout = setTimeout(() => {
      getAlertList(pollingAction);
    }, 300000);
  }

  render() {
    const {
      primaryNavigation,
      secondaryNavigation,
      primaryKey,
      secondaryKey,
      resourceName,
      getTheme,
      toggleAlertsList,
      isImpersonationView,
      loginInfo,
      roleId,
      orgType,
      userType,
      location,
      selectedSourceGroup,
      currentDataStoreId,
      alertListCount,
      locale,
      history,
      alertsList,
      handleSelectFilter,
      linkColor
    } = this.props;
    const theme = getTheme;
    const alertColor =
      primaryNavigation === "Monitor" || toggleAlertsList
        ? theme.palette
          ? theme.palette.primary.main
          : "#6200EE"
        : getTheme.palette?.mode === DARK_MODE
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.6)";

    const isDarkTheme = theme && theme.palette && theme.palette.mode === "dark";
    const customerOrgName = _.get(
      this.props,
      "organizationInfo.organization_name",
      ""
    );
    const parentOrgName = _.get(
      this.props,
      "parentOrganizationInfo.organization_name"
    );
    const monitorName = _.get(this.props, "customerOrgName", customerOrgName);

    // Populate Primary level breadcrumb.
    let breadcrumbItems =
      isImpersonationView &&
      !["My Profile", "Support"].includes(primaryNavigation)
        ? [
            loginInfo &&
            loginInfo.role_id.includes("msp") &&
            secondaryNavigation &&
            secondaryNavigation === "customer-accounts"
              ? {
                  name: primaryNavigation,
                  url: "/protect/customer-accounts/all",
                  key: primaryKey,
                  navigationKey: "Customer Accounts",
                  navigation: "customer-accounts"
                }
              : {
                  name: primaryNavigation,
                  url:
                    constant.PRIMARY_NAVIGATION_URL_MAPPING[primaryNavigation],
                  key:
                    primaryNavigation ===
                      constant.SECONDARY_NAVIGATION.MONITOR && monitorName
                      ? monitorName
                      : primaryKey,
                  navigationKey:
                    constant.SECONDARY_NAVIGATION_PARENT_MAPPING[
                      primaryNavigation
                    ],
                  navigation:
                    constant.SECONDARY_NAVIGATION_MAPPING[primaryNavigation]
                }
          ]
        : [
            secondaryNavigation && secondaryNavigation === "customer-accounts"
              ? {
                  name: primaryNavigation,
                  url: "/protect/customer-accounts/all",
                  key: primaryKey,
                  navigationKey: "Customer Accounts",
                  navigation: "customer-accounts"
                }
              : {
                  name: primaryNavigation,
                  url:
                    roleId && roleId.includes("msp")
                      ? constant.PRIMARY_NAVIGATION_URL_MAPPING_MSP_LEVEL[
                          primaryNavigation
                        ]
                      : constant.PRIMARY_NAVIGATION_URL_MAPPING[
                          primaryNavigation
                        ],
                  key:
                    primaryNavigation ===
                      constant.SECONDARY_NAVIGATION.MONITOR && customerOrgName
                      ? customerOrgName
                      : primaryKey,
                  navigationKey:
                    roleId &&
                    roleId.includes("msp") &&
                    primaryNavigation ===
                      constant.SECONDARY_NAVIGATION.CONFIGURE
                      ? constant.SECONDARY_NAVIGATION_PARENT_MAPPING[
                          constant.SECONDARY_NAVIGATION.user_accounts
                        ]
                      : constant.SECONDARY_NAVIGATION_PARENT_MAPPING[
                          primaryNavigation
                        ],
                  navigation:
                    constant.SECONDARY_NAVIGATION_MAPPING[primaryNavigation]
                }
          ];

    if (primaryNavigation === constant.SECONDARY_NAVIGATION.MONITOR) {
      if (
        isImpersonationView &&
        isCSRUser(roleId) &&
        _.get(this.props, "orgType", "").includes("msp_child")
      ) {
        breadcrumbItems.unshift({
          name: _.get(this.props, "brandData.organization_name", "Arcserve"),
          url: `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`,
          key: _.get(this.props, "brandData.organization_name", "Arcserve"),
          navigationKey: null,
          navigation: null
        });
        breadcrumbItems.unshift({
          name: parentOrgName,
          url: "/csr",
          key: parentOrgName,
          navigationKey: null,
          navigation: null
        });
      } else if (isImpersonationView) {
        breadcrumbItems.unshift({
          name: parentOrgName,
          url: !isCSRUser(roleId)
            ? `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`
            : "/csr",
          key: parentOrgName,
          navigationKey: null,
          navigation: null
        });
      }
    }

    // If secondary Navigation is selected, Populate secondary level Bread crumb.

    if (secondaryNavigation) {
      // Whenever we impersonate and have secondary navigation then adding intial monitor breadcrumb level.
      userType !== "external" &&
        orgType === "msp_child" &&
        breadcrumbItems.unshift({
          name: monitorName,
          url: "/monitor",
          key: monitorName,
          navigationKey: null,
          navigation: null
        });
      isImpersonationView &&
        breadcrumbItems.unshift({
          name: parentOrgName,
          url: !isCSRUser(roleId)
            ? `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`
            : "/csr",
          key: parentOrgName,
          navigationKey: null,
          navigation: null
        });
      if (isCSRUser(roleId) && orgType === "msp_child") {
        breadcrumbItems.splice(1, 0, {
          name: _.get(this.props, "csrCustomerOrgName", "Arcserve"),
          url: `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`,
          key: _.get(this.props, "csrCustomerOrgName", "Arcserve"),
          navigationKey: "Customer Accounts",
          navigation: "customer-accounts"
        });
      }
      if (constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation]) {
        // If secondary navigation selection is not a filter type,
        // (Child of Filter type) get the parent for the selected second level Navigation
        // eg: Sources/Destinations/Infrastructure/Recovered resources...
        let url;
        if (
          constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation] ===
            "Destinations" &&
          this.props.isCDActive === false
        ) {
          url = `${constant.PAGE_ROUTE["RECOVERY_POINT_SERVER"]}`;
        } else {
          url = `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_PARENT_URL_MAPPING[secondaryNavigation]}`;
        }

        breadcrumbItems = breadcrumbItems.concat({
          name:
            constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation],
          url,
          key:
            constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryNavigation],
          navigationKey:
            constant.SECONDARY_NAVIGATION_PARENT_MAPPING[secondaryKey],
          navigation: constant.SECONDARY_NAVIGATION_MAPPING[secondaryKey]
        });
        // Bread crumb for selected secondary navigation
        let secondaryNavigationUrl;
        if (secondaryKey === "DRaaS") {
          secondaryNavigationUrl = `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_PARENT_URL_MAPPING["recovered_vms"]}`;
        } else {
          secondaryNavigationUrl = `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_PARENT_URL_MAPPING[secondaryKey]}/${secondaryNavigation}`;
        }
        breadcrumbItems = breadcrumbItems.concat({
          name: secondaryKey,
          url: secondaryNavigationUrl,
          key: secondaryKey,
          navigationKey: secondaryKey,
          navigation: secondaryNavigation
        });
      }
      // Fixing recovered vms navigation TFS Bug:897230
      // currently parent navigation of recovered_vms is not a clickable link unlike sources/physical_machines
      // when recovered resources becomes a clickable link then we would need to modify breadcrumb to add  parent_url_mapping
      // else if (
      //   secondaryNavigation === constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS
      // ) {
      //   breadcrumbItems = breadcrumbItems.concat({
      //     name: secondaryKey,
      //     url: `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES}/${secondaryNavigation}`,
      //     key: secondaryKey,
      //     navigationKey: secondaryKey,
      //     navigation: secondaryNavigation
      //   });
      // }
      else if (
        secondaryNavigation ===
        constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD
      ) {
        breadcrumbItems = breadcrumbItems.concat({
          name: secondaryKey,
          url: `/${primaryNavigation}/${constant.SECONDARY_NAVIGATION_TYPE.DESTINATIONS}/${secondaryNavigation}`,
          key: secondaryKey,
          navigationKey: secondaryKey,
          navigation: secondaryNavigation
        });
      } else {
        // If secondary navigation selection is a filter type,
        breadcrumbItems = breadcrumbItems.concat({
          name: secondaryKey,
          url: `/${primaryNavigation}/${secondaryNavigation}/all`,
          key: secondaryKey,
          navigationKey: secondaryKey,
          navigation: secondaryNavigation
        });
      }
    }
    if (
      primaryKey === "configure" &&
      secondaryKey === "Source Group" &&
      location.pathname
        .split("/")
        .includes(constant.SECONDARY_NAVIGATION_TYPE.ADD_SOURCES)
    ) {
      breadcrumbItems = breadcrumbItems.concat({
        name: _.get(selectedSourceGroup, "group_name.label"),
        url: `/${primaryNavigation}/${secondaryNavigation}/${_.get(
          selectedSourceGroup,
          "group_id"
        )}/details#`,
        key: _.get(selectedSourceGroup, "group_name.label")
      });
      breadcrumbItems = breadcrumbItems.concat({
        name: constant.ADD_SOURCES_TO_GROUP,
        url: `/${primaryNavigation}/${secondaryNavigation}/${_.get(
          selectedSourceGroup,
          "group_id"
        )}/${constant.SECONDARY_NAVIGATION_TYPE.ADD_SOURCES_TO_GROUP}`,
        key: constant.ADD_SOURCES_TO_GROUP,
        navigationKey: constant.ADD_SOURCES_TO_GROUP,
        navigation: constant.SECONDARY_NAVIGATION_TYPE.ADD_SOURCES
      });
    }

    if (resourceName) {
      if (
        primaryKey === "Protect" &&
        secondaryKey === "Data Stores" &&
        location.pathname.split("/").includes("sources")
      ) {
        breadcrumbItems = breadcrumbItems.concat({
          name: resourceName,
          url: `${PAGE_ROUTE.DATA_STORE}/${currentDataStoreId}/information`,
          key: resourceName
        });
      } else if (
        primaryKey === "Protect" &&
        secondaryKey === "Shared Folders" &&
        location.pathname.split("/").includes("sources")
      ) {
        breadcrumbItems = breadcrumbItems.concat({
          name: resourceName,
          url: `#`,
          key: resourceName,
          class: "no-transform",
          notClickable: true
        });
      } else {
        breadcrumbItems = breadcrumbItems.concat({
          name: resourceName,
          url: "#",
          key: resourceName,
          class: "no-transform"
        });
      }
    }

    if (
      primaryKey === "Protect" &&
      (secondaryKey === "Data Stores" || secondaryKey === "Shared Folders") &&
      location.pathname.split("/").includes("sources")
    ) {
      breadcrumbItems = breadcrumbItems.concat({
        name: constant.BROWSE_RECOVERY_POINTS,
        url: "#",
        key: constant.BROWSE_RECOVERY_POINTS,
        class: "no-transform"
      });
    }

    if (secondaryNavigation === "Downloads") {
      breadcrumbItems = [
        {
          name: primaryNavigation,
          url: "/user_support",
          key: primaryNavigation,
          navigationKey: primaryKey,
          navigation: primaryNavigation
        },
        {
          name: secondaryNavigation,
          url: "/user_support/downloads",
          key: secondaryNavigation,
          navigationKey: secondaryNavigation,
          navigation: secondaryNavigation
        }
      ];
    }

    const showCollapsableIcon =
      secondaryNavigation !== "customer-accounts" &&
      primaryNavigation !== "Monitor" &&
      primaryNavigation !== "My Profile" &&
      primaryNavigation !== "Support";

    const paddingTop = isImpersonationView ? 0 : 0;

    return (
      <>
        <Paper
          square
          className="header-paper-wrapper"
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            zIndex: "+11",
            paddingTop
          }}
        >
          <LeftNavBar {...this.props} />
          <Nav
            variant="pills"
            style={{ minHeight: "auto", border: "none" }}
            className={`
              header-left-sidebar
              ${
                showCollapsableIcon
                  ? "justify-content-between"
                  : "justify-content-between noCollapseIcon"
              }
            `}
          >
            {" "}
            <Nav.Item className="d-flex breadcrumb-wrapper"></Nav.Item>
            <Nav.Item>
              <div
                className="header-right-icons-panel d-flex align-items-center"
                id="alertTabs"
              >
                {primaryNavigation !== "Monitor" && (
                  <Badge
                    badgeContent={
                      toggleAlertsList ||
                      primaryNavigation === "Monitor" ||
                      alertListCount < 0
                        ? 0
                        : alertListCount
                    }
                    color="error"
                    role="button"
                    onClick={this.handleToggleAlertsPanel}
                    disabled={primaryNavigation === "Monitor"}
                    id={`${ID_PREFIX}oxTTS7`}
                    sx={{ "& .MuiBadge-badge": { marginTop: 0.5 } }}
                    style={{
                      display: "flex",
                      textTransform: "none",
                      alignItems: "center",
                      flexDirection: "column",
                      color: alertColor,
                      marginLeft: 20,
                      fontFamily: "Sans Source Pro Medium",
                      fontSize: 15
                    }}
                    max={9}
                  >
                    <IconMUI
                      icon={WarningAmber}
                      style={{
                        height: 30,
                        width: 30,
                        marginBottom: 6
                      }}
                      id="PrimaryNav-Alert"
                    />
                    <FormattedMessage id="Alerts" defaultMessage="Alerts" />
                  </Badge>
                )}
              </div>
              {primaryNavigation === "Monitor" ? (
                <Alerts
                  locale={locale}
                  testId="31ERCp"
                  show
                  history={history}
                  alertsList={alertsList}
                  handleSelectFilter={handleSelectFilter}
                  linkColor={linkColor}
                  isMonitor
                />
              ) : (
                <Alerts
                  locale={locale}
                  testId="31ERCp"
                  show={toggleAlertsList}
                  history={history}
                  alertsList={alertsList}
                  handleSelectFilter={handleSelectFilter}
                  linkColor={linkColor}
                />
              )}
            </Nav.Item>
          </Nav>
        </Paper>
        <div
          style={{
            borderBottom: isDarkTheme
              ? "1px solid rgba(255, 255, 255, 0.1)"
              : "1px solid lightgrey"
          }}
        >
          <SpogBreadcrumb
            items={breadcrumbItems}
            updateStateOnClick={this.updateStateOnClick}
            {...this.props}
          />
        </div>
      </>
    );
  }
}

Header.defaultProps = {
  alertsList: [],
  currentDataStoreId: "",
  roleId: "",
  getTheme: {},
  isImpersonationView: false,
  loginInfo: {},
  orgType: "",
  userType: "",
  selectedSourceGroup: {},
  alertListCount: 0,
  secondaryNavigation: "",
  secondaryKey: "",
  collapseSideBar: false,
  resourceName: "",
  customerOrgName: ""
};

Header.propTypes = {
  primaryNavigation: PropTypes.string.isRequired,
  secondaryNavigation: PropTypes.string,
  primaryKey: PropTypes.string.isRequired,
  secondaryKey: PropTypes.string,
  collapseSideBar: PropTypes.bool,
  handleCollapseSideBar: PropTypes.func.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  handleSelectMenu: PropTypes.func.isRequired,
  handleHomeMSPHomeClick: PropTypes.func.isRequired,
  resourceName: PropTypes.string,
  customerOrgName: PropTypes.string,
  isImpersonationView: PropTypes.bool,
  alertsList: PropTypes.shape(),
  getAlertList: PropTypes.func.isRequired,
  selectMenuAction: PropTypes.func.isRequired,
  toggleAlertsPanel: PropTypes.func.isRequired,
  toggleAlertsList: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  currentDataStoreId: PropTypes.string,
  updateAlertListCount: PropTypes.func.isRequired,
  roleId: PropTypes.string,
  resetAlertListCount: PropTypes.func.isRequired,
  getTheme: PropTypes.shape({
    palette: PropTypes.shape({
      mode: PropTypes.string
    })
  }),
  loginInfo: PropTypes.shape({
    // eslint-disable-next-line camelcase
    role_id: PropTypes.string
  }),
  orgType: PropTypes.string,
  userType: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  selectedSourceGroup: PropTypes.shape({}),
  alertListCount: PropTypes.number,
  locale: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired
};

/**
 * Maps state to the props of the component in React Redux connection
 * @param state
 */
const mapStateToProps = state => ({
  getTheme: state.reducer.theme,
  alertsList: state.monitorReducer.alertsList,
  alertListCount: state.monitorReducer.alertListCount,
  toggleAlertsList: state.reducer.toggleAlertsList,
  hasGoBackNavigation: state.reducer.hasGoBackNavigation,
  organizationInfo: state.login.organizationInfo,
  parentOrganizationInfo: state.login.parentOrganizationInfo,
  loginInfo: getLogin(state),
  selectedSourceGroup: state.configureReducer.selectedSourceGroup,
  brandData:
    state &&
    state.configureReducer &&
    state.configureReducer &&
    state.configureReducer.brandData
      ? state.configureReducer.brandData
      : {},
  linkColor: handleLinkColor(state),
  locale: state?.login?.locale || "",
  csrCustomerOrgName: state.reducer.csrCustomerOrgName,
  currentDataStoreId: state.destinations.dataStores.currentDataStoreId,
  userType: state.login.userDetails.user_type
});

/**
 * Bind the dispatch to the function for Redux action dispatcher
 * @param dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAlertList,
      toggleAlertsPanel,
      selectMenuAction,
      setHasGoBackNavigation,
      updateAlertListCount,
      resetAlertListCount,
      unsetJWTToken
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
