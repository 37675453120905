import {
  select,
  all,
  call,
  put,
  takeEvery,
  fork,
  race,
  take
} from "redux-saga/effects";
import * as ccDataGridActions from "state/actions/ccDataGrid";
import { reset } from "redux-form";
import _ from "lodash";
import {
  getLogin,
  getLoginToken,
  getCDLoginToken,
  getOrgId,
  getReducer
} from "state/selectors";
import * as constant from "utils/appConstants";
import {
  clearStateSelectedRows,
  startLoader,
  stopLoader,
  addAPIresponse,
  updateAdvancedSearchOptions,
  updateTagsForCurrentSearch,
  startGridLoaderById,
  stopGridLoaderById,
  updateDSConnectStatus
} from "state/actions/CommonActions";
import * as ccToast from "state/actions/ccToast";
import {
  getRpsPagination,
  getCurrentRps
} from "state/selectors/destinations/rps";
import { gridCurrentPage, gridPageSize } from "state/selectors/HomeSelectors";
import {
  searchText,
  advancedSearchOptions
} from "state/selectors/CommonSelectors";
import { getDataStoresPagination } from "state/selectors/destinations/datastores";
import {
  getRpsHashMemory,
  remoteConsoleListSuccess,
  sharedFolderListSuccess,
  fetchLightWeightProxiesSuccess,
  storesharedFolderLocalData,
  deleteRemoteConsoleSuccess,
  deleteSharedFolderSuccess,
  sharedFolderList as getSharedFolderList,
  loadRpsListSuccess,
  loadDataStoresSuccess,
  hideDialog,
  Dialog,
  setLoading,
  resetLoading,
  rpsDataStoreListSuccess,
  rpsDataStoreSourceListSuccess,
  rpsDataStoreSourceListFailure,
  connectSharedFolderSuccess,
  onSessionPasswordApplySuccess,
  onSessionPasswordApplyFailure,
  connectSharedFolderFailure,
  getPolicyListSuccess,
  Loader,
  refreshRpsSuccess,
  loadDataStores,
  importDataStoreTemp,
  importCloudDataStoreTemp,
  cloudAccountListByRPSIdSuccess,
  endPointAccountListSuccess,
  setCurrentRps,
  setCurrentDataStore,
  setCurrentSharedFolder,
  setDataStoreEncryptionEnabled,
  cloudAccountListSuccess,
  shadowXafeListSuccess,
  refreshDataStoreSuccess,
  updateMenuCountsOnAddRemoveActions,
  onClearForm,
  getProtectListCount,
  clearDataStores,
  clearRpsList,
  saveJumpStartError,
  showDialog,
  authenticationConnectSuccess,
  updateRecoveryConnectStatus,
  authenticationConnectFailure,
  clearAuthConnection,
  getBrowseRecoveryPointDSListSuccess,
  getBrowseRecoveryPointSharedFolderListSuccess,
  onPolicyDataGetSuccess,
  onGetRecoveryPointsByDestinationPath,
  getRecoveryPointResponse,
  oracleRecoverDBConfigDataSuccess,
  storeOracleRecoveryVersionSuccess,
  clearOracleRecoverDBConfigFailure,
  storeOraclePDBList,
  storeRecoveryOracleDBSettings,
  clearOraclePDBList,
  onGetDestinationsBySourceId,
  updateAvailableDriveLetters,
  updateAgentMachineSourcesList,
  getAdvancedSearchOptions,
  showCurrentSearch,
  loadAllRpsList,
  // getCloudAccountListByTypeSuccess,
  getRegionsForCloudAccountSuccessV3,
  getRegionsForCloudAccountSuccess,
  getComputeRegionsForGCPCloudAccountSuccess,
  getComputeZonesForGCPCloudAccountSuccess,
  GCPSecurityGroupsFetchSuccess,
  clearGCPSecurityGroups,
  getResourceGroupForCloudAccountSuccess,
  clearCloudAccRegions,
  clearGCPCloudAccComputeRegions,
  clearGCPCloudAccComputeZones,
  getBrowseRecoveryPointsDS,
  getBrowseRecoveryPointSharedFolder,
  setRetention,
  getBucketsForCloudAccountSuccess,
  storeRecoveryOracleDBTableSettings,
  clearRecoveryOracleDBSettings,
  clearRecoveryOracleDBTableSettings,
  storeSQLInstanceData,
  updateGridCurrentPageSize,
  getAntiVirusDetailsForAssuredSecurityScanSuccess,
  clearAntiVirusDetailsForAssuredSecurityScan,
  getReplicationTasksForPolicySuccess,
  clearReplicationTasksForPolicy,
  getReplicationTasksForSourceSuccess,
  clearReplicationTasksForSource
  // ,
  // onGetRecoveryPointsSuccess
} from "../../actions/actions";
import {
  getRecoveryPointResponseForVsb,
  onGetRecoveryPointsByDestinationPathForVsb
} from "state/actions/createStandbyVM";
import {
  selectedSource,
  rpEncryptionPassword
} from "state/selectors/destinations/recovery";
import { destinationApis, protectApi } from "../../api";
import * as actionType from "../../actions/actionTypes";
import * as createStandbyVMConstants from "state/constants/createStandbyVM";
import * as protectAction from "../../actions/ProtectActions";
import { takeLatest } from "redux-saga";
import { makeToastData } from "../sagautils";
import { CCDATAGRID_ID } from "utils/appConstants";
import { getDialogVisibility } from "../../selectors/ui";

const getProtectReducer = state => state.protectReducer;

export function* remoteConsoleList({ type, page, pageSize }) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    const resp = yield call(
      destinationApis.getRemoteConsoleList,
      token,
      `page=${page}&page_size=${pageSize}&organization_id=${organizationId}`
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(remoteConsoleListSuccess(resp.data, resp.pagination));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* sharedFolderList({ type, page, pageSize }) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    const resp = yield call(
      destinationApis.getSharedFolderList,
      token,
      `page=${page}&page_size=${pageSize}&organization_id=${organizationId}&sort=path`
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(sharedFolderListSuccess(resp.data, resp.pagination));
      yield put(storesharedFolderLocalData(resp.data));
      let responsePageSize = _.get(resp, "pagination.page_size", 25);
      yield put(updateGridCurrentPageSize(responsePageSize));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* getLightWeightProxies() {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    const response = yield call(
      destinationApis.getLightWeightProxies,
      token,
      `organization_id=${organizationId}`
    );

    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(fetchLightWeightProxiesSuccess(response.data));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteRemoteConsole({ type, id }) {
  try {
    const token = yield select(getLoginToken);

    const resp = yield call(destinationApis.deleteRemoteConsole, token, id);

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(deleteRemoteConsoleSuccess(id));
      yield put(clearStateSelectedRows());
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* deleteSharedFolder({ id }) {
  try {
    const token = yield select(getLoginToken);

    const resp = yield call(destinationApis.deleteSharedFolder, token, id);

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.share-folder.delete-success", "zr9Uz3")
        )
      );
      yield put(deleteSharedFolderSuccess(id));
      yield put(clearStateSelectedRows());
      yield put(getProtectListCount());
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `DKHQXx_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}
export function* updateSharedFolder({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.ADD_REMOTE_CONSOLE));
    const resp = yield call(
      destinationApis.updateSharedFolder,
      data,
      token,
      data.share_folder_id
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getSharedFolderList());

      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.share-folder.update-success", "FlgvAK")
        )
      );
      yield put(clearStateSelectedRows());
      yield put(resetLoading(Loader.ADD_REMOTE_CONSOLE));
      yield put(hideDialog(Dialog.UPDATE_SHARED_FOLDER));
      yield put(getProtectListCount());
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `hqouY4_${resp?.status}`)
        )
      );
      yield put(resetLoading(Loader.ADD_REMOTE_CONSOLE));
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* loadCurrentSharedFolderSaga({ id }) {
  try {
    const stateLogin = yield select(getLogin);
    let response = yield call(
      destinationApis.getSharedFolder,
      id,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(setCurrentSharedFolder(response.data));
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Load RPS list when the Grid loads
 */
export function* loadRpsGridData({ page, pageSize }) {
  try {
    yield put(clearRpsList());
    yield put(startLoader());
    const login = yield select(getLogin);
    let staticResponse = yield call(
      destinationApis.getRPSList,
      login.organization_id,
      page,
      pageSize,
      login.token,
      `server_type=customer&sort=server_name`
    );
    //update static content
    if (staticResponse.status === constant.HTTP_STATUS.OK) {
      //change the status of rps to loading
      if (staticResponse && staticResponse.data)
        // staticResponse.data.forEach(rps => (rps.status = "loading"));
        yield put(loadRpsListSuccess(staticResponse));
    }
    //fetch dynamic content
    // const dynamicResponse = yield call(
    //   destinationApis.getRPSList,
    //   login.organization_id,
    //   page,
    //   pageSize,
    //   login.token,
    //   `content=dynamic&server_type=customer&sort=server_name`
    // );

    // if (dynamicResponse.status === constant.HTTP_STATUS.OK) {
    //   staticResponse.data = staticResponse.data.map((staticContent, index) => {
    //     return {
    //       ...staticContent,
    //       ...dynamicResponse.data[index]
    //     };
    //   });
    //   yield put(loadRpsListSuccess(staticResponse));
    // }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

/**
 * Load All RPS list with pagination false when the Grid loads
 */
export function* loadAllRpsGridData() {
  try {
    yield put(clearRpsList());
    yield put(startLoader());
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["recoverypoint_server_grid"])
    );
    const login = yield select(getLogin);
    let staticResponse = yield call(
      destinationApis.getAllRPSList,
      login.organization_id,
      login.token,
      `server_type=customer&sort=server_name`
    );
    //update static content
    if (staticResponse.status === constant.HTTP_STATUS.OK) {
      //change the status of rps to loading
      if (staticResponse && staticResponse.data)
        // staticResponse.data.forEach(rps => (rps.status = "loading"));
        yield put(loadRpsListSuccess(staticResponse));
    }
    //fetch dynamic content
    // const dynamicResponse = yield call(
    //   destinationApis.getAllRPSList,
    //   login.organization_id,
    //   login.token,
    //   `content=dynamic&server_type=customer&sort=server_name`
    // );

    // if (dynamicResponse.status === constant.HTTP_STATUS.OK) {
    //   staticResponse.data = staticResponse.data.map((staticContent, index) => {
    //     return {
    //       ...staticContent,
    //       ...dynamicResponse.data[index]
    //     };
    //   });
    //   yield put(loadRpsListSuccess(staticResponse));
    // }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["recoverypoint_server_grid"])
    );
  }
}

export function* setDataStoreSearchOptions({
  rpsServerId = null,
  filter = "",
  loadOnStart = false
}) {
  yield put(clearDataStores());

  if (
    rpsServerId &&
    filter === constant.ADVANCED_SEARCH_OPTIONS.RPS_DATA_STORES
  ) {
    yield put(
      getAdvancedSearchOptions({
        rpsServerId: rpsServerId,
        filter: filter
      })
    );
    yield fork(applySearchToDataStoresGrid, rpsServerId, loadOnStart);
  } else {
    yield fork(applySearchToDataStoresGrid, rpsServerId, loadOnStart);
  }
}

export function* advancedSearchLoaded(action) {
  const stateReducer = yield select(getReducer);
  let filter = action.payload.filter;
  let rpsServerId = action.payload.rpsServerId;
  if (rpsServerId) {
    let rps = yield select(getCurrentRps);
    if (
      rps &&
      //eslint-disable-next-line eqeqeq
      (stateReducer.secondaryNavigation ==
        constant.SECONDARY_NAVIGATION_KEYS.DATA_STORE ||
        filter === constant.ADVANCED_SEARCH_OPTIONS.RPS_DATA_STORES)
    ) {
      yield put(
        updateAdvancedSearchOptions({
          index: 0,
          item: [
            {
              label: rps.server_name,
              value: rps.server_name
            }
          ]
        })
      );
    }
    yield put(updateTagsForCurrentSearch(true));
    yield put(showCurrentSearch(true));
  }
}

export function* applySearchToDataStoresGrid(
  rpsServerId = null,
  loadOnStart = false
) {
  const data = {
    page: yield select(gridCurrentPage),
    pageSize: yield select(gridPageSize),
    searchText: yield select(searchText),
    advancedFilter: yield select(advancedSearchOptions),
    sort: null
  };

  if (loadOnStart) {
    //clear selected options , if component is rerendered
    if (data.advancedFilter) {
      data.advancedFilter.forEach(ele => {
        ele["selectedOptions"] = [];
      });
    }
  }
  if (rpsServerId) {
    data.advancedFilter = [
      { key: "rps_server_id", selectedOptions: [{ id: rpsServerId }] }
    ];
  }

  yield call(loadDataStoresGridData, data);
}
/**
 * Load RPS list when the Grid loads
 */
export function* loadDataStoresGridData({
  page,
  pageSize,
  searchText,
  advancedFilter,
  sort,
  rpsId = null
}) {
  try {
    yield put(clearDataStores());
    yield put(startLoader());
    yield put(startGridLoaderById(constant.DATAGRID_IDS["datastore_grid"]));
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const staticResponse = yield call(
      destinationApis.getDataStores,
      orgId,
      null,
      null,
      login.token,
      searchText,
      advancedFilter,
      sort,
      `product_type=udp`
    );
    if (staticResponse.status === constant.HTTP_STATUS.OK) {
      //staticResponse.data.forEach(dataStore => (dataStore.status = "loading"));
      if (rpsId) {
        yield call(applyDSSearch, rpsId);
      } else {
        yield put(loadDataStoresSuccess(staticResponse));
      }
    }
    // Removing this dynamic API call as it is not needed
    // const dynamicResponse = yield call(
    //   destinationApis.getDataStores,
    //   orgId,
    //   null,
    //   null,
    //   login.token,
    //   searchText,
    //   advancedFilter,
    //   sort,
    //   `content=dynamic&product_type=udp`
    // );
    // if (dynamicResponse.status === constant.HTTP_STATUS.OK) {
    //   if (rpsId) {
    //     yield call(applyDSSearch, rpsId);
    //   } else {
    //     yield put(loadDataStoresSuccess(dynamicResponse));
    //   }
    // }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["datastore_grid"]));
  }
}

export function* loadAllDataStoresGridData({ rpsId = null }) {
  try {
    yield put(clearDataStores());
    yield put(startLoader());
    yield put(startGridLoaderById(constant.DATAGRID_IDS["datastore_grid"]));
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const staticResponse = yield call(
      destinationApis.getAllDataStores,
      orgId,
      login.token,
      `product_type=udp&sort=datastore_name`
    );
    if (staticResponse.status === constant.HTTP_STATUS.OK) {
      //staticResponse.data.forEach(dataStore => (dataStore.status = "loading"));
      if (rpsId) {
        yield call(applyDSSearch, rpsId);
      } else {
        yield put(loadDataStoresSuccess(staticResponse));
      }
    }
    // Removing this dynamic API call as it is not needed
    // const dynamicResponse = yield call(
    //   destinationApis.getAllDataStores,
    //   orgId,
    //   login.token,
    //   `content=dynamic&product_type=udp`
    // );
    // if (dynamicResponse.status === constant.HTTP_STATUS.OK) {
    //   if (rpsId) {
    //     yield call(applyDSSearch, rpsId);
    //   } else {
    //     yield put(loadDataStoresSuccess(dynamicResponse));
    //   }
    // }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["datastore_grid"]));
  }
}
/** SAGA to apply the RPS filter when displaying data_stores associated with a single RPS */
export function* applyDSSearch(rpsId) {
  const data = {
    page: yield select(gridCurrentPage),
    pageSize: yield select(gridPageSize),
    searchText: yield select(searchText),
    sort: null
  };
  if (rpsId) {
    data.advancedFilter = [
      { key: "rps_server_id", selectedOptions: [{ id: rpsId }] }
    ];
  }
  try {
    yield put(clearDataStores());
    yield put(startLoader());
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const { page, pageSize, searchText, advancedFilter, sort } = data;
    const resp = yield call(
      destinationApis.getDataStores,
      orgId,
      page,
      pageSize,
      login.token,
      searchText,
      advancedFilter,
      sort
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadDataStoresSuccess(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* rpsDataStore({ type, id, page, pageSize }) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.getRpsDataStore,
      id,
      `page=${page}&page_size=${pageSize}`,
      token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(rpsDataStoreListSuccess(resp.data, resp.pagination));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* addNewRps(data) {
  try {
    yield put(setLoading(Loader.ADD_RPS));
    const login = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const organization_id = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : login.organization_id;
    const payload = { ...data.addRPSObj, organization_id: organization_id };
    const resp = yield call(destinationApis.addRPSData, login.token, payload, {
      showCommonError: true,
      testId: "yUqWuK"
    });
    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(resetLoading(Loader.ADD_RPS));
      yield put(hideDialog(Dialog.ADD_RECOVERY_POINT_SERVERS));
      yield put(reset("addRPS"));
      yield put(loadAllRpsList());
      // resp.context = constant.API_RESPONSE_CONTEXT.ADD_RPS_SUCCESS;
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(constant.API_RESPONSE_CONTEXT.ADD_RPS_SUCCESS, "E4BVcf")
        )
      );
      yield put(addAPIresponse(resp));
      yield put(getProtectListCount());
    } else {
      yield put(resetLoading(Loader.ADD_RPS));
      // resp.context = constant.API_RESPONSE_CONTEXT.ADD_RPS_FAILURE;
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Delete RPS when the Grid loads
 */
export function* deleteRps({ id }) {
  try {
    yield put(setLoading(Loader.DELETE_RPS));
    const login = yield select(getLogin);
    const deleteRpsFunction =
      id && Array.isArray(id) && id.length
        ? destinationApis.deleteRpsBulk
        : destinationApis.deleteRps;
    const resp = yield call(deleteRpsFunction, id, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      // yield put(deleteRpsSuccess(resp));
      if (id && Array.isArray(id) && id.length) {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.BULK_DELETE_RPS_SUCCESS,
              "qhFmxP"
            )
          )
        );
      } else {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.DELETE_RPS_SUCCESS,
              "XCqcNd"
            )
          )
        );
      }
      yield put(clearStateSelectedRows());
      yield put(hideDialog(Dialog.DELETE_RPS));
      yield put(loadAllRpsList());
      // resp.context = constant.API_RESPONSE_CONTEXT.DELETE_RPS_SUCCESS;
      yield put(addAPIresponse(resp));
      yield put(getProtectListCount());
    } else if (resp.errors) {
      // resp.context = constant.API_RESPONSE_CONTEXT.DELETE_RPS_FAILURE;
      yield put(addAPIresponse(resp));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `kX9Keg_${resp?.status}`)
        )
      );
      if (
        constant.SHOW_FORCE_DELETE_RPS_ERROR_CODES.includes(resp.errors[0].code)
      ) {
        yield put(showDialog(Dialog.FORCE_DELETE_RPS));
      }
      /* yield put(
        ccToast.addErrorNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DELETE_RPS_FAILURE,
            `kX9Keg_${resp?.status}`
          )
        )
      ); */
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.DELETE_RPS));
    yield put(hideDialog(Dialog.DELETE_RPS));
  }
}

export function* forceDeleteRPS({ id }) {
  try {
    let apiResponse;
    const login = yield select(getLogin);
    if (id && id?.length === 1) {
      apiResponse = yield call(
        destinationApis.forceDeleteRps,
        login.token,
        id,
        true /* force_delete */
      );
    }
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.FORCE_DELETE_RPS_SUCCESS,
            "eE5Bs1"
          )
        )
      );
      yield put(clearStateSelectedRows());
      yield put(loadAllRpsList());
      yield put(getProtectListCount());
    }
    if (apiResponse && apiResponse.errors.length > 0) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            apiResponse.errors[0].message,
            `vBA9xZ_${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.DELETE_RPS));
    yield put(hideDialog(Dialog.FORCE_DELETE_RPS));
  }
}

export function* refreshRps({ idArray }) {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const { curr_page, page_size } = yield select(getRpsPagination);
    const resp = yield call(
      destinationApis.getRPSList,
      orgId,
      curr_page,
      page_size,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      const updateActions = resp.data
        .filter(rps => idArray.indexOf(rps.server_id) >= 0)
        .map(rps => put(refreshRpsSuccess(rps)));
      yield all(updateActions);
      yield put({
        type: actionType.CLEAR_STATE_SELECTED_ROWS
      });
      yield put(getProtectListCount());
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateRps({ data }) {
  yield put(setLoading(Loader.UPDATE_RPS));
  try {
    const login = yield select(getLogin);
    const payload = {
      server_name: data.source_name,
      server_port: data.port,
      server_protocol: data.protocol,
      server_username: data.username,
      server_password: data.password,
      description: data.description
    };
    if (data.nat_server !== "") {
      payload.nat_server = data.nat_server;
      payload.nat_server_port = data.nat_server_port;
    }
    const resp = yield call(
      destinationApis.updateRps,
      data.id,
      payload,
      login.token,
      { showCommonError: true, testId: "oDdzF8" }
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.UPDATE_RPS_SUCCESS,
            "NANHUm"
          ),
          {
            name: data.source_name
          }
        )
      );
      yield put(hideDialog(Dialog.UPDATE_RPS));
      yield put(refreshRpsSuccess(resp.data));
    } else {
      // resp.context = constant.API_RESPONSE_CONTEXT.UPDATE_RPS_FAILURE;
      //yield put(ccToast.addErrorNotification(resp.errors[0].message));
      //yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.UPDATE_RPS));
  }
}

export function* importDataStoreStepOne(data) {
  yield put(setLoading(Loader.IMPORT_DATA_STORE_STEP_ONE));
  try {
    const login = yield select(getLogin);
    const payload = { ...data.importDataStoreObj };
    const resp = yield call(
      destinationApis.importDataStoreStepOne,
      login.token,
      payload
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (payload?.read_only === false) {
        yield put(
          getRpsHashMemory({
            rpsId: data?.importDataStoreObj?.rps_server_id || ""
          })
        );
      }
      yield put(importDataStoreTemp(resp.data));
    } else {
      if (resp.errors) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `U5wU73_${resp?.status}`)
          )
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.IMPORT_DATA_STORE_STEP_ONE));
  }
}

export function* importCloudDataStoreStepOne(data) {
  yield put(setLoading(Loader.IMPORT_CLOUD_DATA_STORE));
  try {
    const login = yield select(getLogin);
    const payload = { ...data.importCloudDataStoreObj };
    const resp = yield call(
      destinationApis.importCloudDataStoreStepOne,
      login.token,
      payload
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (payload?.read_only === false) {
        yield put(
          getRpsHashMemory({
            rpsId: data?.importCloudDataStoreObj?.rps_server_id || ""
          })
        );
      }
      yield put(importCloudDataStoreTemp(resp.data));
    } else {
      if (resp.errors) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `Sn9HJ7_${resp?.status}`)
          )
        );
        // //Temp
        // let data = {
        //   status: 200,
        //   data: {
        //     datastore_name: "dedupelatest",
        //     dedupe_enabled: true,
        //     encryption_enabled: true,
        //     compression_enabled: true,
        //     rps_server_id: "4691305e-4fee-4a8d-90b4-0e9dbc235d3c",
        //     product_type: "udp",
        //     datastore_id: "22bc5c14-f86d-4ca3-b757-9e7869f5cd27",
        //     server_id: "4691305e-4fee-4a8d-90b4-0e9dbc235d3c",
        //     server_name: "10.60.14.96",
        //     status: null,
        //     datastore_properties: {
        //       compression: "standard",
        //       encryption_password: "12",
        //       concurrent_active_nodes: 4,
        //       datastore_path: "E:\\cloud_ds_import\\backup",
        //       datastore_username: "",
        //       datastore_password: "",
        //       deduplication: {
        //         hash_memory: 1088,
        //         hash_on_ssd: false,
        //         data_destination_path: "E:\\cloud_ds_import\\Data",
        //         index_destination_path: "E:\\cloud_ds_import\\index",
        //         hash_destination_path: "E:\\cloud_ds_import\\hash",
        //         block_size: 64,
        //         data_destination_username: "",
        //         index_destination_username: "",
        //         hash_destination_username: "",
        //         cloud_info: {
        //           cloud_account_id: "c5076540-c9b0-4a6a-bfe5-2f7e679d626c",
        //           amazon_s3: {
        //             region: "US East (Ohio)",
        //             bucket_name: "testbharadwajds"
        //           },
        //           google_cloud: null,
        //           wasabi_cloud: null
        //         }
        //       },
        //       capacity: 0,
        //       shared_path: "\\\\10.60.14.96\\UDP_DEDU0001"
        //     }
        //   },
        //   errors: []
        // };
        // yield put(importCloudDataStoreTemp(data.data));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.IMPORT_CLOUD_DATA_STORE));
  }
}

export function* importCloudDataStoreSteptwo(data) {
  yield put(setLoading(Loader.IMPORT_CLOUD_DATA_STORE_STEP_TWO));
  try {
    const login = yield select(getLogin);
    const payload = data.data.importCloudDataStoreObj;
    const resp = yield call(
      destinationApis.importCloudDataStoreStepTwo,
      login.token,
      payload
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.IMPORT_CLOUD_DATA_STORE));
      yield put(getProtectListCount());
      data.data.history.push("/protect/destinations/data_stores");
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.IMPORT_CLOUD_DATA_STORE_SUCCESS,
            "oevI0B"
          )
        )
      );
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `ufsUYa_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.IMPORT_CLOUD_DATA_STORE_STEP_TWO));
  }
}

export function* rpsDataStoreSource({ type, id, data }) {
  try {
    yield put(startLoader());
    yield put(startGridLoaderById(constant.DATAGRID_IDS["jumb_start_grid"]));
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.getRpsDataStoreSources,
      id,
      data,
      token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(rpsDataStoreSourceListSuccess(resp.data, resp.pagination));
    } else {
      yield put(rpsDataStoreSourceListFailure(resp.errors));
    }
    yield put(stopLoader());
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["jumb_start_grid"]));
  } catch (e) {
    window.console.log(e);
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["jumb_start_grid"]));
  }
}

export function* cloudAccountList() {
  try {
    yield put(startLoader());
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["cloud_account_datagrid"])
    );
    const token = yield select(getLoginToken);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      destinationApis.getCloudAccountList,
      token,
      `organization_id=${orgId}&pagination=false&sort=cloud_account_name`
    );

    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(cloudAccountListSuccess(resp.data, resp.pagination));
    }
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["cloud_account_datagrid"])
    );
  } catch (e) {
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["cloud_account_datagrid"])
    );
    window.console.log(e);
  }
}

export function* cloudAccountListByRPSID(action) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const orgId = yield select(getOrgId);
    const loadCloudAccData = action.data;
    let payload = `organization_id=${orgId}&site_id=${loadCloudAccData.site_id}`;

    const resp = yield call(
      destinationApis.getCloudAccountList,
      token,
      payload
    );

    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(cloudAccountListByRPSIdSuccess(resp.data, resp.pagination));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* endPointList(action) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    let resp = "";

    if (action?.data?.region) {
      resp = yield call(
        destinationApis.getEndPointListByRegion,
        token,
        action.data
      );
    } else {
      resp = yield call(destinationApis.getEndPointList, token, action.data);
    }

    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(endPointAccountListSuccess(resp.data));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* shadowXafeList({ type, page, pageSize }) {
  try {
    yield put(startLoader());
    const token = yield select(getCDLoginToken);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      destinationApis.getShadowXafeDestinations,
      token,
      `organization_id=${orgId}&page=${page}&page_size=${pageSize}`
    );

    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(shadowXafeListSuccess(resp.data, resp.pagination));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}
// export function* cloudAccountListByType({ data }) {
//   try {
//     const token = yield select(getLoginToken);
//     const orgId = yield select(getOrgId);
//     yield put(clearCloudAccounts());
//     const resp = yield call(
//       destinationApis.cloudAccountListByType,
//       token,
//       `organization_id=${orgId}&cloud_account_type=${data}&pagination=false`
//     );
//     if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
//       yield put(getCloudAccountListByTypeSuccess(resp.data));
//     }
//   } catch (e) {
//     window.console.log(e);
//   }
// }

export function* getRegionsForCloudAccount({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_AZURE_REGION));
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_AWS_EC2_REGION));
    // const orgId = yield select(getOrgId);
    yield put(clearCloudAccRegions());
    const resp = yield call(
      destinationApis.getRegionsForCloudAccount,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(getRegionsForCloudAccountSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_AZURE_REGION));
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_AWS_EC2_REGION));
  }
}

export function* getComputeRegionsForGCPCloudAccount({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_GCP_CR_OPTIONS));
    yield put(clearGCPCloudAccComputeRegions());
    const resp = yield call(
      destinationApis.getComputeRegionsForGCPCloudAccount,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(getComputeRegionsForGCPCloudAccountSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_GCP_CR_OPTIONS));
  }
}

export function* getComputeZonesForGCPCloudAccount({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_GCP_CZ_OPTIONS));
    yield put(clearGCPCloudAccComputeZones());
    const resp = yield call(
      destinationApis.getComputeZonesForGCPCloudAccount,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(getComputeZonesForGCPCloudAccountSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_GCP_CZ_OPTIONS));
  }
}

export function* getGCPSecurityGroupsForSelectedNetwork({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(clearGCPSecurityGroups());
    const resp = yield call(
      destinationApis.getGCPSecurityGroupsForSelectedNetwork,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(GCPSecurityGroupsFetchSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

//for aws regions
export function* getRegionsForCloudAccV3({ data }) {
  try {
    const token = yield select(getLoginToken);
    // const orgId = yield select(getOrgId);
    yield put(clearCloudAccRegions());
    const resp = yield call(
      destinationApis.getRegionsForCloudAccountV3,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(getRegionsForCloudAccountSuccessV3(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBucketsForCloudAcc({ data }) {
  try {
    const token = yield select(getLoginToken);
    // const orgId = yield select(getOrgId);
    // yield put(clearCloudAccBuckets());
    const resp = yield call(
      destinationApis.getBucketsForCloudAccount,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(getBucketsForCloudAccountSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getResouceGroupForCloudAccount({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_AZURE_RESOURCE_GROUP));
    // const orgId = yield select(getOrgId);
    const resp = yield call(
      destinationApis.getResouceGroupForCloudAccount,
      token,
      data
    );
    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(getResourceGroupForCloudAccountSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_AZURE_RESOURCE_GROUP));
  }
}

export function* connectSharedFolder({ type, data }) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.connectSharedFolder, data, token);

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.CONNECT_SHARED_FOLDER));
      yield put(connectSharedFolderSuccess(resp.data));
    } else {
      yield put(connectSharedFolderFailure(resp.errors));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* onSessionPasswordApply({ type, data }) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.onSessionPasswordApply,
      data,
      token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(onSessionPasswordApplySuccess(data, resp.data));
      yield put(clearStateSelectedRows());
    } else {
      yield put(onSessionPasswordApplyFailure(resp.errors));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* saveJumpStart({ type, id, data }) {
  try {
    yield put(setLoading(Loader.SAVE_JUMPSTART));
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.saveJumpStart, id, data, token);

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.RPS_JUMBSTART));
      yield put(onClearForm());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.START_JUMPSTART_SUCCESS,
            "TxKFgD"
          )
        )
      );
    } else {
      yield put(saveJumpStartError(resp.errors));
    }
    yield put(resetLoading(Loader.SAVE_JUMPSTART));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getPolicyListForJumpstart({ id }) {
  try {
    yield put(startLoader());
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.getPolicyListForJumpstart,
      token,
      id
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getPolicyListSuccess(resp.data));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}
export function* importDataStoreStepTwo(data) {
  yield put(setLoading(Loader.IMPORT_DATA_STORE_STEP_TWO));
  yield put(setLoading(Loader.IMPORT_DATA_STORE_OVERRIDE));
  try {
    const login = yield select(getLogin);
    const payload = data.data.importDataStoreObj;
    const resp = yield call(
      destinationApis.importDataStoreStepTwo,
      login.token,
      payload
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.IMPORT_DATA_STORE));
      yield put(hideDialog(Dialog.IMPORT_DATA_STORE_OVERRIDE));
      yield put(getProtectListCount());
      data.data.history.push("/protect/destinations/data_stores");
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.IMPORT_DATA_STORE_SUCCESS,
            "QNozUU"
          )
        )
      );
    } else if (resp.errors) {
      if (
        resp.errors[0].code === "60129542529" ||
        resp.errors[0].code === "60129542528"
      ) {
        yield put(showDialog(Dialog.IMPORT_DATA_STORE_OVERRIDE));
      } else {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(resp.errors[0].message, `BKvB7D_${resp?.status}`)
          )
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.IMPORT_DATA_STORE_STEP_TWO));
    yield put(resetLoading(Loader.IMPORT_DATA_STORE_OVERRIDE));
  }
}

export function* deleteCloudAccount({ type, data }) {
  try {
    const token = yield select(getLoginToken);
    const stateReducer = yield select(getReducer);
    let deleteCount = 0;
    const dataType = typeof data;
    let resp = null;
    if (dataType === "string") {
      deleteCount = 1;
      resp = yield call(destinationApis.deleteCloudAccount, token, data);
    } else if (dataType === "object") {
      deleteCount = data.length;
      resp = yield call(destinationApis.deleteAllCloudAccount, token, data);
    }

    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(clearStateSelectedRows());
      yield put(protectAction.cloudAccountList());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            "protect.desitnation.cloud_account.grid.success_delete_message",
            "QVhfwD"
          )
        )
      );

      let mainType = "Protect";
      let sendTypesArray = _.uniq([
        stateReducer.secondaryNavigation,
        constant.SECONDARY_NAVIGATION_TYPE.CLOUD_ACCOUNT
      ]);
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = deleteCount;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      yield put(getProtectListCount());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* testCloudAccountConnection({ type, id }) {
  try {
    const token = yield select(getLoginToken);

    const resp = yield call(
      destinationApis.testCloudAccountConnection,
      token,
      id
    );

    if (_.get(resp, "status", null) === constant.HTTP_STATUS.OK) {
      yield put(clearStateSelectedRows());
      yield put(protectAction.cloudAccountList());
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            "protect.desitnation.cloud_account.grid.success_test_connection_message",
            "MKdrzk"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* addDataStore({ payload, type }) {
  const { dataStoreObj, history, samePaths } = payload;
  try {
    const stateLogin = yield select(getLogin);
    if (samePaths) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            "protect.destinations.data_store.add.data_store_folder_path_unique_message",
            `RbXJ9y`
          )
        )
      );
      return;
    }
    yield put(setLoading(Loader.ADD_DATA_STORE));
    let response = yield call(
      destinationApis.addDataStoreData,
      dataStoreObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(getProtectListCount());
      history.push("/protect/destinations/data_stores");
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DATA_STORE_ADD_SUCCESS,
            "is8vbl"
          )
        )
      );
      yield put(reset("addDataStore"));
      yield put(hideDialog(Dialog.ADD_DATA_STORE));
    } else {
      yield put(addAPIresponse(response));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            response.errors[0].message,
            `opJ0FW_${response?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
    if (!samePaths) {
      yield put(reset("addDataStore"));
      yield put(hideDialog(Dialog.ADD_DATA_STORE));
    }
  } finally {
    if (!samePaths) {
      yield put(resetLoading(Loader.ADD_DATA_STORE));
      yield put(resetLoading(Loader.ADD_CLOUD_DATA_STORE));
    }
  }
}

export function* addCloudDataStore({ payload, type }) {
  const { dataStoreObj, history, samePaths } = payload;
  try {
    const stateLogin = yield select(getLogin);
    if (samePaths) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            "protect.destinations.data_store.add.data_store_folder_path_unique_message",
            `Sb5msi`
          )
        )
      );
      return;
    }
    yield put(setLoading(Loader.ADD_CLOUD_DATA_STORE));
    let response = yield call(
      destinationApis.addCloudDataStoreData,
      dataStoreObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      yield put(getProtectListCount());
      history.push("/protect/destinations/data_stores");
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DATA_STORE_ADD_SUCCESS,
            "yObuYz"
          )
        )
      );
      yield put(reset("addCloudDataStore"));
      yield put(hideDialog(Dialog.ADD_CLOUD_DATA_STORE));
    } else {
      yield put(addAPIresponse(response));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            response.errors[0].message,
            `7Jo2UZ_${response?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
    if (!samePaths) {
      yield put(reset("addCloudDataStore"));
      yield put(hideDialog(Dialog.ADD_CLOUD_DATA_STORE));
    }
  } finally {
    if (!samePaths) {
      yield put(resetLoading(Loader.ADD_CLOUD_DATA_STORE));
    }
  }
}

export function* modifyDataStore(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(data.loadingId));
    let response = yield call(
      destinationApis.modifyDataStore,
      data.dataStoreObj.datastore_id,
      data.dataStoreObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DATA_STORE_UPDATE_SUCCESS,
            "lzChzA"
          )
        )
      );
      yield put(loadDataStores());
      yield put(reset("addDataStore"));
      yield put(hideDialog(Dialog.ADD_DATA_STORE));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            response.errors[0].message,
            `Iz0jNH_${response?.status}`
          )
        )
      );
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
    yield put(reset("addDataStore"));
    yield put(hideDialog(Dialog.ADD_DATA_STORE));
  } finally {
    yield put(resetLoading(Loader.ADD_DATA_STORE));
  }
}

export function* modifyCloudDataStore(data) {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(data.loadingId));
    let response = yield call(
      destinationApis.modifyCloudDataStore,
      data.dataStoreObj.datastore_id,
      data.dataStoreObj,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DATA_STORE_UPDATE_SUCCESS,
            "owcHS5"
          )
        )
      );
      yield put(loadDataStores());
      yield put(reset("addCloudDataStore"));
      yield put(hideDialog(Dialog.ADD_CLOUD_DATA_STORE));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            response.errors[0].message,
            `9lk2sl_${response?.status}`
          )
        )
      );
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
    yield put(reset("addCloudDataStore"));
    yield put(hideDialog(Dialog.ADD_CLOUD_DATA_STORE));
  } finally {
    yield put(resetLoading(Loader.ADD_CLOUD_DATA_STORE));
  }
}

function* loadCurrentRpsSaga({ id }) {
  try {
    const stateLogin = yield select(getLogin);
    let response = "";
    response = yield call(destinationApis.getRPS, id, stateLogin.token);
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(setCurrentRps(response.data));
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* loadCurrentDataStoreSaga({ id }) {
  try {
    const stateLogin = yield select(getLogin);
    let response = yield call(
      destinationApis.getDataStore,
      id,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(setCurrentDataStore(response.data));
    } else {
      yield put(addAPIresponse(response));
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* loadCurrentDataStoreForSourceRecovery({ id }) {
  try {
    const stateLogin = yield select(getLogin);
    let response = yield call(
      destinationApis.getDataStore,
      id,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      let encryption_enabled = _.get(
        response,
        "data.encryption_enabled",
        false
      );
      yield put(setDataStoreEncryptionEnabled(encryption_enabled));
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* deleteDataStore(ids, advancedSearchOptions, forceDelete) {
  try {
    let resp;
    const login = yield select(getLogin);
    const { curr_page, page_size } = yield select(getDataStoresPagination);
    yield put(setLoading(Loader.DELETE_DATA_STORE));
    // yield put(setLoading(Loader.DELETE_DATA_STORE_BULK));
    yield put(startLoader());
    if (ids.length > 1) {
      resp = yield call(destinationApis.deleteDataStoreByID, login.token, ids);
    } else {
      resp = yield call(
        destinationApis.removeDataStoreByID,
        login.token,
        ids,
        forceDelete
      );
    }
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DATA_STORE_DELETE_SUCCESS,
            "isHSlZ"
          )
        )
      );
      yield put(getProtectListCount());
      yield put(
        loadDataStores(curr_page, page_size, "", advancedSearchOptions)
      );
    }
    return resp;
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.DELETE_DATA_STORE));
    // yield put(resetLoading(Loader.DELETE_DATA_STORE_BULK));
  }
}

export function* deleteDataStoreData({ ids, advancedSearchOptions = [] }) {
  try {
    const apiResponse = yield call(
      deleteDataStore,
      ids,
      advancedSearchOptions,
      false /* force_delete */
    );
    if (apiResponse && apiResponse.errors.length > 0) {
      yield put(addAPIresponse(apiResponse));
      if (
        constant.DONT_SHOW_FORCE_DELETE_DS_ERROR_CODES.includes(
          apiResponse.errors[0].code
        )
      ) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              apiResponse.errors[0].message,
              `YYBVbx_${apiResponse?.status}`
            )
          )
        );
      } else {
        yield put(showDialog(Dialog.FORCE_DELETE_DATA_STORE));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(hideDialog(Dialog.DELETE_DATA_STORE));
    // yield put(hideDialog(Dialog.DELETE_DATA_STORE_BULK));
  }
}

export function* forceDeleteStoreData({ ids, advancedSearchOptions = [] }) {
  try {
    let apiResponse = yield call(
      deleteDataStore,
      ids,
      advancedSearchOptions,
      true /* force_delete */
    );
    if (apiResponse && apiResponse.errors.length > 0) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            apiResponse.errors[0].message,
            `YYBVbx_${apiResponse?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.DELETE_DATA_STORE));
    yield put(hideDialog(Dialog.FORCE_DELETE_DATA_STORE));
  }
}

export function* startDataStore({ type, ids, advancedSearchOptions = [] }) {
  try {
    const login = yield select(getLogin);
    const { curr_page, page_size } = yield select(getDataStoresPagination);
    yield put(startLoader());
    const resp = yield call(
      destinationApis.startDataStoreByID,
      login.token,
      ids
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put({
        type: actionType.CLEAR_STATE_SELECTED_ROWS
      });
      yield put(
        loadDataStores(curr_page, page_size, "", advancedSearchOptions)
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            "protect.destination.rps.action.start_datastore",
            "KnQpzv"
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `tqxed0_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* stopDataStore({ type, ids, advancedSearchOptions = [] }) {
  try {
    yield put(setLoading(Loader.STOP_DATA_STORE));
    yield put(setLoading(Loader.STOP_DATA_STORE_BULK));
    const login = yield select(getLogin);
    const { curr_page, page_size } = yield select(getDataStoresPagination);
    yield put(startLoader());
    const resp = yield call(
      destinationApis.stopDataStoreByID,
      login.token,
      ids
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put({
        type: actionType.CLEAR_STATE_SELECTED_ROWS
      });
      yield put(
        loadDataStores(curr_page, page_size, "", advancedSearchOptions)
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            "protect.destination.rps.action.stop_datastore",
            "QJETYV"
          )
        )
      );
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(resetLoading(Loader.STOP_DATA_STORE));
    yield put(resetLoading(Loader.STOP_DATA_STORE_BULK));
    yield put(hideDialog(Dialog.STOP_DATA_STORE));
    yield put(hideDialog(Dialog.STOP_DATA_STORE_BULK));
  }
}

export function* authenticateSharedFolder({ type, data, selectConnectType }) {
  try {
    const login = yield select(getLogin);
    const dsConnectStatus = yield select(
      state => state.reducer.dsConnectStatus
    );
    yield put(clearAuthConnection());
    yield put(setLoading(Loader.ADD_DATA_STORE_CONNECT));
    const resp = yield call(
      destinationApis.authenticateSharedFolder,
      login.token,
      data
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(updateRecoveryConnectStatus("connected"));
      yield put(authenticationConnectSuccess(resp));
      if (selectConnectType) {
        yield put(
          updateDSConnectStatus({
            ...dsConnectStatus,
            [selectConnectType]: constant.CONNECT_STATUS.CONNECTED
          })
        );
      }
    } else {
      yield put(updateRecoveryConnectStatus("notconnected"));
      yield put(authenticationConnectFailure(resp.errors[0]));
      if (selectConnectType) {
        yield put(
          updateDSConnectStatus({
            ...dsConnectStatus,
            [selectConnectType]: constant.CONNECT_STATUS.FAILED
          })
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_DATA_STORE_CONNECT));
    yield put(hideDialog(Dialog.STOP_DATA_STORE));
  }
}

export function* refreshDataStore({ type, ids }) {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    // const { curr_page, page_size } = yield select(getDataStoresPagination);
    yield put(startLoader());
    const resp = yield call(
      destinationApis.getDataStores,
      orgId,
      "",
      "",
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      const updateActions = resp.data
        .filter(ds => ids.indexOf(ds.datastore_id) >= 0)
        .map(ds => {
          //ds.status = "loading";
          return put(refreshDataStoreSuccess(ds));
        });
      yield all(updateActions);
      yield put(getProtectListCount());
    } else {
      yield put(addAPIresponse(resp));
    }

    // Removing this dynamic API call as it is not needed
    // const dynamicResponse = yield call(
    //   destinationApis.getDataStores,
    //   orgId,
    //   "",
    //   "",
    //   login.token,
    //   "",
    //   "",
    //   "",
    //   `content=dynamic`
    // );
    // if (dynamicResponse.status === constant.HTTP_STATUS.OK) {
    //   const updateActions = dynamicResponse.data
    //     .filter(ds => ids.indexOf(ds.datastore_id) >= 0)
    //     .map(ds => {
    //       return put(refreshDataStoreSuccess(ds));
    //     });
    //   yield all(updateActions);
    // }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* disableShareDataStore({ type, ids }) {
  try {
    const token = yield select(getLoginToken);
    yield put(startLoader());
    const resp = yield call(destinationApis.disableShareDataStore, token, ids);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DISABLE_SHARE_SUCCESS,
            "YT4pE3"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* installDeployRps(data) {
  try {
    yield put(setLoading(Loader.ADD_RPS));
    const login = yield select(getLogin);
    const payload = { ...data.data };
    const resp = yield call(
      destinationApis.installDeployRps,
      login.token,
      payload
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (data?.data?.deployment?.schedule) {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.SCHEDULE_DEPLOY_RPS_SUCCESS,
              "bqVMeO"
            )
          )
        );
      } else {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.INSTALL_DEPLOY_RPS_SUCCESS,
              "MqaRrL"
            )
          )
        );
      }
      yield put(reset("upgradeRPS"));
      yield put(hideDialog(Dialog.INSTALL_UPGRADE_RPS));
      yield put(loadAllRpsList());
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `vHGEU2_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_RPS));
  }
}

export function* installDeployRpsBulk(data) {
  try {
    yield put(setLoading(Loader.ADD_RPS));
    const login = yield select(getLogin);
    const payload = data.data;
    const resp = yield call(
      destinationApis.installDeployRpsBulk,
      login.token,
      payload
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.INSTALL_DEPLOY_RPS_SUCCESS,
            "RCuSOU"
          )
        )
      );
      yield put(hideDialog(Dialog.INSTALL_UPGRADE_RPS));
      yield put(loadAllRpsList());
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `FWx26Q_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_RPS));
  }
}

export function* cancelScheduledDeploy({ id }) {
  try {
    yield put(setLoading(Loader.CANCEL_SCHEDULED_DEPLOY_RPS));
    const login = yield select(getLogin);
    const cancelScheduledRPS =
      id &&
      Array.isArray(id) &&
      id.length &&
      destinationApis.cancelScheduledDeploy;
    const resp = yield call(cancelScheduledRPS, id, login.token);
    if (resp.status === constant.HTTP_STATUS.OK) {
      if (id && Array.isArray(id) && id.length) {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.CANCEL_SCHEDULED_DEPLOY_RPS_SUCCESS,
              "qhFmxn"
            )
          )
        );
      } else {
        yield put(
          ccToast.addSuccessNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.CANCEL_SCHEDULED_DEPLOY_RPS_SUCCESS,
              "XCqcNz"
            )
          )
        );
      }
      yield put(loadAllRpsList());
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `kX9Kex_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CANCEL_SCHEDULED_DEPLOY_RPS));
  }
}
export function* getBrowseRecoveryPointDSListHandler(action) {
  try {
    const login = yield select(getLogin);
    yield put({
      type: actionType.CLEAR_BROWSE_SOURCES_DS
    });
    yield put(startLoader());
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["browser_recovery_point_server_grid"]
      )
    );
    const resp = yield call(
      destinationApis.getBrowseRecoveryPointDSList,
      action,
      login.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getBrowseRecoveryPointDSListSuccess(resp.data));
      // TODO add setting search option here
      // let cloudServices = resp.data.map(source => )
      // yield(put(showAdvancedSearchOptions({})));
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `P08uJF_${resp?.status}`)
        )
      );
    }
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(
        constant.DATAGRID_IDS["browser_recovery_point_server_grid"]
      )
    );
  } catch (e) {
    window.console.log(e);
    yield put(
      stopGridLoaderById(
        constant.DATAGRID_IDS["browser_recovery_point_server_grid"]
      )
    );
  }
}

export function* getBrowseRecoveryPointSFListHandler(action) {
  try {
    const login = yield select(getLogin);
    yield put({
      type: actionType.CLEAR_BROWSE_SOURCES_SF
    });
    yield put(startLoader());
    yield put(getBrowseRecoveryPointSharedFolderListSuccess([]));
    yield put(
      ccDataGridActions.initialize(
        CCDATAGRID_ID.BROWSE_SHARE_FOLDER_RECOVERY_POINT
      )
    );
    const resp = yield call(
      destinationApis.getBrowseRecoveryPointSFList,
      action,
      login.token,
      `server_id=${action?.serverID}&server_type=${action?.serverType}`
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      let SFEntities;
      if (resp?.data?.length) {
        const getRespData = resp?.data?.length
          ? resp.data.map(r => ({
              ...r.sources
            }))
          : [resp?.data];
        const getSourceInfo = [];
        // This condition is to iterate the inner source value for each policy, Some policy having more children source
        if (getRespData?.length) {
          // eslint-disable-next-line array-callback-return
          getRespData.map(item => {
            if (Object.keys(item).length > 1) {
              for (let i = 0; i < Object.keys(item).length; ++i) {
                getSourceInfo.push({
                  ...item[i],
                  _rowId: item[i]?.source_id
                });
              }
              return item;
            }
            getSourceInfo.push({
              ...item[0],
              _rowId: item[0]?.source_id
            });
          });
        }
        SFEntities = getSourceInfo;
      } else {
        SFEntities = [];
      }
      yield put(getBrowseRecoveryPointSharedFolderListSuccess(SFEntities));
      const pagination = {
        curr_page: 1,
        total_page: 1,
        page_size: 25,
        has_prev: false,
        has_next: false,
        total_size: SFEntities.length
      };
      yield put(
        ccDataGridActions.fetchDataSuccess(
          CCDATAGRID_ID.BROWSE_SHARE_FOLDER_RECOVERY_POINT,
          resp,
          SFEntities,
          pagination
        )
      );
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `oAInLE_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(
      ccDataGridActions.resetCCDataGridLoader(
        CCDATAGRID_ID.BROWSE_SHARE_FOLDER_RECOVERY_POINT
      )
    );
  }
}

export function* deleteSourceItem(data) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.deleteSource, token, data.id);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(deleteRemoteConsoleSuccess(data.id));
      yield put(clearStateSelectedRows());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshSourceItem(data) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.refreshSource, token, data.id);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(deleteRemoteConsoleSuccess(data.id));
      yield put(clearStateSelectedRows());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshRecoveryPoint({ sourceId, dataStoreId }) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.refreshRecoveryPoint,
      token,
      sourceId,
      dataStoreId
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getBrowseRecoveryPointsDS(dataStoreId));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            "protect.destination.ds.recoveryPoint.refresh.success",
            "SPxYij"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshRecoveryPointSharedFolder({
  sourceId,
  shareFolderId,
  serverID,
  serverType
}) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.refreshRecoveryPointSharedFolder,
      token,
      sourceId,
      shareFolderId
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        getBrowseRecoveryPointSharedFolder(shareFolderId, serverID, serverType)
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.BROWSE_RP_REFRESH_SUCCESS,
            "MyCWba"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* mergeSourceItem(data) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.mergeSource, token, data.id);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(deleteRemoteConsoleSuccess(data.id));
      yield put(clearStateSelectedRows());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRetention(action) {
  try {
    const token = yield select(getLoginToken);
    yield put(startGridLoaderById(constant.DATAGRID_IDS["merge_now_modal_dg"]));
    const resp = yield call(destinationApis.getRetention, token, action.data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(setRetention({ data: resp.data, request: action.data }));
    } else {
      yield put(setRetention([]));
    }
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["merge_now_modal_dg"]));
  } catch (e) {
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["merge_now_modal_dg"]));
    window.console.log(e);
  }
}

export function* mergeNow(action) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.RP_MERGE));
    const resp = yield call(destinationApis.mergeNow, token, action.data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(resetLoading(Loader.RP_MERGE));
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("merge_request_success", "PLNXF9")
        )
      );
      yield put(getBrowseRecoveryPointsDS(action.data[0].id));
      yield put(hideDialog(Dialog.RP_MERGE));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `4PXUCe_${resp?.status}`)
        )
      );
      yield put(resetLoading(Loader.RP_MERGE));
    }
  } catch (e) {
    yield put(resetLoading(Loader.RP_MERGE));
    window.console.log(e);
  }
}

export function* getPolicyDataById(action) {
  try {
    const login = yield select(getLogin);
    // yield put({
    //   type: actionType.CLEAR_BROWSE_SOURCES_DS
    // });
    yield put(startLoader());
    const resp = yield call(destinationApis.getPolicyData, action, login.token);

    if (resp.status === constant.HTTP_STATUS.OK) {
      resp.context =
        constant.API_RESPONSE_CONTEXT.RUN_ASSURED_RECOVERY_TEST_SUCCESS;
      yield put(addAPIresponse(resp));
      yield put(onPolicyDataGetSuccess(resp.data));
    } else {
      resp.context =
        constant.API_RESPONSE_CONTEXT.RUN_ASSURED_RECOVERY_TEST_FAILURE;
      yield put(addAPIresponse(resp));
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchAvailableVolumesSaga({ source, selectedDestination }) {
  try {
    const login = yield select(getLogin);
    const sourceData = yield select(selectedSource);
    yield put(setLoading(Loader.AVAILABLE_VOLUMES));
    const resp = yield call(
      destinationApis.postAvailableVolumes,
      {
        backup_destination_info: {
          destination_path: source.destinationPath,
          destination_id: _.get(selectedDestination, "destination_id", ""),
          destination_type: _.get(selectedDestination, "destination_type", ""),
          datastore_id: sourceData.datastore_id
        },
        backup_session_info: {
          sub_path: source.subPath,
          session_guid: source.session_guid
        },
        mount_server: {
          server_id: source.id,
          from_datastore: sourceData.from_datastore,
          from_destination: sourceData.from_destination
        },
        proxy_id: sourceData.from_destination
          ? sourceData.rps_server_id
          : undefined
      },
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchAvailableVolumesSuccess(resp.data));
    } else {
      resp.context =
        constant.API_RESPONSE_CONTEXT.FETCH_AVAILABLE_VOLUMES_FAILURE;
      if (source.subPath && source.session_guid) {
        yield put(addAPIresponse(resp));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.AVAILABLE_VOLUMES));
  }
}
export function* getAvailableDriveLetters({ data }) {
  try {
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    const payload = {
      server_id: data,
      from_datastore: source ? source.from_datastore : null
    };
    const resp = yield call(
      destinationApis.getAvailableDrivesFromSource,
      token,
      payload,
      true
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(updateAvailableDriveLetters(resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRecoveryPointsByDestinationPath(action) {
  try {
    yield put(startLoader());
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["recover_fnf_linux_summary_grid"]
      )
    );
    yield put(setLoading(Loader.RECOVERY_POINT_LOADER));
    if (!action.data.assured_security_grid) {
      yield put(
        ccDataGridActions.initialize(
          constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
        )
      );
    } else {
      yield put(
        ccDataGridActions.initialize(
          constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
        )
      );
    }
    const login = yield select(getLogin);
    // // yield put({
    // //   type: actionType.CLEAR_BROWSE_SOURCES_DS
    // // });
    const source = yield select(selectedSource);

    if (source) {
      action.data.mount_server.from_datastore = source.from_datastore;
      action.data.backup_destination_info.datastore_id = source.from_datastore
        ? source.datastore_id
        : null;
    }
    const resp = yield call(
      _.get(source, "type", "") === "udp_linux" ||
        _.get(source, "os_major", "") === "linux" ||
        _.get(source, "obj.operatingSystem", "") === "linux" ||
        action?.data?.sessionTypeLinux
        ? destinationApis.getRecoveryPointItemsBySourceId
        : destinationApis.getRecoveryPointItems,
      action,
      login.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      // let { data } = resp;

      // data = data.map(r => ({ ...r, _rowId: r["session_Id"] }));
      if (!action.data.assured_security_grid) {
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
          )
        );
      } else {
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
          )
        );
      }
      if (
        action.type !==
        createStandbyVMConstants.GET_RECOVERY_POINTS_BY_DESTINATION_PATH_FOR_VSB
      ) {
        yield put(getRecoveryPointResponse(resp));
        yield put(onGetRecoveryPointsByDestinationPath(resp.data));
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
          )
        );
      } else {
        yield put(getRecoveryPointResponseForVsb(resp));
        yield put(onGetRecoveryPointsByDestinationPathForVsb(resp.data));
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
          )
        );
      }
    } else if (resp.errors && resp.errors.length > 0) {
      let toastData = resp.errors[0].message;
      if (resp.errors[0].code === "25769803777") {
        toastData = `recovery.sources.no_recovery_points`;
      }
      yield put(
        ccToast.addErrorNotification(
          makeToastData(toastData, `xtFmXP_${resp?.status}`)
        )
      );
    }
    if (resp.status >= 400) {
      if (!action.data.assured_security_grid) {
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT
          )
        );
      } else {
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
          )
        );
      }
    }
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(
        constant.DATAGRID_IDS["recover_fnf_linux_summary_grid"]
      )
    );
  } catch (e) {
    yield put(
      stopGridLoaderById(
        constant.DATAGRID_IDS["recover_fnf_linux_summary_grid"]
      )
    );
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.RECOVERY_POINT_LOADER));
  }
}

export function* getRecoveryOracleVersion(data) {
  try {
    const stateLogin = yield select(getLogin);
    const response = yield call(
      destinationApis.getRecoveryOracleVersion,
      data.sourceId,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(storeOracleRecoveryVersionSuccess(response.data));
    } else if (response.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            response.errors[0].message,
            `2DiagZ_${response?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

// For Browse getting DB setting RMAN
export function* getRecoveryOracleDBConfigurations(action) {
  //  set loader
  // set data
  try {
    yield put(
      ccDataGridActions.startCCDataGridLoader(CCDATAGRID_ID.TABLESPACES)
    );
    yield put(ccDataGridActions.startCCDataGridLoader(CCDATAGRID_ID.DATAFILES));
    const login = yield select(getLogin);
    const encryptPassword = yield select(rpEncryptionPassword);

    yield put(setLoading(Loader.ORACLE_RECOVERY_BROWSE_BUTTON));
    // encryption
    yield put(ccDataGridActions.initialize(constant.CCDATAGRID_ID.TABLESPACES));
    // yield put(ccDataGridActions.startCCDataGridLoader(constant.CCDATAGRID_ID.TABLESPACES));
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["oracle_recovery_parameter_files_grid"]
      )
    );
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["oracle_recovery_archive_logs_grid"]
      )
    );
    yield put(
      startGridLoaderById(
        constant.DATAGRID_IDS["oracle_recovery_control_files_grid"]
      )
    );
    // yield put(clearRecoveryOracleDBSettings());
    yield put(clearRecoveryOracleDBTableSettings());
    let payload = { ...action };
    let isDefault = payload.data?.defaultApi ? true : false;
    if (payload.data?.defaultApi) {
      delete payload.data.defaultApi;
    }
    payload.encryption_password = encryptPassword;
    const resp = yield call(
      destinationApis.getRecoveryPointOracleDatabaseConfigurations,
      payload,
      login.token
    );
    yield put(resetLoading(Loader.ORACLE_RECOVERY_BROWSE_BUTTON));
    if (resp.status === constant.HTTP_STATUS.OK) {
      let apiData = _.cloneDeep(resp.data);
      if (apiData.tables.length > 0) {
        const mapApiData = apiData.tables;
        let tables = mapApiData.map(name => {
          let makeTableObj = {
            tableName: name,
            newName: ""
          };
          return makeTableObj;
        });
        const dataToAdd = {
          left: tables,
          right: []
        };
        yield put(storeRecoveryOracleDBTableSettings(dataToAdd));
        apiData.tables = tables;
      }
      yield put(oracleRecoverDBConfigDataSuccess(apiData));
      yield put(
        stopGridLoaderById(
          constant.DATAGRID_IDS["oracle_recovery_parameter_files_grid"]
        )
      );
      yield put(
        stopGridLoaderById(
          constant.DATAGRID_IDS["oracle_recovery_archive_logs_grid"]
        )
      );
      yield put(
        stopGridLoaderById(
          constant.DATAGRID_IDS["oracle_recovery_control_files_grid"]
        )
      );
      if (action.data.pdb_name.length <= 0) {
        yield put(storeOraclePDBList(resp?.data?.pdb_list));
      }
      if (isDefault) {
        let formData = {
          selectedDB: action.data.database_name,
          RestoreOptions: "Full Restore"
        };
        // yield put(change(`recoverRestoreOptions`, 'selectedDB', formData.selectedDB))
        let TSEntites = [],
          DTEntites = [];
        if (resp?.data?.table_spaces?.length > 0) {
          resp.data.table_spaces.forEach((e, index) => {
            let id = `${e}-${index + 1}`;
            let obj = {
              name: e,
              site_name: e,
              uniqueId: index + 1,
              _rowId: id
            };
            TSEntites.push(obj);
          });
        }
        if (resp?.data?.data_files?.length > 0) {
          resp.data.data_files.forEach((e, index) => {
            let id = `${e.seqNo}-${index + 1}`;
            let obj = {
              ...e,
              _rowId: id
            };
            DTEntites.push(obj);
          });
        }
        const storedData = {
          formData,
          TSEntites,
          DTEntites
        };
        yield put(storeRecoveryOracleDBSettings(storedData));
        if (action.openDialog) {
          yield put(showDialog(Dialog.RESTORE_ORACLE_DB));
        }
      }
    } else {
      let internalError = _.get(resp, "error.message", "");
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(resp, "errors[0].message", internalError),
            `sKX9Q3${resp?.status}`
          )
        )
      );
      let clearData = {
        data_files: [],
        table_spaces: [],
        tables: [],
        apiFailed: true
      };
      yield put(oracleRecoverDBConfigDataSuccess(clearData));
      yield put(clearOraclePDBList());
      yield put(
        stopGridLoaderById(
          constant.DATAGRID_IDS["oracle_recovery_parameter_files_grid"]
        )
      );
      yield put(
        stopGridLoaderById(
          constant.DATAGRID_IDS["oracle_recovery_archive_logs_grid"]
        )
      );
      yield put(
        stopGridLoaderById(
          constant.DATAGRID_IDS["oracle_recovery_control_files_grid"]
        )
      );
      let pagination = {
        curr_page: 1,
        total_page: 1,
        page_size: 25,
        has_prev: false,
        has_next: false,
        total_size: 0
      };
      let api = {
        status: 200,
        data: [],
        errors: [],
        pagination: pagination
      };
      yield put(
        ccDataGridActions.fetchDataSuccess(
          CCDATAGRID_ID.TABLESPACES,
          api,
          api.rows,
          pagination
        )
      );
      yield put(
        ccDataGridActions.fetchDataSuccess(
          CCDATAGRID_ID.DATAFILES,
          api,
          api.rows,
          pagination
        )
      );
      yield put(
        ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.TABLESPACES)
      );
      yield put(
        ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.DATAFILES)
      );
    }

    // Format data to tables before sending if required
  } catch (e) {
    yield put(clearOracleRecoverDBConfigFailure());
    yield put(clearRecoveryOracleDBSettings());
    yield put(clearRecoveryOracleDBTableSettings());
    yield put(clearOraclePDBList());
    yield put(
      ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.TABLESPACES)
    );
    yield put(ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.DATAFILES));
    window.console.log(e);
  }
}
// export function* getRecoveryPoints({data}) {
//   try {
//     const login = yield select(getLogin);
//     // const source = yield select(selectedSource);
//     yield put(setLoading(Loader.RECOVERY_POINT_LOADER));
//     yield put(startLoader());
//     const resp = yield call(
//         destinationApis.fetchRecoveryPoints,
//         login.token,
//         data.payload,
//         data.id
//       );
//       if (resp.status === constant.HTTP_STATUS.OK) {
//         yield put(onGetRecoveryPointsSuccess(resp.data));
//       }
//       yield put(stopLoader());
//   } catch (e) {
//     window.console.log(e);
//   } finally {
//     yield put(resetLoading(Loader.RECOVERY_POINT_LOADER));
//   }
// }

export function* fetchMountedVolumesSaga({ sourceId }) {
  if (!sourceId) {
    return;
  }
  try {
    const login = yield select(getLogin);
    const source = yield select(selectedSource);
    yield put(startLoader());
    const resp = yield call(
      destinationApis.postMountedVolumes,
      {
        server_id: sourceId,
        from_datastore: source ? source.from_datastore : null
      },
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchMountedVolumesSuccess(resp.data));
    } else {
      resp.context =
        constant.API_RESPONSE_CONTEXT.FETCH_MOUNTED_VOLUMES_FAILURE;
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* fetchMountedDatabasesSaga({ sourceId }) {
  if (!sourceId) {
    return;
  }
  try {
    const login = yield select(getLogin);
    yield put(startLoader());
    const resp = yield call(
      destinationApis.postMountedDatabases,
      sourceId,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchMountedDatabasesSuccess(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `AgXY1b_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* getSourcesWithWindowsFilter({ data }) {
  try {
    const token = yield select(getLoginToken);
    const loginReducer = yield select(getLogin);
    const protectReducer = yield select(getProtectReducer);
    const organizationId = protectReducer.orgIdForSourceCSR
      ? protectReducer.orgIdForSourceCSR
      : loginReducer.organization_id;
    const queryParams = `source_type=udp_windows&is_remote=false&is_deleted=false&organization_id=${organizationId}&pagination=false`;
    const resp = yield call(
      destinationApis.getSourcesWithWindowsFilter,
      token,
      queryParams
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(updateAgentMachineSourcesList(resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSQLDBInstances({ data }) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(
      protectApi.getSQLDBInstancesForRecoveryPoint,
      data,
      token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(storeSQLInstanceData(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            resp && resp.errors && resp.errors[0] && resp.errors[0].message,
            `JAJ5JQ_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* dismountVolumeSaga({
  sourceId,
  mountPath,
  mountDiskSignature,
  mountedSourceID,
  setisFinishEnabled
}) {
  try {
    yield put(setLoading(Loader.DISMOUNT_VOLUME_CONFIRM));
    const login = yield select(getLogin);
    const source = yield select(selectedSource);
    const resp = yield call(
      destinationApis.postDismountVolume,
      {
        mounted_volume_info: {
          mount_path: mountPath,
          mount_disk_signature: mountDiskSignature
        },
        mount_server: {
          server_id: sourceId,
          from_datastore: source ? source.from_datastore : null
        }
      },
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchMountedVolumes(mountedSourceID));
      setisFinishEnabled && setisFinishEnabled(true);
      const mainType = "Protect";
      const sendTypesArray = [
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ];
      const tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: 1
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DISMOUNT_VOLUME_SUCCESS,
            "Gs5T7X"
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `sJ5kFr_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.DISMOUNT_VOLUME_CONFIRM));
    yield put(hideDialog(Dialog.DISMOUNT_VOLUME_CONFIRM));
  }
}

export function* dismountDatabaseSaga({ sourceId, data, setisFinishEnabled }) {
  try {
    yield put(setLoading(Loader.DISMOUNT_DATABASE_CONFIRM));
    const login = yield select(getLogin);
    const resp = yield call(
      destinationApis.postDismountDatabase,
      data,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.fetchMountedDatabases(sourceId));
      setisFinishEnabled && setisFinishEnabled(true);
      const mainType = "Protect";
      const sendTypesArray = [
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ];
      const tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: 1
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DISMOUNT_DATABASE_SUCCESS,
            "V36PRe"
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `vltAVN_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.DISMOUNT_DATABASE_CONFIRM));
    yield put(hideDialog(Dialog.DISMOUNT_DATABASE_CONFIRM));
  }
}

export function* getDestinationsBySourceId(action) {
  try {
    const login = yield select(getLogin);
    const isAdhocAssuredSecurityTask = yield select(state =>
      getDialogVisibility(state, Dialog.SOURCE_RUN_ASSURED_SECURITY_SCAN)
    );
    // yield put({
    //   type: actionType.CLEAR_BROWSE_SOURCES_DS
    // });
    yield put(startLoader());
    yield put(setLoading(Loader.GET_DESTINATION_BY_SOURCEID));
    const resp = yield call(
      destinationApis.getDestinationsDataBySourceId,
      action,
      login.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(onGetDestinationsBySourceId(resp.data));
      if (resp.data && resp.data.length === 0 && !isAdhocAssuredSecurityTask) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "recovery.sources.no_recovery_points",
              `0FeXwO_${resp?.status}`
            )
          )
        );
        yield put(
          ccDataGridActions.resetCCDataGridLoader(
            constant.CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
          )
        );
      }
    } else if (
      resp &&
      resp.errors[0] &&
      resp.errors[0].code === "25769803777"
    ) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            "recovery.sources.no_recovery_points",
            `xtFmXP_${resp?.status}`
          )
        )
      );
      yield put(
        ccDataGridActions.resetCCDataGridLoader(
          CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(resp, "errors[0].message", ""),
            `xtFmXP_${resp?.status}`
          )
        )
      );
      yield put(
        ccDataGridActions.resetCCDataGridLoader(
          CCDATAGRID_ID.SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN
        )
      );
    }
    yield put(stopLoader());
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.GET_DESTINATION_BY_SOURCEID));
  }
}

export function* postMountRecoveryPoint({ data }) {
  try {
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    if (source) {
      data.mount_server
        ? (data.mount_server.from_datastore = source.from_datastore)
        : (data.mount_server = {
            from_datastore: source.from_datastore
          });
    }
    yield put(setLoading(Loader.MOUNT_RP_BTN));
    const resp = yield call(
      destinationApis.postMountRecoveryPoint,
      token,
      data
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.MOUNT_RECOVERY_POINT));
      yield put(hideDialog(Dialog.MOUNT_RECOVERY_POINT_LINUX));
      data.setisFinishEnabled && data.setisFinishEnabled(true);
      yield put(protectAction.mountVolumesSuccess(resp.data));
      if (data.source_id) {
        yield put(protectAction.fetchMountedVolumes(data.source_id));
      }
      let mainType = "Protect";
      let sendTypesArray = [
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: 1
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.MOUNT_VOLUME_SUCCESS,
            "ZSbhaV"
          )
        )
      );
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `3a8Hjs_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.MOUNT_RP_BTN));
  }
}

export function* postMountSQLDatabase({ data }) {
  try {
    const token = yield select(getLoginToken);
    const source = yield select(selectedSource);
    yield put(setLoading(Loader.MOUNT_RP_BTN));
    const resp = yield call(
      destinationApis.postMountSQLDatabase,
      token,
      data,
      source
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(hideDialog(Dialog.MOUNT_SQL_DATABASES));
      data.setisFinishEnabled && data.setisFinishEnabled(true);
      yield put(protectAction.mountDatabasesSuccess(resp.data));
      if (source.source_id) {
        yield put(protectAction.fetchMountedDatabases(source.source_id));
      }
      const mainType = "Protect";
      const sendTypesArray = [
        constant.SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS
      ];
      const tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: 1
        })
      );
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.MOUNT_DATABASE_SUCCESS,
            "ZE36j5"
          )
        )
      );
    } else if (resp.errors) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `8lnCf0_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.MOUNT_RP_BTN));
  }
}

const cancelAbleSaga = (saga, cancelAction) =>
  function*(...args) {
    yield race([call(saga, ...args), take(cancelAction)]);
  };

export function* fetchCloudAccountsConfSaga({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(setLoading(Loader.CLOUD_CONFIGURATION_VSB));
    const resp = yield call(
      destinationApis.fetchCloudAccountsConfigurations,
      token,
      data.payload,
      data.cloudId
    );
    yield put(resetLoading(Loader.CLOUD_CONFIGURATION_VSB));
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.storeCloudConfigurations(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `tMBw2M_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
  }
}

export function* fetchCloudAccountSubnetSaga({ data }) {
  try {
    const token = yield select(getLoginToken);
    const resp = yield call(
      destinationApis.fetchCloudAccountSubnet,
      token,
      data.payload,
      data.cloudId
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.storeCloudSubnet(resp.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `c9JBbH_${resp?.status}`)
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
  }
}

export function* validateNFS({ data }) {
  try {
    yield put(setLoading(Loader.ADD_IVM));
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.ivmValidateNFS, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.validateIVMSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_IVM));
  }
}

export function* purgeDatstoreRPs({ id, data }) {
  try {
    yield put(setLoading(Loader.PURGE_DATA_STORE));
    yield put(setLoading(Loader.PURGE_DATA_STORE_BULK));
    const token = yield select(getLoginToken);

    const resp = yield call(destinationApis.purgeDataStore, token, id, data);

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            "protect.destination.data-store.purge.success",
            "AYC9Oi"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog(Dialog.PURGE_DATA_STORE));
    yield put(hideDialog(Dialog.PURGE_DATA_STORE_BULK));
    yield put(resetLoading(Loader.PURGE_DATA_STORE));
    yield put(resetLoading(Loader.PURGE_DATA_STORE_BULK));
  }
}

export function* validateHyperV({ data }) {
  try {
    yield put(setLoading(Loader.ADD_IVM));
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.ivmValidateHyperV, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(protectAction.validateIVMSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_IVM));
  }
}

export function* getAntiVirusDetailsForAssuredSecurityScan(data) {
  try {
    const loginReducer = yield select(getLogin);
    const resp = yield call(
      protectApi.getAntiVirusDetails,
      data.data,
      loginReducer.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getAntiVirusDetailsForAssuredSecurityScanSuccess(resp.data));
    } else {
      yield put(clearAntiVirusDetailsForAssuredSecurityScan());
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `MIh83N_${resp?.status}`)
        )
      );
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* getReplicationTasksForPolicy({ id }) {
  try {
    yield put(
      ccDataGridActions.startCCDataGridLoader(CCDATAGRID_ID.REPLICATION)
    );
    const loginReducer = yield select(getLogin);
    const resp = yield call(
      protectApi.getReplicationTasksForPolicy,
      id,
      loginReducer.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getReplicationTasksForPolicySuccess(resp.data));
      yield put(
        ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.REPLICATION)
      );
    } else {
      yield put(clearReplicationTasksForPolicy());
      yield put(getReplicationTasksForPolicySuccess([]));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `P8vPl6_${resp?.status}`)
        )
      );
      yield put(
        ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.REPLICATION)
      );
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* getReplicationTasksForSource({ id }) {
  try {
    yield put(
      ccDataGridActions.startCCDataGridLoader(CCDATAGRID_ID.REPLICATION)
    );
    const loginReducer = yield select(getLogin);
    const resp = yield call(
      protectApi.getReplicationTasksForSource,
      id,
      loginReducer.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(getReplicationTasksForSourceSuccess(resp.data));
      yield put(
        ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.REPLICATION)
      );
    } else {
      yield put(clearReplicationTasksForSource());
      yield put(getReplicationTasksForSourceSuccess([]));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `x7xb1o_${resp?.status}`)
        )
      );
      yield put(
        ccDataGridActions.resetCCDataGridLoader(CCDATAGRID_ID.REPLICATION)
      );
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* runAdhocReplicationForPolicy({ id, payload }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.REPLICATION_START));
    const resp = yield call(
      protectApi.runAdhocReplicationForPolicy,
      id,
      payload,
      loginReducer.token,
      false
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.replication-out", "4gF12N")
        )
      );
      yield put(clearReplicationTasksForPolicy());
      yield put(resetLoading(Loader.REPLICATION_START));
      yield put(hideDialog(Dialog.REPLICATION));
      yield put(ccDataGridActions.clearCCDataGrid(CCDATAGRID_ID.REPLICATION));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `eoMMlR_${resp?.status}`)
        )
      );
      yield put(resetLoading(Loader.REPLICATION_START));
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* runAdhocReplicationForSource({ id, payload }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.REPLICATION_START));
    const resp = yield call(
      protectApi.runAdhocReplicationForSource,
      id,
      payload,
      loginReducer.token,
      false
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData("toast-success.replication-out", "doKvAY")
        )
      );
      yield put(clearReplicationTasksForSource());
      yield put(resetLoading(Loader.REPLICATION_START));
      yield put(hideDialog(Dialog.REPLICATION));
      yield put(ccDataGridActions.clearCCDataGrid(CCDATAGRID_ID.REPLICATION));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(resp.errors[0].message, `92w3xU_${resp?.status}`)
        )
      );
      yield put(resetLoading(Loader.REPLICATION_START));
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* downloadAssuredSecurityScanDetails({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    const response = yield call(
      protectApi.downloadAssuredSecurityScanDetails,
      data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      if (response?.data?.download_task_url)
        window.open(response.data.download_task_url, "_blank");
    }
  } catch (error) {
    window.console.log(error);
  }
}

export default function* destination() {
  yield all([
    takeEvery(actionType.LOAD_REMOTE_CONSOLE_LIST, remoteConsoleList),
    takeEvery(actionType.LOAD_SHARED_FOLDER_LIST, sharedFolderList),
    takeEvery(actionType.FETCH_LIGHTWEIGHT_PROXIES, getLightWeightProxies),
    takeEvery(actionType.DELETE_REMOTE_CONSOLE, deleteRemoteConsole),
    takeEvery(actionType.DELETE_SHARED_FOLDER, deleteSharedFolder),
    takeEvery(actionType.UPDATE_SHARED_FOLDER, updateSharedFolder),
    takeEvery(
      actionType.LOAD_CURRENT_SHARED_FOLDER,
      loadCurrentSharedFolderSaga
    ),
    takeLatest(
      actionType.LOAD_RPS_LIST,
      cancelAbleSaga(loadRpsGridData, actionType.STOP_LOAD_RPS_LIST)
    ),
    takeLatest(
      actionType.LOAD_ALL_RPS_LIST,
      cancelAbleSaga(loadAllRpsGridData, actionType.STOP_LOAD_RPS_LIST)
    ),
    takeLatest(actionType.LOAD_DATA_STORES, loadDataStoresGridData),
    takeLatest(actionType.LOAD_ALL_DATA_STORES, loadAllDataStoresGridData),
    takeEvery(actionType.RPS_DATA_STORE_LIST, rpsDataStore),
    takeEvery(actionType.RPS_DATA_STORE_SOURCE_LIST, rpsDataStoreSource),
    takeEvery(actionType.CONNECT_SHARED_FOLDER, connectSharedFolder),
    takeEvery(actionType.GET_POLICY_LIST, getPolicyListForJumpstart),
    takeEvery(actionType.SAVE_JUMP_START, saveJumpStart),
    takeEvery(actionType.DELETE_DATA_STORE, deleteDataStoreData),
    takeEvery(actionType.START_DATA_STORE, startDataStore),
    takeEvery(actionType.STOP_DATA_STORE, stopDataStore),
    takeEvery(actionType.REFRESH_DATA_STORE, refreshDataStore),
    takeEvery(actionType.DISABLE_SHARE_DATA_STORE, disableShareDataStore),
    takeEvery(actionType.ADD_NEW_RPS, addNewRps),
    takeEvery(actionType.DELETE_RPS, deleteRps),
    takeEvery(actionType.REFRESH_RPS, refreshRps),
    takeEvery(actionType.UPDATE_RPS, updateRps),
    takeEvery(actionType.IMPORT_DATA_STORE_STEP_ONE, importDataStoreStepOne),
    takeEvery(actionType.IMPORT_DATA_STORE_STEP_TWO, importDataStoreStepTwo),
    takeEvery(actionType.LOAD_SHADOW_XAFE_LIST, shadowXafeList),
    takeEvery(
      actionType.IMPORT_CLOUD_DATA_STORE_STEP_ONE,
      importCloudDataStoreStepOne
    ),
    takeEvery(
      actionType.IMPORT_CLOUD_DATA_STORE_STEP_TWO,
      importCloudDataStoreSteptwo
    ),
    takeEvery(actionType.LOAD_END_POINT_LIST, endPointList),
    takeEvery(actionType.LOAD_CLOUD_ACCOUNT_LIST, cloudAccountList),
    takeEvery(
      actionType.LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_ID,
      cloudAccountListByRPSID
    ),
    // takeEvery(
    //   actionType.GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE,
    //   cloudAccountListByType
    // ),
    takeEvery(actionType.DELETE_CLOUD_ACCOUNT, deleteCloudAccount),
    takeEvery(
      actionType.TEST_CLOUD_ACCOUNT_CONNECTION,
      testCloudAccountConnection
    ),
    takeEvery(actionType.ADD_DATA_STORE_TO_RPS, addDataStore),
    takeEvery(actionType.ADD_CLOUD_DATA_STORE_TO_RPS, addCloudDataStore),
    takeEvery(actionType.UPDATE_DATA_STORE_RPS, modifyDataStore),
    takeEvery(actionType.UPDATE_CLOUD_DATA_STORE, modifyCloudDataStore),
    takeLatest(actionType.LOAD_CURRENT_RPS, loadCurrentRpsSaga),
    takeEvery(actionType.LOAD_CURRENT_DATA_STORE, loadCurrentDataStoreSaga),
    takeEvery(
      actionType.LOAD_CURRENT_DATA_STORE_SOURCE_RECOVERY,
      loadCurrentDataStoreForSourceRecovery
    ),
    takeEvery(actionType.LOAD_DATA_STORES_SEARCH, setDataStoreSearchOptions),
    takeLatest(
      actionType.GET_CURRENT_ACTION_CALLED_DATA_STORE,
      setDataStoreSearchOptions
    ),
    takeLatest(actionType.ADVANCE_SEARCH_LOADED, advancedSearchLoaded),
    takeLatest(
      actionType.RPS_DATA_STORE_REFRESH_RECOVERY_POINT,
      refreshRecoveryPoint
    ),
    takeLatest(
      actionType.SHARED_FOLDER_REFRESH_RECOVERY_POINT,
      refreshRecoveryPointSharedFolder
    ),
    takeEvery(actionType.INSTALL_DEPLOY_RPS, installDeployRps),
    takeEvery(actionType.INSTALL_DEPLOY_RPS_BULK, installDeployRpsBulk),
    takeEvery(actionType.CANCEL_SCHEDULED_DEPLOY_RPS, cancelScheduledDeploy),

    takeEvery(actionType.ON_SESSION_PASSWORD_APPLY, onSessionPasswordApply),
    takeEvery(actionType.AUTHENTICATE_SHARED_FOLDER, authenticateSharedFolder),
    takeEvery(
      actionType.GET_BROWSE_RECOVERY_POINT_DS,
      getBrowseRecoveryPointDSListHandler
    ),
    takeEvery(
      actionType.GET_BROWSE_RECOVERY_POINT_SF,
      getBrowseRecoveryPointSFListHandler
    ),
    takeEvery(
      actionType.RPS_DATA_STORE_SOURCE_LIST_ITEM_DELETE,
      deleteSourceItem
    ),
    takeEvery(
      actionType.RPS_DATA_STORE_SOURCE_LIST_ITEM_REFRESH,
      refreshSourceItem
    ),
    takeEvery(
      actionType.RPS_DATA_STORE_SOURCE_LIST_ITEM_MERGE,
      mergeSourceItem
    ),
    takeLatest(
      actionType.RPS_DATA_STORE_SOURCE_LIST_GET_RETENTION,
      getRetention
    ),
    takeLatest(actionType.RPS_DATA_STORE_SOURCE_LIST_RP_MERGE_NOW, mergeNow),
    takeEvery(actionType.GET_POLICY_DATA_BY_ID, getPolicyDataById),
    takeLatest(actionType.FETCH_AVAILABLE_VOLUMES, fetchAvailableVolumesSaga),
    takeEvery(actionType.FETCH_MOUNTED_VOLUMES, fetchMountedVolumesSaga),
    takeEvery(actionType.FETCH_MOUNTED_DATABASES, fetchMountedDatabasesSaga),
    takeLatest(actionType.DISMOUNT_VOLUME, dismountVolumeSaga),
    takeLatest(actionType.DISMOUNT_DATABASE, dismountDatabaseSaga),
    takeEvery(
      actionType.GET_RECOVERY_POINTS_BY_DESTINATION_PATH,
      getRecoveryPointsByDestinationPath
    ),
    takeEvery(
      createStandbyVMConstants.GET_RECOVERY_POINTS_BY_DESTINATION_PATH_FOR_VSB,
      getRecoveryPointsByDestinationPath
    ),
    takeEvery(actionType.GET_RECOVERY_ORACLE_VERSION, getRecoveryOracleVersion),
    takeEvery(
      actionType.GET_RECOVERY_ORACLE_DB_CONFIG,
      getRecoveryOracleDBConfigurations
    ),
    takeEvery(
      actionType.GET_DESTINATIONS_BY_SOURCE_ID,
      getDestinationsBySourceId
    ),
    takeEvery(actionType.GET_AVAILABLE_DRIVE_LETTERS, getAvailableDriveLetters),
    takeEvery(
      actionType.GET_SOURCES_WITH_WINDOWS_FILTER,
      getSourcesWithWindowsFilter
    ),
    takeEvery(actionType.GET_SQL_DB_INSTANCES, getSQLDBInstances),
    takeEvery(actionType.MOUNT_RECOVERY_POINT, postMountRecoveryPoint),
    takeEvery(actionType.MOUNT_SQL_DATABASE, postMountSQLDatabase),
    takeEvery(actionType.FORCE_DELETE_DATA_STORE, forceDeleteStoreData),
    takeEvery(actionType.FORCE_DELETE_RPS, forceDeleteRPS),
    takeLatest(
      actionType.FETCH_CLOUD_CONFIGURATIONS,
      fetchCloudAccountsConfSaga
    ),
    takeLatest(actionType.FETCH_CLOUD_SUBNET, fetchCloudAccountSubnetSaga),
    takeEvery(
      actionType.GET_REGIONS_FOR_CLOUD_ACCOUNT,
      getRegionsForCloudAccount
    ),
    takeEvery(
      actionType.GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT,
      getComputeRegionsForGCPCloudAccount
    ),
    takeEvery(
      actionType.GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT,
      getComputeZonesForGCPCloudAccount
    ),
    takeEvery(
      actionType.GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK,
      getGCPSecurityGroupsForSelectedNetwork
    ),
    takeEvery(
      actionType.GET_REGIONS_FOR_CLOUD_ACCOUNT_V3,
      getRegionsForCloudAccV3
    ),
    takeEvery(actionType.GET_BUCKETS_FOR_CLOUD_ACCOUNT, getBucketsForCloudAcc),
    takeEvery(
      actionType.GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT,
      getResouceGroupForCloudAccount
    ),
    takeEvery(actionType.IVM_VALIDATE_NFS, validateNFS),
    takeEvery(actionType.IVM_VALIDATE_HYPERV, validateHyperV),
    takeEvery(actionType.PURGE_DATASTORE_RECOVERY_POINTS, purgeDatstoreRPs),
    takeEvery(
      actionType.GET_ANTIVIRUS_DETAILS_FOR_ASSURED_SECURITY_SCAN,
      getAntiVirusDetailsForAssuredSecurityScan
    ),
    takeEvery(
      actionType.GET_REPLICATION_TASKS_FOR_POLICY,
      getReplicationTasksForPolicy
    ),
    takeEvery(
      actionType.GET_REPLICATION_TASKS_FOR_SOURCE,
      getReplicationTasksForSource
    ),
    takeEvery(
      actionType.RUN_ADHOC_REPLICATION_FOR_POLICY,
      runAdhocReplicationForPolicy
    ),
    takeEvery(
      actionType.RUN_ADHOC_REPLICATION_FOR_SOURCE,
      runAdhocReplicationForSource
    ),
    takeEvery(
      actionType.DOWNLOAD_ASSURED_SECURITY_SCAN_DETAILS,
      downloadAssuredSecurityScanDetails
    )
  ]);
}
