import React from "react";
import FormattedMessage, {
  FormattedHTMLMessage
} from "../common/formatted-message";
import changeTitle from "../../utils/changeTitleUtil";
import "../../styles/login.css";
import logoUrl from "../../../src/assets/images/arcserve-logo-black.png";
import enrollIcon from "assets/images/enroll-icon-white.png";

class Maintenance extends React.Component {
  componentDidMount() {
    changeTitle(
      "Arcserve Business Continuity Cloud",
      logoUrl,
      "DEFAULT_ARCSERVE"
    );
  }
  render() {
    const welcomeMessage = (
      <FormattedHTMLMessage
        id="welcome-message"
        values={{
          organization: "Arcserve Business Continuity Cloud"
        }}
      />
    );
    const maintenanceMessage = (
      <FormattedHTMLMessage id="maintenance-message" />
    );
    const message = <FormattedHTMLMessage id="message" />;
    return (
      <div className="login-container d-flex align-items-center justify-content-center">
        <div className="left-section">
          <img className="logo" alt="Logo" src={logoUrl} />

          <div className="login-branding-msg">{message}</div>
          <span className="copyright-text">
            <FormattedMessage id="maintenance.copyright.text" />
          </span>
        </div>
        <form>
          <div className="right-section d-flex flex-column">
            <div className="right-section-header d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-end">
                <h2>
                  <FormattedMessage
                    id="user.login.header"
                    defaultMessage="Login"
                  />
                </h2>
              </div>
              <img alt="enroll-icon" src={enrollIcon} />
            </div>
            <div className="input-wrapper d-flex flex-column">
              <h5>{welcomeMessage}</h5>
              <div id="maintenanceMsg">
                <h5>{maintenanceMessage}</h5>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Maintenance;
