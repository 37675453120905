import React from "react";
import PropTypes from "prop-types";

import CCGraph from "./CCGraph";

const { Provider, Consumer } = React.createContext("CCGraph");

export const CCGraphProvider = Provider;
export const CCGraphConsumer = Consumer;

const createCCGraph = config => {
  const Ret = ({ children = [], ...rest }) => (
    <CCGraph config={config} {...rest}>
      {children}
    </CCGraph>
  );

  Ret.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node)
    ])
  };

  Ret.defaultProps = {
    children: []
  };

  return Ret;
};

export default createCCGraph;
