import React, { useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getOriginalRoleId,
  getLoginToken,
  getIdToken,
  getLoginTokenExpiry,
  getLogoutState
} from "state/selectors";
import { OCTA_LOGOUT, OCTA_LOGIN_REDIRECT } from "state/api/apiUrlConstants";
import { makeImpersonationViewState } from "state/selectors/HomeSelectors";
import checkAuthorization from "utils/authorizationUtil";
import { isCSRUser, isMspMonitorUser, isDirectMonitorUser } from "utils";
import * as actions from "state/actions/actions";
import Cookies from "universal-cookie";

// function deleteCookies() {
//   var allCookies = document.cookie.split(";");

//   // The "expire" attribute of every cookie is
//   // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
//   for (var i = 0; i < allCookies.length; i++)
//     document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();

//   console.log("cookeisss", document.cookie);
// }
// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work

export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);

const PrivateRoute = ({
  component: Component,
  loginToken,
  idToken = "",
  loginTokenExpiry,
  originalRoleId,
  isImpersonateView,
  octaLogout,
  dispatch,
  ...rest
}) => {
  const [redirectToLogout, setRedirectToLogout] = useState(false);
  // useEffect(()=>{
  //   if(octaLogout) {
  //     setRedirectToLogout(true);
  //     dispatch(actions.unsetOctaLogout());
  //   }
  // }, [octaLogout])

  return (
    <Route
      {...rest}
      render={props => {
        if (isCSRUser(originalRoleId) && !isImpersonateView) {
          return <Redirect to={{ pathname: "/csr" }} />;
        }

        if (
          originalRoleId &&
          isMspMonitorUser(originalRoleId) &&
          rest.location.pathname.includes("configure")
        ) {
          return <Redirect to={{ pathname: "/monitor" }} />;
        }

        if (
          originalRoleId &&
          isDirectMonitorUser(originalRoleId) &&
          (rest.location.pathname.includes("/protect") ||
            rest.location.pathname.includes("configure"))
        ) {
          return <Redirect to={{ pathname: "/monitor" }} />;
        }
        if (redirectToLogout) {
          let token = localStorage.getItem("idtoken");
          //localStorage.removeItem('idtoken');
          const cookies = new Cookies();
          console.log("tg1");
          cookies.remove("isSignedIn", { path: "/", domain: ".arcserve.com" });
          cookies.remove("cc", { path: "/", domain: ".arcserve.com" });
          window.location.href = `${OCTA_LOGOUT}?id_token_hint=${token}`;
          return null;
        }
        if (octaLogout) {
          setRedirectToLogout(true);
          dispatch(actions.unsetOctaLogout());
          return null;
        }

        if (checkAuthorization(loginToken, loginTokenExpiry).redirect) {
          return <Component {...props} />;
        } else if (!checkAuthorization(loginToken, loginTokenExpiry).redirect) {
          if (checkAuthorization(loginToken, loginTokenExpiry).tokenExpire) {
            //const cookies = new Cookies();
            dispatch(actions.logOut());
            return null;
            //let ucOpened = cookies.get("uc");
            // if (ucOpened) {
            //   console.log("tg2");
            //   props.history.push("/sessionExpired");
            //   return null;
            // } else {
            //   console.log("tg3");
            //   dispatch(actions.logOut());
            //   return null;
            // }
          }
        }
        // var original_path = props.location.pathname + props.location.search;
        // if (isImpersonateView) original_path = "";
        // when impersonation view is on and token expires , the orginal path is set to "" to prevent user to going back to previous path
        //deleteCookies();
        window.location.href = OCTA_LOGIN_REDIRECT;
        return null;
        // return (
        //   <Redirect
        //     to={{
        //       pathname: "/login",
        //       state: {
        //         original_path
        //       }
        //     }}
        //   />
        // );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  loginTokenExpiry: PropTypes.number.isRequired,
  loginToken: PropTypes.string.isRequired,
  originalRoleId: PropTypes.string.isRequired,
  isImpersonateView: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const getImpersonationView = makeImpersonationViewState();
  return {
    // Grab the token from State
    loginToken: getLoginToken(state),
    idToken: getIdToken(state),
    //Grab token expirty from State
    loginTokenExpiry: getLoginTokenExpiry(state),
    originalRoleId: getOriginalRoleId(state),
    isImpersonateView: getImpersonationView(state),
    octaLogout: getLogoutState(state)
  };
};

export default connect(mapStateToProps)(PrivateRoute);

const PrivateCsrRouteWithout = ({ path, component, originalRoleId }) => {
  if (!isCSRUser(originalRoleId)) {
    window.location.href = OCTA_LOGIN_REDIRECT;
    return null;
  }

  return <Route path={path} component={component} />;
};

PrivateCsrRouteWithout.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  originalRoleId: PropTypes.string.isRequired
};

export const PrivateCsrRoute = connect(state => ({
  originalRoleId: getOriginalRoleId(state)
}))(PrivateCsrRouteWithout);
