import { combineReducers } from "redux";
import * as C from "state/constants";

const auditTrailSearchInitialState = {
  isLoading: false,
  auditTrail: {
    data: []
  }
};

const auditTrailSearch = (state = auditTrailSearchInitialState, action) => {
  switch (action.type) {
    case C.FETCH_AUDIT_TRAIL_SEARCH_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case C.FETCH_AUDIT_TRAIL_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        auditTrail: action.payload
      });
    case C.FETCH_AUDIT_TRAIL_SEARCH_FAILURE:
      return Object.assign({}, state, auditTrailSearchInitialState);
    default:
      return state;
  }
};

const auditTrailCodesInitialState = {
  isLoading: false,
  auditCodes: {
    data: []
  }
};

const auditTrailCodes = (state = auditTrailCodesInitialState, action) => {
  switch (action.type) {
    case C.FETCH_AUDIT_CODES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case C.FETCH_AUDIT_CODES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        auditCodes: action.payload
      });
    case C.FETCH_AUDIT_CODES_FAILURE:
      return Object.assign({}, state, auditTrailCodesInitialState);
    default:
      return state;
  }
};

const auditTrail = combineReducers({
  auditTrailCodes,
  auditTrailSearch
});

export default auditTrail;
