import { registerReducer } from "../store";

const moduleCreator = store => (name, moduleProvider) => {
  if (moduleCreator.hasOwnProperty(name)) {
    return Promise.resolve(moduleCreator[name]);
  } else {
    return moduleProvider.then(mod => {
      registerReducer(store, name, mod.reducer);
      return mod;
    });
  }
};
export default moduleCreator;
