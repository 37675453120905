import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { ID_PREFIX } from "configs/IdConfig";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import TooltipComponent from "components/common/tooltip-mui";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const LoadingDots = () => {
  return (
    <div className="dropdown-loding-icon">
      {" "}
      &nbsp;
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
    </div>
  );
};

const ActionDropdown = ({
  showDateRange,
  onMenuItemClick,
  optionIndex,
  render,
  hasi18nSupport,
  disabledOptions,
  skipDefaultOption,
  defaultOptionKey,
  intl,
  defaultOptionValue,
  variant,
  pullRight,
  dropup,
  loading,
  label,
  id,
  testId,
  keyAsTestId,
  value,
  disabled,
  customStyle,
  className = "",
  customMenuClassName = "",
  hideFloatingLabel,
  displayValue, //displays the value as is
  hideOutline,
  options,
  autoSelectFirstOption,
  meta: { error },
  showTooltip,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    // Select First value by Default
    if (autoSelectFirstOption && options && options.length > 0) {
      onMenuItemClick({
        key: options[0] && options[0].value,
        value: options[0] && options[0].value
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options), autoSelectFirstOption]);
  const handleChange = event => {
    onMenuItemClick({ key: event.target.value, value: event.target.value });
    setTooltipOpen(false);
  };
  const handleTooltip = bool => {
    showTooltip ? setTooltipOpen(bool) : setTooltipOpen(false);
  };
  const processMenuItem = (
    item,
    optionIndex,
    isDisabled = false,
    menuid,
    keyAsTestId
  ) => {
    let customKey = item.value
      ? item.value.toString()
      : item.key
      ? item.key.toString()
      : item.toString();
    if (displayValue) {
      return (
        <MenuItem
          value={item.key}
          key={item.key}
          id={
            menuid
              ? `${menuid}_${
                  keyAsTestId ? customKey.split(".").pop() : optionIndex
                }`
              : ""
          }
        >
          {item.value}
        </MenuItem>
      );
    }
    return item ? (
      <MenuItem
        disabled={isDisabled}
        value={item.key}
        key={item.key}
        id={
          menuid
            ? `${menuid}_${
                keyAsTestId ? customKey.split(".").pop() : optionIndex
              }`
            : ""
        }
      >
        {hasi18nSupport ? (
          <FormattedBrandMessage
            id={
              item.value
                ? item.value.toString()
                : item.key
                ? item.key.toString()
                : item.toString()
            }
            defaultMessage={
              item.value
                ? item.value.toString()
                : item.key
                ? item.key.toString()
                : item.toString()
            }
          />
        ) : typeof item === "object" ? (
          item.key
        ) : (
          item
        )}
      </MenuItem>
    ) : (
      false
    );
  };
  const renderMenuItem = (menuid, keyAsTestId) => {
    if (options && options.length + disabledOptions.length === 0)
      return <div />;
    const cloneOptions = cloneDeep(options);
    if (!skipDefaultOption) {
      cloneOptions.unshift({
        key: hasi18nSupport
          ? defaultOptionKey
          : intl.formatMessage({
              id: defaultOptionKey,
              defaultMessage: defaultOptionKey
            }),
        value: defaultOptionValue
      });
    }
    return (
      cloneOptions?.length > 0 &&
      cloneOptions
        .map((item, index) =>
          processMenuItem(item, index, false, menuid, keyAsTestId)
        )
        .concat(
          disabledOptions.map((item, index) =>
            processMenuItem(item, index, true, menuid, keyAsTestId)
          )
        )
    );
  };

  let title = props.title ? (
    typeof props.title === "string" ? (
      <FormattedBrandMessage id={props.title} defaultMessage={props.title} />
    ) : (
      props.title
    )
  ) : null;

  let inputid = testId ? `${ID_PREFIX}${testId}` : id ? id : "";
  let menuid = testId ? `${ID_PREFIX}${testId}` : id ? id : "";
  let selectid = testId ? `${ID_PREFIX}${testId}` : id ? id : "";

  const formControl = {
    "& .MuiInputLabel-formControl": {
      fontSize: "13px",
      fontFamily: "Sans-Regular",
      position: "relative",
      top: "20px"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      backgroundColor: "white",
      fontSize: "1rem",
      position: "absolute",
      top: "0"
    },
    "& .MuiFormLabel-filled": {
      backgroundColor: "white",
      fontSize: "1rem",
      position: "absolute",
      top: "0"
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      backgroundColor: "rgba(0,0,0,0.12)",
      color: "rgba(0,0,0,0.26)",
      "& > fieldset": {
        borderColor: error && "#EE6A5D !important"
      },
      "& .MuiSelect-select": {
        cursor: showTooltip ? "pointer" : "default"
      }
    },
    width: !(customStyle && customStyle.hasOwnProperty("width"))
      ? "100%"
      : null,
    marginTop: 0
  };
  const isEmpty = options?.length === 0;
  return (
    <div style={formControl}>
      <FormControl
        variant={hideOutline ? "standard" : "outlined"}
        sx={formControl}
        error={error}
      >
        {label ? (
          <InputLabel id={`outlined-age-native-simple-${label}`}>
            <b>{label}</b>
          </InputLabel>
        ) : null}
        <TooltipComponent
          arrow
          placement="top-start"
          open={tooltipOpen}
          content={title}
          dropdown
          popperClassName="dropdown-tooltip-margin"
        >
          <Select
            disableUnderline={hideOutline ? hideOutline : false}
            labelId={`outlined-age-native-simple-${label}`}
            id={selectid}
            disabled={disabled || isEmpty || loading}
            displayEmpty={true}
            defaultValue=""
            // value={options && options.length > 0 ? value : ""}
            value={value}
            renderValue={
              loading ? () => <LoadingDots /> : title ? () => title : () => {}
            }
            onChange={handleChange}
            label={label || null}
            role="button"
            style={customStyle}
            sx={{
              height: "37px",
              fontFamily: "Sans Source Pro",
              fontSize: "13px"
            }}
            className={`${className} mui-action-select-dropdown`}
            inputProps={{
              name: label,
              id: inputid ? `${inputid}_input` : "",
              "aria-label": "Without label"
            }}
            MenuProps={{
              id: `${menuid}_menu`,
              PaperProps: { className: customMenuClassName }
            }}
            onMouseEnter={() => {
              handleTooltip(true);
            }}
            onMouseLeave={() => {
              handleTooltip(false);
            }}
            onClick={() => {
              handleTooltip(false);
            }}
          >
            {renderMenuItem(menuid ? `${menuid}_menu` : "", keyAsTestId)}
          </Select>
        </TooltipComponent>
      </FormControl>
    </div>
  );
};

ActionDropdown.defaultProps = {
  dropup: false,
  pullRight: true,
  disabled: false,
  hasi18nSupport: true,
  options: [],
  title: "",
  optionIndex: 0,
  render: false,
  showDateRange: false,
  skipDefaultOption: true,
  defaultOptionKey: "select",
  defaultOptionValue: null,
  onMenuItemClick: () => {},
  disabledOptions: [],
  className: "",
  loading: false,
  autoSelectFirstOption: false,
  meta: {}
};

ActionDropdown.propTypes = {
  defaultOptionKey: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  dropup: PropTypes.bool,
  pullRight: PropTypes.bool,
  autoSelectFirstOption: PropTypes.bool,
  menuAlign: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  disabledOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  onMenuItemClick: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  hasi18nSupport: PropTypes.bool,
  optionIndex: PropTypes.number,
  render: PropTypes.bool,
  showDateRange: PropTypes.bool,
  skipDefaultOption: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  meta: PropTypes.objectOf()
};

export default injectIntl(React.memo(ActionDropdown));
