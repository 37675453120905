import React from "react";
import _ from "lodash";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { ID_CONSTANTS } from "../../../utils/appConstants";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";

const LoadingDots = () => {
  return (
    <div className="loading-dropdown-dots">
      {" "}
      &nbsp;
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
      <div className="state-loading-bar" />
    </div>
  );
};

const ActionDropdown = ({
  showDateRange,
  onMenuItemClick,
  optionIndex,
  render,
  hasi18nSupport,
  disabledOptions,
  skipDefaultOption,
  defaultOptionKey,
  intl,
  defaultOptionValue,
  variant,
  pullRight,
  dropup,
  loading,
  ...props
}) => {
  /**
   * stop event propagation to table header cell
   */
  const handlebuttonClicked = e => {
    const _elemToStop = e.currentTarget.parentNode.parentNode;
    if (_elemToStop.id === "grid-header-dropdown-wrapper") {
      e.stopPropagation();
    }
  };

  const handleOnSelect = eventKey => {
    let event = Object.assign(
      {},
      {
        ...eventKey,
        showPicker: showDateRange,
        key: "single_select"
      }
    );
    if (onMenuItemClick) {
      render
        ? onMenuItemClick(optionIndex, [{ ...event }])
        : onMenuItemClick(eventKey);
    }
  };

  const processMenuItem = (item, optionIndex, isDisabled = false) => {
    return item ? (
      <Dropdown.Item
        id={`${ID_CONSTANTS.ACTION_DROPDOWN_MENUITEM}_${optionIndex}`}
        key={optionIndex}
        onSelect={() => handleOnSelect(item)}
        eventKey={item}
        disabled={isDisabled}
      >
        {hasi18nSupport ? (
          <FormattedBrandMessage
            id={
              item.value
                ? item.value.toString()
                : item.key
                ? item.key.toString()
                : item.toString()
            }
            defaultMessage={
              item.value
                ? item.value.toString()
                : item.key
                ? item.key.toString()
                : item.toString()
            }
          />
        ) : (
          <span>{typeof item === "object" ? item.key : item}</span>
        )}
      </Dropdown.Item>
    ) : (
      false
    );
  };

  /**
   * to render menu items for dropdown
   */
  const renderMenuItem = () => {
    if (props.options && props.options.length + disabledOptions.length === 0)
      return <div />;
    let options = _.cloneDeep(props.options);
    if (!skipDefaultOption) {
      options.unshift({
        key: hasi18nSupport
          ? defaultOptionKey
          : intl.formatMessage({
              id: defaultOptionKey,
              defaultMessage: defaultOptionKey
            }),
        value: defaultOptionValue
      });
    }
    return (
      options &&
      options
        .map((item, index) => processMenuItem(item, index, false))
        .concat(
          disabledOptions.map((item, index) =>
            processMenuItem(item, index, true)
          )
        )
    );
  };

  const dropdownClass = props.title ? _.get(props, "className", "") : "no-text";

  let title = props.title ? (
    typeof props.title === "string" ? (
      <FormattedBrandMessage id={props.title} defaultMessage={props.title} />
    ) : (
      props.title
    )
  ) : (
    ""
  );

  return (
    <React.Fragment>
      <DropdownButton
        title={title}
        variant={variant || "default"}
        pullRight={pullRight}
        menuAlign={_.get(props, "menuAlign", "left")}
        dropup={+dropup}
        className={dropdownClass}
        onClick={handlebuttonClicked}
        disabled={
          props.disabled
            ? props.disabled
            : props.options && !props.options.length
        }
      >
        {renderMenuItem()}
      </DropdownButton>
      {/* Show loading dots on dropdown */}
      {loading && <LoadingDots />}
    </React.Fragment>
  );
};

ActionDropdown.defaultProps = {
  dropup: false,
  pullRight: true,
  disabled: false,
  hasi18nSupport: true,
  options: [],
  title: "",
  optionIndex: 0,
  render: false,
  showDateRange: false,
  skipDefaultOption: true,
  defaultOptionKey: "select",
  defaultOptionValue: null,
  onMenuItemClick: () => {},
  disabledOptions: [],
  className: "",
  loading: false
};

ActionDropdown.propTypes = {
  defaultOptionKey: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  dropup: PropTypes.bool,
  pullRight: PropTypes.bool,
  menuAlign: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  disabledOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  onMenuItemClick: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  hasi18nSupport: PropTypes.bool,
  optionIndex: PropTypes.number,
  render: PropTypes.bool,
  showDateRange: PropTypes.bool,
  skipDefaultOption: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool
};

export default injectIntl(React.memo(ActionDropdown));
