import { combineReducers } from "redux";
import {
  LOAD_RPS_LIST_SUCCESS,
  SET_CURRENT_RPS,
  REFRESH_RPS_SUCCESS,
  SET_CURRENT_RPS_ID,
  INITIALIZE_APP,
  CLEAR_RPS_LIST,
  LOAD_END_POINT_LIST_SUCCESS
} from "state/actions/actionTypes";

const initialState = {
  endPointsList: []
};

function rpsResponse(state = null, action) {
  switch (action.type) {
    case LOAD_RPS_LIST_SUCCESS:
      return action.data;
    case REFRESH_RPS_SUCCESS:
      return {
        ...state,
        data: state.data.map(rps =>
          rps.server_id === action.data.server_id ? action.data : rps
        )
      };
    case CLEAR_RPS_LIST:
      return null;
    default:
      return state;
  }
}

function currentRpsId(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_RPS_ID:
      return action.id;
    case INITIALIZE_APP:
      return null;
    default:
      return state;
  }
}

function currentRps(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_RPS_ID:
      return null;
    case SET_CURRENT_RPS:
      return action.data;
    case INITIALIZE_APP:
      return null;
    default:
      return state;
  }
}

function getEndPointsList(state = initialState, action) {
  switch (action.type) {
    case LOAD_END_POINT_LIST_SUCCESS:
      return {
        ...state,
        endPointsList: action.data
      };
    default:
      return state;
  }
}

export default combineReducers({
  rpsResponse,
  currentRps,
  currentRpsId,
  getEndPointsList
});
