import {
  GET_REPORT_LIST,
  SHOW_REPORT_LIST,
  GET_REPORT_SCHEDULES,
  UPDATE_REPORT_SCHEDULE,
  DELETE_SCHEDULED_REPORT_FOR_MANAGE_SCHEDULES,
  DELETE_ANALYZE_ALERT,
  DELETE_REPORT_BY_ID,
  DELETE_SCHEDULE_REPORT,
  RUN_REPORT_NOW,
  SHOW_REPORT_OVERLAY,
  UPDATE_REPORT_DATA,
  UPDATE_COLUMN_ANALYZE_DATA,
  CLOSE_REPORT_MODAL,
  CREATE_REPORT,
  CREATE_ANALYZE_ALERT,
  FETCH_ANALYZE_ALERTS,
  UPDATE_ANALYZE_ALERT,
  UPDATE_REPORT_SETTINGS,
  SHOW_SELECTED_USAGE_THRESHOLD,
  REMOVE_ASSIGNED_SOURCE_GROUP,
  UPDATE_ASSIGNED_SOURCE_GROUPS,
  REMOVE_ASSIGNED_EMAIL,
  UPDATE_ASSIGNED_EMAILS,
  FETCH_BACKUP_JOBS,
  UPDATE_BACKUP_JOBS_TOP_SOURCES,
  FILTER_BACKUP_JOBS_TOP_SOURCES,
  UPDATE_BACKUP_JOBS_TREND_SUMMARY,
  GET_REPORT_COLUMN_DATA,
  GET_DATA_TRANSFER_TREND_SUMMARY,
  UPDATE_DATA_TRANSFER_TREND_SUMMARY,
  UPDATE_DATA_TRANSFER_TREND_TOP_SOURCE,
  GET_DATA_TRANSFER_TREND_TOP_SOURCE,
  GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE,
  GET_JOBS_AND_DESTINATION_JOBS_SUMMARY,
  GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION,
  UPDATE_JOBS_AND_DESTINATION_JOBS_SUMMARY,
  UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE,
  UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION,
  GET_CAPACITY_USAGE_CD_TREND_USAGE,
  GET_CAPACITY_USAGE_CH_TREND_USAGE,
  GET_CAPACITY_USAGE_CH_STORE_SAVING_TREND,
  UPDATE_CAPACITY_USAGE_CD_TREND_USAGE,
  UPDATE_CAPACITY_USAGE_CH_TREND_USAGE,
  UPDATE_CAPACITY_USAGE_CH_STORE_SAVING_TREND,
  GET_RESTORE_JOBS,
  UPDATE_RESTORE_JOBS_TOP_SOURCES,
  UPDATE_RESTORE_JOBS_TREND_SUMMARY,
  FILTER_RESTORE_JOBS_TOP_SOURCES,
  FETCH_RESTORE_JOBS_TREND_SUMMARY,
  REFRESH_BACKUP_JOBS_SUMMARY,
  JOBS_AND_DESTINATION_ADVANCE_SEARCH,
  UPDATE_DATE_RANGE_VALUE,
  CAPACITY_USAGE_ADVANCE_SEARCH,
  DATA_TRANSFER_ADVANCE_SEARCH,
  UPDATE_REPORT_TYPE_FOR_COMPONENT,
  REFRESH_BACKUP_TOP_SOURCES,
  FETCH_SINGLE_REPORT_DETAILS,
  UPDATE_SINGLE_REPORT_DETAILS,
  UPDATE_SINGLE_REPORT_REDIRECTION,
  INITIALIZE_EMAIL_SELECTOR,
  UPDATE_ASSIGNED_ORGANIZATIONS,
  UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT,
  REMOVE_ASSIGNED_ORGANIZATION,
  RESET_REPORT_MODAL,
  EXPORT_REPORT_AS_CSV,
  UPDATE_JOBLOG_JOBDATA,
  UPDATE_DATE_RANGE_VALUE_CAPACITY_DATATRANSFER,
  UPDATE_SECONDARY_NAVIGATION,
  UPDATE_ACCOUNT_DETAILS_REPORT_DATA,
  UPDATE_SOURCE_PROTECTION_SOURCES_SUMMARY
} from "../actions/actionTypes";

export const exportReportAsCSV = (data, dgId) => ({
  type: EXPORT_REPORT_AS_CSV,
  data,
  dgId
});

/**
 *
 * @param {*} data
 * @param {*} filterType
 * Populate state with list of reports
 */
export const showReportList = (data, filterType) => ({
  type: SHOW_REPORT_LIST,
  data,
  filterType
});

export const deleteReportByID = (id, dgId) => ({
  type: DELETE_REPORT_BY_ID,
  id,
  dgId
});

export const deleteScheduleReport = (id, dgId) => ({
  type: DELETE_SCHEDULE_REPORT,
  id,
  dgId
});

export const runReportNow = data => ({
  type: RUN_REPORT_NOW,
  data
});

/**
 * To get report data from id
 * @param id
 * @param trigger
 * @returns {{type: string, id: *}}
 */
export const renderReportOverlay = (data, trigger) => ({
  type: SHOW_REPORT_OVERLAY,
  data,
  trigger
});

export const initializeEmailSelector = data => ({
  type: INITIALIZE_EMAIL_SELECTOR,
  data
});

/**
 * To get report data from id
 * @param data
 * @returns {{type: string, id: *}}
 */
export const updateReport = data => ({
  type: UPDATE_REPORT_DATA,
  data
});

/**
 * To get report data from id
 * @returns {{type: string, id: *}}
 */
export const closeReportModal = () => ({
  type: CLOSE_REPORT_MODAL
});

/**
 * Create new report
 * @param {*} data
 */
export const createReport = (data, dgId) => ({
  type: CREATE_REPORT,
  data,
  dgId
});

/**
 * Create new Alert
 * @param {*} data
 */
export const createAnalyzeAlert = data => ({
  type: CREATE_ANALYZE_ALERT,
  data
});

/**
 * Fetch Alerts
 * @param {*} data
 */
export const fetchAnalyzeAlerts = data => ({
  type: FETCH_ANALYZE_ALERTS,
  data
});

/**
 * Create new Alert
 * @param {*} data
 */
export const updateAnalyzeAlert = data => ({
  type: UPDATE_ANALYZE_ALERT,
  data
});

export const resetReportModal = () => ({
  type: RESET_REPORT_MODAL
});

/**
 * To update settings of a previously existing report
 * @param {*} id
 * @param {*} data
 */
export const updateReportSettings = (data, dgId) => ({
  type: UPDATE_REPORT_SETTINGS,
  data,
  dgId
});

/**
 * show set usage threshold on form
 */
export const showThresholdOnForm = data => ({
  type: SHOW_SELECTED_USAGE_THRESHOLD,
  data
});

export const removeAssignedSourceGroup = id => ({
  type: REMOVE_ASSIGNED_SOURCE_GROUP,
  id
});

export const updateAssignedSourceGroups = data => ({
  type: UPDATE_ASSIGNED_SOURCE_GROUPS,
  data
});

export const removeAssignedOrganization = id => ({
  type: REMOVE_ASSIGNED_ORGANIZATION,
  id
});

export const updateAssignedOrganizations = data => ({
  type: UPDATE_ASSIGNED_ORGANIZATIONS,
  data
});

export const updateAssignedOrgsWhenEdit = data => ({
  type: UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT,
  data
});

export const removeAssignedEmail = id => ({
  type: REMOVE_ASSIGNED_EMAIL,
  id
});

export const updateAssignedEmails = data => ({
  type: UPDATE_ASSIGNED_EMAILS,
  data
});

/**
 * To get fetch backup jobs data
 */
//TODO clean up
export const fetchBackupJobsData = payload => ({
  type: FETCH_BACKUP_JOBS,
  payload
});

/**
 * To get fetch backup jobs data
 */
export const updateSelectedDateRangeValue = payload => ({
  type: UPDATE_DATE_RANGE_VALUE,
  payload
});

/**
 * To get fetch backup jobs data
 */
export const updateSelectedDateRangeValueCapacityDatatransfer = payload => ({
  type: UPDATE_DATE_RANGE_VALUE_CAPACITY_DATATRANSFER,
  payload
});

export const updateAnalyzeColumnData = (data, colunmType) => ({
  type: UPDATE_COLUMN_ANALYZE_DATA,
  data,
  colunmType
});

/**
 * To update backup jobs data in reducer
 */
export const updateBackupJobsTopSources = data => ({
  type: UPDATE_BACKUP_JOBS_TOP_SOURCES,
  data
});

/**
 * To update backup jobs data in reducer
 */
export const refreshBackupJobsTopSources = data => ({
  type: REFRESH_BACKUP_TOP_SOURCES,
  data
});
/**
 * To filter backup jobs top sources data in reducer
 */
export const filterTopSourceData = filterType => ({
  type: FILTER_BACKUP_JOBS_TOP_SOURCES,
  filterType
});

/**
 * To filter backup jobs top sources data in reducer
 */
export const refreshBackupJobsSummaryData = payload => ({
  type: REFRESH_BACKUP_JOBS_SUMMARY,
  payload
});

/**
 * To update backup jobs data in reducer
 */
export const updateBackupJobsTrendSummary = data => ({
  type: UPDATE_BACKUP_JOBS_TREND_SUMMARY,
  data
});

/**
 * To update jobs and destination data in reducer
 */
export const jobsAndDestinationAdvancedSearch = payload => ({
  type: JOBS_AND_DESTINATION_ADVANCE_SEARCH,
  payload
});

/**
 * To update data transfer jobs data in reducer
 */
export const dataTransferAdvancedSearch = payload => ({
  type: DATA_TRANSFER_ADVANCE_SEARCH,
  payload
});

/**
 * To update capacity usage data in reducer
 */
export const capacityUsageAdvancedSearch = payload => ({
  type: CAPACITY_USAGE_ADVANCE_SEARCH,
  payload
});

/**
 * @param {*} payload | Fetch report list
 */
export const getReportList = payload => ({
  type: GET_REPORT_LIST,
  payload
});

export const getReportSchedules = payload => ({
  type: GET_REPORT_SCHEDULES,
  payload
});

export const updateReportSchedule = (payload, dgId) => ({
  type: UPDATE_REPORT_SCHEDULE,
  payload,
  dgId
});

export const deleteScheduleReportForManageSchedules = (
  { id = null, ids, deleteInstances, fetchOptions },
  dgId
) => ({
  type: DELETE_SCHEDULED_REPORT_FOR_MANAGE_SCHEDULES,
  payload: {
    ids: id !== null ? [id] : ids,
    deleteInstances,
    fetchOptions
  },
  dgId
});

export const deleteAnalyzeAlert = ({
  id = null,
  ids,
  deleteInstances,
  fetchOptions
}) => ({
  type: DELETE_ANALYZE_ALERT,
  payload: {
    ids: id !== null ? [id] : ids
  }
});

/**
 * @param {*} payload | Fetch restore jobs data
 */
export const getRestoreJobs = payload => ({
  type: GET_RESTORE_JOBS,
  payload
});
/**
 * To update backup jobs data in reducer
 */
export const updateRestoreJobsTopSources = data => ({
  type: UPDATE_RESTORE_JOBS_TOP_SOURCES,
  data
});
/**
 * To update backup jobs data in reducer
 */
export const updateRestoreJobsTrendSummary = data => ({
  type: UPDATE_RESTORE_JOBS_TREND_SUMMARY,
  data
});

/**
 * To filter restore jobs top sources data in reducer
 */
export const fetchRestoreJobsTopSourceData = filterType => ({
  type: FILTER_RESTORE_JOBS_TOP_SOURCES,
  filterType
});

/**
 * To filter restore jobs trend summary data
 */
export const fetchRestoreJobsTrendSummaryData = () => ({
  type: FETCH_RESTORE_JOBS_TREND_SUMMARY
});

export const getReportColumnData = () => ({
  type: GET_REPORT_COLUMN_DATA
});

// Data transfer actions
export const getDataTransferTrendSummary = payload => ({
  type: GET_DATA_TRANSFER_TREND_SUMMARY,
  payload
});
export const getDataTransferTopSources = payload => ({
  type: GET_DATA_TRANSFER_TREND_TOP_SOURCE,
  payload
});

export const updateDataTransferTrendSummary = data => ({
  type: UPDATE_DATA_TRANSFER_TREND_SUMMARY,
  data
});

export const updateDataTransferTopSources = data => ({
  type: UPDATE_DATA_TRANSFER_TREND_TOP_SOURCE,
  data
});

// Jobs and destination actions
export const getJobsAndDestinationTopSources = payload => ({
  type: GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE,
  payload
});
export const getJobsAndDestinationJobsSummary = payload => ({
  type: GET_JOBS_AND_DESTINATION_JOBS_SUMMARY,
  payload
});
export const getJobsAndDestinationTopDestination = payload => ({
  type: GET_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION,
  payload
});

export const updateJobsAndDestinationJobsSummary = data => ({
  type: UPDATE_JOBS_AND_DESTINATION_JOBS_SUMMARY,
  data
});

export const updateJobsAndDestinationTopSources = data => ({
  type: UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE,
  data
});

export const updateJobsAndDestinationTopDestination = data => ({
  type: UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION,
  data
});

//Capacity usage grid and chart
export const getCapacityUsageCDTrendUsage = payload => ({
  type: GET_CAPACITY_USAGE_CD_TREND_USAGE,
  payload
});
export const getCapacityUsageCHTrendUsage = payload => ({
  type: GET_CAPACITY_USAGE_CH_TREND_USAGE,
  payload
});
export const getCapacityUsageCHSavingTrend = payload => ({
  type: GET_CAPACITY_USAGE_CH_STORE_SAVING_TREND,
  payload
});

export const UpdateCapacityUsageCDTrendUsage = data => ({
  type: UPDATE_CAPACITY_USAGE_CD_TREND_USAGE,
  data
});

export const UpdateCapacityUsageCHTrendUsage = data => ({
  type: UPDATE_CAPACITY_USAGE_CH_TREND_USAGE,
  data
});

export const UpdateCapacityUsageCHSavingTrend = data => ({
  type: UPDATE_CAPACITY_USAGE_CH_STORE_SAVING_TREND,
  data
});

export const updateReportTypeForNavingation = data => ({
  type: UPDATE_REPORT_TYPE_FOR_COMPONENT,
  data
});

export const fetchSingleReportData = data => ({
  type: FETCH_SINGLE_REPORT_DETAILS,
  data
});

export const updateSingleReportDetails = data => ({
  type: UPDATE_SINGLE_REPORT_DETAILS,
  data
});

export const updateRedirectedFromReport = data => ({
  type: UPDATE_SINGLE_REPORT_REDIRECTION,
  data
});

/**
 * To get update jobs data
 * @returns {{type: string, id: *}}
 */
export const updateJobLogJobData = data => ({
  type: UPDATE_JOBLOG_JOBDATA,
  data
});

export const updateSecondaryNavigation = data => ({
  type: UPDATE_SECONDARY_NAVIGATION,
  data
});

/**
 * update Analyze > Reports > Account Details Report data and pagination
 */
export const updateAccountDetailsData = data => ({
  type: UPDATE_ACCOUNT_DETAILS_REPORT_DATA,
  data
});

/**
 * Update Analyze > Reports > Source Protection Report Sources Summary widget data
 */
export const updateSourceProtectionSourcesSummary = data => ({
  type: UPDATE_SOURCE_PROTECTION_SOURCES_SUMMARY,
  data
});
