import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//import Icon from "../../../assets/icons/icons";
import FormattedMessage from "../../common/formatted-message";
//import NavIcon from "../../../components/csr-home/components/NavIcon";
import Tab from "@mui/material/Tab";
import DashboardIcon from "@mui/icons-material/Speed";
import ProtectIcon from "@mui/icons-material/Security";
import ReportsIcon from "@mui/icons-material/Leaderboard";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportIcon from "@mui/icons-material/HelpOutlineOutlined";
import ProfileIcon from "@mui/icons-material/Person";
import { ID_PREFIX } from "configs/IdConfig";

let iconMap = {
  monitor_menu: <DashboardIcon style={{ height: 30, width: 30 }} />,
  protect_menu: <ProtectIcon style={{ height: 30, width: 30 }} />,
  analyze_menu: <ReportsIcon style={{ height: 30, width: 30 }} />,
  configure_menu: <SettingsIcon style={{ height: 30, width: 30 }} />,
  support_menu: <SupportIcon style={{ height: 30, width: 30 }} />,
  profile_menu: <ProfileIcon style={{ height: 30, width: 30 }} />
};
const MUITab = ({
  onClick,
  title,
  menuKey,
  routeLink,
  className,
  icon,
  active,
  iconClass,
  iconHeight,
  testId,
  ...props
}) => {
  const handleClick = link => {
    props.history.push(link);
    onClick && onClick(title, menuKey);
  };

  const isActive = () => {
    return active === title;
  };

  const link = routeLink;
  // const iconPath = icon ? icon : null;
  // const isCsr = iconPath === null ? true : false;
  // const iconWidth = props.iconWidth ? props.iconWidth : null;

  return (
    <Tab
      id={`${ID_PREFIX}${testId}`}
      selected={isActive()}
      fullWidth={true}
      textColor="primary"
      icon={iconMap[icon]}
      label={
        <FormattedMessage id={title ? title : " "} defaultMessage={title} />
      }
      style={{
        height: 40,
        textTransform: "none",
        fontSize: "15px",
        fontFamily: "Sans Source Pro Medium"
      }}
      onClick={() => {
        handleClick(link);
      }}
    />
  );
};

MUITab.defaultProps = {
  title: "",
  menuKey: "",
  active: "",
  iconWidth: 0,
  iconHeight: 0,
  icon: "",
  className: ""
};
MUITab.propTypes = {
  routeLink: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  menuKey: PropTypes.string,
  active: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  className: PropTypes.string,
  brandDetails: PropTypes.shape({
    primary_color: PropTypes.string
  })
};

export default withRouter(MUITab);
