import { createSelector } from "reselect";

import * as appConstants from "utils/appConstants";

import * as t from "./transformers";

export const getConfig = ccGraphId => state => {
  if (state.ccGraph[ccGraphId] === undefined) {
    return {};
  }

  return state.ccGraph[ccGraphId].config;
};

// ui stuff
export const isLoading = ccGraphId => state => {
  if (state.ccGraph[ccGraphId] === undefined) {
    return false;
  }

  return state.ccGraph[ccGraphId].loading > 0;
};

export const getResolution = ccGraphId => state => {
  if (state.ccGraph[ccGraphId] === undefined) {
    return "day";
  }

  return state.ccGraph[ccGraphId].ui.resolution;
};

// fetch stuff
export const getFilters = ccGraphId => state => {
  if (state.ccGraph[ccGraphId] === undefined) {
    return null;
  }

  return state.ccGraph[ccGraphId].ui.filters;
};

export const makeGetFilter = ccGraphId => {
  return createSelector([getFilters(ccGraphId)], filter => filter);
};

export const getEntities = ccGraphId => state => {
  if (state.ccGraph[ccGraphId] === undefined) {
    return {};
  }
  return state.ccGraph[ccGraphId].entities;
};

export const makeGetTSLineData = ccGraphId => {
  const myGetEntities = makeGetTSData(ccGraphId);
  const myGetFilters = getFilters(ccGraphId);
  return createSelector(myGetFilters, myGetEntities, (filters, data) =>
    t.lineTransformer(filters, data, ccGraphId)
  );
};

export const makeGetRCLineData = (ccGraphId, locale) => {
  const myGetEntities = makeGetTSData(ccGraphId);
  const myGetFilters = getFilters(ccGraphId);
  const myGetResolution = getResolution(ccGraphId);
  return createSelector(
    myGetFilters,
    myGetEntities,
    myGetResolution,
    (filters, data, resolution) =>
      t.rechartLineTransformer(filters, data, ccGraphId, resolution, locale)
  );
};
// TS Area Selectors
export const makeGetTSData = ccGraphId => {
  const myGetEntities = getEntities(ccGraphId);
  return createSelector(myGetEntities, entities => {
    const { resolution, ...data } = entities ? entities : {};
    return Object.values(data);
  });
};

export const makeGetTSAreaData = ccGraphId => {
  const myGetEntities = makeGetTSData(ccGraphId);
  const myGetFilters = getFilters(ccGraphId);
  const myGetResolution = getResolution(ccGraphId);
  return createSelector(
    myGetFilters,
    myGetEntities,
    myGetResolution,
    (filters, data, resolution) =>
      t.areaTransformer(filters, data, ccGraphId, resolution)
  );
};

//RECHART SELECTORS
export const makeGetRCAreaData = (ccGraphId, locale) => {
  const myGetEntities = makeGetTSData(ccGraphId);
  const myGetFilters = getFilters(ccGraphId);
  const myGetResolution = getResolution(ccGraphId);
  return createSelector(
    myGetFilters,
    myGetEntities,
    myGetResolution,
    (filters, data, resolution) =>
      t.rechartAreaTransformer(filters, data, ccGraphId, resolution, locale)
  );
};

// Donut Selectors
export const makeGetRCDonutData = (ccGraphId, graphType) => {
  if (
    graphType === appConstants.CCGRAPH_TYPES.DASHBOARD_BACKUP_JOBS_SUMMARY ||
    graphType === appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY
  ) {
    const attributeLookup = {
      [appConstants.CCGRAPH_TYPES.DASHBOARD_BACKUP_JOBS_SUMMARY]: d =>
        d.backup_job_report_status_summary,
      [appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_SUMMARY]: d =>
        d.backup_job_report_status_summary,
      [appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY]: d =>
        d && d.backup_job_report_status_summary
    };
    const myGetEntities = getEntities(ccGraphId);
    const myGetFilters = getFilters(ccGraphId);
    return createSelector(myGetFilters, myGetEntities, (filters, entities) =>
      t.rechartPieTransformer(
        filters,
        (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
          [],
        graphType
      )
    );
  } else {
    const myGetEntities = makeGetTSData(ccGraphId);
    const myGetFilters = getFilters(ccGraphId);
    return createSelector(myGetFilters, myGetEntities, (filters, data) =>
      t.rechartPieTransformer(filters, data, graphType)
    );
  }
};

export const makeGetDonutData = (ccGraphId, graphType) => {
  if (
    graphType === appConstants.CCGRAPH_TYPES.DASHBOARD_BACKUP_JOBS_SUMMARY ||
    graphType === appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY
  ) {
    const attributeLookup = {
      [appConstants.CCGRAPH_TYPES.DASHBOARD_BACKUP_JOBS_SUMMARY]: d =>
        d && d.backup_job_report_status_summary,
      [appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_SUMMARY]: d =>
        d && d.backup_job_report_status_summary,
      [appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY]: d =>
        d && d.backup_job_report_status_summary
    };
    const myGetEntities = getEntities(ccGraphId);
    const myGetFilters = getFilters(ccGraphId);
    return createSelector(myGetFilters, myGetEntities, (filters, entities) =>
      t.donutTransformer(
        filters,
        (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
          [],
        graphType
      )
    );
  } else {
    const myGetEntities = makeGetTSData(ccGraphId);
    const myGetFilters = getFilters(ccGraphId);
    return createSelector(myGetFilters, myGetEntities, (filters, data) =>
      t.donutTransformer(filters, data, graphType)
    );
  }
};

//BAR GRAPH selectors barGraphTransformer

export const makeGetBarData = (ccGraphId, graphType) => {
  const attributeLookup = {
    [appConstants.CCGRAPH_TYPES.DASHBOARD_NUMBER_OF_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_LATEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_OLDEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_NUMBER_OF_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_LATEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES]: d =>
      d,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS]: d => d.customers,
    [appConstants.CCGRAPH_TYPES.REPORTS_OLDEST_RECOVERY_POINTS]: d => d
  };
  const myGetEntities = getEntities(ccGraphId);
  return createSelector(myGetEntities, entities =>
    t.barGraphTransformer(
      (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
        [],
      graphType
    )
  );
};

export const makeGetRCBarData = (ccGraphId, graphType, locale) => {
  const attributeLookup = {
    [appConstants.CCGRAPH_TYPES.DASHBOARD_NUMBER_OF_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_NUMBER_OF_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_LATEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_OLDEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_LATEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES]: d =>
      d,
    [appConstants.CCGRAPH_TYPES.REPORTS_OLDEST_RECOVERY_POINTS]: d => d,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS]: d => d.customers
  };
  const myGetEntities = getEntities(ccGraphId);
  return createSelector(myGetEntities, entities =>
    t.RCbarGraphTransformer(
      (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
        [],
      graphType,
      locale
    )
  );
};

export const makeGetRCStackedBarData = (ccGraphId, graphType) => {
  const attributeLookup = {
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS]: d => d && d.customers,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_POLICIES]: d => d && d.policies,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_SOURCES]: d => d && d.sources,
    [appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_SOURCES]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_TOP_SOURCES]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_TOP_SOURCES]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_DATA_TRANSFER_TOP_SOURCES]: d =>
      d && d.sources
  };
  const myGetFilters = getFilters(ccGraphId);
  const myGetEntities = getEntities(ccGraphId);
  return createSelector(myGetFilters, myGetEntities, (filters, entities) =>
    t.RCStackedBarTransformer(
      (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
        [],
      graphType,
      filters
    )
  );
};

//DSBAR GRAPH selectors barGraphTransformer

export const makeGetDsBarData = (ccGraphId, graphType) => {
  const attributeLookup = {
    [appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES]: d =>
      d
  };
  const myGetEntities = getEntities(ccGraphId);
  return createSelector(myGetEntities, entities =>
    t.dsBarGraphTransformer(
      (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
        [],
      graphType
    )
  );
};

// Stacked Bar Selectors
export const makeGetStackedBarData = (ccGraphId, graphType) => {
  const attributeLookup = {
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS]: d => d && d.customers,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_POLICIES]: d => d && d.policies,
    [appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_SOURCES]: d => d && d.sources,
    [appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_SOURCES]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_TOP_SOURCES]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_TOP_SOURCES]: d => d,
    [appConstants.CCGRAPH_TYPES.REPORTS_DATA_TRANSFER_TOP_SOURCES]: d =>
      d && d.sources
  };
  const myGetFilters = getFilters(ccGraphId);
  const myGetEntities = getEntities(ccGraphId);
  return createSelector(myGetFilters, myGetEntities, (filters, entities) =>
    t.stackedBarTransformer(
      (attributeLookup[graphType] && attributeLookup[graphType](entities)) ||
        [],
      graphType,
      filters
    )
  );
};
