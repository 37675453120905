export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      //No State exists. Reducer should initialize the application state.
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    //Reducer should initialize the application state.
    return undefined;
  }
};

export const saveState = state => {
  try {
    const localStorageState = JSON.parse(localStorage.getItem("state"));
    if (
      localStorageState &&
      localStorageState.login.logout &&
      localStorageState.login.refresh_token !== state.login.refresh_token &&
      window.location.pathname !== "/signin"
    ) {
      // if (
      //   !localStorageState.form.userVerification &&
      //   localStorageState.form.login &&
      //   !localStorageState.form.login.submitSucceeded
      // ) {
      state = Object.assign({}, localStorageState);
      window.stopSavingState();
      // clear state from local storage - taken from login saga
      sessionStorage.removeItem("token");
      localStorage.removeItem("state");
      window.location.reload();
      // }
    }
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (error) {
    window.console.log(error);
  }
};
