import React from "react";
import PropTypes from "prop-types";

const NavIcon = ({ className, text }) => (
  <div className="nav-icon__container">
    <div className="nav-icon">
      <i className={className} />
    </div>
  </div>
);

NavIcon.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default NavIcon;
