import * as C from "state/constants";

const initialState = {
  redirect: null,
  displayLoginModal: false,
  selectedDataCenter: null,
  selectedOrgId: null
};

const misc = (state = initialState, action) => {
  switch (action.type) {
    case C.CSR_REDIRECT:
      return Object.assign({}, state, {
        redirect: action.payload.to
      });
    case C.CSR_CLEAR_REDIRECT:
      return Object.assign({}, state, {
        redirect: null
      });
    case C.DISPLAY_LOGIN_MODAL:
      return Object.assign({}, state, {
        displayLoginModal: true
      });
    case C.CLOSE_LOGIN_MODAL:
      return Object.assign({}, state, {
        displayLoginModal: false
      });
    case C.CSR_ORG_DATA_CENTER:
      if (action.payload.datacenter) {
        return Object.assign({}, state, {
          selectedDataCenter: {
            select_datacenter: {
              key: action.payload.datacenter.datacenter_id,
              value: action.payload.datacenter.datacenter_name
            }
          },
          selectedOrgId: action.payload.orgId
        });
      } else {
        return Object.assign({}, state, {
          selectedDataCenter: null,
          selectedOrgId: action.payload.orgId
        });
      }
    case C.RESET_CSR_ORG_DATA_CENTER:
      return Object.assign({}, state, {
        selectedDataCenter: null,
        selectedOrgId: null
      });

    default:
      return state;
  }
};

export default misc;
