import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { SECONDARY_NAVIGATION_TYPE } from "../../utils/appConstants";

class ViewOrg extends PureComponent {
  componentDidMount() {
    const {
      history,
      match,
      impersonateCustomer,
      impersonateSource,
      impersonatePolicy,
      impersonateJob,
      impersonateRecoveryPoints,
      setAdvanceSearchData,
      updateCurrentSearchFromSaved,
      getAdvancedSearchOptions
    } = this.props;
    if (match.params.orgName) {
      impersonateCustomer({
        customerOrgId: match.params.orgId,
        customerOrgName: decodeURIComponent(match.params.orgName),
        customerOrgType: match.params.orgType,
        subMSP: match.params.subMSP === "true",
        imccOrganization: match.params.imccOrganization === "true"
      });
      if (match.params.subMSP === "protect") {
        history.push("/protect/customer-accounts/all");
      } else {
        history.push("/");
      }
    } else {
      let searchText = "";
      let organization_id = match.params.organization_id;
      let organization_name = match.params.organization_name;
      let organization_type = match.params.organization_type;
      if (match.params.source_name) {
        searchText = decodeURIComponent(match.params.source_name);
        impersonateSource({
          sourceName: searchText,
          customerOrgId: organization_id,
          customerOrgName: decodeURIComponent(organization_name),
          customerOrgType: organization_type
        });
        // Add code to filter 'source grid' name
        setAdvanceSearchData({
          selectedOptions: { name: "name", searchString: searchText },
          searchString: searchText
        });
        updateCurrentSearchFromSaved({
          selectedOptions: { name: "name", searchString: searchText },
          searchString: searchText
        });
      } else if (match.params.policy_name) {
        searchText = match.params.policy_name;
        impersonatePolicy({
          policyName: searchText,
          customerOrgId: organization_id,
          customerOrgName: decodeURIComponent(organization_name),
          customerOrgType: organization_type
        });
        // Add code to filter 'source grid' name
        setAdvanceSearchData({
          selectedOptions: { name: "name", searchString: searchText },
          searchString: searchText
        });
        updateCurrentSearchFromSaved({
          selectedOptions: { name: "name", searchString: searchText },
          searchString: searchText
        });
      } else if (match.params.jobId) {
        impersonateJob({
          customerOrgId: organization_id,
          customerOrgName: decodeURIComponent(organization_name),
          customerOrgType: organization_type,
          jobId: match.params.jobId,
          jobName: match.params.jobName
        });
      } else if (match.params.source_id) {
        impersonateRecoveryPoints({
          customerOrgId: organization_id,
          customerOrgName: decodeURIComponent(organization_name),
          customerOrgType: organization_type,
          source_id: match.params.source_id
        });
        setTimeout(
          () =>
            getAdvancedSearchOptions({
              filter: SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS,
              subFilter: "recovery Points Sources CD"
            }),
          2000
        );
      }
      if (match.params.source_name) {
        history.push("/protect/sources/all");
      } else if (match.params.policy_name) {
        history.push("/protect/policies/all");
      } else if (match.params.jobId) {
        let url = `/analyze/jobs/all/${match.params.jobId}/log`;
        history.push(url);
      } else if (match.params.source_id) {
        let recoveryPointURL = `/protect/sources/all/${match.params.source_id}/details/recovery_points`;
        history.push(recoveryPointURL);
      }
    }
  }

  render() {
    return <div />;
  }
}

ViewOrg.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      orgType: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  impersonateCustomer: PropTypes.func.isRequired
};

export default ViewOrg;
