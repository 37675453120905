import { combineReducers } from "redux";

import { combineReducersWithNamespace } from "state/utils";

import * as ccDataGridConstants from "state/constants/ccDataGrid";

import * as constant from "../../../utils/appConstants";
import { cloneDeep } from "lodash";

// context stuff
const config = (state = {}, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_INITIALIZE:
      return {
        ...action.config,
        additionalParameters: action.additionalParameters
      };
    default:
      return state;
  }
};

// loading indicator counter
// 0 means not loading
// for every REQ loading = loading + 1
// for every SUCCESSS/FAILURE loading = loading - 1
// hopefully, it'll reach 0
const loading = (state = 0, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_INITIALIZE:
    case ccDataGridConstants.RESET_CCDATAGRID:
    case ccDataGridConstants.CCDATAGRID_UPDATE_LOCAL_DATA:
      return 0;
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_REQUEST:
    case ccDataGridConstants.START_LOADER:
      return state + 1;
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_FAILURE:
      return state - 1;
    default:
      return state;
  }
};

// state stuff
const sorted = (state = [], action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_UPDATE_SORTED:
      return action.sorted;
    default:
      return state;
  }
};

const page = (state = 1, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
      return action.response.pagination && action.response.pagination.curr_page
        ? action.response.pagination.curr_page
        : 1;
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE:
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_LOCALLY:
      return action.page;
    default:
      return state;
  }
};

const pages = (state = 0, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
      return action.response.pagination && action.response.pagination.total_page
        ? action.response.pagination.total_page
        : 1;
    default:
      return state;
  }
};

const pageSize = (state = 25, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
      return action.pagination === false
        ? 25
        : action.response.pagination && action.response.pagination.page_size
        ? action.response.pagination.page_size
        : 25;
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_SIZE:
      return action.pageSize;
    default:
      return state;
  }
};

const totalSize = (state = -1, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
      return action.response.pagination &&
        (action.response.pagination.total_size ||
          action.response.pagination.total_size === 0)
        ? action.response.pagination.total_size
        : 25;
    // return action.response.pagination.total_size;
    default:
      return state;
  }
};

const columns = (state = null, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_STORE_COLUMNS:
      return action.columns;
    default:
      return state;
  }
};

const checked = (state = {}, action) => {
  let rVal = null;

  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_UNCHECK_ALL:
    case ccDataGridConstants.CCDATAGRID_UPDATE_SORTED:
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE:
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_SIZE:
    case ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH:
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
    case ccDataGridConstants.CCDATAGRID_UPDATE_LOCAL_DATA:
    case ccDataGridConstants.CCDATAGRID_CLEAR_ADVANCED_SEARCH:
      return {};
    case ccDataGridConstants.CCDATAGRID_CHECK_ROWS:
      return action.rowIds.reduce(
        (nextState, rowId) => ({
          ...nextState,
          [rowId]: true
        }),
        state
      );
    case ccDataGridConstants.CCDATAGRID_UNCHECK_ROW:
      rVal = { ...state };

      if (state[action.rowId]) {
        delete rVal[action.rowId];
      }
      return rVal;
    case ccDataGridConstants.CCDATAGRID_CLEAR_DATA:
      return {};
    default:
      return state;
  }
};

const showAdvancedSearchForm = (state = false, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_SHOW_ADVANCED_SEARCH_FORM:
      return true;
    case ccDataGridConstants.CCDATAGRID_HIDE_ADVANCED_SEARCH_FORM:
      return false;
    default:
      return state;
  }
};

const advancedSearch = (state = null, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH:
      return action.values;
    case ccDataGridConstants.CCDATAGRID_CLEAR_ADVANCED_SEARCH:
    case ccDataGridConstants.CCDATAGRID_REMOVE_SEARCH_TAGS_RELOAD:
      return null;
    default:
      return state;
  }
};

const showCCDataGridCurrentSearch = (state = false, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_SHOW_CURRENT_SEARCH:
      return action.data;
    default:
      return state;
  }
};

const advancedSearchOptionsSelected = (state = null, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH_OPTIONS_SELECTED:
      return action.data;
    default:
      return state;
  }
};

const advancedSearchOptions = (state = [], action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH_OPTIONS:
      return action.data;
    case ccDataGridConstants.CCDATAGRID_RESET_ADVANCED_SEARCH_OPTIONS:
      return action.data;
    // case ccDataGridConstants.CCDATAGRID_UPDATE_ADVANCED_SEARCH_OPTIONS:
    //   return {
    //     options: action.data
    //   }
    default:
      return state;
  }
};

const advancedSearchOptionsUnChanged = (state = [], action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH_OPTIONS_UNCHANGED:
      return action.data;
    default:
      return state;
  }
};

const optionsForTags = (state = {}, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_OPTIONS_FOR_TAGS:
      return action.data;
    case ccDataGridConstants.CCDATAGRID_RESET_OPTIONS_FOR_TAGS:
      return {};
    default:
      return state;
  }
};

const selectedSavedSearchInitialState = {
  filter_id: "",
  filter_name: ""
};

const selectedSavedSearch = (
  state = selectedSavedSearchInitialState,
  action
) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_SELECTED_SAVED_SEARCH:
      return {
        filter_id: action.filter_id,
        filter_name: action.filter_name,
        ...action.data
      };
    case ccDataGridConstants.CCDATAGRID_RESET_SELECTED_SAVED_SEARCH:
      return {
        filter_id: "",
        filter_name: ""
      };
    default:
      return state;
  }
};

const userSearchData = (state = [], action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_USER_SEARCH_DATA:
      return action.data.userSearchList;
    case ccDataGridConstants.CCDATAGRID_RESET_USER_SEARCH_DATA:
      return [];
    default:
      return state;
  }
};

const systemSearchData = (state = [], action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_SYSTEM_SEARCH_DATA:
      return action.data.systemSearchList;
    case ccDataGridConstants.CCDATAGRID_RESET_SYSTEM_SEARCH_DATA:
      return [];
    default:
      return state;
  }
};

const showSaveSearchModal = (state = false, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_SHOW_SAVE_SEARCH_MODAL:
      return action.data;
    case ccDataGridConstants.CCDATAGRID_RESET_SHOW_SAVE_SEARCH_MODAL:
      return false;
    default:
      return state;
  }
};
const saveSearchText = (state = "", action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_SET_SAVE_SEARCH_TEXT:
      return action.data;
    case ccDataGridConstants.CCDATAGRID_RESET_SAVE_SEARCH_TEXT:
      return "";
    default:
      return state;
  }
};

const considerAdvancedSearchOptions = (state = true, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_CONSIDER_ADVANCED_SEARCH_OPTIONS:
      return action.data;
    case ccDataGridConstants.CCDATAGRID_RESET_CONSIDER_ADVANCED_SEARCH_OPTIONS:
      return true;
    default:
      return state;
  }
};

const manageSaveSearchOptions = (state = [], action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_MANAGE_SAVE_SEARCH_OPTIONS: {
      let answers = updateOptions(
        action.actualOptions,
        action,
        action.organization_type,
        action.isImpersonationView
      );
      return answers;
    }

    case ccDataGridConstants.CCDATAGRID_UPDATE_MANAGE_SAVE_SEARCH_OPTIONS:
      return action.data;
    // case ccDataGridConstants.CCDATAGRID_UPDATE_ADVANCED_SEARCH_OPTIONS:
    //   return {
    //     options: action.data
    //   }
    default:
      return state;
  }
};

const manageSelectedSavedSearch = (state = {}, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_MANAGE_SELECTED_SAVE_SEARCH:
      return action.data;
    case ccDataGridConstants.CCDATAGRID_RESET_MANAGE_SELECTED_SAVE_SEARCH:
      return {};
    default:
      return state;
  }
};

function updateOptions(
  options,
  action,
  organization_type,
  isImpersonationView
) {
  if (options) {
    return options.map(option => {
      if (action.data.data && action.data.data[option.key] !== undefined) {
        return {
          ...option,
          selectedOptions: updateSelectedOptions(
            option,
            action,
            organization_type,
            isImpersonationView
          ),
          orignalOptions: updateSelectedOptions(
            option,
            action,
            organization_type,
            isImpersonationView
          )
        };
      }
      return option;
    });
  }
}

function updateSelectedOptions(
  option,
  action,
  organization_type,
  isImpersonationView
) {
  let selectedOptions = cloneDeep(option.selectedOptions);
  let type = option.type;

  switch (type) {
    case "date-picker":
    case "date-range-picker":
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.key)
      ) {
        return action.data.data[option.key];
      }
      return {};

    case "single-select": {
      let arrayToReturn = [];
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.key)
      ) {
        let selectedOption = option.options.filter(
          item => item.value === action.data.data[option.key].toString()
        )[0];
        if (selectedOption && selectedOption.label === "direct_admin") {
          if (organization_type !== "msp" || isImpersonationView) {
            selectedOption.label = "admin";
          }
        }
        arrayToReturn.push(selectedOption);
      }
      return arrayToReturn;
    }
    case constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE:
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.type)
      ) {
        let data;
        if (!Array.isArray(action.data.data[option.type])) {
          data = [action.data.data[option.type]];
        } else {
          data = cloneDeep(action.data.data[option.type]);
        }
        return data.map(selOpt => {
          for (let index = 0; index < option.options.length; index++) {
            if (
              option.options[index].id &&
              selOpt === option.options[index].id
            ) {
              selOpt = option.options[index].value;
            }
          }
          return {
            ...selectedOptions,
            label: selOpt,
            value: selOpt
          };
        });
      }
      return [];
    default:
      if (
        action.data &&
        action.data.data &&
        action.data.data.hasOwnProperty(option.key)
      ) {
        let data;
        if (!Array.isArray(action.data.data[option.key])) {
          data = [action.data.data[option.key]];
        } else {
          data = cloneDeep(action.data.data[option.key]);
        }
        return data.map(selOpt => {
          let selOptId = null;
          for (let index = 0; index < option.options.length; index++) {
            if (
              option.options[index].id &&
              selOpt === option.options[index].id
            ) {
              selOpt = option.options[index].value;
              selOptId = option.options[index].id;
            }
          }
          let labelSelOpt = selOpt;
          let valueSelOpt = selOpt;
          let idSelOpt = selOptId;
          if (selOpt === "direct_admin") {
            if (organization_type !== "msp" || isImpersonationView) {
              labelSelOpt = "admin";
            }
          }
          if (option.key === "policy_id" || option.key === "user_ids") {
            return {
              ...selectedOptions,
              label: labelSelOpt,
              value: idSelOpt,
              id: idSelOpt
            };
          } else {
            return {
              ...selectedOptions,
              label: labelSelOpt,
              value: valueSelOpt
            };
          }
        });
      }
      return [];
  }
}

const ui = combineReducers({
  sorted,
  page,
  pages,
  pageSize,
  totalSize,
  columns,
  checked,
  showAdvancedSearchForm,
  advancedSearch,
  showCCDataGridCurrentSearch,
  advancedSearchOptions,
  advancedSearchOptionsUnChanged,
  advancedSearchOptionsSelected,
  optionsForTags,
  selectedSavedSearch,
  userSearchData,
  systemSearchData,
  showSaveSearchModal,
  saveSearchText,
  manageSaveSearchOptions,
  manageSelectedSavedSearch,
  considerAdvancedSearchOptions
});

// data for displaying
const entitiesInitialState = {
  byId: {},
  allIds: []
};

const entities = (state = entitiesInitialState, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
    case ccDataGridConstants.CCDATAGRID_UPDATE_LOCAL_DATA: {
      const { data } = action;
      if (Array.isArray(data)) {
        const byId = {};
        data.forEach(r => {
          byId[r._rowId] = r;
        });
        let x = data.map(r => r._rowId);
        return {
          allIds: x,
          byId
        };
      }

      return state;
    }
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE:
      return action.refreshGrid ? entitiesInitialState : state;
    case ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_SIZE:
      return action.refreshGrid ? entitiesInitialState : state;
    case ccDataGridConstants.CCDATAGRID_UPDATE_SORTED:
      return entitiesInitialState;
    case ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH:
    case ccDataGridConstants.CCDATAGRID_CLEAR_ADVANCED_SEARCH:
      return entitiesInitialState;
    case ccDataGridConstants.CCDATAGRID_CLEAR_DATA:
      return entitiesInitialState;
    default:
      return state;
  }
};

// save last api (for debugging)
const api = (state = null, action) => {
  switch (action.type) {
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS:
    case ccDataGridConstants.CCDATAGRID_FETCH_DATA_FAILURE:
      return action.response;
    case ccDataGridConstants.CCDATAGRID_CLEAR_DATA:
      return {};
    default:
      return state;
  }
};

const ccDataGrid = combineReducersWithNamespace(
  {
    config,
    loading,
    ui,
    entities,
    api
  },
  action => action.ccDataGridId
);

export default ccDataGrid;
