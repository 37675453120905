import React from "react";
import FormattedMessage from "components/common/formatted-message";

import * as DCs from "components/protect/policy/create-policy/destinations/DestinationConstants";

class ScheduleDays extends React.Component {
  constructor() {
    super();
    DCs.daysOfWeek.forEach(function(item) {
      item.active = true;
    });
    this.onClick = this.onClick.bind(this);
    this.state = {
      daysOfWeek: DCs.daysOfWeek
    };
  }

  componentDidMount() {
    this.setDaysOfWeek();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDays !== prevProps.selectedDays) {
      this.setDaysOfWeek();
    }
  }

  onClick(index) {
    let daysOfWeek = this.state.daysOfWeek.map((day, i) => {
      if (i === index) {
        return {
          ...day,
          active: !day.active
        };
      }
      return day;
    });
    if (
      daysOfWeek.filter(function(item) {
        return item.active === true;
      }).length === 0
    ) {
      return;
    }
    this.setState(
      state => {
        return {
          daysOfWeek: this.state.daysOfWeek.map((day, i) => {
            if (i === index) {
              return {
                ...day,
                active: !day.active
              };
            }
            return day;
          })
        };
      },
      () => {
        let result = this.state.daysOfWeek.filter(function(item) {
          return item.active === true;
        });
        let selectedDays = result.map(({ value }) => value).join(",");
        this.props.onChange(selectedDays);
      }
    );
  }

  setDaysOfWeek = () => {
    this.setState(state => {
      return {
        daysOfWeek: state.daysOfWeek.map((day, i) => {
          return {
            ...day,
            active: this.isActiveDay(day)
          };
        })
      };
    });
  };

  isActiveDay = day => {
    if (this.props.selectedDays === "*") return true;
    return this.props.selectedDays.indexOf(day.value) >= 0;
  };

  render() {
    return (
      <div>
        {this.props.label && (
          <div className="content-item d-flex flex-column align-items-start">
            <span className="content-item-title">
              <FormattedMessage
                id={this.props.label}
                defaultMessage={this.props.label}
              />
            </span>
          </div>
        )}
        {this.state.daysOfWeek.map((el, index) => (
          <div
            key={index}
            className={el.active ? "activeDaySchedule" : "inactiveDaySchedule"}
            onClick={() => this.onClick(index)}
          >
            <span className="centerDaysText">
              <FormattedMessage id={el.label} defaultMessage={el.label} />
            </span>
          </div>
        ))}
      </div>
    );
  }
}

export default ScheduleDays;
