// CSR CONSTANTS
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const DISPLAY_LOGIN_MODAL = "DISPLAY_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

export const ADD_CONFIRMATION = "ADD_CONFIRMATION";
export const EXECUTE_CONFIRMATION = "EXECUTE_CONFIRMATION";
export const CANCEL_CONFIRMATION = "CANCEL_CONFIRMATION";

export const CSR_REDIRECT = "CSR_REDIRECT";
export const CSR_ORG_DATA_CENTER = "CSR_ORG_DATA_CENTER";
export const RESET_CSR_ORG_DATA_CENTER = "RESET_CSR_ORG_DATA_CENTER";
export const CSR_CLEAR_REDIRECT = "CSR_CLEAR_REDIRECT";

// org search
export const SET_ORGANIZATIONS_SEARCH_STRING =
  "SET_ORGANIZATIONS_SEARCH_STRING";

export const SET_ORGANIZATIONS_SORT_STRING = "SET_ORGANIZATIONS_SORT_STRING";

export const FETCH_ORGANIZATION_SEARCH_REQUEST =
  "FETCH_ORGANIZATION_SEARCH_REQUEST";

export const REFRESH_ORGANIZATION_SEARCH_OR_EDIT_ORG_REQUEST =
  "REFRESH_ORGANIZATION_SEARCH_OR_EDIT_ORG_REQUEST";
export const FETCH_ORGANIZATION_SEARCH_SUCCESS =
  "FETCH_ORGANIZATION_SEARCH_SUCCESS";
export const FETCH_ORGANIZATION_SEARCH_FAILURE =
  "FETCH_ORGANIZATION_SEARCH_FAILURE";

export const FETCH_ORGANIZATIONS_REQUEST = "FETCH_ORGANIZATIONS_REQUEST";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAILURE = "FETCH_ORGANIZATIONS_FAILURE";

export const FETCH_ORGANIZATIONS_SUPPORT_REQUEST =
  "FETCH_ORGANIZATIONS_SUPPORT_REQUEST";
export const FETCH_ORGANIZATIONS_SUPPORT_SUCCESS =
  "FETCH_ORGANIZATIONS_SUPPORT_SUCCESS";
export const FETCH_ORGANIZATIONS_SUPPORT_FAILURE =
  "FETCH_ORGANIZATIONS_SUPPORT_FAILURE";

export const FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_REQUEST =
  "FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_REQUEST";
export const FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_SUCCESS =
  "FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_SUCCESS";
export const FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_FAILURE =
  "FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_FAILURE";

export const FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_REQUEST =
  "FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_REQUEST";
export const FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_SUCCESS =
  "FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_SUCCESS";
export const FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_FAILURE =
  "FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_FAILURE";

export const ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_DIRECT_REQUEST =
  "ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_DIRECT_REQUEST";
export const ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_HYBRID_REQUEST =
  "ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_HYBRID_REQUEST";
export const ACTIVATE_ORGANIZATION_FREETRIAL_UDP_REQUEST =
  "ACTIVATE_ORGANIZATION_FREETRIAL_UDP_REQUEST";

export const INVALIDATE_CACHE = "INVALIDATE_CACHE";

export const ENABLE_POLICIES = "ENABLE_POLICIES";

export const DISABLE_POLICIES = "DISABLE_POLICIES";

export const ENABLE_ORG = "ENABLE_ORG";

export const SUSPEND_ORG = "SUSPEND_ORG";

export const PRODUCT_STATUS = "PRODUCT_STATUS";

export const FETCH_ORGANIZATIONS_ENTITLEMENTS_REQUEST =
  "FETCH_ORGANIZATIONS_ENTITLEMENTS_REQUEST";
export const FETCH_ORGANIZATIONS_ENTITLEMENTS_SUCCESS =
  "FETCH_ORGANIZATIONS_ENTITLEMENTS_SUCCESS";
export const FETCH_ORGANIZATIONS_ENTITLEMENTS_FAILURE =
  "FETCH_ORGANIZATIONS_ENTITLEMENTS_FAILURE";

export const FETCH_ORGANIZATIONS_PROPERTIES_REQUEST =
  "FETCH_ORGANIZATIONS_PROPERTIES_REQUEST";
export const FETCH_ORGANIZATIONS_PROPERTIES_SUCCESS =
  "FETCH_ORGANIZATIONS_PROPERTIES_SUCCESS";
export const FETCH_ORGANIZATIONS_PROPERTIES_FAILURE =
  "FETCH_ORGANIZATIONS_PROPERTIES_FAILURE";

export const UPDATE_ORGANIZATIONS_REQUEST = "UPDATE_ORGANIZATIONS_REQUEST";
export const UPDATE_ORGANIZATIONS_SUCCESS = "UPDATE_ORGANIZATIONS_SUCCESS";
export const UPDATE_ORGANIZATIONS_FAILURE = "UPDATE_ORGANIZATIONS_FAILURE";

export const DELETE_ORGANIZATIONS_REQUEST = "DELETE_ORGANIZATIONS_REQUEST";

// user search
export const SET_USERS_SEARCH_STRING = "SET_USERS_SEARCH_STRING";
export const SET_USERS_SORT_STRING = "SET_USERS_SORT_STRING";

export const FETCH_USERS_SEARCH_REQUEST = "FETCH_USERS_SEARCH_REQUEST";
export const FETCH_USERS_SEARCH_SUCCESS = "FETCH_USERS_SEARCH_SUCCESS";
export const FETCH_USERS_SEARCH_FAILURE = "FETCH_USERS_SEARCH_FAILURE";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const SEND_RESET_PW_EMAIL_REQUEST = "SEND_RESET_PW_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_REQUEST =
  "SEND_VERIFICATION_EMAIL_REQUEST";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_INGRAM_ORG_REQUEST = "CREATE_INGRAM_ORG_REQUEST";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const FETCH_SITES_REQUEST = "FETCH_SITES_REQUEST";
export const FETCH_SITES_SUCCESS = "FETCH_SITES_SUCCESS";
export const FETCH_SITES_FAILURE = "FETCH_SITES_FAILURE";

export const RESET_CURRENT_RECOVERY_POINT_SERVER_REQUEST =
  "RESET_CURRENT_RECOVERY_POINT_SERVER_REQUEST";
export const FETCH_RECOVERY_POINT_SERVER_REQUEST =
  "FETCH_RECOVERY_POINT_SERVER_REQUEST";
export const FETCH_RECOVERY_POINT_SERVER_SUCCESS =
  "FETCH_RECOVERY_POINT_SERVER_SUCCESS";
export const FETCH_RECOVERY_POINT_SERVER_FAILURE =
  "FETCH_RECOVERY_POINT_SERVER_FAILURE";
export const CREATE_RECOVERY_POINT_SERVER_REQUEST =
  "CREATE_RECOVERY_POINT_SERVER_REQUEST";
export const UPDATE_RECOVERY_POINT_SERVER_REQUEST =
  "UPDATE_RECOVERY_POINT_SERVER_REQUEST";
export const DELETE_RECOVERY_POINT_SERVER_REQUEST =
  "DELETE_RECOVERY_POINT_SERVER_REQUEST";

export const RESET_CURRENT_LINUX_BACKUP_SERVER_REQUEST =
  "RESET_CURRENT_LINUX_BACKUP_SERVER_REQUEST";
export const FETCH_LINUX_BACKUP_SERVER_REQUEST =
  "FETCH_LINUX_BACKUP_SERVER_REQUEST";
export const FETCH_LINUX_BACKUP_SERVER_SUCCESS =
  "FETCH_LINUX_BACKUP_SERVER_SUCCESS";
export const FETCH_LINUX_BACKUP_SERVER_FAILURE =
  "FETCH_LINUX_BACKUP_SERVER_FAILURE";
export const CREATE_LINUX_BACKUP_SERVER_REQUEST =
  "CREATE_LINUX_BACKUP_SERVER_REQUEST";
export const UPDATE_LINUX_BACKUP_SERVER_REQUEST =
  "UPDATE_LINUX_BACKUP_SERVER_REQUEST";
export const DELETE_LINUX_BACKUP_SERVER_REQUEST =
  "DELETE_LINUX_BACKUP_SERVER_REQUEST";

// audit trail
export const FETCH_AUDIT_CODES_REQUEST = "FETCH_AUDIT_CODES_REQUEST";
export const FETCH_AUDIT_CODES_SUCCESS = "FETCH_AUDIT_CODES_SUCCESS";
export const FETCH_AUDIT_CODES_FAILURE = "FETCH_AUDIT_CODES_FAILURE";

export const SET_AUDIT_TRAIL_SEARCH_VALUES = "SET_AUDIT_TRAIL_SEARCH_VALUES";
export const SET_AUDIT_TRAIL_SORT_STRING = "SET_AUDIT_TRAIL_SORT_STRING";

export const FETCH_AUDIT_TRAIL_SEARCH_REQUEST =
  "FETCH_AUDIT_TRAIL_SEARCH_REQUEST";
export const FETCH_AUDIT_TRAIL_SEARCH_SUCCESS =
  "FETCH_AUDIT_TRAIL_SEARCH_SUCCESS";
export const FETCH_AUDIT_TRAIL_SEARCH_FAILURE =
  "FETCH_AUDIT_TRAIL_SEARCH_FAILURE";

export const FETCH_DATA_CENTERS_REQUEST = "FETCH_DATA_CENTERS_REQUEST";
export const FETCH_DATA_CENTERS_SUCCESS = "FETCH_DATA_CENTERS_SUCCESS";
export const FETCH_DATA_CENTERS_FAILURE = "FETCH_DATA_CENTERS_FAILURE";

export const FETCH_RECOVERY_POINT_SERVERS_REQUEST =
  "FETCH_RECOVERY_POINT_SERVERS_REQUEST";
export const FETCH_RECOVERY_POINT_SERVERS_SUCCESS =
  "FETCH_RECOVERY_POINT_SERVERS_SUCCESS";
export const FETCH_RECOVERY_POINT_SERVERS_FAILURE =
  "FETCH_RECOVERY_POINT_SERVERS_FAILURE";

export const FETCH_CLOUD_HYBRID_STORES_REQUEST =
  "FETCH_CLOUD_HYBRID_STORES_REQUEST";
export const FETCH_CLOUD_HYBRID_STORES_SUCCESS =
  "FETCH_CLOUD_HYBRID_STORES_SUCCESS";
export const FETCH_CLOUD_HYBRID_STORES_FAILURE =
  "FETCH_CLOUD_HYBRID_STORES_FAILURE";

export const FETCH_LINUX_BACKUP_SERVERS_REQUEST =
  "FETCH_LINUX_BACKUP_SERVERS_REQUEST";
export const FETCH_LINUX_BACKUP_SERVERS_SUCCESS =
  "FETCH_LINUX_BACKUP_SERVERS_SUCCESS";
export const FETCH_LINUX_BACKUP_SERVERS_FAILURE =
  "FETCH_LINUX_BACKUP_SERVERS_FAILURE";

export const FETCH_DATA_STORES_REQUEST = "FETCH_DATA_STORES_REQUEST";
export const FETCH_DATA_STORES_SUCCESS = "FETCH_DATA_STORES_SUCCESS";
export const FETCH_DATA_STORES_FAILURE = "FETCH_DATA_STORES_FAILURE";

export const SET_RECOVERY_POINT_SERVERS_SORT_STRING =
  "SET_RECOVERY_POINT_SERVERS_SORT_STRING";
export const SET_LINUX_BACKUP_SERVERS_SORT_STRING =
  "SET_LINUX_BACKUP_SERVERS_SORT_STRING";
export const SET_DATA_STORES_SORT_STRING = "SET_DATA_STORES_SORT_STRING";
export const SET_CH_DATA_STORES_SORT_STRING = "SET_CH_DATA_STORES_SORT_STRING";

export const CREATE_DATA_STORE_REQUEST = "CREATE_DATA_STORE_REQUEST";
export const CREATE_DATA_STORE_SUCCESS = "CREATE_DATA_STORE_SUCCESS";
export const CREATE_DATA_STORE_FAILURE = "CREATE_DATA_STORE_FAILURE";

export const FETCH_DATA_STORE_REQUEST = "FETCH_DATA_STORE_REQUEST";
export const FETCH_DATA_STORE_SUCCESS = "FETCH_DATA_STORE_SUCCESS";
export const FETCH_DATA_STORE_FAILURE = "FETCH_DATA_STORE_FAILURE";

export const UPDATE_DATA_STORE_REQUEST = "UPDATE_DATA_STORE_REQUEST";
export const UPDATE_DATA_STORE_SUCCESS = "UPDATE_DATA_STORE_SUCCESS";
export const UPDATE_DATA_STORE_FAILURE = "UPDATE_DATA_STORE_FAILURE";

export const DESTROY_DATA_STORE_REQUEST = "DESTROY_DATA_STORE_REQUEST";

export const DELETE_DATA_STORE_REQUEST = "DELETE_DATA_STORE_REQUEST";

export const START_DATA_STORE_REQUEST = "START_DATA_STORE_REQUEST";
export const STOP_DATA_STORE_REQUEST = "STOP_DATA_STORE_REQUEST";

export const FETCH_BI_REPORTS_TYPES_REQUEST = "FETCH_BI_REPORTS_TYPES_REQUEST";
export const FETCH_BI_REPORTS_TYPES_SUCCESS = "FETCH_BI_REPORTS_TYPES_SUCCESS";
export const FETCH_BI_REPORTS_TYPES_FAILURE = "FETCH_BI_REPORTS_TYPES_FAILURE";

export const FETCH_BI_REPORT_REQUEST = "FETCH_BI_REPORT_REQUEST";
export const FETCH_BI_REPORT_SUCCESS = "FETCH_BI_REPORT_SUCCESS";
export const FETCH_BI_REPORT_FAILURE = "FETCH_BI_REPORT_FAILURE";

export const FETCH_BI_REPORT_RECIPIENTS_REQUEST =
  "FETCH_BI_REPORT_RECIPIENTS_REQUEST";
export const FETCH_BI_REPORT_RECIPIENTS_SUCCESS =
  "FETCH_BI_REPORT_RECIPIENTS_SUCCESS";
export const FETCH_BI_REPORT_RECIPIENTS_FAILURE =
  "FETCH_BI_REPORT_RECIPIENTS_FAILURE";
export const UPDATE_BI_REPORT_RECIPIENTS_REQUEST =
  "UPDATE_BI_REPORT_RECIPIENTS_REQUEST";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_HELP_LINK_FILE = "UPLOAD_HELP_LINK_FILE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
