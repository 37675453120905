/**
 * Helper service to build Dashboard widgets
 */
const ReportsHelper = {
  /**
   * Builds dashboard widget with requested configuration and data.
   * @param {string} type
   * @param {object} configuration
   * @param {object} data
   * @param {object} legends
   */
  buildWidgetForType() {
    return null;
  }
};

/**
 * Builds widget for constant.ReportsWidgetTypes.CH_VOLUME_TREND
 * @param {object} configuration
 * @param {object} data
 * @param {object} legends
 */
// TODO clean up
// const buildWidgetForCHvolumes = (
//   configuration,
//   data,
//   legends,
//   options,
//   actions,
//   advancedSearchData
// ) => {
//   return (
//     <ChartLayout
//       title="Usage Trend for Cloud Hybrid Stores"
//       actions={{
//         optionAction:
//           actions && actions.optionAction ? actions.optionAction : "",
//         refreshAction:
//           actions && actions.refreshAction ? actions.refreshAction : ""
//       }}
//       advancedSearchData={advancedSearchData}
//       chart={
//         data.length ? (
//           <Trend
//             configuration={configuration}
//             data={data}
//             legends={[]}
//             options={options ? options : ""}
//           />
//         ) : (
//           emptyChartView()
//         )
//       }
//     />
//   );
// };

export default ReportsHelper;
