import { combineReducers } from "redux";
import * as actionType from "../../actions/actionTypes";

function sources(state = [], { type, data }) {
  switch (type) {
    case actionType.CLEAR_AGENT_MACHINE_SOURCES_LIST:
      return [];
    case actionType.UPDATE_AGENT_MACHINE_SOURCES_LIST:
      return data;
    default:
      return state;
  }
}

export default combineReducers({
  sources
});
