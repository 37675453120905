/* eslint-disable no-case-declarations */
import {
  ADD_DISCOVER_SOURCES_SUCCESS,
  ADD_DISCOVER_SOURCES_FAILURE,
  SELECT_AD_FOR_BROWSE,
  ADD_DISCOVER_ADD_AD,
  ADD_DISCOVER_LIST_AD,
  ADD_DISCOVER_BROWSE_AD_SOURCES,
  FETCH_AD_SOURCES,
  ADD_SOURCES_FROM_ACTIVE_DIRECTORIES,
  CLEAR_AD_DATA,
  SHOW_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
  AD_SOURCES_SUCCESSFULLY_AUTHENTICATED,
  AD_SOURCES_AUTH_RESPONSE,
  CLEAR_AD_AUTH_RESPONSE,
  DELETE_AUTHENTICATED_SOURCE,
  AD_SOURCES_FAILED_AUTHENTIATION,
  CLEAR_AD_SOURCES_FAILED_AUTHENTIATION,
  CLEAR_AD_SOURCES_LIST,
  AD_INCREMENT_AUTH_COUNT,
  AD_INITIALIZE_AUTH_COUNT,
  AD_WIN_SOURCE_AUTH_SUCCESS,
  AD_WIN_SOURCE_AUTH_FAILURE,
  CLEAR_AD_ERROR,
  BROWSE_ACTIVE_DIRECTORIES,
  FETCH_SOURCES_FROM_ACTIVE_DIRECTORIES_ERROR,
  CLEAR_AD_SOURCES_ERROR,
  CLEAR_SUCCESSFULL_AUTHENTICATED_AD_SOURCES
} from "state/actions/actionTypes";
import { combineReducers } from "redux";

/**
 * Errors occured during Connect API call to LBS is maintained here
 * @param {array} state
 * @param {*} action
 */
const addAdErrors = (state = [], { type, errors }) => {
  switch (type) {
    case ADD_DISCOVER_SOURCES_FAILURE:
      return [...errors];
    case ADD_DISCOVER_SOURCES_SUCCESS:
      return [];
    case CLEAR_AD_DATA:
      return [];
    case CLEAR_AD_ERROR:
      return [];
    default:
      return state;
  }
};

export const LeftPanel = {
  ADD_AD: "addAd",
  AD_LIST: "adList",
  BROWSE: "browse",
  FETCH_LIST: "fetchList"
};

/**
 *
 * @param {string} state
 * @param {*} action
 */
const leftPanel = (state = LeftPanel.AD_LIST, { type }) => {
  switch (type) {
    case ADD_DISCOVER_ADD_AD:
      return LeftPanel.ADD_AD;
    case ADD_DISCOVER_LIST_AD:
      return LeftPanel.AD_LIST;
    case ADD_DISCOVER_BROWSE_AD_SOURCES:
      return LeftPanel.BROWSE;
    case CLEAR_AD_DATA:
      return LeftPanel.AD_LIST;
    case FETCH_AD_SOURCES:
      return LeftPanel.FETCH_LIST;
    default:
      return state;
  }
};

/**
 * If Connect API is success, then we are adding the fields
 * to connected list of servers to be added to sources
 * @param {*} state
 * @param {*} action
 */
const adList = (state = [], { type, data, entryId, selected }) => {
  switch (type) {
    case ADD_DISCOVER_SOURCES_SUCCESS:
      return data;
    case SELECT_AD_FOR_BROWSE:
      return state.map(item =>
        item.entry_id === entryId ? { ...item, selected } : item
      );
    case CLEAR_AD_DATA:
      return [];
    default:
      return state;
  }
};

const adSourcesList = (state = [], { type, data, source, error_message }) => {
  switch (type) {
    case ADD_SOURCES_FROM_ACTIVE_DIRECTORIES:
      return data;
    case AD_SOURCES_SUCCESSFULLY_AUTHENTICATED:
      const newSavedList = state.filter(
        obj => obj.node_name !== data.node_name
      );
      return newSavedList;
    case DELETE_AUTHENTICATED_SOURCE:
      return [...state, { ...data }];
    case AD_WIN_SOURCE_AUTH_SUCCESS:
      return state.filter(item => item.node_name !== source.node_name);
    case AD_WIN_SOURCE_AUTH_FAILURE:
      return state.map(item =>
        item.node_name === source.node_name
          ? { ...item, showErrorIcon: true, msg: error_message }
          : item
      );
    case CLEAR_AD_SOURCES_LIST:
      return [];
    case CLEAR_AD_DATA:
      return [];
    default:
      return state;
  }
};

const adSourcesListError = (state = [], { type, data }) => {
  switch (type) {
    case FETCH_SOURCES_FROM_ACTIVE_DIRECTORIES_ERROR:
      return data;
    case CLEAR_AD_SOURCES_ERROR:
      return [];
    default:
      return state;
  }
};

const successfullyAuthenticatedSource = (
  state = [],
  { type, data, source }
) => {
  switch (type) {
    case AD_SOURCES_SUCCESSFULLY_AUTHENTICATED:
      return [...state, { ...data }];
    case DELETE_AUTHENTICATED_SOURCE:
      const newSavedList = state.filter(
        obj => obj.node_name !== data.node_name
      );
      return newSavedList;
    case CLEAR_SUCCESSFULL_AUTHENTICATED_AD_SOURCES:
      return [];
    case AD_WIN_SOURCE_AUTH_SUCCESS:
      const isSourceExist = state.some(
        obj => obj.node_name === source.node_name
      );
      return [...state, ...(!isSourceExist ? [source] : [])];
    case CLEAR_AD_DATA:
      return [];
    default:
      return state;
  }
};

const failedAuthenticatedSource = (state = [], { type, data }) => {
  switch (type) {
    case AD_SOURCES_FAILED_AUTHENTIATION:
      return [...state, { ...data }];
    case CLEAR_AD_SOURCES_FAILED_AUTHENTIATION:
      return [];
    case CLEAR_AD_DATA:
      return [];
    default:
      return state;
  }
};

const authResponse = (state = {}, { type, result, dataWithCredentials }) => {
  switch (type) {
    case AD_SOURCES_AUTH_RESPONSE:
      return {
        result: result,
        dataWithCredentials: dataWithCredentials
      };
    case CLEAR_AD_AUTH_RESPONSE:
      return {};
    case CLEAR_AD_DATA:
      return {};
    default:
      return state;
  }
};

/**
 * Show the Auth modal when Save to List is clicked
 * @param {boolean} state
 * @param {*} action
 */
const showAuthModal = (state = false, { type }) => {
  switch (type) {
    case SHOW_AUTH_MODAL:
      return true;
    case CLOSE_AUTH_MODAL:
      return false;
    default:
      return state;
  }
};

const authenticatedCount = (state = 0, { type }) => {
  switch (type) {
    case AD_INCREMENT_AUTH_COUNT:
      return state + 1;
    case AD_INITIALIZE_AUTH_COUNT:
      return 0;
    case CLEAR_AD_DATA:
      return 0;
    default:
      return state;
  }
};

const authenticationTotalCount = (state = 0, { type, total }) => {
  switch (type) {
    case AD_INITIALIZE_AUTH_COUNT:
      return total;
    // case ADD_DISCOVER_BROWSE_AD_SOURCES:
    //   return 0;
    case CLEAR_AD_DATA:
      return 0;
    default:
      return state;
  }
};

const selectedAd = (state = null, { type, activeDirectories }) => {
  switch (type) {
    case BROWSE_ACTIVE_DIRECTORIES:
      return activeDirectories[0];
    default:
      return state;
  }
};

export default combineReducers({
  addAdErrors,
  adList,
  leftPanel,
  showAuthModal,
  adSourcesList,
  successfullyAuthenticatedSource,
  authResponse,
  failedAuthenticatedSource,
  authenticatedCount,
  authenticationTotalCount,
  selectedAd,
  adSourcesListError
});
