import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormattedMessage, injectIntl } from "react-intl";
import { updateSelectedDropdownWidget } from "state/actions/actions";
import { updateFilters, resetFilters } from "state/actions/ccGraph";
import { getFilters } from "state/selectors/ccGraph";
import { getLogin } from "state/selectors";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { ID_PREFIX } from "configs/IdConfig";

const Header = props => {
  const onChange = (data, action, graphId) => {
    /*To invoke action from props*/
    if (data && action) {
      props.updateFilters(graphId, { filter_type: data }, true, null);
    }
  };

  const filterByOption = event => {
    if (!event) return;
    onChange(event.target.value, props.action, props.graphId);
  };
  const { titleId, subtitleId, options, chartType, children } = props;
  const cd_product_name = get(
    props,
    "brand_names.CD_PRODUCT_NAME",
    "Cloud Direct"
  );
  const recoveryPointWidgets = [
    "numberOfRecoveryPoints",
    "latestRecoveryPoints",
    "oldestRecoveryPoints",
    "reportsNumberOfRecoveryPoints",
    "reportsLatestRecoveryPoints",
    "reportsOldestRecoveryPoints"
  ];
  const isRecoveryPointWidget = recoveryPointWidgets.includes(chartType);
  const filterOptions =
    options &&
    options.filterOptions.map(d => {
      return {
        ...d,
        label: isRecoveryPointWidget
          ? d.label
          : props.intl.formatMessage({
              id: `${chartType}.${d.value}`
            })
      };
    });
  return (
    <div className="chart-header d-flex align-items-center justify-content-between">
      <div className="d-flex header-wrapper">
        {titleId && (
          <h6>
            <FormattedMessage
              id={titleId}
              values={{ CD_PRODUCT_NAME: cd_product_name }}
            />
          </h6>
        )}
        {subtitleId && (
          <span className="subtitle">
            <FormattedMessage id={subtitleId} />
          </span>
        )}
      </div>

      <div className="header-icons">
        {options &&
        filterOptions &&
        filterOptions.length &&
        props.showDropdown ? (
          <div className="chart-dropdown">
            <Select
              className="select-box mui-action-select-dropdown"
              onChange={filterByOption}
              value={
                props.selectedOption &&
                !isEmpty(props.selectedOption) &&
                filterOptions.map(d => d.value).includes(props.selectedOption)
                  ? props.selectedOption
                  : options.selectedOption
              }
              id={`${ID_PREFIX}${props.graphId}_eHNiVL`}
            >
              {filterOptions.map(item => {
                return (
                  <MenuItem
                    key={`${props.graphId}_${item.value}`}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        ) : (
          ""
        )}
        {children}
      </div>
    </div>
  );
};

Header.propTypes = {
  titleId: PropTypes.string.isRequired,
  subtitleId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  options: PropTypes.shape({}),
  updateFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired
};

Header.defaultProps = {
  subtitleId: null,
  children: null,
  options: null
};

const mapStateToProps = (state, ownProps) => {
  return {
    brand_names: getLogin(state).brand_names,
    selectedOption: getFilters(ownProps.graphId)(state)
      ? getFilters(ownProps.graphId)(state).filter_type
      : null
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSelectedDropdownWidget,
      updateFilters,
      resetFilters
    },
    dispatch
  );

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header));
