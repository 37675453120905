/* eslint-disable camelcase */
import {
  select,
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import * as ccToast from "state/actions/ccToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getDisplayLoginModal } from "state/selectors/csr";
import * as CsrActions from "state/actions/csr";
import { getLogin } from "state/selectors";
import { isCSRUser, isMspMonitorUser, isDirectMonitorUser } from "utils";
import { reset, stopSubmit } from "redux-form";
import store from "state/store";
import {
  addSuccessNotification,
  addErrorNotification
} from "state/actions/ccToast";
import _ from "lodash";
import {
  FETCH_ACCESS_TOKEN,
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOG_OUT,
  GET_USER_DETAILS,
  GET_OCTA_USER_DETAILS,
  UPDATE_USER_CONTACT_INFO,
  UPDATED_USER_CONTACT_INFO,
  UPDATE_PASSWORD,
  PASSWORD_UPDATED,
  SHOW_INFORMATION,
  UPLOAD_PHOTO,
  DELETE_PHOTO,
  USER_PHOTO_DELETED,
  USER_PHOTO_UPDATED,
  CREATE_PASSWORD,
  CREATE_PASSWORD_ERROR,
  ACTIVATION_SUCCESSFUL,
  ACTIVATION_FAILED,
  RESEND_ACTIVATION_EMAIL,
  ACTIVATE_ACCOUNT,
  CREATE_PASSWORD_SUCCESS,
  GET_BRAND_DETAILS_ON_LOGIN,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_MESSAGE,
  IMPERSONATE_CUSTOMER,
  IMPERSONATE_SOURCE,
  IMPERSONATE_POLICY,
  IMPERSONATE_JOB,
  IMPERSONATE_RECOVERY_POINTS,
  FETCH_ENTITLEMENTS_PRODUCTS_REQUEST,
  TWO_FACTOR_VERIFY_PASSWORD,
  TWO_FACTOR_CHECK,
  TWO_FACTOR_GENERATE_CODES,
  TWO_FACTOR_TOGGLE,
  TWO_FACTOR_GET_SECRET,
  GET_ORGANIZATION,
  FETCH_ALL_HELP_LINK_REQUEST,
  GET_TWO_FACTOR_SECRET,
  TRIGGER_LOG_OUT
} from "../actions/actionTypes";
import {
  setJWTToken,
  unsetJWTToken,
  logoutRequest,
  logOut,
  showUserDetails,
  showUserProfileDetails,
  setLoginLoaderOpacity,
  showBrandingDetails,
  persistColumnData,
  fetchEntitlementsProducts,
  fetchEntitlementsProductsSuccess,
  fetchEntitlementsProductsFailure,
  fetchUserLanguageSuccess,
  updateMenuForMspMonitor,
  updateMenuForDirectMonitor,
  passwordValidated,
  addAPIresponse,
  showDialog,
  Dialog,
  twoFactorEnabled,
  twoFactorCodes,
  twoFactorToggle,
  twoFactorStoreSecret,
  twoFactorGetSecret,
  setLoading,
  resetLoading,
  hideDialog,
  Loader,
  storeOrganization,
  getOrganization,
  storeTemp,
  fetchAllHelpLink,
  fetchedAllHelpLinkSuccess,
  setTheme
} from "../actions/actions";
import { parseJwt } from "../../utils/authorizationUtil";
import { configureApi, loginApis, commonApi } from "../api";
import { HTTP_STATUS, DEFAULT_BRANDING_DATA } from "../../utils/appConstants";
import {
  MUI_INITIAL_THEME,
  setThemePrimaryAndSecColors,
  DARK_MODE,
  LIGHT_MODE
} from "../../utils/theme";
import { makeToastData } from "./sagautils";
import { changeCSSProperty } from "./sagautils";

const loginUrl = `${process.env.REACT_APP_BE_URL}/users/login`;
const loginCDUrl = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}api/login/authenticate`;
const redirectCDUrl = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}api/login/authenticatewithredirect`;

const setPasswordUrl = `${process.env.REACT_APP_BE_URL}/users/setpassword`;
const accountActivateUrl = `${process.env.REACT_APP_BE_URL}/organizations/enroll`;

const getState = state => state.reducer;
const getDialog = state => state.ui.dialogs;
// const getReducer = state => state.reducer;

function postForm(path, params, method) {
  method = method || "post";

  let form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);

  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      let hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

function loginApi(email, password, twoFactorCode, twoFactorRemember) {
  //Get the username and password fields from login form
  //into FormData object.
  const data = new FormData();
  data.append("username", email);
  data.append("password", password);
  //Post credentials
  return fetch(loginUrl, {
    headers: {
      origin: "*"
    },
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      username: email,
      password: password,
      two_factor_code: twoFactorCode,
      two_factor_remember: twoFactorRemember
    })
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {
      // on error display error message.
      if (response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
        store.dispatch(
          addErrorNotification(
            makeToastData("error.login.500", `c1iy63_${response.status}`)
          )
        );
      }
      if (response.status !== HTTP_STATUS.OK) {
        throw response;
      }
      return response;
    })
    .catch(error => {
      // eslint-disable-next-line no-throw-literal
      throw { error };
    });
}

function loginCDApi(username, password) {
  //Get the username and password fields from login form
  //into FormData object.
  const data = new FormData();
  data.append("username", username.trim());
  data.append("password", password.trim());

  return fetch(loginCDUrl, {
    headers: {
      origin: "*"
    },
    method: "POST",
    body: data
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {
      // on error display error message.
      if (response.status !== "SUCCESS") {
        throw response;
      } else {
        //we tried doing fetch but it caused redirection and cors errors, another way was setting window.location.href but to send data in post we had to use postForm method
        //so its kind of hack we did to make it work
        postForm(redirectCDUrl, {
          username: username.trim(),
          password: password.trim()
        });
      }
    })
    .catch(error => {
      // eslint-disable-next-line no-throw-literal
      throw { error };
    });
}

/*function refreshLoginApi(token) {
  //Post credentials
  return fetch(loginUrl, {
    headers: {
      origin: "*"

    },
    method: "POST",
    body: JSON.stringify({
      username: email,
      password: password
    })
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {
      // on error display error message.
      if (response.status !== HTTP_STATUS.OK) {
        throw response;
      }
      return response;
    })
    .catch(error => {
      throw { error };
    });
}*/

function setPasswordApi(email, password, token, type, two_factor_code) {
  //Get the username and password fields from login form
  //into FormData object.
  //Post credentials

  let requestPayload = {
    email: email,
    password: password,
    token: token,
    two_factor_code
  };
  if (type === "enrollment") {
    requestPayload["is_org_enrollment"] = true;
  }
  return fetch(setPasswordUrl, {
    headers: {
      origin: "*",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify(requestPayload)
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {
      return response;
    })
    .catch(error => {
      // eslint-disable-next-line no-throw-literal
      throw { error };
    });
}

function* logout() {
  yield put(persistColumnData(getState.columnData, getState.filterType));
  sessionStorage.removeItem("token");
  localStorage.removeItem("state");
  yield put(unsetJWTToken());
  yield put(storeTemp(""));
}

function* getTwoFactorSecret() {
  const loginReducer = yield select(getLogin);
  if (
    _.get(loginReducer, "organizationInfo.two_factor_required") === true &&
    !loginReducer.twoFactorEnabled
  ) {
    yield put(twoFactorGetSecret());
  }
}

function* triggerLogout() {
  try {
    const stateLogin = yield select(getLogin);
    // const commonState = yield select(getReducer);
    // let organization_id = commonState.isImpersonationView
    //   ? commonState.customerOrgId
    //   : stateLogin.organization_id;

    let apiResponse = yield call(loginApis.triggerLogout, stateLogin.token);
    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put(unsetJWTToken());
      yield put(logoutRequest());
      yield put(logOut());
    } else {
      alert("Logout api Failed");
    }
    //yield put(showAlertList(apiResponse));
  } catch (e) {
    alert("Logout api Failed");
    window.console.error(e);
  }
}

function* login(payload) {
  const csrLoginModalActive = yield select(getDisplayLoginModal);

  //Check if username & password fields are entered.
  try {
    yield put(setLoginLoaderOpacity(0.5));

    yield put(showLoading());
    //Read token from Login response
    let response = {};
    const loginReducer2 = yield select(getLogin);
    if (loginReducer2.twoFactorEnabled) {
      response = yield call(
        loginApi,
        payload.username,
        payload.password,
        payload.two_factor_code,
        payload.two_factor_remember
      );
    } else {
      response = yield call(loginApi, payload.username, payload.password);
    }
    let jwtToken;
    let refreshToken;
    jwtToken = response.data.token;
    refreshToken = response.data.refresh_token;
    //Set token in state
    const parsedToken = parseJwt(jwtToken);

    yield put(setJWTToken({ jwtToken, parsedToken, refreshToken }));
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(fetchEntitlementsProducts(organizationId));
    yield put(getOrganization(organizationId));
    yield put(fetchAllHelpLink());
    yield put(hideLoading());
    yield put({ type: GET_USER_DETAILS });
    const userDetails = yield select(getLogin);
    const roleId = userDetails.role_id;
    let redirectTo = "/monitor";

    if (isCSRUser(roleId)) {
      redirectTo = "/csr";
    } else if (isDirectMonitorUser(roleId)) {
      yield put(updateMenuForDirectMonitor());
    } else if (isMspMonitorUser(roleId)) {
      yield put(updateMenuForMspMonitor());
    }

    yield call(twoFactorCheck, {
      username: payload.username,
      ignore_cookie: true
    });
    // const loginReducer1 = yield select(getLogin);

    // if (
    //   (_.get(loginReducer1, "organizationInfo.two_factor_required") === true ||
    //     _.get(loginReducer1, "organizationInfo.two_factor_required") ===
    //       undefined) &&
    //   !loginReducer1.twoFactorEnabled
    // ) {
    //   yield put(storeTemp(payload.password));
    //   yield put(twoFactorGetSecret());
    //   yield put(showDialog(Dialog.TWO_FACTOR_CONFIG));
    // }
    yield put({ type: LOGIN_SUCCESS, redirectTo });

    yield* handleFetchUserLanguage();
    if (csrLoginModalActive) {
      yield put(CsrActions.closeLoginModal());
    }
  } catch (error) {
    try {
      //if CC login fails then try CD login as well
      yield call(loginCDApi, payload.username, payload.password);
    } catch (e) {
      yield put(setLoginLoaderOpacity(0));
      yield put(hideLoading());
      //note that we are displaying CC error below as that is the prime error that we should show if both CC and CD login fails
      yield put({ type: LOGIN_ERROR, error });
    }

    if (csrLoginModalActive) {
      if (error.error && error.error.errors && error.error.errors.length > 0) {
        yield put(
          CsrActions.addNotifications(
            error.error.errors.map(err => err.message)
          )
        );
      }
    }
  } finally {
    const loginReducer2 = yield select(getLogin);
    if (
      _.get(loginReducer2, "organizationInfo.two_factor_required") === true &&
      !loginReducer2.twoFactorEnabled
    ) {
      yield put(storeTemp(payload.password));
      yield put(twoFactorGetSecret());
      yield put(showDialog(Dialog.TWO_FACTOR_CONFIG));
    }
  }
}

function* setPassword(payload) {
  //Check if username & password fields are entered.
  try {
    yield put(setLoginLoaderOpacity(0.5));
    yield put(showLoading());
    //Read token from Login response
    let response = yield call(
      setPasswordApi,
      payload.data.email,
      payload.data.password,
      payload.data.token,
      payload.data.type,
      payload.data.two_factor_code
    );

    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
      yield put({ type: CREATE_PASSWORD_ERROR, error: { status: 403 } });
    } else if (response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
      yield put({ type: CREATE_PASSWORD_ERROR, error: { status: 500 } });
    } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
      yield put({
        type: CREATE_PASSWORD_ERROR,
        error: {
          status: response.status,
          errorCode: response.errors[0].code,
          errorMessage: response.errors[0].message
        }
      });
    } else {
      let jwtToken;
      let refreshToken;
      jwtToken = response.data.token;
      //Handle null refresh token.
      refreshToken = response.data.refresh_token
        ? response.data.refresh_token
        : null;

      //Set token in state
      const parsedToken = parseJwt(jwtToken);

      yield put(setJWTToken({ jwtToken, parsedToken, refreshToken }));
      yield put(hideLoading());
      yield put({ type: GET_USER_DETAILS });
      const loginReducer = yield select(getLogin);
      const organizationId = loginReducer.organization_id;
      yield put(fetchEntitlementsProducts(organizationId));
      yield put(getOrganization(organizationId));
      const userDetails = yield call(loginApis.fetchUserDetails, jwtToken);
      const loginReducer1 = yield select(getLogin);

      if (
        _.get(loginReducer1, "organizationInfo.two_factor_required") &&
        !loginReducer1.twoFactorEnabled
      ) {
        yield put(twoFactorGetSecret());
        yield put(showDialog(Dialog.TWO_FACTOR_CONFIG));
      }
      let redirectTo = "/monitor";

      if (isCSRUser(userDetails.data.role_id)) {
        redirectTo = "/csr";
      }

      //Update state with token details and successful login state.
      yield put({ type: CREATE_PASSWORD_SUCCESS, redirectTo });
    }
  } catch (error) {
    yield put(setLoginLoaderOpacity(0));
    yield put(hideLoading());
    yield put({ type: CREATE_PASSWORD_ERROR, error });
  }
}

function activateAccountApi(getLogin) {
  var organizationObj = {
    organization_name: getLogin.enroll_company,
    organization_type: getLogin.enroll_reseller ? "msp" : "direct",
    //datacenter_id: datacenter_id,
    country: getLogin.country
  };
  if (
    getLogin.enrollment_code !== undefined &&
    getLogin.enrollment_code !== null
  ) {
    organizationObj.partner_enrollment_code = getLogin.enrollment_code;
  }

  return fetch(accountActivateUrl, {
    headers: {
      origin: "*",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      user: {
        first_name: getLogin.enroll_firstName,
        last_name: getLogin.enroll_lastName,
        email: getLogin.enroll_email,
        phone_number: getLogin.enroll_phone
      },
      organization: organizationObj,
      clouddirect_trial: false
    })
  })
    .then(response => response.json()) //On successful response get the JSON Object.
    .then(function(response) {
      return response;
    })
    .catch(error => {
      // eslint-disable-next-line no-throw-literal
      throw { error };
    });
}

/**
 * Get the user details
 */
function* getUserData() {
  try {
    const stateLogin = yield select(getLogin);
    let userDetails = {};
    userDetails = yield call(loginApis.fetchUserDetails, stateLogin.token);
    if (userDetails.status === HTTP_STATUS.OK) {
      yield put(showUserDetails(userDetails.data));
      yield handleFetchAllHelpLinks();
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get the user details
 */
function* getOctaUserData() {
  try {
    const stateLogin = yield select(getLogin);
    let userDetails = {};
    userDetails = yield call(loginApis.fetchOctaUserDetails, stateLogin.token);
    if (userDetails.status === HTTP_STATUS.OK)
      yield put(showUserProfileDetails(userDetails.data));
  } catch (e) {
    window.console.log(e);
  }
}

function* fetchAccessToken({ history }) {
  try {
    const stateLogin = yield select(getLogin);
    let userDetails = {};
    userDetails = yield call(loginApis.getRefreshToken, stateLogin.token);
    if (userDetails) {
      const organizationId = userDetails.organization_id;
      const apiResponse = yield call(
        configureApi.fetchBrandDetails,
        organizationId,
        stateLogin.token
      );
      if (HTTP_STATUS.OK === apiResponse.status) {
        const brandObj = Object.assign({}, apiResponse.data);
        let themeValue = MUI_INITIAL_THEME();
        themeValue = setThemePrimaryAndSecColors(themeValue, brandObj);
        yield put(setTheme(themeValue));
        history.push("/");
      } else {
        const themeValue = MUI_INITIAL_THEME();
        yield put(setTheme(themeValue));
        history.push("/");
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Update user password
 */
function* updatePassword(action) {
  try {
    let responseStatus = {
      passwordChanged: false,
      errors: []
    };
    const stateLogin = yield select(getLogin);
    let response = yield call(
      loginApis.changePassword,
      action.data,
      stateLogin.token
    );
    if (response.status === HTTP_STATUS.OK) {
      responseStatus.passwordChanged = true;
    } else {
      responseStatus.passwordChanged = false;
      responseStatus.errors.push(response.errors[0]);
    }
    yield put({ type: PASSWORD_UPDATED, data: responseStatus });
    yield put({ type: SHOW_INFORMATION, data: true });
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Update user password
 */
function* forgotPassword(action) {
  try {
    let response = yield call(loginApis.forgotPassword, action.data);
    yield put({ type: FORGOT_PASSWORD_MESSAGE, response });
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Update user contact information
 */
function* updateUserData(action) {
  try {
    yield put(setLoading(Loader.UPDATE_PROFILE_DATA));
    const payload = action.data;
    delete payload.oldLanguage;
    const { userType } = payload;
    delete payload.userType;
    let userDetails = {};
    // to be changed when integrated with API
    const stateLogin = yield select(getLogin);
    const brandObj = yield select(state => state.configureReducer.brandData);
    let dataWithSuccessOrFail;
    let updateError = [];
    let onlyThemeChanged = false;

    if (action.data && action.data.preference_theme) {
      localStorage.setItem("CCTheme", action.data.preference_theme);
      if (
        action.data &&
        action.data.preference_language &&
        action.data.preference_language !== action.data.oldPreferenceLanguage
      ) {
        let themeValue = MUI_INITIAL_THEME();
        if (action.data.preference_theme === "system_default") {
          themeValue.palette.mode = action.data.prefersDarkMode
            ? DARK_MODE
            : LIGHT_MODE;
        } else {
          themeValue.palette.mode = action.data.preference_theme;
        }
        themeValue = setThemePrimaryAndSecColors(themeValue, brandObj);
        userDetails = yield call(
          loginApis.updateUserContactInfo,
          payload,
          stateLogin.token
        );
        yield put(setTheme(themeValue));
        changeCSSProperty(themeValue);
      } else {
        let themeValue = MUI_INITIAL_THEME();
        if (action.data.preference_theme === "system_default") {
          themeValue.palette.mode = action.data.prefersDarkMode
            ? DARK_MODE
            : LIGHT_MODE;
        } else {
          themeValue.palette.mode = action.data.preference_theme;
        }
        themeValue = setThemePrimaryAndSecColors(themeValue, brandObj);
        yield put(setTheme(themeValue));
        changeCSSProperty(themeValue);
        onlyThemeChanged = true;
      }
    } else {
      userDetails = yield call(
        loginApis.updateUserContactInfo,
        payload,
        stateLogin.token
      );
    }

    // below condition use to persist user details in case of error occurs.
    !userDetails.data
      ? (dataWithSuccessOrFail = action.data)
      : (dataWithSuccessOrFail = userDetails.data);
    if (onlyThemeChanged) {
      yield put(addSuccessNotification("Profile_Update"));
    } else if (
      userDetails.status === HTTP_STATUS.OK &&
      userDetails.errors.length === 0
    ) {
      yield* handleFetchUserLanguage();
      yield put(
        addSuccessNotification(makeToastData("Profile_Update", "nK6zwb"))
      );
    } else if (userDetails.errors && userDetails.errors.length !== 0) {
      updateError = userDetails.errors[0].message;
    }
    delete dataWithSuccessOrFail.oldLanguage;
    dataWithSuccessOrFail.user_type = userType;
    yield put({
      type: UPDATED_USER_CONTACT_INFO,
      data: dataWithSuccessOrFail,
      error: updateError
    });
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.UPDATE_PROFILE_DATA));
  }
}

/**
 * update user profile picture
 */
function* updatePhoto(action) {
  try {
    yield put(showLoading());
    const stateLogin = yield select(getLogin);
    let response = yield call(
      loginApis.changePhoto,
      action.data,
      stateLogin.token
    );
    if (response.status === HTTP_STATUS.CREATED) {
      yield put({
        type: USER_PHOTO_UPDATED,
        data:
          response.data && `${response.data.image_url}?${new Date().getTime()}`
      });
      yield put(
        addSuccessNotification(makeToastData("Profile_Photo_Changed", "Bm2aio"))
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * delete user profile picture
 */
function* deletePhoto() {
  try {
    yield put(showLoading());
    const stateLogin = yield select(getLogin);
    let response = yield call(loginApis.deletePhoto, stateLogin.token);
    if (response.status === HTTP_STATUS.OK) {
      yield put({ type: USER_PHOTO_DELETED });
      yield put(
        addSuccessNotification(makeToastData("Profile_Photo_Removed", "TUDogH"))
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * re-send activation email.
 */
function* resendActivationEmail() {}

/**
 * activate account.
 */
function* activateAccount() {
  let response = yield call(activateAccountApi, yield select(getLogin));
  if (response.status === 201) {
    yield put({ type: ACTIVATION_SUCCESSFUL });
  } else {
    yield put({ type: ACTIVATION_FAILED, response: response });
  }
}

function* getBrandingData(payload) {
  try {
    let portalURL = payload.portalURL;
    let apiResponse = yield call(
      loginApis.getBrandingData,
      `portal_url=${portalURL}`
    );
    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put(showBrandingDetails(apiResponse.data));
    } else {
      yield put(showBrandingDetails(DEFAULT_BRANDING_DATA));
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* impersonateCustomer(payload) {
  try {
    yield put(fetchEntitlementsProducts(payload.customerData.customerOrgId));
  } catch (e) {
    window.console.log(e);
  }
}
function* impersonateSource(payload) {
  try {
    yield put(fetchEntitlementsProducts(payload.sourceData.customerOrgId));
  } catch (e) {
    window.console.log(e);
  }
}
function* impersonatePolicy(payload) {
  try {
    yield put(fetchEntitlementsProducts(payload.policyData.customerOrgId));
  } catch (e) {
    window.console.log(e);
  }
}

function* impersonateJob(payload) {
  try {
    yield put(fetchEntitlementsProducts(payload.policyData.customerOrgId));
  } catch (e) {
    window.console.log(e);
  }
}

function* impersonateRecoveryPoints(payload) {
  try {
    yield put(fetchEntitlementsProducts(payload.policyData.customerOrgId));
  } catch (e) {
    window.console.log(e);
  }
}

function* handleFetchEntitlementsProducts(action) {
  try {
    const stateLogin = yield select(getLogin);
    const token = stateLogin.token;

    let apiResponse = yield call(
      loginApis.getEntitlements,
      token,
      action.payload.organizationId
    );

    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put(fetchEntitlementsProductsSuccess(apiResponse.data));
    } else {
      yield put(fetchEntitlementsProductsFailure());
    }
  } catch (e) {
    window.console.log(e);
  }
}

function* handleFetchUserLanguage() {
  try {
    const stateLogin = yield select(getLogin);
    const token = stateLogin.token;

    let apiResponse = yield call(loginApis.getUserLanguage, token);

    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put(fetchUserLanguageSuccess(apiResponse.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* verifyPassword(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = {};
    if (loginReducer.twoFactorEnabled) {
      yield put(
        twoFactorToggle({
          password: action.data.password,
          two_factor_enable: false,
          userid: loginReducer.user_id
        })
      );
    } else {
      response = yield call(
        loginApis.userVerifyPassword,
        loginReducer.token,
        action.data
      );

      if (response.status === HTTP_STATUS.OK) {
        yield put(passwordValidated(true));
        yield put(twoFactorGetSecret());
        yield put(showDialog(Dialog.TWO_FACTOR_CONFIG));
      } else {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              _.get(response, "errors[0].message", ""),
              `KkBlSi_${response.status}`
            )
          )
        );
      }
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* twoFactorCheck(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = {};
    if (action.username) {
      response = yield call(
        loginApis.twoFactorEnabled,
        loginReducer.token,
        {
          username: action.username,
          ignore_cookie: action.ignore_cookie ? action.ignore_cookie : false
        },
        false
      );
    } else {
      response = yield call(
        loginApis.twoFactorEnabled,
        loginReducer.token,
        {
          username: loginReducer.email,
          ignore_cookie: true
        },
        false
      );
    }
    if (response.status === HTTP_STATUS.OK) {
      yield put(twoFactorEnabled(response.data));
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* twoFactorGenerateCodes(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      loginApis.userUseOneTimeCodes,
      loginReducer.token,
      action.data
    );

    if (response.status === HTTP_STATUS.OK) {
      yield put(twoFactorCodes(response.data));
      yield put(passwordValidated(true));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `Zhy8x9_${response.status}`
          )
        )
      );
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* userTwoFactorToggle(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      loginApis.userTwoFactorToggle,
      loginReducer.token,
      action.data
    );

    if (response.status === HTTP_STATUS.OK) {
      const dialogReducer = yield select(getDialog);
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            _.get(action.data, "two_factor_enable")
              ? "two_factor_enabled"
              : "two_factor_disabled",
            "jAiIcH"
          )
        )
      );
      yield call(twoFactorCheck, loginReducer.email);
      if (_.get(dialogReducer, "TWO_FACTOR_CONFIG")) {
        yield put(hideDialog(Dialog.TWO_FACTOR_CONFIG));
        yield put(reset("twoFactorModal"));
        yield put(passwordValidated(false));
        yield put(twoFactorStoreSecret({}));
        yield call(twoFactorCheck, loginReducer.email);
        yield put(stopSubmit("twoFactorModal"));
      }
    } else {
      yield put(stopSubmit("twoFactorModal"));
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `JeTG1F_${response.status}`
          )
        )
      );
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(resetLoading(Loader.TWO_FACTOR_ENABLE));
    yield put(storeTemp(""));
    yield put(hideLoading());
  }
}

export function* userTwoFactorGetSecret(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      loginApis.twoFactorCheckSecret,
      loginReducer.token
    );

    if (response.status === HTTP_STATUS.OK) {
      yield put(twoFactorStoreSecret(response.data));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `U6y3r9_${response.status}`
          )
        )
      );
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* getOrganizationInfo(action) {
  try {
    const loginReducer = yield select(getLogin);
    // yield put(showLoading());
    let response = yield call(
      commonApi.fetchOrganizationById,
      loginReducer.token,
      action.id
    );
    if (response.status === HTTP_STATUS.OK) {
      if (action.impersonation === "impersonationView") {
        yield put(storeOrganization(response.data, true));
      } else {
        yield put(storeOrganization(response.data, false));
      }
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    // yield put(hideLoading());
  }
}

function* handleFetchAllHelpLinks() {
  try {
    const { token, locale } = yield select(getLogin);
    let apiResponse = yield call(loginApis.getAllHelpLink, token, locale);
    if (apiResponse && apiResponse.status === HTTP_STATUS.OK) {
      yield put(fetchedAllHelpLinkSuccess(apiResponse.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export default function* loginSaga() {
  yield all([
    takeEvery(FETCH_ACCESS_TOKEN, fetchAccessToken),
    takeEvery(LOGIN_REQUESTING, login),
    takeEvery(LOG_OUT, logout),
    takeEvery(GET_USER_DETAILS, getUserData),
    takeEvery(GET_OCTA_USER_DETAILS, getOctaUserData),
    takeEvery(UPDATE_USER_CONTACT_INFO, updateUserData),
    takeEvery(UPDATE_PASSWORD, updatePassword),
    takeEvery(UPLOAD_PHOTO, updatePhoto),
    takeEvery(DELETE_PHOTO, deletePhoto),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(CREATE_PASSWORD, setPassword),
    takeLatest(RESEND_ACTIVATION_EMAIL, resendActivationEmail),
    takeLatest(ACTIVATE_ACCOUNT, activateAccount),
    takeLatest(GET_BRAND_DETAILS_ON_LOGIN, getBrandingData),
    takeEvery(IMPERSONATE_CUSTOMER, impersonateCustomer),
    takeEvery(IMPERSONATE_SOURCE, impersonateSource),
    takeEvery(IMPERSONATE_POLICY, impersonatePolicy),
    takeEvery(IMPERSONATE_JOB, impersonateJob),
    takeEvery(IMPERSONATE_RECOVERY_POINTS, impersonateRecoveryPoints),
    takeEvery(
      FETCH_ENTITLEMENTS_PRODUCTS_REQUEST,
      handleFetchEntitlementsProducts
    ),
    takeLatest(TWO_FACTOR_VERIFY_PASSWORD, verifyPassword),
    takeLatest(TWO_FACTOR_CHECK, twoFactorCheck),
    takeLatest(TWO_FACTOR_GENERATE_CODES, twoFactorGenerateCodes),
    takeLatest(TWO_FACTOR_TOGGLE, userTwoFactorToggle),
    takeLatest(TWO_FACTOR_GET_SECRET, userTwoFactorGetSecret),
    takeLatest(GET_ORGANIZATION, getOrganizationInfo),
    takeEvery(FETCH_ALL_HELP_LINK_REQUEST, handleFetchAllHelpLinks),
    takeEvery(GET_TWO_FACTOR_SECRET, getTwoFactorSecret),
    takeEvery(TRIGGER_LOG_OUT, triggerLogout)
  ]);
}
