import { SHOW_DIALOG, HIDE_DIALOG } from "../actionTypes";

export const Dialog = {
  // when adding a new Dialog , add its value in initial state as well in src/state/reducers/ui/dialogs.js
  ADD_SOURCE_FROM_HYPERVISOR: "addSourceFromHypervisor",
  ADD_LINUX_BACKUP_SERVER: "addLinuxBackupServer",
  CONNECT_SHARED_FOLDER_DESTINATION: "connectSharedFolderDestination",
  DISCOVER_SOURCES_FROM_AD: "discoverSourcesFromAd",
  ADD_LINUX: "addLinux",
  DISCOVER_SOURCES_FROM_AD_EDIT: "DISCOVER_SOURCES_FROM_AD_EDIT",
  ADD_SOURCE_FROM_HYPERVISOR_CONTAINER_POLICY:
    "ADD_SOURCE_FROM_HYPERVISOR_CONTAINER_POLICY",
  ADD_SITE: "addsite",
  ADD_RECOVERY_POINT_SERVERS: "addRecoveryPointServers",
  EDIT_SITE: "modifySite",
  DELETE_SITE: "deleteSite",
  CANCEL_JOB: "cancelJob",
  SHOW_REG_SITE: "showRegBaseCode",
  ADD_REMOTE_CONSOLE: "addRemoteConsole",
  RESET_ADD_SOURCE_FROM_HYPER: "RESET_ADD_SOURCE_FROM_HYPER",
  IMPORT_DATA_STORE: "IMPORT_DATA_STORE",
  IMPORT_DATA_STORE_OVERRIDE: "IMPORT_DATA_STORE_OVERRIDE",
  IMPORT_CLOUD_DATA_STORE: "IMPORT_CLOUD_DATA_STORE",
  CREATE_CLOUD_ACCOUNT: "addCloudAccount",
  ADD_DATA_STORE: "addDataStore",
  ADD_CLOUD_DATA_STORE: "addCloudDataStore",
  CONNECT_DATA_STORE: "connectDataStore",
  DATA_STORE_PLAN_REQUIREMENTS: "dataStorePlanRequirements",
  INSTALL_UPGRADE_RPS: "INSTALL_UPGRADE_RPS",
  INSTALL_UPGRADE_SOURCE: "INSTALL_UPGRADE_SOURCE",
  UPGRADE_CLOUD_DIRECT: "UPGRADE_CLOUD_DIRECT",
  DELETE_RPS: "DELETE_RPS",
  RPS_JUMBSTART: "RPS_JUMBSTART",
  UPDATE_SHARED_FOLDER: "updateSharedFolder",
  SHARED_FOLDER_SERVER_SELECTION: "SHARED_FOLDER_SERVER_SELECTION",
  CONNECT_SHARED_FOLDER: "connectSharedFolder",
  DELETE_DATA_STORE: "DELETE_DATA_STORE",
  // DELETE_DATA_STORE_BULK: "DELETE_DATA_STORE_BULK",
  STOP_DATA_STORE: "STOP_DATA_STORE",
  STOP_DATA_STORE_BULK: "STOP_DATA_STORE_BULK",
  UPDATE_RPS: "UPDATE_RPS",
  BROWSE_REMOTE_CONSOLE: "BROWSE_REMOTE_CONSOLE",
  DATA_STORE_FOLDER_BROWSE: "DATA_STORE_FOLDER_BROWSE",
  MODIFY_DATA_STORE_WARNING: "MODIFY_DATA_STORE_WARNING",
  UNC_NFS_CONFIG: "UNC_NFS_CONFIG",
  CONNECT_UNC_NFS_PATH: "connectUncNfsPath",
  RESTORE_RECOVERY_POINT: "RESTORE_RECOVERY_POINT",
  RESTORE_ORACLE_DB: "RESTORE_ORACLE_DB",
  RESTORE_ORACLE_TABLES: "RESTORE_ORACLE_TABLES",
  UPDATE_SOURCE: "UPDATE_SOURCE",
  SOURCE_RUN_ASSURED_RECOVERY_TEST: "SOURCE_RUN_ASSURED_RECOVERY_TEST",
  SOURCE_RUN_ASSURED_SECURITY_SCAN: "SOURCE_RUN_ASSURED_SECURITY_SCAN",
  CLOUD_DIRECT_TRIAL: "CLOUD_DIRECT_TRIAL",
  MODIFY_CUSTOMER_NAME: "modifyCustomerName",
  SHARE_POLICY_ACTION: "SHARE_POLICY_ACTION",
  RUN_ASSURED_RECOVERY_TEST: "RUN_ASSURED_RECOVERY_TEST",
  RUN_ASSURED_SECURITY_SCAN: "RUN_ASSURED_SECURITY_SCAN",
  MOUNT_RECOVERY_POINT: "MOUNT_RECOVERY_POINT",
  MOUNT_RECOVERY_POINT_LINUX: "MOUNT_RECOVERY_POINT_LINUX",
  MOUNT_SQL_DATABASES: "MOUNT_SQL_DATABASES",
  DISCOVER_FROM_AD: "DISCOVER_FROM_AD",
  DISCOVER_FROM_HYPERV: "DISCOVER_FROM_HYPERV",
  DISCOVER_FROM_VMWARE: "DISCOVER_FROM_VMWARE",
  DELETE_AD_SOURCE: "DELETE_AD_SOURCE",
  DISMOUNT_VOLUME_CONFIRM: "DISMOUNT_VOLUME_CONFIRM",
  DISMOUNT_DATABASE_CONFIRM: "DISMOUNT_DATABASE_CONFIRM",
  DISMOUNT_RPS_CONFIRM: "DISMOUNT_RPS_CONFIRM",
  UPDATE_HYPERVISORS: "UPDATE_HYPERVISORS",
  DELETE_SOURCE_DISCOVERY: "DELETE_SOURCE_DISCOVERY",
  ADD_WINDOWS_SOURCES: "ADD_WINDOWS_SOURCES",
  UPDATE_PROXY: "UPDATE_PROXY",
  AR_ADD_PROXY: "AR_ADD_PROXY",
  ADD_RPA: "ADD_RPA",
  CREATE_STANBY_VM_RECOVERY_POINT_ACCESS_MODAL:
    "CREATE_STANBY_VM_RECOVERY_POINT_ACCESS_MODAL",
  IVM_CONFIRM_DIALOG: "IVM_CONFIRM_DIALOG",
  IVM_ERROR_DIALOG: "IVM_ERROR_DIALOG",
  IVM_BOOT_DIALOG: "IVM_BOOT_DIALOG",
  CREATE_STANDBY_VM_BOOT_DIALOG: "CREATE_STANDBY_VM_BOOT_DIALOG",
  ADD_VMWARE: "ADD_VMWARE",
  ADD_HYPERV: "ADD_HYPERV",
  IVM_WIZARD: "IVM_WIZARD",
  CREATE_STANDBY_VM_WIZARD: "CREATE_STANDBY_VM_WIZARD",
  CANCEL_CREATE_STANDBY_VM_CONFIRMATION_MODAL:
    "CANCEL_CREATE_STANDBY_VM_CONFIRMATION_MODAL",
  ASSURED_SECURITY_SCAN_DETAILS_MODAL: "ASSURED_SECURITY_SCAN_DETAILS_MODAL",
  CREATE_STANDBY_VM_CONNECT_MODAL: "CREATE_STANDBY_VM_CONNECT_MODAL",
  AR_ADD_LINUX_PROXY: "AR_ADD_LINUX_PROXY",
  AR_ADD_LINUX_POLICY_PROXY: "AR_ADD_LINUX_POLICY_PROXY",
  ADD_DESTINATION_HOST: "ADD_DESTINATION_HOST",
  ADD_DESTINATION_HOST_LINUX: "ADD_DESTINATION_HOST_LINUX",
  RECOVER_VOLUME_CONFIRM: "RECOVER_VOLUME_CONFIRM",
  RECOVER_VOLUME_FULL_BMR: "RECOVER_VOLUME_FULL_BMR",
  PIT_ERROR: "PIT_ERROR",
  ADD_STORAGE_ARRAY: "ADD_STORAGE_ARRAY",
  MODIFY_STORAGE_ARRAY: "MODIFY_STORAGE_ARRAY",
  IVM_NW_ADAPTERS: "IVM_NW_ADAPTERS",
  IVM_DNS_SETTINGS: "IVM_DNS_SETTINGS",
  IVM_UPDATE_DNS: "IVM_UPDATE_DNS",
  CUSTOMIZE_DNS_UPDATE: "CUSTOMIZE_DNS_UPDATE",
  STANDBY_VM_WIZARD: "STANDBY_VM_WIZARD",
  VIRTAUL_STANDBY_VM_RESUME_WIZARD: "VIRTAUL_STANDBY_VM_RESUME_WIZARD",
  INSTANT_VMS_RR: "INSTANT_VMS_RR",
  VALIDATE_VM_DIALOG: "VALIDATE_VM_DIALOG",
  VALIDATE_LINUX_VM_DIALOG: "VALIDATE_LINUX_VM_DIALOG",
  VERIFY_PASSWORD_DIALOG: "VERIFY_PASSWORD_DIALOG",
  STANDBY_VM_NETWORK_CONFIGURATION: "STANDBY_VM_NETWORK_CONFIGURATION",
  SOURCE_VM_VALIDATE_DIALOG: "SOURCE_VM_VALIDATE_DIALOG",
  FORCE_DELETE_DATA_STORE: "FORCE_DELETE_DATA_STORE",
  ADD_SOURCE_FROM_HYPERVISOR_POLICY: "ADD_SOURCE_FROM_HYPERVISOR_POLICY",
  TWO_FACTOR_CONFIG: "TWO_FACTOR_CONFIG",
  CONFIRMATION_DIALOG: "CONFIRMATION_DIALOG",
  STANDBY_VM_SHUTDOWN_CONFIRMATION: "STANDBY_VM_SHUTDOWN_CONFIRMATION",
  CANCEL_RECOVERY: "CANCEL_RECOVERY",
  SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL:
    "SHOW_SQL_ADDITIONAL_DATABASE_OPTIONS_MODAL",
  RECOVERY_FREE_SPACE_WARNING: "RECOVERY_FREE_SPACE_WARNING",
  CANCEL_IVM: "CANCEL_IVM",
  CANCEL_CREATE_STANDBY_VM: "CANCEL_CREATE_STANDBY_VM",
  SOURCE_CONFIGURE: "SOURCE_CONFIGURE",
  WARNING_SOURCE_CONFIG: "WARNING_SOURCE_CONFIG",
  ADD_HYPERVISOR: "ADD_HYPERVISOR",
  CANCEL_RPS_JUMP_START: "CANCEL_RPS_JUMP_START",
  PURGE_DATA_STORE: "PURGE_DATA_STORE",
  PURGE_DATA_STORE_BULK: "PURGE_DATA_STORE_BULK",
  RP_MERGE: "RP_MERGE",
  COLLECT_DIAGNOSTIC_DATA: "collect_diagnostic_data",
  CONNECT_TO_WIN: "connect_to_win",
  MERGE_NOW_CONFIRMATION_MODAL: "MERGE_NOW_CONFIRMATION_MODAL",
  ADD_ORACLE_SOURCE_MODAL: "ADD_ORACLE_SOURCE_MODAL",
  ADD_ORACLE_DB: "ADD_ORACLE_DB",
  ADD_ORACLE_DB_TO_LIST: "ADD_ORACLE_DB_TO_LIST",
  CONFIRM_ORACLE_RECOVERY: "CONFIRM_ORACLE_RECOVERY",
  NUTANIX_SNAPSHOT_WARNING: "NUTANIX_SNAPSHOT_WARNING",
  SET_BACKUP_PASSWORDS_MODAL: "SET_BACKUP_PASSWORDS_MODAL",
  ADD_BACKUP_PASSWORD_MODAL: "ADD_BACKUP_PASSWORD_MODAL",
  EDIT_BACKUP_PASSWORD_MODAL: "EDIT_BACKUP_PASSWORD_MODAL",
  BACKUP_PASSWORD_CONFIRMATION_MODAL: "BACKUP_PASSWORD_CONFIRMATION_MODAL",
  ADD_VIRTUAL_STANDBY_MONITOR_SERVER_DIALOG:
    "ADD_VIRTUAL_STANDBY_MONITOR_SERVER_DIALOG",
  FORCE_DELETE_SOURCE: "FORCE_DELETE_SOURCE",
  FORCE_DELETE_RPS: "FORCE_DELETE_RPS",
  ORACLE_PIT_ERROR: "ORACLE_PIT_ERROR",
  TERMINATE_STANDBY_VM: "TERMINATE_STANDBY_VM",
  REPLICATION: "REPLICATION",
  IMPORT_CLOUD_DS_STEP_ONE_WARNING: "IMPORT_CLOUD_DS_STEP_ONE_WARNING"
};
// when adding a new Dialog , add its value in initial state as well in src/state/reducers/ui/dialogs.js

export const showDialog = name => ({
  type: SHOW_DIALOG,
  name
});

export const hideDialog = name => ({
  type: HIDE_DIALOG,
  name
});
