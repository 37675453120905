import _ from "lodash";
import { select, all, call, put, takeEvery } from "redux-saga/effects";

import { getData } from "state/api/apiMethods";
import { getLogin } from "state/selectors";

import localeData from "assets/i18n/locales/data.json"; // i18n translated strings
import * as ccToast from "state/actions/ccToast";
import { doNotInitializeGraph } from "state/actions/actions";
import * as ccGraphConstants from "state/constants/ccGraph";
import * as ccGraphActions from "state/actions/ccGraph";
import * as ccGraphSelectors from "state/selectors/ccGraph";
import * as analyzeSelectors from "state/selectors/AnalyzeSelector";
import * as appConstants from "utils/appConstants";
import * as CommonSelectors from "state/selectors/CommonSelectors";
import { makeSecondaryNavigationState } from "state/selectors/HomeSelectors";
import { getChartParameters } from "components/analyze/reports/charts/Dashboard.js";
import { insertSelectedSavedSearchIntoAdvancedSearch } from "utils/SpogDataGridUtil";

/* Returns true if an advanced search is being performed */
export function* isAdvancedSearch() {
  const stateReducer = yield select(getReducer);
  if (
    stateReducer.selectedSavedSearchId === "" &&
    (stateReducer.searchOption || stateReducer.selectedSavedSearch)
  ) {
    return true;
  }
  return false;
}

const DASHBOARD_RECOVERY_POINT_WIGETS =
  ccGraphConstants.DASHBOARD_RECOVERY_POINT_WIGETS;
const REPORT_RECOVERY_POINT_WIGETS =
  ccGraphConstants.REPORT_RECOVERY_POINT_WIGETS;

export function* initializeFetchData(action, updatedFilters, ccGraphId) {
  try {
    if (updatedFilters) {
      yield put(
        ccGraphActions.fetchData(
          action.ccGraphId,
          updatedFilters,
          action.additionalParams,
          true
        )
      );
    } else {
      yield put(ccGraphActions.fetchData(ccGraphId, null));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* analyzePageGraphGenerateFilter(
  navigation,
  updatedFilters,
  selectedFilterOptions,
  ccGraphId
) {
  try {
    let updatedFiltersOrig = { ...updatedFilters };
    const savedSearchSelected = yield select(
      CommonSelectors.makeSelectedSavedSearchState()
    );
    const selectedSavedSearchfiltersEmpty =
      savedSearchSelected && !selectedFilterOptions;
    const noUpdatedFilters = !updatedFilters || !updatedFilters.filter_type;
    let analyzePageNoSelectedSavedSearchId = true;
    //handling filters for analyze pages with graphs and saved search.
    if (
      appConstants.ANALYZE_PAGES_WITH_GRAPHS.indexOf(navigation) > -1 &&
      (noUpdatedFilters ||
        selectedSavedSearchfiltersEmpty ||
        savedSearchSelected)
    ) {
      const advancedSearchSelected = yield select(
        CommonSelectors.makeSelectedSavedSearchIdState()
      );
      analyzePageNoSelectedSavedSearchId = !advancedSearchSelected;
      if (advancedSearchSelected) {
        const currentSearchOptions = yield select(
          CommonSelectors.makeCurrentSearchOptionsState()
        );
        let advancedSearchOptions = yield select(
          analyzeSelectors.makeOptionsState()
        );
        if (savedSearchSelected && !currentSearchOptions) {
          advancedSearchOptions = yield call(
            insertSelectedSavedSearchIntoAdvancedSearch,
            savedSearchSelected,
            advancedSearchOptions
          );
        }

        let criteria = {
          advancedSearchOptions: advancedSearchOptions
        };
        const searchTextMapping = appConstants.SEARCH_TEXT_MAPPING[navigation];
        if (savedSearchSelected && savedSearchSelected[searchTextMapping]) {
          criteria.searchText = savedSearchSelected[searchTextMapping];
        } else if (updatedFilters && updatedFilters[searchTextMapping]) {
          criteria.searchText = updatedFilters[searchTextMapping];
        }
        updatedFilters = yield call(
          getChartParameters,
          criteria,
          advancedSearchOptions,
          "",
          appConstants.SEARCH_TEXT_MAPPING[navigation]
        );
        if (appConstants.GRAPH_CHANGING_TYPE.indexOf(ccGraphId) > -1) {
          if (
            (updatedFilters.range === "" && updatedFiltersOrig.range !== "") ||
            (updatedFilters.range === "" && updatedFiltersOrig.range === "")
          ) {
            updatedFilters = updatedFiltersOrig;
          }
        }
      }

      return {
        updatedFilters: updatedFilters,
        analyzePageNoSelectedSavedSearchId: analyzePageNoSelectedSavedSearchId
      };
    }
  } catch (e) {
    window.console.log(e);
  }
}

const getReducer = state => state.reducer;

export function* initialize(action) {
  const { ccGraphId } = action;
  let stateReducer = yield select(getReducer);
  try {
    const navigation = yield select(makeSecondaryNavigationState());
    if (
      (!stateReducer.doNotInitializeGraph ||
        appConstants.ANALYZE_PAGES_WITH_GRAPHS.indexOf(navigation) === -1) &&
      appConstants.PROTECT_PAGES_WITH_GRAPHS.indexOf(navigation) === -1
    ) {
      const { initialFilters } = yield select(
        ccGraphSelectors.getConfig(ccGraphId)
      );
      let updatedFilters = yield select(ccGraphSelectors.getFilters(ccGraphId));
      const monitorTopSourcesGraph =
        !navigation &&
        ccGraphId === appConstants.CCGRAPH_IDS.DASHBOARD_TOP_SOURCES;
      const recoveryPointGraph =
        !navigation &&
        DASHBOARD_RECOVERY_POINT_WIGETS.includes(action.ccGraphId);

      let analyzePageGraphFilters = yield call(
        analyzePageGraphGenerateFilter,
        navigation,
        updatedFilters,
        stateReducer.selectedFilterOptions,
        ccGraphId
      );

      updatedFilters =
        analyzePageGraphFilters && analyzePageGraphFilters.updatedFilters
          ? analyzePageGraphFilters.updatedFilters
          : updatedFilters;

      let analyzePageNoSelectedSavedSearchId =
        analyzePageGraphFilters &&
        "analyzePageNoSelectedSavedSearchId" in analyzePageGraphFilters
          ? analyzePageGraphFilters.analyzePageNoSelectedSavedSearchId
          : true;

      let advancedSearch = yield call(isAdvancedSearch);

      if (
        monitorTopSourcesGraph ||
        recoveryPointGraph ||
        (analyzePageNoSelectedSavedSearchId && !advancedSearch)
      ) {
        yield put(ccGraphActions.resetFilters(ccGraphId, true));
      } else if (updatedFilters) {
        if (ccGraphId in appConstants.GRAPH_DEFAULT_FILTER) {
          yield put(
            ccGraphActions.updateFilters(
              ccGraphId,
              {
                ...updatedFilters,
                filter_type: appConstants.GRAPH_DEFAULT_FILTER[ccGraphId]
              },
              false
            )
          );
        } else {
          if (appConstants.PROTECT_PAGES_WITH_GRAPHS.indexOf(navigation) > -1) {
            yield put(doNotInitializeGraph(true));
          } else {
            yield put(
              ccGraphActions.updateFilters(ccGraphId, updatedFilters, false)
            );
          }
        }
      } else if (initialFilters) {
        yield put(
          ccGraphActions.updateFilters(ccGraphId, initialFilters, false)
        );
      } else {
        stateReducer = yield select(getReducer);
        if (
          appConstants.PROTECT_PAGES_WITH_GRAPHS.indexOf(navigation) > -1 &&
          !stateReducer.doNotInitializeGraph
        ) {
          yield put(doNotInitializeGraph(true));
        } else {
          yield put(ccGraphActions.resetFilters(ccGraphId, false));
        }
      }
      // const savedSearchSelected = yield select(
      //   CommonSelectors.makeSelectedSavedSearchState()
      // );

      const initFetchData =
        !monitorTopSourcesGraph &&
        !recoveryPointGraph &&
        (!analyzePageNoSelectedSavedSearchId || advancedSearch) &&
        appConstants.PROTECT_PAGES_WITH_GRAPHS.indexOf(navigation) === -1;

      if (initFetchData) {
        yield call(initializeFetchData, action, updatedFilters, ccGraphId);
      }
    }
    yield put(doNotInitializeGraph(false));
  } catch (e) {
    window.console.log(e);
  }
}

// filters
export function* updateFilters(action) {
  if (action.refreshGrid) {
    yield put(
      ccGraphActions.fetchData(
        action.ccGraphId,
        action.values,
        action.additionalParams
      )
    );
  }
}

export function* resetFilters(action) {
  const { ccGraphId } = action;
  const navigation = yield select(makeSecondaryNavigationState());
  const stateLogin = yield select(getLogin);
  const { products } = stateLogin;
  const defaultProduct =
    products && products.clouddirect === true && products.udp === false
      ? "cloud_direct"
      : "udp";
  const monitorTopSourcesGraph =
    !navigation &&
    action.ccGraphId === appConstants.CCGRAPH_IDS.DASHBOARD_TOP_SOURCES;
  const recoveryPointGraph =
    !navigation && DASHBOARD_RECOVERY_POINT_WIGETS.includes(action.ccGraphId);
  const ReportsRecoveryPointGraph = REPORT_RECOVERY_POINT_WIGETS.includes(
    action.ccGraphId
  );
  if (action.refreshGrid) {
    if (recoveryPointGraph || ReportsRecoveryPointGraph) {
      yield put(
        ccGraphActions.updateFilters(
          ccGraphId,
          { filter_type: defaultProduct },
          true
        )
      );
    } else if (
      monitorTopSourcesGraph ||
      ccGraphId in appConstants.GRAPH_DEFAULT_FILTER
    ) {
      yield put(
        ccGraphActions.updateFilters(
          ccGraphId,
          { filter_type: appConstants.GRAPH_DEFAULT_FILTER[ccGraphId] },
          true
        )
      );
    } else {
      yield put(ccGraphActions.fetchData(action.ccGraphId, null));
    }
  }
}

// returns params
const processFilters = (microservice, filters) => {
  const params = [];

  Object.keys(filters).forEach(key => {
    const val = filters[key];

    // if value is null, ignore
    if (val == null || val === "") {
      return;
    } else if (Array.isArray(val)) {
      if (microservice === appConstants.MICROSERVICES.CLOUD_DIRECT) {
        val.forEach(v => params.push(`${key}[]=${v}`));
      } else if (microservice === appConstants.MICROSERVICES.CLOUD_CONSOLE) {
        params.push(`${key}=${val.join("%7C")}`);
      }
    } else {
      params.push(`${key}=${val}`);
    }
  });

  return params;
};

// fetch
export function* fetchData(action) {
  try {
    const stateLogin = yield select(getLogin);
    const { cdtoken, token, organization_id } = stateLogin;
    let err = null;

    const { ccGraphId } = action;

    //TODO need to verify the scenario why ccGraphId is null or empty and fix it from root
    if (ccGraphId === "" || ccGraphId === undefined) {
      return;
    }
    const params = [];

    const { microservice, api, additionalParameters } = yield select(
      ccGraphSelectors.getConfig(ccGraphId)
    );

    if (api === undefined || microservice === undefined) {
      return;
    }

    let filters = yield select(ccGraphSelectors.getFilters(ccGraphId));
    if (
      action.filteredData &&
      filters &&
      action.filteredData.filter_type !== filters.filter_type &&
      (ccGraphId === appConstants.CCGRAPH_IDS.DASHBOARD_TOP_SOURCES ||
        ccGraphId === appConstants.CCGRAPH_IDS.DASHBOARD_TOP_SOURCES)
    ) {
      filters = action.filteredData;
    }
    if (
      action.filteredData &&
      filters &&
      action.filteredData.product_type !== filters.filter_type &&
      DASHBOARD_RECOVERY_POINT_WIGETS.includes(ccGraphId)
    ) {
      filters = action.filteredData;
    }

    if (action.values) {
      filters = action.values;
    }

    if (
      ccGraphId ===
        appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSFER_TOP_SOURCES ||
      ccGraphId ===
        appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSREPORTS_DATA_TRANSFER_SUMMARY
    ) {
      filters = action.filteredData;
    }

    const reportDetails = yield select(analyzeSelectors.currentReportTypeAndId);
    //added product id when the graph type is data transfer.
    if (
      ccGraphId ===
        appConstants.CCGRAPH_IDS.DATA_TRANSFER_CLOUD_DIRECT_VOLUME ||
      ccGraphId === appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY
    ) {
      params.push(`product_type=${appConstants.PRODUCT_TYPE.CLOUD_DIRECT}`);
    }
    if (
      DASHBOARD_RECOVERY_POINT_WIGETS.includes(ccGraphId) ||
      REPORT_RECOVERY_POINT_WIGETS.includes(ccGraphId)
    ) {
      if (filters && filters["filter_type"]) {
        filters.product_type = filters["filter_type"];
        let { filter_type, ...filtersTemp } = filters;
        filters = filtersTemp;
      }
    }
    //reportDetails are for report graph
    if (reportDetails && reportDetails.report_id) {
      params.push(`report_id=${reportDetails.report_id}`);
    }
    // add organization_id assuming it's not overwritten
    _.get(additionalParameters, "organization_id", false) ||
      _.get(filters, "organization_id", false) ||
      (ccGraphId !== "REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES" &&
        params.push(`organization_id=${organization_id}`));

    // remove the additionalParameters that are overwritten
    const appliedAdditionalParameters =
      additionalParameters &&
      Object.keys(additionalParameters)
        .filter(key => !filters || !filters.hasOwnProperty(key))
        .reduce(
          (acc, key) => ({ ...acc, [key]: additionalParameters[key] }),
          {}
        );

    // construct params
    appliedAdditionalParameters &&
      params.push(...processFilters(microservice, appliedAdditionalParameters));
    if (
      action.ccGraphId ===
        appConstants.CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE &&
      action.initial
    ) {
      filters = action.additionalParameters;
    }
    filters && params.push(...processFilters(microservice, filters));

    // TODO: refactor this to
    // const { response, data, errors } = yield call(
    //   fetchGet,
    //   microserice,
    //   api,
    //   token,
    //   params.join("&")
    // );
    let url =
      ccGraphId !== "REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES"
        ? `${appConstants.MICROSERVICES_TO_URL[microservice]}${api}`
        : `${appConstants.MICROSERVICES_TO_URL[microservice]}${api}/${organization_id}`;
    let response = yield call(
      getData,
      url,
      params.join("&"),
      url.includes("cloudconsole") || action.ccGraphId.includes("restapi")
        ? cdtoken
        : token
    );

    // TODO: Refactor this stuff in a fetchGet function
    let data = null;
    let errors = [];

    if (microservice === appConstants.MICROSERVICES.CLOUD_DIRECT) {
      if (response.status === "SUCCESS") {
        data = response.data;
      } else {
        err = response.error;
        errors = [err];
        if (localeData.en.hasOwnProperty(`toast-errors.cd.${response.code}`)) {
          yield put(
            ccToast.addErrorNotification(
              response.code,
              undefined,
              "toast-errors.cd"
            )
          );
        } else {
          yield put(
            ccToast.addErrorNotification("apiErrorResponse", {
              message: err
            })
          );
        }
      }
    } else if (microservice === appConstants.MICROSERVICES.CLOUD_CONSOLE) {
      data = response.data;
      errors = response.errors;

      if (Array.isArray(response.errors) && response.errors.length > 0) {
        for (let i = 0; i < response.errors.length; ++i) {
          err = response.errors[i];
          if (localeData.en.hasOwnProperty(`toast-errors.cc.${err.code}`)) {
            yield put(
              ccToast.addErrorNotification(
                err.code,
                undefined,
                "toast-errors.cc"
              )
            );
          } else {
            yield put(
              ccToast.addErrorNotification("apiErrorResponse", {
                message: err.message
              })
            );
          }
        }
      }
    }
    // END TODO REFACTOR

    if (errors.length === 0) {
      // Condition If GraphId is of type "DATA_TRANSFER_CLOUD_DIRECT_VOLUME" or "REPORTS_DATA_TRANSFER_SUMMARY" then remove "data_processed" property from response.
      // Removing "data_processed" property will remove (data processed trend line) from Cloud Direct Data Transfer Widget  and Data transfer report Widget.
      if (
        ccGraphId ===
          appConstants.CCGRAPH_IDS.DATA_TRANSFER_CLOUD_DIRECT_VOLUME ||
        ccGraphId === appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY
      ) {
        if (
          typeof data === "object" &&
          data !== null &&
          data.hasOwnProperty("data_processed")
        ) {
          delete data["data_processed"];
        }
      }
      if (ccGraphId === appConstants.CCGRAPH_IDS.STC_CHANGE_RATE) {
        let metricData = response.data.map(d => ({
          ts: d.timestamp,
          date: d.time,
          value: d.dataChangeRate
        }));
        metricData = _.sortBy(metricData, ["ts"]);
        _.reverse(metricData);
        response.data = {
          change_rate: {
            id: "change_rate",
            latest: metricData[0],
            metric: metricData.splice(0, 30),
            name: "change_rate"
          },
          resolution: "day"
        };
      }
      yield put(
        ccGraphActions.fetchDataSuccess(ccGraphId, response, response.data)
      );
    } else {
      yield put(ccGraphActions.fetchDataFailure(ccGraphId, response, errors));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export default function* ccGraph() {
  yield all([
    takeEvery(ccGraphConstants.CCGRAPH_INITIALIZE, initialize),
    takeEvery(ccGraphConstants.CCGRAPH_UPDATE_FILTERS_REQUEST, updateFilters),
    takeEvery(ccGraphConstants.CCGRAPH_RESET_FILTERS_REQUEST, resetFilters),
    takeEvery(ccGraphConstants.CCGRAPH_FETCH_DATA_REQUEST, fetchData)
  ]);
}
