import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { loadState, saveState } from "./localstorage";

// Import the index sagas
import rootSaga from "./sagas/index";

//Import Login Saga
//import loginSaga from './redux/sagas/loginSaga';

//Import the index reducers
import rootReducer from "./reducers/index";

//Persist state to local Storage, to facilitate browser refresh.
const persistedState = loadState();

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

// Redux DevTools
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//TODO:
const composeEnhancers =
  process.env.REACT_APP_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(
  // Middleware you want to use in development:
  applyMiddleware(sagaMiddleware)
  // other store enhancers if any
);

export default (() => {
  // Creates the Redux reducer with the redux-saga middleware, and redux dev tools support
  const store = createStore(
    rootReducer,
    persistedState,
    // Redux DevTools  and Saga middleware
    enhancer
  );
  //On state change update/load state to localStorage
  window.stopSavingState = store.subscribe(() => {
    saveState(store.getState());
  });
  // Create hook for async sagas
  store.async = {};
  // Begin our Index Saga
  store.runSaga = sagaMiddleware.run(rootSaga);

  return store;
})();

//set new reducers (on the async property) and the substitutes the main one with it
export function registerReducer(store, name, reducer) {
  if (reducer !== undefined) {
    store.async[name] = reducer;
    store.replaceReducer(createReducer(store.async));
  }
}

//Define initial reducer
//setup the bare setup of our store/state
function createReducer(reducers) {
  return combineReducers({
    reducer: (state = null) => state,
    ...reducers
  });
}
