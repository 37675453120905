import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Holdes constants definitions for Source Group
 */
export const Attributes = {
  SOURCE_GROUP_PROP_ID: "group_id",
  SOURCE_GROUP_PROP_NAME: "group_name",
  SOURCE_GROUP_PROP_COUNT: "source_count"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "protect.sources.group.".concat(identifier);
};

/**
 * Column definition for the Destination table
 */
export const COLUMN_DEFS = [
  {
    accessor: Attributes.SOURCE_GROUP_PROP_NAME,
    displayIdentifier: getDisplayIdentifier(Attributes.SOURCE_GROUP_PROP_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: Attributes.SOURCE_GROUP_PROP_COUNT,
    displayIdentifier: getDisplayIdentifier(Attributes.SOURCE_GROUP_PROP_COUNT),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  }
];
