import React from "react";
import PropTypes from "prop-types";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import { organizationNameHandler } from "utils/SpogDataGridUtil";
import { ID_PREFIX } from "configs/IdConfig";
import Paper from "@mui/material/Paper";
import { removeCCDataGridInstance } from "state/actions/actions";
import { useDispatch } from "react-redux";
/**
 * Helper component that renders breadcrumbs for navigation.
 */
const SpogBreadcrumb = ({
  updateStateOnClick,
  organizationInfo,
  items,
  isCsr,
  isImpersonationView,
  handleHomeMSPHomeClick
}) => {
  const dispatch = useDispatch();
  const updateStateOnClickHandler = (
    secondaryKey,
    secondaryNavigation,
    itemURL
  ) => {
    if (
      isImpersonationView &&
      ["/protect/customer-accounts/all", "/csr"].includes(itemURL)
    ) {
      handleHomeMSPHomeClick && handleHomeMSPHomeClick();
      dispatch(removeCCDataGridInstance("all"));
    }
    updateStateOnClick(secondaryKey, secondaryNavigation);
  };

  /**
   * Handle and return breadcrum name based on key
   * @param {String} breadcrumbItemKey
   * @return {String}
   */
  const handleBreadcrumName = breadcrumbItemKey => {
    return breadcrumbItemKey === "protect"
      ? "Protect"
      : breadcrumbItemKey === "Monitor"
      ? organizationNameHandler(organizationInfo, breadcrumbItemKey)
      : breadcrumbItemKey;
  };

  /**
   * Renders breadcrumbs by iterating over the item list.
   */
  const renderBreadcrumbItem = items => {
    return items.map((item, index) => {
      let keyIndex = `${item.key}_${index}`;
      item.key = handleBreadcrumName(item.key);
      return (
        <div
          key={keyIndex}
          onClick={() =>
            !(index + 1 === items.length) &&
            updateStateOnClickHandler(
              item.navigationKey,
              item.navigation,
              item.url.toLowerCase()
            )
          }
          id={keyIndex}
        >
          {index + 1 === items.length || item.notClickable ? (
            <Typography
              variant={"font_18_reg"}
              color="textPrimary"
              style={{ padding: 6, fontSize: "16px" }}
            >
              <FormattedBrandMessage id={item.key} defaultMessage={item.key} />
            </Typography>
          ) : (
            <NavLink
              to={item.url ? item.url.toLowerCase() : ""}
              id={`${ID_PREFIX}ZKB7Do_${item.url &&
                item.url
                  .toLowerCase()
                  .substring(1)
                  .split("/")
                  .join("-")}`}
            >
              <Typography
                color="textSecondary"
                style={{ padding: 6, fontSize: "16px" }}
              >
                <FormattedBrandMessage
                  id={
                    (item.key = item.key === "protect" ? "Protect" : item.key)
                  }
                  defaultMessage={
                    (item.key = item.key === "protect" ? "Protect" : item.key)
                  }
                />
              </Typography>
            </NavLink>
          )}
        </div>
      );
    });
  };

  return !isCsr ? (
    <Paper elevation={0}>
      <Breadcrumbs
        className="breadcrumb-nav"
        maxItems={5}
        style={
          !isCsr
            ? {
                paddingLeft: 10,
                paddingTop: 4,
                paddingBottom: 4
              }
            : {}
        }
      >
        {renderBreadcrumbItem(items)}
      </Breadcrumbs>
    </Paper>
  ) : (
    <Breadcrumbs
      maxItems={5}
      style={
        !isCsr
          ? {
              paddingLeft: 10,
              paddingTop: 4,
              paddingBottom: 4
            }
          : {}
      }
    >
      {renderBreadcrumbItem(items)}
    </Breadcrumbs>
  );
};

SpogBreadcrumb.defaultProps = {
  items: []
};

SpogBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateStateOnClick: PropTypes.func.isRequired
};

export default SpogBreadcrumb;
