import { combineReducers } from "redux";
import * as actionType from "../../actions/actionTypes";

function letters(state = [], { type, data }) {
  switch (type) {
    case actionType.CLEAR_AVAILABLE_DRIVE_LETTER:
      return [];
    case actionType.UPDATE_AVAILABLE_DRIVE_LETTER:
      return data;
    default:
      return state;
  }
}

export default combineReducers({
  letters
});
