import * as _protectApi from "./protectApis";
import * as _destinationApis from "./destinationApis";
import * as _configureApi from "./configureApis";
// import { browserHistory } from 'react-router'
import * as _analyzeApi from "./analyzeApis";
import * as _monitorApi from "./monitorApis";
import * as _loginApis from "./loginApis";
import * as _commonApi from "./commonApis";
import * as _csrApi from "./csrApis";
import * as _downloadsApi from "./downloadsApi";

export const protectApi = _protectApi;
export const configureApi = _configureApi;
export const analyzeApi = _analyzeApi;
export const monitorApi = _monitorApi;
export const loginApis = _loginApis;
export const commonApi = _commonApi;
export const csrApi = _csrApi;
export const downloadsApi = _downloadsApi;
export const destinationApis = _destinationApis;
