import { all, select, takeLatest, call, put } from "redux-saga/effects";
import { getFormValues, change } from "redux-form";
import get from "lodash/get";
import { getLogin, getOrgId } from "state/selectors";
import {
  HTTP_STATUS,
  API_RESPONSE_CONTEXT,
  PRODUCT_TYPE,
  CONNECT_STATUS,
  CCDATAGRID_ID
} from "utils/appConstants";
import {
  CREATE_VMWARE_FOR_VSB,
  GET_VSB_NUTANIX_CONFIGURATION,
  LOAD_VMWARE_NUTNAIX_MONITOR,
  GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_VSB,
  GET_REGIONS_FOR_CLOUD_ACCOUNT_VSB,
  GET_ALL_VSB_HYPERVISORS_FOR_CREATE_STANDBY_VM,
  HYPERV_SHARED_FOLDERS_BROWSE,
  FETCH_CLOUD_CONFIGURATIONS_VSB,
  CREATE_NUTANIX_FOR_VSB,
  EC2_VALIDATE_CLOUD_PROXY,
  GET_VMWARE_CONFIGURATION_VSB,
  TASK_TYPES,
  GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB,
  GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB
} from "state/constants/createStandbyVM";

import maxBy from "lodash/maxBy";

import {
  addSuccessNotification,
  addErrorNotification
} from "state/actions/ccToast";
import {
  fetchDataSuccess,
  resetCCDataGridLoader,
  startCCDataGridLoader
} from "state/actions/ccDataGrid";
import {
  CREATE_HYPERV_FOR_VM,
  VSB_CONNECT_VMWARE_MONITOR,
  VSB_CONNECT_HYPERV
} from "../../actions/actionTypes";
import {
  Loader,
  setLoading,
  resetLoading,
  hideDialog,
  Dialog
} from "../../actions/actions";
import { protectApi, destinationApis } from "../../api";
// eslint-disable-next-line import/no-cycle
import { makeToastData } from "../sagautils";

import {
  createHyperVForIVMSuccess,
  getHypervisorsListByType,
  getVmwareConfigurationsSuccess,
  ec2CloudProxyValidationResponse,
  createStandbyVmwareMonitorConnectSuccess,
  connectModalStatus,
  createNutanixForIVMSuccess,
  setHypervFolderInitialPath,
  browseSharedFoldersForHyperv,
  storeCloudConfigurations,
  getVSBHypervisorsSuccess,
  getVSBCloudAccountListByTypeSuccess,
  getVSBHypervisorsFailed,
  getRegionsForVSBCloudAccountSuccess,
  clearCloudAccRegions,
  getResourceGroupForCloudAccountSuccessVSB,
  loadVsbMonitorSuccess,
  getNutanixConfigurationsSuccess,
  createVmwareForVSBSuccess,
  getComputeRegionsForGCPCloudAccountSuccessForVsb,
  getComputeZonesForGCPCloudAccountSuccessForVsb
} from "../../actions/createStandbyVM";

/**
 * fetch downdloads from api and store it in the
 * store
 */

export function* getAllVSBHypervisors() {
  try {
    const loginReducer = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const queryString = `organization_id=${orgId}`;

    const resp = yield call(
      protectApi.fetchAllVSBHypervisorList,
      queryString,
      loginReducer?.token
    );

    if (resp.status === HTTP_STATUS.OK) {
      yield put(getVSBHypervisorsSuccess(resp.data));
      const response = resp.data;
      const cloudData = [];
      // eslint-disable-next-line array-callback-return
      response.map(item => {
        if (
          item.target_type === "amazon_ec2" ||
          item.target_type === "amazon_ec2_china" ||
          item.target_type === "ms_azure"
        ) {
          cloudData.push({
            cloud_account_id: item.target_id,
            cloud_account_name: item.target_name,
            cloud_account_type: item.target_type
          });
        }
      });
      yield put(getVSBCloudAccountListByTypeSuccess(cloudData));
    } else if (resp.status >= 400) {
      yield put(getVSBHypervisorsFailed());
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRegionsForCloudAccount({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(clearCloudAccRegions());
    const resp = yield call(
      destinationApis.getRegionsForCloudAccount,
      loginReducer?.token,
      data
    );
    if (get(resp, "status", null) === HTTP_STATUS.OK) {
      yield put(getRegionsForVSBCloudAccountSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getResouceGroupForCloudAccount({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    // const orgId = yield select(getOrgId);
    const resp = yield call(
      destinationApis.getResouceGroupForCloudAccount,
      loginReducer?.token,
      data
    );
    if (get(resp, "status", null) === HTTP_STATUS.OK) {
      yield put(getResourceGroupForCloudAccountSuccessVSB(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* createHyperVForIVM({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
    if (data && loginReducer?.token) {
      const resp = yield call(
        protectApi.createHyperVForIVMv2,
        data,
        loginReducer?.token
      );
      if (resp.status === HTTP_STATUS.OK) {
        yield put(createHyperVForIVMSuccess(resp));
        yield put(getHypervisorsListByType("hyperv"));
        if (formName) {
          yield put(
            change(formName, "hyperv_cluster_id", resp.data.hypervisor_id)
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}
export function* vsbVmwareMonitorConnectSaga({ data, formName, namespace }) {
  try {
    const loginReducer = yield select(getLogin);
    const formValues = yield select(
      getFormValues("createStandbyVMConnectModal")
    );
    if (data && loginReducer?.token) {
      yield put(setLoading(Loader.SAVE_CREATE_STANDBY_VM_CONNECT_MODAL));
      const payload = {
        proxy_name: data.proxy_name,
        username: formValues.username,
        password: formValues.password,
        site_id: data.site_id,
        product_type: PRODUCT_TYPE.UDP,
        server_role_type: "vsb_monitor",
        server_port: formValues.port,
        server_protocol: formValues.protocol,
        checkExist: false
      };
      yield put(connectModalStatus(CONNECT_STATUS.CONNECTING));
      const resp = yield call(
        protectApi.createBackupProxy,
        payload,
        loginReducer?.token
      );
      if (resp.status === HTTP_STATUS.CREATED) {
        yield put(
          addSuccessNotification(
            makeToastData(API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS, "Nd9Jcv")
          )
        );
        yield put(
          change(
            formName,
            `${namespace}.${TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
            resp.data.proxy_id
          )
        );
        yield put(createStandbyVmwareMonitorConnectSuccess(resp.data));
        yield put(connectModalStatus(CONNECT_STATUS.CONNECTED));
        yield put(hideDialog(Dialog.CREATE_STANDBY_VM_CONNECT_MODAL));
      } else if (resp.status >= HTTP_STATUS.BAD_REQUEST) {
        yield put(createStandbyVmwareMonitorConnectSuccess(resp));
        yield put(connectModalStatus(CONNECT_STATUS.FAILED));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.SAVE_CREATE_STANDBY_VM_CONNECT_MODAL));
  }
}

export function* getVMwareConfiguration({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    if (data && loginReducer?.token) {
      const resp = yield call(
        protectApi.getVmwareConfigurationDetails,
        data,
        loginReducer?.token
      );
      if (resp.status === HTTP_STATUS.OK) {
        yield put(getVmwareConfigurationsSuccess(resp.data));
      } else {
        yield put(getVmwareConfigurationsSuccess(null));
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* policyVsbConnectHyperVSaga({ data, formName, namespace }) {
  try {
    const loginReducer = yield select(getLogin);
    if (data && loginReducer?.token) {
      const formValues = yield select(
        getFormValues("createStandbyVMConnectModal")
      );
      yield put(setLoading(Loader.SAVE_CREATE_STANDBY_VM_CONNECT_MODAL));
      yield put(connectModalStatus(CONNECT_STATUS.CONNECTING));
      const payload = {
        hypervisor_id: data.hypervisor_id,
        node_name: data.node_name || undefined,
        port: formValues?.port,
        protocol: "https"
      };
      // Clearing monitor fields before api call
      yield put(
        change(
          formName,
          `${namespace}.${TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor`,
          undefined
        )
      );
      yield put(setLoading(Loader.POLICY_VSB_CONNECT_DIALOG));
      const resp = yield call(
        protectApi.postPolicyHyperVConnect,
        payload,
        loginReducer?.token
      );
      if (resp.status === HTTP_STATUS.OK) {
        yield put(
          addSuccessNotification(
            makeToastData(API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS, "ocPaSP")
          )
        );
        yield put(
          change(
            formName,
            `${namespace}.${TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
            resp.data.proxy_id
          )
        );
        yield put(setHypervFolderInitialPath(null));
        yield put(
          browseSharedFoldersForHyperv(
            {
              source_id: resp.data.proxy_id
            },
            namespace,
            formName
          )
        );
        yield put(createStandbyVmwareMonitorConnectSuccess(resp.data));
        yield put(connectModalStatus(CONNECT_STATUS.CONNECTED));

        yield put(hideDialog(Dialog.CREATE_STANDBY_VM_CONNECT_MODAL));
      } else {
        yield put(createStandbyVmwareMonitorConnectSuccess(resp));
        yield put(connectModalStatus(CONNECT_STATUS.FAILED));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.SAVE_CREATE_STANDBY_VM_CONNECT_MODAL));
  }
}

export function* createStandbyValidateCloudProxy({
  data,
  formName,
  namespace
}) {
  try {
    yield put(setLoading(Loader.SAVE_CREATE_STANDBY_VM_CONNECT_MODAL));
    const loginReducer = yield select(getLogin);
    if (data && loginReducer?.token) {
      yield put(connectModalStatus(CONNECT_STATUS.CONNECTING));

      const resp = yield call(
        protectApi.createBackupProxy,
        data,
        loginReducer?.token
      );
      if (resp.status === HTTP_STATUS.CREATED) {
        yield put(
          addSuccessNotification(
            makeToastData(API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS, "C29Wvl")
          )
        );
        yield put(
          change(
            formName,
            `${namespace}.${TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.id`,
            resp.data.proxy_id
          )
        );
        yield put(
          change(
            formName,
            `${namespace}.${TASK_TYPES.UDP_VIRTUAL_STANDBY}.virtualization_server.monitor.name`,
            data.proxy_name
          )
        );
        yield put(ec2CloudProxyValidationResponse(resp.data));
        yield put(connectModalStatus(CONNECT_STATUS.CONNECTED));
        yield put(hideDialog(Dialog.CREATE_STANDBY_VM_CONNECT_MODAL));
        yield put(hideDialog("vsbEc2MonitorConnect"));
      } else {
        yield put(ec2CloudProxyValidationResponse(resp));
        yield put(connectModalStatus(CONNECT_STATUS.FAILED));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.SAVE_CREATE_STANDBY_VM_CONNECT_MODAL));
  }
}

export function* createNutanixForIVM({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    if (data && loginReducer?.token) {
      yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
      yield put(
        startCCDataGridLoader(CCDATAGRID_ID.SELECT_CREATE_STANDBY_VM_NUTANIX)
      );
      const resp = yield call(
        protectApi.createNutanixForIVM,
        data,
        loginReducer?.token
      );
      if (resp.status === HTTP_STATUS.OK) {
        resp.data.hypervisor_id = data.hypervisor_id;

        yield put(createNutanixForIVMSuccess(resp));
        yield put(getHypervisorsListByType("nutanix"));
        const dataForGrid = resp.data.length
          ? resp.data.map(r => ({
              ...r,
              _rowId: r?.hypervisor_id
            }))
          : [resp?.data];
        const pagination = {
          curr_page: 1,
          total_page: 1,
          page_size: 25,
          has_prev: false,
          has_next: false,
          total_size: dataForGrid.length
        };
        yield put(
          fetchDataSuccess(
            CCDATAGRID_ID.SELECT_CREATE_STANDBY_VM_NUTANIX,
            resp,
            dataForGrid,
            pagination
          )
        );
        yield put(
          resetCCDataGridLoader(CCDATAGRID_ID.SELECT_CREATE_STANDBY_VM_NUTANIX)
        );
        if (formName) {
          yield put(
            change(formName, "nutanix_cluster_id", resp.data.hypervisor_id)
          );
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}
export function* browseSharedFoldersHyperv({ data, namespace, formName }) {
  try {
    const loginReducer = yield select(getLogin);

    const resp = yield call(
      protectApi.browseSharedFolders,
      data,
      loginReducer?.token
    );
    if (resp.status === HTTP_STATUS.OK) {
      const maxVal = maxBy(resp.data, "total_size");
      yield put(
        change(
          formName,
          `${namespace}.${TASK_TYPES.UDP_VIRTUAL_STANDBY}.storage_settings.vm_datastore_path`,
          `${maxVal.path}udpvm`
        )
      );
      yield put(setHypervFolderInitialPath(`${maxVal.path}udpvm`));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_FROM_HYPERVISOR_CONNECT_BTN));
  }
}

export function* fetchCloudAccountsConfSaga({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    if (data && loginReducer?.token) {
      const resp = yield call(
        destinationApis.fetchCloudAccountsConfigurations,
        loginReducer?.token,
        data.payload,
        data.cloudId
      );
      if (resp.status === HTTP_STATUS.OK) {
        yield put(storeCloudConfigurations(resp.data));
      } else {
        yield put(
          addErrorNotification(
            makeToastData(resp.errors[0].message, `sfeswz_${resp?.status}`)
          )
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* loadVsbMonitors({ siteId }) {
  try {
    const loginReducer = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      protectApi.getBackupProxy,
      `organization_id=${orgId}&server_role_types=ar_windows_proxy%7Cwindows_backup_server%7Cvsb_convertor%7Cvsb_monitor&site_id=${siteId}`,
      loginReducer.token
    );
    if (resp.status === HTTP_STATUS.OK) {
      yield put(loadVsbMonitorSuccess(resp));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getNutanixConfiguration({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    const resp = yield call(
      protectApi.getNutanixConfigurationDetails,
      data,
      loginReducer.token
    );
    if (resp.status === HTTP_STATUS.OK) {
      yield put(getNutanixConfigurationsSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* createVmwareForVSB({ data, formName }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
    yield put(hideDialog(Dialog.ADD_VMWARE));
    const resp = yield call(
      // protectApi.createVmwareForIVM,
      protectApi.createVmwareForIVMv2,
      data,
      loginReducer.token
    );
    if (resp.status === HTTP_STATUS.OK) {
      yield put(createVmwareForVSBSuccess(resp));
      yield put(getHypervisorsListByType("esxhost%7Cvcenter"));
      if (formName) {
        yield put(change(formName, "esx_server_id", resp.data.hypervisor_id));
      }
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.CREATE_VMWARE_HYPERV_IVM));
  }
}

export function* getComputeRegionsForGCPCloudAccount({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_GCP_CR_OPTIONS));
    const resp = yield call(
      destinationApis.getComputeRegionsForGCPCloudAccount,
      loginReducer.token,
      data
    );
    if (resp?.status === HTTP_STATUS.OK) {
      yield put(getComputeRegionsForGCPCloudAccountSuccessForVsb(resp.data));
    }
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_GCP_CR_OPTIONS));
  } catch (e) {
    window.console.log(e);
  }
}

export function* getComputeZonesForGCPCloudAccount({ data }) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(setLoading(Loader.VIRTUALIZATION_SERVER_GCP_CZ_OPTIONS));
    const resp = yield call(
      destinationApis.getComputeZonesForGCPCloudAccount,
      loginReducer.token,
      data
    );
    if (resp?.status === HTTP_STATUS.OK) {
      yield put(getComputeZonesForGCPCloudAccountSuccessForVsb(resp.data));
    }
    yield put(resetLoading(Loader.VIRTUALIZATION_SERVER_GCP_CZ_OPTIONS));
  } catch (e) {
    window.console.log(e);
  }
}

export default function* VMLocationSaga() {
  return yield all([
    takeLatest(CREATE_HYPERV_FOR_VM, createHyperVForIVM),
    takeLatest(VSB_CONNECT_VMWARE_MONITOR, vsbVmwareMonitorConnectSaga),
    takeLatest(GET_VMWARE_CONFIGURATION_VSB, getVMwareConfiguration),
    takeLatest(VSB_CONNECT_HYPERV, policyVsbConnectHyperVSaga),
    takeLatest(EC2_VALIDATE_CLOUD_PROXY, createStandbyValidateCloudProxy),
    takeLatest(CREATE_NUTANIX_FOR_VSB, createNutanixForIVM),
    takeLatest(FETCH_CLOUD_CONFIGURATIONS_VSB, fetchCloudAccountsConfSaga),
    takeLatest(HYPERV_SHARED_FOLDERS_BROWSE, browseSharedFoldersHyperv),
    takeLatest(
      GET_ALL_VSB_HYPERVISORS_FOR_CREATE_STANDBY_VM,
      getAllVSBHypervisors
    ),
    takeLatest(GET_REGIONS_FOR_CLOUD_ACCOUNT_VSB, getRegionsForCloudAccount),
    takeLatest(
      GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_VSB,
      getResouceGroupForCloudAccount
    ),
    takeLatest(LOAD_VMWARE_NUTNAIX_MONITOR, loadVsbMonitors),
    takeLatest(GET_VSB_NUTANIX_CONFIGURATION, getNutanixConfiguration),
    takeLatest(CREATE_VMWARE_FOR_VSB, createVmwareForVSB),
    takeLatest(
      GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB,
      getComputeRegionsForGCPCloudAccount
    ),
    takeLatest(
      GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB,
      getComputeZonesForGCPCloudAccount
    )
  ]);
}
