import React, { Component } from "react";

import { Redirect } from "react-router";

class Logout extends Component {
  componentDidMount() {
    this.props.logOut();
  }
  render() {
    return <div>{<Redirect to="/signout" push />}</div>;
  }
}

export default Logout;
