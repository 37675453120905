import { select, all, call, put, takeEvery } from "redux-saga/effects";

import * as C from "state/constants";
import * as CsrActions from "state/actions/csr";
import { getLogin, getOriginalLogin } from "state/selectors";
import * as CsrSelectors from "state/selectors/csr";
import { csrApi } from "state/api";
import * as ch from "components/cloud-hybrid/constants";
import {
  startLoader,
  stopLoader,
  addAPIresponse,
  Loader,
  resetLoading,
  ingramOrgFailure,
  showEnrollOrgModal
} from "state/actions/actions";
import * as constant from "utils/appConstants";
import { addSuccessNotification } from "state/actions/ccToast";
import { makeToastData } from "../sagautils";

export function* addNotifications(action) {
  const { notifications } = action.payload;
  if (notifications.find(n => n.includes("JWT"))) {
    yield put(CsrActions.clearNotifications());
    yield put(CsrActions.displayLoginModal());
  }
}

export function* executeConfirmation(action) {
  const confs = yield select(CsrSelectors.getConfirmations);
  const conf = confs.find(
    c => c.confirmationId === action.payload.confirmationId
  );
  yield put(conf.action);
  yield put(CsrActions.cancelConfirmation(conf.confirmationId));
}

export function* fetchOrganizationSearch(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  yield put(startLoader());
  const response = yield call(
    csrApi.organizationsSearch,
    token,
    action.payload.searchString,
    action.payload.page,
    action.payload.pageSize,
    action.payload.sort
  );

  if (response.data) {
    yield put(
      CsrActions.fetchOrganizationSearchSuccess(
        response.data,
        response.pagination
      )
    );
  } else {
    yield put(addAPIresponse(response));
    yield put(CsrActions.fetchOrganizationSearchFailure(response.errors));
  }
  yield put(stopLoader());
}
//Common saga for Refresh org search data grid or edit org property after csr actions
export function* refreshOrgSearchOrEditOrg(action) {
  if (action.payload.fromCsrSearch) {
    yield put(CsrActions.csrRedirect("/csr/org-search"));
    // refresh the search
    const searchString = yield select(
      CsrSelectors.getOrganizationsSearchString
    );
    const pagination = yield select(CsrSelectors.getOrganizationsPagination);
    const sortString = yield select(CsrSelectors.getOrganizationsSortString);
    if (searchString) {
      yield put(
        CsrActions.fetchOrganizationSearch(
          searchString,
          pagination.curr_page,
          pagination.page_size,
          sortString
        )
      );
    }
  } else {
    yield put(CsrActions.fetchOrganizations(action.payload.orgId));
    yield put(CsrActions.fetchOrganizationsProperties(action.payload.orgId));
  }
}

export function* fetchOrganizations(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  let datacenter = null;
  const response = yield call(
    csrApi.organizations,
    token,
    action.payload.orgId
  );

  if (response.data) {
    datacenter =
      response.data && response.data.datacenter
        ? response.data.datacenter
        : null;
    yield put(CsrActions.fetchOrganizationsSuccess(response.data));
    yield put(CsrActions.setCsrOrgDatacenter(datacenter, action.payload.orgId));
    yield put(resetLoading(Loader.CSR_ORG_DATACENTER));
  } else {
    yield put(CsrActions.fetchOrganizationsFailure(response.errors));
    yield put(addAPIresponse(response));
    yield put(CsrActions.setCsrOrgDatacenter(datacenter, action.payload.orgId));
    yield put(resetLoading(Loader.CSR_ORG_DATACENTER));
  }
}

export function* fetchOrganizationsSupport(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.organizationsSupport,
    token,
    action.payload.orgId
  );

  if (response.data) {
    yield put(CsrActions.fetchOrganizationsSupportSuccess(response.data));
  } else {
    yield put(CsrActions.fetchOrganizationsSupportFailure(response.errors));
    yield put(addAPIresponse(response));
  }
}

export function* fetchOrganizationsFreetrialCloudDirect(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.organizationsFreetrialCloudDirect,
    token,
    action.payload.orgId
  );

  if (response.data) {
    yield put(
      CsrActions.fetchOrganizationsFreetrialCloudDirectSuccess(response.data)
    );
  } else {
    yield put(
      CsrActions.fetchOrganizationsFreetrialCloudDirectFailure(response.errors)
    );
    yield put(addAPIresponse(response));
  }
}

export function* fetchOrganizationsFreetrialCloudHybrid(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.organizationsFreetrialCloudHybrid,
    token,
    action.payload.orgId
  );

  if (response.data) {
    yield put(
      CsrActions.fetchOrganizationsFreetrialCloudHybridSuccess(response.data)
    );
  } else {
    yield put(addAPIresponse(response));
    yield put(
      CsrActions.fetchOrganizationsFreetrialCloudHybridFailure(response.errors)
    );
    // yield put(CsrActions.addNotifications(messages));
  }
}

export function* activateOrganizationFreetrialCloudDirect(action) {
  const stateLogin = yield select(getLogin);
  const postData = { datacenter_id: action.payload.dataCenterId };
  const token = stateLogin.token;
  const response = yield call(
    csrApi.activateOrganizationFreetrialCloudDirect,
    token,
    action.payload.orgId,
    postData
  );

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.ACTIVATE_CLOUD_DIRECT;
    if (action.payload.fromCsrSearch) {
      yield put(CsrActions.csrRedirect("/csr/org-search"));
      // refresh the search
      const searchString = yield select(
        CsrSelectors.getOrganizationsSearchString
      );
      const pagination = yield select(CsrSelectors.getOrganizationsPagination);
      const sortString = yield select(CsrSelectors.getOrganizationsSortString);
      if (searchString) {
        yield put(
          CsrActions.fetchOrganizationSearch(
            searchString,
            pagination.curr_page,
            pagination.page_size,
            sortString
          )
        );
      }
    } else {
      yield put(
        CsrActions.fetchOrganizationsFreetrialCloudDirect(action.payload.orgId)
      );
      yield put(CsrActions.fetchOrganizations(action.payload.orgId));
      yield put(CsrActions.fetchOrganizationsProperties(action.payload.orgId));
    }
  }
  yield put(addAPIresponse(response));
}

export function* activateOrganizationFreetrialCloudHybrid(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.activateOrganizationFreetrialCloudHybrid,
    token,
    action.payload.orgId
  );

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.ACTIVATE_CLOUD_HYBRID;
    if (action.payload.fromCsrSearch) {
      yield put(CsrActions.csrRedirect("/csr/org-search"));
      // refresh the search
      const searchString = yield select(
        CsrSelectors.getOrganizationsSearchString
      );
      const pagination = yield select(CsrSelectors.getOrganizationsPagination);
      const sortString = yield select(CsrSelectors.getOrganizationsSortString);
      if (searchString) {
        yield put(
          CsrActions.fetchOrganizationSearch(
            searchString,
            pagination.curr_page,
            pagination.page_size,
            sortString
          )
        );
      }
    } else {
      yield put(
        CsrActions.fetchOrganizationsFreetrialCloudHybrid(action.payload.orgId)
      );
      yield put(CsrActions.fetchOrganizations(action.payload.orgId));
      yield put(CsrActions.fetchOrganizationsProperties(action.payload.orgId));
    }
  }
  yield put(addAPIresponse(response));
}

export function* activateOrganizationFreetrialUDP(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.activateOrganizationFreetrialUDP,
    token,
    action.payload.orgId
  );

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.ACTIVATE_UDP;
    yield put(
      CsrActions.refreshOrgSearchOrEditOrg(
        action.payload.fromCsrSearch,
        action.payload.orgId
      )
    );
  }
  yield put(addAPIresponse(response));
}

export function* invalidateCache(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.invalidateCache,
    token,
    action.payload.orgId
  );

  if (response.errors.length === 0) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.INVALIDATE_CACHE_FOR_ORGANIZATION;
  }
  yield put(addAPIresponse(response));
}

export function* enablePolicies(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.enablePolicies,
    token,
    action.payload.orgId
  );

  if (response.errors.length === 0) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.ENABLE_POLICY_ORGANIZATION;
    yield put(
      CsrActions.refreshOrgSearchOrEditOrg(
        action.payload.fromCsrSearch,
        action.payload.orgId
      )
    );
  }
  yield put(addAPIresponse(response));
}

export function* disablePolicies(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.disablePolicies,
    token,
    action.payload.orgId
  );

  if (response.errors.length === 0) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.DISABLE_POLICY_ORGANIZATION;
    yield put(
      CsrActions.refreshOrgSearchOrEditOrg(
        action.payload.fromCsrSearch,
        action.payload.orgId
      )
    );
  }
  yield put(addAPIresponse(response));
}

export function* enableOrg(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.enableOrg, token, action.payload);

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.ENABLE_ORGANIZATION;
  }
  yield put(addAPIresponse(response));
}

export function* suspendOrg(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.suspendOrg, token, action.payload);

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.SUSPEND_ORGANIZATION;
  }
  yield put(addAPIresponse(response));
}

export function* handleProductStatus(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.handleProductStatus, token, action);

  if (response.errors.length === 0) {
    response.context =
      constant.CSR_PRODUCT_API_RESPONSE_CONTEXT[action.productType][
        action.status
      ];

    yield put(
      CsrActions.refreshOrgSearchOrEditOrg(action.fromCsrSearch, action.orgId)
    );
  }
  yield put(addAPIresponse(response));
}

export function* fetchOrganizationsEntitlements(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.organizationsEntitlements,
    token,
    action.payload.orgId
  );

  if (response.data) {
    yield put(CsrActions.fetchOrganizationsEntitlementsSuccess(response.data));
  } else {
    yield put(
      CsrActions.fetchOrganizationsEntitlementsFailure(response.errors)
    );
    yield put(addAPIresponse(response));
  }
}

export function* fetchOrganizationsProperties(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.organizationsProperties,
    token,
    action.payload.orgId
  );

  if (response.data) {
    yield put(CsrActions.fetchOrganizationsPropertiesSuccess(response.data));
  } else {
    yield put(CsrActions.fetchOrganizationsPropertiesFailure(response.errors));
    yield put(addAPIresponse(response));
  }
}

export function* fetchUsersSearch(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.usersSearch,
    token,
    action.payload.searchString,
    action.payload.page,
    action.payload.pageSize,
    action.payload.sort
  );

  if (response.data) {
    yield put(
      CsrActions.fetchUsersSearchSuccess(response.data, response.pagination)
    );
  } else {
    yield put(CsrActions.fetchUsersSearchFailure(response.errors));
    yield put(addAPIresponse(response));
  }
}

export function* fetchUser(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.user, token, action.payload.userId);
  if (response.data) {
    yield put(CsrActions.fetchUserSuccess(response.data));
  } else {
    yield put(CsrActions.fetchUserFailure(response.errors));
    yield put(addAPIresponse(response));
  }
}

export function* updateUser(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.updateUser,
    token,
    action.payload.userId,
    action.payload.firstName,
    action.payload.lastName,
    action.payload.phoneNumber,
    action.payload.email,
    action.payload.roleId,
    action.payload.blocked
  );

  if (response.data) {
    yield put(CsrActions.fetchUserSuccess(response.data));

    const searchString = yield select(CsrSelectors.getUsersSearchString);
    const pagination = yield select(CsrSelectors.getUsersPagination);
    const sortString = yield select(CsrSelectors.getUsersSortString);

    if (searchString) {
      yield put(
        CsrActions.fetchUsersSearch(
          searchString,
          pagination.curr_page,
          pagination.page_size,
          sortString
        )
      );
    }
    response.context = constant.CSR_API_RESPONSE_CONTEXT.UPDATE_USER;
  } else {
    yield put(CsrActions.fetchUserFailure(response.errors));
  }
  yield put(addAPIresponse(response));
}

export function* createUser(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.createUser,
    token,
    action.payload.organizationId,
    action.payload.firstName,
    action.payload.lastName,
    action.payload.phoneNumber,
    action.payload.email,
    action.payload.roleId
  );

  if (response.data) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.CREATE_USER;
  }
  yield put(addAPIresponse(response));
}

export function* enrollIngramOrg(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  let data = {
    user: action.payload.userObj,
    organization: action.payload.organization
  };
  const response = yield call(csrApi.enrollIngramOrg, token, data);

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.ENROLL_ORGANIZATION;
    yield put(showEnrollOrgModal(false));
    yield put(addAPIresponse(response));
  } else {
    yield put(ingramOrgFailure(response));
    yield put(showEnrollOrgModal(true));
  }
}

export function* deleteUser(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.deleteUser, token, action.payload.userId);
  if (response.errors.length === 0) {
    yield put(CsrActions.csrRedirect("/csr/user-search"));
    response.context = constant.CSR_API_RESPONSE_CONTEXT.DELETE_USER;
  }
  yield put(addAPIresponse(response));
}

export function* updateOrganizations(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.updateOrganization,
    token,
    action.payload.orgId,
    action.payload.organization
  );
  // response success/failure
  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.UPDATE_ORGANIZATION;
    // yield put(addAPIresponse(response));
    // yield put(
    //   CsrActions.addNotifications(["Organization updated successfully"])
    // );
    yield put(CsrActions.csrRedirect("/csr/org-search"));

    // refresh the search
    const searchString = yield select(
      CsrSelectors.getOrganizationsSearchString
    );
    const pagination = yield select(CsrSelectors.getOrganizationsPagination);
    const sortString = yield select(CsrSelectors.getOrganizationsSortString);
    if (searchString) {
      yield put(
        CsrActions.fetchOrganizationSearch(
          searchString,
          pagination.curr_page,
          pagination.page_size,
          sortString
        )
      );
    }
  }
  yield put(addAPIresponse(response));
}

export function* deleteOrganizations(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.deleteOrganizations,
    token,
    action.payload.orgId
  );

  // response success/failure
  if (response.errors.length === 0) {
    yield put(CsrActions.csrRedirect("/csr/org-search"));
    response.context = constant.CSR_API_RESPONSE_CONTEXT.DELETE_ORGANIZATION;
    // refresh the search
    const searchString = yield select(
      CsrSelectors.getOrganizationsSearchString
    );
    const pagination = yield select(CsrSelectors.getOrganizationsPagination);
    const sortString = yield select(CsrSelectors.getOrganizationsSortString);
    if (searchString) {
      yield put(
        CsrActions.fetchOrganizationSearch(
          searchString,
          pagination.curr_page,
          pagination.page_size,
          sortString
        )
      );
    }
  }
  yield put(addAPIresponse(response));
}

export function* sendResetPWEmail(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.sendResetPWEmail,
    token,
    action.payload.userId
  );

  // response success/failure
  if (response.errors.length === 0) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.RESET_PASSWORD_EMAIL_SENT;
  }
  yield put(addAPIresponse(response));
}

export function* sendVerificationEmail(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.sendVerificationEmail,
    token,
    action.payload.userId
  );

  // response success/failure
  if (response.errors.length === 0) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.VERIFICATION_EMAIL_SENT;
  }
  yield put(addAPIresponse(response));
}

export function* fetchAuditCodes() {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.auditCodes, token);

  if (response.data) {
    yield put(
      CsrActions.fetchAuditCodesSuccess(response.data, response.pagination)
    );
  } else {
    yield put(CsrActions.fetchAuditCodesFailure(response.errors));
    yield put(addAPIresponse(response));
  }
}

export function* fetchDataCenters() {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.fetchDataCenters, token);

  if (response.data) {
    yield put(CsrActions.fetchDataCentersSuccess(response.data));
    yield put(CsrActions.createDataStoreFailure(response.data));
  } else {
    yield put(addAPIresponse(response));
    yield put(CsrActions.fetchDataCentersFailure(response.errors));
  }
}

export function* fetchSites(action) {
  const stateLogin = yield select(getOriginalLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.fetchSites,
    token,
    stateLogin.organization_id
  );

  if (response.data) {
    yield put(CsrActions.fetchSitesSuccess(response.data));
  } else {
    yield put(CsrActions.fetchSitesFailure(response.errors));
    yield put(addAPIresponse(response));
  }
}

export function* fetchRecoveryPointServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.fetchRecoveryPointServer,
    token,
    action.payload.id
  );
  if (response.data) {
    yield put(CsrActions.fetchRecoveryPointServerSuccess(response.data));
  } else {
    yield put(addAPIresponse(response));
    yield put(CsrActions.fetchRecoveryPointServerFailure(response.errors));
  }
}

export function* createRecoveryPointServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.createRecoveryPointServer,
    token,
    action.payload
  );

  if (response.data) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.CREATE_RECOVERY_POINT_SERVER_REQUEST;
    const pagination = yield select(
      CsrSelectors.getRecoveryPointServersPagination
    );
    yield put(
      CsrActions.fetchRecoveryPointServers(
        pagination.curr_page,
        pagination.page_size
      )
    );
  }
  yield put(addAPIresponse(response));
}

export function* updateRecoveryPointServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.updateRecoveryPointServer,
    token,
    action.payload
  );
  if (response.data) {
    const pagination = yield select(
      CsrSelectors.getRecoveryPointServersPagination
    );
    yield put(
      CsrActions.fetchRecoveryPointServers(
        pagination.curr_page,
        pagination.page_size
      )
    );
    yield put(CsrActions.fetchRecoveryPointServerSuccess(response.data));
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.UPDATE_RECOVERY_POINT_SERVER_REQUEST;
  } else {
    yield put(CsrActions.fetchRecoveryPointServerFailure(response.errors));
  }
  yield put(addAPIresponse(response));
}

export function* deleteRecoveryPointServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.deleteRecoveryPointServer,
    token,
    action.payload.id
  );

  if (response.errors.length === 0) {
    const page = action.payload.pagination.curr_page;
    const pageSize = action.payload.pagination.page_size;
    yield put(CsrActions.fetchRecoveryPointServers(page, pageSize));
    yield put(CsrActions.csrRedirect(ch.RECOVERY_POINT_SERVERS_PATH));
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.DELETE_RECOVERY_POINT_SERVER_REQUEST;
  }
  yield put(addAPIresponse(response));
}

export function* fetchRecoveryPointServers(action) {
  yield put(startLoader());
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  let defaultSort = action.payload.sort;
  if (action.payload.sort === "" || action.payload.sort === undefined) {
    const defaultSortObject =
      constant.GRID_DEFAULT_SORTING["recovery_point_servers"][0];
    defaultSort = defaultSortObject.desc
      ? `-${defaultSortObject.id}`
      : defaultSortObject.id;
  }
  const response = yield call(
    csrApi.fetchRecoveryPointServers,
    token,
    action.payload.page,
    action.payload.pageSize,
    defaultSort
  );

  if (response.data) {
    yield put(
      CsrActions.fetchRecoveryPointServersSuccess(
        response.data,
        response.pagination
      )
    );
  } else {
    yield put(addAPIresponse(response));
    yield put(CsrActions.fetchRecoveryPointServersFailure(response.errors));
  }
  yield put(stopLoader());
}

export function* fetchCloudHybridStores(action) {
  yield put(startLoader());
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;

  const response = yield call(
    csrApi.fetchCloudHybridStores,
    token,
    action.payload.rpsServerId
  );

  if (response.errors.length === 0) {
    yield put(
      CsrActions.fetchCloudHybridStoresSuccess(
        action.payload.rpsServerId,
        response.data,
        response.pagination
      )
    );
  } else {
    yield put(
      CsrActions.fetchCloudHybridStoresFailure(
        action.payload.rpsServerId,
        response.errors
      )
    );
    yield put(addAPIresponse(response));
  }
  yield put(stopLoader());
}

export function* fetchAuditTrailSearch(action) {
  yield put(startLoader());
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;

  const {
    organizationId,
    userId,
    auditCodes,
    from,
    to,
    page,
    pageSize,
    sort
  } = action.payload;

  let auditCodesParam = auditCodes;

  // if ALL is selected (code_id === -1), pass in nothing
  if (auditCodesParam.find(codeId => codeId === "-1")) {
    auditCodesParam = [];
  }

  let response = null;
  let defaultSort = sort;
  if (sort === undefined) {
    const defaultSortObject = constant.GRID_DEFAULT_SORTING["audit_trail"][0];
    defaultSort = defaultSortObject.desc
      ? `-${defaultSortObject.id}`
      : defaultSortObject.id;
  }

  if (organizationId) {
    response = yield call(
      csrApi.organizationsAuditTrail,
      token,
      organizationId,
      auditCodesParam,
      from,
      to,
      page,
      pageSize,
      defaultSort
    );
  } else {
    response = yield call(
      csrApi.usersAuditTrail,
      token,
      userId,
      auditCodesParam,
      from,
      to,
      page,
      pageSize,
      defaultSort
    );
  }
  if (response.data) {
    yield put(
      CsrActions.fetchAuditTrailSearchSuccess(
        response.data,
        response.pagination
      )
    );
  } else {
    yield put(addAPIresponse(response));
    yield put(CsrActions.fetchAuditTrailSearchFailure(response.errors));
  }
  yield put(stopLoader());
}

export function* fetchLinuxBackupServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.fetchLinuxBackupServer,
    token,
    action.payload.id
  );
  if (response.data) {
    yield put(CsrActions.fetchLinuxBackupServerSuccess(response.data));
  } else {
    yield put(addAPIresponse(response));
    yield put(CsrActions.fetchLinuxBackupServerFailure(response.errors));
  }
}

export function* createLinuxBackupServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.createLinuxBackupServer,
    token,
    action.payload
  );

  if (response.data) {
    const pagination = yield select(
      CsrSelectors.getLinuxBackupServersPagination
    );
    yield put(
      CsrActions.fetchLinuxBackupServers(
        pagination.curr_page,
        pagination.page_size
      )
    );
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.CREATE_LINUX_BACKUP_SERVER_REQUEST;
  }
  yield put(addAPIresponse(response));
}

export function* updateLinuxBackupServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.updateLinuxBackupServer,
    token,
    action.payload
  );
  if (response.data) {
    const pagination = yield select(
      CsrSelectors.getLinuxBackupServersPagination
    );
    yield put(
      CsrActions.fetchLinuxBackupServers(
        pagination.curr_page,
        pagination.page_size
      )
    );
    yield put(CsrActions.fetchLinuxBackupServerSuccess(response.data));
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.UPDATE_LINUX_BACKUP_SERVER_REQUEST;
  } else {
    yield put(CsrActions.fetchLinuxBackupServerFailure(response.errors));
  }
  yield put(addAPIresponse(response));
}

export function* deleteLinuxBackupServer(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.deleteLinuxBackupServer,
    token,
    action.payload.id
  );
  if (response.errors.length === 0) {
    const page = action.payload.pagination.curr_page;
    const pageSize = action.payload.pagination.page_size;
    yield put(CsrActions.fetchLinuxBackupServers(page, pageSize));
    yield put(CsrActions.csrRedirect(ch.LINUX_BACKUP_SERVERS_PATH));
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.DELETE_LINUX_BACKUP_SERVER_REQUEST;
  }
  yield put(addAPIresponse(response));
}
export function* fetchLinuxBackupServers(action) {
  yield put(startLoader());
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  let defaultSort = action.payload.sort;
  if (action.payload.sort === "" || action.payload.sort === undefined) {
    const defaultSortObject =
      constant.GRID_DEFAULT_SORTING["linux_backup_servers"][0];
    defaultSort = defaultSortObject.desc
      ? `-${defaultSortObject.id}`
      : defaultSortObject.id;
  }
  const response = yield call(
    csrApi.fetchLinuxBackupServers,
    token,
    action.payload.page,
    action.payload.pageSize,
    defaultSort
  );

  if (response.data) {
    yield put(
      CsrActions.fetchLinuxBackupServersSuccess(
        response.data,
        response.pagination
      )
    );
  } else {
    yield put(CsrActions.fetchLinuxBackupServersFailure(response.errors));
    yield put(addAPIresponse(response));
  }
  yield put(stopLoader());
}

export function* createDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.createDataStore, token, action.payload);

  if (response.data) {
    yield put(CsrActions.fetchCloudHybridStores(action.payload.rps_server_id));

    yield put(
      CsrActions.csrRedirect("/csr/cloud-hybrid/recovery-point-servers")
    );
    yield put(CsrActions.createDataStoreSuccess(action.payload.rps_server_id));
    response.context = constant.CSR_API_RESPONSE_CONTEXT.CREATE_DATA_STORE;
    yield put(addAPIresponse(response));
  } else {
    yield put(
      CsrActions.createDataStoreFailure(action.payload.rps_server_id, response)
    );
  }
}

export function* fetchDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.fetchDataStore,
    token,
    action.payload.dataStoreId
  );

  if (response.data) {
    yield put(CsrActions.fetchDataStoreSuccess(response.data));
  } else {
    yield put(addAPIresponse(response));
  }
}

export function* updateDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.updateDataStore,
    token,
    action.payload.dataStoreId,
    action.payload.dataStore
  );

  if (response.data) {
    yield put(
      CsrActions.csrRedirect("/csr/cloud-hybrid/recovery-point-servers")
    );
    response.context = constant.CSR_API_RESPONSE_CONTEXT.UPDATE_DATA_STORE;
  }
  yield put(addAPIresponse(response));
}

export function* destroyDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.destroyDataStore,
    token,
    action.payload.dataStoreId
  );

  if (response.errors.length === 0) {
    yield put(
      CsrActions.csrRedirect("/csr/cloud-hybrid/recovery-point-servers")
    );
    response.context = constant.CSR_API_RESPONSE_CONTEXT.DESTROY_DATASTORE;
    yield put(CsrActions.fetchCloudHybridStores(action.payload.rpsServerId));
  }
  yield put(addAPIresponse(response));
}

export function* deleteDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.deleteDataStore,
    token,
    action.payload.rpsServerId,
    { datastore_id: action.payload.dataStoreId }
  );

  if (response.errors.length === 0) {
    yield put(CsrActions.fetchCloudHybridStores(action.payload.rps_server_id));
    yield put(CsrActions.csrRedirect("/csr/cloud-hybrid/data-stores"));
    response.context = constant.CSR_API_RESPONSE_CONTEXT.DELETE_DATASTORE;
  }
  yield put(addAPIresponse(response));
}

export function* startDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.startDataStore,
    token,
    action.payload.dataStoreId
  );

  if (response.errors.length === 0) {
    response.context =
      constant.CSR_API_RESPONSE_CONTEXT.START_DATASTORE_SUCCESS;
    yield put(CsrActions.fetchDataStore(action.payload.dataStoreId));
    yield put(CsrActions.fetchCloudHybridStores(action.payload.rpsServerId));
  }
  yield put(addAPIresponse(response));
}

export function* stopDataStore(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.stopDataStore,
    token,
    action.payload.dataStoreId
  );

  if (response.errors.length === 0) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.STOP_DATASTORE_SUCCESS;
    yield put(CsrActions.fetchDataStore(action.payload.dataStoreId));
    yield put(CsrActions.fetchCloudHybridStores(action.payload.rpsServerId));
  }
  yield put(addAPIresponse(response));
}

export function* fetchBIReportsTypes() {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(csrApi.fetchBIReportTypes, token);
  if (response.data) {
    yield put(CsrActions.fetchBIReportsTypesSuccess(response.data));
  } else {
    yield put(addAPIresponse(response));
  }
}

export function* fetchBIReport(action) {
  yield put(CsrActions.fetchBIReportSuccess([]));
  yield put(startLoader());
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.fetchBIReport,
    token,
    action.payload.reportType
  );
  if (response.data) {
    yield put(CsrActions.fetchBIReportSuccess(response.data));
  } else {
    yield put(addAPIresponse(response));
  }
  yield put(stopLoader());
}

export function* fetchBIReportRecipients(action) {
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;
  const response = yield call(
    csrApi.fetchBIReportRecipients,
    token,
    action.payload.reportType
  );
  if (response.data) {
    yield put(CsrActions.fetchBIReportRecipientsSuccess(response.data));
  } else {
    yield put(addAPIresponse(response));
  }
}

export function* updateBIReportRecipients(action) {
  let requestType, response;
  const stateLogin = yield select(getLogin);
  const token = stateLogin.token;

  const biRecipientsResponse = yield call(
    csrApi.fetchBIReportRecipients,
    token,
    action.payload.reportType
  );

  if (biRecipientsResponse.data.recipient) {
    requestType = "put";
  } else {
    requestType = "post";
  }

  response = yield call(
    csrApi.updateBIReportRecipients,
    requestType,
    token,
    action.payload.reportType,
    { recipient: action.payload.recipient }
  );

  if (response.data) {
    response.context = constant.CSR_API_RESPONSE_CONTEXT.RECIPIENTS_SUCCESS;
  }
  yield put(addAPIresponse(response));
}

/**
 * To upload the Support > Download Content > CSV file.
 */
function* uploadFile(uploadData) {
  yield put(startLoader());
  try {
    const stateLogin = yield select(getLogin);
    let response = yield call(
      csrApi.uploadFile,
      uploadData.uploadData,
      stateLogin.token
    );
    if (response.errors.length === 0) {
      yield put(
        addSuccessNotification(
          makeToastData(
            constant.CSR_API_RESPONSE_CONTEXT.UPLOAD_FILE_SUCCESS,
            "AQM592"
          )
        )
      );
    }
    yield put(addAPIresponse(response));
  } catch (e) {
    window.console.log(e);
  }
  yield put(stopLoader());
}

/**
 * To upload the Help Link CSV file.
 * @param uploadData
 */
function* uploadHelpLinkFile(uploadData) {
  yield put(startLoader());
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      csrApi.uploadHelpLinkCSVFile,
      uploadData.uploadData,
      loginReducer.token
    );
    if (apiResponse.errors.length === 0) {
      yield put(
        addSuccessNotification(
          constant.CSR_API_RESPONSE_CONTEXT.UPLOAD_HELP_LINK_SUCCESS
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    console.log(e);
  }
  yield put(stopLoader());
}

/**
 * Set a user's password
 * @param uploadData
 */
export function* updateUserPassword(data) {
  yield put(startLoader());
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      csrApi.updateUserPassword,
      loginReducer.token,
      {
        email: data.data.email,
        password: data.data.password
      }
    );
    if (apiResponse.errors.length === 0) {
      yield put(
        addSuccessNotification(
          makeToastData(
            constant.CSR_API_RESPONSE_CONTEXT.USER_PASSWORD_UPDATED,
            "E9i3yJ"
          )
        )
      );
    }
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    console.log(e);
  }
  yield put(stopLoader());
}

export default function* csrSaga() {
  yield all([
    takeEvery(C.ADD_NOTIFICATIONS, addNotifications),
    takeEvery(C.EXECUTE_CONFIRMATION, executeConfirmation),
    takeEvery(C.FETCH_ORGANIZATION_SEARCH_REQUEST, fetchOrganizationSearch),
    takeEvery(
      C.REFRESH_ORGANIZATION_SEARCH_OR_EDIT_ORG_REQUEST,
      refreshOrgSearchOrEditOrg
    ),
    takeEvery(C.FETCH_ORGANIZATIONS_REQUEST, fetchOrganizations),
    takeEvery(
      C.FETCH_ORGANIZATIONS_PROPERTIES_REQUEST,
      fetchOrganizationsProperties
    ),
    takeEvery(C.FETCH_ORGANIZATIONS_SUPPORT_REQUEST, fetchOrganizationsSupport),
    takeEvery(
      C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_REQUEST,
      fetchOrganizationsFreetrialCloudDirect
    ),
    takeEvery(
      C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_REQUEST,
      fetchOrganizationsFreetrialCloudHybrid
    ),
    takeEvery(
      C.ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_DIRECT_REQUEST,
      activateOrganizationFreetrialCloudDirect
    ),
    takeEvery(
      C.ACTIVATE_ORGANIZATION_FREETRIAL_CLOUD_HYBRID_REQUEST,
      activateOrganizationFreetrialCloudHybrid
    ),
    takeEvery(
      C.ACTIVATE_ORGANIZATION_FREETRIAL_UDP_REQUEST,
      activateOrganizationFreetrialUDP
    ),
    takeEvery(C.INVALIDATE_CACHE, invalidateCache),
    takeEvery(C.ENABLE_POLICIES, enablePolicies),
    takeEvery(C.DISABLE_POLICIES, disablePolicies),
    takeEvery(C.SUSPEND_ORG, suspendOrg),
    takeEvery(C.ENABLE_ORG, enableOrg),
    takeEvery(C.PRODUCT_STATUS, handleProductStatus),
    takeEvery(
      C.FETCH_ORGANIZATIONS_ENTITLEMENTS_REQUEST,
      fetchOrganizationsEntitlements
    ),
    takeEvery(C.UPDATE_ORGANIZATIONS_REQUEST, updateOrganizations),
    takeEvery(C.DELETE_ORGANIZATIONS_REQUEST, deleteOrganizations),
    takeEvery(C.FETCH_USERS_SEARCH_REQUEST, fetchUsersSearch),
    takeEvery(C.FETCH_USER_REQUEST, fetchUser),
    takeEvery(C.UPDATE_USER_REQUEST, updateUser),
    takeEvery(C.SEND_RESET_PW_EMAIL_REQUEST, sendResetPWEmail),
    takeEvery(C.SEND_VERIFICATION_EMAIL_REQUEST, sendVerificationEmail),
    takeEvery(C.CREATE_USER_REQUEST, createUser),
    takeEvery(C.CREATE_INGRAM_ORG_REQUEST, enrollIngramOrg),
    takeEvery(C.DELETE_USER_REQUEST, deleteUser),
    takeEvery(C.FETCH_AUDIT_CODES_REQUEST, fetchAuditCodes),
    takeEvery(C.FETCH_AUDIT_TRAIL_SEARCH_REQUEST, fetchAuditTrailSearch),
    takeEvery(C.FETCH_DATA_CENTERS_REQUEST, fetchDataCenters),
    takeEvery(C.FETCH_SITES_REQUEST, fetchSites),
    takeEvery(C.FETCH_RECOVERY_POINT_SERVER_REQUEST, fetchRecoveryPointServer),
    takeEvery(
      C.CREATE_RECOVERY_POINT_SERVER_REQUEST,
      createRecoveryPointServer
    ),
    takeEvery(
      C.UPDATE_RECOVERY_POINT_SERVER_REQUEST,
      updateRecoveryPointServer
    ),
    takeEvery(
      C.DELETE_RECOVERY_POINT_SERVER_REQUEST,
      deleteRecoveryPointServer
    ),
    takeEvery(
      C.FETCH_RECOVERY_POINT_SERVERS_REQUEST,
      fetchRecoveryPointServers
    ),
    takeEvery(C.FETCH_LINUX_BACKUP_SERVER_REQUEST, fetchLinuxBackupServer),
    takeEvery(C.CREATE_LINUX_BACKUP_SERVER_REQUEST, createLinuxBackupServer),
    takeEvery(C.UPDATE_LINUX_BACKUP_SERVER_REQUEST, updateLinuxBackupServer),
    takeEvery(C.DELETE_LINUX_BACKUP_SERVER_REQUEST, deleteLinuxBackupServer),
    takeEvery(C.FETCH_CLOUD_HYBRID_STORES_REQUEST, fetchCloudHybridStores),
    takeEvery(C.FETCH_LINUX_BACKUP_SERVERS_REQUEST, fetchLinuxBackupServers),
    takeEvery(C.FETCH_DATA_STORE_REQUEST, fetchDataStore),
    takeEvery(C.CREATE_DATA_STORE_REQUEST, createDataStore),
    takeEvery(C.UPDATE_DATA_STORE_REQUEST, updateDataStore),
    takeEvery(C.DESTROY_DATA_STORE_REQUEST, destroyDataStore),
    takeEvery(C.DELETE_DATA_STORE_REQUEST, deleteDataStore),
    takeEvery(C.START_DATA_STORE_REQUEST, startDataStore),
    takeEvery(C.STOP_DATA_STORE_REQUEST, stopDataStore),
    takeEvery(C.FETCH_BI_REPORTS_TYPES_REQUEST, fetchBIReportsTypes),
    takeEvery(C.FETCH_BI_REPORT_REQUEST, fetchBIReport),
    takeEvery(C.FETCH_BI_REPORT_RECIPIENTS_REQUEST, fetchBIReportRecipients),
    takeEvery(C.UPDATE_BI_REPORT_RECIPIENTS_REQUEST, updateBIReportRecipients),
    takeEvery(C.UPLOAD_FILE, uploadFile),
    takeEvery(C.UPLOAD_HELP_LINK_FILE, uploadHelpLinkFile),
    takeEvery(C.UPDATE_USER_PASSWORD, updateUserPassword)
  ]);
}
