import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Holdes constants definitions for Sources
 */
export const userAccountsAttributes = {
  USER_ACCOUNTS_IMAGE_URL: "image_url",
  USER_ACCOUNTS_NAME: "name",
  USER_ACCOUNTS_USER_NAME: "user_name",
  USER_ACCOUNTS_FIRST_NAME: "first_name",
  USER_ACCOUNTS_LAST_NAME: "last_name",
  USER_ACCOUNTS_EMAIL: "email",
  USER_ACCOUNTS_ROLE_ID: "role_id",
  USER_ACCOUNTS_STATUS: "status",
  USER_ACCOUNTS_ID: "user_id",
  USER_ACCOUNTS_CREATE_TS: "create_ts",
  USER_ACCOUNTS_LAST_LOGGED_IN: "last_login_ts",
  USER_ACCOUNTS_IS_BLOCKED: "blocked",
  USER_ACCOUNTS_ACTIONS: "available_actions",
  USER_ACCOUNTS_SEARCH_STRING: "search_string",
  USER_ACCOUNTS_ACCOUNTS: "accounts",
  USER_ACCOUNTS_ENABLED: "app_enabled"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return `configure.accessControl.userAccounts.${identifier}`;
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_CHANGE_STATUS : On click of cell the status will change
 * @type {{CLICKABLE_CELL_TO_VIEW_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: userAccountsAttributes.USER_ACCOUNTS_NAME
};

export const UserAccountsConstants = {
  /**
   * Contextual actions for user accounts
   */
  CONTEXTUAL_ACTION: {
    RESET_PASSWORD: "resetpassword",
    RESEND_VERIFICATION_MAIL: "verificationemail",
    DELETE_USER: "delete",
    ASSIGN_ACCOUNT: "assignaccount",
    ADD_USER: "adduser",
    EDIT_USER: "edituser",
    UPDATE_USER: "update",
    RESET_TWO_FACTOR: "resettwofactor",
    MANAGE_PERMISSION: "update_role"
  }
};

/**
 * Column definition for the User Accounts table
 */
export const COLUMN_DEFS = [
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_NAME,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_USER_NAME,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_USER_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_EMAIL,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_EMAIL
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_ROLE_ID,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_ROLE_ID
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_ACCOUNTS,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_ACCOUNTS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type:
        DataGridColumnCellTypes.RENDER_ACCOUNTS_OVERLAY_CELL_WITHOUT_LINK_MUI
    },
    settings: {}
  },
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_ENABLED,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_ENABLED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  /* {
    accessor: userAccountsAttributes.USER_ACCOUNTS_STATUS,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  }, */
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_CREATE_TS,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_CREATE_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI }
  },
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_LAST_LOGGED_IN,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_LAST_LOGGED_IN
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  /* {
    accessor: userAccountsAttributes.USER_ACCOUNTS_IS_BLOCKED,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_IS_BLOCKED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  }, */
  {
    accessor: userAccountsAttributes.USER_ACCOUNTS_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      userAccountsAttributes.USER_ACCOUNTS_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  }
];
