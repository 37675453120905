import * as C from "state/constants";

const initialState = {
  recoveryPointServersSortString: "",
  linuxBackupServersSortString: "",
  dataStoresSortString: "",
  chDataStoresSortString: ""
};

const organizationSearch = (state = initialState, action) => {
  switch (action.type) {
    case C.SET_RECOVERY_POINT_SERVERS_SORT_STRING:
      return Object.assign({}, state, {
        recoveryPointServersSortString: action.payload.sortString,
        linuxBackupServersSortString: "",
        dataStoresSortString: "",
        chDataStoresSortString: ""
      });

    case C.SET_LINUX_BACKUP_SERVERS_SORT_STRING:
      return Object.assign({}, state, {
        recoveryPointServersSortString: "",
        linuxBackupServersSortString: action.payload.sortString,
        dataStoresSortString: "",
        chDataStoresSortString: ""
      });

    case C.SET_DATA_STORES_SORT_STRING:
      return Object.assign({}, state, {
        recoveryPointServersSortString: "",
        linuxBackupServersSortString: "",
        dataStoresSortString: action.payload.sortString,
        chDataStoresSortString: ""
      });

    case C.SET_CH_DATA_STORES_SORT_STRING:
      return Object.assign({}, state, {
        recoveryPointServersSortString: "",
        linuxBackupServersSortString: "",
        dataStoresSortString: "",
        chDataStoresSortString: action.payload.sortString
      });

    default:
      return state;
  }
};

export default organizationSearch;
