import { LOCALES } from "./locales";
import en from "./Messages/messages_en.json";
import de from "./Messages/messages_de.json";
import es from "./Messages/messages_es.json";
import fr from "./Messages/messages_fr.json";
import it from "./Messages/messages_it.json";
import ja from "./Messages/messages_ja.json";
import pt from "./Messages/messages_pt.json";

export const messages = {
  [LOCALES.en]: en,
  [LOCALES.fr]: fr,
  [LOCALES.de]: de,
  [LOCALES.ja]: ja,
  [LOCALES.it]: it,
  [LOCALES.es]: es,
  [LOCALES.pt]: pt
};
