import {
  select,
  all,
  call,
  put,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import { getLogin, getOrgId, getLoginToken } from "state/selectors";
import * as constant from "utils/appConstants";
import { protectApi, destinationApis } from "state/api";
import * as createStandbyVMConstants from "state/constants/createStandbyVM";
import { setLoading, resetLoading, Loader } from "state/actions/UIActions";
import {
  fetchDataSuccess,
  resetCCDataGridLoader
} from "state/actions/ccDataGrid";
import {
  fetchProxiesVSBSuccess,
  validateVSBSuccess
} from "../../actions/createStandbyVM";
import { showErrToastFromResponse } from "../sagautils";

export function* fetchProxiesVsbSaga({
  data,
  srcId,
  srcType,
  destinationType
}) {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    let resp = {};
    if (srcType === "udp_windows" && destinationType === "local_disk") {
      const respObj = yield call(
        protectApi.fetchSourceById,
        srcId,
        login.token
      );
      resp = { ...respObj };
      resp.pagination = {
        curr_page: 1,
        total_page: 1,
        page_size: 25,
        has_prev: false,
        has_next: false,
        total_size: 1
      };
      resp.data = [
        {
          ...respObj.data,
          proxy_id: respObj.data && respObj.data.source_id,
          proxy_name: respObj.data && respObj.data.source_name
        }
      ];
    } else {
      resp = yield call(
        protectApi.getBackupProxy,
        `organization_id=${orgId}&server_role_types=ar_windows_proxy%7Cwindows_backup_server%7Cvsb_convertor%7Cvsb_monitor&site_id=${data}`,
        login.token
      );
    }

    resp.context = constant.API_RESPONSE_CONTEXT.FETCH_PROXIES_IVM;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchProxiesVSBSuccess(resp));
      const dataForGrid =
        resp.data &&
        resp.data.map(r => ({
          ...r,
          _rowId: r?.proxy_id
        }));
      const pagination = {
        curr_page: 1,
        total_page: 1,
        page_size: 25,
        has_prev: false,
        has_next: false,
        total_size: dataForGrid.length
      };
      yield put(
        fetchDataSuccess(
          constant.CCDATAGRID_ID.SELECT_CREATE_STANDBY_VM_PROXY,
          resp,
          dataForGrid,
          pagination
        )
      );
      yield put(
        resetCCDataGridLoader(
          constant.CCDATAGRID_ID.SELECT_CREATE_STANDBY_VM_PROXY
        )
      );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (error) {
    window.console.log(error);
  }
}

export function* validateNFS({ data }) {
  try {
    yield put(setLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.ivmValidateNFS, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(validateVSBSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
  }
}

export function* validateHyperV({ data }) {
  try {
    yield put(setLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
    const token = yield select(getLoginToken);
    const resp = yield call(destinationApis.ivmValidateHyperV, token, data);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(validateVSBSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
  }
}

export default function* recoveryServer() {
  yield all([
    takeLatest(createStandbyVMConstants.FETCH_PROXIES_VSB, fetchProxiesVsbSaga),
    takeEvery(createStandbyVMConstants.VSB_VALIDATE_NFS, validateNFS),
    takeEvery(createStandbyVMConstants.VSB_VALIDATE_HYPERV, validateHyperV)
  ]);
}
