import React from "react";
import PropTypes from "prop-types";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { injectIntl } from "react-intl";
import Icon from "../../../assets/icons/icons";
import FormattedMessage from "../formatted-message";
import {
  getLocaleDate,
  convertUTCtimestamp
} from "../../../utils/SpogDataGridUtil";

const DatePicker = props => {
  const { intl } = props;

  const callBackHandler = (event, picker) => {
    if (!props.isSingleDatePicker) {
      const startDate = picker.startDate;
      let item = [
        {
          label: getLocaleDate(startDate.startOf("day").unix(), props.format),
          value: convertUTCtimestamp(startDate.startOf("day").unix(), false)
        }
      ];
      const endDate = picker.endDate;
      item.push({
        label: getLocaleDate(endDate.endOf("day").unix(), props.format),
        value: convertUTCtimestamp(endDate.endOf("day").unix(), false)
      });
      props.callBackHandler(props.index, item);
    } else {
      const startDate = picker.startDate;
      let item = {
        label: getLocaleDate(startDate.startOf("day").unix(), props.format),
        value: {
          start: convertUTCtimestamp(startDate.startOf("day").unix(), false),
          end: convertUTCtimestamp(startDate.endOf("day").unix(), false)
        }
      };
      props.callBackHandler(props.index, item);
    }
  };

  /**
   * get the current dates passed to the date picker
   */
  const getCurrentDates = () => {
    if (!props.isSingleDatePicker) {
      let selectedDate = [];
      if (props.startDate && props.startDate.length) {
        selectedDate = props.startDate.map(item =>
          getLocaleDate(item.value, props.format)
        );
      }

      if (props.endDate && props.endDate.length) {
        selectedDate = [
          ...selectedDate,
          props.endDate.map(item => getLocaleDate(item.value, props.format))
        ];
      }
      return selectedDate.join(" - ");
    } else {
      let selectedDate = "";
      if (props.startDate) {
        selectedDate = getLocaleDate(props.startDate, props.format);
      }
      return selectedDate;
    }
  };

  return (
    <div
      key={props.index}
      className={
        "date-picker-wrapper " +
        (props.isSingleDatePicker
          ? "single-date-wrapper"
          : "date-range-wrapper")
      }
    >
      {!props.hideDatePickerLabel && (
        <label htmlFor="date-picker-label">
          <FormattedMessage id={props.name} defaultMessage={props.name} />
        </label>
      )}
      <DateRangePicker
        applyClass="date-range-picker-buttons primary"
        cancelClass="date-range-picker-buttons secondary"
        singleDatePicker={props.isSingleDatePicker || false}
        showDropdowns
        drops={props.dropdownDirection}
        onApply={callBackHandler}
        opens={props.opens}
        minDate={props.minDate}
        maxDate={props.maxDate}
        containerClass={
          props.isSingleDatePicker
            ? "react-bootstrap-daterangepicker-container"
            : "date-range"
        }
        parentEl={props.parentEl}
        locale={{
          // http://www.daterangepicker.com/#config
          applyLabel: intl.formatMessage({ id: "datepicker.apply" }),
          cancelLabel: intl.formatMessage({ id: "datepicker.cancel" })
        }}
      >
        <div>
          <input
            type="text"
            placeholder={intl.formatMessage({ id: "select" })}
            value={getCurrentDates()}
            readOnly
            style={{
              minWidth:
                getCurrentDates()?.length > 31
                  ? `${getCurrentDates().length * 8}px`
                  : props.minWidth
                  ? props.minWidth
                  : "250px"
            }}
            className={
              "date-picker-control " +
              (props.isSingleDatePicker ? "" : "date-range-control")
            }
          />
          <span className="calendar-icon">
            <Icon icon="calendar" height="35" width="35" />
          </span>
        </div>
      </DateRangePicker>
    </div>
  );
};

DatePicker.defaultProps = {
  opens: "center",
  isSingleDatePicker: true,
  startDate: [],
  endDate: [],
  hideDatePickerLabel: false,
  dropdownDirection: "down",
  format: "LL",
  parentEl: "body",
  name: ""
};

DatePicker.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  callBackHandler: PropTypes.func.isRequired,
  opens: PropTypes.string,
  isSingleDatePicker: PropTypes.bool,
  hideDatePickerLabel: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dropdownDirection: PropTypes.string,
  format: PropTypes.string,
  parentEl: PropTypes.string,
  minWidth: PropTypes.string
};

export default injectIntl(DatePicker);
