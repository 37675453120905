import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import FormattedMessage from "components/common/formatted-message";

function getParameterByName(name, url = window.location.href) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const Error = () => {
  let errorCode = getParameterByName("error");
  let errorDescription = getParameterByName("error_description");

  return (
    <div
      style={{
        width: "98vw",
        height: "98vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div style={{ marginTop: -140 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LockIcon style={{ height: 84, width: 84, color: "#6200ee" }} />
        </div>

        <div style={{ fontSize: "1.1rem", marginTop: 20 }}>
          <FormattedMessage
            id="octa.errorPage.error"
            defaultMessage="Cloud Console authorization was denied. Please contact your Arcserve Support."
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "1.1rem"
          }}
        >
          <span>Error {errorCode}</span>
          <span>: {errorDescription}</span>
        </div>
      </div>
    </div>
  );
};

export default Error;
