import React from "react";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

/**
 * Reusable component to render chip
 */
const ChipComponent = props => {
  const { size, label, onClick, onDelete, className, color, children } = props;
  return (
    <Chip
      color={color}
      size={size}
      //eslint-disable-next-line eqeqeq
      label={label != undefined ? label : children}
      onClick={onClick}
      onDelete={onDelete}
      className={className}
    />
  );
};

ChipComponent.propTypes = {
  size: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ]).isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string
};

export default ChipComponent;
