import React from "react";

import { FormattedMessage } from "react-intl";

const InsufficientData = () => (
  <div className="insufficient-data-container">
    <FormattedMessage
      id="dashboard.insufficientData"
      defaultMessage="Insufficient data to render this widget."
    />
  </div>
);

export default InsufficientData;
