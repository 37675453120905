import { call } from "redux-saga/effects";
import { getData, deleteData, putData } from "./apiMethods";
import * as API_URL from "./apiUrlConstants";

/**
 * to update widgets location
 */
export function* updateWidgetsOrder(user_id, postbody, token) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = url + "/users/" + user_id + "/dashboard/widgets";
    return yield call(putData, url, postbody, token);
  } catch (error) {
    window.console.log(error);
  }
}

/**
 * to fetch widgets list
 */
export function* fetchWidgetsList(user_id, token, pollingAction = false) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = url + "/users/" + user_id + "/dashboard/widgets";
    return yield call(getData, url, "", token, pollingAction);
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to fetch alerts list
 */
export function* fetchAlertsList(
  organization_id,
  token,
  pollingAction = false
) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = url + "/v2/alerts?organization_id=" + organization_id;
    return yield call(getData, url, "", token, pollingAction);
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchAlertsDetail(organization_id, token, alert_id) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = url + "/v2/organizations/" + organization_id + "/alerts/" + alert_id;
    const data = yield call(getData, url, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to delete alert by id
 */
export function* deleteAlert(
  organization_id,
  token,
  alert_id,
  showCommonError = true
) {
  try {
    let url = API_URL.BE_HOST_URL;
    url += "/alerts/" + alert_id;
    const response = yield call(deleteData, url, token, "", showCommonError);
    return response;
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to delete all alert by type
 */
export function* acknowledgeAllAlert(
  organization_id,
  alert_type,
  token,
  showCommonError = false
) {
  try {
    let url = API_URL.BE_HOST_URL;
    url = url + "/alerts";
    return yield call(
      deleteData,
      `${url}?organization_id=${organization_id}&severity=${alert_type}`,
      token,
      {},
      showCommonError
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to fetch dashboard usage summary
 */
export function* fetchUsageSummary(orgId, query, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_LICENCE_DETAIL}/${orgId}/entitlements`,
      query,
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to fetch dashboard customer summary
 */
export function* fetchCustomerSummary(orgId, token) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_LICENCE_DETAIL}/${orgId}/accountsummary`,
      "",
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to fetch dashboard job status
 */
export function* fetchJobStatus(query, token) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_DASHBOARD_BACKUP_JOBS_SUMMARY_V2,
      query,
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to fetch dashboard source summary
 */
export function* fetchSourceSummary(query, token) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_DASHBOARD_SOURCE_SUMMARY,
      query,
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to fetch dashboard source protection summary
 */
export function* fetchSourceProtectionSummary(query, token) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_DASHBOARD_SOURCE_PROTECTION_SUMMARY,
      query,
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * to fetch dashboard policy summary
 */
export function* fetchPolicySummary(query, token) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_DASHBOARD_POLICY_SUMMARY,
      query,
      token
    );
  } catch (e) {
    window.console.error(e);
  }
}

/**
 * To fetch dashboard widgets data
 */

export function* loadWidgets(url, queryParams, token) {
  try {
    const response = yield call(getData, url, queryParams, token);
    return response;
  } catch (e) {
    window.console.error(e);
  }
}
