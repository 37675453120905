import { combineReducers } from "redux";
import {
  SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_ERROR,
  SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE,
  SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_SUCCESS,
  HIDE_DIALOG,
  REMOVE_FROM_LIST_LINUX_BACKUP_SERVER_SOURCE
} from "state/actions/actionTypes";
import { Dialog } from "state/actions/actions";

/**
 * Errors occured during Connect API call to LBS is maintained here
 * @param {*} state
 * @param {*} action
 */
const connectErrors = (state = null, { type, data, name }) => {
  switch (type) {
    case SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE:
      return null;

    case HIDE_DIALOG:
      return name === Dialog.ADD_LINUX_BACKUP_SERVER ? null : state;

    case SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_ERROR:
      return data;

    default:
      return state;
  }
};

/**
 * If Connect API is success, then we are adding the fields
 * to connected list of servers to be added to sources
 * @param {*} state
 * @param {*} action
 */
const savedList = (state = [], { type, data, name, uuid }) => {
  switch (type) {
    case SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_SUCCESS:
      return [...state, data];

    case REMOVE_FROM_LIST_LINUX_BACKUP_SERVER_SOURCE:
      return state.filter(source => source.uuid !== uuid);

    case HIDE_DIALOG:
      return name === Dialog.ADD_LINUX_BACKUP_SERVER ? [] : state;

    default:
      return state;
  }
};

export default combineReducers({ connectErrors, savedList });
