import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import TextField from "@mui/material/TextField";

import SearchResults from "./components/SearchResults";
import Button from "../../../../common/button";
import FormattedMessage from "../../../../common/formatted-message";
import { CSR_CONSTANTS } from "../../../../../utils/appConstants";

const OrgSearch = ({
  setOrganizationsSearchString,
  resetOrganizationId,
  searchString,
  input,
  id
}) => {
  const [searchStringText, setSearchStringText] = useState(null);

  const handleSearchOrgClick = () => {
    setOrganizationsSearchString(searchStringText);
    resetOrganizationId("");
  };

  return (
    <div>
      <div className="org-search__control-container">
        <div>
          <TextField
            id={id ? id : "outlined-basic"}
            inputProps={{
              style: {
                border: "none",
                paddingTop: "18.5px",
                paddingRight: "14px",
                paddingBottom: "18.5px",
                paddingLeft: "14px"
              }
            }}
            className="spog-mui-textfield"
            placeholder={CSR_CONSTANTS.search_org_placeholder}
            onChange={e => setSearchStringText(e.target.value)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchOrgClick();
              }
            }}
            variant="outlined"
          />
          {/* <input
            type="text"
            placeholder={CSR_CONSTANTS.search_org_placeholder}
            onChange={e => setSearchStringText(e.target.value)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchOrgClick();
              }
            }}
          /> */}
          &nbsp;&nbsp;&nbsp;
          <Button
            color="secondary"
            content={
              <FormattedMessage
                id="csr.serach_for_org"
                defaultMessage="Search for Org *"
              />
            }
            onClick={handleSearchOrgClick}
            style={{ margin: "10px", textTransform: "none" }}
            variant="contained"
            testId="40DS84"
          />
        </div>
      </div>
      {searchString && (
        <div className="search-results__table">
          <SearchResults
            key={searchString}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
      )}
    </div>
  );
};

OrgSearch.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  setOrganizationsSearchString: PropTypes.func.isRequired,
  resetOrganizationId: PropTypes.func.isRequired,
  searchString: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired
};

OrgSearch.defaultProps = {
  searchString: null
};

export default React.memo(injectIntl(OrgSearch));
