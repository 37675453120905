import { all, select, takeLatest, call, put } from "redux-saga/effects";
import { getLogin } from "state/selectors";
import { HTTP_STATUS } from "utils/appConstants";
import { FETCH_DOWNLOADS } from "../actions/actionTypes";
import { downloadsApi } from "../api";
import { fetchDownloadsSuccess, fetchDownloadsError } from "../actions/actions";

/**
 * fetch downdloads from api and store it in the
 * store
 */
function* fetchDownloads() {
  try {
    const loginReducer = yield select(getLogin);
    const apiResponse = yield call(
      downloadsApi.getDownloads,
      loginReducer.token
    );
    if (apiResponse.status === HTTP_STATUS.OK) {
      yield put(fetchDownloadsSuccess(apiResponse.data));
    } else {
      yield put(fetchDownloadsError());
    }
  } catch (error) {
    put(fetchDownloadsError());
  }
}

/**
 * downloads root saga
 */
export default function* downloadsSaga() {
  return yield all([takeLatest(FETCH_DOWNLOADS, fetchDownloads)]);
}
