import loginSaga from "./loginSaga";
import appSaga from "./saga";
import protectSaga from "./protectSaga";
import configureSaga from "./configureSaga";
import analyzeSaga from "./analyzeSaga";
import csrSaga from "./csrSaga";
import monitorSaga from "./monitorSaga";
import ccDataGrid from "./ccDataGrid";
import ccGraph from "./ccGraph";
import downloadsSaga from "./downloadsSaga";
import destination from "./destination";
import createStandbyVMSaga from "./createStandbyVM";

export default function* rootSaga() {
  yield [
    appSaga(),
    loginSaga(),
    protectSaga(),
    configureSaga(),
    monitorSaga(),
    analyzeSaga(),
    csrSaga(),
    ccDataGrid(),
    ccGraph(),
    downloadsSaga(),
    destination(),
    createStandbyVMSaga()
  ];
}
