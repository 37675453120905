import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FormattedMessage from "components/common/formatted-message";

const Tooltipped = ({ id, text, children }) => (
  <OverlayTrigger
    trigger={["focus", "hover"]}
    placement="bottom"
    overlay={
      <Tooltip id={`${id}_tooltip`}>
        <FormattedMessage id={text} defaultMessage={text} />
      </Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

Tooltipped.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Tooltipped;
