import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { CHART_COLORS } from "utils/appConstants";
import { withCCGraph } from "components/common/CCGraph/HOCs";
import * as sGraph from "state/selectors/ccGraph";
import InsufficientData from "./InsufficientData";
import CustomizedLegend from "./CustomizedLegend";
import Wrapper from "./RechartWrapper";
import { PieChart, Pie, ResponsiveContainer, Legend } from "recharts";
import RenderActiveShape from "./PieChartHOC";

const loadingState = () => (
  <svg
    className="load-state donut-load-state"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 376 331"
  >
    <defs>
      <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0" className="loading-charts" stopOpacity="0.8" />
        <stop offset="33.33%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="50%" className="loading-charts" stopOpacity="0" />
        <stop offset="66.66%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="100%" className="loading-charts" stopOpacity="0.8" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(116 107)">
        <circle
          cx="72"
          cy="72"
          r="72"
          stroke="#EFF2F5"
          strokeLinecap="round"
          strokeWidth="9.6"
        />
        <path
          stroke="#D2D6DF"
          strokeWidth="19.2"
          d="M126.153559,119.524055 C137.264629,106.845712 144,90.2321371 144,72.0444604 C144,32.2554036 111.764502,0 72,0"
        />
        <circle cx="72" cy="72" r="67.2" fill="#FFF" />
      </g>
    </g>
    <rect
      className="chart-filter"
      fill="url(#lgrad)"
      x="-100%"
      y="0"
      width="300%"
      height="100%"
    ></rect>
  </svg>
);

const Donut = props => {
  let wrapperRef = useRef();
  const [activePieIndex, setActivePieIndex] = useState(null);
  const { intl, config } = props;
  let PieChartData = props.data && props.data.length > 0 ? props.data : [];

  PieChartData = !props.order
    ? PieChartData
    : props.order
        .filter(o => PieChartData.find(d => d.name === o))
        .map(o => PieChartData.find(d => d.name === o));
  //eslint-disable-next-line array-callback-return
  PieChartData.map((object, index) => {
    object.fill = props.colorSchema
      ? props.colorSchema[index]
      : CHART_COLORS[index];
  });

  const onPieEnter = (_, index) => {
    setActivePieIndex(index);
  };

  const onPieLeave = (_, index) => {
    setActivePieIndex(null);
  };

  return (
    <>
      {!props.isLoading && PieChartData.length < 1 ? (
        <InsufficientData />
      ) : (
        <Wrapper wrapperRef={wrapperRef}>
          <ResponsiveContainer>
            {(props.isLoading && PieChartData.length === 0) ||
            (props.fetchApi == false && PieChartData.length === 0) ? ( //eslint-disable-line eqeqeq
              loadingState()
            ) : (
              <PieChart>
                <Pie
                  activeIndex={activePieIndex}
                  activeShape={activeShapeProps => (
                    <RenderActiveShape
                      {...activeShapeProps}
                      intl={intl}
                      config={config}
                    />
                  )}
                  data={PieChartData}
                  cy={150}
                  innerRadius={70}
                  outerRadius={130}
                  dataKey="quantity"
                  onMouseEnter={onPieEnter}
                  onMouseLeave={onPieLeave}
                  blendStroke
                />
                <Legend
                  content={
                    <CustomizedLegend
                      data={PieChartData}
                      config={props.config}
                      colors={props.colorSchema || CHART_COLORS}
                      order={props.order}
                    />
                  }
                />
              </PieChart>
            )}
          </ResponsiveContainer>
        </Wrapper>
      )}
    </>
  );
};

Donut.propTypes = {
  intl: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colorSchema: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.shape(),
  order: PropTypes.arrayOf(PropTypes.string)
};

Donut.defaultProps = {
  colorSchema: null,
  config: null,
  order: null
};

const makeMapState = (outerState, { ccGraph: { config: outerConfig } }) => {
  const getData = sGraph.makeGetRCDonutData(
    outerConfig.ccGraphId,
    outerConfig.graphType
  );

  return (state, { ccGraph: { config } }) => ({
    isLoading: sGraph.isLoading(config.ccGraphId)(state),
    data: getData(state)
  });
};

export default compose(injectIntl, withCCGraph, connect(makeMapState))(Donut);
