import cloneDeep from "lodash/cloneDeep";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Chip from "components/common/chip";
import FormattedMessage from "../formatted-message";
import {
  updateCurrentSavedSearchName,
  showHideSaveSearchNClearAll,
  clearAllSearchTags,
  updateSearchText,
  flushSelectedSavedSearch,
  updateSelectedFilterOptions
} from "../../../state/actions/actions";
import * as constant from "../../../utils/appConstants";
import {
  makeUserSearchDataState,
  makeSystemDefinedSearchState,
  makeSelectedSavedSearchNameState,
  makeSelectedSavedSearchIdState,
  makeSuccessSaveSearchState
} from "../../../state/selectors/CommonSelectors";
import {
  is_deleted,
  isDeletedApiConvert
} from "../advanced-search-mui/AdvanceSearchDataHelper";
import {
  VSB_FILTER_ATTRIBUTES,
  VSB_IDENTIFIERS
} from "components/protect/recoveredResources/virtualStandby/virtualStandbyConstants";

const SavedSearches = ({
  updateCurrentSavedSearchName,
  successSaveSearch,
  clearAllSearchTags,
  flushSelectedSavedSearch,
  selectedSavedSearchId,
  updateSearchText,
  options,
  updateSelectedFilterOptions,
  handleAdvancedSearchClick,
  showHideSaveSearchNClearAll,
  secondaryNavigation,
  selectedSavedSearchName,
  showUserDefinedSearch,
  ...props
}) => {
  useEffect(() => {
    let userDefinedSearchData =
      props.userDefinedSearchData && props.userDefinedSearchData.data
        ? props.userDefinedSearchData.data
        : [];
    let markDefaultData = [];
    if (userDefinedSearchData.length > 0) {
      userDefinedSearchData.forEach(obj => {
        if (obj.is_default === true) {
          markDefaultData.push(obj);
        }
      });
    }

    if (markDefaultData.length > 0) {
      dispatchDefaultSearchAction(markDefaultData[0], "");
      updateCurrentSavedSearchName(
        markDefaultData[0].filter_name,
        markDefaultData[0].filter_id
      );
    } else {
      if (successSaveSearch) {
        // clearAllSearchTags();
        flushSelectedSavedSearch();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * saved search filter click handler
   */
  const dispatchDefaultSearchAction = (savedSearch, fromType) => {
    const filter = props.filterType;
    let searchText = "";
    let advancedSearchOptions = {};
    let deselect = false;
    if (
      savedSearch.filter_id === selectedSavedSearchId &&
      fromType === "click"
    ) {
      clearAllSearchTags();
      updateCurrentSavedSearchName("", ""); //  user deselects a pre selected option
      updateSearchText(""); //  user deselects a pre selected option
      advancedSearchOptions = cloneDeep(options);
      advancedSearchOptions.forEach(item => {
        // Otherwise, this is the one we want - return an updated value
        item.selectedOptions = [];
      });
      updateSelectedFilterOptions([]);
      deselect = true;
    } else {
      updateCurrentSavedSearchName(
        savedSearch.filter_name,
        savedSearch.filter_id
      );
      advancedSearchOptions = generateOptions(savedSearch);
      searchText = savedSearch[constant.SEARCH_TEXT_MAPPING[filter]];
      updateSelectedFilterOptions(advancedSearchOptions);
    }
    const criteria = {
      advancedSearchOptions,
      searchText,
      filter,
      doNotUpdateOptions: true,
      deselect: deselect,
      savedsearch: true
    };
    handleAdvancedSearchClick(criteria);
    showHideSaveSearchNClearAll(true);
  };

  /**
   * generate the dropdown options in the required format
   */
  const generateOptions = savedSearch => {
    //  flushSelectedSavedSearch();
    //  clearAllSearchTags();
    let searchOptions = cloneDeep(options);
    if (Array.isArray(searchOptions)) {
      searchOptions.forEach(option => {
        let key = option.key;
        let type = option.type;
        if (savedSearch.hasOwnProperty(key)) {
          switch (type) {
            case "date-picker":
            case "date-range-picker":
              option.selectedOptions = Object.assign({}, savedSearch[key]);
              break;
            case constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE:
              //eslint-disable-next-line no-lone-blocks
              {
                option.selectedOptions = [];
                if (savedSearch[type] && !Array.isArray(savedSearch[type])) {
                  let selectedOption = savedSearch[type].toString();
                  const selectedOpt = option.options.filter(opt => {
                    let tempValue = opt.id ? opt.id : opt.value;
                    return tempValue === selectedOption;
                  });
                  option.selectedOptions.push(
                    selectedOpt && selectedOpt.length > 0 ? selectedOpt[0] : []
                  );
                } else {
                  savedSearch[type] &&
                    savedSearch[type].forEach(selectedOption => {
                      const selectedOpt = option.options.filter(opt => {
                        let tempValue = opt.id ? opt.id : opt.value;
                        return tempValue === selectedOption.toString();
                      });
                      option.selectedOptions.push(
                        selectedOpt && selectedOpt.length > 0
                          ? selectedOpt[0]
                          : []
                      );
                    });
                }
              }
              break;
            default: {
              option.selectedOptions = [];
              if (
                (savedSearch[key] && !Array.isArray(savedSearch[key])) ||
                typeof savedSearch[key] === "boolean"
              ) {
                let selectedOption = savedSearch[key].toString();
                const selectedOpt = option.options.filter(opt => {
                  let tempValue = opt.id ? opt.id : opt.value;
                  return tempValue === selectedOption;
                });
                option.selectedOptions.push(
                  selectedOpt && selectedOpt.length > 0 ? selectedOpt[0] : []
                );
              } else {
                if (option.key === is_deleted.key) {
                  option.selectedOptions = isDeletedApiConvert(
                    savedSearch.is_deleted
                  );
                } else {
                  //TODO: root cause is UserSearchData has some destination_id as array and some as array of objects
                  const isArrayOfStrings =
                    Array.isArray(savedSearch[key]) &&
                    savedSearch[key].every(ele => typeof ele === "string");
                  if (!isArrayOfStrings) {
                    option.selectedOptions = savedSearch[key].filter(
                      ele => !Array.isArray(ele)
                    );
                  } else {
                    savedSearch[key] &&
                      savedSearch[key].forEach(selectedOption => {
                        const selectedOpt = option.options.filter(opt => {
                          // Checking source state filter and remove VSB identifier to send BE supported source state filters.
                          // To fix bug 1039958
                          if (
                            key === VSB_FILTER_ATTRIBUTES.SOURCE_STATE &&
                            opt.value.includes(VSB_IDENTIFIERS[key])
                          ) {
                            opt.value = opt.value.replace(
                              VSB_IDENTIFIERS[option.key],
                              ""
                            );
                          }
                          let tempValue = opt.id ? opt.id : opt.value;
                          return tempValue === selectedOption.toString();
                        });
                        option.selectedOptions.push(
                          selectedOpt && selectedOpt.length > 0
                            ? selectedOpt[0]
                            : []
                        );
                      });
                  }
                }
              }
              break;
            }
          }
        }
      });
      return searchOptions;
    }
  };

  /**  sort the saved searches by ascending order on create_ts
   */
  const sortDataByCreatedTime = userDefinedSearchData => {
    if (userDefinedSearchData) {
      userDefinedSearchData.sort(function(a, b) {
        if (a.create_ts < b.create_ts) {
          return -1;
        } else if (a.create_ts > b.create_ts) {
          return 1;
        }
        return 0;
      });
    }
  };

  let userDefinedSearchData = props.userDefinedSearchData || [];
  sortDataByCreatedTime(userDefinedSearchData);
  let systemDefinedSearchData = props.systemDefinedSearchData || [];
  if (
    secondaryNavigation === constant.SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS
  ) {
    systemDefinedSearchData = [];
  }
  sortDataByCreatedTime(systemDefinedSearchData);

  return (
    !(
      userDefinedSearchData.length === 0 && systemDefinedSearchData.length === 0
    ) && (
      <div className="quick-filter-wrapper d-flex">
        <label htmlFor="title" className="title">
          <FormattedMessage
            id="protect.sources.savedSearches"
            defaultMessage="Saved Searches:"
          />
        </label>

        <div className="d-flex filter-value-wrapper flex-wrap">
          {systemDefinedSearchData.map((obj, i) => {
            let keyIndex = i;
            return (
              <Chip
                key={keyIndex}
                size="small"
                label={obj.filter_name}
                color={
                  selectedSavedSearchName === obj.filter_name
                    ? "primary"
                    : "default"
                }
                className={`saved-searches ${
                  selectedSavedSearchName === obj.filter_name
                    ? "selectedFilterLabel"
                    : ""
                }`}
                onClick={() => dispatchDefaultSearchAction(obj, "click")}
              />
            );
          })}
          {showUserDefinedSearch &&
            userDefinedSearchData.map((obj, i) => {
              let keyIndex = i;
              return (
                <Chip
                  key={keyIndex}
                  size="small"
                  label={obj.filter_name}
                  color={
                    selectedSavedSearchName === obj.filter_name
                      ? "primary"
                      : "default"
                  }
                  className={`saved-searches ${
                    selectedSavedSearchName === obj.filter_name
                      ? "selectedFilterLabel"
                      : ""
                  }`}
                  onClick={() => dispatchDefaultSearchAction(obj, "click")}
                />
              );
            })}
        </div>
      </div>
    )
  );
};

SavedSearches.defaultProps = {
  userDefinedSearchData: [],
  systemDefinedSearchData: [],
  showUserDefinedSearch: true,
  successSaveSearch: false,
  filterType: "",
  options: []
};

SavedSearches.propTypes = {
  userDefinedSearchData: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clearAllSearchTags: PropTypes.func.isRequired,
  showHideSaveSearchNClearAll: PropTypes.func.isRequired,
  updateCurrentSavedSearchName: PropTypes.func.isRequired,
  handleAdvancedSearchClick: PropTypes.func,
  filterType: PropTypes.string.isRequired,
  updateSearchText: PropTypes.func.isRequired,
  flushSelectedSavedSearch: PropTypes.func.isRequired,
  successSaveSearch: PropTypes.bool.isRequired,
  systemDefinedSearchData: PropTypes.arrayOf(PropTypes.shape({})),
  showUserDefinedSearch: PropTypes.bool,
  updateSelectedFilterOptions: PropTypes.func.isRequired,
  selectedSavedSearchName: PropTypes.string.isRequired,
  selectedSavedSearchId: PropTypes.string.isRequired
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getUserSearchDataState = makeUserSearchDataState();
  const getSystemDefinedSearchState = makeSystemDefinedSearchState();
  const getSelectedSavedSearchNameState = makeSelectedSavedSearchNameState();
  const getSelectedSavedSearchIdState = makeSelectedSavedSearchIdState();
  const getSuccessSaveSearchState = makeSuccessSaveSearchState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      userDefinedSearchData: getUserSearchDataState(state, props),
      systemDefinedSearchData: getSystemDefinedSearchState(state, props),
      selectedSavedSearchName: getSelectedSavedSearchNameState(state, props),
      selectedSavedSearchId: getSelectedSavedSearchIdState(state, props),
      successSaveSearch: getSuccessSaveSearchState(state, props),
      userSearchData: state.reducer.userSearchData,
      secondaryNavigation: state.reducer.secondaryNavigation
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearAllSearchTags,
      showHideSaveSearchNClearAll,
      updateCurrentSavedSearchName,
      updateSearchText,
      flushSelectedSavedSearch,
      updateSelectedFilterOptions
    },
    dispatch
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(SavedSearches);
