import * as C from "state/constants";

const initialState = {
  isTypesLoading: true,
  types: [],
  isReportLoading: true,
  report: [],
  isRecipientsLoading: true,
  recipients: []
};

const biReporting = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_BI_REPORTS_TYPES_REQUEST:
      return Object.assign({}, state, {
        isTypesLoading: true
      });
    case C.FETCH_BI_REPORTS_TYPES_SUCCESS:
      return Object.assign({}, state, {
        isTypesLoading: false,
        types: action.payload.data
      });
    case C.FETCH_BI_REPORTS_TYPES_FAILURE:
      return Object.assign({}, state, {
        isTypesLoading: false
      });
    case C.FETCH_BI_REPORT_REQUEST:
      return Object.assign({}, state, {
        isReportLoading: true
      });
    case C.FETCH_BI_REPORT_SUCCESS:
      return Object.assign({}, state, {
        isReportLoading: false,
        report: action.payload.data
      });
    case C.FETCH_BI_REPORT_FAILURE:
      return Object.assign({}, state, {
        isReportLoading: false
      });
    case C.FETCH_BI_REPORT_RECIPIENTS_REQUEST:
      return Object.assign({}, state, {
        isRecipientsLoading: true,
        recipient: []
      });
    case C.FETCH_BI_REPORT_RECIPIENTS_SUCCESS:
      return Object.assign({}, state, {
        isRecipientsLoading: false,
        recipients: action.payload.recipient || []
      });
    case C.FETCH_BI_REPORT_RECIPIENTS_FAILURE:
      return Object.assign({}, state, {
        isRecipientsLoading: false
      });
    default:
      return state;
  }
};

export default biReporting;
