/* eslint-disable camelcase */
/*
 *file for api url constants
 *
 */
export const HOST_URL = `${process.env.REACT_APP_URL}/`;
export const HOST_BE_URL = `${process.env.REACT_APP_BE_URL}/`;
export const FETCH_COLUMNS = `${process.env.REACT_APP_BE_URL}/columns`;
export const FETCH_SOURCES = `${process.env.REACT_APP_BE_URL}/sources`;
export const SEARCH_SOURCES = `${process.env.REACT_APP_BE_URL}/sources/search`;
export const FETCH_V2_SOURCES = `${process.env.REACT_APP_BE_URL}/v2/sources`;
export const FETCH_POLICY_SOURCE_DR_CD = `${process.env.REACT_APP_BE_URL}/organizations`;
export const FETCH_LICENSES = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/cloud/licenses?org_id=`;
export const FETCH_SOURCES_COUNTS = `${process.env.REACT_APP_BE_URL}/sources/types`;
export const FETCH_DESTINATIONS_COUNTS = `${process.env.REACT_APP_BE_URL}/destinations/types`;
export const FETCH_POLICIES_COUNTS = `${process.env.REACT_APP_API_URL}/protection_policies_counts`;
export const LOGOUT = `${process.env.REACT_APP_URL}/logout`;

export const OCTA_LOGOUT = `${process.env.REACT_APP_OCTA_BE_URL}/user/logout`;
export const OCTA_LOGIN_REDIRECT = `${process.env.REACT_APP_OCTA_BE_URL}/user/oktalogin`;
export const CC_HOST_URL = `${process.env.REACT_APP_CC_URL}`;
export const OS_HOST_URL = `${process.env.REACT_APP_OS_URL}`;
export const UCC_HOST_URL = `${process.env.REACT_APP_UCC_URL}/`;
export const UCC_BE_URL = `${process.env.REACT_APP_UCC_BE_URL}`;
export const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_CHANGE_PASSWORD_URL}`;
export const CLEAN_STALE_JOBS = `${process.env.REACT_APP_BE_URL}/organizations/{id}/jobs/clean`;

/**
 * get Recovered VMS count
 */
export const FETCH_RECOVERED_VMS_COUNTS = `${process.env.REACT_APP_BE_URL}/recoveredresources/types`;

export const FETCH_INFRASTRUCTURE_COUNTS = `${process.env.REACT_APP_API_URL}/infrastructure_counts`;
export const FETCH_COLUMN_DATA = `${process.env.REACT_APP_API_URL}/column_data`;
export const FETCH_DESTINATIONS = `${process.env.REACT_APP_BE_URL}/destinations`;
export const FETCH_DEFAULTSEARCHES = `${process.env.REACT_APP_BE_URL}/users`;
export const FETCH_FILTERS = `${process.env.REACT_APP_BE_URL}/filters`;
export const FETCH_FILTERS_V2 = `${process.env.REACT_APP_BE_URL}/v2/filters`;
export const FETCH_SAVEDSEARCHES = `${process.env.REACT_APP_API_URL}/savedsearches`;
export const FETCH_POLICIES = `${process.env.REACT_APP_BE_URL}/policies`;
export const FETCH_DISCOVERIES = `${process.env.REACT_APP_BE_URL}/discoveries`;
export const FETCH_ADVANCE_SEARCH = `${process.env.REACT_APP_API_URL}/advancedSearch`;

export const FETCH_INFRASTRUCTURE = `${process.env.REACT_APP_API_URL}/infrastructure`;
export const FETCH_INFRASTRUCTURE_ARCSERVECLOUD_VOLUME = `${process.env.REACT_APP_API_URL}/volumes`;
export const FETCH_BASIC_DETAILS = `${process.env.REACT_APP_BE_URL}/policies/types`;
export const FETCH_POLICY_DETAILS = `${process.env.REACT_APP_API_URL}/policyDetails`;
export const FETCH_RETENTION_DROPDOWNS = `${process.env.REACT_APP_API_URL}/editDropdowns`;
export const FETCH_RECOVERY_POINTS = `${process.env.REACT_APP_API_URL}/recoveryPoints`;
export const FETCH_RECOVERY_POINTS_SOURCES = `${process.env.REACT_APP_API_URL}/recoveryPointsSources`;
export const FETCH_MOUNTPOINT_INFO = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/recoverypoints/mountpoint`;
export const FETCH_VIRTUAL_MACHINES = `${process.env.REACT_APP_API_URL}/virtualMachines`;
export const FETCH_JOBS_DETAILS = `${process.env.REACT_APP_API_URL}/jobDetails`;
export const FETCH_JOBS = `${process.env.REACT_APP_BE_URL}/v2/jobs`;
export const FETCH_v1_JOBS = `${process.env.REACT_APP_BE_URL}/jobs`;
export const DELETE_v1_JOBS = `${process.env.REACT_APP_BE_URL}/jobs`;
export const FETCH_LOG = `${process.env.REACT_APP_BE_URL}/v2/logs`;
export const FETCH_ANALYZE_COUNTS = `${process.env.REACT_APP_API_URL}/analyze_counts`;
export const FETCH_HYPERVISOR_LIST = `${process.env.REACT_APP_BE_URL}/hypervisors`;
export const FETCH_STORAGE_ARRAY = `${process.env.REACT_APP_BE_URL}/storagearrays`;
export const FETCH_HYPERVISORS_COUNTS = `${process.env.REACT_APP_BE_URL}/hypervisors/types`;
export const FETCH_VMS_LIST = `${process.env.REACT_APP_BE_URL}/vms`;
export const ADD_NEW_SOURCE = `${process.env.REACT_APP_BE_URL}/sources`;
export const UPDATE_SOURCE_CONFIGURATION = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/recoveredresources`;
export const USER_DETAILS = `${process.env.REACT_APP_BE_URL}/user`;
export const OCTA_USER_DETAILS = `${process.env.REACT_APP_BE_URL}/user/profile`;

export const TRIGGER_LOG_OUT = `${process.env.REACT_APP_BE_URL}/user/logout`;
export const CHANGE_PASSWORD = `${process.env.REACT_APP_BE_URL}/user/changepassword`;
export const FORGOT_PASSWORD = `${process.env.REACT_APP_BE_URL}/user/resetpassword`;
export const UPDATE_PHOTO = `${process.env.REACT_APP_BE_URL}/users`;
export const FETCH_SOURCES_GROUPS = `${process.env.REACT_APP_BE_URL}/sources/groups`;
export const FETCH_POLICIES_BE = `${process.env.REACT_APP_BE_URL}/policies`;
export const FETCH_ALL_ADVANCED_SEARCH_API = `${process.env.REACT_APP_BE_URL}/sources/search/filters`;
export const FETCH_LIGHTWEIGHT_POLICIES = `${process.env.REACT_APP_BE_URL}/policies/lightweight`;
export const FETCH_LIGHTWEIGHT_SOURCES = `${process.env.REACT_APP_BE_URL}/sources/lightweight`;
export const UPDATE_SITE_DETAILS = `${process.env.REACT_APP_BE_URL}/sites`;
export const DELETE_SITE = `${process.env.REACT_APP_BE_URL}/sites`;
export const FETCH_USERS = `${process.env.REACT_APP_BE_URL}/users`;
export const FETCH_USERS_V2 = `${process.env.REACT_APP_BE_URL}/v2/users`;
export const FETCH_USER_ROLES = `${process.env.REACT_APP_BE_URL}/userroles`;
export const FETCH_DATA_CENTER = `${process.env.REACT_APP_BE_URL}/destinations/datacenters`;
export const FETCH_CUSTOMER_ACCOUNTS_BE = `${process.env.REACT_APP_API_URL}/customer_accounts`;
export const FETCH_RECOVERED_RESOURCES = `${process.env.REACT_APP_API_URL}/recoveredResources`;
export const FETCH_LICENCE_DETAIL = `${process.env.REACT_APP_BE_URL}/organizations`;

export const FETCH_TRANSACTION_DETAILS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/sqldbtransactions`;

export const FETCH_DESTINATION_MOCK = `${process.env.REACT_APP_API_URL}/destinationMock`;

export const FETCH_PROTECT_CUSTOMER_LIST = `${process.env.REACT_APP_API_URL}/protectCustomerAccounts`;
export const FETCH_USER_ROLE_DETAILS = `${process.env.REACT_APP_API_URL}/roleDetails`;
export const FETCH_ADMIN_DETAILS_TO_ASSIGN = `${process.env.REACT_APP_API_URL}/assigned_admins`;
export const FETCH_BY_ORGANIZATIONS = `${process.env.REACT_APP_BE_URL}/organizations`;
export const FETCH_BY_ORGANIZATIONS_V2 = `${process.env.REACT_APP_BE_URL}/v2/organizations`;

// To be removed once APIs are available
export const FETCH_REPORTS_RESTORE_JOB = `${process.env.REACT_APP_BE_URL}/restorejobreports/details`;
export const FETCH_REPORTS_DATA_TRANSFER = `${process.env.REACT_APP_BE_URL}/datatransferreport/details`;
export const FETCH_REPORTS_DATA_TRANSFER_SUMMARY = `${process.env.REACT_APP_BE_URL}/datatransferreport/summary`;
export const FETCH_REPORTS_DATA_TRANSFER_TOP_SOURCE = `${process.env.REACT_APP_BE_URL}/dashboards/topsources`;
export const FETCH_REPORT_DETAILS = `${process.env.REACT_APP_API_URL}/report_details`;
export const CREATE_JOB_REPORT = `${process.env.REACT_APP_BE_URL}/reportschedules`;
export const FETCH_CAPACITY_USAGE_TREND = `${process.env.REACT_APP_BE_URL}/capacityusage/cloudhybrid/dedupesavings`;
export const FETCH_LOGO_IMAGE_URL = `${process.env.REACT_APP_API_URL}/logo_img_url`;

export const FETCH_CLOUDVOLUME_RECOVERYPOINTS = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/recoverypoints`;
// for cloudconsole cloud volume api
// To be uncommented once APIs are available for reports
export const FETCH_REPORTS = `${process.env.REACT_APP_BE_URL}/reports`;
export const FETCH_SCHEDULE_REPORTS = `${process.env.REACT_APP_BE_URL}/reportschedules`;
export const FETCH_REPORTS_BACKUP_JOB = `${process.env.REACT_APP_BE_URL}/v2/backupjobreports/details`;
export const FETCH_REPORTS_POLICY_TASKS = `${process.env.REACT_APP_BE_URL}/policytaskreports/details`;
// export const FETCH_REPORTS_RESTORE_JOB = `${process.env.REACT_APP_BE_URL}/reports`;
// export const FETCH_REPORTS_DATA_TRANSFER = `${process.env.REACT_APP_BE_URL}/reports`;

// Jobs and destination URL
export const FETCH_REPORTS_JOBS_AND_DESTINATIONS = `${process.env.REACT_APP_BE_URL}/jobsdestinationreports/details`;
export const FETCH_JOBS_AND_DESTINATIONS_JOBS_SUMMARY = `${process.env.REACT_APP_BE_URL}/jobsdestinationreports/summary`;
export const FETCH_JOBS_AND_DESTINATION_TREND_TOP_SOURCE = `${process.env.REACT_APP_BE_URL}/jobsdestinationreports/topsources`;
export const FETCH_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION = `${process.env.REACT_APP_BE_URL}/jobsdestinationreports/topdestinations`;

// Capacity Usage grid and charts
export const FETCH_REPORTS_CAPACITY_USAGE = `${process.env.REACT_APP_BE_URL}/capacityusage/report/details`;
export const FETCH_CAPACITY_USAGE_CD_TREND_USAGE = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/usage`;
export const FETCH_MSP_CAPACITY_USAGE_CD_TREND_USAGE = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/accounts/usage`;

// Recovery Point report
export const FETCH_RECOVERY_POINT_REPORT = `${process.env.REACT_APP_BE_URL}/recoverypoints/source/report`;

export const FETCH_REPORT_CAPACITY_USAGE_CD_TREND_USAGE = `${process.env.REACT_APP_BE_URL}/cloudconsole/usage`;
export const FETCH_REPORT_MSP_CAPACITY_USAGE_CD_TREND_USAGE = `${process.env.REACT_APP_BE_URL}/cloudconsole/accounts/usage`;

export const FETCH_CAPACITY_USAGE_CH_TREND_USAGE = `${process.env.REACT_APP_BE_URL}/capacityusage/cloudhybrid/usage`;
export const FETCH_CAPACITY_USAGE_CH_STORE_SAVING_TREND = `${process.env.REACT_APP_BE_URL}/capacityusage/cloudhybrid/dedupesavings`;

export const BE_HOST_URL = `${process.env.REACT_APP_BE_URL}`;

export const FETCH_FILE_BROWSER = `${process.env.REACT_APP_API_URL}/file_browser`;
// api url for restore cookie auth action
export const RESTORE_COOKIEAUTH = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}/restapi/restore/cookieauth`;
/**
 * fetch recovered vms
 */
export const FETCH_RECOVERED_VMS = `${process.env.REACT_APP_BE_URL}/recoveredresources`;
export const FETCH_RECOVERED_VMS_USING_SOURCE_ID = `${process.env.REACT_APP_BE_URL}/sources/{id}/recoveredresource`;
export const FETCH_MOUNTED_RECOVERY_POINTS = `${process.env.REACT_APP_BE_URL}/recovery/mountedvolumes/all`;
export const UNMOUNT_RECOVERY_POINTS = `${process.env.REACT_APP_BE_URL}/recovery/recoverypoint/dismount`;
// export const FETCH_WIDGETS = `${process.env.REACT_APP_API_URL}/monitor`;

export const FETCH_SOURCE_GROUPS_COUNTS = `${process.env.REACT_APP_BE_URL}/sources/groups/amount`;

export const FETCH_USER_COUNTS = `${process.env.REACT_APP_BE_URL}/users/amount`;

export const FETCH_ROLE_COUNTS = `${process.env.REACT_APP_BE_URL}/userroles/amount`;

export const FETCH_REPORTS_MOCK = `${process.env.REACT_APP_API_URL}/reportDetails`;
export const FETCH_BACKUP_JOBS_TOP_SOURCES = `${process.env.REACT_APP_BE_URL}/backupjobreports/topsources`;
export const FETCH_SOURCE_PROTECTION_REPORT = `${process.env.REACT_APP_BE_URL}/sources/protection/report`;
export const FETCH_NETWORK_PORT_FORWARD_LIST = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/clouddirectdraas/cloud`;
export const FETCH_NETWORK_CONFIG_LIST = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/clouddirectdraas/portforwarding`;
export const UPDATE_NETWORK_CONFIG_LIST = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/clouddirectdraas/portforwarding`;
export const DOWNLOAD_SESSION_LOG = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/cloudvpn/sessionlog`;

export const FETCH_DESTINATION_FOR_CHART = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}cloudconsole/usage`;

export const FETCH_DASHBOARD_SOURCE_SUMMARY = `${process.env.REACT_APP_BE_URL}/dashboards/sourcesummary`;
export const FETCH_DASHBOARD_SOURCE_PROTECTION_SUMMARY = `${process.env.REACT_APP_BE_URL}/dashboards/sourceprotectionsummary`;

export const FETCH_DASHBOARD_POLICY_SUMMARY = `${process.env.REACT_APP_BE_URL}/dashboards/policysummary`;
export const FETCH_RECOVERY_POINT_SERVERS = `${process.env.REACT_APP_BE_URL}/recoverypointservers`;
export const FETCH_RESTORE_JOBS_TOP_SOURCES = `${process.env.REACT_APP_BE_URL}/restorejobreports/topsources`;
export const FETCH_RESTORE_JOBS_TREND_SUMMARY = `${process.env.REACT_APP_BE_URL}/restorejobreports/jobstatussummary`;
export const UPDATE_ASSINGN_POLICY = `${process.env.REACT_APP_BE_URL}/policies`;
export const FETCH_DASHBOARD_BACKUP_JOBS_SUMMARY = `${process.env.REACT_APP_BE_URL}/dashboards/backupjobstatussummary`;
export const FETCH_DASHBOARD_BACKUP_JOBS_SUMMARY_V2 = `${process.env.REACT_APP_BE_URL}/v2/dashboards/backupjobstatussummary`;
export const FETCH_DASHBOARD_TOP_SOURCES = `${process.env.REACT_APP_BE_URL}/dashboards/topsources`;
export const FETCH_DASHBOARD_TOP_CUSTOMERS = `${process.env.REACT_APP_BE_URL}/dashboards/topcustomers`;
export const FETCH_DASHBOARD_TOP_POLICIES = `${process.env.REACT_APP_BE_URL}/dashboards/toppolicies`;
export const FETCH_DASHBOARD_CAPACITY_USAGE = `${process.env.REACT_APP_BE_URL}/capacityusage/cloudhybrid/usage`;
export const FETCH_REPORT_SCHEDULE = `${process.env.REACT_APP_BE_URL}/reportschedules`;
export const POST_ANALYZE_ALERT = `${process.env.REACT_APP_BE_URL}/alerts/recipients`;

export const FETCH_ANALYZE_ALERT = `${process.env.REACT_APP_BE_URL}/alerts/recipients`;

export const FETCH_REPORT_DATA = `${process.env.REACT_APP_BE_URL}/reportdata`;
export const FETCH_REPORT_DATA_SINGLE = `${process.env.REACT_APP_BE_URL}/reportschedules`;
export const BRANDING_PORTAL_URL = `${process.env.REACT_APP_BE_URL}/organizations/branding`;
export const FETCH_BY_ORGANIZATION = `${process.env.REACT_APP_BE_URL}/organization`;
export const FETCH_BY_ORGANIZATION_ID = `${process.env.REACT_APP_BE_URL}/organizations`;
export const FETCH_GENERATED_FROM = `${process.env.REACT_APP_BE_URL}/logs/generatedfroms`;
export const REFRESH_TOKEN_URL = `${process.env.REACT_APP_BE_URL}/user/refreshtoken`;

export const OCTA_REFRESH_TOKEN_URL = `${process.env.REACT_APP_BE_URL}/user/accesstoken/refresh`;

export const FETCH_POLICY = `${process.env.REACT_APP_BE_URL}/policies`;
export const GET_POLICY_BY_ID = `${process.env.REACT_APP_BE_URL}/policies/{id}`;
export const GET_SOURCE_BY_ID = `${process.env.REACT_APP_BE_URL}/sources/{id}/artasks`;
export const GET_SOURCE_ASTASKS_BY_ID = `${process.env.REACT_APP_BE_URL}/sources/{id}/assuredsecurity/tasks`;
export const FETCH_HYPVERVISOR = `${process.env.REACT_APP_BE_URL}/hypervisors`;
export const FETCH_AUDIT_LOGS = `${process.env.REACT_APP_BE_URL}/audittrail`;
export const FFTCH_ORGANIZATION_VPN_DETAILS = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/cloudvpn`;

export const FETCH_ORGANIZATIONS_DETAILS = `${process.env.REACT_APP_BE_URL}/organizations`;

export const POST_REMOTE_POLICIES = `${process.env.REACT_APP_BE_URL}/policies/remotepolicies `;

export const FETCH_REMOTE_POLICIES = `${process.env.REACT_APP_BE_URL}/policies/remotepolicies`;

export const FETCH_TERMS_OF_SERVICE_URL = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/tnc/latest?org_type=0`;

export const FETCH_USER_LANGUAGE = `${HOST_BE_URL}user/language `;

export const O365_POPUP_HOST_URL = `http://demo2020047.mockable.io/sources`;
export const O365_POPUP_CONNECT_URL = `/office365/connect`;
export const O365_ADD_SOURCE_URL = `/office365`;
export const MOCK_HOST_URL = `http://demo2020047.mockable.io/sources`;
export const LINUX_CONNECT_URL = `sources/linux/connect`;
export const WINDOWS_CONNECT_URL = `sources/windows/connect`;
export const ORACLE_WINDOWS_CONNECT_URL = `sources/windows/oracle/connect`;
export const ORACLE_LINUX_CONNECT_URL = `sources/linux/oracle/connect`;
export const ORACLE_WINDOWS_DB_CONNECT_URL = `sources/oracle/connect`;
export const ORACLE_DB_OPTIONS_URL = `oracle/databases`;
export const ORACLE_VERSION = `oracle/version`;
export const ADD_REMOTE_CONSOLE_URL = `destinations/remoteconsoles`;
export const UPDATE_REMOTE_CONSOLE_URL = `${process.env.REACT_APP_BE_URL}/destinations/remoteconsoles`;
export const SOURCES_URL = `sourceslist`;
export const SOURCES_CONTAINER_URL = `sources/containers`;
export const SOURCES_CONTAINER_POLICIES_URL = `sources/policies`;
export const WINDOWS_ADD_SOURCE_URL = `/windows`;
export const LINUX_ADD_SOURCE_URL = `/linux`;
export const LINUX_BACKUP_CONNECT_URL = `/sources/linuxbackupserver/connect`;
export const LINUX_BACKUP_ADD_SOURCE_URL = `/sources/linuxbackupserver`;
export const DISCOVERIES_SOURCES_ADD_URL = `/discoveries`;
export const BROWSE_ACTIVE_DIRECTORIES_URL = `/browse`;
export const ADD_SOURCES_LIST = `/sourceslist`;
export const DISCOVER_SOURCES_ADD_URL = `/discover/add`;
export const DISCOVER_GET_SOURCES = `http://demo2020047.mockable.io/discover/browse`;
export const DISCOVER_SOURCES_VERIFY_CONNECT = `http://demo6172953.mockable.io/sources/discover/connect`;
export const DISCOVER_SOURCES_ADD_TO_SPOG = `http://demo6172953.mockable.io/sources/discover-sources`;
export const VMWARE_CONNECT_URI = "/hypervisors/vmware/connect";
export const VMWARE_CONNECT_URI_V2 = "/v2/hypervisors/vmware/connect";
export const VMWARE_CONNECT_URI_V3 = "/v3/hypervisors/vmware/connect";
export const VMWARE_URI = "/hypervisors/vmwares";
export const VMWARE_SERVER_TYPE = "hypervisors/vmware/servertype";
export const BROWSE_VMWARE_RESOURCE_POOL = `${process.env.REACT_APP_BE_URL}/hypervisors/vmware/resourcepools`;
export const BROWSE_VMWARE_RESOURCE_POOL_V2 = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/resourcepools`;
export const BROWSE_HYPERV = `${process.env.REACT_APP_BE_URL}/hypervisors/hyperv/browsefolders`;
export const BROWSE_HYPERV_V2 = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/browsefolders`;
export const UPDATE_AD_DETAILS = `${process.env.REACT_APP_BE_URL}/discoveries`;
export const CREATE_SITE = `sites/link`;
export const HYPERV_CONNECT_URI = "/hypervisors/hyperv/connect"; // Old API For Hyper-V connect.
export const HYPERV_CONNECT_URI_V2 = "/v2/hypervisors/hyperv/connect"; // New API For Hyper-V connect.
export const HYPERV_CONNECT_URI_V3 = "/v3/hypervisors/hyperv/connect"; // New API For Hyper-V connect.
export const FETCH_ALL_VSB_HYPERVISOR_LIST_V2 = "/v2/hypervisors/target"; // New API For Hyper-V target.
export const HYPERV_CONNECT_URI_V4 = "/v4/hypervisors/hyperv/connect"; // New API For Hyper-V connect.
// export const HYPERV_URI = "/hypervisors/hypervs";
export const HYPERV_URI = "/v2/hypervisors/hypervs";
export const NUTANIX_CONNECT_URI = "/hypervisors/nutanix/connect";
export const DOWNLOADS_URL = "agents/downloadinfo";
export const BROWSE_SHARED_FOLDERS_DESTINATIONS =
  "/destinations/sharefolders/browse";
export const CREATE_FILE_BROWSE_FOLDER =
  "/destinations/sharefolders/createfolder"; // CREATE FILE BROWSE FOLDER

export const CREATE_FILE_BROWSE_FOLDER_LINUX =
  "/destinations/sharefolders/linux/createfolder"; // CREATE FILE BROWSE FOLDER HYPERV

export const CREATE_FILE_BROWSE_FOLDER_HYPERV =
  "/hypervisors/hyperv/createfolder"; // CREATE FILE BROWSE FOLDER HYPERV

export const CREATE_FILE_BROWSE_FOLDER_HYPERV_V2 =
  "/v2/hypervisors/hyperv/createfolder"; // CREATE FILE BROWSE FOLDER HYPERV

/**
 * Destinations > RPS
 */
export const GET_RPS_LIST = `${process.env.REACT_APP_BE_URL}/recoverypointservers`;
export const DELETE_RPS_LIST = `${process.env.REACT_APP_BE_URL}/recoverypointservers`;
export const DELETE_RPS_LIST_V2 = `${process.env.REACT_APP_BE_URL}/v2/recoverypointservers`;
export const PUT_RPS = `${process.env.REACT_APP_BE_URL}/recoverypointservers`;
export const GET_RPS = `${process.env.REACT_APP_BE_URL}/recoverypointservers`;
export const GET_DATA_STORES = `${process.env.REACT_APP_BE_URL}/destinations/datastores`;
export const GET_SHARED_FOLDER = `${process.env.REACT_APP_BE_URL}/destinations/sharefolders`;
export const UPDATE_CLOUD_DATA_STORE = `${process.env.REACT_APP_BE_URL}/destinations/clouddatastores`;
export const IMPORT_DATA_STORE_STEP_ONE = `${process.env.REACT_APP_BE_URL}/destinations/datastores/importstep1`;
export const IMPORT_DATA_STORE_STEP_TWO = `${process.env.REACT_APP_BE_URL}/destinations/datastores/importstep2`;
export const IMPORT_CLOUD_DATA_STORE_STEP_ONE = `${process.env.REACT_APP_BE_URL}/destinations/clouddatastores/importstep1`;
export const IMPORT_CLOUD_DATA_STORE_STEP_TWO = `${process.env.REACT_APP_BE_URL}/destinations/clouddatastores/importstep2`;
export const DEPLOY_SINGLE_RPS = `${process.env.REACT_APP_BE_URL}/recoverypointservers/deployrps`;
export const DEPLOY_BULK_RPS = `${process.env.REACT_APP_BE_URL}/recoverypointservers/bulkdeployrps`;
export const CANCEL_SCHEDULED_DEPLOY_SINGLE_RPS = `${process.env.REACT_APP_BE_URL}/recoverypointservers/{id}/canceldeployrps`;
export const GET_POLICIES = `${process.env.REACT_APP_BE_URL}/policies`;

export const FETCH_SITES = `${process.env.REACT_APP_BE_URL}/sites`;
export const UPGRADE_SITE = `${process.env.REACT_APP_BE_URL}/sites/id/upgrade`;
export const FETCH_STORAGE_SITES = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/storagecraft/sites`;
export const FETCH_STORAGE_JOBS = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/storagecraft/jobs`;
export const FETCH_STORAGE_SOURCES = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/storagecraft/sources`;
export const FETCH_STORAGE_DESTINATIONS = `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}restapi/storagecraft/destinations`;
export const FETCH_ACTIVE_DIRECTORIES = `${process.env.REACT_APP_BE_URL}/discoveries`;
export const REMOTE_CONSOLE_LIST = "/destinations/remoteconsoles";
export const REMOTE_CONSOLE_DELETE = "/destinations/remoteconsoles";
export const RPS_DATASTORE_LIST = "/recoverypointservers/id/datastores";
export const RPS_HASHMEMORY = `${process.env.REACT_APP_BE_URL}/recoverypointservers/id/hashmemory`;
export const RPS_DATASTORE_SOURCE_LIST = "/destinations/datastores/id/sources";
export const SHARED_FOLDER_LIST = "/destinations/sharefolders";
export const FETCH_LIGHTWEIGHT_PROXIES = `${process.env.REACT_APP_BE_URL}/proxies/lightweight`;
export const AUTHENTICATE_SHARED_FOLDER =
  "/destinations/sharefolders/authenticate";
export const POLICY_LIST_JUMPSTART = "/destinations/datastores/id";
export const JUMP_START_SAVE = "/recoverypointservers/id/jumpstart";
export const CONNECT_SHARED_FOLDER_SOURCE =
  "/destinations/sharefolders/sources";
export const DATA_STORE_BASE = "/destinations/datastores";
export const DATA_STORE_START = "start";
export const DATA_STORE_STOP = "stop";
export const NETWORKS = "networks";
export const REGIONS = "regions";
export const GCP_SECURITY_GROUPS = "securitygroups";
export const GCP_COMPUTE_REGIONS = "regions";
export const BUCKETS = "buckets";
export const RESOURCE_GROUPS = "resourcegroups";
export const CLOUD_ACCOUNT_LIST = "/destinations/cloudaccounts";
export const GCP_CLOUD_ACCOUNT_LIST = "/destinations/cloudaccounts/gcp";
export const CLOUD_ACCOUNT_DELETE = "/destinations/cloudaccounts";
export const CLOUD_ACCOUNT_TEST_CONNECTION = "/connect/";
export const ADD_DATA_STORE = "destinations/datastores";
export const ADD_CLOUD_DATA_STORE = "destinations/clouddatastores";
export const SHARED_FOLDERS_AUTHENTICATE =
  "/destinations/sharefolders/authenticate";
export const END_POINTS_LIST = "/destinations/cloudaccounts/{id}/buckets";
export const END_POINTS_LIST_BY_REGION =
  "/v2/destinations/cloudaccounts/{id}/buckets";
export const ON_SESSION_PASSWORD =
  "/destinations/sharefolders/recoverypoints/validate";
export const FETCH_BACKUP_PROXY = `${process.env.REACT_APP_BE_URL}/proxies`;
export const CREATE_BACKUP_PROXY = `${process.env.REACT_APP_BE_URL}/proxies`;
export const CREATE_BACKUP_SOURCE_LINUX = `${process.env.REACT_APP_BE_URL}/sources/linux`;
export const CREATE_LINUX_BACKUP_PROXY = `${process.env.REACT_APP_BE_URL}/linuxbackupserversAdd`;
export const DELETE_PROXY = `${process.env.REACT_APP_BE_URL}/proxies`;
export const CREATE_LINUX_BACKUP_SERVER = `${process.env.REACT_APP_BE_URL}/sources/linuxbackupservers`;
export const GET_SELECTED_LBS_NETWORKS = `${process.env.REACT_APP_BE_URL}/sources/linuxbackupservers/{id}/networks`;
export const FETCH_LBS = `${process.env.REACT_APP_BE_URL}/sources?source_type=udp_linux_backup_server`;
export const FETCH_LINUX = `${process.env.REACT_APP_BE_URL}/sources?source_type=udp_linux`;
export const FETCH_LINUX_LBS = `${process.env.REACT_APP_BE_URL}/sources?source_type=udp_linux`;
export const PREFLIGHT_VMWARE_CHECK = `${process.env.REACT_APP_BE_URL}/hypervisors/vmware/validate`;
export const AVAILABLE_DRIVE_LETTERS = `${process.env.REACT_APP_BE_URL}/recovery/mountserver/availabledriveletters`;
export const GET_SOURCES = `${process.env.REACT_APP_BE_URL}/sources`;
export const MOUNT_RECOVERY_POINT = `${process.env.REACT_APP_BE_URL}/recovery/recoverypoint/mount`;
export const MOUNT_SQL_DATABASE = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/database/mount`;
/**
 * Protect > Destinations > Cloud Account
 */
export const CREATE_CLOUD_ACCOUNT_URL = `destinations/cloudaccounts`;
export const UPDATE_CLOUD_ACCOUNT_URL = `${process.env.REACT_APP_BE_URL}/destinations/cloudaccounts`;
export const BROWSE_RECOVERY_POINTS_DS_URL = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/sources`;
export const BROWSE_RECOVERY_POINTS_SF_URL = `${process.env.REACT_APP_BE_URL}/v2/destinations/sharefolders/{id}/sources`;
export const BROWSE_RECOVERY_POINTS_DS_REFERSH_URL = `${process.env.REACT_APP_BE_URL}/recoverypointservers/{id}/datastore/sources`;
export const BROWSE_RECOVERY_POINTS_DS_MERGE_NOW = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/sources/merge`;
export const BROWSE_RECOVERY_POINTS_DS_GET_RETENTION = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/sources/retention`;
export const BROWSE_RECOVERY_POINTS_DS_DELETE = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/sources`;
export const BROWSE_RECOVERY_POINTS_DS_PURGE = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/sources/purge`;
export const REFRESH_RECOVERY_POINTS_DS_URL = `${process.env.REACT_APP_BE_URL}/destinations/datastores/`;
export const REFRESH_RECOVERY_POINTS_SF_URL = `${process.env.REACT_APP_BE_URL}/v2/destinations/sharefolders/`;

/**
 * Configure > Settings > Default Deployment
 */
export const DEPLOYMENT_SETTINGS = `${process.env.REACT_APP_BE_URL}/configurations/deploysettings`;

/**
 * Configure > Settings > Source Discovery
 */
export const SOURCE_DISCOVERY = `${process.env.REACT_APP_BE_URL}/configurations/discoverysettings`;
export const AUTODISCOVERIES = `${process.env.REACT_APP_BE_URL}/autodiscoveries`;
export const AUTODISCOVERIES_V2 = `${process.env.REACT_APP_BE_URL}/v2/autodiscoveries`;

/**
 * Protect > Sources > Add UNC or NFS Path
 */
export const UNC_OR_NFS_CONNECT_URL = `sources/unc/connect`;
export const ADD_UNC_OR_NFS_URL = `sourceslist`;
export const CONFIGURATIONS = `configurations`;

export const INSTALL_UPDATE_UPGRADE_AGENT = `sources/id/deployudpagent`;
export const INSTALL_UPDATE_UPGRADE_AGENT_BULK = `v2/sources/upgradeagent`;
export const SHARE_POLICY_BY_ID = `shares/sharetoemail`;
export const SHARE_POLICY_TO_USERNAME_BY_ID = `shares/sharetousername`;
export const UN_SHARE_POLICY_BY_ID = `shares/unshareorganization`;

/**
 * Recovery API endpoints
 */
export const RECOVERY_AVAILABLE_VOLUMES = `${process.env.REACT_APP_BE_URL}/recovery/recoverypoint/availablevolumes`;
export const RECOVERY_MOUNTED_VOLUMES = `${process.env.REACT_APP_BE_URL}/recovery/mountserver/mountedvolumes`;
export const RECOVERY_MOUNTED_DATABASES = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/mounteddatabases `;
export const RECOVERY_DISMOUNT_VOLUME = `${process.env.REACT_APP_BE_URL}/recovery/recoverypoint/dismount`;
export const RECOVERY_DISMOUNT_DATABASE = `${process.env.REACT_APP_BE_URL}/recovery/recoverypoint/database/dismount`;
// Protect > sources > Restore > Recovery
export const GET_DESTINATIONS_BY_SOURCE_ID = `${process.env.REACT_APP_BE_URL}/recovery/source/{id}/recoverypointdestinations`;
export const GET_RECOVERY_POINT_ITEMS = `${process.env.REACT_APP_BE_URL}/recovery/source/recoverpoints`;
export const GET_RECOVERY_POINT_ITEMS_BY_SOURCE_ID = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoints`;
export const GET_RECOVERY_ORACLE_DATABASE_CONFIGURATIONS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/oracle/configurations`;

export const FETCH_ALL_DESTINATIONS = `${process.env.REACT_APP_BE_URL}/destinations/all`;

export const UPDATE_VMWARE_VCENTER_ESXHOST = `${process.env.REACT_APP_BE_URL}/hypervisors/vmware`;
export const UPDATE_HYPERV = `${process.env.REACT_APP_BE_URL}/hypervisors/hyperv`;
export const UPDATE_NUTANIX_AHV = `${process.env.REACT_APP_BE_URL}/hypervisors/nutanixs`;
export const GET_HYPERVISOR_CONFIG = `${process.env.REACT_APP_BE_URL}/hypervisors`;
export const GET_ESX_SERVERS = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/esxservers`;
export const UPDATE_PROXIES = `${process.env.REACT_APP_BE_URL}/proxies`;

// export const GET_HYPERV_CONFIGURATION = `${process.env.REACT_APP_BE_URL}/hypervisors/hyperv/configurations`;
export const GET_HYPERV_CONFIGURATION = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/configurations`;
// export const GET_VMWARE_CONFIGURATION = `${process.env.REACT_APP_BE_URL}/hypervisors/vmware/configurations`;
export const GET_VMWARE_CONFIGURATION = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/configurations`;
export const GET_NUTANIX_CONFIGURATION = `${process.env.REACT_APP_BE_URL}/hypervisors/nutanix/configurations`;
// Recovery APIs
export const START_RECOVERY = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/linux/restore`;
export const GET_SQL_INSTANCES = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/sqlinstances`;
export const LINUX_VALIDATE_TIME = `${process.env.REACT_APP_BE_URL}/recovery/sources/linux/validateschedule`;
export const LINUX_FETCH_SCRIPTS = `${process.env.REACT_APP_BE_URL}/recovery/sources/linux/scripts`;

export const GENERATE_DOWNLOAD_LINK = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/items/generatedownloadlink`;
export const FETCH_SOURCES_RECOVERYPOINTS_ITEMS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoints/items`;
export const FETCH_SOURCES_SQLDBDETAILS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/sqldbdetails`;
export const FETCH_SOURCES_RECOVERY_POINTS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoints`;
export const FETCH_SOURCES_RP_VOLUMES = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/volumes`;

export const RECOVER_RP_ITEMS_VALIDATE = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/items/validate`;
export const SQL_ITEMS_VALIDATE = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/sqlitems/validate`;
export const RECOVER_FILE_FOLDER_VALIDATE_VM = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/filefolder/validatevm`;
export const RECOVER_FILE_FOLDER_VALIDATE_LINUX_VM = `${process.env.REACT_APP_BE_URL}/recovery/sources/linux/connect`;
export const WINDOWS_START_RECOVERY = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/windows/restore`;
export const ORACLE_START_RECOVERY = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/oracle/restore`;
export const FETCH_DESTINATION_HYPERVISORS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/destination/hypervisor`;
export const LINUX_GENERATE_DOWNLOAD_LINK = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/items/generatedownloadlink`;
export const LINUX_FETCH_RECOVERYPOINTS_ITEMS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoints/items`;
export const LINUX_FETCH_RECOVERY_POINTS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoints`;
export const SINGLE_ACTION_ON_VM = `${process.env.REACT_APP_BE_URL}/recoveredresources/{id}/{action}`;
export const SINGLE_ACTION_DELETE_VM = `${process.env.REACT_APP_BE_URL}/recoveredresources/{id}`;
export const SINGLE_ACTION_PAUSE_RESUME_VM = `${process.env.REACT_APP_BE_URL}/recoveredresources/{id}/controlautorestoredata`;
export const BULK_ACTION_ON_VM = `${process.env.REACT_APP_BE_URL}/recoveredresources/{action}`;
export const RECOVERY_BACKUP_VM_DISKS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/backupvmdisks`;
export const VM_NETWORK_ADAPTERS = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/vmnetworkslist`;
export const NUTANIX_NETWORKS = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/nutanix/networks`;
export const NUTANIX_STORAGE = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/nutanix/storages`;
export const CONNECT_TO_HYPERV_VM_PATH = `${process.env.REACT_APP_BE_URL}/hypervisors/hyperv/{id}/defaultvmfolder`;
export const VALIDATE_ENCRYPTION_PASSWORD = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoints/verifypassword`;
export const COLLECT_DIAGNOSTIC_DATA = `${process.env.REACT_APP_BE_URL}/sources/collectdiagnosticslogs`;
export const BACKUP_PASSWORDS_DATA = `${process.env.REACT_APP_BE_URL}/sources/sessionpasswords`;
export const VALIDATE_RECOVERY_POINT = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/validate`;
export const VALIDATE_RECOVERY_POINT_CONFIGURATION = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/recoverypoint/configuration`;
export const RECOVERY_FULL_VM = `${process.env.REACT_APP_BE_URL}/v2/recovery/sources/{id}/recoveryvm`;
export const GET_VMWARE_DATASTORES = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/datastores`;
export const GET_VMWARE_STORAGE_POLICIES = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/storagepolicies`;
export const GET_HYPERVISOR_TYPE = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/types`;
export const VALIDATE_HYPERVISOR_HYPERV = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/verifyvm`;
export const VALIDATE_HYPERVISOR_VMWARE = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/verifyvm`;
export const VALIDATE_HYPERVISOR_NUTANIX = `${process.env.REACT_APP_BE_URL}/hypervisors/nutanix/verifyvm`;
export const GET_HYPERVISOR_TYPES = `${process.env.REACT_APP_BE_URL}/sources/{id}/hypervisor`;
export const GET_HYPERVISOR_TYPES_V2 = `${process.env.REACT_APP_BE_URL}/v2/sources/{id}/hypervisor`;
export const VALIDATE_DISK = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/validatedisk`;
export const FETCH_NETWORKS_FOR_HYPERV = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/networks`;
export const FETCH_NETWORKS_FOR_VMWARE = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware/networks`;
export const FETCH_DEFAULT_VM_FOLDER = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/defaultvmfolder`;
export const FETCH_DEFAULT_VHD_FOLDER = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv/defaultvhdfolder`;
export const CREATE_IVM = `${process.env.REACT_APP_BE_URL}/recovery/instantvm/submit`;

export const TWO_FACTOR_ENABLED = `${process.env.REACT_APP_BE_URL}/user/twofactor/enabled`;
export const USER_VERIFY_PASSWWORD = `${process.env.REACT_APP_BE_URL}/user/twofactor/validatepassword`;
export const TWO_FACTOR_GET_SECRET = `${process.env.REACT_APP_BE_URL}/user/twofactor/getsecret`;
export const USER_TWO_FACTOR_TOGGLE = `${process.env.REACT_APP_BE_URL}/user/twofactor/toggle`;
export const USER_TWO_FACTOR_ONETIMECODES = `${process.env.REACT_APP_BE_URL}/user/twofactor/onetimecodes`;

export const HYPERVISOR_IVM_VMWARE = `${process.env.REACT_APP_BE_URL}/hypervisors/instantvm/vmwares`;
export const HYPERVISOR_IVM_HYPERV = `${process.env.REACT_APP_BE_URL}/hypervisors/instantvm/hypervs`;
export const HYPERVISOR_IVM_VMWARE_v2 = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/instantvm/vmwares`;
export const HYPERVISOR_IVM_HYPERV_v2 = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/instantvm/hypervs`;
export const HYPERVISOR_IVM_NUTANIX = `${process.env.REACT_APP_BE_URL}/hypervisors/instantvm/{id}/nutanix`;
// APIs for Analyze > Reports > Account Details Rpeorts
export const FETCH_ACCOUNT_DETAILS = `${process.env.REACT_APP_BE_URL}/organizations/{id}/accounts`;
export const FETCH_SOURCE_PROTECTION_SOURCES_SUMMARY = `${process.env.REACT_APP_BE_URL}/sourceprotectionreports/sourcessummary`;
export const FETCH_ACCOUNT_SUMMARY_DETAILS = `${process.env.REACT_APP_BE_URL}/organizations/{id}/accountsummary`;

// VSB- Azure & EC2
export const FETCH_CLOUD_CONFIGURATIONS = `${process.env.REACT_APP_BE_URL}/destinations/cloudaccounts/{id}/configurations`;
export const FETCH_CLOUD_SUBNET = `${process.env.REACT_APP_BE_URL}/destinations/cloudaccounts/{id}/subnets`;

export const IVM_VALIDATE_HYPERV = `${process.env.REACT_APP_BE_URL}/recovery/instantvm/validatehyperv`;
export const IVM_VALIDATE_NFS = `${process.env.REACT_APP_BE_URL}/recovery/instantvm/validatenfs`;
export const FETCH_DISKS = `${process.env.REACT_APP_BE_URL}/sources/{id}/disks`;
export const REFRESH_HYPERVISOR = `${process.env.REACT_APP_BE_URL}/hypervisors/{hypervisorId}/refresh?request={syncType}`;
export const REFRESH_CD_HYPERVISOR = `${process.env.REACT_APP_BE_URL}/hypervisors/{hypervisorId}/refreshvms`;
export const REFRESH_CLOUD_ACCOUNT = `${process.env.REACT_APP_BE_URL}/destinations/cloudaccounts/{cloudaccounts}/refresh?request={syncType}`;
export const CONNECT_HYPERVISOR_HYPERV = `${process.env.REACT_APP_BE_URL}/v4/hypervisors/hyperv/connect`;
export const CONNECT_HYPERVISOR_VMWARE = `${process.env.REACT_APP_BE_URL}/v3/hypervisors/vmware/connect`;
export const CONNECT_HYPERVISOR_NUTANIX = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/nutanix/connect`;
export const ADD_HYPERVISOR_HYPERV = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/hyperv`;
export const ADD_HYPERVISOR_VMWARE = `${process.env.REACT_APP_BE_URL}/v2/hypervisors/vmware`;
export const ADD_HYPERVISOR_NUTANIX = `${process.env.REACT_APP_BE_URL}/hypervisors/nutanix`;
export const SET_LAST_VIEWED_APP = `${process.env.REACT_APP_UCC_BE_URL}/`;
export const VALIDATE_ORACLE_PATH = `${process.env.REACT_APP_BE_URL}/recovery/sources/{id}/oracle/validatepath`;
export const DISABLE_SHARE_DATASTORES = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/disableshare`;
export const SUBMIT_VIRTUAL_STANDBY = `${process.env.REACT_APP_BE_URL}/sources/{id}/virtualstandby`;
export const GET_NUTANIX_CLUSTER_DETAILS_FOR_POLICY = `${process.env.REACT_APP_BE_URL}/hypervisors/instantvm/{id}/nutanix`;
export const GET_ANTIVIRUS_DETAILS_FOR_ASSURED_SECURITY_SCAN = `${process.env.REACT_APP_BE_URL}/sources/{id}/antivirus`;
export const TERMINATE_STANDBY_VM = `${process.env.REACT_APP_BE_URL}/sources/{id}/standbyvm`;
export const GET_REPLICATION_TASKS_FOR_POLICY = `${process.env.REACT_APP_BE_URL}/policies/{id}/replicatetasks`;
export const GET_REPLICATION_TASKS_FOR_SOURCE = `${process.env.REACT_APP_BE_URL}/sources/{id}/replicatetasks`;
export const RUN_ADHOC_REPLICATION_FOR_SOURCE = `${process.env.REACT_APP_BE_URL}/v2/sources/{id}/replicate`;
export const RUN_ADHOC_REPLICATION_FOR_POLICY = `${process.env.REACT_APP_BE_URL}/v2/policies/{id}/replicate`;
export const DOWNLOAD_ASSURED_SECURITY_SCAN_DETAILS = `${process.env.REACT_APP_BE_URL}/recovery/recoverypoint/scanresult/download`;
export const GET_DESTINATION_INFO = `${process.env.REACT_APP_BE_URL}/destinations/datastores/{id}/sources/{sourceId}/destinfo`;
