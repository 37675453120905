/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconMUI from "components/common/icon/IconMUI";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Paper from "@mui/material/Paper";
import { ID_PREFIX } from "configs/IdConfig";

import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// Import Selectors
import { makeImpersonationViewState } from "state/selectors/HomeSelectors";
import AlertDetails from "./tabs/AlertDetailsMUI";
import FormattedMessage from "../formatted-message";
import { ALERT_TYPES } from "../../../utils/appConstants";
import AlertListTab from "./tabs";
import { GridTabMUI } from "../tab";

/**
 * Parent component that loads all alerts
 */
const Alerts = ({
  show,
  alertsList,
  handleSelectFilter,
  history,
  testId,
  id,
  linkColor,
  isMonitor,
  locale
}) => {
  const [criticalAlerts, setCriticalAlerts] = useState([]);
  const [warningAlerts, setWarningAlerts] = useState([]);
  const [infoAlerts, setInfoAlerts] = useState([]);
  useEffect(() => {
    const criticalList = [];
    const infoList = [];
    const warningList = [];
    const alert_list = Object.assign([], alertsList.data);
    alert_list.forEach(list => {
      if (list.severity === ALERT_TYPES.CRITICAL) {
        criticalList.push(list);
      } else if (list.severity === ALERT_TYPES.WARNING) {
        warningList.push(list);
      } else if (list.severity === ALERT_TYPES.INFO) {
        infoList.push(list);
      }
    });
    setCriticalAlerts(criticalList);
    setWarningAlerts(warningList);
    setInfoAlerts(infoList);
  }, [alertsList.data]);

  // React-Redux Hooks
  const isImpersonationView = useSelector(makeImpersonationViewState());

  const getClassListForAlertPanel = isFromMonitor =>
    show
      ? isImpersonationView
        ? isMonitor
          ? "alerts-panel-wrapper-monitor-direct alerts-panel-wrapper alerts-panel-wrapper-monitor-height-direct-user show"
          : "alerts-panel-wrapper alerts-panel-wrapper-height-direct-user show"
        : isMonitor
        ? "alerts-panel-wrapper-monitor-msp alerts-panel-wrapper alerts-panel-wrapper-monitor-height-msp-user show"
        : "alerts-panel-wrapper alerts-panel-wrapper-height-msp-user show"
      : "d-none";

  const tabOptions = [];

  tabOptions.push(
    {
      tab: (
        <div>
          <span className="d-flex align-items-center justify-content-center alert-icon-wrapper">
            <IconMUI icon={WarningRoundedIcon} color="error" />
            <span className="critical" style={{ color: "yellow !important" }}>
              {criticalAlerts.length}
            </span>
          </span>
          <label htmlFor="tab-text" className="tab-text word">
            <FormattedMessage
              id="alerts.tab.critical"
              defaultMessage="Critical"
            />
          </label>
        </div>
      ),
      tabkey: "critical",
      tabClassName:
        locale === "fr"
          ? "alert-tab-button alert-tab-selector"
          : "alert-tab-selector",
      testId: "KJcsd8",
      content: (
        <AlertListTab
          type={ALERT_TYPES.CRITICAL}
          history={history}
          alert={criticalAlerts}
          handleSelectFilter={handleSelectFilter}
          linkColor={linkColor}
        />
      )
    },
    {
      tab: (
        <div>
          <span className="d-flex align-items-center justify-content-center alert-icon-wrapper">
            <IconMUI icon={WarningRoundedIcon} color="warning" />
            <span className="warning">{warningAlerts.length}</span>
          </span>
          <label htmlFor="tab-text" className="tab-text">
            <FormattedMessage
              id="alerts.tab.warning"
              defaultMessage="Warning"
            />
          </label>
        </div>
      ),
      tabkey: "warnings",
      tabClassName:
        locale === "fr"
          ? "alert-tab-button alert-tab-selector"
          : "alert-tab-selector",
      testId: "skj8s0",
      content: (
        <AlertListTab
          type={ALERT_TYPES.WARNING}
          history={history}
          alert={warningAlerts}
          handleSelectFilter={handleSelectFilter}
          linkColor={linkColor}
        />
      )
    },
    {
      tab: (
        <div>
          <span className="d-flex align-items-center justify-content-center alert-icon-wrapper">
            <IconMUI icon={WarningRoundedIcon} color="info" />
            <span className="info">{infoAlerts.length}</span>
          </span>
          <label htmlFor="tab-text" className="tab-text">
            <FormattedMessage
              id="alerts.tab.info"
              defaultMessage="Information"
            />
          </label>
        </div>
      ),
      tabkey: "info",
      tabClassName:
        locale === "fr"
          ? "alert-tab-button alert-tab-selector"
          : "alert-tab-selector",
      testId: "KJSXIU",
      content: (
        <AlertListTab
          type={ALERT_TYPES.INFO}
          history={history}
          alert={infoAlerts}
          handleSelectFilter={handleSelectFilter}
          linkColor={linkColor}
        />
      )
    }
  );

  /**
   * to select tab with alerts count
   */
  const getSelectedTab = () => {
    let selectedTab = 0;
    if (criticalAlerts.length) {
      selectedTab = 0;
    } else if (warningAlerts.length) {
      selectedTab = 1;
    } else if (infoAlerts.length) {
      selectedTab = 2;
    } else {
      selectedTab = 0;
    }
    return selectedTab;
  };

  const handleToggledValue = value => {};

  const alertId = testId ? `${ID_PREFIX}${testId}` : id || "";

  return (
    <Paper
      elevation={0}
      square
      className={getClassListForAlertPanel(isMonitor)}
      id={alertId}
    >
      {alertsList.data ? (
        <GridTabMUI
          tabOptions={tabOptions}
          override
          tabButtonClass="alerts-panel-tab-button"
          defaultTab={getSelectedTab()}
          paperMuiStyleAsPerIndex={{ height: "calc(100% - 70px)" }}
          customStyleObject={{
            customTabComponentStyle: {
              padding: 0
            },
            customHeight: {
              height: `100%`,
              overflow: "auto"
            }
          }}
          tabHandleChange={value => handleToggledValue(value)}
          verticalLayout
        />
      ) : (
        <span className="loading-data">
          <FormattedMessage
            id="loading.alerts"
            defaultMessage="Loading Alerts..."
          />
        </span>
      )}
      <AlertDetails history={history} handleSelectFilter={handleSelectFilter} />
    </Paper>
  );
};

Alerts.defaultProps = {
  testId: "",
  id: "",
  isMonitor: false
};

/**
 * Properties of component
 * @type {{alertsList: *, history: * }}
 */
Alerts.propTypes = {
  alertsList: PropTypes.shape([]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  testId: PropTypes.string,
  id: PropTypes.string,
  linkColor: PropTypes.string.isRequired,
  isMonitor: PropTypes.bool,
  locale: PropTypes.string.isRequired
};

export default withRouter(Alerts);
