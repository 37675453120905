import { AppBar, Grid, Toolbar, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import MainDrawer from "../MainDrawer";
import { IntlProvider } from "react-intl";
import { LOCALES } from "../i18n/locales.js";
import { messages } from "../i18n/messages";
import { SUPPORTED_LANGUAGES } from "../appConstants";
import Cookies from "universal-cookie";
import { customTypography, defaultTheme } from "../arcserveTheme";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const UnifiedNavigation = ({
  title,
  appName,
  name = "",
  email = "",
  handleLogout,
  handleMenuClick,
  appPath = "",
  appsUrl = {
    cloud_console: "/",
    one_system: "/",
    uc: "/"
  },
  uccIconStyle = {},
  showTitle = false,
  loadFromAPI,
  isLoading,
  userName,
  ucBeURL,
  showLogout = false,
  token,
  language,
  UCMenuTheme,
  ucUrl = ""
}) => {
  const [locale, setLocale] = React.useState(
    SUPPORTED_LANGUAGES.includes(language) ? LOCALES[language] : LOCALES.en
  );
  const [menuTheme, setMenuTheme] = React.useState(createTheme(defaultTheme));
  useEffect(() => {
    const lang = SUPPORTED_LANGUAGES.includes(language)
      ? LOCALES[language]
      : LOCALES.en;
    setLocale(lang);
  }, [language]);

  useEffect(() => {
    if (UCMenuTheme) {
      const theme = createTheme({ ...UCMenuTheme, ...customTypography });
      setMenuTheme(theme);
    }
  }, [UCMenuTheme]);

  const cookies = new Cookies();
  const isSignedIn = cookies.get("isSignedIn");

  if (!isSignedIn) {
    cookies.remove("isSignedIn", { path: "/", domain: ".arcserve.com" });
    cookies.remove("cc", { path: "/", domain: ".arcserve.com" });
    handleLogout();
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const interval = setInterval(() => {
      const cookies = new Cookies();
      const isSignedIn = cookies.get("isSignedIn");

      if (!isSignedIn) {
        cookies.remove("isSignedIn", {
          path: "/",
          domain: ".arcserve.com"
        });
        clearInterval(interval);
        handleLogout();
      }
    }, 2000);

    const tabCheck = setInterval(() => {
      const cookies = new Cookies();
      const ccOpened = cookies.get("cc");
      const isSignedIn = cookies.get("isSignedIn");
      if (ccOpened && isSignedIn) {
        cookies.set("cc", "opened", {
          path: "/",
          domain: ".arcserve.com"
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(tabCheck);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={LOCALES.en}
    >
      <ThemeProvider theme={menuTheme}>
        {showTitle ? (
          <AppBar
            sx={{ backgroundColor: "#323232 !important" }}
            position="fixed"
          >
            <Toolbar
              sx={{
                "&.MuiToolbar-root": {
                  minHeight: 32
                }
              }}
            >
              <Grid
                container
                wrap="nowrap"
                spacing={1}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Grid item>
                  <MainDrawer
                    appName={appName}
                    name={name}
                    email={email}
                    handleLogout={handleLogout}
                    handleMenuClick={handleMenuClick}
                    appPath={appPath}
                    appsUrl={appsUrl}
                    uccIconStyle={{ ...uccIconStyle }}
                    showTitle={showTitle}
                    loadFromAPI={loadFromAPI}
                    isLoading={isLoading}
                    ucBeURL={ucBeURL}
                    userName={userName}
                    showLogout={showLogout}
                    token={token}
                    ucUrl={ucUrl}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: "white" }} variant="h6">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        ) : (
          <MainDrawer
            appName={appName}
            name={name}
            email={email}
            handleLogout={handleLogout}
            handleMenuClick={handleMenuClick}
            appPath={appPath}
            appsUrl={appsUrl}
            uccIconStyle={{ ...uccIconStyle }}
            showTitle={showTitle}
            loadFromAPI={loadFromAPI}
            isLoading={isLoading}
            ucBeURL={ucBeURL}
            userName={userName}
            showLogout={showLogout}
            token={token}
            ucUrl={ucUrl}
          />
        )}
        {/* add the below dropdown for testing */}
        {/* <BasicSelect locale={locale} setLocale={setLocale} /> */}
      </ThemeProvider>
    </IntlProvider>
  );
};

const appNames = {
  SYSTEM_PROTECTION: "system-protection",
  MONITORING: "monitoring",
  CLOUD_BACKUP: "cloud-backup",
  ONEXAFE_APPLIANCE: "onexafe-appliance",
  UDP: "UDP",
  CLOUD_DIRECT: "CLOUD_DIRECT",
  UC: "UC"
};

export default UnifiedNavigation;

UnifiedNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  appName: PropTypes.oneOf(Object.values(appNames)).isRequired,
  language: PropTypes.string,
  showTitle: PropTypes.bool
};

UnifiedNavigation.defaultProps = {
  language: "en",
  showTitle: false
};
