import {
  LOAD_DEFAULT_DEPLOYMENT_SUCCESS,
  LOAD_SOURCE_DISCOVERY_SETTINGS_SUCCESS,
  FETCH_SOURCE_DISCOVERY_LIST_SUCCESS,
  FETCH_SOURCE_DISCOVERY_LIST
} from "state/actions/actionTypes";
import { combineReducers } from "redux";

function deploymentConfigResp(state = null, action) {
  switch (action.type) {
    case LOAD_DEFAULT_DEPLOYMENT_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function sourceDiscoverySettingsResp(state = null, action) {
  switch (action.type) {
    case LOAD_SOURCE_DISCOVERY_SETTINGS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function sourceDiscoveryList(state = [], action) {
  switch (action.type) {
    case FETCH_SOURCE_DISCOVERY_LIST:
      return [];
    case FETCH_SOURCE_DISCOVERY_LIST_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
export default combineReducers({
  deploymentConfigResp,
  sourceDiscoverySettingsResp,
  sourceDiscoveryList
});
