import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./router.config";
import AsyncComponent from "../components/common/async";
import store from "../state/store";
import moduleCreator from "../state/registry/module";

//Get Register from module.js, which will fetch the reducer associated to the component.
const register = moduleCreator(store);
//Get all Route Components along with its reducer.

const Monitor = () =>
  register(
    "reducers",
    import(/* webpackChunkName: "monitor" */ "../components/monitor")
  );

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
class ComponentRouter extends React.Component {
  render() {
    return (
      <Switch>
        {routes.map((route, i) => (
          // Render <Route>s with the same paths as
          // above, with main components to populate grid area.
          <Route
            id={i + route.key}
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={() => (
              <AsyncComponent
                {...this.props}
                routes={route.routes}
                moduleProvider={route.main}
              />
            )}
          />
        ))}
        <Route
          render={() => (
            <AsyncComponent {...this.props} moduleProvider={Monitor} />
          )}
        />
      </Switch>
    );
  }
}

export default ComponentRouter;
