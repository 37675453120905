import React, { useState } from "react";
import { connect } from "react-redux";
import { getIdToken, getLogoutState } from "state/selectors";
import { OCTA_LOGOUT } from "state/api/apiUrlConstants";
import Cookies from "universal-cookie";
import * as actions from "state/actions/actions";
import { reduxForm } from "redux-form";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Signout = ({ idToken, octaLogout, dispatch }) => {
  const [redirectToLogout, setRedirectToLogout] = useState(false);

  if (redirectToLogout && octaLogout) {
    //let token = getCookieValue("idtoken");
    let token = localStorage.getItem("idtoken");

    //localStorage.removeItem("idtoken");

    // token = token ? token : idToken;
    const cookies = new Cookies();
    //cookies.set("isLoggedOut", true, { path: "/", domain: ".arcserve.com" });
    cookies.remove("isSignedIn", { path: "/", domain: ".arcserve.com" });
    cookies.remove("cc", { path: "/", domain: ".arcserve.com" });
    window.location.href = `${OCTA_LOGOUT}?id_token_hint=${token}`;
    return null;
  } else {
    if (octaLogout) {
      setRedirectToLogout(true);
      dispatch(actions.unsetOctaLogout());
      return null;
    }
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", height: "80%" }}>
      <CircularProgress
        sx={{ display: "flex", height: "unset !important", color: "#6200EE" }}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    // Grab the token from State
    idToken: getIdToken(state),
    octaLogout: getLogoutState(state)
  };
};

const SignoutForm = reduxForm({
  form: "signout"
})(Signout);
export default connect(mapStateToProps)(SignoutForm);
