import React from "react";
import { Link } from "react-router-dom";
import { DARK_MODE, MUI_INITIAL_THEME } from "../../../utils/theme";

const handleLinkColor = store => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;
  const { secondary } =
    theme && theme.palette ? theme.palette : MUI_INITIAL_THEME().palette;
  const { main: linkSecondary } = secondary;
  return linkSecondary;
};

const ProgressBarLinkComponent = ({ cell, child, store }) => {
  let reduxStore = store?.getState();
  let theme = reduxStore?.reducer?.theme;
  return (
    <Link
      id={`${cell.original ? cell.original.id : cell.row.original.id}-${
        cell.column.id
      }-job-type`}
      to={cell.value.href || "#"}
      href={cell.value.href || "#"}
      target={cell.value.target || "_self"}
      className={`
         ${
           cell.value.isDisabledPerm
             ? "link-cell-in-progress link-disabled-perm wordBreak"
             : cell.value.isDisabled
             ? "link-cell-in-progress link-disabled wordBreak"
             : "link-cell-in-progress wordBreak"
         } ${theme?.palette?.mode === DARK_MODE ? "link-dark-theme" : ""}
        `}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        //whiteSpace: "nowrap",
        fontSize: "0.8125rem",
        display: "block",
        color: `${
          cell.value.isDisabledPerm || cell.value.isDisabled
            ? "inherit"
            : handleLinkColor(store)
        }`
      }}
      data-ele-type="link"
    >
      {child}
    </Link>
  );
};

export default ProgressBarLinkComponent;
