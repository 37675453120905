import { combineReducers } from "redux";
import { combineReducersWithNamespace } from "state/utils";

import * as ccGraphConstants from "state/constants/ccGraph";

// context stuff
const config = (state = {}, action) => {
  switch (action.type) {
    case ccGraphConstants.CCGRAPH_INITIALIZE:
      return {
        ...action.config,
        additionalParameters: action.additionalParameters
      };
    default:
      return state;
  }
};

// loading indicator counter
// 0 means not loading
// for every REQ loading = loading + 1
// for every SUCCESSS/FAILURE loading = loading - 1
// hopefully, it'll reach 0
const loading = (state = 0, action) => {
  switch (action.type) {
    case ccGraphConstants.CCGRAPH_INITIALIZE:
      return 0;
    case ccGraphConstants.CCGRAPH_FETCH_DATA_REQUEST:
      return state + 1;
    case ccGraphConstants.CCGRAPH_FETCH_DATA_SUCCESS:
    case ccGraphConstants.CCGRAPH_FETCH_DATA_FAILURE:
      return state - 1;
    default:
      return state;
  }
};

// ui stuff
const resolution = (state = null, action) => {
  switch (action.type) {
    case ccGraphConstants.CCGRAPH_FETCH_DATA_SUCCESS: {
      return action.data.resolution || null; // Note: only included in TIME_SERIRES response
    }
    default: {
      return state;
    }
  }
};

const filters = (state = null, action) => {
  switch (action.type) {
    case ccGraphConstants.CCGRAPH_UPDATE_FILTERS_REQUEST:
      return action.values ? { ...state, ...action.values } : null;
    case ccGraphConstants.CCGRAPH_RESET_FILTERS_REQUEST:
      return state ? { filter_type: state.filter_type } : null;
    default:
      return state;
  }
};

const ui = combineReducers({
  resolution,
  filters
});

const entities = (state = {}, action) => {
  switch (action.type) {
    case ccGraphConstants.CCGRAPH_FETCH_DATA_SUCCESS:
      return action.data;
    case ccGraphConstants.CCGRAPH_FETCH_DATA_FAILURE:
      return null;
    default:
      return state;
  }
};

const ccGraph = combineReducersWithNamespace(
  {
    config,
    loading,
    ui,
    entities
  },
  action => action.ccGraphId
);

export default ccGraph;
