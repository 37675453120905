import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  getOrganizationsSearchString,
  getOrganizations,
  getOrganizationsPagination,
  isOrganizationsLoading
} from "state/selectors/csr";
import {
  fetchOrganizationSearch,
  setOrganizationsSortString,
  addConfirmation
} from "state/actions/csr";

import SearchResults from "./SearchResults";

export default connect(
  state => {
    return {
      isLoading: isOrganizationsLoading(state),
      organizations: getOrganizations(state),
      pagination: getOrganizationsPagination(state),
      searchString: getOrganizationsSearchString(state)
    };
  },
  dispatch =>
    bindActionCreators(
      {
        fetchOrganizationSearch,
        setOrganizationsSortString,
        addConfirmation
      },
      dispatch
    )
)(SearchResults);
