import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tag from "../../common/tags/index";
import Button from "../../common/button";
import FormattedMessage from "../../common/formatted-message";
import SelectDropdown from "../../common/select-dropdown-mui/index";

const SelectDropdownWithTagsMUI = ({
  handleTagClose,
  optionsToTags,
  options,
  addClickHandler,
  customStyle,
  testId,
  id,
  loading = false
}) => {
  const selectId = testId ? `${testId}_select` : id ? id : "";
  const btnId = testId ? `${testId}_btn` : id ? id : "";

  const [optionsSelected, setOptionsSelected] = useState([]);

  useEffect(() => {
    return () => {
      setOptionsSelected([]);
    };
  }, []);

  /**
   * Callback to handle event for tags close button
   * @param - array index of cleared tag input
   */
  const handleTagCloseHandler = searchTag => {
    handleTagClose(
      optionsToTags.find(option => option.value === searchTag.value)
    );
  };

  const renderTags = () => {
    return (
      optionsToTags &&
      optionsToTags.length > 0 && (
        <div
          className="grid-header-wrapper"
          style={{ width: "100%", marginTop: "10px" }}
        >
          <div
            className="saved-filter-wrapper select-tag-wrapper d-flex"
            style={{ marginTop: 0, maxHeight: "200px", overflow: "auto" }}
          >
            <div className="tags-wrapper">
              <Tag
                tags={optionsToTags}
                onTagClose={handleTagCloseHandler}
                showTagsWithCategory={false}
              />
            </div>
          </div>
        </div>
      )
    );
  };

  const renderSelectDropdown = () => {
    return (
      <div className="select-wrapper">
        <SelectDropdown
          dropdownOptions={options}
          isMenuCloseOnSelect={false}
          isDropdownDisabled={false}
          isMultiSelect
          hasi18nSupport={false}
          placeholder="select"
          selectedOption={optionsSelected}
          getSelectedOption={(index, item) => setOptionsSelected(item)}
          optionType={0}
          style={customStyle}
          testId={selectId}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="column-name-list-wrapper d-flex text-left col-md-12 pl-0">
        <div className="flex-wrap pl-0">{renderSelectDropdown()}</div>
        <div
          className="col-md-3"
          style={{ marginTop: "1px", marginLeft: "5px" }}
        >
          <Button
            color="secondary"
            content={<FormattedMessage id="add" defaultMessage="Add" />}
            disabled={!(optionsSelected && optionsSelected.length > 0)}
            onClick={() => {
              addClickHandler(optionsSelected);
              setOptionsSelected([]);
            }}
            variant="contained"
            testId={btnId}
          />
        </div>
      </div>
      <div
        className="column-name-list-wrapper d-flex text-left col-md-12 pl-0 pr-0"
        style={{ width: "100%" }}
      >
        {renderTags()}
      </div>
    </div>
  );
};

SelectDropdownWithTagsMUI.defaultProps = {
  optionsToTags: []
};

SelectDropdownWithTagsMUI.propTypes = {
  handleTagClose: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedOptionsHandler: PropTypes.func,
  addClickHandler: PropTypes.func.isRequired,
  optionsToTags: PropTypes.arrayOf(PropTypes.shape({}))
};

export default React.memo(SelectDropdownWithTagsMUI);
