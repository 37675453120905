import React from "react";
//Import dependencies/helper libraries
import { connect } from "react-redux";
import lodash from "lodash";
import { withRouter } from "react-router-dom";
import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import qs from "query-string";
import {
  getOriginalRoleId,
  getRoleId,
  getLoginToken,
  getLoginTokenExpiry,
  getLogin,
  getReducer,
  getFeatureFlag
} from "state/selectors";
import { getUIVersion, getAPIVersion } from "state/selectors/CommonSelectors";
import {
  isCSRUser,
  isMspMonitorUser,
  isDirectAdminUser,
  isMspAccountMonitorUser,
  isDirectMonitorUser,
  isRecoveryUser,
  isDirectRecoveryAdminUser,
  isMspAccountAdmin,
  isMspRecoveryAdmin,
  isMspAccountRecoveryAmdin
} from "utils";
import CsrNotifications from "components/csr-home/components/CsrNotifications";
//import { bindActionCreators } from "redux";
import LoginModal from "../csr-home/components/LoginModal";
//Import application styles
import "../../styles/app.css";

//Import state utils and routes
import * as actions from "../../state/actions/actions";
import { displayLoginModal as displayLoginModalaction } from "state/actions/csr";
import { getDisplayLoginModal } from "state/selectors/csr";

import { ComponentRouter } from "../../routes";
import {
  SECONDARY_NAVIGATION,
  PAGES_THAT_MSP_USERS_NEED_IMPERSONATION
} from "../../utils/appConstants";
import changeTitle from "../../utils/changeTitleUtil";

//Import components
import Header from "../header";
//import LeftNavBar from "../navigator";
import SecondaryNavigator from "../secondary-navigator";
import profileImgUrl from "../../assets/images/Profile_icon.png";
import FormattedMessage, {
  FormattedHTMLMessage
} from "../common/formatted-message";
import * as constant from "../../utils/appConstants";
// import {
//   // arcserveTheme,
//   UnifiedNavigation
// } from "@spog/component_library/dist/Components/nav/index";
import { UnifiedNavigation } from "./nav/index";
// import { OCTA_LOGOUT, OCTA_LOGIN_REDIRECT } from "state/api/apiUrlConstants";
import {
  CC_HOST_URL,
  OS_HOST_URL,
  UCC_HOST_URL,
  UCC_BE_URL
} from "state/api/apiUrlConstants";

//Import Selectors
import {
  makeSiteState,
  makeAlertState,
  makeMonitorViewState,
  makeCustomerState,
  makePrimaryNavigationState,
  makeSecondaryNavigationState,
  makeCollapseSidebarState,
  makeMenuState,
  makeContainerWidthState,
  makePrimKeyaryState,
  makeSecondaryKeyState,
  makeParentKeyState,
  makeResourceNameState,
  makeCroppedImageURLState,
  makeImageURLState,
  makeApiResponseState,
  makeOrgTypeState,
  makeIsSubMSPState,
  makeGridCurrentPageState,
  makeGridPageSizeState,
  makeImpersonationViewState,
  makeCustomerOrgIdState,
  makeCustomerOrgNameState,
  makeBrandingState,
  makeBrandingLogoState,
  makeIsBrandingLogoState
} from "../../state/selectors/HomeSelectors";
import { makeshowBrowseRecoveryPointModalProtectState } from "../../state/selectors/recovery-points";
import {
  getUserDetails,
  fetchEntitlementsProducts
} from "state/actions/actions";

/**
 *
 *
 * @class Home
 * @extends {React.Component}
 */
class Home extends React.Component {
  /**
   * Creates an instance of Home.
   * @param {array} props
   * @memberof Home
   */
  constructor(props) {
    super(props);
    this._handleWindowResizeListener = lodash.debounce(() => {
      this.handleWindowResize();
    }, 100);
    this.fromNavigation = false;
    this.timer = null;
    this.isCDActive = null;
  }

  componentDidMount = () => {
    // const cookies = new Cookies();
    // cookies.set("isLoggedOut", "", { path: "/", domain: ".arcserve.com" });
    const orgName = lodash.get(this.props, "branding.organization_name", "");
    const organizationId = lodash.get(this.props, "organizationId", "");
    const logoUrl = lodash.get(this.props, "branding.logo_img_url", "");
    const brandingFrom = lodash.get(this.props, "branding.branding_from", "");
    changeTitle(orgName, logoUrl, brandingFrom);
    //this.props.dispatch(getOctaUserDetails())
    this.props.dispatch(getUserDetails());
    this.props.dispatch(fetchEntitlementsProducts(organizationId));
    //yield put(fetchEntitlementsProducts(organizationId));
    this.handleLoginModalDisplay(
      this.props.loginTokenExpiry,
      displayLoginModalaction,
      this.props.displayLoginModal
    );
    const redirectUser = () => {
      //fix breadcrumbs and menu selection after redirecting
      this.props.dispatch(
        actions.selectMenuAction({
          selectedMenu: "Protect",
          selectedMenuKey: "Protect"
        })
      );
      //navigate the msp back , if he tries to access policies/sources/destinations without impersonating a suborg
      this.props.history.push("/protect/customer-accounts/all");
    };
    if (
      PAGES_THAT_MSP_USERS_NEED_IMPERSONATION.filter(item =>
        this.props.history.location.pathname.includes(item.toLowerCase())
      ).length > 0 &&
      this.props.loginOrganizationType === "msp" &&
      this.props.isImpersonationView !== true
    ) {
      redirectUser();
    }
    if (
      this.props.history.location.pathname.includes("sources") &&
      this.props.loginOrganizationType === "msp" &&
      this.props.isImpersonationView !== true
    ) {
      const item = qs.parse(this.props.location.search);

      if (item && item.organization_id && item.organization_name) {
        let organization_id = item.organization_id;
        let organization_name = item.organization_name;
        let customerData = {
          customerOrgId: organization_id,
          customerOrgName: organization_name,
          customerOrgType: "msp_child"
        };
        this.props.dispatch(actions.impersonateCustomer(customerData));
      } else {
        redirectUser();
      }
    }
    window.addEventListener("resize", this._handleWindowResizeListener);
    this.props.dispatch(actions.clearStateSelectedRows());
    this.props.dispatch(actions.getBrandingDetails());
    /* this.props.isImpersonationView &&
      this.props.dispatch(
        actions.getOrganization(this.props.customerOrgId, "impersonationView")
      ); */
    if (lodash.get(this.props.selectedResource, "source_id")) {
      this.props.dispatch(
        actions.setRestoreCookieAuthAction(
          lodash.get(this.props.selectedResource, "source_id")
        )
      );
    }
  };

  componentDidUpdate(prevProps) {
    const orgName = lodash.get(this.props, "branding.organization_name", "");

    const logoUrl = lodash.get(this.props, "branding.logo_img_url", "");
    const brandingFrom = lodash.get(this.props, "branding.branding_from", "");

    const prevOrgName = lodash.get(prevProps, "branding.organization_name", "");
    // const prevEmail = lodash.get(prevProps, "email", "");
    // if (prevEmail == "" && this.props.email !== "") {
    //   this.props.dispatch(actions.setLastViewedApp(this.props.email));
    // }
    if (prevOrgName === "" && orgName !== "") {
      changeTitle(orgName, logoUrl, brandingFrom);
    }

    if (!this.props.displayLoginModal) {
      this.handleLoginModalDisplay(
        this.props.loginTokenExpiry,
        displayLoginModalaction,
        this.props.displayLoginModal
      );
    }
    if (
      prevProps.isImpersonationView !== this.props.isImpersonationView ||
      prevOrgName !== orgName
    ) {
      if (this.props.customerOrgId && this.props.isImpersonationView) {
        this.props.dispatch(
          actions.getOrganization(this.props.customerOrgId, "impersonationView")
        );
      } else {
        const organizationId = lodash.get(this.props, "organizationId", "");
        this.props.dispatch(actions.getOrganization(organizationId));
      }
    }
  }
  /**
   *
   *
   * @memberof Home
   */
  componentWillUnmount = () => {
    //TODO: to be removed. temporary solution.
    //localStorage.clear();
    localStorage.removeItem("dashboardAuthWidgets");
    localStorage.removeItem("dashboardLayout");
    localStorage.removeItem("state");
    window.removeEventListener("resize", this._handleWindowResizeListener);
    if (this.timer) clearTimeout(this.timer);
  };

  resetImpersonateCustomerState = () => {
    this.props.dispatch(actions.resetImpersonateCustomerState());
    this.props.dispatch(actions.getBrandingDetails());
  };

  handleMSPHomeClick = () => {
    this.handleSelectMenu({
      selectedMenu: "Protect",
      selectedMenuKey: "Protect"
    });
    // this.handleSelectFilter("customer-accounts", "Customer Accounts");
    this.props.dispatch(
      actions.selectFilterAction({
        selectedFilter: "customer-accounts",
        selectedFilterKey: "Customer Accounts"
      })
    );
    if (this.props.loginOrganizationType === "csr") {
      let customerData = {
        customerOrgId: lodash.get(this.props, "csrCustomerOrgId", null),
        customerOrgName: lodash.get(this.props, "csrCustomerOrgName", null),
        customerOrgType: "msp"
      };
      this.props.dispatch(actions.impersonateCustomer(customerData));
    }
    this.props.loginOrganizationType !== "csr" &&
      this.resetImpersonateCustomerState();
    this.props.dispatch(actions.clearAllMenusCountToZero({ type: "Protect" }));
    this.props.dispatch(actions.updateOrganizationsForAdvanceSearch([]));
    this.props.dispatch(actions.updateSourceGroupsForAdvanceSearch([]));
    this.props.dispatch(actions.updateDestinationsForAdvanceSearch([]));
    this.props.dispatch(actions.updateGeneratedFromAdvanceSearch([]));
    this.props.dispatch(actions.updatePoliciesForAdvanceSearch([]));
    this.props.dispatch(actions.searchEnterEvent([]));
  };

  runGetAdvancedSearchOptions = (
    selectedFilter,
    secondaryNavigation,
    primaryNavgation
  ) => {
    if (
      (((selectedFilter === secondaryNavigation ||
        constant.HOME_RUN_ADV_SEARCH.indexOf(selectedFilter) > -1) &&
        primaryNavgation === constant.SECONDARY_NAVIGATION.ANALYZE) ||
        (primaryNavgation !== constant.SECONDARY_NAVIGATION.ANALYZE &&
          constant.CONFIGURE_PAGES_WITHOUT_SEARCH.indexOf(selectedFilter) ===
            -1) ||
        (selectedFilter === constant.SECONDARY_NAVIGATION_TYPE.LOG &&
          constant.HOME_RUN_ADV_SEARCH.indexOf(secondaryNavigation) === -1) ||
        (secondaryNavigation === constant.SECONDARY_NAVIGATION_TYPE.LOG &&
          selectedFilter === constant.SECONDARY_NAVIGATION_TYPE.SOURCES) ||
        (secondaryNavigation === constant.SECONDARY_NAVIGATION_TYPE.JOBS &&
          selectedFilter === constant.SECONDARY_NAVIGATION_TYPE.LOG)) &&
      !(
        this.props.history.location.pathname.includes("details") &&
        this.props.history.location.pathname.includes("sources") &&
        primaryNavgation === constant.SECONDARY_NAVIGATION.PROTECT &&
        [
          constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
          constant.SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
          constant.SECONDARY_NAVIGATION_TYPE.SOURCES
        ].indexOf(secondaryNavigation) > -1
      )
      //when going from sources details to sources getAdvancedSearchOptions gets called and cancelled.
    ) {
      if (selectedFilter !== "sources") {
        if (
          selectedFilter !== "jobs" ||
          (selectedFilter === "jobs" && secondaryNavigation !== "jobs")
        ) {
          this.props.dispatch(
            actions.getAdvancedSearchOptions({
              filter: selectedFilter
            })
          );
        }
      }
      if (
        selectedFilter ===
        constant.SECONDARY_NAVIGATION_TYPE.MANAGE_REPORT_SCHEDULES
      ) {
        this.props.dispatch(actions.getReportSchedules({ selectedFilter }));
      }
    } else if (
      secondaryNavigation === constant.SECONDARY_NAVIGATION_TYPE.ALERTS &&
      selectedFilter !== constant.SECONDARY_NAVIGATION_TYPE.ALERTS
    ) {
      this.props.dispatch(actions.fromNavigationApi(false));
    }
  };

  /**
   *
   *
   * @param {String} selectedFilter
   * @memberof Home
   */
  handleSelectFilter = (selectedFilter, selectedFilterKey) => {
    this.fromNavigation = true;
    if (
      selectedFilter !== "jobs" ||
      // eslint-disable-next-line react/destructuring-assignment
      (selectedFilter === "jobs" && this.props.secondaryNavigation !== "jobs")
    ) {
      this.props.dispatch(actions.updateCurrentSavedSearchName(null, ""));
      this.props.dispatch(actions.clearAllSearchTags());
    }
    this.props.dispatch(actions.clearContextualAction());
    if (this.props.secondaryKey !== null) {
      this.props.dispatch(actions.fromNavigationApi(true));
      /*when going from another component to Logs, Reports, or SubReports,
        the filters are called twice - once from here and once from 
        componentDidMount on the component
      */
      this.runGetAdvancedSearchOptions(
        selectedFilter,
        this.props.secondaryNavigation,
        this.props.primaryNavigation
      );
    }
    this.props.dispatch(
      actions.selectFilterAction({
        selectedFilter: selectedFilter,
        selectedFilterKey: selectedFilterKey
      })
    );
  };

  /**
   * Update resource name on breadcrumb click.
   *
   * @param {String} resourceName
   * @memberof Home
   */
  updateResourceName = resourceName => {
    this.props.dispatch(actions.setResourceName(resourceName));
  };
  /**
   *
   *
   * @param {Boolean} collapseSideBar
   * @memberof Home
   */
  handleCollapseSideBar = collapseSideBar => {
    this.props.dispatch(actions.collapseSidebar(collapseSideBar));
  };
  /**
   *
   *
   * @memberof Home
   */
  handleWindowResize = () => {
    this.props.dispatch(actions.detectWindowDimensions());
  };

  /**
   *
   *
   * @param {String} selectedMenu
   * @memberof Home
   */
  handleSelectMenu = (selectedMenu, selectedMenuKey) => {
    this.props.dispatch(
      actions.selectMenuAction(selectedMenu, selectedMenuKey)
    );
  };

  /**
   * To get response message from API response
   * @param apiResponse
   * @returns {*}
   */
  getResponse = apiResponse => {
    if (
      apiResponse &&
      (apiResponse.context ===
        constant.API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_SUCCESS ||
        apiResponse.context ===
          constant.API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_ALL_SUCCESS)
    ) {
      return <FormattedHTMLMessage id={apiResponse.context} />;
    }
  };

  handleLoginModalDisplay(
    loginTokenExpiry,
    displayLoginModalaction,
    displayLoginModal
  ) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (
      isCSRUser(this.props.originalRoleId) &&
      this.props.isImpersonationView
    ) {
      let timeToExpiration = loginTokenExpiry - Date.now();
      if (!displayLoginModal && timeToExpiration > 0) {
        this.timer = setTimeout(() => {
          this.props.dispatch(displayLoginModalaction());
        }, timeToExpiration);
      } else {
        this.props.dispatch(displayLoginModalaction());
      }
    }
  }

  /**
   *
   *
   * @returns
   * @memberof Home
   */
  render() {
    const { displayLoginModal } = this.props;
    // let customerOrgName = this.props.customerOrgName;
    // let secondaryColor = this.props.branding.secondary_color;
    const copyrightMessage = lodash.get(this.props, "branding.copyright", "");
    // let toastDisplay = null;
    // if (this.props.apiResponse) {
    //   if (
    //     [
    //       constant.API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_SUCCESS,
    //       constant.API_RESPONSE_CONTEXT.ALERT_ACKNOWLEDGE_ALL_SUCCESS
    //     ].indexOf(this.props.apiResponse.context) === -1 &&
    //     lodash.get(this.props.apiResponse, "contextualAction.type") !==
    //       SourceConstants.CONTEXTUAL_ACTION.ASSIGN_POLICY
    //   ) {
    //     toastDisplay = (
    //       <SpogToast
    //         testId="xH11r4"
    //         type={getToastTypeForResponse(this.props.apiResponse)}
    //         message={this.getResponse(this.props.apiResponse)}
    //       />
    //     );
    //   } else if (
    //     [
    //       constant.API_RESPONSE_CONTEXT.SOURCE_CONFIG_UPDATE_FAILURE,
    //       constant.API_RESPONSE_CONTEXT.SOURCE_CONFIG_UPDATE_SUCCESS
    //     ].indexOf(this.props.apiResponse.context) === -1 &&
    //     lodash.get(this.props.apiResponse, "contextualAction.type") !==
    //       SourceConstants.CONTEXTUAL_ACTION.ASSIGN_POLICY
    //   ) {
    //     toastDisplay = (
    //       <SpogError testId="XylEhS" apiResponse={this.props.apiResponse} />
    //     );
    //   }
    // }
    let loginModal = null;
    if (isCSRUser(this.props.originalRoleId) && displayLoginModal) {
      loginModal = <LoginModal />;
    }
    let newMenu = this.props.menu;
    if (
      ((isMspMonitorUser(this.props.originalRoleId) ||
        isMspAccountMonitorUser(this.props.originalRoleId)) &&
        isDirectAdminUser(this.props.roleId)) ||
      isDirectMonitorUser(this.props.originalRoleId)
    ) {
      newMenu = this.props.menu.filter(
        ele => ele.key !== SECONDARY_NAVIGATION.PROTECT
      );
    }
    if (
      isDirectRecoveryAdminUser(this.props.originalRoleId) ||
      isMspRecoveryAdmin(this.props.originalRoleId) ||
      isMspAccountRecoveryAmdin(this.props.originalRoleId)
    ) {
      newMenu = this.props.menu.filter(
        ele => ele.key !== SECONDARY_NAVIGATION.CONFIGURE
      );
    }
    if (
      isMspMonitorUser(this.props.originalRoleId) ||
      isMspAccountMonitorUser(this.props.originalRoleId) ||
      isDirectMonitorUser(this.props.originalRoleId) ||
      isMspAccountAdmin(this.props.originalRoleId)
    ) {
      if (
        this.props.isImpersonationView !== true ||
        isMspAccountMonitorUser(this.props.originalRoleId) ||
        isMspMonitorUser(this.props.originalRoleId)
      ) {
        newMenu = newMenu.filter(
          ele => ele.key !== SECONDARY_NAVIGATION.CONFIGURE
        );
      }
    }
    if (
      !lodash.isEmpty(this.props.products) &&
      ["active", "trial"].indexOf(
        lodash.get(this.props.products, "cloudhybrid.state")
      ) === -1 &&
      ["active", "trial"].indexOf(
        lodash.get(this.props.products, "clouddirect.state")
      ) === -1
    ) {
      newMenu.forEach((item, i) => {
        if (item.key === constant.SECONDARY_NAVIGATION.PROTECT) {
          if (Array.isArray(newMenu[i].subMenu)) {
            this.isCDActive = false;
            newMenu[i].subMenu = newMenu[i].subMenu.filter(
              item =>
                item.key !== constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD
            );
          }
        }
      });
    } else {
      this.isCDActive = true;
    }

    if (
      !lodash.isEmpty(this.props.products) &&
      ["active", "trial"].indexOf(
        lodash.get(this.props.products, "udp.state")
      ) === -1
    ) {
      newMenu.forEach((item, i) => {
        if (item.key === constant.SECONDARY_NAVIGATION.PROTECT) {
          if (Array.isArray(newMenu[i].subMenu)) {
            newMenu[i].subMenu = newMenu[i].subMenu.filter(
              item =>
                constant.SECONDARY_NAVIGATION_KEYS_CLOUD_HYBRID.indexOf(
                  item.key
                ) === -1
            );
          }
        }

        if (
          item.key === constant.SECONDARY_NAVIGATION.CONFIGURE &&
          this.props.organizationId !== "78506751-cc21-4989-a1f4-d7019df22a9f"
        ) {
          if (Array.isArray(newMenu[i].subMenu)) {
            newMenu[i].subMenu = newMenu[i].subMenu.filter(
              item => item.key !== constant.SECONDARY_NAVIGATION_KEYS.SITES
            );
          }
        }
      });
    }

    if (isRecoveryUser(this.props.originalRoleId)) {
      newMenu.forEach((item, i) => {
        if (item.key === constant.SECONDARY_NAVIGATION.CONFIGURE) {
          if (Array.isArray(newMenu[i].subMenu)) {
            newMenu[i].subMenu = newMenu[i].subMenu.filter(
              item =>
                [
                  constant.SECONDARY_NAVIGATION_KEYS.ENTITLEMENTS,
                  constant.SECONDARY_NAVIGATION_KEYS.BRANDING
                ].indexOf(item.key) === -1
            );
          }
        }
      });
    }
    if (
      (this.props.isImpersonationView && !this.props.isImpersonateFromCsr) ||
      this.props.orgType === "msp_child" ||
      (this.props.userType !== "" &&
        this.props.userType !== "admin" &&
        !this.props.isImpersonateFromCsr)
    ) {
      newMenu.forEach((item, i) => {
        if (item.key === constant.SECONDARY_NAVIGATION.CONFIGURE) {
          if (Array.isArray(newMenu[i].subMenu)) {
            newMenu[i].subMenu = newMenu[i].subMenu.filter(
              item =>
                [
                  constant.SECONDARY_NAVIGATION_KEYS.USER_ACCOUNTS,
                  constant.SECONDARY_NAVIGATION_KEYS.ACCESS_CONTROL,
                  constant.SECONDARY_NAVIGATION_KEYS.USER_ROLES
                ].indexOf(item.key) === -1
            );
          }
        }
      });
    }

    if (!this.props.isRMANVisible) {
      newMenu.forEach((item, i) => {
        if (item.key === constant.SECONDARY_NAVIGATION.CONFIGURE) {
          if (Array.isArray(newMenu[i].subMenu)) {
            newMenu[i].subMenu = newMenu[i].subMenu.filter(
              menu =>
                [constant.SECONDARY_NAVIGATION_KEYS.ORACLE_HOSTS].indexOf(
                  menu.key
                ) === -1
            );
          }
        }
      });
    }

    let firstName = this.props.firstName || this.props.givenName;
    let lastName = this.props.lastName || this.props.familyName;
    return (
      <>
        <UnifiedNavigation
          // appName="UDP"
          title="Cloud Console"
          appsUrl={{
            cloud_console: CC_HOST_URL,
            one_system: OS_HOST_URL,
            uc: UCC_HOST_URL
          }}
          showTitle={true}
          name={firstName + " " + lastName}
          email={this.props.email || this.props.userName}
          userName={this.props.email || this.props.userName}
          ucUrl={UCC_HOST_URL}
          ucBeURL={UCC_BE_URL}
          handleLogout={() => {
            this.props.history.push(`/logout`);
            // this.props.dispatch(actions.logoutRequest());
            // this.props.dispatch(actions.unsetJWTToken());
            // this.props.dispatch(actions.logOut());
          }}
          token={this.props.token}
          showLogout
          language={this.props.language}
          UCMenuTheme={{ ...this.props.theme }}
        />
        <div
          className="spog-app-wrapper container-fluid d-flex flex-md-row"
          style={{
            // backgroundImage: `linear-gradient(to right, ${"#000000"} 58%, ${secondaryColor} 94%)`
            backgroundColor: "#313231"
          }}
        >
          {/* <LeftNavBar
          menu={newMenu}
          handleSelectMenu={this.handleSelectMenu}
          secondaryNavigation={this.props.secondaryNavigation}
          secondaryKey={this.props.secondaryKey}
          handleSelectFilter={this.handleSelectFilter}
          active={this.props.primaryNavigation}
          orgType={this.props.orgType}
          isImpersonationView={this.props.isImpersonationView}
          customerOrgId={this.props.customerOrgId}
          customerOrgName={this.props.customerOrgName}
        />  */}
          {/* {toastDisplay} */}
          <div className="content-wrapper">
            {/* {this.props.isImpersonationView && (
            <div className="app-banner">
              <FormattedMessage
                id={
                  !isCSRUser(this.props.originalRoleId)
                    ? "header.banner.text"
                    : "header.banner.text-csr"
                }
                values={{
                  customerOrgName,
                  here: (
                    <NavLink
                      to={
                        !isCSRUser(this.props.originalRoleId)
                          ? `${PAGE_ROUTE.CUSTOMER_ACCOUNTS}/all`
                          : "/csr"
                      }
                      onClick={this.handleMSPHomeClick}
                    >
                      <FormattedMessage
                        id="header.banner.here.text"
                        defaultMessage="here"
                      />
                    </NavLink>
                  )
                }}
              />
            </div>
          )} */}
            <div className="app-render flex-md-column d-flex">
              <Header
                primaryNavigation={this.props.primaryNavigation}
                primaryKey={this.props.primaryKey}
                collapseSideBar={this.props.collapseSideBar}
                handleCollapseSideBar={this.handleCollapseSideBar}
                secondaryNavigation={this.props.secondaryNavigation}
                secondaryKey={this.props.secondaryKey}
                resourceName={this.props.resourceName}
                isImpersonationView={this.props.isImpersonationView}
                customerOrgId={this.props.customerOrgId}
                customerOrgName={this.props.customerOrgName}
                handleSelectFilter={this.handleSelectFilter}
                handleSelectMenu={this.handleSelectMenu}
                handleHomeMSPHomeClick={this.handleMSPHomeClick}
                resetImpersonateCustomerState={
                  this.resetImpersonateCustomerState
                }
                updateResourceName={this.updateResourceName}
                profileImage={
                  //eslint-disable-next-line eqeqeq
                  this.props.imageURL && this.props.imageURL.indexOf(null) == -1
                    ? this.props.imageURL
                    : profileImgUrl
                }
                roleId={this.props.originalRoleId}
                menu={newMenu}
                active={this.props.primaryNavigation}
                orgType={this.props.orgType}
                isCDActive={this.isCDActive}
              />
            </div>
            <div
              className={
                this.props.isImpersonationView
                  ? "app-content impersonation-view"
                  : "app-content"
              }
            >
              <Paper
                elevation={0}
                square
                className="d-flex align-items-left flex-column sub-menu-panel-wrapper"
              >
                {this.props.secondaryNavigation !== "customer-accounts" && (
                  <SecondaryNavigator
                    {...this.props}
                    handleSelectFilter={this.handleSelectFilter}
                    isImpersonationView={this.props.isImpersonationView}
                    customerOrgId={this.props.customerOrgId}
                    customerOrgName={this.props.customerOrgName}
                    fromNavigation={this.fromNavigation}
                  />
                )}
              </Paper>
              {loginModal}
              <div className="app-render flex-md-column d-flex">
                <div
                  className={
                    this.props.showBrowseRecoveryPointModal
                      ? "browser-recovery-modal-loader loader-wrapper"
                      : "loader-wrapper"
                  }
                >
                  {" "}
                  {this.props.isReducerLoading && (
                    <LinearProgress
                      color={"secondary"}
                      className="loadingBar"
                    />
                  )}
                  {/* <LoadingBar
                    className="loadingBar"
                    style={{
                      backgroundColor: this.props.theme?.palette?.secondary
                        ?.main
                    }}
                  /> */}
                </div>

                <div
                  className={`load-view-content ${
                    this.props?.primaryNavigation === "Monitor"
                      ? "hide-scroll"
                      : ""
                  }`}
                >
                  <ComponentRouter
                    {...this.props}
                    secondaryNavigation={this.props.secondaryNavigation}
                    handleSelectFilter={this.handleSelectFilter}
                    secondaryKey={this.props.secondaryKey}
                    orgType={this.props.orgType}
                    isSubMSP={this.props.isSubMSP}
                    resourceName={this.props.resourceName}
                    fromNavigation={this.fromNavigation}
                  />
                </div>
              </div>
            </div>
            {process.env.REACT_APP_ENV === "production" && (
              <div className="copyright-banner">
                <FormattedMessage
                  id="copyright.text"
                  values={{ msg: copyrightMessage }}
                />
              </div>
            )}
            {process.env.REACT_APP_ENV !== "production" && (
              <div className="copyright-banner">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{}}>
                    <FormattedMessage
                      id="copyright.text"
                      values={{ msg: copyrightMessage }}
                    />
                  </div>
                  <span className="footer-version">
                    <FormattedHTMLMessage
                      id="footer.version.text"
                      values={{
                        UIVersion: this.props.UIVersion,
                        APIVersion: this.props.APIVersion
                      }}
                    />
                  </span>
                </div>
              </div>
            )}

            {isCSRUser(this.props.originalRoleId) &&
            !this.props.isImpersonationView ? (
              <CsrNotifications />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

Home.propTypes = {
  originalRoleId: PropTypes.string.isRequired,
  primaryNavigation: PropTypes.string.isRequired,
  secondaryNavigation: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  collapseSideBar: PropTypes.bool.isRequired,
  parentKey: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
  orgType: PropTypes.string.isRequired,
  isSubMSP: PropTypes.bool.isRequired,
  secondaryKey: PropTypes.string,
  primaryKey: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  apiResponse: PropTypes.shape({}),
  isImpersonationView: PropTypes.bool.isRequired,
  customerOrgId: PropTypes.string,
  customerOrgName: PropTypes.string,
  branding: PropTypes.shape({
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    copyright: PropTypes.string
  }),
  showBrowseRecoveryPointModal: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  loginOrganizationType: PropTypes.string.isRequired,
  isRMANVisible: PropTypes.bool.isRequired
};

Home.defaultProps = {
  apiResponse: null,
  branding: {
    primary_color: "#000000",
    secondary_color: "#58E2C2",
    copyright: ""
  },
  showBrowseRecoveryPointModal: false,
  isSubMSP: false,
  secondaryKey: "",
  secondaryNavigation: "",
  parentKey: "",
  resourceName: ""
};

//Using makeMapStateToProps to memoize a selectorFunction for reusability
// This will create a new instance of selector function
//every time it is needed for proper memoization in multiple components.
const makeMapStateToProps = () => {
  //create a new instance of selector function
  //This makes it independent of any other selector function
  //and it will properly memoize across multiple mounted & connected components.
  const getSite = makeSiteState();
  const getAlert = makeAlertState();
  const getMonitorView = makeMonitorViewState();
  const getCustomer = makeCustomerState();
  const getPrimaryNavigation = makePrimaryNavigationState();
  const getSecondaryNavigation = makeSecondaryNavigationState();
  const getCollapseSideBar = makeCollapseSidebarState();
  const getMenu = makeMenuState();
  const getContainerWidth = makeContainerWidthState();
  const getPrimaryKey = makePrimKeyaryState();
  const getSecondaryKey = makeSecondaryKeyState();
  const getParentKey = makeParentKeyState();
  const getResourceName = makeResourceNameState();
  const getCroppedImageURL = makeCroppedImageURLState();
  const getImageURL = makeImageURLState();
  const getApiResponse = makeApiResponseState();
  const getOrgType = makeOrgTypeState();
  const getIsSubMSP = makeIsSubMSPState();
  const getGridCurrentPage = makeGridCurrentPageState();
  const getGridPageSize = makeGridPageSizeState();
  const getImpersonationView = makeImpersonationViewState();
  const getCustomerOrgId = makeCustomerOrgIdState();
  const getCustomerOrgName = makeCustomerOrgNameState();
  const getBranding = makeBrandingState();
  const getBrandingLogo = makeBrandingLogoState();
  const getIsBrandingLogo = makeIsBrandingLogoState();
  const getshowBrowseRecoveryPointModalProtectState = makeshowBrowseRecoveryPointModalProtectState();

  const mapStateToProps = (state, props) => {
    return {
      //Use selector function instance to update Props.
      originalRoleId: getOriginalRoleId(state),
      organizationId: getLogin(state).organization_id,
      roleId: getRoleId(state),
      UIVersion: getUIVersion()(state),
      APIVersion: getAPIVersion()(state),
      site: getSite(state, props),
      alert: getAlert(state, props),
      monitorView: getMonitorView(state, props),
      customer: getCustomer(state, props),
      primaryNavigation: getPrimaryNavigation(state, props),
      secondaryNavigation: getSecondaryNavigation(state, props),
      collapseSideBar: getCollapseSideBar(state, props),
      menu: getMenu(state, props),
      containerWidth: getContainerWidth(state, props),
      primaryKey: getPrimaryKey(state, props),
      secondaryKey: getSecondaryKey(state, props),
      parentKey: getParentKey(state, props),
      resourceName: getResourceName(state, props),
      croppedImageURL: getCroppedImageURL(state, props),
      imageURL: getImageURL(state, props),
      apiResponse: getApiResponse(state, props),
      orgType: getOrgType(state, props),
      isSubMSP: getIsSubMSP(state, props),
      gridCurrentPage: getGridCurrentPage(state, props),
      gridPageSize: getGridPageSize(state, props),
      isImpersonationView: getImpersonationView(state, props),
      customerOrgId: getCustomerOrgId(state, props),
      customerOrgName: getCustomerOrgName(state, props),
      branding: getBranding(state, props),
      brandingLogo: getBrandingLogo(state, props),
      isBrandingLogo: getIsBrandingLogo(state, props),
      loginOrganizationType: state.login.organization_type,
      showBrowseRecoveryPointModal: getshowBrowseRecoveryPointModalProtectState(
        state,
        props
      ),
      displayLoginModal: getDisplayLoginModal(state),
      loginToken: getLoginToken(state),
      loginTokenExpiry: getLoginTokenExpiry(state),
      products: state.api.entitlementsProducts.entitlementsProducts,
      selectedResource: state.protectReducer.selectedResource,
      isImpersonateFromCsr: state.reducer.isImpersonateFromCsr,
      impersonateUserType: state.reducer.impersonateUserType,
      firstName: lodash.get(state, "login.userDetails.first_name", ""),
      lastName: lodash.get(state, "login.userDetails.last_name", ""),
      givenName: lodash.get(state, "login.userDetails.given_name", ""),
      familyName: lodash.get(state, "login.userDetails.family_name", ""),
      email: lodash.get(state, "login.userDetails.user_name", ""),
      userName: lodash.get(state, "login.userDetails.preferred_username", ""),
      userType: lodash.get(state, "login.userDetails.user_type", ""),
      token: lodash.get(state, "login.token", ""),
      isReducerLoading: state.loadingBar.default > 0,
      organizationInfo: state.login.organizationInfo,
      theme: getReducer(state).theme,
      language: lodash.get(state, "login.locale", "en"),
      isRMANVisible: getFeatureFlag(state).rman,
      csrCustomerOrgId: state.reducer.csrCustomerOrgId,
      csrCustomerOrgName: state.reducer.csrCustomerOrgName
    };
  };
  return mapStateToProps;
};

//connect(): get a wrapper that passes state from the store as props
//Use anonymous function, which returns mapStateToProps.
export default withRouter(connect(makeMapStateToProps)(Home));
