import {
  FETCH_DOWNLOADS,
  FETCH_DOWNLOADS_SUCCESS
} from "state/actions/actionTypes";

const downloads = (state = [], { type, downloadsArray }) => {
  switch (type) {
    case FETCH_DOWNLOADS:
      return [];
    case FETCH_DOWNLOADS_SUCCESS:
      return [...downloadsArray];
    default:
      return state;
  }
};

export default downloads;
