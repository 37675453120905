import { combineReducers } from "redux";
import {
  LOAD_DATA_STORES_SUCCESS,
  SET_CURRENT_DATA_STORE_ID,
  SET_CURRENT_DATA_STORE,
  IMPORT_DATA_STORE_TEMP,
  REFRESH_DATA_STORE_DATA,
  REFRESH_DATA_STORE_SUCCESS,
  IMPORT_DATA_STORE_TEMP_CLEAR,
  CLEAR_DATA_STORES,
  LOAD_DATA_STORES,
  LOAD_RPS_LIST_SUCCESS,
  LOAD_DATA_STORES_SEARCH,
  LOAD_RPS_LIST,
  LOAD_ALL_RPS_LIST,
  RPS_DATA_STORE_SOURCE_LIST_GET_RETENTION,
  RPS_DATA_STORE_SOURCE_LIST_SET_RETENTION,
  IMPORT_CLOUD_DATA_STORE_TEMP,
  IMPORT_CLOUD_DATA_STORE_TEMP_CLEAR
} from "state/actions/actionTypes";

function dataStoresResponse(state = null, action) {
  switch (action.type) {
    case LOAD_DATA_STORES_SUCCESS:
      return action.data;
    case REFRESH_DATA_STORE_DATA:
      return state;
    case REFRESH_DATA_STORE_SUCCESS:
      return {
        ...state,
        data: state.data.map(ds =>
          ds.datastore_id === action.data.datastore_id ? action.data : ds
        )
      };
    case CLEAR_DATA_STORES:
      return null;
    case LOAD_DATA_STORES:
      return null;
    case LOAD_RPS_LIST_SUCCESS:
    case LOAD_RPS_LIST:
    case LOAD_ALL_RPS_LIST:
      return null;
    case LOAD_DATA_STORES_SEARCH:
      return null;
    default:
      return state;
  }
}

function currentDataStoreId(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_DATA_STORE_ID:
      return action.id;
    default:
      return state;
  }
}

function currentDataStore(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_DATA_STORE_ID:
      return null;
    case SET_CURRENT_DATA_STORE:
      return action.data;
    default:
      return state;
  }
}

function currentDataStoreRPRetention(state = {}, action) {
  switch (action.type) {
    case RPS_DATA_STORE_SOURCE_LIST_GET_RETENTION:
      return {};
    case RPS_DATA_STORE_SOURCE_LIST_SET_RETENTION:
      return action.data;
    default:
      return state;
  }
}

function importDataStoreResponse(state = null, action) {
  switch (action.type) {
    case IMPORT_DATA_STORE_TEMP:
      return action.data;
    case IMPORT_DATA_STORE_TEMP_CLEAR:
      return null;
    default:
      return state;
  }
}

function importCloudDataStoreResponse(state = null, action) {
  switch (action.type) {
    case IMPORT_CLOUD_DATA_STORE_TEMP:
      return action.data;
    case IMPORT_CLOUD_DATA_STORE_TEMP_CLEAR:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  dataStoresResponse,
  currentDataStoreId,
  currentDataStoreRPRetention,
  currentDataStore,
  importDataStoreResponse,
  importCloudDataStoreResponse
});
