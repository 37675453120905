export const sortNodesAndChildren = nodes => {
  nodes.sort((prevNode, nextNode) => {
    const prevName = prevNode.name.toLowerCase();
    const nextName = nextNode.name.toLowerCase();
    if (prevName < nextName) {
      return -1;
    }
    if (prevName > nextName) {
      return 1;
    }
    return 0;
  });
  nodes.forEach(node => {
    if (node?.children) {
      sortNodesAndChildren(node.children);
    }
  });
};
