import "react-grid-layout/css/styles.css";
import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { getLogin } from "state/selectors";
import { DEFAULT_MESSAGES } from "../../../utils/appConstants";
import { BRAND_NAMES } from "utils/appConstants";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
/**
 * Parent component that loads and renders dropdown
 */
class SelectDropdown extends Component {
  /**
   * Add "Select All" option to list of options
   */
  addSelectAll = options => {
    if (options && options.length > 4) {
      this.dropdownOptions = [
        {
          label: "select_all",
          value: "select-all"
          // value: this.props.intl.formatMessage({
          //   id: "select_all",
          //   defaultMessage: "Select All"
          // })
        }
      ].concat(options);
    }
  };

  /**
   * function to toggle display of node
   * @argument node : element to be updated
   * @argument value : value of display attribute to be assigned to node
   */
  toggleLabel = (node, value) => {
    node.style.display = value;
  };

  /**
   * function to handle events when menu is opened
   */
  handleMenuOpen = () => {
    if (this.props.isMultiSelect) {
      let nodeSelect = this.node && this.node.querySelector(".Select-value");
      nodeSelect && this.toggleLabel(nodeSelect, "none");
      let nodeClear =
        this.node && this.node.querySelector(".Select-clear-zone");
      nodeClear && this.toggleLabel(nodeClear, "none");

      this.node && this.node.querySelector("div.Select-input > input").focus();

      let flag = false;
      if (
        this.props.selectedOption.length === this.props.dropdownOptions.length
      ) {
        flag = true;
      }
      this.handleSelectAllCheckbox(flag);
    }
  };

  /**
   * function to handle events when menu is closed
   */
  handleMenuClose = () => {
    let nodeSelect = this.node && this.node.querySelector(".Select-value");
    nodeSelect && this.toggleLabel(nodeSelect, "inline-block");
    let nodeClear = this.node && this.node.querySelector(".Select-clear-zone");
    nodeClear && this.toggleLabel(nodeClear, "table-cell");
  };

  /**
   * function to handle checked status of select-all checkbox
   * @param {boolean} flag - true if checkbox to be checked, else false
   */
  handleSelectAllCheckbox = flag => {
    const chkBox =
      this.node && this.node.querySelector("input.select-all-checkbox");
    if (chkBox) {
      chkBox.checked = flag;
    }
  };

  /**
   * to handle change of options
   * @param {Object} option
   */
  handleSelectChange = option => {
    if (this.props.isMultiSelect) {
      let flag = false;
      let selectedOptions = [].concat(option);
      if (
        option.length > 0 &&
        option[option.length - 1].value === "select-all"
      ) {
        if (selectedOptions.length === this.dropdownOptions.length) {
          selectedOptions = [];
        } else {
          selectedOptions = [].concat(this.props.dropdownOptions);
        }
      }
      if (selectedOptions.length === this.props.dropdownOptions.length) {
        flag = true;
      }
      this.handleSelectAllCheckbox(flag);
      this.props.getSelectedOption(this.props.optionType, selectedOptions);
    } else {
      this.props.getSelectedOption(option.value);
    }
  };

  /**
   * to show custom text for dropdown button text
   * @return - markup with count of selected option
   */
  showDropdownText = () => {
    let selectedOptions = [].concat(this.props.selectedOption);
    if (
      selectedOptions.length > 0 &&
      selectedOptions[0].value === "select-all"
    ) {
      selectedOptions.shift();
    }
    if (selectedOptions.length > 1) {
      let count = selectedOptions.length;
      return (
        <label>
          <FormattedBrandMessage
            id="selectedTagsCount"
            values={{ count }}
            defaultMessage={DEFAULT_MESSAGES.CURRENT_SEARCH_CRITERIA_TAG}
          />
        </label>
      );
    } else {
      let labelToDisplay =
        this.props.selectedOption.length > 0 &&
        this.props.selectedOption[0].label;
      return (
        <label>
          {this.props.hasi18nSupport && labelToDisplay ? (
            <FormattedBrandMessage
              id={labelToDisplay}
              defaultMessage={labelToDisplay}
            />
          ) : (
            labelToDisplay
          )}
        </label>
      );
    }
  };

  /**
   * function to load options for single select dropdown
   * @param {object} option - object of selected option
   */
  renderSingleSelectOption = option => {
    return (
      <label className="menu-item">
        {this.props.hasi18nSupport ? (
          <FormattedBrandMessage
            id={option.label}
            defaultMessage={option.label}
          />
        ) : (
          option.label
        )}
      </label>
    );
  };

  /**
   * function to load options for Multi select dropdown
   * @param {object} option - object of selected options
   */
  renderMultiSelectOption = option => {
    let classIdentifier = "";
    let isSelectAllOption = false;
    if (option.value === "select-all") {
      classIdentifier = "select-all-checkbox";
      isSelectAllOption = true;
    }
    return (
      <div>
        <input className={classIdentifier} type="checkbox" />
        <label className="menu-item">
          {this.props.hasi18nSupport || isSelectAllOption ? (
            <FormattedBrandMessage
              id={option.label}
              defaultMessage={option.label}
            />
          ) : (
            option.label
          )}
        </label>
      </div>
    );
  };

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  render() {
    //  this.dropdownOptions = this.props.dropdownOptions || [];
    let brandNames = _.get(this.props, "brand_names", BRAND_NAMES);
    this.dropdownOptions =
      this.props.dropdownOptions.map(option => {
        return {
          ...option,
          label: this.props.hasi18nSupportByLabel
            ? this.props.intl.formatMessage(
                {
                  id: option.label,
                  defaultMessage: option.label
                },
                { ...brandNames }
              )
            : this.props.hasi18nSupport
            ? this.props.intl.formatMessage(
                {
                  id: option.value,
                  defaultMessage: option.value
                },
                { ...brandNames }
              )
            : option.label
        };
      }) || [];
    if (this.props.isMultiSelect) {
      this.addSelectAll(this.dropdownOptions);
    }

    let renderOption = this.props.isMultiSelect
      ? this.renderMultiSelectOption
      : this.renderSingleSelectOption;
    let noResultsText = this.props.intl.formatMessage({
      id: this.props.noResultsText,
      defaultMessage: "No results found"
    });
    let placeholder = this.props.intl.formatMessage({
      id: this.props.placeholder,
      defaultMessage: this.props.placeholder
    });
    // this is to remove references of deleted filters
    let selectedOption = _.intersectionBy(
      this.props.selectedOption,
      this.dropdownOptions,
      "value"
    );
    return (
      <div ref={node => (this.node = node)}>
        <Select
          className={this.props.class}
          options={this.dropdownOptions}
          placeholder={placeholder}
          closeOnSelect={this.props.isMenuCloseOnSelect}
          disabled={this.props.isDropdownDisabled}
          multi={this.props.isMultiSelect}
          removeSelected={false}
          autosize={false}
          onChange={this.handleSelectChange}
          onOpen={this.handleMenuOpen}
          onClose={this.handleMenuClose}
          optionRenderer={renderOption}
          scrollMenuIntoView={false}
          valueRenderer={this.showDropdownText}
          value={selectedOption}
          onSelectResetsInput={false}
          onCloseResetsInput
          onBlurResetsInput
          noResultsText={noResultsText}
        />
      </div>
    );
  }
}

SelectDropdown.defaultProps = {
  hasi18nSupport: true,
  isMultiSelect: true,
  isMenuCloseOnSelect: false,
  isDropdownDisabled: false,
  selectedOption: [],
  class: "",
  noResultsText: "select.noResults"
};

SelectDropdown.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string.isRequired,
  isMenuCloseOnSelect: PropTypes.bool,
  isDropdownDisabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  selectedOption: PropTypes.arrayOf(PropTypes.shape({})),
  getSelectedOption: PropTypes.func.isRequired,
  optionType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  class: PropTypes.string,
  hasi18nSupport: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  noResultsText: PropTypes.string
};

const makeMapStateToProps = () => {
  const mapStateToProps = state => ({
    brand_names: getLogin(state).brand_names
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(injectIntl(SelectDropdown));
