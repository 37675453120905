import * as ccGraphConstants from "state/constants/ccGraph";

export const initialize = (ccGraphId, config = {}, additionalParameters) => ({
  type: ccGraphConstants.CCGRAPH_INITIALIZE,
  ccGraphId,
  config,
  additionalParameters
});

export const fetchData = (
  ccGraphId,
  filteredData = null,
  additionalParams = null,
  initial = false
) => ({
  type: ccGraphConstants.CCGRAPH_FETCH_DATA_REQUEST,
  ccGraphId,
  filteredData,
  additionalParams,
  initial
});

export const fetchDataSuccess = (ccGraphId, response, data) => ({
  type: ccGraphConstants.CCGRAPH_FETCH_DATA_SUCCESS,
  ccGraphId,
  response,
  data
});

export const fetchDataFailure = (ccGraphId, response, errors) => ({
  type: ccGraphConstants.CCGRAPH_FETCH_DATA_FAILURE,
  ccGraphId,
  response,
  errors
});

/*
1d, 7d, 14d, 1m etc works?
{
  type: "CCGRAPH/UPDATE_FILTERS_REQUEST",
  ccGraphId: "CloudDirectUsage2",
  values: { range: "custom", start_ts: "1547705600", end_ts: "1547711999" },
  refreshGrid: true
}
{
  type: "CCGRAPH/UPDATE_FILTERS_REQUEST",
  ccGraphId: "CloudDirectUsage",
  values: { range: "7d" },
  refreshGrid: true
}
*/

export const updateFilters = (ccGraphId, values, refreshGrid = true) => ({
  type: ccGraphConstants.CCGRAPH_UPDATE_FILTERS_REQUEST,
  ccGraphId,
  values,
  refreshGrid
});

export const resetFilters = (ccGraphId, refreshGrid = true) => ({
  type: ccGraphConstants.CCGRAPH_RESET_FILTERS_REQUEST,
  ccGraphId,
  refreshGrid
});
