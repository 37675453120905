import { all, select, takeLatest, call, put } from "redux-saga/effects";
import get from "lodash/get";
import { HTTP_STATUS, API_RESPONSE_CONTEXT } from "utils/appConstants";
import {
  SUBMIT_VM,
  FETCH_CLOUD_SUBNET_AZURE,
  GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB
} from "state/constants/createStandbyVM";
import { getLogin, getLoginToken } from "state/selectors";
import {
  addSuccessNotification,
  addErrorNotification
} from "state/actions/ccToast";
import { showErrToastFromResponse, makeToastData } from "../sagautils";

import {
  Loader,
  setLoading,
  resetLoading,
  hideDialog,
  Dialog
} from "../../actions/actions";
import { protectApi, destinationApis } from "../../api";

import {
  submitVMSuccess,
  storeAzureCloudSubnet,
  GCPSecurityGroupsFetchSuccessForVsb,
  clearGCPSecurityGroupsForVsb
} from "../../actions/createStandbyVM";

/**
 * fetch downdloads from api and store it in the
 * store
 */
export function* submitVM({ srcId, data }) {
  try {
    const loginReducer = yield select(getLogin);
    const payload = get(data, "payload", {});
    const resp = {};
    yield put(setLoading(Loader.FINISH_CREATE_STANDBY_VM));
    if (payload) {
      const resp = yield call(
        protectApi.submitVM,
        data.id,
        payload,
        loginReducer.token
      );
      if (resp.status === HTTP_STATUS.OK) {
        if (data && data.history) {
          data.history.push(`/protect/recovered_resources/virtual_standbys`);
        }
        yield put(
          addSuccessNotification(
            makeToastData(API_RESPONSE_CONTEXT.CREATE_VM_SUCCESS, "9RAPKG")
          )
        );
        //   yield put(getProtectListCount());
        yield put(hideDialog(Dialog.CREATE_STANDBY_VM_WIZARD));
      }
    }

    if (resp.status === HTTP_STATUS.OK) {
      yield put(submitVMSuccess(resp));
      yield put(
        addSuccessNotification(
          makeToastData(API_RESPONSE_CONTEXT.VSB_CONNECT_SUCCESS, "74r6G3")
        )
      );
    } else {
      showErrToastFromResponse(resp);
      yield put(resetLoading(Loader.FINISH_CREATE_STANDBY_VM));
    }
  } catch (error) {
    window.console.log(error);
    yield put(resetLoading(Loader.FINISH_CREATE_STANDBY_VM));
  }
}
export function* fetchCloudAccountSubnetSaga({ data }) {
  try {
    if (data) {
      const token = yield select(getLoginToken);
      const resp = yield call(
        destinationApis.fetchCloudAccountSubnet,
        token,
        data.payload,
        data.cloudId
      );
      if (resp.status === HTTP_STATUS.OK) {
        yield put(storeAzureCloudSubnet(resp.data));
      } else {
        yield put(
          addErrorNotification(
            makeToastData(resp.errors[0].message, `rsea1s_${resp?.status}`)
          )
        );
      }
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* getGCPSecurityGroupsForSelectedNetwork({ data }) {
  try {
    const token = yield select(getLoginToken);
    yield put(clearGCPSecurityGroupsForVsb());
    const resp = yield call(
      destinationApis.getGCPSecurityGroupsForSelectedNetwork,
      token,
      data
    );
    if (get(resp, "status", null) === HTTP_STATUS.OK) {
      yield put(GCPSecurityGroupsFetchSuccessForVsb(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export default function* VMSettingsaga() {
  return yield all([
    takeLatest(SUBMIT_VM, submitVM),
    takeLatest(FETCH_CLOUD_SUBNET_AZURE, fetchCloudAccountSubnetSaga),
    takeLatest(
      GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB,
      getGCPSecurityGroupsForSelectedNetwork
    )
  ]);
}
