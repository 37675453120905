import React, { PureComponent } from "react";
import Iframe from "react-iframe";
import { FormattedMessage } from "react-intl";
import * as API_URL from "state/api/apiUrlConstants";
import jaToS from "assets/arcserve-cloud-services-terms-of-service-jp.pdf";
/**
 * Parent component to render advanced search overlay
 */

const HOST_BE_URL = `${process.env.REACT_APP_BE_URL}/`;
class TermsOfService extends PureComponent {
  constructor() {
    super();
    this.state = {
      tosUrl: ""
    };
  }
  componentDidMount() {
    fetch(`${HOST_BE_URL}language`)
      .then(blob => blob.json())
      .then(json => {
        const { data: language } = json;
        //here we are using fetchLanguage because of issue in IE and Edge, getBrowserLocale doesn't work properly there
        if (language === "ja") {
          this.setState({ tosUrl: jaToS });
        } else {
          fetch(API_URL.FETCH_TERMS_OF_SERVICE_URL)
            .then(blob => blob.json())
            .then(json => {
              let url = json.data.url;
              if (json.data.url.charAt(0) === "/") {
                url = json.data.url.slice(1, json.data.url.length);
              }
              this.setState({
                tosUrl: `${process.env.REACT_APP_CLOUDVOLUME_HOST_URL}${url}`
              });
            });
        }
      });
  }

  render() {
    return (
      <div className="tos flex-column">
        <div className="tos-header">
          <FormattedMessage id="termsOfService.header" />
        </div>
        {this.state.tosUrl !== "" && (
          <div className="tos-body">
            <Iframe
              url={this.state.tosUrl}
              width="100%"
              height="100%"
              id="myId"
              className=""
              display="initial"
              position="relative"
              allowFullScreen
            />
          </div>
        )}
      </div>
    );
  }
}

export default TermsOfService;
