import { select, all, call, put, takeEvery } from "redux-saga/effects";
import { getLogin, getLoginToken, getOrgId } from "state/selectors";
import * as constant from "utils/appConstants";
import * as ccToast from "state/actions/ccToast";
import { destinationApis, protectApi } from "state/api";
import * as createStandbyVMConstants from "state/constants/createStandbyVM";
import { v4 as uuidv4 } from "uuid";
import get from "lodash/get";
import { resetCCDataGridLoader } from "state/actions/ccDataGrid";
import {
  onGetDestinationsBySourceIdforVsb,
  saveRPPasswordForVsb,
  storeSelectedRecoveryPointForVsb,
  validateRecoveryPointForVSB,
  storeValidateConfigdataForVsb,
  changeCreateStandbyVMTab,
  fetchProxiesVSBSuccess
} from "../../actions/createStandbyVM";
import { setLoading, resetLoading, Loader } from "../../actions/actions";
import { makeToastData, showErrToastFromResponse } from "../sagautils";

export function* getDestinationsBySourceIdforVsb(action) {
  try {
    const login = yield select(getLogin);
    const resp = yield call(
      destinationApis.getDestinationsDataBySourceId,
      action,
      login.token
    );

    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(onGetDestinationsBySourceIdforVsb(resp.data));
      if (resp.data && resp.data.length === 0) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "recovery.sources.no_recovery_points",
              `uQpL0O_${resp?.status}`
            )
          )
        );
      }
    } else if (
      resp &&
      resp.errors[0] &&
      resp.errors[0].code === "25769803777"
    ) {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            "recovery.sources.no_recovery_points",
            `S7F2cM_${resp?.status}`
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            get(resp, "errors[0].message", ""),
            `S7F2cM_${resp?.status}`
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* storeNewVSBRecoveryPoint({ data }) {
  try {
    yield put(setLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
    const token = yield select(getLoginToken);
    const passwordValidation = yield call(
      protectApi.verifyEncryptionPassword,
      data.payload,
      data.id,
      token
    );
    if (
      passwordValidation.status === constant.HTTP_STATUS.OK &&
      passwordValidation.data.verification_result
    ) {
      yield put(saveRPPasswordForVsb(data.payload.password));
      yield put(storeSelectedRecoveryPointForVsb(data.rp));
      yield put(
        validateRecoveryPointForVSB({
          payload: data.verifyRPPayloadConfig,
          id: data.id
        })
      );
    } else {
      if (passwordValidation.errors && passwordValidation.errors.length === 0)
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              constant.API_RESPONSE_CONTEXT.PASSWORD_VERIFICATION_FAILED,
              `syLaHm_${passwordValidation?.status}`
            )
          )
        );
      yield put(resetLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
    }
  } catch (e) {
    window.console.log(e);
    yield put(resetLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
  }
}

export function* validateRecoveryPointSagaForVSB({ data }) {
  try {
    yield put(setLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
    const token = yield select(getLoginToken);
    const payload = get(data, "payload", {});

    if (Object.keys(payload).length === 0) {
      return;
    }

    const rpValidation = yield call(
      protectApi.validateRecoveryPointConfiguration,
      payload,
      data.id,
      token
    );
    if (rpValidation.status === constant.HTTP_STATUS.OK) {
      if (Object.keys(rpValidation.data).length === 0) return;
      //eslint-disable-next-line eqeqeq
      if (rpValidation.data.has_boot_volume == false) {
        //eslint-disable-next-line eqeqeq
        if (rpValidation.data.has_system_volume == false) {
          yield put(
            ccToast.addErrorNotification(
              makeToastData(
                "toast-errors.noBoot_noSystemVolume",
                `V90r06_${rpValidation?.status}`
              )
            )
          );
          return;
        }
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "toast-errors.noBootVolume",
              `DID1eO_${rpValidation?.status}`
            )
          )
        );
        return;
      }
      //eslint-disable-next-line eqeqeq
      if (rpValidation.data.has_system_volume == false) {
        yield put(
          ccToast.addErrorNotification(
            makeToastData(
              "toast-errors.noSystemVolume",
              `RzIHE2_${rpValidation?.status}`
            )
          )
        );
        return;
      }
      const configData = rpValidation.data;
      if (configData && configData.network_adapters)
        configData.network_adapters = configData.network_adapters.map(
          adapter => {
            adapter.key = uuidv4();
            return adapter;
          }
        );
      yield put(storeValidateConfigdataForVsb(configData));
      yield put(changeCreateStandbyVMTab(2));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.TRAVERSAL_CREATE_STANDBY_VM));
  }
}

export function* fetchProxiesVsbSaga({
  data,
  srcId,
  srcType,
  destinationType
}) {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    let resp = {};
    if (srcType === "udp_windows" && destinationType === "local_disk") {
      const respObj = yield call(
        protectApi.fetchSourceById,
        srcId,
        login.token
      );
      resp = { ...respObj };
      resp.pagination = {
        curr_page: 1,
        total_page: 1,
        page_size: 25,
        has_prev: false,
        has_next: false,
        total_size: 1
      };
      resp.data = [
        {
          ...respObj.data,
          proxy_id: respObj.data && respObj.data.source_id,
          proxy_name: respObj.data && respObj.data.source_name
        }
      ];
    } else {
      resp = yield call(
        protectApi.getBackupProxy,
        `org_id=${orgId}&site_id=${data}`,
        login.token
      );
    }

    resp.context = constant.API_RESPONSE_CONTEXT.FETCH_PROXIES_IVM;
    if (resp.status === constant.HTTP_STATUS.OK) {
      resp &&
        resp.data &&
        resp.data.forEach(r => ({
          ...r,
          _rowId: r?.proxy_id
        }));
      yield put(fetchProxiesVSBSuccess(resp));
      yield put(
        resetCCDataGridLoader(
          constant.CCDATAGRID_ID.SELECT_CREATE_STANDBY_VM_PROXY
        )
      );
    } else {
      showErrToastFromResponse(resp);
    }
  } catch (error) {
    window.console.log(error);
  }
}

export default function* selectRecoveryPoint() {
  yield all([
    takeEvery(
      createStandbyVMConstants.GET_DESTINATIONS_BY_SOURCE_ID_FOR_VSB,
      getDestinationsBySourceIdforVsb
    ),
    takeEvery(
      createStandbyVMConstants.STORE_NEW_VSB_RP,
      storeNewVSBRecoveryPoint
    ),
    takeEvery(
      createStandbyVMConstants.VALIDATE_RP_VSB,
      validateRecoveryPointSagaForVSB
    )
  ]);
}
