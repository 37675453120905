/**
 *
 * index.js
 *
 * This is the entry file for the application, mostly just setup and boilerplate
 * code
 *
 */

// Import all the third party stuff
import "babel-polyfill";
import React from "react";
import { Provider, connect } from "react-redux";
import ReactDOM from "react-dom";
import * as actions from "state/actions/actions";
import "font-awesome/css/font-awesome.min.css";

//Import redux Store
import store from "./state/store";
// Import the components used as pages
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

const ConnectedApp = connect(
  () => ({}),
  dispatch => ({
    handleLogOut: () => {
      dispatch(actions.logoutRequest());
      dispatch(actions.unsetJWTToken());
      dispatch(actions.logOut());
    }
  })
)(App);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedApp />
  </Provider>,
  document.getElementById("root")
);

// Service worker appears to cause issues with serving stale files which causes
// blank screens. Commenting out for now.
// registerServiceWorker();
