import { createSelector } from "reselect";

import { getLogin } from "state/selectors";

// selectors (If props is required, use (state, props))
const activationMessage = state => getLogin(state).activation_message;
const enrollSuccessful = state => getLogin(state).createPasswordSuccess;
const enrollRedirectTo = state => getLogin(state).redirectTo;
const enrollRequesting = state => getLogin(state).enroll_requesting;
const enrollFailure = state => getLogin(state).cp_errors;
const forgotPasswordMessage = state => getLogin(state).forgotPasswordMessage;

// reselect functions
export const makeEnrollActivationMessageState = () =>
  createSelector([activationMessage], activationMessage => activationMessage);
export const makeEnrollSuccessfulState = () =>
  createSelector([enrollSuccessful], enrollSuccessful => enrollSuccessful);
export const makeEnrollFailureState = () =>
  createSelector([enrollFailure], enrollFailure => enrollFailure);
export const makeEnrollRedirectToState = () =>
  createSelector([enrollRedirectTo], enrollRedirectTo => enrollRedirectTo);
export const makeEnrollRequestingState = () =>
  createSelector([enrollRequesting], enrollRequesting => enrollRequesting);
export const makeForgotPasswordMessageState = () =>
  createSelector(
    [forgotPasswordMessage],
    forgotPasswordMessage => forgotPasswordMessage
  );
