import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import * as ccGraphActions from "state/actions/ccGraph";

import { CCGraphProvider } from "components/common/CCGraph";

const CCGraphUnconnected = props => {
  let pollTimer;
  const { config, children, organizationType } = props;
  useEffect(() => {
    const {
      initialize,
      fetchData,
      config: { pollInterval }
    } = props;
    organizationType && initialize();

    if (organizationType && pollInterval) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pollTimer = setInterval(() => {
        fetchData();
      }, pollInterval * 1000);
    }
    return () => {
      if (pollTimer) {
        clearInterval(pollTimer);
      }
    };
  }, [organizationType]);
  return (
    <CCGraphProvider
      value={{
        config
      }}
    >
      {children}
    </CCGraphProvider>
  );
};

CCGraphUnconnected.propTypes = {
  initialize: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  config: PropTypes.shape({
    pollInterval: PropTypes.number
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

const CCGraph = connect(
  state => ({
    organizationType: get(
      state,
      "login.organizationInfo.organization_type",
      null
    )
  }),
  (dispatch, ownProps) => ({
    initialize() {
      const { additionalParameters } = ownProps;
      const { ccGraphId, ...restConfig } = ownProps.config;
      dispatch(
        ccGraphActions.initialize(ccGraphId, restConfig, additionalParameters)
      );
    },
    fetchData() {
      const { ccGraphId } = ownProps.config;
      dispatch(ccGraphActions.fetchData(ccGraphId));
    }
  })
)(CCGraphUnconnected);

export default CCGraph;
