import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import FormattedMessage, {
  FormattedHTMLMessage
} from "components/common/formatted-message";
import { ID_PREFIX } from "configs/IdConfig";
import {
  updateGridCurrentPageNumber,
  updateGridCurrentPageSize
} from "../../../../../state/actions/actions";

const RowPerPageText = () => (
  <FormattedMessage id="rowsPerPageText" defaultMessage="Rows per page:" />
);

/**
 * Component to render custom pagination footer.
 */
const GridFooter = ({
  usePaginationWithApi,
  totalSize,
  setPageSize,
  handlePaginationApi,
  page,
  intl,
  testId,
  filterType,
  resourceId
}) => {
  const footerId = testId ? `${ID_PREFIX}${testId}` : "";
  // React-Redux Hooks
  const dispatch = useDispatch();
  const gridPageSize = useSelector(state => state.reducer.gridPageSize);
  const gridCurrentPage = useSelector(state => state.reducer.gridCurrentPage);

  const defaultRowOptions = [
    {
      value: 25,
      label: <span id={`${footerId}_per_page_menu_0`}>25</span>
    },
    {
      value: 50,
      label: <span id={`${footerId}_per_page_menu_1`}>50</span>
    }
  ];

  useEffect(() => {
    dispatch(updateGridCurrentPageNumber(page));
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * to handle page change
   * page change action dispatch to datagrid component
   */
  const handlePageSelect = newPage => {
    dispatch(updateGridCurrentPageNumber(Number(newPage) + 1));
    const tempgridPageSize = gridPageSize;
    if (usePaginationWithApi) {
      const currentPage = Number(newPage) + 1;
      handlePaginationApi({
        gridCurrentPage: currentPage,
        gridPageSize: tempgridPageSize,
        filter: filterType,
        resourceId
      });
    }
  };

  /**
   * to handle page size change
   * dropdown menu item action dispatch to datagrid component
   */
  const handleDropdownMenuItemClick = e => {
    const currentPageSize = Number(e.target.value);
    setPageSize(currentPageSize);
    dispatch(updateGridCurrentPageSize(currentPageSize));
    if (usePaginationWithApi) {
      const gridCurrentPageValue = 1;
      dispatch(updateGridCurrentPageNumber(gridCurrentPageValue));
      handlePaginationApi({
        gridCurrentPageValue,
        gridPageSize: currentPageSize,
        filter: filterType,
        resourceId
      });
    }
  };

  const labelDisplayedRows = ({ from, to, count }) => (
    <span id={`${footerId}_pagination`}>
      <FormattedHTMLMessage
        id="gridPaginationOnlyFromAndToText"
        values={{
          from,
          to
        }}
      />
      <FormattedHTMLMessage id="gridPaginationOnlyOfText" />
      <FormattedHTMLMessage
        id="gridPaginationOnlyCountText"
        values={{
          count
        }}
      />
    </span>
  );

  const TablePaginationActions = () => (
    <div className="MuiTablePagination-actions">
      <IconButton
        id={`${footerId}_pagination_previous`}
        onClick={() => handlePageSelect(currentPage - 1)}
        disabled={currentPage === 0}
        aria-label={intl.formatMessage({
          id: "goToPreviousPage",
          defaultMessage: "Go to previous page"
        })}
        title={intl.formatMessage({
          id: "goToPreviousPage",
          defaultMessage: "Go to previous page"
        })}
      >
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        id={`${footerId}_pagination_next`}
        onClick={() => handlePageSelect(currentPage + 1)}
        disabled={currentPage >= Math.ceil(totalSize / gridPageSize) - 1}
        aria-label={intl.formatMessage({
          id: "goToNextPage",
          defaultMessage: "Go to next page"
        })}
        title={intl.formatMessage({
          id: "goToNextPage",
          defaultMessage: "Go to next page"
        })}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );

  let currentPage = gridCurrentPage - 1;
  return (
    <TablePagination
      rowsPerPageOptions={defaultRowOptions}
      component="div"
      count={totalSize}
      rowsPerPage={gridPageSize}
      page={currentPage}
      onPageChange={() => ({})}
      onRowsPerPageChange={handleDropdownMenuItemClick}
      labelRowsPerPage={<RowPerPageText />}
      labelDisplayedRows={labelDisplayedRows}
      ActionsComponent={TablePaginationActions}
    />
  );
};

GridFooter.defaultProps = {
  usePaginationWithApi: false,
  filterType: "",
  resourceId: ""
};

GridFooter.propTypes = {
  usePaginationWithApi: PropTypes.bool,
  totalSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  handlePaginationApi: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  testId: PropTypes.string.isRequired,
  filterType: PropTypes.string,
  resourceId: PropTypes.string
};

export default React.memo(injectIntl(GridFooter));
