import { connect } from "react-redux";
import { change } from "redux-form";

import { setOrganizationsSearchString } from "state/actions/csr";
import { getOrganizationsSearchString } from "state/selectors/csr";
import { formName as newUserForm } from "components/user-search/components/NewUserModal";

import OrgSearch from "./OrgSearch";

export default connect(
  state => ({
    searchString: getOrganizationsSearchString(state)
  }),
  dispatch => ({
    setOrganizationsSearchString: data =>
      dispatch(setOrganizationsSearchString(data)),
    resetOrganizationId: value => {
      dispatch(change(newUserForm, "organizationId", value));
    }
  })
)(OrgSearch);
