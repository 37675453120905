import moment from "moment";

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
}

function checkAuthorization(loginToken) {
  if (!loginToken) {
    return { redirect: false, tokenExpire: false };
  }
  const parsedToken = parseJwt(loginToken);
  return {
    redirect: moment(parsedToken.exp * 1000).diff(moment(), "minutes") > 0,
    tokenExpire: moment(parsedToken.exp * 1000).diff(moment(), "minutes") < 0
  };
}

export default checkAuthorization;
