/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import GridFooterWithApiAndWithoutPageNumber from "./PaginationFooter/GridFooterWithApi/WithoutPageNumber";
import GridFooterWithApiAndWithPageNumber from "./PaginationFooter/GridFooterWithApi/WithPageNumber";
import GridFooterWithoutApiAndWithoutPageNumber from "./PaginationFooter/GridFooterWithoutApi/WithoutPageNumber";
import GridFooterWithoutApiAndWithPageNumber from "./PaginationFooter/GridFooterWithoutApi/WithPageNumber";

/**
 * Component to render custom pagination footer.
 */
const GridFooter = props => {
  const { usePaginationWithApi, usePaginationWithPageNumber } = props;
  return (
    <div>
      {usePaginationWithApi ? (
        usePaginationWithPageNumber ? (
          <GridFooterWithApiAndWithPageNumber {...props} />
        ) : (
          <GridFooterWithApiAndWithoutPageNumber {...props} />
        )
      ) : usePaginationWithPageNumber ? (
        <GridFooterWithoutApiAndWithPageNumber {...props} />
      ) : (
        <GridFooterWithoutApiAndWithoutPageNumber {...props} />
      )}
    </div>
  );
};

GridFooter.defaultProps = {
  usePaginationWithApi: false,
  usePaginationWithPageNumber: false
};

GridFooter.propTypes = {
  usePaginationWithApi: PropTypes.bool,
  usePaginationWithPageNumber: PropTypes.bool
};

export default React.memo(GridFooter);
