import { all } from "redux-saga/effects";
import selectRecoveryPoint from "./SelectRecoveryPoint";
import recoveryServer from "./RecoveryServer";
import VMLocationSaga from "./VMLocation";
import VMSettingsaga from "./VMSettings";

export default function* createStandbyVMSaga() {
  return yield all([
    VMLocationSaga(),
    selectRecoveryPoint(),
    recoveryServer(),
    VMSettingsaga()
  ]);
}
