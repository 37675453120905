import * as ccDataGridConstants from "state/constants/ccDataGrid";

export const initialize = (
  ccDataGridId,
  config = {},
  additionalParameters = {},
  defaultAdvancedSearchOptions = []
) => ({
  type: ccDataGridConstants.CCDATAGRID_INITIALIZE,
  ccDataGridId,
  config: {
    defaultPageSize: 25,
    defaultPage: 1,
    localSearch: false,
    ...config
  },
  additionalParameters,
  defaultAdvancedSearchOptions
});

// fetch stuff
export const fetchData = (
  ccDataGridId,
  init = false,
  polling = false,
  additionalParameters = undefined
) => ({
  type: ccDataGridConstants.CCDATAGRID_FETCH_DATA_REQUEST,
  ccDataGridId,
  init,
  polling,
  additionalParameters
});

export const resetCCDataGridLoader = ccDataGridId => ({
  type: ccDataGridConstants.RESET_CCDATAGRID,
  ccDataGridId
});

export const startCCDataGridLoader = ccDataGridId => ({
  type: ccDataGridConstants.START_LOADER,
  ccDataGridId
});

export const fetchDataSuccess = (
  ccDataGridId,
  response,
  data,
  pagination = true
) => ({
  type: ccDataGridConstants.CCDATAGRID_FETCH_DATA_SUCCESS,
  ccDataGridId,
  response,
  data,
  pagination
});

export const UpdateLocalData = (
  ccDataGridId,
  response,
  data,
  pagination = true
) => ({
  type: ccDataGridConstants.CCDATAGRID_UPDATE_LOCAL_DATA,
  ccDataGridId,
  response,
  data,
  pagination
});

export const fetchDataFailure = (ccDataGridId, errors) => ({
  type: ccDataGridConstants.CCDATAGRID_FETCH_DATA_FAILURE,
  ccDataGridId,
  errors
});

// state stuff
export const updateSorted = (ccDataGridId, sorted, refreshGrid = true) => ({
  type: ccDataGridConstants.CCDATAGRID_UPDATE_SORTED,
  ccDataGridId,
  sorted,
  refreshGrid
});

export const updatePage = (
  ccDataGridId,
  page,
  refreshGrid = true,
  update = false
) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_UPDATE_PAGE,
    ccDataGridId,
    page,
    refreshGrid,
    update
  };
};

export const updatePageLocally = (ccDataGridId, page) => ({
  type: ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_LOCALLY,
  ccDataGridId,
  page
});

export const updatePageSize = (ccDataGridId, pageSize, refreshGrid = true) => ({
  type: ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_SIZE,
  ccDataGridId,
  pageSize,
  refreshGrid
});

export const updateColumns = (ccDataGridId, columns, refreshGrid = true) => ({
  type: ccDataGridConstants.CCDATAGRID_STORE_COLUMNS,
  ccDataGridId,
  columns,
  refreshGrid
});

export const uncheckAll = ccDataGridId => ({
  type: ccDataGridConstants.CCDATAGRID_UNCHECK_ALL,
  ccDataGridId
});

export const clearCCDataGrid = ccDataGridId => ({
  type: ccDataGridConstants.CCDATAGRID_CLEAR_DATA,
  ccDataGridId
});

export const checkRows = (ccDataGridId, ...rowIds) => ({
  type: ccDataGridConstants.CCDATAGRID_CHECK_ROWS,
  ccDataGridId,
  rowIds
});

export const uncheckRow = (ccDataGridId, rowId) => ({
  type: ccDataGridConstants.CCDATAGRID_UNCHECK_ROW,
  ccDataGridId,
  rowId
});

export const showAdvancedSearchForm = ccDataGridId => ({
  type: ccDataGridConstants.CCDATAGRID_SHOW_ADVANCED_SEARCH_FORM,
  ccDataGridId
});

export const hideAdvancedSearchForm = ccDataGridId => ({
  type: ccDataGridConstants.CCDATAGRID_HIDE_ADVANCED_SEARCH_FORM,
  ccDataGridId
});

export const advancedSearch = (ccDataGridId, values, refreshGrid = true) => ({
  type: ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH,
  ccDataGridId,
  values,
  refreshGrid
});

export const clearAdvancedSearch = (ccDataGridId, refreshGrid = true) => ({
  type: ccDataGridConstants.CCDATAGRID_CLEAR_ADVANCED_SEARCH,
  ccDataGridId,
  refreshGrid
});

export const showHideSaveSearchNClearAll = (ccDataGridId, data) => ({
  type: ccDataGridConstants.CCDATAGRID_SHOW_SAVEDSEARCH_CLEARALL_LINK,
  ccDataGridId,
  data
});

export const removeSearchTagsReload = (ccDataGridId, data) => ({
  type: ccDataGridConstants.CCDATAGRID_REMOVE_SEARCH_TAGS_RELOAD,
  ccDataGridId,
  data
});

export const ccDataGridShowCurrentSearch = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_SHOW_CURRENT_SEARCH,
    ccDataGridId,
    data
  };
};

export const isAdvancedSearchOptionsSelected = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH_OPTIONS_SELECTED,
    ccDataGridId,
    data
  };
};
//Advanced Search Options Function
export const fetchAdvancedSearchOptions = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH_OPTIONS,
    ccDataGridId,
    data
  };
};

export const fetchAdvancedSearchOptionsUnChanged = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH_OPTIONS_UNCHANGED,
    ccDataGridId,
    data
  };
};

//Options for Tags when AdvancedSearch is clicked
export const fetchOptionsForTags = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_OPTIONS_FOR_TAGS,
    ccDataGridId,
    data
  };
};

export const resetOptionsForTags = ccDataGridId => {
  return {
    type: ccDataGridConstants.CCDATAGRID_RESET_OPTIONS_FOR_TAGS,
    ccDataGridId
  };
};

export const setSelectedSavedSearch = (
  ccDataGridId,
  data,
  filter_id = "",
  filter_name = ""
) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_SELECTED_SAVED_SEARCH,
    ccDataGridId,
    data,
    filter_id,
    filter_name
  };
};

export const resetSelectedSavedSearch = ccDataGridId => {
  return {
    type: ccDataGridConstants.CCDATAGRID_RESET_SELECTED_SAVED_SEARCH,
    ccDataGridId
  };
};

export const resetAdvancedSearchOptions = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_RESET_ADVANCED_SEARCH_OPTIONS,
    ccDataGridId,
    data
  };
};

export const setUserSearchData = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_USER_SEARCH_DATA,
    ccDataGridId,
    data
  };
};
export const setSystemSearchData = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_SYSTEM_SEARCH_DATA,
    ccDataGridId,
    data
  };
};

export const setShowSaveSearchModal = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_SHOW_SAVE_SEARCH_MODAL,
    ccDataGridId,
    data
  };
};

export const setSaveSearchText = (ccDataGridId, data = "") => {
  return {
    type: ccDataGridConstants.CCDATAGRID_SET_SAVE_SEARCH_TEXT,
    ccDataGridId,
    data
  };
};

export const setManageSelectedSavedSearch = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_MANAGE_SELECTED_SAVE_SEARCH,
    ccDataGridId,
    data
  };
};

export const setManageSaveSearchOptions = (
  ccDataGridId,
  data,
  actualOptions = [],
  organization_type,
  isImpersonationView
) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_MANAGE_SAVE_SEARCH_OPTIONS,
    ccDataGridId,
    data,
    actualOptions,
    organization_type,
    isImpersonationView
  };
};

export const updateManageSaveSearchOptions = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_UPDATE_MANAGE_SAVE_SEARCH_OPTIONS,
    ccDataGridId,
    data
  };
};

export const invokeCCSavedSearch = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_SAVED_SEARCH,
    ccDataGridId,
    data
  };
};

export const invokeAdvancedSearchOptions = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_ADVANCED_SEARCH_OPTIONS,
    ccDataGridId,
    data
  };
};
export const invokeSelectedSavedSearch = action => {
  let {
    ccDataGridId,
    filter_id = "",
    filter_name = "",
    defaultValue = false,
    init = false,
    data
  } = action;
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_SELECTED_SAVED_SEARCH,
    ccDataGridId,
    filter_id,
    filter_name,
    defaultValue,
    init,
    data
  };
};

export const invokeManageSavedSearch = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH,
    ccDataGridId,
    data
  };
};

export const invokeDeleteSavedSearch = (ccDataGridId, id) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_DELETE_SAVED_SEARCH,
    ccDataGridId,
    id
  };
};

export const invokeManageSavedSearchOptions = (
  ccDataGridId,
  data,
  organization_type,
  isImpersonationView
) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH_OPTIONS,
    ccDataGridId,
    data,
    organization_type,
    isImpersonationView
  };
};

export const UpdateSourceInCCGrid = (ccDataGrid, changedSource, changedKey) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_UPDATE_SOURCE_IN_CCDATAGRID,
    ccDataGrid,
    changedSource,
    changedKey
  };
};

export const deleteSourceInCCDataGrid = (ccDataGrid, id) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_DELETE_SOURCE_IN_CCDATAGRID,
    ccDataGrid,
    id
  };
};

export const deletePolicyInCCDataGrid = (ccDataGrid, id) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_DELETE_POLICY_IN_CCDATAGRID,
    ccDataGrid,
    id
  };
};

export const isAdvancedSearchOptionsConsidered = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_CONSIDER_ADVANCED_SEARCH_OPTIONS,
    ccDataGridId,
    data
  };
};

export const resetAdvancedSearchOptionsConsidered = (ccDataGridId, data) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_RESET_CONSIDER_ADVANCED_SEARCH_OPTIONS,
    ccDataGridId
  };
};

export const fetchColumnsCCDataGrid = (stateColumnData, columnData) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_FETCH_COLUMNS,
    stateColumnData,
    columnData
  };
};

export const ccDataGridReOrderColumns = (
  data,
  oldItem,
  newItem,
  stateColumnData,
  stateFiltertype
) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_INVOKE_REORDER_COLUMN_DATA,
    data,
    oldItem,
    newItem,
    stateColumnData,
    stateFiltertype
  };
};

export const manageGridCheckBoxSettings = (data, stateData, filterType) => {
  return {
    type: ccDataGridConstants.CCDATAGRID_GRID_CHECKBOX_SETTINGS,
    data,
    stateData,
    filterType
  };
};
