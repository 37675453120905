const dataUserRoles = {
  status: 200,
  data: [
    //Durect user

    //   Super Admin direct_admin

    {
      role_id: "direct_admin",
      role_name: "direct_super_admin_role_name",
      role_description: "direct_super_admin_role_description",
      organization_type: "direct",
      user_type: "admin",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["view", "manage"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: ["view", "manage"]
        }
      ]
    },

    //   Admin direct_admin
    {
      role_id: "direct_admin",
      role_name: "direct_admin_role_name",
      role_description: "direct_admin_role_description",
      organization_type: "direct",
      user_type: "user",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["view", "manage"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },

    // direct_monitor Monitor
    {
      role_id: "direct_monitor",
      role_name: "direct_monitor_role_name",
      role_description: "direct_monitor_role_description",
      organization_type: "direct",
      user_type: "user",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["view", "manage"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["view", "manage"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },

    //   direct_recovery Recovery

    {
      role_id: "direct_recovery_admin",
      role_name: "direct_recovery_admin_role_name",
      role_description: "direct_recovery_admin_role_description",
      organization_type: "direct",
      user_type: "user",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["view"]
        },

        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["view", "manage"],
          text: "msp_text_recovery"
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["view"]
        },
        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },

    //    //msp_admin MSP Super Admin
    {
      role_id: "msp_admin",
      role_name: "msp_super_admin_role_name",
      role_description: "msp_super_admin_role_description",
      organization_type: "msp",
      user_count: 0,
      user_type: "admin",
      permissions: [
        {
          navigation: "dashboard", //dashboard
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp"],
          text: "msp_text_n/a"
        }
      ]
    },
    //msp_admin MSP Admin
    {
      role_id: "msp_admin",
      role_name: "msp_admin_role_name",
      role_description: "msp_admin_role_description",
      organization_type: "msp",
      user_count: 0,
      user_type: "user",
      permissions: [
        {
          navigation: "dashboard", //dashboard
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    //   msp_monitor MSP Monitor
    {
      role_id: "msp_monitor",
      role_name: "msp_monitor_role_name",
      role_description: "msp_monitor_role_description",
      organization_type: "msp",
      user_type: "user",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    //   msp_recovery_admin MSP Recovery
    {
      role_id: "msp_recovery_admin",
      role_name: "msp_recovery_admin_role_name",
      role_description: "msp_recovery_admin_role_description",
      organization_type: "msp",
      user_count: 0,
      user_type: "user",
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewinmsp", "viewintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp", "viewintenant", "manageintenant"],
          text: "msp_text_recovery"
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "viewintenant"] //need to check for manage in tenant
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    //   MSP Account Admin msp_account_admin

    {
      role_id: "msp_account_admin",
      role_name: "msp_account_admin_role_name",
      role_description: "msp_account_admin_role_description",
      organization_type: "msp",
      user_count: 0,
      user_type: "user",
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    //   MSP Account Monitor msp_account_monitor

    {
      role_id: "msp_account_monitor",
      role_name: "msp_account_monitor_role_name",
      role_description: "msp_account_monitor_role_description",
      organization_type: "msp",
      user_count: 0,
      user_type: "user",
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "manageinmsp", "viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    //    MSP Account Recovery msp_account_recovery_admin

    {
      role_id: "msp_account_recovery_admin",
      role_name: "msp_account_recovery_admin_role_name",
      role_description: "msp_account_recovery_admin_role_description",
      organization_type: "msp",
      user_type: "user",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewinmsp", "viewintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewinmsp", "viewintenant", "manageintenant"],
          text: "msp_text_recovery"
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewinmsp", "viewintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    //   direct_admin Tenant Admin -> msp
    {
      role_id: "direct_admin",
      role_name: "msp_direct_admin_role_name",
      role_description: "msp_direct_admin_role_description",
      organization_type: "msp",
      user_type: "external",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    // direct_monitor Tenant Monitor -< msp
    {
      role_id: "direct_monitor",
      role_name: "msp_direct_monitor_role_name",
      role_description: "msp_direct_monitor_role_description",
      organization_type: "msp",
      user_type: "external",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    },
    // direct_recovery Tenant Recovery -> msp
    {
      role_id: "direct_recovery_admin",
      role_name: "msp_direct_recovery_admin_role_name",
      role_description: "msp_direct_recovery_admin_role_description",
      organization_type: "msp",
      user_type: "external",
      user_count: 0,
      permissions: [
        {
          navigation: "dashboard",
          sub_navigation: "all",
          access: ["viewintenant"]
        },
        {
          navigation: "protect",
          sub_navigation: "all",
          access: ["viewintenant", "manageintenant"],
          text: "msp_text_recovery"
        },
        {
          navigation: "reports",
          sub_navigation: "all",
          access: ["viewintenant"]
        },

        {
          navigation: "settings",
          sub_navigation: "all",
          access: []
        },
        {
          navigation: "usermanagement",
          sub_navigation: "all",
          access: []
        }
      ]
    }
  ],
  pagination: {
    curr_page: 1,
    total_page: 1,
    page_size: 25,
    has_prev: false,
    has_next: false,
    total_size: 6
  },
  errors: []
};

export default dataUserRoles;
