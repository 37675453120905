export const GROUPED_JOB_MENU_ITEMS = [
  "startbackup",
  "stopbackup",
  "cancelbackup",
  "startrecovery",
  "cancelrecovery",
  "heart_beat_pause",
  "heart_beat_resume",
  "vsb_pause",
  "vsb_resume",
  "standbyvm",
  "standbyvmnetworkconfiguration",
  "set_backup_passwords",
  "terminate_ec2_instance",
  "terminate_gcp_instance",
  "canceljob",
  "deletejob",
  "cancelrepliationin",
  "cancelrepliationout"
];

export const GROUPED_MANAGE_MENU_ITEMS = [
  "update",
  "assignpolicy",
  "unassignpolicy",
  "deploy_policy",
  "delete",
  "remove",
  "assigngroup"
];

export const GROUPED_ADMINISTER_MENU_ITEMS = [
  "launch_udp_agent_ui",
  "install_agent",
  "downloadagent",
  "upgradeagent",
  "cancel_scheduled_deploy",
  "collect_diagnostic_data",
  "convert_to_agentless",
  "view_source"
];

export const GROUPED_ACTION_MENU_ITEMS = [
  "create_instant_vm",
  "create_virtual_standby",
  "replication",
  "startrepliationout",
  "start_ar_test",
  "run_assured_security_scan",
  "provision"
];
