import { CCGRAPH_IDS } from "utils/appConstants";

/**
 * Lists the full list of supported dashboard types
 */
export const ReportsWidgetTypes = {
  BACKUP_JOBS_TREND: "backupJobsTrend",
  BACKUP_JOBS_DONUT: "backupJobsDonut",
  TOP_TEN_SOURCES_STACKEDBAR: "topTenSourcesStackedBar",
  RESTORE_JOBS_DONUT: "restoreJobsDonut",
  RESTORE_JOBS_TREND: "restoreJobsTrend",
  DATA_TRANSFER_LINE: "dataTransferLine",
  JOBS_SUMMARY_DONUT: "jobsSummaryDonut",
  TOP_TEN_DESTINATIONS_STACKEDBAR: "topTenDestinationsStackedBar",
  CD_VOLUME_TREND: "cdVolumeTrend",
  CD_VOLUME_TREND_REPORT: "cdVolumeTrendReport",
  CH_VOLUME_TREND: "chVolumeTrend",
  DEDUPE_SAVING_LINE: "dedupeSavingLine",
  REPORTS_BACKUP_JOBS_TOP_SOURCES: "backupJobsTopSources",
  REPORTS_RECOVERY_JOBS_TOP_SOURCES: "recoveryJobsTopSources",
  REPORTS_DATA_TRANSFER_TOP_SOURCES: "datatransferJobsTopSources",
  REPORTS_POLICY_TASKS_TREND: "policyTasksTrend",
  REPORTS_POLICY_TASKS_DONUT: "policyTasksDonut",
  REPORTS_POLICY_TASKS_TOP_SOURCES: "policyTasksTopSources",
  STORED_DATA_USAGE: "storedDataUsage",
  MSP_CD_VOLUME_TREND: "MSPCdVolumeTrend",
  REPORTS_NUMBER_OF_RECOVERY_POINTS: "reportsNumberOfRecoveryPoints",
  REPORTS_LATEST_RECOVERY_POINTS: "reportsLatestRecoveryPoints",
  REPORTS_OLDEST_RECOVERY_POINTS: "reportsOldestRecoveryPoints",
  REPORTS_SOURCE_PROTECTION_SUMMARY: "sourceProtectionSummary",
  REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES: "sourceProtectionSummarySources"
};

export const widgetTypeToCCGraphId = {
  [ReportsWidgetTypes.CD_VOLUME_TREND]: CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE,
  [ReportsWidgetTypes.CD_VOLUME_TREND_REPORT]:
    CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE,
  [ReportsWidgetTypes.CH_VOLUME_TREND]: CCGRAPH_IDS.REPORTS_CLOUD_HYBRID_USAGE,
  [ReportsWidgetTypes.BACKUP_JOBS_DONUT]:
    CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY,
  [ReportsWidgetTypes.BACKUP_JOBS_TREND]:
    CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY,
  [ReportsWidgetTypes.REPORTS_BACKUP_JOBS_TOP_SOURCES]:
    CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES,
  [ReportsWidgetTypes.DATA_TRANSFER_LINE]:
    CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY,
  [ReportsWidgetTypes.DEDUPE_SAVING_LINE]:
    CCGRAPH_IDS.REPORTS_DEDUPE_SAVINGS_CLOUD_HYBRID,
  [ReportsWidgetTypes.REPORTS_RECOVERY_JOBS_TOP_SOURCES]:
    CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_TOP_SOURCES,
  [ReportsWidgetTypes.REPORTS_DATA_TRANSFER_TOP_SOURCES]:
    CCGRAPH_IDS.REPORTS_DATA_TRANSFER_TOP_SOURCES,
  [ReportsWidgetTypes.RESTORE_JOBS_DONUT]:
    CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY,
  [ReportsWidgetTypes.RESTORE_JOBS_TREND]:
    CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY,
  [ReportsWidgetTypes.REPORTS_POLICY_TASKS_DONUT]:
    CCGRAPH_IDS.REPORTS_REPORTS_POLICY_TASKS_SUMMARY,
  [ReportsWidgetTypes.REPORTS_POLICY_TASKS_TREND]:
    CCGRAPH_IDS.REPORTS_REPORTS_POLICY_TASKS_SUMMARY,
  [ReportsWidgetTypes.REPORTS_POLICY_TASKS_TOP_SOURCES]:
    CCGRAPH_IDS.REPORTS_POLICY_TASKS_TOP_SOURCES
};

export const WidgetHeaderConfig = {
  backupJobTrend: {
    refreshAction: "refreshBackupJobsSummaryData"
  },
  reportsDataTransferSummary: {
    filterOptions: [],
    selectedOption: "job_status"
  },
  topSource: {
    selectedOption: "job_status",
    filterOptions: [
      {
        label: "Backup Job Status",
        value: "job_status"
      },
      {
        label: "Events",
        value: "events"
      },
      {
        label: "Job Duration",
        value: "job_duration"
      },
      {
        label: "Data Transferred",
        value: "data_transferred"
      }
    ],
    optionAction: "filterTopSourceData",
    refreshAction: "refreshBackupJobsTopSources"
  },
  topSourceForPolicy: {
    selectedOption: "job_duration",
    filterOptions: [
      {
        label: "Events",
        value: "events"
      },
      {
        label: "Job Duration",
        value: "job_duration"
      }
    ],
    optionAction: "filterTopSourceData"
  },
  dataTransferTopSource: {
    refreshAction: "getDataTransferTopSources"
  },
  dataTransferSummary: {
    refreshAction: "getDataTransferTrendSummary"
  },
  cdVolumeTrend: {
    refreshAction: "getCapacityUsageCDTrendUsage"
  },
  chVolumeTrend: {
    refreshAction: "getCapacityUsageCHTrendUsage"
  },
  capacitydedupeSavingLine: { refreshAction: "getCapacityUsageCHSavingTrend" },
  dedupeSavingLine: {
    refreshAction: "filterTopSourceData"
  },
  restoreJobsTopSource: {
    selectedOption: "job_status",
    filterOptions: [
      {
        label: "Recovery Job Status",
        value: "job_status"
      },
      {
        label: "Events",
        value: "events"
      },
      {
        label: "Job Duration",
        value: "job_duration"
      },
      {
        label: "Data Transferred",
        value: "data_transferred"
      }
    ],
    optionAction: "fetchRestoreJobsTopSourceData",
    refreshAction: "fetchRestoreJobsTopSourceData"
  },
  restoreJobsTrendSummary: {
    refreshAction: "fetchRestoreJobsTrendSummaryData"
  },
  jobSummaryDonuts: { refreshAction: "getJobsAndDestinationJobsSummary" },
  jobTopTenDestinations: {
    refreshAction: "getJobsAndDestinationTopDestination"
  },
  jobTopTenSources: { refreshAction: "getJobsAndDestinationTopSources" }
};
