import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  DARK_MODE,
  LIGHT_MODE,
  MUI_INITIAL_THEME,
  setThemePrimaryAndSecColors,
  DARK_THEME,
  LIGHT_THEME
} from "utils/theme";
import { setTheme } from "state/actions/actions";
import { changeCSSProperty } from "state/sagas/sagautils";

const Theme = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const selectedTheme = localStorage.getItem("CCTheme");
  const dispatch = useDispatch();
  const brandObj = useSelector(state => state.configureReducer.brandData);

  React.useMemo(() => {
    let themeValue = MUI_INITIAL_THEME();
    if (selectedTheme) {
      if (selectedTheme === "system_default") {
        const themePaletteMode = prefersDarkMode ? DARK_MODE : LIGHT_MODE;
        if (themePaletteMode === DARK_MODE) {
          themeValue = DARK_THEME;
        } else {
          themeValue = LIGHT_THEME;
        }
        themeValue = setThemePrimaryAndSecColors(themeValue, brandObj);
        dispatch(setTheme(themeValue));
        changeCSSProperty(themeValue);
      } else {
        themeValue.palette.mode = selectedTheme;
        themeValue = setThemePrimaryAndSecColors(themeValue, brandObj);
        dispatch(setTheme(themeValue));
        changeCSSProperty(themeValue);
      }
    } else {
      localStorage.setItem("CCTheme", LIGHT_MODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTheme, prefersDarkMode]);

  return <></>;
};

export default Theme;
