export const CHANGE_CREATE_STANDBY_VM_TAB = "CHANGE_CREATE_STANDBY_VM_TAB";
export const CREATE_HYPERV_FOR_VM_SUCCESS = "CREATE_HYPERV_FOR_VM_SUCCESS";
export const CLEAR_CREATE_HYPERV_FOR_VM_SUCCESS =
  "CLEAR_CREATE_HYPERV_FOR_VM_SUCCESS";
export const CLEAR_VM_LOCATION_DETAILS = "CLEAR_VM_LOCATION_DETAILS";
export const CREATE_HYPERV_FOR_VM = "CREATE_HYPERV_FOR_VM";
export const GET_HYPERVISORS_LIST_BY_TYPE = "GET_HYPERVISORS_LIST_BY_TYPE";
export const GET_HYPERVISORS_INFO = "GET_HYPERVISORS_INFO";
export const GET_ESX_INFO = "GET_ESX_INFO";
export const GET_ADD_NW_INFO = "GET_ADD_NW_INFO";
export const GET_VMLOCATION_INFO = "GET_VMLOCATION_INFO";
export const GET_VW_HYPER_INFO = "GET_VW_HYPER_INFO";
export const VSB_CONNECT_VMWARE_MONITOR = "VSB_CONNECT_VMWARE_MONITOR";
export const GET_VMWARE_CONFIGURATION_VSB = "GET_VMWARE_CONFIGURATION_VSB";
export const GET_VMWARE_CONFIGURATION_SUCCESS =
  "GET_VMWARE_CONFIGURATION_SUCCESS";
export const VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS =
  "VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS";
export const STORE_VALIDATE_CONFIG_DATA = "STORE_VALIDATE_CONFIG_DATA";
export const INITIALIZE_APP = "INITIALIZE_APP";
export const GET_HYPERV_CONFIGURATION_SUCCESS =
  "GET_HYPERV_CONFIGURATION_SUCCESS";

export const TASK_TYPES = {
  UDP_VIRTUAL_STANDBY: "udp_vsb"
};
export const GET_DESTINATIONS_BY_SOURCE_ID_FOR_VSB =
  "GET_DESTINATIONS_BY_SOURCE_ID_FOR_VSB";
export const DESTINATIONS_DATA_GET_SUCCESS_FOR_VSB =
  "DESTINATIONS_DATA_GET_SUCCESS_FOR_VSB";
export const STORE_SOURCE_DATA_FOR_VSB = "STORE_SOURCE_DATA_FOR_VSB";
export const SAVE_RECOVERY_SELECTED_DESTINATION_DATA_VSB =
  "SAVE_RECOVERY_SELECTED_DESTINATION_DATA_VSB";
export const GET_RECOVERY_POINTS_BY_DESTINATION_PATH_FOR_VSB =
  "GET_RECOVERY_POINTS_BY_DESTINATION_PATH_FOR_VSB";
export const GET_RECOVERY_POINT_RESPONSE_FOR_VSB =
  "GET_RECOVERY_POINT_RESPONSE_FOR_VSB";
export const RECOVERY_POINTS_BY_DESTINATION_PATH_GET_FOR_VSB_SUCCESS =
  "RECOVERY_POINTS_BY_DESTINATION_PATH_GET_FOR_VSB_SUCCESS";
export const STORE_NEW_VSB_RP = "STORE_NEW_VSB_RP";
export const SAVE_RP_PASSWORD_FOR_VSB = "SAVE_RP_PASSWORD_FOR_VSB";
export const STORE_SELECTED_RECOVERY_POINT_FOR_VSB =
  "STORE_SELECTED_RECOVERY_POINT_FOR_VSB";
export const VALIDATE_RP_VSB = "VALIDATE_RP_VSB";
export const STORE_VALIDATE_CONFIG_DATA_FOR_VSB =
  "STORE_VALIDATE_CONFIG_DATA_FOR_VSB";
export const CLEAN_UP_VSB_STATE = "CLEAN_UP_VSB_STATE";
export const FETCH_PROXIES_VSB = "FETCH_PROXIES_VSB";
export const FETCH_PROXIES_VSB_SUCCESS = "FETCH_PROXIES_VSB_SUCCESS";
export const STORE_CLICKED_SOURCE_DETAILS = "STORE_CLICKED_SOURCE_DETAILS";
export const SET_SELECTED_PROXY_FOR_VSB = "SET_SELECTED_PROXY_FOR_VSB";
export const VSB_VALIDATE_NFS = "VSB_VALIDATE_NFS";
export const VSB_VALIDATE_HYPERV = "VSB_VALIDATE_HYPERV";
export const VALIDATE_VSB_SUCCESS = "VALIDATE_VSB_SUCCESS";
export const VSB_CONNECT_HYPERV = "VSB_CONNECT_HYPERV";
export const GET_HYPERV_CONFIGURATION = "GET_HYPERV_CONFIGURATION";
export const SUBMIT_VM = "SUBMIT_VM";
export const SUBMIT_VM_SUCCESS = "SUBMIT_VM_SUCCESS";
export const CLEAR_RECOVERY_POINTS_DATA_FOR_VSB =
  "CLEAR_RECOVERY_POINTS_DATA_FOR_VSB";
export const EC2_VALIDATE_CLOUD_PROXY = "EC2_VALIDATE_CLOUD_PROXY";
export const EC2_VALIDATE_CLOUD_PROXY_RESPONSE =
  "EC2_VALIDATE_CLOUD_PROXY_RESPONSE";
export const HYPERV_SELECTED_ROW = "HYPERV_SELECTED_ROW";
export const CONNECT_MODAL_STATUS = "CONNECT_MODAL_STATUS";
export const CREATE_NUTANIX_FOR_IVM_SUCCESS = "CREATE_NUTANIX_FOR_IVM_SUCCESS";
export const CREATE_NUTANIX_FOR_VSB = "CREATE_NUTANIX_FOR_VSB";
export const STORE_CREATE_STANDBY_VM_FINAL_PAYLOAD =
  "STORE_CREATE_STANDBY_VM_FINAL_PAYLOAD";
export const SET_HYPERV_FOLDER_INITIAL_PATH = "SET_HYPERV_FOLDER_INITIAL_PATH";
export const HYPERV_SHARED_FOLDERS_BROWSE = "HYPERV_SHARED_FOLDERS_BROWSE";
export const STORE_FORM_INVALID_INFO = "STORE_FORM_INVALID_INFO";
export const FETCH_CLOUD_CONFIGURATIONS_VSB = "FETCH_CLOUD_CONFIGURATIONS_VSB";
export const STORE_CLOUD_CONFIGURATIONS = "STORE_CLOUD_CONFIGURATIONS";
export const FETCH_CLOUD_SUBNET_AZURE = "FETCH_CLOUD_SUBNET_AZURE";
export const STORE_AZORE_CLOUD_SUBNET_VSB = "STORE_AZORE_CLOUD_SUBNET_VSB";
export const GET_ALL_VSB_HYPERVISORS_FOR_CREATE_STANDBY_VM =
  "GET_ALL_VSB_HYPERVISORS_FOR_CREATE_STANDBY_VM";
export const GET_VSB_SUCCESS_HYPERVISORS_FOR_CREATE_STANDBY_VM =
  "GET_VSB_SUCCESS_HYPERVISORS_FOR_CREATE_STANDBY_VM";
export const GET_LOAD_VSB_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS =
  "GET_LOAD_VSB_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS";
export const GET_VSB_FAILED_HYPERVISORS_INFO =
  "GET_VSB_FAILED_HYPERVISORS_INFO";
export const GET_REGIONS_FOR_CLOUD_ACCOUNT_VSB =
  "GET_REGIONS_FOR_CLOUD_ACCOUNT_VSB";
export const GET_REGIONS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS =
  "GET_REGIONS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS";
export const CLEAR_CLOUD_ACCOUNTS_REGIONS_VSB =
  "CLEAR_CLOUD_ACCOUNTS_REGIONS_VSB";
export const GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_VSB =
  "GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_VSB";
export const GET_RESOURCE_GROUPS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS =
  "GET_RESOURCE_GROUPS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS";
export const LOAD_VMWARE_NUTNAIX_MONITOR = "LOAD_VMWARE_NUTNAIX_MONITOR";
export const LOAD_VSB_MONITOR_SUCCESS = "LOAD_VSB_MONITOR_SUCCESS";
export const GET_VSB_NUTANIX_CONFIGURATION = "GET_VSB_NUTANIX_CONFIGURATION";
export const GET_VSB_NUTANIX_CONFIGURATION_SUCCESS =
  "GET_VSB_NUTANIX_CONFIGURATION_SUCCESS";
export const CREATE_VMWARE_FOR_VSB = "CREATE_VMWARE_FOR_VSB";
export const CREATE_VMWARE_FOR_VSB_SUCCESS = "CREATE_VMWARE_FOR_VSB_SUCCESS";
export const GET_SELECTED_VMWARE_TREE_INFO = "GET_SELECTED_VMWARE_TREE_INFO";
export const GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB =
  "GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB";
export const GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB =
  "GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB";
export const GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB =
  "GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB";
export const GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB =
  "GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB";
export const GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB =
  "GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB";
export const GCP_SECURITY_GROUPS_FETCH_SUCCESS_FOR_VSB =
  "GCP_SECURITY_GROUPS_FETCH_SUCCESS_FOR_VSB";
export const CLEAR_GCP_SECURITY_GROUPS_FOR_VSB =
  "CLEAR_GCP_SECURITY_GROUPS_FOR_VSB";
