import { combineReducers } from "redux";
import {
  SAVE_TO_LIST_ORACLE_SOURCE_ERROR,
  SAVE_TO_LIST_ORACLE_SOURCE_SUCCESS,
  CLEAR_ORACLE_SOURCE_LIST,
  DELETE_SOURCE_FROM_LIST_ORACLE_SOURCE,
  CLEAR_ORACLE_SOURCE_ERROR,
  ADD_ORACLE_WINDOW_SOUCE_PAGE
} from "../../actions/actionTypes";

/**
 * Errors occured during Connect API call to LBS is maintained here
 * @param {*} state
 * @param {*} action
 */
const connectErrors = (state = null, { type, data }) => {
  switch (type) {
    case SAVE_TO_LIST_ORACLE_SOURCE_ERROR:
      return data;
    case CLEAR_ORACLE_SOURCE_ERROR:
      return null;
    default:
      return state;
  }
};

/**
 * If Connect API is success, then we are adding the fields
 * to connected list of servers to be added to sources
 * @param {*} state
 * @param {*} action
 */
const savedList = (state = [], { type, data, source }) => {
  switch (type) {
    case SAVE_TO_LIST_ORACLE_SOURCE_SUCCESS:
      return [...state, data];
    case DELETE_SOURCE_FROM_LIST_ORACLE_SOURCE: {
      const newSavedList = state.filter(
        obj => obj.uniqueId !== source.uniqueId
      );
      return newSavedList;
    }
    case CLEAR_ORACLE_SOURCE_LIST:
      return [];
    default:
      return state;
  }
};

const onPage = (state = 1, { type, data }) => {
  switch (type) {
    case ADD_ORACLE_WINDOW_SOUCE_PAGE:
      return data;
    default:
      return state;
  }
};

export default combineReducers({
  connectErrors,
  savedList,
  onPage
});
