import _ from "lodash";
import { SUPPORTED_LANGUAGES, BRAND_NAMES } from "utils/appConstants";
import {
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_JWT_TOKEN,
  UNSET_JWT_TOKEN,
  SET_OCTA_TOKEN,
  LOG_OUT,
  UNSET_LOG_OUT,
  SHOW_USER_DETAILS,
  SHOW_USER_PROFILE_DETAILS,
  UPDATED_USER_CONTACT_INFO,
  PASSWORD_UPDATED,
  SET_LOGIN_LOADER_OPACITY,
  SET_LOGIN_LOADER_HEIGHT,
  DISMISS_ALERT,
  USER_PHOTO_DELETED,
  USER_PHOTO_UPDATED,
  ERROR_MESSAGE,
  CREATE_PASSWORD,
  CREATE_PASSWORD_ERROR,
  CREATE_PASSWORD_SUCCESS,
  ENROLL_PERSONAL_DETAILS,
  ACTIVATION_SUCCESSFUL,
  ACTIVATE_ACCOUNT,
  ACTIVATION_FAILED,
  CLEAR_ACTIVATION_ERROR,
  IMPERSONATE_CUSTOMER,
  SHOW_DATA_CENTERS,
  FORGOT_PASSWORD_MESSAGE_CLEAR,
  FORGOT_PASSWORD_MESSAGE,
  FETCH_USER_LANGUAGE_SUCCESS,
  UPDATE_PRODUCTS,
  SHOW_BRANDING_DETAILS,
  PASSWORD_VALIDATED,
  TWO_FACTOR_ENABLED,
  TWO_FACTOR_CODES,
  TWO_FACTOR_STORE_SECRET,
  STORE_ORGANIZATION,
  STORE_TEMP,
  FETCH_ALL_HELP_LINK_SUCCESS,
  SET_ORGANIZATION_TYPE
} from "../actions/actionTypes";

const initialState = {
  passwordValidated: false,
  twoFactorEnabled: false,
  twoFactorCodes: [],
  twoFactorSecret: {},
  requesting: false,
  successful: false,
  messages: [],
  errors: undefined,
  redirectTo: "",
  logout: false,
  user_id: null,
  token: null,
  refresh_token: null,
  idtoken: "",
  role_id: null,
  organization_id: null,
  email: null,
  child_organization_ids: null,
  tokenExpiry: null,
  site_id: null,
  organization_type: null,
  userDetails: {},
  userProfileDetails: {},
  passwordChange: false,
  showSuccessMsg: false,
  errorCode: "0",
  enroll_page: "1",
  enroll_requesting: false,
  dataCenters: [],
  forgotPasswordMessage: {},
  vmware_download_link: "",
  locale: null,
  brand_names: {
    ARCSERVE_PRODUCT_NAME: "Arcserve",
    CD_PRODUCT_NAME: "Cloud Direct",
    CH_PRODUCT_NAME: "Cloud Hybrid",
    UDP_PRODUCT_NAME: "UDP"
  },
  organizationInfo: {},
  parentOrganizationInfo: {},
  temp: "",
  octaToken: "",
  octaLogout: false,
  featureFlag: {
    rman: false,
    nutanix: false
  }
};

const loginReducer = function loginReducer(state = initialState, action) {
  switch (action.type) {
    // Set the requesting flag and append a message to be shown
    case LOGIN_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: "Logging in", time: new Date() }],
        errors: undefined,
        redirectTo: ""
      };
    // Successful?  Reset the login state.
    case LOGIN_SUCCESS:
      return {
        ...state,
        errors: undefined,
        messages: [],
        requesting: false,
        successful: true, //redirectTo: "/monitor"
        redirectTo: action.redirectTo
      };
    case FETCH_USER_LANGUAGE_SUCCESS:
      return {
        ...state,
        locale:
          SUPPORTED_LANGUAGES.filter(
            language => language.value === action.payload
          ).length > 0
            ? action.payload
            : "en"
      };
    case IMPERSONATE_CUSTOMER:
      return {
        ...state,
        redirectTo: ""
      };

    // Append the error returned from our api
    // set the success and requesting flags to false
    case LOGIN_ERROR:
      return {
        ...state,
        errors: action.error,
        messages: [],
        requesting: false,
        successful: false,
        redirectTo: ""
      };

    case FORGOT_PASSWORD_MESSAGE: {
      return {
        ...state,
        forgotPasswordMessage: action.response
      };
    }

    case FORGOT_PASSWORD_MESSAGE_CLEAR: {
      return {
        ...state,
        forgotPasswordMessage: {}
      };
    }
    case SET_ORGANIZATION_TYPE:
      return {
        ...state,
        organization_type: action.data.organization_type,
        organization_id: action.data.organization_id
      };
    case SET_JWT_TOKEN:
      return {
        ...state,
        //brand_names: { ...action.payload.parsedToken.brand_names },
        user_id: action.payload.parsedToken.user_id,
        //token: action.payload.jwtToken,
        //refresh_token: action.payload.refreshToken,
        cdtoken: action.payload.jwtToken,
        cdrefresh_token: action.payload.refreshToken,
        role_id: action.payload.parsedToken.role_id,
        organization_id: action.payload.parsedToken.organization_id,
        email: action.payload.parsedToken.email,
        child_organization_ids:
          action.payload.parsedToken.child_organization_ids, //API sending data in secs. (3600 secs), hence multiplying by 1000 to get milli seconds.
        tokenExpiry: action.payload.parsedToken.exp * 1000,
        site_id: action.payload.parsedToken.site_id,
        organization_type: action.payload.parsedToken.organization_type,
        apis: JSON.parse(action.payload.parsedToken.apis),
        products: JSON.parse(action.payload.parsedToken.products),
        vmware_download_link: getVmWareDownloadLink(
          action.payload.parsedToken.links
        ),
        first_login: action.payload.parsedToken.first_login,
        root_organization: action.payload.parsedToken.root_organization,
        imcc_organization: action.payload.parsedToken.imcc_organization,
        sub_msp: action.payload.parsedToken.sub_msp,
        logout: false,
        UDP_backup_jobs: false, // Replace the field with when BE send the actual field
        featureFlag: {
          // Pass true for the feature that needs to be enabled
          rman: action.payload.parsedToken?.rman || false,
          nutanix: action.payload.parsedToken?.nutanix || false
        }
      };
    case UNSET_JWT_TOKEN:
      return {
        ...state,
        id: null,
        token: null,
        cdtoken: null,
        cdrefresh_token: null,
        primaryNavigation: null,
        secondaryNavigation: null
      };
    case SET_OCTA_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        idtoken: action.payload.idtoken,
        refresh_token: action.payload.refreshToken,
        octaLogout: false,
        successful: true,
        logout: false
      };
    case UNSET_LOG_OUT:
      return {
        ...state,
        octaLogout: false,
        successful: false
        //logout: false
      };

    case LOG_OUT:
      return {
        ...state,
        errors: undefined,
        messages: [],
        requesting: false,
        successful: false,
        redirectTo: "",
        token: null,
        locale: null,
        logout: true,
        octaToken: "",
        octaLogout: true
      };
    case SHOW_USER_DETAILS:
      return {
        ...state,
        userDetails: action.data,
        role_id: action.data.role_id,
        userManagementUrl: action.data.user_management_url,
        locale: action.data.preference_language
      };
    case SHOW_USER_PROFILE_DETAILS:
      return { ...state, userProfileDetails: action.data };
    case UPDATED_USER_CONTACT_INFO:
      return {
        ...state,
        userDetails: action.data,
        showSuccessMsg: true,
        errors: action.error
      };
    case DISMISS_ALERT:
      return { ...state, showSuccessMsg: action.data };
    case PASSWORD_UPDATED:
      return { ...state, passwordChange: action.data };
    // Return height of Login Button
    case SET_LOGIN_LOADER_HEIGHT:
      return { ...state, height: action.height };

    // Set opacity of login loader
    case SET_LOGIN_LOADER_OPACITY:
      return { ...state, opacity: action.opacity };

    case USER_PHOTO_DELETED: {
      let userDetails = JSON.parse(
        JSON.stringify(Object.assign({}, state.userDetails))
      );
      userDetails.image_url = "";
      return { ...state, userDetails, showSuccessMsg: true };
    }

    case USER_PHOTO_UPDATED: {
      let userDetails = JSON.parse(
        JSON.stringify(Object.assign({}, state.userDetails))
      );
      userDetails.image_url = "";
      userDetails.image_url = `${action.data}`;
      return { ...state, userDetails, showSuccessMsg: true };
    }

    case ERROR_MESSAGE:
      return { ...state, errorCode: action.data };

    // Append the error returned from our api
    // set the success and requesting flags to false
    case CREATE_PASSWORD_ERROR:
      return {
        ...state,
        cp_errors: action.error,
        cp_messages: [],
        redirectTo: "",
        enroll_requesting:
          action.error && action.error.status === 403 ? true : false
      };

    case ENROLL_PERSONAL_DETAILS:
      return {
        ...state,
        enroll_firstName: action.data.firstName,
        enroll_lastName: action.data.lastName,
        enroll_email: action.data.email,
        enroll_phone: action.data.phone,
        country: action.data.country,
        enroll_company: action.data.companyName,
        enroll_terms: action.data.terms,
        enroll_reseller: action.data.reseller,
        enrollment_code: action.data.enrollmentCode,
        activation_message: undefined
      };
    case ACTIVATE_ACCOUNT: {
      return { ...state, enroll_requesting: true };
    }
    case ACTIVATION_SUCCESSFUL:
      return {
        ...state,
        activation_message: undefined,
        enroll_page: "2",
        enroll_requesting: false
      };
    case ACTIVATION_FAILED:
      return {
        ...state,
        activation_message: action.response,
        enroll_page: "1",
        enroll_requesting: false
      };

    case CLEAR_ACTIVATION_ERROR:
      return { ...state, activation_message: undefined };
    case CREATE_PASSWORD: {
      return { ...state, enroll_requesting: true, cp_errors: undefined };
    }
    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        enroll_requesting: false,
        cp_errors: undefined,
        createPasswordSuccess: true,
        redirectTo: action.redirectTo,
        requesting: false,
        successful: true,
        errors: undefined,
        messages: []
      };

    case SHOW_DATA_CENTERS:
      return {
        ...state,
        dataCenters: action.data
      };

    case UPDATE_PRODUCTS:
      return {
        ...state,
        products: action.data
      };
    case SHOW_BRANDING_DETAILS: {
      let brandingNames = _.get(action, "data.branding_names");
      if (!brandingNames) {
        brandingNames = BRAND_NAMES;
      }
      return {
        ...state,
        brand_names: { ...brandingNames }
      };
    }
    case PASSWORD_VALIDATED: {
      return {
        ...state,
        passwordValidated: action.data
      };
    }

    case TWO_FACTOR_ENABLED: {
      return {
        ...state,
        twoFactorEnabled: action.data
      };
    }

    case TWO_FACTOR_CODES: {
      return {
        ...state,
        twoFactorCodes: action.data
      };
    }

    case TWO_FACTOR_STORE_SECRET: {
      return {
        ...state,
        twoFactorSecret: action.data
      };
    }

    case STORE_ORGANIZATION: {
      if (!action.isImpersonate) {
        return {
          ...state,
          organizationInfo: action.data,
          parentOrganizationInfo: action.data
        };
      } else {
        return {
          ...state,
          organizationInfo: action.data
        };
      }
    }

    case STORE_TEMP: {
      return {
        ...state,
        temp: action.data
      };
    }

    case FETCH_ALL_HELP_LINK_SUCCESS:
      return {
        ...state,
        helpLinks: action.payload
      };
    default:
      return state;
  }
};

export default loginReducer;

/*
 * function to get object from string
 */
function getStringsAsObject(strings) {
  return JSON.parse(strings);
}

/*
 * function to get download vmware link
 */
function getVmWareDownloadLink(strings) {
  let getLinksObj = getStringsAsObject(strings);
  return getLinksObj && getLinksObj.vmware_download_link
    ? getLinksObj.vmware_download_link
    : "";
}
