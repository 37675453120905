import { LOAD_SHADOW_XAFE_LIST_SUCCESS } from "../../actions/actionTypes";

const initialState = {
  shadowXafeList: []
};

const ShadowXafe = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SHADOW_XAFE_LIST_SUCCESS:
      return {
        ...state,
        shadowXafeList: action.data,
        shadowXafePagination: action.pagination
      };
    default:
      return state;
  }
};

export default ShadowXafe;
