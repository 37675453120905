import * as C from "state/constants";

const initialState = {
  isLoading: true,
  recoveryPointServers: {
    data: [],
    pagination: {}
  },
  current: {
    isLoading: false,
    recoveryPointServer: {}
  }
};

const recoveryPointServers = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_RECOVERY_POINT_SERVERS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case C.FETCH_RECOVERY_POINT_SERVERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        recoveryPointServers: action.payload
      });
    case C.FETCH_RECOVERY_POINT_SERVERS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        recoveryPointServers: {}
      });
    case C.FETCH_RECOVERY_POINT_SERVER_REQUEST:
      return Object.assign({}, state, {
        current: {
          isLoading: true
        }
      });
    case C.FETCH_RECOVERY_POINT_SERVER_SUCCESS:
      return Object.assign({}, state, {
        current: {
          isLoading: false,
          recoveryPointServer: action.payload
        }
      });
    case C.RESET_CURRENT_RECOVERY_POINT_SERVER_REQUEST:
      return Object.assign({}, state, {
        current: {
          isLoading: false,
          recoveryPointServer: {}
        }
      });
    default:
      return state;
  }
};

export default recoveryPointServers;
