import { combineReducers } from "redux";

const {
  FETCH_PROXIES_IVM_SUCCESS,
  SET_SELECTED_PROXY,
  CLEAR_IVM,
  STORE_SELECTED_RECOVERY_POINT,
  GET_RECOVERY_POINTS_BY_DESTINATION_PATH,
  VALIDATE_IVM_SUCCESS,
  STORE_DESTINATION_TYPE
} = require("state/actions/actionTypes");

function proxiesResp(state = null, action) {
  switch (action.type) {
    case FETCH_PROXIES_IVM_SUCCESS:
      return action.data;
    case CLEAR_IVM:
      return null;
    default:
      return state;
  }
}

function selectedProxy(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_PROXY:
      return action.data;
    case CLEAR_IVM:
    case GET_RECOVERY_POINTS_BY_DESTINATION_PATH:
      return null;
    default:
      return state;
  }
}

function selectedDestinationType(state = null, action) {
  switch (action.type) {
    case STORE_DESTINATION_TYPE:
      return action.data;
    case CLEAR_IVM:
      return null;
    default:
      return state;
  }
}

function selectedIVMRecoveryPoint(state = null, action) {
  switch (action.type) {
    case STORE_SELECTED_RECOVERY_POINT:
      return action.data;
    case CLEAR_IVM:
    case GET_RECOVERY_POINTS_BY_DESTINATION_PATH:
      return null;
    default:
      return state;
  }
}

function validateIVMResponse(state = null, action) {
  switch (action.type) {
    case VALIDATE_IVM_SUCCESS:
      return action.data;
    case GET_RECOVERY_POINTS_BY_DESTINATION_PATH:
    case CLEAR_IVM:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  proxiesResp,
  selectedProxy,
  selectedIVMRecoveryPoint,
  validateIVMResponse,
  selectedDestinationType
});
