import { call } from "redux-saga/effects";
import * as API_URL from "./apiUrlConstants";

import {
  getData,
  postData,
  putData,
  deleteData,
  postDataMultipart,
  postCookieData,
  getRefreshTokenDetails
} from "./apiMethods";

export function* getRefreshToken(token) {
  try {
    const data = yield call(getRefreshTokenDetails);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get current logged in user details
 * @param type
 * @returns {*}
 */
export function* fetchUserDetails(token) {
  try {
    const data = yield call(getData, `${API_URL.USER_DETAILS}`, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get current logged in user details
 * @param type
 * @returns {*}
 */
export function* fetchOctaUserDetails(token) {
  try {
    const data = yield call(getData, `${API_URL.OCTA_USER_DETAILS}`, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* triggerLogout(token) {
  try {
    const data = yield call(getData, `${API_URL.TRIGGER_LOG_OUT}`, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To update the current logged in user details
 * @param {*} postbody
 * @param {*} token
 */
export function* updateUserContactInfo(
  postbody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      putData,
      `${API_URL.USER_DETAILS}`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To change the password
 * @param {*} postbody
 * @param {*} token
 */
export function* changePassword(postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.CHANGE_PASSWORD}`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To reset  the password
 * @param {*} postbody
 * @param {*} token
 */
export function* forgotPassword(postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FORGOT_PASSWORD}`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To update the profile picture
 */
export function* changePhoto(postbody, token) {
  try {
    const data = yield call(
      postDataMultipart,
      `${API_URL.USER_DETAILS}/picture`,
      postbody,
      token,
      true /*showCommonError*/
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To delete the profile picture
 */
export function* deletePhoto(token) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.USER_DETAILS}/picture`,
      token,
      true /*showCommonError*/
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBrandingData(query) {
  try {
    return yield call(getData, API_URL.BRANDING_PORTAL_URL, query);
  } catch (e) {
    window.console.log(e);
  }
}

export function* getEntitlements(token, orgId) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_ORGANIZATIONS_DETAILS}/${orgId}/entitlements`,
      "usage=false",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getUserLanguage(token) {
  try {
    return yield call(getData, `${API_URL.FETCH_USER_LANGUAGE}`, "", token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* twoFactorEnabled(token, data, skipRefresh) {
  try {
    const resp = yield call(
      postCookieData,
      `${API_URL.TWO_FACTOR_ENABLED}`,
      data,
      token,
      false,
      false
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* userVerifyPassword(token, data) {
  try {
    const resp = yield call(
      postData,
      `${API_URL.USER_VERIFY_PASSWWORD}`,
      data,
      token
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* twoFactorCheckSecret(token) {
  try {
    const resp = yield call(
      getData,
      `${API_URL.TWO_FACTOR_GET_SECRET}`,
      "",
      token
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* userTwoFactorToggle(token, data) {
  try {
    const resp = yield call(
      postData,
      `${API_URL.USER_TWO_FACTOR_TOGGLE}`,
      data,
      token
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* userUseOneTimeCodes(token, data) {
  try {
    const resp = yield call(
      postData,
      `${API_URL.USER_TWO_FACTOR_ONETIMECODES}`,
      data,
      token
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

export function* getAllHelpLink(token, locale) {
  try {
    return yield call(
      getData,
      `${API_URL.HOST_BE_URL}help`,
      `pagination=false&language_type=${locale}`,
      token,
      false,
      true
    );
  } catch (e) {
    window.console.log(e);
  }
}
