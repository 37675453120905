import { createSelector } from "reselect";
import { Action } from "components/protect/destination/rps/datagrid/RPSGridColumns";

/* eslint-disable import/prefer-default-export */

const RPS_AVAILABLE_ACTIONS = [
  Action.REFRESH,
  Action.UPDATE,
  Action.DELETE,
  Action.ADD_DATA_STORE,
  Action.IMPORT_LOCAL_DATA_STORE,
  Action.RPS_JUMPSTART,
  Action.DEPLOY_RPS,
  Action.CANCEL_RPS
];

export const getRpsList = state => {
  return state.destinations.rps.rpsResponse
    ? state.destinations.rps.rpsResponse.data
    : [];
};

export const getRpsGridData = createSelector([getRpsList], rpsList =>
  rpsList.map(rps => ({
    id: rps.server_id,
    available_actions: RPS_AVAILABLE_ACTIONS,
    name: rps.server_name,
    username: rps.username,
    port: rps.port,
    protocol: rps.protocol,
    nat_server: rps.nat.nat_server,
    nat_server_port: rps.nat.nat_server_port,
    description: rps.description,
    site_name: rps.site.site_name,
    site_id: rps.site.site_id,
    status: ["normal", "running"].includes(rps.status)
      ? "success"
      : ["starting", "creating"].includes(rps.status)
      ? "warning"
      : "error",
    policies: rps.policies,
    datastore_count: rps.datastore_count,
    version: rps.rps_version
  }))
);

export const getDestHostKeyValues = state =>
  state.destinations.recovery.destinationHostList.map(host => ({
    key: host.proxy_id,
    value: host.proxy_name
  }));

export const getSourcesKeyValues = state =>
  state.destinations.agentMachines.sources.map(sources => ({
    key: sources.source_id,
    value: sources.source_name
  }));

export const page = state =>
  state &&
  state.destinations &&
  state.destinations.recovery &&
  state.destinations.recovery.recoveryFlowPage;
export const recoveryType = state =>
  state &&
  state.destinations &&
  state.destinations.recovery &&
  state.destinations.recovery.recoveryType;
export const sourcePagination = state =>
  state &&
  state.destinations &&
  state.destinations.recovery &&
  state.destinations.recovery.selectedSourcePagination
    ? state.destinations.recovery.selectedSourcePagination
    : { gridCurrentPage: 1, gridPageSize: 25 };

// Grid data and tree data has to be taken from treeData.
// Child items should be in grid data and tree nodes should be available in treeData
export const folderTreeData = state =>
  state.destinations.recovery.folderTreeData;
export const FnfGridData = state => state.destinations.recovery.FnfGridData;
export const resetFnfGridData = state =>
  state.destinations.recovery.resetFnfGridData;
export const gridData = state => state.destinations.recovery.treeData; // update it
export const treeData = state => state.destinations.recovery.treeData;
export const currSelectedTableData = state =>
  state.destinations.recovery.FnfGridDataPagination.currSelectedData;
export const sqlSummaryData = state =>
  state.destinations.recovery.sqlSummaryData;
export const oracleSummaryData = state =>
  state.destinations.recovery.oracleSummaryData;
export const sqlDbDetails = state => state.destinations.recovery.sqlDbDetails;

export const fnfSummaryData = state =>
  state.destinations.recovery.fnfSummaryData;
export const volumeSummaryData = state =>
  state.destinations.recovery.volumeSummaryData;
export const fnfLinuxSummaryData = state =>
  state.destinations.recovery.fnfLinuxSummaryData;
export const selectedSource = state =>
  state.destinations.recovery.selectedSource;
export const linuxScriptsList = state =>
  state.destinations.recovery.linuxScriptsList;
export const updatedLbs = state => state.destinations.recovery.updatedLbs;
export const selectedRecoveryPoint = state =>
  state.destinations.recovery.selectedRecoveryPoint;
export const selectedItemForRecovery = state =>
  state.destinations.recovery.selectedItemForRecovery;
export const selectOracleDBSettings = state =>
  state.destinations.recovery.storedOracleDBSettings;
export const selectOracleDBTableSettings = state =>
  state.destinations.recovery.storedOracleDBTableSettings;
export const hypervisorInfo = state =>
  state.destinations.recovery.hypervisorInfo;
export const validVMData = state => state.destinations.recovery.validVMData;
export const validLinuxVMData = state =>
  state.destinations.recovery.validLinuxVMData;
export const rpEncryptionPassword = state =>
  state.destinations.recovery.rpEncryptionPassword;
export const destinationsData = state =>
  state.destinations.recovery.destinationsDataBySourceForRecovery;
export const recoverVMPayload = state =>
  state.destinations.recovery.recoverVMPayload;
export const selectedDestinationData = state =>
  state.destinations.recovery.selectedDestinationData;
export const sharedPathCredentials = state =>
  state.destinations.recovery.sharedPathCredentials;
export const selectedDestination = state =>
  state.destinations.recovery.selectedDestination;
export const selectedHypervisorType = state =>
  state.destinations.recovery.selectedHypervisorType;
export const selectedSourceHypervisorType = state =>
  state.destinations.recovery.selectedSourceHypervisorType;
export const selectedHypervisorIdInRecovery = state =>
  state.destinations.recovery.selectedHypervisorIdInRecovery;
export const getHypervVMDisk = state =>
  state.destinations.recovery.backupVmDisks;
export const isSourceRecoveryEncryptionEnabled = state =>
  state.destinations.recovery.isSourceRecoveryEncryptionEnabled;
export const isSQLServerWriterEnabled = state =>
  state.destinations.recovery.isSQLServerWriterEnabled;
export const selectedRecoveryPointData = state =>
  state.destinations.recovery.selectedRecoveryPoint;
export const selectedOracleRecoveryType = state =>
  state.destinations.recovery.oracleRecoveryType;
export const selectedRpsListWithPassword = state =>
  state.destinations.recovery.selectedRpsWithPasswords;
