import "react-grid-layout/css/styles.css";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import ReactGridLayout from "react-grid-layout";
import createCCGraph from "components/common/CCGraph";
import GraphLayout from "components/common/CCGraph/Layout";
import AreaChart from "components/common/CCGraph/Recharts/AreaChart";
import LineChart from "components/common/CCGraph/Recharts/LineChart";
import PieChart from "components/common/CCGraph/Recharts/PieChart";
import * as DCs from "components/analyze/reports/charts/DashboardConstants";
import BarChart from "components/common/CCGraph/Recharts/BarChart";
import * as appConstants from "utils/appConstants";
import * as util from "utils/SpogDataGridUtil";
import { StackedBarData, LegendData } from "./testData";
import ReportsHelper from "./ReportsHelper";
import Layout from "components/common/CCGraph/Layout";
import Header from "components/common/CCGraph/Layout/Header";
import Body from "components/common/CCGraph/Layout/Body";
import StackedBarChart from "components/common/CCGraph/Recharts/StackedBar";

const TileWrapper = ({
  TileProvider,
  titleId,
  subtitleId,
  graphId,
  options,
  chartType,
  action,
  children,
  showDropdown = true
}) => (
  <TileProvider>
    <Layout>
      <Header
        titleId={titleId}
        subtitleId={subtitleId}
        graphId={graphId}
        options={options}
        chartType={chartType}
        action={action}
        showDropdown={showDropdown}
      ></Header>
      <Body>{children}</Body>
    </Layout>
  </TileProvider>
);

TileWrapper.propTypes = {
  TileProvider: PropTypes.node.isRequired,
  titleId: PropTypes.string.isRequired,
  subtitleId: PropTypes.string,
  children: PropTypes.node.isRequired,
  graphId: PropTypes.string,
  chartType: PropTypes.string
};

TileWrapper.defaultProps = {
  subtitleId: null,
  graphId: "",
  chartType: ""
};

const CHART_PARAMS = {
  DESTINATION_ID: "destination_id",
  GROUP_ID: "group_id",
  POLICY_ID: "policy_id",
  ORGANIZATION_ID: "organization_id",
  DATASTORE_ID: "datastore_id",
  PROTECTION_STATUS: "protection_status",
  CONNECTION_STATUS: "connection_status",
  LAST_JOB: "last_job",
  OPERATING_SYSTEM: "operating_system"
};

export const getChartParameters = (
  criteria,
  currentSearchOptions,
  selectedOption,
  searchOptionName
) => {
  let filterOptions = null;
  if (
    criteria.advancedSearchOptions &&
    criteria.advancedSearchOptions.length > 0
  ) {
    filterOptions = criteria.advancedSearchOptions;
  } else {
    filterOptions = currentSearchOptions;
  }

  const rVal = {
    range: "",
    start_ts: undefined,
    end_ts: undefined
  };
  filterOptions &&
    filterOptions.forEach(option => {
      if (option.key === "date_range") {
        switch (option.selectedOptions.type) {
          case "last_24_hours": {
            rVal.range = "1d";
            break;
          }
          case "last_7_days": {
            rVal.range = "7d";
            break;
          }
          case "last_2_weeks": {
            rVal.range = "14d";
            break;
          }
          case "last_1_month": {
            rVal.range = "1m";
            break;
          }
          case "custom": {
            rVal.range = "custom";
            rVal.start_ts =
              moment.unix(option.selectedOptions["start_ts"]) / 1000;
            rVal.end_ts = moment.unix(option.selectedOptions["end_ts"]) / 1000;
            break;
          }
          default:
            break;
        }
      } else if (option.key === "organization_id") {
        rVal.sub_organization_id = util.getSelectedFilterOption(option);
      } else if (CHART_PARAMS[option.key.toUpperCase()] === option.key) {
        rVal[option.key] = util.getSelectedFilterOption(option);
      }
    });
  criteria.searchText
    ? (rVal[searchOptionName] = criteria.searchText)
    : selectedOption
    ? (rVal[searchOptionName] = selectedOption)
    : (rVal[searchOptionName] = undefined);
  return rVal;
};

const CloudDirectUsageProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE,
  microservice: appConstants.MICROSERVICES.CLOUD_DIRECT,
  api: appConstants.CCGRAPH_APIS.CLOUD_DIRECT_USAGE,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const MSPCloudDirectUsageProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_MSP_CLOUD_DIRECT_USAGE,
  microservice: appConstants.MICROSERVICES.CLOUD_DIRECT,
  api: appConstants.CCGRAPH_APIS.MSP_CLOUD_DIRECT_USAGE,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const CloudDirectUsageReportProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.CLOUD_DIRECT_REPORT_USAGE,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const CloudHybridUsageProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_CLOUD_HYBRID_USAGE,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.CLOUD_HYBRID_USAGE,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const BackupJobsSummaryProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_BACKUP_JOBS_SUMMARY,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_SUMMARY
});

const SourceProtectionSummaryProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_SOURCE_PROTECTION_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_SOURCE_PROTECTION_SUMMARY,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY
});

const SourceProtectionSummarySourcesProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES,
  graphType:
    appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES
});

const BackupJobsSummaryTrendProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_BACKUP_JOBS_SUMMARY,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const BackupJobsTopSourcesProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_BACKUP_JOBS_TOP_SOURCES,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_SOURCES
});

const PolicyTasksSummaryProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_POLICY_TASKS_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_POLICY_TASKS_SUMMARY,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_SUMMARY
});

const RecoveryJobsSummaryProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_RECOVERY_JOBS_SUMMARY,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_SUMMARY
});

const RecoveryJobsSummaryTrendProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_RECOVERY_JOBS_SUMMARY,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const DataTransferCloudDirectVolumeProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.DATA_TRANSFER_CLOUD_DIRECT_VOLUME,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const SotredDataProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORT_STORED_DATA,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.DATA_STORES_USAGE,
  graphType: appConstants.CCGRAPH_TYPES.TIME_SERIES
});

const numberOfRecoveryPointsProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_NUMBER_OF_RECOVERY_POINTS,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_NUMBER_OF_RECOVERY_POINTS,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_NUMBER_OF_RECOVERY_POINTS
});

const latestRecoveryPointsProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_LATEST_RECOVERY_POINTS,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_LATEST_RECOVERY_POINTS,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_LATEST_RECOVERY_POINTS
});

const oldestRecoveryPointsProvider = createCCGraph({
  ccGraphId: appConstants.CCGRAPH_IDS.REPORTS_OLDEST_RECOVERY_POINTS,
  microservice: appConstants.MICROSERVICES.CLOUD_CONSOLE,
  api: appConstants.CCGRAPH_APIS.REPORTS_OLDEST_RECOVERY_POINTS,
  graphType: appConstants.CCGRAPH_TYPES.REPORTS_OLDEST_RECOVERY_POINTS
});

const widgetTypeToGraph = {
  [DCs.ReportsWidgetTypes.CD_VOLUME_TREND]: () => (
    <CloudDirectUsageProvider>
      <GraphLayout titleId="dashboard.ccGraph.usage-cd-volumes.title">
        <AreaChart yAxisLabelId="ccGraph.yAxisLabel.gigabytes" />
      </GraphLayout>
    </CloudDirectUsageProvider>
  ),
  [DCs.ReportsWidgetTypes.MSP_CD_VOLUME_TREND]: () => (
    <MSPCloudDirectUsageProvider>
      <GraphLayout titleId="dashboard.ccGraph.usage-cd-volumes.title">
        <AreaChart yAxisLabelId="ccGraph.yAxisLabel.gigabytes" />
      </GraphLayout>
    </MSPCloudDirectUsageProvider>
  ),
  [DCs.ReportsWidgetTypes.CD_VOLUME_TREND_REPORT]: () => (
    <CloudDirectUsageReportProvider>
      <GraphLayout titleId="dashboard.ccGraph.usage-cd-volumes.title">
        <AreaChart yAxisLabelId="ccGraph.yAxisLabel.gigabytes" />
      </GraphLayout>
    </CloudDirectUsageReportProvider>
  ),
  [DCs.ReportsWidgetTypes.CH_VOLUME_TREND]: () => (
    <CloudHybridUsageProvider>
      <GraphLayout titleId="dashboard.ccGraph.usage-ch-stores.title">
        <AreaChart yAxisLabelId="ccGraph.yAxisLabel.gigabytes" />
      </GraphLayout>
    </CloudHybridUsageProvider>
  ),
  [DCs.ReportsWidgetTypes.DATA_TRANSFER_LINE]: () => (
    <DataTransferCloudDirectVolumeProvider
      additionalParameters={{
        filter_type: "job_status"
      }}
    >
      <GraphLayout titleId="dashboard.ccGraph.reports-data-transfer-cloud-direct-volume">
        <LineChart
          yAxisLabelId="ccGraph.yAxisLabel.gigabytes"
          colorSchema={["#12DD99"]}
        />
      </GraphLayout>
    </DataTransferCloudDirectVolumeProvider>
  ),
  [DCs.ReportsWidgetTypes.RESTORE_JOBS_DONUT]: () => (
    <RecoveryJobsSummaryProvider>
      <GraphLayout titleId="dashboard.ccGraph.recovery-jobs-summary.title">
        <PieChart
          config={{
            finished: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.finished"
            },
            canceled: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.canceled"
            },
            failed: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.failed"
            }
          }}
          order={["finished", "canceled", "failed"]}
          colorSchema={["#4BBFA5", "#757181", "#F96B81"]}
        />
      </GraphLayout>
    </RecoveryJobsSummaryProvider>
  ),
  [DCs.ReportsWidgetTypes.BACKUP_JOBS_DONUT]: () => (
    <BackupJobsSummaryProvider>
      <GraphLayout titleId="dashboard.ccGraph.backup-jobs-summary.title">
        <PieChart
          config={{
            finished: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.finished"
            },
            canceled: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.canceled"
            },
            failed: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.failed"
            }
          }}
          order={["finished", "canceled", "failed"]}
          colorSchema={["#12DD99", "#2196F3", "#EE6A5D"]}
        />
      </GraphLayout>
    </BackupJobsSummaryProvider>
  ),
  [DCs.ReportsWidgetTypes.BACKUP_JOBS_TREND]: () => (
    <BackupJobsSummaryTrendProvider>
      <GraphLayout titleId="dashboard.ccGraph.backup-jobs-summary-trend.title">
        <AreaChart
          yAxisLabelId="ccGraph.yAxisLabel.gigabytes"
          valueFormatter={d => d}
          order={["canceled", "failed", "finished"]}
          colorSchema={["#757181", "#F96B81", "#4BBFA5"]}
        />
      </GraphLayout>
    </BackupJobsSummaryTrendProvider>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_BACKUP_JOBS_TOP_SOURCES]: () => (
    <BackupJobsTopSourcesProvider>
      <GraphLayout
        titleId="dashboard.ccGraph.top-sources.title"
        options={DCs.WidgetHeaderConfig.topSource}
        chartType="topProblematicSources"
        graphId={appConstants.CCGRAPH_IDS.REPORTS_BACKUP_JOBS_TOP_SOURCES}
        action={{
          optionAction: "filterTopSourceData",
          refreshAction: "refreshBackupJobsTopSources"
        }}
      >
        <StackedBarChart
          showLegends
          yAxisLabelId={filter =>
            filter === "job_duration"
              ? "ccGraph.yAxisLabel.minutes"
              : filter === "data_transferred"
              ? "ccGraph.yAxisLabel.gigabytes"
              : null
          }
          colorSchema={["#EE6A5D", "#2196F3", "#12DD99"]}
        />
      </GraphLayout>
    </BackupJobsTopSourcesProvider>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_POLICY_TASKS_DONUT]: () => (
    <PolicyTasksSummaryProvider>
      <GraphLayout titleId="dashboard.ccGraph.backup-jobs-summary.title">
        <PieChart
          config={{
            success: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.success"
            },
            warning: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.warning"
            },
            error: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.error"
            }
          }}
          order={["success", "warning", "error"]}
          colorSchema={["#4BBFA5", "#757181", "#F96B81"]}
        />
      </GraphLayout>
    </PolicyTasksSummaryProvider>
  ),
  //   <PolicyTasksSummaryTrendProvider>
  //     <GraphLayout titleId="dashboard.ccGraph.backup-jobs-summary-trend.title">
  //       <TSArea
  //         yAxisLabelId="ccGraph.yAxisLabel.gigabytes"
  //         valueFormatter={d => d}
  //         order={["error", "success", "warning"]}
  //         colorSchema={["#F96B81", "#4BBFA5", "#757181"]}
  //       />
  //     </GraphLayout>
  //   </PolicyTasksSummaryTrendProvider>
  // ),
  // [DCs.ReportsWidgetTypes.REPORTS_POLICY_TASKS_TOP_SOURCES]: () => (
  //   <PolicyTasksTopSourcesProvider>
  //     <GraphLayout
  //       titleId="dashboard.ccGraph.top-sources.title"
  //       options={DCs.WidgetHeaderConfig.topSourceForPolicy}
  //       chartType="topProblematicSources"
  //       graphId={appConstants.CCGRAPH_IDS.REPORTS_POLICY_TASKS_TOP_SOURCES}
  //       action={{
  //         optionAction: "filterTopSourceData"
  //       }}
  //     >
  //       <StackedBar
  //         showLegends
  //         yAxisLabelId={filter =>
  //           filter === "job_duration"
  //             ? "ccGraph.yAxisLabel.minutes"
  //             : filter === "data_transferred"
  //             ? "ccGraph.yAxisLabel.gigabytes"
  //             : null
  //         }
  //       />
  //     </GraphLayout>
  //   </PolicyTasksTopSourcesProvider>
  // ),
  // [DCs.ReportsWidgetTypes.REPORTS_RECOVERY_JOBS_TOP_SOURCES]: () => (
  //   <RecoveryJobsTopSourcesProvider>
  //     <GraphLayout
  //       titleId="dashboard.ccGraph.top-sources.title"
  //       options={DCs.WidgetHeaderConfig.topSource}
  //       chartType="topProblematicRecoverySources"
  //       graphId="REPORTS_RECOVERY_JOBS_TOP_SOURCES"
  //       action={{
  //         optionAction: "filterTopSourceData",
  //         refreshAction: "refreshBackupJobsTopSources"
  //       }}
  //     >
  //       <StackedBar
  //         showLegends
  //         yAxisLabelId={filter =>
  //           filter === "job_duration"
  //             ? "ccGraph.yAxisLabel.minutes"
  //             : filter === "data_transferred"
  //             ? "ccGraph.yAxisLabel.gigabytes"
  //             : null
  //         }
  //       />
  //     </GraphLayout>
  //   </RecoveryJobsTopSourcesProvider>
  // ),
  // [DCs.ReportsWidgetTypes.REPORTS_DATA_TRANSFER_TOP_SOURCES]: () => (
  //   <DataTransferJobsTopSourcesProvider>
  //     <GraphLayout
  //       titleId="dashboard.ccGraph.top-sources.title"
  //       chartType="topProblematicSources"
  //       graphId="REPORTS_DATA_TRANSFER_TOP_SOURCES"
  //     >
  //       <StackedBar showLegends />
  //     </GraphLayout>
  //   </DataTransferJobsTopSourcesProvider>
  // ),
  [DCs.ReportsWidgetTypes.RESTORE_JOBS_TREND]: () => (
    <RecoveryJobsSummaryTrendProvider>
      <GraphLayout titleId="dashboard.ccGraph.recovery-jobs-summary-trend.title">
        <AreaChart
          yAxisLabelId="ccGraph.yAxisLabel.gigabytes"
          valueFormatter={d => d}
          order={["canceled", "failed", "finished"]}
          colorSchema={["#757181", "#F96B81", "#4BBFA5"]}
        />
      </GraphLayout>
    </RecoveryJobsSummaryTrendProvider>
  ),
  [DCs.ReportsWidgetTypes.STORED_DATA_USAGE]: () => (
    <SotredDataProvider>
      <GraphLayout titleId="dashboard.ccGraph.usage-ds.title">
        <AreaChart yAxisLabelId="ccGraph.yAxisLabel.gigabytes" />
      </GraphLayout>
    </SotredDataProvider>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_NUMBER_OF_RECOVERY_POINTS]: ({ options }) => (
    <TileWrapper
      TileProvider={numberOfRecoveryPointsProvider}
      titleId="dashboard.ccGraph.number-of-rps.title"
      chartType="reportsNumberOfRecoveryPoints"
      options={options}
      graphId="REPORTS_NUMBER_OF_RECOVERY_POINTS"
      showDropdown={options?.filterOptions?.length > 1 ? true : false}
      action={{}}
    >
      <BarChart />
    </TileWrapper>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_LATEST_RECOVERY_POINTS]: ({ options }) => (
    <TileWrapper
      TileProvider={latestRecoveryPointsProvider}
      titleId="dashboard.ccGraph.latest-rps.title"
      chartType="reportsLatestRecoveryPoints"
      options={options}
      showDropdown={options?.filterOptions?.length > 1 ? true : false}
      graphId="REPORTS_LATEST_RECOVERY_POINTS"
      action={{}}
    >
      <BarChart
        colorSchema={[
          "#12DD99",
          "#12DD99",
          "#12DD99",
          "#FFB800",
          "#FFB800",
          "#EE6A5D",
          "#EE6A5D",
          "#EE6A5D"
        ]}
      />
    </TileWrapper>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_OLDEST_RECOVERY_POINTS]: ({ options }) => (
    <TileWrapper
      TileProvider={oldestRecoveryPointsProvider}
      titleId="dashboard.ccGraph.oldest-rps.title"
      chartType="reportsOldestRecoveryPoints"
      options={options}
      showDropdown={options?.filterOptions?.length > 1 ? true : false}
      graphId="REPORTS_OLDEST_RECOVERY_POINTS"
      action={{}}
    >
      <BarChart
        colorSchema={[
          "#12DD99",
          "#12DD99",
          "#12DD99",
          "#FFB800",
          "#FFB800",
          "#EE6A5D",
          "#12DD99",
          "#12DD99"
        ]}
      />
    </TileWrapper>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_SOURCE_PROTECTION_SUMMARY]: () => (
    <SourceProtectionSummaryProvider>
      <GraphLayout titleId="dashboard.ccGraph.backup-jobs-summary.title">
        <PieChart
          config={{
            finished: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.finished"
            },
            canceled: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.canceled"
            },
            failed: {
              titleId: "ccGraph.common-title.job",
              pluralTitleId: "ccGraph.common-title.jobs",
              labelId: "ccGraph.common-label.failed"
            }
          }}
          order={["finished", "canceled", "failed"]}
          colorSchema={["#12DD99", "#2196F3", "#EE6A5D"]}
        />
      </GraphLayout>
    </SourceProtectionSummaryProvider>
  ),
  [DCs.ReportsWidgetTypes.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES]: () => (
    <SourceProtectionSummarySourcesProvider>
      <div className="source-summary-widget-wrapper">
        <GraphLayout titleId="protect.customer_accounts.unprotected_sources.title">
          <BarChart
            barChartClassName="source-summary-chart-layout"
            horizontalBarchart={true}
            order={[
              "num_offline",
              "num_without_backups",
              "num_without_policy",
              "num_backups_failed"
            ]}
            colorSchema={["#12DD99", "#12DD99", "#12DD99", "#12DD99"]}
          />
        </GraphLayout>
      </div>
    </SourceProtectionSummarySourcesProvider>
  )
};

/**
 * Parent component that loads and renders all dashboard widgets
 */
class Dashboard extends PureComponent {
  /**
   * Returns mock data for the stacked-bar chart
   * @ignore
   */
  getMonitorBarChartData() {
    let data = {
      chart: StackedBarData.with3SourcesBar(),
      legend: LegendData.with3Points()
    };
    return data;
  }

  getLayout = widgets => {
    let totalRows = Math.ceil(widgets.length / 2);
    totalRows = 10;
    let layout = [];
    for (let x = 0; x < totalRows; x++) {
      for (let y = 0; y <= 1; y++) {
        layout.push({
          i: "index_" + x + y,
          x: y,
          y: x,
          w: widgets.length === 1 ? 2 : x === 1 && y === 0 ? 2 : 1,
          h: 1,
          isResizable: false
        });
      }
    }

    return layout;
  };

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  render() {
    let { widgets } = this.props;
    let layout = this.getLayout(widgets);
    let containerWidth = this.props.containerWidth || window.innerWidth;
    let width = containerWidth - 80; // -80 due to left side navigation and right side padding
    if (!this.props.sideBar) {
      width -= 165;
    }

    let height = 460;

    return (
      <div className="d-flex flex-row flex-wrap">
        <div className="dashboard-wrapper" style={{ width: width }}>
          <ReactGridLayout
            className="layout"
            layout={layout}
            cols={2}
            useCSSTransforms={false}
            rowHeight={height}
            width={width}
          >
            {widgets &&
              widgets.map((widget, index) => {
                if (widgetTypeToGraph.hasOwnProperty(widget.type)) {
                  const GraphComp = widgetTypeToGraph[widget.type];
                  return (
                    <div key={layout && layout[index] && layout[index].i}>
                      <GraphComp options={this.props.options} />
                    </div>
                  );
                }

                return (
                  <div className="" key={layout[index].i}>
                    {ReportsHelper.buildWidgetForType(
                      widget.type,
                      widget.configuration,
                      widget.data,
                      widget.legends,
                      widget.options,
                      widget.actions,
                      widget.advancedSearchData
                    )}
                  </div>
                );
              })}
          </ReactGridLayout>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containerWidth: PropTypes.number,
  sideBar: PropTypes.bool.isRequired
};

Dashboard.defaultProps = {
  containerWidth: 0
};

export default Dashboard;
