import React from "react";
import { CCGraphConsumer } from "components/common/CCGraph";

export const withCCGraph = WrappedComponent => props => (
  <CCGraphConsumer>
    {context => <WrappedComponent ccGraph={context} {...props} />}
  </CCGraphConsumer>
);

// stupid ESLint
export default {};
