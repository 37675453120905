import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import Icon from "../../../assets/icons/icons";
import FormattedMessage from "../../common/formatted-message";
import NavIcon from "../../../components/csr-home/components/NavIcon";

const Tab = ({
  onClick,
  title,
  menuKey,
  active,
  routeLink,
  className,
  icon,
  iconClass,
  iconHeight,
  ...props
}) => {
  const handleClick = () => {
    onClick && onClick(title, menuKey);
  };

  const isActive = () => {
    return active === title;
  };

  const link = routeLink;
  const iconPath = icon ? icon : null;
  const isCsr = iconPath === null ? true : false;
  const iconWidth = props.iconWidth ? props.iconWidth : null;

  return (
    <li className={className} value={title}>
      <NavLink
        id="link"
        to={link}
        className={className}
        onClick={onClick !== undefined ? handleClick : () => {}}
        activeStyle={
          isCsr
            ? {
                borderColor: "#F7E71C",
                borderStyle: "solid",
                width: "70px",
                height: "70px"
              }
            : {
                borderColor: "#F7E71C",
                borderStyle: "solid"
              }
        }
        isActive={isActive}
      >
        {iconPath !== null ? (
          <Icon icon={iconPath} width={iconWidth} height={iconHeight} />
        ) : (
          <NavIcon className={iconClass} text={title} />
        )}
      </NavLink>
      <label htmlFor="link">
        <FormattedMessage id={title} defaultMessage={title} />
      </label>
    </li>
  );
};

Tab.defaultProps = {
  title: "",
  menuKey: "",
  active: "",
  iconWidth: 0,
  iconHeight: 0,
  icon: "",
  className: ""
};
Tab.propTypes = {
  routeLink: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  menuKey: PropTypes.string,
  active: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  className: PropTypes.string,
  brandDetails: PropTypes.shape({
    primary_color: PropTypes.string
  })
};

export default withRouter(Tab);
