import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedHTMLMessage } from "components/common/formatted-message";
import { getLogin } from "state/selectors";
const FormattedBrandMessage = props => {
  const {
    id = "",
    defaultMessage = "",
    brand_names = {
      ARCSERVE_PRODUCT_NAME: "Arcserve",
      CD_PRODUCT_NAME: "Cloud Direct",
      CH_PRODUCT_NAME: "Cloud Hybrid",
      UDP_PRODUCT_NAME: "UDP"
    },
    values = {}
  } = props;

  return (
    <FormattedHTMLMessage
      id={id ? id : " "}
      values={{ ...brand_names, ...values }}
      defaultMessage={defaultMessage}
    />
  );
};
FormattedBrandMessage.defaultProps = {
  id: "",
  defaultMessage: ""
};

FormattedBrandMessage.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
  const mapStateToProps = state => ({
    brand_names: getLogin(state).brand_names
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(FormattedBrandMessage);
