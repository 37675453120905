import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import IconMUI from "components/common/icon/IconMUI";
import { getMinimumSearchLength } from "utils/SpogDataGridUtil";
import { ID_PREFIX } from "configs/IdConfig";
import { useSelector } from "react-redux";
import {
  Paper,
  TextField,
  Divider,
  IconButton,
  InputAdornment
} from "@mui/material";
import { ArrowDropDown, Search } from "@mui/icons-material";
import FormattedMessage from "../formatted-message";
import { DARK_MODE } from "utils/theme";

/**
 * Component to render Input box with search icon
 */

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: "2px 4px",
//     display: "flex",
//     alignItems: "center",
//     width: 400
//   },
//   input: {
//     marginLeft: theme.spacing(1),
//     flex: 1
//   },
//   iconButton: {
//     padding: 10
//   },
//   divider: {
//     height: 28,
//     margin: 4
//   }
// }));

const InputSearch = ({
  searchText,
  intl,
  handleSearchClick,
  handleSearchTextChange,
  isArrow,
  disableSearchButton,
  onClickError,
  retainSearchText,
  testId,
  id,
  arrowIconId,
  searchIconId,
  className,
  disableNameSearchInput,
  fromModal,
  ...props
}) => {
  const themeMode = useSelector(
    state => state?.reducer?.theme?.palette?.mode || ""
  );
  const [error, setError] = useState(false);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchText === "") {
      handleTextClear();
    }
  }, [searchText]);
  /**
   * callback on click of caret in input box
   */
  const handleCaretClick = () => {
    props.handleCaretClick();
  };

  const handleSearchEnterEvent = e => {
    e.preventDefault();
    if (searchText.trim().length >= getMinimumSearchLength(intl.locale)) {
      setError(false);
      if (handleSearchClick) {
        handleTextClear();
        handleSearchClick();
      }
    } else if (searchText.trim().length > 0 && searchText.trim().length < 3)
      setError(true);
  };

  const updateSearchText = e => {
    if (
      e.target.value.trim().length === 0 ||
      e.target.value.trim().length >= getMinimumSearchLength(intl.locale)
    ) {
      setError(false);
    }
    handleSearchTextChange(e.target.value);
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      if (e.target.value.trim().length >= getMinimumSearchLength(intl.locale)) {
        setError(false);
        if (!retainSearchText) {
          handleSearchClick && handleTextClear();
        }
        handleSearchClick && handleSearchClick(true);
      } else if (
        e.target.value.trim().length > 0 &&
        e.target.value.trim().length < getMinimumSearchLength(intl.locale)
      ) {
        handleSearchClick && setError(true);
      }
      e.stopPropagation();
      e.preventDefault();
    }
  };

  // TODO: Cleanup
  const handleTextClear = () => {
    searchInputRef.current.value = "";
  };

  const { hideSearchButtonInModal } = props;

  const placeholder = props.placeholder
    ? typeof props.placeholder === "string"
      ? intl.formatMessage({
          id: props.placeholder,
          defaultMessage: props.placeholder
        })
      : props.placeholder
    : "";

  const inputBaseId = testId ? `${ID_PREFIX}${testId}` : id ? id : "";

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 300,
        background: "transparent",
        marginBottom: "12px"
      }}
    >
      <TextField
        id={inputBaseId}
        placeholder={placeholder}
        className={
          fromModal && themeMode === DARK_MODE
            ? `input-search-mui-field-for-modal-dark-mode ${className || ""}`
            : `input-search-mui-field ${className || ""}`
        }
        // sx={{ flex: 1 }}
        onKeyDown={keyPress}
        disabled={disableNameSearchInput}
        onChange={updateSearchText}
        inputRef={searchInputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isArrow && (
                <IconMUI
                  sx={{ cursor: "pointer" }}
                  icon={ArrowDropDown}
                  onClick={() => handleCaretClick()}
                  id={arrowIconId}
                />
              )}
              <Divider
                sx={{
                  height: 37,
                  margin: "10px",
                  borderColor: fromModal
                    ? themeMode === DARK_MODE && "rgba(255, 255, 255, 0.7)"
                    : themeMode === DARK_MODE
                    ? "rgba(255, 255, 255, 0.7)"
                    : "rgba(0, 0, 0, 0.6)"
                }}
                orientation="vertical"
              />
              {!hideSearchButtonInModal && (
                <IconButton
                  aria-label="directions"
                  sx={{ padding: "0px" }}
                  onClick={e => {
                    handleSearchEnterEvent(e);
                  }}
                  disabled={disableSearchButton || searchText.trim().length < 1}
                  id={searchIconId}
                >
                  <IconMUI
                    icon={Search}
                    style={{
                      color:
                        themeMode === DARK_MODE
                          ? "rgba(255, 255, 255, 0.7)"
                          : "rgba(0, 0, 0, 0.6)"
                    }}
                  />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        sx={
          disableNameSearchInput && {
            "& .MuiInputBase-input.Mui-disabled": {
              backgroundColor: "transparent"
            }
          }
        }
      />

      {/* <Divider sx={{ height: 28,margin: 4}} orientation="vertical" />
      <IconButton
        color="primary"
        sx={{padding: 10}}
        aria-label="directions"
      >
        <IconMUI icon={Directions} />
      </IconButton> */}
      <div className="user-registration input-search-error-wrapper">
        {error && onClickError && (
          <FormattedMessage
            id="advanced.search.input.error"
            defaultMessage="Minimum 3 characters required."
          />
        )}
      </div>
    </Paper>
  );
};

/**
 * Default prop types fot input search
 */
InputSearch.defaultProps = {
  isArrow: false,
  placeholder: "",
  hideSearchButtonInModal: false,
  onClickError: true,
  retainSearchText: false,
  searchText: "",
  disableSearchButton: true,
  className: "",
  testId: "",
  id: "",
  fromModal: false
};

InputSearch.propTypes = {
  isArrow: PropTypes.bool,
  placeholder: PropTypes.string,
  handleCaretClick: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  hideSearchButtonInModal: PropTypes.bool,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func.isRequired,
  disableSearchButton: PropTypes.bool,
  onClickError: PropTypes.bool,
  retainSearchText: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string
  }).isRequired,
  testId: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  fromModal: PropTypes.bool
};

export default injectIntl(InputSearch);
