import moment from "moment";

export const convertGBtoTB = value => (value / 1024).toFixed(2);

export const lookupResolutionXaxisFormat = (resolution, locale, value) => {
  switch (locale) {
    case "ja":
    case "pt":
    case "de":
    case "fr":
    case "it":
    case "es": {
      moment.locale(locale);
      switch (resolution) {
        case "hour":
          return moment(value, "LLL").format("HH:mm");
        case "day":
          return moment(value, "LLL").format("l");
        case "month":
          return moment(value, "LLL").format("MM/YY");
        default:
          return moment(value, "LLL").format("l");
      }
    }
    case "en": {
      switch (resolution) {
        case "hour":
          return moment(new Date(value), "LLL").format("LTS");
        case "day":
          return moment(new Date(value), "LLL").format("l");
        case "month":
          return moment(new Date(value), "LLL").format("MMM YY");
        default:
          return moment(new Date(value), "LLL").format("l");
      }
    }
    default:
      return moment(value, "LLL").format("l"); //default
  }
};

export const setIntervalTicks = (x, y) => {
  // For BUG_1113328 Added this condition(Y===0) resolution is month on initial payload, After changing a date, resolutions become hour.
  // Have to check this and change the condition later.
  if (x <= y || y === 0) return 0;
  let i = 1;
  while (true) {
    if (x / i <= y) return i;
    i += 1;
  }
};

export default {};
