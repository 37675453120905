import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Enumrates list of Add Source attributes known to the UI.
 */
export const AddSourceAttributes = {
  ADD_SOURCE_PROP_SOURCE_ID: "source_id",
  ADD_SOURCE_PROP_VM_NAME: "vm_name",
  ADD_SOURCE_PROP_DATA_CENTER: "vm_data_center",
  ADD_SOURCE_PROP_HOST: "vm_host_system",
  ADD_SOURCE_PROP_RESOURCE_POOL: "vm_resource_pool"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier =>
  "protect.source.addSource.".concat(identifier);

/**
 * Column definition for data-grid
 */
export const COLUMN_DEFS = [
  {
    accessor: AddSourceAttributes.ADD_SOURCE_PROP_VM_NAME,
    displayIdentifier: getDisplayIdentifier(
      AddSourceAttributes.ADD_SOURCE_PROP_VM_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { minWidth: 100 }
  },
  {
    accessor: AddSourceAttributes.ADD_SOURCE_PROP_DATA_CENTER,
    displayIdentifier: getDisplayIdentifier(
      AddSourceAttributes.ADD_SOURCE_PROP_DATA_CENTER
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { minWidth: 100 }
  },
  {
    accessor: AddSourceAttributes.ADD_SOURCE_PROP_HOST,
    displayIdentifier: getDisplayIdentifier(
      AddSourceAttributes.ADD_SOURCE_PROP_HOST
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { minWidth: 100 }
  },
  {
    accessor: AddSourceAttributes.ADD_SOURCE_PROP_RESOURCE_POOL,
    displayIdentifier: getDisplayIdentifier(
      AddSourceAttributes.ADD_SOURCE_PROP_RESOURCE_POOL
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { minWidth: 300 }
  }
];
