/* eslint-disable camelcase */
import DataGridConstants from "components/common/data-grid/DataGridConstants";
import { JOB_STATUS } from "utils/appConstants";
import _ from "lodash";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

export const JOB_STATUS_LIST = _.values(JOB_STATUS);

/**
 * mapping for i18n key
 */
export const JobNameNLSKey = {
  COMPLETE: "job_name_complete",
  SOURCE_ONLY: "job_name_source_only",
  FOR_SOURCE_ONLY: "job_name_for_source_only",
  DESTINATION_ONLY: "job_name_destination_only",
  NAME_ONLY: "job_name_only"
};

/**
 * mapping for i18n key
 */
export const JobNameNLSKeyHTML = {
  COMPLETE: "job_name_complete_html",
  SOURCE_ONLY: "job_name_source_only_html",
  FOR_SOURCE_ONLY: "job_name_for_source_only_html",
  DESTINATION_ONLY: "job_name_destination_only_html",
  NAME_ONLY: "job_name_only_html"
};

/**
 * job_type to Component mapping,
 * returns name of component to be rendered for job details
 */
export const JobDetailsComponent = {
  restore: "CDRestoreJobComponent",
  backup: "CDBackupJobComponent",
  backup_full: "CDBackupJobComponent",
  oracledb_backup_full: "UDPBackupFullComponent",
  vm_backup_full: "CDBackupJobComponent",
  cifs_backup_full: "CDBackupJobComponent",
  cifs_backup: "CDBackupJobComponent",
  vm_backup_incremental: "CDBackupJobComponent",
  cifs_backup_incremental: "CDBackupJobComponent",
  backup_incremental: "CDBackupJobComponent",
  oracledb_backup_incremental: "UDPBackupFullComponent",
  rps_replicate_in_bound: "CHReplicationINComponent",
  rps_replicate: "CHReplicationOUTComponent",
  deploy_policy_to_cloud_direct: "CDPolicyDeploymentComponent",
  rps_data_seeding: "UDPRPSJumpStartJobComponent",
  rps_data_seeding_in: "UDPRPSJumpStartJobComponent",
  vm_recovery: "UDPVMFullRecoveryComponent",
  conversion: "UDPVSBComponent",
  start_instant_vm: "IVMJobMonitorComponent",
  linux_instant_vm: "IVMJobMonitorComponent",
  // stop_instant_vm: "IVMJobMonitorComponent",
  vm_catalog_fs: "VmCatalogComponent",
  catalog_fs: "VmCatalogComponent",
  rps_merge: "UDPMergeComponent",
  udp_upgrade_site: "GatewayUpgradeMonitorComponent",
  assured_recovery: "UDPAssuredRecoveryComponent",
  assure_recovery: "UDPAssuredRecoveryComponent",
  backup_verified: "UDPBackupFullComponent",
  vm_backup_verified: "UDPBackupFullComponent",
  oracledb_backup_verified: "UDPBackupFullComponent",
  assured_security: "UDPAssuredSecurityComponent"
};

export const JobDetailsUDPComponent = {
  rps_replicate_in_bound: "UDPReplicationJobComponent",
  rps_replicate: "UDPReplicationJobComponent",
  backup: "UDPBackupFullComponent",
  backup_full: "UDPBackupFullComponent",
  oracledb_backup_full: "UDPBackupFullComponent",
  vm_backup_full: "UDPBackupFullComponent",
  cifs_backup_full: "UDPBackupFullComponent",
  cifs_backup: "UDPBackupFullComponent",
  vm_backup_incremental: "UDPBackupFullComponent",
  cifs_backup_incremental: "UDPBackupFullComponent",
  backup_incremental: "UDPBackupFullComponent",
  vm_backup_verified: "UDPBackupFullComponent",
  oracledb_backup_incremental: "UDPBackupFullComponent",
  rps_data_seeding: "UDPRPSJumpStartJobComponent",
  rps_data_seeding_in: "UDPRPSJumpStartJobComponent",
  restore: "UDPRestoreWindowsComponent",
  vm_recovery: "UDPVMFullRecoveryComponent",
  vm_catalog_fs: "VmCatalogComponent",
  catalog_fs: "VmCatalogComponent",
  virtual_standby: "UDPVSBComponent",
  conversion: "UDPVSBComponent",
  restore_linux: "UDPRestoreLinuxComponent",
  start_instant_vm: "IVMJobMonitorComponent",
  linux_instant_vm: "IVMJobMonitorComponent",
  rps_merge: "UDPMergeComponent",
  udp_upgrade_site: "GatewayUpgradeMonitorComponent",
  restore_sql: "UDPRestoreSQLComponent",
  assured_recovery: "UDPAssuredRecoveryComponent",
  assure_recovery: "UDPAssuredRecoveryComponent",
  // stop_instant_vm: "IVMJobMonitorComponent"
  backup_verified: "UDPBackupFullComponent",
  oracledb_backup_verified: "UDPBackupFullComponent",
  assured_security: "UDPAssuredSecurityComponent"
};

export const REPLICATION_IN = "rps_replicate_in_bound";
export const REPLICATION_OUT = "rps_replicate";
export const BACKUP_FULL = "backup_full";
export const BACKUP_INCREMENTAL = "backup_incremental";
export const REPLICATION_PROGRESS = "job.details.replication_progress";
export const BACKUP_PROGRESS = "job.details.backup_progress";
export const COMPRESSION = "job.details.compression";
export const BACKUP_VOLUMES = "Backing up volumes";
export const RPS_JUMPSTART_PROGRESS = "RPS Jumpstart Progress";
export const AR_TEST_PROGRESS = "job.details.test_progress";
export const OVERALL_DATA_REDUCTION = "Overall Data Reduction";
export const RESTORE_PROGRESS = "job.details.restore_progress";
export const TRANSFERRING_PROGRESS = "job.details.transferring_progress";
export const TRANSFERRING_RP_PROGRESS_TITLE =
  "job.details.transferring_rp_progress_title";
export const CREATING_CATALOG = "job.details.creating_catalog";
/**
 * Enumrates list of Jobs attributes known to the UI.
 */
export const JobsAttributes = {
  JOBS_PROP_JOB_ID: "job_id",
  JOBS_PROP_SOURCE: "source_name",
  JOBS_PROP_JOB_NAME: "job_name",
  JOBS_PROP_JOB_TYPE: "job_type",
  JOBS_PROP_JOB_STATUS: "job_status",
  JOBS_PROP_JOB_POLICY: "policy_name",
  JOBS_PROP_JOB_DESTINATION: "destination_name",
  JOBS_PROP_JOB_START_TIME: "start_time_ts",
  JOBS_PROP_JOB_END_TIME: "end_time_ts",
  JOBS_PROP_JOB_DURATION: "duration",
  JOBS_PROP_ORGANIZATION: "organization",
  JOBS_PROP_ACTIONS: "available_actions",
  JOBS_PROP_JOB_SITE: "site_name"
};

/**
 *  Job types for which source name field should be disabled
 */
export const JOB_TYPES_SOURCE_DISABLED = [
  "vm_catalog_fs",
  // "vm_backup_verified",
  "deploy_policy_to_udp",
  "merge",
  "udp_deploy_agent",
  "rps_merge",
  "catalog_fs",
  "undeploy_policy_to_udp",
  "undeploy_policy_to_cloud_direct",
  "udp_deploy_rps",
  "backup_verified"
];

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS : On click of cell the details will show
 * @type {{CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_JOB_DETAILS: JobsAttributes.JOBS_PROP_JOB_NAME,
  CLICKABLE_CELL_TO_VIEW_JOB_DETAILS_IN_PROGRESS:
    JobsAttributes.JOBS_PROP_JOB_STATUS,
  CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS: JobsAttributes.JOBS_PROP_SOURCE,
  CLICKABLE_CELL_TO_VIEW_DESTINATION_DETAILS:
    JobsAttributes.JOBS_PROP_JOB_DESTINATION,
  CLICKABLE_CELL_TO_VIEW_POLICY_DETAILS: JobsAttributes.JOBS_PROP_JOB_POLICY,
  CLICKABLE_CELL_TO_VIEW_ORGANIZATION_DETAILS:
    JobsAttributes.JOBS_PROP_ORGANIZATION
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => "common.jobs.".concat(identifier);

/**
 * Column definition for data-grid
 */
export const COLUMN_DEFS = [
  {
    accessor: JobsAttributes.JOBS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_SOURCE,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_SOURCE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_NAME,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_JOB_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 400 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_TYPE,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_JOB_TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.JOB_TYPE_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_SITE,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_JOB_SITE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_STATUS,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.PROGRESS_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_POLICY,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_DESTINATION,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_DESTINATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.RENDER_DESTINATION_OVERLAY_CELL_MUI
    },
    settings: { width: 250 }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_START_TIME,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_START_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_END_TIME,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_END_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_DURATION,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_DURATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DURATION_MUI },
    settings: {}
  },
  {
    accessor: JobsAttributes.JOBS_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_ACTIONS),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { sortable: false, width: 80 }
  }
];

export const RECENT_JOBS_COLUMN_DEFS = [
  {
    accessor: JobsAttributes.JOBS_PROP_SOURCE,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_SOURCE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_WITH_OVERLAY_MUI },
    settings: {}
  },

  {
    accessor: JobsAttributes.JOBS_PROP_JOB_STATUS,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.PROGRESS_TEXT_CELL_WITHOUT_PERCENTAGE_MUI
    },
    settings: { noFlex: true }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_JOB_DURATION,
    displayIdentifier: getDisplayIdentifier(
      JobsAttributes.JOBS_PROP_JOB_DURATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DURATION_MOMENT_MUI },
    settings: { width: 180, noFlex: true }
  },
  {
    accessor: JobsAttributes.JOBS_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifier(JobsAttributes.JOBS_PROP_ACTIONS),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { sortable: false, width: 50, noFlex: true }
  }
];
