import {
  CONNECT_AUTH_SUCCESS,
  CONNECT_AUTH_FAILURE,
  CLEAR_CONNECT_AUTH
} from "../../actions/actionTypes";
import { combineReducers } from "redux";

/**
 * Errors occured during Connect API call to LBS is maintained here
 * @param {*} state
 * @param {*} action
 */
const connectErrors = (state = null, { type, data }) => {
  switch (type) {
    case CONNECT_AUTH_FAILURE:
      return data;
    case CLEAR_CONNECT_AUTH:
      return null;
    default:
      return state;
  }
};

/**
 * If Connect API is success, then we are adding the fields
 * to connected list of servers to be added to sources
 * @param {*} state
 * @param {*} action
 */
const connectResponse = (state = null, { type, data }) => {
  switch (type) {
    case CONNECT_AUTH_SUCCESS:
      return data;
    case CLEAR_CONNECT_AUTH:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ connectErrors, connectResponse });
