import * as C from "state/constants";

const initialState = {
  isLoading: true,
  user: {}
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        user: {}
      });
    case C.FETCH_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: action.payload.data
      });
    case C.FETCH_USER_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        user: {}
      });
    default:
      return state;
  }
};

export default users;
