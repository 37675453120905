import {
  SHOW_REPORT_LIST,
  CLEAR_STATE,
  UPDATE_SELECTED_RESOURCE,
  CLEAR_SELECTED_RESOURCE,
  UPDATE_REPORT_DATA,
  CLOSE_REPORT_MODAL,
  REMOVE_ASSIGNED_SOURCE_GROUP,
  UPDATE_ASSIGNED_SOURCE_GROUPS,
  REMOVE_ASSIGNED_EMAIL,
  UPDATE_ASSIGNED_EMAILS,
  UPDATE_BACKUP_JOBS_TOP_SOURCES,
  UPDATE_BACKUP_JOBS_TREND_SUMMARY,
  UPDATE_DATA_TRANSFER_TREND_SUMMARY,
  UPDATE_DATA_TRANSFER_TREND_TOP_SOURCE,
  UPDATE_JOBS_AND_DESTINATION_JOBS_SUMMARY,
  UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE,
  UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION,
  UPDATE_CAPACITY_USAGE_CD_TREND_USAGE,
  UPDATE_CAPACITY_USAGE_CH_TREND_USAGE,
  UPDATE_CAPACITY_USAGE_CH_STORE_SAVING_TREND,
  UPDATE_RESTORE_JOBS_TOP_SOURCES,
  UPDATE_RESTORE_JOBS_TREND_SUMMARY,
  UPDATE_DATE_RANGE_VALUE,
  UPDATE_REPORT_TYPE_FOR_COMPONENT,
  UPDATE_SINGLE_REPORT_DETAILS,
  UPDATE_SINGLE_REPORT_REDIRECTION,
  INITIALIZE_EMAIL_SELECTOR,
  UPDATE_ASSIGNED_ORGANIZATIONS,
  UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT,
  REMOVE_ASSIGNED_ORGANIZATION,
  RESET_REPORT_MODAL,
  UPDATE_SELECTED_FILTER_OPTIONS_WIDGET,
  CLEAR_SELECTED_FILTER_OPTIONS_WIDGET,
  UPDATE_DATE_RANGE_VALUE_CAPACITY_DATATRANSFER,
  UPDATE_ACCOUNT_DETAILS_REPORT_DATA,
  UPDATE_SOURCE_PROTECTION_SOURCES_SUMMARY
} from "../actions/actionTypes";
import * as constant from "../../utils/appConstants";

const initialState = {
  data: {
    data: [],
    pagination: {
      curr_page: 1,
      total_page: 1,
      page_size: 25,
      has_prev: false,
      has_next: true,
      total_size: 25
    }
  },
  columnData: [],
  reportsData: {},
  assignedSourceGroups: [],
  assignedOrganizations: [],
  assignedEmails: [],
  topSources: [],
  trendSummary: [],
  restoreJobsTopSources: [],
  restoreJobsSummary: [],
  widgets: [],
  selectedDateValue: "last_24_hours",
  redirectedFromReport: false,
  selectedDateValueForCapacityDataTransfer: ""
};

const analyzeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_REPORT_LIST:
      return {
        ...state,
        reportsData: action.data
      };

    case UPDATE_REPORT_DATA: {
      return {
        ...state,
        showReportModal: true,
        reportModalData: action.data.report,
        trigger: action.data.trigger_source,
        reportDropdowns: {
          sourceGroups: action.data.sourceGroups,
          orgList: action.data.orgList
        }
      };
    }

    case CLOSE_REPORT_MODAL: {
      return {
        ...state,
        showReportModal: false,
        reportModalData: undefined,
        reportDropdowns: undefined,
        trigger: undefined,
        assignedSourceGroups: undefined,
        assignedEmails: undefined
      };
    }

    case CLEAR_STATE:
      return {
        ...state,
        data: [],
        columnData: [],
        reportsData: {},
        logData: [],
        jobsData: [],
        accountDetailsData: [],
        accountDetailsPagination: [],
        accountSummaryData: []
      };

    case UPDATE_SELECTED_RESOURCE:
      return { ...state, selectedResource: action.data };

    case CLEAR_SELECTED_RESOURCE:
      return { ...state, selectedResource: null };

    case REMOVE_ASSIGNED_SOURCE_GROUP: {
      return {
        ...state,
        assignedSourceGroups: state.assignedSourceGroups.filter(
          item => item.value !== action.id
        )
      };
    }

    case UPDATE_ASSIGNED_SOURCE_GROUPS: {
      return {
        ...state,
        assignedSourceGroups: getArrayIntersection(
          state.assignedSourceGroups,
          action.data
        )
      };
    }
    case UPDATE_SELECTED_FILTER_OPTIONS_WIDGET: {
      return {
        ...state,
        selectedWidgetDropdown: action.data
      };
    }
    case CLEAR_SELECTED_FILTER_OPTIONS_WIDGET: {
      return {
        ...state,
        selectedWidgetDropdown: undefined
      };
    }

    case REMOVE_ASSIGNED_ORGANIZATION: {
      return {
        ...state,
        assignedOrganizations: state.assignedOrganizations.filter(
          item => item.value !== action.id
        )
      };
    }

    case UPDATE_ASSIGNED_ORGANIZATIONS: {
      return {
        ...state,
        assignedOrganizations: getArrayIntersection(
          state.assignedOrganizations,
          action.data
        )
      };
    }

    case UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT: {
      return {
        ...state,
        assignedOrganizations: action.data
      };
    }
    //updateAssignedOrgsWhenEdit // UPDATE_ASSIGNED_ORGANIZATIONS_WHEN_EDIT

    case REMOVE_ASSIGNED_EMAIL: {
      return {
        ...state,
        assignedEmails: state.assignedEmails.filter(item => item !== action.id)
      };
    }

    case INITIALIZE_EMAIL_SELECTOR: {
      return {
        ...state,
        assignedEmails: action.data
      };
    }

    case UPDATE_ASSIGNED_EMAILS: {
      return {
        ...state,
        assignedEmails: updateArray(state.assignedEmails, action.data)
      };
    }

    case RESET_REPORT_MODAL: {
      return {
        ...state,
        assignedSourceGroups: [],
        assignedOrganizations: [],
        assignedEmails: []
      };
    }

    case UPDATE_BACKUP_JOBS_TOP_SOURCES: {
      return {
        ...state,
        topSources: translateKeys(action.data, constant.Stack)
      };
    }

    case UPDATE_BACKUP_JOBS_TREND_SUMMARY: {
      return {
        ...state,
        trendSummary: action.data
      };
    }

    case UPDATE_DATA_TRANSFER_TREND_SUMMARY: {
      return {
        ...state,
        transferSummaryData: action.data
      };
    }

    case UPDATE_DATA_TRANSFER_TREND_TOP_SOURCE: {
      return {
        ...state,
        transferTopSourceData: translateKeys(action.data, constant.Stack)
      };
    }
    //reducers Jobs and destination charts and grid
    case UPDATE_JOBS_AND_DESTINATION_JOBS_SUMMARY: {
      return {
        ...state,
        jobsAndDestinationJobsSummary: action.data
      };
    }
    case UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE: {
      return {
        ...state,
        jobsAndDestinationTopSource: action.data
      };
    }
    case UPDATE_JOBS_AND_DESTINATION_TREND_TOP_SOURCE_FOR_DESTINATION: {
      return {
        ...state,
        jobsAndDestinationForDestination: action.data
      };
    }

    //Capacity usage charts and grid
    case UPDATE_CAPACITY_USAGE_CD_TREND_USAGE: {
      return {
        ...state,
        capacityUsageCDTrendUsage: action.data
      };
    }
    case UPDATE_CAPACITY_USAGE_CH_TREND_USAGE: {
      return {
        ...state,
        capacityUsageCHTrendUsage: action.data
      };
    }
    case UPDATE_CAPACITY_USAGE_CH_STORE_SAVING_TREND: {
      return {
        ...state,
        capacityUsageCHStoreSavingTrend: action.data
      };
    }

    case UPDATE_RESTORE_JOBS_TOP_SOURCES: {
      return {
        ...state,
        restoreJobsTopSources: translateKeys(action.data, constant.Stack)
      };
    }
    case UPDATE_RESTORE_JOBS_TREND_SUMMARY: {
      return {
        ...state,
        restoreJobsSummary: action.data || []
      };
    }
    case UPDATE_DATE_RANGE_VALUE: {
      return {
        ...state,
        selectedDateValue:
          action.payload === "" ? state.selectedDateValue : action.payload
      };
    }

    case UPDATE_DATE_RANGE_VALUE_CAPACITY_DATATRANSFER: {
      return {
        ...state,
        selectedDateValueForCapacityDataTransfer: action.payload
          ? action.payload
          : ""
      };
    }

    case UPDATE_REPORT_TYPE_FOR_COMPONENT: {
      return {
        ...state,
        currentReportTypeAndId: action.data
      };
    }

    case UPDATE_SINGLE_REPORT_DETAILS: {
      return {
        ...state,
        currentSingleReportData: JSON.parse(action.data.data)
      };
    }

    case UPDATE_SINGLE_REPORT_REDIRECTION: {
      return {
        ...state,
        redirectedFromReport: JSON.parse(action.data)
      };
    }

    /**
     * update Analyze > Reports > Account Details Report data and pagination
     */
    case UPDATE_ACCOUNT_DETAILS_REPORT_DATA: {
      return {
        ...state,
        accountDetailsData: action.data.gridDataResponse.data,
        accountDetailsPagination: action.data.gridDataResponse.pagination,
        accountSummaryData: action.data.graphData.data
      };
    }

    case UPDATE_SOURCE_PROTECTION_SOURCES_SUMMARY: {
      return {
        ...state,
        sourceProtectionSourcesSummary: action.data.data
      };
    }

    default:
      return state;
  }

  function updateArray(arr, data) {
    let arrToReturn = [];
    if (arr && arr.length > 0) {
      arrToReturn = arr.map(item => item);
      if (arr.indexOf(data) < 0) {
        arrToReturn.push(data);
      }
    } else {
      arrToReturn.push(data);
    }
    return arrToReturn;
  }

  function getArrayIntersection(oldArray, newArray) {
    let arrToReturn = [];
    if (!oldArray) {
      oldArray = [];
    }
    if (!newArray) {
      newArray = [];
    }
    for (let i in oldArray) {
      let shared = false;
      for (let j in newArray)
        if (newArray[j].value === oldArray[i].value) {
          shared = true;
          break;
        }
      if (!shared) arrToReturn.push(oldArray[i]);
    }
    return arrToReturn.concat(newArray).sort(function(a, b) {
      return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
    });
  }

  function translateKeys(source, key) {
    let data;
    if (source !== null || source !== undefined) {
      if (Array.isArray(source)) {
        data = source;
      } else {
        data =
          source.data.sources !== undefined
            ? [...source.data.sources]
            : [...source.data];
      }
      data.map(obj => {
        obj.stack = obj[key];
        obj.newStack = obj[key];
        obj[key] =
          constant.ConvertText[
            constant.ConvertText[obj[key].toLowerCase()] !== undefined
              ? obj[key].toLowerCase()
              : obj.newStack
          ];
        return obj;
      });
    }
    return source;
  }
};

export default analyzeReducer;
