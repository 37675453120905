export const CCGRAPH_INITIALIZE = "CCGRAPH/INITIALIZE";

export const CCGRAPH_FETCH_DATA_REQUEST = "CCGRAPH/FETCH_DATA_REQUEST";
export const CCGRAPH_FETCH_DATA_SUCCESS = "CCGRAPH/FETCH_DATA_SUCCESS";
export const CCGRAPH_FETCH_DATA_FAILURE = "CCGRAPH/FETCH_DATA_FAILURE";

export const CCGRAPH_UPDATE_FILTERS_REQUEST = "CCGRAPH/UPDATE_FILTERS_REQUEST";
export const CCGRAPH_RESET_FILTERS_REQUEST = "CCGRAPH/RESET_FILTERS_REQUEST";

export const DASHBOARD_RECOVERY_POINT_WIGETS = [
  "DASHBOARD_NUMBER_OF_RECOVERY_POINTS",
  "DASHBOARD_LATEST_RECOVERY_POINTS",
  "DASHBOARD_OLDEST_RECOVERY_POINTS"
];
export const REPORT_RECOVERY_POINT_WIGETS = [
  "REPORTS_NUMBER_OF_RECOVERY_POINTS",
  "REPORTS_LATEST_RECOVERY_POINTS",
  "REPORTS_OLDEST_RECOVERY_POINTS"
];
