import cloneDeep from "lodash/cloneDeep";
import React, { PureComponent } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { isNumber } from "util";
import Tag from "../tags/index";
import {
  showHideSaveSearchModal,
  flushSelectedSavedSearch,
  removeFromSelectedSavedSearch,
  showCurrentSearch,
  showHideSaveSearchNClearAll
} from "../../../state/actions/actions";
import SaveSearchModal from "./SaveSearchModal";
import FormattedMessage from "../formatted-message";
import {
  makeShowSaveSearchModalState,
  makeClearAllOnDefaultState,
  makeAdvancedSearchOptionsState,
  makeSearchTextState
} from "../../../state/selectors/CommonSelectors";
import { getLocaleDate } from "../../../utils/SpogDataGridUtil";
import {
  PAGE_ROUTE,
  searchOptionClearDS,
  searchOptionClearRPS
} from "../../../utils/appConstants";
import { getLogin } from "state/selectors";
import { BRAND_NAMES, SECONDARY_NAVIGATION_TYPE } from "utils/appConstants";
const category = {
  RPS_ID: "rps_server_id"
};

class CurrentSearchCriteria extends PureComponent {
  getSelectedOptions = () => {
    let brandNames = get(this.props, "brand_names", BRAND_NAMES);
    const selectedOptions = [];

    if (
      this.props.searchOption.selectedOption &&
      this.props.searchOption.selectedOption.length > 0
    ) {
      selectedOptions.push({
        tagCategory: this.props.searchOption.name,
        display: this.props.searchOption.selectedOption,
        value: this.props.searchOption.selectedOption
      });
    }

    if (this.props.searchText && this.props.searchText.length) {
      selectedOptions.push({
        tagCategory: "name",
        display: this.props.searchText,
        value: this.props.searchText
      });
    }

    let options = cloneDeep(this.props.options);
    if (options) {
      Object.keys(options).forEach(option => {
        let values = options[option];
        if (option === "generated_on") {
          if (isNumber(values)) {
            let tagCategory = this.props.intl.formatMessage({
              id: option,
              defaultMessage: option
            });
            selectedOptions.push({
              key: option,
              tagCategory,
              display: getLocaleDate(values, "LL"),
              value: values
            });
          }
        } else if (
          option === "start_time_ts" ||
          option === "log_ts" ||
          option === "date_range"
        ) {
          if (values && Object.keys(values).length && values.type) {
            let tagCategory = this.props.intl.formatMessage({
              id: "date_range",
              defaultMessage: "Date Range"
            });
            if (values.type === "custom") {
              selectedOptions.push({
                key: option,
                tagCategory,
                display: `${getLocaleDate(
                  values.start_ts,
                  "LL"
                )} - ${getLocaleDate(values.end_ts, "LL")}`,
                value: values
              });
            } else {
              selectedOptions.push({
                key: option,
                tagCategory,
                display: this.props.intl.formatMessage({
                  id: values.type,
                  defaultMessage: values.type
                }),
                value: values
              });
            }
          }
        } else {
          let tagCategory = this.props.intl.formatMessage({
            id: option,
            defaultMessage: option
          });
          let tempDisplayValue = values[0];
          if (tempDisplayValue) {
            if (
              this.props.organization_type !== "msp" ||
              this.props.isImpersonationView
            ) {
              if (option === "role_id") {
                values.forEach(optionValues => {
                  if (optionValues === "direct_admin") {
                    tempDisplayValue = "admin";
                  }
                });
              }
            }
            selectedOptions.push({
              key: option,
              tagCategory,
              display:
                values.length > 1
                  ? this.props.intl.formatMessage(
                      {
                        id: "filters_selected"
                      },
                      { count: values.length }
                    )
                  : this.props.intl.formatMessage(
                      {
                        id: tempDisplayValue,
                        defaultMessage: tempDisplayValue
                      },
                      { ...brandNames }
                    ),
              value: values
            });
          }
        }
      });
    }
    if (
      selectedOptions.length === 0 &&
      !this.props.searchOption.selectedOption &&
      this.props.searchOption.selectedOption !== ""
    ) {
      this.clearAllTags();
    }
    return selectedOptions;
  };

  changeBreadcrumbOnTagClear = () => {
    const { history } = this.props;
    history.push(`${PAGE_ROUTE.DATA_STORE}`);
  };

  clearAllTags = () => {
    this.props.handleClearAllTags();
    const advancedSearchOptions = [];
    const searchText = "";
    const filterType = this.props.filterType;
    const resourceId = this.props.resourceId;

    this.props.handleAdvancedSearchClick({
      advancedSearchOptions,
      searchText,
      filterType,
      id: resourceId,
      clear: true
    });
    this.props.dispatch(flushSelectedSavedSearch());
    const { url } = this.props.match;
    if (
      url.includes(searchOptionClearDS) &&
      url.includes(searchOptionClearRPS)
    ) {
      this.changeBreadcrumbOnTagClear();
    }
    if (
      this.props.secondaryNavigation === SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS
    ) {
      this.props.dispatch(showCurrentSearch(false));
      this.props.dispatch(showHideSaveSearchNClearAll(true));
    }
  };

  callSaveSearchModal = () => {
    this.props.dispatch(showHideSaveSearchModal(true));
  };
  /**
   * Callback to handle event for tags close button
   * @param - array index of cleared tag input
   */
  handleTagClose = searchTag => {
    let key = searchTag.key[0];
    const filterType = this.props.filterType;
    const resourceId = this.props.resourceId;
    this.props.handleSearchTagsClose(key, filterType, resourceId);
    this.props.dispatch(removeFromSelectedSavedSearch(key));
    if (
      searchTag.category === category.RPS_ID &&
      this.props.match.url.includes(searchOptionClearDS)
    ) {
      this.changeBreadcrumbOnTagClear();
    }

    setTimeout(() => {
      const advancedSearchOptions = this.props.advancedSearchOptions;
      const searchText = this.props.searchText
        ? this.props.searchText
        : this.props.searchOption.selectedOption;
      let searches = this.getSelectedOptions();
      this.props.handleAdvancedSearchClick({
        advancedSearchOptions,
        searchText,
        filterType,
        id: resourceId,
        tagClose: true,
        searches: searches
      });

      if (searches.length === 0) {
        this.props.handleClearAllTags();
      }
    }, 0);
  };

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  render() {
    //these are dummy tags, needs to remove after component wired up
    let searches = this.getSelectedOptions();
    return (
      <div className={this.props.classes}>
        <span className="saved-filter-text">
          <FormattedMessage
            id="current_search_label"
            defaultMessage="Search results for : "
          />
        </span>
        <div className="tags-wrapper">
          <Tag
            tags={searches}
            onTagClose={this.handleTagClose}
            advSearchOptions={this.props.advSearchOptions}
            disableClose={this.props.disableClose}
            tagToDisable={this.props.tagToDisable}
          />
          {!this.props.showSaveSearchClearAllOnDefault && (
            <div className="pb-2">
              <button
                type="button"
                onClick={() => this.clearAllTags()}
                className="clear-all"
              >
                <FormattedMessage id="clearall" defaultMessage="Clear All" />
              </button>
              {!this.props.hideSaveSearchLink && (
                <button
                  type="button"
                  className="save-filter"
                  onClick={() => this.callSaveSearchModal()}
                >
                  <FormattedMessage
                    id="Save Filter Criteria"
                    defaultMessage="Save Filter Criteria"
                  />
                </button>
              )}
            </div>
          )}
        </div>
        <SaveSearchModal
          show={this.props.showSaveSearchModal}
          filterType={this.props.filterType}
          dataGridID={this.props.dataGridID}
        />
      </div>
    );
  }
}

CurrentSearchCriteria.defaultProps = {
  showSaveSearchModal: false,
  showSaveSearchClearAllOnDefault: true,
  hideSaveSearchLink: false,
  searchOption: {},
  options: [],
  resourceId: ""
};

CurrentSearchCriteria.propTypes = {
  options: PropTypes.shape({}).isRequired,
  handleSearchTagsClose: PropTypes.func.isRequired,
  handleClearAllTags: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  showSaveSearchModal: PropTypes.bool,
  showSaveSearchClearAllOnDefault: PropTypes.bool,
  filterType: PropTypes.string.isRequired,
  handleAdvancedSearchClick: PropTypes.func,
  searchOption: PropTypes.shape({
    selectedOption: PropTypes.string,
    name: PropTypes.string
  }),
  hideSaveSearchLink: PropTypes.bool,
  resourceId: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  advSearchOptions: PropTypes.arrayOf(PropTypes.shape({})),
  organization_type: PropTypes.string.isRequired,
  isImpersonationView: PropTypes.bool.isRequired,
  advancedSearchOptions: PropTypes.arrayOf(PropTypes.shape({})),
  searchText: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({}).isRequired
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getShowSaveSearchModalState = makeShowSaveSearchModalState();
  const getClearAllOnDefaultState = makeClearAllOnDefaultState();
  const getAdvancedSearchOptionsState = makeAdvancedSearchOptionsState();
  const getSearchTextState = makeSearchTextState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      showSaveSearchModal: getShowSaveSearchModalState(state, props),
      showSaveSearchClearAllOnDefault: getClearAllOnDefaultState(state, props),
      organization_type: state.reducer.organization_type,
      isImpersonationView: state.reducer.isImpersonationView,
      advancedSearchOptions: getAdvancedSearchOptionsState(state, props),
      searchText: getSearchTextState(state, props),
      brand_names: getLogin(state).brand_names,
      secondaryNavigation: state.reducer.secondaryNavigation
    };
  };
  return mapStateToProps;
};

export default withRouter(
  connect(makeMapStateToProps)(injectIntl(CurrentSearchCriteria))
);
