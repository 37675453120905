import DataGridConstants from "components/common/data-grid/DataGridConstants";
import { sortByOrder } from "utils/SpogDataGridUtil";

const HeaderTypes = DataGridConstants.GridColumnHeaderTypes;
const CellTypes = DataGridConstants.GridColumnCellTypes;

export const Action = {
  REFRESH: "protect.destination.rps.action.refresh",
  UPDATE: "protect.destination.rps.action.update",
  DELETE: "protect.destination.rps.action.delete",
  // ADD_DATA_STORE: "protect.destination.rps.action.add_datastore",
  ADD_CLOUD_DATA_STORE: "protect.destination.rps.action.add_clouddatastore",
  ADD_CLOUD_DATA_STORE_ACTION:
    "protect.destination.rps.action.add_cloud_data_store",
  IMPORT_DATA_STORE: "protect.destination.rps.action.import_datastore",
  ADD_DATA_STORE: "protect.destination.rps.action.add_local_datastore",
  IMPORT_LOCAL_DATA_STORE:
    "protect.destination.rps.action.import_local_datastore",
  IMPORT_CLOUD_DATA_STORE:
    "protect.destination.rps.action.import_cloud_datastore",
  RPS_JUMPSTART: "protect.destination.rps.action.rps_jumpstart",
  DEPLOY_RPS: "protect.destination.rps.action.deploy_rps",
  CANCEL_RPS: "protect.destination.rps.action.cancel_rps",
  BROWSE_DATA_STORE: "protect.destination.ds.action.browse",
  CANCEL_SCHEDULED_DEPLOY:
    "protect.destination.rps.action.cancel_scheduled_deploy"
};

export const i18n = {
  ACTION: "protect.desitnation.rps.grid.action",
  Name: "protect.desitnation.rps.grid.name"
};

export const COLUMNS = {
  ACTION: "available_actions",
  NAME: "name",
  SERVER_NAME: "server_name"
};

const COLUMN_DEFS = {
  columns: [
    {
      accessor: "available_actions",
      displayIdentifier: i18n.ACTION,
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: {
        type: CellTypes.ACTION_DROPDOWN_CELL_MUI,
        actionDropdown: true
      },
      settings: { sortable: false, width: 80 }
    },
    {
      accessor: "server_name",
      displayIdentifier: i18n.Name,
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.LINK_CELL_MUI },
      settings: {
        sortable: false,
        sortType: sortByOrder
      }
    },
    {
      accessor: "site_name",
      displayIdentifier: "protect.desitnation.rps.grid.site_name",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: "status",
      displayIdentifier: "protect.desitnation.rps.grid.status",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.ICON_CELL_MUI_WITHOUT_OVERLAY },
      settings: { sortable: false }
    },
    {
      accessor: "policies",
      displayIdentifier: "protect.desitnation.rps.grid.policies",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: {
        type: CellTypes.RENDER_POLICY_OVERLAY_CELL_MUI
      },
      settings: {
        width: 200,
        sortable: false
      }
    },
    {
      accessor: "datastore_count",
      displayIdentifier: "protect.desitnation.rps.grid.datastore_count",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: "rps_version",
      displayIdentifier: "protect.desitnation.rps.grid.version",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { sortable: false }
    },
    {
      accessor: "description",
      displayIdentifier: "protect.desitnation.rps.grid.description",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.HOVER_CELL_MUI },
      settings: { className: "rps-description", sortable: false }
    }
  ],
  columnData: [
    {
      id: "available_actions",
      accessor: "available_actions",
      identifier: "available_actions",
      Header: "Action",
      show: true,
      sort: false
    },
    {
      id: "name",
      accessor: "name",
      identifier: "name",
      Header: "Name",
      show: true,
      sort: false
    },
    {
      id: "site_name",
      accessor: "site_name",
      identifier: "site_name",
      Header: "Site",
      show: true,
      sort: false
    },
    {
      id: "status",
      accessor: "status",
      identifier: "status",
      Header: "Status",
      show: true,
      sort: false
    },
    {
      id: "policies",
      accessor: "policies",
      identifier: "policies",
      Header: "Policy",
      show: true,
      sort: false
    },
    {
      id: "datastore_count",
      accessor: "datastore_count",
      identifier: "datastore_count",
      Header: "Data Store Count",
      show: true,
      sort: false
    },
    {
      id: "deduplication",
      accessor: "deduplication",
      identifier: "deduplication",
      Header: "Deduplication",
      show: true,
      sort: false
    },
    {
      id: "version",
      accessor: "version",
      identifier: "version",
      Header: "Version",
      show: true,
      sort: false
    },
    {
      id: "description",
      accessor: "description",
      identifier: "description",
      Header: "Description",
      show: true,
      sort: false
    }
  ]
};
export const SOURCE_COLUMN_DEFS = {
  columns: [
    {
      accessor: "source_name",
      displayIdentifier: "protect.destination.rps.jumbstart.datagrid.name",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "first_recovery_point_formated",
      displayIdentifier:
        "protect.destination.rps.jumbstart.datagrid.first_recovery_point",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI },
      settings: { width: 180 }
    },
    {
      accessor: "most_recent_recovery_point_formated",
      displayIdentifier:
        "protect.destination.rps.jumbstart.datagrid.recent_recovery_point",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "recovery_points_count",
      displayIdentifier: "protect.destination.rps.jumbstart.datagrid.count",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    }
  ],
  columnData: [
    {
      id: "source_name",
      accessor: "source_name",
      identifier: "source_name",
      Header: "Source Name",
      show: true
    },
    {
      id: "first_recovery_point_formated",
      accessor: "first_recovery_point_formated",
      identifier: "first_recovery_point_formated",
      Header: "First Recovery Point",
      show: true
    },
    {
      id: "most_recent_recovery_point_formated",
      accessor: "most_recent_recovery_point_formated",
      identifier: "most_recent_recovery_point_formated",
      Header: "Most Recent Recovery Point",
      show: true
    },
    {
      id: "recovery_points_count",
      accessor: "recovery_points_count",
      identifier: "recovery_points_count",
      Header: "Recovery Count",
      show: true
    }
  ]
};
export const SOURCE_COLUMN_DEFS_SHARED_FOLDER = {
  columns: [
    {
      accessor: "source_name",
      displayIdentifier: "protect.destination.rps.jumbstart.datagrid.name",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "first_recovery_point_formated",
      displayIdentifier:
        "protect.destination.rps.jumbstart.datagrid.first_recovery_point",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "most_recent_recovery_point_formated",
      displayIdentifier:
        "protect.destination.rps.jumbstart.datagrid.recent_recovery_point",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "recovery_points_count",
      displayIdentifier: "protect.destination.rps.jumbstart.datagrid.count",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "encrypt",
      displayIdentifier: "protect.destination.rps.jumbstart.datagrid.encrypt",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    },
    {
      accessor: "validated",
      displayIdentifier: "protect.destination.rps.jumbstart.datagrid.validated",
      renderHeader: { type: HeaderTypes.HEADER_TEXT_MUI },
      renderRecord: { type: CellTypes.SHORT_TEXT_CELL_MUI }
    }
  ],
  columnData: [
    {
      id: "source_name",
      accessor: "source_name",
      identifier: "source_name",
      Header: "Source Name",
      show: true
    },
    {
      id: "first_recovery_point_formated",
      accessor: "first_recovery_point_formated",
      identifier: "first_recovery_point_formated",
      Header: "First Recovery Point",
      show: true
    },
    {
      id: "most_recent_recovery_point_formated",
      accessor: "most_recent_recovery_point_formated",
      identifier: "most_recent_recovery_point_formated",
      Header: "Most Recent Recovery Point",
      show: true
    },
    {
      id: "recovery_points_count",
      accessor: "recovery_points_count",
      identifier: "recovery_points_count",
      Header: "Recovery Count",
      show: true
    },
    {
      id: "encrypt",
      accessor: "encrypt",
      identifier: "encrypt",
      Header: "Encrypt",
      show: true
    },
    {
      id: "validated",
      accessor: "validated",
      identifier: "validated",
      Header: "Validated",
      show: true
    }
  ]
};

export default COLUMN_DEFS;
