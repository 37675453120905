import * as C from "state/constants";

const initialState = {
  isLoading: true,
  isLoadingDataStore: true,
  dataStores: {
    data: [],
    pagination: {}
  },
  dataStore: {}
};

const dataStores = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_DATA_STORES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case C.FETCH_DATA_STORES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        dataStores: action.payload
      });
    case C.FETCH_DATA_STORES_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        dataStores: {}
      });
    case C.FETCH_DATA_STORE_REQUEST:
      return Object.assign({}, state, {
        isLoadingDataStore: true,
        dataStore: {}
      });
    case C.FETCH_DATA_STORE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingDataStore: false,
        dataStore: action.payload
      });
    case C.FETCH_DATA_STORE_FAILURE:
      return Object.assign({}, state, {
        isLoadingDataStore: false,
        dataStore: {}
      });
    default:
      return state;
  }
};

export default dataStores;
