export default {
  content: {
    width: "1160px",
    margin: "auto",
    bottom: "auto",
    textAlign: "center"
  },
  overlay: {
    zIndex: 1300,
    overflow: "auto"
  }
};
