import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Constants from "../data-grid/DataGridConstants";
/**
 * Reusable component to render overlay
 */
class Overlay extends React.Component {
  /**
   * Default constructor
   * @param {*} props
   * @override
   */

  /**
   * Trigger on click of each anchor tag  in overlay
   * dispatch action to parent
   */
  handleLinkClick = () => {};

  /**
   * Function to get popover data
   */
  getPopoverContent = () => {
    return (
      this.props.overlayData &&
      this.props.overlayData.map((value, index) => (
        // eslint-disable-next-line
        <a key={index} onClick={this.handleLinkClick}>
          {value.key}
        </a>
      ))
    );
  };

  /**
   * Function to render content for popover
   * @return - html for overlay content
   */
  renderPopoverContent = () => {
    return (
      <Popover id="popover" title="" className={this.props.className}>
        {this.getPopoverContent()}
      </Popover>
    );
  };

  /**
   * Function to render content for tooltip
   * @return - html for overlay content
   */
  renderTooltipContent = props => {
    return (
      <Tooltip id="tooltip" className={this.props.className} {...props}>
        <span>{this.props.overlayData}</span>
      </Tooltip>
    );
  };
  // style={{fontFamily: 'Sans Source Pro Medium',
  //   fontSize: '10px',
  //   color: 'red'}}>

  renderOverlayForType = props => {
    switch (this.props.type) {
      case Constants.OverlayTypes.POPOVER:
        return this.renderPopoverContent();
      case Constants.OverlayTypes.TOOLTIP:
        return this.renderTooltipContent(props);
      default:
        break;
    }
  };

  // = () => {
  // _.get(this.overlayRef, "current.hide()");
  //};

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  render() {
    return (
      // <span onWheel={this.mouseOutHandler}>
      <OverlayTrigger
        trigger={this.props.triggerEvent}
        rootClose={false}
        placement={this.props.placement}
        overlay={this.renderOverlayForType}
        delay={{ show: 0, hide: this.props.delayHide || 200 }}
      >
        {
          // eslint-disable-next-line
          <a
            id={this.props.id}
            style={this.props.style ? this.props.style : {}}
          >
            {this.props.data}
          </a>
        }
      </OverlayTrigger>
      // </span>
    );
  }
}

Overlay.defaultProps = {
  triggerEvent: ["hover", "focus"],
  placement: "bottom"
};

Overlay.propTypes = {
  triggerEvent: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  placement: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]).isRequired,
  overlayData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  delayHide: PropTypes.number,
  linkClick: PropTypes.func,
  type: PropTypes.string
};
export default Overlay;
