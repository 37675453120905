import React, { PureComponent } from "react";
// import Modal from "react-modal";
import FormattedMessage from "components/common/formatted-message";
import Button from "components/common/button";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import { connect } from "react-redux";
import { updateUIVersion, updateAPIVersion } from "state/actions/actions";
import { makeRequestingLoginState } from "state/selectors/LoginSelectors";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";

const VERSION_CHECK_INTERVAL =
  process.env.REACT_APP_VERSION_CHECK_INTERVAL * 1000;
const HOST_BE_URL = `${process.env.REACT_APP_BE_URL}/`;

const MustRefresh = () => (
  <Modal open>
    <Paper className="version-info-modal">
      <FormattedBrandMessage id="refresh.message" defaultMessage="" />
      <Button
        content={<FormattedMessage id="refresh.button.title" />}
        onClick={() => window.location.reload()}
        style={{ padding: "0px", marginLeft: "5px" }}
        variant="outlined"
        color="primary"
      />
    </Paper>
  </Modal>
);

class VersionInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uiBranch: null,
      uiVersion: null,
      apiBranch: null,
      apiVersion: null,
      display: localStorage.getItem("display_version") !== null,
      versionMismatch: false
    };

    this.checkVersions = this.checkVersions.bind(this);
    this.fetchUiVersion = this.fetchUiVersion.bind(this);
    this.fetchApiVersion = this.fetchApiVersion.bind(this);
  }

  componentDidMount() {
    this.checkVersions(true /*Show version in footer*/);
    setInterval(() => {
      this.checkVersions(false /*Show version in footer*/);
    }, VERSION_CHECK_INTERVAL);
  }

  checkVersions(showVersionInFooter) {
    this.fetchUiVersion(showVersionInFooter);
    this.fetchApiVersion(showVersionInFooter);
  }

  fetchUiVersion(showVersionInFooter) {
    fetch("/ui-version.json", { cache: "no-cache" })
      .then(blob => blob.json())
      .then(json => {
        if (showVersionInFooter) {
          this.props.updateUIVersion({ uiVersion: json.version });
        }
        this.setState(state => ({
          uiBranch: json.branch,
          uiVersion: json.version,
          versionMismatch:
            state.versionMismatch ||
            (state.uiBranch && json.branch !== state.uiBranch) ||
            (state.uiVersion && json.version !== state.uiVersion) ||
            window.showVersionMismatch
        }));
      })
      .catch(e => console.log(e)); // eslint-disable-line no-console
  }

  fetchApiVersion(showVersionInFooter) {
    fetch(`${HOST_BE_URL}version`, { cache: "no-cache" })
      .then(blob => blob.json())
      .then(json => {
        const apiBranch = json.data ? json.data.branch : null;
        const apiVersion = json.data ? json.data.version : null;
        if (showVersionInFooter) {
          this.props.updateAPIVersion({ apiVersion: apiVersion });
        }
        this.setState(state => ({
          apiBranch,
          apiVersion,
          versionMismatch:
            state.versionMismatch ||
            (state.apiBranch && apiBranch !== state.apiBranch) ||
            (state.apiVersion && apiVersion !== state.apiVersion) ||
            window.showVersionMismatch
        }));
      })
      .catch(e => console.log(e)); // eslint-disable-line no-console
  }

  render() {
    const {
      display,
      uiBranch,
      uiVersion,
      apiBranch,
      apiVersion,
      versionMismatch
    } = this.state;

    if (versionMismatch) {
      // stop persistenting state
      //window.stopSavingState();

      // clear state from local storage - taken from login saga
      //sessionStorage.removeItem("token");
      //localStorage.removeItem("state");

      return <MustRefresh />;
    }

    if (!display) {
      return null;
    }

    return (
      <div>
        <div className="version-info__container">
          <div className="version-info__item">
            UI Branch: {uiBranch || "N/A"};
          </div>
          <div className="version-info__item">
            UI Version: {uiVersion || "N/A"};
          </div>
          <div className="version-info__item">
            API Branch: {apiBranch || "N/A"};
          </div>
          <div className="version-info__item">
            API Version: {apiVersion || "N/A"}
          </div>
        </div>
      </div>
    );
  }
}
const makeMapStateToProps = () => {
  const getRequestingLoginState = makeRequestingLoginState();

  const mapStateToProps = (state, props) => {
    return {
      requesting: getRequestingLoginState(state, props)
    };
  };
  return mapStateToProps;
};

window.displayVersionOn = () => {
  localStorage.setItem("display_version", "true");
  window.location.reload();
};

window.displayVersionOff = () => {
  localStorage.removeItem("display_version");
  window.location.reload();
};

export default connect(makeMapStateToProps, (dispatch, ownProps) => {
  return {
    updateUIVersion: data => dispatch(updateUIVersion(data)),
    updateAPIVersion: data => dispatch(updateAPIVersion(data))
  };
})(VersionInfo);
