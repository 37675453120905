import { combineReducers } from "redux";

import confirmations from "./confirmations";
import notifications from "./notifications";
import misc from "./misc";
import organizationSearch from "./organizationSearch";
import usersSearch from "./usersSearch";
import auditTrail from "./auditTrail";
import cloudHybrid from "./cloudHybrid";

const ui = combineReducers({
  confirmations,
  notifications,
  misc,
  organizationSearch,
  usersSearch,
  auditTrail,
  cloudHybrid
});

export default ui;
