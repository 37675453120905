import { combineReducers } from "redux";

import organizationSearch from "./organizationSearch";
import organizations from "./organizations";
import usersSearch from "./usersSearch";
import users from "./users";
import auditTrail from "./auditTrail";
import dataCenters from "./dataCenters";
import recoveryPointServers from "./recoveryPointServers";
import cloudHybridStores from "./cloudHybridStores";
import linuxBackupServers from "./linuxBackupServers";
import dataStores from "./dataStores";
import biReporting from "./biReporting";
import sites from "./sites";

const entites = combineReducers({
  organizationSearch,
  organizations,
  usersSearch,
  users,
  auditTrail,
  dataCenters,
  recoveryPointServers,
  cloudHybridStores,
  linuxBackupServers,
  dataStores,
  biReporting,
  sites
});

export default entites;
