import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ChipComponent from "../../common/chip";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import { ID_PREFIX } from "configs/IdConfig";
import { useSelector } from "react-redux";
import { DARK_MODE } from "utils/theme";

const MenuTabMUI = ({
  onClick,
  title,
  menuKey,
  routeLink,
  className,
  amount,
  clickable,
  displayCount
}) => {
  const theme = useSelector(state => state.reducer.theme);

  const handleClick = () => {
    onClick(title, menuKey);
  };

  const link = routeLink;

  return (
    <li
      className={clickable ? className : className + " menu-disabled"}
      value={title}
    >
      <NavLink
        to={link}
        onClick={handleClick}
        id={`${ID_PREFIX}zelHgU_${link
          .substring(1)
          .split("/")
          .join("-")}`}
        className={
          theme?.palette?.mode === DARK_MODE ? "nav-link-dark-mode" : ""
        }
      >
        <div className="d-flex justify-content-between list-wrapper word">
          <FormattedBrandMessage id={title} defaultMessage={title} />
          {clickable && displayCount ? (
            <ChipComponent
              className="menu-chip"
              size="small"
              color="secondary"
              label={
                <label
                  id={`${ID_PREFIX}zelHgU_${link
                    .substring(1)
                    .split("/")
                    .join("-")}_count`}
                >
                  {amount}
                </label>
              }
            />
          ) : (
            ""
          )}
        </div>
      </NavLink>
    </li>
  );
};

MenuTabMUI.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  menuKey: PropTypes.string,
  routeLink: PropTypes.string,
  className: PropTypes.string,
  amount: PropTypes.number,
  clickable: PropTypes.bool,
  displayCount: PropTypes.bool
};

export default MenuTabMUI;
