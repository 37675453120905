import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Cookies from "universal-cookie";
import { UCID } from "../appConstants";

const generateItem = (text, icon, url) => {
  return { text, icon, url };
};

const ListofItems = [
  // generateItem("Account", <PersonIcon />, "/profile"),
  // generateItem("Admin", <SupervisedUserCircleIcon />),
  // generateItem("Settings", <SettingsIcon />),
  generateItem("Log Out", <ArrowForwardIcon />, "/logout")
];

const UserConfigurations = ({ handleLogout, appPath, ucUrl }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <List>
      {ListofItems.map(({ text, icon, url }) => {
        return (
          <ListItem sx={{ width: "100%", padding: 0 }}>
            <ListItemButton
              key={<FormattedMessage id="learn_to" />}
              disabled={isDisabled}
              button
              // selected={url==="/profile"&&window.location.pathname.includes("/profile")}
              onClick={() => {
                if (text === "Log Out") {
                  const cookies = new Cookies();
                  let ucOpened = cookies.get("uc");
                  if (ucOpened) {
                    cookies.set("uc", "notOpened", {
                      path: "/",
                      domain: ".arcserve.com"
                    });
                    setIsDisabled(true);
                    setTimeout(() => {
                      let ucVal = cookies.get("uc");
                      if (ucVal == "opened") {
                        cookies.remove("isSignedIn", {
                          path: "/",
                          domain: ".arcserve.com"
                        });
                        handleLogout();
                      } else if (ucVal == "notOpened") {
                        cookies.remove("isSignedIn", {
                          path: "/",
                          domain: ".arcserve.com"
                        });
                        window.open(ucUrl, "_blank");
                        handleLogout();
                      }
                      setIsDisabled(false);
                    }, 1500);
                  } else {
                    cookies.remove("isSignedIn", {
                      path: "/",
                      domain: ".arcserve.com"
                    });
                    cookies.remove("cc", {
                      path: "/",
                      domain: ".arcserve.com"
                    });
                    handleLogout();
                  }
                } else {
                  if (url) {
                    window.location.href =
                      window.location.origin + appPath + url;
                  }
                }
              }}
            >
              <ListItemIcon
                style={{ minWidth: "42px" }}
                id={`${UCID}_logout_icon`}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="logout" />}
                id={`${UCID}_logout`}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default UserConfigurations;
