import * as C from "state/constants";

const initialState = {
  searchString: null,
  sortString: ""
};

const usersSearch = (state = initialState, action) => {
  switch (action.type) {
    case C.SET_USERS_SORT_STRING:
      return Object.assign({}, state, {
        sortString: action.payload.sortString
      });

    case C.SET_USERS_SEARCH_STRING:
      return Object.assign({}, state, {
        searchString: action.payload.searchString,
        sortString: ""
      });

    default:
      return state;
  }
};

export default usersSearch;
