import _ from "lodash";
import { createSelector } from "reselect";
import { VOLUME_TYPES } from "components/protect/policy/create-policy/destinations/DestinationConstants";

export const getReducer = state => state.reducer;
export const getApiResponse = state => state.reducer.apiResponse;
export const getFeatureFlag = state => _.get(state, "login.featureFlag", {});
export const getLogin = state => {
  if (isImpersonationView(state)) {
    return {
      ...state.login,
      organization_id: getReducer(state).customerOrgId,
      organization_name: getReducer(state).customerOrgName,
      organization_type: getReducer(state).customerOrgType,
      sub_msp: getReducer(state).isCustomerSubMSP,
      imcc_organization: getReducer(state).imccOrganization,
      role_id:
        getReducer(state).customerOrgType === "msp"
          ? "msp_admin"
          : "direct_admin",
      products: getProcessedProducts(state),
      main_organization_type: state.login.organization_type
    };
  }
  return state.login;
};

export const getOriginalLogin = state => state.login;
export const getConnectAuthResponse = state =>
  state.destination.browseAuthentication.connectResponse;
export const getConnectAuthError = state =>
  state.destination.browseAuthentication.connectErrors;

export const isImpersonationView = state =>
  getReducer(state).isImpersonationView;

export const getRoleId = state => getLogin(state).role_id;
export const getOriginalRoleId = state => state.login.role_id;
export const getLoginToken = state => getLogin(state).token;
export const getCDLoginToken = state => getLogin(state).cdtoken;
export const getIdToken = state => getLogin(state).idtoken;
export const getLogoutState = state => getLogin(state).octaLogout;
export const getLoginRootOrg = state => getLogin(state).root_organization;
export const getLoginIMCCOrg = state => getLogin(state).imcc_organization;
export const getLoginTokenExpiry = state => getLogin(state).tokenExpiry;
export const getCheckboxState = state => getLogin(state).UDP_backup_jobs;
export const getContextualAction = state => state.reducer.contextualAction;

// policy actions
export const getPolicy = state => _.get(state, "api.policy.policy", {});
export const getHypervisor = state =>
  _.get(state, "api.hypervisor.hypervisor", {});
export const getRemotePolicies = state =>
  _.get(state, "api.remotePolicies.remotePolicies", []);
export const getDestinations = state =>
  _.get(state, "api.destinations.destinations", []);

export const getCDDestinations = state =>
  getDestinations(state)
    .filter(d => {
      if (
        _.get(d, "cloud_direct_volume.volume_type", null) === VOLUME_TYPES.BAAS
      ) {
        return true;
      }
      return false;
    })
    .map(d => ({ key: d.destination_id, value: d.destination_name }));
export const getFailbackDestinations = state =>
  _.get(state, "api.failbackDestinations.failbackDestinations", []);
export const getEntitlementsProducts = state =>
  _.get(state, "api.entitlementsProducts.entitlementsProducts", {});

export const getProcessedProducts = createSelector(
  getEntitlementsProducts,
  entitlementsProducts => {
    let products = {};

    Object.keys(entitlementsProducts).forEach(product => {
      products[product] = entitlementsProducts[product].enable;
    });

    return products;
  }
);

export const getOrgId = createSelector(
  [getLogin, getReducer],
  (stateLogin, stateReducer) =>
    stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id
);

const backupProxyList = state => state.protectReducer.backupProxyList;
export const backupProxyStateKeyValueSelector = createSelector(
  backupProxyList,
  backupProxyList =>
    backupProxyList &&
    backupProxyList.map(data => ({
      key: data.proxy_id,
      value: data.proxy_name
    }))
);
