import {
  LOAD_CLOUD_ACCOUNT_LIST_SUCCESS,
  DELETE_CLOUD_ACCOUNT_SUCCESS,
  LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_SUCCESS
} from "../../actions/actionTypes";

const initialState = {
  cloudAccountList: [],
  cloudAccountListByRPSId: []
};

const CloudAccount = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CLOUD_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        cloudAccountList: action.data,
        cloudAccountPagination: action.pagination
      };
    case LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_SUCCESS:
      return {
        ...state,
        cloudAccountListByRPSId: action.data
      };

    case DELETE_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        cloudAccountList: deleteCloudAccountList(
          state.cloudAccountList,
          action.id
        ),
        cloudAccountPagination: deleteTotal(state.cloudAccountPagination)
      };
    default:
      return state;
  }
};

// const setCloudAccountList = data => {
//   const available_actions = ["delete", "modify"];
//   return data.map(rc => {
//     return {
//       ...rc,
//       id: uuidv4(),
//       available_actions: available_actions
//     };
//   });
// };

const deleteCloudAccountList = (data, id) => {
  return data.filter(rc => rc.cloud_account_id !== id);
};
const deleteTotal = data => {
  return {
    ...data,
    total_size: data.total_size - 1
  };
};

export default CloudAccount;
