import { connect } from "react-redux";
import { withRouter } from "react-router";
import { reduxForm } from "redux-form";

import { setOrganizationsSearchString, createUser } from "state/actions/csr";

import NewUserModal from "./NewUserModal";

export const formName = "new-user-modal";

const validate = values => {
  const errors = {};

  if (!values.organizationId) {
    errors.organizationId = "Required";
  }

  if (!values.firstName) {
    errors.firstName = "Required";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.roleId) {
    errors.roleId = "Required";
  }

  return errors;
};

const createReduxForm = reduxForm({ form: formName, validate });

export default withRouter(
  connect(
    () => ({
      initialValues: {
        roleId: "csr_admin"
      }
    }),
    (dispatch, ownProps) => ({
      onSubmit: values => {
        dispatch(
          createUser(
            values.organizationId,
            values.firstName,
            values.lastName,
            values.phoneNumber,
            values.email,
            values.roleId
          )
        );

        ownProps.history.push("/csr/user-search");
      },
      setOrganizationsSearchString: val =>
        dispatch(setOrganizationsSearchString(val))
    })
  )(createReduxForm(NewUserModal))
);
