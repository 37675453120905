/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import FormattedMessage from "../formatted-message";
import TextField from "@mui/material/TextField";
import { ID_PREFIX } from "configs/IdConfig";
import { useSelector } from "react-redux";
import { DARK_MODE } from "utils/theme";

export const InputFieldMUI = props => {
  const {
    input,
    type,
    meta: { visited, error },
    className,
    wrapperClassName,
    style,
    autoComplete,
    autoFocus,
    additionalProps = {},
    label,
    placeholder,
    multiline,
    rows,
    hideFloatingLabel,
    id,
    testId,
    inputStyle,
    errMsgStyle,
    preventDefaultOnBlur
  } = props;
  let fieldClassName = hideFloatingLabel
    ? "input-field-mui spog-mui-textfield"
    : "";
  //The below code prevents "e", "." , "-", "+" keys from entered into the input field
  let otherProps = { ...additionalProps };
  const themeMode = useSelector(state => state.reducer.theme.palette.mode);

  if (type === "number") {
    otherProps["onKeyDown"] = e => {
      // Prevent characters that are not numbers ("e", ".", "+" & "-")
      let isInvalid = false;
      if (e.key !== undefined) {
        isInvalid =
          e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
      } else if (e.keyCode !== undefined) {
        isInvalid =
          e.keyCode === 69 ||
          e.keyCode === 190 ||
          e.keyCode === 187 ||
          e.keyCode === 189;
      }
      return isInvalid && e.preventDefault();
    };
  }
  // The code below checks the type of the input field, and for a number field removes leading zeroes
  // For eg.: 0009, 010 --> 9, 10 etc
  if (type === "number") {
    let pos = 0;
    if (typeof myVar === "string" || input.value instanceof String) {
      let val = input.value.split("");

      for (let i = 0; i < val.length; i++) {
        if (Number(val[i]) > 0) {
          pos = i;
          break;
        }
      }
      input.value = input.value.substring(pos, input.value.length);
    }
  }

  let inputFieldId = id ? id : testId ? `${ID_PREFIX}${testId}` : "";

  return (
    <div className={wrapperClassName}>
      <TextField
        id={inputFieldId}
        inputProps={{
          style: {
            border: "none",
            padding: multiline ? "0" : "18.5px 14px",
            ...inputStyle
          },
          form: {
            autocomplete: "off"
          }
        }}
        label={!hideFloatingLabel ? label : null}
        placeholder={placeholder}
        variant="outlined"
        className={`${className} ${fieldClassName}`.trim()}
        error={error}
        style={style}
        multiline={multiline}
        rows={rows}
        {...input}
        {...otherProps}
        type={type}
        disabled={props.disabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onBlur={
          preventDefaultOnBlur
            ? e => {
                e.preventDefault();
              }
            : input.onBlur
        }
        sx={
          themeMode !== DARK_MODE
            ? {
                "& .MuiInputBase-input.Mui-disabled": {
                  backgroundColor: "rgba(0,0,0,0.12)",
                  color: "rgba(0,0,0,0.26)"
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  "& > fieldset": {
                    borderColor: error && "#EE6A5D !important"
                  }
                }
              }
            : {
                "& .MuiInputBase-input.Mui-disabled": {
                  backgroundColor: "rgba(255,255,255,0.12)",
                  color: "rgba(255,255,255,.38)",
                  borderColor: "rgba(255,255,255,0.12)"
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  "& > fieldset": {
                    borderColor: error && "#EE6A5D !important"
                  }
                }
              }
        }
      />

      {/* <input
        className={`${className} ${visited && error && "border-danger"}`.trim()}
        style={style}
        {...input}
        {...otherProps}
        type={type}
        disabled={props.disabled}
        autoComplete={autoComplete}
      /> */}
      {visited && error && (
        <div className="text-danger mt-1" style={{ ...errMsgStyle }}>
          <FormattedMessage id={error.id} values={error.values} />
        </div>
      )}
    </div>
  );
};

InputFieldMUI.propTypes = {
  autoFocus: PropTypes.bool,
  input: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape({})
    })
  }).isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  style: PropTypes.object,
  preventDefaultOnBlur: PropTypes.string
};

InputFieldMUI.defaultProps = {
  autoFocus: false,
  className: "",
  wrapperClassName: "",
  style: {},
  multiline: false,
  preventDefaultOnBlur: true
};

export default InputFieldMUI;
