import * as actionTypes from "./actionTypes";

export const updateSelectedFilterOptions = (data, flag) => ({
  type: actionTypes.UPDATE_SELECTED_FILTER_OPTIONS,
  data,
  flag
});

export const impersonateFromCSR = userType => ({
  type: actionTypes.IMPERSONATE_FROM_CSR,
  impersonateUserType: userType
});

export const setHasGoBackNavigation = data => ({
  type: actionTypes.SET_GO_BACK_NAVIGATION,
  data
});

export const clearState = () => ({
  type: actionTypes.CLEAR_STATE
});
export const clearStateWithoutColumnData = () => ({
  type: actionTypes.CLEAR_STATE_WITHOUT_COLUMN_DATA
});

export const clearResourceId = () => ({
  type: actionTypes.CLEAR_STATE_RESOURCE_ID
});

export const deleteDataStores = (ids, advancedSearchOptions = []) => ({
  type: actionTypes.DELETE_DATA_STORE,
  ids,
  advancedSearchOptions
});

export const forceDeleteDataStores = (ids, advancedSearchOptions = []) => ({
  type: actionTypes.FORCE_DELETE_DATA_STORE,
  ids,
  advancedSearchOptions
});

export const startDataStores = (ids, advancedSearchOptions = []) => ({
  type: actionTypes.START_DATA_STORE,
  ids,
  advancedSearchOptions
});

export const stopDataStores = (ids, advancedSearchOptions = []) => ({
  type: actionTypes.STOP_DATA_STORE,
  ids,
  advancedSearchOptions
});

export const refreshDataStore = ids => ({
  type: actionTypes.REFRESH_DATA_STORE,
  ids
});

export const disableShareDataStore = ids => ({
  type: actionTypes.DISABLE_SHARE_DATA_STORE,
  ids
});

export const selectMenuAction = ({ selectedMenu, selectedMenuKey }) => ({
  type: actionTypes.SELECT_MENU,
  selectedMenu,
  selectedMenuKey
});

export const selectFilterAction = ({ selectedFilter, selectedFilterKey }) => ({
  type: actionTypes.SELECT_FILTER,
  selectedFilter,
  selectedFilterKey
});

export const showGridLoader = isLoading => ({
  type: actionTypes.SHOW_GRID_LOADER,
  isLoading
});

export function collapseSidebar(payload) {
  return { type: actionTypes.COLLAPSE_SIDEBAR, payload };
}

export function showDataGrid(payload) {
  return { type: actionTypes.DATA_GRID, payload };
}

export const getLogList = payload => ({
  type: actionTypes.GET_LOG_LIST,
  payload
});

export const showLogList = (data, filterType) => ({
  type: actionTypes.SHOW_LOG_LIST,
  data,
  filterType
});

export const getAnalyzeCount = () => ({
  type: actionTypes.GET_ANALYZE_COUNT
});

export const getSourcesList = payload => ({
  type: actionTypes.GET_SOURCE_LIST,
  payload
});

export const getPoliciesList = payload => ({
  type: actionTypes.GET_POLICIES_LIST,
  payload
});

export const getDestinationList = () => ({
  type: actionTypes.GET_DESTINATION_LIST
});

export const getProtectListCount = () => ({
  type: actionTypes.GET_PROTECT_LIST_COUNT
});

export const getConfigureListCount = () => ({
  type: actionTypes.GET_CONFIGURE_COUNT
});

export const updateProtectListCount = (
  data = {},
  stateMenuData = [],
  statePrimaryNavigation = "Protect"
) => ({
  type: actionTypes.UPDATE_PROTECT_LIST_COUNT,
  data,
  stateMenuData,
  statePrimaryNavigation
});

export const updateSourceCount = (data = []) => ({
  type: actionTypes.UPDATE_SOURCE_LIST_COUNT,
  data
});

export const getCloudAccountsList = () => ({
  type: actionTypes.GET_CLOUD_ACCOUNTS_LIST
});

export const getInfrastructureListCount = () => ({
  type: actionTypes.GET_INFRASTRUCTURE_LIST_COUNT
});

export const updateCloudAccountsListCount = (
  data,
  stateMenuData,
  statePrimaryNavigation
) => ({
  type: actionTypes.UPDATE_INFRASTRUCTURE_LIST_COUNT,
  data,
  stateMenuData,
  statePrimaryNavigation
});

export const detectWindowDimensions = () => ({
  type: actionTypes.DETECT_WINDOW_DIMENSIONS
});

export const getColumnData = data => ({
  type: actionTypes.GET_COLUMN_DATA,
  data
});

export const toggleAdvancedSearch = data => ({
  type: actionTypes.TOGGLE_ADVANCED_SEARCH,
  data
});

export const updateAdvancedSearchOptions = data => ({
  type: actionTypes.UPDATE_ADVANCED_SEARCH,
  data
});

export const submitAdvancedSearch = () => ({
  type: actionTypes.ADVANCED_SEARCH_CLICKED
});

export const setAdvancedSearch = data => ({
  type: actionTypes.ADVANCED_SEARCH_SET,
  data
});

export const setCurrentTab = data => ({
  type: actionTypes.SET_CURRENT_TAB,
  data
});

export const getAdvancedSearchOptions = data => ({
  type: actionTypes.GET_ADVANCED_SEARCH_OPTIONS,
  data
});

export const showAdvancedSearchOptions = data => ({
  type: actionTypes.UPDATE_ADVANCED_SEARCH_OPTIONS,
  data
});

export const advanceSearchLoaded = payload => ({
  type: actionTypes.ADVANCE_SEARCH_LOADED,
  payload
});

export const getAdvancedSearchOptionsForInnerTable = data => ({
  type: actionTypes.GET_ADVANCED_SEARCH_OPTIONS_INNER_TAB,
  data
});

export const showAdvancedSearchOptionsForInnerTable = data => ({
  type: actionTypes.UPDATE_ADVANCED_SEARCH_OPTIONS_INNER_TAB,
  data
});

export const searchList = payload => ({
  type: actionTypes.ADVANCED_SEARCH_TRIGGERED,
  payload
});

export const removeSearchTags = data => ({
  type: actionTypes.REMOVE_SEARCH_TAGS,
  data
});

export const removeSearchTagsReload = data => ({
  type: actionTypes.REMOVE_SEARCH_TAGS_RELOAD,
  data
});

export const clearAllSearchTags = showCurrentSearch => ({
  type: actionTypes.CLEAR_ALL_SEARCH_TAGS,
  showCurrentSearch
});

export const clearAllSearchTagsReload = () => ({
  type: actionTypes.CLEAR_ALL_SEARCH_TAGS_RELOAD
});

export const searchEnterEvent = data => ({
  type: actionTypes.SEARCH_ENTITY,
  data
});

export const removeSearchTextTag = data => ({
  type: actionTypes.REMOVE_SEARCH_TEXT_TAG,
  data
});

export const updateSearchText = data => ({
  type: actionTypes.UPDATE_SEARCH_TEXT,
  data
});

export const getDetailsForData = data => ({
  type: actionTypes.GET_ALL_DETAILS,
  data
});

export const getArcserveCloudDetails = data => ({
  type: actionTypes.GET_ARCSERVE_CLOUD_DETAILS,
  data
});

export const getArcserveCloudVolume = data => ({
  type: actionTypes.GET_ARCSERVE_CLOUD_VOLUME,
  data
});

export const getSavedSearchData = payload => ({
  type: actionTypes.GET_DEFAULT_SAVED_SEARCH,
  payload
});

export const stopGetSavedSearchData = () => ({
  type: actionTypes.STOP_GET_DEFAULT_SAVED_SEARCH
});

export const updateSearchFiltersData = data => ({
  type: actionTypes.UPDATE_DEFAULT_SEARCH,
  data
});

export const updateCurrentSearchFromSaved = payload => ({
  type: actionTypes.UPDATE_CURRENT_SEARCH_FROM_SAVED,
  payload
});

export const showManageSearchModal = data => ({
  type: actionTypes.SHOW_MANAGE_SEARCH_MODAL,
  data
});

export const deleteSavedSearchData = (id, filter, dgId) => ({
  type: actionTypes.DELETE_SAVED_SEARCH,
  id,
  filter,
  dgId
});

export const editUpdateManageSavedSearch = (filter, isAdd = false, dgId) => ({
  type: actionTypes.EDIT_UPDATE_MANAGE_SAVE_SEARCH,
  filter,
  isAdd,
  dgId
});

export const editUpdateManageSavedSearchCCDataGrid = (
  filter,
  isAdd = false,
  saveSearchName = "",
  searchText = ""
) => ({
  type: actionTypes.EDIT_UPDATE_MANAGE_SAVE_SEARCH_CCDATAGRID,
  filter,
  isAdd,
  saveSearchName,
  searchText
});

export const getSearchFromDefaultSavedSearch = () => ({
  type: actionTypes.GET_SEARCH_FROM_DEFAULT_SAVED_SEARCH
});

export const showHideSaveSearchModal = data => ({
  type: actionTypes.SHOW_SAVE_SEARCH_MODAL,
  data
});

export const saveNewSearch = (saveSearchName, filter) => ({
  type: actionTypes.ADD_SAVE_SEARCH,
  saveSearchName,
  filter
});

export const selectunselectRows = data => ({
  type: actionTypes.UNSELECT_ALL_CHECKBOXES,
  data
});

export const selectunselectRow = data => ({
  type: actionTypes.SELECT_CHECKBOX,
  data
});

export const getSelectedRowsFromAllPages = data => ({
  type: actionTypes.GET_SELECTED_ROWS_FROM_ALL_PAGES,
  data
});

export const getSelectedRows = data => ({
  type: actionTypes.GET_SELECTED_ROWS,
  data
});

export const clearStateSelectedRows = () => ({
  type: actionTypes.CLEAR_STATE_SELECTED_ROWS
});

export const clearStateAdvancedParams = () => ({
  type: actionTypes.CLEAR_STATE_ADVANCED_PARAMS
});

export const addDestinations = data => ({
  type: actionTypes.ADD_DESTINATIONS,
  data
});

export const updatePaginationDropdown = data => ({
  type: actionTypes.UPDATE_PAGINATION_DROPDOWN,
  data
});

export const showHideSaveSearchNClearAll = data => ({
  type: actionTypes.SHOW_SAVEDSEARCH_CLEARALL_LINK,
  data
});

export const updateHypervisorInformation = (hypervisor_id, data) => ({
  type: actionTypes.EDIT_UPDATE_HYPERVISOR_INFORMATION,
  hypervisor_id,
  data
});

export const updateSelectedResource = data => ({
  type: actionTypes.UPDATE_SELECTED_RESOURCE,
  data
});

export const getPolicyDetails = (data, isNew) => ({
  type: actionTypes.GET_POLICY_DETAILS,
  data,
  isNew
});

export const destroyPolicy = () => ({
  type: actionTypes.DESTROY_POLICY
});

export const getPolicyPageDropdownsAndProtectionCard = data => ({
  type: actionTypes.GET_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD,
  data
});

export const updatePolicyPageDropdownsAndProtectionCard = data => ({
  type: actionTypes.UPDATE_POLICY_DETAILS_DROPDOWN_PROTECTIONCARD,
  data
});

export const disableSavePolicyButton = data => ({
  type: actionTypes.DISABLE_SAVE_POLCIY_BUTTON,
  data
});

export const updatePolicyDetails = data => ({
  type: actionTypes.UPDATE_POLICY_DETAILS,
  data
});

export const addScheduleJob = data => ({
  type: actionTypes.ADD_SCHEDULE_JOB,
  data
});

export const updateScheduleJob = data => ({
  type: actionTypes.UPDATE_SCHEDULE,
  data
});

export const editUpdateDestinationInformation = data => ({
  type: actionTypes.EDIT_UPDATE_DESTINATION_DETAILS,
  data
});

/**
 * get selected saved search data
 */
export const getSelectedSavedSearch = data => ({
  type: actionTypes.GET_SELECTED_SAVED_SEARCH,
  data
});

/**
 * update the state with the selected saved search data fetched using getSelectedSavedSearch above
 */
export const updateDefaultSavedSearch = data => ({
  type: actionTypes.UPDATE_DEFAULT_SAVEDSEARCH,
  data
});

/**
 * update the state with the selected saved search data fetched using getSelectedSavedSearch above
 */
export const updateCurrentSavedSearch = data => ({
  type: actionTypes.UPDATE_CURRENT_SAVEDSEARCH,
  data
});

/**
 * updates the saved search name for the selected search
 */
export const updateCurrentSavedSearchName = (searchName, searchID) => ({
  type: actionTypes.UPDATE_CURRENT_SAVEDSEARCH_NAME,
  savedSearchName: searchName,
  savedSearchID: searchID
});

/**
 * updates the saved search name for the selected search
 */
export const updateManageSavedSearchName = searchName => ({
  type: actionTypes.UPDATE_MANAGE_SAVEDSEARCH_NAME,
  savedSearchName: searchName
});

/**
 * updates the saved search - 'search text' for the selected search
 */
export const updateCurrentSavedSearchText = data => ({
  type: actionTypes.UPDATE_CURRENT_SAVEDSEARCH_TEXT,
  savedSearchText: data
});

/**
 * updates the mark as default saved search for the selected search
 */
export const updateCurrentSavedSearchMarkDef = data => ({
  type: actionTypes.UPDATE_CURRENT_SAVEDSEARCH_MARK_DEF,
  is_default: data
});

/**
 * updates the mark as default saved search for the selected search
 */
export const flushSelectedSavedSearch = () => ({
  type: actionTypes.FLUSH_CURRENT_SAVEDSEARCH
});

export const removeFromSelectedSavedSearch = key => ({
  type: actionTypes.REMOVE_FROM_SELECTED_SAVED_SEARCH,
  key
});

export const getJobsList = payload => ({
  type: actionTypes.GET_JOBS_LIST,
  payload
});

export const showJobsList = (data, filterType) => ({
  type: actionTypes.SHOW_JOBS_LIST,
  data,
  filterType
});

export const updateJobsLogsCount = (
  data,
  stateMenuData,
  statePrimaryNavigation
) => ({
  type: actionTypes.UPDATE_ANALYZE_LIST_COUNT,
  data,
  stateMenuData,
  statePrimaryNavigation
});

export const showAddDestination = data => ({
  type: actionTypes.SHOW_ADD_DESTINATION,
  data
});

export const updateRecoveryStatus = data => ({
  type: actionTypes.UPDATE_RECOVERY_STATUS,
  data
});

export const showConfirmation = data => ({
  type: actionTypes.SHOW_CONFIRMATION,
  data
});

export const showRecoveryPoint = data => ({
  type: actionTypes.SHOW_RECOVERY_POINT,
  data
});

export const getUserDetails = data => ({
  type: actionTypes.GET_USER_DETAILS,
  data
});

export const getOctaUserDetails = data => ({
  type: actionTypes.GET_OCTA_USER_DETAILS,
  data
});

export const showUpdatePhoto = data => ({
  type: actionTypes.SHOW_UPDATE_PHOTO,
  data
});

export const updateUserContactInformation = data => ({
  type: actionTypes.UPDATE_USER_CONTACT_INFO,
  data
});

export const updateImgURL = data => ({
  type: actionTypes.UPDATE_IMG_URL,
  data
});

export const croppedImgURL = data => ({
  type: actionTypes.CROPPED_IMG_URL,
  data
});

export const showInformation = data => ({
  type: actionTypes.SHOW_INFORMATION,
  data
});

export const updatePassword = data => ({
  type: actionTypes.UPDATE_PASSWORD,
  data
});

export const getVMsList = (id, gridCurrentPage, gridPageSize) => ({
  type: actionTypes.GET_VMS_LIST,
  id,
  gridCurrentPage,
  gridPageSize
});

export const updateBreadCrumbList = data => ({
  type: actionTypes.UPDATE_BREADCRUMB_LIST,
  data
});
export const uploadPhoto = data => ({
  type: actionTypes.UPLOAD_PHOTO,
  data
});

export const deletePhoto = data => ({
  type: actionTypes.DELETE_PHOTO,
  data
});

export const getDataCenters = () => ({
  type: actionTypes.GET_DATA_CENTERS
});

export const handleContextualAction = (
  contextualAction,
  data,
  showConfirmation
) => ({
  type: actionTypes.HANDLE_GRID_CONTEXTUAL_ACTIONS,
  data,
  contextualAction,
  showConfirmation
});

export const storeSourceIdForResumeVSB = data => ({
  type: actionTypes.STORE_SOURCE_ID_FOR_RESUME_VSB,
  data
});

export const deleteSourceById = (action, data, isHardDelete, gridInfo) => ({
  type: actionTypes.REMOVE_SOURCE,
  action,
  data,
  isHardDelete,
  gridInfo
});

export const upgradeAgentBySourceId = (data, action, gridInfo) => ({
  type: actionTypes.UPGRADE_AGENT,
  data,
  action,
  gridInfo
});

export const toggleBackupBySourceId = (data, backupAction, gridInfo) => ({
  type: actionTypes.TOGGLE_BACKUP,
  data,
  backupAction,
  gridInfo
});

export const toggleBackupBySourceIdWithPayload = (
  data,
  backupAction,
  gridInfo
) => ({
  type: actionTypes.TOGGLE_BACKUP_WITH_PAYLOAD,
  data,
  backupAction,
  gridInfo
});

export const addAPIresponse = data => ({
  type: actionTypes.API_RESPONSE,
  data
});

export const startFailbackSuccess = data => ({
  type: actionTypes.START_FAILBACK_SUCCESS,
  data
});

export const startFailbackFailure = data => ({
  type: actionTypes.START_FAILBACK_FAILURE,
  data
});

export const startFailbackLoading = (data = true) => ({
  type: actionTypes.START_FAILBACK_LOADING,
  data
});

export const deleteDestinationById = (data, name) => ({
  type: actionTypes.DELETE_DESTINATION,
  data,
  name
});

export const getSourceGroupList = data => ({
  type: actionTypes.GET_SOURCE_GROUP,
  data
});

export const assignUnassignSourceGroup = (
  data,
  assign,
  unassign,
  gridInfo
) => ({
  type: actionTypes.ASSIGN_UNASSIGN_SOURCE_GROUP,
  data,
  assign,
  unassign,
  gridInfo
});

export const createSourceGroup = (data, checkPagination = false) => ({
  type: actionTypes.CREATE_SOURCE_GROUP,
  data,
  checkPagination
});

export const addGridDefaultSelection = data => ({
  type: actionTypes.GRID_DEFAULT_SELECTION,
  data
});

/**
 * get list of users
 */
export const getUsers = payload => ({
  type: actionTypes.GET_USERS,
  payload
});

/**
 * get list of users
 */
export const getUsersV2 = payload => ({
  type: actionTypes.GET_USERS_V2,
  payload
});

/**
 * delete user by id
 */
export const deleteUser = data => ({
  type: actionTypes.DELETE_USER,
  userId: data
});

/**
 * get the user - roles
 */
export const getUserRoles = () => ({
  type: actionTypes.GET_USER_ROLES
});

/**
 * get details of selected role
 * @param {*} data
 */
export const getRoleDetails = data => ({
  type: actionTypes.GET_ROLE_DETAILS,
  data
});

/**
 * add a new user
 */
export const addUser = data => ({
  type: actionTypes.ADD_USER,
  data
});

export const manageMSPUserPermissions = data => ({
  type: actionTypes.MANAGE_MSP_USER_PERMISSIONS,
  data
});

export const showBrowseRecoveryPoint = data => ({
  type: actionTypes.SHOW_BROWSE_RECOVERY_POINT,
  data
});

export const switchRecoveryTabs = data => ({
  type: actionTypes.SWITCH_RECOVERY_TABS,
  data
});

export const downloadRecoveryPoint = data => ({
  type: actionTypes.DOWNLOAD_RECOVERY_POINT,
  data
});
export const cancelJobById = (data, actionType) => ({
  type: actionTypes.CANCEL_JOB_BY_ID,
  data,
  actionType
});

export const deleteJobById = (data, actionType, pagination, filter) => ({
  type: actionTypes.DELETE_JOB_BY_ID,
  data,
  actionType,
  pagination,
  filter
});

export const cancelBackupJobById = (jobID, sourceID) => ({
  type: actionTypes.CANCEL_BACKUP_JOB_BY_ID,
  jobID,
  sourceID
});

export const getSelectedJobDetails = data => ({
  type: actionTypes.GET_JOB_DETAILS,
  data
});

export const getJobsMonitorDetails = data => ({
  type: actionTypes.GET_MONITOR_JOB_DETAILS,
  data
});

export const updateJobsMonitorDetails = data => ({
  type: actionTypes.UPDATE_MONITOR_JOB_DETAILS,
  data
});

export const clearJobsMonitorDetails = () => ({
  type: actionTypes.CLEAR_MONITOR_JOB_DETAILS
});

export const showJobDetails = data => ({
  type: actionTypes.SHOW_JOB_DETAILS,
  data
});

export const clearJobDetails = () => ({
  type: actionTypes.CLEAR_JOB_DETAILS
});

export const updateJobResourceInList = data => ({
  type: actionTypes.UPDATE_JOB_RESOURCE_IN_GRID,
  data
});

export const getCurrentActionCalled = payload => ({
  type: actionTypes.GET_CURRENT_ACTION_CALLED,
  payload,
  pollingAction: true
});

export const creatingNewPolicy = payload => ({
  type: actionTypes.CREATING_NEW_POLICY,
  createNewpolicy: payload
});

export const getCustomerAccountsList = payload => ({
  type: actionTypes.GET_CUSTOMER_ACCOUNTS_LIST,
  payload
});

export const deleteCustomerAccountById = data => ({
  type: actionTypes.DELETE_CUSTOMER_ACCOUNT,
  data
});

export const suspendCustomerAccountById = data => ({
  type: actionTypes.SUSPEND_CUSTOMER_ACCOUNT,
  data
});

export const enableCustomerAccountById = data => ({
  type: actionTypes.ENABLE_CUSTOMER_ACCOUNT,
  data
});

export const getSelectedCustomerAccountDetails = data => ({
  type: actionTypes.GET_CUSTOMER_ACCOUNT_DETAILS,
  data
});

export const editUpdateCustomerAccountInformation = data => ({
  type: actionTypes.EDIT_UPDATE_CUSTOMER_ACCOUNT_DETAILS,
  data
});
/**
 * Action to get license details
 * @param data
 * @returns {{type, data: *}}
 */
export const getLicenseDetails = pollingAction => ({
  type: actionTypes.GET_LICENSE_DETAILS,
  pollingAction
});

/**
 * Action to activate order details
 * @param data
 * @returns {{type, data: *}}
 */
export const activateOrder = data => ({
  type: actionTypes.ACTIVATE_ORDER,
  data
});

export const deleteRecoveredVM = (id, values) => ({
  type: actionTypes.DELETE_RECOVERED_VM,
  data: id,
  values
});

export const unmountRPS = (
  sourceId,
  mountPath,
  mountDiskSignature,
  mountedSourceID
) => ({
  type: actionTypes.UNMOUNT_RPS,
  sourceId,
  mountPath,
  mountDiskSignature,
  mountedSourceID
});

export const unmountRPSSQL = data => ({
  type: actionTypes.UNMOUNT_RPS_SQL,
  data
});

export const stopFailback = (id, values) => ({
  type: actionTypes.STOP_FAILBACK,
  data: id,
  values
});

export const startFinalFailbackStep = (id, values) => ({
  type: actionTypes.START_FINAL_FAILBACK_STEP,
  data: id,
  values
});

export const startFailback = data => ({
  type: actionTypes.START_FAILBACK,
  data
});

export const updateDeleteRecoveredVMInList = id => ({
  type: actionTypes.DELETE_RECOVERED_VM_FROM_GRID,
  data: id
});

export const getSelectedResourceDetails = data => ({
  type: actionTypes.RECOVERED_RESOURCE_DETAILS,
  data
});

export const getRecoveryPointsOnDestinationDetails = data => ({
  type: actionTypes.RECOVERY_POINS_DESTINATION_DETAILS,
  data
});

export const addCustomerAccounts = data => ({
  type: actionTypes.ADD_CUSTOMER_ACCOUNT_DATA,
  data
});

export const setBrowseURL = data => ({
  type: actionTypes.SET_BROWSE_URL,
  data
});

export const setResourceName = (resourceName, resourceId = "") => ({
  type: actionTypes.SET_RESOURCE_NAME,
  resourceName,
  resourceId
});

export const editSetUsageThreshold = (data, selectedCustomer) => ({
  type: actionTypes.EDIT_SET_USAGE_THRESHOLD_MODAL,
  data,
  selectedCustomer
});

export const getAdminsForCustomers = payload => ({
  type: actionTypes.GET_ADMIN_FOR_ASSIGN,
  payload
});

export const resendEnrollEmail = organizationId => ({
  type: actionTypes.RESEND_ENROLL_EMAIL,
  organizationId
});

/**
 * Action to switch to impersonated view.
 * Update state with Customer id, customer name and Impersonate state = true.
 * @param customerData
 *
 */
export const impersonateCustomer = customerData => ({
  type: actionTypes.IMPERSONATE_CUSTOMER,
  customerData
});

export const storeImpersonatedCSRData = customerData => ({
  type: actionTypes.STORE_CSR_DATA,
  customerData
});

export const impersonateSource = sourceData => ({
  type: actionTypes.IMPERSONATE_SOURCE,
  sourceData
});
export const impersonatePolicy = policyData => ({
  type: actionTypes.IMPERSONATE_POLICY,
  policyData
});

export const impersonateJob = analyzeData => ({
  type: actionTypes.IMPERSONATE_JOB,
  analyzeData
});

export const impersonateRecoveryPoints = recoveryPointsData => ({
  type: actionTypes.IMPERSONATE_RECOVERY_POINTS,
  recoveryPointsData
});

/* Action to switch to impersonated view.
 * Update state with Customer id, customer name and Impersonate state = true.
 * @param customerData
 *
 */
export const resetImpersonateCustomerState = customerData => ({
  type: actionTypes.RESET_IMPERSONATE_CUSTOMER,
  customerData
});

/**
 * Mock action for destination
 */

export const getDestinationListMock = payload => ({
  type: actionTypes.GET_DESTINATION_LIST_MOCK,
  payload
});

/**
 * Mock action for Protect Customer accounts
 */
export const getProtectCustomerList = payload => ({
  type: actionTypes.GET_PROTECT_CUSTOMER_LIST,
  payload
});

export const assignedAdminHandler = data => ({
  type: actionTypes.ASSIGNED_ADMIN_POST_DATA,
  data
});

/**
 * To delete policy by id
 * @param data
 * @returns {{type: *, data: *}}
 */
export const deletePolicyById = (data, action) => ({
  type: actionTypes.REMOVE_POLICY,
  data,
  action
});

export const disablePolicyById = (data, action) => ({
  type: actionTypes.DISABLE_POLICY,
  data,
  action
});

export const enablePolicyById = (data, action) => ({
  type: actionTypes.ENABLE_POLICY,
  data,
  action
});

export const deployPolicyById = (data, action) => ({
  type: actionTypes.DEPLOY_POLICY,
  data,
  action
});

export const refreshPolicyById = (data, action) => ({
  type: actionTypes.REFRESH_POLICY,
  data,
  action
});

export const backupPolicyById = (data, action) => ({
  type: actionTypes.BACKUP_POLICY,
  data,
  action
});
export const replicatePolicyById = (data, action) => ({
  type: actionTypes.REPLICATE_POLICY,
  data,
  action
});
export const sharePolicyById = (data, action) => ({
  type: actionTypes.SHARE_POLICY,
  data,
  action
});

export const unassignAdminFromCustomersList = data => ({
  type: actionTypes.UNASSIGN_ADMIN_FROM_CUSTOMERS_LIST,
  data
});

/**
 * get recovery points
 */
export const getRecoveryPoints = payload => ({
  type: actionTypes.GET_RECOVERY_POINTS,
  payload
});

/**
 * To add destionation filter values for schedules in policy
 * @param payload
 * @returns {{type, payload: *}}
 */
export const addDestinationDropdownsForSchedule = payload => ({
  type: actionTypes.FILTER_POLICY_SCHEDULES_DROPDOWN,
  payload
});

/**
 * Update source -> configuration details
 */
export const updateSourceConfiguration = payload => ({
  type: actionTypes.UPDATE_SOURCE_CONFIGURATION,
  payload
});

/**
 * to delete a source group
 */
export const deleteSourceGroup = (data, isDetailPage = false) => ({
  type: actionTypes.DELETE_SOURCE_GROUP,
  id: data,
  isDetailPage
});

/**
 * get Sources for selected Source Group
 */
export const getSourcesByGroup = data => ({
  type: actionTypes.GET_SOURCES_BY_GROUP,
  id: data
});

/**
 * remove source/s from source group
 */
export const removeSourcesFromGroup = data => ({
  type: actionTypes.REMOVE_SOURCES_FROM_GROUP,
  data
});

/**
 * assign multiple sources to group
 */
export const addSourcesToGroup = data => ({
  type: actionTypes.ADD_SOURCES_TO_GROUP,
  data
});

/**
 * Action to get brand details to configure branding
 */
export const getBrandingDetails = () => ({
  type: actionTypes.GET_BRANDING_DETAILS
});

export const setLastViewedApp = data => ({
  type: actionTypes.SET_LAST_VIEWED_APP,
  data
});

export const updateMenuForMspMonitor = () => ({
  type: actionTypes.UPDATE_MENU_MSP_MONITOR
});

export const updateMenuForDirectMonitor = () => ({
  type: actionTypes.UPDATE_MENU_DIRECT_MONITOR
});

/**
 * Action to update brand details
 * @param data
 * @returns {{type, data: *}}
 */
export const updateBrandInformation = data => ({
  type: actionTypes.EDIT_UPDATE_BRAND_INFORMATION,
  data
});

/**
 * Action to clear brand details
 * @param data
 * @returns {{type, data: *}}
 */
export const clearBrandInformation = data => ({
  type: actionTypes.CLEAR_BRAND_INFORMATION,
  data
});

/**
 * Action to get emailer details to configure emailer
 */
export const getEmailerDetails = () => ({
  type: actionTypes.GET_EMAILER_DETAILS
});

/**
 * Action to update emailer details
 * @param data
 * @returns {{type, data: *}}
 */
export const updateEmailerInformation = data => ({
  type: actionTypes.EDIT_UPDATE_EMAILER_INFORMATION,
  data
});

/**
 * clear contextual action data
 */
export const clearContextualAction = () => ({
  type: actionTypes.CLEAR_CONTEXTUAL_ACTION
});

/**
 * set the sorted column data
 */
export const setSortData = data => ({
  type: actionTypes.SET_SORTED_DATA,
  data
});

/**
 * set the sorted column data
 */
export const clearSortData = () => ({
  type: actionTypes.CLEAR_SORTED_DATA
});

/**
 * get file browser recovry point data
 */
export const getFileBrowserData = data => ({
  type: actionTypes.GET_FILE_BROWSER_DATA,
  data
});

/**
 * update file browser recovry point data in reducer
 */
export const updateFileBrowserData = data => ({
  type: actionTypes.UPDATE_FILE_BROWSER_DATA,
  data
});

/**
 * update file browser recovery point data in reducer
 */
export const updateFileBrowserDataGridData = data => ({
  type: actionTypes.UPDATE_FILE_BROWSER_GRID_DATA,
  data
});

/**
 * update file browser recovery point pagination data in reducer
 */
export const updateFileBrowserDataGridPaginationData = data => ({
  type: actionTypes.UPDATE_FILE_BROWSER_GRID_PAGINATION_DATA,
  data
});

/**
 * set setRestoreCookieAuthAction
 */
export const setRestoreCookieAuthAction = data => ({
  type: actionTypes.SET_RESTORE_COOKIE_AUTH,
  data
});

/**
 * update cookie set or not
 */
// export const updateRestoreCookieAuthFlagAction = data => ({
//   type: actionTypes.UPDATE_RESTORE_COOKIE_FLAG,
//   data
// });

/**
 * set chart filter - 7D / 14D / 1Y...
 */
export const setChartFilter = data => ({
  type: actionTypes.SET_CHART_FILTER,
  data
});

export const updateColumnData = (stateColumnData, columnData) => ({
  type: actionTypes.UPDATE_COLUMN_DATA,
  columnData,
  stateColumnData
});

/**
 * display custom filter for chart
 */
export const showDateFilterHandler = data => ({
  type: actionTypes.SHOW_DATE_FILTER_HANDLER,
  data
});

/**
 * display custom filter for chart
 */
export const showDateFilterCHHandler = data => ({
  type: actionTypes.SHOW_DATE_FILTER_CH_HANDLER,
  data
});

export const showDateFilterCUHandler = data => ({
  type: actionTypes.SHOW_DATE_FILTER_CU_HANDLER,
  data
});
/**
 * to toggle alerts list panel
 */
export function toggleAlertsPanel(data) {
  return { type: actionTypes.TOGGLE_ALERTS_PANEL, data };
}
/**
 * to toggle alerts list panel
 */
export function hideAlertsPanel() {
  return { type: actionTypes.HIDE_ALERTS_PANEL };
}

export const resetExclusionCheckbox = data => ({
  type: actionTypes.RESET_EXCLUSION_CHECKBOX,
  data
});

export const resetPolicyPassword = data => ({
  type: actionTypes.RESET_POLICY_PASSWORD,
  data
});

export const resetSelectNetworkForbackupCheckbox = data => ({
  type: actionTypes.RESET_SELECTED_NETWORK_FOR_BACKUP_CHECKBOX,
  data
});

/**
 * display source groups in advance search values
 */
export const updateSourceGroupsForAdvanceSearch = data => ({
  type: actionTypes.UPDATE_SOURCE_GROUPS_FOR_ADVANCE_SEARCH,
  data
});

/**
 * display sites in advance search values
 */
export const updateSitesForAdvanceSearch = data => ({
  type: actionTypes.UPDATE_SITES_FOR_ADVANCE_SEARCH,
  data
});

/**
 * display source groups in advance search values
 */
export const updateDestinationsForAdvanceSearch = data => ({
  type: actionTypes.UPDATE_DESTINATIONS_FOR_ADVANCE_SEARCH,
  data
});

/**
 * display policies in advance search values
 */
export const updatePoliciesForAdvanceSearch = data => ({
  type: actionTypes.UPDATE_POLICIES_FOR_ADVANCE_SEARCH,
  data
});

export const updateSourcesForAdvanceSearch = data => ({
  type: actionTypes.UPDATE_SOURCES_FOR_ADVANCE_SEARCH,
  data
});

/**
 * display policies in advance search values
 */
export const updateGeneratedFromAdvanceSearch = data => ({
  type: actionTypes.UPDATE_GENERATED_FROM_ADVANCE_SEARCH,
  data
});

/**
 * display policies in advance search values
 */
export const updateOrganizationsForAdvanceSearch = data => ({
  type: actionTypes.UPDATE_ORGANIZATIONS_FOR_ADVANCE_SEARCH,
  data
});

export const addAdvancedSearchParam = data => ({
  type: actionTypes.ADD_ADVANCED_SEARCH_PARAM,
  data
});

// For User Support Data
export const getUserSupportData = data => ({
  type: actionTypes.GET_USER_SUPPORT_DATA,
  data
});

export const fetchUserSupportData = data => ({
  type: actionTypes.FETCH_USER_SUPPORT_DATA,
  data
});
/**
 * toggle for reduce redundant api's
 */
export const fromNavigationApi = data => ({
  type: actionTypes.FROM_NAVIGATION_API,
  data
});
/**
 * Persist column data in DB
 */
export const persistColumnData = (columnData, filterType) => ({
  type: actionTypes.PUT_COLUMN_DATA,
  columnData,
  filterType
});

export const exportLog = data => ({
  type: actionTypes.EXPORT_LOG,
  data
});

export const exportCustomerAccount = () => ({
  type: actionTypes.EXPORT_CUSTOMER_ACCOUNT
});

export const exportAuditLogs = () => ({
  type: actionTypes.EXPORT_AUDIT_LOGS
});

export const updateExportLogData = data => ({
  type: actionTypes.UPDATE_EXPORT_LOG,
  data
});

export const submitBtnDisable = flag => ({
  type: actionTypes.UPDATE_SUBMIT_BTN_STATE,
  flag
});

export const removeSingleAdvanceSearchOption = data => ({
  type: actionTypes.REMOVE_SINGLE_ADVANCESEARCH_OPTION,
  data
});

export const handleBulkAction = (
  resourceType,
  action,
  data,
  subType = "",
  dgId
) => ({
  type: actionTypes.HANDLE_BULK_ACTION,
  resourceType,
  action,
  data,
  subType,
  dgId
});

export const closeBulkAction = () => ({
  type: actionTypes.CLOSE_BULK_ACTION
});

export const showBulkAction = (resourceType, action, data) => ({
  type: actionTypes.SHOW_BULK_ACTION,
  resourceType,
  action,
  data
});

export const updateGridCurrentPageNumber = data => ({
  type: actionTypes.UPDATE_GRID_PAGE_NUMBER,
  data
});

export const updateGridCurrentPageSize = data => ({
  type: actionTypes.UPDATE_GRID_PAGE_SIZE,
  data
});

export const updateGridPagination = data => ({
  //true or false
  type: actionTypes.UPDATE_GRID_PAGINATION,
  data
});

export const showCurrentSearch = data => ({
  type: actionTypes.SHOW_CURRENT_SEARCH,
  data
});

export const clearAllMenusCountToZero = data => ({
  type: actionTypes.CLEAR_ALL_MENU_COUNTS,
  data
});

export const updateMenuCountsOnAddRemoveActions = data => ({
  type: actionTypes.UPDATE_MENU_COUNTS,
  data
});

export const updateTagsForCurrentSearch = () => ({
  type: actionTypes.UPDATE_TAGS_CURRENT_SEARCH
});

export const updateTagsForCurrentSearchValue = data => ({
  type: actionTypes.UPDATE_TAGS_CURRENT_SEARCH_VALUE,
  data
});

export const setResetFlag = () => ({
  type: actionTypes.SET_RESET_FLAG
});

export const startLoader = () => ({
  type: actionTypes.START_LOADER
});
export const stopLoader = () => ({
  type: actionTypes.STOP_LOADER
});

export const startGridLoaderById = data => ({
  type: actionTypes.START_GRID_LOADER_BY_ID,
  data
});

export const stopGridLoaderById = data => ({
  type: actionTypes.STOP_GRID_LOADER_BY_ID,
  data
});

export const updateColumnsAPIInProgress = data => ({
  type: actionTypes.COLUMNS_API_IN_PROGRESS,
  data
});

export const updateRowsAPIInProgress = data => ({
  type: actionTypes.ROWS_API_IN_PROGRESS,
  data
});

export const browseRemoteConsoleLoading = () => ({
  type: actionTypes.BROWSE_REMOTE_CONSOLE_LOADING
});

export const browseRemoteConsole = (data, path = null, browseType = "") => ({
  type: actionTypes.BROWSE_REMOTE_CONSOLE,
  data,
  path,
  browseType
});

export const browseRemoteConsoleSuccess = data => ({
  type: actionTypes.BROWSE_REMOTE_CONSOLE_SUCCESS,
  data
});

export const fetchResourcePool = data => ({
  type: actionTypes.FETCH_RESOURCE_POOL,
  data: data
});

export const browseResourcePoolSuccess = data => ({
  type: actionTypes.BROWSE_RESOURCE_POOL_SUCCESS,
  data
});

export const clearBrowseRemoteConsole = () => ({
  type: actionTypes.CLEAR_BROWSE_REMOTE_CONSOLE
});

export const browseRemoteConsoleFailure = data => ({
  type: actionTypes.BROWSE_REMOTE_CONSOLE_FAILURE,
  data
});

export const requestTrial = (
  trialType,
  dataCenterId,
  showLicenseDetailsAfterSuccess
) => ({
  type: actionTypes.CLOUD_HYBRID_TRIAL_REQUEST,
  trialType,
  dataCenterId,
  showLicenseDetailsAfterSuccess
});

export const requestUDPTrial = showLicenseDetailsAfterSuccess => ({
  type: actionTypes.UDP_TRIAL_REQUEST,
  showLicenseDetailsAfterSuccess
});

export const setColumnWidth = updatedColumnData => ({
  type: actionTypes.SET_COLUMN_WIDTH,
  updatedColumnData
});

export const advancedSearchLoading = data => ({
  type: actionTypes.ADVANCED_SEARCH_LOADING,
  data
});

export const advancedSearchLoaded = data => ({
  type: actionTypes.ADVANCED_SEARCH_LOADED,
  data
});

export const defaultSearchConfigured = data => ({
  type: actionTypes.DEFAULT_SEARCH_CONFIGURED,
  data
});

export const doNotInitializeGraph = data => ({
  type: actionTypes.DO_NOT_INITIALIZE_GRAPH,
  data
});

/**
 * This action will be triggered whenever the application starts
 * and if you want to reset any persisted value from redux
 * you should use this listen to this action to reset the value
 * Please do not trigger this action manually at any other point
 * of time
 */
export const initializeApp = () => ({
  type: actionTypes.INITIALIZE_APP
});

export const configureAccountType = data => ({
  type: actionTypes.CONFIGURE_ACCOUNT_TYPE,
  data
});

export const getOrganizationWithoutId = () => ({
  type: actionTypes.GET_ORGANIZATION_WITHOUT_ID
});

export const setOrganizationType = data => ({
  type: actionTypes.SET_ORGANIZATION_TYPE,
  data
});

export const addBackupSchedule = (data = "ele") => ({
  type: actionTypes.ADD_BACKUP_SCHEDULE,
  data: data
});
export const removeBackupSchedule = (data = 0) => ({
  type: actionTypes.REMOVE_BACKUP_SCHEDULE,
  data: data
});
export const ingramOrgFailure = data => ({
  type: actionTypes.INGRAM_ORG_FAILURE,
  data
});

export const showEnrollOrgModal = data => ({
  type: actionTypes.SHOW_INGRAM_ORG_MODAL,
  data
});
export const setHyperLink = data => ({
  type: actionTypes.SET_HYPER_LINK,
  data
});
export const setAdvanceSearchData = data => ({
  type: actionTypes.SET_ADVANCE_SEARCH_DATA,
  data
});
export const resetAdvanceSearchData = () => ({
  type: actionTypes.RESET_ADVANCE_SEARCH_DATA
});
export const setFilterFromMonitorWidget = data => ({
  type: actionTypes.SET_FILTER_FROM_MONITOR_WIDGET,
  data
});
export const resetFilterFromMonitorWidget = () => ({
  type: actionTypes.RESET_FILTER_FROM_MONITOR_WIDGET
});
export const updateUIVersion = data => ({
  type: actionTypes.UPDATE_UI_VERSION,
  payload: data
});
export const updateAPIVersion = data => ({
  type: actionTypes.UPDATE_API_VERSION,
  payload: data
});

export const pageReloaded = data => ({
  type: actionTypes.PAGE_RELOADED,
  payload: data
});

export const updateFolderTreeData = payload => ({
  type: actionTypes.UPDATE_FOLDER_TREE_DATA,
  payload
});
export const selectedSourcePagination = payload => ({
  type: actionTypes.SELECTED_SOURCE_PAGINATION,
  payload
});
export const hideFolderStructureLoader = () => ({
  type: actionTypes.HIDE_FOLDER_STRUCTURE_LOADER
});
export const updateFnFGridData = payload => ({
  type: actionTypes.UPDATE_FNF_TABLE_DATA,
  payload
});
export const resetFnFGridData = payload => ({
  type: actionTypes.RESET_FNF_TABLE_DATA,
  payload
});

export const updateFnFGridDataPagination = payload => ({
  type: actionTypes.UPDATE_FNF_TABLE_DATA_PAGINATION,
  payload
});
export const updateFnFPagination = payload => ({
  type: actionTypes.UPDATE_FNF_PAGINATION,
  payload
});

export const fetchFnFGridData = data => ({
  type: actionTypes.FETCH_FNF_TABLE_DATA,
  data
});

export const fetchFnFGridDataLinux = data => ({
  type: actionTypes.FETCH_FNF_TABLE_DATA_LINUX,
  data
});
export const setInitialPath = data => ({
  type: actionTypes.SET_INITIAL_PATH,
  data
});
export const setOrganizationIdForSourceCSR = data => ({
  type: actionTypes.SET_ORGANIZATION_ID_FOR_SOURCE_CSR,
  data
});

export const updateRecoveryConnectStatus = data => ({
  type: actionTypes.RECOVERY_FNF_CONNECT_STATUS,
  data
});

export const updateDSConnectStatus = data => ({
  type: actionTypes.DS_CONNECT_STATUS,
  data
});

export const getHypervisors = (fetchHypervForPage, fetchType) => ({
  type: actionTypes.GET_HYPERVISORS,
  fetchHypervForPage,
  fetchType
});

export const getHypervisorsSuccess = data => ({
  type: actionTypes.GET_HYPERVISORS_SUCCESS,
  data
});

export const resetHypervisorsList = () => ({
  type: actionTypes.RESET_HYPERVISORS_LIST
});

export const getHypervisorsFailed = () => ({
  type: actionTypes.GET_HYPERVISORS_FAILED
});

export const refreshHypervisors = data => ({
  type: actionTypes.REFRESH_HYPERVISORS,
  data: data
});

export const connectAddSourcesHypervisor = data => ({
  type: actionTypes.CONNECT_HYPERVISOR,
  data: data
});

export const refreshCloudAccount = data => ({
  type: actionTypes.REFRESH_CLOUD_ACCOUNT,
  data: data
});

export const setSelectedSourceOS = data => ({
  type: actionTypes.SET_SELECTED_SOURCE_OS,
  data: data
});

export const setTheme = data => {
  return {
    type: actionTypes.SET_THEME,
    data: data
  };
};

export const removeCCDataGridInstance = data => {
  return {
    type: actionTypes.REMOVE_CCDATAGRID_INSTANCE,
    data
  };
};

export const forceDeleteSource = id => ({
  type: actionTypes.FORCE_DELETE_SOURCE,
  id
});

export const forceDeleteRPS = id => ({
  type: actionTypes.FORCE_DELETE_RPS,
  id
});

export const clearSelectedProxyDetail = () => ({
  type: actionTypes.CLEAR_SELECTED_PROXY_DETAIL
});
