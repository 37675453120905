import { call } from "redux-saga/effects";
import { HOST_BE_URL, DOWNLOADS_URL } from "./apiUrlConstants";
import { getData } from "./apiMethods";

/**
 * To get the download data and links
 */
// eslint-disable-next-line import/prefer-default-export
export function* getDownloads(token) {
  try {
    const apiResponse = yield call(
      getData,
      HOST_BE_URL + DOWNLOADS_URL,
      "",
      token
    );
    return apiResponse;
  } catch (error) {
    window.console.log(error);
  }
}
