import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import Button from "../button";
import { ID_PREFIX } from "configs/IdConfig";

const DropdownButton = ({
  className,
  title,
  children,
  color,
  variant,
  size,
  endIcon,
  disabled,
  customButtonStyle,
  menuAlign,
  testId,
  id,
  closeOnClick = true,
  showManageSettingsModal,
  closeTriggeredFromParent
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (showManageSettingsModal) {
      setAnchorEl(null);
    }
  }, [showManageSettingsModal]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    closeTriggeredFromParent && setAnchorEl(null);
  }, [closeTriggeredFromParent]);

  return (
    <>
      <Button
        testId={testId ? `${testId}_button` : ""}
        id={id}
        color={color}
        content={title}
        className={className}
        onClick={handleClick}
        variant={variant}
        size={size}
        endIcon={endIcon}
        disabled={disabled}
        style={customButtonStyle ? { ...customButtonStyle } : {}}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={closeOnClick ? handleClose : () => {}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuAlign ? menuAlign : "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuAlign ? menuAlign : "right"
        }}
        id={`${ID_PREFIX}${testId}_menu`}
      >
        {children}
      </Menu>
    </>
  );
};

export default DropdownButton;
