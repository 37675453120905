/* eslint-disable import/prefer-default-export */
import _ from "lodash";

export const proxiesGrid = state => {
  let proxies =
    state &&
    state.destinations &&
    state.destinations.ivm &&
    state.destinations.ivm.proxiesResp
      ? state.destinations.ivm.proxiesResp.data
      : [];
  return proxies.map(proxy => ({
    id: proxy.proxy_id,
    source_name: proxy.proxy_name,
    status: proxy.connection_status,
    // policy:
    //   proxy.policy && proxy.policy.length ? proxy.policy[0].policy_name : "-",
    // products: proxy.agent.agent_name,
    os: proxy.operating_system.os_major,
    operating_system: {
      text: _.get(proxy, "operating_system.os_name", ""),
      iconClass: _.get(proxy, "operating_system.os_major", "")
    },
    agent: proxy.agent
  }));
};

export const lbsGrid = state => {
  let lbss =
    state && state.protectReducer && state.protectReducer.lbsList
      ? state.protectReducer.lbsList
      : [];
  return lbss.map(lbs => ({
    id: lbs.source_id,
    source_name: lbs.source_name,
    status: lbs.connection_status,
    os: lbs.operating_system.os_major,
    operating_system: {
      text: _.get(lbs, "operating_system.os_name", ""),
      iconClass: _.get(lbs, "operating_system.os_major", "")
    },
    agent: lbs.agent
  }));
};

export const selectedDestinationDataIVM = state =>
  state.ivm.selectedDestinationDataIVM;

export const proxiesPagination = state =>
  _.get(state, "state.destinations.ivm.proxiesResp.pagination", {
    curr_page: 1,
    total_page: 2,
    page_size: 25,
    has_prev: true,
    has_next: true,
    total_size: 15
  });
