import { combineReducers } from "redux";
import * as actionTypes from "state/actions/actionTypes";

const initialState = {
  isLoading: true,
  policy: {}
};

function policy(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_POLICY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case actionTypes.FETCH_POLICY_SUCCESS:
    case actionTypes.UPDATE_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        policy: action.payload
      });
    case actionTypes.DESTROY_POLICY:
    case actionTypes.FETCH_POLICY_FAILURE:
    case actionTypes.UPDATE_POLICY_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        policy: {}
      });
    default:
      return state;
  }
}

const initialStateHypervisor = {
  isLoading: true,
  hypervisor: {}
};

function hypervisor(state = initialStateHypervisor, action) {
  switch (action.type) {
    case actionTypes.FETCH_HYPERVISOR_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case actionTypes.FETCH_HYPERVISOR_SUCCESS:
    case actionTypes.UPDATE_HYPERVISOR_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        hypervisor: action.payload
      });
    case actionTypes.FETCH_HYPERVISOR_FAILURE:
    case actionTypes.UPDATE_HYPERVISOR_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        hypervisor: {}
      });
    default:
      return state;
  }
}

const initialStateRemotePolicies = {
  isLoading: true,
  remotePolicies: []
};

function remotePolicies(state = initialStateRemotePolicies, action) {
  switch (action.type) {
    case actionTypes.FETCH_REMOTE_POLICIES_CLEAR:
      return Object.assign({}, state, {
        remotePolicies: []
      });
    case actionTypes.FETCH_REMOTE_POLICIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case actionTypes.FETCH_REMOTE_POLICIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        remotePolicies: action.payload
      });
    case actionTypes.FETCH_REMOTE_POLICIES_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        remotePolicies: []
      });
    default:
      return state;
  }
}

const failbackDestinationsInitialState = {
  isLoading: true,
  failbackDestinations: []
};

function failbackDestinations(
  state = failbackDestinationsInitialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_FAILBACK_DESTINATIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case actionTypes.FETCH_FAILBACK_DESTINATIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        failbackDestinations: action.payload
      });
    case actionTypes.FETCH_FAILBACK_DESTINATIONS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        failbackDestinations: []
      });
    default:
      return state;
  }
}

const destinationsInitialState = {
  isLoading: true,
  destinations: []
};

function destinations(state = destinationsInitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_ALL_DESTINATIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case actionTypes.FETCH_ALL_DESTINATIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        destinations: action.payload
      });
    case actionTypes.FETCH_ALL_DESTINATIONS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        destinations: []
      });
    default:
      return state;
  }
}

const entitlementsProductsInitialState = {
  isLoading: false,
  entitlementsProducts: {}
};

function entitlementsProducts(
  state = entitlementsProductsInitialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_ENTITLEMENTS_PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true // ,
        // entitlementsProducts: {} // cache it
      });
    case actionTypes.FETCH_ENTITLEMENTS_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        entitlementsProducts: action.payload.entitlements
      });
    case actionTypes.FETCH_ENTITLEMENTS_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        entitlementsProducts: {}
      });
    default:
      return state;
  }
}

// Stored the data of agentless vms sources for filtring the data from policy add sources from hypervior tree.
const sourcesinitialState = {
  agentlessVMSources: {}
};

function agentlessVMSources(state = sourcesinitialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_AGENTLESS_VM_SOURECES_REQUEST:
      return Object.assign({}, state, {
        agentlessVMSources: action.payload
      });
    default:
      return state;
  }
}

export default combineReducers({
  policy,
  hypervisor,
  remotePolicies,
  destinations,
  entitlementsProducts,
  failbackDestinations,
  agentlessVMSources
});
