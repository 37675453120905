import * as C from "state/constants";

const initialState = {
  isLoading: false,
  users: {
    data: [],
    pagination: {}
  }
};

const usersSearch = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_USERS_SEARCH_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case C.FETCH_USERS_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        users: action.payload
      });
    case C.FETCH_USERS_SEARCH_FAILURE:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default usersSearch;
