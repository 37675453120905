import React, { useEffect } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import { MenuTab } from "../common/tab";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Cookies from "universal-cookie";
import {
  setOCTAToken,
  fetchAccessToken,
  getOrganizationWithoutId
} from "../../state/actions/actions";

const getCookieValue = name =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const Signin = ({
  getOrganizationWithoutId,
  setOCTAToken,
  fetchToken,
  history,
  loginToken
}) => {
  useEffect(() => {
    // let search =
    //   window.location && window.location.search ? window.location.search : "";
    // let code = search ? search.substring(6) : "";

    //these two lines should uncomment for bcc
    let token = getCookieValue("accesstoken");
    let refreshToken = getCookieValue("refreshtoken");
    let idtoken = getCookieValue("idtoken");
    if (idtoken) {
      localStorage.setItem("idtoken", idtoken);
    }

    //these two lines should be commented for bcc

    /* let token = accessToken;
    let refreshToken = refreshtoken; */
    // let accessToken =
    //   "eyJraWQiOiJDbnhYU3o1TjljdEw5TXhUcXNvOUZHc1g4QTNEdC1SXzU5d2x5cGozZWdrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjB0T3NyZVNWLUk5OW9KS0sweXRMTnNVS0Q5dlRabDJMTlFxWTA5MEdGVVEub2FyMXF4aGYydTFpbUxqUHkwaDciLCJpc3MiOiJodHRwczovL3N0b3JhZ2VjcmFmdC5va3RhcHJldmlldy5jb20vb2F1dGgyL2F1czE1dDR1cnYzUkJOY09RMGg4IiwiYXVkIjoiYXBpOi8vY2xvdWRjb25zb2xlIiwiaWF0IjoxNjYwMzA5MDgxLCJleHAiOjE2NjAzMTI2ODEsImNpZCI6IjBvYTE4bWtyMDFsd1NONmIwMGg4IiwidWlkIjoiMDB1MTZkcDV2MGhZTThuWlgwaDgiLCJzY3AiOlsicHJvZmlsZSIsIm9mZmxpbmVfYWNjZXNzIiwib3BlbmlkIiwiZW1haWwiLCJzbm93YmlyZCIsImZsZXhlcmEiXSwiYXV0aF90aW1lIjoxNjYwMjkxMzU4LCJzdWIiOiJob21lci5zaW1wc29uIiwibXNwQ29tcGFueU5hbWUiOiJUaGUgU2ltcHNvbnMiLCJjbG91ZENvbnNvbGVVc2VyVHlwZSI6ImFkbWluIiwiY3VzdG9tZXJJZCI6ImRvaCIsInNhbGVzZm9yY2VJZCI6ImJvb191cm5zIiwibG9naW4iOiJob21lci5zaW1wc29uIiwibXNwVVJMIjoiaHR0cHM6Ly9xYS1tc3Auc3RvcmFnZWNyYWZ0LmNvbS9wYXJ0bmVyc3FhIn0.ILbbqvi9pG9Rg00KAUwEqjjgj8PYvnyNgV3lXo_jHJ8Xo2LQje9CmnC99gkviLnmNlAQL3VGIzhBG11VNLDBYgvsUZbIXHzw5Ovw8qQD3pC3WeJbRUFW7tWZR1NXJEI4B-gmV_jE3dC_BOn6W-jx82F7uKWk5UJ21VHv-0cmWi5ERRX4IMZjOs0u_mcNRfXuu2lcsUIj3sWPBPNYAd77lp4fM0YK0t-PR_b5giezB2rUd7e45Fun_BCBRQ2YVGFIIEz7aKvU9oxNGcoYVag3PVv9K7ra1-ugpp3--4XLTce1AjLMEHsDxVLVDLzxBaokJ1opfJGl8oCTYiKw8Hmfbw";
    // let refreshtoken = "Ci9Gky7pAu-nZyrADQfv9rYEuA2DHhNbkQUepQwLc_I";
    // let token = accessToken;
    // let refreshToken = refreshtoken;

    //console.log(token)
    //alert(token)
    //if (!token) {

    if (!loginToken) {
      const cookies = new Cookies();
      //cookies.set("isLoggedOut", "", { path: "/", domain: ".arcserve.com" });
      cookies.set("isSignedIn", true, { path: "/", domain: ".arcserve.com" });
      cookies.set("cc", true, { path: "/", domain: ".arcserve.com" });
      setOCTAToken({ token, refreshToken, idtoken });
      fetchToken({ history });
      getOrganizationWithoutId();
    } else {
      const cookies = new Cookies();
      cookies.set("isSignedIn", true, { path: "/", domain: ".arcserve.com" });
      cookies.set("cc", true, { path: "/", domain: ".arcserve.com" });
      //cookies.set("isLoggedOut", "", { path: "/", domain: ".arcserve.com" });
      history.push("/");
    }

    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: "flex", justifyContent: "center", height: "80%" }}>
      <CircularProgress
        sx={{ display: "flex", height: "unset !important", color: "#6200EE" }}
      />
    </Box>
  );
};

const ConnectedApp = connect(
  state => {
    // console.log("state", state);
    return {
      loginToken: state && state.login && state.login.token
    };
  },
  dispatch => ({
    getOrganizationWithoutId: () => {
      dispatch(getOrganizationWithoutId());
    },
    setOCTAToken: payload => {
      dispatch(setOCTAToken(payload));
    },
    fetchToken: ({ history }) => {
      dispatch(fetchAccessToken({ history }));
    }
  })
)(Signin);
export default ConnectedApp;
