/* eslint-disable camelcase */
import moment from "moment";

export const source_state = {
  name: "protect.RecoveredResources.virtual_standbys.source_state",
  key: "source_state",
  options: [
    {
      label: "Running",
      value: "protect.RecoveredResources.virtual_standbys.source_state.running"
    },
    {
      label: "Not monitored",
      value: "protect.RecoveredResources.virtual_standbys.source_state.paused"
    },
    {
      label: "Heartbeat Timeout",
      value: "protect.RecoveredResources.virtual_standbys.source_state.unknown"
    }
  ],
  selectedOptions: []
};

export const vsb_state = {
  name: "state",
  key: "state",
  options: [
    {
      label: "power_off",
      value: "power_off"
    },
    {
      label: "power_on",
      value: "power_on"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

export const severity = {
  name: "log_severity_type",
  key: "log_severity_type",
  options: [
    {
      label: "information",
      value: "information"
    },
    {
      label: "warning",
      value: "warning"
    },
    {
      label: "error",
      value: "error"
    }
  ],
  selectedOptions: []
};

// Note: Job type filter is added based on Alphabetical order
// so Please pay attention while adding new job type filter
export const jobType = {
  name: "job_type",
  key: "job_type",
  options: [
    // A
    { label: "assured_recovery", value: "assured_recovery" },
    { label: "assured_security", value: "assured_security" },

    // B
    { label: "backup", value: "backup" },

    // C
    { label: "rps_replication", value: "rps_replication" },

    // D
    { label: "deployment", value: "deployment" },
    //I
    { label: "instant_vm", value: "instant_vm" },
    // M
    { label: "Merge", value: "merge" },

    // P
    { label: "job_type.rps_purge_datastore", value: "purge" },

    // N

    // R
    { label: "recovery", value: "restore" },

    // S

    // U

    // V
    { label: "vsb", value: "vsb" }
  ],
  selectedOptions: []
};

// Note: Job type filter is added based on Japanese Alphabetical order
// so Please pay attention while adding new job type filter
export const jaJobType = {
  name: "job_type",
  key: "job_type",
  options: [
    { label: "Merge", value: "merge" },
    { label: "recovery", value: "restore" },
    { label: "rps_replication", value: "rps_replication" },
    //newly added need to add in correct order
    { label: "backup", value: "backup" },
    { label: "deployment", value: "deployment" },
    { label: "instant_vm", value: "instant_vm" },
    { label: "vsb", value: "vsb" }
  ],
  selectedOptions: []
};

/** Note: CD Job type filter is added based on Alphabetical order
  so Please pay attention while adding new CD job type filter
**/
export const cdJobType = {
  name: "job_type",
  key: "job_type",
  options: [
    { label: "backup", value: "backup" },
    { label: "deployment", value: "deployment" },
    { label: "recovery", value: "restore" }
  ],
  selectedOptions: []
};

export const jaCdJobType = {
  name: "job_type",
  key: "job_type",
  options: [
    { label: "recovery", value: "restore" },
    //newly added need to add in correct order
    { label: "backup", value: "backup" },
    { label: "deployment", value: "deployment" }
  ],
  selectedOptions: []
};

export const dateRangeReportsLanding = {
  name: "report_date_range",
  key: "date_range",
  type: "date-range-picker",
  minDate: moment("2018-01-01"),
  format: "LL",
  showTimePicker: false,
  options: [
    "last_24_hours",
    "last_7_days",
    "last_2_weeks",
    "last_1_month",
    "custom"
  ],
  selectedOptions: {}
};

export const dateRangeReports = {
  name: "date_range",
  key: "date_range",
  type: "date-range-picker",
  showTimePicker: false,
  minDate: moment()
    .subtract(3, "months")
    .add(1, "day"),
  format: "LL",
  options: [
    "last_24_hours",
    "last_7_days",
    "last_2_weeks",
    "last_1_month",
    "custom"
  ],
  selectedOptions: {}
};

export const dateRangeJobs = {
  name: "date_range",
  key: "start_time_ts",
  type: "date-range-picker",
  minDate: moment("2018-01-01"),
  format: "LL",
  showTimePicker: false, // To send custom keyword in API url
  options: [
    "last_24_hours",
    "last_7_days",
    "last_2_weeks",
    "last_1_month",
    "custom"
  ],
  selectedOptions: {}
};

export const dateRangeLog = {
  name: "date_range",
  key: "log_ts",
  type: "date-range-picker",
  minDate: moment("2018-01-01"),
  format: "LL",
  showTimePicker: false, // To send custom keyword in API url
  options: [
    "last_24_hours",
    "last_7_days",
    "last_2_weeks",
    "last_1_month",
    "custom"
  ],
  selectedOptions: {}
};
export const dateRangeAuditLog = {
  name: "date_range",
  key: "audit_ts",
  type: "date-range-picker",
  minDate: moment("2018-01-01"),
  format: "LL",
  showTimePicker: false, // To send custom keyword in API url
  options: [
    "last_24_hours",
    "last_7_days",
    "last_2_weeks",
    "last_1_month",
    "custom"
  ],
  selectedOptions: {}
};

export const Type = {
  name: "type",
  key: "on_resource_type",
  options: [
    {
      label: "User",
      value: "user"
    },
    {
      label: "Organization",
      value: "organization"
    },
    {
      label: "Cloud Account",
      value: "cloud_account"
    },
    {
      label: "site",
      value: "site"
    },
    {
      label: "Source",
      value: "source"
    },
    {
      label: "Destination",
      value: "destination"
    },
    {
      label: "Source Group",
      value: "source_group"
    },
    {
      label: "View Filter",
      value: "view_filter"
    },
    {
      label: "Report Schedule",
      value: "report_schedule"
    },
    {
      label: "Hypervisor",
      value: "hypervisor"
    },
    {
      label: "Report",
      value: "report"
    },
    {
      label: "Job",
      value: "job"
    },
    {
      label: "policy",
      value: "policy"
    },
    {
      label: "rps",
      value: "recovery_point_server"
    },
    {
      label: "datastore",
      value: "datastore"
    },
    {
      label: "alert_email_recipients",
      value: "alert_email_recipients"
    },
    {
      label: "Proxy",
      value: "proxy"
    },
    {
      label: "Shared Folder",
      value: "shared_folder"
    },
    {
      label: "configurations",
      value: "configurations"
    },
    {
      label: "storagearray",
      value: "storage_array"
    },
    {
      label: "Recovered Resource",
      value: "recovered_resource"
    },
    {
      label: "Active Directory",
      value: "active_directory"
    }
  ],
  selectedOptions: []
};

/** Note: Job status filter is added based on Alphabetical order
  so Please pay attention while adding new job status
**/
export const jobStatus = {
  name: "job_status",
  key: "job_status",
  options: [
    // C
    { label: "canceled", value: "canceled" },
    // F
    { label: "failed", value: "failed" },
    // I
    { label: "incomplete", value: "incomplete" },
    { label: "active", value: "active" },
    // M
    { label: "missed", value: "missed" },
    // S
    { label: "skipped", value: "skipped" },
    { label: "job_finished", value: "finished" },
    // W
    { label: "warning", value: "warning" }
  ],
  selectedOptions: []
};

export const lastJobStatus = {
  name: "last_job",
  key: "last_job",
  options: [
    // F
    { label: "failed", value: "failed" },
    // I
    { label: "incomplete", value: "incomplete" },
    { label: "active", value: "active" },
    // M
    { label: "missed", value: "missed" },
    // S
    { label: "job_finished", value: "finished" },
    // W
    { label: "warning", value: "warning" }
  ],
  selectedOptions: []
};

//To do need to sort based on JA language
export const jaLastJobStatus = {
  name: "last_job",
  key: "last_job",
  options: [
    { label: "active", value: "active" },
    { label: "failed", value: "failed" },
    { label: "finished", value: "finished" },
    { label: "incomplete", value: "incomplete" },
    { label: "missed", value: "missed" },
    { label: "warning", value: "warning" }
  ],
  selectedOptions: []
};

/** Note: Job status filter is added based on Japanese Alphabetical order
  so Please pay attention while adding new job status
**/
export const jaJobStatus = {
  name: "job_status",
  key: "job_status",
  options: [
    { label: "warning", value: "warning" },
    { label: "failed", value: "failed" },
    { label: "active", value: "active" },
    { label: "incomplete", value: "incomplete" },
    { label: "missed", value: "missed" },
    //Modyfied need to add in sorted order
    { label: "job_finished", value: "finished" }
  ],
  selectedOptions: []
};

export const retention = {
  name: "retention",
  key: "retention_id",
  options: [
    {
      label: "7D",
      value: "7D"
    },
    {
      label: "14D",
      value: "14D"
    },
    {
      label: "1M",
      value: "1M"
    },
    {
      label: "2M",
      value: "2M"
    },
    {
      label: "3M",
      value: "3M"
    },
    {
      label: "6M",
      value: "6M"
    },
    {
      label: "1Y",
      value: "1Y"
    },
    {
      label: "2Y",
      value: "2Y"
    },
    {
      label: "3Y",
      value: "3Y"
    },
    {
      label: "7Y",
      value: "7Y"
    },
    {
      label: "10Y",
      value: "10Y"
    },
    {
      label: "forever",
      value: "forever"
    }
  ],
  selectedOptions: []
};

export const protectionStatus = {
  name: "protection_status",
  key: "protection_status",
  options: [
    {
      label: "protect",
      value: "protect"
    },
    {
      label: "unprotect",
      value: "unprotect"
    }
  ],
  selectedOptions: []
};

export const protectionStatusAccounts = {
  name: "protection_status",
  key: "protection_status",
  options: [
    {
      label: "protect",
      value: "protect"
    },
    {
      label: "unprotect",
      value: "unprotect"
    }
  ],
  selectedOptions: []
};

export const connectionStatus = {
  name: "connection_status",
  key: "connection_status",
  options: [
    {
      label: "online",
      value: "online"
    },
    {
      label: "offline",
      value: "offline"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

export const backupSchedule = {
  name: "backup_schedule",
  key: "backup_schedule",
  options: [
    {
      label: "sunday",
      value: "Sun"
    },
    {
      label: "monday",
      value: "Mon"
    },
    {
      label: "tuesday",
      value: "Tue"
    },
    {
      label: "wednesday",
      value: "Wed"
    },
    {
      label: "thursday",
      value: "Thu"
    },
    {
      label: "friday",
      value: "Fri"
    },
    {
      label: "saturday",
      value: "Sat"
    }
  ],
  selectedOptions: []
};

export const policyStatus = {
  name: "status",
  key: "status",
  options: [
    {
      label: "deploying",
      value: "deploying"
    },
    {
      label: "success",
      value: "success"
    },
    {
      label: "failure",
      value: "failure"
    },
    {
      label: "disabled",
      value: "disabled"
    }
  ],
  selectedOptions: []
};

/** Note: Backup status filter is added based on Alphabetical order
  so Please pay attention while adding new Backup status
**/
export const backupStatus = {
  name: "backup_status",
  key: "last_job",
  options: [
    // C
    { label: "canceled", value: "canceled" },
    // F
    { label: "failed", value: "failed" },
    // I
    { label: "incomplete", value: "incomplete" },
    { label: "active", value: "active" },
    // M
    { label: "missed", value: "missed" },
    // S
    { label: "skipped", value: "skipped" },
    { label: "job_finished", value: "finished" },
    // W
    { label: "warning", value: "warning" }
  ],
  selectedOptions: []
};

/** Note: Backup status filter is added based on Japanese Alphabetical order
  so Please pay attention while adding new Backup status
**/
export const jaBackupStatus = {
  name: "backup_status",
  key: "last_job",
  options: [
    { label: "canceled", value: "canceled" },
    { label: "skipped", value: "skipped" },
    { label: "warning", value: "warning" },
    { label: "failed", value: "failed" },
    { label: "active", value: "active" },
    { label: "incomplete", value: "incomplete" },
    //newly added need to add in correct order
    { label: "job_finished", value: "finished" },
    { label: "missed", value: "missed" }
  ],
  selectedOptions: []
};

export const operatingSystem = {
  name: "o_s",
  key: "operating_system",
  options: [
    {
      label: "windows",
      value: "windows"
    },
    {
      label: "linux",
      value: "linux"
    },
    {
      label: "mac",
      value: "mac"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};
export const SourceType = {
  name: "source_type",
  key: "source_type",
  options: [
    {
      label: "udp_agent_based_windows",
      value: "udp_windows"
    },
    {
      label: "udp_agent_based_linux",
      value: "udp_linux"
    },
    {
      label: "udp_linux_backup_server",
      value: "udp_linux_backup_server"
    },
    {
      label: "agentless_vms",
      value: "agentless_vm"
    },
    {
      label: "udp_oracle",
      value: "udp_oracle"
    },
    {
      label: "UNC/NFS Paths",
      value: "shared_folder"
    }
  ],
  selectedOptions: []
};

export const is_deleted = {
  name: "is_deleted",
  key: "is_deleted",
  options: [
    {
      label: "true",
      value: "true"
    },
    {
      label: "false",
      value: "false"
    }
  ],
  selectedOptions: []
};

export const isDeletedApiConvert = isDeleted => {
  if (isDeleted.every(ele => typeof ele === "boolean")) {
    isDeleted.forEach((ele, i) => {
      isDeleted[i] = ele ? "true" : "false";
    });
  } else if (isDeleted.every(ele => Object.keys(ele).indexOf("value") > -1)) {
    isDeleted.forEach((ele, i) => {
      isDeleted[i] = ele["value"];
    });
  }
  return isDeleted;
};

export const operatingSystemPolicySource = {
  name: "o_s",
  key: "operating_system",
  options: [
    {
      label: "windows",
      value: "windows"
    },
    {
      label: "linux",
      value: "linux"
    },
    {
      label: "mac",
      value: "mac"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

export const recoveredResourcesOS = {
  name: "o_s",
  key: "operating_system",
  options: [
    {
      label: "windows",
      value: "windows"
    },
    {
      label: "linux",
      value: "linux"
    },
    {
      label: "mac",
      value: "mac"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

export const dataCenter = {
  name: "data_center",
  key: "dataCenter",
  options: [
    {
      label: "Zetta",
      value: "Zetta"
    }
  ],
  selectedOptions: []
};

export const host = {
  name: "host",
  key: "host",
  options: [
    {
      label: "esxi7.zetta.net",
      value: "esxi7.zetta.net"
    },
    {
      label: "esxi8.zetta.net",
      value: "esxi8.zetta.net"
    }
  ],
  selectedOptions: []
};

export const resourcePool = {
  name: "resource_pool",
  key: "resourcePool",
  options: [
    {
      label: "esxi7.zetta.net",
      value: "esxi7.zetta.net"
    },
    {
      label: "esxi8.zetta.net",
      value: "esxi8.zetta.net"
    }
  ],
  selectedOptions: []
};

export const recoveryPointsStatus = {
  name: "pinned_recovery_point",
  key: "pinned",
  options: [
    {
      label: "pinned_label",
      value: "true"
    },
    {
      label: "unpinned_label",
      value: "false"
    }
  ],
  selectedOptions: []
};

/**
 * status of a user
 */
export const userStatus = {
  name: "status",
  key: "status",
  options: [
    {
      label: "verified",
      value: "verified"
    },
    {
      label: "unverified",
      value: "unverified"
    },
    {
      label: "reset",
      value: "reset"
    }
  ],
  selectedOptions: []
};

export const blockedUserStatus = {
  name: "is_blocked",
  type: "single-select",
  key: "blocked",
  options: [
    {
      label: "label_true",
      value: "true"
    },
    {
      label: "label_false",
      value: "false"
    }
  ],
  selectedOptions: []
};

export const scheduledFor = {
  name: "schedule_frequency",
  key: "schedule_frequency",
  options: [
    {
      label: "daily",
      value: "daily"
    },
    {
      label: "weekly",
      value: "weekly"
    },
    {
      label: "monthly",
      value: "monthly"
    },
    {
      label: "on_demand",
      value: "on_demand"
    }
  ],
  selectedOptions: []
};

/**
 * state for a recovered resource
 */
export const state = {
  name: "state",
  key: "state",
  options: [
    {
      label: "provisioning",
      value: "provisioning"
    },
    {
      label: "started",
      value: "started"
    },
    {
      label: "stopped",
      value: "stopped"
    },
    {
      label: "provisioning_failed",
      value: "provisioning_failed"
    },
    {
      label: "deprovisioning",
      value: "deprovisioning"
    },
    {
      label: "deprovisioned",
      value: "deprovisioned"
    },
    {
      label: "starting",
      value: "starting"
    },
    {
      label: "stopping",
      value: "stopping"
    },
    {
      label: "restarting",
      value: "restarting"
    },
    {
      label: "missing",
      value: "missing"
    },
    {
      label: "error",
      value: "error"
    }
  ],
  selectedOptions: []
};

/**
 * type for a recovered resource
 */
export const recoveredResourceType = {
  name: "type",
  key: "type",
  options: [
    {
      label: "clouddirect_draas",
      value: "clouddirect_draas"
    },
    {
      label: "ivm",
      value: "ivm"
    },
    {
      label: "vsb",
      value: "vsb"
    }
  ],
  selectedOptions: []
};

export const datePicker = {
  name: "generated_on",
  key: "generated_on",
  type: "date-picker",
  hideLabel: true,
  singleSelect: true,
  minDate: moment("2018-01-01"),
  selectedOptions: {}
};

export const reportType = {
  name: "report_type",
  key: "report_type",
  options: [
    { label: "Backup Jobs", value: "backup_jobs" },
    { label: "Policy Tasks", value: "policy_tasks" },
    { label: "Capacity Usage", value: "capacity_usage" },
    { label: "Data Transfer", value: "data_transfer" },
    { label: "Recovery Jobs", value: "restore_jobs" },
    { label: "Account Details", value: "account_details" },
    { label: "Source Protection", value: "source_protection" },
    { label: "Recovery Point", value: "source_rpo" }
  ],
  selectedOptions: []
};

export const dataStoreStatus = {
  name: "status",
  key: "status",
  options: [
    // { label: "Running", value: "running" }, //hidden running status filter bug_994487
    { label: "Stopped", value: "stopped" },
    { label: "Starting", value: "starting" },
    { label: "Stopping", value: "stopping" },
    { label: "Modifying", value: "modifying" },
    { label: "Deleting", value: "deleting" },
    { label: "Deleted", value: "deleted" },
    { label: "Creating", value: "creating" },
    { label: "Create Failed", value: "create_failed" },
    { label: "Modify Copying", value: "modify_copying" },
    { label: "Modify Failed", value: "modify_failed" },
    { label: "Abnormal Block All", value: "abnormal_block_all" },
    { label: "Abnormal Restory Only", value: "abnormal_restore_only" },
    { label: "Unknown", value: "unknown" }
  ]
};

/**
 * state for a instant VMs
 */
export const instantVMsState = {
  name: "state",
  key: "state",
  options: [
    {
      label: "error",
      value: "error"
    },
    {
      label: "failure",
      value: "failure"
    },
    {
      label: "crash",
      value: "crash"
    },
    {
      label: "cancelled",
      value: "cancelled"
    },
    {
      label: "starting",
      value: "starting"
    },
    {
      label: "running",
      value: "running"
    },
    {
      label: "migrating",
      value: "migrating"
    },
    {
      label: "disconnection_hypervisor",
      value: "disconnection_hypervisor"
    },
    {
      label: "disconnection_recoveryserver",
      value: "disconnection_recoveryserver"
    },
    {
      label: "pending_ui",
      value: "pending_ui"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

export const instantVMsOS = {
  name: "o_s",
  key: "operating_system",
  options: [
    {
      label: "windows",
      value: "windows"
    },
    {
      label: "linux",
      value: "linux"
    },
    {
      label: "mac",
      value: "mac"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

export const osMajorStatus = {
  name: "operating_system",
  key: "operating_system",
  options: [
    {
      label: "windows",
      value: "windows"
    },
    {
      label: "linux",
      value: "linux"
    },
    {
      label: "mac",
      value: "mac"
    },
    {
      label: "unknown",
      value: "unknown"
    }
  ],
  selectedOptions: []
};

/**
 * Recovered  VMs
 */
export const recoveredVMSAdvanceSearchDataSets = [state, recoveredResourcesOS];

/**
 * Recovered  VMs
 */
export const recoveredResourcesAdvanceSearchDataSets = [
  recoveredResourceType,
  recoveredResourcesOS
];

/**
 * Disaster Recovery > Instant VMs
 */
export const instantVMsAdvanceSearchDataSets = [instantVMsState, instantVMsOS];

/**
 * Handle source advance search filter
 * @param {String} locale // Language string
 * @returns {Array}
 */
export const sourcesAdvanceSearchDataSets = (locale = "en", navigation) => {
  const JaAdvancedList = [
    protectionStatus,
    connectionStatus,
    jaBackupStatus,
    operatingSystem,
    is_deleted
  ];
  const advancedList = [
    protectionStatus,
    connectionStatus,
    backupStatus,
    operatingSystem,
    is_deleted
  ];
  if (navigation === "sources") {
    JaAdvancedList.push(SourceType);
    advancedList.push(SourceType);
  }
  return locale === "ja" ? JaAdvancedList : advancedList;
};

export const sourceAdvanceSearchDataSets = [operatingSystem];

export const customerAccountAdvanceSearchDataSets = [
  protectionStatusAccounts,
  connectionStatus
];

export const policySourceAdvanceSearchDataSets = [operatingSystemPolicySource];

export const destinationAdvanceSearchDataSets = [];

export const joblogAdvanceSearchDataSets = [dateRangeLog, severity];

export const auditLogAdvancedSearchDataSets = [dateRangeAuditLog, Type];
/**
 * Handle logs advance search filter
 * @param {String} locale // Language string
 * @param {Boolean} isUPD // Its entitlements udp enable boolean value
 * @returns {Array}
 */
export const logAdvanceSearchDataSets = (locale = "en", isUDP = true) => {
  if (!isUDP) {
    return locale === "ja"
      ? [jaCdJobType, severity, dateRangeLog]
      : [cdJobType, severity, dateRangeLog];
  }
  return locale === "ja"
    ? [jaJobType, severity, dateRangeLog]
    : [jobType, severity, dateRangeLog];
};

/**
 * Handle jobs advance search filter
 * @param {String} locale // Language string
 * @param {Boolean} isUPD // Its entitlements udp enable boolean value
 * @returns {Array}
 */
export const jobsAdvanceSearchDataSets = (locale = "en", isUDP = true) => {
  if (!isUDP) {
    return locale === "ja"
      ? [jaJobStatus, jaCdJobType, dateRangeJobs]
      : [jobStatus, cdJobType, dateRangeJobs];
  }
  return locale === "ja"
    ? [jaJobStatus, jaJobType, dateRangeJobs]
    : [jobStatus, jobType, dateRangeJobs];
};

export const policiesAdvanceSearchDataSets = [policyStatus];

export const vmsAdvanceSearchDataSets = [dataCenter, host, resourcePool];

export const recoveryPointsAdvanceSearchDataSets = [recoveryPointsStatus];

export const usersAdvanceSearchDataSets = [userStatus, blockedUserStatus];

export const virtualStandbyDataSets = [
  protectionStatus,
  connectionStatus,
  operatingSystem
];

export const virtualStandbysDataSets = [
  //for Recovered Resources> Virtual Standbys
  source_state,
  operatingSystem,
  vsb_state
];

export const reportsAdvanceSearchDataSets = [scheduledFor, datePicker];
export const reportsDetailsAdvanceSearchDataSets = [dateRangeReports];

export const reportsBackupJobsAdvanceSearchDataSets = [dateRangeReports];

export const reportsRecoveryPointAdvanceSearchDataSets = [];

export const reportsRestoreJobsAdvanceSearchDataSets = [dateRangeReports];

export const reportsDataTransferAdvanceSearchDataSets = [dateRangeReports];

export const reportsJobsAndDestinationsAdvanceSearchDataSets = [
  retention,
  jobType,
  dateRangeReports,
  backupSchedule
];

export const reportsCapacityUsageAdvanceSearchDataSets = [dateRangeReports];

export const reportsAccountActivityAdvanceSearchDataSets = [dateRangeReports];

export const reportsManageReportSchedulesAdvanceSearchDataSets = roleId => {
  let reportTypes = { ...reportType };
  if (!roleId.includes("msp")) {
    reportTypes.options = reportTypes.options.filter(
      option => option.value !== "account_details"
    );
  }
  return [scheduledFor, reportTypes];
};
export const reportsSourceProtectionAdvanceSearchDataSets = (locale = "en") => {
  return locale === "en"
    ? [protectionStatus, connectionStatus, lastJobStatus, osMajorStatus]
    : [protectionStatus, connectionStatus, jaLastJobStatus, osMajorStatus];
};

export const accountDetailsReportAdvanceSearchDataSets = [protectionStatus];
