import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateColumnStateData } from "../../../state/actions/actions";
import FormattedBrandMessage from "components/common/FormattedBrandMessage";
import { makeManageSettingColumnState } from "../../../state/selectors/CommonSelectors";
import Checkbox from "@mui/material/Checkbox";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconMUI from "components/common/icon/IconMUI";
import { handleLinkColor } from "state/selectors/ui";

const CheckBoxItem = ({
  updateColumnStateData,
  column,
  index,
  columnData,
  linkColor,
  filterType
}) => {
  /**
   * Updating colmn in state show hide true/false
   */
  const updateColumnShowHide = (e, column, stateColumnData, filterType) => {
    updateColumnStateData(column, stateColumnData, filterType);
  };

  let checkedVar = column.show;
  return (
    <li key={index} className="d-flex align-items-end">
      {/* <FormControlLabel
            control={
              <Checkbox id={"gridSettings" + index} checked={checkedVar} onChange={e => updateColumnShowHide(e, column, columnData, filterType)}/>
            }
            label={<label htmlFor={"gridSettings" + index}>
            <FormattedBrandMessage id={column.displayIdentifier} />
          </label>}
          /> */}
      <Checkbox
        id={"gridSettings" + index}
        checked={checkedVar}
        onChange={e => updateColumnShowHide(e, column, columnData, filterType)}
      />
      <label
        htmlFor={"gridSettings" + index}
        style={{
          width: "73%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "13px"
        }}
      >
        <FormattedBrandMessage id={column.displayIdentifier} />
      </label>
      <span
        className="dragDropHandle"
        style={{
          display: "block",
          width: "15px",
          position: "absolute",
          right: "0",
          bottom: "0",
          cursor: "move"
        }}
      >
        {" "}
        <IconMUI icon={DragIndicatorIcon} style={{ color: linkColor }} />{" "}
      </span>
    </li>
  );
};

CheckBoxItem.defaultProps = {
  column: {},
  columnData: []
};

CheckBoxItem.propTypes = {
  updateColumnStateData: PropTypes.func.isRequired,
  configurecolumnData: PropTypes.array,
  anlyzecolumnData: PropTypes.array,
  column: PropTypes.shape({
    show: false,
    displayIdentifier: ""
  }),
  index: PropTypes.number.isRequired,
  columnData: PropTypes.arrayOf(PropTypes.shape({})),
  filterType: PropTypes.string,
  reducer: PropTypes.object
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getManageSettingColumnState = makeManageSettingColumnState();

  const mapStateToProps = (state, props) => {
    return {
      /**
       * Use selector function instance to update Props.
       */
      columnData: getManageSettingColumnState(state, props),
      anlyzecolumnData: state.analyzeReducer.columnData,
      configurecolumnData: state.configureReducer.columnData,
      linkColor: handleLinkColor(state),
      primaryNavigation: state.reducer.primaryNavigation
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateColumnStateData
    },
    dispatch
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(CheckBoxItem);
