import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import { DataGrid } from "../data-grid-mui";
import DataGridHeader from "../data-grid-header-mui";
import ErrorProtectBoundary from "../error-boundry/ErrorProtectBoundry";

/**
 * Custom hook for getting previous pagination value
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

/**
 * Re-usable data-grid component that supports a
 * search header component and a grid body with pagination footer.
 * It delegates the rendering of actual components to {@link DataGrid}
 */
const SpogDataGrid = props => {
  const prevPagination = usePrevious(props.pagination);
  const divRef = React.createRef();

  useEffect(() => {
    if (
      prevPagination &&
      prevPagination.curr_page !== props.pagination.curr_page
    ) {
      const tbody = document.querySelector(".rt-tbody");
      if (tbody && tbody.hasAttributes("scrollTop")) {
        tbody.scrollTop = 0;
      }
    }
  }, [props.pagination]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div
      className={`${props.classNames} spog-data-grid-wrapper d-flex flex-column`}
    >
      {props.showToolbar && (
        <DataGridHeader
          ref={divRef}
          showUserDefinedSearch={props.showUserDefinedSearch}
          isAdvSearchDisabled={props.isAdvSearchDisabled}
          showAdvancedSearchInput={props.showAdvancedSearchInput}
          columnsModel={props.columnsModel}
          advancedSearchGridOptions={props.options}
          placeholder={props.placeholder}
          inputPlaceholder={props.inputPlaceholder}
          isMenuCloseOnSelect={props.isMenuCloseOnSelect}
          isDropdownDisabled={props.isDropdownDisabled}
          disableGridSettings={props.disableGridSettings}
          disableManageSaveSearches={props.disableManageSaveSearches}
          isMultiSelect={props.isMultiSelect}
          handleExportReport={props.handleExportReport}
          removeSource={props.removeSource}
          selectSource={props.selectSource}
          addSource={props.addSource}
          showAddSourceComponent={props.showAddSourceComponent}
          addSelectedSources={props.addSelectedSources}
          actions={props.actions}
          showSelectedRows={props.showSelectedRows}
          filterType={props.filterType}
          showSavedSearchList={props.showSavedSearchList}
          handleAdvancedSearchClick={props.handleAdvancedSearchClick}
          handleAddSourceClick={props.handleAddSourceClick}
          createReportHandler={props.createReportHandler}
          handleAddSourceBtnClick={props.handleAddSourceBtnClick}
          addSourceOptionClick={props.addSourceOptionClick}
          handleRemoveSourceClick={props.handleRemoveSourceClick}
          handleSelectSourceClick={props.handleSelectSourceClick}
          handleAddSource={props.handleAddSource}
          gridHeaderButtons={props.gridHeaderButtons}
          showColumnSettings={props.showColumnSettings}
          showAddCustomerAccountButton={props.showAddCustomerAccountButton}
          addCustomerAccountHandler={props.addCustomerAccountHandler}
          showGridCount={props.showGridCount}
          showGridCountText={props.showGridCountText}
          totalSize={
            props.usePaginationWithApi
              ? props.pagination.total_size
              : props.rows && props.rows.length
          }
          hideSaveSearchLink={props.hideSaveSearchLink}
          showCreateReport={props.showCreateReportBtn}
          showCreateAlert={props.showCreateAlertBtn}
          exportActions={props.exportActions}
          showCreateGroupButton={props.showCreateGroupButton}
          createGroupHandler={props.createGroupHandler}
          addSourcesToGroupHandler={props.addSourcesToGroupHandler}
          showAddSourcesToGroupButton={props.showAddSourcesToGroupButton}
          groupActionTitle={props.groupActionTitle}
          groupActionOptions={props.groupActionOptions}
          groupActionHandler={props.groupActionHandler}
          showAddSelectedSourcesButton={props.showAddSelectedSourcesButton}
          addSelectedSourcesHandler={props.addSelectedSourcesHandler}
          showCancelButton={props.showCancelButton}
          cancelHandler={props.cancelHandler}
          resourceId={props.resourceId}
          onExportLogClick={props.onExportLogClick}
          localSearch={props.localSearch}
          isHypervPresent={props.isHypervPresent}
          disableClose={props.disableClose}
          tagToDisable={props.tagToDisable}
          dataGridID={props.testId}
          testId={props.testId}
          onClickError={props.onClickError}
          retainSearchText={props.retainSearchText}
        />
      )}
      <div
        id="export-container-pdf"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow:
            props.showGrid && !props.customComponent ? "hidden" : "auto",
          height: "100%"
        }}
      >
        {props.customComponent && (
          <div className="data-grid-custom-component">
            {props.customComponent}
          </div>
        )}
        {props.gridHeader && (
          <Paper elevation={0}>
            <div className="data-grid-heading">{props.gridHeader}</div>
          </Paper>
        )}
        <ErrorProtectBoundary>
          {props.showGrid && (
            <DataGrid
              testId={props.testId}
              autoResetPage={props.autoResetPage}
              keyAsTestId={props.keyAsTestId}
              filterType={props.filterType}
              resourceId={props.resourceId}
              noDataText={props.noDataText}
              columns={props.columns}
              rows={props.rows}
              data={props.rows}
              noRowsFound={props.noRowsFound || false}
              defaultGridPageSize={props.defaultGridPageSize}
              defaultGridSize={props.defaultGridSize}
              showPagination={props.showPagination}
              defaultSortedColumn={props.defaultSortedColumn}
              onRowClick={props.onRowClick}
              onRowCellClick={props.onRowCellClick}
              onHeaderCellClick={props.onHeaderCellClick}
              onSortChange={props.onSortChange}
              hideCheckbox={props.hideCheckbox}
              page={props.pagination ? props.pagination.curr_page : 1}
              pages={props.pagination ? props.pagination.total_page : 1}
              pageSize={props.pagination ? props.pagination.page_size : 25}
              canPrevious={props.pagination ? props.pagination.has_prev : false}
              canNext={props.pagination ? props.pagination.has_next : true}
              totalSize={
                props.usePaginationWithApi
                  ? props.pagination.total_size
                  : props.rows && props.rows.length
              }
              handlePaginationApi={props.handlePaginationApi}
              usePaginationWithApi={props.usePaginationWithApi}
              usePaginationWithPageNumber={props.usePaginationWithPageNumber}
              SubComponent={props.SubComponent || null}
              getData={props.getData || null}
              subComponentDetails={props.subComponentDetails}
              TheadComponent={props.TheadComponent}
              getTrProps={props.getTrProps}
              changeDrggable={props.changeDrggable}
              isSingleSelect={props.isSingleSelect}
              selectionFromAllPages={props.selectionFromAllPages}
              checkboxDisabled={props.checkboxDisabled}
              isSQLServerWriterChecked={props.isSQLServerWriterChecked}
              defaultSelectedRowsData={props.defaultSelectedRowsData}
              withoutHeader={props.withoutHeader}
              triggerCheckboxSelection={props.triggerCheckboxSelection}
              noSelectionDataUpdate={props.noSelectionDataUpdate}
              setCheckboxTriggerFalse={props.setCheckboxTriggerFalse}
              resetColumnSelection={props.resetColumnSelection}
              setResetColumnSelection={props.setResetColumnSelection}
              disableColumnWidthDispatch={props.disableColumnWidthDispatch}
              isModalGrid={props.isModalGrid}
              isRecoveryGrid={props.isRecoveryGrid}
              searchText={props.searchText}
            />
          )}
        </ErrorProtectBoundary>
      </div>
    </div>
  );
};

SpogDataGrid.defaultProps = {
  classNames: "",
  showUserDefinedSearch: true,
  showGrid: true,
  showToolbar: true,
  actions: false,
  showSelectedRows: true,
  hideCheckbox: false,
  isDropdownDisabled: false,
  isMenuCloseOnSelect: true,
  isMultiSelect: true,
  defaultPageSize: 25,
  defaultGridPageSize: 25,
  showPagination: true,
  disableGridSettings: false,
  disableManageSaveSearches: false,
  removeSource: false,
  selectSource: false,
  addSource: false,
  totalSize: 25,
  usePaginationWithApi: false,
  usePaginationWithPageNumber: false,
  showColumnSettings: true,
  hideSaveSearchLink: false,
  isAdvSearchDisabled: false,
  showAddSourceComponent: false,
  addSelectedSources: false,
  showSavedSearchList: true,
  pagination: {
    curr_page: 1,
    total_page: 1,
    page_size: 1,
    has_prev: false,
    has_next: false,
    total_size: 25
  },
  showAddCustomerAccountButton: false,
  addCustomerAccountHandler: false,
  showGridCount: false,
  showCreateReportBtn: false,
  showCreateGroupButton: false,
  showAddSourcesToGroupButton: false,
  showAddSelectedSourcesButton: false,
  showCancelButton: false,
  showAdvancedSearchInput: true,
  noDataText: "No rows found",
  columnsModel: [],
  placeholder: "Select",
  onExportLogClick: () => {},
  inputPlaceholder: "Search",
  filterType: "",
  addSelectedSourcesHandler: () => {},
  groupActionHandler: () => {},
  groupActionOptions: [],
  addSourcesToGroupHandler: () => {},
  getData: () => {},
  handleAddSource: () => {},
  handleExportReport: () => {},
  cancelHandler: () => {},
  createReportHandler: () => {},
  addSourceOptionClick: () => {},
  handleAddSourceBtnClick: () => {},
  handleAddSourceClick: () => {},
  handleRemoveSourceClick: () => {},
  handleSelectSourceClick: () => {},
  onHeaderCellClick: () => {},
  onRowClick: () => {},
  createGroupHandler: () => {},
  onRowCellClick: () => {},
  isSingleSelect: false,
  withoutHeader: false,
  disableColumnWidthDispatch: false,
  isModalGrid: false,
  onClickError: true,
  autoResetPage: true,
  searchText: "",
  retainSearchText: false
};

SpogDataGrid.propTypes = {
  classNames: PropTypes.string,
  showUserDefinedSearch: PropTypes.bool,
  showGrid: PropTypes.bool,
  noDataText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultPageSize: PropTypes.number,
  defaultGridPageSize: PropTypes.number,
  showPagination: PropTypes.bool,
  defaultSortedColumn: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func,
  onRowCellClick: PropTypes.func,
  onHeaderCellClick: PropTypes.func,
  onSortChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  filterType: PropTypes.string,
  isMenuCloseOnSelect: PropTypes.bool,
  isDropdownDisabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  disableGridSettings: PropTypes.bool,
  disableManageSaveSearches: PropTypes.bool,
  handleExportReport: PropTypes.func,
  showToolbar: PropTypes.bool,
  removeSource: PropTypes.bool,
  selectSource: PropTypes.bool,
  addSource: PropTypes.bool,
  actions: PropTypes.bool,
  showCreateReportBtn: PropTypes.bool,
  showSelectedRows: PropTypes.bool,
  handleSelectSourceClick: PropTypes.func,
  handleRemoveSourceClick: PropTypes.func,
  hideCheckbox: PropTypes.bool,
  totalSize: PropTypes.number,
  handlePaginationApi: PropTypes.func,
  usePaginationWithApi: PropTypes.bool,
  usePaginationWithPageNumber: PropTypes.bool,
  showColumnSettings: PropTypes.bool,
  hideSaveSearchLink: PropTypes.bool,
  isAdvSearchDisabled: PropTypes.bool,
  columnsModel: PropTypes.arrayOf(PropTypes.shape({})),
  showAddSourceComponent: PropTypes.bool,
  addSelectedSources: PropTypes.bool,
  showSavedSearchList: PropTypes.bool,
  handleAdvancedSearchClick: PropTypes.func,
  handleAddSourceClick: PropTypes.func,
  handleAddSourceBtnClick: PropTypes.func,
  addSourceOptionClick: PropTypes.func,
  handleAddSource: PropTypes.func,
  pagination: PropTypes.shape({
    curr_page: PropTypes.number,
    total_page: PropTypes.number,
    page_size: PropTypes.number,
    has_prev: PropTypes.bool,
    has_next: PropTypes.bool,
    total_size: PropTypes.number
  }),
  SubComponent: PropTypes.func,
  getData: PropTypes.func,
  autoResetPage: PropTypes.bool,
  subComponentDetails: PropTypes.arrayOf(PropTypes.shape({})),
  showAddCustomerAccountButton: PropTypes.bool,
  addCustomerAccountHandler: PropTypes.bool,
  showGridCount: PropTypes.bool,
  showGridCountText: PropTypes.arrayOf(PropTypes.shape({})),
  exportActions: PropTypes.array,
  gridHeader: PropTypes.string,
  customComponent: PropTypes.object,
  showCreateGroupButton: PropTypes.bool,
  createGroupHandler: PropTypes.func,
  addSourcesToGroupHandler: PropTypes.func,
  showAddSourcesToGroupButton: PropTypes.bool,
  groupActionTitle: PropTypes.string,
  groupActionOptions: PropTypes.arrayOf(PropTypes.shape({})),
  groupActionHandler: PropTypes.func,
  showAddSelectedSourcesButton: PropTypes.bool,
  addSelectedSourcesHandler: PropTypes.func,
  createReportHandler: PropTypes.func,
  showCancelButton: PropTypes.bool,
  cancelHandler: PropTypes.func,
  resourceId: PropTypes.string,
  showAdvancedSearchInput: PropTypes.bool,
  onExportLogClick: PropTypes.func,
  isSingleSelect: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  isModalGrid: PropTypes.bool,
  onClickError: PropTypes.bool,
  retainSearchText: PropTypes.bool,
  searchText: PropTypes.string
};

export default React.memo(SpogDataGrid);
