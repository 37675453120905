/* eslint-disable import/prefer-default-export */
import { put, all } from "redux-saga/effects";
import {
  addErrorNotification,
  addSuccessNotification
} from "state/actions/ccToast";
import localeData from "assets/i18n/locales/data.json";
import { darken, lighten } from "@mui/material";
import { DARK_MODE, LIGHT_MODE } from "utils/theme";
import { intl } from "../../intl";

import variables from "../../styles/_variables.scss";

/**
 * This is a generic function which shows the error message
 * for a given response, when context is specified on the
 * response
 * @param {*} resp
 * @param {*} values
 */
export function* showToastFromResponse(resp, values, errOnly = false) {
  const actions = [];
  if (resp.errors && resp.errors.length > 0) {
    actions.push(
      ...resp.errors.map(err =>
        addErrorNotification(
          getErrMsgId(resp.context, err.code, err.message),
          values
        )
      )
    );
  } else if (resp.status >= 400) {
    actions.push(addErrorNotification(getErrMsgId(resp.context), values));
  } else if (!errOnly) {
    actions.push(addSuccessNotification(resp.context + ".success", values));
  }
  yield all(actions.map(act => put(act)));
}

/**
 * Show toast only on errors and not on success
 * @param {*} resp
 * @param {*} values
 */
export function* showErrToastFromResponse(resp, values) {
  return yield* showToastFromResponse(resp, values, true);
}

/**
 * Format of how the error message id for react-intl
 * is formed given context, error number and error message
 * @param {*} ctx
 * @param {*} errNo
 * @param {*} errMsg
 */
const getErrMsgId = (ctx, errNo, errMsg) => {
  if (ctx && errNo) {
    let key = `${ctx}.error.${errNo}`;
    return localeData.en[key] ? key : errMsg;
  } else if (errMsg) {
    return errMsg;
  } else {
    return `${ctx}.error`;
  }
};

export const getFormattedMessage = (id, defaultMessage = "") =>
  intl.formatMessage({
    id,
    defaultMessage
  });

export const makeToastData = (messageId, testId) => {
  return { messageId, testId };
};
export const changeCSSProperty = themeValue => {
  const {
    scrollbarColorSchemeLight,
    scrollbarColorSchemeDark,
    muiModalFooterBorderTop,
    pagebgColor,
    pageBgColorDark,
    darkBg,
    grayBg,
    footerBg,
    footerBgDark,
    sidebarBorderColor,
    lightGrayBg,
    alertTabLight,
    alertTabDark,
    primaryTextColorDark,
    primaryTextDark,
    primaryText,
    grayColor,
    secondaryTextDark,
    dataGridHoverColor,
    dataGridHoverColorDark,
    dataGridHeaderBg,
    dataGridHeaderBgDark,
    roleDetailsBg,
    roleDetailsBgDark,
    disableBg,
    darkGrayBorder,
    darkThemeBorder,
    lightGrayBorder,
    policyCardBg,
    cardsDarkBg,
    sourceBlockBg,
    successLightBgDarkTheme,
    selectedGreenBg,
    headerBgColorDark,
    headerBgColor,
    infoBlueBg,
    infoBlueBgDark,
    blackColor,
    linkDisable,
    dividerLight,
    dividerDark,
    disableTextDark,
    disableText,
    whiteColor,
    purpleColorActive,
    purpleColorActiveDark,
    actionDarkBg,
    cardDarkBorder,
    cardLightBorder,
    darkDividerThin,
    ghostLight,
    ghostDark,
    secondaryChipColor,
    darkSecondaryChipColor,
    tooltipBorderLight,
    secondaryNavLightHover,
    secondaryNavDarkHover,
    linkColor,
    secondaryHoverButtonBorder,
    cardDividerColor,
    muiLightBorderBottom,
    lightChartDottedLine,
    darkChartDottedLine,
    borderContentBottom,
    recoverCardLightBorder,
    recoverCardLightBg,
    recoverSelectCardLightBg,
    recoveryCardSelectedPoint,
    recoveryCardSelectedPointDark,
    purpleColorHoverDark,
    purpleColorHover,
    modalDividerLight,
    secondaryNavigationHoverColorDark,
    secondaryNavigationColorDark,
    backgroundTransparent,
    barChartBottomLight,
    lightLoadingIcon,
    darkLoadingIcon,
    bgImageDarkTheme,
    disabledSelectLightBg,
    disabledSelectDarkBg,
    xAxisDarkColor,
    xAxisLightColor,
    popOverShadowLight,
    popOverShadowDark,
    cardsLightBg,
    lightHoverColor,
    darkHoverColor,
    autofillColorDark,
    bgImageCardDarkTheme,
    pageBgColorDarkLighterAccent,
    searchBorderDark,
    searchBorderLight,
    placeholderColorDarkTheme,
    placeholderColorLightTheme,
    placeholderOpacityDarkTheme,
    placeholderOpacityLightTheme
  } = variables;
  const theme = themeValue?.palette?.mode
    ? themeValue?.palette?.mode
    : LIGHT_MODE;

  document.documentElement.style.setProperty(
    "--scrollbar-color",
    theme === DARK_MODE ? scrollbarColorSchemeDark : scrollbarColorSchemeLight
  );
  document.documentElement.style.setProperty(
    "--page-bg-color",
    theme === DARK_MODE ? pageBgColorDark : pagebgColor
  );
  document.documentElement.style.setProperty(
    "--page-bg-color-lighter",
    theme === DARK_MODE ? pageBgColorDarkLighterAccent : pagebgColor
  );
  document.documentElement.style.setProperty(
    "--overlay-text-color",
    theme === DARK_MODE ? pagebgColor : blackColor
  );
  document.documentElement.style.setProperty(
    "--modal-footer-border-top",
    theme === DARK_MODE ? headerBgColorDark : muiModalFooterBorderTop
  );
  document.documentElement.style.setProperty(
    "--footer-bg",
    theme === DARK_MODE ? footerBgDark : footerBg
  );
  document.documentElement.style.setProperty(
    "--sidebar-border-color",
    theme === DARK_MODE ? cardDarkBorder : sidebarBorderColor
  );
  document.documentElement.style.setProperty(
    "--light-gray-bg",
    theme === DARK_MODE ? darkBg : lightGrayBg
  );
  document.documentElement.style.setProperty(
    "--primary-text-color",
    theme === DARK_MODE ? primaryTextDark : primaryText
  );
  document.documentElement.style.setProperty(
    "--filter-text-color",
    theme === DARK_MODE ? primaryTextColorDark : primaryTextDark
  );
  document.documentElement.style.setProperty(
    "--gray-color",
    theme === DARK_MODE ? secondaryTextDark : grayColor
  );

  document.documentElement.style.setProperty(
    "--data-grid-hover-color",
    theme === "dark" ? dataGridHoverColorDark : dataGridHoverColor
  );
  document.documentElement.style.setProperty(
    "--data-grid-header-bg",
    theme === "dark" ? dataGridHeaderBgDark : dataGridHeaderBg
  );
  document.documentElement.style.setProperty(
    "--role-details-bg",
    theme === "dark" ? roleDetailsBgDark : roleDetailsBg
  );
  document.documentElement.style.setProperty(
    "--disable-bg",
    theme === DARK_MODE ? headerBgColorDark : disableBg
  );
  document.documentElement.style.setProperty(
    "--dark-gray-border",
    theme === DARK_MODE ? darkThemeBorder : darkGrayBorder
  );
  document.documentElement.style.setProperty(
    "--light-gray-border",
    theme === DARK_MODE ? darkThemeBorder : lightGrayBorder
  );
  document.documentElement.style.setProperty(
    "--policy-card-bg",
    theme === DARK_MODE ? cardsDarkBg : policyCardBg
  );
  document.documentElement.style.setProperty(
    "--source-block-bg",
    theme === DARK_MODE ? cardsDarkBg : sourceBlockBg
  );
  document.documentElement.style.setProperty(
    "--selected-green-bg",
    theme === DARK_MODE ? successLightBgDarkTheme : selectedGreenBg
  );
  document.documentElement.style.setProperty(
    "--header-bg-color",
    theme === DARK_MODE ? headerBgColorDark : headerBgColor
  );
  document.documentElement.style.setProperty(
    "--info-blue-bg",
    theme === DARK_MODE ? infoBlueBgDark : infoBlueBg
  );
  document.documentElement.style.setProperty(
    "--black-border",
    theme === DARK_MODE ? darkThemeBorder : blackColor
  );
  document.documentElement.style.setProperty(
    "--disable-text-link",
    theme === DARK_MODE ? primaryTextDark : linkDisable
  );
  document.documentElement.style.setProperty(
    "--primary-text-color",
    theme === DARK_MODE ? primaryTextDark : primaryText
  );
  document.documentElement.style.setProperty(
    "--divider-color",
    theme === DARK_MODE ? dividerDark : dividerLight
  );
  document.documentElement.style.setProperty(
    "--mui-tab-color",
    theme === DARK_MODE ? alertTabDark : alertTabLight
  );
  document.documentElement.style.setProperty(
    "--dark-black-color",
    theme === "dark" ? whiteColor : blackColor
  );
  document.documentElement.style.setProperty(
    "--disabled-text-color",
    theme === "dark" ? disableTextDark : disableText
  );
  document.documentElement.style.setProperty(
    "--primary-purple-color",
    theme === "dark" ? purpleColorActiveDark : purpleColorActive
  );
  document.documentElement.style.setProperty(
    "--primary-purple-color-hover",
    theme === "dark" ? purpleColorHoverDark : purpleColorHover
  );
  document.documentElement.style.setProperty(
    "--selected-action-bg",
    theme === "dark" ? actionDarkBg : selectedGreenBg
  );
  // cardLightBorder-> #e8e8e8, cardDarkBorder-> rgba(255,255,255,0.2)
  document.documentElement.style.setProperty(
    "--card-border",
    theme === "dark" ? cardDarkBorder : cardLightBorder
  );
  // darkGrayBorder-> #cecece, cardDarkBorder-> rgba(255,255,255,0.2)
  document.documentElement.style.setProperty(
    "--card-border-bold",
    theme === DARK_MODE ? cardDarkBorder : darkGrayBorder
  );
  document.documentElement.style.setProperty(
    "--thin-divider",
    theme === "dark" ? darkDividerThin : lightGrayBorder
  );
  document.documentElement.style.setProperty(
    "--data-grid-ghost-color",
    theme === "dark" ? ghostDark : ghostLight
  );
  document.documentElement.style.setProperty(
    "--secondary-chip-color",
    theme === "dark" ? darkSecondaryChipColor : secondaryChipColor
  );
  document.documentElement.style.setProperty(
    "--tooltip-border",
    theme === "dark" ? cardDarkBorder : tooltipBorderLight
  );
  document.documentElement.style.setProperty(
    "--box-border-color",
    theme === "dark" ? darkDividerThin : grayColor
  );
  document.documentElement.style.setProperty(
    "--secondary-nav-hover",
    theme === "dark" ? secondaryNavDarkHover : secondaryNavLightHover
  );
  document.documentElement.style.setProperty(
    "--secondary-nav-hover-text",
    theme === "dark" ? whiteColor : linkColor
  );
  document.documentElement.style.setProperty(
    "--secondary-hover-border",
    theme === "dark" ? purpleColorActiveDark : secondaryHoverButtonBorder
  );

  document.documentElement.style.setProperty(
    "--secondary-navigation-hover-color",
    theme === "dark" ? secondaryNavigationHoverColorDark : linkColor
  );
  document.documentElement.style.setProperty(
    "--secondary-navigation-color",
    theme === "dark" ? secondaryNavigationColorDark : grayColor
  );
  document.documentElement.style.setProperty(
    "--modal-divider",
    theme === "dark" ? headerBgColorDark : modalDividerLight
  );
  document.documentElement.style.setProperty(
    "--advanced-search-disabled-bg",
    theme === "dark" ? backgroundTransparent : whiteColor
  );
  document.documentElement.style.setProperty(
    "--active-secondary-nav-option-color",
    theme === "dark" ? whiteColor : primaryText
  );
  document.documentElement.style.setProperty(
    "--card-border-color",
    theme === "dark" ? darkDividerThin : cardDividerColor //
  );

  document.documentElement.style.setProperty(
    "--divider-thin-color",
    theme === "dark" ? darkDividerThin : muiLightBorderBottom
  );
  document.documentElement.style.setProperty(
    "--chart-dotted-line",
    theme === "dark" ? darkChartDottedLine : lightChartDottedLine
  );
  document.documentElement.style.setProperty(
    "--border-content-top",
    theme === "dark" ? dividerDark : borderContentBottom
  );
  document.documentElement.style.setProperty(
    "--recovery-card-bg",
    theme === "dark" ? pageBgColorDark : recoverCardLightBg
  );
  document.documentElement.style.setProperty(
    "--recocvery-card-border",
    theme === "dark" ? cardDarkBorder : recoverCardLightBorder
  );
  document.documentElement.style.setProperty(
    "--recocvery-selected-card-border",
    theme === "dark" ? darkDividerThin : recoverSelectCardLightBg
  );
  document.documentElement.style.setProperty(
    "--recovery-selected-point",
    theme === "dark" ? recoveryCardSelectedPointDark : recoveryCardSelectedPoint
  );
  document.documentElement.style.setProperty(
    "--bar-chart-bottom-line",
    theme === "dark" ? cardDarkBorder : barChartBottomLight
  );
  document.documentElement.style.setProperty(
    "--paper-bg-image",
    theme === "dark" ? bgImageDarkTheme : "none"
  );
  document.documentElement.style.setProperty(
    "--disabled-select-bg",
    theme === "dark" ? disabledSelectDarkBg : disabledSelectLightBg
  );
  document.documentElement.style.setProperty(
    "--loading-dot-color",
    theme === "dark" ? darkLoadingIcon : lightLoadingIcon
  );
  document.documentElement.style.setProperty(
    "--button-primary-text-color",
    theme === DARK_MODE ? primaryTextColorDark : whiteColor
  );
  document.documentElement.style.setProperty(
    "--button-primary-background-color",
    themeValue.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--button-secondary-text-color",
    themeValue.palette.secondary.main
  );
  document.documentElement.style.setProperty(
    "--button-secondary-background-color",
    themeValue.palette.secondary.main
  );

  document.documentElement.style.setProperty(
    "--barchart-xaxis-color",
    theme === "dark" ? xAxisDarkColor : xAxisLightColor
  );
  document.documentElement.style.setProperty(
    "--dropdown-list-bg",
    theme === "dark" ? cardsDarkBg : cardsLightBg
  );
  document.documentElement.style.setProperty(
    "--button-primary-background-color-lighten",
    theme === "dark"
      ? darken(themeValue.palette.primary.main, 0.85)
      : lighten(themeValue.palette.primary.main, 0.85)
  );
  document.documentElement.style.setProperty(
    "--button-primary-background-color-lighten-hover",
    theme === "dark"
      ? darken(themeValue.palette.primary.main, 0.8)
      : lighten(themeValue.palette.primary.main, 0.8)
  );
  document.documentElement.style.setProperty(
    "--mui-dropdown-hover-color",
    theme === "dark" ? darkHoverColor : lightHoverColor
  );
  document.documentElement.style.setProperty(
    "--pop-over-box-shadow",
    theme === "dark" ? popOverShadowDark : popOverShadowLight
  );
  document.documentElement.style.setProperty(
    "--autofill-bg-color",
    theme === DARK_MODE ? autofillColorDark : pagebgColor
  );
  document.documentElement.style.setProperty(
    "--primary-card-border-color",
    theme === "dark"
      ? darken(themeValue.palette.primary.main, 0.5)
      : lighten(themeValue.palette.primary.main, 0.5)
  );
  document.documentElement.style.setProperty(
    "--card-bg-image",
    theme === "dark" ? bgImageCardDarkTheme : "none"
  );
  document.documentElement.style.setProperty(
    "--primary-calendar-selected-color",
    theme === "dark"
      ? darken(themeValue.palette.primary.main, 0.5)
      : lighten(themeValue.palette.primary.main, 0.8)
  );
  document.documentElement.style.setProperty(
    "--icon-color-for-draggable-list",
    theme === "dark" ? purpleColorActiveDark : primaryText
  );
  document.documentElement.style.setProperty(
    "--search-input-border-color",
    theme === "dark" ? searchBorderDark : searchBorderLight
  );
  document.documentElement.style.setProperty(
    "--policy-bg-color-darker",
    theme === DARK_MODE
      ? darken(themeValue.palette.primary.main, 0.85)
      : lighten(themeValue.palette.primary.main, 0.85)
  );
  document.documentElement.style.setProperty(
    "--policy-tabs-bg-color",
    theme === DARK_MODE ? pageBgColorDarkLighterAccent : grayBg
  );
  document.documentElement.style.setProperty(
    "--placeholder-color",
    theme === DARK_MODE ? placeholderColorDarkTheme : placeholderColorLightTheme
  );
  document.documentElement.style.setProperty(
    "--placeholder-opacity",
    theme === DARK_MODE
      ? placeholderOpacityDarkTheme
      : placeholderOpacityLightTheme
  );
};
