import React from "react";
import FormattedMessage from "components/common/formatted-message";

const LinuxSourceConstant = {
  OracleSourceAttribute: {
    ORACLE_PROP_CONFIGURATION: "configuration",
    ORACLE_PROP_SAVE_TO_LIST: "save.list",
    ORACLE_PROP_SITE: "site",
    ORACLE_PROP_NAME_IP: "name.ip",
    ORACLE_PROP_USERNAME: "username",
    ORACLE_PROP_PASSWORD: "password",
    ORACLE_PROP_UDP_SSH_KEY_AUTHENTICATION:
      "protect.source.oracle.ssh.key.authentication",
    ORACLE_PROP_SSH_PORT: "ssh.port",
    ORACLE_PROP_ORACLE_OWNER: "owner",
    ORACLE_WINDOW_SOURCE_TYPE: "windows_oracle_host",
    ORACLE_LINUX_SOURCE_TYPE: "linux_oracle_host",
    ORACLE_PROP_SITE_NAME: "site_name",
    ORACLE_PROP_USERNAME_COLUMN_DEF: "username",
    ORACLE_PROP_SOURCE_NAME: "source_name",
    ORACLE_PROP_OS_NAME: "oracle_source_type",
    ORACLE_PROP_OWNER_NAME: "oracle_username",
    ORACLE_PROP_ICON: "icon",
    ORACLE_PROP_COLLECT_INFO_JOB: "collect.info.job",
    ORACLE_PROP_OS_HEADER: (
      <FormattedMessage id="protect.sources.operating_system" />
    ),
    ORACLE_PROP_SITE_HEADER: (
      <FormattedMessage id="protect.source.oracle.site" />
    ),
    ORACLE_PROP_USERNAME_HEADER: (
      <FormattedMessage id="protect.source.oracle.username" />
    ),
    ORACLE_PROP_SOURCE_NAME_HEADER: (
      <FormattedMessage id="protect.source.oracle.name.ip" />
    ),
    ORACLE_PROP_ORACLE_OWNER_HEADER: (
      <FormattedMessage
        id="protect.source.oracle.owner"
        defaultMessage="Oracle Owner"
      />
    ),
    ORACLE_PROP_NON_ROOT_CREDENTIAL: "non.root.credential",
    ORACLE_PROP_NON_ROOT_USERNAME: "non.root.username",
    ORACLE_PROP_NON_ROOT_PASSWORD: "non.root.password",
    ORACLE_PROP_ADD_LINUXSOURCE: "addLinuxsource",
    ORACLE_PROP_LIST_SOURCES: "list.sources",
    ORACLE_PROP_ADDED_SOURCES_FAR: "added.sources.far",
    ORACLE_PROP_CONFIG_SOURCE_CLICK: "config.source.click",
    ORACLE_PROP_BUTTON_GET_STARTED: "button.get.started",
    ORACLE_PROP_HELP_BTN: "help.btn",
    ORACLE_PROP_CANCEL_BTN: "cancel.btn",
    ORACLE_PROP_ADD_SOURCES_BTN: "add.sources.btn",
    TIME_DELAY: 3000,
    OS_MAJOR_LINUX: "linux",
    OS_MAJOR_WINDOWS: "windows",
    CONNECTION_STATUS: "online"
  },
  ORACLE_SOURCE_FORM: "OracleSources",
  ORACLE_WINDOWS_SOURCE_FORM: "OracleWindowsSources",
  ORACLE_LINUX_SOURCE_FORM: "OracleLinuxSources",
  /**
   * Returns an i18n supported key for the identifier
   * @param {string} identifier
   */
  getDisplayIdentifier(identifier) {
    return "protect.source.oracle.".concat(identifier);
  }
};
export default LinuxSourceConstant;
