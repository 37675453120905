import _ from "lodash";
import { createSelector } from "reselect";
import { registeredSitesSelector } from "state/selectors/SitesSelector";
import localeData from "assets/i18n/locales/data.json"; // i18n translated strings
import { API_RESPONSE_CONTEXT } from "utils/appConstants";

// Linux Backup Server starts here
/**
 * Path to connect API's response for LBS
 * @param {*} state
 */
export const lbsConnectErrors = state =>
  state.addSources && state.addSources.lbs.connectErrors;

/**
 * Path to connect API's Errors for Hypervisor
 * @param {*} state
 */
export const hypervisorConnectErrors = state =>
  state.addSources.fromHypervisor.connectErrors;

/**
 * Error selectors for connect API's response for LBS
 */
const errorMessage = ({ code, message }, context) => {
  if (localeData.en.hasOwnProperty(`error.${context}.${code}`)) {
    return localeData.en[`error.${context}.${code}`];
  } else {
    return message;
  }
};

export const lbsConnectIsError = createSelector(
  lbsConnectErrors,
  errors => errors && errors.length > 0
);

export const lbsConnectErrorMsg = createSelector(
  lbsConnectErrors,
  errors =>
    errors &&
    errors.length > 0 &&
    errorMessage(
      errors[0],
      API_RESPONSE_CONTEXT.LINUX_BACKUP_SERVER_SOURCE_CONNECT
    )
);

/**
 * Add LBS Grid data
 */
export const getLbsGridData = createSelector(
  [
    state => state.addSources && state.addSources.lbs.savedList,
    registeredSitesSelector
  ],
  (lbsGridData, sites) => {
    return (
      lbsGridData &&
      lbsGridData.map(source => {
        let site = sites.find(site => site.site_id === source.site_id);
        return {
          ...source,
          site_name: (site && site.site_name) || ""
        };
      })
    );
  }
);
// Linux Backup Server ends here

// Active Directory starts here
/**
 * Path to add API's response for Active Directory
 * @param {*} state
 */
export const addAdErrors = state => state.addSources.discoverFromAd.addAdErrors;

/**
 * Error selectors for add API's response for Active Directory
 */
export const addAdIsError = createSelector(
  addAdErrors,
  errors => errors.length > 0
);

export const addAdErrorMsg = createSelector(addAdErrors, errors =>
  errors.reduce((acc, error) => `${error.message} `, "")
);

export const adList = state => state.addSources.discoverFromAd.adList;
// Active Directory ends here

/**
 * Hypervisor Vmware
 */
export const hyperLeftPanel = state =>
  state &&
  state.addSources &&
  state.addSources.fromHypervisor &&
  state.addSources.fromHypervisor.leftPanel;

export const hyperRightPanel = state =>
  state &&
  state.addSources &&
  state.addSources.fromHypervisor &&
  state.addSources.fromHypervisor.rightPanel;

/**
 * Error selectors for connect API's response for Hypervisor
 */
export const hypervisorConnectIsError = createSelector(
  hypervisorConnectErrors,
  errors => errors.length > 0
);

export const hypervisorConnectErrorMsg = createSelector(
  hypervisorConnectErrors,
  errors => errors.reduce((acc, error) => `${error.message} `, "")
);

/**
 * Selected site
 */
export const getSelectedSite = state =>
  state &&
  state.addSources &&
  state.addSources.fromHypervisor &&
  state.addSources.fromHypervisor.selectedSite;

export const getSelectedVms = state =>
  state &&
  state.addSources &&
  state.addSources.fromHypervisor &&
  state.addSources.fromHypervisor.selectedVms;

export const getSelectedVmsGrid = createSelector(
  [getSelectedSite, getSelectedVms],
  (site, vmList) => vmList.map(vm => ({ ...vm, site_name: site.name }))
);

export const getHypervisorId = state =>
  state &&
  state.addSources &&
  state.addSources.fromHypervisor &&
  state.addSources.fromHypervisor.hypervisorId;

export const getServerType = state =>
  state &&
  state.addSources &&
  state.addSources.fromHypervisor &&
  state.addSources.fromHypervisor.vmwareServerType;

export const hypervOptions = state => {
  let hypervs = _.get(state, "protectReducer.sourceHypervisorList", []);
  return hypervs.map(x => ({
    key: x.hypervisor_name,
    value: x.hypervisor_name
  }));
};

export const getHypervisorsListFromReducer = state =>
  state?.reducer?.hypervisorsList;
