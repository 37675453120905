import {
  SAVE_TO_LIST_LINUX_SOURCE_ERROR,
  SAVE_TO_LIST_LINUX_SOURCE_SUCCESS,
  CLEAR_LINUX_SOURCE_LIST,
  DELETE_SOURCE_FROM_LIST_LINUX_SOURCE,
  CLEAR_LINUX_SOURCE_ERROR
} from "../../actions/actionTypes";
import { combineReducers } from "redux";

/**
 * Errors occured during Connect API call to LBS is maintained here
 * @param {*} state
 * @param {*} action
 */
const connectErrors = (state = null, { type, data }) => {
  switch (type) {
    case SAVE_TO_LIST_LINUX_SOURCE_ERROR:
      return data;
    case CLEAR_LINUX_SOURCE_ERROR:
      return null;
    default:
      return state;
  }
};

/**
 * If Connect API is success, then we are adding the fields
 * to connected list of servers to be added to sources
 * @param {*} state
 * @param {*} action
 */
const savedList = (state = [], { type, data, source }) => {
  switch (type) {
    case SAVE_TO_LIST_LINUX_SOURCE_SUCCESS:
      return [...state, data];
    case DELETE_SOURCE_FROM_LIST_LINUX_SOURCE:
      const newSavedList = state.filter(
        obj => obj.uniqueId !== source.uniqueId
      );
      return newSavedList;
    case CLEAR_LINUX_SOURCE_LIST:
      return [];
    default:
      return state;
  }
};

export default combineReducers({ connectErrors, savedList });
