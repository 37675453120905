import * as C from "state/constants";

const initialState = {
  isLoading: true,
  organization: {},
  isLoadingSupport: true,
  support: {},
  isLoadingEntitlements: true,
  freetrialCloudDirect: {},
  isLoadingFreetrialCloudDirect: true,
  freetrialCloudHybrid: {},
  isLoadingFreetrialCloudHybrid: true
};

const organizations = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_ORGANIZATIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        organization: {}
      });
    case C.FETCH_ORGANIZATIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        organization: action.payload.data
      });
    case C.FETCH_ORGANIZATIONS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        organization: {}
      });
    case C.FETCH_ORGANIZATIONS_SUPPORT_REQUEST:
      return Object.assign({}, state, {
        isLoadingSupport: true,
        support: {}
      });
    case C.FETCH_ORGANIZATIONS_SUPPORT_SUCCESS:
      return Object.assign({}, state, {
        isLoadingSupport: false,
        support: action.payload.data
      });
    case C.FETCH_ORGANIZATIONS_SUPPORT_FAILURE:
      return Object.assign({}, state, {
        isLoadingSupport: false,
        support: {}
      });
    case C.FETCH_ORGANIZATIONS_ENTITLEMENTS_REQUEST:
      return Object.assign({}, state, {
        isLoadingEntitlements: true,
        entitlements: {}
      });
    case C.FETCH_ORGANIZATIONS_ENTITLEMENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingEntitlements: false,
        entitlements: action.payload.data
      });
    case C.FETCH_ORGANIZATIONS_ENTITLEMENTS_FAILURE:
      return Object.assign({}, state, {
        isLoadingEntitlments: false,
        entitlements: {}
      });
    case C.FETCH_ORGANIZATIONS_PROPERTIES_REQUEST:
      return Object.assign({}, state, {
        isLoadingProperties: true,
        properties: {}
      });
    case C.FETCH_ORGANIZATIONS_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        isLoadingProperties: false,
        properties: action.payload.data
      });
    case C.FETCH_ORGANIZATIONS_PROPERTIES_FAILURE:
      return Object.assign({}, state, {
        isLoadingProperties: false,
        properties: {}
      });
    case C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_REQUEST:
      return Object.assign({}, state, {
        isLoadingFreetrialCloudDirect: true,
        freetrialCloudDirect: {}
      });
    case C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_SUCCESS:
      return Object.assign({}, state, {
        isLoadingFreetrialCloudDirect: false,
        freetrialCloudDirect: action.payload.data
      });
    case C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_DIRECT_FAILURE:
      return Object.assign({}, state, {
        isLoadingFreetrialCloudDirect: false,
        freetrialCloudDirect: {}
      });
    case C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_REQUEST:
      return Object.assign({}, state, {
        isLoadingFreetrialCloudHybrid: true,
        freetrialCloudHybrid: {}
      });
    case C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_SUCCESS:
      return Object.assign({}, state, {
        isLoadingFreetrialCloudHybrid: false,
        freetrialCloudHybrid: action.payload.data
      });
    case C.FETCH_ORGANIZATIONS_FREETRIAL_CLOUD_HYBRID_FAILURE:
      return Object.assign({}, state, {
        isLoadingFreetrialCloudHybrid: false,
        freetrialCloudHybrid: {}
      });
    default:
      return state;
  }
};

export default organizations;
