/* eslint-disable camelcase */
/**
 * Destination types in policy
 * @type {{PRIMARY: string, SECONDARY: string}}
 */
export const destinationType = {
  PRIMARY: "primary",
  SECONDARY: "secondary"
};

/**
 * Empty object for policy to add new policy
 * @type {{}}
 */
export const EMPTY_POLICY_OBJECT = {
  id: null,
  policy_id: "temp_create_id",
  policy_name: "",
  policy_description: "",
  policy_type: "",
  sla: {
    recovery_time_objectives: {
      assured_recovery_test: {
        value: "",
        unit: "Minutes"
      },
      vm_recovery: {
        value: "",
        unit: "Minutes"
      },
      instant_vm_recovery: {
        value: "",
        unit: "Minutes"
      },
      file_level_restore: {
        value: "",
        unit: "Minutes"
      },
      bare_metal_recovery: {
        value: "",
        unit: "Minutes"
      }
    },
    recovery_point_objective: {
      value: {
        value: "",
        unit: "Minutes"
      }
    }
  },
  schedules: [],
  sources: [],
  destinations: [],
  throttles: [],
  remoteRPS: false
};
// pre-filled data for schedule
export const destinationProtectDropBoxSkeleton = {
  backupSchedule: {
    backupType: [
      "Every 15 Mins",
      "Every 1 Hour",
      "Every 6 Hours",
      "Every 1 Day"
    ],
    runSchedule: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    startTime: {
      minutes: "00",
      hour: "",
      AMPM: ["AM", "PM"]
    }
  },
  throttleSchedule: {
    throughputLimit: {
      speed: "",
      unit: ["kbits/s"]
    },
    runSchedule: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    startTime: {
      time: "",
      AMPM: ["AM", "PM"]
    },
    endTime: {
      time: "",
      AMPM: ["AM", "PM"]
    }
  }
};

// pre-filled data for RPS schedule
export const destinationProtectRPSDropBoxSkeleton = {
  merge: {
    runSchedule: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    startTime: {
      time: "",
      AMPM: ["AM", "PM"]
    },
    endTime: {
      time: "",
      AMPM: ["AM", "PM"]
    }
  },
  replication: {
    runSchedule: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    startTime: {
      time: "",
      AMPM: ["AM", "PM"]
    },
    endTime: {
      time: "",
      AMPM: ["AM", "PM"]
    }
  },
  ar: {
    runSchedule: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    startTime: {
      time: "",
      AMPM: ["AM", "PM"]
    },
    endTime: {
      time: "",
      AMPM: ["AM", "PM"]
    }
  }
};

export const driveBackUpType = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Z"
];

export const additionalSettingsFilesFolders = ["file", "directory", "path"];

export const daysOfWeek = [
  { label: "Sun", value: "0" },
  { label: "Mon", value: "1" },
  { label: "Tue", value: "2" },
  { label: "Wed", value: "3" },
  { label: "Thu", value: "4" },
  { label: "Fri", value: "5" },
  { label: "Sat", value: "6" }
];
export const FulldaysOfWeek = [
  { label: "Sunday", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" }
];

export const ARTestOptions = {
  daily_backup: false,
  weekly_backup: false,
  monthly_backup: false,
  lastet_recovery_point: false
};

export const protectionType = {
  CD: "cloud_direct_baas",
  DR: "cloud_direct_draas",
  RPS: "cloud_hybrid_replication",
  UDP_AGENT_BASED_WINDOWS: "udp_agent_based_windows",
  UDP_ORACLE_DATABASE: "udp_oracle_database",
  UDP_AGENT_BASED_LINUX: "udp_agent_based_linux",
  UDP_UNC_NFC_BACKUP: "udp_files_on_unc",
  UDP_REPLICATE_FROM_REMOTE_RPS: "udp_replication_from_remote",
  UDP_AGENTLESS_VM: "udp_agentless_vm_backup",
  CD_HYPERVISOR: "cloud_direct_hypervisor"
};

export const destinationFilterType = {
  CD: "normal",
  DR: "zero_copy",
  RPS: "cloud_hybrid_store"
};

export const everyOneDay = "Every 1 Day";
export const everyFifteenMinutes = "Every 15 Mins";
export const everyOneHour = "Every 1 Hour";
export const everySixHour = "Every 6 Hours";

export const destinationTileSkeleton = {
  obj: {
    primary: {
      type: destinationType.PRIMARY,
      task_id: "",
      task_type: "",
      destination_id: "",
      parent_id: null,
      destination_name: "",
      destination_type: "",
      cloud_direct_image_backup: {
        drives: ["*"],
        local_backup: {
          path: "",
          enabled: false
        }
      },
      cloud_direct_file_backup: {
        path: [],
        excludes: [],
        local_backup: {
          path: "",
          enabled: false
        }
      },
      udp_replication_from_remote_ch: {
        // perform_ar_test: {
        //   daily_backup: false,
        //   weekly_backup: false,
        //   monthly_backup: false,
        //   lastet_recovery_point: false
        // },
        retention_policy: {
          daily_backup: "7",
          weekly_backup: "5",
          monthly_backup: "12",
          manual_backup: "31"
        }
      },
      udp_replication_from_remote: {
        retention_policy: {
          daily_backup: "7",
          weekly_backup: "5",
          monthly_backup: "12",
          manual_backup: "31"
        }
      },
      udp_replication_to_remote: {
        user_name: "",
        password: "",
        port: "8015",
        protocol: "https",
        remote_plan_global_uuid: "",
        remote_plan_rpspolicy_uuid: "",
        remote_plan_uuid: "",
        remote_plan_name: "",
        remote_plan_rpspolicy_name: "",
        retry_start: "10",
        retry_times: "3"
      },
      merge: [],
      ar: [],
      replication: [],
      backupSchedule: [],
      throttleSchedule: [],
      isDisabled: false,
      driveSelection: "",
      selectUnselectAll: false
    }
  }
};

export const resetDestinationSecondTile = {
  obj: {
    primary: {
      type: destinationType.PRIMARY,
      task_id: "",
      task_type: "",
      parent_id: null,
      cloud_direct_image_backup: {
        drives: ["*"],
        local_backup: {
          path: "",
          enabled: false
        }
      },
      cloud_direct_file_backup: {
        path: [""],
        excludes: [],
        local_backup: {
          path: "",
          enabled: false
        }
      },
      udp_replication_from_remote_ch: {
        // perform_ar_test: {
        //   daily_backup: false,
        //   weekly_backup: false,
        //   monthly_backup: false,
        //   lastet_recovery_point: false
        // },
        retention_policy: {
          daily_backup: "7",
          weekly_backup: "5",
          monthly_backup: "12",
          manual_backup: "31"
        }
      },
      udp_replication_from_remote: {
        retention_policy: {
          daily_backup: "7",
          weekly_backup: "5",
          monthly_backup: "12",
          manual_backup: "31"
        }
      },
      udp_replication_to_remote: {
        user_name: "",
        password: "",
        port: "",
        protocol: "https",
        remote_plan_global_uuid: "",
        remote_plan_rpspolicy_uuid: "",
        remote_plan_uuid: "",
        remote_plan_name: "",
        remote_plan_rpspolicy_name: "",
        retry_start: "",
        retry_times: ""
      },
      isDisabled: true
    }
  }
};
export const backupScheduleForCdDr = [
  {
    schedule_id: "",
    schedule_type: everyOneDay,
    task_id: "",
    task_type: "",
    destination_id: "",
    destination_name: "",
    start_time: {
      hour: "",
      minutes: "00",
      AMPM: "AM"
    },
    end_time: null,
    schedule: {
      cloud_direct_schedule: {
        // TODO generate string based on interval selected
        crontab_string: "0 0 * * *"
      },
      custom_schedule: {
        first_start_time: "",
        backup_method: "",
        days_of_week: "",
        is_repeat_enable: true,
        interval: "Select",
        interval_unit: ""
      }
    }
  }
];

export const everyDayHoursAndMinutes = {
  hours: [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ],
  minutes: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59"
  ]
};

export const everySixHoursAndMinutes = {
  hours: ["12", "01", "02", "03", "04", "05"],
  minutes: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59"
  ]
};

export const everyHourHoursAndMinutes = {
  hours: ["12"],
  minutes: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59"
  ]
};

export const everyFifteenMinutesHoursAndMinutes = {
  hours: ["12"],
  minutes: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14"
  ]
};

export const AMPM = ["AM", "PM"];
export const AM = ["AM"];
export const driveSelectionOptions = [
  "backupAllVolumes",
  "backupSelectedVolumes"
];

export const checkBoxGroupTwo = [
  {
    key: "SYSTEM_VOLUME",
    value:
      "policy.destination.label.driveSelection.checkBoxGroupTwo.systemReservedVolume"
  },
  {
    key: "NO_DRIVE_LETTER_VOLUME",
    value:
      "policy.destination.label.driveSelection.checkBoxGroupTwo.mountedInNTFSFolderVolumes"
  },
  {
    key: "RECOVERY_VOLUME",
    value:
      "policy.destination.label.driveSelection.checkBoxGroupTwo.recoveryVolume"
  },
  {
    key: "BOOT_VOLUME",
    value: "policy.destination.label.driveSelection.checkBoxGroupTwo.bootVolume"
  }
];

export const EncryptionAlgorithmOptions = [
  "no_encryption",
  "AES-128",
  "AES-192",
  "AES-256"
];

export const scheduleTypes = ["daily", "weekly", "monthly", "always"];

export const backupSnapshotTypes = ["software", "hardware"];

export const EnableCompressionOptions = [
  "no_compression",
  "standard",
  "maximum"
];

export const EnableCompressionLinuxOptions = ["standard", "maximum"];

export const backupDestinationTypeOptionsForUdpUnc = ["recovery_point_server"];
export const backupDestinationTypeOptionsForOracle = ["recovery_point_server"];

export const backupDestinationTypeOptions = [
  "recovery_point_server",
  "share_folder",
  "local_disk"
];

export const backupDestinationTypeOptionsForLinux = [
  "recovery_point_server",
  "share_folder"
];

export const LOCAL_DISK = "local_disk";
export const RECOVERY_POINT_SERVER = "recovery_point_server";
export const SHARE_FOLDER = "share_folder";
export const SHARED_FOLDER = "shared_folder";

export const diskReadOptions = ["mb_min", "gb_min"];

export const networkOptions = ["kbps", "mbps"];

export const EXCLUDE_LARGER_UNIT_OPTIONS = ["mb", "gb"];

export const POLICY_STATES = ["trial", "activelicense", "activesubscription"];
