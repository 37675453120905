import { v4 as uuidv4 } from "uuid";
import {
  LOAD_REMOTE_CONSOLE_LIST_SUCCESS,
  DELETE_REMOTE_CONSOLE_SUCCESS
} from "../../actions/actionTypes";

const initialState = {
  remoteConsoleList: []
};

const RemoteConsole = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REMOTE_CONSOLE_LIST_SUCCESS:
      return {
        ...state,
        remoteConsoleList: setRemoteConsoleList(action.data),
        remoteConsoleListPagination: action.pagination
      };
    case DELETE_REMOTE_CONSOLE_SUCCESS:
      return {
        ...state,
        remoteConsoleList: deleteRemoteConsoleList(
          state.remoteConsoleList,
          action.id
        ),
        remoteConsoleListPagination: deleteTotal(
          state.remoteConsoleListPagination
        )
      };
    default:
      return state;
  }
};

const setRemoteConsoleList = data => {
  const available_actions = ["delete", "modify"];
  return data.map(rc => {
    return {
      ...rc,
      id: uuidv4(),
      available_actions: available_actions
    };
  });
};

const deleteRemoteConsoleList = (data, id) => {
  return data.filter(rc => rc.cloud_account_id !== id);
};
const deleteTotal = data => {
  return {
    ...data,
    total_size: data.total_size - 1
  };
};

export default RemoteConsole;
