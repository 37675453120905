import { createSelector } from "reselect";

import { getLogin } from "state/selectors";

// selectors (If props is required, use (state, props))
const requesting = state => getLogin(state).requesting;
const errors = state => getLogin(state).errors;
const successful = state => getLogin(state).successful;
const redirectTo = state => getLogin(state).redirectTo;
const loaderHeight = state => getLogin(state).height;
const loaderOpacity = state => getLogin(state).opacity;
const email = state => getLogin(state).email;
const brandLogin = state => state.configureReducer.brandData;
const brandDataRequesting = state =>
  state.configureReducer.brandData_requesting;

// reselect functions
export const makeLoginErrorsState = () =>
  createSelector([errors], errors => errors);
export const makeRequestingLoginState = () =>
  createSelector([requesting], requesting => requesting);
export const makeSuccesfulLoginState = () =>
  createSelector([successful], successful => successful);
export const makeRedirectToComponentState = () =>
  createSelector([redirectTo], redirectTo => redirectTo);
export const makeLoginLoaderHeightState = () =>
  createSelector([loaderHeight], loaderHeight => loaderHeight);
export const makeLoginLoaderOpacityState = () =>
  createSelector([loaderOpacity], loaderOpacity => loaderOpacity);
export const makeLoginEmailState = () =>
  createSelector([email], email => email);
export const makeLoginBrandState = () =>
  createSelector([brandLogin], brandLogin => brandLogin);
export const makeBrandDataRequestingState = () =>
  createSelector(
    [brandDataRequesting],
    brandDataRequesting => brandDataRequesting
  );
