import React from "react";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

function Skelton(props) {
  const { variant, width, height } = props;
  return <Skeleton variant={variant} width={width} height={height} />;
}

Skeleton.defaultProps = {
  variant: "rectangular",
  width: 222,
  height: 47
};
Skeleton.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Skelton;
