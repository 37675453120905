import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  impersonateCustomer,
  impersonateSource,
  impersonatePolicy,
  impersonateJob,
  impersonateRecoveryPoints,
  setAdvanceSearchData,
  updateCurrentSearchFromSaved,
  getAdvancedSearchOptions
} from "state/actions/actions";

import ViewOrgWithout from "./ViewOrg";

const ViewOrg = withRouter(
  connect(
    () => ({}),
    dispatch =>
      bindActionCreators(
        {
          impersonateCustomer,
          impersonateSource,
          impersonatePolicy,
          impersonateJob,
          impersonateRecoveryPoints,
          setAdvanceSearchData,
          updateCurrentSearchFromSaved,
          getAdvancedSearchOptions
        },
        dispatch
      )
  )(ViewOrgWithout)
);

export default ViewOrg;
