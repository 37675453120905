import React from "react";
import PropTypes from "prop-types";
import { Tab, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import IconMUI from "components/common/icon/IconMUI";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { ID_PREFIX } from "configs/IdConfig";

const WizardTab = props => {
  const {
    vertical,
    tabOptions,
    headerButtons,
    animation,
    disabled,
    selectedTab,
    routeLink,
    detailsTab,
    tabIndex
  } = props;

  /**
   * to render tab items
   * @param {array of object} data
   * @return html for tabs
   */
  //TODO refactor NavLink for disabled and enabled
  const renderTabItem = data => {
    return data.map((item, index) => (
      <Nav.Item
        eventKey={item.tabkey}
        key={index}
        disabled={disabled || item.disabled}
        className={item.chevron && item.tabkey === selectedTab ? "active" : ""}
      >
        {!item.disabled ? (
          <NavLink
            to={routeLink + "/" + item.tabkey.toLowerCase().replace(/\s/g, "")}
            onClick={() => handleTabClick(item.searchKey)}
            id={`${ID_PREFIX}${routeLink
              .substring(1)
              .split("/")
              .join("-")}${item.tabkey}`}
          >
            {item.tab}
            {item.icon && item.tabkey !== selectedTab ? (
              <IconMUI
                icon={CheckIcon}
                color="primary"
                sx={{
                  position: "absolute",
                  right: "10px",
                  margin: 0
                }}
              />
            ) : (
              void 0
            )}
            {item.chevron && item.tabkey === selectedTab ? (
              <IconMUI
                icon={ChevronRightOutlinedIcon}
                sx={{
                  position: "absolute",
                  right: "10px",
                  margin: 0
                }}
              />
            ) : (
              void 0
            )}
            {detailsTab ? <label>{item.details}</label> : ""}
          </NavLink>
        ) : (
          <Nav.Link
            id={`${ID_PREFIX}${routeLink
              .substring(1)
              .split("/")
              .join("-")}${item.tabkey}`}
            style={{ padding: 0 }}
            href={
              routeLink + "/" + item.tabkey.toLowerCase().replace(/\s/g, "")
            }
            disabled={item.disabled}
            onClick={() => handleTabClick(item.searchKey)}
          >
            {item.tab}
            {item.icon && item.tabkey !== selectedTab ? (
              <IconMUI
                icon={CheckIcon}
                color="primary"
                sx={{
                  position: "absolute",
                  right: "10px",
                  margin: 0
                }}
              />
            ) : (
              void 0
            )}
            {item.chevron && item.tabkey === selectedTab ? (
              <IconMUI
                icon={ChevronRightOutlinedIcon}
                sx={{
                  position: "absolute",
                  right: "10px",
                  margin: 0
                }}
              />
            ) : (
              void 0
            )}
            {detailsTab ? <label>{item.details}</label> : ""}
          </Nav.Link>
        )}
      </Nav.Item>
    ));
  };

  /**
   * to render tabs content
   * @param {array of object} data
   * @return html for tab content
   */
  const renderTabContent = data => {
    return data.map((item, index) => (
      <Tab.Pane eventKey={item.tabkey} key={index}>
        {item.content}
      </Tab.Pane>
    ));
  };

  const handleTabClick = tab => {
    if (props.handleTabClick) {
      props.handleTabClick(tab);
    }
  };

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  let attrs = {};
  tabIndex && (attrs["activeKey"] = tabIndex);
  const className = props.className || "";
  return (
    <Tab.Container activeKey={selectedTab}>
      <div
        className={
          vertical
            ? "clearfix m-0 tab-wrapper"
            : "clearfix m-0 tab-wrapper horizontal-tab-wrapper"
        }
      >
        <Nav variant="tabs" className={className || ""} stacked={vertical}>
          {renderTabItem(tabOptions)}
          {headerButtons}
        </Nav>
        <Tab.Content animation={animation}>
          {renderTabContent(tabOptions)}
        </Tab.Content>
      </div>
    </Tab.Container>
  );
};

WizardTab.defaultProps = {
  vertical: false,
  animation: true,
  detailsTab: false,
  disabled: false,
  headerButtons: null
};

WizardTab.propTypes = {
  vertical: PropTypes.bool.isRequired,
  tabOptions: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
    .isRequired,
  animation: PropTypes.bool,
  detailsTab: PropTypes.bool,
  handleTabClick: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  routeLink: PropTypes.string,
  selectedTab: PropTypes.string,
  headerButtons: PropTypes.element
};

export default WizardTab;
