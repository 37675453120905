import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import AsyncComponent from "../components/common/async";

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
class SubComponentRouter extends React.Component {
  render() {
    const routes = this.props.routes;
    return (
      <Switch>
        {routes.map(route => (
          // Render <Route>s with the same paths as
          // above, with main components to populate grid area.
          <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={() => (
              <AsyncComponent
                {...this.props}
                routes={route.routes}
                moduleProvider={route.main}
              />
            )}
          />
        ))}
      </Switch>
    );
  }
}

SubComponentRouter.defaultProps = {
  routes: []
};

SubComponentRouter.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({}))
};

export default SubComponentRouter;
