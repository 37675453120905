import {
  SHOW_ALERT_DETAILS_MODAL,
  GET_ALERT_LIST,
  SHOW_ALERT_LIST,
  UPDATE_ALERT_LIST_COUNT,
  RESET_ALERT_LIST_COUNT,
  REMOVE_ALERT,
  ACKNOWLEDGE_ALL_ALERTS,
  MANAGE_WIDGETS,
  EXPAND_WIDGET,
  GET_DASHBOARD_SUMMARY,
  UPDATE_DASHBOARD_SUMMARY,
  LOAD_DASHBOARD_TOP_POLICIES_WIDGET,
  FILTER_DASHBOARD_TOP_SOURCES_WIDGET_DATA,
  GET_ALERT_LIST_DETAIL,
  CLOSE_ALERT_LIST,
  LOAD_DATA_TRANSFER_SUMMARY_WIDGET,
  LOAD_CAPACITY_USAGE_TREND_WIDGET,
  LOAD_USAGE_TREND_CD_VOLUMES_WIDGET,
  LOAD_USAGE_TREND_CH_STORES_WIDGET,
  LOAD_DASHBOARD_TOP_CUSTOMERS_WIDGET,
  LOAD_RECENT_JOBS_IN_PROGRESS_WIDGET,
  CLEAR_WIDGET_DATA,
  CANCEL_RECENT_JOB_BY_ID,
  DELETE_RECENT_JOB_BY_ID,
  UPDATE_SELECTED_FILTER_OPTIONS_WIDGET,
  CLEAR_SELECTED_FILTER_OPTIONS_WIDGET,
  GET_WIDGETS_LIST,
  UPDATE_WIDGETS_ORDER,
  SET_FROM_MONITOR_PAGE,
  GET_WIDGETS
} from "./actionTypes";

/**
 * Update Users Widgets location
 */
export const updateWidgetsOrder = (data, afterReload) => ({
  type: UPDATE_WIDGETS_ORDER,
  data,
  afterReload
});

/**
 * Get Users Widgets List
 */
export const getWidgetsList = payload => ({
  type: GET_WIDGETS_LIST,
  payload
});

/**
 * Get Users Widgets List
 */
export const updateWidgetsList = data => ({
  type: GET_WIDGETS,
  data
});

/**
 * show/hide alert details modal
 */
export const showAlertDetailsModal = data => ({
  type: SHOW_ALERT_DETAILS_MODAL,
  data
});

/**
 * Action to get alert list
 */
export const getAlertList = (pollingAction = false) => ({
  type: GET_ALERT_LIST,
  pollingAction
});

export const deleteAlertsListDetails = () => ({
  type: CLOSE_ALERT_LIST
});

export const getAlertListDetails = data => ({
  type: GET_ALERT_LIST_DETAIL,
  data
});

/**
 * Action to show alert LIST
 * @param data
 * @returns {{type, data: *}}
 */
export const showAlertList = data => ({
  type: SHOW_ALERT_LIST,
  data
});

/**
 * Action to update alert list count
 * @param data
 * @returns {{type, data: *}}
 */
export const updateAlertListCount = data => ({
  type: UPDATE_ALERT_LIST_COUNT,
  data
});

/**
 * Action to reset alert list count
 * @param data
 * @returns {{type, data: *}}
 */
export const resetAlertListCount = () => ({
  type: RESET_ALERT_LIST_COUNT
});
/**
 * Action to delete alert from list
 * @param data
 * @returns {{type, data: *}}
 */
export const deleteAlertById = data => ({
  type: REMOVE_ALERT,
  data
});

/** Action to delete all acknowledged alerts
 * @param data
 * @returns {{type, data: *}}
 */
export const deleteAllAlerts = data => ({
  type: ACKNOWLEDGE_ALL_ALERTS,
  data
});

/**
 * Action to load dashboard widgets
 * @param data
 * @returns {{type, data: *}}
 */

/**
 * Action to manage dashboard widgets
 * @param data
 * @param widgetList
 * @returns {{type, data: *}}
 */
export const manageWidgets = (data, widgetList) => ({
  type: MANAGE_WIDGETS,
  data,
  widgetList
});

/**
 * Action to expand dashboard widgets
 * @param data
 * @returns {{type, data: *}}
 */
export const expandWidget = index => ({
  type: EXPAND_WIDGET,
  index
});

/**
 * Action to load dashboard top customers widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadTopCustomersWidget = payload => ({
  type: LOAD_DASHBOARD_TOP_CUSTOMERS_WIDGET,
  payload
});

/**
 * Action to filter dashboard top sources widget data
 * @param data
 * @returns {{type, payload: *}}
 */
export const filterTopSourcesWidgetData = payload => ({
  type: FILTER_DASHBOARD_TOP_SOURCES_WIDGET_DATA,
  payload
});

/**
 * Action to load dashboard top policies widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadTopPoliciesWidget = payload => ({
  type: LOAD_DASHBOARD_TOP_POLICIES_WIDGET,
  payload
});

/**
 * Action to load dashboard top policies widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadDataTransferSummaryWidget = payload => ({
  type: LOAD_DATA_TRANSFER_SUMMARY_WIDGET,
  payload
});

/**
 * Action to load dashboard top policies widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadCapacityUsageTrendWidget = payload => ({
  type: LOAD_CAPACITY_USAGE_TREND_WIDGET,
  payload
});

/**
 * Action to load dashboard usage trends for CD volumes widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadUsageTrendCDVolumesWidget = payload => ({
  type: LOAD_USAGE_TREND_CD_VOLUMES_WIDGET,
  payload
});

/**
 * Action to load dashboard usage trends for CD volumes widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadUsageTrendCHStoresWidget = payload => ({
  type: LOAD_USAGE_TREND_CH_STORES_WIDGET,
  payload
});

/**
 * Action to load dashboard recent 10 jobs in progress widget
 * @param data
 * @returns {{type, payload: *}}
 */
export const loadRecentTenJobsInProgressWidget = payload => ({
  type: LOAD_RECENT_JOBS_IN_PROGRESS_WIDGET,
  payload
});
/**
 * Action to get dashboard summary tiles
 * @returns {{type, data: *}}
 */
export const getDashboardSummary = () => ({
  type: GET_DASHBOARD_SUMMARY
});

/**
 * To update dashboard summary tiles in state
 * @param data
 * @returns {{type, data: *}}
 */
export const updateDashboardSummary = data => ({
  type: UPDATE_DASHBOARD_SUMMARY,
  data
});

/**
 * To CLEAR widgets data in state
 */
export const clearWidgetData = () => ({
  type: CLEAR_WIDGET_DATA
});

export const cancelRecentJobById = (data, actionType) => ({
  type: CANCEL_RECENT_JOB_BY_ID,
  data,
  actionType
});

export const deleteRecentJobById = (data, actionType) => ({
  type: DELETE_RECENT_JOB_BY_ID,
  data,
  actionType
});

export const updateSelectedDropdownWidget = (chartType, data) => ({
  type: UPDATE_SELECTED_FILTER_OPTIONS_WIDGET,
  chartType,
  data
});

export const clearSelectedDropdownWidget = () => ({
  type: CLEAR_SELECTED_FILTER_OPTIONS_WIDGET
});

export const setFromMonitorPage = data => ({
  type: SET_FROM_MONITOR_PAGE,
  data
});
