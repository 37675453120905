import {
  UNC_OR_NFS_PATH_SAVE_TO_LIST_SUCCESS,
  UNC_OR_NFS_PATH_SAVE_TO_LIST_ERROR,
  UNC_OR_NFS_DELETE_PATH,
  UNC_OR_NFS_CLEAR_PATH_LIST,
  UNC_OR_NFS_CLEAR_PATH_LIST_ERROR
} from "../../actions/actionTypes";
import { combineReducers } from "redux";

/**
 * Errors occured during Connect API call
 * @param {*} state
 * @param {*} action
 */
const connectErrors = (state = null, { type, data }) => {
  switch (type) {
    case UNC_OR_NFS_PATH_SAVE_TO_LIST_ERROR:
      return data;
    case UNC_OR_NFS_CLEAR_PATH_LIST_ERROR:
      return null;
    default:
      return state;
  }
};

/**
 * If Connect API is success, then we are adding the fields
 * to connected list of servers to be added to sources
 * @param {*} state
 * @param {*} action
 */
const savedList = (state = [], { type, data, source }) => {
  switch (type) {
    case UNC_OR_NFS_PATH_SAVE_TO_LIST_SUCCESS:
      return [...state, data];
    case UNC_OR_NFS_DELETE_PATH:
      const newSavedList = state.filter(
        obj => obj.uniqueId !== source.uniqueId
      );
      return newSavedList;
    case UNC_OR_NFS_CLEAR_PATH_LIST:
      return [];
    default:
      return state;
  }
};

export default combineReducers({ connectErrors, savedList });
