import {
  LOGIN_REQUESTING,
  FETCH_ACCESS_TOKEN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_JWT_TOKEN,
  UNSET_JWT_TOKEN,
  SET_OCTA_TOKEN,
  LOG_OUT,
  UNSET_LOG_OUT,
  TRIGGER_LOG_OUT,
  SHOW_USER_DETAILS,
  SHOW_USER_PROFILE_DETAILS,
  UPDATED_USER_CONTACT_INFO,
  SET_LOGIN_LOADER_OPACITY,
  SET_LOGIN_LOADER_HEIGHT,
  DISMISS_ALERT,
  USER_PHOTO_DELETED,
  USER_PHOTO_UPDATED,
  ERROR_MESSAGE,
  CREATE_PASSWORD,
  ACTIVATE_ACCOUNT,
  ENROLL_PERSONAL_DETAILS,
  RESEND_ACTIVATION_EMAIL,
  ACTIVATION_SUCCESSFUL,
  ACTIVATION_FAILED,
  CLEAR_ACTIVATION_ERROR,
  GET_BRAND_DETAILS_ON_LOGIN,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_MESSAGE_CLEAR,
  FETCH_ENTITLEMENTS_PRODUCTS_REQUEST,
  FETCH_ENTITLEMENTS_PRODUCTS_SUCCESS,
  FETCH_ENTITLEMENTS_PRODUCTS_FAILURE,
  FETCH_USER_LANGUAGE,
  FETCH_USER_LANGUAGE_SUCCESS,
  FETCH_USER_LANGUAGE_ERROR,
  UPDATE_PRODUCTS,
  TWO_FACTOR_VERIFY_PASSWORD,
  PASSWORD_VALIDATED,
  TWO_FACTOR_CHECK,
  TWO_FACTOR_ENABLED,
  TWO_FACTOR_GENERATE_CODES,
  TWO_FACTOR_CODES,
  TWO_FACTOR_TOGGLE,
  TWO_FACTOR_STORE_SECRET,
  TWO_FACTOR_GET_SECRET,
  GET_ORGANIZATION,
  STORE_ORGANIZATION,
  STORE_TEMP,
  FETCH_ALL_HELP_LINK_REQUEST,
  FETCH_ALL_HELP_LINK_SUCCESS,
  GET_TWO_FACTOR_SECRET
} from "../actions/actionTypes";

export function loginRequest(payload) {
  return { type: LOGIN_REQUESTING, payload };
}

export function fetchAccessToken({ history }) {
  return { type: FETCH_ACCESS_TOKEN, history };
}

export function logOut() {
  return { type: LOG_OUT };
}

export function unsetOctaLogout() {
  return { type: UNSET_LOG_OUT };
}
export function logoutRequest() {
  return {
    type: LOG_OUT
  };
}

export function triggerLogout() {
  return {
    type: TRIGGER_LOG_OUT
  };
}
export function setJWTToken(payload) {
  return {
    type: SET_JWT_TOKEN,
    payload
  };
}
export function setOCTAToken(payload) {
  return {
    type: SET_OCTA_TOKEN,
    payload
  };
}

export function unsetJWTToken() {
  return { type: UNSET_JWT_TOKEN };
}

export function loginSuccess() {
  return { type: LOGIN_SUCCESS };
}

export function fetchUserLanguage() {
  return { type: FETCH_USER_LANGUAGE };
}

export function fetchUserLanguageSuccess(payload) {
  return { type: FETCH_USER_LANGUAGE_SUCCESS, payload };
}

export function fetchUserLanguageFailure() {
  return { type: FETCH_USER_LANGUAGE_ERROR };
}

export function loginError(error) {
  return { type: LOGIN_ERROR, error };
}

export const setLoginLoaderHeight = height => ({
  type: SET_LOGIN_LOADER_HEIGHT,
  height
});

export const setLoginLoaderOpacity = opacity => ({
  type: SET_LOGIN_LOADER_OPACITY,
  opacity
});

export const dismissAlert = data => ({
  type: DISMISS_ALERT,
  data
});

export const userPhotoDeleted = () => ({
  type: USER_PHOTO_DELETED
});

export const userPhotoUpdated = data => ({
  type: USER_PHOTO_UPDATED,
  data
});

export const errorMessage = data => ({
  type: ERROR_MESSAGE,
  data
});

export const showUserDetails = data => ({
  type: SHOW_USER_DETAILS,
  data
});

export const showUserProfileDetails = data => ({
  type: SHOW_USER_PROFILE_DETAILS,
  data
});

export const updatedUserContactInformation = data => ({
  type: UPDATED_USER_CONTACT_INFO,
  data
});
//Set Password for new User post User Verification
export const createPasswordForNewUser = data => ({
  type: CREATE_PASSWORD,
  data
});
//Activate account/Customer enrollment.
export const activateAccount = () => ({
  type: ACTIVATE_ACCOUNT
});
//Enroll personal Details.
export const enrollPersonalDetails = data => ({
  type: ENROLL_PERSONAL_DETAILS,
  data
});
//resend activation email.
export const resendActivationEmail = data => ({
  type: RESEND_ACTIVATION_EMAIL,
  data
});
//Next Page
export const activationSuccessful = data => ({
  type: ACTIVATION_SUCCESSFUL,
  data
});

//clear activation error
export const clearActivationError = data => ({
  type: CLEAR_ACTIVATION_ERROR,
  data
});

export const activationFailed = data => ({
  type: ACTIVATION_FAILED,
  data
});

export const getBrandingOnLogin = portalURL => ({
  type: GET_BRAND_DETAILS_ON_LOGIN,
  portalURL
});

export const forgotPassword = data => ({
  type: FORGOT_PASSWORD,
  data
});

export const clearForgotPassword = () => ({
  type: FORGOT_PASSWORD_MESSAGE_CLEAR
});

export const fetchEntitlementsProducts = organizationId => ({
  type: FETCH_ENTITLEMENTS_PRODUCTS_REQUEST,
  payload: { organizationId }
});

export const fetchEntitlementsProductsSuccess = entitlements => ({
  type: FETCH_ENTITLEMENTS_PRODUCTS_SUCCESS,
  payload: { entitlements }
});

export const fetchEntitlementsProductsFailure = () => ({
  type: FETCH_ENTITLEMENTS_PRODUCTS_FAILURE,
  payload: {}
});

export const updateProducts = data => ({
  type: UPDATE_PRODUCTS,
  data
});

export const twoFactorVerifyPassword = data => ({
  type: TWO_FACTOR_VERIFY_PASSWORD,
  data
});

export const passwordValidated = data => ({
  type: PASSWORD_VALIDATED,
  data
});

export const twoFactorCheck = data => ({
  type: TWO_FACTOR_CHECK,
  data
});

export const twoFactorEnabled = data => ({
  type: TWO_FACTOR_ENABLED,
  data
});

export const twoFactorGenerateCodes = data => ({
  type: TWO_FACTOR_GENERATE_CODES,
  data
});

export const twoFactorCodes = data => ({
  type: TWO_FACTOR_CODES,
  data
});

export const twoFactorToggle = data => ({
  type: TWO_FACTOR_TOGGLE,
  data
});

export const getTwoFactorSecret = () => ({
  type: GET_TWO_FACTOR_SECRET
});

export const twoFactorStoreSecret = data => ({
  type: TWO_FACTOR_STORE_SECRET,
  data
});

export const twoFactorGetSecret = () => ({
  type: TWO_FACTOR_GET_SECRET
});

export const getOrganization = (id, impersonation) => ({
  type: GET_ORGANIZATION,
  id,
  impersonation
});

export const storeOrganization = (data, isImpersonate) => ({
  type: STORE_ORGANIZATION,
  data,
  isImpersonate
});

export const storeTemp = data => ({
  type: STORE_TEMP,
  data
});

export const fetchAllHelpLink = () => ({
  type: FETCH_ALL_HELP_LINK_REQUEST,
  payload: {}
});

export const fetchedAllHelpLinkSuccess = links => ({
  type: FETCH_ALL_HELP_LINK_SUCCESS,
  payload: { links }
});
