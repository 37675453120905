/* eslint-disable import/prefer-default-export */
import * as util from "utils/SpogDataGridUtil";
import * as Constants from "utils/appConstants";
import moment from "moment";

export const deploySettingsFormMap = state => {
  if (state && state.settings && state.settings.deploymentConfigResp) {
    let {
      install_path,
      port,
      protocol,
      install_driver = false
    } = state.settings.deploymentConfigResp;
    return { install_path, port, protocol, install_driver };
  }
  return {};
};

export const deployConfig = state =>
  state && state.settings && state.settings.deploymentConfigResp;

function toLocal(timestring) {
  let localTime = moment
    .utc(timestring, "HH:mm")
    .local()
    .format("HH:mm");
  return localTime;
}

export const sourceDiscoveryFormMap = state => {
  if (state && state.settings && state.settings.sourceDiscoverySettingsResp) {
    let {
      discovery_repeat_method,
      discovery_day_interval,
      discovery_hour_interval,
      discovery_days_of_week,
      discovery_schedule_time,
      discovery_day_of_month,
      discovery_status
    } = state.settings.sourceDiscoverySettingsResp;
    discovery_schedule_time = toLocal(discovery_schedule_time);
    let obj = {
      sourceDiscovery: discovery_status,
      repeatMethod: {
        key: discovery_repeat_method,
        value: discovery_repeat_method
      },
      hour: discovery_schedule_time.split(":")[0],
      minute: discovery_schedule_time.split(":")[1]
    };
    switch (discovery_repeat_method) {
      case "everyNumberOfDays":
        obj.every = discovery_day_interval;
        obj.everyDaysOfWeek = "0";
        break;
      case "everyNumberOfHours":
        obj.every = discovery_hour_interval;
        obj.everyDaysOfWeek = "0";
        break;
      case "everySelectedDaysOfWeek":
        obj.every = "";
        // API accepts only 1 to 7 whereas
        // component only works on 0 to 6
        obj.everyDaysOfWeek = discovery_days_of_week
          .split(",")
          .map(c => (isNaN(parseInt(c)) ? 0 : parseInt(c) - 1))
          .join(",");
        break;
      case "everySelectedDayOfMonth":
        obj.every = discovery_day_of_month;
        obj.everyDaysOfWeek = "0";
        break;
      default:
        break;
    }
    return obj;
  } else {
    return {
      sourceDiscovery: "disable",
      repeatMethod: { key: "everyNumberOfDays", value: "everyNumberOfDays" },
      hour: "00",
      minute: "00",
      everyDaysOfWeek: "0"
    };
  }
};

export const sourceDiscoveryList = state =>
  (state.settings && state.settings.sourceDiscoveryList) || [];

const prepareIconObject = status => {
  let iconClass = "";
  switch (status) {
    case Constants.AVAILABLE_STATUS.ACTIVE:
    case Constants.AVAILABLE_STATUS.FINISHED:
      iconClass = Constants.STATUS_ICONS.SUCCESS;
      break;
    case Constants.AVAILABLE_STATUS.IDLE:
    case Constants.AVAILABLE_STATUS.WAITING:
    case Constants.AVAILABLE_STATUS.POLICY_DEPLOYING:
    case Constants.AVAILABLE_STATUS.UNDEPLOYING_POLICY:
      iconClass = Constants.STATUS_ICONS.WARNING;
      break;
    default:
      iconClass = Constants.STATUS_ICONS.ERROR;
  }
  return status
    ? {
        text: "source_discovery.status." + status,
        iconClass
      }
    : Constants.STATUS_ICONS.ERROR;
};

export const sourceDiscoveryGridRows = state =>
  state.settings.sourceDiscoveryList.map(row => ({
    ...row,
    status: prepareIconObject(row.job_status),
    last_discovery_start_time:
      row.last_discovery_start_time !== 0
        ? util.getLocaleDate(row.last_discovery_start_time, "lll")
        : "-",
    last_discovery_end_time:
      row.last_discovery_end_time !== 0
        ? util.getLocaleDate(row.last_discovery_end_time, "lll")
        : "-",
    site: row.site.site_name,
    existing_source_number: row.existing_node_count
  }));
