import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import OrgSearch from "./components/OrgSearch";

export const renderInputField = ({
  id,
  input,
  type,
  label,
  placeholder,
  disabled,
  meta: { visited, error }
}) => (
  <label className="rf-container" htmlFor={id}>
    <div
      className={classNames("rf-label", {
        "rf-label--error": visited && error
      })}
    >
      {label}
    </div>
    <div className="rf-children">
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />
    </div>
  </label>
);

renderInputField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    visited: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool
};

renderInputField.defaultProps = {
  placeholder: "",
  disabled: false
};

export const renderSelectField = ({
  id,
  label,
  input,
  meta: { visited, error },
  children
}) => (
  <label className="rf-container" htmlFor={id}>
    <div
      className={classNames("rf-label", {
        "rf-label--error": visited && error
      })}
    >
      {label}
    </div>
    <div className="rf-children">
      <select {...input}>{children}</select>
    </div>
  </label>
);

renderSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired
};

export const renderCheckboxField = ({
  id,
  input,
  label,
  disabled = false,
  meta: { visited, error }
}) => (
  <div className="rf-container">
    <label
      htmlFor={id}
      className={classNames("rf-label", {
        "rf-label--error": visited && error
      })}
    >
      <input
        {...input}
        id={id}
        type="checkbox"
        checked={input.value}
        disabled={disabled}
      />{" "}
      {label}
    </label>
  </div>
);

renderCheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool
};

renderCheckboxField.defaultProps = {
  disabled: false
};

export const renderRadioField = ({
  id,
  input,
  label,
  meta: { visited, error }
}) => {
  return (
    <div className="rf-container">
      <label
        htmlFor={id}
        className={classNames("rf-label", {
          "rf-label--error": visited && error
        })}
      >
        <input
          {...input}
          id={id}
          type="radio"
          style={{ opacity: 1, position: "relative" }}
        />
        {label}
      </label>
    </div>
  );
};

renderRadioField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired
};

export const renderOrganizationSelectionField = OrgSearch;
