import React, { useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";
import { getTextWidth } from "utils";

import { CHART_COLORS, ROTATE_ANGLE_X } from "utils/appConstants";

import { withCCGraph } from "components/common/CCGraph/HOCs";
import * as sGraph from "state/selectors/ccGraph";

import {
  lookupResolutionXaxisFormat,
  setIntervalTicks
} from "components/common/CCGraph/utils";

import Wrapper from "./RechartWrapper";
import InsufficientData from "./InsufficientData";
import {
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  AreaChart,
  Area,
  Label
} from "recharts";
import TooltipComponent from "./TooltipComponent";

const loadingState = (width, height) => (
  <svg
    className="load-state stacked-area-load-state"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 711 325"
    width={width}
    height={height}
  >
    <defs>
      <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0" className="loading-charts" stopOpacity="0.8" />
        <stop offset="33.33%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="50%" className="loading-charts" stopOpacity="0" />
        <stop offset="66.66%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="100%" className="loading-charts" stopOpacity="0.8" />
      </linearGradient>
    </defs>
    <path
      id="chart-bg"
      className="chart-bg"
      style={{ stroke: "#C3C6CF" }}
      d="M3.4,216.5h707.3 M3.4,160.5h707.3 M3.3,103.5h707.3 M3.3,48.5h707.6 M0.4,276.6H710H0.4z"
    />
    <g transform="translate(20 50)">
      <path
        id="chart-area"
        strokeLinecap="square"
        d="M0.34233103,0.593688165 L709.977885,0.593688189"
        transform="translate(.01 227.976)"
      />
      <path
        fill="#C3C6CF"
        d="M0.528124801,224.014648 L0.528124801,177.734375 L53.3834796,177.734375 C71.5390789,177.734375 86.8277373,168.972754 101.240241,151.662202 C112.578335,138.044258 121.139826,123.110227 136.974507,91.596773 C137.343842,90.8617404 139.300293,86.9654028 139.856735,85.8583549 C155.041692,55.6476711 163.354313,41.0906306 174.319873,27.7179171 C188.951312,9.87459412 204.885845,0.5 223.830634,0.5 C242.123071,0.5 257.291724,8.27027858 270.907992,23.1359289 C281.228683,34.4036118 289.135925,47.1272372 302.542017,72.085092 C303.275893,73.4513345 306.289669,79.0766612 307.063369,80.5168656 C321.41025,107.222876 330.088083,120.97663 341.470704,132.92446 C355.88994,148.05969 371.908861,155.792969 391.654853,155.792969 C412.142049,155.792969 428.763593,152.325614 442.880698,145.765582 C454.197328,140.506893 463.373931,133.679865 473.786035,123.626931 C476.528659,120.978915 486.44777,110.911455 488.791866,108.6483 C502.907223,95.0203436 514.194325,88.9355469 530.135322,88.9355469 C546.532652,88.9355469 559.505909,97.338499 575.973261,115.41103 C579.723508,119.526837 593.103621,135.086814 592.915496,134.871799 C605.09738,148.794859 614.368835,157.635549 625.072091,164.58539 C638.386599,173.230769 652.701021,177.734375 669.279853,177.734375 L673.779853,177.734375 L673.779853,224.014648 L0.528124801,224.014648 Z"
      />
    </g>
    <rect
      className="chart-filter"
      fill="url(#lgrad)"
      x="-100%"
      y="0"
      width="300%"
      height="100%"
    ></rect>
  </svg>
);

const TSArea = props => {
  let wrapperRef = useRef();
  moment.locale(props.intl.locale);
  const CustomizedTick = tickProps => {
    const { x, y, payload } = tickProps;
    if (!payload) {
      console.log("error");
      return null;
    }

    return (
      <g transform={`translate(${x},${y}) rotate(22)`} height={"100%"}>
        <text
          x={-5}
          y={7}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          fontSize={11}
          textAnchor="start"
          fill="#8f8f8f"
        >
          {lookupResolutionXaxisFormat(
            props.resolution,
            props.intl.locale,
            payload.value
          )}
        </text>
      </g>
    );
  };
  const CustomizedLabel = props => {
    const { chartProps } = props;
    return (
      <g transform={`rotate(-90)`}>
        <text
          x={-220}
          y={30}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          fontSize={16}
          textAnchor="start"
          fill="#adb0b6"
        >
          {chartProps.intl.formatMessage({
            id: chartProps.yAxisLabelId
          })}
        </text>
      </g>
    );
  };

  const UsageAreaChart = () => {
    if (!wrapperRef || !wrapperRef.current) return null;
    const { width, height } = getComputedStyle(wrapperRef.current);
    let myWidth = parseInt(width, 10) > 0 ? parseInt(width, 10) : 0;
    let myHeight = parseInt(height, 10) > 0 ? parseInt(height, 10) : 0;
    myWidth =
      myWidth === 0
        ? parseInt(
            (props.windowWidth ? props.windowWidth : window.innerWidth) *
              (props.widthAdjust ? props.widthAdjust : 1),
            10
          )
        : myWidth;
    myHeight =
      myHeight <= 90
        ? parseInt(
            (props.windowHeight ? props.windowHeight : window.innerHeight) *
              (props.heightAdjust ? props.heightAdjust : 1),
            10
          )
        : myHeight;
    if (props.isLoading && props.data.length === 0) {
      return loadingState(myWidth, myHeight);
    }
    let xTicks =
      props.filters &&
      props.filters.range === "custom" &&
      props.resolution !== "hour"
        ? moment(props.filters.end_ts * 1000).diff(
            moment(props.filters.start_ts * 1000),
            "days"
          )
        : props.originalData[0].metric.length;
    xTicks = xTicks > 12 ? 12 : xTicks;
    return (
      <AreaChart
        width={myWidth}
        height={myHeight}
        data={props.data}
        margin={{
          top: 20,
          left: 10,
          right:
            Math.abs(
              getTextWidth("DD/MM/YYYY", `11px ${"Sans Source Pro"}`) *
                Math.cos(Math.radians(22))
            ) + 10
        }}
      >
        <XAxis
          textAnchor={"start"}
          interval={setIntervalTicks(props.data.length, xTicks)}
          angle={22}
          tick={<CustomizedTick />}
          domain={["auto", "auto"]}
          dataKey="date"
          height={
            Math.abs(
              getTextWidth("DD/MM/YYYY", `11px ${"Sans Source Pro"}`) *
                Math.sin(Math.radians(22))
            ) + 12
          }
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
        />
        <YAxis
          fill="#8f8f8f"
          axisLine={false}
          fontSize={11}
          tick={{ fill: "#8f8f8f" }}
          fontFamily={"Sans Source Pro"}
          fontWeight={400}
          scale={"linear"}
        >
          {props.yAxisLabelId && (
            <Label content={<CustomizedLabel chartProps={props} />} />
          )}
        </YAxis>
        {props.customSvgConfig && props.customSvgConfig.type === "Line" && (
          <ReferenceLine
            y={props.customSvgConfig.value}
            isFront={true}
            alwaysShow={true}
            className="area-chart-refline"
            label={{
              value: props.customSvgConfig.value + " GB",
              position: "top"
            }}
            strokeDasharray="3 3"
          />
        )}
        <Tooltip
          chartConfig={props.config}
          myWidth={myWidth}
          locale={props.intl.locale}
          order={props.order}
          cursor={{ fill: "transparent" }}
          content={<TooltipComponent />}
          allowEscapeViewBox={{ x: true }}
          isAnimationActive={false}
        />
        {props.order &&
          props.order.length > 0 &&
          props.order.map((ele, i) => (
            <Area
              isAnimationActive={false}
              type="linear"
              dataKey={ele}
              stackId="name"
              stroke={
                (props.colorSchema && props.colorSchema[i]) || CHART_COLORS[i]
              }
              fill={
                (props.colorSchema && props.colorSchema[i]) || CHART_COLORS[i]
              }
            />
          ))}
        {!props.order.length > 0 &&
          props.names &&
          props.names.map((ele, i) => (
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey={ele}
              key={i}
              stackId="name"
              stroke={
                (props.colorSchema && props.colorSchema[i]) || CHART_COLORS[i]
              }
              fill={
                (props.colorSchema && props.colorSchema[i]) || CHART_COLORS[i]
              }
            />
          ))}
      </AreaChart>
    );
  };
  return (
    <>
      {!props.isLoading && props.data.length < 2 ? (
        <InsufficientData />
      ) : (
        <Wrapper wrapperRef={wrapperRef}>
          <UsageAreaChart props={props} />
        </Wrapper>
      )}
    </>
  );
};

TSArea.propTypes = {
  intl: PropTypes.shape().isRequired,
  order: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  resolution: PropTypes.string,
  originalData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  colorSchema: PropTypes.arrayOf(PropTypes.string),
  rotateAngleX: PropTypes.number,
  yAxisLabelId: PropTypes.string,
  valueFormatter: PropTypes.func,
  filters: PropTypes.shape()
};

TSArea.defaultProps = {
  colorSchema: null,
  rotateAngleX: ROTATE_ANGLE_X,
  yAxisLabelId: null,
  valueFormatter: null,
  order: []
};

const makeMapState = (
  outerState,
  { ccGraph: { config: outerConfig }, intl }
) => {
  const getData = sGraph.makeGetTSData(outerConfig.ccGraphId);
  const getTSAreaData = sGraph.makeGetRCAreaData(
    outerConfig.ccGraphId,
    intl.locale
  );
  return (state, { ccGraph: { config } }) => {
    return {
      isLoading: sGraph.isLoading(config.ccGraphId)(state),
      resolution: sGraph.getResolution(config.ccGraphId)(state),
      filters: sGraph.getFilters(config.ccGraphId)(state),
      originalData: getData(state),
      data: getTSAreaData(state).data,
      names: getTSAreaData(state).names,
      config: outerConfig
    };
  };
};

export default compose(injectIntl, withCCGraph, connect(makeMapState))(TSArea);
