import {
  FETCH_DOWNLOADS_ERROR,
  FETCH_DOWNLOADS,
  FETCH_DOWNLOADS_SUCCESS
} from "./actionTypes";

export const fetchDownloads = () => ({ type: FETCH_DOWNLOADS });
export const fetchDownloadsSuccess = downloadsArray => ({
  type: FETCH_DOWNLOADS_SUCCESS,
  downloadsArray
});
export const fetchDownloadsError = () => ({ type: FETCH_DOWNLOADS_ERROR });
