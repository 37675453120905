import { combineReducers } from "redux";
import { SET_CURRENT_SHARED_FOLDER } from "state/actions/actionTypes";

function currentSharedFolder(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_SHARED_FOLDER:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  currentSharedFolder
});
