import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Holdes constants definitions for Sources
 */
export const recoveredResourcesAttributes = {
  ID: "recoveredresource_id",
  TYPE: "type",
  NAME: "recoveredresource_name",
  STATE: "state",
  PROTECTION: "protection_status",
  CONNECTION: "connection_status",
  SOURCE: "source",
  SOURCE_NAME: "source_name",
  RECOVERY_POINT_TS: "recoverypoint_ts",
  POLICY: "policy",
  IP_ADDRESS: "ip_address",
  ALLOCATED_RAM: "allocated_ram",
  ACTIVATED_ON: "provision_ts",
  ACTIONS: "available_actions",
  ALLOCATED_CPU: "allocated_vcpu",
  OS: "operating_system",
  FAILBACK: "failback",
  SITE_NAME: "site_name",
  HYPERVISOR_NAME: "hypervisor_name",
  SOURCE_VM: "source_vm",
  TARGET_VM: "target_vm",
  POLICY_NAME: "policy_name"
};

export const failbackStatus = {
  CANCELLED: "CANCELLED",
  IN_PROGRESS: "IN_PROGRESS"
};

export const failbackState = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING"
};

export const recoveredResourcesConstants = {
  /**
   * Contextual actions for Recovered VMS
   */
  CONTEXTUAL_ACTION: {
    DEPROVISION: "deprovision",
    GRACEFULSTOP: "gracefulstop",
    TARGET_VM_CONFIGURATION: "target_vm_configuration",
    HARDSTOP: "hardstop",
    START: "start",
    RESTART: "restart",
    PROVISION: "provision",
    DELETE: "delete",
    FAILBACK_START: "failback_start",
    FAILBACK_STOP: "failback_stop",
    FAILBACK_FINAL_STEP: "failback_final_step"
  },
  STATE: {
    MISSING: "missing"
  }
};

//Recovered vms action mapping
export const RECOVERED_VMS_ACTION = {
  gracefulstop: "Gracefully Stopping",
  hardstop: "Hard Stopping",
  restart: "Restarting",
  start: "Starting",
  deprovision: "Deprovisioning",
  provision: "Provisioning",
  delete: "Deleting",
  failback_stop: "Failback Stopped for",
  failback_start: "Failback Started for",
  failback_final_step: "Started final failback step for"
};

export const START_FAILBACK_NUMBER_STEP = 3;
/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "protect.RecoveredResources.".concat(identifier);
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_VIEW_DETAILS : On click of cell the details will show
 * @type {{CLICKABLE_CELL_TO_VIEW_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: recoveredResourcesAttributes.NAME,
  CLICKABLE_CELL_TO_VIEW_SOURCE: recoveredResourcesAttributes.SOURCE,
  CLICKABLE_CELL_TO_VIEW_POLICY: recoveredResourcesAttributes.POLICY
};

/**
 * Column definition for the RecoveredResources table
 */
export const COLUMN_DEFS = [
  {
    accessor: recoveredResourcesAttributes.NAME,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: recoveredResourcesAttributes.FAILBACK,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.FAILBACK
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: recoveredResourcesAttributes.SOURCE,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.SOURCE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: recoveredResourcesAttributes.TYPE,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: recoveredResourcesAttributes.STATE,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.STATE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.STATE_LOADER_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: recoveredResourcesAttributes.OS,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.OS),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.RECOVERY_POINT_TS,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.RECOVERY_POINT_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.ACTIVATED_ON,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ACTIVATED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.POLICY,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: recoveredResourcesAttributes.IP_ADDRESS,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.IP_ADDRESS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.ALLOCATED_RAM,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ALLOCATED_RAM
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.ALLOCATED_CPU,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ALLOCATED_CPU
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  }
];

export const DISASTER_RECOVERY_GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: recoveredResourcesAttributes.SOURCE_NAME,
  CLICKABLE_CELL_TO_TARGET_VM: recoveredResourcesAttributes.TARGET_VM,
  CLICKABLE_CELL_TO_VIEW_POLICY: recoveredResourcesAttributes.POLICY_NAME
};

/**
 * Column definition for the RecoveredResources table
 */
export const RECOVERED_RESOURCES_COLUMN_DEFS = [
  {
    accessor: recoveredResourcesAttributes.SOURCE_NAME,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.SOURCE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.SOURCE_VM,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.SOURCE_VM
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.STATE,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.STATE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.STATE_LOADER_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.RECOVERY_POINT_TS,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.RECOVERY_POINT_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.TARGET_VM,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.TARGET_VM
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.OS,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.OS),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.ACTIVATED_ON,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ACTIVATED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.TYPE,
    displayIdentifier: getDisplayIdentifier(recoveredResourcesAttributes.TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.HYPERVISOR_NAME,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.HYPERVISOR_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.IP_ADDRESS,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.IP_ADDRESS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.POLICY_NAME,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.POLICY_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.ALLOCATED_RAM,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ALLOCATED_RAM
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: recoveredResourcesAttributes.SITE_NAME,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.SITE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: recoveredResourcesAttributes.ALLOCATED_CPU,
    displayIdentifier: getDisplayIdentifier(
      recoveredResourcesAttributes.ALLOCATED_CPU
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  }
];

export const RECOVERED_RESOURCES_STATE = {
  STARTED: "power_on",
  STOPPED: "power_off",
  ERROR: "error",
  PROVISIONING_FAILED: "provisioning_failed",
  DEPROVISIONED: "deprovisioned"
};

export const RECOVERED_RESOURCES_STATE_TO_PROPERTY_MAP = {
  [RECOVERED_RESOURCES_STATE.STARTED]:
    "protect.RecoveredResources.state.power_on",
  [RECOVERED_RESOURCES_STATE.STOPPED]:
    "protect.RecoveredResources.state.power_off",
  [RECOVERED_RESOURCES_STATE.ERROR]: "error",
  [RECOVERED_RESOURCES_STATE.PROVISIONING_FAILED]: "provisioning_failed",
  [RECOVERED_RESOURCES_STATE.DEPROVISIONED]: "deprovisioned"
};

export const TARGET_VM_NAVIGATION_MAP = {
  "Virtual Standby": "virtual_standbys",
  "Instant VM": "instant_vms_rr",
  "Cloud Direct DraaS": "recovered_vms"
};
