import * as C from "state/constants";

const initialState = {
  isLoading: false,
  organizations: {
    data: [],
    pagination: {}
  }
};

const organizationSearch = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_ORGANIZATION_SEARCH_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case C.FETCH_ORGANIZATION_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        organizations: action.payload
      });
    case C.FETCH_ORGANIZATION_SEARCH_FAILURE:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default organizationSearch;
