import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Contextual actions for recovery points
 */
export const CONTEXTUAL_ACTION = {
  CREATE_VM: "Create an Instant VM",
  MOUNTPOINT: "mountpoint"
};

/**
 * Types for cloud direct recovery points column type
 */
export const CD_RECOVERY_POINTS_COLUMN_TYPE = {
  RECOVERY_POINT_CV: "recovery Points CD",
  RECOVERY_POINT_SOURCES_CV: "recovery Points Sources CD"
};

/**
 * Types for cloud hybrid recovery points column type
 */
export const CH_RECOVERY_POINTS_COLUMN_TYPE = {
  RECOVERY_POINT_CH: "recovery Points CH",
  RECOVERY_POINT_SOURCES_CH: "recovery Points Sources CH"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "protect.destination.recovery.".concat(identifier);
};

/**
 * Holds constants definitions for Recovery Points
 */
export const SourcesRecoveryAttributesCloudHybrid = {
  RECOVERY_NONE_ACTION: "none_action",
  RECOVERY_SOURCE: "recoverypoint_name", //"source.source_name",
  RECOVERY_STATUS: "status",
  RECOVERY_CREATEDON: "created_on",
  RECOVERY_PROTECTION_POLICY: "policy.policy_name",
  RECOVERY_ACTIONS: "allowed_actions",
  RECOVERY_RETAINED: "retained",
  RECOVERY_CONTENTS: "contents",
  RECOVERY_DESTINATION: "destination.destination_name",
  RECOVERY_DATA_SIZE: "source_data_size"
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_CHANGE_STATUS : On click of cell the status will change
 * @type {{CLICKABLE_CELL_CHANGE_STATUS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_CHANGE_STATUS:
    SourcesRecoveryAttributesCloudHybrid.RECOVERY_STATUS
};

/**
 * Column definition for the Destination table
 */
export const COLUMN_DEFS_FOR_SOURCE_CH = [
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_STATUS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: { width: 50 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_CREATEDON,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_CREATEDON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_DESTINATION,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_DESTINATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_DATA_SIZE,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_DATA_SIZE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_CONTENTS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_CONTENTS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_SOURCE,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_SOURCE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_PROTECTION_POLICY,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_PROTECTION_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_RETAINED,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_RETAINED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 100 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudHybrid.RECOVERY_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudHybrid.RECOVERY_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { className: "no-ellipsis" }
  }
];

/**
 * Holds constants definitions for Recovery Points
 */
export const SourcesRecoveryAttributesCloudDirect = {
  RECOVERY_NONE_ACTION: "none_action",
  RECOVERY_SOURCE: "source.name",
  RECOVERY_STATUS: "status",
  RECOVERY_CREATEDON: "create_ts",
  RECOVERY_PROTECTION_POLICY: "policy.name",
  RECOVERY_ACTIONS: "available_actions",
  RECOVERY_RETAINED: "retained",
  RECOVERY_CONTENTS: "contents",
  RECOVERY_DESTINATION: "destination.name",
  RECOVERY_DATA_SIZE: "dataSize"
};

/**
 * Column definition for the Destination table
 */
export const COLUMN_DEFS_FOR_SOURCE_CD = [
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_STATUS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: { width: 50 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_CREATEDON,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_CREATEDON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: { width: 180 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_DESTINATION,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_DESTINATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_DATA_SIZE,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_DATA_SIZE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_CONTENTS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_CONTENTS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_SOURCE,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_SOURCE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_PROTECTION_POLICY,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_PROTECTION_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_RETAINED,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_RETAINED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 100 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { className: "no-ellipsis" }
  }
];

/**
 * Column definition for the Destination table
 */
export const SUBCOMPONENT_COLUMN_DEFS_CD = [
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_NONE_ACTION,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_NONE_ACTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 20 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_STATUS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: { width: 50 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_CREATEDON,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_CREATEDON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: { width: 180 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_DESTINATION,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_DESTINATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_DATA_SIZE,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_DATA_SIZE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_CONTENTS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_CONTENTS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_SOURCE,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_SOURCE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_PROTECTION_POLICY,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_PROTECTION_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_RETAINED,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_RETAINED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 100 }
  },
  {
    accessor: SourcesRecoveryAttributesCloudDirect.RECOVERY_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      SourcesRecoveryAttributesCloudDirect.RECOVERY_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { className: "no-ellipsis" }
  }
];

/**
 * Holds constants definitions for Recovery Points
 */
export const RecoveryAttributesForDestinations = {
  RECOVERY_NONE_ACTION: "none_action",
  RECOVERY_SOURCE_UUID: "source_uuid",
  RECOVERY_COUNT: "recovery_count",
  RECOVERY_SOURCE: "source_name",
  RECOVERY_SOURCE_OBJECT_NAME: "source.source_name",
  RECOVERY_STATUS: "status",
  RECOVERY_CREATEDON: "created_on",
  RECOVERY_PROTECTION_POLICY: "policy.policy_name",
  RECOVERY_ACTIONS: "allowed_actions",
  RECOVERY_RETAINED: "retained",
  RECOVERY_CONTENTS: "name",
  RECOVERY_DESTINATION: "destination.destination_name",
  RECOVERY_DATA_SIZE: "source_data_size"
};

/**
 * Column definition for the Destination table
 */
export const COLUMN_DEFS_FOR_DESTINATION = [
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_NONE_ACTION,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_NONE_ACTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.EMPTY_TEXT_CELL_MUI },
    settings: { width: 50 }
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_STATUS,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: { width: 50 }
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_CREATEDON,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_CREATEDON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: { width: 200, resizable: false }
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_DESTINATION,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_DESTINATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_DATA_SIZE,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_DATA_SIZE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_CONTENTS,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_CONTENTS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_SOURCE,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_SOURCE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 400, resizable: false }
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_SOURCE_OBJECT_NAME,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_SOURCE_OBJECT_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_PROTECTION_POLICY,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_PROTECTION_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_RETAINED,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_RETAINED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 100 }
  },
  {
    accessor: RecoveryAttributesForDestinations.RECOVERY_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinations.RECOVERY_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { className: "no-ellipsis" }
  }
];

/**
 * Holds constants definitions for Recovery Points
 Cloud volume */
export const RecoveryAttributesForDestinationsCloudVolume = {
  RECOVERY_NONE_ACTION: "none_action",
  RECOVERY_SOURCE: "source.name",
  RECOVERY_STATUS: "status",
  RECOVERY_CREATEDON: "create_ts",
  RECOVERY_PROTECTION_POLICY: "policy.name",
  RECOVERY_ACTIONS: "available_actions",
  RECOVERY_RETAINED: "resolution",
  RECOVERY_CONTENTS: "contents",
  RECOVERY_DESTINATION: "destination.name",
  RECOVERY_DATA_SIZE: "source_data_size"
};

/**
 * Column definition for the Destination table Recovery Points Cloud volume
 */
export const COLUMN_DEFS_FOR_DESTINATION_CLOUD_VOLUME = [
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_NONE_ACTION,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_NONE_ACTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 20 }
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_STATUS,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: { width: 50 },
    showCursorPointer: true
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_CREATEDON,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_CREATEDON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_DESTINATION,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_DESTINATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_DATA_SIZE,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_DATA_SIZE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_CONTENTS,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_CONTENTS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_SOURCE,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_SOURCE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor:
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_PROTECTION_POLICY,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_PROTECTION_POLICY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_RETAINED,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_RETAINED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 100 }
  },
  {
    accessor: RecoveryAttributesForDestinationsCloudVolume.RECOVERY_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      RecoveryAttributesForDestinationsCloudVolume.RECOVERY_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { className: "no-ellipsis" }
  }
];
