import { select, all, call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { getData, postData } from "state/api/apiMethods";
import { FETCH_STORAGE_SITES } from "state/api/apiUrlConstants";
import {
  CDAPIS,
  CCDATAGRID_ID_SEARCH_FIELD,
  CCDATAGRID_ID,
  CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS
} from "utils/appConstants";
import { getLogin, getReducer, getFeatureFlag } from "state/selectors";
import uuidv4 from "uuid/v4";
import * as ccDataGridConstants from "state/constants/ccDataGrid";
import * as ccToast from "state/actions/ccToast";
import * as ccDataGridActions from "state/actions/ccDataGrid";
import { fromNavigationApi, getSavedSearchData } from "state/actions/actions";
import * as ccDataGridSelectors from "state/selectors/ccDataGrid";
import dataUserRoles from "../data";
import * as util from "../../../utils/SpogDataGridUtil";

import * as constant from "../../../utils/appConstants";
import {
  is_deleted,
  isDeletedApiConvert
} from "../../../components/common/advanced-search-mui/AdvanceSearchDataHelper";
import {
  VSB_FILTER_ATTRIBUTES,
  VSB_IDENTIFIERS
} from "../../../components/protect/recoveredResources/virtualStandby/virtualStandbyConstants";
import SourceConstants from "../../../components/protect/source/SourceConstants";
import { takeLatest } from "redux-saga";

// initialize
export function* initialize(action) {
  const {
    ccDataGridId,
    config: {
      persist,
      defaultPage,
      defaultPageSize,
      defaultSorted = [],
      columns = null,
      defaultAdvancedSearch = null,
      getDataFromSavedSearch = false,
      calledApiBeforeInit = false
    },
    defaultAdvancedSearchOptions = []
  } = action;

  try {
    if (
      ![
        CCDATAGRID_ID.TABLESPACES,
        CCDATAGRID_ID.DATAFILES,
        CCDATAGRID_ID.SELECT_RECOVERY_POINT
      ].includes(ccDataGridId)
    ) {
      // yield put(ccDataGridActions.fetchOptionsForTags(ccDataGridId, {}));
      // yield put(ccDataGridActions.resetSelectedSavedSearch(ccDataGridId));
      // yield put(ccDataGridActions.hideAdvancedSearchForm(ccDataGridId));
      yield put(ccDataGridActions.startCCDataGridLoader(ccDataGridId));
      // yield put(
      //   ccDataGridActions.resetAdvancedSearchOptionsConsidered(ccDataGridId)
      // );
      // yield put(ccDataGridActions.setSaveSearchText(ccDataGridId, ""));
      // yield put(
      //   ccDataGridActions.updatePageSize(ccDataGridId, defaultPageSize, false)
      // );
      // yield put(ccDataGridActions.updatePage(ccDataGridId, defaultPage, false));
      // if (defaultAdvancedSearchOptions) {
      const UI = yield select(
        ccDataGridSelectors.getAdvancedSearchOptions(ccDataGridId)
      );
      const optionsUnChanged = yield select(
        ccDataGridSelectors.getAdvancedSearchOptionsUnChanged(ccDataGridId)
      );
      // if (defaultAdvancedSearchOptions) {
      // let optionsToSet = isAdvancedSearchOptionsSelected ? (( UI && UI?.length >0) ? UI : defaultAdvancedSearchOptions) : ()
      // ( UI && UI?.length >0) ? UI : defaultAdvancedSearchOptions
      yield put(
        ccDataGridActions.fetchAdvancedSearchOptions(
          ccDataGridId,
          UI && UI?.length > 0
            ? UI
            : defaultAdvancedSearchOptions.length > 0
            ? defaultAdvancedSearchOptions
            : []
        )
      );
      const payload = {
        advancedSearchOptions: defaultAdvancedSearchOptions,
        filterType: ccDataGridId,
        init: true
      };
      if (getDataFromSavedSearch) yield put(getSavedSearchData(payload));
      yield put(
        ccDataGridActions.fetchAdvancedSearchOptionsUnChanged(
          ccDataGridId,
          optionsUnChanged && optionsUnChanged?.length > 0
            ? optionsUnChanged
            : defaultAdvancedSearchOptions.length > 0
            ? defaultAdvancedSearchOptions
            : []
        )
      );
      // }
    }

    if (!persist) {
      // update state without fetching
      yield put(ccDataGridActions.updatePage(ccDataGridId, defaultPage, false));

      yield put(
        ccDataGridActions.updatePageSize(ccDataGridId, defaultPageSize, false)
      );

      yield put(
        ccDataGridActions.updateSorted(ccDataGridId, defaultSorted, false)
      );

      if (columns) {
        yield put(
          ccDataGridActions.updateColumns(ccDataGridId, columns, false)
        );
      }

      if (defaultAdvancedSearch) {
        yield put(
          ccDataGridActions.advancedSearch(
            ccDataGridId,
            defaultAdvancedSearch,
            false
          )
        );
      }
    }

    // fetch
    if (!getDataFromSavedSearch && !calledApiBeforeInit) {
      yield put(ccDataGridActions.fetchData(action.ccDataGridId, true));
    }
  } catch (e) {
    window.console.log(e);
  }
}

// State hooks
export function* updateSorted(action) {
  try {
    if (action.refreshGrid) {
      yield put(ccDataGridActions.fetchData(action.ccDataGridId));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* updatePage(action) {
  try {
    if (action.refreshGrid) {
      yield put(ccDataGridActions.startCCDataGridLoader(action.ccDataGrid));
      yield put(ccDataGridActions.fetchData(action.ccDataGridId));
      yield put(ccDataGridActions.resetCCDataGridLoader(action.ccDataGrid));
    } else if (action.update) {
      yield put(
        ccDataGridActions.updatePageLocally(action.ccDataGridId, action.page)
      );
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* updatePageSize(action) {
  try {
    if (action.refreshGrid) {
      yield put(ccDataGridActions.fetchData(action.ccDataGridId));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateColumns(action) {
  try {
    if (action.refreshGrid) {
      yield put(ccDataGridActions.fetchData(action.ccDataGridId));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* advancedSearch(action) {
  try {
    if (action.refreshGrid) {
      yield put(ccDataGridActions.fetchData(action.ccDataGridId));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* clearAdvancedSearch(action) {
  try {
    if (action.refreshGrid) {
      yield put(ccDataGridActions.fetchData(action.ccDataGridId));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* removeSearchTagsReload(action) {
  try {
    yield put(ccDataGridActions.fetchData(action.ccDataGridId));
  } catch (e) {
    window.console.log(e);
  }
}

// fetch hooks

// only sort, pagination, and advanced search are supported
// todo: saved searches and column config
export function* fetchData(action) {
  try {
    const stateReducer = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const { token } = stateLogin;
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    const { ccDataGridId, init = false, polling = false } = action;
    const params = [];
    let isLocal = false;
    let expectedItems;
    const useApi = action?.additionalParameters?.useApi
      ? action.additionalParameters.useApi
      : false;
    // const { api, primaryKey, additionalParameters } = yield select(
    //   ccDataGridSelectors.getConfig(ccDataGridId)
    // );

    let {
      api,
      primaryKey,
      additionalParameters,
      pagination,
      localSearch = false,
      setFromNavigationAPI = undefined
    } = yield select(ccDataGridSelectors.getConfig(ccDataGridId));

    if (localSearch) {
      expectedItems = stateReducer.menu
        .find(ele => ele.key === "Protect")
        .subMenu.find(
          ele =>
            ele.key === ccDataGridId ||
            (ccDataGridId === "machine" && ele.key === "physical_machines")
        ).amount;
    }
    if (
      !api &&
      ![CCDATAGRID_ID.TABLESPACES, CCDATAGRID_ID.DATAFILES].includes(
        ccDataGridId
      )
    ) {
      return;
    }
    if (action.additionalParameters) {
      additionalParameters = action.additionalParameters;
      if (additionalParameters?.useApi) delete additionalParameters.useApi;
    }

    if (
      ccDataGridId === "sites" &&
      organizationId === "78506751-cc21-4989-a1f4-d7019df22a9f"
    ) {
      api = FETCH_STORAGE_SITES;
    }

    if (ccDataGridId === "source_configure") {
      api = api.replace(`{id}`, additionalParameters.id);
      if (!additionalParameters.id) return;
    }

    const sorted = yield select(ccDataGridSelectors.getSorted(ccDataGridId));
    const page = yield select(ccDataGridSelectors.getPage(ccDataGridId));
    let pageSize = yield select(ccDataGridSelectors.getPageSize(ccDataGridId));
    if (localSearch && expectedItems <= 50) pageSize = 50;

    const advancedSearchOptions = yield select(
      ccDataGridSelectors.getAdvancedSearchOptions(ccDataGridId)
    );

    if (
      additionalParameters &&
      ccDataGridId !== "source_configure" &&
      ccDataGridId !== "source_search"
    ) {
      Object.keys(additionalParameters).forEach(key =>
        params.push(`${key}=${additionalParameters[key]}`)
      );
    }
    let sortString = "";
    if (sorted.length > 0 && ccDataGridId !== CCDATAGRID_ID.POLICIES) {
      const tempSorted = _.cloneDeep(sorted);
      if (ccDataGridId === "user_accounts" && tempSorted[0].id === "name") {
        tempSorted[0].id = "first_name";
      }
      sortString = tempSorted
        .map(sort => `${sort.desc ? "-" : ""}${sort.id}`)
        .join(",");
      params.push(`sort=${sortString}`);
    }

    if (
      pagination === false ||
      ["source_configure", "policies"].includes(ccDataGridId)
    ) {
      params.push(`pagination=false`);
    } else if (ccDataGridId !== "source_search") {
      params.push(`page=${page}`);
      params.push(`page_size=${pageSize}`);
    }

    // if (advancedSearch) {
    //   Object.keys(advancedSearch).forEach(key =>
    //     params.push(`${key}=${advancedSearch[key]}`)
    //   );
    // }
    let queryString = "";
    let subOrgFilter = false;
    if (
      (advancedSearchOptions && advancedSearchOptions.length > 0) ||
      ccDataGridId === CCDATAGRID_ID.SOURCE_GROUP
    ) {
      // currently sources,policies and source_group are using advancedsearch. since for sources and policies for advanced search options it is going into this conditon, in future we can props for text search.
      let makeQueryString = "";
      const considerAdvancedSearch = yield select(
        ccDataGridSelectors.getConsiderAdvancedSearchOptions(ccDataGridId)
      );
      makeQueryString = considerAdvancedSearch
        ? util.getQueryStringByAdvanceSearchOptions(
            queryString,
            advancedSearchOptions
          )
        : "";
      if (
        ccDataGridId === CCDATAGRID_ID.AUDIT_TRAIL &&
        considerAdvancedSearch &&
        makeQueryString.includes("sub_organization_id")
      ) {
        subOrgFilter = true;
        makeQueryString = makeQueryString.replace(
          "sub_organization_id",
          "audit_organization_ids"
        );
      }
      if (
        ccDataGridId === CCDATAGRID_ID.AUDIT_TRAIL &&
        considerAdvancedSearch &&
        makeQueryString.includes("user_ids")
      ) {
        makeQueryString = makeQueryString.replace("user_ids", "user_id");
      }
      const searchField = CCDATAGRID_ID_SEARCH_FIELD[ccDataGridId];
      const saveSearchText = yield select(
        ccDataGridSelectors.getCCDataGridSaveSearchText(ccDataGridId)
      );
      // let searchText = getCCDataGridSaveSearchText;
      // let textToSend = searchText || saveSearchText; // Fix this -> if advancedSearch is there use searchtext else use saveSearchText
      queryString = util.getQueryStringBySearchNameText(
        makeQueryString,
        saveSearchText,
        "",
        searchField
      );
      params.push(queryString);
    }

    if (ccDataGridId !== "source_search" && !subOrgFilter) {
      _.get(additionalParameters, "organization_id", false) ||
        params.push(`organization_id=${organizationId}`);
    }

    let payload = {};
    if (ccDataGridId === "source_search") {
      payload = {
        search_string: additionalParameters?.source_name,
        page: page,
        page_size: pageSize
      };
    }

    let response = [];
    if (ccDataGridId === "user_roles") {
      const userRoleParams = [];
      userRoleParams.push(`organization_id=${organizationId}`);
      userRoleParams.push(`pagination=false`);
      const apiResponse = yield call(
        getData,
        api,
        userRoleParams.join("&"),
        CDAPIS.includes(api) ? stateLogin.cdtoken : token
      );
      if (apiResponse?.errors && apiResponse.errors?.length > 0) {
        const toastData = {
          messageId: apiResponse.errors[0].message,
          testId: `SeEqhF_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      }

      const ans = JSON.parse(JSON.stringify(dataUserRoles));
      const searchFilter = stateLogin.organization_type;
      ans.data = ans.data.filter(ele => ele.organization_type === searchFilter);
      const modifiedDataUserRoles = JSON.parse(JSON.stringify(ans));
      // making api call for /user(userAccounts) to get and map counts in userRoles
      apiResponse &&
        apiResponse.data &&
        apiResponse.data.length > 0 &&
        apiResponse.data.forEach(apiResp => {
          modifiedDataUserRoles.data.forEach(staticUserData => {
            if (
              apiResp.role_id === staticUserData.role_id &&
              apiResp.user_type === staticUserData.user_type
            ) {
              staticUserData.user_count = staticUserData.user_count + 1;
            }
          });
        });
      response = modifiedDataUserRoles;
    } else if (ccDataGridId === "source_search") {
      response = yield call(
        postData,
        api,
        payload,
        CDAPIS.includes(api) ? stateLogin.cdtoken : token
      );
      if (response?.errors && response.errors?.length > 0) {
        const toastData = {
          messageId: response.errors[0].message,
          testId: `UxQKJA_${response.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      }
    } else if (
      [CCDATAGRID_ID.TABLESPACES, CCDATAGRID_ID.DATAFILES].includes(
        ccDataGridId
      )
    ) {
      if (!init) {
        const apiDetails = yield select(
          ccDataGridSelectors.getApiDetails(ccDataGridId)
        );
        if (apiDetails?.data?.length > 0) {
          let genericList = genericSort(apiDetails, sortString);
          const { data } = genericList;
          yield put(
            ccDataGridActions.UpdateLocalData(
              ccDataGridId,
              sortString?.length > 0 ? genericList : apiDetails,
              sortString?.length > 0 ? data : apiDetails?.data,
              sortString?.length > 0
                ? genericList?.pagination
                : apiDetails?.pagination
            )
          );
        }
        let { data } = apiDetails;
        let checkedData = data?.length > 0 ? data.map(e => e._rowId) : [];
        yield put(ccDataGridActions.checkRows(ccDataGridId, ...checkedData));
      } else {
        return;
      }
    } else {
      const sources = yield select(
        ccDataGridSelectors.getApiDetails(ccDataGridId)
      );
      if (
        !useApi &&
        !polling &&
        !init &&
        localSearch &&
        sources &&
        sources.data &&
        sources.data.length > 0 &&
        expectedItems > 0 &&
        expectedItems <= 50 &&
        sources.data.length >= expectedItems
      ) {
        const selectedSavedSearchID = yield select(
          ccDataGridSelectors.getCCDataGridSelectedSavedSearchId(ccDataGridId)
        );
        const savedSearchData = yield select(
          ccDataGridSelectors.getCCDataGridUserSearchData(ccDataGridId)
        );
        const searchData = savedSearchData?.find(
          item => item?.filter_id === selectedSavedSearchID
        );
        isLocal = true;
        const list =
          ccDataGridId !== "policies"
            ? sourceInternalFilterAndSorting(
                queryString,
                sources,
                sortString,
                searchData
              )
            : policyInternalFilterAndSorting(queryString, sources, sortString);
        let { data } = list;
        // MUST SUCCEED (testing)
        data = data.map(r => ({
          ...r,
          _rowId: r[primaryKey] + "-" + uuidv4()
        }));
        yield put(
          ccDataGridActions.UpdateLocalData(
            ccDataGridId,
            list,
            data,
            pagination
          )
        );
      } else {
        const parameters = params.filter(Boolean);
        const isSourceTypeAvailableInParams = params.find(a =>
          a.includes("source_type=")
        );
        if (
          Object.keys(CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS).includes(
            ccDataGridId
          )
        ) {
          const { rman: isRMANVisible } = yield select(getFeatureFlag);
          if (!isRMANVisible && ccDataGridId === "sources") {
            parameters.push(
              `source_type=${CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS.sourcesWithoutOracleDB}`
            );
          } else if (!isSourceTypeAvailableInParams) {
            parameters.push(
              `source_type=${CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS[ccDataGridId]}`
            );
          }
        }
        response = yield call(
          getData,
          api,
          parameters.join("&"),
          CDAPIS.includes(api) ? stateLogin.cdtoken : token
        );
        if (response?.errors && response.errors?.length > 0) {
          const toastData = {
            messageId: response.errors[0].message,
            testId: `vANG8K_${response.status}`
          };
          yield put(ccToast.addErrorNotification(toastData));
        }
      }
    }
    if (!isLocal && response?.errors?.length === 0) {
      let { data } = response;
      // MUST SUCCEED (testing)
      data = data.map(r => ({ ...r, _rowId: r[primaryKey] + "-" + uuidv4() }));
      yield put(
        ccDataGridActions.fetchDataSuccess(
          ccDataGridId,
          response,
          data,
          pagination
        )
      );
    }
    yield put(ccDataGridActions.resetCCDataGridLoader(ccDataGridId));
    if (setFromNavigationAPI !== undefined) {
      yield put(fromNavigationApi(setFromNavigationAPI));
    }
    yield put(
      ccDataGridActions.resetAdvancedSearchOptionsConsidered(ccDataGridId)
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* ccAdvanceSearchOptions(action) {
  const { ccDataGridId, data } = action;
  const isAdvancedSearchOptionsSelected = yield select(
    ccDataGridSelectors.getAdvancedSearchOptionsSelected(ccDataGridId)
  );
  let latestAdvancedSearchOptions = _.cloneDeep(data);
  if (isAdvancedSearchOptionsSelected) {
    const previousAdvancedSearchOptions = _.cloneDeep(
      yield select(ccDataGridSelectors.getAdvancedSearchOptions(ccDataGridId))
    );

    if (latestAdvancedSearchOptions.length > 0) {
      latestAdvancedSearchOptions.forEach(obj2 => {
        const obj1 = previousAdvancedSearchOptions.find(
          o => o.key === obj2.key
        );
        if (obj1 && obj1.selectedOptions.length > 0)
          obj2.selectedOptions = obj1.selectedOptions;
      });
    }
  }
  yield put(
    ccDataGridActions.fetchAdvancedSearchOptions(
      ccDataGridId,
      latestAdvancedSearchOptions
    )
  );
  yield put(
    ccDataGridActions.fetchAdvancedSearchOptionsUnChanged(ccDataGridId, data)
  );
}

export function* ccSavedSearchData(action) {
  const { ccDataGridId, data } = action;
  yield put(ccDataGridActions.setUserSearchData(ccDataGridId, data));
  yield put(ccDataGridActions.setSystemSearchData(ccDataGridId, data));
}

export function* updateSelectedSavedSearch(action) {
  const {
    ccDataGridId,
    filter_id,
    filter_name,
    defaultValue = false,
    init = false,
    data
  } = action;
  const currentSelectedSavedSearch = yield select(
    ccDataGridSelectors.getCCDataGridSelectedSavedSearch(ccDataGridId)
  );
  currentSelectedSavedSearch.filter_id = filter_id;
  currentSelectedSavedSearch.filter_name = filter_name;
  yield put(
    ccDataGridActions.setSelectedSavedSearch(
      ccDataGridId,
      currentSelectedSavedSearch,
      filter_id,
      filter_name
    )
  );
  if (data && Object.keys(data).length > 0) {
    const selectedSearchText = data[CCDATAGRID_ID_SEARCH_FIELD[ccDataGridId]];
    yield put(
      ccDataGridActions.setSaveSearchText(ccDataGridId, selectedSearchText)
    );
  }
  if (defaultValue) {
    const actualOptions = yield select(
      ccDataGridSelectors.getAdvancedSearchOptionsUnChanged(ccDataGridId)
    );
    const generateOptions = savedSearch => {
      const searchOptions = _.cloneDeep(actualOptions);
      if (Array.isArray(searchOptions)) {
        searchOptions.forEach(option => {
          const { key, type } = option;
          if (savedSearch.hasOwnProperty(key)) {
            switch (type) {
              case "date-picker":
              case "date-range-picker":
                option.selectedOptions = Object.assign({}, savedSearch[key]);
                break;
              case constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE:
                //eslint-disable-next-line no-lone-blocks
                {
                  option.selectedOptions = [];
                  if (savedSearch[type] && !Array.isArray(savedSearch[type])) {
                    let selectedOption = savedSearch[type].toString();
                    const selectedOpt = option.options.filter(opt => {
                      let tempValue = opt.id ? opt.id : opt.value;
                      return tempValue === selectedOption;
                    });
                    option.selectedOptions.push(
                      selectedOpt && selectedOpt.length > 0
                        ? selectedOpt[0]
                        : []
                    );
                  } else {
                    savedSearch[type] &&
                      savedSearch[type].forEach(selectedOption => {
                        const selectedOpt = option.options.filter(opt => {
                          let tempValue = opt.id ? opt.id : opt.value;
                          return tempValue === selectedOption.toString();
                        });
                        option.selectedOptions.push(
                          selectedOpt && selectedOpt.length > 0
                            ? selectedOpt[0]
                            : []
                        );
                      });
                  }
                }
                break;
              default: {
                option.selectedOptions = [];
                if (
                  (savedSearch[key] && !Array.isArray(savedSearch[key])) ||
                  typeof savedSearch[key] === "boolean"
                ) {
                  let selectedOption = savedSearch[key].toString();
                  const selectedOpt = option.options.filter(opt => {
                    let tempValue = opt.id ? opt.id : opt.value;
                    return tempValue === selectedOption;
                  });
                  option.selectedOptions.push(
                    selectedOpt && selectedOpt.length > 0 ? selectedOpt[0] : []
                  );
                } else {
                  if (option.key === is_deleted.key) {
                    option.selectedOptions = isDeletedApiConvert(
                      savedSearch.is_deleted
                    );
                  } else {
                    //TODO: root cause is UserSearchData has some destination_id as array and some as array of objects
                    const isArrayOfStrings =
                      Array.isArray(savedSearch[key]) &&
                      savedSearch[key].every(ele => typeof ele === "string");
                    if (!isArrayOfStrings) {
                      option.selectedOptions = savedSearch[key].filter(
                        ele => !Array.isArray(ele)
                      );
                    } else {
                      savedSearch[key] &&
                        savedSearch[key].forEach(selectedOption => {
                          const selectedOpt = option.options.filter(opt => {
                            // Checking source state filter and remove VSB identifier to send BE supported source state filters.
                            // To fix bug 1039958
                            if (
                              key === VSB_FILTER_ATTRIBUTES.SOURCE_STATE &&
                              opt.value.includes(VSB_IDENTIFIERS[key])
                            ) {
                              opt.value = opt.value.replace(
                                VSB_IDENTIFIERS[option.key],
                                ""
                              );
                            }
                            let tempValue = opt.id ? opt.id : opt.value;
                            return tempValue === selectedOption.toString();
                          });
                          option.selectedOptions.push(
                            selectedOpt && selectedOpt.length > 0
                              ? selectedOpt[0]
                              : []
                          );
                        });
                    }
                  }
                }
                break;
              }
            }
          }
        });
        return searchOptions;
      }
    };
    const updatedAdvancedSearch = generateOptions(data);
    yield put(
      ccDataGridActions.fetchAdvancedSearchOptions(
        ccDataGridId,
        updatedAdvancedSearch
      )
    );
    yield put(ccDataGridActions.fetchData(action.ccDataGridId, init));
  } else if (!defaultValue && init) {
    yield put(ccDataGridActions.fetchData(ccDataGridId, init));
  }
}

export function* updateManageSaveSearchOptionsAndValues(action) {
  const { ccDataGridId, data, organization_type, isImpersonationView } = action;
  const actualOptions = yield select(
    ccDataGridSelectors.getAdvancedSearchOptions(ccDataGridId)
  );
  yield put(ccDataGridActions.setManageSelectedSavedSearch(ccDataGridId, data));
  yield put(
    ccDataGridActions.setManageSaveSearchOptions(
      ccDataGridId,
      { data },
      actualOptions,
      organization_type,
      isImpersonationView
    )
  );
}

export function* ManageSavedSearchComplete(action) {
  const { ccDataGridId } = action;
  const userSearchData = yield select(
    ccDataGridSelectors.getCCDataGridUserSearchData(ccDataGridId)
  );
  const updatedUserSearchData = _.unionBy(
    [action.data],
    userSearchData,
    "filter_id"
  );
  const dataToSend = { userSearchList: updatedUserSearchData };
  yield put(ccDataGridActions.setManageSelectedSavedSearch(ccDataGridId, {}));
  yield put(ccDataGridActions.updateManageSaveSearchOptions(ccDataGridId, []));
  yield put(ccDataGridActions.setUserSearchData(ccDataGridId, dataToSend));
}

export function* DeletedSaveSearch(action) {
  const { ccDataGridId, id } = action;
  const userSearchData = yield select(
    ccDataGridSelectors.getCCDataGridUserSearchData(ccDataGridId)
  );
  const updatedUserSearchData = userSearchData
    .slice()
    .filter(item => item.filter_id !== id);
  const dataToSend = { userSearchList: updatedUserSearchData };
  yield put(ccDataGridActions.setUserSearchData(ccDataGridId, dataToSend));
}

export function* DefaultSavedSearchValues(action) {
  const { ccDataGridId, data } = action;
  yield put(
    ccDataGridActions.setSelectedSavedSearch(
      ccDataGridId,
      data,
      data.filter_id,
      data.filter_name
    )
  );
}

export function* deleteSourceInGrid(action) {
  let { ccDataGrid } = action;
  const { primaryKey } = yield select(
    ccDataGridSelectors.getConfig(ccDataGrid)
  );
  const sources = yield select(ccDataGridSelectors.getApiDetails(ccDataGrid));
  const updatedRecords = sources.data.filter(
    item => item[SourceConstants.SourceAttributes.SOURCE_PROP_ID] !== action.id
  );
  sources.data = updatedRecords;
  const updatedRec = updatedRecords.map(r => ({
    ...r,
    _rowId: r[primaryKey] + "-" + uuidv4()
  }));
  let check;

  sources.pagination.total_size--;
  yield put(
    ccDataGridActions.fetchDataSuccess(ccDataGrid, sources, updatedRec, check)
  );
}

export function* deletePolicyInGrid(action) {
  let { ccDataGrid } = action;
  const { primaryKey } = yield select(
    ccDataGridSelectors.getConfig(ccDataGrid)
  );
  const policies = yield select(ccDataGridSelectors.getApiDetails(ccDataGrid));
  const updatedRecords = policies.data.filter(
    item => item.policy_id !== action.id
  );
  const entities = yield select(
    ccDataGridSelectors.getEntities(constant.SECONDARY_NAVIGATION_TYPE.POLICIES)
  );
  policies.data = updatedRecords;
  const updateEntities = entities.filter(item => item.policy_id !== action.id);
  const updatedRec = updateEntities.map(r => ({
    ...r,
    _rowId: r[primaryKey] + "-" + uuidv4()
  }));
  let check;

  policies.pagination.total_size--;
  yield put(
    ccDataGridActions.fetchDataSuccess(ccDataGrid, policies, updatedRec, check)
  );
}
export function* updateSourceInGrid(action) {
  let { ccDataGrid, changedSource, changedKey } = action;
  const { primaryKey } = yield select(
    ccDataGridSelectors.getConfig(ccDataGrid)
  );
  const sources = _.cloneDeep(
    yield select(ccDataGridSelectors.getApiDetails(ccDataGrid))
  );
  const entities = yield select(ccDataGridSelectors.getEntities(ccDataGrid));
  const updatedRecords = updateObjectInArray(
    entities,
    changedSource,
    changedKey,
    primaryKey
  );

  sources.data = updatedRecords;
  let check;
  yield put(
    ccDataGridActions.fetchDataSuccess(
      ccDataGrid,
      sources,
      updatedRecords,
      check
    )
  );
}

export function* fetchColumns(action) {
  const columnReducerData = action.columnData;
  let columnsData = columnReducerData.columnData;
  if (constant.WITH_STORED_COLUMNS.includes(columnReducerData.name)) {
    columnsData &&
      columnsData.find(cd => cd.identifier === "available_actions") ===
        undefined &&
      columnsData.unshift({
        identifier: "available_actions",
        show: true,
        sort: false
      });
  }
  const columnReducerDataFiltered =
    columnsData && columnsData.length > 0
      ? getReorderColumns(columnsData, columnReducerData.name)
      : [];
  yield put(
    ccDataGridActions.updateColumns(
      columnReducerData.name,
      columnReducerDataFiltered,
      false
    )
  );
}

export function* setReorderColumns(action) {
  const { data, oldItem, newItem, stateFiltertype } = action;
  const emptyObj = [];
  const stateData = emptyObj.concat(data);
  const tempObj = stateData.splice(oldItem.y + 2, 1); // first element removed
  stateData.splice(newItem.y + 2, 0, tempObj[0]);
  const columnDataConfig = stateData;
  yield put(
    ccDataGridActions.updateColumns(stateFiltertype, columnDataConfig, false)
  );
}

export function* setGridCheckboxSettings(action) {
  const { data, stateData, filterType } = action;
  let columnStateData = JSON.parse(
    JSON.stringify(Object.assign([], stateData))
  );
  let columnDataShowHideFiltered;
  if (!Array.isArray(columnStateData)) {
    columnDataShowHideFiltered = columnStateData.map(obj => {
      if (obj.name) {
        obj.columnData.map(insideobj => {
          if (insideobj.identifier === data.identifier) {
            insideobj.show = !insideobj.show;
          }
          return insideobj;
        });
      }
      obj.columnData = getReorderColumns(obj.columnData);
      return obj;
    });
  } else {
    columnStateData = columnStateData.map(insideobj => {
      if (insideobj.identifier === data.identifier) {
        insideobj.show = !insideobj.show;
      }
      return insideobj;
    });
    columnDataShowHideFiltered = getReorderColumns(columnStateData);
  }
  yield put(
    ccDataGridActions.updateColumns(
      filterType,
      columnDataShowHideFiltered,
      false
    )
  );
}

function updateObjectInArray(array, action, key, primaryKey) {
  const updatedArray = array.map(item => {
    if (item[key ? key : "id"] !== action[key ? key : "id"]) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    const updatedRecord = {
      ...action,
      _rowId: action[primaryKey] + "-" + uuidv4()
    };
    return updatedRecord;
  });
  return updatedArray;
}

const sourceInternalFilterAndSorting = (
  queryString,
  data,
  sortString,
  searchData
) => {
  let filtered = _.cloneDeep(data.data);
  if (queryString.includes("source_name=")) {
    const sourceParamString = decodeURIComponent(queryString)
      .split("source_name=")[1]
      .split("&")[0];
    filtered = [
      ...filtered.filter(ele =>
        _.get(ele, "source_name")
          ?.toLowerCase()
          .includes(sourceParamString?.toLowerCase())
      )
    ];
  }
  if (queryString.includes("last_job=")) {
    let sO = queryString
      .split("last_job=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => sO.includes(_.get(ele, "last_job[0].status")))
    ];
  }
  if (queryString.includes("policy_id=")) {
    let sO = queryString
      .split("policy_id=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => sO.includes(_.get(ele, "policy[0].policy_id")))
    ];
  }
  if (queryString.includes("site_id=")) {
    let sO = queryString
      .split("site_id=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => sO.includes(_.get(ele, "site.site_id")))
    ];
  }
  if (queryString.includes("protection_status=")) {
    let sO = queryString
      .split("protection_status=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => sO.includes(_.get(ele, "protection_status")))
    ];
  }
  if (queryString.includes("is_deleted=")) {
    let sO = queryString
      .split("is_deleted=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele =>
        sO.includes(_.get(ele, "is_deleted").toString())
      )
    ];
  }
  if (queryString.includes("group_id=")) {
    let sO = queryString
      .split("group_id=")[1]
      .split("&")[0]
      .split("%7C");
    if (sO[0] === "" && searchData) {
      sO = searchData?.group_id?.map(val => val);
    }
    filtered = [
      ...filtered.filter(ele =>
        sO.some(
          e =>
            !_.get(ele, "source_group", []).findIndex(obj => obj.group_id === e)
        )
      )
    ];
  }
  if (queryString.includes("connection_status=")) {
    let sO = queryString
      .split("connection_status=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => sO.includes(_.get(ele, "connection_status")))
    ];
  }
  if (queryString.includes("source_type=")) {
    let sO = queryString
      .split("source_type=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => sO.includes(_.get(ele, "source_type")))
    ];
  }
  if (queryString.includes("operating_system=")) {
    let sO = queryString
      .split("operating_system=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele =>
        sO.includes(_.get(ele, "operating_system.os_major"))
      )
    ];
  }

  //sorting section
  if (sortString.length > 1 && data.data.length > 1) {
    let sortv = sortString;
    if (sortv.includes("-")) {
      if (sortv.includes("operating_system")) {
        // filtered.sort((a, b) =>
        //   a[sortv]["os_major"] < b[sortv]["os_major"] ? 1 : -1
        // );
      } else {
        filtered.sort((a, b) => (a[sortv] < b[sortv] ? 1 : -1));
      }
    } else {
      if (sortv.includes("operating_system")) {
        filtered.sort((a, b) =>
          a[sortv]["os_major"] > b[sortv]["os_major"] ? 1 : -1
        );
      } else {
        filtered.sort((a, b) => (a[sortv] > b[sortv] ? 1 : -1));
      }
    }
  }
  let protectList;
  protectList = {
    data: filtered,
    errors: data.errors,
    pagination: data.pagination,
    status: 200
  };

  return protectList;
};

const genericSort = (data, sortString) => {
  let filtered = _.cloneDeep(data.data);
  //sorting section
  if (sortString.length > 1 && data.data.length > 1) {
    let sortv = sortString;
    if (sortv.includes("-")) {
      filtered.sort((a, b) => (a[sortv] < b[sortv] ? 1 : -1));
    } else {
      filtered.sort((a, b) => (a[sortv] > b[sortv] ? 1 : -1));
    }
  }
  let genericList;
  genericList = {
    data: filtered,
    errors: data.errors,
    pagination: data.pagination,
    status: 200
  };
  return genericList;
};

const policyInternalFilterAndSorting = (queryString, data, sortString) => {
  let filtered = _.cloneDeep(data.data);
  if (queryString.includes("policy_name=")) {
    let sO = decodeURIComponent(queryString)
      .split("policy_name=")[1]
      .split("&")[0];
    if (sO.length > 0) {
      sO = sO.replaceAll("%20", " ");
    }
    filtered = [
      ...filtered.filter(ele => {
        return _.get(ele, "policy_name")
          ?.toLowerCase()
          .includes(sO?.toLowerCase());
      })
    ];
  }
  if (queryString.includes("status=")) {
    let sO = queryString
      .split("status=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(ele => {
        if (sO.includes("disabled"))
          return (
            sO.includes(_.get(ele, "policy_status")) ||
            sO.includes(_.get(ele, "usage_status")) ||
            ["msp_disabled", "csr_disabled", "disabled"].includes(
              _.get(ele, "usage_status")
            )
          );
        else
          return (
            sO.includes(_.get(ele, "policy_status")) &&
            _.get(ele, "usage_status") !== "disabled"
          );
      })
    ];
  }
  if (queryString.includes("group_id=")) {
    let sO = queryString
      .split("group_id=")[1]
      .split("&")[0]
      .split("%7C");
    filtered = [
      ...filtered.filter(
        ele =>
          ele?.source_group?.length > 0 &&
          sO.some(
            e =>
              !_.get(ele, "source_group", []).findIndex(
                obj => obj.group_id === e
              )
          )
      )
    ];
  }
  //sorting section
  if (sortString.length > 1 && data.data.length > 1) {
    let sortv = sortString;
    if (sortv.includes("-")) {
      filtered.sort((a, b) => (a[sortv] < b[sortv] ? 1 : -1));
    } else {
      filtered.sort((a, b) => (a[sortv] > b[sortv] ? 1 : -1));
    }
  }
  let policyList;
  policyList = {
    data: filtered,
    errors: data.errors,
    pagination: data.pagination,
    status: 200
  };

  return policyList;
};

const getReorderColumns = (columns, name) => {
  let showData = [];
  let hideData = [];
  let columnDataSorted = [];
  let available_actions = [];
  if (name === "customer-accounts") {
    columns.unshift({
      identifier: "customer_view",
      show: true
    });
  }
  columns.forEach(obj => {
    if (
      obj.identifier === constant.SPOG_GRID_PROPERTIES.ACTION_KEY.ACTION ||
      obj.identifier ===
        constant.SPOG_GRID_PROPERTIES.ACTION_KEY.AVAILABLE_ACTIONS
    ) {
      available_actions.push(obj);
    } else {
      if (obj.show === true) {
        showData.push(obj);
      } else {
        hideData.push(obj);
      }
    }
  });
  columnDataSorted = [...available_actions, ...showData, ...hideData];
  return columnDataSorted;
};

export default function* ccDataGrid() {
  yield all([
    takeEvery(ccDataGridConstants.CCDATAGRID_INITIALIZE, initialize),
    takeEvery(ccDataGridConstants.CCDATAGRID_UPDATE_SORTED, updateSorted),
    takeEvery(ccDataGridConstants.CCDATAGRID_UPDATE_PAGE, updatePage),
    takeEvery(ccDataGridConstants.CCDATAGRID_UPDATE_PAGE_SIZE, updatePageSize),
    takeEvery(ccDataGridConstants.CCDATAGRID_UPDATE_COLUMNS, updateColumns),
    takeEvery(ccDataGridConstants.CCDATAGRID_ADVANCED_SEARCH, advancedSearch),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_CLEAR_ADVANCED_SEARCH,
      clearAdvancedSearch
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_REMOVE_SEARCH_TAGS_RELOAD,
      removeSearchTagsReload
    ),
    takeLatest(ccDataGridConstants.CCDATAGRID_FETCH_DATA_REQUEST, fetchData),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_SAVED_SEARCH,
      ccSavedSearchData
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_SELECTED_SAVED_SEARCH,
      updateSelectedSavedSearch
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_DELETE_SAVED_SEARCH,
      DeletedSaveSearch
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH_OPTIONS,
      updateManageSaveSearchOptionsAndValues
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_ADVANCED_SEARCH_OPTIONS,
      ccAdvanceSearchOptions
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_DELETE_SOURCE_IN_CCDATAGRID,
      deleteSourceInGrid
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_UPDATE_SOURCE_IN_CCDATAGRID,
      updateSourceInGrid
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_DELETE_POLICY_IN_CCDATAGRID,
      deletePolicyInGrid
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_FETCH_COLUMNS,
      fetchColumns
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_MANAGE_SAVED_SEARCH,
      ManageSavedSearchComplete
    ),
    takeEvery(
      ccDataGridConstants.CCDATAGRID_INVOKE_REORDER_COLUMN_DATA,
      setReorderColumns
    ),
    takeLatest(
      ccDataGridConstants.CCDATAGRID_GRID_CHECKBOX_SETTINGS,
      setGridCheckboxSettings
    )
  ]);
}
