import React from "react";
import PropTypes from "prop-types";
import { IntlProvider, addLocaleData } from "react-intl";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import en from "react-intl/locale-data/en";
import ja from "react-intl/locale-data/ja";
import pt from "react-intl/locale-data/pt";
import de from "react-intl/locale-data/de";
import es from "react-intl/locale-data/es";
import it from "react-intl/locale-data/it";
import fr from "react-intl/locale-data/fr";
import browserLocale from "browser-locale";
import { getLogin, getReducer } from "state/selectors";
import moment from "moment";
import "moment/min/locales";
import ViewOrg from "components/view-org";
import LogOut from "components/logout";
import SignOut from "components/signout";

import VersionInfo from "components/VersionInfo";
import CCToast from "components/common/CCToast";
import TermsOfService from "components/terms-of-service";
// import CCDataGridPlayground from "components/playground/CCDataGrid";
// import CCGraphPlayground from "components/playground/CCGraph";

import { MUI_INITIAL_THEME } from "utils/theme";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Redirect } from "react-router";
import PrivateRoute, { PrivateCsrRoute } from "./routes/route.utils"; // Import Private Route to ensure authorized access.
import localeData from "./assets/i18n/locales/data.json"; // i18n translated strings
// import { Login } from "./components/login/";
import Maintenance from "./components/maintenance";
import Signin from "./components/signin";
import Error from "./components/error";
import { UserVerification } from "./components/user-verification";
import Home from "./components/home";
import ForgotPasswordForm from "./components/user-resetpassword";
import ViewInstruction from "./components/view-instruction/ViewInstruction";
import SessionExpired from "./components/SessionExpired";
// import { ThemeProvider } from "@mui/material/styles";

// import { Redirect } from "react-router";
import IntlGlobalProvider from "./intl";
import Theme from "./components/theme/Theme";

const LoadableCsrHome = Loadable({
  loader: () => import(/* webpackChunkName: "csr" */ "./components/csr-home"),
  loading: () => <div />
});

const HOST_BE_URL = `${process.env.REACT_APP_BE_URL}/`;
const SUPPORTED_LANGUAGES = ["en", "ja", "pt", "de", "fr", "it", "es"];
// `${process.env.NODE_ENV}` === "production" ? ["en", "ja"] : ["en", "ja"];

/**
 * Main component to handle all workflows within app.
 * A route check loads either the Login or Home component.
 *
 * To enable i18n support for a specific language please import the locale-data for the same
 * and register it through addLocalData() call. You'd also want to ensure the translated messages file
 * for the language has been added under src/assets/i18n/locales/content for the build to include the same.
 *
 */
class App extends React.Component {
  /**
   * Default constructor
   * @param {*} props
   * @override
   */
  constructor(props) {
    super(props);

    const { locale } = this.props;
    let language = locale || browserLocale();

    // if (
    //   language.toLowerCase() === "ja-jp" &&
    //   SUPPORTED_LANGUAGES.includes("ja")
    // ) {
    //   language = "ja";
    // } else if (!SUPPORTED_LANGUAGES.includes(language)) {
    //   language = "en";
    // }
    if (SUPPORTED_LANGUAGES.includes(language.slice(0, 2))) {
      language = language.slice(0, 2);
    } else {
      language = "en";
    }

    this.state = {
      language,
      theme: createTheme(MUI_INITIAL_THEME())
    };

    this.fetchLanguage = this.fetchLanguage.bind(this);
  }

  componentDidMount() {
    this.addClassInBodyForCSR();
    //  this.fetchLanguage();
  }

  componentDidUpdate(prevProps) {
    const { locale, login } = this.props;
    if (prevProps.locale !== locale) {
      if (locale !== null) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ language: locale });
      } else {
        this.fetchLanguage();
      }
    }
    if (prevProps.login.organization_type !== login.organization_type) {
      this.addClassInBodyForCSR();
    }
    if (this.props.theme && prevProps.theme !== this.props.theme) {
      let theme = createTheme(this.props.theme);
      this.setState({ theme: theme });
    }
  }

  /**
   * Handle any cleanup before component unmounts.
   * @override
   */
  componentWillUnmount() {
    localStorage.removeItem("dashboardAuthWidgets");
    localStorage.removeItem("dashboardLayout");
    localStorage.removeItem("state");
    // TODO: to be removed. temporary solution.
    // localStorage.clear();
  }

  addClassInBodyForCSR() {
    const { login } = this.props;
    if (login.organization_type === "csr") {
      const ele = document.getElementsByTagName("html");
      ele && ele[0] && ele[0].classList.add("csr_body");
    } else {
      const ele = document.getElementsByTagName("html");
      ele && ele[0] && ele[0].classList.remove("csr_body");
    }
  }

  fetchLanguage() {
    const { language: languageFromState } = this.state;
    fetch(`${HOST_BE_URL}language`)
      .then(blob => blob.json())
      .then(json => {
        const { data: language } = json;
        if (
          language !== languageFromState &&
          SUPPORTED_LANGUAGES.includes(language)
        ) {
          this.setState({ language });
        }
      });
  }

  fetchUserLanguage() {
    const { login } = this.props;
    const { language: languageFromState } = this.state;
    const requestGetHeaders = {
      origin: "*",
      Authorization: `Bearer ${login.token}`
    };
    fetch(`${HOST_BE_URL}user/language`, {
      headers: requestGetHeaders
    })
      .then(blob => blob.json())
      .then(json => {
        const { data: language } = json;
        if (
          language !== languageFromState &&
          SUPPORTED_LANGUAGES.includes(language)
        ) {
          this.setState({ language });
        }
      });
  }

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  render() {
    const { language } = this.state;
    const { login, handleLogOut } = this.props;

    addLocaleData([...en, ...ja, ...pt, ...de, ...es, ...it, ...fr]);

    /*
     * Define user's language. Different browsers have the user locale defined
     * on different fields on the `navigator` object, so we make sure to account
     * for these different by checking all of them.
     */

    moment.locale(language);

    // Try full locale, try locale without region code, fallback to 'en'
    const messages = Object.prototype.hasOwnProperty.call(localeData, language)
      ? localeData[language]
      : localeData.en;

    // Disable missing translation message as translations will be added later.
    // We can add a toggle for this later when we have most translations.

    // eslint-disable-next-line
    const consoleError = console.error.bind(console);
    // eslint-disable-next-line
    console.error = (message, ...args) => {
      if (typeof message === "string" && message.startsWith("[React Intl]")) {
        return;
      }
      consoleError(message, ...args);
    };

    // We select the messages to pass to IntlProvider based on the user's locale
    return (
      <IntlProvider key={language} locale={language} messages={messages}>
        <IntlGlobalProvider>
          <ThemeProvider theme={this.state.theme}>
            <Router>
              <div
                className={`w-100 h-100 ${login.organization_type === "csr" &&
                  " csr_container"}`}
              >
                <Switch>
                  <PrivateRoute
                    path="/view-instruction"
                    component={ViewInstruction}
                  />
                  {/* <Route path="/enroll" component={WizardForm} /> */}
                  <Route path="/termsOfService" component={TermsOfService} />
                  <Route
                    path="/users/setpassword"
                    exact
                    component={UserVerification}
                  />
                  <Route
                    path="/login"
                    exact
                    render={() => <Redirect to="/" push />}
                  />
                  <Route path="/maintenance" exact component={Maintenance} />
                  <Route path="/signin" exact component={Signin} />
                  <Route
                    path="/sessionExpired"
                    exact
                    component={SessionExpired}
                  />
                  <Route path="/error" exact component={Error} />
                  <Route
                    path="/resetpassword"
                    exact
                    component={ForgotPasswordForm}
                  />
                  <Route
                    path="/logout"
                    render={() => <LogOut logOut={handleLogOut} />}
                  />

                  <Route path="/signout" render={() => <SignOut />} />

                  {/* playgrounds */}
                  {/*
                <Route
                  path="/playground/ccDataGrid"
                  exact
                  component={CCDataGridPlayground}
                />
                */}
                  {/* TODO: Comment this out before merging to dev */}
                  {/*
                <Route
                  path="/playground/ccGraph"
                  exact
                  component={CCGraphPlayground}
                />
                */}
                  {/* Recharts Playground */}
                  {/* <Route path="/playground/recharts" exact component={Recharts} /> */}

                  <PrivateCsrRoute
                    path="/csr/view-org/:orgId/:orgName/:orgType/:subMSP/:imccOrganization"
                    component={ViewOrg}
                  />
                  <PrivateCsrRoute
                    path="/csr/protect/sources/all/:source_name/:organization_name/:organization_id/:organization_type"
                    component={ViewOrg}
                  />

                  <PrivateCsrRoute
                    path="/csr/protect/policies/all/:policy_name/:organization_name/:organization_id/:organization_type"
                    component={ViewOrg}
                  />

                  <PrivateCsrRoute
                    path="/csr/analyze/jobs/all/:jobId/log/:jobName/:organization_name/:organization_id/:organization_type"
                    component={ViewOrg}
                  />

                  <PrivateCsrRoute
                    path="/csr/protect/sources/:source_id/details/recovery_points/:organization_name/:organization_id/:organization_type"
                    component={ViewOrg}
                  />
                  <PrivateCsrRoute path="/csr" component={LoadableCsrHome} />

                  <PrivateRoute path="/" component={Home} />
                </Switch>

                <VersionInfo />
                <CCToast />
                <Theme />
              </div>
            </Router>
          </ThemeProvider>
        </IntlGlobalProvider>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  handleLogOut: PropTypes.func.isRequired,
  locale: PropTypes.string,
  login: PropTypes.shape({}),
  theme: PropTypes.shape({})
};

App.defaultProps = {
  locale: null,
  theme: {},
  login: {}
};

const mapStateToProps = state => ({
  locale: getLogin(state).locale,
  theme: getReducer(state).theme,
  selectedTheme: getReducer(state)?.theme?.palette?.mode,
  login: getLogin(state)
});

export default connect(mapStateToProps)(App);
