import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Tag from "../../common/tags/index";
import Button from "../../common/button";
import FormattedMessage from "../../common/formatted-message";
import TextField from "@mui/material/TextField";
import { ID_PREFIX } from "configs/IdConfig";
import { VALIDATION_REGEX } from "../../../utils/appConstants";

class InputWithTags extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textValue: ""
    };
  }

  checkLinuxPathValidation = () => {
    if (this.props.linuxPathValidation) {
      return (
        this.state.textValue &&
        !VALIDATION_REGEX.VALID_LOCAL_PATH_FOR_LINUX.test(this.state.textValue)
      );
    } else {
      return false;
    }
  };

  /**
   * Callback to handle event for tags close button
   * @param - array index of cleared tag input
   */
  handleTagClose = searchTag => {
    let data = searchTag.value;
    this.props.handleTagClose(data);
  };

  renderTags = () => {
    return (
      this.props.optionsToTags &&
      this.props.optionsToTags.length > 0 && (
        <div
          className="grid-header-wrapper"
          style={{ width: "100%", marginTop: "10px" }}
        >
          <div
            className="saved-filter-wrapper select-tag-wrapper d-flex"
            style={{
              marginTop: 0,
              maxHeight: "200px",
              overflow: "auto",
              ...this.props.customStyles
            }}
          >
            <div className="tags-wrapper">
              <Tag
                tags={this.props.optionsToTags}
                onTagClose={this.handleTagClose}
                showTagsWithCategory={false}
              />
            </div>
          </div>
        </div>
      )
    );
  };

  render() {
    const { testId, id } = this.props;
    const textFieldId = testId
      ? `${ID_PREFIX}${testId}_textField`
      : id
      ? id
      : "";
    const btnId = testId ? `${testId}_btn` : id ? id : "";

    return (
      <div className="reporting-modal-width">
        <div className="column-name-list-wrapper d-flex text-left col-md-12 pl-0">
          <div className="column-name-list d-inline-flex flex-wrap pl-0 mr-4">
            <TextField
              type="text"
              list="data"
              value={this.state.textValue}
              onInput={event =>
                this.setState({ textValue: event.target.value })
              }
              inputProps={{
                form: {
                  autocomplete: "off"
                }
              }}
              className="spog-mui-textfield input-tags-textfield"
              id={textFieldId}
              error={this.checkLinuxPathValidation() ? true : false}
            />
            {this.checkLinuxPathValidation() && (
              <div className="text-danger mt-1">
                <FormattedMessage
                  id={"custom-input-error.path"}
                  values={"custom-input-error.path"}
                />
              </div>
            )}
            {this.props.options && this.props.options.length > 0 && (
              <datalist id="data">
                {this.props.options.map((item, index) => {
                  let keyIndex = `option_${index}`;
                  return <option key={keyIndex} value={item} />;
                })}
              </datalist>
            )}
          </div>
          <div
            className="column-name-list d-inline-flex flex-wrap pl-0"
            style={{ height: "100%", minHeight: "38px" }}
          >
            <Button
              color="secondary"
              content={<FormattedMessage id="add" defaultMessage="Add" />}
              disabled={
                this.state.textValue &&
                this.state.textValue.length > 0 &&
                (this.props.noEmailValidation ||
                  this.props.validator?.test(this.state.textValue)) &&
                !this.checkLinuxPathValidation()
                  ? false
                  : true
              }
              onClick={() => {
                this.props.addClickHandler(this.state.textValue);
                this.setState({ textValue: "" });
              }}
              variant="contained"
              testId={btnId}
            />
          </div>
        </div>
        <div
          className="column-name-list-wrapper d-flex text-left col-md-12 pl-0 pr-0"
          style={{ width: "100%" }}
        >
          {this.renderTags()}
        </div>
      </div>
    );
  }
}

InputWithTags.defaultProps = {
  optionsToTags: [],
  validator: /^.*/,
  options: [],
  customStyles: {}
};

InputWithTags.propTypes = {
  handleTagClose: PropTypes.func.isRequired,
  addClickHandler: PropTypes.func.isRequired,
  optionsToTags: PropTypes.arrayOf(PropTypes.shape({})),
  validator: PropTypes.instanceOf(RegExp),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  customStyles: PropTypes.object
};

export default InputWithTags;
