import React, { useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";
import { CHART_COLORS } from "utils/appConstants";
import { withCCGraph } from "components/common/CCGraph/HOCs";
import * as sGraph from "state/selectors/ccGraph";
import { getTextWidth } from "utils";
import {
  lookupResolutionXaxisFormat,
  setIntervalTicks
} from "components/common/CCGraph/utils";
import Wrapper from "./RechartWrapper";
import InsufficientData from "./InsufficientData";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  Legend
} from "recharts";
import TooltipComponent from "./TooltipComponent";

const loadingState = (width, height) => (
  <svg
    className="load-state line-load-state"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 711 325"
    width={width}
    height={height}
  >
    <defs>
      <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0" className="loading-charts" stopOpacity="0.8" />
        <stop offset="33.33%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="50%" className="loading-charts" stopOpacity="0" />
        <stop offset="66.66%" className="loading-charts" stopOpacity="0.8" />
        <stop offset="100%" className="loading-charts" stopOpacity="0.8" />
      </linearGradient>
    </defs>
    <path
      id="chart-bg"
      className="chart-bg"
      style={{ stroke: "#C3C6CF" }}
      d="M3.4,216.5h707.3 M3.4,160.5h707.3 M3.3,103.5h707.3 M3.3,48.5h707.6 M0.4,276.6H710H0.4z"
    />
    <polyline
      id="chart-line"
      className="chart-line"
      style={{ stroke: "#C3C6CF", strokeWidth: 4, fill: "none" }}
      points="8.8,175.8 62.4,237.7 116.1,184.7 169.7,175.8 223.3,57 277,176.8 330.6,176.8 384.3,122.5 437.9,176.8 491.6,176.8 545.2,218.4 598.8,122.5 652.5,184.7 706.1,135.1 "
    />
    <rect
      className="chart-filter"
      fill="url(#lgrad)"
      x="-100%"
      y="0"
      width="300%"
      height="100%"
    ></rect>
  </svg>
);

const TSLine = props => {
  let wrapperRef = useRef();
  moment.locale(props.intl.locale);

  const LineChartData = props.data && props.data.length > 0 ? props.data : [];

  const CustomizedTick = tickProps => {
    const { x, y, payload } = tickProps;
    return (
      <g transform={`translate(${x},${y}) rotate(22)`} height={"100%"}>
        <text
          x={-5}
          y={7}
          fontFamily={"Sans Source Pro"}
          fontSize={11}
          fontWeight={400}
          textAnchor="start"
          fill="#666"
        >
          {lookupResolutionXaxisFormat(
            props.resolution,
            props.intl.locale,
            payload.value
          )}
        </text>
      </g>
    );
  };

  const CustomizedLabel = props => {
    const { chartProps } = props;
    return (
      <g transform={`rotate(-90)`}>
        <text
          x={-220}
          y={30}
          fontFamily={"Sans-Regular"}
          fontSize={16}
          textAnchor="start"
          fontWeight={400}
          fill="#adb0b6"
        >
          {chartProps.intl.formatMessage({
            id: chartProps.yAxisLabelId
          })}
        </text>
      </g>
    );
  };

  const RenderLineChart = () => {
    if (!wrapperRef || !wrapperRef.current) return null;
    const { width, height } = getComputedStyle(wrapperRef.current);
    let myWidth = parseInt(width, 10) > 0 ? parseInt(width, 10) : 0;
    let myHeight = parseInt(height, 10) > 0 ? parseInt(height, 10) : 0;
    myWidth =
      myWidth === 0
        ? parseInt(
            (props.windowWidth ? props.windowWidth : window.innerWidth) *
              (props.widthAdjust ? props.widthAdjust : 1),
            10
          )
        : myWidth;
    myHeight =
      myHeight === 90
        ? parseInt(
            (props.windowHeight ? props.windowHeight : window.innerHeight) *
              (props.heightAdjust ? props.heightAdjust : 1),
            10
          )
        : myHeight;
    if (props.isLoading && LineChartData.length === 0) {
      return loadingState(myWidth, myHeight);
    }
    //eslint-disable-next-line eqeqeq
    if (props.fetchApi == false && LineChartData.length === 0) {
      return loadingState(myWidth, myHeight);
    }
    let xTicks =
      props.filters &&
      props.filters.range === "custom" &&
      props.resolution !== "hour"
        ? moment(props.filters.end_ts * 1000).diff(
            moment(props.filters.start_ts * 1000),
            "days"
          )
        : props.originalData &&
          props.originalData.length &&
          props.originalData[0].metric.length;
    xTicks = xTicks > 12 ? 12 : xTicks;

    LineChartData.forEach(obj => {
      for (const prop in obj) {
        const parsed = parseFloat(obj[prop]);
        obj[prop] = isNaN(parsed) ? obj[prop] : parsed;
      }
    });

    return (
      <ResponsiveContainer>
        <LineChart
          width={myWidth}
          height={myHeight}
          data={LineChartData}
          margin={{
            top: 20,
            right:
              Math.abs(
                getTextWidth("DD/MM/YYYY", "11px Sans-Regular") *
                  Math.cos(Math.radians(22))
              ) + 20
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke="#00000010"
          />
          <XAxis
            textAnchor={"start"}
            interval={setIntervalTicks(LineChartData.length, xTicks)}
            angle={22}
            dataKey="date"
            height={
              Math.abs(
                getTextWidth("DD/MM/YYYY", `11px ${"Sans Source Pro"}`) *
                  Math.sin(Math.radians(22))
              ) + 12
            }
            fontFamily={"Sans Source Pro"}
            tick={<CustomizedTick />}
          />
          <YAxis
            interval={0}
            domain={[0, dataMax => Math.ceil(dataMax)]}
            fill="#8f8f8f"
            fontSize={11}
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#8f8f8f" }}
            fontFamily={"Sans-Regular"}
          >
            {props.yAxisLabelId && (
              <Label content={<CustomizedLabel chartProps={props} />} />
            )}
          </YAxis>
          {props.names && props.names.length > 1 && (
            <Legend
              name="legend-line-chart"
              iconSize="16"
              iconType="circle"
              formatter={(value, entry, index) => {
                return (
                  <span
                    style={{
                      fontSize: "15px",
                      marginRight: "21px",
                      marginLeft: "4px",
                      color: "black"
                    }}
                  >
                    {props.intl.formatMessage({
                      id: `ccGraph.names.${value}`
                    })}
                  </span>
                );
              }}
              verticalAlign="top"
              height={66}
            />
          )}
          <Tooltip
            chartConfig={props.config}
            myWidth={myWidth}
            locale={props.intl.locale}
            order={props.names}
            cursor={{ fill: "transparent" }}
            content={<TooltipComponent />}
            allowEscapeViewBox={{ x: true }}
            isAnimationActive={false}
          />
          {props.names &&
            props.names.map((ele, i) => (
              <Line
                isAnimationActive={false}
                type="linear"
                strokeWidth={2}
                key={ele}
                dataKey={ele}
                dot={false}
                stroke={
                  (props.colorSchema && props.colorSchema[i]) || CHART_COLORS[i]
                }
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      {!props.isLoading && LineChartData.length < 1 ? (
        <InsufficientData />
      ) : (
        <Wrapper wrapperRef={wrapperRef}>
          <RenderLineChart props={props} />
        </Wrapper>
      )}
    </>
  );
};

TSLine.propTypes = {
  intl: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  resolution: PropTypes.string.isRequired,
  originalData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  colorSchema: PropTypes.arrayOf(PropTypes.string),
  yAxisLabelId: PropTypes.string
};

TSLine.defaultProps = {
  colorSchema: null,
  yAxisLabelId: null
};

const makeMapState = (
  outerState,
  { ccGraph: { config: outerConfig }, intl }
) => {
  const getData = sGraph.makeGetTSData(outerConfig.ccGraphId);
  const getTSLineData = sGraph.makeGetRCLineData(
    outerConfig.ccGraphId,
    intl.locale
  );
  return (state, { ccGraph: { config } }) => {
    return {
      isLoading: sGraph.isLoading(config.ccGraphId)(state),
      resolution: sGraph.getResolution(config.ccGraphId)(state),
      originalData: getData(state),
      data: getTSLineData(state).data,
      names: getTSLineData(state).names,
      config: outerConfig
    };
  };
};

export default compose(injectIntl, withCCGraph, connect(makeMapState))(TSLine);
