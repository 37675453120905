const with3Sources = () => [
  {
    name: "Critical",
    value: 0,
    date: "2011-01-05T00:00:00"
  },
  {
    name: "Critical",
    value: 10,
    date: "2011-01-06T00:00:00"
  },
  {
    name: "Critical",
    value: 7,
    date: "2011-01-07T00:00:00"
  },
  {
    name: "Critical",
    value: 6,
    date: "2011-01-08T00:00:00"
  },
  {
    name: "Warning",
    value: 3,
    date: "2011-01-05T00:00:00"
  },
  {
    name: "Warning",
    value: 16,
    date: "2011-01-06T00:00:00"
  },
  {
    name: "Warning",
    value: 10,
    date: "2011-01-07T00:00:00"
  },
  {
    name: "Warning",
    value: 0,
    date: "2011-01-08T00:00:00"
  },
  {
    name: "Error",
    value: 10,
    date: "2011-01-05T00:00:00"
  },
  {
    name: "Error",
    value: 15,
    date: "2011-01-06T00:00:00"
  },
  {
    name: "Error",
    value: 3,
    date: "2011-01-07T00:00:00"
  },
  {
    name: "Error",
    value: 33,
    date: "2011-01-08T00:00:00"
  }
];

const with2Sources = () => [
  {
    job_status: "Critical",
    job_count: 0,
    time_instance: "2011,01,01"
  },
  {
    job_status: "Critical",
    job_count: 10,
    time_instance: "2011,01,02"
  },
  {
    job_status: "Critical",
    job_count: 7,
    time_instance: "2011,01,03"
  },
  {
    job_status: "Critical",
    job_count: 6,
    time_instance: "2011,01,04"
  },
  {
    job_status: "Warning",
    job_count: 3,
    time_instance: "2011,01,05"
  },
  {
    job_status: "Warning",
    job_count: 16,
    time_instance: "2011,01,06"
  },
  {
    job_status: "Warning",
    job_count: 10,
    time_instance: "2011,01,07"
  },
  {
    job_status: "Warning",
    job_count: 0,
    time_instance: "2011,03,02"
  },
  {
    job_status: "Error",
    job_count: 10,
    time_instance: "2011,01,02"
  },
  {
    name: "Error",
    job_count: 15,
    time_instance: "2011,09,02"
  },
  {
    name: "Error",
    job_count: 3,
    time_instance: "2011,03,02"
  },
  {
    name: "Error",
    job_count: 33,
    time_instance: "2011,01,02"
  },
  {
    job_status: "Critical",
    job_count: 0,
    time_instance: "2011,09,01"
  },
  {
    job_status: "Critical",
    job_count: 10,
    time_instance: "2011,09,02"
  },
  {
    job_status: "Critical",
    job_count: 7,
    time_instance: "2011,09,03"
  },
  {
    job_status: "Critical",
    job_count: 6,
    time_instance: "2011,07,04"
  },
  {
    job_status: "Warning",
    job_count: 3,
    time_instance: "2011,04,05"
  },
  {
    job_status: "Warning",
    job_count: 16,
    time_instance: "2011,08,06"
  },
  {
    job_status: "Warning",
    job_count: 10,
    time_instance: "2011,01,07"
  },
  {
    job_status: "Warning",
    job_count: 0,
    time_instance: "2011,07,02"
  },
  {
    job_status: "Error",
    job_count: 10,
    time_instance: "2011,05,02"
  },
  {
    name: "Error",
    job_count: 15,
    time_instance: "2011,02,02"
  },
  {
    name: "Error",
    job_count: 3,
    time_instance: "2011,02,02"
  },
  {
    name: "Error",
    job_count: 33,
    time_instance: "2011,02,02"
  }
];

const with3SourcesBar = () => [
  {
    stack: "Information",
    value: 13,
    name: "s1-pun-12"
  },
  {
    stack: "Information",
    value: 15,
    name: "s1-pun-13"
  },
  {
    stack: "Information",
    value: 8,
    name: "s1-pun-14"
  },
  {
    stack: "Information",
    value: 11,
    name: "s1-pun-15"
  },
  {
    stack: "Information",
    value: 10,
    name: "s1-pun-16"
  },
  {
    stack: "Information",
    value: 9,
    name: "s1-pun-17"
  },
  {
    stack: "Information",
    value: 7,
    name: "s1-pun-18"
  },
  {
    stack: "Information",
    value: 6,
    name: "s1-pun-19"
  },
  {
    stack: "Information",
    value: 8,
    name: "s1-pun-20"
  },
  {
    stack: "Information",
    value: 6,
    name: "s1-pun-21"
  },
  {
    stack: "Warning",
    value: 2,
    name: "s1-pun-12"
  },
  {
    stack: "Warning",
    value: 3,
    name: "s1-pun-13"
  },
  {
    stack: "Warning",
    value: 4,
    name: "s1-pun-14"
  },
  {
    stack: "Warning",
    value: 1,
    name: "s1-pun-15"
  },
  {
    stack: "Warning",
    value: 2,
    name: "s1-pun-16"
  },
  {
    stack: "Warning",
    value: 2,
    name: "s1-pun-17"
  },
  {
    stack: "Warning",
    value: 1,
    name: "s1-pun-18"
  },
  {
    stack: "Warning",
    value: 1,
    name: "s1-pun-19"
  },
  {
    stack: "Warning",
    value: 1,
    name: "s1-pun-20"
  },
  {
    stack: "Warning",
    value: 1,
    name: "s1-pun-21"
  },
  {
    stack: "Critical",
    value: 5,
    name: "s1-pun-12"
  },
  {
    stack: "Critical",
    value: 2,
    name: "s1-pun-13"
  },
  {
    stack: "Critical",
    value: 2,
    name: "s1-pun-14"
  },
  {
    stack: "Critical",
    value: 1,
    name: "s1-pun-15"
  },
  {
    stack: "Critical",
    value: 1,
    name: "s1-pun-16"
  },
  {
    stack: "Critical",
    value: 1,
    name: "s1-pun-17"
  },
  {
    stack: "Critical",
    value: 2,
    name: "s1-pun-18"
  },
  {
    stack: "Critical",
    value: 0,
    name: "s1-pun-19"
  },
  {
    stack: "Critical",
    value: 0,
    name: "s1-pun-20"
  },
  {
    stack: "Critical",
    value: 0,
    name: "s1-pun-21"
  }
];

const with6Points = () => [
  {
    name: "Shiny",
    id: 1,
    quantity: 86
  },
  {
    name: "Blazing",
    id: 2,
    quantity: 300
  },
  {
    name: "Dazzling",
    id: 3,
    quantity: 276
  },
  {
    name: "Radiant",
    id: 4,
    quantity: 195
  },
  {
    name: "Sparkling",
    id: 5,
    quantity: 36
  },
  {
    name: "Other",
    id: 0,
    quantity: 814
  }
];

const with3Points = () => [
  {
    name: "Information",
    id: 1,
    quantity: 6,
    percentage: 78
  },
  {
    name: "Warning",
    id: 2,
    quantity: 300,
    percentage: 78
  },
  {
    name: "Critical",
    id: 3,
    quantity: 86,
    percentage: 78
  }
];

const with6PointsDonut = () => [
  {
    quantity: 78,
    percentage: 78,
    name: "Successful",
    id: 1
  },
  {
    quantity: 11,
    percentage: 11,
    name: "Failed",
    id: 2
  },
  {
    quantity: 17,
    percentage: 17,
    name: "Canceled",
    id: 3
  },
  {
    quantity: 21,
    percentage: 21,
    name: "Missed",
    id: 4
  },
  {
    quantity: 5,
    percentage: 5,
    name: "Not Connected",
    id: 5
  }
];

const with1Line = () => [
  {
    averageData: [
      {
        value: "45%",
        label: "Dedupe Savings"
      },
      {
        value: "145 GB",
        label: "Total Source Data"
      },
      {
        value: "95 GB",
        label: "Total Data Transferred"
      }
    ],
    dataByTopic: [
      {
        topicName: "Data Transferred",
        topic: 0,
        dates: [
          { date: new Date(2017, 0, 7), value: 32.3 },
          { date: new Date(2017, 0, 14), value: 33.9 },
          { date: new Date(2017, 0, 21), value: 26.0 },
          { date: new Date(2017, 0, 28), value: 15.8 },
          { date: new Date(2017, 1, 4), value: 18.6 },
          { date: new Date(2017, 1, 11), value: 34.6 },
          { date: new Date(2017, 1, 18), value: 37.7 },
          { date: new Date(2017, 1, 25), value: 24.7 },
          { date: new Date(2017, 2, 4), value: 35.9 },
          { date: new Date(2017, 2, 11), value: 12.8 },
          { date: new Date(2017, 2, 18), value: 38.1 },
          { date: new Date(2017, 2, 25), value: 42.4 }
        ]
      },
      {
        topicName: "Data Processed",
        topic: 1,
        dates: [
          { date: new Date(2017, 0, 7), value: 42.5 },
          { date: new Date(2017, 0, 14), value: 44.3 },
          { date: new Date(2017, 0, 21), value: 28.7 },
          { date: new Date(2017, 0, 28), value: 22.5 },
          { date: new Date(2017, 1, 4), value: 25.6 },
          { date: new Date(2017, 1, 11), value: 45.7 },
          { date: new Date(2017, 1, 18), value: 54.6 },
          { date: new Date(2017, 1, 25), value: 32.0 },
          { date: new Date(2017, 2, 4), value: 43.9 },
          { date: new Date(2017, 2, 11), value: 26.4 },
          { date: new Date(2017, 2, 18), value: 40.3 },
          { date: new Date(2017, 2, 25), value: 54.2 }
        ]
      },
      {
        topicName: "Data written",
        topic: 3,
        dates: [
          { date: new Date(2017, 0, 7), value: 85.4 },
          { date: new Date(2017, 0, 14), value: 92.7 },
          { date: new Date(2017, 0, 21), value: 64.9 },
          { date: new Date(2017, 0, 28), value: 58.0 },
          { date: new Date(2017, 1, 4), value: 63.4 },
          { date: new Date(2017, 1, 11), value: 69.9 },
          { date: new Date(2017, 1, 18), value: 88.9 },
          { date: new Date(2017, 1, 25), value: 66.3 },
          { date: new Date(2017, 2, 4), value: 82.7 },
          { date: new Date(2017, 2, 11), value: 60.2 },
          { date: new Date(2017, 2, 18), value: 87.3 },
          { date: new Date(2017, 2, 25), value: 98.5 }
        ]
      },
      {
        topicName: "Total Source Data",
        topic: 4,
        dates: [
          { date: new Date(2017, 0, 7), value: 75.4 },
          { date: new Date(2017, 0, 14), value: 82.7 },
          { date: new Date(2017, 0, 21), value: 54.9 },
          { date: new Date(2017, 0, 28), value: 48.0 },
          { date: new Date(2017, 1, 4), value: 53.4 },
          { date: new Date(2017, 1, 11), value: 59.9 },
          { date: new Date(2017, 1, 18), value: 78.9 },
          { date: new Date(2017, 1, 25), value: 56.3 },
          { date: new Date(2017, 2, 4), value: 72.7 },
          { date: new Date(2017, 2, 11), value: 50.2 },
          { date: new Date(2017, 2, 18), value: 77.3 },
          { date: new Date(2017, 2, 25), value: 88.5 }
        ]
      }
    ]
  }
];

export const StackedAreaData = {
  with3Sources,
  with2Sources
};

export const StackedBarData = {
  with3SourcesBar
};

export const LegendData = {
  with6Points,
  with3Points
};

export const DonutData = {
  with6PointsDonut
};

export const LineChartData = {
  with1Line
};
