import * as C from "state/constants";

const confirmations = (state = [], action) => {
  switch (action.type) {
    case C.ADD_CONFIRMATION:
      return [...state, action.payload];
    case C.CANCEL_CONFIRMATION:
      return state.filter(
        confirmation =>
          confirmation.confirmationId !== action.payload.confirmationId
      );
    default:
      return state;
  }
};

export default confirmations;
