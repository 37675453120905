import React from "react";

const Icon = props => {
  const IconClass = props.icon;
  return (
    <IconClass
      color={props.color}
      fontSize={props.fontSize}
      sx={props.sx}
      style={props.style}
      {...props}
    />
  );
};

export default Icon;
