import {
  SET_LOADING,
  RESET_LOADING,
  INITIALIZE_APP
} from "../../actions/actionTypes";

const loading = (state = {}, { type, name }) => {
  switch (type) {
    case "@@INIT":
      return {};
    case SET_LOADING:
      return { ...state, [name]: true };
    case RESET_LOADING:
      return { ...state, [name]: false };
    case INITIALIZE_APP:
      return {};
    default:
      return { ...state };
  }
};

export default loading;
