import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Holds constants definitions for SourceGroups
 */
export const sourceGroupsAttributes = {
  SOURCE_GROUPS_NAME: "group_name",
  SOURCE_GROUPS_ID: "group_id",
  SOURCE_GROUPS_TOTAL_ASSIGNED_SOURCES: "source_count",
  SOURCE_GROUPS_PROTECTED_SOURCES: "protected_sources",
  SOURCE_GROUPS_NOT_PROTECTED_SOURCES: "not_protected_sources",
  SOURCE_GROUPS_CREATED: "create_ts",
  SOURCE_GROUPS_LAST_MODIFIED: "modify_ts",
  SOURCE_GROUPS_STATUS: "source_status",
  SOURCE_GROUPS_NUM_TOTAL: "num_total",
  SOURCE_GROUPS_NUM_PROTECTED: "num_protected",
  SOURCE_GROUPS_NUM_UNPROTECTED: "num_unprotected",
  SOURCE_GROUPS_ACTIONS: "available_actions"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => {
  return "configure.sourceGroups.".concat(identifier);
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_CHANGE_STATUS : On click of cell the status will change
 * @type {{CLICKABLE_CELL_TO_VIEW_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_DETAILS: sourceGroupsAttributes.SOURCE_GROUPS_NAME
};

export const SourceGroupsConstants = {
  /**
   * Contextual actions for source groups
   */
  CONTEXTUAL_ACTION: {
    DELETE_GROUP: "delete_group"
  }
};

/**
 * Column definition for the source groups table
 */
export const COLUMN_DEFS = [
  {
    accessor: sourceGroupsAttributes.SOURCE_GROUPS_NAME,
    displayIdentifier: getDisplayIdentifier(
      sourceGroupsAttributes.SOURCE_GROUPS_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: sourceGroupsAttributes.SOURCE_GROUPS_TOTAL_ASSIGNED_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      sourceGroupsAttributes.SOURCE_GROUPS_TOTAL_ASSIGNED_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: sourceGroupsAttributes.SOURCE_GROUPS_PROTECTED_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      sourceGroupsAttributes.SOURCE_GROUPS_PROTECTED_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: sourceGroupsAttributes.SOURCE_GROUPS_NOT_PROTECTED_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      sourceGroupsAttributes.SOURCE_GROUPS_NOT_PROTECTED_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  }
  // {
  //   accessor: sourceGroupsAttributes.SOURCE_GROUPS_ACTIONS,
  //   displayIdentifier: getDisplayIdentifier(
  //     sourceGroupsAttributes.SOURCE_GROUPS_ACTIONS
  //   ),
  //   renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_SELECT },
  //   renderRecord: {
  //     type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL,
  //     actionDropdown: true
  //   },
  //   settings: {}
  // }
];

/**
 * Source Group Details - Common/Group Actions
 */
export const DETAIL_GROUP_ACTIONS = {
  DELETE_GROUP: "deleteGroup",
  REMOVE_FROM_GROUP: "removeFromGroup"
};

/**
 * Source Group Details - Contextual Actions
 */
export const DETAIL_CONTEXTUAL_ACTIONS = {
  REMOVE_FROM_GROUP: "removeFromGroup"
};
