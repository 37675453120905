import React, { useEffect } from "react";
import PropTypes from "prop-types";

import SpogDataGrid from "../../../../../../common/spog-data-grid-mui";

const SearchResults = ({
  fetchOrganizationSearch,
  searchString,
  value,
  onChange,
  pagination,
  organizations
}) => {
  useEffect(() => {
    if (!searchString) {
      return;
    }

    fetchOrganizationSearch(searchString, 1, 25);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getColumns = () => {
    const SearchColumns = [
      {
        id: "radioButtons",
        accessor: d => (
          <div>
            <input
              id={d.organization_id}
              type="radio"
              name="orgSelection"
              value={d.organization_id}
              style={{ position: "relative", opacity: 1 }}
              checked={d.organization_id === value}
              onChange={() => onChange(d.organization_id)}
            />
          </div>
        ),
        width: 40
      },
      {
        id: "organization_id",
        Header: "Org Id",
        accessor: "organization_id",
        sortable: false
      },
      {
        id: "organization_name",
        Header: "Name",
        accessor: "organization_name"
      }
    ];

    return SearchColumns;
  };

  const getRowData = () => {
    const rows = organizations;
    return rows.map(row => ({
      ...row,
      organization_id: row.organization_id,
      organization_name: row.organization_name,
      organization_type: row.organization_type
    }));
  };

  let paginationObj = pagination || {};

  return (
    <div className="spog-data-grid-wrapper d-flex flex-column new-user-modal__org-search-container">
      <SpogDataGrid
        columns={getColumns()}
        rows={getRowData()}
        showPagination
        pages={paginationObj.total_page}
        hideCheckbox
        showSelectedRows={false}
        actions={false}
        pagination={pagination}
        handlePaginationApi={pagination => {
          fetchOrganizationSearch(
            searchString,
            pagination.gridCurrentPage,
            pagination.gridPageSize
          );
        }}
        showToolbar={false}
        usePaginationWithApi
        disableGridSettings
        disableManageSaveSearches
        showSavedSearchList={false}
        showAdvancedSearchInput={false}
        isAdvSearchDisabled={false}
        isMenuCloseOnSelect={false}
        isDropdownDisabled={false}
      />
    </div>
  );
};

SearchResults.propTypes = {
  setOrganizationsSortString: PropTypes.func.isRequired,
  fetchOrganizationSearch: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    total_page: PropTypes.number
  }),
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

SearchResults.defaultProps = {
  pagination: {
    total_page: 0,
    gridPageSize: 25
  },
  value: null
};

export default React.memo(SearchResults);
