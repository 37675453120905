/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import SourceConstants from "components/protect/source/SourceConstants";
import * as apiUrl from "state/api/apiUrlConstants";

/**
 * Constant to get ADVANCED SEARCH CRITERIA API key's
 * @type {{DESTINATIONS: string, SOURCE_POLICY: string, SOURCES: string, POLICIES: string, VIRTUAL_MACHINES: string, VIRTUAL_STANDBYS: string, HYPERVISORS: string, JOBS: string, LOG: string, RECOVERY_POINTS: string, USER_ACCOUNTS: string, INSTANT_VMS: string}}
 */
export const ADVANCED_SEARCH_OPTIONS = {
  DESTINATIONS: "destinations",
  SOURCE_POLICY: "source_policy",
  SOURCES_SOURCE_GROUP: "sources_source_group",
  SOURCES: "sources",
  POLICIES: "policies",
  VIRTUAL_MACHINES: "vms",
  VIRTUAL_STANDBYS: "virtual_standby",
  VIRTUAL_STANDBY: "virtual_standbys",
  HYPERVISORS: "hypervisors",
  JOBS: "jobs",
  LOG: "log",
  JOBLOG: "jobLog",
  SOURCE_JOBS: "source_jobs",
  SOURCE_LOG: "source_log",
  RPS_JOBS: "rps_jobs",
  RPS_LOG: "rps_log",
  RPS_DATA_STORES: "rps_data_stores",
  DATA_STORE_LOG: "data_store_log",
  DATA_STORE_JOBS: "data_store_jobs",
  REPORTS: "reports",
  BACKUP_JOB_REPORT: "backup_jobs",
  RESTORE_JOB_REPORT: "restore_jobs",
  JOBS_AND_DESTINATIONS_REPORT: "jobs_and_destinations",
  CAPACITY_USAGE_REPORT: "capacity_usage",
  DATA_TRANSFER_REPORT: "data_transfer",
  REPORT_DETAIL: "report_detail",
  RECOVERY_POINTS: "recovery Points",
  USER_ACCOUNTS: "user_accounts",
  INSTANT_VMS: "instant_vms",
  RECOVERED_VMS: "recovered_vms",
  RECOVERED_RESOURCES: "recovered_resources",
  INSTANT_VMS_RR: "instant_vms_rr"
};

export const MAX_POSITIVE_INTEGER = 2147483647;
export const NUTANIX_PORT = 9440;
export const VCENTER_PORT = 443;

export const CSR_USERS = {
  csr_admin: {},
  csr_read_only: {}
};

export const CSR_CONSTANTS = {
  search_org_placeholder: "UUID or Org Name...",
  search_source_placeholder: "Source ID or Source Name...",
  search_org_id_placeholder: "Organization UUID...",
  search_changed_by_placeholder: "User UUID...",
  search_audit_data_placeholder: "Audit Data Search String..."
};

export const ALL_CSR_USERS = Object.keys(CSR_USERS);

export const RECOVERY_USERS_ACTIONS = [
  "startrecovery",
  "cancelrecovery",
  "create_instant_vm",
  "create_virtual_standby",
  "run_assured_security_scan",
  "collect_diagnostic_data"
];

/**
 * Common Grid properties
 * @type {{ID_KEY: string}}
 */
export const SPOG_GRID_PROPERTIES = {
  ID_KEY: "id",
  ACTION_KEY: { ACTION: "action", AVAILABLE_ACTIONS: "available_actions" },
  BULK_ACTION_EMPTY: "no_actions_supported",
  BULK_ACTION_UNSUPPORTED: [
    "startrecovery",
    "assigngroup",
    "edit",
    "view_recovery_point",
    "remote_console",
    "modify",
    "edit_report",
    "create_now",
    "viewlogs",
    "assignaccount",
    "remoteconsole",
    "remote_console",
    "target_vm_configuration"
  ],
  BULK_ACTION: {
    DELETE: "delete",
    DELETE_REPORT: "delete_report",
    REMOVE: "remove",
    ASSIGN_POLICY: "assignpolicy",
    REMOVE_POLICY: "unassignpolicy",
    ENABLE_POLICY: "enable",
    DISABLE_POLICY: "disable",
    START_REPLICATION_OUT: "startrepliationout",
    START_REPLICATION: "replication",
    CANCEL_REPLICATION: "cancelrepliationin",
    CANCEL_REPLICATION_OUT: "cancelrepliationout",
    CANCEL_RECOVERY: "cancelrecovery",
    CANCEL_BACKUP: "cancelbackup",
    START_BACKUP: "startbackup",
    BACKUP_POLICY: "backup",
    PROVISION: "provision",
    DEPROVISION: "deprovision",
    GRACEFUL_STOP: "gracefulstop",
    HARD_STOP: "hardstop",
    START: "start",
    RESTART: "restart",
    RESET_PASSWORD: "resetpassword",
    VERIFICATION_EMAIL: "verificationemail",
    CANCEL_JOB: "canceljob",
    UPGRADE_AGENT: "upgradeagent",
    DEPLOY: "deploy_policy",
    LAUNCH_UDP_AGENT_UI: "launch_udp_agent_ui",
    UPDATE: "update",
    REFRESH: "refresh",
    SHARE: "share",
    EDIT_MANAGE_SCHEDULE_REPORT: "modifyschedule",
    CREATE_VM_INSTANT: "create_instant_vm",
    COLLECT_DIAGNOSTIC_DATA: "collect_diagnostic_data",
    RUN_ASSURED_RECOVERY_TEST: "start_ar_test",
    SET_BACKUP_PASSWORDS: "set_backup_passwords",
    CANCEL_SCHEDULED_DEPLOY: "cancel_scheduled_deploy",
    INSTALL_AGENT: "install_agent"
  },
  RESOURCES_ID_KEY: {
    sources: "source_id",
    recoveredresources: "recoveredresource_id",
    users: "user_id",
    destinations: "destination_id",
    policies: "policy_id",
    reports: "report_id",
    jobs: "job_id",
    proxies: "proxy_id"
  }
};

/**
 * Toast types
 * @type {{SUCCESS: {type: string, icon: string}, ERROR: {type: string, icon: string}, WARNING: {type: string, icon: string}, INFORMATION: {type: string, icon: string}}}
 */
export const SPOG_TOAST_TYPES = {
  SUCCESS: { type: "success", icon: "successToaster" },
  ERROR: { type: "danger", icon: "errorToaster" },
  WARNING: { type: "warning", icon: "warningToaster" },
  INFORMATION: { type: "information", icon: "infoToaster" }
};

/**
 * Secondary Navigation key
 * @type {{}}
 */
export const SECONDARY_NAVIGATION_TYPE = {
  JOBS: "jobs",
  LOG: "log",
  ALERTS: "alerts",
  JOBLOG: "jobLog",
  SOURCES: "sources",
  PHYSICAL_MACHINES: "physical_machines",
  MACHINES: "machine",
  AGENTLESS_VM: "agentless_vm",
  UNC_NFS_PATHS: "unc_nfs_paths",
  VIRTUAL_MACHINES: "virtual_machines",
  VIRTUAL_STANDBYS: "virtual_standby",
  VIRTUAL_STANDBY: "virtual_standbys",
  INSTANT_VMS: "instant_vm",
  SHARE_POINT: "share_point",
  OFFICE_365: "office_365",
  SHARE_FOLDER: "shared_folder",
  DESTINATIONS: "destinations",
  CLOUD_VOLUMES: "cloud_volumes",
  ARCSERVE_CLOUD: "arcserve_cloud",
  POLICIES: "policies",
  HYPERVISORS: "hypervisors",
  ARCSERVE_CLOUD_RPS: "cloud_rps",
  SITES: "Sites",
  PROXIES: "proxies",
  SOURCE_GROUPS: "source_groups",
  VMS: "vms",
  DEDUPE_STORE: "cloud_stores",
  RECOVERY_POINTS: "recovery Points",
  RECOVERY_POINTS_SOURCES: "recovery Points Sources",
  RECOVERY_POINTS_URL: "recovery_points",
  VM_HYPERVISOR: "vm_hypervisor",
  SOURCE_LOG: "source_log",
  SOURCE_JOBS: "source_jobs",
  RPS_LOG: "rps_log",
  RPS_JOBS: "rps_jobs",
  DATA_STORE_LOG: "data_store_log",
  DATA_STORE_JOBS: "data_store_jobs",
  SOURCE_POLICY: "source_policy",
  USER_ACCOUNTS: "user_accounts",
  CUSTOMER_ACCOUNTS: "customer-accounts",
  USER_ROLES: "user_roles",
  REPORTS: "reports",
  BACKUP_JOB_REPORT: "backup_jobs",
  POLICY_TASKS_REPORT: "policy_tasks",
  RESTORE_JOB_REPORT: "restore_jobs",
  JOBS_AND_DESTINATIONS_REPORT: "jobs_and_destinations",
  CAPACITY_USAGE_REPORT: "capacity_usage",
  RECOVERY_POINT_REPORT: "recovery_point_report",
  SOURCE_PROTECTION_REPORT: "source_protection",
  RECOVERY_POINT_REPORT_TYPE: "source_rpo",
  DATA_TRANSFER_REPORT: "data_transfer",
  MANAGE_REPORT_SCHEDULES: "manage_schedules",
  REPORT_DETAIL: "report_detail",
  SOURCE_GROUP_DETAILS: "source_group_details",
  ADD_SOURCES: "source_group_add_sources",
  RECOVERED_VMS: "recovered_vms",
  MOUNTED_RECOVERY_POINTS: "mounted_recovery_points",
  NETWORK_CONFIGURATION: "network_configuration",
  RECOVERY_TARGETS: "recoverytargetsources",
  INFRASTRUCTURE: "Infrastructure",
  CLOUD_ACCOUNT: "cloud_account",
  STORAGE_ARRAYS: "storage_arrays",
  ORACLE_HOSTS: "oracle_hosts",
  UDP_LINUX: "udp_linux",
  UDP_ORACLE: "udp_oracle",
  RECOVERED_RESOURCES: "recovered_resources",
  INSTANT_VMS_RR: "instant_vms_rr",
  RECOVERY_POINT_SERVERS: "recovery_point_servers",
  DATA_STORES: "data_stores",
  CUSTOMER_ACCOUNT: "customer-accounts",
  ACCOUNT_DETAILS: "account_details",
  AUDIT_LOGS: "audit_logs"
};

export const ANALYZE_REPORTS_SUBREPORTS = [
  SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT,
  SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT,
  SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT,
  SECONDARY_NAVIGATION_TYPE.MANAGE_REPORT_SCHEDULES
];

export const DEFAULT_SEARCH_PAGES = [
  SECONDARY_NAVIGATION_TYPE.REPORTS,
  SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT,
  SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT,
  SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT,
  SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT,
  SECONDARY_NAVIGATION_TYPE.SOURCES,
  SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT
];

export const ANALYZE_PAGES_WITH_GRAPHS = [
  SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT,
  SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT,
  SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT,
  SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT
];

export const PROTECT_PAGES_WITH_GRAPHS = [
  SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES,
  SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE,
  SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD,
  SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNTS
];

export const ANALYZE_PAGES_WITH_GRAPH_AND_SOURCE_NAME_AS_SEARCH = [
  SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT,
  SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT,
  SECONDARY_NAVIGATION_TYPE.DATA_TRANSFER_REPORT
];

export const ANALYZE_PAGE_ROUTE = {
  BACKUP_JOB_REPORT: "/analyze/reports/backup_jobs",
  POLICY_TASKS_REPORT: "/analyze/reports/policy_tasks",
  RESTORE_JOB_REPORT: "/analyze/reports/restore_jobs",
  DATA_TRANSFER_REPORT: "/analyze/reports/data_transfer",
  CAPACITY_USAGE_REPORT: "/analyze/reports/capacity_usage",
  RECOVERY_POINT_REPORT: "/analyze/reports/recovery_point_report",
  SOURCE_PROTECTION_REPORT: "/analyze/reports/source_protection"
};

export const SUPPORT_PAGE_ROUTE = {
  DOWNLOADS: "/user_support/downloads"
};

export const HOME_RUN_ADV_SEARCH = [
  SECONDARY_NAVIGATION_TYPE.ALERTS,
  SECONDARY_NAVIGATION_TYPE.JOBS
];

export const COLUMN_TYPE = {
  SOURCE: "SOURCE",
  DESTINATION: "DESTINATION",
  JOB: "JOB",
  LOG: "LOG",
  USER: "USER",
  HYPERVISOR: "HYPERVISOR",
  BACKUP_JOB_REPORT: "BACKUP_JOB_REPORT",
  RESTORE_JOB_REPORT: "RESTORE_JOB_REPORT",
  RECOVERED_VMS: "RECOVEREDRESOURCE",
  MOUNTED_RECOVERY_POINTS: "MOUNTED_RECOVERY_POINT",
  VIRTUAL_STANDBY: "VIRTUAL_STANDBY",
  POLICY: "POLICY",
  POLICY_TASK_REPORT: "POLICY_TASK_REPORT",
  ALERT_EMAIL_RECIPIENT: "ALERT_EMAIL_RECIPIENT",
  MANAGE_REPORT_SCHEDULES: "REPORT_SCHEDULE",
  STORED_DATA_REPORT_MSP: "STORED_DATA_REPORT_MSP",
  STORED_DATA_REPORT_DIRECT: "STORED_DATA_REPORT_DIRECT",
  RECOVERY_POINT_REPORT_MSP: "SOURCE_RPO_REPORT",
  RECOVERY_POINT_REPORT_DIRECT: "SOURCE_RPO_REPORT_DIRECT",
  RECOVERED_RESOURCES: "DISASTER_RECOVERY",
  INSTANT_VMS_RR: "INSTANT_VM",
  RECOVERY_POINT_SERVERS: "RECOVERY_POINT_SERVER",
  DATA_STORES: "DATASTORE",
  CUSTOMER_ACCOUNT: "CUSTOMER_ACCOUNT",
  ARCSERVE_CLOUD: "ARCSERVE_CLOUD",
  ACCOUNT_DETAILS: "CUSTOMER_ACCOUNT_REPORT",
  SOURCE_PROTECTION_REPORT_MSP: "SOURCE_PROTECTION_REPORT",
  SOURCE_PROTECTION_REPORT_DIRECT: "SOURCE_PROTECTION_REPORT_DIRECT",
  AUDIT_TRAIL: "AUDIT_TRAIL"
};

export const SECONDARY_NAVIGATION_COLUMN_TYPE_MAPPING = {
  [SECONDARY_NAVIGATION_TYPE.SOURCES]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.MACHINES]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES]: COLUMN_TYPE.DESTINATION,
  [SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE]: COLUMN_TYPE.DESTINATION,
  [SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD]: COLUMN_TYPE.ARCSERVE_CLOUD,
  [SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS]: COLUMN_TYPE.RECOVERED_VMS,
  [SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS]:
    COLUMN_TYPE.MOUNTED_RECOVERY_POINTS,
  [SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY]: COLUMN_TYPE.VIRTUAL_STANDBY,
  [SECONDARY_NAVIGATION_TYPE.POLICIES]: COLUMN_TYPE.POLICY,
  [SECONDARY_NAVIGATION_TYPE.HYPERVISORS]: COLUMN_TYPE.HYPERVISOR,
  [SECONDARY_NAVIGATION_TYPE.JOBS]: COLUMN_TYPE.JOB,
  [SECONDARY_NAVIGATION_TYPE.LOG]: COLUMN_TYPE.LOG,
  [SECONDARY_NAVIGATION_TYPE.SOURCE_LOG]: COLUMN_TYPE.LOG,
  [SECONDARY_NAVIGATION_TYPE.SOURCE_JOBS]: COLUMN_TYPE.JOB,
  [SECONDARY_NAVIGATION_TYPE.BACKUP_JOB_REPORT]: COLUMN_TYPE.BACKUP_JOB_REPORT,
  [SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_SERVERS]:
    COLUMN_TYPE.RECOVERY_POINT_SERVERS,
  [SECONDARY_NAVIGATION_TYPE.DATA_STORES]: COLUMN_TYPE.DATA_STORES,
  [SECONDARY_NAVIGATION_TYPE.RESTORE_JOB_REPORT]:
    COLUMN_TYPE.RESTORE_JOB_REPORT,
  [SECONDARY_NAVIGATION_TYPE.POLICY_TASKS_REPORT]:
    COLUMN_TYPE.POLICY_TASK_REPORT,
  [SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS]: COLUMN_TYPE.USER,
  [SECONDARY_NAVIGATION_TYPE.ALERTS]: COLUMN_TYPE.ALERT_EMAIL_RECIPIENT,
  [SECONDARY_NAVIGATION_TYPE.MANAGE_REPORT_SCHEDULES]:
    COLUMN_TYPE.MANAGE_REPORT_SCHEDULES,
  [SECONDARY_NAVIGATION_TYPE.CAPACITY_USAGE_REPORT]: {
    MSP: COLUMN_TYPE.STORED_DATA_REPORT_MSP,
    DIRECT: COLUMN_TYPE.STORED_DATA_REPORT_DIRECT
  },
  [SECONDARY_NAVIGATION_TYPE.RECOVERY_POINT_REPORT]: {
    MSP: COLUMN_TYPE.RECOVERY_POINT_REPORT_MSP,
    DIRECT: COLUMN_TYPE.RECOVERY_POINT_REPORT_DIRECT
  },
  [SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES]:
    COLUMN_TYPE.RECOVERED_RESOURCES,
  [SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR]: COLUMN_TYPE.INSTANT_VMS_RR,
  [SECONDARY_NAVIGATION_TYPE.CUSTOMER_ACCOUNT]: COLUMN_TYPE.CUSTOMER_ACCOUNT,
  [SECONDARY_NAVIGATION_TYPE.ACCOUNT_DETAILS]: COLUMN_TYPE.ACCOUNT_DETAILS,
  [SECONDARY_NAVIGATION_TYPE.SOURCE_PROTECTION_REPORT]: {
    MSP: COLUMN_TYPE.SOURCE_PROTECTION_REPORT_MSP,
    DIRECT: COLUMN_TYPE.SOURCE_PROTECTION_REPORT_DIRECT
  },
  [SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS]: COLUMN_TYPE.SOURCE,
  [SECONDARY_NAVIGATION_TYPE.AUDIT_LOGS]: COLUMN_TYPE.AUDIT_TRAIL
};

/**
 * Secondary Navigation key
 * for navigation keys
 * @type {{}}
 */
export const SECONDARY_NAVIGATION_KEYS = {
  SOURCES: "sources",
  PHYSICAL_MACHINES: "physical_machines",
  VIRTUAL_MACHINES: "virtual_machines",
  POLICIES: "policies",
  RECOVERED_VMS: "recovered_vms",
  MOUNTED_RECOVERY_POINTS: "mounted_recovery_points",
  REPORTS: "reports",
  SITES: "sites",
  PROXIES: "proxies",
  ARCSERVE_CLOUD: "arcserve_cloud",
  CLOUD_ACCOUNT: "cloud_account",
  DATA_STORE: "data_stores",
  RECOVERY_POINT_SERVER: "recovery_point_servers",
  SHARED_FOLDER: "shared_folder",
  UNC_NFS_PATHS: "unc_nfs_paths",
  DESTINATIONS: "destinations",
  ENTITLEMENTS: "license_subscriptions",
  BRANDING: "branding",
  RECOVERED_RESOURCES: "recovered_resources",
  INSTANT_VMS_RR: "instant_vms_rr",
  USER_ACCOUNTS: "user_accounts",
  ACCESS_CONTROL: "access_control",
  USER_ROLES: "user_roles",
  ORACLE_HOSTS: "oracle_hosts",
  AUDIT_LOGS: "audit_logs"
};

export const SECONDARY_NAVIGATION_KEYS_CLOUD_HYBRID = [
  SECONDARY_NAVIGATION_KEYS.CLOUD_ACCOUNT,
  SECONDARY_NAVIGATION_KEYS.DATA_STORE,
  SECONDARY_NAVIGATION_KEYS.RECOVERY_POINT_SERVER,
  SECONDARY_NAVIGATION_KEYS.SHARED_FOLDER
];
export const SECONDARY_NAVIGATION_TYPE_UI_FILTER = [
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY
];
/**
 * Secondary Navigation Parent Mapping
 * for navigation keys
 * @type {{}}
 */
export const SECONDARY_NAVIGATION_PARENT_MAPPING = {
  physical_machines: "Sources",
  virtual_machines: "Sources",
  office_365: "Sources",
  share_point: "Sources",
  virtual_standby: "Recovered Resources",
  virtual_standbys: "Disaster Recovery",
  recovered_vms: "Disaster Recovery",
  mounted_recovery_points: "Disaster Recovery",
  machines: "Sources",
  agentless_vms: "Sources",
  instant_vm: "Recovered Resources",
  cloud_volumes: "Destinations",
  cloud_stores: "Destinations",
  hypervisors: "Infrastructure",
  sites: "Infrastructure",
  proxies: "Infrastructure",
  network_configuration: "Infrastructure",
  storage_arrays: "Infrastructure",
  "Storage Arrays": "infrastructure",
  oracle_hosts: "Infrastructure",
  "Oracle Hosts": "infrastructure",
  user_accounts: "Access Control",
  user_roles: "Access Control",
  backup_jobs: "Reports",
  restore_jobs: "Reports",
  data_transfer: "Reports",
  capacity_usage: "Reports",
  manage_schedules: "Reports",
  jobs_and_destinations: "Reports",
  Machines: "Sources",
  "Agentless VMs": "Sources",
  "UNC/NFS Paths": "Sources",
  "Office 365": "Sources",
  SharePoint: "Sources",
  "Cloud Direct Volumes": "Destinations",
  "Cloud Hybrid Stores": "Destinations",
  "Virtual Standby": "Disaster Recovery",
  "Instant VMs": "Virtual Standby",
  Policies: "Policies",
  "Network Configuration": "Hypervisors",
  Protect: "Sources",
  Analyze: "Jobs",
  Monitor: "Monitor",
  Configure: "Hypervisors",
  Recover: "Recover",
  Infrastructure: "Hypervisors",
  Sites: "Infrastructure",
  Proxies: "Infrastructure",
  Sources: "Sources",
  Destinations: "Destinations",
  Jobs: "Jobs",
  Logs: "Log",
  Alerts: "Analyze",
  "Access Control": "User Accounts",
  "User Accounts": "User Accounts",
  Roles: "User Accounts",
  Hypervisors: "Hypervisors",
  "Backup Jobs": "Reports",
  "Policy Tasks": "Reports",
  "Recovery Jobs": "Reports",
  "Data Transfer": "Reports",
  "Capacity Usage": "Reports",
  "Manage Report Schedules": "Reports",
  "Jobs & Destinations": "Reports",
  "Recovery Point Servers": "Recovery Point Servers",
  "Linux Backup Servers": "Linux Backup Servers",
  cd_trial: "Cloud Direct Trial",
  cd_exemption: "Cloud Direct Trial Exemptions",
  cd_near_capacity: "Cloud Direct Near Capacity",
  cd_over_capacity: "Cloud Direct Over Capacity",
  cd_expiration: "Cloud Direct Expiration",
  ch_trial: "Cloud Hybrid Trial",
  ch_exemption: "Cloud Hybrid Trial Exemptions",
  ch_near_capacity: "Cloud Hybrid Near Capacity",
  ch_over_capacity: "Cloud Hybrid Over Capacity",
  ch_expiration: "Cloud Hybrid Expiration",
  arcserve_cloud: "Destinations",
  recovery_point_servers: "Destinations",
  "Data Stores": "Destinations",
  data_stores: "Destinations",
  cloud_account: "Destinations",
  remote_console: "Destinations",
  shared_folder: "Destinations",
  unc_nfs_paths: "Sources",
  udp_trial: "UDP Trial Report",
  customer_report: "UDP Customer Report",
  instant_vms_rr: "Disaster Recovery",
  "Mounted Recovery Points": "Disaster Recovery",
  account_details: "Reports",
  recovery_point_report: "Reports",
  source_protection: "Reports",
  source_discovery: "Settings",
  default_deployment: "Settings"
};

/**
 * Secondary Navigation Parent Mapping
 * for navigation keys
 * @type {{}}
 */
export const SECONDARY_NAVIGATION_MAPPING = {
  Protect: "sources",
  Analyze: "jobs",
  Monitor: "Monitor",
  Configure: "hypervisors",
  Recover: "Recover",
  Infrastructure: "hypervisors",
  Sites: "hypervisors",
  Proxies: "hypervisors",
  sources: "sources",
  destinations: "destinations",
  cloud_volumes: "destinations",
  cloud_stores: "destinations",
  policies: "policies",
  jobs: "jobs",
  logs: "log",
  alerts: "alerts",
  access_control: "user_accounts",
  "Network Configuration": "hypervisors",
  Machines: "sources",
  "Office 365": "sources",
  SharePoint: "sources",
  "Agentless VMs": "sources",
  "UNC/NFS Paths": "sources",
  "Cloud Direct Volumes": "destinations",
  "Cloud Hybrid Stores": "destinations",
  "Virtual Standby": "recovered_resources",
  "Instant VMs": "virtual_standby",
  Hypervisors: "hypervisors",
  // "Arcserve Cloud": "arcserve_cloud",
  "User Accounts": "user_accounts",
  Roles: "user_accounts",
  "Backup Jobs": "reports",
  "Policy Tasks": "reports",
  "Recovery Jobs": "reports",
  "Data Transfer": "reports",
  "Capacity Usage": "reports",
  "Jobs & Destinations": "reports",
  "Manage Report Schedules": "reports",
  "Storage Arrays": "storage_arrays",
  "Oracle Hosts": "oracle_hosts",
  DRaaS: "recovered_resources",
  "Mounted Recovery Points": "recovered_resources"
};

/**
 * Secondary Navigation Parent URL Mapping
 * for navigation keys
 * @type {{}}
 */
export const SECONDARY_NAVIGATION_PARENT_URL_MAPPING = {
  physical_machines: "sources/all",
  virtual_machines: "sources/all",
  unc_nfs_paths: "sources/all",
  recovered_vms: "recovered_resources/recovered_vms",
  office_365: "sources/all",
  share_point: "sources/all",
  virtual_standby: "recovered_resources/virtual_standby",
  instant_vm: "recovered_resources/instant_vm",
  cloud_volumes: "destinations/all",
  cloud_stores: "destinations/all",
  hypervisors: "infrastructure/hypervisors",
  network_configuration: "infrastructure/hypervisors",
  user_accounts: "access_control/user_accounts",
  user_roles: "access_control/user_accounts",
  Machines: "sources",
  "Agentless VMs": "sources",
  "UNC/NFS Paths": "sources",
  "Office 365": "sources",
  "Virtual Standby": "recovered_resources",
  "Instant VMs": "recovered_resources",
  "Cloud Direct Volumes": "destinations",
  "Cloud Hybrid Stores": "destinations",
  "Arcserve Cloud": "destinations",
  "Shared Folders": "destinations",
  SharePoint: "sources",
  Policies: "policies",
  Hypervisors: "infrastructure",
  Sites: "infrastructure/hypervisors",
  Proxies: "infrastructure/hypervisors",
  backup_jobs: "reports/all",
  restore_jobs: "reports/all",
  report_detail: "reports/all",
  data_transfer: "reports/all",
  capacity_usage: "reports/all",
  manage_schedules: "reports/all",
  jobs_and_destinations: "reports/all",
  arcserve_cloud: "destinations/arcserve_cloud",
  "Data Stores": "destinations",
  remote_console: "destinations/remote_console",
  shared_folder: "destinations/arcserve_cloud",
  "Recovery Point Servers": "destinations",
  "Storage Arrays": "infrastructure",
  storage_arrays: "infrastructure/hypervisors",
  oracle_hosts: "infrastructure/hypervisors",
  account_details: "reports/all",
  recovery_point_report: "reports/all",
  source_protection: "reports/all",
  source_discovery: "settings/source_discovery",
  default_deployment: "settings/source_discovery",
  mounted_recovery_points: "recovered_resources/recovered_vms",
  instant_vms_rr: "recovered_resources/recovered_vms",
  virtual_standbys: "recovered_resources/recovered_vms",
  recovery_point_servers: "destinations/arcserve_cloud",
  data_stores: "destinations/arcserve_cloud",
  cloud_account: "destinations/arcserve_cloud"
};

/**
 * Secondary Navigation Parent URL Mapping
 * for navigation keys
 * @type {{}}
 */
export const PRIMARY_NAVIGATION_URL_MAPPING = {
  Protect: "/protect/sources/all",
  Analyze: "/analyze/jobs/all",
  Monitor: "/monitor",
  Configure: "/configure/infrastructure/hypervisors",
  Recover: "/recover",
  Machine: "/protect/sources/physical_machines",
  Agentless_vms: "/protect/sources/virtual_machines",
  Cloud_Hybrid: "/csr/cloud-hybrid/recovery-point-servers",
  Reports: "/csr/bi-reporting/cd_trial"
};

export const PRIMARY_NAVIGATION_URL_MAPPING_MSP_LEVEL = {
  Protect: "/protect/sources/all",
  Analyze: "/analyze/jobs/all",
  Monitor: "/monitor",
  Configure: "/configure/access_control/user_accounts",
  Recover: "/recover",
  Machine: "/protect/sources/physical_machines",
  Agentless_vms: "/protect/sources/virtual_machines",
  Cloud_Hybrid: "/csr/cloud-hybrid/recovery-point-servers",
  Reports: "/csr/bi-reporting/cd_trial"
};

export const SECONDARY_NAVIGATION = {
  MONITOR: "Monitor",
  PROTECT: "Protect",
  ANALYZE: "Analyze",
  RECOVER: "Recover",
  CONFIGURE: "Configure"
};

/**
 * CRUD Action types
 * @type {{}}
 */
export const ACTION_TYPE = {
  CREATE: "create",
  EDIT: "edit",
  DETAILS: "details",
  ADD: "add",
  REMOVE: "remove",
  LOG: "log"
};

/**
 * SPOG product
 * @type {{CLOUD_DIRECT: string, CLOUD_HYBRID: string}}
 */
export const SPOG_PRODUCTS = {
  CLOUD_DIRECT: "clouddirect",
  CLOUD_HYBRID: "cloudhybrid",
  UDP: "udp",
  TRIAL_KEY: "trial"
};

/**
/** *
 *  Route for the components
 * @type {{POLICIES: string}}
 */
export const PAGE_ROUTE = {
  CSR_POLICIES: "/csr/protect/policies",
  CSR_JOBS: "/csr/analyze/jobs",
  ANALYZE: "/analyze",
  JOBS: "/analyze/jobs",
  REPORTS: "/analyze/reports",
  SOURCES: "/protect/sources",
  POLICIES: "/protect/policies",
  DESTINATIONS: "/protect/destinations",
  CUSTOMER_ACCOUNTS: "/protect/customer-accounts",
  HYPERVISORS: "/configure/infrastructure/hypervisors",
  // ARCSERVE_CLOUD: "/configure/infrastructure/arcserve_cloud",
  SOURCE_GROUPS: "/configure/source_groups",
  RECOVERY_POINT_SERVER: "/protect/destinations/recovery_point_servers",
  // DS_BROWSE_SOURCES: "/protect/destinations/datastores",
  DATA_STORE: "/protect/destinations/data_stores",
  SHARED_FOLDER: "/protect/destinations/shared_folder"
};

/**
/** *
 *  Route for the components
 * @type {{POLICIES: string}}
 */
export const PRIMARY_NAVIGATION_ROUTE = {
  PROTECT: "/protect/sources/all",
  ANALYZE: "/analyze/jobs/all",
  CONFIGURE: "/configure/infrastructure/hypervisors",
  RECOVER: "/recover",
  MONITOR: "/protect/destinations"
};

/**
 * Contextual actions
 */

export const CONTEXTUAL_ACTION_DROPDOWN = {
  startbackup: "Start Backup",
  stopbackup: "Stop Backup",
  upgradeagent: "Upgrade Agent",
  viewlog: "View Logs",
  canceljob: "Cancel Job",
  cancelrecovery: "Cancel Recovery"
};

export const CONTEXTUAL_ACTION = {
  VIEW_LOG: "viewlogs",
  CANCEL_JOB: "canceljob",
  CANCEL_RECOVERY: "cancelrecovery",
  RUN_REPORT_NOW: "create_now",
  EDIT_REPORT_SETTINGS: "edit_report",
  DELETE_REPORT_BY_ID: "delete_report",
  DELETE_SCHEDULE_REPORT: "delete_schedule",
  CREATE_REPORT: "create_now",
  DELETE_HYPERVISOR: "delete",
  DELETE_STORAGE_ARRAY: "delete",
  UPDATE_VM_INFORMATION: "update_vm_information",
  UPDATE: "update",
  UNMOUNT: "mounted.rps.action.dismount",
  UPDATE_STORAGE_ARRAY: "modify",
  DELETE: "delete",
  MODIFY: "modify",
  TARGET_VM_CONFIGURATION: "target_vm_configuration",
  VIRTUAL_STANDBY_PAUSE: "vsb_pause",
  VIRTUAL_STANDBY_RESUME: "vsb_resume",
  HEARTBEAT_PAUSE: "heart_beat_pause",
  HEARTBEAT_RESUME: "heart_beat_resume",
  START_VM: "start_vm",
  STANDBY_VM: "standby_vm",
  START: "start",
  STOP: "stop",
  RESTART: "restart",
  STANDBY_VM_NETWORK_CONFIGURATION: "standbyvmnetworkconfiguration",
  ASSIGN_POLICY: "assignpolicy",
  VIEW_SOURCE: "view_source",
  DELETE_JOB: "deletejob",
  UPDATE_HYPERVISOR: "configure.infrastructure.hypervisors.action.update",
  REFRESH_HYPERVISOR: "configure.infrastructure.hypervisors.action.refresh",
  PAUSE_AUTO_RECOVERY: "pause_auto_recovery",
  RESUME_AUTO_RECOVERY: "resume_auto_recovery",
  TERMINATE_GCP_INSTANCE: "terminate_gcp_instance",
  TERMINATE_EC2_INSTANCE: "terminate_ec2_instance"
};

/**
 * Recovery point status
 * @type {{}}
 */
export const STATUS = {
  PINNED: "pinned",
  UNPINNED: "unpinned",
  HIDEPIN: "hidepin"
};

export const CD_RECOVER_POINT_BROWSE_CURRENT_ID = -1;
export const CD_RECOVER_POINT_BROWSE_CURRENT_RP_NAME =
  "protect.source.recoveryPoints.recoverFromCurrent";
export const CD_RESTORE_VMWARE_BASE_PATH = /^\/ZettaMirror\/zsystem\d+\/VMWARE\/$/;
// eslint-disable-next-line no-useless-escape
export const CD_RESTORE_HYPERV_BASE_PATH = /^\/ZettaMirror\/zsystem\d+\/HYPERV\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\/$/;
/**
 * Date/Time formats to be used across application
 * @type {{}}
 */
export const DATEFORMAT = {
  DATE_LONG_US: "MM/DD/YYYY",
  DATE_LONG: "DD/MM/YYYY",
  DATETIME_LONG: "DD/MM/YYYY hh:mm A",
  // DATETIME_GRID: "MM/DD/YYYY hh:mm A",
  DATETIME_GRID: "L LT",
  DATETIME_DURATION: "HH:mm:ss",
  LATEST_JOBS: "ddd MMM DD, YYYY hh:mm A (Z)"
};

/**
 * Destination types in policy
 * @type {{PRIMARY: string, SECONDARY: string}}
 */
export const DESTINATION_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  ALL: "all",
  SHARE_FOLDER: "shared_folder",
  CLOUD_DEDUPE_STORE: "cloud_hybrid_store",
  CLOUD_DIRECT_VOLUME: "cloud_direct_volume",
  CLOUD_DIRECT: "cloud_direct"
};

/**
 * For external links
 */
export const EXTERNAL_LINKS = {
  MESSAGE_DETAILS: "" // yet to be provided
};

/**
 * ToDo Remove (when actual api integrated)
 * For Mock API keys
 * @type {{NAME: string}}
 */
export const MOCK_API_KEYS = {
  NAME: "name",
  DESTINATION: "destination_name",
  CUSTOMER_ACCOUNTS: "organization_name"
};

/**
 * Advance search name, key and filter key (field) constants
 * for dropdown options values those are coming from api
 *
 * @type {{NAME: string}}
 * @type {{KEY: string}}
 * @type {{FIELD: string}}
 */

export const ADVANCED_SEARCH_API_OPTIONS_KEYS = {
  SOURCE_GROUPS: {
    NAME: "Source Group",
    KEY: "group_id",
    FIELD: "group_name"
  },
  PROTECTION_POLICY: {
    NAME: "Protection Policy",
    KEY: "policy_id",
    FIELD: "policy_name"
  },
  SOURCE: {
    NAME: "Source",
    KEY: "source_id",
    FIELD: "source_name"
  },
  SITES: {
    NAME: "Sites",
    KEY: "site_id",
    FIELD: "site_name"
  },
  USER_ROLES: {
    NAME: "Role",
    KEY: "role_id",
    FIELD: "role_name"
  },
  DESTINATIONS: {
    NAME: "Destination",
    KEY: "destination_id",
    FIELD: "destination_name",
    TYPE: "destinations"
  },
  ORGANIZATIONS: {
    NAME: "Organization",
    KEY: "organization_id",
    FIELD: "organization_name",
    TYPE: "sub_organization_id"
  },
  GENERATED_FROM: {
    NAME: "generated_from",
    KEY: "generated_from_id",
    FIELD: "generated_from_name"
  },
  DATASTORES: {
    NAME: "Data Stores",
    KEY: "datastore_id",
    FIELD: "datastore_name",
    TYPE: "datastore"
  },
  USERS: {
    NAME: "User Name",
    KEY: "user_ids",
    FIELD: "user_name",
    TYPE: "user"
  }
};

export const SEARCH_FILTERS_TYPE = {
  group_id: "mutiple",
  policy_id: "multiple",
  role_id: "multiple",
  destination_id: "multiple",
  organization_id: "multiple",
  generated_from_id: "multiple",
  date_range: "single"
};

/**
 * Edit policy form name for each tab
 * @type {{BASIC: string, SOURCE: string, SLA: string, DESTINATION: string, SCHEDULE: string}}
 */
export const POLICY_DETAIL_FORM = {
  BASIC: "basicTabForm",
  SOURCE: "sourceTabForm",
  SLA: "slaTabForm",
  DESTINATION: "destinationTabForm",
  SCHEDULE: "scheduleTabForm"
};

export const BROWSE_REMOTE_CONSOLE_TYPE = {
  RPS_JUMPSTART: "RPS_JUMPSTART",
  SHARED_FOLDER: "SHARED_FOLDER",
  IMPORT_DATA_STORE: "IMPORT_DATASTORE",
  ASSURED_RECOVERY: "ASSURED_RECOVERY",
  RESOURCE_POOL: "RESOURCE_POOL",
  RECOVERY_RESOURCE_POOL: "RECOVERY_RESOURCE_POOL",
  HYPERV: "HYPERV",
  LINUX: "LINUX"
};

export const BROWSE_REMOTE_CONSOLE_PATH = {
  [BROWSE_REMOTE_CONSOLE_TYPE.RPS_JUMPSTART]: "path",
  [BROWSE_REMOTE_CONSOLE_TYPE.SHARED_FOLDER]: "path",
  [BROWSE_REMOTE_CONSOLE_TYPE.IMPORT_DATA_STORE]: "datastore_path",
  [BROWSE_REMOTE_CONSOLE_TYPE.ASSURED_RECOVERY]: "path"
};

/**
 * form for Add an Instant VM
 */
export const CREATE_VM_FORM = "createVM";

export const EXCLUDE_USER_DEFINED_SEARCH = ["source_jobs"];

export const EXCLUDE_SYSTEM_DEFINED_SEARCH = [
  "source_protection",
  "recovery_point_report"
];
/**
 * Search filter by url mapping
 */
export const SEARCH_FILTERS = {
  jobs: "jobfilters",
  source_jobs: "jobfilters",
  rps_jobs: "jobfilters",
  data_store_jobs: "jobfilters",
  sources: "sourcefilters",
  user_accounts: "userfilters",
  destinations: "destinationfilters",
  physical_machines: "sourcefilters",
  virtual_machines: "sourcefilters",
  office_365: "sourcefilters",
  share_point: "sourcefilters",
  machine: "sourcefilters",
  shared_folder: "sourcefilters",
  agentless_vm: "sourcefilters",
  unc_nfs_paths: "sourcefilters",
  cloud_direct_volume: "destinationfilters",
  cloud_dedupe_store: "destinationfilters",
  cloud_hybrid_store: "destinationfilters",
  cloud_volumes: "destinationfilters",
  cloud_stores: "destinationfilters",
  policies: "policyfilters",
  recovered_vms: "recoveredresourcesfilters",
  reports: "reportfilters",
  backup_jobs: "reportfilters",
  recovery_point_report: "reportfilters",
  policy_tasks: "reportfilters",
  restore_jobs: "reportfilters",
  report_detail: "reportfilters",
  data_transfer: "reportfilters",
  capacity_usage: "reportfilters",
  jobs_and_destinations: "reportfilters",
  recovered_resources: "recoveredresourcesfilters",
  instant_vms_rr: "recoveredresourcesfilters",
  account_details: "reportfilters",
  source_protection: "reportfilters",
  "customer-accounts": "userfilters",
  virtual_standbys: "virtual_standby_filter",
  audit_logs: "audit_filter"
};

/**
 * Search filter by url mapping
 */
export const SAVED_SEARCH_FILTERS_KEYS_MAPPING = {
  jobs: "job_filter",
  source_jobs: "job_filter",
  rps_jobs: "job_filter",
  sources: "source_filter",
  user_accounts: "user_filter",
  destinations: "destination_filter",
  physical_machines: "source_filter_machine",
  unc_nfs_paths: "source_filter_shared_folder",
  virtual_machines: "source_filter_agentless_vm",
  office_365: "source_filter",
  share_point: "source_filter",
  machine: "source_filter_machine",
  agentless_vm: "source_filter_agentless_vm",
  shared_folder: "source_filter",
  cloud_direct_volume: "destination_filter_cloud_direct_volume",
  cloud_volumes: "destination_filter_cloud_direct_volume",
  cloud_stores: "destination_filter_cloud_hybrid_store",
  cloud_hybrid_store: "destination_filter_cloud_hybrid_store",
  policies: "policy_filter",
  reports: "report_filter",
  backup_jobs: "report_filter_backup_jobs",
  recovery_point_report: "report_filter_source_rpo",
  policy_tasks: "report_filter_policy_tasks",
  restore_jobs: "report_filter_restore_jobs",
  report_detail: "report_filter",
  data_transfer: "report_filter_data_transfer",
  capacity_usage: "report_filter_capacity_usage",
  jobs_and_destinations: "report_filter_jobs_and_destinations",
  recovered_vms: "draas_filter",
  data_store_jobs: "job_filter",
  recovered_resources: "recoveredresource_filter",
  instant_vms_rr: "recoveredresource_filter",
  account_details: "report_filter_account_details",
  source_protection: "report_filter_source_protection",
  virtual_standbys: "virtual_standby_filter",
  "customer-accounts": "tentant_filter",
  audit_logs: "audit_filter"
};

export const SYSTEM_SEARCH_FILTERS_KEYS_MAPPING = {
  jobs: "job_filter_global",
  source_jobs: "job_filter_global",
  rps_jobs: "job_filter_global",
  data_store_jobs: "job_filter_global",
  sources: "source_filter_global",
  user_accounts: "user_filter_global",
  destinations: "destination_filter_global",
  physical_machines: "source_filter_global",
  virtual_machines: "source_filter_global",
  office_365: "source_filter_global",
  share_point: "source_filter_global",
  machine: "source_filter_global",
  agentless_vm: "source_filter_global",
  unc_nfs_paths: "source_filter_global",
  shared_folder: "source_filter_global",
  cloud_direct_volume: "destination_filter_global",
  cloud_volumes: "destination_filter_global",
  cloud_stores: "destination_filter_global",
  cloud_hybrid_store: "destination_filter_global",
  policies: "policy_filter_global",
  reports: "report_filter_global",
  backup_jobs: "report_filter_global",
  policy_tasks: "report_filter_global",
  restore_jobs: "report_filter_global",
  source_protection: "report_filter_global",
  report_detail: "report_filter_global",
  data_transfer: "report_filter_global",
  capacity_usage: "report_filter_global",
  jobs_and_destinations: "report_filter_global",
  recovered_vms: "recoveredresource_filter_global",
  recovered_resources: "recoveredresource_filter_global",
  instant_vms_rr: "recoveredresource_filter_global",
  account_details: "report_filter_global",
  virtual_standbys: "recoveredresource_filter_global",
  "customer-accounts": "tentant_filter_global",
  audit_logs: "audit_filter_global"
};

/**
 * map the searchText with the actual key for individual modules
 */
export const SEARCH_TEXT_MAPPING = {
  user_accounts: "search_string",
  destinations: "destination_name",
  sources: "source_name",
  jobs: "source_name",
  logs: "source_name",
  physical_machines: "source_name",
  virtual_machines: "source_name",
  virtual_standbys: "recoveredresource_name",
  office_365: "source_name",
  share_point: "source_name",
  machine: "source_name",
  agentless_vm: "source_name",
  unc_nfs_paths: "source_name",
  shared_folder: "source_name",
  cloud_direct_volume: "destination_name",
  cloud_hybrid_store: "destination_name",
  source_groups: "group_name",
  cloud_stores: "destination_name",
  cloud_volumes: "destination_name",
  policies: "policy_name",
  recovered_vms: "recoveredresource_name",
  mounted_recovery_points: "mounted_recovery_points",
  reports: "report_name",
  backup_jobs: "source_name",
  policy_tasks: "source_name",
  restore_jobs: "source_name",
  report_detail: "source_name",
  data_transfer: "source_name",
  capacity_usage: "destination_name",
  recovery_point_report: "source_name",
  manage_schedules: "source_name",
  jobs_and_destinations: "source_name",
  recovered_resources: "recoveredresource_name",
  instant_vms_rr: "source_name",
  account_details: "organization_name",
  source_protection: "source_name",
  "customer-accounts": "organization_name"
};

/**
 * Http Status code
 * @type {{OK: number, CREATED: number, BAD_REQUEST: number, UNAUTHORIZED: number, FORBIDDEN: number, NOT_FOUND: number, METHOD_NOT_ALLOWED: number, GONE: number, INTERNAL_SERVER_ERROR: number}}
 */
export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 403,
  FORBIDDEN: 401,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  INTERNAL_SERVER_ERROR: 500,
  SUCCESS: "SUCCESS"
};

export const SERVER_TYPES = {
  RPS: "recovery_point_server",
  SITE: "site",
  PROXY: "proxy"
};

export const JOB_STATUS = {
  ACTIVE: "active",
  FINISHED: "finished",
  CANCELED: "canceled",
  FAILED: "failed",
  INCOMPLETE: "incomplete",
  WARNING: "warning",
  IDLE: "idle",
  WAITING: "waiting",
  CRASH: "crash",
  LICENSE_FAILED: "license_failed",
  BACKUPJOB_PROC_EXIT: "backupjob_proc_exit",
  SKIPPED: "skipped",
  STOP: "stop",
  MISSED: "missed"
};

export const AREA_CHART_COLOR_SCHEMA = [
  "#8E90D7",
  "#A2D9EF",
  "#4894B3",
  "#83D3C0",
  "#5DC39A",
  "#9E4A89",
  "#5395B1",
  "#529499",
  "#A3DAF0",
  "#AFDB7D",
  "#FDC9A9",
  "#999BDB",
  "#FACC58",
  "#FF6767",
  "#F37E6B",
  "#FFA957",
  "#EE8D8D",
  "#9F9CA6",
  "#6463A3",
  "#8DAFEE",
  "#6B8C7B",
  "#B15384",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF"
];

/**
 * Donut chart customer accnt colr schema
 * @type {{}}
 */

export const METRICS_DONUT_CHART_COLOR_SCHEMA = ["#4BBFA5", "#F96B81"];

/**
 * Donut chart configuration
 * @type {{}}
 */
export const METRICS_DONUT_CHART_CONFIGURATION = [
  { name: "Protected", color: "#4BBFA5", quantity: 720, percentage: 60 },
  { name: "Not Protected", color: "#F96B81", quantity: 480, percentage: 40 }
];

export const MSP_ORG_TYPE = "msp";
export const MSP_ADMIN = "msp_admin";
export const MSP_ACCOUNT_ADMIN = "msp_account_admin";
export const SUB_MSP_ORG_TYPE = "msp_child";
export const IMCC_ORG_TYPE = "IMCC";

export const ID_CONSTANTS = {
  ACTION_DROPDOWN_MENUITEM: "MenuItem"
};

export const DEFAULT_MESSAGES = {
  CURRENT_SEARCH_CRITERIA_TAG: "{count} Selected",
  ACTION_DROPDOWN: "Actions"
};

export const CSR_PRODUCT_API_RESPONSE_CONTEXT = {
  all: {
    resume: "csr.alert.enable_product",
    suspend: "csr.alert.suspend_product"
  },
  cloud_direct: {
    resume: "csr.alert.enable_cloud_direct",
    suspend: "csr.alert.suspend_cloud_direct"
  },
  cloud_hybrid: {
    resume: "csr.alert.enable_cloud_hybrid",
    suspend: "csr.alert.suspend_cloud_hybrid"
  },
  udp: {
    resume: "csr.alert.enable_udp",
    suspend: "csr.alert.suspend_udp"
  }
};

export const CSR_API_RESPONSE_CONTEXT = {
  UPDATE_ORGANIZATION: "csr.alert.update_organization",
  ENROLL_ORGANIZATION: "csr.alert.enroll_organization",
  DELETE_ORGANIZATION: "csr.alert.delete_organization",
  SUSPEND_ORGANIZATION: "csr.alert.suspend_organization",
  ENABLE_ORGANIZATION: "csr.alert.enable_organization",
  DISABLE_POLICY_ORGANIZATION: "csr.alert.disable_policy_organization",
  ENABLE_POLICY_ORGANIZATION: "csr.alert.enable_policy_organization",
  INVALIDATE_CACHE_FOR_ORGANIZATION:
    "csr.alert.invalidate_cache_for_organization",
  CREATE_RECOVERY_POINT_SERVER_REQUEST: "csr.alert.create_rps",
  DELETE_RECOVERY_POINT_SERVER_REQUEST: "csr.alert.delete_rps",
  UPDATE_RECOVERY_POINT_SERVER_REQUEST: "csr.alert.update_rps",
  START_DATASTORE_SUCCESS: "csr.alert.start_datastore",
  STOP_DATASTORE_SUCCESS: "csr.alert.stop_datastore",
  DESTROY_DATASTORE: "csr.alert.destroy_datastore",
  DELETE_LINUX_BACKUP_SERVER_REQUEST: "csr.alert.delete_lbs",
  CREATE_LINUX_BACKUP_SERVER_REQUEST: "csr.alert.create_lbs",
  UPDATE_LINUX_BACKUP_SERVER_REQUEST: "csr.alert.update_lbs",
  CREATE_DATA_STORE: "csr.alert.create_data_store",
  UPDATE_DATA_STORE: "csr.alert.update_data_store",
  CREATE_USER: "csr.alert.create_user",
  UPDATE_USER: "csr.alert.update_user",
  DELETE_USER: "csr.alert.delete_user",
  RESET_PASSWORD_EMAIL_SENT: "csr.alert.reset_password_email_sent",
  VERIFICATION_EMAIL_SENT: "csr.alert.verification_email_sent",
  ACTIVATE_CLOUD_DIRECT: "csr.alert.activate_cloud_direct",
  ACTIVATE_CLOUD_HYBRID: "csr.alert.activate_cloud_hybrid",
  ACTIVATE_UDP: "csr.alert.activate_UDP",
  RECIPIENTS_SUCCESS: "csr.alert.recipients_success",
  UPLOAD_FILE_SUCCESS: "csr.org_search.upload_the_download_content_csv_success",
  UPLOAD_HELP_LINK_SUCCESS: "csr.org_search.upload_help_link_csv_success",
  USER_PASSWORD_UPDATED: "csr.user.password.update.success"
};

export const API_RESPONSE_CONTEXT = {
  ALERT_ACKNOWLEDGE_SUCCESS: "alerts.acknowledge.success",
  ALERT_ACKNOWLEDGE_ALL_SUCCESS: "alerts.acknowledge.all.success",
  POLICY_DELETE: "protect.policy.remove.success",
  POLICY_DEPLOY: "protect.ploicy",
  POLICY_DISABLE: "protect.policy.disable",
  POLICY_ENABLE: "protect.policy.enable",
  POLICY_REFRESH: "protect.policy.refresh",
  POLICY_BACKUP: "protect.policy.backup",
  POLICY_BACKUP_SUCCESS: "protect.policy.backup.success",
  POLICY_SHARE: "protect.policy.share",
  POLICY_ERROR: "protect.policy",
  SOURCES: "protect.sources",
  UPDATE_DESTINATIONS: "update.destination",
  DESTINATIONS: "protect.destination",
  POLICIES: "protect.policies",
  SAVED_SEARCH: "saved.search",
  UPDATE_SAVED_SEARCH_DATA: "update.saved.search",
  DELETE_AD_SUCCESS: "protect.source.discover.browse.delete.success",
  CANCEL_JOB: "common.jobs.cancelJob",
  DELETE_JOB: "common.jobs.deleteJob",
  CANCEL_RECOVERY: "common.jobs.cancelRecovery",
  USER_ACCOUNTS: "users",
  MANAGE_PERMISSIONS: "update_role",
  USER_ROLES: "roles",
  UPDATE_ACTION_SAVED_SEARCH_DATA: "manage_saved_search.update.success",
  ADD_SAVED_SEARCH_DATA: "manage_saved_search.add.success",
  DELETE_SAVED_SEARCH_DATA: "manage_saved_search.delete.success",
  DELETE_REPORT_SUCCESS: "delete.report.success",
  DELETE_REPORT_FAILURE: "delete.report.failed",
  DELETE_SCHEDULE_REPORT_SUCCESS: "delete.schedule.report.success",
  DELETE_SCHEDULE_REPORT_FAILURE: "delete.schedule.report.failed",
  UPDATE_REPORT_SETTINGS_SUCCESS: "update.report.settings.success",
  UPDATE_REPORT_SETTINGS_FAILURE: "update.report.settings.failed",
  UPDATE_REPORT_SCHEDULE_ERROR: "update.report.settings.failure",
  CREATE_REPORT_SUCCESS: "create.report.success",
  CREATE_REPORT_SCHEDULE_SUCCESS: "create.report.schedule.success",
  CREATE_ANALYZE_ALERT_SUCCESS: "create.analyze.alert.success",
  CREATE_ANALYZE_ALERT_FAILURE: "create.analyze.alert.failure",
  UPDATE_ALERT_SETTINGS_SUCCESS: "update.alert.settings.success",
  DELETE_ANALYZE_ALERT_SUCCESS: "delete.analyze.alert.success",
  DELETE_ANALYZE_ALERT_FAILURE: "delete.analyze.alert.failed",
  CREATE_REPORT_FAILURE: "create.report.failed",
  SOURCE_CONFIG_UPDATE_SUCCESS: "source.configuration.update.success",
  SOURCE_CONFIG_UPDATE_FAILURE: "source.configuration.update.failure",
  SOURCE_GROUP_CONFIG_ERROR: "sourceGroup.error",
  SCHEDULE_UPDATE_SUCCESS: "schedule.update.success",
  NETWORK_CONFIG_UPDATE: "network.configuration.update.success",
  NETWORK_CONFIG_DELETE: "network.configuration.update.delete",
  NETWORK_CONFIG_ADD: "network.configuration.add.success",
  NETWORK_CONFIG: "portforwarding",
  UPDATE_BRANDING_DETAILS: "branding.update.success",
  UPDATE_BRANDING_LOGO: "branding.update.logo.success",
  DELETE_HYPERVISOR_SUCCESS: "delete.hypervisor.success",
  DELETE_STORAGE_ARRAY_SUCCESS: "delete.storagearray.success",
  DELETE_HYPERVISOR_FAILURE: "delete.hypervisor.failed",
  DELETE_STORAGE_ARRAY_FAILURE: "delete.storagearray.failed",
  RUN_REPORT_SUCCESS: "run.report.success",
  RUN_REPORT_FAILURE: "run.report.failed",
  RUN_REPORTS_SUCCESS: "run.reports.success",
  RUN_REPORTS_FAILURE: "run.reports.failed",
  CUSTOMER_ACCOUNT_CREATE: "protect.customer_account.customer_added",
  CUSTOMER_ACCOUNT_MODIFY: "protect.customer_account.account_updated",
  CUSTOMER_ACCOUNT_ASSIGN_ADMINS: "protect.customer_account.admins_assigned",
  CUSTOMER_ACCOUNT_SET_THRESHOLD: "protect.customer_account.threshold_set",
  CUSTOMER_ACCOUNT_DELETE: "protect.customer_account.customer_removed",
  CUSTOMER_ACCOUNT_SUSPEND: "protect.customer_account.suspend",
  CUSTOMER_ACCOUNT_ENABLE: "protect.customer_account.resume",
  CUSTOMER_ACCOUNT_UNASSIGN_ADMINS:
    "protect.customer_account.admins_unassigned",
  CUSTOMER_ACCOUNT_ERROR: "protect.customer_account.error",
  WINDOWS_SOURCE_CONNECT_SUCCESS: "protect.windows.source.connect.message",
  WINDOWS_SOURCE_ADD_SUCCESS: "protect.windows.source.success.message",
  ORACLE_DB_ADD_SUCCESS: "protect.oracle.database.success.message",
  RECOVERED_RESOURCES_ACTION_SUCCESS: "recovered_resources.action.success",
  SOURCES_ADD_FAILURE: "protect.sources.error.message",
  SOURCES_ERROR: "protect.sources.error",
  LINUX_BACKUP_SERVER_SOURCE_ADD_SUCCESS: "protect.linux.backup.succes.message",
  LINUX_BACKUP_SERVER_SOURCE_CONNECT: "protect.add.lbs.connect",
  LINUX_BACKUP_SERVER_SOURCE_ADD: "protect.add.lbs.source",
  DISCOVER_GET_SOURCE_NAME_FAILURE:
    "protect.discover.source.name.failure.message",
  DISCOVER_SOURCE_CONNECT_FAILURE:
    "protect.discover.source.connect.failure.message",
  WINDOWS_SOURCE_ADD_TO_LIST_ERROR: "protect.windows.add.to.list.error",
  SITE_ADD_FAILURE: "site.error.error.message",
  SITE_EXIST: "site.error.exist.message",
  HYPERVISOR_ADD_SOURCES_SUCCESS: "protect.sources.hypervisor.add.success",
  HYPERVISOR_ADD_SOURCES: "protect.sources.hypervisor.add",
  LINUX_SOURCE_ADD_TO_LIST_ERROR: "protect.linux.add.to.list.error",
  REMOTE_CONSOLE_ADD_SUCCESS:
    "protect.destination.remoteconsole.success.message",
  REMOTE_CONSOLE_UPDATE_SUCCESS:
    "protect.destination.remoteconsole.update.success.message",
  CREATE_CLOUD_ACCOUNT_SUCCESS:
    "protect.destination.cloud_account.create.success.message",
  UPDATE_CLOUD_ACCOUNT_SUCCESS:
    "protect.destination.cloud_account.update.success.message",
  BROWSE_REMOTE_SUCCESS:
    "protect.source.collect_diagnostic_data.browse.success",
  BROWSE_REMOTE_FAILED: "protect.source.collect_diagnostic_data.browse.failed",
  ADD_RPS_SUCCESS: "protect.destination.rps.add.success",
  ADD_RPS_FAILURE: "protect.destination.rps.add.failure",
  DATA_STORE_ADD_SUCCESS: "protect.destination.datastore.add.success.message",
  DATA_STORE_UPDATE_SUCCESS:
    "protect.destination.datastore.update.success.message",
  DATA_STORE_DELETE_SUCCESS:
    "protect.destination.datastore.delete.success.message",
  DELETE_RPS_SUCCESS: "protect.destination.rps.delete.success",
  FORCE_DELETE_RPS_SUCCESS: "protect.destination.rps.force.delete.success",
  BULK_DELETE_RPS_SUCCESS: "protect.destination.rps.delete.success.message",
  UPDATE_RPS_SUCCESS: "protect.destination.rps.update.success",
  UPDATE_RPS_FAILURE: "protect.destination.rps.update.failure",
  IMPORT_DATA_STORE_SUCCESS: "protect.destination.rps.import.datastore.success",
  START_JUMPSTART_SUCCESS: "protect.destination.rps.jumpstart.start.success",
  IMPORT_CLOUD_DATA_STORE_SUCCESS:
    "protect.destination.rps.import.cloud_datastore.success",
  SCHEDULE_DEPLOY_RPS_SUCCESS:
    "protect.destination.rps.schedule.deploy.success",
  INSTALL_DEPLOY_RPS_SUCCESS: "protect.destination.rps.install.deploy.success",
  CANCEL_SCHEDULED_DEPLOY_RPS_SUCCESS:
    "protect.destination.rps.cancel.scheduled.deploy.success",
  CREATE_BACKUP_PROXY_SUCCESS: "policy.udp_unc.create_backup_proxy.success",
  CREATE_LBS_SUCCESS: "policy.udp_unc.create_lbs.success",
  SAVE_TO_LIST_VMWARE_ERROR: "protect.add.sources.saveToList.vmware.error",
  DEPLOYMENT_CONFIG_UPDATE_SUCCESS: "default_deployment.success",
  UPGRADE_AGENT_SOURCE_SUCCESS: "protect.destination.source.upgrade.success",
  UPGRADE_AGENT_SOURCE_SCHEDULE_SUCCESS:
    "protect.destination.source.upgrade.schedule.success",
  UPDATE_SOURCE_SUCCESS: "protect.source.update.success",
  UPDATE_SOURCE_FAILURE: "protect.source.update.failure",
  SHARE_POLICY_ACTION_SUCCESS: "protect.policy.share.success",
  UNSHARE_POLICY_ACTION_SUCCESS: "protect.policy.unshare.success",
  RUN_ASSURED_RECOVERY_TEST_SUCCESS: "protect.policy.run.success",
  RUN_ASSURED_RECOVERY_TEST_FAILURE: "protect.policy.run.failure",
  RUN_ASSURED_SECURITY_SCAN_SUCCESS: "protect.policy.as.run.success",
  FETCH_AVAILABLE_VOLUMES_FAILURE: "recovery.volumes.available.failure",
  FETCH_MOUNTED_VOLUMES_FAILURE: "recovery.volumes.mounted.failure",
  DISMOUNT_VOLUME_SUCCESS: "recovery.volumes.dismount.success",
  DISMOUNT_DATABASE_SUCCESS: "recovery.databases.dismount.success",
  MOUNT_VOLUME_SUCCESS: "recovery.volumes.mount.success",
  MOUNT_DATABASE_SUCCESS: "recovery.databases.mount.success",
  DISMOUNT_VOLUME_FAILURE: "recovery.volumes.dismount.failure",
  ADD_SELECTED_SOURCES: "configure.groups.add.selected.sources",
  LOAD_SOURCE_DISCOVERY_SETTINGS: "configure.source_discovery.load",
  SAVE_SOURCE_DISCOVERY_SETTINGS_SUCCESS:
    "configure.source_discovery.save.success",
  SOURCE_DISCOVERY_RUN_NOW: "configure.source_discovery.run_now",
  SAVE_SOURCE_DISCOVERY_SETTINGS_FAILURE:
    "configure.source_discovery.save.failure",
  DISCOVER_FROM_AD: "configure.source_discovery.from_ad",
  DISCOVER_FROM_HYPERV: "configure.source_discovery.from_hyperv",
  DISCOVER_FROM_VMWARE: "configure.source_discovery.from_vmware",
  DELETE_SOURCE_DISCOVERY: "configure.source_discovery.delete",
  SITE_NAME_UPDATE_SUCCESS: "configure.site.name.update.success.message",
  SITE_DELETE_SUCCESS: "configure.site.delete.success.message",
  UNMOUNT_RPS_SUCCESS: "protect.rps.unmount.success",
  UNMOUNT_RPS_SQL_SUCCESS: "protect.rps.sql.unmount.success",
  CREATE_DESTINATION_HOST_SUCCESS:
    "protect.destination.data-store.recovery_points.sql_recovery.add_host_success",
  START_RECOVERY: "protect.recovery.start",
  PROXY_DELETE_SUCCESS: "configure.proxy.delete.success.message",
  FETCH_PROXIES_IVM: "protect.ivm.proxies",
  ERROR_403: "error.common.403",
  VIRTUAL_STANDBY_START: "protect.virtualStandby.started",
  CREATE_IVM_SUCCESS: "protect.ivm.create.success",
  VIRTUAL_STANDBY_PAUSE: "protect.virtualStandby.paused",
  VIRTUAL_STANDBY_RESUME: "protect.virtualStandby.resumed",
  VIRTUAL_STANDBY_HEARTBEAT_PAUSE: "protect.virtualStandby.heartbeat_paused",
  VIRTUAL_STANDBY_HEARTBEAT_RESUME: "protect.virtualStandby.heartbeat_resumed",
  HEARTBEAT_PAUSE_SUCCESS:
    "protect.source.action.heartbeat_pause.success.message",
  HEARTBEAT_RESUME_SUCCESS:
    "protect.source.action.heartbeat_resume.success.message",
  STANDBY_VM_SUCCESS: "protect.source.action.standby_vm.success.message",
  STANDBY_VM_SHUTDOWN: "protect.source.action.standby_vm.shutdown.message",
  STANDBY_VM_POWERON: "protect.source.action.standby_vm.poweron.message",
  VIRTUAL_STANDBY_PAUSE_SUCCESS:
    "protect.source.action.virtual_standby_pause.success.message",
  VIRTUAL_STANDBY_RESUME_SUCCESS:
    "protect.source.action.virtual_standby_resume.success.message",
  STANDBY_VM_NETWORK_CONFIGURATION:
    "protect.source.action.standby_vm_network_configuration.success.message",
  SHARED_FOLDER_DESTINATION_CONNECT_SUCCESS:
    "policy.shared_folder_destination.connect.success",
  VSB_CONNECT_SUCCESS: "policy.vsb.connect.success",
  PASSWORD_VERIFICATION_FAILED: "wrong_password_error_msg",
  SQL_INSTANCES_FAILED: "Failed to get SQL Instances",
  ESX_VCENTER_CONNECT_SUCCESS: "protect.recover.VM.vcenter_esx.connect_success",
  TWO_FACTOR_REQUIRED_SUCCESS: "two_factor_required.true.success",
  TWO_FACTOR_NOT_REQUIRED_SUCCESS: "two_factor_required.false.success",
  TWO_FACTOR_RESET_SUCCESS: "two_factor_reset_success",
  UPGRADE_SITE_SUCCESS: "configure.site.upgrade.success.message",
  ADD_HYPERVISOR_SUCCESS: "hypervisor.added.successfully",
  BACKUP_PASSWORDS_SUCCESS: "protect.sources.setBackupPasswords.api_success",
  COLLECT_DATA_SUCCESS: "protect.source.collectDiagnostic.api_success",
  RESTORE_NOT_ALLOWED: "protect.restore.volume.restore_not_allowed",
  ANALYZE_CSV_REPORT_SUCCESS: "analyze.csv.export",
  EXPORT_LOG_SUCCESS: "common.logs.exportLog",
  EXPORT_LOG_FAILED: "common.logs.exportLogFail",
  USER_ACCCOUNTS_REMOVE_SUCCESS:
    "configure.accessControl.userAccounts.removeSuccess",
  VALIDATE_ORACLE_PATH_SUCCESS: "protect.source.oracle.path.validation.success",
  DISABLE_SHARE_SUCCESS: "protect.destination.ds.disable_share.request.success",
  "protect.ivm.create.success":
    "Create Instant VM job has been submitted successfully",
  CREATE_VM_SUCCESS: "protect.vm.create.success",
  BROWSE_RP_REFRESH_SUCCESS:
    "protect.destination.ds.recoveryPoint.refresh.success",
  TERMINATE_STANBY_VM: "protect.sources.terminate_standby_vm.toast_message"
};

export const CPU_COUNT_VALUES = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16"
];

export const MEMORY_SIZE = {
  MIN: 512,
  MAX: 65533
};

export const RAM_SIZE = {
  MIN: 2,
  MAX: 128
};

/**
 * used for Create instant VM action
 */
export const SOURCE_PRODUCT = {
  CLOUD_DIRECT: "cloud_direct",
  UDP: "udp",
  CLOUD_HYBRID: "cloud_hybrid"
};

export const CONFIGURATION_FORM = {
  CD: "cdForm",
  CH: "chForm"
};

export const CREATE_VM_ACTIONS = {
  BOOT_NOW: "Boot Now",
  BOOT_LATER: "Boot Later",
  CREATE: "Create"
};

// Max file size for Profile Pic (5MB)
// export const PROFILE_PIC_MAX_SIZE = 5242880;

// Max file size for Profile Pic (1.5MB)
export const PROFILE_PIC_MAX_SIZE = 1572864;

/**
 * used for text field validations
 */
export const VALIDATION_REGEX = {
  TWENTY_FOUR_HOURS_FORMAT: /^([01]\d|2[0-3]):?([0-5]\d)$/,
  DESTINATION_NAME: /[\w-.\s]{3,}$/,
  DESTINATION_ALPHANUMERIC_NAME: /^[a-zA-Z0-9_-\s]*$/,
  POLICY_ALPHANUMERIC_NAME: /^[a-zA-Z0-9_-\s\(\)]*$/,
  CONTACT_INFORMATION: /^[\d]+$/,
  BRANDING_COLOR_CODE: /^[#]{1}[A-F0-9]{6}$/i,
  EMAIL_VALIDATOR: /^[\w.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  WEBSITE_VALIDATOR: /^((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i,
  PHONE_NUMBER_VALIDATOR: /^[0-9*#+--()]+$/,
  FACEBOOK_LINK_VALIDATOR: /^.*facebook.*$/,
  TWITTER_LINK_VALIDATOR: /^.*twitter.*$/,
  LINKEDIN_LINK_VALIDATOR: /^.*linkedin.*$/,
  // eslint-disable-next-line no-control-regex
  PASSWORD_VALIDATOR: /^(?:(?=.*([^\u0000-\u007F])+)|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,}$/,
  IPV4_VALIDATOR: /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/,
  IPV6_VALIDATOR: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/,
  // Fully Qualified Domain Name validator for source name value
  FQDN_VALIDATOR: /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/,
  // Validate host name for RFC1123 standard
  RFC1123_HOST_NAME_VALIDATOR: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/,
  RFC1123_HOST_NAME_VALIDATOR_WITH_PORT: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])(([:]([0-9]{1,5}))|[A-Za-z0-9]*)$/,
  HOST_NAME_VALIDATOR_AS_PER_UDP: /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/,
  INSTALLATION_PATH_VALIDATOR: /(%([^ ~!@#$%^&*()_+{}|\\:"';<>,./?]+)%*)(\\([^(/)]+))+$/,
  SPACES: /^[\s]+$/,
  VALID_LOCAL_PATH_FOR_NFS: /^(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|^([A-z0-9]+):(\/([A-z0-9+]+\/)*([A-z0-9]+))$/,
  VALID_LOCAL_PATH_FOR_LINUX: /^(\/[a-zA-Z0-9_-]+)+$(?<!^\/bin|\/dev|\/etc|\/mnt|\/opt|\/run|\/srv|\/sys|\/tmp|\/usr|\/var|\/lib|\/proc|\/sbin|\/root|\/boot|\/home|\/lib64|\/media|\/usr\/bin|\/usr\/lib|\/usr\/lib64|\/usr\/sbin|(\/usr\/bin)|(\/usr\/lib)|(\/usr\/lib64)|(\/usr\/sbin)$)/,
  ONLY_NUMBER: /^[0-9\b]+$/,
  ALLOWED_CHARACTER: /^[a-zA-Z0-9_.@-\s\(\)]*$/,
  ALLOWED_ALPHANUMERIC_HYPHEN_UNDERSCORE: /^[a-zA-Z0-9-_]+$/,
  AWS_BUCKET_NAME: /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(?!xn--).*)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)((?<!-s3alias)$)/,
  WASABI_BUCKET_NAME: /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/,
  GCP_BUCKET_NAME: /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(?!goog).*)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-_]*[a-z0-9])$)/,
  AZURE_BLOB_STORAGE_CONTAINER_NAME: /^[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]$/,
  VALID_DATABASE_NAME: /^(?![0-9])[a-zA-Z0-9]*$/,
  VALID_DATABASE_PASSWORD: /^[^0-9\W@_][^@\n]{7,}$/,
  ESXI_VM_NAME_VALIDATOR: /^[^":\/\\]+$/,
  GCP_RESOURCES_ON_COMPUTE_ENGINE: /^[a-z]([-a-z0-9]*[-a-z0-9])?$/
  // ^[^0-9\W@_][^@\n]{8,}$
  // /^(?![0-9\W])[^@\s]*$/
};

/**
 * Reserved ipv4 addresses
 */
export const RESERVED_IPV4_VALUES = ["0.0.0.0", "255.255.255.255"];

/**
 * default font
 */
export const DEFAULT_FONT = {
  SIZE: "0.8125rem",
  FAMILY: "Sans Source Pro"
};

export const TRANSPORT_MODES_OPTIONS = ["SAN", "HotAdd", "NBD", "NBDSSL"];

export const EXPORT_REPORT_ACTIONS = ["export-as-csv"];

export const DATE_RANGE_OPTIONS = {
  TODAY: "today",
  LAST_24_HOURS: "last_24_hours",
  LAST_7_DAYS: "last_7_days",
  LAST_2_WEEKS: "last_2_weeks",
  LAST_1_MONTH: "last_1_month",
  LAST_3_MONTHS: "last_3_months",
  LAST_6_MONTHS: "last_6_months",
  LAST_1_YEAR: "last_1_year",
  CUSTOM: "custom"
};
/**
 * default grid style
 */
export const GRID_DIV_STYLE = {
  width: "100%",
  height: "100%",
  position: "relative",
  overflow: "hidden"
};

/**
 * group actions
 */
export const GROUP_ACTIONS = {
  SOURCE_GROUPS_SOURCE: ["removeFromGroup", "deleteGroup"]
};

/**
 * add source to group - bread crumb name
 */
export const ADD_SOURCES_TO_GROUP = "Add Sources to Group";

export const BROWSE_RECOVERY_POINTS = "browse_recovery_points";

export const BRANDING_FROM_SELF = "SELF";

export const DEFAULT_BRANDING_DATA = {
  branding_from: "DEFAULT_ARCSERVE",
  branding_logo: true,
  portal_url: "",
  logo_img_url: "",
  primary_color: "#4cbfa5",
  secondary_color: "#196ba2",
  brandingLogo: true,
  login_img_url: "",
  branding_msg:
    "Guaranteed data protection through seamless integrations of backup-restore",
  copyright: "",
  branding_names: {
    ARCSERVE_PRODUCT_NAME: "Arcserve",
    CD_PRODUCT_NAME: "Cloud Direct",
    CH_PRODUCT_NAME: "Cloud Hybrid",
    UDP_PRODUCT_NAME: "UDP"
  }
};
/**
 * default sorting for Grids
 */
export const GRID_DEFAULT_SORTING = {
  "customer-accounts": [
    {
      id: "organization_name",
      desc: false
    }
  ],
  vm_hypervisor: [
    {
      id: "vm_name",
      desc: false
    }
  ],
  user_accounts: [
    {
      id: "create_ts",
      desc: true
    }
  ],
  user_roles: [
    {
      id: "role_name",
      desc: false
    }
  ],
  recovered_vms: [
    {
      id: "state",
      desc: false
    }
  ],
  source_jobs: [
    {
      id: "start_time_ts",
      desc: true
    }
  ],
  data_store_jobs: [
    {
      id: "start_time_ts",
      desc: true
    }
  ],
  jobs: [
    {
      id: "start_time_ts",
      desc: true
    }
  ],
  source_log: [
    {
      id: "log_ts",
      desc: true
    }
  ],
  log: [
    {
      id: "log_ts",
      desc: true
    }
  ],
  jobLog: [
    {
      id: "log_ts",
      desc: true
    }
  ],
  hypervisors: [
    {
      id: "hypervisor_name",
      desc: false
    }
  ],
  reports: [
    {
      id: "create_ts",
      desc: true
    }
  ],
  backup_jobs: [
    {
      id: "job_status",
      desc: false
    }
  ],
  policy_tasks: [
    {
      id: "start_time_ts",
      desc: true
    }
  ],
  restore_jobs: [
    {
      id: "job_status",
      desc: false
    }
  ],
  data_transfer: [
    {
      id: "create_ts",
      desc: true
    }
  ],
  jobs_and_destinations: [
    {
      id: "start_time_ts",
      desc: false
    }
  ],
  capacity_usage: [
    {
      id: "destination_name",
      desc: false
    }
  ],
  source_groups: [
    {
      id: "group_name",
      desc: true
    }
  ],
  audit_trail: [
    {
      id: "create_ts",
      desc: true
    }
  ],
  linux_backup_servers: [
    {
      id: "modify_ts",
      desc: true
    }
  ],
  recovered_resources: [
    {
      id: "state",
      desc: false
    }
  ],
  account_details: [
    {
      id: "organization_name",
      desc: false
    }
  ],
  cloud_account: [
    {
      id: "cloud_account_name",
      desc: false
    }
  ],
  mounted_recovery_points: [
    {
      id: "mounted_on",
      desc: false
    }
  ],
  arcserve_cloud: [
    {
      id: "destination_name",
      desc: false
    }
  ],
  recovery_point_servers: [
    {
      id: "server_name",
      desc: false
    }
  ],
  virtual_standbys: [
    {
      id: "source_name",
      desc: false
    }
  ],
  data_stores: [
    {
      id: "datastore_name",
      desc: false
    }
  ],
  instant_vms_rr: [
    {
      id: "source_name",
      desc: false
    }
  ]
};

/*
 * alert types
 */
export const ALERT_TYPES = {
  CRITICAL: "critical",
  WARNING: "warning",
  INFO: "information"
};

export const RETENTION_VALUES = [
  {
    key: "7 Days",
    value: "7D"
  },
  {
    key: "14 Days",
    value: "14D"
  },
  {
    key: "1 Month",
    value: "1M"
  },
  {
    key: "2 Months",
    value: "2M"
  },
  {
    key: "3 Months",
    value: "3M"
  },
  {
    key: "6 Months",
    value: "6M"
  },
  {
    key: "1 Year",
    value: "1Y"
  },
  {
    key: "2 Years",
    value: "2Y"
  },
  {
    key: "3 Years",
    value: "3Y"
  },
  {
    key: "7 Years",
    value: "7Y"
  },
  {
    key: "10 Years",
    value: "10Y"
  },
  {
    key: "Forever",
    value: "forever"
  }
];

export const RETENTION_VALUES_DR = [
  {
    key: "7 Days - High Frequency",
    value: "7Dhf"
  },
  {
    key: "14 Days - High Frequency",
    value: "14Dhf"
  },
  {
    key: "1 Month - High Frequency",
    value: "1Mhf"
  }
];

/**
 * retention mapping to get the value by key
 */
export const GET_RETENTION_BY_KEY = {
  "1Y": "1 Year",
  "2Y": "2 Years",
  "3Y": "3 Years",
  "7Y": "7 Years",
  "10Y": "10 Years",
  forever: "Forever"
};

export const REPORT_OVERLAY_TRIGGER_SOURCE = {
  EDIT_REPORT_SETTINGS: "edit_report_settings",
  REPORTS: "create-report",
  BACKUP_JOB_REPORT: "backup_jobs",
  POLICY_TASKS_REPORT: "policy_tasks",
  RESTORE_JOB_REPORT: "restore_jobs",
  JOBS_AND_DESTINATIONS_REPORT: "jobs_and_destinations",
  CAPACITY_USAGE_REPORT: "capacity_usage",
  RECOVERY_POINT_REPORT: "source_rpo",
  DATA_TRANSFER_REPORT: "data_transfer",
  ALERTS: "alert",
  EDIT_ALERTS_SETTINGS: "edit_alert_settings",
  ACCOUNT_DETAILS: "account_details",
  SOURCE_PROTECTION_REPORT: "source_protection"
};

export const SCHEDULE_FREQUENCY = ["daily", "weekly", "monthly"];

export const AMOUNT = "amount";

/**
 * Restore flow task type keys
 * for defining each task type
 * @type {{}}
 */
export const RESTORE_TASK_TYPE_KEYS = {
  CLOUD_DIRECT_IMAGE_BACKUP: "cloud_direct_image_backup",
  CLOUD_DIRECT_FILE_FOLDER_BACKUP: "cloud_direct_file_folder_backup",
  CLOUD_DIRECT_HYPERVISOR: "cloud_direct_vmware_backup",
  CLOUD_DIRECT_HYPERVISOR_HYPERV: "cloud_direct_hyperv_backup",
  CLOUD_DIRECT_SQL_SERVER_BACKUP: "cloud_direct_sql_server_backup"
};

/**
 * key to split browser url
 * so that we can make host and path
 */
export const RESTORE_BROWSER_URL_SPLIT_KEY = "zetta";

/**
 * OS for user machines
 */
export const OS = {
  MAC: "Mac",
  WIN: "Win",
  LIN: "Lin",
  MAC_OS: "Mac OS",
  LINUX: "Linux",
  WINDOWS: "Windows",
  MAC_OS_8: "10.8"
};

/**
 * system types
 */
export const SYSTEM_TYPES = {
  BIT_32: "32",
  BIT_64: "64"
};

export const COLUMN_API_TYPES = {
  STATIC: "static",
  API: "api"
};
/**
 * Report backupjob summary default params
 */
export const TIME_RESOLUTION = "time_resolution";

/**
 * Time resolution params
 *
 */
export const TIME_RESOLUTION_PARAMS = {
  today: "hour",
  last_24_hours: "hour",
  last_7_days: "day",
  last_2_weeks: "day",
  last_1_month: "day",
  last_3_months: "day",
  last_6_months: "month",
  last_1_year: "month",
  custom: "day"
};

export const FILTER_TYPE = "filter_type";
/**
 * date filter options used in charts
 */
export const CD_CHART_FILTER = ["7D", "14D", "1M", "1Y", "custom"];

export const CU_CHART_FILTER = ["7D", "14D", "1M", "1Y", "custom"];
/**
 * date filter options used in charts
 */
export const CH_CHART_FILTER = ["7D", "14D", "1M", "1Y", "custom"];

export const CHART_FILTER_MAPPINGS = {
  "7D": "last_7_days",
  "14D": "last_2_weeks",
  "1M": "last_1_month",
  "3M": "last_3_months",
  "1Y": "last_1_year"
};
/**
 * filter types for chart
 */
export const CHART_FILTER_TYPES = {
  CLOUD_DIRECT: "cloud_direct",
  CLOUD_HYBRID: "cloud_hybrid",
  CAPACITY_USAGE: "capacity_usage"
};

/*
 * Action api call keys
 */
export const ACTION_API_CALL_KEYS = {
  SAVE_SEARCH: "savesearch",
  CURRENT_CALL: "currentcall"
};

export const OVA_DOWNLOAD_LINK =
  "https://download.zetta.net/vmware_appliance/VMwareAppliance.ova";

export const DRAAS_SKU = {
  NACDICMPSLWC01S01: "Cloud Direct DRaaS-4 GB RAM and 1 vCPU ",
  NACDICMPSLWAADS01: "Cloud Direct DRaaS-Active Directory",
  NACDICMPSLWAIPS01: "Cloud Direct DRaaS-IP Address"
};

export const SKU_ID_LENGTH = Object.keys(DRAAS_SKU)[0].length;

/**
 * ISD codes for countries
 */
export const ISD_CODES = [
  {
    name: "Aruba",
    code: "AW",
    callingCode: "+297"
  },
  {
    name: "Afghanistan",
    code: "AF",
    callingCode: "+93"
  },
  {
    name: "Angola",
    code: "AO",
    callingCode: "+244"
  },
  {
    name: "Anguilla",
    code: "AI",
    callingCode: "+1264"
  },
  {
    name: "Åland Islands",
    code: "AX",
    callingCode: "+358"
  },
  {
    name: "Albania",
    code: "AL",
    callingCode: "+355"
  },
  {
    name: "Andorra",
    code: "AD",
    callingCode: "+376"
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    callingCode: "+971"
  },
  {
    name: "Argentina",
    code: "AR",
    callingCode: "+54"
  },
  {
    name: "Armenia",
    code: "AM",
    callingCode: "+374"
  },
  {
    name: "American Samoa",
    code: "AS",
    callingCode: "+1684"
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    callingCode: "+1268"
  },
  {
    name: "Australia",
    code: "AU",
    callingCode: "+61"
  },
  {
    name: "Austria",
    code: "AT",
    callingCode: "+43"
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    callingCode: "+994"
  },
  {
    name: "Burundi",
    code: "BI",
    callingCode: "+257"
  },
  {
    name: "Belgium",
    code: "BE",
    callingCode: "+32"
  },
  {
    name: "Benin",
    code: "BJ",
    callingCode: "+229"
  },
  {
    name: "Burkina Faso",
    code: "BF",
    callingCode: "+226"
  },
  {
    name: "Bangladesh",
    code: "BD",
    callingCode: "+880"
  },
  {
    name: "Bulgaria",
    code: "BG",
    callingCode: "+359"
  },
  {
    name: "Bahrain",
    code: "BH",
    callingCode: "+973"
  },
  {
    name: "Bahamas",
    code: "BS",
    callingCode: "+1242"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    callingCode: "+387"
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    callingCode: "+590"
  },
  {
    name: "Belarus",
    code: "BY",
    callingCode: "+375"
  },
  {
    name: "Belize",
    code: "BZ",
    callingCode: "+501"
  },
  {
    name: "Bermuda",
    code: "BM",
    callingCode: "+1441"
  },
  {
    name: "Bolivia",
    code: "BO",
    callingCode: "+591"
  },
  {
    name: "Brazil",
    code: "BR",
    callingCode: "+55"
  },
  {
    name: "Barbados",
    code: "BB",
    callingCode: "+1246"
  },
  {
    name: "Brunei",
    code: "BN",
    callingCode: "+673"
  },
  {
    name: "Bhutan",
    code: "BT",
    callingCode: "+975"
  },
  {
    name: "Botswana",
    code: "BW",
    callingCode: "+267"
  },
  {
    name: "Central African Republic",
    code: "CF",
    callingCode: "+236"
  },
  {
    name: "Canada",
    code: "CA",
    callingCode: "+1"
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    callingCode: "+61"
  },
  {
    name: "Switzerland",
    code: "CH",
    callingCode: "+41"
  },
  {
    name: "Chile",
    code: "CL",
    callingCode: "+56"
  },
  {
    name: "China",
    code: "CN",
    callingCode: "+86"
  },
  {
    name: "Ivory Coast",
    code: "CI",
    callingCode: "+225"
  },
  {
    name: "Cameroon",
    code: "CM",
    callingCode: "+237"
  },
  {
    name: "DR Congo",
    code: "CD",
    callingCode: "+243"
  },
  {
    name: "Republic of the Congo",
    code: "CG",
    callingCode: "+242"
  },
  {
    name: "Cook Islands",
    code: "CK",
    callingCode: "+682"
  },
  {
    name: "Colombia",
    code: "CO",
    callingCode: "+57"
  },
  {
    name: "Comoros",
    code: "KM",
    callingCode: "+269"
  },
  {
    name: "Cape Verde",
    code: "CV",
    callingCode: "+238"
  },
  {
    name: "Costa Rica",
    code: "CR",
    callingCode: "+506"
  },
  {
    name: "Cuba",
    code: "CU",
    callingCode: "+53"
  },
  {
    name: "Curaçao",
    code: "CW",
    callingCode: "+5999"
  },
  {
    name: "Christmas Island",
    code: "CX",
    callingCode: "+61"
  },
  {
    name: "Cayman Islands",
    code: "KY",
    callingCode: "+1345"
  },
  {
    name: "Cyprus",
    code: "CY",
    callingCode: "+357"
  },
  {
    name: "Czechia",
    code: "CZ",
    callingCode: "+420"
  },
  {
    name: "Germany",
    code: "DE",
    callingCode: "+49"
  },
  {
    name: "Djibouti",
    code: "DJ",
    callingCode: "+253"
  },
  {
    name: "Dominica",
    code: "DM",
    callingCode: "+1767"
  },
  {
    name: "Denmark",
    code: "DK",
    callingCode: "+45"
  },
  {
    name: "Dominican Republic",
    code: "DO",
    callingCode: "+1809"
  },
  {
    name: "Dominican Republic",
    code: "DO",
    callingCode: "+1829"
  },
  {
    name: "Dominican Republic",
    code: "DO",
    callingCode: "+1849"
  },
  {
    name: "Algeria",
    code: "DZ",
    callingCode: "+213"
  },
  {
    name: "Ecuador",
    code: "EC",
    callingCode: "+593"
  },
  {
    name: "Egypt",
    code: "EG",
    callingCode: "+20"
  },
  {
    name: "Eritrea",
    code: "ER",
    callingCode: "+291"
  },
  {
    name: "Western Sahara",
    code: "EH",
    callingCode: "+212"
  },
  {
    name: "Spain",
    code: "ES",
    callingCode: "+34"
  },
  {
    name: "Estonia",
    code: "EE",
    callingCode: "+372"
  },
  {
    name: "Ethiopia",
    code: "ET",
    callingCode: "+251"
  },
  {
    name: "Finland",
    code: "FI",
    callingCode: "+358"
  },
  {
    name: "Fiji",
    code: "FJ",
    callingCode: "+679"
  },
  {
    name: "Falkland Islands",
    code: "FK",
    callingCode: "+500"
  },
  {
    name: "France",
    code: "FR",
    callingCode: "+33"
  },
  {
    name: "Faroe Islands",
    code: "FO",
    callingCode: "+298"
  },
  {
    name: "Micronesia",
    code: "FM",
    callingCode: "+691"
  },
  {
    name: "Gabon",
    code: "GA",
    callingCode: "+241"
  },
  {
    name: "United Kingdom",
    code: "GB",
    callingCode: "+44"
  },
  {
    name: "Georgia",
    code: "GE",
    callingCode: "+995"
  },
  {
    name: "Guernsey",
    code: "GG",
    callingCode: "+44"
  },
  {
    name: "Ghana",
    code: "GH",
    callingCode: "+233"
  },
  {
    name: "Gibraltar",
    code: "GI",
    callingCode: "+350"
  },
  {
    name: "Guinea",
    code: "GN",
    callingCode: "+224"
  },
  {
    name: "Guadeloupe",
    code: "GP",
    callingCode: "+590"
  },
  {
    name: "Gambia",
    code: "GM",
    callingCode: "+220"
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    callingCode: "+245"
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    callingCode: "+240"
  },
  {
    name: "Greece",
    code: "GR",
    callingCode: "+30"
  },
  {
    name: "Grenada",
    code: "GD",
    callingCode: "+1473"
  },
  {
    name: "Greenland",
    code: "GL",
    callingCode: "+299"
  },
  {
    name: "Guatemala",
    code: "GT",
    callingCode: "+502"
  },
  {
    name: "French Guiana",
    code: "GF",
    callingCode: "+594"
  },
  {
    name: "Guam",
    code: "GU",
    callingCode: "+1671"
  },
  {
    name: "Guyana",
    code: "GY",
    callingCode: "+592"
  },
  {
    name: "Hong Kong",
    code: "HK",
    callingCode: "+852"
  },
  {
    name: "Honduras",
    code: "HN",
    callingCode: "+504"
  },
  {
    name: "Croatia",
    code: "HR",
    callingCode: "+385"
  },
  {
    name: "Haiti",
    code: "HT",
    callingCode: "+509"
  },
  {
    name: "Hungary",
    code: "HU",
    callingCode: "+36"
  },
  {
    name: "Indonesia",
    code: "ID",
    callingCode: "+62"
  },
  {
    name: "Isle of Man",
    code: "IM",
    callingCode: "+44"
  },
  {
    name: "India",
    code: "IN",
    callingCode: "+91"
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    callingCode: "+246"
  },
  {
    name: "Ireland",
    code: "IE",
    callingCode: "+353"
  },
  {
    name: "Iran",
    code: "IR",
    callingCode: "+98"
  },
  {
    name: "Iraq",
    code: "IQ",
    callingCode: "+964"
  },
  {
    name: "Iceland",
    code: "IS",
    callingCode: "+354"
  },
  {
    name: "Israel",
    code: "IL",
    callingCode: "+972"
  },
  {
    name: "Italy",
    code: "IT",
    callingCode: "+39"
  },
  {
    name: "Jamaica",
    code: "JM",
    callingCode: "+1876"
  },
  {
    name: "Jersey",
    code: "JE",
    callingCode: "+44"
  },
  {
    name: "Jordan",
    code: "JO",
    callingCode: "+962"
  },
  {
    name: "Japan",
    code: "JP",
    callingCode: "+81"
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    callingCode: "+76"
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    callingCode: "+77"
  },
  {
    name: "Kenya",
    code: "KE",
    callingCode: "+254"
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    callingCode: "+996"
  },
  {
    name: "Cambodia",
    code: "KH",
    callingCode: "+855"
  },
  {
    name: "Kiribati",
    code: "KI",
    callingCode: "+686"
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    callingCode: "+1869"
  },
  {
    name: "South Korea",
    code: "KR",
    callingCode: "+82"
  },
  {
    name: "Kosovo",
    code: "XK",
    callingCode: "+383"
  },
  {
    name: "Kuwait",
    code: "KW",
    callingCode: "+965"
  },
  {
    name: "Laos",
    code: "LA",
    callingCode: "+856"
  },
  {
    name: "Lebanon",
    code: "LB",
    callingCode: "+961"
  },
  {
    name: "Liberia",
    code: "LR",
    callingCode: "+231"
  },
  {
    name: "Libya",
    code: "LY",
    callingCode: "+218"
  },
  {
    name: "Saint Lucia",
    code: "LC",
    callingCode: "+1758"
  },
  {
    name: "Liechtenstein",
    code: "LI",
    callingCode: "+423"
  },
  {
    name: "Sri Lanka",
    code: "LK",
    callingCode: "+94"
  },
  {
    name: "Lesotho",
    code: "LS",
    callingCode: "+266"
  },
  {
    name: "Lithuania",
    code: "LT",
    callingCode: "+370"
  },
  {
    name: "Luxembourg",
    code: "LU",
    callingCode: "+352"
  },
  {
    name: "Latvia",
    code: "LV",
    callingCode: "+371"
  },
  {
    name: "Macau",
    code: "MO",
    callingCode: "+853"
  },
  {
    name: "Saint Martin",
    code: "MF",
    callingCode: "+590"
  },
  {
    name: "Morocco",
    code: "MA",
    callingCode: "+212"
  },
  {
    name: "Monaco",
    code: "MC",
    callingCode: "+377"
  },
  {
    name: "Moldova",
    code: "MD",
    callingCode: "+373"
  },
  {
    name: "Madagascar",
    code: "MG",
    callingCode: "+261"
  },
  {
    name: "Maldives",
    code: "MV",
    callingCode: "+960"
  },
  {
    name: "Mexico",
    code: "MX",
    callingCode: "+52"
  },
  {
    name: "Marshall Islands",
    code: "MH",
    callingCode: "+692"
  },
  {
    name: "Macedonia",
    code: "MK",
    callingCode: "+389"
  },
  {
    name: "Mali",
    code: "ML",
    callingCode: "+223"
  },
  {
    name: "Malta",
    code: "MT",
    callingCode: "+356"
  },
  {
    name: "Myanmar",
    code: "MM",
    callingCode: "+95"
  },
  {
    name: "Montenegro",
    code: "ME",
    callingCode: "+382"
  },
  {
    name: "Mongolia",
    code: "MN",
    callingCode: "+976"
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    callingCode: "+1670"
  },
  {
    name: "Mozambique",
    code: "MZ",
    callingCode: "+258"
  },
  {
    name: "Mauritania",
    code: "MR",
    callingCode: "+222"
  },
  {
    name: "Montserrat",
    code: "MS",
    callingCode: "+1664"
  },
  {
    name: "Martinique",
    code: "MQ",
    callingCode: "+596"
  },
  {
    name: "Mauritius",
    code: "MU",
    callingCode: "+230"
  },
  {
    name: "Malawi",
    code: "MW",
    callingCode: "+265"
  },
  {
    name: "Malaysia",
    code: "MY",
    callingCode: "+60"
  },
  {
    name: "Mayotte",
    code: "YT",
    callingCode: "+262"
  },
  {
    name: "Namibia",
    code: "NA",
    callingCode: "+264"
  },
  {
    name: "New Caledonia",
    code: "NC",
    callingCode: "+687"
  },
  {
    name: "Niger",
    code: "NE",
    callingCode: "+227"
  },
  {
    name: "Norfolk Island",
    code: "NF",
    callingCode: "+672"
  },
  {
    name: "Nigeria",
    code: "NG",
    callingCode: "+234"
  },
  {
    name: "Nicaragua",
    code: "NI",
    callingCode: "+505"
  },
  {
    name: "Niue",
    code: "NU",
    callingCode: "+683"
  },
  {
    name: "Netherlands",
    code: "NL",
    callingCode: "+31"
  },
  {
    name: "Norway",
    code: "NO",
    callingCode: "+47"
  },
  {
    name: "Nepal",
    code: "NP",
    callingCode: "+977"
  },
  {
    name: "Nauru",
    code: "NR",
    callingCode: "+674"
  },
  {
    name: "New Zealand",
    code: "NZ",
    callingCode: "+64"
  },
  {
    name: "Oman",
    code: "OM",
    callingCode: "+968"
  },
  {
    name: "Pakistan",
    code: "PK",
    callingCode: "+92"
  },
  {
    name: "Panama",
    code: "PA",
    callingCode: "+507"
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    callingCode: "+64"
  },
  {
    name: "Peru",
    code: "PE",
    callingCode: "+51"
  },
  {
    name: "Philippines",
    code: "PH",
    callingCode: "+63"
  },
  {
    name: "Palau",
    code: "PW",
    callingCode: "+680"
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    callingCode: "+675"
  },
  {
    name: "Poland",
    code: "PL",
    callingCode: "+48"
  },
  {
    name: "Puerto Rico",
    code: "PR",
    callingCode: "+1787"
  },
  {
    name: "Puerto Rico",
    code: "PR",
    callingCode: "+1939"
  },
  {
    name: "North Korea",
    code: "KP",
    callingCode: "+850"
  },
  {
    name: "Portugal",
    code: "PT",
    callingCode: "+351"
  },
  {
    name: "Paraguay",
    code: "PY",
    callingCode: "+595"
  },
  {
    name: "Palestine",
    code: "PS",
    callingCode: "+970"
  },
  {
    name: "French Polynesia",
    code: "PF",
    callingCode: "+689"
  },
  {
    name: "Qatar",
    code: "QA",
    callingCode: "+974"
  },
  {
    name: "Réunion",
    code: "RE",
    callingCode: "+262"
  },
  {
    name: "Romania",
    code: "RO",
    callingCode: "+40"
  },
  {
    name: "Russia",
    code: "RU",
    callingCode: "+7"
  },
  {
    name: "Rwanda",
    code: "RW",
    callingCode: "+250"
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    callingCode: "+966"
  },
  {
    name: "Sudan",
    code: "SD",
    callingCode: "+249"
  },
  {
    name: "Senegal",
    code: "SN",
    callingCode: "+221"
  },
  {
    name: "Singapore",
    code: "SG",
    callingCode: "+65"
  },
  {
    name: "South Georgia",
    code: "GS",
    callingCode: "+500"
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    callingCode: "+4779"
  },
  {
    name: "Solomon Islands",
    code: "SB",
    callingCode: "+677"
  },
  {
    name: "Sierra Leone",
    code: "SL",
    callingCode: "+232"
  },
  {
    name: "El Salvador",
    code: "SV",
    callingCode: "+503"
  },
  {
    name: "San Marino",
    code: "SM",
    callingCode: "+378"
  },
  {
    name: "Somalia",
    code: "SO",
    callingCode: "+252"
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    callingCode: "+508"
  },
  {
    name: "Serbia",
    code: "RS",
    callingCode: "+381"
  },
  {
    name: "South Sudan",
    code: "SS",
    callingCode: "+211"
  },
  {
    name: "São Tomé and Príncipe",
    code: "ST",
    callingCode: "+239"
  },
  {
    name: "Suriname",
    code: "SR",
    callingCode: "+597"
  },
  {
    name: "Slovakia",
    code: "SK",
    callingCode: "+421"
  },
  {
    name: "Slovenia",
    code: "SI",
    callingCode: "+386"
  },
  {
    name: "Sweden",
    code: "SE",
    callingCode: "+46"
  },
  {
    name: "Swaziland",
    code: "SZ",
    callingCode: "+268"
  },
  {
    name: "Sint Maarten",
    code: "SX",
    callingCode: "+1721"
  },
  {
    name: "Seychelles",
    code: "SC",
    callingCode: "+248"
  },
  {
    name: "Syria",
    code: "SY",
    callingCode: "+963"
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    callingCode: "+1649"
  },
  {
    name: "Chad",
    code: "TD",
    callingCode: "+235"
  },
  {
    name: "Togo",
    code: "TG",
    callingCode: "+228"
  },
  {
    name: "Thailand",
    code: "TH",
    callingCode: "+66"
  },
  {
    name: "Tajikistan",
    code: "TJ",
    callingCode: "+992"
  },
  {
    name: "Tokelau",
    code: "TK",
    callingCode: "+690"
  },
  {
    name: "Turkmenistan",
    code: "TM",
    callingCode: "+993"
  },
  {
    name: "Timor-Leste",
    code: "TL",
    callingCode: "+670"
  },
  {
    name: "Tonga",
    code: "TO",
    callingCode: "+676"
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    callingCode: "+1868"
  },
  {
    name: "Tunisia",
    code: "TN",
    callingCode: "+216"
  },
  {
    name: "Turkey",
    code: "TR",
    callingCode: "+90"
  },
  {
    name: "Tuvalu",
    code: "TV",
    callingCode: "+688"
  },
  {
    name: "Taiwan",
    code: "TW",
    callingCode: "+886"
  },
  {
    name: "Tanzania",
    code: "TZ",
    callingCode: "+255"
  },
  {
    name: "Uganda",
    code: "UG",
    callingCode: "+256"
  },
  {
    name: "Ukraine",
    code: "UA",
    callingCode: "+380"
  },
  {
    name: "Uruguay",
    code: "UY",
    callingCode: "+598"
  },
  {
    name: "United States",
    code: "US",
    callingCode: "+1"
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    callingCode: "+998"
  },
  {
    name: "Vatican City",
    code: "VA",
    callingCode: "+379"
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    callingCode: "+1784"
  },
  {
    name: "Venezuela",
    code: "VE",
    callingCode: "+58"
  },
  {
    name: "British Virgin Islands",
    code: "VG",
    callingCode: "+1284"
  },
  {
    name: "United States Virgin Islands",
    code: "VI",
    callingCode: "+1340"
  },
  {
    name: "Vietnam",
    code: "VN",
    callingCode: "+84"
  },
  {
    name: "Vanuatu",
    code: "VU",
    callingCode: "+678"
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    callingCode: "+681"
  },
  {
    name: "Samoa",
    code: "WS",
    callingCode: "+685"
  },
  {
    name: "Yemen",
    code: "YE",
    callingCode: "+967"
  },
  {
    name: "South Africa",
    code: "ZA",
    callingCode: "+27"
  },
  {
    name: "Zambia",
    code: "ZM",
    callingCode: "+260"
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    callingCode: "+263"
  }
];

export const JA_ISD_CODES = [
  { name: "Iceland", code: "IS", callingCode: "+354" },
  { name: "Ireland", code: "IE", callingCode: "+353" },
  { name: "Azerbaijan", code: "AZ", callingCode: "+994" },
  { name: "Afghanistan", code: "AF", callingCode: "+93" },
  { name: "United States", code: "US", callingCode: "+1" },
  { name: "United States Virgin Islands", code: "VI", callingCode: "+1340" },
  { name: "American Samoa", code: "AS", callingCode: "+1684" },
  { name: "United Arab Emirates", code: "AE", callingCode: "+971" },
  { name: "Aruba", code: "AW", callingCode: "+297" },
  { name: "Algeria", code: "DZ", callingCode: "+213" },
  { name: "Botswana", code: "BW", callingCode: "+267" },
  { name: "Argentina", code: "AR", callingCode: "+54" },
  { name: "Albania", code: "AL", callingCode: "+355" },
  { name: "Armenia", code: "AM", callingCode: "+374" },
  { name: "Anguilla", code: "AI", callingCode: "+1264" },
  { name: "Angola", code: "AO", callingCode: "+244" },
  { name: "Antigua and Barbuda", code: "AG", callingCode: "+1268" },
  { name: "Andorra", code: "AD", callingCode: "+376" },
  { name: "Yemen", code: "YE", callingCode: "+967" },
  { name: "British Virgin Islands", code: "VG", callingCode: "+1284" },
  { name: "Israel", code: "IL", callingCode: "+972" },
  { name: "Italy", code: "IT", callingCode: "+39" },
  { name: "Iraq", code: "IQ", callingCode: "+964" },
  { name: "Iran", code: "IR", callingCode: "+98" },
  { name: "India", code: "IN", callingCode: "+91" },
  { name: "Indonesia", code: "ID", callingCode: "+62" },
  { name: "Wallis and Futuna", code: "WF", callingCode: "+681" },
  { name: "Uganda", code: "UG", callingCode: "+256" },
  { name: "Ukraine", code: "UA", callingCode: "+380" },
  { name: "Uzbekistan", code: "UZ", callingCode: "+998" },
  { name: "Uruguay", code: "UY", callingCode: "+598" },
  { name: "Ecuador", code: "EC", callingCode: "+593" },
  { name: "Egypt", code: "EG", callingCode: "+20" },
  { name: "Estonia", code: "EE", callingCode: "+372" },
  { name: "Ethiopia", code: "ET", callingCode: "+251" },
  { name: "Eritrea", code: "ER", callingCode: "+291" },
  { name: "El Salvador", code: "SV", callingCode: "+503" },
  { name: "Australia", code: "AU", callingCode: "+61" },
  { name: "Austria", code: "AT", callingCode: "+43" },
  { name: "Åland Islands", code: "AX", callingCode: "+358" },
  { name: "Oman", code: "OM", callingCode: "+968" },
  { name: "Netherlands", code: "NL", callingCode: "+31" },
  { name: "Ghana", code: "GH", callingCode: "+233" },
  { name: "Cape Verde", code: "CV", callingCode: "+238" },
  { name: "Guernsey", code: "GG", callingCode: "+44" },
  { name: "Guyana", code: "GY", callingCode: "+592" },
  { name: "Kazakhstan", code: "KZ", callingCode: "+76" },
  { name: "Qatar", code: "QA", callingCode: "+974" },
  { name: "Canada", code: "CA", callingCode: "+1" },
  { name: "Gabon", code: "GA", callingCode: "+241" },
  { name: "Cameroon", code: "CM", callingCode: "+237" },
  { name: "Gambia", code: "GM", callingCode: "+220" },
  { name: "Cambodia", code: "KH", callingCode: "+855" },
  { name: "Guinea", code: "GN", callingCode: "+224" },
  { name: "Guinea-Bissau", code: "GW", callingCode: "+245" },
  { name: "Cyprus", code: "CY", callingCode: "+357" },
  { name: "Cuba", code: "CU", callingCode: "+53" },
  { name: "Curaçao", code: "CW", callingCode: "+5999" },
  { name: "Greece", code: "GR", callingCode: "+30" },
  { name: "Kiribati", code: "KI", callingCode: "+686" },
  { name: "Kyrgyzstan", code: "KG", callingCode: "+996" },
  { name: "Guatemala", code: "GT", callingCode: "+502" },
  { name: "Guadeloupe", code: "GP", callingCode: "+590" },
  { name: "Guam", code: "GU", callingCode: "+1671" },
  { name: "Kuwait", code: "KW", callingCode: "+965" },
  { name: "Cook Islands", code: "CK", callingCode: "+682" },
  { name: "Greenland", code: "GL", callingCode: "+299" },
  { name: "Christmas Island", code: "CX", callingCode: "+61" },
  { name: "Georgia", code: "GE", callingCode: "+995" },
  { name: "Grenada", code: "GD", callingCode: "+1473" },
  { name: "Croatia", code: "HR", callingCode: "+385" },
  { name: "Cayman Islands", code: "KY", callingCode: "+1345" },
  { name: "Kenya", code: "KE", callingCode: "+254" },
  { name: "Ivory Coast", code: "CI", callingCode: "+225" },
  { name: "Cocos (Keeling) Islands", code: "CC", callingCode: "+61" },
  { name: "Costa Rica", code: "CR", callingCode: "+506" },
  { name: "Kosovo", code: "XK", callingCode: "+383" },
  { name: "Comoros", code: "KM", callingCode: "+269" },
  { name: "Colombia", code: "CO", callingCode: "+57" },
  { name: "Republic of the Congo", code: "CG", callingCode: "+242" },
  { name: "DR Congo", code: "CD", callingCode: "+243" },
  { name: "Saudi Arabia", code: "SA", callingCode: "+966" },
  { name: "South Georgia", code: "GS", callingCode: "+500" },
  { name: "Samoa", code: "WS", callingCode: "+685" },
  { name: "Saint Barthélemy", code: "BL", callingCode: "+590" },
  { name: "São Tomé and Príncipe", code: "ST", callingCode: "+239" },
  { name: "Zambia", code: "ZM", callingCode: "+260" },
  { name: "Saint Pierre and Miquelon", code: "PM", callingCode: "+508" },
  { name: "San Marino", code: "SM", callingCode: "+378" },
  { name: "Saint Martin", code: "MF", callingCode: "+590" },
  { name: "Sierra Leone", code: "SL", callingCode: "+232" },
  { name: "Djibouti", code: "DJ", callingCode: "+253" },
  { name: "Gibraltar", code: "GI", callingCode: "+350" },
  { name: "Jersey", code: "JE", callingCode: "+44" },
  { name: "Jamaica", code: "JM", callingCode: "+1876" },
  { name: "Syria", code: "SY", callingCode: "+963" },
  { name: "Singapore", code: "SG", callingCode: "+65" },
  { name: "Sint Maarten", code: "SX", callingCode: "+1721" },
  { name: "Zimbabwe", code: "ZW", callingCode: "+263" },
  { name: "Switzerland", code: "CH", callingCode: "+41" },
  { name: "Svalbard and Jan Mayen", code: "SJ", callingCode: "+4779" },
  { name: "Sweden", code: "SE", callingCode: "+46" },
  { name: "Sudan", code: "SD", callingCode: "+249" },
  { name: "Spain", code: "ES", callingCode: "+34" },
  { name: "Suriname", code: "SR", callingCode: "+597" },
  { name: "Sri Lanka", code: "LK", callingCode: "+94" },
  { name: "Slovakia", code: "SK", callingCode: "+421" },
  { name: "Slovenia", code: "SI", callingCode: "+386" },
  { name: "Swaziland", code: "SZ", callingCode: "+268" },
  { name: "Seychelles", code: "SC", callingCode: "+248" },
  { name: "Senegal", code: "SN", callingCode: "+221" },
  { name: "Serbia", code: "RS", callingCode: "+381" },
  { name: "Saint Kitts and Nevis", code: "KN", callingCode: "+1869" },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    callingCode: "+1784"
  },
  { name: "Saint Lucia", code: "LC", callingCode: "+1758" },
  { name: "Somalia", code: "SO", callingCode: "+252" },
  { name: "Solomon Islands", code: "SB", callingCode: "+677" },
  { name: "Thailand", code: "TH", callingCode: "+66" },
  { name: "Tajikistan", code: "TJ", callingCode: "+992" },
  { name: "Tanzania", code: "TZ", callingCode: "+255" },
  { name: "Czechia", code: "CZ", callingCode: "+420" },
  { name: "Chad", code: "TD", callingCode: "+235" },
  { name: "Tunisia", code: "TN", callingCode: "+216" },
  { name: "Chile", code: "CL", callingCode: "+56" },
  { name: "Tuvalu", code: "TV", callingCode: "+688" },
  { name: "Denmark", code: "DK", callingCode: "+45" },
  { name: "Germany", code: "DE", callingCode: "+49" },
  { name: "Togo", code: "TG", callingCode: "+228" },
  { name: "Tokelau", code: "TK", callingCode: "+690" },
  { name: "Dominica", code: "DM", callingCode: "+1767" },
  { name: "Dominican Republic", code: "DO", callingCode: "+1809" },
  { name: "Trinidad and Tobago", code: "TT", callingCode: "+1868" },
  { name: "Turkmenistan", code: "TM", callingCode: "+993" },
  { name: "Turks and Caicos Islands", code: "TC", callingCode: "+1649" },
  { name: "Turkey", code: "TR", callingCode: "+90" },
  { name: "Tonga", code: "TO", callingCode: "+676" },
  { name: "Nigeria", code: "NG", callingCode: "+234" },
  { name: "Nauru", code: "NR", callingCode: "+674" },
  { name: "Namibia", code: "NA", callingCode: "+264" },
  { name: "Niue", code: "NU", callingCode: "+683" },
  { name: "Nicaragua", code: "NI", callingCode: "+505" },
  { name: "Niger", code: "NE", callingCode: "+227" },
  { name: "New Caledonia", code: "NC", callingCode: "+687" },
  { name: "New Zealand", code: "NZ", callingCode: "+64" },
  { name: "Nepal", code: "NP", callingCode: "+977" },
  { name: "Norfolk Island", code: "NF", callingCode: "+672" },
  { name: "Norway", code: "NO", callingCode: "+47" },
  { name: "Bahrain", code: "BH", callingCode: "+973" },
  { name: "Haiti", code: "HT", callingCode: "+509" },
  { name: "Pakistan", code: "PK", callingCode: "+92" },
  { name: "Vatican City", code: "VA", callingCode: "+379" },
  { name: "Panama", code: "PA", callingCode: "+507" },
  { name: "Vanuatu", code: "VU", callingCode: "+678" },
  { name: "Bahamas", code: "BS", callingCode: "+1242" },
  { name: "Papua New Guinea", code: "PG", callingCode: "+675" },
  { name: "Bermuda", code: "BM", callingCode: "+1441" },
  { name: "Palau", code: "PW", callingCode: "+680" },
  { name: "Paraguay", code: "PY", callingCode: "+595" },
  { name: "Barbados", code: "BB", callingCode: "+1246" },
  { name: "Palestine", code: "PS", callingCode: "+970" },
  { name: "Hungary", code: "HU", callingCode: "+36" },
  { name: "Bangladesh", code: "BD", callingCode: "+880" },
  { name: "Pitcairn Islands", code: "PN", callingCode: "+64" },
  { name: "Fiji", code: "FJ", callingCode: "+679" },
  { name: "Philippines", code: "PH", callingCode: "+63" },
  { name: "Finland", code: "FI", callingCode: "+358" },
  { name: "Bhutan", code: "BT", callingCode: "+975" },
  { name: "Puerto Rico", code: "PR", callingCode: "+1787" },
  { name: "Faroe Islands", code: "FO", callingCode: "+298" },
  { name: "Falkland Islands", code: "FK", callingCode: "+500" },
  { name: "Brazil", code: "BR", callingCode: "+55" },
  { name: "France", code: "FR", callingCode: "+33" },
  { name: "French Guiana", code: "GF", callingCode: "+594" },
  { name: "French Polynesia", code: "PF", callingCode: "+689" },
  { name: "Bulgaria", code: "BG", callingCode: "+359" },
  { name: "Burkina Faso", code: "BF", callingCode: "+226" },
  { name: "Brunei", code: "BN", callingCode: "+673" },
  { name: "Burundi", code: "BI", callingCode: "+257" },
  { name: "Vietnam", code: "VN", callingCode: "+84" },
  { name: "Benin", code: "BJ", callingCode: "+229" },
  { name: "Venezuela", code: "VE", callingCode: "+58" },
  { name: "Belarus", code: "BY", callingCode: "+375" },
  { name: "Belize", code: "BZ", callingCode: "+501" },
  { name: "Peru", code: "PE", callingCode: "+51" },
  { name: "Belgium", code: "BE", callingCode: "+32" },
  { name: "Poland", code: "PL", callingCode: "+48" },
  { name: "Bosnia and Herzegovina", code: "BA", callingCode: "+387" },
  { name: "Bolivia", code: "BO", callingCode: "+591" },
  { name: "Portugal", code: "PT", callingCode: "+351" },
  { name: "Honduras", code: "HN", callingCode: "+504" },
  { name: "Marshall Islands", code: "MH", callingCode: "+692" },
  { name: "Macau", code: "MO", callingCode: "+853" },
  { name: "Macedonia", code: "MK", callingCode: "+389" },
  { name: "Madagascar", code: "MG", callingCode: "+261" },
  { name: "Mayotte", code: "YT", callingCode: "+262" },
  { name: "Malawi", code: "MW", callingCode: "+265" },
  { name: "Mali", code: "ML", callingCode: "+223" },
  { name: "Malta", code: "MT", callingCode: "+356" },
  { name: "Martinique", code: "MQ", callingCode: "+596" },
  { name: "Malaysia", code: "MY", callingCode: "+60" },
  { name: "Isle of Man", code: "IM", callingCode: "+44" },
  { name: "Micronesia", code: "FM", callingCode: "+691" },
  { name: "Myanmar", code: "MM", callingCode: "+95" },
  { name: "Mexico", code: "MX", callingCode: "+52" },
  { name: "Mauritius", code: "MU", callingCode: "+230" },
  { name: "Mauritania", code: "MR", callingCode: "+222" },
  { name: "Mozambique", code: "MZ", callingCode: "+258" },
  { name: "Monaco", code: "MC", callingCode: "+377" },
  { name: "Maldives", code: "MV", callingCode: "+960" },
  { name: "Moldova", code: "MD", callingCode: "+373" },
  { name: "Morocco", code: "MA", callingCode: "+212" },
  { name: "Mongolia", code: "MN", callingCode: "+976" },
  { name: "Montenegro", code: "ME", callingCode: "+382" },
  { name: "Montserrat", code: "MS", callingCode: "+1664" },
  { name: "Jordan", code: "JO", callingCode: "+962" },
  { name: "Laos", code: "LA", callingCode: "+856" },
  { name: "Latvia", code: "LV", callingCode: "+371" },
  { name: "Lithuania", code: "LT", callingCode: "+370" },
  { name: "Libya", code: "LY", callingCode: "+218" },
  { name: "Liechtenstein", code: "LI", callingCode: "+423" },
  { name: "Liberia", code: "LR", callingCode: "+231" },
  { name: "Romania", code: "RO", callingCode: "+40" },
  { name: "Luxembourg", code: "LU", callingCode: "+352" },
  { name: "Rwanda", code: "RW", callingCode: "+250" },
  { name: "Lesotho", code: "LS", callingCode: "+266" },
  { name: "Lebanon", code: "LB", callingCode: "+961" },
  { name: "Réunion", code: "RE", callingCode: "+262" },
  { name: "Russia", code: "RU", callingCode: "+7" },
  { name: "United Kingdom", code: "GB", callingCode: "+44" },
  { name: "British Indian Ocean Territory", code: "IO", callingCode: "+246" },
  { name: "South Korea", code: "KR", callingCode: "+82" },
  { name: "North Korea", code: "KP", callingCode: "+850" },
  { name: "Northern Mariana Islands", code: "MP", callingCode: "+1670" },
  { name: "Equatorial Guinea", code: "GQ", callingCode: "+240" },
  { name: "Taiwan", code: "TW", callingCode: "+886" },
  { name: "Central African Republic", code: "CF", callingCode: "+236" },
  { name: "China", code: "CN", callingCode: "+86" },
  { name: "Western Sahara", code: "EH", callingCode: "+212" },
  { name: "Japan", code: "JP", callingCode: "+81" },
  { name: "Hong Kong", code: "HK", callingCode: "+852" },
  { name: "Timor-Leste", code: "TL", callingCode: "+670" },
  { name: "South Africa", code: "ZA", callingCode: "+27" },
  { name: "South Sudan", code: "SS", callingCode: "+211" }
];

export const Today = "today";
export const last_24_hours = "last_24_hours";

export const CHART_DATA_TYPE = {
  DURATION: "duration",
  BYTES: "bytes",
  DT_BYTES: "dt_bytes"
};

export const CHART_DATA_TYPE_UNIT = { Minutes: "min", Gigabytes: "GB" };

export const ColorCodes = {
  missed: "#FACD6C",
  failed: "#F96B81",
  canceled: "#757181",
  finished: "#4BBFA5",
  error: "#F96B81",
  errors: "#F96B81",
  warnings: "#757181",
  warning: "#757181",
  active: "#5395B1",
  data_transferred: "#4894B3",
  "data transferred": "#4894B3",
  job_duration: "#8e90D7",
  "job duration": "#8e90D7",
  success: "#4BBFA5"
};

export const ConvertText = {
  data_processed: "Data Processed",
  data_transferred: "Data Transferred",
  data_written: "Data Written",
  source_data: "Source Data",
  dedupe_savings: "Dedupe Savings",
  job_duration: "Job Duration",
  active: "Active",
  failed: "Failed",
  canceled: "Canceled",
  finished: "Finished",
  warning: "Warning",
  warnings: "Warnings",
  errors: "Errors"
};

export const JobNameMapping = {
  restore: "Restore",
  rps_merge: "Merge",
  rps_replicate_in_bound: "Copy Recovery Points (IN)",
  rps_replicate: "Copy Recovery Points (OUT)",
  backup_incremental: "Backup-Incremental",
  oracledb_backup_incremental: "Backup-Incremental",
  backup_full: "Backup-Full",
  oracledb_backup_full: "Backup-Full",
  undeploy_policy_to_cloud_direct: "Unassign Policy",
  backup: "Backup",
  deploy_policy_to_udp: "Policy Deployment",
  undeploy_policy_to_udp: "Policy Undeployment",
  backup_verified: "Verify Backup",
  oracledb_backup_verified: "Verify Backup",
  merge: "Merge",
  assured_recovery: "Assured Recovery",
  replicate: "Replicate",
  vm_backup_full: "VM-Backup-Full",
  vm_backup_incremental: "VM-Backup-Incremental",
  udp_deploy_rps: "Deploy RPS",
  catalog_fs: "Catalog",
  vm_backup_verified: "Verify Backup",
  bmr: "BMR",
  node_discovery: "Source Discovery",
  data_seeding: "Data Seeding",
  purge: "Purge",
  catalog: "Catalog",
  instant_vhd: "Instant VHD",
  deploy_policy: "Deploy Policy",
  undeploy_policy: "Undeploy Policy",
  vm_catalog_fs: "VM-Catalog-FS",
  conversion: "Conversion",
  rps_conversion: "RPS Conversion",
  start_instant_vm: "Start Instant VM",
  stop_instant_vm: "Stop Instant VM",
  cifs_backup_incremental: "Incremental Backup",
  cifs_backup_full: "Full Backup",
  cifs_backup_verified: "Verify Backup",
  cifs_backup: "Full Backup",
  mount_recovery_point: "Mount Recovery Point",
  assure_recovery: "Assured Recovery",
  assured_security: "Assured Security",
  vm_recovery: "Recover VM",
  linux_instant_vm: "Linux Instant VM"
};

export const JobStatus = {
  skipped: "Skipped",
  canceled: "Canceled",
  idle: "Idle",
  finished: "Finished",
  failed: "Failed",
  missed: "Missed",
  incomplete: "Incomplete",
  crash: "Crash",
  waiting: "Waiting",
  stop: "Stop",
  active: "In progress",
  error: "Error",
  warning: "Warning",
  critical: "Critical",
  success: "Success",
  failure: "Failure",
  deploying: "Deploying",
  policy_deploying: "Deploying Policy",
  undeploying_policy: "Undeploying Policy",
  out_of_sync: "Out of Sync"
};

export const Name = "name";
export const Stack = "stack";
export const TopicName = "topicName";
export const X_AXIS_TICKS = 7;
export const IsGraterThanEqualTo = "=>";

export const RECOVERED_RESOURCES_TYPE = {
  clouddirect_draas: "Cloud Direct DRaaS"
};

export const CHART_COLORS = [
  "#83D3C0",
  "#5DC39A",
  "#9E4A89",
  "#5395B1",
  "#529499",
  "#A3DAF0",
  "#AFDB7D",
  "#FDC9A9",
  "#999BDB",
  "#FACC58",
  "#FF6767",
  "#F37E6B",
  "#FFA957",
  "#EE8D8D",
  "#9F9CA6",
  "#6463A3",
  "#8DAFEE",
  "#6B8C7B",
  "#B15384",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF"
];

export const LICENSE_STATUS = {
  TRIAL: "trial",
  ACTIVE: "active",
  INACTIVE: "inactive",
  DISABLED: "disabled",
  NONE: "none"
};

export const WIDGET_STATUS = {
  job_status: [
    { stack: "Finished" },
    { stack: "Failed" },
    { stack: "Canceled" }
  ],
  events: [{ stack: "Warnings" }, { stack: "Errors" }],
  job_duration: [{ stack: "Job Duration" }],
  data_transferred: [{ stack: "Data Transferred" }]
};

/**
 * states of recovered vms for loading various state and classes
 */
export const RECOVERD_VMS_LOADING_STATES = [
  "provisioning",
  "deprovisioning",
  "starting",
  "stopping",
  "restarting",
  " "
];

// NEW STUFF BELOW
export const MICROSERVICES = {
  CLOUD_DIRECT: "CLOUD_DIRECT",
  CLOUD_CONSOLE: "CLOUD_CONSOLE"
};

export const MICROSERVICES_TO_URL = {
  [MICROSERVICES.CLOUD_DIRECT]: process.env.REACT_APP_CLOUDVOLUME_HOST_URL,
  [MICROSERVICES.CLOUD_CONSOLE]: `${process.env.REACT_APP_BE_URL}/`
};

export const CCGRAPH_TYPES = {
  TIME_SERIES: "TIME_SERIES",
  // non-generic types
  REPORTS_BACKUP_JOBS_SUMMARY: "REPORTS_BACKUP_JOBS_SUMMARY",
  REPORTS_RECOVERY_JOBS_SUMMARY: "REPORTS_RECOVERY_JOBS_SUMMARY",
  DASHBOARD_BACKUP_JOBS_SUMMARY: "DASHBOARD_BACKUP_JOBS_SUMMARY",
  DASHBOARD_TOP_CUSTOMERS: "DASHBOARD_TOP_CUSTOMERS",
  DASHBOARD_TOP_POLICIES: "DASHBOARD_TOP_POLICIES",
  DASHBOARD_TOP_SOURCES: "DASHBOARD_TOP_SOURCES",
  DASHBOARD_NUMBER_OF_RECOVERY_POINTS: "DASHBOARD_NUMBER_OF_RECOVERY_POINTS",
  DASHBOARD_LATEST_RECOVERY_POINTS: "DASHBOARD_LATEST_RECOVERY_POINTS",
  DASHBOARD_OLDEST_RECOVERY_POINTS: "DASHBOARD_OLDEST_RECOVERY_POINTS",
  REPORTS_NUMBER_OF_RECOVERY_POINTS: "REPORTS_NUMBER_OF_RECOVERY_POINTS",
  REPORTS_LATEST_RECOVERY_POINTS: "REPORTS_LATEST_RECOVERY_POINTS",
  REPORTS_OLDEST_RECOVERY_POINTS: "REPORTS_OLDEST_RECOVERY_POINTS",
  REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES:
    "REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES",
  REPORTS_BACKUP_JOBS_TOP_SOURCES: "REPORTS_BACKUP_JOBS_TOP_SOURCES",
  REPORTS_POLICY_TASKS_SUMMARY: "REPORTS_POLICY_TASKS_SUMMARY",
  REPORTS_POLICY_TASKS_TOP_SOURCES: "REPORTS_POLICY_TASKS_TOP_SOURCES",
  REPORTS_RECOVERY_JOBS_TOP_SOURCES: "REPORTS_RECOVERY_JOBS_TOP_SOURCES",
  REPORTS_DATA_TRANSFER_TOP_SOURCES: "REPORTS_DATA_TRANSFER_TOP_SOURCES",
  DESTINATIONS_METRICS_CH_USAGE: "DESTINATIONS_METRICS_CH_USAGE",
  REPORTS_SOURCE_PROTECTION_SUMMARY: "REPORTS_SOURCE_PROTECTION_SUMMARY",
  STC_USAGE: "STC_USAGE"
};

export const CCGRAPH_IDS = {
  STC_CHANGE_RATE: "STC_CHANGE_RATE",
  STC_USAGE: "STC_USAGE",
  CLOUD_DIRECT_USAGE: "CLOUD_DIRECT_USAGE",
  MSP_CLOUD_DIRECT_USAGE: "MSP_CLOUD_DIRECT_USAGE",
  DATA_STORES_USAGE: "DATA_STORES_USAGE",
  MSP_DATA_STORES_USAGE: "MSP_DATA_STORES_USAGE",
  CUSTOMER_DATA_STORES_USAGE: "CUSTOMER_DATA_STORES_USAGE",
  DATA_TRANSFER_CLOUD_DIRECT_VOLUME: "DATA_TRANSFER_CLOUD_DIRECT_VOLUME",
  CLOUD_HYBRID_USAGE: "CLOUD_HYBRID_USAGE",
  CUSTOMER_METRICS_CD_USAGE: "CUSTOMER_METRICS_CD_USAGE",
  CUSTOMER_METRICS_CH_USAGE: "CUSTOMER_METRICS_CH_USAGE",
  DESTINATIONS_METRICS_CD_USAGE: "DESTINATIONS_METRICS_CD_USAGE",
  DESTINATIONS_METRICS_CH_USAGE: "DESTINATIONS_METRICS_CH_USAGE",
  DASHBOARD_BACKUP_JOBS_SUMMARY: "DASHBOARD_BACKUP_JOBS_SUMMARY",
  DASHBOARD_TOP_CUSTOMERS: "DASHBOARD_TOP_CUSTOMERS",
  DASHBOARD_TOP_POLICIES: "DASHBOARD_TOP_POLICIES",
  DASHBOARD_TOP_SOURCES: "DASHBOARD_TOP_SOURCES",
  DASHBOARD_NUMBER_OF_RECOVERY_POINTS: "DASHBOARD_NUMBER_OF_RECOVERY_POINTS",
  DASHBOARD_LATEST_RECOVERY_POINTS: "DASHBOARD_LATEST_RECOVERY_POINTS",
  DASHBOARD_OLDEST_RECOVERY_POINTS: "DASHBOARD_OLDEST_RECOVERY_POINTS",
  DASHBOARD_DEDUPE_SAVINGS_CLOUD_HYBRID:
    "DASHBOARD_DEDUPE_SAVINGS_CLOUD_HYBRID",
  REPORTS_BACKUP_JOBS_SUMMARY: "REPORTS_BACKUP_JOBS_SUMMARY",
  REPORTS_BACKUP_JOBS_TOP_SOURCES: "REPORTS_BACKUP_JOBS_TOP_SOURCES",
  REPORTS_BACKUP_JOBS_SUMMARY_TREND: "REPORTS_BACKUP_JOBS_SUMMARY_TREND",
  REPORTS_CLOUD_HYBRID_USAGE: "REPORTS_CLOUD_HYBRID_USAGE",
  REPORTS_CLOUD_DIRECT_USAGE: "REPORTS_CLOUD_DIRECT_USAGE",
  REPORTS_DATA_TRANSFER_SUMMARY: "REPORTS_DATA_TRANSFER_SUMMARY",
  REPORTS_DEDUPE_SAVINGS_CLOUD_HYBRID: "REPORTS_DEDUPE_SAVINGS_CLOUD_HYBRID",
  REPORTS_RECOVERY_JOBS_SUMMARY: "REPORTS_RECOVERY_JOBS_SUMMARY",
  REPORTS_RECOVERY_JOBS_TOP_SOURCES: "REPORTS_RECOVERY_JOBS_TOP_SOURCES",
  REPORTS_DATA_TRANSFER_TOP_SOURCES: "REPORTS_DATA_TRANSFER_TOP_SOURCES",
  REPORTS_RECOVERY_JOBS_SUMMARY_TREND: "REPORTS_RECOVERY_JOBS_SUMMARY_TREND",
  REPORTS_POLICY_TASKS_SUMMARY: "REPORTS_POLICY_TASKS_SUMMARY",
  REPORTS_POLICY_TASKS_TOP_SOURCES: "REPORTS_POLICY_TASKS_TOP_SOURCES",
  REPORT_STORED_DATA: "REPORT_STORED_DATA",
  REPORTS_NUMBER_OF_RECOVERY_POINTS: "REPORTS_NUMBER_OF_RECOVERY_POINTS",
  REPORTS_LATEST_RECOVERY_POINTS: "REPORTS_LATEST_RECOVERY_POINTS",
  REPORTS_OLDEST_RECOVERY_POINTS: "REPORTS_OLDEST_RECOVERY_POINTS",
  REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES:
    "REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES",
  REPORTS_MSP_CLOUD_DIRECT_USAGE: "REPORTS_MSP_CLOUD_DIRECT_USAGE",
  REPORTS_SOURCE_PROTECTION_SUMMARY: "REPORTS_SOURCE_PROTECTION_SUMMARY"
};

export const CCGRAPH_APIS = {
  STC_CHANGE_RATE: "restapi/storagecraft/changerate",
  STC_USAGE: "restapi/storagecraft/usage",
  DATA_TRANSFER_CLOUD_DIRECT_VOLUME: "datatransferreport/summary",
  CLOUD_DIRECT_REPORT_USAGE: "capacityusage/clouddirect/usage",
  CLOUD_DIRECT_USAGE: "cloudconsole/usage",
  MSP_CLOUD_DIRECT_USAGE: "cloudconsole/usage/accountusage",
  DATA_STORES_USAGE: "capacityusage/udp/usage",
  MSP_DATA_STORES_USAGE: "capacityusage/udp/usage",
  CUSTOMER_DATA_STORES_USAGE: "capacityusage/udp/usage",
  CLOUD_HYBRID_USAGE: "capacityusage/cloudhybrid/usage",
  DASHBOARD_BACKUP_JOBS_SUMMARY: "dashboards/backupjobstatussummary",
  REPORTS_BACKUP_JOBS_SUMMARY: "backupjobreports/jobstatussummary",
  REPORTS_RECOVERY_JOBS_SUMMARY: "restorejobreports/jobstatussummary",
  DASHBOARD_TOP_CUSTOMERS: "dashboards/topcustomers",
  DASHBOARD_TOP_POLICIES: "dashboards/toppolicies",
  DASHBOARD_TOP_SOURCES: "dashboards/topsources",
  DASHBOARD_NUMBER_OF_RECOVERY_POINTS: "dashboards/monthlyrecoverypoints",
  DASHBOARD_LATEST_RECOVERY_POINTS: "dashboards/latestrecoverypoints",
  DASHBOARD_OLDEST_RECOVERY_POINTS: "dashboards/oldestrecoverypoints",
  DASHBOARD_DEDUPE_SAVINGS_CLOUD_HYBRID:
    "capacityusage/cloudhybrid/dedupesavings",
  REPORTS_BACKUP_JOBS_TOP_SOURCES: "backupjobreports/topsources",
  REPORTS_RECOVERY_JOBS_TOP_SOURCES: "restorejobreports/topsources",
  REPORTS_DATA_TRANSFER_TOP_SOURCES: "dashboards/topsources",
  REPORTS_POLICY_TASKS_SUMMARY: "policytaskreports/jobstatussummary",
  REPORTS_POLICY_TASKS_TOP_SOURCES: "policytaskreports/topsources",
  REPORTS_NUMBER_OF_RECOVERY_POINTS:
    "recoverypointreports/monthlyrecoverypoints",
  REPORTS_LATEST_RECOVERY_POINTS: "recoverypointreports/latestrecoverypoints",
  REPORTS_OLDEST_RECOVERY_POINTS: "recoverypointreports/oldestrecoverypoints",
  REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES:
    "sourceprotectionreports/sourcessummary",
  REPORTS_SOURCE_PROTECTION_SUMMARY:
    "sourceprotectionreports/backupjobstatussummary"
};

// graphs with select
export const GRAPH_DEFAULT_FILTER = {
  REPORTS_BACKUP_JOBS_TOP_SOURCES: "job_status",
  REPORTS_POLICY_TASKS_TOP_SOURCES: "job_duration",
  REPORTS_RECOVERY_JOBS_TOP_SOURCES: "job_status",
  DASHBOARD_TOP_SOURCES: "job_status",
  DASHBOARD_NUMBER_OF_RECOVERY_POINTS: "udp",
  DASHBOARD_LATEST_RECOVERY_POINTS: "udp",
  DASHBOARD_OLDEST_RECOVERY_POINTS: "udp",
  REPORTS_NUMBER_OF_RECOVERY_POINTS: "udp",
  REPORTS_LATEST_RECOVERY_POINTS: "udp",
  REPORTS_OLDEST_RECOVERY_POINTS: "udp"
};

// graphs with changing type
export const GRAPH_CHANGING_TYPE = [
  CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY,
  CCGRAPH_IDS.REPORTS_POLICY_TASKS_SUMMARY,
  CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY
];

export const ANALYZE_SEARCHTEXT_KEY = {};

export const ROTATE_ANGLE_X = 22;

// list of languages which support lastname before firstname
export const SUPPORT_LASTNAME_FIRSTNAME = ["ja"];

export const SUPPORTED_THEMES = [
  {
    value: "system_default",
    key: "system_default"
  },
  {
    value: "light",
    key: "light"
  },
  {
    value: "dark",
    key: "dark"
  }
];

// list of languages supported by Cloud Console
// value is the language code, key is the translation key for the name of the language
// and name is the actual name of the language in its own native way
export const SUPPORTED_LANGUAGES = [
  {
    value: "en",
    key: "english",
    name: "English"
  },
  {
    value: "ja",
    key: "japanese",
    name: "日本語"
  },
  {
    value: "pt",
    key: "brazilian portuguese",
    name: "Português"
  },
  {
    value: "de",
    key: "german",
    name: "Deutsch"
  },
  {
    value: "fr",
    key: "france",
    name: "Français"
  },
  {
    value: "it",
    key: "italian",
    name: "Italiano"
  },
  {
    value: "es",
    key: "spanish",
    name: "Español"
  }
];

export const AGENTLESS_VM_OPTIONS = [
  {
    key: "select-sources",
    id: "protect.source.discover.select.sources",
    defaultMessage: "Select Source(s)"
  },
  {
    key: "add-hypervisor",
    id: "protect.source.add.hypervisor",
    defaultMessage: "Add Source(s) from Hypervisor"
  }
];

export const ADD_SOURCE_OPTIONS = [
  {
    key: "add-hypervisor",
    id: "protect.source.add.hypervisor",
    defaultMessage: "Add Source(s) from Hypervisor"
  },
  {
    key: "add-window-source",
    id: "protect.add.windows.source",
    defaultMessage: "Add Windows Source"
  },
  {
    key: "add-linux-source",
    id: "protect.source.add.linux.source",
    defaultMessage: "Add Linux Source"
  },
  {
    key: "add-linuxbackup-source",
    id: "protect.source.add.linux.backup.source",
    defaultMessage: "Add Linux Backup Server"
  },
  {
    key: "add-unc-nfs-path-source",
    id: "protect.add.unc_nfs_path.source",
    defaultMessage: "Add a UNC or NFS Path"
  },
  {
    key: "add-oracle-database",
    id: "protect.source.add.oracle.database",
    defaultMessage: "Add Oracle Database"
  },
  {
    key: "discover-directory",
    id: "protect.source.discove.source.active.directory",
    defaultMessage: "Discover Source(s) from Active Directory"
  }
];

export const ADD_SOURCE_OPTIONS_UDP = [
  "add-window-source",
  "add-linux-source",
  "add-linuxbackup-source",
  "discover-directory",
  "add-unc-nfs-path-source",
  "add-oracle-source",
  "add-oracle-database"
];

export const SOURCE_TYPE_WINDOWS = "machine";
export const WINDOWS_SOURCES = "windowsSources";
export const ORACLE_SOURCES = "OracleSources";
export const ORACLE_WINDOWS_SOURCE_FORM = "OracleWindowsSources";
export const ORACLE_LINUX_SOURCE_FORM = "OracleLinuxSources";
export const ORACLE_WINDOWS_DB_SOURCES = "OracleWindowsDataBaseSources";
export const LINUX_SOURCES = "linuxSources";
export const PORT = 6054;
export const SOURCE_ID = "uuid";
export const SOURCE_TYPE_LINUX = "linux";
export const SOURCE_TYPE_AGENTLESS_VM = "agentless_vm";
export const SOURCE_TYPE_LINUX_BACKUP_SERVER = "udp_linux_backup_server";
export const UNC_OR_NFS_PATHS = "UncOrNfsPaths";
export const SOURCE_TYPE_UDP_WINDOWS = "udp_windows";
export const SOURCE_TYPE_UDP_LINUX = "udp_linux";
export const SOURCE_TYPE_SHARED_FOLDER = "shared_folder";
export const SOURCE_TYPE_ORACLE_DATABASE = "udp_oracle";
export const SOURCE_TYPE_WINDOWS_ORACLE_HOST = "windows_oracle_host";
export const SOURCE_TYPE_LINUX_ORACLE_HOST = "linux_oracle_host";

export const QUERY_STRING_FOR_SOURCE_GRID = `${SOURCE_TYPE_WINDOWS}%7C${SOURCE_TYPE_LINUX}%7C${SOURCE_TYPE_AGENTLESS_VM}%7C${SOURCE_TYPE_LINUX_BACKUP_SERVER}%7C${SOURCE_TYPE_UDP_WINDOWS}%7C${SOURCE_TYPE_UDP_LINUX}%7C${SOURCE_TYPE_SHARED_FOLDER}%7C${SOURCE_TYPE_ORACLE_DATABASE}`;
export const QUERY_STRING_FOR_SOURCE_GRID_WITHOUT_ORACLE_DB = `${SOURCE_TYPE_WINDOWS}%7C${SOURCE_TYPE_LINUX}%7C${SOURCE_TYPE_AGENTLESS_VM}%7C${SOURCE_TYPE_LINUX_BACKUP_SERVER}%7C${SOURCE_TYPE_UDP_WINDOWS}%7C${SOURCE_TYPE_UDP_LINUX}%7C${SOURCE_TYPE_SHARED_FOLDER}`;
export const OS_MAJOR = {
  LINUX: "linux"
};

export const CONNECTION_STATUS = {
  ONLINE: "online"
};

export const API_ERROR_CODE = {
  RESTORE_JOB_ALREADY_RUNNING: "4100",
  UNABLE_TO_DELETE_DESTINATION: "00C00006"
};

export const PRODUCT_TYPE = {
  CLOUD_DIRECT: "cloud_direct",
  UDP: "udp"
};

export const ROLES_THAT_CAN_BE_ASSIGNED_ORGS = [
  "msp_account_admin",
  "msp_account_recovery_admin",
  "msp_account_monitor"
];

export const ROLES_THAT_CANNOT_START_TRIAL = [
  "msp_account_admin",
  "msp_monitor",
  "msp_account_recovery_admin",
  "msp_recovery_admin",
  "msp_account_monitor"
];

export const ROLES_WITH_ASSIGN_ADMIN_DISABLED = [
  "msp_account_admin",
  "msp_account_recovery_admin",
  "msp_recovery_admin",
  "msp_account_monitor"
];

export const ROLES_WITH_READ_ONLY_ACCESS = [
  "msp_account_recovery_admin",
  "msp_recovery_admin",
  "msp_monitor",
  "direct_monitor",
  "direct_recovery_admin",
  "msp_account_monitor"
];

export const ROLES_THAT_CANNOT_CANCEL_IVM_JOBS = [
  "direct_admin",
  "direct_recovery_admin",
  "msp_admin",
  "msp_account_recovery_admin",
  "msp_recovery_admin",
  "msp_account_admin",
  "msp_monitor",
  "direct_monitor",
  "msp_account_monitor"
];
export const DESTINATION_COUNT_KEYS = {
  DATA_STORE: "datastore",
  RECOVERY_POINT_SERVER: "recovery_point_server",
  SHARED_FOLDER: "share_folder"
};

export const DATA_STORE_VALUES = {
  deduplicationBlockSizes: [
    {
      key: 4,
      value: "4 KB"
    },
    {
      key: 8,
      value: "8 KB"
    },
    {
      key: 16,
      value: "16 KB"
    },
    {
      key: 32,
      value: "32 KB"
    },
    {
      key: 64,
      value: "64 KB"
    }
  ],
  signal: {
    signalOne: "signal-one",
    signalTwo: "signal-two",
    signalThree: "signal-three",
    signalFour: "signal-four",
    signalFive: "signal-five",
    standardVal: "standard",
    maximumVal: "maximum"
  },
  compressionType: {
    standard: "standard",
    maximum: "maximum"
  },
  defaultStoreValues: {
    concurrentActiveNodes: "4",
    minHash: "1024",
    maxHash: "4095",
    compressionType: "standard"
  },
  requirementPlanningValues: {
    dataSizeStored: "10000",
    dataPercentageDeduplicated: "60",
    dataPercentageCompressedVal: "30"
  }
};

export const ENCRYPT = {
  YES: "Yes",
  NO: "No"
};

export const VALIDATED = {
  YES: "Yes",
  NO: "No"
};
export const COPY_TO_DS = {
  type: "udp_replication",
  backup_destination_type: "local_disk",
  linux_backup_destination_type: "shared_folder"
};

export const ADVANCE_SEARCH_ERROR_MESSAGE = "protect.policy.search_name_error";
export const searchOptionClearDS = "datastores";
export const searchOptionClearRPS = "recovery_point_servers";
export const DOCUMENT_LINK =
  "https://documentation.arcserve.com/Arcserve-Cloud/Available/Cloud_Console/ENU/olh/default.htm";

export const AVAILABLE_STATUS = {
  ACTIVE: "active",
  FINISHED: "finished",
  IDLE: "idle",
  WAITING: "waiting",
  POLICY_DEPLOYING: "policy_deploying",
  UNDEPLOYING_POLICY: "undeploying_policy"
};

export const STATUS_ICONS = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error"
};

export const WIDGETS_HIDDEN = [
  "top10Policies",
  "usageTrendsForCloudHybridStores",
  "usageTrendsForCloudDirectVolumes",
  "usageTrendsForDataStores",
  "backupJobSummary",
  "capacityUsageTrendForCloudHybridStores",
  "dataTransferSummaryForCloudDirectVolumes",
  "top10customers",
  "stcChangeRate",
  "stcUsage"
];

export const DEFAULT_HELP_LINK =
  "https://documentation.arcserve.com/Arcserve-Cloud/Available/Cloud_Console/ENU/olh/default.htm";

export const NETAPP_STORAGE_MODES = [
  { key: "standalone", value: "Standalone" },
  { key: "cluster", value: "Cluster (SVM)" },
  { key: "vfiler", value: "vFiler" }
];

export const STORAGE_VENDORS = [
  { key: "Nimble", value: "Nimble" },
  { key: "NetApp", value: "NetApp" }
  // { key: "HP_RMC", value: "HP-RMC" } hidden for  US-1050322
];

export const STORAGE_VENDORS_LIST = {
  Nimble: "Nimble",
  NetApp: "NetApp",
  HP_RMC: "HP_RMC"
};

/*
 * Proxy roles
 */
export const PROXY_ROLES = {
  VIRTUAL_STANDBY_CONVERSION: "virtual_standby_conversion",
  VIRTUAL_STANDBY_MONITORING: "virtual_stabdby_monitoring",
  INSTANT_VIRTUAL_MACHINE: "instant_virtual_machine",
  ASSURED_RECOVERY_TEST: "assured_recovery_test",
  LINUX_BACKUP_SERVER: "linux_backup_server",
  WINDOWS_BACKUP_SERVER: "windows_backup_server",
  UNC_PATH: "unc_path",
  VMWARE_AGENTLESS: "vmware_agentless",
  HYPERV_AGENTLESS: "hyperv_agentless",
  NUTANIX_AGENTLESS: "nutanix_agentless",
  UNKNOWN: "unknown",
  VSB_MONITOR: "vsb_monitor"
};

export const PAGES_THAT_MSP_USERS_NEED_IMPERSONATION = [
  SECONDARY_NAVIGATION_TYPE.SOURCES,
  SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.MACHINES,
  SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
  SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBYS,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY,
  SECONDARY_NAVIGATION_TYPE.INSTANT_VMS,
  SECONDARY_NAVIGATION_TYPE.SHARE_POINT,
  SECONDARY_NAVIGATION_TYPE.OFFICE_365,
  SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER,
  SECONDARY_NAVIGATION_TYPE.DESTINATIONS,
  SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES,
  SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD,
  SECONDARY_NAVIGATION_TYPE.POLICIES,
  SECONDARY_NAVIGATION_TYPE.HYPERVISORS,
  SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD_RPS,
  SECONDARY_NAVIGATION_TYPE.SITES,
  SECONDARY_NAVIGATION_TYPE.PROXIES,
  SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS,
  SECONDARY_NAVIGATION_TYPE.VMS,
  SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE,
  SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS,
  SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS_SOURCES,
  SECONDARY_NAVIGATION_TYPE.RECOVERY_POINTS_URL,
  SECONDARY_NAVIGATION_TYPE.VM_HYPERVISOR,
  SECONDARY_NAVIGATION_TYPE.SOURCE_LOG,
  SECONDARY_NAVIGATION_TYPE.SOURCE_JOBS,
  SECONDARY_NAVIGATION_TYPE.RPS_LOG,
  SECONDARY_NAVIGATION_TYPE.RPS_JOBS,
  SECONDARY_NAVIGATION_TYPE.DATA_STORE_LOG,
  SECONDARY_NAVIGATION_TYPE.DATA_STORE_JOBS,
  SECONDARY_NAVIGATION_TYPE.SOURCE_POLICY,
  SECONDARY_NAVIGATION_TYPE.RECOVERED_RESOURCES,
  SECONDARY_NAVIGATION_TYPE.SOURCE_GROUP_DETAILS,
  SECONDARY_NAVIGATION_TYPE.ADD_SOURCES,
  SECONDARY_NAVIGATION_TYPE.RECOVERED_VMS,
  SECONDARY_NAVIGATION_TYPE.MOUNTED_RECOVERY_POINTS,
  SECONDARY_NAVIGATION_TYPE.NETWORK_CONFIGURATION,
  SECONDARY_NAVIGATION_TYPE.RECOVERY_TARGETS,
  SECONDARY_NAVIGATION_TYPE.INFRASTRUCTURE,
  SECONDARY_NAVIGATION_TYPE.CLOUD_ACCOUNT,
  SECONDARY_NAVIGATION_TYPE.STORAGE_ARRAYS,
  SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS,
  SECONDARY_NAVIGATION_TYPE.UDP_LINUX,
  SECONDARY_NAVIGATION_TYPE.INSTANT_VMS_RR
];
export const CCDATAGRID_ID = {
  SOURCE_GROUP: "source_group",
  HYPERVISOR: "hypervisor",
  STORAGE_ARRAYS: "storage_arrays",
  ORACLE_HOSTS: "oracle_hosts",
  USER_ROLES: "user_roles",
  USER_ACCOUNTS: "user_accounts",
  SITES: "sites",
  PROXIES: "proxies",
  SOURCE_SEARCH: "source_search",
  SELECT_RECOVERY_POINT: "select_recovery_point",
  SELECT_CREATE_STANDBY_VM_PROXY: "select_create_standby_vm_proxy",
  SELECT_CREATE_STANDBY_VM_HYPERV: "select_create_standby_vm_hyperv",
  SELECT_CREATE_STANDBY_VM_NUTANIX: "select_create_standby_vm_nutanix",
  TABLESPACES: "TableSpaces",
  DATAFILES: "DataFiles",
  SOURCE_CONFIGURE: "source_configure",
  CONTAINER_POLICY: "container_policy",
  RP_MERGE: "RP_MERGE",
  SOURCES: "sources",
  PHYSICAL_MACHINES: "physical_machines",
  VIRTUAL_MACHINES: "virtual_machines",
  UNC_OR_NFS_PATHS: "unc_nfs_paths",
  POLICIES: "policies",
  AUDIT_TRAIL: "audit_logs",
  SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN:
    "select_recovery_point_assured_recovery_scan",
  BROWSE_SHARE_FOLDER_RECOVERY_POINT: "browse_shared_folder_recovery_point",
  REPLICATION: "replication"
};

export const ACTIVE_CCDATAGRID_ID = {
  SOURCE_GROUP: "source_group",
  HYPERVISOR: "hypervisor",
  STORAGE_ARRAYS: "storage_arrays",
  // ORACLE_HOSTS: "oracle_hosts",
  USER_ROLES: "user_roles",
  // USER_ACCOUNTS: "user_accounts",
  SITES: "sites",
  PROXIES: "proxies",
  SOURCE_SEARCH: "source_search",
  SELECT_RECOVERY_POINT: "select_recovery_point",
  SELECT_CREATE_STANDBY_VM_PROXY: "select_create_standby_vm_proxy",
  SELECT_CREATE_STANDBY_VM_HYPERV: "select_create_standby_vm_hyperv",
  SELECT_CREATE_STANDBY_VM_NUTANIX: "select_create_standby_vm_nutanix",
  SOURCE_CONFIGURE: "source_configure",
  CONTAINER_POLICY: "container_policy",
  // RP_MERGE: "RP_MERGE",
  SOURCES: "sources",
  PHYSICAL_MACHINES: "physical_machines",
  VIRTUAL_MACHINES: "virtual_machines",
  UNC_OR_NFS_PATHS: "unc_nfs_paths",
  POLICIES: "policies",
  AUDIT_TRAIL: "audit_logs",
  SELECT_RECOVERY_POINT_ASSURED_SECURITY_SCAN:
    "select_recovery_point_assured_recovery_scan",
  BROWSE_SHARE_FOLDER_RECOVERY_POINT: "browse_shared_folder_recovery_point",
  REPLICATION: "replication"
};

export const WITH_STORED_COLUMNS = [
  SECONDARY_NAVIGATION_TYPE.SOURCES,
  SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.MACHINES,
  SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
  SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
  SECONDARY_NAVIGATION_TYPE.POLICIES
];

export const CCDATAGRID_ID_SEARCH_FIELD = {
  [CCDATAGRID_ID.SOURCE_GROUP]: "group_name",
  [CCDATAGRID_ID.SOURCES]: "source_name",
  [CCDATAGRID_ID.PHYSICAL_MACHINES]: "source_name",
  [CCDATAGRID_ID.VIRTUAL_MACHINES]: "source_name",
  [CCDATAGRID_ID.UNC_OR_NFS_PATHS]: "source_name",
  [CCDATAGRID_ID.POLICIES]: "policy_name",
  [CCDATAGRID_ID.AUDIT_TRAIL]: "on_resource_name"
};

export const BRAND_NAMES = {
  ARCSERVE_PRODUCT_NAME: "Arcserve",
  CD_PRODUCT_NAME: "Cloud Direct",
  CH_PRODUCT_NAME: "Cloud Hybrid",
  UDP_PRODUCT_NAME: "UDP"
};

export const CONFIGURE_PAGES_WITHOUT_SEARCH = [
  SECONDARY_NAVIGATION_TYPE.HYPERVISORS,
  SECONDARY_NAVIGATION_TYPE.STORAGE_ARRAYS,
  SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS,
  SECONDARY_NAVIGATION_TYPE.PROXIES
];

export const TWO_FACTOR_MOBILE_OS = [
  { key: "Android", value: "Android" },
  { key: "iOS", value: "iOS" },
  { key: "Blackberry", value: "Blackberry" }
];

export const TWO_FACTOR_LINKS = {
  GOOGLE_SUPPORT: "https://support.google.com/accounts/answer/185834",
  IOS: "http://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8",
  ANDROID:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
};

export const HELP_LINK_ID = {
  // SOURCE
  SOURCE_ADD_HYPERVISOR: "help001",
  SOURCE_ADD_WINDOWS: "help002",
  SOURCE_ADD_UNF_NFS_PATH: "help003",
  SOURCE_DISCOVERY_NODES_ACTIVE_DIRECTORY: "help004",
  SOURCE_START_RECOVERY: "help005",
  SOURCE_UPDATE: "help006",
  SOURCE_RESUME_VIRTUAL_STANDBY: "help007",
  SOURCE_START_VIRTUAL_STANDBY: "help008",
  SOURCE_STANDBY_VM_NETWORK_CONFIGURATION: "help009",
  SOURCE_ADD_UNC_BACKUP_PROXY_SERVER: "help010",
  SOURCE_INSTALL_UPGRADE_AGENT: "help011",
  SOURCE_CREATE_INSTANT_VM: "help012",
  SOURCE_CREATE_STANDBY_VM: "help100",
  SOURCE_CREATE_STANDBY_VM_CONNECT_MODAL: "help102",
  SOURCE_VM_DESTINATION: "help013", // Not in use
  SOURCE_ADD_NEWTWORK_ADAPTER: "help014",
  SOURCE_UPDATE_DNS_SETTINGS: "help015",
  SOURCE_ADD_O365: "help016", // Not in use
  SOURCE_RECOVER_FILES_FOLDERS: "help017", // Not in use
  SOURCE_RECOVER_FILES_FOLDERS_BROWSE: "help018", // Not in use
  SOURCE_RECOVER_FILES_FOLDERS_LINUX_BROWSE: "help019", // Not in use
  SOURCE_RECOVER_SQL_BROWSE: "help020", // Not in use
  SOURCE_RECOVER_VM_BROWSE: "help021", // Not in use
  SOURCE_RECOVER_VM_VCENTER_ESX_BROWSE: "help022", // Not in use
  SOURCE_RECOVER_VOLUME_BROWSE: "help023", // Not in use
  SOURCE_UNC_NFS_PATH_BROWSE: "help024", // Not in use
  SOURCE_EDIT_ACTIVE_DIRECTORY: "help025", // Not in use
  SOURCE_ADD_NODE_ACTIVE_DIRECTORY_AUTH: "help026",
  SOURCE_ADD_LINUX_BACKUP_SERVER: "help027",
  SOURCE_ADD_LINUX_SOURCE: "help028",
  SOURCE_ADD_SOURCE: "help029", // Not in use
  SOURCE_CONNECT_UNC_NFS_PATH: "help030",
  SOURCE_START_BACKUP: "help073",
  SOURCE_MOUNT_RECOVERY_POINT: "help075",
  SOURCE_COLLECT_DIAGNOSTIC_DATA: "help076",
  SOURCE_CUSTOMIZE_DNS_UPDATE_DIALOG: "help077",
  SOURCE_MERGE_NOW: "help081",
  SOURCE_RECOVERY_VERIFY_PASSWORD: "help082",
  SOURCE_IVM_VERIFY_PASSWORD: "help083",
  SOURCE_CONNECT_FNF_MODAL: "help084",
  SOURCE_RUN_ASSURED_RECOVERY_TEST_MODAL: "help090",
  SOURCE_CONNECT_ORACLE_MODAL: "help091",
  SOURCE_ADD_ORACLE_HOST: "help092",
  SOURCE_ADD_ORACLE_DB: "help093",
  SOURCE_ORACLE_DB_UPDATE: "help094",
  SOURCE_ORACLE_HOST_UPDATE: "help095",
  SOURCE_SET_BACKUP_PASSWORDS_MODAL: "help096",
  SOURCE_RECOVER_CD: "help097",
  SOURCE_CREATE_STANBY_VM_VERIFY_PASSWORD: "help101",
  SOURCE_ADHOC_ASSURED_SECURITY_VERIFY_PASSWORD: "help104",
  SOURCE_RUN_ASSURED_SECURITY_SCAN_MODAL: "help114",
  ADD_VIRTUAL_STANDBY_MONITOR: "help098",
  VSB_EC2_CLOUD_PROXY: "help105",
  VSB_GCP_CLOUD_PROXY: "help108",
  ADD_ASSURED_RECOVERY: "help111",
  UPLOAD_DIAGNOSTIC_INFORMATION: "help110",
  ADHOC_REPLICATION: "help113",
  VM_VOLUME_INFORMATION: "help115",

  // DESTINATION
  DESTINATION_ADD_RECOVERY_POINT_SERVER: "help031",
  DESTINATION_UPDATE_RECOVERY_POINT_SERVER: "help032",
  DESTINATION_ADD_DATA_STORE: "help033",
  DESTINATION_ADD_CLOUD_DATA_STORE: "help085",
  DESTINATION_IMPORT_DATA_STORE: "help034",
  DESTINATION_IMPORT_CLOUD_DATA_STORE: "help086",
  DESTINATION_CONNECT_DATA_STORE: "help035",
  DESTINATION_ADD_DATA_STORE_BROWSE: "help036", // Not in use
  DESTINATION_IMPORT_DATA_STORE_BROWSE: "help037", // Not in use
  DESTINATION_SHARED_FOLDER_BROWSE: "help038", // Not in use
  DESTINATION_ADD_DESTINATION_HOST: "help039",
  DESTINATION_RPS_JUMPSTART: "help040",
  DESTINATION_DEPLOYE_UPGRADE_RPS: "help041", // Not in use
  DESTINATION_MODIFY_DATA_STORE: "help042",
  DESTINATION_MODIFY_CLOUD_DATA_STORE: "help087",
  DESTINATION_ADD_CLOUD_ACCOUNT: "help043",
  DESTINATION_RPS_DEPLOYE_UPGRADE: "help044",
  DESTINATION_UPDATE_CLOUD_ACCOUNT: "help045",
  DESTINATION_UPDATE_SHARED_FOLDER: "help046",
  DESTINATION_RECOVER_VM: "help047", // Not in use
  DESTINATION_VM_VALIDATION: "help048",
  DESTINATION_ADD_HYPERV_DIALOG: "help049", // Not in use
  DESTINATION_ADD_REMOTE_CONSOLE: "help050", // Not in use
  DESTINATION_CONNECT_SHARED_FOLDER: "help051", // Not in use
  DEDUPLICATION_AND_COMPRESSION: "help106",
  DESTIONATION_CLOUD_DATASTORE_SYNC_WARNING: "help116",

  // POLICY
  POLICY_UNC_ASSURED_RECOVERY_BROWSE: "help052", // Not in use
  POLICY_AVM_ASSURED_RECOVERY_BROWSE: "help053", // Not in use
  POLICY_ABW_ASSURED_RECOVERY_BROWSE: "help054", // Not in use
  POLICY_ABL_ASSURED_RECOVERY_BROWSE: "help055", // Not in use
  POLICY_VIRTUAL_STANDBY_BROWSE: "help056", // Not in use
  POLICY_ASSURED_RECOVERY_BROWSE: "help057", // Not in use
  POLICY_ADD_LINUX_BACKUP: "help058",
  POLICY_ASSURED_RECOVERY_LINUX_PROXY_SERVER: "help059",
  POLICY_ASSURED_RECOVERY_PROXY_SERVER: "help060",
  POLICY_LINUX_BACKUP_SERVER_NODE: "help061", // Not in use
  POLICY_CONNECT_DIALOG: "help078",
  POLICY_ADD_SOURCE_FROM_HYPERVISOR_MODAL: "help079",
  POLICY_RUN_ASSURED_RECOVERY_TEST_MODAL: "help089",
  POLICY_ASSURED_RECOVERY_NUTANIX_MODAL: "help099",
  POLICY_RUN_ASSURED_SECURITY_SCAN_MODAL: "help103",
  PREREQUISITES_FOR_ORACLE_DB: "help107",
  POLICY_ORACLE_DB_BACKUP: "help109",

  // CONFGIURE
  CONFIGURE_UPDATE_VCENTER_ESX: "help062",
  CONFIGURE_UPDATE_HYPER_V: "help063",
  CONFIGURE_ADD_SITE: "help064",
  CONFIGURE_MODIFY_SITE: "help088",
  CONFIGURE_ADD_STORAGE_ARRAY: "help065",
  CONFIGURE_MODIFY_STORAGE_ARRAY: "help066",
  CONFIGURE_MODIFY_PROXY: "help067",
  CONFIGURE_ADD_ACTIVE_DIRECTORY: "help068",
  CONFIGURE_ADD_MICROSOFT_HYPER_V: "help069", // Not in use
  CONFIGURE_ADD_VMWARE_VSPHERE: "help070",
  COMMON_RESTORE_RECOVERY_POINT: "help071", // Not in use
  ANALYZE_CREATE_REPORTS_MODAL: "help072",
  CONFIGURE_ACCESS_CONTROL_USER_ACCOUNTS_MANAGE_PERMISSION: "help074",
  CONFIGURE_ADD_HYPERVISOR: "help080",
  AR_VMWARE_HELP: "help112" // Not in use,
};

export const CONNECT_STATUS = {
  INITIAL: "connect",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  FAILED: "notconnected"
};

export const HYPERVISOR_PAGES = {
  SOURCE_DISCOVERY: "sourceDiscovery"
};

export const CLOUD_SERVICES = [
  { key: "Amazon EC2", value: "amazon_ec2" },
  {
    key: "Amazon EC2-China",
    value: "amazon_ec2_china"
  },
  {
    key: "Microsoft Azure Compute",
    value: "microsoft_azure_compute"
  }
];

/**
 *  Job types for which progress bar should not be shown
 */
export const JOB_TYPES_HIDE_PROGRESS_BAR = [
  "vm_recovery",
  "restore",
  "assure_recovery",
  "mount_recovery_point"
];

export const ENTITY_NODE_TYPE = {
  VIRTUAL_MACHINE: "VirtualMachine",
  RESOURCE_POOL: "ResourcePool",
  HOST_SYSTEM: "HostSystem",
  DATACENTER: "Datacenter",
  CLUSTER_COMPUTE_RESOURCE: "ClusterComputeResource",
  VIRTUAL_MACHINE_FOLDER: "VirtualMachine-Folder",
  VCENTER: "vCenter",
  VIRTUALAPP: "VirtualApp",
  INVENTORY_SERVICE_CATEGORY: "InventoryServiceCategory",
  INVENTORY_SERVICE_TAG: "InventoryServiceTag",
  HYPERV: "HyperV",
  HYPERV_LOCALDISK: "HyperVLocalDisk"
};

export const HYPERV_TYPE = {
  hyperv: "Hyper-V",
  vcenter: "vCenter",
  esxhost: "ESX"
};

export const SOURCES_GRID_LOCALLOAD_PAGES = [
  SECONDARY_NAVIGATION_TYPE.SOURCES,
  SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.MACHINES,
  SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.SHARE_POINT,
  SECONDARY_NAVIGATION_TYPE.OFFICE_365,
  SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
  SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER,
  SECONDARY_NAVIGATION_TYPE.UDP_LINUX,
  SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS
];

export const CDAPIS = [
  apiUrl.FETCH_STORAGE_SITES,
  apiUrl.FETCH_MSP_CAPACITY_USAGE_CD_TREND_USAGE,
  apiUrl.FETCH_REPORT_MSP_CAPACITY_USAGE_CD_TREND_USAGE,
  apiUrl.FETCH_CAPACITY_USAGE_CD_TREND_USAGE
];

export const DATAGRID_IDS = {
  sources_datagrid: "dg01",
  recoverypoint_server_grid: "dg02",
  mounted_recoverypoint_gid: "dg03",
  render_folder_structure_volume: "dg04",
  cloud_account_datagrid: "dg05",
  destination_datagrid: "dg06",
  recovered_resources_datagrid: "dg07",
  instant_vm_grid: "dg08",
  virtual_standby_grid: "dg09",
  volumes_grid: "dg10",
  browser_recovery_point_server_grid: "dg11",
  datastore_grid: "dg12",
  remote_console_grid: "dg13",
  install_upgrade_recovery_point_modal_grid: "dg14",
  jumb_start_grid: "dg15",
  shadow_xafe_grid: "dg16",
  shared_folder_grid: "dg17",
  recent_jobs_inprogress_grid: "dg18",
  user_accounts_grid: "dg19",
  capcaity_usage_grid: "dg20",
  port_grid: "dg21",
  source_discovery_grid: "dg22",
  alerts_grid: "dg23",
  reports_grid: "dg24",
  account_details_grid: "dg25",
  backup_jobs_report_grid: "dg26",
  capcaity_usage_report_grid: "dg27",
  datatransfer_report_grid: "dg28",
  manage_schedules_grid: "dg29",
  policy_task_report_grid: "dg30",
  recovery_point_report_grid: "dg31",
  report_job_report_grid: "dg32",
  source_protection_report_grid: "dg33",
  jobs_grid: "dg34",
  logs_grid: "dg35",
  recovered_resources_grid: "dg36",
  add_discover_sources_modal_grid: "dg37",
  discover_sources_active_directory_grid: "dg38",
  discover_sources_browse_directory_grid: "dg39",
  hyper_v_selected_table: "dg40",
  vmware_selected_table: "dg41",
  add_sources_grid: "dg42",
  add_unc_or_nfs_path_modal_grid: "dg43",
  add_windows_source_modal_grid: "dg44",
  dns_update_settings_grid: "dg45",
  network_adapter_seetings_grid: "dg46",
  proxies_tab_grid: "dg47",
  vm_location_grid: "dg48",
  sources_policy_grid: "dg49",
  recover_sql_job_options_configuration_grid: "dg50",
  recover_sql_summary_grid: "dg51",
  render_folder_structure_grid: "dg52",
  render_oracle_recovery_points_grid: "dg76",
  customer_account_grid: "dg53",
  collect_diagostic_data_modal_grid: "dg54",
  recover_volume_summary_grid: "dg55",
  render_folder_structure_windows_volume_grid: "dg56",
  hypervisor_list_grid: "dg57",
  recover_fnf_linux_summary_grid: "dg58",
  add_linux_backup_server_source_modal_grid: "dg59",
  add_linux_source_modal_grid: "dg60",
  recovery_fnf_summary_grid: "dg61",
  policy_grid: "dg62",
  merge_now_modal_dg: "dg63",
  customer_assigned_admin: "dg64",
  cd_source_rps_grid: "dg65",
  destination_rps_grid: "dg66",
  recovery_target_grid: "dg67",
  hyperV_policy_grid: "dg68",
  vmTree_policy: "dg69",
  add_oracle_windows_source_modal_grid: "dg70",
  add_oracle_database_modal_grid: "dg71",
  oracle_hosts_grid: "dg72",
  oracle_recovery_parameter_files_grid: "dg73",
  oracle_recovery_archive_logs_grid: "dg74",
  oracle_recovery_control_files_grid: "dg75",
  setBackupPasswordGrid: "dg77",
  audit_logs: "dg78",
  sql_databases_grid: "dg79",
  nutanix_policy_grid: "dg80"
};

export const RP_MIN_SIZE_FOR_ORACLE_POLICY = 8;
export const RP_MAX_SIZE_FOR_ORACLE_POLICY = 128;
export const RP_MAX_VOLUME_SIZE_IN_GB = 131072;
export const RP_MAX_VOLUME_SIZE_IN_MB = 134217728;
export const CCDATAGRID_GET_DATA_FROM_SAVED_SEARCH = [
  SECONDARY_NAVIGATION_TYPE.SOURCES,
  SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.MACHINES,
  SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
  SECONDARY_NAVIGATION_TYPE.POLICIES,
  SECONDARY_NAVIGATION_TYPE.AUDIT_LOGS
];

export const CCDATAGRID_MIGRATED_BULK_ACTION_ID = [
  SECONDARY_NAVIGATION_TYPE.SOURCES,
  SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.MACHINES,
  SECONDARY_NAVIGATION_TYPE.AGENTLESS_VM,
  SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES,
  SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS,
  SECONDARY_NAVIGATION_TYPE.POLICIES
];

export const CCDATAGRID_ADDITIONAL_SOURCE_PARAMETERS = {
  physical_machines:
    "machine%7Cudp_windows%7Cudp_linux%7Cudp_linux_backup_server",
  unc_nfs_paths: "shared_folder",
  virtual_machines: "agentless_vm",
  sources: QUERY_STRING_FOR_SOURCE_GRID,
  sourcesWithoutOracleDB: QUERY_STRING_FOR_SOURCE_GRID_WITHOUT_ORACLE_DB
};

export const hourlyScheduleOptions = ["1", "2", "3", "4", "5", "custom"];
export const minuteScheduleOptions = ["15", "20", "40", "custom"];
export const BUCKET_RULES_URL = {
  AWS_BUCKET_RULES:
    "https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html",
  WASABI_BUCKET_RULES:
    "https://docs.wasabi.com/docs/working-with-buckets-and-objects",
  GCP_BUCKET_RULES: "https://cloud.google.com/storage/docs/buckets#naming",
  AZURE_BLOB_STORAGE_BUCKET_RULES:
    "https://learn.microsoft.com/en-us/rest/api/storageservices/naming-and-referencing-containers--blobs--and-metadata#container-names"
};
export const SHOW_FORCE_DELETE_RPS_ERROR_CODES = [
  "UDP_ERROR_12884902005",
  "00900006",
  "03000011",
  "03000010",
  "00500051",
  "03000012",
  "12884901903",
  "12884901907"
];
export const SHOW_FORCE_DELETE_SOURCE_ERROR_CODES = [
  "UDP_ERROR_12884902005",
  "UDP_ERROR_12884902000",
  "12884901903",
  "12884901956",
  "UDP_ERROR_12884902004"
];

export const DONT_SHOW_FORCE_DELETE_DS_ERROR_CODES = ["00C00022", "01500029"];

export const unmountList = [
  {
    id: "1day",
    value: "1",
    defaultLabel: "1 Day"
  },
  {
    id: "3days",
    value: "3",
    defaultLabel: "3 Days"
  },
  {
    id: "1_week",
    value: "7",
    defaultLabel: "1 week"
  },
  {
    id: "4weeks",
    value: "28",
    defaultLabel: "4 weeks"
  }
];
export const CREATE_VM_ACTION_WITH_TOOLTIP = {
  key: SourceConstants.CONTEXTUAL_ACTION.CREATE_VM,
  isDisabled: true,
  showTooltip: true,
  tooltipId: "protect.sources.available_actions.tooltip.instant_vm",
  tooltipDefaultMsg:
    "To create an instant virtual machine (IVM), you need at least one hypervisor."
};
export const CREATE_STANDBY_ACTION_WITH_TOOLTIP = {
  key: SourceConstants.CONTEXTUAL_ACTION.CREATE_STANDBY_VM,
  isDisabled: true,
  showTooltip: true,
  tooltipId: "protect.sources.available_actions.tooltip.standby_vm",
  tooltipDefaultMsg:
    "To create a standby virtual machine (VSB), you need at least one hypervisor or cloud account."
};

export const DS_PATH_FIElDS = {
  dataStoreVal: "dataStore",
  dataDestinationVal: "dataDestination",
  indexDestinationVal: "indexDestination",
  hashDestinationVal: "hashDestination"
};

export const COMPLETED_JOB_STATUS = [
  "finished",
  "canceled",
  "failed",
  "incomplete",
  "warning",
  "crash",
  "license_failed",
  "backupjob_proc_exit",
  "skipped",
  "stop",
  "missed",
  "out_of_sync"
];
