import React from "react";
import PropTypes from "prop-types";
// Note: Notice "Component", we want this to rerender on every prop change, so it
//  triggers componentDidUpdate and rerenders the chart
const Wrapper = props => {
  // eslint-disable-line react-hooks/exhaustive-deps

  const { height } = props;
  return (
    <div
      id={"Chart_0a30bfb3-9dfa-4e2c-b35f-44eda00e7878"}
      ref={props.wrapperRef}
      style={{ height }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

Wrapper.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  wrapperRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  renderChart: PropTypes.func
};

Wrapper.defaultProps = {
  className: "",
  height: "100%"
};

export default Wrapper;
