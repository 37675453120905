import { createSelector } from "reselect";

// selectors (If props is required, use (state, props))
const columnData = state => state.reducer.columnData;
const columnDataReducer = state => state.reducer.columnData;
const reportsData = state => state.analyzeReducer.reportsData;
const options = state => state.reducer.options;
const collapseSideBar = state => state.reducer.collapseSideBar;
const secondaryNavigation = state => state.reducer.secondaryNavigation;
const searchText = state => state.reducer.searchText;
const gridCurrentPage = state => state.reducer.gridCurrentPage;
const gridPageSize = state => state.reducer.gridPageSize;
const apiResponse = state => state.reducer.apiResponse;
const showReportModal = state => state.analyzeReducer.showReportModal;
const topSourcesData = state => state.analyzeReducer.topSources;
const trendSummaryData = state => state.analyzeReducer.trendSummary;
const selectedDateValue = state => state.analyzeReducer.selectedDateValue;
const selectedWidgetDropdown = state =>
  state.analyzeReducer.selectedWidgetDropdown;
export const currentReportTypeAndId = state =>
  state.analyzeReducer.currentReportTypeAndId;
const capacityUsageCHStoreSavingTrend = state =>
  state.analyzeReducer.capacityUsageCHStoreSavingTrend;
const capacityUsageCDTrendUsage = state =>
  state.analyzeReducer.capacityUsageCDTrendUsage;
const capacityUsageCHTrendUsage = state =>
  state.analyzeReducer.capacityUsageCHTrendUsage;

const transferSummaryData = state => state.analyzeReducer.transferSummaryData;
const transferTopSourceData = state =>
  state.analyzeReducer.transferTopSourceData;

const jobsAndDestinationJobsSummary = state =>
  state.analyzeReducer.jobsAndDestinationJobsSummary;
const jobsAndDestinationTopSource = state =>
  state.analyzeReducer.jobsAndDestinationTopSource;
const jobsAndDestinationForDestination = state =>
  state.analyzeReducer.jobsAndDestinationForDestination;

export const makeColumnDataState = () =>
  createSelector([columnData], columnData => columnData);
export const makeColumnDataReducerState = () =>
  createSelector([columnDataReducer], columnDataReducer => columnDataReducer);
export const makeReportsDataState = () =>
  createSelector([reportsData], reportsData => reportsData);
export const makeOptionsState = () =>
  createSelector([options], options => options);
export const makeCollapseSideBarState = () =>
  createSelector([collapseSideBar], collapseSideBar => collapseSideBar);
export const makeSecondaryNavigationState = () =>
  createSelector(
    [secondaryNavigation],
    secondaryNavigation => secondaryNavigation
  );
export const makeSearchTextState = () =>
  createSelector([searchText], searchText => searchText);
export const makeGridCurrentPageState = () =>
  createSelector([gridCurrentPage], gridCurrentPage => gridCurrentPage);
export const makeGridPageSizeState = () =>
  createSelector([gridPageSize], gridPageSize => gridPageSize);
export const makeApiResponseState = () =>
  createSelector([apiResponse], apiResponse => apiResponse);
export const makeShowReportModalState = () =>
  createSelector([showReportModal], showReportModal => showReportModal);
export const makeTopSourcesDataState = () =>
  createSelector([topSourcesData], topSourcesData => topSourcesData);
export const makeTrendSummaryDataState = () =>
  createSelector([trendSummaryData], trendSummaryData => trendSummaryData);
export const makeSelectedDateValueState = () =>
  createSelector([selectedDateValue], selectedDateValue => selectedDateValue);
export const makeSelectedWidgetDropdownState = () =>
  createSelector(
    [selectedWidgetDropdown],
    selectedWidgetDropdown => selectedWidgetDropdown
  );
export const makeCapacityUsageCHStoreSavingTrendState = () =>
  createSelector(
    [capacityUsageCHStoreSavingTrend],
    capacityUsageCHStoreSavingTrend => capacityUsageCHStoreSavingTrend
  );
export const makeCapacityUsageCDTrendUsageState = () =>
  createSelector(
    [capacityUsageCDTrendUsage],
    capacityUsageCDTrendUsage => capacityUsageCDTrendUsage
  );
export const makeCapacityUsageCHTrendUsageState = () =>
  createSelector(
    [capacityUsageCHTrendUsage],
    capacityUsageCHTrendUsage => capacityUsageCHTrendUsage
  );

export const makeTransferSummaryDataState = () =>
  createSelector(
    [transferSummaryData],
    transferSummaryData => transferSummaryData
  );
export const makeTransferTopSourceDataState = () =>
  createSelector(
    [transferTopSourceData],
    transferTopSourceData => transferTopSourceData
  );

export const makeJobsAndDestinationJobsSummaryState = () =>
  createSelector(
    [jobsAndDestinationJobsSummary],
    jobsAndDestinationJobsSummary => jobsAndDestinationJobsSummary
  );

export const makeJobsAndDestinationTopSourceState = () =>
  createSelector(
    [jobsAndDestinationTopSource],
    jobsAndDestinationTopSource => jobsAndDestinationTopSource
  );

export const makeJobsAndDestinationForDestinationState = () =>
  createSelector(
    [jobsAndDestinationForDestination],
    jobsAndDestinationForDestination => jobsAndDestinationForDestination
  );
