import _ from "lodash";
import moment from "moment";
import * as appConstants from "utils/appConstants";
// Input Data
// [
//   {
//     id: <uuid>,
//     name: "string",
//     metric: [
//       { ts: <timestamp>, date: "date string (debug only)", value: <int> },
//       ...
//     ]
//     latest: {
//       ts: <timestamp>,
//       date: "date string (debug only)"
//       value: <int>
//     }
//   },
//   ...
// ]

// returns:
// [
//   {
//     id: <uuid>,
//     name: "string",
//     metric: [
//       { ts: <timestamp>, date: "date string (debug only)", value: <int> },
//       ...
//     ]
//   },
//   ...
// ]
const fixTimezone = (filters, data) => {
  const myRange = filters && filters.range ? filters.range.toLowerCase() : "1m"; // it defaults to 1m
  const myEndTs = filters && filters.end_ts ? filters.end_ts : null;

  return _.cloneDeep(data).map(({ id, name, metric, latest }) => {
    if (
      (myRange !== "custom" && myRange !== "1d") ||
      (myRange === "custom" && moment.utc(myEndTs * 1000).local() > moment())
    ) {
      const last = metric[metric.length - 1];
      let retMetric = null;

      if (
        moment
          .utc(last.ts * 1000)
          .local()
          .format("l") === moment().format("l")
      ) {
        // same day
        retMetric = [...metric.slice(0, metric.length - 1), latest];
      } else {
        // not the same day
        retMetric = metric;
      }

      return {
        id,
        name,
        metric: retMetric
      };
    }

    return {
      id,
      name,
      metric
    };
  });
};

// const fixStcTimezone = (filters, data) => {
//   const myRange = filters && filters.range ? filters.range.toLowerCase() : "1m"; // it defaults to 1m
//   const myEndTs = filters && filters.end_ts ? filters.end_ts : null;

//   return _.cloneDeep(data).map(({ id, name, metric, latest }) => {
//     if (
//       (myRange !== "custom" && myRange !== "1d") ||
//       (myRange === "custom" && moment.utc(myEndTs * 1000).local() > moment())
//     ) {
//       const last = metric[metric.length - 1];
//       let retMetric = null;

//       if (
//         moment
//           .utc(last.ts * 1000)
//           .local()
//           .format("l") === moment().format("l")
//       ) {
//         // same day
//         retMetric = [...metric.slice(0, metric.length - 1), latest];
//       } else {
//         // not the same day
//         retMetric = metric;
//       }

//       return {
//         id,
//         name,
//         metric: retMetric
//       };
//     }

//     return {
//       id,
//       name,
//       metric
//     };
//   });
// };

const fixTimezone1 = (filters, data, resolution) => {
  const myRange = filters && filters.range ? filters.range.toLowerCase() : "1m"; // it defaults to 1m
  // const myEndTs = filters && filters.end_ts ? filters.end_ts : null;
  let last = null;
  let retMetric = null;
  return _.cloneDeep(data).map(({ id, name, metric, latest }) => {
    if (myRange !== "custom" && myRange !== "1d") {
      last = metric[metric.length - 1];
      retMetric = null;

      if (
        moment
          .utc(last.ts * 1000)
          .local()
          .format("l") === moment().format("l")
      ) {
        // same day
        retMetric = [...metric.slice(0, metric.length - 1), latest];
      } else {
        // not the same day
        retMetric = metric;
      }

      return {
        id,
        name,
        metric: retMetric
      };
    } else if (myRange === "custom" && resolution === "day") {
      last = metric[metric.length - 1];

      // same day

      if (
        moment
          .utc(last.ts * 1000)
          .local()
          .format("l") === moment().format("l")
      ) {
        // same day
        retMetric = [...metric.slice(0, metric.length - 1), latest];
      } else {
        // not the same day
        retMetric = metric;
      }
      retMetric = retMetric.reduce((obj, data) => {
        if (obj[moment(data.ts * 1000).dayOfYear()]) {
          //add the items
          obj[moment(data.ts * 1000).dayOfYear()].value += data.value;
        } else {
          obj[moment(data.ts * 1000).dayOfYear()] = data;
        }
        return obj;
      }, {});

      retMetric = Object.values(retMetric);

      return {
        id,
        name,
        metric: retMetric
      };
    }

    return {
      id,
      name,
      metric
    };
  });
};

const findMaxMetricLength = data => {
  if (data.length === 0) return 0;
  const { index } = data.reduce(
    (acc, currentObj, currentIndex) => {
      if (currentObj?.metric?.length > acc?.maxLength) {
        return {
          id: currentObj?.id,
          index: currentIndex,
          maxLength: currentObj?.metric?.length
        };
      }
      return acc;
    },
    { id: null, index: -1, maxLength: -1 }
  );
  return index;
};

export const rechartAreaTransformer = (
  filters,
  DATA,
  ccGraphId,
  resolution,
  locale
) => {
  moment.locale(locale);
  let data = fixTimezone1(filters, DATA, resolution);
  let names = [];
  let metric = [];
  let areadata = [];
  //eslint-disable-next-line default-case
  switch (ccGraphId) {
    case appConstants.CCGRAPH_IDS.MSP_CLOUD_DIRECT_USAGE:
    case appConstants.CCGRAPH_IDS.CLOUD_DIRECT_USAGE:
    case appConstants.CCGRAPH_IDS.REPORTS_CLOUD_DIRECT_USAGE:
    case appConstants.CCGRAPH_IDS.REPORTS_MSP_CLOUD_DIRECT_USAGE:
    case appConstants.CCGRAPH_IDS.REPORT_STORED_DATA:
    case appConstants.CCGRAPH_IDS.DATA_STORES_USAGE:
    case appConstants.CCGRAPH_IDS.MSP_DATA_STORES_USAGE:
    case appConstants.CCGRAPH_IDS.CUSTOMER_DATA_STORES_USAGE:
    case appConstants.CCGRAPH_IDS.CUSTOMER_METRICS_CD_USAGE:
    case appConstants.CCGRAPH_IDS.CUSTOMER_METRICS_CH_USAGE:
    case appConstants.CCGRAPH_IDS.DESTINATIONS_METRICS_CD_USAGE:
    case appConstants.CCGRAPH_IDS.DESTINATIONS_METRICS_CH_USAGE:
    case appConstants.CCGRAPH_IDS.REPORTS_CLOUD_HYBRID_USAGE:
    case appConstants.CCGRAPH_IDS.CLOUD_HYBRID_USAGE:
      data.map(ele => names.push(ele.name));
      const maxIndexLength = findMaxMetricLength(data);
      data &&
        data.length > 0 &&
        data[maxIndexLength]["metric"].map(ele =>
          metric.push(
            moment
              .utc(ele.ts * 1000)
              .local()
              .format("LLL")
          )
        );
      //initialize data in format
      //eslint-disable-next-line array-callback-return
      metric.map(ele => {
        let obj = { date: ele };
        //eslint-disable-next-line array-callback-return
        names.map(name => {
          obj = {
            ...obj,
            [name]: 0,
            tooltipHeader: moment(ele, "LLL").format("ll")
          };
        });
        //eslint-disable-next-line array-callback-return
        areadata.push(obj);
      });
      //eslint-disable-next-line array-callback-return
      data.map(ele => {
        let name = ele.name;
        let metricData = ele.metric;
        //eslint-disable-next-line array-callback-return
        metricData.map((m, index) => {
          areadata[index][name] = (m.value / 1024 ** 3).toFixed(2);
        });
      });
      return {
        data: areadata,
        names
      };
    // case appConstants.CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY:
    // case appConstants.CCGRAPH_IDS.REPORTS_POLICY_TASKS_SUMMARY:
    case appConstants.CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY:
      data.map(ele => names.push(ele.name));
      data &&
        data.length > 0 &&
        data[0]["metric"].map(ele =>
          metric.push(
            moment
              .utc(ele.ts * 1000)
              .local()
              .format("LLL")
          )
        );
      //initialize data in format
      //eslint-disable-next-line array-callback-return
      metric.map(ele => {
        let obj = { date: ele };
        //eslint-disable-next-line array-callback-return
        names.map(name => {
          obj = {
            ...obj,
            [name]: 0,
            tooltipHeader: moment(ele).format("ll")
          };
        });
        //eslint-disable-next-line array-callback-return
        areadata.push(obj);
      });
      // now fill the correct data
      //eslint-disable-next-line array-callback-return
      data.map(ele => {
        let name = ele.name;
        let metricData = ele.metric;
        //eslint-disable-next-line array-callback-return
        metricData.map((m, index) => {
          areadata[index][name] = m.value.toFixed(0);
        });
      });
      return {
        data: areadata,
        names
      };
  }
};

export const areaTransformer = (filters, data, ccGraphId, resolution) => {
  switch (ccGraphId) {
    case appConstants.CCGRAPH_IDS.REPORTS_RECOVERY_JOBS_SUMMARY:
    case appConstants.CCGRAPH_IDS.REPORTS_POLICY_TASKS_SUMMARY:
    case appConstants.CCGRAPH_IDS.REPORTS_BACKUP_JOBS_SUMMARY:
      return fixTimezone1(filters, data)
        .map(d =>
          d.metric.map(m => ({
            date: moment
              .utc(m.ts * 1000)
              .local()
              .format("LLL"),
            name: d.name,
            value: m.value.toFixed(0)
          }))
        )
        .reduce((d, acc) => [...d, ...acc], []);
    case appConstants.CCGRAPH_IDS.CLOUD_DIRECT_USAGE:
    case appConstants.CCGRAPH_IDS.MSP_CLOUD_DIRECT_USAGE:
    case appConstants.CCGRAPH_IDS.DATA_STORES_USAGE:
    case appConstants.CCGRAPH_IDS.MSP_DATA_STORES_USAGE:
    case appConstants.CCGRAPH_IDS.REPORT_STORED_DATA:
      return fixTimezone1(filters, data, resolution)
        .map(d =>
          d.metric.map(m => ({
            date: moment
              .utc(m.ts * 1000)
              .local()
              .format("LLL"),
            name: d.name,
            value: (m.value / 1024 ** 3).toFixed(2),
            [d.name]: (m.value / 1024 ** 3).toFixed(2)
            //TODO
          }))
        )
        .reduce((d, acc) => [...d, ...acc], []);
    case appConstants.CCGRAPH_IDS.CUSTOMER_DATA_STORES_USAGE: {
      const range =
        filters && filters.range ? filters.range.toLowerCase() : "1m";
      if (range === "custom" && resolution === "hour") {
        return fixTimezone1(filters, data)
          .map(d =>
            d.metric.map(m => ({
              date: moment
                .utc(m.ts * 1000)
                .local()
                .format("LLL"),
              name: d.name,
              value: (m.value / 1024 ** 3).toFixed(2)
            }))
          )
          .reduce((d, acc) => [...d, ...acc], []);
      } else {
        return fixTimezone(filters, data, resolution)
          .map(d =>
            d.metric.map(m => ({
              date: moment.utc(m.ts * 1000).format("LL"),
              name: d.name,
              value: (m.value / 1024 ** 3).toFixed(2)
            }))
          )
          .reduce((d, acc) => [...d, ...acc], []);
      }
    }
    default:
      return fixTimezone1(filters, data)
        .map(d =>
          d.metric.map(m => ({
            date: moment
              .utc(m.ts * 1000)
              .local()
              .format("LLL"),
            name: d.name,
            value: (m.value / 1024 ** 3).toFixed(2)
          }))
        )
        .reduce((d, acc) => [...d, ...acc], []);
  }
};

export const rechartLineTransformer = (
  filters,
  DATA,
  ccGraphId,
  resolution,
  locale
) => {
  moment.locale(locale);
  let data = fixTimezone1(filters, DATA, resolution);
  let names = [];
  let metric = [];
  let linedata = [];
  if (ccGraphId === appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY) {
    data =
      data && data.length
        ? data.filter(d => {
            return d.id !== "data_transferred";
          })
        : [];
  }
  data && data.length && data.map(ele => names.push(ele.name));
  data &&
    data.length > 0 &&
    //eslint-disable-next-line array-callback-return
    data[0]["metric"].map(ele =>
      metric.push(
        moment
          .utc(ele.ts * 1000)
          .local()
          .format("LLL")
      )
    );
  metric &&
    metric.length &&
    //eslint-disable-next-line array-callback-return
    metric.map(ele => {
      let obj = { date: ele };
      names &&
        names.length &&
        //eslint-disable-next-line array-callback-return
        names.map(name => {
          obj = {
            ...obj,
            [name]: 0,
            tooltipHeader: moment(ele, "LLL").format("ll")
          };
        });
      linedata.push(obj);
    });
  data &&
    data.length &&
    //eslint-disable-next-line array-callback-return
    data.map(ele => {
      let name = ele.name;
      let metricData = ele.metric;
      metricData &&
        metricData.length &&
        //eslint-disable-next-line array-callback-return
        metricData.map((m, index) => {
          linedata[index][name] = (m.value / 1024 ** 3).toFixed(2);
        });
    });
  //eslint-disable-next-line array-callback-return
  return {
    data: linedata,
    names
  };
};

export const lineTransformer = (filters, data, ccGraphId) => {
  // condition added for Data_trasnfer report without data_tranferred data
  let tempData = data;
  if (ccGraphId === appConstants.CCGRAPH_IDS.REPORTS_DATA_TRANSFER_SUMMARY) {
    tempData =
      data &&
      data.filter(d => {
        return d.id !== "data_transferred";
      });
  } else if (ccGraphId === appConstants.CCGRAPH_IDS.STC_CHANGE_RATE) {
    return {
      dataByTopic: fixTimezone({ range: "custom" }, tempData).map((d, idx) => ({
        topic: idx,
        topicName: d.name,
        dates: d.metric.map(m => ({
          // date: moment
          //   .utc(m.ts * 1000)
          //   .local()
          //   .format(),
          date: moment(m.date).format("LLL"),
          value: (m.value / 1024 ** 3).toFixed(2)
        }))
      }))
      //  .reduce((d, acc) => [...d, ...acc], [])
    };
  }
  return {
    dataByTopic: fixTimezone(filters, tempData).map((d, idx) => ({
      topic: idx,
      topicName: d.name,
      dates: d.metric.map(m => ({
        date: moment
          .utc(m.ts * 1000)
          .local()
          .format(),
        value: (m.value / 1024 ** 3).toFixed(2)
      }))
    }))
    //  .reduce((d, acc) => [...d, ...acc], [])
  };
};

export const legendTransformer = data =>
  fixTimezone(data).map((d, idx) => ({
    id: idx,
    name: d.name,
    quantity: d.metric.reduce((acc, m) => acc + m.value, 0)
  }));

export const rechartPieTransformer = (filters, data, graphType) => {
  const nameLookup = {
    [appConstants.CCGRAPH_TYPES[graphType]]: d => d.job_status
  };

  const quanityLookup = {
    [appConstants.CCGRAPH_TYPES[graphType]]: d => d.job_count
  };

  const nameAndQuantityLookup = () =>
    data.map((d, idx) => ({
      id: idx,
      name: nameLookup[graphType](d),
      quantity: quanityLookup[graphType](d)
    }));

  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.DASHBOARD_BACKUP_JOBS_SUMMARY:
    case appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY:
      return nameAndQuantityLookup();
    case appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_SUMMARY:
    case appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_SUMMARY:
    case appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_SUMMARY:
      return fixTimezone(filters, data).map(d => ({
        id: d.id,
        name: d.name,
        quantity:
          d.metric && d.metric.length > 0
            ? d.metric.reduce((sum, cur) => sum + cur.value, 0)
            : d.metric
      }));
    default:
      window.console.log("*** donutTransformer > graphType not supported");
      return [];
  }
};

export const donutTransformer = (filters, data, graphType) => {
  let usageMap = {};
  let temp = [];
  const nameLookup = {
    [appConstants.CCGRAPH_TYPES[graphType]]: d => d.job_status
  };

  const quanityLookup = {
    [appConstants.CCGRAPH_TYPES[graphType]]: d => d.job_count
  };

  const nameAndQuantityLookup = () =>
    data.map((d, idx) => ({
      id: idx,
      name: nameLookup[graphType](d),
      quantity: quanityLookup[graphType](d)
    }));

  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.DASHBOARD_BACKUP_JOBS_SUMMARY:
    case appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY:
      return nameAndQuantityLookup();
    case appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_SUMMARY:
    case appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_SUMMARY:
    case appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_SUMMARY:
      //eslint-disable-next-line array-callback-return
      return fixTimezone(filters, data).map(d => ({
        id: d.id,
        name: d.name,
        quantity:
          d.metric && d.metric.length > 0
            ? d.metric.reduce((sum, cur) => sum + cur.value, 0)
            : d.metric
      }));
    case appConstants.CCGRAPH_TYPES.STC_USAGE:
      //eslint-disable-next-line array-callback-return
      data.map(d => {
        usageMap["free"] = {
          id: "free",
          name: "free",
          quantity:
            _.get(usageMap, "free.quantity", 0) +
            parseFloat((d["free"] / 1099511627776).toFixed(2))
        };
        usageMap["used"] = {
          id: "used",
          name: "used",
          quantity:
            _.get(usageMap, "used.quantity", 0) +
            parseFloat(((d["size"] - d["free"]) / 1099511627776).toFixed(2))
        };
      });
      Object.keys(usageMap).map(key =>
        temp.push({
          ...usageMap[key]
        })
      );
      return temp;
    default:
      window.console.log("*** donutTransformer > graphType not supported");
      return [];
  }
};

export const barGraphTransformer = (data, graphType) => {
  //perform data transformation here, if required
  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.DASHBOARD_NUMBER_OF_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.REPORTS_NUMBER_OF_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.DASHBOARD_LATEST_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.REPORTS_LATEST_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.DASHBOARD_OLDEST_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.REPORTS_OLDEST_RECOVERY_POINTS:
      return data;
    default:
      return data;
  }
};

export const RCbarGraphTransformer = (data, graphType, locale) => {
  //perform data transformation here
  let d = data;
  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.DASHBOARD_NUMBER_OF_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.REPORTS_NUMBER_OF_RECOVERY_POINTS:
      return (
        d.length &&
        d.map(ele => {
          let date;
          if (locale === "ja") {
            date =
              moment(new Date(ele.year, ele.month - 1))
                ?.format("LL")
                ?.split("月")?.[0] + "月";
          } else {
            date = moment(new Date(ele.year, ele.month - 1))?.format(
              "MMMM YYYY"
            );
          }
          return {
            name: `common.short_month.${ele.month_name}`,
            toolTipTitle: date,
            label: "Sources",
            value: ele.recovery_point_count
          };
        })
      );
    case appConstants.CCGRAPH_TYPES.DASHBOARD_LATEST_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.REPORTS_LATEST_RECOVERY_POINTS:
      return (
        d.length &&
        d.reduce((acc, ele) => {
          if (ele.date_range !== "no_backups") {
            acc.push({
              toolTipTitle: `dashboard.latestrps.${ele.date_range}`,
              name: `dashboard.latestrps.short.${ele.date_range}`,
              label: "Sources",
              value: ele.source_count
            });
          }
          return acc;
        }, [])
      );
    case appConstants.CCGRAPH_TYPES.DASHBOARD_OLDEST_RECOVERY_POINTS:
    case appConstants.CCGRAPH_TYPES.REPORTS_OLDEST_RECOVERY_POINTS:
      return (
        d.length &&
        d.reduce((acc, ele) => {
          if (ele.date_range !== "no_backups") {
            acc.push({
              toolTipTitle: `dashboard.oldestrps.${ele.date_range}`,
              name: `dashboard.oldestrps.short.${ele.date_range}`,
              label: "Sources",
              value: ele.source_count
            });
          }
          return acc;
        }, [])
      );
    case appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES:
      let emptyGraphFlag = true;
      //eslint-disable-next-line eqeqeq
      if (d == undefined || d == null || Object.keys(d).length === 0) return [];
      const toolTipNames = {
        num_offline: "num_offline",
        num_without_backups: "num_without_backups",
        num_without_policy: "num_without_policy",
        num_backups_failed: "failed_backup"
      };
      if (
        d["num_offline"] > 0 ||
        d["num_without_backups"] > 0 ||
        d["num_without_policy"] > 0 ||
        d["num_backups_failed"] > 0
      )
        emptyGraphFlag = false;
      if (emptyGraphFlag) return [];
      else
        return [
          {
            id: "num_offline",
            name: toolTipNames["num_offline"],
            value: Number(d["num_offline"])
          },
          {
            id: "num_without_backups",
            name: toolTipNames["num_without_backups"],
            value: Number(d["num_without_backups"])
          },
          {
            id: "num_without_policy",
            name: toolTipNames["num_without_policy"],
            value: Number(d["num_without_policy"])
          },
          {
            id: "num_backups_failed",
            name: toolTipNames["num_backups_failed"],
            value: Number(d["num_backups_failed"])
          }
        ];
    case appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS:
      return (
        d.length &&
        d.map(ele => ({
          ...ele,
          value: Number((ele.value / 1024 ** 3).toFixed(0)),
          toolTipContent: ele.stack,
          tooltipHeader: ele.name
        }))
      );

    default:
      return data;
  }
};

export const dsBarGraphTransformer = (data, graphType) => {
  //perform data transformation here, if required
  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.REPORTS_SOURCE_PROTECTION_SUMMARY_SOURCES:
      let emptyGraphFlag = true;
      const toolTipNames = {
        num_offline: "tool_tip_name.num_offline",
        num_without_backups: "tool_tip_name.num_without_backups",
        num_without_policy: "tool_tip_name.num_without_policy",
        num_backups_failed: "tool_tip_name.failed_backup"
      };
      const res =
        data &&
        Object.keys(data)
          .filter(name =>
            [
              "num_offline",
              "num_without_backups",
              "num_without_policy",
              "num_backups_failed"
            ].includes(name)
          )
          .map(keyName => {
            if (data[keyName] > 0) emptyGraphFlag = false;
            return {
              id: keyName,
              value: data[keyName].toFixed(0),
              name:
                keyName === "num_backups_failed" ? "failed_backup" : keyName,
              toolTipName: toolTipNames[keyName]
            };
          });
      if (emptyGraphFlag) return [];
      else return res;
    default:
      return data;
  }
};

export const stackedBarTransformer = (data, graphType, filters = null) => {
  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS:
      return data.map(d => ({ ...d, value: (d.value / 1024 ** 3).toFixed(0) }));
    case appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_CUSTOMERS:
      return data.map(d => ({ ...d, value: (d.value / 1024 ** 3).toFixed(0) }));
    case appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_TOP_SOURCES:
    case appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_SOURCES:
    case appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_TOP_SOURCES:
    case appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_SOURCES:
      //TODO use constants
      if (filters && filters.filter_type === "job_duration") {
        return data.length > 0
          ? data.map(d => ({ ...d, value: (d.value / 60).toFixed(0) }))
          : data;
      } else if (filters && filters.filter_type === "data_transferred") {
        return data.length > 0
          ? data.map(d => ({
              ...d,
              value: (d.value / 1024 ** 3).toFixed(0)
            }))
          : data;
      } else return data;
    default:
      return data;
  }
};

export const RCStackedBarTransformer = (data, graphType, filters = null) => {
  switch (graphType) {
    case appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_CUSTOMERS:
      return data.map(d => ({ ...d, value: (d.value / 1024 ** 3).toFixed(0) }));
    case appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_CUSTOMERS:
      return data.map(d => ({ ...d, value: (d.value / 1024 ** 3).toFixed(0) }));
    case appConstants.CCGRAPH_TYPES.DASHBOARD_TOP_SOURCES:
    case appConstants.CCGRAPH_TYPES.REPORTS_BACKUP_JOBS_TOP_SOURCES:
      if (
        filters &&
        filters.filter_type === "job_duration" &&
        data &&
        data.length > 0
      ) {
        data =
          data.length > 0
            ? data.map(d => ({ ...d, value: (d.value / 60).toFixed(0) }))
            : data;
      } else if (
        filters &&
        filters.filter_type === "data_transferred" &&
        data &&
        data.length > 0
      ) {
        data =
          data.length > 0
            ? //eslint-disable-next-line array-callback-return
              data.map(d => ({ ...d, value: (d.value / 1024 ** 3).toFixed(2) }))
            : data;
      }
      if (data && data.length > 0) {
        let names = new Set();
        //eslint-disable-next-line array-callback-return
        data.map(ele => names.add(ele.name)); //find all the sources
        names = Array.from(names);
        let tempstackData = {}; // acts as a dictionary
        //eslint-disable-next-line array-callback-return
        names.map(name => {
          tempstackData = {
            ...tempstackData,
            [name]: {} //initialize empty object for each source
          };
        });
        //eslint-disable-next-line array-callback-return
        data.map(ele => {
          tempstackData[ele.name] = {
            //initialize the values in dictionary as per the given stack
            ...tempstackData[ele.name],
            [ele.stack]: ele.value
          };
        });
        // form the final stackData
        let stacks = ["failed", "canceled", "finished"];
        let stackData = [];
        //eslint-disable-next-line array-callback-return
        names.map(name => {
          stackData.push({
            name,
            ...tempstackData[name]
          });
        });
        //modify the data object
        data = {
          data: stackData,
          stacks
        };
      }
      return data;
    case appConstants.CCGRAPH_TYPES.REPORTS_RECOVERY_JOBS_TOP_SOURCES:
    case appConstants.CCGRAPH_TYPES.REPORTS_POLICY_TASKS_TOP_SOURCES:
      //TODO use constants
      if (filters && filters.filter_type === "job_duration") {
        return data.length > 0
          ? data.map(d => ({ ...d, value: (d.value / 60).toFixed(0) }))
          : data;
      } else if (filters && filters.filter_type === "data_transferred") {
        return data.length > 0
          ? data.map(d => ({
              ...d,
              value: (d.value / 1024 ** 3).toFixed(0)
            }))
          : data;
      } else return data;
    default:
      return data;
  }
};
