import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Field } from "redux-form";
import SpogModal from "components/common/modal-mui";
import {
  required,
  email
} from "components/common/redux-form-components/validation";
import Button from "components/common/button";
import FormattedMessage from "components/common/formatted-message";
import {
  renderInputFieldMUI,
  renderSelectFieldMUI
} from "components/common/redux-form-components";
import OrgSearch from "components/common/renderFields/components/OrgSearch";

const NewUserModal = props => {
  const {
    setOrganizationsSearchString,
    handleSubmit,
    pristine,
    invalid,
    history,
    intl
  } = props;

  useEffect(() => {
    setOrganizationsSearchString(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = () => {
    history.push("/csr/user-search");
  };

  const ROLES = [
    {
      value: "csr_admin",
      key: intl.formatMessage({
        id: "csr.csr_admin",
        defaultMessage: "CSR Admin"
      })
    },
    {
      value: "csr_read_only",
      key: intl.formatMessage({
        id: "csr.csr_read_only",
        defaultMessage: "CSR Read Only"
      })
    },
    {
      value: "direct_monitor",
      key: intl.formatMessage({
        id: "csr.direct_monitor",
        defaultMessage: "Monitor"
      })
    },
    {
      value: "msp_monitor",
      key: intl.formatMessage({
        id: "csr.msp_monitor",
        defaultMessage: "MSP Monitor"
      })
    },
    {
      value: "msp_admin",
      key: intl.formatMessage({
        id: "csr.msp_admin",
        defaultMessage: "MSP Admin"
      })
    },
    {
      value: "msp_account_admin",
      key: intl.formatMessage({
        id: "csr.msp_account_admin",
        defaultMessage: "MSP Account Admin"
      })
    },
    {
      value: "msp_account_recovery_admin",
      key: intl.formatMessage({
        id: "csr.msp_account_recovery_admin",
        defaultMessage: "MSP Account Recovery"
      })
    },
    {
      value: "msp_recovery_admin",
      key: intl.formatMessage({
        id: "csr.msp_recovery_admin",
        defaultMessage: "MSP Recovery Admin"
      })
    },
    {
      value: "direct_admin",
      key: intl.formatMessage({
        id: "csr.direct_admin",
        defaultMessage: "Direct Admin"
      })
    },
    {
      value: "direct_recovery_admin",
      key: intl.formatMessage({
        id: "csr.direct_recovery_admin",
        defaultMessage: "Direct Recovery Admin"
      })
    },
    {
      value: "msp_account_monitor",
      key: intl.formatMessage({
        id: "csr.msp_account_monitor",
        defaultMessage: "MSP Account Monitor"
      })
    }
  ];

  return (
    <div>
      <SpogModal
        testId="FDCUkK"
        className="csr-new-user-modal csr-modal"
        size="sm"
        show
        handleCloseModalEvent={closeModal}
        title={intl.formatMessage({
          id: "csr.new_user",
          defaultMessage: "New User"
        })}
        customDialogContentStyle={{
          textAlign: "center"
        }}
        footer={
          <div
            // className="footer csr-footer"
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              color="secondary"
              content={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
              onClick={closeModal}
              style={{ margin: "5px" }}
              variant="contained"
              testId="CEjoQf"
            />
            <Button
              color="primary"
              content={<FormattedMessage id="save" defaultMessage="Save" />}
              disabled={pristine || invalid}
              onClick={handleSubmit}
              style={{ margin: "5px" }}
              type="submit"
              variant="contained"
              testId="Amn4lY"
            />
          </div>
        }
      >
        <form
          className="new-user-modal__form-container"
          // onSubmit={handleSubmit}
        >
          <div
            className="csr-modal-body"
            style={{
              paddingTop: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Field
              id="firstName"
              name="firstName"
              label={intl.formatMessage({
                id: "First Name",
                defaultMessage: "First Name"
              })}
              component={renderInputFieldMUI}
              hideFloatingLabel={true}
              customStyle={{ width: 250 }}
              type="text"
              placeholder={intl.formatMessage({
                id: "First Name",
                defaultMessage: "First Name"
              })}
              validate={required}
            />

            <Field
              id="lastName"
              name="lastName"
              label={intl.formatMessage({
                id: "Last Name",
                defaultMessage: "Last Name"
              })}
              component={renderInputFieldMUI}
              hideFloatingLabel={true}
              customStyle={{ width: 250 }}
              type="text"
              placeholder={intl.formatMessage({
                id: "Last Name",
                defaultMessage: "Last Name"
              })}
              validate={required}
            />

            <Field
              id="email"
              name="email"
              label={intl.formatMessage({
                id: "Email",
                defaultMessage: "Email"
              })}
              component={renderInputFieldMUI}
              hideFloatingLabel={true}
              customStyle={{ width: 250 }}
              validate={[required, email]}
              type="text"
              placeholder="email@email.com"
            />

            <Field
              id="phoneNumber"
              name="phoneNumber"
              label={intl.formatMessage({
                id: "Phone Number",
                defaultMessage: "Phone Number"
              })}
              component={renderInputFieldMUI}
              hideFloatingLabel={true}
              customStyle={{ width: 250 }}
              type="text"
              placeholder="+1-555-555-5555"
            />

            <Field
              id="role"
              name="roleId"
              label={intl.formatMessage({
                id: "Role",
                defaultMessage: "Role"
              })}
              options={ROLES.map(item => ({
                value: item.key,
                key: item.value
              }))}
              component={renderSelectFieldMUI}
              customStyle={{ width: 250 }}
              validate={[required]}
            />

            <label
              htmlFor="org"
              className="new-user-modal__org-search grid-width mt-3"
            >
              <Field id="org" name="organizationId" component={OrgSearch} />
            </label>
          </div>
        </form>
      </SpogModal>
    </div>
  );
};

/**
 * Maps state to the props of the component in React Redux connection
 * @param state
 */
const mapStateToProps = state => ({
  apiResponse: state.reducer.apiResponse
});

NewUserModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setOrganizationsSearchString: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  apiResponse: PropTypes.shape({})
};

export default connect(mapStateToProps, null)(injectIntl(NewUserModal));
