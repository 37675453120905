import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";

const ProgressBarComponent = ({ now, barColor, progressColor }) => {
  return (
    <LinearProgress
      sx={{
        height: 6,
        borderRadius: 5,
        width: "160px",
        "&.MuiLinearProgress-colorPrimary": {
          backgroundColor: `${barColor}`
        },
        "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: `${progressColor}`
        }
      }}
      variant="determinate"
      value={now}
    />
  );
};

/**
 * Default props value for ProgressBar Component
 * @type {{actions: boolean}}
 */
ProgressBarComponent.defaultProps = {
  barColor: "#cecece",
  progressColor: "#2196F3",
  now: 0
};

ProgressBarComponent.propTypes = {
  now: PropTypes.number,
  barColor: PropTypes.string,
  progressColor: PropTypes.string
};

export default ProgressBarComponent;
