import * as C from "state/constants";

const initialState = {
  isLoadingCloudHybridStores: {},
  addDataStore: {
    errors: []
  },
  cloudHybridStores: {}
};

const cloudHybridStores = (state = initialState, action) => {
  const { rpsServerId = null } = action.payload || {};

  switch (action.type) {
    case C.CREATE_DATA_STORE_REQUEST:
      return Object.assign({}, state, {
        isLoadingCloudHybridStores: {
          ...state.isLoadingCloudHybridStores,
          [action.payload.rps_server_id]: true
        },
        addDataStore: {
          errors: []
        }
      });
    case C.CREATE_DATA_STORE_FAILURE:
      return Object.assign({}, state, {
        isLoadingCloudHybridStores: {
          ...state.isLoadingCloudHybridStores,
          [rpsServerId]: false
        },
        addDataStore: {
          errors:
            (action &&
              action.payload &&
              action.payload.response &&
              action.payload.response.errors.map(err => err.message)) ||
            ""
        }
      });
    case C.CREATE_DATA_STORE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingCloudHybridStores: {
          ...state.isLoadingCloudHybridStores,
          [rpsServerId]: false
        },
        addDataStore: {
          error: []
        }
      });
    case C.FETCH_CLOUD_HYBRID_STORES_REQUEST:
      return Object.assign({}, state, {
        isLoadingCloudHybridStores: {
          ...state.isLoadingCloudHybridStores,
          [rpsServerId]: true
        }
      });
    case C.FETCH_CLOUD_HYBRID_STORES_SUCCESS:
      return Object.assign({}, state, {
        isLoadingCloudHybridStores: {
          ...state.isLoadingCloudHybridStores,
          [rpsServerId]: false
        },
        cloudHybridStores: {
          ...state.cloudHybridStores,
          [rpsServerId]: action.payload.data
        }
      });
    case C.FETCH_CLOUD_HYBRID_STORES_FAILURE:
      return Object.assign({}, state, {
        isLoadingCloudHybridStores: {
          ...state.isLoadingCloudHybridStores,
          [rpsServerId]: false
        },
        cloudHybridStores: {
          ...state.cloudHybridStores,
          [rpsServerId]: []
        }
      });
    default:
      return state;
  }
};

export default cloudHybridStores;
