import React from "react";
import FormattedMessage from "components/common/formatted-message";
export const GridLoader = () => {
  return (
    <tr className="loading">
      <td className="loading-bar" />
      <td className="loading-bar" />
      <td className="loading-bar" />
      <td className="loading-bar" />
    </tr>
  );
};

export const GridNoDataText = props => {
  return (
    <tr className="grid-nodata-text">
      <td>
        <FormattedMessage
          id={props.noDataText}
          defaultMessage={props.noDataText}
        />
      </td>
    </tr>
  );
};
