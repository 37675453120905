import { createSelector } from "reselect";
import moment from "moment";

export const DS_AVAILABLE_ACTIONS = ["modify", "delete"];

export const sitesSelector = state => state.protectReducer.sites;
export const registeredSitesSelector = state =>
  state.protectReducer && state.protectReducer.registeredSites;

/**
 * Key value pairs from sites to be used in dropdowns
 */
export const sitesKeyValueSelector = createSelector(sitesSelector, sites =>
  sites.map(x => ({
    key: x.site_id,
    value: x.site_name,
    product_type: x.product_type
  }))
);

export const registeredSitesKeyValueSelector = createSelector(
  registeredSitesSelector,
  sites =>
    sites &&
    sites.map(x => ({
      key: x.site_id,
      value: x.site_name,
      product_type: x.product_type,
      site_type: x.site_type
    }))
);

/**
 * for Sites Page
 */
export const getSitesList = state =>
  state.sites && state.sites.sitesResponse
    ? state.sites.sitesResponse.data.map(site => ({
        ...site,
        last_contact_time: site.last_contact_time
          ? moment(new Date(site.last_contact_time * 1000)).format("L LTS")
          : "",
        available_actions: DS_AVAILABLE_ACTIONS
      }))
    : [];

export const getSitesPagination = state =>
  state.sites.sitesResponse
    ? state.sites.sitesResponse.pagination
    : { current_page: 1, page_size: 0, total_size: 0 };
