import React from "react";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";

const ProgressBarComponent = ({ now, label, bsStyle }) => {
  return <ProgressBar now={now} label={label} variant={bsStyle} />;
};

/**
 * Default props value for ProgressBar Component
 * @type {{actions: boolean}}
 */
ProgressBarComponent.defaultProps = {
  bsStyle: "",
  now: 0,
  label: ""
};

ProgressBarComponent.propTypes = {
  now: PropTypes.number,
  bsStyle: PropTypes.string,
  label: PropTypes.string
};

export default ProgressBarComponent;
