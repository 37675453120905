import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Mapping for severity type attribute to display appropriate icon
 */
export const SeverityAttributes = {
  information: { label: "information", icon: "information" },
  error: { label: "error", icon: "error" },
  success: { label: "common.log.success", icon: "success" },
  warning: { label: "warning", icon: "warning" },
  "error&warning": { label: "error&warning", icon: "error" }
};

/**
 * Enumrates list of log attributes known to the UI.
 */
export const LogAttributes = {
  LOG_PROP_DATE_TIME: "log_ts",
  LOG_PROP_SEVERITY: "log_severity_type",
  LOG_PROP_SOURCE: "source_name",
  LOG_PROP_GENERATED_FROM: "generated_from",
  LOG_PROP_JOB_TYPE: "job_type",
  LOG_PROP_MESSAGE_ID: "help_message_id",
  LOG_PROP_MESSAGE: "message",
  LOG_PROP_JOB_NAME: "job_name",
  LOG_PROP_ORGANIZATION: "organization",
  LOG_DESTINATION: "destination_name",
  LOG_SITE: "site_name"
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_VIEW_DETAILS : On click of cell the details will show
 * @type {{CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS: LogAttributes.LOG_PROP_SOURCE,
  CLICKABLE_CELL_TO_VIEW_JOB_DETAILS: LogAttributes.LOG_PROP_JOB_NAME,
  CLICKABLE_CELL_TO_VIEW_ORGANIZATION_DETAILS:
    LogAttributes.LOG_PROP_ORGANIZATION
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier => "common.log.".concat(identifier);

/**
 * Column definition for data-grid
 */
export const COLUMN_DEFS = [
  {
    accessor: LogAttributes.LOG_PROP_DATE_TIME,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_DATE_TIME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: LogAttributes.LOG_PROP_SEVERITY,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_SEVERITY),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ICON_CELL_MUI_WITHOUT_OVERLAY
    },
    settings: {}
  },
  {
    accessor: LogAttributes.LOG_PROP_SOURCE,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_SOURCE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: LogAttributes.LOG_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      LogAttributes.LOG_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: LogAttributes.LOG_PROP_GENERATED_FROM,
    displayIdentifier: getDisplayIdentifier(
      LogAttributes.LOG_PROP_GENERATED_FROM
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: LogAttributes.LOG_PROP_JOB_TYPE,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_JOB_TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: LogAttributes.LOG_SITE,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_SITE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: LogAttributes.LOG_DESTINATION,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_DESTINATION),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.RENDER_DESTINATION_OVERLAY_CELL_MUI
    },
    settings: { width: 150 }
  },
  {
    accessor: LogAttributes.LOG_PROP_MESSAGE_ID,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_MESSAGE_ID),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.EXTERNAL_LINK_CELL_MUI },
    settings: {}
  },
  {
    accessor: LogAttributes.LOG_PROP_MESSAGE,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_MESSAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.WRAPPED_CELL_WITH_HYPERLINK_MUI
    },
    settings: { width: 550 }
  },
  {
    accessor: LogAttributes.LOG_PROP_JOB_NAME,
    displayIdentifier: getDisplayIdentifier(LogAttributes.LOG_PROP_JOB_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 450 }
  }
];
