import * as createStandbyVMConstants from "state/constants/createStandbyVM";

export const changeCreateStandbyVMTab = data => ({
  type: createStandbyVMConstants.CHANGE_CREATE_STANDBY_VM_TAB,
  data
});

export const storeHypervInfo = data => ({
  type: createStandbyVMConstants.GET_HYPERVISORS_INFO,
  data
});
export const storeEsxInfo = data => ({
  type: createStandbyVMConstants.GET_ESX_INFO,
  data
});
export const storeAddnwInfo = data => ({
  type: createStandbyVMConstants.GET_ADD_NW_INFO,
  data
});
export const VMlocationDetails = data => ({
  type: createStandbyVMConstants.GET_VMLOCATION_INFO,
  data
});
export const createHyperVForIVMSuccess = data => ({
  type: createStandbyVMConstants.CREATE_HYPERV_FOR_VM_SUCCESS,
  data
});
export const createHyperVForIVM = (data, formName) => ({
  type: createStandbyVMConstants.CREATE_HYPERV_FOR_VM,
  data,
  formName
});
export const getHypervisorsListByType = data => ({
  type: createStandbyVMConstants.GET_HYPERVISORS_LIST_BY_TYPE,
  data
});

export const vsbVmwareMonitorConnect = (data, formName, namespace) => ({
  type: createStandbyVMConstants.VSB_CONNECT_VMWARE_MONITOR,
  data,
  formName,
  namespace
});

export const createStandbyVmwareMonitorConnectSuccess = data => ({
  type: createStandbyVMConstants.VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS,
  data
});
export const selectedVmHyperv = data => ({
  type: createStandbyVMConstants.GET_VW_HYPER_INFO,
  data
});

export const storeValidateConfigdata = data => ({
  type: createStandbyVMConstants.STORE_VALIDATE_CONFIG_DATA,
  data
});
export const storeCreateStandVMbyFinalPayload = data => ({
  type: createStandbyVMConstants.STORE_CREATE_STANDBY_VM_FINAL_PAYLOAD,
  data
});
export const getVmwareConfigurations = data => ({
  type: createStandbyVMConstants.GET_VMWARE_CONFIGURATION_VSB,
  data
});
export const getVmwareConfigurationsSuccess = data => ({
  type: createStandbyVMConstants.GET_VMWARE_CONFIGURATION_SUCCESS,
  data
});

export const getDestinationsBySourceIdforVsb = data => ({
  type: createStandbyVMConstants.GET_DESTINATIONS_BY_SOURCE_ID_FOR_VSB,
  data
});

export const onGetDestinationsBySourceIdforVsb = data => ({
  type: createStandbyVMConstants.DESTINATIONS_DATA_GET_SUCCESS_FOR_VSB,
  data
});

export const storeSelectedSourceForVsb = data => ({
  type: createStandbyVMConstants.STORE_SOURCE_DATA_FOR_VSB,
  data
});

export const storeSelectedDestinationDataVSB = data => ({
  type: createStandbyVMConstants.SAVE_RECOVERY_SELECTED_DESTINATION_DATA_VSB,
  data
});

export const getRecoveryPointsByDestinationPathForVsb = data => ({
  type:
    createStandbyVMConstants.GET_RECOVERY_POINTS_BY_DESTINATION_PATH_FOR_VSB,
  data
});

export const getRecoveryPointResponseForVsb = data => ({
  type: createStandbyVMConstants.GET_RECOVERY_POINT_RESPONSE_FOR_VSB,
  data
});

export const onGetRecoveryPointsByDestinationPathForVsb = data => ({
  type:
    createStandbyVMConstants.RECOVERY_POINTS_BY_DESTINATION_PATH_GET_FOR_VSB_SUCCESS,
  data
});

export const storeNewVSBRecoveryPoint = data => ({
  type: createStandbyVMConstants.STORE_NEW_VSB_RP,
  data
});

export const saveRPPasswordForVsb = data => ({
  type: createStandbyVMConstants.SAVE_RP_PASSWORD_FOR_VSB,
  data
});

export const storeSelectedRecoveryPointForVsb = data => ({
  type: createStandbyVMConstants.STORE_SELECTED_RECOVERY_POINT_FOR_VSB,
  data
});

export const validateRecoveryPointForVSB = data => ({
  type: createStandbyVMConstants.VALIDATE_RP_VSB,
  data
});

export const storeValidateConfigdataForVsb = data => ({
  type: createStandbyVMConstants.STORE_VALIDATE_CONFIG_DATA_FOR_VSB,
  data
});

export const cleanUpCreateStandbyVMState = () => ({
  type: createStandbyVMConstants.CLEAN_UP_VSB_STATE
});

export const fetchProxiesVSB = (
  data,
  srcId = "",
  srcType = "",
  destinationType = ""
) => ({
  type: createStandbyVMConstants.FETCH_PROXIES_VSB,
  data,
  srcId,
  srcType,
  destinationType
});
export const submitVM = data => ({
  type: createStandbyVMConstants.SUBMIT_VM,
  data
});

export const fetchProxiesVSBSuccess = data => ({
  type: createStandbyVMConstants.FETCH_PROXIES_VSB_SUCCESS,
  data
});
export const submitVMSuccess = data => ({
  type: createStandbyVMConstants.SUBMIT_VM_SUCCESS,
  data
});

export const storeClickedSourceDetails = data => ({
  type: createStandbyVMConstants.STORE_CLICKED_SOURCE_DETAILS,
  data
});

export const setSelectedProxy = data => ({
  type: createStandbyVMConstants.SET_SELECTED_PROXY_FOR_VSB,
  data
});

export const validateNfsForVSB = data => ({
  type: createStandbyVMConstants.VSB_VALIDATE_NFS,
  data
});

export const validateHyperVForVSB = data => ({
  type: createStandbyVMConstants.VSB_VALIDATE_HYPERV,
  data
});

export const validateVSBSuccess = data => ({
  type: createStandbyVMConstants.VALIDATE_VSB_SUCCESS,
  data
});

export const createStandbyHyperVConnect = (data, formName, namespace) => ({
  type: createStandbyVMConstants.VSB_CONNECT_HYPERV,
  data,
  formName,
  namespace
});

export const clearRecoveryPointsDataForVsb = () => ({
  type: createStandbyVMConstants.CLEAR_RECOVERY_POINTS_DATA_FOR_VSB
});

export const createStandbyValidateCloudProxy = data => ({
  type: createStandbyVMConstants.EC2_VALIDATE_CLOUD_PROXY,
  data
});
export const ec2CloudProxyValidationResponse = data => ({
  type: createStandbyVMConstants.EC2_VALIDATE_CLOUD_PROXY_RESPONSE,
  data
});

export const storeSelectedHyperV = data => ({
  type: createStandbyVMConstants.HYPERV_SELECTED_ROW,
  data
});

export const connectModalStatus = data => ({
  type: createStandbyVMConstants.CONNECT_MODAL_STATUS,
  data
});

export const createNutanixForIVM = (data, formName) => ({
  type: createStandbyVMConstants.CREATE_NUTANIX_FOR_VSB,
  data,
  formName
});

export const createNutanixForIVMSuccess = data => ({
  type: createStandbyVMConstants.CREATE_NUTANIX_FOR_IVM_SUCCESS,
  data
});
export const setHypervFolderInitialPath = data => ({
  type: createStandbyVMConstants.SET_HYPERV_FOLDER_INITIAL_PATH,
  data
});
export const getCreateStandbyVMFormIsValidInfo = data => ({
  type: createStandbyVMConstants.STORE_FORM_INVALID_INFO,
  data
});
export const browseSharedFoldersForHyperv = (data, namespace, formName) => ({
  type: createStandbyVMConstants.HYPERV_SHARED_FOLDERS_BROWSE,
  data,
  namespace,
  formName
});

export const getCloudConfigurations = data => ({
  type: createStandbyVMConstants.FETCH_CLOUD_CONFIGURATIONS_VSB,
  data
});

export const storeCloudConfigurations = data => ({
  type: createStandbyVMConstants.STORE_CLOUD_CONFIGURATIONS,
  data
});

export const getCloudSubnet = data => ({
  type: createStandbyVMConstants.FETCH_CLOUD_SUBNET_AZURE,
  data
});

export const storeAzureCloudSubnet = data => ({
  type: createStandbyVMConstants.STORE_AZORE_CLOUD_SUBNET_VSB,
  data
});

export const getAllVSBHypervisorsForCreateStandbyVM = data => ({
  type: createStandbyVMConstants.GET_ALL_VSB_HYPERVISORS_FOR_CREATE_STANDBY_VM,
  data
});
export const getVSBHypervisorsSuccess = data => ({
  type:
    createStandbyVMConstants.GET_VSB_SUCCESS_HYPERVISORS_FOR_CREATE_STANDBY_VM,
  data
});
export const getVSBCloudAccountListByTypeSuccess = data => ({
  type:
    createStandbyVMConstants.GET_LOAD_VSB_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS,
  data
});
export const getVSBHypervisorsFailed = () => ({
  type: createStandbyVMConstants.GET_VSB_FAILED_HYPERVISORS_INFO
});

export const getRegionsForVSBCloudAccountSuccess = data => ({
  type: createStandbyVMConstants.GET_REGIONS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS,
  data
});
export const getRegionsForCloudAccountVSB = data => ({
  type: createStandbyVMConstants.GET_REGIONS_FOR_CLOUD_ACCOUNT_VSB,
  data
});
export const clearCloudAccRegions = () => ({
  type: createStandbyVMConstants.CLEAR_CLOUD_ACCOUNTS_REGIONS_VSB
});
export const getResourceGroupForCloudAccountVSB = data => ({
  type: createStandbyVMConstants.GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_VSB,
  data
});

export const getResourceGroupForCloudAccountSuccessVSB = data => ({
  type:
    createStandbyVMConstants.GET_RESOURCE_GROUPS_FOR_VSB_CLOUD_ACCOUNT_SUCCESS,
  data
});

export const VsbFetchMonitors = siteId => ({
  type: createStandbyVMConstants.LOAD_VMWARE_NUTNAIX_MONITOR,
  siteId
});

export const loadVsbMonitorSuccess = data => ({
  type: createStandbyVMConstants.LOAD_VSB_MONITOR_SUCCESS,
  data
});
export const getNutanixConfigurations = data => ({
  type: createStandbyVMConstants.GET_VSB_NUTANIX_CONFIGURATION,
  data
});
export const getNutanixConfigurationsSuccess = data => ({
  type: createStandbyVMConstants.GET_VSB_NUTANIX_CONFIGURATION_SUCCESS,
  data
});

export const createVmwareForVSB = (data, formName) => ({
  type: createStandbyVMConstants.CREATE_VMWARE_FOR_VSB,
  data,
  formName
});

export const createVmwareForVSBSuccess = data => ({
  type: createStandbyVMConstants.CREATE_VMWARE_FOR_VSB_SUCCESS,
  data
});
export const storeSelectedVmwareTreeData = data => ({
  type: createStandbyVMConstants.GET_SELECTED_VMWARE_TREE_INFO,
  data
});

export const getComputeRegionsForGCPCloudAccountForVsb = data => ({
  type:
    createStandbyVMConstants.GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB,
  data
});

export const getComputeRegionsForGCPCloudAccountSuccessForVsb = data => ({
  type:
    createStandbyVMConstants.GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB,
  data
});

export const getComputeZonesForGCPCloudAccountForVsb = data => ({
  type:
    createStandbyVMConstants.GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_FOR_VSB,
  data
});

export const getComputeZonesForGCPCloudAccountSuccessForVsb = data => ({
  type:
    createStandbyVMConstants.GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_SUCCESS_FOR_VSB,
  data
});

export const getGCPSecurityGroupsForSelectedNetworkForVsb = data => ({
  type:
    createStandbyVMConstants.GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK_FOR_VSB,
  data
});

export const GCPSecurityGroupsFetchSuccessForVsb = data => ({
  type: createStandbyVMConstants.GCP_SECURITY_GROUPS_FETCH_SUCCESS_FOR_VSB,
  data
});

export const clearGCPSecurityGroupsForVsb = () => ({
  type: createStandbyVMConstants.CLEAR_GCP_SECURITY_GROUPS_FOR_VSB
});
