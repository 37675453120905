import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Field } from "redux-form";
import { NavLink } from "react-router-dom";
import modalStyles from "configs/modal";
import FormattedMessage from "components/common/formatted-message";
import { renderInputField } from "components/common/renderFields";
import IconMUI from "components/common/icon/IconMUI";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const LoginModal = ({ handleSubmit }, twoFactorEnabled) => (
  <Modal
    isOpen
    style={{
      ...modalStyles,
      content: {
        height: "300px",
        width: "400px",
        top: "20%",
        left: "40%"
      },
      overlay: {
        zIndex: 9998
      }
    }}
  >
    <div>
      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <Field
          id="email"
          name="email"
          label="Email"
          component={renderInputField}
          type="text"
          disabled
        />
        <Field
          id="password"
          name="password"
          label="Password"
          component={renderInputField}
          type="password"
        />
        {twoFactorEnabled && (
          <Field
            id="two_factor_code"
            name="two_factor_code"
            label="Two Factor Code"
            component={renderInputField}
            type="text"
          />
        )}
        <button type="submit">Login</button>
      </form>
      <NavLink to="/logout">
        <IconMUI icon={LogoutOutlinedIcon} />{" "}
        <FormattedMessage id="csr.gotologin" defaultMessage="Login" />
      </NavLink>
    </div>
  </Modal>
);

LoginModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default LoginModal;
