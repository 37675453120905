import DataGridConstants from "components/common/data-grid/DataGridConstants";

const DataGridColumnCellTypes = DataGridConstants.GridColumnCellTypes;
const DataGridColumnHeaderTypes = DataGridConstants.GridColumnHeaderTypes;

/**
 * Contextual actions for reports
 */
export const CONTEXTUAL_ACTION_REPORT = {
  REMOVE: "deletereport",
  MODIFY: "modifyreport",
  DELETE: "delete_report"
};

export const CONTEXTUAL_ACTION_ALERT = {
  REMOVE: "deletereport",
  MODIFY: "modifyreport",
  DELETE: "delete",
  EDIT: "edit"
};

export const SOURCE_GROUP_TYPES = {
  ALL_SOURCES: "all_sources",
  SOURCE_GROUP: "selected_source_groups",
  ALL_ORGANIZATIONS: "all_organizations",
  ORGANIZATIONS: "selected_organizations"
};

export const DATE_RANGE_SELECT_OPTIONS = [
  "last_24_hours",
  "last_7_days",
  "last_1_month",
  "custom"
];

export const REPORTS_API_URL = {
  reports: "FETCH_REPORTS",
  report_detail: "FETCH_REPORT_DETAILS",
  backup_jobs: "FETCH_REPORTS_BACKUP_JOB",
  restore_jobs: "FETCH_REPORTS_RESTORE_JOB",
  data_transfer: "FETCH_REPORTS_DATA_TRANSFER",
  capacity_usage: "FETCH_REPORTS_CAPACITY_USAGE",
  policy_tasks: "FETCH_REPORTS_POLICY_TASKS",
  account_details: "FETCH_ACCOUNT_DETAILS",
  recovery_point_report: "FETCH_RECOVERY_POINT_REPORT",
  source_protection: "FETCH_SOURCE_PROTECTION_REPORT",
  source_rpo: "FETCH_RECOVERY_POINT_REPORT"
};

/**
 * Available reports type
 * @type {string[]}
 */
export const REPORTS_TYPE = [
  "backup_jobs",
  "data_transfer",
  "capacity_usage",
  "source_rpo",
  "source_protection"
];

export const REPORT_FILTER_TIME_FIELD = {
  reports: "create_ts",
  report_detail: "start_time_ts",
  backup_jobs: "start_time_ts",
  policy_tasks: "start_time_ts",
  restore_jobs: "start_time_ts",
  data_transfer: "create_ts",
  capacity_usage: "create_ts",
  jobs_and_destinations: "start_time_ts"
};

/**
 * Enumrates list of reports attributes known to the UI.
 */
export const ReportsAttributes = {
  SITE_NAME: "site_name",
  RECOVERY_POINT_COUNT: "numberOfRecoveryPoints",
  LATEST_RECOVERY_POINT_TIME: "latestRecoveryPoint",
  OLDEST_RECOVERY_POINT_TIME: "oldestRecoveryPoint",
  REPORTS_PROP_REPORT_ID: "report_id",
  REPORTS_PROP_REPORT_NAME: "report_name",
  REPORTS_PROP_DATE_RANGE: "range_type",
  REPORTS_PROP_REPORT_FOR: "report_for_type",
  REPORTS_PROP_SOURCE_GROUPS: "source_group",
  REPORTS_PROP_SOURCE_GROUP_NAME: "source_group_name",
  REPORTS_PROP_GENERATED_ON: "create_ts",
  REPORTS_PROP_SCHEDULED_TIME: "schedule_time",
  REPORTS_PROP_RECEPIENTS_MAIL: "recipient_mail",
  REPORTS_PROP_SOURCE_NAME: "source_name",
  REPORTS_PROP_PROTECTION_STATUS: "protection_status",
  REPORTS_PROP_CONNECTION_STATUS: "connection_status",
  REPORTS_PROP_OS: "operating_system",
  REPORTS_PROP_ADDED_ON: "added_on",
  REPORTS_PROP_AGENT_INFO: "agent_info",
  REPORTS_PROP_LATEST_BACKUP_JOB: "latest_backup_job",
  REPORTS_PROP_LATEST_PROCESSED_BYTES: "latestProcessedBytes",
  REPORTS_PROP_LATEST_PROCESSED_RATE: "latestProcessedRate",
  REPORTS_PROP_JOB_STATUS: "job_status",
  REPORTS_PROP_DESTINATION_NAME: "destination_name",
  REPORTS_PROP_POLICY_NAME: "policy_name",
  REPORTS_PROP_START_TIME_TS: "start_time_ts",
  REPORTS_PROP_END_TIME_TS: "end_time_ts",
  REPORTS_PROP_DURATION: "duration",
  REPORTS_PROP_WARNINGS: "warnings",
  REPORTS_PROP_ERRORS: "errors",
  REPORTS_PROP_SOURCE_PATH: "source_path",
  REPORTS_PROP_DESTINATION_PATH: "destination_path",
  REPORTS_PROP_DATA_TRANSFERRED: "transferred_bytes",
  REPORTS_PROP_DATA_PROCESSED: "processed_bytes",
  REPORTS_PROP_DATA_WRITTEN: "written_bytes",
  REPORTS_PROP_JOB_TYPE: "job_type",
  REPORTS_PROP_JOB_NAME: "job_name",
  REPORTS_PROP_JOB_START: "start_time_ts",
  REPORTS_PROP_JOB_END: "end_time_ts",
  REPORTS_PROP_TASK: "task",
  REPORTS_PROP_TASK_TYPE: "task_type",
  REPORTS_PROP_SEVERITY: "severity",
  REPORTS_PROP_BACKUP: "backup",
  REPORTS_PROP_BACKUP_SCHEDULE: "backup_schedule",
  REPORTS_PROP_BACKUP_TIME_TS: "backup_time_ts",
  REPORTS_PROP_DESTINATION_TYPE: "destination_type",
  REPORTS_PROP_RETENTION: "retention_name",
  REPORTS_PROP_ORGANIZATION: "organization_name",
  BACKUP_RESTORE_REPORTS_PROP_ORGANIZATION: "organization",
  REPORTS_PROP_TOTAL_SOURCE_DATA: "total_source_data",
  REPORTS_PROP_TOTAL_DATA_TRANSFERRED: "total_data_transferred",
  REPORTS_PROP_DEDUPE_SAVINGS: "dedupe_savings_percent",
  REPORTS_PROP_ACTION_NAME: "action_name",
  REPORTS_PROP_USER_NAME: "user_name",
  REPORTS_PROP_ACTIVITY_DATE: "activity_date_ts",
  REPORTS_PROP_ACTIVITY_DETAILS: "activity_details",
  REPORTS_PROP_ACTIONS: "available_actions",
  REPORTS_PROP_TOTAL_SOURCES: "total_sources",
  REPORTS_PROP_CREATE_USER_ID: "create_user",
  REPORTS_PROP_CREATED_BY: "created_by",
  REPORTS_PROP_SCHEDULE_FREQUENCY: "schedule_frequency",
  REPORTS_TYPE: "report_type",
  REPORTS_PROP_LAST_GENERATED: "last_generated_ts",
  REPORTS_PROP_LAST_MODIFIED: "modify_ts",
  ALERT_TYPE: "alert_type",
  ALERT_NAME: "alert_name",
  REPORTS_PROP_ALERT_FOR: "report_for_type",
  ALERT_PROP_ALERT_FOR: "report_for",
  ALERT_RECIPIENT: "recipients",
  SCHEDULE_PROP_GENERATED_ON: "created_on",
  DAY_UTC: "day_utc",
  CLOUD_DIRECT_USAGE: "cloud_direct_usage",
  UDP_USAGE: "udp_usage",
  TOTAL_USAGE: "total_usage",
  USAGE: "usage",
  REPORT_PROP_LATEST_JOB_NAME: "name",
  REPORT_PROP_LATEST_JOB_STATUS: "status",
  REPORT_PROP_LATEST_JOB_DATE: "date"
};

export const REPORTS_SEARCH_NAME_PARAM = {
  reports: ReportsAttributes.REPORTS_PROP_REPORT_NAME,
  backup_jobs: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
  policy_tasks: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
  restore_jobs: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
  data_transfer: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
  capacity_usage: ReportsAttributes.REPORTS_PROP_DESTINATION_NAME,
  account_details: ReportsAttributes.REPORTS_PROP_ORGANIZATION,
  recovery_point_report: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
  source_protection: ReportsAttributes.REPORTS_PROP_SOURCE_NAME
};

/**
 * Additional Grid properties :
 * CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS : On click of cell the details will show
 * @type {{CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS: string}}
 */
export const GRID_PROPS = {
  CLICKABLE_CELL_TO_VIEW_REPORT_DETAILS:
    ReportsAttributes.REPORTS_PROP_REPORT_NAME,
  CLICKABLE_CELL_TO_VIEW_SOURCE_DETAILS:
    ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
  CLICKABLE_CELL_TO_VIEW_POLICY_DETAILS:
    ReportsAttributes.REPORTS_PROP_POLICY_NAME,
  CLICKABLE_CELL_TO_VIEW_DESTINATION_DETAILS:
    ReportsAttributes.REPORTS_PROP_DESTINATION_NAME,
  CLICKABLE_CELL_TO_VIEW_ORGANIZATION_DETAILS: "organization",
  CLICKABLE_CELL_TO_VIEW_ORGANIZATION: "organization_name"
};

/**
 * Returns an i18n supported key for the identifier
 * @param {string} identifier
 */
const getDisplayIdentifier = identifier =>
  "analyze.reports.".concat(identifier);

/**
 * Column definition for recoveryPointReport data-grid
 */
export const SOURCE_PROTECTION_REPORT_COLUMN_DEFS = [
  {
    accessor: ReportsAttributes.REPORTS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_PROTECTION_STATUS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_PROTECTION_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ICON_CELL_MUI_WITHOUT_OVERLAY
    },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_CONNECTION_STATUS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_CONNECTION_STATUS
    ),
    renderHeader: {
      type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI
    },
    renderRecord: {
      type: DataGridColumnCellTypes.ICON_CELL_MUI_WITHOUT_OVERLAY
    },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_POLICY_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_POLICY_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.SITE_NAME,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.SITE_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_OS,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.REPORTS_PROP_OS),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ADDED_ON,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ADDED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_AGENT_INFO,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_AGENT_INFO
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LATEST_BACKUP_JOB,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_LATEST_BACKUP_JOB
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LATEST_JOB_LINK_CELL_MUI },
    settings: { className: "overflow-hidden" }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LATEST_PROCESSED_BYTES,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_LATEST_PROCESSED_BYTES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LATEST_PROCESSED_RATE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_LATEST_PROCESSED_RATE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.LATEST_RECOVERY_POINT_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.LATEST_RECOVERY_POINT_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  }
];
/*
 * Name
 * Report type
 * Organization/Sources
 * Frequency
 * Created By
 * Created On: Day the schedule was created
 * Last Generated
 */

/**
 * Column definition for data-grid
 */
export const COLUMN_DEFS = [
  {
    accessor: ReportsAttributes.LATEST_RECOVERY_POINT_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.LATEST_RECOVERY_POINT_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.OLDEST_RECOVERY_POINT_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.OLDEST_RECOVERY_POINT_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.RECOVERY_POINT_COUNT,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.RECOVERY_POINT_COUNT
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.SITE_NAME,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.SITE_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LAST_GENERATED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_LAST_GENERATED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_REPORT_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_REPORT_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATE_RANGE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATE_RANGE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_REPORT_FOR,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_REPORT_FOR
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_GROUPS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_GROUPS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_GENERATED_ON,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_GENERATED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SCHEDULED_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SCHEDULED_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_RECEPIENTS_MAIL,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_RECEPIENTS_MAIL
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: {
      width: 250
    }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_STATUS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_JOB_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DESTINATION_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.BACKUP_RESTORE_REPORTS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.BACKUP_RESTORE_REPORTS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_POLICY_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_POLICY_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_START_TIME_TS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_START_TIME_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_END_TIME_TS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_END_TIME_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DURATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DURATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_WARNINGS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_WARNINGS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.NUMERICAL_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ERRORS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ERRORS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.NUMERICAL_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_PATH,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_PATH
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_PATH,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DESTINATION_PATH
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATA_TRANSFERRED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATA_TRANSFERRED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATA_PROCESSED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATA_PROCESSED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATA_WRITTEN,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATA_WRITTEN
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_JOB_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_TYPE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_JOB_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_BACKUP,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_BACKUP
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_BACKUP_SCHEDULE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_BACKUP_SCHEDULE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_BACKUP_TIME_TS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_BACKUP_TIME_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_TYPE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DESTINATION_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_RETENTION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_RETENTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    access: ReportsAttributes.REPORTS_PROP_TOTAL_SOURCE_DATA,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TOTAL_SOURCE_DATA
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_TOTAL_DATA_TRANSFERRED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TOTAL_DATA_TRANSFERRED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DEDUPE_SAVINGS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DEDUPE_SAVINGS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },

  {
    accessor: ReportsAttributes.REPORTS_PROP_TOTAL_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TOTAL_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SCHEDULE_FREQUENCY,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SCHEDULE_FREQUENCY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_CREATE_USER_ID,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_CREATE_USER_ID
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_TYPE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.REPORTS_TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },

  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTION_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTION_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_USER_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_USER_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_TASK_TYPE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TASK_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIVITY_DATE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIVITY_DATE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIVITY_DETAILS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIVITY_DETAILS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.MULTILINE_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  },
  {
    accessor: ReportsAttributes.DAY_UTC,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.DAY_UTC),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.CLOUD_DIRECT_USAGE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.CLOUD_DIRECT_USAGE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.UDP_USAGE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.UDP_USAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.TOTAL_USAGE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.TOTAL_USAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.USAGE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.USAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  }
];

export const BACKUP_JOB_COLUMN_DEFS = [
  {
    accessor: ReportsAttributes.LATEST_RECOVERY_POINT_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.LATEST_RECOVERY_POINT_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.OLDEST_RECOVERY_POINT_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.OLDEST_RECOVERY_POINT_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.RECOVERY_POINT_COUNT,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.RECOVERY_POINT_COUNT
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.SITE_NAME,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.SITE_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LAST_GENERATED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_LAST_GENERATED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_REPORT_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_REPORT_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATE_RANGE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATE_RANGE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_REPORT_FOR,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_REPORT_FOR
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_GROUPS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_GROUPS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_GENERATED_ON,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_GENERATED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SCHEDULED_TIME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SCHEDULED_TIME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_RECEPIENTS_MAIL,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_RECEPIENTS_MAIL
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: {
      width: 250
    }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_STATUS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_JOB_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DESTINATION_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.RENDER_DESTINATION_OVERLAY_CELL_MUI
    },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.BACKUP_RESTORE_REPORTS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.BACKUP_RESTORE_REPORTS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_POLICY_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_POLICY_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_START_TIME_TS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_START_TIME_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_END_TIME_TS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_END_TIME_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DURATION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DURATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_WARNINGS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_WARNINGS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.NUMERICAL_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ERRORS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ERRORS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.NUMERICAL_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_PATH,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SOURCE_PATH
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_PATH,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DESTINATION_PATH
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATA_TRANSFERRED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATA_TRANSFERRED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATA_PROCESSED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATA_PROCESSED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DATA_WRITTEN,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DATA_WRITTEN
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_JOB_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_TYPE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_JOB_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_BACKUP,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_BACKUP
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_BACKUP_SCHEDULE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_BACKUP_SCHEDULE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_BACKUP_TIME_TS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_BACKUP_TIME_TS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_TYPE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DESTINATION_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_RETENTION,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_RETENTION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    access: ReportsAttributes.REPORTS_PROP_TOTAL_SOURCE_DATA,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TOTAL_SOURCE_DATA
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_TOTAL_DATA_TRANSFERRED,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TOTAL_DATA_TRANSFERRED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DEDUPE_SAVINGS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_DEDUPE_SAVINGS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 200 }
  },

  {
    accessor: ReportsAttributes.REPORTS_PROP_TOTAL_SOURCES,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TOTAL_SOURCES
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SCHEDULE_FREQUENCY,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_SCHEDULE_FREQUENCY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_CREATE_USER_ID,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_CREATE_USER_ID
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_TYPE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.REPORTS_TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },

  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTION_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTION_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_USER_NAME,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_USER_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_TASK_TYPE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_TASK_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIVITY_DATE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIVITY_DATE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIVITY_DETAILS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIVITY_DETAILS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.MULTILINE_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  },
  {
    accessor: ReportsAttributes.DAY_UTC,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.DAY_UTC),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.CLOUD_DIRECT_USAGE,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.CLOUD_DIRECT_USAGE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.UDP_USAGE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.UDP_USAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.TOTAL_USAGE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.TOTAL_USAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.USAGE,
    displayIdentifier: getDisplayIdentifier(ReportsAttributes.USAGE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: {}
  }
];

const getDisplayIdentifierMRS = identifier =>
  "analyze.reports.mrs.".concat(identifier);

const getDisplayIdentifierAlerts = identifier =>
  "analyze.reports.alerts.".concat(identifier);

const getDisplayIdentifierPolicyTasks = identifier =>
  "analyze.reports.policy_tasks.".concat(identifier);

export const COLUMN_DEFS_POLICY_TASKS = [
  {
    accessor: ReportsAttributes.REPORTS_PROP_ORGANIZATION,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_ORGANIZATION
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SOURCE_NAME,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_SOURCE_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_POLICY_NAME,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_POLICY_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_DESTINATION_NAME,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_DESTINATION_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_TASK,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_TASK
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_NAME,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_JOB_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_TYPE,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_JOB_TYPE
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_STATUS,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_JOB_STATUS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_START,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_JOB_START
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_JOB_END,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_JOB_END
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SEVERITY,
    displayIdentifier: getDisplayIdentifierPolicyTasks(
      ReportsAttributes.REPORTS_PROP_SEVERITY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.ICON_CELL_MUI },
    settings: {}
  }
];

export const COLUMN_DEFS_ANALYZE_ALERTS = [
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifier(
      ReportsAttributes.REPORTS_PROP_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  },
  {
    accessor: ReportsAttributes.ALERT_NAME,
    displayIdentifier: getDisplayIdentifierAlerts(ReportsAttributes.ALERT_NAME),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.ALERT_TYPE,
    displayIdentifier: getDisplayIdentifierAlerts(ReportsAttributes.ALERT_TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.ALERT_PROP_ALERT_FOR,
    displayIdentifier: getDisplayIdentifierAlerts(
      ReportsAttributes.ALERT_PROP_ALERT_FOR
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.ALERT_RECIPIENT,
    displayIdentifier: getDisplayIdentifierAlerts(
      ReportsAttributes.ALERT_RECIPIENT
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: {
      width: 250
    }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LAST_GENERATED,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_LAST_GENERATED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_CREATE_USER_ID,
    displayIdentifier: getDisplayIdentifierAlerts(
      ReportsAttributes.REPORTS_PROP_CREATE_USER_ID
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_GENERATED_ON,
    displayIdentifier: getDisplayIdentifierAlerts(
      ReportsAttributes.REPORTS_PROP_GENERATED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  }
];

export const COLUMN_DEFS_MANAGE_REPORT_SCHEDULES = [
  {
    accessor: ReportsAttributes.REPORTS_PROP_LAST_GENERATED,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_LAST_GENERATED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_REPORT_NAME,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_REPORT_NAME
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LINK_CELL_MUI },
    settings: { width: 300 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ALERT_FOR,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_ALERT_FOR
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.OVERLAY_CELL_MUI },
    settings: { width: 130 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_CREATED_BY,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_CREATED_BY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 250 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_SCHEDULE_FREQUENCY,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_SCHEDULE_FREQUENCY
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.SHORT_TEXT_CELL_MUI },
    settings: { width: 150 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_GENERATED_ON,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_GENERATED_ON
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  },
  {
    accessor: ReportsAttributes.REPORTS_TYPE,
    displayIdentifier: getDisplayIdentifierMRS(ReportsAttributes.REPORTS_TYPE),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.LONG_TEXT_CELL_MUI },
    settings: { width: 200 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_ACTIONS,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_ACTIONS
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: {
      type: DataGridColumnCellTypes.ACTION_DROPDOWN_CELL_MUI,
      actionDropdown: true
    },
    settings: { width: 80 }
  },
  {
    accessor: ReportsAttributes.REPORTS_PROP_LAST_MODIFIED,
    displayIdentifier: getDisplayIdentifierMRS(
      ReportsAttributes.REPORTS_PROP_LAST_MODIFIED
    ),
    renderHeader: { type: DataGridColumnHeaderTypes.HEADER_TEXT_MUI },
    renderRecord: { type: DataGridColumnCellTypes.DATE_TIME_CELL_MUI },
    settings: {}
  }
];
