import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PersonalDetails from "./ForgotPassword";

import {} from "../../state/actions/actions";

class ResetPasswordForm extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="h-100">
        <PersonalDetails />
      </div>
    );
  }
}
ResetPasswordForm.defaultProps = {};

/**
 * Properties required to render component
 * @type {{}}
 */
ResetPasswordForm.propTypes = {
  email: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(ResetPasswordForm);
