import { call } from "redux-saga/effects";
import {
  getData,
  postData,
  putData,
  deleteData,
  postFileDataMultipart
} from "./apiMethods";

const beUrl = process.env.REACT_APP_BE_URL;
// const mockUrl = process.env.REACT_APP_API_URL;

export function* organizationsSearch(
  token,
  searchString,
  page = 1,
  pageSize = 10,
  sort
) {
  const data = {
    search_string: searchString,
    page,
    page_size: pageSize,
    sort,
    include_deleted: false
  };

  return yield call(postData, `${beUrl}/organizations/search`, data, token);
}

export function* updateOrganization(token, orgId, data) {
  return yield call(putData, `${beUrl}/organizations/${orgId}`, data, token);
}

export function* organizations(token, orgId) {
  return yield call(
    getData,
    `${beUrl}/organizations/${orgId}`,
    undefined,
    token
  );
}

export function* organizationsSupport(token, orgId) {
  return yield call(
    getData,
    `${beUrl}/organizations/${orgId}/support`,
    undefined,
    token
  );
}

export function* organizationsFreetrialCloudDirect(token, orgId) {
  return yield call(
    getData,
    `${beUrl}/organizations/${orgId}/freetrial/clouddirect`,
    undefined,
    token
  );
}

export function* organizationsFreetrialCloudHybrid(token, orgId) {
  return yield call(
    getData,
    `${beUrl}/organizations/${orgId}/freetrial/cloudhybrid`,
    undefined,
    token
  );
}

export function* organizationsProperties(token, orgId) {
  return yield call(
    getData,
    `${beUrl}/organizations/${orgId}/properties`,
    undefined,
    token
  );
}

export function* activateOrganizationFreetrialCloudDirect(token, orgId, data) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/freetrial/clouddirect`,
    data,
    token
  );
}

export function* enrollIngramOrg(token, data) {
  return yield call(postData, `${beUrl}/organizations/enroll`, data, token);
}

export function* activateOrganizationFreetrialCloudHybrid(token, orgId) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/freetrial/cloudhybrid`,
    undefined,
    token
  );
}
export function* activateOrganizationFreetrialUDP(token, orgId) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/freetrial/udp`,
    undefined,
    token
  );
}

export function* enablePolicies(token, orgId) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/policies/enable`,
    undefined,
    token
  );
}

export function* disablePolicies(token, orgId) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/policies/disable`,
    undefined,
    token
  );
}

export function* enableOrg(token, orgId) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/resume`,
    undefined,
    token
  );
}

export function* suspendOrg(token, orgId) {
  return yield call(
    postData,
    `${beUrl}/organizations/${orgId}/suspend`,
    undefined,
    token
  );
}

export function* handleProductStatus(token, action) {
  let productTypeUrl = "";
  if (action && action.productType !== "all") {
    productTypeUrl = `?product_type=${action.productType}`;
  }
  return yield call(
    postData,
    `${beUrl}/organizations/${action.orgId}/${action.status}${productTypeUrl}`,
    undefined,
    token
  );
}

export function* invalidateCache(token, orgId) {
  return yield call(
    deleteData,
    `${beUrl}/organizations/${orgId}/cache/resources`,
    token,
    undefined
  );
}

export function* organizationsEntitlements(token, orgId) {
  return yield call(
    getData,
    `${beUrl}/organizations/${orgId}/entitlements`,
    undefined,
    token
  );
}

export function* deleteOrganizations(token, orgId) {
  return yield call(
    deleteData,
    `${beUrl}/organizations/${orgId}`,
    token,
    undefined
  );
}

export function* usersSearch(
  token,
  searchString,
  page = 1,
  pageSize = 10,
  sort
) {
  const data = {
    search_string: searchString,
    page,
    page_size: pageSize,
    sort
  };

  return yield call(postData, `${beUrl}/users/search`, data, token);
}

export function* user(token, userId) {
  return yield call(getData, `${beUrl}/users/${userId}`, undefined, token);
}

export function* updateUser(
  token,
  userId,
  firstName,
  lastName,
  phoneNumber,
  email,
  roleId,
  blocked
) {
  const d = {
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    email,
    role_id: roleId,
    blocked
  };

  return yield call(putData, `${beUrl}/users/${userId}`, d, token);
}

export function* sendResetPWEmail(token, userId) {
  return yield call(
    postData,
    `${beUrl}/users/${userId}/resetpassword`,
    undefined,
    token
  );
}

export function* sendVerificationEmail(token, userId) {
  return yield call(
    postData,
    `${beUrl}/users/${userId}/verificationemail`,
    undefined,
    token
  );
}

export function* createUser(
  token,
  organizationId,
  firstName,
  lastName,
  phoneNumber,
  email,
  roleId
) {
  const d = {
    organization_id: organizationId,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    email,
    role_id: roleId
  };

  return yield call(postData, `${beUrl}/users`, d, token);
}

export function* deleteUser(token, userId) {
  return yield call(deleteData, `${beUrl}/users/${userId}`, token, undefined);
}

export function* auditCodes(token) {
  return yield call(
    getData,
    `${beUrl}/audittrail/codes?page_size=1000`,
    undefined,
    token
  );
}

export function* organizationsAuditTrail(
  token,
  organizationId,
  auditCodes,
  from,
  to,
  page,
  pageSize,
  sort
) {
  const ac = auditCodes.map(code => code).join("%7C"); // %7C encodes |
  const dateFilter = `create_ts=${encodeURIComponent(
    ">"
  )}${from}&create_ts=${encodeURIComponent("<")}${to}`;
  const codeFilter = ac.length > 0 ? `code_id=${ac}&` : "";

  return yield call(
    getData,
    `${beUrl}/organizations/${organizationId}/audittrail`,
    `${codeFilter}${dateFilter}&page=${page}&page_size=${pageSize}&sort=${sort}`,
    token
  );
}

export function* fetchSites(token, organization_id) {
  return yield call(
    getData,
    `${beUrl}/sites?organization_id=${organization_id}&site_type=gateway&pagination=false`,
    undefined,
    token
  );
}

export function* fetchDataCenters(token) {
  return yield call(
    getData,
    `${beUrl}/destinations/datacenters`,
    undefined,
    token
  );
}
export function* createRecoveryPointServer(token, payload) {
  return yield call(postData, `${beUrl}/recoverypointservers`, payload, token);
}

export function* updateRecoveryPointServer(token, payload) {
  return yield call(
    putData,
    `${beUrl}/recoverypointservers/${payload.id}`,
    payload.payload,
    token
  );
}
export function* fetchRecoveryPointServers(
  token,
  page = 1,
  pageSize = 10,
  sort = ""
) {
  return yield call(
    getData,
    `${beUrl}/recoverypointservers`,
    `page=${page}&page_size=${pageSize}&sort=${sort}`,
    token
  );
}

export function* fetchRecoveryPointServer(token, id) {
  return yield call(
    getData,
    `${beUrl}/recoverypointservers/${id}`,
    undefined,
    token
  );
}

export function* deleteRecoveryPointServer(token, id) {
  return yield call(
    deleteData,
    `${beUrl}/recoverypointservers/${id}`,
    token,
    undefined
  );
}

export function* fetchCloudHybridStores(token, rpsServerId) {
  return yield call(
    getData,
    `${beUrl}/recoverypointservers/${rpsServerId}/cloudhybridstores`,
    `pagination=false`,
    token
  );
}

export function* usersAuditTrail(
  token,
  userId,
  auditCodes,
  from,
  to,
  page,
  pageSize,
  sort
) {
  const ac = auditCodes.map(code => code).join("%7C"); // %7C encodes |
  const dateFilter = `create_ts=${encodeURIComponent(
    ">"
  )}${from}&create_ts=${encodeURIComponent("<")}${to}`;
  const codeFilter = ac.length > 0 ? `code_id=${ac}&` : "";

  return yield call(
    getData,
    `${beUrl}/users/${userId}/audittrail`,
    `${codeFilter}${dateFilter}&page=${page}&page_size=${pageSize}&sort=${sort}`,
    token
  );
}

export function* createLinuxBackupServer(token, payload) {
  return yield call(postData, `${beUrl}/linuxbackupservers`, payload, token);
}

export function* updateLinuxBackupServer(token, payload) {
  return yield call(
    putData,
    `${beUrl}/linuxbackupservers/${payload.id}`,
    payload.payload,
    token
  );
}

export function* fetchLinuxBackupServer(token, id) {
  return yield call(
    getData,
    `${beUrl}/linuxbackupservers/${id}`,
    undefined,
    token
  );
}

export function* deleteLinuxBackupServer(token, id) {
  return yield call(
    deleteData,
    `${beUrl}/linuxbackupservers/${id}`,
    token,
    undefined
  );
}
export function* fetchLinuxBackupServers(
  token,
  page = 1,
  pageSize = 10,
  sort = ""
) {
  return yield call(
    getData,
    `${beUrl}/linuxbackupservers`,
    `page=${page}&page_size=${pageSize}&sort=${sort}`,
    token
  );
}

export function* fetchDataStores(token, page = 1, pageSize = 10, sort = "") {
  return yield call(
    getData,
    `${beUrl}/datastores`,
    `page=${page}&page_size=${pageSize}&sort=${sort}`,
    token
  );
}

export function* createDataStore(token, ds) {
  return yield call(postData, `${beUrl}/cloudhybridstores`, ds, token);
}

export function* fetchDataStore(token, dataStoreId) {
  return yield call(
    getData,
    `${beUrl}/cloudhybridstores/${dataStoreId}`,
    undefined,
    token
  );
}

export function* updateDataStore(token, dataStoreId, ds) {
  return yield call(
    putData,
    `${beUrl}/cloudhybridstores/${dataStoreId}`,
    ds,
    token
  );
}

export function* deleteDataStore(token, rpsServerId, ds) {
  return yield call(
    deleteData,
    `${beUrl}/recoverypointservers/${rpsServerId}`,
    token,
    ds
  );
}

export function* destroyDataStore(token, dataStoreId) {
  return yield call(
    deleteData,
    `${beUrl}/cloudhybridstores/${dataStoreId}/destroy`,
    token,
    undefined
  );
}

export function* startDataStore(token, dataStoreId) {
  return yield call(
    postData,
    `${beUrl}/cloudhybridstores/${dataStoreId}/start`,
    undefined,
    token
  );
}

export function* stopDataStore(token, dataStoreId) {
  return yield call(
    postData,
    `${beUrl}/cloudhybridstores/${dataStoreId}/stop`,
    undefined,
    token
  );
}

export function* fetchBIReportTypes(token) {
  return yield call(
    getData,
    `${beUrl}/businessintelligencereports/types`,
    undefined,
    token
  );
}

export function* fetchBIReport(token, reportType) {
  return yield call(
    getData,
    `${beUrl}/businessintelligencereports/types/${reportType}`,
    undefined,
    token
  );
}

export function* fetchBIReportRecipients(token, reportType) {
  return yield call(
    getData,
    `${beUrl}/businessintelligencereports/types/${reportType}/recipients`,
    undefined,
    token
  );
}

export function* updateBIReportRecipients(
  httpType,
  token,
  reportType,
  recipient
) {
  let requestType = httpType === "post" ? postData : putData;
  return yield call(
    requestType,
    `${beUrl}/businessintelligencereports/types/${reportType}/recipients`,
    recipient,
    token
  );
}

/**
 * To upload the Support > Download Content > CSV file.
 */
export function* uploadFile(postbody, token) {
  try {
    const data = yield call(
      postFileDataMultipart,
      `${beUrl}/agents/downloadinfo`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To upload the help link CSV file.
 */
export function* uploadHelpLinkCSVFile(postBody, token) {
  try {
    const data = yield call(
      postFileDataMultipart,
      `${beUrl}/help`,
      postBody,
      token,
      true /* showCommonError */
    );
    return data;
  } catch (err) {
    window.console.log(err);
  }
}

export function* updateUserPassword(token, data) {
  return yield call(putData, `${beUrl}/users/updatepassword`, data, token);
}
