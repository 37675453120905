import uuidv4 from "uuid/v4";
import * as C from "state/constants/ccToast";
import { ID_PREFIX } from "configs/IdConfig";

/*
{
type: "CCTOAST/ADD_NOTIFICATION",
  notification: {
    notificationId: 1,
    messageId: "test"
  }
}
*/

export const addNotification = (
  icon,
  messageId,
  messageValues = {},
  namespace = "",
  dismiss = 5 // default to fade out at 5 seconds
) => {
  const translationId = messageId?.messageId ? messageId.messageId : messageId;
  const notificationId = messageId?.testId
    ? `${ID_PREFIX}${messageId.testId}`
    : uuidv4();
  return {
    type: C.CCTOAST_ADD_NOTIFICATION,
    notification: {
      notificationId: notificationId,
      icon,
      messageId: translationId,
      messageValues,
      namespace,
      dismiss
    }
  };
};

export const addSuccessNotification = (
  messageId,
  messageValues,
  namespace,
  dismiss = 5
) =>
  addNotification(
    "successToaster",
    messageId,
    messageValues,
    namespace,
    dismiss
  );

export const addErrorNotification = (
  messageId,
  messageValues,
  namespace,
  dismiss = 5
) =>
  addNotification("errorToaster", messageId, messageValues, namespace, dismiss);

export const addWarningNotification = (
  messageId,
  messageValues,
  namespace,
  dismiss
) =>
  addNotification(
    "warningToaster",
    messageId,
    messageValues,
    namespace,
    dismiss
  );

export const addInfoNotification = (
  messageId,
  messageValues,
  namespace,
  dismiss = 5
) =>
  addNotification("infoToaster", messageId, messageValues, namespace, dismiss);

export const clearNotifications = () => ({
  type: C.CCTOAST_CLEAR_NOTIFICATIONS
});

export const clearNotification = notificationId => ({
  type: C.CCTOAST_CLEAR_NOTIFICATION,
  notificationId
});
