/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { call } from "redux-saga/effects";
import * as API_URL from "./apiUrlConstants";
import {
  getData,
  postData,
  putData,
  deleteData,
  postCookieData
} from "./apiMethods";
import * as constant from "../../utils/appConstants";

/**
 * Get sources list from the API
 * @param type - Type of the sources
 * @returns {Promise<any>}
 */
export function* fetchSourcesList(queryString, token, pollingAction = false) {
  try {
    const data = yield call(
      getData,
      // API_URL.FETCH_SOURCES,
      queryString.includes("78506751-cc21-4989-a1f4-d7019df22a9f")
        ? API_URL.FETCH_STORAGE_SOURCES
        : API_URL.FETCH_SOURCES,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get sources list from the API
 * @param type - Type of the sources
 * @returns {Promise<any>}
 */
export function* fetchSourcesListForCDPolicy(
  queryString,
  token,
  url,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_POLICY_SOURCE_DR_CD}/${url}`,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervisorList(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_HYPERVISOR_LIST,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchAllVSBHypervisorList(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.FETCH_ALL_VSB_HYPERVISOR_LIST_V2,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSourceARTData(
  { sourceId },
  token,
  pollingAction,
  showCommonError
) {
  const composedURL = API_URL.GET_SOURCE_BY_ID.replace("{id}", sourceId);
  try {
    return yield call(
      getData,
      composedURL,
      sourceId,
      token,
      pollingAction,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSourceASSData(
  { sourceId },
  token,
  pollingAction,
  showCommonError
) {
  const composedURL = API_URL.GET_SOURCE_ASTASKS_BY_ID.replace(
    "{id}",
    sourceId
  );
  try {
    return yield call(
      getData,
      composedURL,
      sourceId,
      token,
      pollingAction,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getHyperVConfigurationDetails(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.GET_HYPERV_CONFIGURATION}`,
      data,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getVmwareConfigurationDetails(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.GET_VMWARE_CONFIGURATION}`,
      data,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getNutanixConfigurationDetails(data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.GET_NUTANIX_CONFIGURATION}`,
      data,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchVMsList(id, query, token, pollingAction = false) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${id}/vms`,
      query,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* export const fetchPoliciesList (queryString)  {
 try{

 const data = yield call(getData, API_URL.FETCH_POLICIES, queryString);
 return data;
 }catch(e){
 window.console.log(e);
 }
 } */

/* export const fetchSearchSourceResult (queryString)  {
 try{
 const data = yield call(getData, API_URL.FETCH_SOURCES_RESULTS, queryString);
 return data;
 }catch(e){
 window.console.log(e);
 }
 } */

/**
 * Get sources count list from the API
 *
 * @returns {Promise<any>}
 */
export function* getSourcesCounts(token, queryString, showLoader) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_SOURCES_COUNTS,
      queryString,
      token,
      false,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get destination count list from the API
 *
 * @returns {Promise<any>}
 */
export function* getDestinationsCounts(token, queryString, showLoader) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_DESTINATIONS_COUNTS,
      queryString,
      token,
      false,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDestinations(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_DESTINATIONS,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * Get policies count list from the API
 *
 * @returns {Promise<any>}
 */
export function* getPoliciesCounts() {
  try {
    const data = yield call(getData, API_URL.FETCH_POLICIES_COUNTS);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * getRecoveredVMSCount
 */
export function* getRecoveredVMSCount(token, queryString, showLoader) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_RECOVERED_VMS_COUNTS,
      queryString,
      token,
      false,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get advanced search options for the Protect tab
 * @param type
 * @returns {*}
 */
export function* getAdvancedSearch() {
  try {
    const data = yield call(getData, API_URL.FETCH_ADVANCE_SEARCH);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get destination list from the API
 * @param type - Type of the destination
 * @returns {Promise<any>}
 */
export function* fetchDestinationsList(
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_DESTINATIONS,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get destinations and datastores list from the API
 * @param type - Type of the destination
 * @returns {Promise<any>}
 */
export function* fetchAllDestinationsList(
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_ALL_DESTINATIONS,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get failback destinations list from the API
 * @param type - Type of the destination
 * @returns {Promise<any>}
 */
export function* fetchFailbackDestinationsList(
  recoveredResourceId,
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_RECOVERED_VMS}/${recoveredResourceId}/failbacktargetoptions`,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get default search data list from the API
 *
 * @returns {Promise<any>}
 */

export function* getDefaultSearchData(user_id, token) {
  try {
    let url = API_URL.FETCH_DEFAULTSEARCHES;
    url = `${url}/${user_id}/sourcefilters`;
    const data = yield call(getData, url, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* To get recovered resources result as per the given criteria
 * @param searchCriteria
 * @returns {Promise<any>}
 */
export function* fetchRecoveredResourcesResult(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_RECOVERED_RESOURCES,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* To get detail of specific resource
 * @param id
 * @returns {Promise<any>}
 */
export function* fetchRecoveredResourceById(id, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_RECOVERED_RESOURCES}?resource_id=${id}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get destinations result as per the given criteria
 * @param searchCriteria
 * @param token
 * @returns {Promise<any>}
 */
export function* fetchPoliciesSourceResult(
  searchCriteria,
  token,
  pollingAction = false
) {
  try {
    return yield call(
      getData,
      API_URL.FETCH_POLICIES,
      searchCriteria,
      token,
      pollingAction
    );
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get policy details by id
 * @param id
 * @returns {Promise<any>}
 */
export function* fetchPolicyDetailsById(id, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_POLICIES}/${id}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To delete policy details by id
 * @param id
 * @returns {Promise<any>}
 */
export function* deletePolicyDetailsById(id, token, showCommonError = true) {
  try {
    return yield call(
      deleteData,
      `${API_URL.FETCH_POLICIES}/${id}`,
      token,
      {},
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteActiveDirectoryByID(id, token) {
  try {
    return yield call(deleteData, `${API_URL.FETCH_DISCOVERIES}/${id}`, token);
  } catch (e) {
    window.console.log(e);
  }
}

export function* enablePolicyDetailsById(
  id,
  data,
  token,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/enable`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* refreshPolicyDetailsById(id, token, showCommonError = false) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_POLICIES}/${id}`,
      "",
      token,
      false,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* backupPolicyById(id, data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/backup`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* replicatePolicyById(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/replicate`,
      data,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}
export function* getSharedPolicyDetails(token, id) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_POLICIES}/${id}/shares`,
      "",
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* sharePolicyDetailsById(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/${API_URL.SHARE_POLICY_TO_USERNAME_BY_ID}`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* startArTest(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/startartest`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}
export function* sourceStartArTest(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/startartest`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* sourceStartAsScan(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/assuredsecurity/run`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* sourceStartAdhocAsScan(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/assuredsecurity`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* startAsTest(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/assuredsecurity/run`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* unsharePolicyDetailsById(id, data, token) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/${API_URL.UN_SHARE_POLICY_BY_ID}`,
      data,
      token
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* disablePolicyDetailsById(
  id,
  data,
  token,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/disable`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* deployPolicyById(id, token, showCommonError = false) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_POLICIES}/${id}/deploy`,
      "",
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * To update policy details by id
 * @param id
 * @returns {Promise<any>}
 */
export function* updatePolicyDetailsById(id, postDataBody, token) {
  try {
    const data = yield call(
      putData,
      `${API_URL.FETCH_POLICIES_BE}/${id}`,
      postDataBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* updateSiteDetailsById(id, postDataBody, token) {
  try {
    const data = yield call(
      putData,
      `${API_URL.UPDATE_SITE_DETAILS}/${id}`,
      postDataBody,
      token,
      true /* showCommonError */
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* transactionDetails(req, id, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_TRANSACTION_DETAILS.replace("{id}", id)}`,
      req,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteSiteById(id, token) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.UPDATE_SITE_DETAILS}/${id}`,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* updateRemoteConsoleDetailsById(id, postDataBody, token) {
  try {
    const data = yield call(
      putData,
      `${API_URL.UPDATE_REMOTE_CONSOLE_URL}/${id}`,
      postDataBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateAdDetailsByID(postDataBody, token, id) {
  try {
    const data = yield call(
      putData,
      `${API_URL.UPDATE_AD_DETAILS}/${id}`,
      postDataBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To create policy details
 * @param id
 * @returns {Promise<any>}
 */
export function* createPolicyDetails(postDataBody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_POLICIES}`,
      postDataBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To create policy details for list //TODO remove after API integration temporary added for listing policy
 * @param id
 * @returns {Promise<any>}
 */
export function* createPolicyDetailsForList(postDataBody) {
  try {
    const data = yield call(postData, API_URL.FETCH_POLICIES, postDataBody);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get basic tab content for create policy
 * @param type
 * @returns {*}
 */
export function* getBasicTabDetails(token, queryString) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_BASIC_DETAILS,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get basic tab content for create policy
 * @param type
 * @returns {*}
 */
export function* getPoliciesDetails() {
  try {
    const data = yield call(getData, API_URL.FETCH_POLICY_DETAILS);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchSourceById(id, token, query) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${id}`,
      query,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Remove source by id - Source will be listed as ghost
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* removeSourceById(
  id,
  token,
  payload = {},
  showCommonError = false
) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_SOURCES}/${id}`,
      token,
      payload,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* forceDeleteSource(token, id, forceDelete) {
  try {
    const data = yield call(
      postData,
      API_URL.FETCH_V2_SOURCES + "/" + id,
      { force_delete: forceDelete },
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Hard Remove source by id - source will be removed from grid
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* hardRemoveSourceById(
  id,
  token,
  payload = {},
  showCommonError = false
) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_SOURCES}/${id}/remove`,
      token,
      payload,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Upgrade agent by source id
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* upgradeAgentBySourceId(id, token, showCommonError = false) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/upgradeagent`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Toggle backup now by source id (Startbackup, Stopbackup)
 * @param id
 * @param backupAction
 * @param token
 * @returns {Promise<*>}
 */
export function* toggleBackupBySourceId(
  id,
  backupAction,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/${backupAction}`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Toggle backup now by source id (Startbackup, Stopbackup) with payload
 * @param id
 * @param backupAction
 * @param payload
 * @param token
 * @returns {Promise<*>}
 */
export function* toggleBackupBySourceIdWithPayload(
  id,
  backupAction,
  payload,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_V2_SOURCES}/${id}/${backupAction}`,
      payload,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Cancel recovery now by source id
 * @param id
 * @param query
 * @param token
 * @returns {Promise<*>}
 */
export function* cancelRecoveryBySourceId(
  id,
  query,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/cancelrecovery?${query}`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get detail of specific destinaiton
 * @param id
 * @returns {Promise<any>}
 */
export function* fetchDestinationsById(id, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_DESTINATIONS}/${id}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To add a destination
 */
export function* addDestination(postDataBody, token, showCommonError = false) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_DESTINATIONS}`,
      postDataBody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Delete destination by id
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* deleteDestination(id, token, showCommonError = false) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_DESTINATIONS}/${id}`,
      token,
      {},
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 *
 * @param type
 * @returns {Promise<any>}
 */
export function* updateDestinationInformation(
  postbody,
  id,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      putData,
      `${API_URL.FETCH_DESTINATIONS}/${id}`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get retention dropdown option Destination edit view
 * @param type
 * @returns {*}
 */
export const getRetentionDropdown = async () => {
  try {
    const response = await fetch(`${API_URL.FETCH_RETENTION_DROPDOWNS}`);
    const data = await response.json();
    return data;
  } catch (e) {
    window.console.log(e);
  }
};

/**
 * To fetch the list of recovery points
 * @param type
 * @returns {*}
 */
export function* getRecoveryPoints(
  queryString,
  id,
  token,
  filterType,
  pollingAction = false
) {
  try {
    let response;
    // const response = yield call(getData, API_URL.FETCH_RECOVERY_POINTS, queryString);
    if (id && filterType === constant.DESTINATION_TYPE.CLOUD_DEDUPE_STORE) {
      response = yield call(
        getData,
        `${API_URL.FETCH_DESTINATIONS}/${id}/sources`,
        queryString,
        token,
        pollingAction
      );
    } else {
      response = yield call(
        getData,
        `${API_URL.FETCH_CLOUDVOLUME_RECOVERYPOINTS}`,
        queryString,
        token,
        pollingAction
      );
    }

    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Fetch recovery point for sources
 */
export function* getRecoveryPointsSources(
  queryString,
  token,
  filterType,
  source_id,
  pollingAction = false
) {
  try {
    let response;
    if (filterType !== constant.DESTINATION_TYPE.CLOUD_DIRECT) {
      response = yield call(
        getData,
        `${API_URL.FETCH_SOURCES}/${source_id}/recoverypoints`,
        queryString,
        token,
        pollingAction
      );
    } else {
      response = yield call(
        getData,
        `${API_URL.FETCH_CLOUDVOLUME_RECOVERYPOINTS}`,
        queryString,
        token,
        pollingAction
      );
    }

    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To add new sources
 * @param {*} data
 * @param {*} token
 * @returns {Promise<any>}
 */
export function* addNewSources(data, token, id) {
  try {
    const response = yield call(
      postData,
      `${API_URL.FETCH_HYPERVISOR_LIST}/${id}/vms/enablevms`,
      data,
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 *
 * @param type
 * @returns {Promise<any>}
 */
export function* updateRecoveryPinnedUnpinned(
  organization_id,
  recovery_point_id,
  token,
  pinUnpinStatus
) {
  try {
    // const data = yield call(putData, `${API_URL.FETCH_RECOVERY_POINTS}/${id}`, postbody);
    const data = yield call(
      postData,
      `${API_URL.FETCH_CLOUDVOLUME_RECOVERYPOINTS}/${pinUnpinStatus}?recovery_point_id=${recovery_point_id}&organization_id=${organization_id}`,
      {},
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get sources groups list from the BE API
 * @param queryString - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* fetchSourcesGroups(
  queryString,
  token,
  pollingAction,
  showLoader = true
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_SOURCES_GROUPS,
      queryString,
      token,
      pollingAction,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Create new source groups
 * @param body - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* createNewSourceGroup(body, token, showCommonError = false) {
  try {
    const data = yield call(
      postData,
      API_URL.FETCH_SOURCES_GROUPS,
      body,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Assign source group
 * @param queryString - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* assignSourcesGroups(id, body, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES_GROUPS}/${id}/members`,
      body,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * UnAssign source group
 * @param queryString - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* unassignSourcesGroups(id, body, token) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_SOURCES_GROUPS}/${id}/members`,
      token,
      body
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get policies/protection policy list from the BE API
 * this method is calling api with tspog BE URL
 * @param queryString - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* fetchAllSourceAdvancedSearchAPI(
  queryString,
  token,
  pollingAction,
  showLoader = true
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_ALL_ADVANCED_SEARCH_API,
      queryString,
      token,
      pollingAction,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchPolicies(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_POLICIES_BE,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchLightWeightPolicies(
  queryString,
  token,
  showLoader = true
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_LIGHTWEIGHT_POLICIES,
      queryString,
      token,
      false,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* fetchLightWeightSources(
  queryString,
  token,
  showLoader = true
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_LIGHTWEIGHT_SOURCES,
      queryString,
      token,
      false,
      false,
      showLoader
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * Get policies/protection policy list from the BE API
 * this method is calling api with tspog BE URL
 * for single as well as bulk
 * @param queryString - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* fetchPoliciesBySourceType(token, source_id, sourceArray) {
  try {
    if (source_id) {
      const data = yield call(
        getData,
        `${API_URL.FETCH_SOURCES}/${source_id}/type/policies`,
        "",
        token
      );
      return data;
    }
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/type/policies`,
      sourceArray,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * fetch datacenters list
 */

export function* fetchDataCentersList(token, query) {
  try {
    const data = yield call(getData, API_URL.FETCH_DATA_CENTER, query, token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get customer accounts list from the BE API
 * this method is calling api with tspog BE URL
 * @param queryString - queryString of the sources groups
 * @returns {Promise<any>}
 */
export function* fetchCustomerAccountsResult(
  queryString,
  token,
  organization_id,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/accounts`,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchSuspendedCustomerAccountsResult(
  token,
  organization_id,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/suspendedaccounts`,
      "",
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Delete Customer Accnt by id
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* deleteCustomerAccountApi(
  id,
  token,
  organization_id,
  showCommonError = true
) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/accounts/${id}`,
      token,
      {},
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Suspend Customer Accnt by id
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* suspendCustomerAccountApi(id, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/suspend`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Enable Customer Accnt by id
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export function* enableCustomerAccountApi(id, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}/resume`,
      {},
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get detail of specific Customer Accnt
 * @param id
 * @returns {Promise<any>}
 */
export function* fetchCustomerAccntDetailsById(
  parent_id,
  child_id,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${parent_id}/accounts/${child_id}`,
      "",
      token,
      false,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To add a customer account
 */
export function* addCustomerAccount(
  postDataBody,
  token,
  organization_id,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/accounts`,
      postDataBody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To add a customer account as submsp
 *  /organizations/{id}/submspaccounts
 */
export function* addCustomerAsSubMspAccount(
  postDataBody,
  token,
  organization_id,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/submspaccounts`,
      postDataBody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To edit a customer account - add the usage threshold
 */
export function* setUsageThreshold(
  postDataBody,
  token,
  organization_id,
  showCommonError = true
) {
  try {
    // const data = yield call(putData, `${API_URL.FETCH_CUSTOMER_ACCOUNTS_BE}/${postDataBody.id}`, postDataBody, token);
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/accounts/${postDataBody.id}/threshold`,
      postDataBody.data,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To destination details list
 */
export function* getDestMock(token) {
  try {
    const data = yield call(getData, API_URL.FETCH_DESTINATION_MOCK, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Protect customers list
 */
export function* getCustomersAssignedAdmin(
  queryString,
  token,
  id,
  parent_id,
  showCommonError = true,
  isCyclicRequired = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${parent_id}/accounts/${id}/assignadmins`,
      queryString,
      token,
      false,
      showCommonError
    );

    // fix for cyclic object value
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    if (isCyclicRequired) {
      return JSON.stringify(data, getCircularReplacer());
    }
    return data;
  } catch (e) {
    window.console.log(`Protect customer list error: ${e}`);
  }
}

export function* fetchAdminDetailsToAssign(
  queryString,
  token,
  organization_id
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/users?pagination=false`,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* resendEnrollEmail(
  postDataBody,
  token,
  organization_id,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organization_id}/enrollmentemail`,
      postDataBody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To update sources -> configuration
 * @param {*} data
 * @param {*} token
 * @returns {Promise<any>}
 */
export function* fetchSourceConfiguration(query, token) {
  try {
    const response = yield call(
      getData,
      API_URL.UPDATE_SOURCE_CONFIGURATION,
      query,
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To update sources -> configuration
 * @param {*} data
 * @param {*} token
 * @returns {Promise<any>}
 */
export function* editSourceConfiguration(data, id, token) {
  try {
    const response = yield call(
      putData,
      `${API_URL.UPDATE_SOURCE_CONFIGURATION}?source_id=${id}`,
      data,
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To add a addAssignedDataToCustomer
 */
export function* addAssignedDataToCustomer(
  parent_id,
  organization_id,
  postDataBody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${parent_id}/accounts/${organization_id}/assignadmins`,
      postDataBody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To udpate customer information data
 * @param data
 * @param id
 * @param token
 * @param parent_id
 * @returns {Promise<any>}
 */

export function* editCustomerInformation(
  postbody,
  id,
  token,
  parent_id,
  showCommonError = true
) {
  try {
    const data = yield call(
      putData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${parent_id}/accounts/${id}`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To unassign admins from customer list
 * @param id
 * @returns {Promise<any>}
 */
export function* unassignAdminFromCustomer(
  postbody,
  id,
  token,
  parent_id,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${parent_id}/accounts/${id}/unassignadmins`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to delete a source group
 */
export function* deleteSourceGroup(id, token, showCommonError = false) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_SOURCES_GROUPS}/${id}`,
      token,
      {},
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get recovered Vms
 */
export function* getRecoveredVMS(queryString, token, pollingAction) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_RECOVERED_VMS,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get Mounted RecoveryPoints
 */
export function* getMountedRecoveryPoints(orgId, token) {
  try {
    const queryString = `pagination=false&${orgId}`;
    const data = yield call(
      getData,
      API_URL.FETCH_MOUNTED_RECOVERY_POINTS,
      queryString,
      token,
      false /* pollingAction */,
      true /* showCommonError */
    );

    return data;
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * to get recovered VM by id
 */
export function* getRecoveredVMById(id, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_RECOVERED_VMS}/${id}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to get recovered VM by source id
 */
export function* getRecoveredVMBySourceId(id, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_RECOVERED_VMS_USING_SOURCE_ID.replace("{id}", id),
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle actions for a Recovered VM - deprovision/provision/start/restart/hardstop/gracefultstop
 */
export function* handleRecoveredVMActions(
  id,
  token,
  action = "",
  postBody = {}
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_RECOVERED_VMS}/${id}/${action}`,
      postBody,
      token,
      true /* showCommonError */
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * to delete a recoveredVM
 */
export function* deleteRecoveredVM(id, token) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.FETCH_RECOVERED_VMS}/${id}`,
      token,
      {}
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * to unmount RPS
 */

export function* unmountRPS(data, token) {
  try {
    return yield call(
      postData,
      API_URL.UNMOUNT_RECOVERY_POINTS,
      data,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * To stop failback for a Recovered VM
 */
export function* stopFailback(id, token, postBody = {}) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_RECOVERED_VMS}/${id}/stopfailback`,
      postBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To handle complete final failback step for a Recovered VM
 */
export function* startFinalFailbackStep(id, token, postBody = {}) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_RECOVERED_VMS}/${id}/startfinalfailbackstep`,
      postBody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * verify password for get Filbrowser data
 */
export function* verifyPasswordForGetFileBrowserDataAPI(
  recoveryPointId,
  sourceId,
  from,
  queryPostData,
  token,
  destination_source_id = ""
) {
  const encryptPasswordData = {
    encrypt_password: queryPostData.encrypt_password
  };
  if (from !== constant.SECONDARY_NAVIGATION_TYPE.SOURCES) {
    const data = yield call(
      postData,
      `${API_URL.FETCH_DESTINATIONS}/${sourceId}/sources/${destination_source_id}/recoverypoints/${recoveryPointId}/verifyPassword`,
      encryptPasswordData,
      token
    );
    return data;
  }
  const data = yield call(
    postData,
    `${API_URL.FETCH_SOURCES}/${sourceId}/recoverypoints/${recoveryPointId}/verifyPassword`,
    encryptPasswordData,
    token
  );
  return data;
}

/**
 * get Filbrowser data
 */
export function* getFileBrowserDataAPI(
  recoveryPointId,
  sourceId,
  from,
  queryPostData,
  token,
  destination_source_id = "",
  verifyPassword,
  makeQueryString
) {
  try {
    if (verifyPassword) {
      const responseData = yield call(
        verifyPasswordForGetFileBrowserDataAPI,
        recoveryPointId,
        sourceId,
        from,
        queryPostData,
        token,
        destination_source_id
      );
      return responseData;
    }
    const queryString = makeQueryString ? `?${makeQueryString}` : "";
    if (from !== constant.SECONDARY_NAVIGATION_TYPE.SOURCES) {
      const data = yield call(
        postData,
        `${API_URL.FETCH_DESTINATIONS}/${sourceId}/sources/${destination_source_id}/recoverypoints/${recoveryPointId}/items${queryString}`,
        queryPostData,
        token
      );
      return data;
    }
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${sourceId}/recoverypoints/${recoveryPointId}/items${queryString}`,
      queryPostData,
      token
    );
    return data;
  } catch (e) {
    window.console.log(`Protect customer list error: ${e}`);
  }
}

/** To post and set cookieauth in browser for restore CD
 * @param {*} source_id
 */
export function* setRestoreCookieauth(source_id, token) {
  try {
    const data = yield call(
      postCookieData,
      `${API_URL.RESTORE_COOKIEAUTH}?system_id=${source_id}`,
      {
        system_id: source_id
      },
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/** To get recovery image formats by
 * @param {*} source_id
 */
export function* getRecoveryImageFormatsAPI(source_id, query, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${source_id}/recoveryimageformats`,
      query,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/** To get recovery Targets by
 * @param {*} source_id
 */
export function* getRecoveryTargetsAPI(
  source_id,
  query,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${source_id}/recoverytargets`,
      query,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get download links
 */
export function* getDownloadLinks(organizationId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_BY_ORGANIZATIONS}/${organizationId}/agentdownloads`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/** To post and set cookieauth in browser for restore CD
 * @param {*} postActionData
 */
export function* restoreRecoverMachineApi(
  source_id,
  postActionData,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${source_id}/recover`,
      postActionData,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/** To post and set cookieauth in browser for vCenter, restore CD
 * @param {*} postActionData
 */
export function* restoreRecovervCenterApi(
  source_id,
  postActionData,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${source_id}/recovertovmware`,
      postActionData,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get chart data for selected destination - CD
 */
export function* getDestinationCD(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_DESTINATION_FOR_CHART,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get chart data for selected customer account - CD
 */
export function* getCustomerAccountCD(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_DESTINATION_FOR_CHART,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* setPolicyToSource(
  id,
  postbody,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.UPDATE_ASSINGN_POLICY}/${id}/sources`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Un assign policy from source by id
 * @param id
 * @param token
 * @param body
 * @returns {Promise<*>}
 */
export function* unSetPolicyToSource(id, token, body, showCommonError = false) {
  try {
    const data = yield call(
      deleteData,
      `${API_URL.UPDATE_ASSINGN_POLICY}/${id}/sources`,
      token,
      body,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/** To get ch rp on dest details
 * @param {*} source_id
 * @param {*} destination_id
 */
export function* getChRpOnDestDetailsApi(
  token,
  destination_id,
  source_id,
  queryString
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_DESTINATIONS}/${destination_id}/sources/${source_id}/recoverypoints`,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/** To post and get generated downdload link for restore CH
 * @param {*} postActionData
 */
export function* getGenerateDownloadRPApi(
  postActionData,
  source_id,
  recovery_point_id,
  destination_source_id,
  token
) {
  try {
    if (destination_source_id) {
      const data = yield call(
        postData,
        `${API_URL.FETCH_DESTINATIONS}/${source_id}/sources/${destination_source_id}/recoverypoints/${recovery_point_id}/generatelink`,
        postActionData,
        token
      );
      return data;
    }
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${source_id}/recoverypoints/${recovery_point_id}/generatelink`,
      postActionData,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * get chart data for selected customer account - CD
 */
export function* getCustomerAccountCH(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_CAPACITY_USAGE_CH_TREND_USAGE,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * API call to update the source
 * @param {*} id - source id
 * @param {*} data - form data
 * @param {*} token - token
 */
export function* putSource(id, data, token, showCommonError = false) {
  try {
    const resp = yield call(
      putData,
      `${API_URL.FETCH_SOURCES}/${id}/update`,
      data,
      token,
      showCommonError
    );
    return resp;
  } catch (error) {
    window.console.log(error);
  }
}

/* @param {*} id
 * @param {*} postbody
 * @param {*} token
 * to do provision for a source
 */
export function* sourceProvision(id, postbody, token, showCommonError = false) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/provision`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* @param {*} id
 * @param {*} postbody
 * @param {*} token
 * to do provision for a source
 */
export function* sourceCancelReplication(
  id,
  postbody,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/cancelreplicationin`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* @param {*} id
 * @param {*} postbody
 * @param {*} token
 * to do provision for a source
 */
export function* sourceCancelReplicationOut(
  id,
  postbody,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/cancelreplicationout`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* @param {*} id
 * @param {*} postbody
 * @param {*} token
 * to do provision for a source
 */
export function* startReplicationOut(id, postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/replicate`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* startReplication(id, postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/replicate`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* heartbeatPause(id, postbody, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/pauseheartbeat`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* heartbeatResume(id, postbody, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/resumeheartbeat`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* standByVM(id, postbody, token, showCommonError = true) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/snapshots`,
      "",
      token,
      false,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* snapshotStandByVMNetworkConfiguration(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/networkconfigurations`,
      "",
      token,
      false,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* shutdownVM(id, postbody, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/shutdown`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* powerOnVM(id, postbody, token, showCommonError = true) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/poweron`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* virtualStandbyPause(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/virtualstandby/pause`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* virtualStandbyResume(id, token, showCommonError = true) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${id}/virtualstandby/pendingsessions`,
      "",
      token,
      false,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* virtualStandbyResumeSmartCopy(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/virtualstandby/resume`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* standByVMNetworkConfiguration(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/networkconfigurations`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* backupPasswordsList(id, token, showCommonError = true) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${id}/sessionpasswords`,
      "",
      token,
      false,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* standByVMGetNetworkConfiguration(
  id,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/networkconfigurations`,
      "",
      token,
      false,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* standByVMPostNetworkConfiguration(
  id,
  postbody,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/standbyvm/networkconfigurations`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* convertToAgentless(id, postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/convert_to_agentless`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* deploy(id, postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/deploy`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
/* @param {*} id
 * @param {*} postbody
 * @param {*} token
 * to do provision for a source
 */
export function* fetchLicenses(organization_id, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_LICENSES}${organization_id}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchEntitlements(orgId, token, pollingAction = false) {
  try {
    return yield call(
      getData,
      `${API_URL.FETCH_LICENCE_DETAIL}/${orgId}/entitlements`,
      "",
      token,
      pollingAction
    );
  } catch (e) {
    window.console.error(e);
  }
}
export function* fetchPolicy(policyId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_POLICY}/${policyId}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createPolicy(policy, token) {
  try {
    const data = yield call(postData, `${API_URL.FETCH_POLICY}`, policy, token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* updatePolicy(policyId, policy, token, showCommonError) {
  const data = yield call(
    putData,
    `${API_URL.FETCH_POLICY}/${policyId}`,
    policy,
    token,
    showCommonError
  );
  try {
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervisor(hypvervisorId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_HYPVERVISOR}/${hypvervisorId}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* updateHypervisor(hypvervisorId, hypervisor, token) {
  try {
    const data = yield call(
      putData,
      `${API_URL.FETCH_HYPVERVISOR}/${hypvervisorId}`,
      hypervisor,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* hypervisorUpgradeAgent(hypvervisorId, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_HYPVERVISOR}/${hypvervisorId}/upgradeagent`,
      undefined,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* hypervisorRefreshVms(hypvervisorId, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_HYPVERVISOR}/${hypvervisorId}/refreshvms`,
      undefined,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/* @param {*} postbody
 * @param {*} token
 * to do provision for a source
 */
export function* postRemotePoliciesApi(postbody, token) {
  try {
    const data = yield call(
      postData,
      `${API_URL.POST_REMOTE_POLICIES}`,
      postbody,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function getSite(token) {
  // TODO use getData for API call insted fetch()
  let res;
  return fetch(`${API_URL.HOST_BE_URL}sites`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => {
      res = response.data;
      return res;
    });
}
export function* postWindowsSourceList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.WINDOWS_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postOracleWindowsSourceList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ORACLE_WINDOWS_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postOracleLinuxSourceList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ORACLE_LINUX_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postOracleWindowsDBList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ORACLE_WINDOWS_DB_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getOracleWindowsDBList(data, token) {
  try {
    const result = yield call(
      getData,
      `${API_URL.FETCH_SOURCES}/${data}/${API_URL.ORACLE_DB_OPTIONS_URL}`,
      "",
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addRemoteConsoleData(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ADD_REMOTE_CONSOLE_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* createNewSiteData(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.CREATE_SITE}`,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* addSources(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.SOURCES_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postWindowsSourceAddSource(data) {
  try {
    const result = yield call(
      postData,
      `${API_URL.MOCK_HOST_URL + API_URL.WINDOWS_ADD_SOURCE_URL}`,
      data
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postLinuxSourceList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.LINUX_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postLinuxSourceAddSource(data) {
  try {
    const result = yield call(
      postData,
      `${API_URL.MOCK_HOST_URL + API_URL.LINUX_ADD_SOURCE_URL}`,
      data
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postLinuxBackupServerSourceSaveToList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL + API_URL.LINUX_BACKUP_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postLinuxBackupServerSourceAddSource(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL + API_URL.ADD_SOURCES_LIST}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postAddActiveDirectoryToList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL + API_URL.DISCOVERIES_SOURCES_ADD_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postBrowseActiveDirectoriesToList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL}${API_URL.DISCOVERIES_SOURCES_ADD_URL}/${data.entry_id}${API_URL.BROWSE_ACTIVE_DIRECTORIES_URL}`,
      {},
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* getDisoverSourceName() {
  try {
    const data = yield call(getData, `${API_URL.DISCOVER_GET_SOURCES}`);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postVerifyCredential(data) {
  try {
    const result = yield call(
      postData,
      `${API_URL.DISCOVER_SOURCES_VERIFY_CONNECT}`,
      data
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postDiscoverSourceAddSource(data) {
  try {
    const result = yield call(
      postData,
      `${API_URL.DISCOVER_SOURCES_ADD_TO_SPOG}`,
      data
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Post call to connect to VMware vCenter/ESX
 * @param {*} data
 * @param string token
 */
export function* postHypervisorVmwareConnect(
  data,
  token,
  showCommonError = false
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.VMWARE_CONNECT_URI,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Post call to vmware/connect API with two type of authentication data first is only hypervisor_id  and second is site_id, host/server, port, username, password, inventory_type.
 * @param {*} data
 * @param string token
 */
export function* postHypervisorVmwareConnectV2(
  data,
  token,
  showCommonError = false
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.VMWARE_CONNECT_URI_V2,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postHypervisorVmwareConnectV3(
  data,
  token,
  showCommonError = false
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.VMWARE_CONNECT_URI_V3,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* browseSharedFolders(data, token) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.BROWSE_SHARED_FOLDERS_DESTINATIONS,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createFileBrowseFolder(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.CREATE_FILE_BROWSE_FOLDER,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* createFileBrowseFolderHyperV(
  data,
  token,
  showCommonError = true
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.CREATE_FILE_BROWSE_FOLDER_HYPERV,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createFileBrowseFolderLinux(
  data,
  token,
  showCommonError = true
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.CREATE_FILE_BROWSE_FOLDER_LINUX,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* createFileBrowseFolderHyperVV2(
  data,
  token,
  showCommonError = true
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.CREATE_FILE_BROWSE_FOLDER_HYPERV_V2,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * Post call to add sources to Hypervisor
 * @param {*} data
 * @param string token
 */
export function* postHypervisorAddSources(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL}/${API_URL.SOURCES_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postHypervisorAddSourcesContainer(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL}/${API_URL.SOURCES_CONTAINER_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postHypervisorAddSourcesPoliciesContainer(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.BE_HOST_URL}/${API_URL.SOURCES_CONTAINER_POLICIES_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* postHypervisorHyperVConnect(data, token) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.HYPERV_CONNECT_URI,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

// export function* postHypervisorHyperVConnectV2(data, token) {
//   try {
//     const result = yield call(
//       postData,
//       //API_URL.BE_HOST_URL + API_URL.HYPERV_CONNECT_URI_V2,
//       data,
//       token
//     );
//     return result;
//   } catch (e) {
//     window.console.log(e);
//   }
// }

export function* postHypervisorHyperVConnectV3(data, token) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.HYPERV_CONNECT_URI_V3,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postHypervisorHyperVConnectV4(data, token) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.HYPERV_CONNECT_URI_V4,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchDestinationPath(token, organization_id) {
  try {
    const result = yield call(
      getData,
      API_URL.BE_HOST_URL + API_URL.SHARED_FOLDER_LIST,
      `organization_id=${organization_id}&pagination=false`,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postSharedFolderDestinationConnect(
  data,
  token,
  showCommonError = true
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.SHARED_FOLDER_LIST,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postAuthenticateSharedFolder(
  data,
  token,
  showCommonError = true
) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.AUTHENTICATE_SHARED_FOLDER,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postPolicyHyperVConnect(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.HYPERV_URI,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postPolicyVmwareConnect(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.BE_HOST_URL + API_URL.VMWARE_URI,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postPolicyVmwareEsxNodes(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.GET_ESX_SERVERS,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postHypervisorNutanixonnect(data, token) {
  try {
    const result = yield call(
      postData,
      API_URL.CONNECT_HYPERVISOR_NUTANIX,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchOrganizationInfo(id, token) {
  try {
    const url = `${API_URL.FETCH_BY_ORGANIZATIONS}/${id}`;
    const data = yield call(getData, url, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSites(page, pageSize, orgId, token, all, filter) {
  try {
    let queryString = all
      ? `pagination=false&organization_id=${orgId}`
      : `page=${page}&page_size=${pageSize}&organization_id=${orgId}`;
    queryString = filter ? `${queryString}&${filter}` : queryString;
    const result = yield call(getData, API_URL.FETCH_SITES, queryString, token);
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* getRemotePolicies(token, payload, orgId) {
  try {
    const result = yield call(
      getData,
      API_URL.FETCH_REMOTE_POLICIES,
      `site_id=${payload.id}&organization_id=${orgId}`,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getActiveDirectory(orgId, token) {
  try {
    const result = yield call(
      getData,
      API_URL.FETCH_ACTIVE_DIRECTORIES,
      `pagination=false&organization_id=${orgId}`,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRegisteredSites(queryString, token, showLoader = true) {
  try {
    const result = yield call(
      getData,
      API_URL.FETCH_SITES,
      queryString,
      token,
      false,
      false,
      showLoader
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Protect > Destinations > Cloud Account > Add a Cloud Account (POST API)
 * @param {*} data
 * @param {*} token
 */

export function* createCloudAccount(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.CREATE_CLOUD_ACCOUNT_URL}`,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Protect > Destinations > Cloud Account > Edit a Cloud Account (PUT API)
 * @param {*} id
 * @param {*} updatedData
 * @param {*} token
 */
export function* updateCloudAccount(
  id,
  updatedData,
  token,
  showCommonError = true
) {
  try {
    const data = yield call(
      putData,
      `${API_URL.UPDATE_CLOUD_ACCOUNT_URL}/${id}`,
      updatedData,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * Get Vmware server type by sending connection details
 * @param {*} data
 * @param {*} token
 */
export function* getVmwareServerType(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.VMWARE_SERVER_TYPE}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRecoveryPointServers(queryString, token) {
  try {
    const result = yield call(
      getData,
      API_URL.FETCH_RECOVERY_POINT_SERVERS,
      queryString,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBackupTrafficUsingRPSID(id, token) {
  try {
    const result = yield call(
      getData,
      `${API_URL.GET_RPS}/${id}/${API_URL.NETWORKS}`,
      "",
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRestoreTrafficUsingRPSID(id, token) {
  try {
    const result = yield call(
      getData,
      `${API_URL.GET_RPS}/${id}/${API_URL.NETWORKS}`,
      "",
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRestoreTrafficUsingProxyID(id, token) {
  try {
    const result = yield call(
      getData,
      `${API_URL.FETCH_BACKUP_PROXY}/${id}/${API_URL.NETWORKS}`,
      "",
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAllHypervisorsList(token) {
  try {
    const result = yield call(
      getData,
      `${API_URL.FETCH_HYPERVISOR_LIST}?pagination=false`,
      "",
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAllHypervisorConfiguration(id, payload, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.GET_HYPERV_CONFIGURATION}`,
      payload,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAllVMHypervisorConfiguration(id, payload, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.GET_VMWARE_CONFIGURATION}`,
      payload,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* getAllProxiesList(token, queryString) {
  try {
    const result = yield call(
      getData,
      `${API_URL.FETCH_BACKUP_PROXY}`,
      queryString,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getRPSDataStores(queryString, token) {
  try {
    const result = yield call(
      getData,
      API_URL.GET_DATA_STORES,
      queryString,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBackupProxy(queryString, token) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_BACKUP_PROXY,
      queryString,
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}
export function* getLBS(orgId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_LBS}&organization_id=${orgId}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getLinux(orgId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_LINUX}&organization_id=${orgId}`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getLinuxLBS(orgId, token) {
  try {
    const data = yield call(
      getData,
      `${API_URL.FETCH_LINUX_LBS}&organization_id=${orgId}&is_remote=false`,
      "",
      token
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSelectedNetworkBackupProxy(data, token) {
  try {
    const response = yield call(
      getData,
      `${API_URL.FETCH_BACKUP_PROXY}/${data}/${API_URL.NETWORKS}`,
      "",
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSelectedLBSNetworks(id, token) {
  try {
    const response = yield call(
      getData,
      API_URL.GET_SELECTED_LBS_NETWORKS.replace("{id}", id),
      "",
      token
    );
    return response;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createBackupProxy(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      `${API_URL.CREATE_BACKUP_PROXY}`,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createLinuxBackupProxy(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.CREATE_LINUX_BACKUP_PROXY}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createLinuxBackupServer(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.CREATE_LINUX_BACKUP_SERVER}`,
      data,
      token,
      true /* showCommonError */
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* preflightVmwareCheck(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.PREFLIGHT_VMWARE_CHECK}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addUncOrNfsPaths(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.ADD_UNC_OR_NFS_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postUncOrNfsPathList(data, token) {
  try {
    const result = yield call(
      postData,
      `${API_URL.HOST_BE_URL + API_URL.UNC_OR_NFS_CONNECT_URL}`,
      data,
      token
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* installUpdateUpgradeAgent(
  token,
  data,
  id,
  showCommonError = false
) {
  try {
    const result = yield call(
      postData,
      API_URL.HOST_BE_URL +
        API_URL.INSTALL_UPDATE_UPGRADE_AGENT.replace("id", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}
export function* installUpdateUpgradeAgentBulk(
  token,
  data,
  id,
  showCommonError = false
) {
  try {
    const result = yield call(
      postData,
      API_URL.HOST_BE_URL + API_URL.INSTALL_UPDATE_UPGRADE_AGENT_BULK,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* cancelSourceScheduledDeploy(
  id,
  postbody,
  token,
  showCommonError = false
) {
  try {
    const data = yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/${id}/canceldeploy`,
      postbody,
      token,
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* cancelSourceScheduledDeployBulk(
  data,
  token,
  query,
  showCommonError = false
) {
  try {
    return yield call(
      postData,
      `${API_URL.FETCH_SOURCES}/canceldeploy`,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* addDestinationHost(proxyData, token) {
  try {
    const res = yield call(
      postData,
      API_URL.CREATE_BACKUP_PROXY,
      proxyData,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* addDestinationHostLinux(proxyData, token) {
  try {
    const res = yield call(
      postData,
      API_URL.CREATE_BACKUP_SOURCE_LINUX,
      proxyData,
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postRecovery(id, data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.WINDOWS_START_RECOVERY.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* linuxPostRecovery(id, data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.START_RECOVERY.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* oraclePostRecovery(id, data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.ORACLE_START_RECOVERY.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createIVM(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.CREATE_IVM,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchEsxServers(token, data, showCommonError = true) {
  try {
    const {
      proxy_id,
      username,
      password,
      server_name,
      protocol,
      port,
      site_id
    } = data;
    const payload = {
      proxy_id: proxy_id || "",
      vmware: {
        username: username || "",
        password: password || "",
        server_name: server_name || "",
        protocol: protocol || "",
        port: port || ""
      },
      site_id: site_id || ""
    };
    const res = yield call(
      postData,
      API_URL.GET_ESX_SERVERS,
      payload,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* postConnectProxy(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      `${API_URL.CREATE_BACKUP_PROXY}`,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateLinuxSchedule(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.LINUX_VALIDATE_TIME,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchLinuxScripts(data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.LINUX_FETCH_SCRIPTS,
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* generateDownloadLink(id, data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.GENERATE_DOWNLOAD_LINK.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* generateDownloadLinkLinux(
  id,
  data,
  token,
  showCommonError = true
) {
  try {
    const result = yield call(
      postData,
      API_URL.LINUX_GENERATE_DOWNLOAD_LINK.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateRecoveryPointItems(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.RECOVER_RP_ITEMS_VALIDATE.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateSQLItems(data, id, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.SQL_ITEMS_VALIDATE.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getVolumesForRecoveryPoint(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.FETCH_SOURCES_RP_VOLUMES.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * This method is used to send the single request of starting|stoping|restarting|delete the VM (Disaster Recovery > Instant VMs)
 * @param {*} id
 * @param {*} action
 * @param {*} token
 * @param {*} showCommonError
 */
export function* singleActionOnVM(id, action, token, showCommonError = true) {
  try {
    let result = null;
    if (action !== constant.CONTEXTUAL_ACTION.DELETE) {
      if (
        action === constant.CONTEXTUAL_ACTION.PAUSE_AUTO_RECOVERY ||
        action === constant.CONTEXTUAL_ACTION.RESUME_AUTO_RECOVERY
      ) {
        result = yield call(
          postData,
          API_URL.SINGLE_ACTION_PAUSE_RESUME_VM.replace("{id}", id),
          {},
          token,
          showCommonError
        );
      } else {
        result = yield call(
          postData,
          API_URL.SINGLE_ACTION_ON_VM.replace("{id}", id).replace(
            "{action}",
            action
          ),
          {},
          token,
          showCommonError
        );
      }
    } else {
      result = yield call(
        deleteData,
        API_URL.SINGLE_ACTION_DELETE_VM.replace("{id}", id),
        token,
        "",
        showCommonError
      );
    }
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateRecoveryPointVM(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.RECOVER_FILE_FOLDER_VALIDATE_VM.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateRecoveryPointLinuxVM(
  data,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.RECOVER_FILE_FOLDER_VALIDATE_LINUX_VM,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervisorInfo(data, id, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.FETCH_DESTINATION_HYPERVISORS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* verifyEncryptionPassword(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.VALIDATE_ENCRYPTION_PASSWORD.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* backupPasswordsData(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.BACKUP_PASSWORDS_DATA,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* collectDiagnosticData(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.COLLECT_DIAGNOSTIC_DATA,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateRecoveryPoint(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.VALIDATE_RECOVERY_POINT.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateRecoveryPointConfiguration(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.VALIDATE_RECOVERY_POINT_CONFIGURATION.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getItemsForRecoveryPoint(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.FETCH_SOURCES_RECOVERYPOINTS_ITEMS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getItemsForRecoveryPointLinux(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.LINUX_FETCH_RECOVERYPOINTS_ITEMS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSQLDBDetailsForRecoveryPoint(
  data,
  id,
  token,
  showCommonError = true
) {
  try {
    return yield call(
      postData,
      API_URL.FETCH_SOURCES_SQLDBDETAILS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSQLDBInstancesForRecoveryPoint(id, token) {
  const composedURL = API_URL.GET_SQL_INSTANCES.replace("{id}", id);
  try {
    const data = yield call(getData, composedURL, "", token);
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * This method is used to send the bulk request of starting|stoping|restarting|delete the VM (Disaster Recovery > Instant VMs)
 * @param {*} ids
 * @param {*} action
 * @param {*} token
 * @param {*} showCommonError
 */
export function* bulkActionOnVM(ids, action, token, showCommonError = true) {
  try {
    let result = null;
    if (action !== constant.CONTEXTUAL_ACTION.DELETE) {
      result = yield call(
        postData,
        API_URL.BULK_ACTION_ON_VM.replace("{action}", action),
        ids,
        token,
        showCommonError
      );
    } else {
      result = yield call(
        deleteData,
        API_URL.BULK_ACTION_ON_VM.replace("{action}", action),
        token,
        ids,
        showCommonError
      );
    }
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getBackupVmDisksList(id, data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.RECOVERY_BACKUP_VM_DISKS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getVMNetworksList(id, data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.VM_NETWORK_ADAPTERS.replace("{id}", id),
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}
export function* getNutanixStorages(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.NUTANIX_STORAGE,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getNutanixNetworks(data, token, showCommonError = true) {
  try {
    return yield call(
      postData,
      API_URL.NUTANIX_NETWORKS,
      data,
      token,
      showCommonError
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* getHypervisorsListWithFilter(
  queryString,
  token,
  pollingAction = false
) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_HYPERVISOR_LIST,
      queryString,
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* connectHypervVMpath(id, data, token) {
  try {
    return yield call(
      postData,
      API_URL.CONNECT_TO_HYPERV_VM_PATH.replace("{id}", id),
      data,
      token,
      true /* showCommonError */
    );
  } catch (e) {
    window.console.log(e);
  }
}

export function* fullVMRecovery(id, data, token, showCommonError = true) {
  try {
    const result = yield call(
      postData,
      API_URL.RECOVERY_FULL_VM.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return result;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchVmwareDatastores(token, data, showCommonError = true) {
  try {
    const payload = { ...data };
    const res = yield call(
      postData,
      API_URL.GET_VMWARE_DATASTORES,
      payload,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchVmwareStoragepolicies(
  token,
  data,
  showCommonError = true
) {
  try {
    const payload = { ...data };
    const res = yield call(
      postData,
      API_URL.GET_VMWARE_STORAGE_POLICIES,
      payload,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervisorType(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.GET_HYPERVISOR_TYPE,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateHypervisorHyperv(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.VALIDATE_HYPERVISOR_HYPERV,
      data,
      token,
      showCommonError,
      "",
      10 /* error dismiss time */
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateDisk(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.VALIDATE_DISK,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateHypervisorVMware(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.VALIDATE_HYPERVISOR_VMWARE,
      data,
      token,
      showCommonError,
      "",
      10 /* error dismiss time */
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}
export function* validateHypervisorNutanix(
  data,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.VALIDATE_HYPERVISOR_NUTANIX,
      data,
      token,
      showCommonError,
      "",
      10 /* error dismiss time */
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getSelectedSourceHypervisorType(
  sourceId,
  data,
  token,
  showCommonError = false
) {
  try {
    const res = yield call(
      postData,
      API_URL.GET_HYPERVISOR_TYPES_V2.replace("{id}", sourceId),
      data,
      token,
      showCommonError,
      "",
      10 /* error dismiss time */
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getHypervisorType(
  sourceId,
  data,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.GET_HYPERVISOR_TYPES_V2.replace("{id}", sourceId),
      data,
      token,
      showCommonError,
      "",
      10 /* error dismiss time */
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getMountPointInfo(
  id,
  organization_id,
  source_id,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      getData,
      API_URL.FETCH_MOUNTPOINT_INFO,
      `organization_id=${organization_id}${
        id === -1 ? `&source_id=${source_id}` : `&recovery_point_id=${id}`
      }`,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchHypervNetworks(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.FETCH_NETWORKS_FOR_HYPERV,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchVmwareNetworks(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.FETCH_NETWORKS_FOR_VMWARE,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchDefaultVmFolder(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.FETCH_DEFAULT_VM_FOLDER,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* fetchDefaultVhdFolder(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.FETCH_DEFAULT_VHD_FOLDER,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getProxyById(data, token, pollingAction = false) {
  try {
    const res = yield call(
      getData,
      `${API_URL.FETCH_BACKUP_PROXY}/${data}`,
      "",
      token,
      pollingAction
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createVmwareForIVM(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.HYPERVISOR_IVM_VMWARE,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createHyperVForIVM(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.HYPERVISOR_IVM_HYPERV,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createVmwareForIVMv2(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.HYPERVISOR_IVM_VMWARE_v2,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createHyperVForIVMv2(data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.HYPERVISOR_IVM_HYPERV_v2,
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* createNutanixForIVM(data, token, showCommonError = true) {
  try {
    const res = yield call(
      getData,
      API_URL.HYPERVISOR_IVM_NUTANIX.replace("{id}", data?.hypervisor_id),
      "",
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}
export function* getNutanixClusterForPolicy(
  data,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_NUTANIX_CLUSTER_DETAILS_FOR_POLICY.replace(
        "{id}",
        data?.hypervisor_id
      ),
      "",
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * The API to get all sources from source type = agentless_vm
 * @param type - Type of the sources
 * @returns {Promise<any>}
 */
export function* fetchAllSources(token, pollingAction = false) {
  try {
    const data = yield call(
      getData,
      API_URL.FETCH_SOURCES,
      "source_type=agentless_vm&pagination=false",
      token,
      pollingAction
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* validateOraclePath(token, id, data, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.VALIDATE_ORACLE_PATH.replace("{id}", id),
      data,
      token,
      showCommonError
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* submitVM(sourceId, data, token, showCommonError = true) {
  try {
    const res = yield call(
      postData,
      API_URL.SUBMIT_VIRTUAL_STANDBY.replace("{id}", sourceId),
      data,
      token,
      showCommonError,
      "",
      10 /* error dismiss time */
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getAntiVirusDetails(sourceId, token) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_ANTIVIRUS_DETAILS_FOR_ASSURED_SECURITY_SCAN.replace(
        "{id}",
        sourceId
      ),
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* terminateStandbyVM(id, token, showCommonError = false) {
  try {
    const data = yield call(
      deleteData,
      API_URL.TERMINATE_STANDBY_VM.replace("{id}", id),
      token,
      {},
      showCommonError
    );
    return data;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getReplicationTasksForSource(sourceId, token) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_REPLICATION_TASKS_FOR_SOURCE.replace("{id}", sourceId),
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getReplicationTasksForPolicy(policyId, token) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_REPLICATION_TASKS_FOR_POLICY.replace("{id}", policyId),
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* runAdhocReplicationForPolicy(
  id,
  data,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.RUN_ADHOC_REPLICATION_FOR_POLICY.replace("{id}", id),
      data,
      token,
      showCommonError,
      ""
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* runAdhocReplicationForSource(
  id,
  data,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.RUN_ADHOC_REPLICATION_FOR_SOURCE.replace("{id}", id),
      data,
      token,
      showCommonError,
      ""
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* downloadAssuredSecurityScanDetails(
  data,
  token,
  showCommonError = true
) {
  try {
    const res = yield call(
      postData,
      API_URL.DOWNLOAD_ASSURED_SECURITY_SCAN_DETAILS,
      data,
      token,
      showCommonError,
      ""
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}

export function* getDestinationInfo(dataStoreId, sourceId, token) {
  try {
    const res = yield call(
      getData,
      API_URL.GET_DESTINATION_INFO.replace("{id}", dataStoreId).replace(
        "{sourceId}",
        sourceId
      ),
      "",
      token
    );
    return res;
  } catch (e) {
    window.console.log(e);
  }
}
