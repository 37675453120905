import { createSelector } from "reselect";

import _ from "lodash";
import { getLogin, getEntitlementsProducts } from "state/selectors";

// selectors
const site = state => state.reducer.site;
const alert = state => state.reducer.alert;
const monitorView = state => state.reducer.monitorView;
const customer = state => state.reducer.customer;
const primaryNavigation = state => state.reducer.primaryNavigation;
const secondaryNavigation = state => state.reducer.secondaryNavigation;
const collapseSideBar = state => state.reducer.collapseSideBar;
const containerWidth = state => state.reducer.containerWidth;
const primaryKey = state => state.reducer.primaryKey;
const secondaryKey = state => state.reducer.secondaryKey;
const parentKey = state => state.reducer.parentKey;
const resourceName = state => state.reducer.resourceName;
const croppedImageURL = state => state.reducer.croppedImageURL;
const imageURL = state => getLogin(state).userDetails.image_url;
const apiResponse = state => state.reducer.apiResponse;
const orgType = state => getLogin(state).organization_type;
const isSubMSP = state => getLogin(state).sub_msp;
export const gridCurrentPage = state => state.reducer.gridCurrentPage;
export const gridPageSize = state => state.reducer.gridPageSize;
const isImpersonationView = state => state.reducer.isImpersonationView;
const customerOrgId = state => state.reducer.customerOrgId;
const customerOrgName = state => state.reducer.customerOrgName;
const branding = state => state.configureReducer.brandData;
const brandingLogo = state => state.configureReducer.logoImgData;
const isBrandingLogo = state => state.configureReducer.brandingLogo;
const organizationType = state => state.login.organization_type;

// reselect functions
export const makeSiteState = () => createSelector([site], site => site);
export const makeAlertState = () => createSelector([alert], alert => alert);
export const makeOrganizationTypeState = () =>
  createSelector([organizationType], organizationType => organizationType);
export const makeMonitorViewState = () =>
  createSelector([monitorView], monitorView => monitorView);
export const makeCustomerState = () =>
  createSelector([customer], customer => customer);
export const makePrimaryNavigationState = () =>
  createSelector([primaryNavigation], primaryNavigation => primaryNavigation);
export const makeSecondaryNavigationState = () =>
  createSelector(
    [secondaryNavigation],
    secondaryNavigation => secondaryNavigation
  );
export const makeCollapseSidebarState = () =>
  createSelector([collapseSideBar], collapseSideBar => collapseSideBar);
export const makeContainerWidthState = () =>
  createSelector([containerWidth], containerWidth => containerWidth);
export const makePrimKeyaryState = () =>
  createSelector([primaryKey], primaryKey => primaryKey);
export const makeSecondaryKeyState = () =>
  createSelector([secondaryKey], secondaryKey => secondaryKey);
export const makeParentKeyState = () =>
  createSelector([parentKey], parentKey => parentKey);
export const makeResourceNameState = () =>
  createSelector([resourceName], resourceName => resourceName);
export const makeCroppedImageURLState = () =>
  createSelector([croppedImageURL], croppedImageURL => croppedImageURL);
export const makeImageURLState = () =>
  createSelector([imageURL], imageURL => imageURL);
export const makeApiResponseState = () =>
  createSelector([apiResponse], apiResponse => apiResponse);
export const makeOrgTypeState = () =>
  createSelector([orgType], orgType => orgType);
export const makeIsSubMSPState = () =>
  createSelector([isSubMSP], isSubMSP => isSubMSP);
export const makeGridCurrentPageState = () =>
  createSelector([gridCurrentPage], gridCurrentPage => gridCurrentPage);
export const makeGridPageSizeState = () =>
  createSelector([gridPageSize], gridPageSize => gridPageSize);
export const makeImpersonationViewState = () =>
  createSelector(
    [isImpersonationView],
    isImpersonationView => isImpersonationView
  );
export const makeCustomerOrgIdState = () =>
  createSelector([customerOrgId], customerOrgId => customerOrgId);
export const makeCustomerOrgNameState = () =>
  createSelector([customerOrgName], customerOrgName => customerOrgName);
export const makeBrandingState = () =>
  createSelector([branding], branding => branding);
export const makeBrandingLogoState = () =>
  createSelector([brandingLogo], brandingLogo => brandingLogo);
export const makeIsBrandingLogoState = () =>
  createSelector([isBrandingLogo], isBrandingLogo => isBrandingLogo);

//MenuFilter for different cases
const menuFilter = newMenu => {
  if (newMenu.name === "Analyze") {
    return newMenu.subMenu.filter(
      smc => smc && smc.key !== "policy_tasks" && smc.key !== "restore_jobs"
    );
  }
  return newMenu.subMenu;
};

const menuFilterUDP = (state, newMenu) => {
  let entitlements = getEntitlementsProducts(state);
  let udpEnabled = _.get(entitlements, "udp.enable", false);
  let chEnabled = _.get(entitlements, "cloudhybrid.enable", false);
  if (!chEnabled && !udpEnabled) {
    newMenu.subMenu = newMenu.subMenu.filter(sm => {
      if (
        sm.key === "source_discovery" ||
        sm.key === "settings" ||
        sm.parentKey === "settings" ||
        sm.key === "default_deployment" ||
        sm.key === "mounted_recovery_points" ||
        sm.key === "virtual_standbys" ||
        sm.key === "storage_arrays" ||
        sm.key === "proxies" ||
        sm.key === "oracle_hosts" ||
        sm.key === "instant_vms_rr" ||
        sm.key === "recovery_point_report" ||
        (sm.key === "unc_nfs_paths" && sm.parentKey === "sources")
      ) {
        return false;
      }
      return true;
    });
  }
  return newMenu;
};

//Get Menu as per logged in user type.
const getMenu = state => {
  const loginData = getLogin(state);
  const getEntitlementsProductsData = getEntitlementsProducts(state);
  if (loginData.organization_type === "msp") {
    let mspMenu = state.reducer.menu.map(m => {
      let newMenu = Object.assign({}, m); // copies element
      newMenu.subMenu = newMenu.subMenu.filter(
        sm => sm.includeMSPMenu === true
      );
      newMenu.subMenu = menuFilter(newMenu);
      return newMenu;
    });
    if (
      [
        "msp_account_recovery_admin",
        // "msp_account_admin",
        "msp_recovery_admin"
      ].indexOf(loginData.role_id) > -1
    ) {
      mspMenu = mspMenu.filter(mc => mc.key !== "Configure");
    } else if (
      loginData.role_id === "msp_admin" ||
      loginData.role_id === "msp_account_admin"
    ) {
      mspMenu.map(
        mc =>
          (mc.subMenu = mc.subMenu.filter(
            smc => smc.key !== "infrastructure" && smc.key !== "proxies"
          ))
      );
    }
    if (loginData.sub_msp) {
      mspMenu.map(
        mc =>
          (mc.subMenu = mc.subMenu.filter(
            smc => smc.key !== "license_subscriptions"
          ))
      );
    }
    return mspMenu;
  } else if (loginData.organization_type === "msp_child") {
    return state.reducer.menu.map(mc => {
      let newMenu = Object.assign({}, mc); // copies element
      newMenu.subMenu = newMenu.subMenu.filter(
        smc =>
          smc.key !== "license_subscriptions" &&
          smc.key !== "branding" &&
          smc.key !== "account_details"
      );
      if (
        state.configureReducer.licenseDetails === undefined ||
        (state.configureReducer.licenseDetails &&
          state.configureReducer.licenseDetails.clouddirect &&
          state.configureReducer.licenseDetails.clouddirect.draas &&
          !state.configureReducer.licenseDetails.clouddirect.draas.enable &&
          !state.configureReducer.licenseDetails.clouddirect.draas.configured)
      ) {
        newMenu.subMenu = newMenu.subMenu.filter(
          smc => smc.key !== "network_configuration"
        );
      }
      if (
        getEntitlementsProductsData &&
        getEntitlementsProductsData.clouddirect &&
        getEntitlementsProductsData.clouddirect.enable === false
      ) {
        newMenu.subMenu = newMenu.subMenu.filter(
          smc => smc.key !== "recovered_vms"
        );
      }
      if (
        newMenu.key === "Configure" ||
        newMenu.key === "Protect" ||
        newMenu.key === "Analyze"
      ) {
        newMenu = menuFilterUDP(state, newMenu);
      }
      newMenu.subMenu = menuFilter(newMenu);
      return newMenu;
    });
  } else if (loginData.organization_type === "direct") {
    return state.reducer.menu.map(mc => {
      let newMenu = Object.assign({}, mc); // copies element
      newMenu.subMenu = newMenu.subMenu.filter(
        smc => smc && smc.key !== "account_details"
      );
      if (
        state.configureReducer.licenseDetails === undefined ||
        (state.configureReducer.licenseDetails &&
          state.configureReducer.licenseDetails.clouddirect &&
          state.configureReducer.licenseDetails.clouddirect.draas &&
          !state.configureReducer.licenseDetails.clouddirect.draas.enable &&
          !state.configureReducer.licenseDetails.clouddirect.draas.configured)
      ) {
        newMenu.subMenu = newMenu.subMenu.filter(
          smc => smc && smc.key !== "network_configuration"
        );
      }
      if (
        getEntitlementsProductsData &&
        getEntitlementsProductsData.clouddirect &&
        getEntitlementsProductsData.clouddirect.enable === false
      ) {
        newMenu.subMenu = newMenu.subMenu.filter(
          smc => smc.key !== "recovered_vms"
        );
      }
      if (
        newMenu.key === "Configure" ||
        newMenu.key === "Protect" ||
        newMenu.key === "Analyze"
      ) {
        newMenu = menuFilterUDP(state, newMenu);
      }
      newMenu.subMenu = menuFilter(newMenu);
      return newMenu;
    });
  } else {
    return state.reducer.menu;
  }
};

const getCSRMenu = state => {
  return state.reducer.csr_menu;
};

export const makeMenuState = () => createSelector([getMenu], menu => menu);

export const makeCSRMenuState = () =>
  createSelector([getCSRMenu], menu => menu);
