import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import CCToastNotification from "./CCToastNotification";

class CCToast extends PureComponent {
  constructor(props) {
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      const { clearNotifications } = this.props;
      clearNotifications();
    }
  }

  handleDismiss(id) {
    const { clearNotification } = this.props;
    clearNotification(id);
  }

  render() {
    const { notifications } = this.props;

    return (
      <div className="cc-alert-container">
        {notifications.map(n => (
          <CCToastNotification
            key={n.notificationId}
            id={n.notificationId}
            icon={n.icon}
            messageId={n.messageId}
            messageValues={n.messageValues}
            namespace={n.namespace}
            dismiss={n.dismiss}
            handleDismiss={this.handleDismiss}
          />
        ))}
      </div>
    );
  }
}

CCToast.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      notificationId: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      messageId: PropTypes.string.isRequired,
      messageValues: PropTypes.shape({}),
      namespace: PropTypes.string,
      dismiss: PropTypes.integer
    })
  ).isRequired,
  clearNotifications: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired
};

export default CCToast;
