import { combineReducers } from "redux";
import fromHypervisor from "./fromHypervisor";
import lbs from "./lbs";
import discoverFromAd from "./discoverFromAd";
import windows from "./windows";
import linux from "./linux";
import UncOrNfs from "./UncOrNfs";
import oracleSource from "./oracleSource";
import oracleDatabases from "./oracleDatabases";

export default combineReducers({
  fromHypervisor,
  lbs,
  windows,
  linux,
  discoverFromAd,
  UncOrNfs,
  oracleSource,
  oracleDatabases
});
