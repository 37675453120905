import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { List } from "react-virtualized";

const filter = createFilterOptions();

// const LoadingDots = testId => {
//   return (
//     <div id={testId}>
//       {" "}
//       &nbsp;
//       <div className="state-loading-bar" />
//       <div className="state-loading-bar" />
//       <div className="state-loading-bar" />
//     </div>
//   );
// };

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;
  let height =
    Array.isArray(children) && children.length < 6 ? children.length * 40 : 250;
  if (children?.length === 1) {
    const length = children?.[0]?.key?.length;
    height = length ? Math.min(Math.ceil(length / 26) * itemSize, 150) : 40;
    height += 5;
  }
  const getRowHeight = ({ index }) => {
    let itemLength = children?.[index]?.key?.length;
    const itemHeight = 30;
    if (!itemLength) itemLength = 0;
    const additionalHeight =
      itemLength <= 25 ? 40 : Math.ceil(itemLength / 26) * itemHeight;
    const rowHeight = additionalHeight;
    return rowHeight;
  };
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          style={{ width: "100% !important" }}
          height={height}
          width={275}
          rowHeight={getRowHeight}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props =>
            React.cloneElement(children[props.index], {
              style: props.style
            })
          }
          role={role}
        />
      </div>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string
};

ListboxComponent.defaultProps = {
  children: [],
  role: "listbox"
};

export default function VirtualizedCustomizableDropdown({
  options,
  label,
  onChange,
  customStyle,
  placeholder,
  id,
  value,
  selectedOption,
  meta: { error }
}) {
  const textFieldClassname = label
    ? "multiselectDropdown"
    : "multiselectDropdown spog-mui-textfield";
  return (
    <React.Fragment>
      <Autocomplete
        id={id || "virtualize-demo"}
        className={"spog-autocomplete-dropdown"}
        value={value}
        sx={{
          "& .MuiOutlinedInput-input": {
            marginRight: "50px"
          }
        }}
        style={customStyle}
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={options}
        onChange={onChange}
        isOptionEqualToValue={(option, value) =>
          option.label === (value.label ? value.label : value)
        }
        renderInput={params => (
          <TextField
            {...params}
            error={error}
            variant="outlined"
            label={label}
            className={textFieldClassname}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            option => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`
            });
          }
          return filtered;
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
      />
    </React.Fragment>
  );
}
