/* eslint-disable camelcase */
import {
  select,
  all,
  call,
  put,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
// import { delay } from "redux-saga";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as _ from "lodash";
import {
  getContextualAction,
  getLogin,
  getOrgId,
  getFeatureFlag
} from "state/selectors";
import * as ccToastActions from "state/actions/ccToast";
import { fetchData } from "state/sagas/ccDataGrid";
import * as actionType from "../actions/actionTypes";
import {
  showCloudAccountsList,
  updateCloudAccountsListCount,
  addAPIresponse,
  showHypervisorDetails,
  showAdvancedSearchOptions,
  searchEnterEvent,
  submitAdvancedSearch,
  showArcserveCloudDetails,
  showArcserveCloudVolumeDetails,
  setResourceName,
  showLicenseDetails,
  showRoleDetails,
  updateUsers,
  updateUsersV2,
  updateSources,
  deleteUpdateSource,
  getSourcesByGroup,
  clearStateSelectedRows,
  showBrandingDetails,
  showEmailerDetails,
  changeURLFlag,
  searchList,
  showNetworkConfigList,
  showHypervisorList,
  updateCustomerList,
  updateMSPChildAccounts,
  updateConfigureCount,
  updatedDestinationsForDropdown,
  updateCustomersToAssign,
  showNetworkPortForwardDetails,
  deleteUpdateNetworkConfigGridList,
  addNetworkPortForwardDetails,
  submitBtnDisable,
  updateMenuCountsOnAddRemoveActions,
  updateVPNDetails,
  Loader,
  Dialog,
  hideDialog,
  resetLoading,
  stopLoader,
  startLoader,
  setLoading,
  addNewSite,
  switchAddSiteTab,
  loadSites,
  getConfigureListCount,
  loadDefaultDeploymentSuccess,
  loadSourceDiscoverySettingsSuccess,
  fetchSourceDiscoveryListSuccess,
  fetchSourceDiscoveryList,
  showStorageArrays,
  getProxiesData,
  getOrganization,
  setTheme,
  startGridLoaderById,
  stopGridLoaderById
} from "../actions/actions";
import * as ccToast from "state/actions/ccToast";
import { configureApi, protectApi, commonApi } from "../api";
import * as constant from "../../utils/appConstants";
import { LIGHT_MODE, MUI_INITIAL_THEME } from "../../utils/theme";
import {
  getQueryStringFromSortedData,
  storeDataToTxt,
  getTimestamp
} from "../../utils/SpogDataGridUtil";
import { reset, getFormValues, stopSubmit } from "redux-form";
import moment from "moment";
import { deployConfig } from "state/selectors/settingsSelectors";
import { makeCheckboxSelectionState } from "state/selectors/CommonSelectors";
import {
  showToastFromResponse,
  showErrToastFromResponse,
  getFormattedMessage,
  makeToastData
} from "./sagautils";
import { userAccountsAttributes } from "components/configure/access-control/user-accounts/UserAccountsConstants";
import { PORT_FORWORDING_ERR_CODES } from "components/configure/infrastructure/network-configuration/NetworkConstants";
import LinuxSourceConstant from "components/protect/source/add-source/oracle-window-sources/OracleSourceConstant";

const getReducer = state => state.reducer;
const getConfigureReducer = state => state.configureReducer;
// const getTheme = state => state.reducer.theme;

export function* receiveInfrastructureList() {
  try {
    const stateReducer = yield select(getReducer);
    let infrastructureList = [];
    let filterType = "";
    switch (stateReducer.secondaryNavigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.CLOUD_ACCOUNTS:
        filterType = constant.SECONDARY_NAVIGATION_TYPE.CLOUD_ACCOUNTS;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.HYPERVISORS:
        filterType = constant.SECONDARY_NAVIGATION_TYPE.HYPERVISORS;
        break;

      case constant.SECONDARY_NAVIGATION_TYPE.ARCSERVE_CLOUD_RPS:
        filterType = "cloud_rps";
        break;

      default:
        break;
    }

    infrastructureList = yield call(
      configureApi.fetchInfrastructureList,
      filterType
    );
    yield put(showCloudAccountsList(infrastructureList, filterType));
  } catch (e) {
    yield put(startLoader());
    window.console.log(e);
  }
}

/**
 * To get the Advanced search options for Inner Tables for API
 */
function* getAdvancedSearchOptionsDataForInnerTab(action) {
  try {
    let optionsList = yield call(configureApi.getAdvancedSearch);
    yield put(showAdvancedSearchOptions(optionsList[action.data]));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To search the Result by using advanced search options
 * @param data
 */
export function* retrieveSearchresults(data) {
  try {
    const stateReducer = yield select(getReducer);
    const loginReducer = yield select(getLogin);
    let searchCriteria = [];
    if (
      data.payload &&
      data.payload.advancedSearchOptions &&
      data.payload.advancedSearchOptions.length > 0
    ) {
      getOptions(searchCriteria, data.payload.advancedSearchOptions);
    } else if (
      stateReducer.selectedFilterOptions &&
      stateReducer.selectedFilterOptions.length > 0
    ) {
      getOptions(searchCriteria, stateReducer.selectedFilterOptions);
    } else if (
      stateReducer.currentSearchOptions &&
      stateReducer.currentSearchOptions.length > 0
    ) {
      getOptions(searchCriteria, stateReducer.currentSearchOptions);
    }

    // const gridCurrentPage =
    //   data.payload && data.payload.gridCurrentPage
    //     ? data.payload.gridCurrentPage
    //     : stateReducer.gridCurrentPage;
    // const gridPageSize =
    //   data.payload && data.payload.gridPageSize
    //     ? data.payload.gridPageSize
    //     : stateReducer.gridPageSize;
    if (
      data.payload &&
      data.payload.searchText &&
      data.payload.searchText.length > 0
    ) {
      let criterion = "source_name=" + data.payload.searchText;
      searchCriteria.push(criterion);
      yield put(searchEnterEvent(data.payload));
    }
    if (searchCriteria.length > 0) {
      yield put(submitAdvancedSearch());
    }

    let navigation = stateReducer.secondaryNavigation;
    if (data.payload && data.payload.filter) {
      navigation = data.payload.filter;
    }
    let apiResponse = "";
    switch (navigation) {
      case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_GROUP_DETAILS:
        yield put(startLoader());
        searchCriteria = searchCriteria.join("&");
        // if (gridCurrentPage && gridPageSize) {
        //   if (searchCriteria != "") {
        //     searchCriteria += `&`;
        //   }
        //   searchCriteria += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
        // }
        if (searchCriteria !== "") {
          searchCriteria += `&pagination=false`;
        } else {
          searchCriteria = `pagination=false`;
        }
        var sourceGroupId = data.payload && data.payload.sourceGroupId;
        apiResponse = yield call(
          configureApi.getSourcesByGroupId,
          sourceGroupId,
          loginReducer.token,
          searchCriteria
        );
        if (constant.HTTP_STATUS.OK === apiResponse.status) {
          yield put(
            updateSources({
              data: apiResponse.data,
              paginationData: apiResponse.pagination
            })
          );
        } else {
          yield put(addAPIresponse(apiResponse));
        }
        yield put(stopLoader());
        break;
      default:
        break;
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put({
      type: actionType.CLEAR_STATE_SELECTED_ROWS
    });
  }
}

function* getInfrastructureListTotalCounts() {
  try {
    const stateReducerForMenu = yield select(getReducer);
    let infraCount = yield call(configureApi.getInfrastructureCounts);
    yield put(
      updateCloudAccountsListCount(
        infraCount,
        stateReducerForMenu.menu,
        stateReducerForMenu.primaryNavigation
      )
    );
  } catch (e) {
    window.console.log(e);
    //    yield put(showSourcesList(e));
  }
}

export function* getStorageArrays(payload) {
  yield put(startLoader());
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let navigation = stateReducer.secondaryNavigation;
    const gridCurrentPage =
      payload && payload.data && payload.data.gridCurrentPage
        ? payload.data.gridCurrentPage
        : stateReducer.gridCurrentPage;
    const gridPageSize =
      payload && payload.data && payload.data.gridPageSize
        ? payload.data.gridPageSize
        : stateReducer.gridPageSize;
    const gridPagination = stateReducer.gridPagination;
    let queryString = "";
    const organizationId = loginReducer.organization_id;
    const product_type = _.get(payload, "data.product_type", undefined);
    if (organizationId) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `organization_id=${organizationId}`;
    }

    if (product_type) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `product_type=${product_type}`;
    }
    if (gridCurrentPage && gridPageSize && gridPagination !== false) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
    }

    if (
      gridPagination === false &&
      navigation !== constant.SECONDARY_NAVIGATION_MAPPING.storage_arrays
    ) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += "pagination=false";
    }

    let sortedDataSet = [];
    if (payload.data && payload.data.sortedData) {
      sortedDataSet = payload.data.sortedData;
    } else if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedDataSet = stateReducer.sortedColumns;
    } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
      sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
    }

    if (sortedDataSet.length) {
      queryString = getQueryStringFromSortedData(queryString, sortedDataSet);
    }

    yield put(showLoading());

    let apiResponse = yield call(
      configureApi.fetchStorageArrayList,
      queryString,
      loginReducer.token
    );

    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(showStorageArrays(apiResponse));
    } else {
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
    yield put(stopLoader());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* getHypervisorsList(payload) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    let navigation = stateReducer.secondaryNavigation;
    const gridCurrentPage =
      payload && payload.data && payload.data.gridCurrentPage
        ? payload.data.gridCurrentPage
        : stateReducer.gridCurrentPage;
    const gridPageSize =
      payload && payload.data && payload.data.gridPageSize
        ? payload.data.gridPageSize
        : stateReducer.gridPageSize;
    const gridPagination = stateReducer.gridPagination;
    let queryString = "";
    const organizationId = loginReducer.organization_id;
    const product_type = _.get(payload, "data.product_type", undefined);
    if (organizationId) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `organization_id=${organizationId}`;
    }

    if (product_type) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `product_type=${product_type}`;
    }
    if (gridCurrentPage && gridPageSize && gridPagination !== false) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `page=${gridCurrentPage}&page_size=${gridPageSize}`;
    }

    if (
      gridPagination === false &&
      navigation !== constant.SECONDARY_NAVIGATION_MAPPING.Hypervisors
    ) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += "pagination=false";
    }

    let sortedDataSet = [];
    if (payload.data && payload.data.sortedData) {
      sortedDataSet = payload.data.sortedData;
    } else if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedDataSet = stateReducer.sortedColumns;
    } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
      sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
    }

    if (sortedDataSet.length) {
      queryString = getQueryStringFromSortedData(queryString, sortedDataSet);
    }

    yield put(showLoading());

    let apiResponse = yield call(
      configureApi.fetchHypervisorList,
      queryString,
      loginReducer.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(showHypervisorList(apiResponse));
    } else {
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/**
 * To get detail of a particular cloud account
 * @param action
 */
export function* getHypervisorDetail(action) {
  try {
    const loginReducer = yield select(getLogin);
    const configureReducer = yield select(getConfigureReducer);

    let apiResponse = yield call(
      configureApi.fetchHypervisorDetailById,
      action.id,
      loginReducer.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      if (configureReducer.selectedResource)
        yield put(
          setResourceName(configureReducer.selectedResource.hypervisor_name)
        );
      else yield put(setResourceName(apiResponse.data.hypervisor_name));

      yield put(showHypervisorDetails(apiResponse.data));
    } else {
      yield put(addAPIresponse(apiResponse));
    }
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

export function* loadConfigureProxies({ page, pageSize }) {
  try {
    yield put(startLoader());
    const loginReducer = yield select(getLogin);

    let apiResponse = yield call(
      configureApi.loadConfigureProxies,
      page,
      pageSize,
      `organization_id=${loginReducer.organization_id}`,
      loginReducer.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.data.map((item, index) => {
        return (item.id = apiResponse.data[index].proxy_id);
      });
      yield put(getProxiesData(apiResponse));
    } else {
      yield put(addAPIresponse(apiResponse));
    }
    yield put(stopLoader());
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * To get detail of a particular cloud account
 * @param action
 */
export function* getArcserveCloudDetails() {
  try {
    yield put(showLoading());
    let details = yield call(configureApi.fetchInfraDetailById, 1);
    yield put(hideLoading());
    yield put(showArcserveCloudDetails({ details }));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get detail of a particular cloud account volume
 * @param action
 */
export function* receiveArcserveCloudVolume(action) {
  try {
    yield put(showLoading());
    let details = yield call(
      configureApi.fetchArcerveCloudVolumeById,
      action.data
    );
    yield put(hideLoading());
    yield put(showArcserveCloudVolumeDetails({ details }));
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * To get detail of a hypervisor information
 * @param action
 */
export function* editUpdateHypervisorInformation(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.updateHypervisorDetails,
      action.data,
      action.hypervisor_id,
      loginReducer.token
    );
    if (apiResponse && apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put({
        type: actionType.UPDATE_HYPERVISOR_INFORMATION,
        data: apiResponse.data
      });
      apiResponse.context =
        constant.API_RESPONSE_CONTEXT.SOURCE_CONFIG_UPDATE_SUCCESS;
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
    yield put(submitBtnDisable(false));
  } catch (e) {
    yield put(hideLoading());
    window.console.log(e);
  }
}

/**
 * Get list of users
 */
export function* getUsersData(payload) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const gridCurrentPage = payload.gridCurrentPage
      ? payload.gridCurrentPage
      : stateReducer.gridCurrentPage;
    const gridPageSize = payload.gridPageSize
      ? payload.gridPageSize
      : stateReducer.gridPageSize;

    let queryString = "sort=create_ts,last_login_ts";
    const organizationId = loginReducer.organization_id;

    if (organizationId) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `organization_id=${organizationId}`;
    }

    if (gridCurrentPage && gridPageSize) {
      queryString += `&page=${gridCurrentPage}&page_size=${gridPageSize}`;
    }

    yield put(showLoading());

    const apiResponse = yield call(
      configureApi.getUsers,
      queryString,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //  update data in configureReducer on success
      yield put(updateUsers(apiResponse));
    } else {
      apiResponse.context = constant.API_RESPONSE_CONTEXT.USER_ACCOUNTS;
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * Get list of users
 */
export function* getUsersDataV2(payload) {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const gridCurrentPage = payload.gridCurrentPage
      ? payload.gridCurrentPage
      : stateReducer.gridCurrentPage;
    const gridPageSize = payload.gridPageSize
      ? payload.gridPageSize
      : stateReducer.gridPageSize;

    let queryString = "sort=create_ts,last_login_ts";
    const organizationId = loginReducer.organization_id;

    if (organizationId) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `organization_id=${organizationId}`;
    }

    if (gridCurrentPage && gridPageSize) {
      queryString += `&page=${gridCurrentPage}&page_size=${gridPageSize}`;
    }

    yield put(showLoading());
    yield put(startGridLoaderById(constant.DATAGRID_IDS["user_accounts_grid"]));

    const apiResponse = yield call(
      configureApi.getUsersV2,
      queryString,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //  update data in configureReducer on success
      yield put(updateUsersV2(apiResponse));
    } else {
      apiResponse.context = constant.API_RESPONSE_CONTEXT.USER_ACCOUNTS;
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["user_accounts_grid"]));
  } catch (e) {
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["user_accounts_grid"]));
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * Delete user by id
 */
export function* deleteUser(data) {
  try {
    const loginReducer = yield select(getLogin);
    const userId = data.userId ? data.userId : null;
    const contextualAction = yield select(getContextualAction);
    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.deleteUser,
      userId,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //  fetch list from back end
      yield put(searchList());
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.USER_ACCOUNTS });
      let mainType = "Configure";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      //Success Notification
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.USER_ACCCOUNTS_REMOVE_SUCCESS,
        testId: "3tjuNq"
      };
      const email =
        contextualAction.additionalProperties.item[
          userAccountsAttributes.USER_ACCOUNTS_EMAIL
        ];
      yield put(ccToast.addSuccessNotification(toastData, { email }));
    } else {
      //Error Notification
      const error =
        (apiResponse.errors &&
          apiResponse.errors[0] &&
          apiResponse.errors[0].message) ||
        " ";
      const toastData = {
        messageId: "configure.accessControl.userAccounts.assignAccountsError",
        testId: `8emT8q_${apiResponse.status}`
      };
      yield put(ccToast.addErrorNotification(toastData, { error }));
    }
    yield put(hideLoading());
    // apiResponse.context = constant.API_RESPONSE_CONTEXT.USER_ACCOUNTS;
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * Get list of user roles
 */
export function* getUserRoles() {
  try {
    const loginReducer = yield select(getLogin);
    let queryString = "";
    const organizationId = loginReducer.organization_id;

    if (organizationId) {
      if (queryString && queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `organization_id=${organizationId}`;
    }

    yield put(showLoading()); //  show loader
    const apiResponse = yield call(
      configureApi.getUserRoles,
      queryString,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //  update data in configureReducer on success
      yield put({ type: actionType.UPDATE_USER_ROLES, data: apiResponse.data });
    } else {
      apiResponse.context = constant.API_RESPONSE_CONTEXT.USER_ROLES;
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

export function* getRoleDetails(data) {
  try {
    const loginReducer = yield select(getLogin);
    const apiResponse = yield call(
      configureApi.fetchRoleDetailsById,
      data.id,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(showRoleDetails(apiResponse.data));
    } else {
      apiResponse.context = constant.API_RESPONSE_CONTEXT.USER_ROLES;
      yield put(addAPIresponse(apiResponse));
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/**
 * Create/Edit user
 */
export function* addUser(action) {
  try {
    const loginReducer = yield select(getLogin);
    action.data.organization_id = loginReducer.organization_id;
    const isAddMode = action.data.user_id ? false : true;

    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.addUser,
      action.data,
      loginReducer.token,
      isAddMode
    );
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      //  new user created => fetch list from back end
      yield put(searchList());
      let mainType = "Configure";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    } else if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //  user updated => fetch list from back end
      yield put(searchList());
    }
    apiResponse.context = constant.API_RESPONSE_CONTEXT.USER_ACCOUNTS;
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading()); //  hide loader
    yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.USER_ACCOUNTS });
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

export function* manageMSPUserPermissions(action) {
  try {
    const loginReducer = yield select(getLogin);

    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.manageMSPUserPermissions,
      action.data,
      loginReducer.token
    );
    yield put(hideLoading()); //  hide loader
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //Success Notification
      const toastData = {
        messageId: "configure.accessControl.userAccounts.update_role",
        testId: "e25j5Q"
      };
      yield put(ccToast.addSuccessNotification(toastData));
    } else {
      //Error Notification
      if (constant.HTTP_STATUS.INTERNAL_SERVER_ERROR === apiResponse.status) {
        const toastData = {
          messageId: "toast-errors.internal-error",
          testId: `Nv3oho_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      } else {
        const error = _.get(apiResponse, "errors[0].message");
        const toastData = {
          messageId: "configure.accessControl.userAccounts.update_role.error",
          testId: `Nv3oho_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData, { error }));
      }
    }

    // apiResponse.context = constant.API_RESPONSE_CONTEXT.MANAGE_PERMISSIONS;
    yield put({
      type: actionType.EDIT_UPDATE_USERS_V2,
      data: apiResponse.data
    });
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * Create New Site
 */
export function* createNewSite(data) {
  try {
    const stateLogin = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : stateLogin.organization_id;
    let sitePayload = { ...data.siteobj, organization_id: organizationId };
    yield put(setLoading(Loader.ADD_SITE));
    let response = yield call(
      protectApi.createNewSiteData,
      sitePayload,
      stateLogin.token
    );
    if (response.status === constant.HTTP_STATUS.CREATED) {
      const updatedDetails = {
        siteName: response.data.site_name,
        gatewayAuthorizationCode: response.data.registration_basecode,
        siteId: response.data.site_id
      };
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.SITES });
      yield put(addNewSite(updatedDetails));
      yield put(loadSites());
      yield put(getConfigureListCount());
      yield put(switchAddSiteTab(2));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_SITE));
  }
}

/*
 * To get the license details of user
 */
export function* receiveLicenseDetails(data) {
  try {
    yield put(showLoading()); //  show loader
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    let licenseDetails = yield call(
      configureApi.getLicenseDetails,
      loginReducer.token,
      organizationId,
      data.pollingAction
    );
    if (typeof licenseDetails.data === "object") {
      licenseDetails.data["user"] =
        loginReducer.userDetails.first_name +
        " " +
        loginReducer.userDetails.last_name;
      yield put(showLicenseDetails(licenseDetails.data));
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    yield put(hideLoading()); //  hide loader
    yield put(
      ccToastActions.addErrorNotification(
        makeToastData("toast-errors.internal-error", `TaRMvq`)
      )
    );
  }
}

/*
 * To activate the license details of user
 */
export function* activateOrderDetails(payload) {
  try {
    yield put(showLoading()); //  show loader
    const loginReducer = yield select(getLogin);
    let brandNames = _.get(loginReducer, "brand_names", constant.BRAND_NAMES);
    const organizationId = loginReducer.organization_id;
    let activateOrderResp = yield call(
      configureApi.updateLicenseDetails,
      payload.data,
      organizationId,
      loginReducer.token
    );
    let licenseDetails = yield call(
      configureApi.getLicenseDetails,
      loginReducer.token,
      organizationId
    );
    licenseDetails.data["user"] =
      loginReducer.userDetails.first_name +
      " " +
      loginReducer.userDetails.last_name;
    yield put(showLicenseDetails(licenseDetails.data));

    if (activateOrderResp.status === constant.HTTP_STATUS.CREATED) {
      if (Array.isArray(activateOrderResp.data.skus)) {
        // Check if DR or CD is added
        let cloudDirectDraas = false;
        let cloudHybridBaas = false;
        let supportInfo = {
          support_link: process.env.REACT_APP_URL + "/user_support",
          ...brandNames
        };

        activateOrderResp.data.skus.forEach(function(sku) {
          if (sku.product_type === "clouddirect_draas") cloudDirectDraas = true;
          else if (sku.product_type === "cloudhybrid_baas")
            cloudHybridBaas = true;
        });

        if (!cloudDirectDraas && !cloudHybridBaas) {
          // Neither DR nor CH is in the order
          yield put(
            ccToastActions.addSuccessNotification(
              makeToastData("configure.license.activate.success", "744xrD")
            )
          );
        } else {
          // Either DR or CH, or both are included in the order
          let $messageId = "configure.license.activate.draas.success"; // DR Config is added
          if (cloudDirectDraas && cloudHybridBaas)
            // both DR & CH is added
            $messageId = "configure.license.activate.draas_hybrid.success";
          else if (cloudHybridBaas)
            // CH is added
            $messageId = "configure.license.activate.hybrid.success";

          // success notification with instruction, never fade out
          yield put(
            ccToastActions.addSuccessNotification(
              makeToastData($messageId, "2XDheC"),
              supportInfo,
              "",
              0
            )
          );
        }
      }
    } else if (activateOrderResp.status === constant.HTTP_STATUS.BAD_REQUEST) {
      if (
        Array.isArray(activateOrderResp.errors) &&
        activateOrderResp.errors.length > 0
      ) {
        switch (activateOrderResp.errors[0].code) {
          case "8500":
          case "8501":
            yield put(
              ccToastActions.addErrorNotification(
                makeToastData(
                  `toast-errors.cd.${activateOrderResp.errors[0].code}`,
                  `pcQBwe_${activateOrderResp?.status}`
                ),
                {
                  order_id: payload.data.order_id,
                  fulfillment_id: payload.data.fulfillment_id
                }
              )
            );
            break;
          default:
            yield put(
              ccToastActions.addErrorNotification(
                makeToastData(
                  `apiErrorResponse`,
                  `GHeiyw_${activateOrderResp?.status}`
                ),
                {
                  message: activateOrderResp.errors[0].message
                }
              )
            );
            break;
        }
      } else {
        yield put(
          ccToastActions.addErrorNotification(
            makeToastData(
              "toast-errors.internal-error",
              `BHwytW_${activateOrderResp?.status}`
            )
          )
        );
      }
    } else if (activateOrderResp.status === constant.HTTP_STATUS.UNAUTHORIZED) {
      let errmsg = _.get(activateOrderResp, "errors[0].message");
      if (errmsg && activateOrderResp.errors[0].code === "00200039") {
        yield put(
          ccToastActions.addErrorNotification(
            makeToastData(
              `apiErrorResponse`,
              `mLKjmz_${activateOrderResp?.status}`
            ),
            {
              message: activateOrderResp.errors[0].message
            }
          )
        );
      } else if (errmsg) {
        yield put(
          ccToastActions.addErrorNotification(
            makeToastData(
              `apiErrorResponse`,
              `YGxRTs_${activateOrderResp?.status}`
            ),
            {
              message: activateOrderResp.errors[0].message
            }
          )
        );
      }
    }
    // const ccToastReducer = yield select(getccToast);
    // if (_.get(ccToastReducer, "allIds") && ccToastReducer.allIds.length > 0) {
    //   yield call(delay, error ? 5000 : 10000);
    //   yield put(ccToastActions.clearNotifications());
    // }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    yield put(hideLoading()); //  hide loader
    yield put(
      ccToastActions.addErrorNotification(
        makeToastData("toast-errors.bad-request", `jhJSKm`)
      )
    );
  }
}

/**
 * get sources for selected source group by group id
 */
export function* getSourcesByGroupSaga(action) {
  try {
    yield put(startGridLoaderById(constant.DATAGRID_IDS["sources_datagrid"]));
    const loginReducer = yield select(getLogin);
    const configureReducer = yield select(getConfigureReducer);
    const stateReducer = yield select(getReducer);
    const selectedSourceGroup = configureReducer.selectedSourceGroup;
    let navigation = stateReducer.secondaryNavigation;

    let queryString = "pagination=false";
    let sortedDataSet = [];
    if (
      stateReducer.sortedColumns &&
      Array.isArray(stateReducer.sortedColumns) &&
      stateReducer.sortedColumns.length > 0
    ) {
      sortedDataSet = stateReducer.sortedColumns;
    } else if (constant.GRID_DEFAULT_SORTING[navigation]) {
      sortedDataSet = constant.GRID_DEFAULT_SORTING[navigation];
    }

    if (sortedDataSet.length) {
      queryString = getQueryStringFromSortedData(queryString, sortedDataSet);
    }

    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.getSourcesByGroupId,
      action.id,
      loginReducer.token,
      queryString
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(
        updateSources({
          data: apiResponse.data,
          paginationData: apiResponse.pagination
        })
      );
      yield put(setResourceName(selectedSourceGroup.group_name.label));
    } else {
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["sources_datagrid"]));
  } catch (e) {
    yield put(stopGridLoaderById(constant.DATAGRID_IDS["sources_datagrid"]));
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * remove source/s from source group
 */
export function* removeSourcesFromGroup(action) {
  try {
    const loginReducer = yield select(getLogin);
    const sourceGroupId = action.data.sourceGroupId || "";
    const body = { sources: action.data.sources };
    const contextualAction = yield select(getContextualAction);
    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.removeSourcesFromGroup,
      sourceGroupId,
      loginReducer.token,
      body,
      { showCommonError: true, testId: "qMdTL0" }
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const item = contextualAction?.additionalProperties?.item;
      const count = Array.isArray(item) ? item.length : 0;
      const toastData = {
        messageId: "configure.sourceGroups.remove.success",
        testId: "y7Yq24"
      };
      yield put(ccToast.addSuccessNotification(toastData, { count }));
      yield put(deleteUpdateSource(action.data.sourcesIds));
      yield put(getSourcesByGroup(sourceGroupId));
      yield put(clearStateSelectedRows());
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * add multiple sources to group
 */
export function* addSourcesToGroup(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    const sourceGroupId = action.data.sourceGroupId;
    const body = { sources: action.data.sources };
    const apiResponse = yield call(
      configureApi.addSourcesToGroup,
      sourceGroupId,
      body,
      loginReducer.token,
      { showCommonError: true, testId: "bsAGnk" }
    );
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      yield put(clearStateSelectedRows());
      yield put(changeURLFlag(true));
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.ADD_SELECTED_SOURCES;
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.ADD_SELECTED_SOURCES,
        testId: "Pdzrk3"
      };
      yield put(ccToastActions.addSuccessNotification(toastData));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/**
 * To get detail of a brand details
 * @param action
 */
export function* receiveBrandDetails() {
  try {
    const loginReducer = yield select(getLogin);
    // const theme = yield select(getTheme);
    const organizationId = loginReducer.organization_id;
    let apiResponse = yield call(
      configureApi.fetchBrandDetails,
      organizationId,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      let brandObj = Object.assign({}, apiResponse.data);
      if (!brandObj.login_img_url) {
        brandObj.login_img_url = null;
      }
      if (!brandObj.brandingLogo) {
        brandObj.brandingLogo = false;
      }
      yield put(showBrandingDetails(brandObj));
      const themeValue = { ...MUI_INITIAL_THEME() };
      if (themeValue?.palette) {
        if (themeValue?.palette?.mode === LIGHT_MODE) {
          themeValue.palette.primary.main = brandObj.primary_color;
          themeValue.palette.secondary.main = brandObj.secondary_color;
        }
        yield put(setTheme(themeValue));
      }
    } else {
      yield put(showBrandingDetails(constant.DEFAULT_BRANDING_DATA));
    }
  } catch (e) {
    window.console.log(e);
  }
}

export function* receiveLastViewedAppDetails(data) {
  try {
    const loginReducer = yield select(getLogin);
    // let apiResponse =
    yield call(commonApi.setLastViewedApp, data, loginReducer.token);
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * To update brand details
 * @param action
 */
export function* updateBrandDetails(action) {
  try {
    yield put(setLoading(Loader.UPDATE_BRANDING_DETAILS));
    const loginReducer = yield select(getLogin);
    const configReducer = yield select(getConfigureReducer);
    const organizationId = loginReducer.organization_id;
    let postData = {
      ...action.data,
      login_img_path: configReducer.loginImgData.logo_img_path,
      login_img_url: configReducer.loginImgData.image_url,
      logo_img_path: configReducer.logoImgData.logo_img_path,
      logo_img_url: configReducer.logoImgData.image_url,
      branding_from: configReducer.brandingFrom
    };
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.updateBrandDetails,
      postData,
      loginReducer.token,
      organizationId,
      { showCommonError: true, testId: "oHMfxI" }
    );
    if (
      constant.HTTP_STATUS.OK === apiResponse.status ||
      constant.HTTP_STATUS.CREATED === apiResponse.status
    ) {
      if (!action.data.imageUpload) {
        yield put({
          type: actionType.UPDATE_BRAND_INFORMATION,
          data: {
            ...apiResponse.data,
            brandingLogo: apiResponse.data.brandingLogo,
            logo_img_url: `${
              apiResponse.data.logo_img_url
            }?refresh=${Date.now()}` // ARC001-3880
          }
        });
      }
      if (action.data.imageUpload) {
        const toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.UPDATE_BRANDING_LOGO,
          testId: "Ge7khR"
        };
        yield put(ccToastActions.addSuccessNotification(toastData));
      } else {
        const toastData = {
          messageId: constant.API_RESPONSE_CONTEXT.UPDATE_BRANDING_DETAILS,
          testId: "tYZFAl"
        };
        yield put(ccToastActions.addSuccessNotification(toastData));
      }

      yield put({
        type: actionType.UPDATE_BRANDING_FROM,
        data: apiResponse.data.branding_from
      });
      action.data.imageUpload
        ? (apiResponse.context =
            constant.API_RESPONSE_CONTEXT.UPDATE_BRANDING_LOGO)
        : (apiResponse.context = "");
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
    yield put(hideLoading());
  } finally {
    yield put(resetLoading(Loader.UPDATE_BRANDING_DETAILS));
  }
}

/**
 * To get detail of a emailer details
 * @param action
 */
export function* receiveEmailerDetails() {
  try {
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.fetchEmailerDetails,
      organizationId,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      yield put(showEmailerDetails(apiResponse.data));
    } else {
      yield put(addAPIresponse(apiResponse));
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * To update emailer details
 * @param action
 */
export function* updateEmailerDetails(action) {
  try {
    yield put(setLoading(Loader.UPDATE_BRANDING_DETAILS));
    const loginReducer = yield select(getLogin);
    const organizationId = loginReducer.organization_id;
    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.updateEmailerDetails,
      action.data,
      loginReducer.token,
      organizationId,
      { showCommonError: true, testId: "kmbqYL" }
    );
    if (
      [constant.HTTP_STATUS.OK, constant.HTTP_STATUS.CREATED].includes(
        apiResponse.status
      )
    ) {
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.UPDATE_BRANDING_DETAILS;
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.UPDATE_BRANDING_DETAILS,
        testId: "M2Br8v"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield put(showEmailerDetails(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  } finally {
    yield put(resetLoading(Loader.UPDATE_BRANDING_DETAILS));
  }
}

/*
 * to reset user password
 * @param action
 */
export function* resetUserPassword(action) {
  try {
    const loginReducer = yield select(getLogin);
    const contextualAction = yield select(getContextualAction);

    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.resetUserPassword,
      action.id,
      loginReducer.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      const toastData = {
        messageId: "configure.accessControl.userAccounts.resetPasswordSuccess",
        testId: "bEle6o"
      };
      const email =
        contextualAction.additionalProperties.item[
          userAccountsAttributes.USER_ACCOUNTS_EMAIL
        ];
      yield put(ccToast.addSuccessNotification(toastData, { email }));
    } else {
      //Error Notification
      if (constant.HTTP_STATUS.INTERNAL_SERVER_ERROR === apiResponse.status) {
        const toastData = {
          messageId: "toast-errors.internal-error",
          testId: `edQLzq_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      } else {
        const error = _.get(apiResponse, "errors[0].message");
        const toastData = {
          messageId: "configure.accessControl.userAccounts.assignAccountsError",
          testId: `edQLzq_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData, { error }));
      }
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

/*
 * to resend verification mail
 * @param action
 */
export function* resendUserVerificationEmail(action) {
  try {
    const loginReducer = yield select(getLogin);
    const contextualAction = yield select(getContextualAction);
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.resendUserVerificationEmail,
      action.id,
      loginReducer.token
    );

    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      //Success Notification
      const email =
        contextualAction.additionalProperties.item[
          userAccountsAttributes.USER_ACCOUNTS_EMAIL
        ];
      const toastData = {
        messageId:
          "configure.accessControl.userAccounts.verificationEmailSuccess",
        testId: "yi8bVw"
      };
      yield put(ccToast.addSuccessNotification(toastData, { email }));
    } else {
      //Error Notification
      if (constant.HTTP_STATUS.INTERNAL_SERVER_ERROR === apiResponse.status) {
        const toastData = {
          messageId: "toast-errors.internal-error",
          testId: `DXSajN_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      } else {
        const error = _.get(apiResponse, "errors[0].message");
        const toastData = {
          messageId: "configure.accessControl.userAccounts.assignAccountsError",
          testId: `DXSajN_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData, { error }));
      }
    }
    yield put(hideLoading());
    yield put(addAPIresponse(apiResponse));
  } catch (e) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: e }]
      })
    );
  }
}

export function* getBrandingImageURL(data) {
  try {
    const loginReducer = yield select(getLogin);
    const configReducer = yield select(getConfigureReducer);
    const organizationId = loginReducer.organization_id;

    let uploadAPI = null;
    let action = null;

    switch (data.trigger) {
      case "login":
        uploadAPI = configureApi.getLoginImageURL;
        action = actionType.UPDATE_LOGIN_IMG_URL;
        break;
      case "brand":
        uploadAPI = configureApi.getLogoImageURL;
        action = actionType.UPDATE_LOGO_IMG_URL;
        break;
      default:
        break;
    }

    let apiResponse = yield call(
      uploadAPI,
      data.imageBlob,
      organizationId,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.CREATED === apiResponse.status) {
      let brandObj = _.cloneDeep(configReducer.brandData);
      switch (data.trigger) {
        case "login":
          brandObj.login_img_path = apiResponse.data.logo_img_path;
          brandObj.imageUpload = true;
          brandObj.brandingLogo = false;
          yield put({
            type: action,
            data: apiResponse.data
          });
          break;
        case "brand":
          brandObj.logo_img_path = apiResponse.data.logo_img_path;
          brandObj.imageUpload = true;
          yield put({
            type: action,
            data: {
              ...apiResponse.data,
              image_url: `${apiResponse.data.image_url}?refresh=${Date.now()}`
            }
          });
          break;
        default:
          break;
      }
      yield* updateBrandDetails({ data: brandObj });
    }
    //apiResponse.context = constant.API_RESPONSE_CONTEXT.UPDATE_BRANDING_DETAILS;
    //yield put(addAPIresponse(apiResponse));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* getCustomerList() {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    let apiResponse = yield call(
      configureApi.fetchCustomerList,
      organizationId,
      stateLogin.token
    );
    if (apiResponse.status !== constant.HTTP_STATUS.OK) {
      yield put(addAPIresponse(apiResponse));
    } else {
      yield put(updateCustomerList(apiResponse.data));
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* getMSPChildAccounts() {
  try {
    const stateLogin = yield select(getLogin);
    yield put(setLoading(Loader.MANAGE_MSP_USER_PERMISSIONS));
    const organizationId = stateLogin.organization_id;
    let apiResponse = yield call(
      configureApi.fetchMSPChildAccounts,
      organizationId,
      stateLogin.token
    );
    if (apiResponse.status !== constant.HTTP_STATUS.OK) {
      yield put(addAPIresponse(apiResponse));
    } else {
      yield put(updateMSPChildAccounts(apiResponse.data));
    }
    yield put(resetLoading(Loader.MANAGE_MSP_USER_PERMISSIONS));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}
/**
 * to get count for source group, user accounts and roles
 */
export function* getConfigureCount() {
  try {
    const stateReducerForMenu = yield select(getReducer);
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    let makeQueryString = `organization_id=${organizationId}`;
    let hypervisorsApiCount = { hypervisors: 0 };
    let sitesCounts = { sites: 0 };
    if (stateLogin.organization_type !== "msp") {
      //bug 935204 => when organization_type=="msp" not calling for hypervisors count , as msp dont need hypervisors count
      let hypervisorCountResponse = yield call(
        configureApi.getHypervisorCounts,
        makeQueryString,
        stateLogin.token
      );
      if (hypervisorCountResponse.status === constant.HTTP_STATUS.OK) {
        hypervisorsApiCount.hypervisors =
          hypervisorCountResponse.data && hypervisorCountResponse.data.amount;
      }

      //Sites count
      let orgId = yield select(getOrgId);
      let sitesResponse = yield call(
        protectApi.getSites,
        1,
        20,
        orgId,
        stateLogin.token,
        false,
        "site_type=gateway"
      );
      if (sitesResponse.status === constant.HTTP_STATUS.OK) {
        sitesCounts.sites = _.get(sitesResponse, "pagination.total_size", 0);
      }
    }
    // get source groups count
    let groupsCountResponse = yield call(
      configureApi.getSourceGroupCounts,
      makeQueryString,
      stateLogin.token
    );
    let sourceGroupsApiCount = { source_groups: 0 };
    if (groupsCountResponse.status === constant.HTTP_STATUS.OK) {
      sourceGroupsApiCount.source_groups =
        groupsCountResponse.data && groupsCountResponse.data.amount;
    }

    // get user accounts count
    let usersCountResponse = yield call(
      configureApi.getUserCounts,
      makeQueryString,
      stateLogin.token
    );
    let usersApiCount = { user_accounts: 0 };
    if (usersCountResponse.status === constant.HTTP_STATUS.OK) {
      usersApiCount.user_accounts =
        usersCountResponse.data && usersCountResponse.data.amount;
    }

    // get roles count
    let rolesCountResponse = yield call(
      configureApi.getRoleCounts,
      makeQueryString,
      stateLogin.token,
      stateLogin
    );
    let rolesApiCount = { user_roles: 0 };
    if (rolesCountResponse.status === constant.HTTP_STATUS.OK) {
      rolesApiCount.user_roles =
        rolesCountResponse.data && rolesCountResponse.data.amount;
    }
    let orgId = yield select(getOrgId);
    // const sitesCount = { sites: sites.pagination.total_size };

    let storageArrayCount = 0;
    let proxiesCount = 0;
    if (_.get(stateLogin, "role_id") !== "msp_admin") {
      let storageArrays = yield call(
        configureApi.fetchStorageArrayList,
        `organization_id=${orgId}&pagination=false`,
        stateLogin.token,
        false
      );
      storageArrayCount = {
        storage_arrays: storageArrays.pagination.total_size
      };
      // Proxies count
      let proxies = yield call(
        configureApi.loadConfigureProxies,
        1,
        25,
        "organization_id=" + orgId,
        stateLogin.token
      );
      proxiesCount = { proxies: proxies.pagination.total_size };
    }

    let oracleHostsCount = 0;
    const { rman: isRMANVisible } = yield select(getFeatureFlag);
    if (isRMANVisible) {
      const oracleHosts = yield call(
        protectApi.fetchSourcesList,
        `source_type=${LinuxSourceConstant.OracleSourceAttribute.ORACLE_WINDOW_SOURCE_TYPE}%7C${LinuxSourceConstant.OracleSourceAttribute.ORACLE_LINUX_SOURCE_TYPE}&organization_id=${orgId}`,
        stateLogin.token
      );
      oracleHostsCount = oracleHosts.data.length;
    }
    oracleHostsCount = { oracle_hosts: oracleHostsCount };
    const totalCount = Object.assign(
      {},
      sitesCounts,
      proxiesCount,
      hypervisorsApiCount,
      sourceGroupsApiCount,
      usersApiCount,
      rolesApiCount,
      storageArrayCount,
      oracleHostsCount
    );
    yield put(
      updateConfigureCount(
        totalCount,
        stateReducerForMenu.menu,
        stateReducerForMenu.primaryNavigation
      )
    );
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/***
 * Add assigned customers to user
 */
export function* addCustomersToUser(action) {
  try {
    const loginReducer = yield select(getLogin);
    const customerData = action.data;
    const contextualAction = yield select(getContextualAction);
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.assignCustomersToUser,
      action.userId,
      customerData,
      loginReducer.token
    );
    if (constant.HTTP_STATUS.OK === apiResponse.status) {
      apiResponse.status = 200;
      // apiResponse.context =
      //   "configure.accessControl.userAccounts.customersAssigned";
      const toastData = {
        messageId: "configure.accessControl.userAccounts.assignAccountsSuccess",
        testId: "2NRzer"
      };
      const email =
        contextualAction.additionalProperties.item[
          userAccountsAttributes.USER_ACCOUNTS_EMAIL
        ];
      yield put(ccToast.addSuccessNotification(toastData, { email }));
    } else {
      //Error Notification
      if (constant.HTTP_STATUS.INTERNAL_SERVER_ERROR === apiResponse.status) {
        const toastData = {
          messageId: "toast-errors.internal-error",
          testId: `u16NGn_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      } else {
        const error = _.get(apiResponse, "errors[0].message");
        const toastData = {
          messageId: "configure.accessControl.userAccounts.assignAccountsError",
          testId: `u16NGn_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData, { error }));
      }
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* downloadAgents() {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    let apiResponse = yield call(
      configureApi.downloadAgents,
      organizationId,
      stateLogin.token
    );
    yield put(addAPIresponse(apiResponse));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* refreshVMs(data) {
  try {
    const loginReducer = yield select(getLogin);
    let apiResponse = yield call(
      configureApi.refreshVMs,
      data.id,
      loginReducer.token
    );
    apiResponse.context = "refreshed";
    yield put(addAPIresponse(apiResponse));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

export function* getDestinationsForDropdowns() {
  try {
    const loginReducer = yield select(getLogin);
    const stateReducer = yield select(getReducer);
    const organizationId = stateReducer.isImpersonationView
      ? stateReducer.customerOrgId
      : loginReducer.organization_id;
    let apiResponse = yield call(
      configureApi.getDestinationsForDropdowns,
      `destination_type=cloud_direct_volume&organization_id=${organizationId}`,
      loginReducer.token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield put(updatedDestinationsForDropdown(apiResponse.data));
    }
    yield put(addAPIresponse(apiResponse));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/***
 * get customers for user
 */
export function* getCustomersForUser(action) {
  try {
    let filteredCustomers = [];
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    const allCustomers = yield call(
      configureApi.fetchCustomerAccounts,
      "",
      stateLogin.token,
      organizationId
    );

    let customersForSelectedUser = yield call(
      configureApi.getCustomersByUser,
      action.userId,
      stateLogin.token
    );
    if (constant.HTTP_STATUS.OK === customersForSelectedUser.status) {
      const customers = customersForSelectedUser.data;
      const customerIds = [];
      customers.forEach(cust => {
        customerIds.push(cust.organization_id);
      });
      //ARC001-2211: Used lodash as .includes was not working as expected.
      filteredCustomers = _.filter(
        allCustomers.data,
        v => !_.includes(customerIds, v.organization_id) && !v.sub_msp
      );
    }
    yield put(updateCustomersToAssign(filteredCustomers));
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get list of network configured port forwarding list
 * @param action
 */
export function* getNetworkPortForwardList(action) {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    yield put(showLoading());
    let list = yield call(
      configureApi.fetchNetworkPortForwardDetails,
      organizationId,
      stateLogin.cdtoken,
      action.data
    );
    if (list && list.data) {
      yield put(showNetworkPortForwardDetails(list.data));
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get list of network configured vms
 * @param action
 */
export function* getNetworkConfigList(action) {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    yield put(showLoading());
    let list = yield call(
      configureApi.fetchNetworkConfigList,
      organizationId,
      stateLogin.cdtoken,
      action.data
    );
    if (list && list.status === constant.HTTP_STATUS.SUCCESS) {
      yield put(showNetworkConfigList(list.data));
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    window.console.log(e);
  }
}

/*
 * To edit list of network configured vms
 * @param action
 */
export function* editNetworkConfigList(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let details = yield call(
      configureApi.updateNetworkConfigList,
      action.data,
      action.data.recoveredresource_id,
      loginReducer.cdtoken
    );
    if (details.status === constant.HTTP_STATUS.SUCCESS) {
      const toastData = {
        messageId: "network.configuration.update.success",
        testId: "nvbT2A"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield put({
        type: actionType.UPDATE_NETWORK_CONFIG_LIST,
        data: details.data
      });
      yield* getNetworkConfigList({ data: details.data });
      // details.context = constant.API_RESPONSE_CONTEXT.NETWORK_CONFIG_UPDATE;
      yield put(addAPIresponse(details));
    } else {
      // details.context = constant.API_RESPONSE_CONTEXT.NETWORK_CONFIG;
      yield* showErrorForPortForwading(details, "yLUD6H");
      yield put(
        addAPIresponse({
          status: constant.HTTP_STATUS.BAD_REQUEST,
          errors: [details],
          context: details.context
        })
      );
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    window.console.log(e);
  }
}

function* showErrorForPortForwading(apiResponse, testId) {
  try {
    const apiErrorCode = apiResponse.code;
    if (apiErrorCode) {
      if (PORT_FORWORDING_ERR_CODES.includes(apiErrorCode)) {
        const toastData = {
          messageId: `error.portforwarding.${apiErrorCode}`,
          testId: `${testId}_${apiResponse.status}`
        };
        yield put(ccToast.addErrorNotification(toastData));
      } else {
        if (apiResponse.message.split(": ")[1]) {
          let value = apiResponse.message.split(": ")[1];
          const toastData = {
            messageId: "error.portforwarding",
            testId: `${testId}_${apiResponse.status}`
          };
          yield put(
            ccToast.addErrorNotification(toastData, {
              value: getFormattedMessage(
                `configure.network.configuration.${value}`
              )
            })
          );
        }
      }
    } else {
      const toastData = {
        messageId: _.get(
          apiResponse,
          "errors[0].message",
          "toast-errors.internal-error"
        ),
        testId: `${testId}_${apiResponse.status}`
      };
      yield put(ccToast.addErrorNotification(toastData));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To delete list of network configured vms
 */
export function* deleteUpdatedNetworkList(action) {
  try {
    const loginReducer = yield select(getLogin);
    const id = action.data.recoveredresource_id
      ? action.data.recoveredresource_id
      : null;
    yield put(showLoading());
    const apiResponse = yield call(
      configureApi.deleteNetworkConfigRow,
      id,
      loginReducer.cdtoken,
      action.data
    );
    if (constant.HTTP_STATUS.SUCCESS === apiResponse.status) {
      const toastData = {
        messageId: "network.configuration.update.delete",
        testId: "XoaNcY"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield put(
        deleteUpdateNetworkConfigGridList({
          id: id,
          data: action.data,
          typeId: "recoveredresource_id",
          rowId: action.data.row_id
        })
      );
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.NETWORK_CONFIG_DELETE;

      yield put(addAPIresponse(apiResponse));
    } else {
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.NETWORK_CONFIG;
      yield* showErrorForPortForwading(apiResponse, "Jb0NuQ");
      yield put(
        addAPIresponse({
          status: constant.HTTP_STATUS.BAD_REQUEST,
          errors: [apiResponse],
          context: apiResponse.context
        })
      );
    }
    yield put(hideLoading());
  } catch (e) {
    window.console.log(e);
  }
}
/**
 * Add new list for network configuration
 */
export function* addNetworkList(action) {
  try {
    const loginReducer = yield select(getLogin);

    yield put(showLoading()); //  show loader
    const apiResponse = yield call(
      configureApi.addNetworkConfigurationList,
      action.data,
      action.data.recoveredresource_id,
      loginReducer.cdtoken
    );
    if (constant.HTTP_STATUS.SUCCESS === apiResponse.status) {
      const toastData = {
        messageId: "network.configuration.add.success",
        testId: "rSsjCH"
      };
      yield put(ccToast.addSuccessNotification(toastData));

      yield put(
        addNetworkPortForwardDetails({
          data: apiResponse
        })
      );
      yield* getNetworkConfigList({ data: apiResponse });
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.NETWORK_CONFIG_ADD;
      yield put(addAPIresponse(apiResponse));
    } else {
      // apiResponse.context = constant.API_RESPONSE_CONTEXT.NETWORK_CONFIG;
      yield* showErrorForPortForwading(apiResponse, "LcV8y3");

      yield put(
        addAPIresponse({
          status: constant.HTTP_STATUS.BAD_REQUEST,
          errors: [apiResponse],
          context: apiResponse.context
        })
      );
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get session logs of network configuration
 * @param action
 */
export function* getNetworkSessionLogs(action) {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    let apiResponse = yield call(
      configureApi.downloadNetworkConfigSessionLogs,
      organizationId,
      stateLogin.cdtoken,
      action.data
    );
    if (apiResponse && !apiResponse.includes('"status":"ERROR"')) {
      storeDataToTxt(apiResponse, "text/plain", "sessionLogs.txt");
    } else {
      let parseApiResponse = JSON.parse(apiResponse);
      yield put(
        addAPIresponse({
          status: constant.HTTP_STATUS.BAD_REQUEST,
          errors: [{ message: parseApiResponse.message }]
        })
      );
      const toastData = {
        messageId: parseApiResponse.message,
        testId: `SWPRcr_${apiResponse.status}`
      };
      yield put(ccToast.addErrorNotification(toastData));
    }
  } catch (e) {
    window.console.log(e);
  }
}

/**
 * To get session logs of network configuration
 * @param action
 */
export function* getVPNdetails() {
  try {
    const stateLogin = yield select(getLogin);
    const organizationId = stateLogin.organization_id;
    yield put(showLoading()); //  show loader
    let apiResponse = yield call(
      configureApi.fetchOrganizationVPNDetails,
      organizationId,
      stateLogin.cdtoken
    );
    if (apiResponse.status === "SUCCESS") {
      yield put(updateVPNDetails(apiResponse.data));
    } else {
      yield put(
        addAPIresponse({
          status: constant.HTTP_STATUS.BAD_REQUEST,
          errors: [{ message: apiResponse.message }]
        })
      );
    }
    yield put(hideLoading()); //  hide loader
  } catch (e) {
    window.console.log(e);
  }
}

export function* deleteHypervisorByID(data) {
  try {
    const stateLogin = yield select(getLogin);
    const token = stateLogin.token;
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.deleteHypervisor,
      data.id,
      token,
      { showCommonError: true, testId: "sPRZlp" }
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.DELETE_HYPERVISOR_SUCCESS;
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.DELETE_HYPERVISOR_SUCCESS,
        testId: "ny2f2C"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.HYPERVISOR
      });
      let mainType = "Configure";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.HYPERVISORS];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "remove" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* deleteStorageArrayByID(data) {
  try {
    const stateLogin = yield select(getLogin);
    const token = stateLogin.token;
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.deleteStorageArray,
      data.id,
      token
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.DELETE_STORAGE_ARRAY_SUCCESS,
        testId: "5kIjOV"
      };

      yield put(ccToastActions.addSuccessNotification(toastData));

      yield call(getStorageArrays, {
        data: { gridCurrentPage: 1, gridPageSize: 25 }
      });

      // apiResponse.context =
      //   constant.API_RESPONSE_CONTEXT.DELETE_STORAGE_ARRAY_SUCCESS;
      yield call(getStorageArrays, {
        data: { gridCurrentPage: 1, gridPageSize: 25 }
      });
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.STORAGE_ARRAYS });
      yield put(getConfigureListCount());
    } else {
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.DELETE_STORAGE_ARRAY_SUCCESS,
        testId: `7hRJtr_${apiResponse.status}`
      };
      yield put(ccToast.addErrorNotification(toastData));
    }
    yield put(addAPIresponse(apiResponse));
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

function getOptions(searchCriteria, array) {
  array.forEach(value => {
    switch (value.type) {
      case "date-picker":
        if (
          value.selectedOptions &&
          Object.keys(value.selectedOptions).length
        ) {
          searchCriteria.push(
            `${value.key}=%3E${value.selectedOptions.start}&${value.key}=%3C${value.selectedOptions.end}`
          );
        }
        break;

      case "date-range-picker":
        if (
          value.selectedOptions &&
          Object.keys(value.selectedOptions).length
        ) {
          let { startTime, endTime } = getTimestamp(value.selectedOptions);
          if (startTime && endTime)
            searchCriteria.push(
              `${value.key}=%3E${startTime}&${value.key}=%3C${endTime}&${
                constant.TIME_RESOLUTION
              }=${constant.TIME_RESOLUTION_PARAMS[value.selectedOptions.type]}`
            );
        }
        break;
      default:
        if (value.selectedOptions && value.selectedOptions.length) {
          let optionArr = value.selectedOptions.map(item =>
            item.id ? item.id : item.value
          );
          searchCriteria.push(
            `${value.key}=${encodeURIComponent(optionArr.join("|"))}`
          );
        }
        break;
    }
  });
}

function* loadDefaultDeploymentSaga() {
  try {
    const login = yield select(getLogin);
    const orgId = yield select(getOrgId);
    const resp = yield call(
      configureApi.fetchDefaultDeployment,
      orgId,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadDefaultDeploymentSuccess(resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (error) {
    window.console.log(error);
  }
}

function* updateDefaultDeploymentSaga() {
  try {
    yield put(setLoading(Loader.UPDATE_DEFAULT_DEPLOYMENT));
    const login = yield select(getLogin);
    const formValues = yield select(getFormValues("defaultDeployment"));
    const {
      configuration_id,
      configuration_type,
      organization_id
    } = yield select(deployConfig);
    const data = {
      ...formValues,
      configuration_id,
      configuration_type,
      organization_id
    };
    const resp = yield call(
      configureApi.putDefaultDeployment,
      configuration_id,
      data,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.DEPLOYMENT_CONFIG_UPDATE_SUCCESS,
            "6Yy950"
          )
        )
      );
      yield put(loadDefaultDeploymentSuccess(resp.data));
    } else {
      yield put(addAPIresponse(resp));
    }
  } catch (error) {
    window.console.log(error);
  } finally {
    yield put(resetLoading(Loader.UPDATE_DEFAULT_DEPLOYMENT));
  }
}

export function* updateVMInformationByID(data) {
  try {
    const stateLogin = yield select(getLogin);
    const token = stateLogin.token;
    yield put(showLoading());
    let apiResponse = yield call(
      configureApi.updateVMInformation,
      data.id,
      token,
      { showCommonError: true, testId: "hdkdp7" }
    );
    if (apiResponse.status === constant.HTTP_STATUS.OK) {
      yield call(getHypervisorsList, {
        data: { gridCurrentPage: 1, gridPageSize: 25 }
      });
      const toastData = {
        messageId: "configure.hypervisors.update_vm_information.success",
        testId: "ALoJi9"
      };
      yield put(ccToastActions.addSuccessNotification(toastData));
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(hideLoading());
  }
}

export function* updateStorageArrayByID(data) {
  const formName = "modifyStorageArrays";
  try {
    const stateLogin = yield select(getLogin);
    const token = stateLogin.token;
    yield put(showLoading());
    let apiResponse = undefined;

    let applianceType = _.get(data.data, "vendor");
    let updatedData = {
      site_id: data.data.site_id,
      vendor: data.data.vendor,
      username: data.data.username,
      password: data.data.password,
      port: data.data.port,
      protocol: data.data.protocol,
      storage_array_ip: data.data.storage_array_ip
    };
    if (applianceType === constant.STORAGE_VENDORS_LIST.NetApp) {
      updatedData = {
        ...updatedData,
        netapp_info: {
          data_ip: data.data.data_ip,
          appliance_mode: data.data.mode
        }
      };
    } else if (applianceType === constant.STORAGE_VENDORS_LIST.Nimble) {
      updatedData = {
        ...updatedData,
        nimble_info: {
          data_ip: data.data.data_ip
        }
      };
    } else if (applianceType === constant.STORAGE_VENDORS_LIST.HP_RMC) {
      updatedData = {
        ...updatedData,
        hprmc_info: {
          rmc_ip: data.data.storage_array_ip
        }
      };
    }

    if (data.data.modify) {
      apiResponse = yield call(
        configureApi.updateStorageArray,
        data.data.storage_array_id,
        token,
        updatedData,
        { showCommonError: true, testId: "TZogmu" }
      );
    } else {
      apiResponse = yield call(
        configureApi.addStorageArray,
        token,
        updatedData,
        { showCommonError: true, testId: "dUBcla" }
      );
    }

    if (apiResponse.status === constant.HTTP_STATUS.CREATED) {
      const toastData = {
        messageId: "configure.storagearray.create_storagearray.success",
        testId: "6vmBSC"
      };
      yield put(ccToastActions.addSuccessNotification(toastData));
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.STORAGE_ARRAYS });

      yield put(hideDialog(Dialog.ADD_STORAGE_ARRAY));
    } else if (apiResponse.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: "configure.storagearray.update_storagearray.success",
        testId: "nUataZ"
      };
      yield put(ccToastActions.addSuccessNotification(toastData));
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.STORAGE_ARRAYS });

      yield put(getConfigureListCount());
      yield put(hideDialog(Dialog.MODIFY_STORAGE_ARRAY));
    }
    yield put(hideLoading());
    yield put(stopSubmit(formName));
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
    yield put(stopSubmit(formName));
    yield put(hideLoading());
  }
}

function* loadSourceDiscoverySettingsSaga() {
  try {
    let login = yield select(getLogin);
    let orgId = yield select(getOrgId);
    let resp = yield call(
      configureApi.fetchSourceDiscoverySettings,
      orgId,
      login.token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.LOAD_SOURCE_DISCOVERY_SETTINGS;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(loadSourceDiscoverySettingsSuccess(resp.data));
    }
    yield call(showErrToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  }
}

function getTimeString(hour, minute) {
  let time = moment(`${hour}:${minute}`, "HH:mm")
    .utc()
    .format("HH:mm");
  return time;
}

function* saveSourceDiscoverySettingsSaga() {
  try {
    yield put(setLoading(Loader.SAVE_SOURCE_DISCOVERY_SETTINGS));
    let login = yield select(getLogin);
    let values = yield select(getFormValues("sourceDiscoverySettings"));
    let prevResp = yield select(
      state =>
        state && state.settings && state.settings.sourceDiscoverySettingsResp
    );
    if (!prevResp) {
      return;
    }
    let data = {
      organization_id: prevResp.organization_id,
      configuration_id: prevResp.configuration_id,
      discovery_status: values.sourceDiscovery,
      discovery_repeat_method: values.repeatMethod.value,
      discovery_schedule_time: getTimeString(values.hour, values.minute),
      configuration_type: prevResp.configuration_type
    };
    //eslint-disable-next-line default-case
    switch (data.discovery_repeat_method) {
      case "everyNumberOfDays":
        data.discovery_day_interval = values.every;
        break;
      case "everySelectedDaysOfWeek":
        // API accepts only 1 to 7 whereas
        // component only works on 0 to 6
        data.discovery_days_of_week = values.everyDaysOfWeek
          .split(",")
          .map(c => parseInt(c) + 1)
          .join(",");
        break;
      case "everySelectedDayOfMonth":
        data.discovery_day_of_month = values.every;
        break;
      case "everyNumberOfHours":
        data.discovery_hour_interval = values.every;
        break;
    }
    let resp = yield call(
      configureApi.putSourceDiscoverySettings,
      prevResp.configuration_id,
      data,
      login.token
    );
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT
              .SAVE_SOURCE_DISCOVERY_SETTINGS_SUCCESS,
            "v2JLP4"
          )
        )
      );
      yield put(loadSourceDiscoverySettingsSuccess(resp.data));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.SAVE_SOURCE_DISCOVERY_SETTINGS));
  }
}

function* fetchSourceDiscoveryListSaga() {
  try {
    let login = yield select(getLogin);
    let orgId = yield select(getOrgId);
    let queryString = `organization_id=${orgId}`;
    yield put(startLoader());
    yield put(
      startGridLoaderById(constant.DATAGRID_IDS["source_discovery_grid"])
    );
    let resp = yield call(
      configureApi.fetchSourceDiscoveryList,
      queryString,
      login.token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.LOAD_SOURCE_DISCOVERY_SETTINGS;
    yield call(showErrToastFromResponse, resp);
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryListSuccess(resp.data));
      yield put(clearStateSelectedRows());
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
    yield put(
      stopGridLoaderById(constant.DATAGRID_IDS["source_discovery_grid"])
    );
  }
}

function* addDiscoverFromADSaga() {
  try {
    let login = yield select(getLogin);
    let orgId = yield select(getOrgId);
    let values = yield select(getFormValues("discoverFromAd"));
    yield put(setLoading(Loader.ADD_DISCOVER_FROM_AD));
    let data = {
      site_id: values.site.key,
      domain_controller: values.domain_controller,
      username: values.username,
      password: values.password,
      computer_name_filter: values.computer_name_filter,
      organization_id: orgId
    };
    let resp = yield call(configureApi.postDiscoverFromAD, data, login.token);
    resp.context = constant.API_RESPONSE_CONTEXT.DISCOVER_FROM_AD;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryList());
    }
    yield call(showToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog(Dialog.DISCOVER_FROM_AD));
    yield put(resetLoading(Loader.ADD_DISCOVER_FROM_AD));
  }
}

function* addDiscoverFromHyperVSaga() {
  try {
    let login = yield select(getLogin);
    let values = yield select(getFormValues("discoverFromHyperV"));
    yield put(setLoading(Loader.ADD_DISCOVER_FROM_HYPERV));
    let data = {
      hypervisor_id: values.hostname
    };
    let resp = yield call(
      configureApi.postDiscoverFromHyperV,
      data,
      login.token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.DISCOVER_FROM_HYPERV;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryList());
    }
    yield call(showToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog(Dialog.DISCOVER_FROM_HYPERV));
    yield put(resetLoading(Loader.ADD_DISCOVER_FROM_HYPERV));
  }
}

function* addDiscoverFromVmwareSaga() {
  try {
    let login = yield select(getLogin);
    let values = yield select(getFormValues("discoverFromHyperV"));
    yield put(setLoading(Loader.ADD_DISCOVER_FROM_HYPERV));
    let data = {
      hypervisor_id: values.hostname
    };
    let resp = yield call(
      configureApi.postDiscoverFromVmware,
      data,
      login.token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.DISCOVER_FROM_HYPERV;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryList());
    }
    yield call(showToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog(Dialog.DISCOVER_FROM_HYPERV));
    yield put(resetLoading(Loader.ADD_DISCOVER_FROM_HYPERV));
  }
}

function* addDiscoverFromNutanixSaga() {
  try {
    const login = yield select(getLogin);
    const values = yield select(getFormValues("discoverFromHyperV"));
    yield put(setLoading(Loader.ADD_DISCOVER_FROM_HYPERV));
    const data = {
      hypervisor_id: values.hostname
    };
    const resp = yield call(
      configureApi.postDiscoverFromNutanix,
      data,
      login.token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.DISCOVER_FROM_HYPERV;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryList());
    }
    yield call(showToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog(Dialog.DISCOVER_FROM_HYPERV));
    yield put(resetLoading(Loader.ADD_DISCOVER_FROM_HYPERV));
  }
}

function* sourceDiscoveryRunNowSaga() {
  try {
    let login = yield select(getLogin);
    let selectedIds = yield select(makeCheckboxSelectionState());
    let data = selectedIds.map(row => ({
      discovery_id: row.id,
      discovery_type: row.discovery_type
    }));
    let resp = yield call(
      configureApi.postSourceDiscoveryRunNow,
      data,
      login.token
    );
    resp.context = constant.API_RESPONSE_CONTEXT.SOURCE_DISCOVERY_RUN_NOW;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryList());
    }
    yield call(showToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  }
}

function* deleteSourceDiscoverySaga({ data }) {
  try {
    let login = yield select(getLogin);
    let resp;
    yield put(setLoading(Loader.DELETE_SOURCE_DISCOVERY));
    if (data.length === 1) {
      resp = yield call(
        configureApi.deleteSourceDiscovery,
        data[0],
        login.token
      );
    } else {
      resp = yield call(
        configureApi.deleteSourceDiscoveryList,
        data,
        login.token
      );
    }
    resp.context = constant.API_RESPONSE_CONTEXT.DELETE_SOURCE_DISCOVERY;
    if (resp.status === constant.HTTP_STATUS.OK) {
      yield put(fetchSourceDiscoveryList());
    }
    yield call(showToastFromResponse, resp);
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(hideDialog(Dialog.DELETE_SOURCE_DISCOVERY));
    yield put(resetLoading(Loader.DELETE_SOURCE_DISCOVERY));
  }
}

/**
 * THis function will update Hyper-V OR VMware hypervisor details such as hypervisor name, port, protocol, username & password
 * @param {data} contains the updateHypervisor form data for api to send in request.
 */
export function* updateHyperVOrVMwareSaga(action) {
  try {
    yield put(setLoading(Loader.UPDATE_HYPERVISORS));
    let login = yield select(getLogin);
    let { id, data } = action;
    let response = [];
    if (["esxhost", "vcenter", "vmware"].includes(data.obj.hypervisor_type)) {
      let payload = {
        server_name: data.hypervisor_name,
        protocol: data.protocol,
        port: data.port,
        username: data.username,
        password: data.password
      };
      response = yield call(
        configureApi.updateVMwareVCenterESXHost,
        id,
        payload,
        login.token,
        { showCommonError: true, testId: "kaYPC2" }
      );
    } else if (data && data.hypervisor_type === "hyperv") {
      let payload = {
        username: data.username,
        password: data.password,
        host: data.hypervisor_name
      };
      response = yield call(
        configureApi.updateHyperV,
        id,
        payload,
        login.token,
        { showCommonError: true, testId: "RXQKjO" }
      );
    } else if (data && data.hypervisor_type.toLowerCase() === "nutanix") {
      let payload = {
        server_name: data.hypervisor_name,
        protocol: data.protocol,
        port: data.port,
        username: data.username,
        password: data.password
      };
      response = yield call(
        configureApi.updateNutanixAHV,
        id,
        payload,
        login.token,
        { showCommonError: true, testId: "xO6Zsy" }
      );
    }

    if (response && response.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId:
          "configure.infrastructure.hypervisors.update.success.message",
        testId: "QRXQzX"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.HYPERVISOR });
    }
  } catch (error) {
    window.console.log(error);
  } finally {
    yield put(reset("updateHypervisors"));
    yield put(resetLoading(Loader.UPDATE_HYPERVISORS));
    yield put(hideDialog(Dialog.UPDATE_HYPERVISORS));
  }
}

/**
 * This function will update proxy details such as proxy name, port, protocol
 * @param {data} contains the updateProxy form data for api to send in request.
 */
export function* updateProxySaga(action) {
  try {
    yield put(setLoading(Loader.UPDATE_PROXY));
    let login = yield select(getLogin);
    let { data } = action;
    let response = [];
    let payload = {};
    if (
      data.server_role_types &&
      data.server_role_types.includes(constant.PROXY_ROLES.LINUX_BACKUP_SERVER)
    ) {
      payload = {
        proxy_name: data.proxy_name,
        protocol: data.protocol,
        port: data.port,
        username: data.username,
        password: data.password
      };
    } else {
      payload = {
        username: data.username,
        password: data.password,
        proxy_name: data.proxy_name
      };
    }

    response = yield call(
      configureApi.updateProxies,
      data.proxy_id,
      payload,
      login.token,
      { showCommonError: true, testId: "qQENEo" }
    );

    if (response && response.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: "configure.infrastructure.proxy.update.success.message",
        testId: "Dr0IAZ"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.PROXIES });
    }
  } catch (error) {
    window.console.log(error);
  } finally {
    yield put(reset("updateProxy"));
    yield put(resetLoading(Loader.UPDATE_PROXY));
    yield put(hideDialog(Dialog.UPDATE_PROXY));
  }
}

export function* removeProxy(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      configureApi.deleteProxyById,
      action.data.proxy_id,
      loginReducer.token,
      { showCommonError: true, testId: "bwxzYT" }
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      const toastData = {
        messageId: constant.API_RESPONSE_CONTEXT.PROXY_DELETE_SUCCESS,
        testId: "VijAsk"
      };
      yield put(ccToast.addSuccessNotification(toastData));
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.PROXIES });
      yield put(getConfigureListCount());
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* editOrg(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      configureApi.editOrganization,
      loginReducer.organization_id,
      action.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            action.data.two_factor_required
              ? constant.API_RESPONSE_CONTEXT.TWO_FACTOR_REQUIRED_SUCCESS
              : constant.API_RESPONSE_CONTEXT.TWO_FACTOR_NOT_REQUIRED_SUCCESS,
            "ZBUZXR"
          )
        )
      );
      yield put(getOrganization(loginReducer.organization_id));
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `4NeTFK_${response?.status}`
          )
        )
      );
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

export function* twoFactorDisable(action) {
  try {
    const loginReducer = yield select(getLogin);
    yield put(showLoading());
    let response = yield call(
      configureApi.disableTwoFactor,
      action.id,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.TWO_FACTOR_RESET_SUCCESS,
            "DfFnIF"
          )
        )
      );
    } else {
      yield put(
        ccToast.addErrorNotification(
          makeToastData(
            _.get(response, "errors[0].message", ""),
            `875jjC_${response?.status}`
          )
        )
      );
    }
  } catch (error) {
    yield put(
      addAPIresponse({
        status: constant.HTTP_STATUS.BAD_REQUEST,
        errors: [{ message: error }]
      })
    );
  }
}

/**
 * Upgrade site saga handler
 * @param {Object} data
 */
export function* upgradeSite(data) {
  yield put(startLoader());
  try {
    const loginReducer = yield select(getLogin);
    let response = yield call(
      configureApi.upgradeSite,
      data.data,
      loginReducer.token
    );
    if (response.status === constant.HTTP_STATUS.OK) {
      yield* fetchData({ ccDataGridId: constant.CCDATAGRID_ID.SITES });
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.UPGRADE_SITE_SUCCESS,
            "G6YPuw"
          )
        )
      );
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(stopLoader());
  }
}

export function* addHypervisorSaga() {
  try {
    yield put(setLoading(Loader.ADD_HYPERVISOR));
    const loginReducer = yield select(getLogin);
    let token = _.get(loginReducer, "token", null);
    let formValues = yield select(getFormValues("addHypervisor"));
    let hypervisor_type = _.get(formValues, "hypervisor_type", false);

    let resp = null;
    if (hypervisor_type && hypervisor_type === "hyperv") {
      let payload = {
        site_id: formValues.site.key,
        host: formValues.vcenter_esx_ip,
        username: formValues.username,
        password: formValues.password
      };
      resp = yield call(configureApi.addHypervisorHyperVApi, payload, token);
    } else if (hypervisor_type && hypervisor_type === "vcenter_esx") {
      let payload = {
        site_id: formValues.site.key,
        server_name: formValues.vcenter_esx_ip,
        username: formValues.username,
        password: formValues.password,
        protocol: formValues.protocol,
        port: formValues.port
      };
      resp = yield call(configureApi.addHypervisorVmwareApi, payload, token);
    } else if (hypervisor_type && hypervisor_type === "nutanix") {
      let payload = {
        site_id: formValues.site.key,
        server_name: formValues.vcenter_esx_ip,
        username: formValues.username,
        password: formValues.password,
        protocol: formValues.protocol,
        port: formValues.port
      };
      resp = yield call(configureApi.addHypervisorNutanixApi, payload, token);
    }

    if (resp.status === constant.HTTP_STATUS.CREATED) {
      yield put(
        ccToast.addSuccessNotification(
          makeToastData(
            constant.API_RESPONSE_CONTEXT.ADD_HYPERVISOR_SUCCESS,
            "a6RHxu"
          )
        )
      );
      yield* fetchData({
        ccDataGridId: constant.CCDATAGRID_ID.HYPERVISOR
      });
      let mainType = "Configure";
      let sendTypesArray = [constant.SECONDARY_NAVIGATION_TYPE.HYPERVISORS];
      let tempUpdateMenuKeys = { types: sendTypesArray, action: "add" };
      let payloadLength = 1;
      yield put(
        updateMenuCountsOnAddRemoveActions({
          type: mainType,
          updateMenuKeys: tempUpdateMenuKeys,
          count: payloadLength
        })
      );
      yield put(hideDialog(Dialog.ADD_HYPERVISOR));
      yield put(reset("addHypervisor"));
    }
  } catch (e) {
    window.console.log(e);
  } finally {
    yield put(resetLoading(Loader.ADD_HYPERVISOR));
  }
}

export default function* configureSaga() {
  yield all([
    takeLatest(actionType.GET_STORAGE_ARRAYS, getStorageArrays),
    takeLatest(actionType.GET_HYPERVISORS_LIST, getHypervisorsList),
    takeEvery(actionType.GET_HYPERVISOR_DETAIL, getHypervisorDetail),
    takeEvery(actionType.LOAD_CONFIGURE_PROXIES, loadConfigureProxies),
    takeLatest(
      actionType.GET_DESTINATIONS_FOR_DROPDOWNS,
      getDestinationsForDropdowns
    ),
    takeEvery(
      actionType.EDIT_UPDATE_HYPERVISOR_INFORMATION,
      editUpdateHypervisorInformation
    ),
    takeEvery(actionType.GET_ARCSERVE_CLOUD_DETAILS, getArcserveCloudDetails),
    takeEvery(actionType.GET_ARCSERVE_CLOUD_VOLUME, receiveArcserveCloudVolume),
    takeEvery(actionType.GET_CLOUD_ACCOUNTS_LIST, receiveInfrastructureList),
    takeEvery(
      actionType.GET_INFRASTRUCTURE_LIST_COUNT,
      getInfrastructureListTotalCounts
    ),
    takeEvery(
      actionType.GET_ADVANCED_SEARCH_OPTIONS_INNER_TAB,
      getAdvancedSearchOptionsDataForInnerTab
    ),
    takeEvery(actionType.ADVANCED_SEARCH_TRIGGERED, retrieveSearchresults),
    takeEvery(actionType.GET_USERS, getUsersData),
    takeEvery(actionType.GET_USERS_V2, getUsersDataV2),
    takeEvery(actionType.DELETE_USER, deleteUser),
    takeEvery(actionType.GET_USER_ROLES, getUserRoles),
    takeEvery(actionType.GET_ROLE_DETAILS, getRoleDetails),
    takeEvery(actionType.ADD_USER, addUser),
    takeEvery(actionType.MANAGE_MSP_USER_PERMISSIONS, manageMSPUserPermissions),
    takeEvery(actionType.GET_LICENSE_DETAILS, receiveLicenseDetails),
    takeEvery(actionType.ACTIVATE_ORDER, activateOrderDetails),
    takeEvery(actionType.GET_SOURCES_BY_GROUP, getSourcesByGroupSaga),
    takeEvery(actionType.REMOVE_SOURCES_FROM_GROUP, removeSourcesFromGroup),
    takeEvery(actionType.ADD_SOURCES_TO_GROUP, addSourcesToGroup),
    takeEvery(actionType.RESET_PASSWORD, resetUserPassword),
    takeEvery(
      actionType.RESEND_VERIFICATION_EMAIL,
      resendUserVerificationEmail
    ),
    takeEvery(actionType.GET_BRANDING_DETAILS, receiveBrandDetails),
    takeEvery(actionType.SET_LAST_VIEWED_APP, receiveLastViewedAppDetails),
    takeEvery(actionType.EDIT_UPDATE_BRAND_INFORMATION, updateBrandDetails),
    takeEvery(actionType.GET_EMAILER_DETAILS, receiveEmailerDetails),
    takeEvery(actionType.EDIT_UPDATE_EMAILER_INFORMATION, updateEmailerDetails),
    takeEvery(actionType.GET_BRANDING_IMG_URL, getBrandingImageURL),

    takeEvery(actionType.GET_NETWORK_CONFIG_VIEW, getNetworkConfigList),
    takeLatest(actionType.GET_CUSTOMER_LIST, getCustomerList),
    takeLatest(actionType.GET_MSP_CHILD_ACCOUNTS, getMSPChildAccounts),
    takeEvery(actionType.GET_CONFIGURE_COUNT, getConfigureCount),
    takeEvery(actionType.ADD_CUSTOMERS_TO_USER, addCustomersToUser),
    takeLatest(actionType.DOWNLOAD_AGENTS, downloadAgents),
    takeLatest(actionType.REFRESH_VMS, refreshVMs),
    takeEvery(actionType.GET_CUSTOMERS_FOR_USER, getCustomersForUser),
    takeEvery(actionType.GET_NETWORK_PORT_FORWARD, getNetworkPortForwardList),
    takeEvery(actionType.EDIT_NETWORK_CONFIG_LIST, editNetworkConfigList),
    takeEvery(actionType.DELETE_NETWORK_CONFIG_LIST, deleteUpdatedNetworkList),
    takeEvery(actionType.SHOW_ADDED_NETWORK_CONFIG_LIST, addNetworkList),
    takeEvery(
      actionType.GET_DOWNLOAD_NETWORK_SESSION_LOGS,
      getNetworkSessionLogs
    ),
    takeEvery(actionType.FETCH_VPN_DETAILS, getVPNdetails),
    takeEvery(actionType.CREATE_NEW_SITE, createNewSite),
    takeLatest(actionType.DELETE_HYPERVISOR, deleteHypervisorByID),
    takeLatest(actionType.DELETE_STORAGE_ARRAY, deleteStorageArrayByID),
    takeLatest(actionType.LOAD_DEFAULT_DEPLOYMENT, loadDefaultDeploymentSaga),
    takeLatest(
      actionType.UPDATE_DEFAULT_DEPLOYMENT,
      updateDefaultDeploymentSaga
    ),
    takeLatest(
      actionType.LOAD_SOURCE_DISCOVERY_SETTINGS,
      loadSourceDiscoverySettingsSaga
    ),
    takeLatest(
      actionType.SAVE_SOURCE_DISCOVERY_SETTINGS,
      saveSourceDiscoverySettingsSaga
    ),
    takeLatest(
      actionType.FETCH_SOURCE_DISCOVERY_LIST,
      fetchSourceDiscoveryListSaga
    ),
    takeLatest(actionType.SOURCE_DISCOVERY_RUN_NOW, sourceDiscoveryRunNowSaga),
    takeLatest(actionType.ADD_DISCOVER_FROM_AD, addDiscoverFromADSaga),
    takeLatest(actionType.ADD_DISCOVER_FROM_HYPERV, addDiscoverFromHyperVSaga),
    takeLatest(actionType.ADD_DISCOVER_FROM_VMWARE, addDiscoverFromVmwareSaga),
    takeLatest(
      actionType.ADD_DISCOVER_FROM_NUTANIX,
      addDiscoverFromNutanixSaga
    ),
    takeLatest(actionType.DELETE_SOURCE_DISCOVERY, deleteSourceDiscoverySaga),
    takeLatest(actionType.UPDATE_VM_INFORMATION, updateVMInformationByID),
    takeLatest(actionType.UPDATE_STORAGE_ARRAY, updateStorageArrayByID),
    takeLatest(actionType.UPDATE_HYPER_V_OR_VMWARE, updateHyperVOrVMwareSaga),
    takeLatest(actionType.UPDATE_PROXY, updateProxySaga),
    takeLatest(actionType.REMOVE_PROXY, removeProxy),
    takeLatest(actionType.EDIT_ORGANIZATION, editOrg),
    takeLatest(actionType.TWO_FACTOR_USER_DISABLE, twoFactorDisable),
    takeLatest(actionType.UPGRADE_SITE, upgradeSite),
    takeLatest(actionType.ADD_HYPERVISOR, addHypervisorSaga)
  ]);
}
