import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "5%",
    left: "20%",
    right: "20%",
    bottom: "auto"
  },
  overlay: {
    zIndex: 9999
  }
};

const CsrNotifications = ({ notifications, clearNotifications }) => (
  <Modal
    isOpen={notifications.length > 0}
    onRequestClose={clearNotifications}
    style={customStyles}
  >
    <div className="notification-modal__inner-container">
      <div className="notification-modal__notifications-container">
        {notifications.map(notification => (
          <p key={notification}>{notification}</p>
        ))}
      </div>

      <button onClick={clearNotifications}>Close</button>
    </div>
  </Modal>
);

CsrNotifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  clearNotifications: PropTypes.func.isRequired
};

export default CsrNotifications;
