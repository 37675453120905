import React from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Body from "./Body";
import Paper from "@mui/material/Paper";

const Layout = ({
  className,
  titleId,
  subtitleId,
  graphId,
  options,
  chartType,
  action,
  children
}) => {
  if (React.Children.count(children) === 1) {
    return (
      <Paper elevation={0} className="cc-chart-wrapper">
        <Header
          titleId={titleId}
          subtitleId={subtitleId}
          graphId={graphId}
          options={options}
          chartType={chartType}
          action={action}
        />
        <Body className={className}>{children}</Body>
      </Paper>
    );
  }

  return (
    <Paper elevation={0} className="cc-chart-wrapper">
      {children}
    </Paper>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  titleId: PropTypes.string,
  subtitleId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

Layout.defaultProps = {
  className: null,
  titleId: null,
  subtitleId: null
};

export default Layout;
