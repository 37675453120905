import { createSelector } from "reselect";
import { Action } from "components/protect/destination/rps/datagrid/RPSGridColumns";

/* eslint-disable import/prefer-default-export */

const RPS_AVAILABLE_ACTIONS = [
  Action.REFRESH,
  Action.UPDATE,
  Action.DELETE,
  Action.ADD_DATA_STORE,
  Action.IMPORT_LOCAL_DATA_STORE,
  Action.ADD_CLOUD_DATA_STORE_ACTION,
  Action.IMPORT_CLOUD_DATA_STORE,
  Action.RPS_JUMPSTART,
  Action.DEPLOY_RPS,
  Action.CANCEL_RPS,
  Action.CANCEL_SCHEDULED_DEPLOY
];

const getRpsAvaialbleAction = rps => {
  return RPS_AVAILABLE_ACTIONS.filter(action => {
    if (
      (action === Action.IMPORT_CLOUD_DATA_STORE &&
        !rps?.available_actions?.includes("import_cloud_data_store")) ||
      (action === Action.ADD_CLOUD_DATA_STORE_ACTION &&
        !rps?.available_actions?.includes("add_cloud_data_store")) ||
      (action === Action.CANCEL_SCHEDULED_DEPLOY &&
        !rps?.available_actions?.includes("cancel_scheduled_deploy"))
    ) {
      return false;
    }
    return true;
  });
};

const getRpsAvailableActions = rps => {
  // eslint-disable-next-line array-callback-return, consistent-return
  const rpsAvailableActionsArrayRaw = rps.available_actions.map(action => {
    if (action === "add_local_data_store") {
      return Action.ADD_DATA_STORE;
    } else if (action === "import_data_store") {
      return Action.ADD_DATA_STORE;
    } else if (action === "import_local_data_store") {
      return Action.IMPORT_LOCAL_DATA_STORE;
    } else if (action === "import_cloud_data_store") {
      return Action.IMPORT_CLOUD_DATA_STORE;
    } else if (action === "add_cloud_data_store") {
      return Action.ADD_CLOUD_DATA_STORE_ACTION;
    } else if (action === "rps_jump_start") {
      return Action.RPS_JUMPSTART;
    } else if (action === "refresh") {
      return Action.REFRESH;
    } else if (action === "delete") {
      return Action.DELETE;
    } else if (action === "update") {
      return Action.UPDATE;
    } else if (action === "deploy") {
      return Action.DEPLOY_RPS;
    } else if (action === "cancel_scheduled_deploy") {
      return Action.CANCEL_SCHEDULED_DEPLOY;
    }
  });
  const rpsAvailableActionsArrayProcessedFromApi = [
    ...new Set(rpsAvailableActionsArrayRaw)
  ];
  const rpsAvailableActionsArrayProcessed = RPS_AVAILABLE_ACTIONS.filter(
    action => rpsAvailableActionsArrayProcessedFromApi.includes(action)
  );
  return rpsAvailableActionsArrayProcessed;
};

export const getRpsList = state =>
  state.destinations && state.destinations.rps.rpsResponse
    ? state.destinations.rps.rpsResponse.data
    : [];

export const getRpsListForDS = state =>
  state.protectReducer && state.protectReducer.recoveryPointServers
    ? state.protectReducer.recoveryPointServers
    : [];

const prepareIconObject = status => {
  let iconClass = "";
  switch (status) {
    case "running":
    case "normal":
      iconClass = "rps_success";
      break;
    case "starting":
    case "creating":
    case "deploying_patch":
      iconClass = "warning";
      break;
    case "loading":
    case "deploying":
      iconClass = "upgrading";
      break;
    default:
      iconClass = "error";
  }
  return {
    text: status,
    iconClass
  };
};

export const getRpsGridData = createSelector([getRpsList], rpsList =>
  rpsList.map(rps => ({
    id: rps.server_id,
    available_actions: getRpsAvailableActions(rps),
    name: rps.server_name,
    server_name: rps.server_name,
    username: rps.username,
    port: rps.port,
    protocol: rps.protocol,
    nat_server: rps.nat.nat_server,
    nat_server_port: rps.nat.nat_server_port,
    description: rps.description,
    site_name: rps.site.site_name,
    site_id: rps.site.site_id,
    status: prepareIconObject(rps.status),
    policies: rps.policies,
    datastore_count: rps.datastore_count,
    version: rps.rps_version,
    rps_version: rps.rps_version,
    actionsFromBE: [...rps.available_actions],
    deployment: rps.deployment
  }))
);

export const getRpsGridDataForDS = createSelector([getRpsListForDS], rpsList =>
  rpsList.map(rps => ({
    id: rps.server_id,
    available_actions: getRpsAvaialbleAction(rps),
    name: rps.server_name,
    server_name: rps.server_name,
    username: rps.username,
    port: rps.port,
    protocol: rps.protocol,
    nat_server: rps.nat.nat_server,
    nat_server_port: rps.nat.nat_server_port,
    description: rps.description,
    site_name: rps.site.site_name,
    site_id: rps.site.site_id,
    status: prepareIconObject(rps.status),
    policies: rps.policies,
    datastore_count: rps.datastore_count,
    version: rps.rps_version,
    rps_version: rps.rps_version,
    actionsFromBE: [...rps.available_actions]
  }))
);

export const getRpsPagination = state =>
  state.destinations && state.destinations.rps.rpsResponse
    ? state.destinations.rps.rpsResponse.pagination
    : {};

export const getCurrentRpsId = state => state.destinations.rps.currentRpsId;
export const getCurrentRps = state => state.destinations.rps.currentRps;
