import { combineReducers } from "redux";

import ccToast from "./ccToast";
import dialogs from "./dialogs";
import loading from "./loading";

const ui = combineReducers({
  ccToast,
  dialogs,
  loading
});

export default ui;
