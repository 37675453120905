import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { ID_PREFIX } from "configs/IdConfig";

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: "100%",
//     display: "flex"
//   },
//   button: {
//     marginRight: theme.spacing(1)
//   },
//   backButton: {
//     marginRight: theme.spacing(1)
//   },
//   completed: {
//     display: "inline-block"
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//     height: "100%"
//   }
// }));

function getSteps(tabOptions) {
  return tabOptions.map(item => {
    return <b>{item.details}</b>;
  });
}

function getStepContent(tabOptions, step) {
  return (
    <div>
      {tabOptions.map((item, index) => {
        if (step === index) {
          return <div key={index}>{item.content}</div>;
        } else {
          return (
            <div key={index} style={{ display: "none" }}>
              {item.content}
            </div>
          );
        }
      })}
    </div>
  );
  //return tabOptions[step].content;
}

export default function HorizontalNonLinearAlternativeLabelStepper({
  tabOptions,
  tabIndex,
  testId
}) {
  // const classes = useStyles();
  //const [activeStep, setActiveStep] = React.useState(0);
  const activeStep = Number(tabIndex - 1);
  const completed = new Set();
  const skipped = new Set();
  const steps = getSteps(tabOptions);

  // const totalSteps = () => {
  //   return getSteps(tabOptions).length;
  // };

  // const isStepOptional = step => {
  //   return step === 1;
  // };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   //setActiveStep(prevActiveStep => prevActiveStep + 1);
  //   setSkipped(prevSkipped => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const skippedSteps = () => {
  //   return skipped.size;
  // };

  // const completedSteps = () => {
  //   return completed.size;
  // };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps() - skippedSteps();
  // };

  // const isLastStep = () => {
  //   return activeStep === totalSteps() - 1;
  // };

  // const handleNext = () => {
  // const newActiveStep =
  //   isLastStep() && !allStepsCompleted()
  //     ? // It's the last step, but not all steps have been completed
  //       // find the first step that has been completed
  //       steps.findIndex((step, i) => !completed.has(i))
  //     : activeStep + 1;
  //setActiveStep(newActiveStep);
  // };

  // const handleBack = () => {
  //   //setActiveStep(prevActiveStep => prevActiveStep - 1);
  // };

  const handleStep = step => () => {
    //setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = new Set(completed);
  //   newCompleted.add(activeStep);
  //   setCompleted(newCompleted);

  //   /**
  //    * Sigh... it would be much nicer to replace the following if conditional with
  //    * `if (!this.allStepsComplete())` however state is not set when we do this,
  //    * thus we have to resort to not being very DRY.
  //    */
  //   if (completed.size !== totalSteps() - skippedSteps()) {
  //     handleNext();
  //   }
  // };

  // const handleReset = () => {
  //   //setActiveStep(0);
  //   setCompleted(new Set());
  //   setSkipped(new Set());
  // };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <div>
      <Paper elevation={0} className="mui-modal-paper">
        {/* <div>Create Instant VM</div> */}
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            //   if (isStepOptional(index)) {
            //     buttonProps.optional = <Typography variant="caption">Optional</Typography>;
            //   }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepButton
                  id={`${ID_PREFIX}${testId}_${index}`}
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
      <div>
        <div>
          <Typography
            variant="div"
            sx={{
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
              height: "100%"
            }}
          >
            {getStepContent(tabOptions, activeStep)}
          </Typography>
        </div>
      </div>
    </div>
  );
}
