import { combineReducers } from "redux";
import * as actionType from "../../actions/actionTypes";

function browseSourcesSF(state = null, action) {
  switch (action.type) {
    case actionType.CLEAR_BROWSE_SOURCES_DS:
      return [];
    case actionType.GET_BROWSE_RECOVERY_POINT_SF_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  browseSourcesSF
});
