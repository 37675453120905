import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { MUITab } from "../common/tab";
import * as constant from "../../utils/appConstants";
//import logoUrl from "../../../src/assets/images/default-logo.png";
import { getRoleId } from "state/selectors";
import Tabs from "@mui/material/Tabs";
// import PhoneIcon from "@mui/icons-material/FindInPage";
// import FavoriteIcon from "@mui/icons-material/Security";
// import PersonPinIcon from "@mui/icons-material/Backup";
// import Configure from "@mui/icons-material/SettingsBackupRestore";

const PrimaryTabMap = {
  Monitor: 0,
  Protect: 1,
  Analyze: 2,
  Configure: 3,
  Support: 4,
  Profile: 5
};

const PrimaryNavigator = props => {
  // const menuLen = props && props.menu && props.menu.length;

  const handleTabActiveValue = active => {
    const mapping = {};

    if (
      props &&
      props.menu &&
      props.menu.length !== Object.keys(PrimaryTabMap).length
    ) {
      let menusLength = props.menu.length;
      let count = 0;
      for (let i = 0; i < menusLength; i++) {
        let name = props.menu[i].name;
        mapping[name] = count;
        count++;
      }
    }
    return Object.keys(mapping).length > 0
      ? mapping[active]
      : PrimaryTabMap[active];
  };

  const handleTabClick = (menu, key) => {
    PrimaryTabMap[active] === 0 && props.resetAlertListCount();
    props.handleSelectMenu({ selectedMenu: menu, selectedMenuKey: key });
    //eslint-disable-next-line default-case
    switch (menu) {
      case "Protect":
        props.orgType === constant.MSP_ORG_TYPE
          ? props.handleSelectFilter("customer-accounts", "Customer Accounts")
          : props.handleSelectFilter("sources", "Sources"); //this.props.handleSelectFilter("sources", "Sources")
        break;
      case "Analyze":
        props.handleSelectFilter("jobs", "Jobs");
        break;
      case "Configure":
        props.orgType === constant.MSP_ORG_TYPE
          ? props.handleSelectFilter("user_accounts", "User Accounts")
          : props.handleSelectFilter("hypervisors", "Hypervisors");
        break;
    }
  };

  const renderTabs = () => {
    const active = props.active;
    return (
      props &&
      props.menu &&
      props.menu.map(menu => (
        <MUITab
          testId={menu.testId}
          key={menu.id}
          menuKey={menu.key}
          routeLink={renderLinks(menu.key)}
          title={menu.name}
          icon={menu.icon}
          id={menu.id}
          //className="navigate-icons d-flex align-items-center flex-column"
          iconWidth="50px"
          iconHeight="50px"
          onClick={handleTabClick}
          active={active}
        />
      ))
    );
  };

  const renderLinks = key => {
    let finalLink = "/configure/access_control/user_accounts";
    if (key === "Configure") {
      let totalMenu = props.menu.filter(e => e.key === "Configure");
      let accessControlCheck =
        totalMenu[0].subMenu &&
        totalMenu[0].subMenu.filter(su => {
          return ["access_control", "user_roles", "user_accounts"].includes(
            su.key
          );
        });
      finalLink = "";
      finalLink =
        accessControlCheck && accessControlCheck.length > 0
          ? "/configure/access_control/user_accounts"
          : "/configure/license_subscriptions";
    }
    let routeLink = "";
    switch (key) {
      case "Protect":
        routeLink =
          props.orgType === constant.MSP_ORG_TYPE
            ? "/protect/customer-accounts/all"
            : "/protect/sources/all";
        break;
      case "Analyze":
        routeLink = "/analyze/jobs/all";
        break;
      case "Configure":
        routeLink =
          props.orgType === constant.MSP_ORG_TYPE &&
          props.roleId !== constant.MSP_ADMIN &&
          props.roleId !== constant.MSP_ACCOUNT_ADMIN
            ? "/configure/infrastructure/proxies"
            : props.roleId === constant.MSP_ADMIN ||
              props.roleId === constant.MSP_ACCOUNT_ADMIN
            ? finalLink
            : "/configure/infrastructure/hypervisors";
        break;
      case "Monitor":
        routeLink = "/monitor";
        break;
      case "Recover":
        routeLink = "/recover";
        break;
      case "Support":
        routeLink = "/user_support";
        break;
      case "Profile":
        routeLink = "/profile";
        break;
      default:
        routeLink = "/monitor";
    }

    return routeLink;
  };

  const active = props.active;

  return (
    <Tabs
      value={handleTabActiveValue(active)}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      aria-label="icon label tabs example"
      style={{
        //maxWidth: "50%", removing this and using sx prop for different maxwidths based on breakpoints
        width: 825
      }}
      sx={{
        maxWidth: {
          xs: 0.6,
          lg: 0.5
        }
      }}
    >
      {renderTabs()}
    </Tabs>
  );
};

PrimaryNavigator.defaultProps = {
  isImpersonationView: false,
  brandDetails: null,
  orgType: null,
  brandingFrom: ""
};

PrimaryNavigator.propTypes = {
  handleSelectMenu: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  ),
  handleSelectFilter: PropTypes.func.isRequired,
  orgType: PropTypes.string,
  isImpersonationView: PropTypes.bool,
  brandDetails: PropTypes.shape({
    logo_img_url: PropTypes.string
  }),
  logoImageData: PropTypes.shape({
    image_url: PropTypes.string
  }),
  brandingFrom: PropTypes.string
};

/**
 * Maps state to the props of the component in React Redux connection
 * @param state
 */
const mapStateToProps = state => ({
  brandDetails: state.configureReducer.brandData,
  logoImageData: state.configureReducer.logoImgData,
  brandingFrom: state.configureReducer.brandingFrom,
  roleId: getRoleId(state)
});

export default withRouter(connect(mapStateToProps, {})(PrimaryNavigator));
