import * as C from "state/constants";

const initialState = {
  isLoading: true,
  dataCenters: []
};

const dataCenters = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_DATA_CENTERS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        datacenters: []
      });
    case C.FETCH_DATA_CENTERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        dataCenters: action.payload.data
      });
    case C.FETCH_DATA_CENTERS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        dataCenters: []
      });
    default:
      return state;
  }
};

export default dataCenters;
