import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { makeLoginEmailState } from "state/selectors/LoginSelectors";

import LoginModal from "./LoginModal";

export const formName = "csr-login-modal";

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "required";
  }

  if (!values.password) {
    errors.password = "required";
  }

  return errors;
};

const createReduxForm = reduxForm({ form: formName, validate });

export default connect(
  state => ({
    initialValues: {
      email: makeLoginEmailState()(state)
    },
    twoFactorEnabled: state.login.twoFactorEnabled
  }),
  dispatch => ({
    onSubmit: values => {
      dispatch({
        type: "LOGIN_REQUESTING",
        username: values.email,
        password: values.password,
        two_factor_code: values.two_factor_code
      });
    }
  })
)(createReduxForm(LoginModal));
