import { createSelector } from "reselect";

// destinationData: getProtectdestinationDataState(state, props),
//     showDownloadRecoveryPointModal:
//     getshowDownloadRecoveryPointModalProtectState(state, props),
//     contextualAction: getcontextualActionProtectState(state, props),
//     showConfirmationModal: getshowConfirmationModalProtectState(state, props),
//     recoveryPointsGroup: getrecoveryPointsGroupProtectState(state, props),
//     recoveryPointsDetailsdata: getProtectrecoveryPointsDetailsDataState(state, props)

// import {
//     makeProtectdestinationDataState,
//     makeshowDownloadRecoveryPointModalProtectState,
//     makecontextualActionProtectState,
//     makeshowConfirmationModalProtectState,
//     makerecoveryPointsGroupProtectState,
//     makeProtectrecoveryPointsDetailsDataState
//   } from "../../state/selectors/recovery-points";
const mountPointInfo = state => state.protectReducer.mountPointInfo;
export const makeMountPointInfo = () =>
  createSelector([mountPointInfo], mountPointInfo => mountPointInfo);

const recoveryTargetSwitch = state => state.protectReducer.recoveryTargetSwitch;
export const makerecoveryTargetSwitchProtectState = () =>
  createSelector(
    [recoveryTargetSwitch],
    recoveryTargetSwitch => recoveryTargetSwitch
  );

const restoreRecoverSnapshot = state =>
  state.protectReducer.restoreRecoverSnapshot;
export const makerestoreRecoverSnapshotProtectState = () =>
  createSelector(
    [restoreRecoverSnapshot],
    restoreRecoverSnapshot => restoreRecoverSnapshot
  );

const targetSourceId = state => state.protectReducer.targetSourceId;
export const maketargetSourceIdProtectState = () =>
  createSelector([targetSourceId], targetSourceId => targetSourceId);

const restoreRecoverHost = state => state.protectReducer.restoreRecoverHost;
export const makerestoreRecoverHostProtectState = () =>
  createSelector(
    [restoreRecoverHost],
    restoreRecoverHost => restoreRecoverHost
  );

const tabIndex = state => state.reducer.tabIndex;
export const maketabIndexProtectState = () =>
  createSelector([tabIndex], tabIndex => tabIndex);

const restoreRecoverPath = state => state.protectReducer.restoreRecoverPath;
export const makerestoreRecoverPathProtectState = () =>
  createSelector(
    [restoreRecoverPath],
    restoreRecoverPath => restoreRecoverPath
  );

const selectedRecoverImageFormat = state =>
  state.protectReducer.selectedRecoverImageFormat;
export const makeselectedRecoverImageFormatProtectState = () =>
  createSelector(
    [selectedRecoverImageFormat],
    selectedRecoverImageFormat => selectedRecoverImageFormat
  );

const recoveryImageFormats = state => state.protectReducer.recoveryImageFormats;
export const makerecoveryImageFormatsProtectState = () =>
  createSelector(
    [recoveryImageFormats],
    recoveryImageFormats => recoveryImageFormats
  );

const gridPageSize = state => state.reducer.gridPageSize;
export const makegridPageSizeProtectState = () =>
  createSelector([gridPageSize], gridPageSize => gridPageSize);

const gridCurrentPage = state => state.reducer.gridCurrentPage;
export const makegridCurrentPageProtectState = () =>
  createSelector([gridCurrentPage], gridCurrentPage => gridCurrentPage);

const commonDataArrayPagination = state => state.protectReducer.data.pagination;
export const makecommonDataArrayPaginationProtectState = () =>
  createSelector(
    [commonDataArrayPagination],
    commonDataArrayPagination => commonDataArrayPagination
  );

const searchText = state => state.reducer.searchText;
export const makesearchTextProtectState = () =>
  createSelector([searchText], searchText => searchText);

const secondaryNavigation = state => state.reducer.secondaryNavigation;
export const makesecondaryNavigationProtectState = () =>
  createSelector(
    [secondaryNavigation],
    secondaryNavigation => secondaryNavigation
  );

const commonDataArray = state => state.protectReducer.data.data;
export const makecommonDataArrayProtectState = () =>
  createSelector([commonDataArray], commonDataArray => commonDataArray);

const recoveryPointsGroup = state => state.protectReducer.recoveryPointsGroup;
export const makerecoveryPointsGroupProtectState = () =>
  createSelector(
    [recoveryPointsGroup],
    recoveryPointsGroup => recoveryPointsGroup
  );

const showConfirmationModal = state => state.reducer.showConfirmationModal;
export const makeshowConfirmationModalProtectState = () =>
  createSelector(
    [showConfirmationModal],
    showConfirmationModal => showConfirmationModal
  );

const contextualAction = state => state.reducer.contextualAction;
export const makecontextualActionProtectState = () =>
  createSelector([contextualAction], contextualAction => contextualAction);

const dataStores = state => state.protectReducer.editDropdowns.dataStores;
export const makedataStoresProtectState = () =>
  createSelector([dataStores], dataStores => dataStores);

const rpsNames = state => state.protectReducer.editDropdowns.rpsNames;
export const makerpsNamesProtectState = () =>
  createSelector([rpsNames], rpsNames => rpsNames);

const showDownloadRecoveryPointModal = state =>
  state.reducer.showDownloadRecoveryPointModal;
export const makeshowDownloadRecoveryPointModalProtectState = () =>
  createSelector(
    [showDownloadRecoveryPointModal],
    showDownloadRecoveryPointModal => showDownloadRecoveryPointModal
  );

const recoveryPoints = state => state.protectReducer.recoveryPoints;
const columnData = state => state.protectReducer.columnData;
const showBrowseRecoveryPointModal = state =>
  state.reducer.showBrowseRecoveryPointModal;

export const makeProtectRecoveryPointsState = () =>
  createSelector([recoveryPoints], recoveryPoints => recoveryPoints);

export const makeProtectColumnDataState = () =>
  createSelector([columnData], columnData => columnData);

export const makeshowBrowseRecoveryPointModalProtectState = () =>
  createSelector(
    [showBrowseRecoveryPointModal],
    showBrowseRecoveryPointModal => showBrowseRecoveryPointModal
  );

// selectors (If props is required, use (state, props))
//browse rp

const browseURL = state => state.reducer.browseURL;
const selectedFileBrowserNodeId = state =>
  state.protectReducer.selectedFileBrowserNodeId;
const selectedFileBrowserUrl = state =>
  state.protectReducer.selectedFileBrowserUrl;
const fileBrowserTreePaths = state => state.protectReducer.fileBrowserTreePaths;
const selection = state => state.reducer.selection;
const protectedPassword = state => state.protectReducer.chProtectedPassword;

// reselect functions

export const makeProtectbrowseURLState = () =>
  createSelector([browseURL], browseURL => browseURL);
export const makeselectedFileBrowserNodeIdComponentState = () =>
  createSelector(
    [selectedFileBrowserNodeId],
    selectedFileBrowserNodeId => selectedFileBrowserNodeId
  );
export const makeselectedFileBrowserUrlProtectState = () =>
  createSelector(
    [selectedFileBrowserUrl],
    selectedFileBrowserUrl => selectedFileBrowserUrl
  );
export const makeProtectfileBrowserTreePathsState = () =>
  createSelector(
    [fileBrowserTreePaths],
    fileBrowserTreePaths => fileBrowserTreePaths
  );
export const makeProtectselectionState = () =>
  createSelector([selection], selection => selection);
export const makeProtectprotectedPasswordState = () =>
  createSelector([protectedPassword], protectedPassword => protectedPassword);

//ch destination rp
const options = state => state.reducer.options;
const sourceData = state => state.protectReducer.sourceData;
const recoveryPointDetails = state => state.protectReducer.recoveryPointDetails;
const showRecoveryPointModal = state => state.reducer.showRecoveryPointModal;
const apiResponseProtect = state => state.reducer.apiResponse;

// reselect functions
export const makeOptionsProtectState = () =>
  createSelector([options], options => options);
export const makeSourceDataComponentState = () =>
  createSelector([sourceData], sourceData => sourceData);
export const makeRecoveryPointDetailsProtectState = () =>
  createSelector(
    [recoveryPointDetails],
    recoveryPointDetails => recoveryPointDetails
  );

export const makeProtectShowRecoveryPointModalState = () =>
  createSelector(
    [showRecoveryPointModal],
    showRecoveryPointModal => showRecoveryPointModal
  );
export const makeProtectapiResponseState = () =>
  createSelector(
    [apiResponseProtect],
    apiResponseProtect => apiResponseProtect
  );

//ch dest subcomponent
const destinationData = state => state.protectReducer.destinationData;
const fileBrowsertreeData = state => state.protectReducer.fileBrowsertreeData;
const fileBrowsergridData = state => state.protectReducer.fileBrowsergridData;
const recoveryPointsDetailsData = state =>
  state.protectReducer.recoveryPointsDetailsdata;
const showProtectedPasswordModal = state =>
  state.protectReducer.showProtectedPasswordModal;

export const makeProtectdestinationDataState = () =>
  createSelector([destinationData], destinationData => destinationData);

export const makeProtectfileBrowsertreeDataState = () =>
  createSelector(
    [fileBrowsertreeData],
    fileBrowsertreeData => fileBrowsertreeData
  );

export const makeProtectfileBrowsergridDataState = () =>
  createSelector(
    [fileBrowsergridData],
    fileBrowsergridData => fileBrowsergridData
  );

export const makeProtectrecoveryPointsDetailsDataState = () =>
  createSelector(
    [recoveryPointsDetailsData],
    recoveryPointsDetailsData => recoveryPointsDetailsData
  );

export const makeProtectshowProtectedPasswordModalState = () =>
  createSelector(
    [showProtectedPasswordModal],
    showProtectedPasswordModal => showProtectedPasswordModal
  );
