import * as actionConstant from "./actionTypes";

export const showSourcesList = (data, filterType) => ({
  type: actionConstant.SHOW_SOURCE_LIST,
  data,
  filterType
});

export const showOracleSources = (data, filterType) => ({
  type: actionConstant.SHOW_ORACLE_SOURCES,
  data,
  filterType
});

export const clearOracleSources = () => ({
  type: actionConstant.CLEAR_ORACLE_SOURCES
});

export const showPoliciesLocalList = (data, filterType) => ({
  type: actionConstant.SHOW_POLICIES_LOCAL_LIST,
  data,
  filterType
});

export const showSourcesLocalList = (data, filterType) => ({
  type: actionConstant.SHOW_SOURCE_LOCAL_LIST,
  data,
  filterType
});

export const showPoliciesList = (data, filterType) => ({
  type: actionConstant.SHOW_POLICIES_LIST,
  data,
  filterType
});

export const showDestinationList = (data, filterType) => ({
  type: actionConstant.SHOW_DESTINATION_LIST,
  data,
  filterType
});

export const updateColumnStateData = (data, stateData, filterType) => ({
  type: actionConstant.UPDATE_COLUMN_DATA_FROM_STATE,
  data,
  stateData,
  filterType
});

export const updateReorderColumns = (
  data,
  oldItem,
  newItem,
  stateColumnData,
  stateFiltertype
) => ({
  type: actionConstant.UPDATE_REORDER_COLUMN_DATA,
  data,
  oldItem,
  newItem,
  stateColumnData,
  stateFiltertype
});

export const destinationOverlay = data => ({
  type: actionConstant.SHOW_DESTINATION_OVERLAY,
  data
});

export const showPolicyDetails = data => ({
  type: actionConstant.SHOW_POLICY_DETAILS,
  data
});

export const showScheduleModal = data => ({
  type: actionConstant.SHOW_SCHEDULE_MODAL,
  data
});

export const showEventDetailsOverlay = data => ({
  type: actionConstant.SHOW_EVENT_DETAILS_OVERLAY,
  data
});

export const updateDestinationsDetails = data => ({
  type: actionConstant.UPDATE_DESTINATION_OVERLAY_DETAILS,
  data
});

export const removeDestination = data => ({
  type: actionConstant.REMOVE_DESTINATION,
  data
});

export const showSelectSourceGrid = data => ({
  type: actionConstant.SHOW_SELECT_SOURCE_GRID_VIEW,
  data
});

export const showPolicyName = data => ({
  type: actionConstant.SHOW_POLICY_NAME,
  data
});

export const customSort = data => ({
  type: actionConstant.CUSTOM_SORT,
  data
});

export const getSelectedDestinationDetails = data => ({
  type: actionConstant.GET_DESTINATION_DETAILS,
  data
});

export const showDestinationDetails = data => ({
  type: actionConstant.SHOW_DESTINATION_DETAILS,
  data
});

export const filteredRecoveryList = (data, filterType) => ({
  token: null,
  type: actionConstant.FILTERED_RECOVERY_POINTS,
  data,
  filterType
});

export const showHypervisorList = data => ({
  type: actionConstant.SHOW_HYPERVISOR_LIST,
  data
});

export const setSelectedHypervisorIdInRecovery = data => ({
  type: actionConstant.SET_SELECTED_HYPERVISOR_ID_IN_RECOVERY,
  data
});

export const setRecoveryNextLoading = data => ({
  type: actionConstant.SET_RECOVERY_NEXT_LOADING,
  data
});

export const listHypervisors = data => ({
  type: actionConstant.LIST_HYPERVISORS,
  data
});

export const listHypervisorsSuccess = data => ({
  type: actionConstant.LIST_HYPERVISORS_SUCCESS,
  data
});

export const showVMsList = data => ({
  type: actionConstant.SHOW_VMS_LIST,
  data
});

export const addNewSources = (id, data) => ({
  type: actionConstant.ADD_NEW_SOURCE,
  id,
  data
});

export const getSelectedSourceDetails = data => ({
  type: actionConstant.GET_SOURCE_DETAILS,
  data
});

export const showSourceDetails = data => ({
  type: actionConstant.SHOW_SOURCE_DETAILS,
  data
});

export const getSourceDetails = data => ({
  type: actionConstant.GET_SELECTED_SOURCE_DETAILS,
  data
});

export const showSelectedSourceDetails = data => ({
  type: actionConstant.SHOW_SELECTED_SOURCE_DETAILS,
  data
});

export const showDataCenters = data => ({
  type: actionConstant.SHOW_DATA_CENTERS,
  data
});

export const updateResourceInList = (data, key) => ({
  type: actionConstant.UPDATE_RESOURCE_IN_GRID,
  data,
  key
});

export const updateOracleHostsInList = (data, key) => ({
  type: actionConstant.UPDATE_ORACLE_HOSTS_IN_GRID,
  data,
  key
});

export const addFilteredSourceGroup = data => ({
  type: actionConstant.FILTERED_SOURCE_GROUP,
  data
});

export const updateSourceGroupList = data => ({
  type: actionConstant.UPDATE_SOURCE_GROUP,
  data
});

export const addSourceGroupList = (data, checkPagination) => ({
  type: actionConstant.ADD_SOURCE_GROUP_IN_LIST,
  data,
  checkPagination
});

export const updatedDestinationList = data => ({
  type: actionConstant.UPDATED_DESTINATION_LIST,
  data
});

export const deletedDestinationList = data => ({
  type: actionConstant.DELETED_DESTINATION_LIST,
  data
});
export const showHideCreateSourceGroup = data => ({
  type: actionConstant.SHOW_HIDE_CREATE_SOURCE_GROUP,
  data
});

export const showCustomerAccountsList = (data, filterType) => ({
  type: actionConstant.SHOW_CUSTOMER_ACCOUNTS_LIST,
  data,
  filterType
});

export const deletedCustomerAccountDataSet = data => ({
  type: actionConstant.DELETED_CUSTOMER_ACCOUNT_DATA,
  data
});

export const showCustomerAccountDetails = data => ({
  type: actionConstant.UPDATE_CUSTOMER_ACCOUNT_DETAILS,
  data
});

export const showRecoveredResources = data => ({
  type: actionConstant.SHOW_RECOVERED_RESOURCES,
  data
});

export const showSelectedResourceDetails = data => ({
  type: actionConstant.SHOW_RESOURCE_DETAILS,
  data
});

export const showHideAddCustomerAccountForm = data => ({
  type: actionConstant.SHOW_ADD_CUSTOMER_ACCOUNT_MODAL,
  data
});

export const updatedCustomerAccountList = data => ({
  type: actionConstant.UPDATE_ADDED_CUSTOMER_ACCOUNT_DATA,
  data
});

export const updateCustomerMetricsCheckboxByType = data => ({
  type: actionConstant.UPDATE_METRICS_CHECKBOX_FILTER,
  data
});

export const showHideSetUsageThresholdForm = data => ({
  type: actionConstant.SHOW_SET_USAGE_THRESHOLD_MODAL,
  data
});

export const updateSetUsageThreshold = data => ({
  type: actionConstant.UPDATE_SET_USAGE_THRESHOLD_MODAL,
  data
});

export const showHideAssignUsersModal = data => ({
  type: actionConstant.SHOW_HIDE_ASSIGN_USERS_MODAL,
  data
});

export const addAdminToTagsHandler = () => ({
  type: actionConstant.ADD_ADMIN_TO_SHOW_TAGS
});

export const showDestinationListMock = payload => ({
  type: actionConstant.SHOW_DESTINATION_LIST_MOCK,
  payload
});

export const showProtectCustomerList = payload => ({
  type: actionConstant.SHOW_PROTECT_CUSTOMER_LIST,
  payload
});

export const updateSelectedAdminsDataHandler = data => ({
  type: actionConstant.UPDATE_ADMIN_DROPDOWN_OPTIONS,
  data
});

export const handleAssignTagsClose = data => ({
  type: actionConstant.REMOVE_ADMIN_ASSIGN_TAGS,
  data
});

export const insertAssignUserInList = data => ({
  type: actionConstant.UPDATE_ASSIGNED_ADMIN_USER_LIST,
  data
});

export const removeUnassignAdminFromCustomerList = data => ({
  type: actionConstant.REMOVE_UPDATE_UNASSIGN_ADMIN_FROM_CUSTOMER,
  data
});

/**
 * To filter schedules in policy
 * @param payload
 * @returns {{type, payload: *}}
 */
export const filterSchedule = payload => ({
  type: actionConstant.FILTER_POLICY_SCHEDULES,
  payload
});

/**
 * Update sourceData after source -> configuration has been updated at the back end
 */
export const editUpdateSourceConfiguration = data => ({
  type: actionConstant.EDIT_UPDATE_SOURCE_CONFIGURATION,
  data
});

/**
 * Clear selected resource from state
 * @param {*} data
 */
export const clearSelectedResource = data => ({
  type: actionConstant.CLEAR_SELECTED_RESOURCE,
  data
});

/**
 * to update source group list after a group is deleted from a back end
 */
export const deleteUpdateSourceGroups = data => ({
  type: actionConstant.DELETE_UPDATE_SOURCE_GROUP,
  id: data
});

/*
 * get recovery point details by index
 */
export const getRecoveryPointByIndex = data => ({
  type: actionConstant.GET_RECOVERY_POINTS_DEATILS,
  data
});

/**
 * Delete the record from the grid
 * @param id
 * @returns {{type: *, id: *}}
 */
export const updateDeletePolicyInList = id => ({
  type: actionConstant.DELETE_POLICY_FROM_GRID,
  id
});

/**
 * Delete the record from the grid
 * @param id
 * @returns {{type: *, id: *}}
 */
export const updateDeleteSourceInList = id => ({
  type: actionConstant.DELETE_SOURCE_FROM_GRID,
  id
});

export const updateDeleteOracleHostsInList = id => ({
  type: actionConstant.DELETE_ORACLE_HOSTS_FROM_GRID,
  id
});

/**
 * To clear policy state
 * @param id
 */
export const clearPolicyState = id => ({
  type: actionConstant.CLEAR_POLICY_DETAILS_STATE,
  id
});

/**
 * Redirects to policy
 * @returns {{type: string}}
 */
export const redirectToPolicyGrid = () => ({
  type: actionConstant.REDIRECT_TO_POLICY_GRID
});

/*
 * update recovery point details
 */
export const updateRecoveryPointsDetails = data => ({
  type: actionConstant.UPDATE_RECOVERY_POINT_DETAILS,
  data
});

export const selectShow = currentPath => ({
  type: "FB_SELECT_SHOW",
  selectedPath: currentPath
});

export const selectDone = (level, index) => ({
  type: "FB_SELECT",
  level,
  index
});

export const expandDone = (data, path) => ({
  type: "FB_EXPAND",
  tree: data,
  path
});

export const toggleClose = (level, index) => ({
  type: "FB_CLOSE",
  level,
  index
});

export const toggleOpen = (level, index) => ({
  type: "FB_OPEN",
  level,
  index
});
/**
 * updates recovered vms data to state
 */
export const updateRecoveredVMS = data => ({
  type: actionConstant.UPDATE_RECOVERED_VMS,
  data
});

/**
 * updates mounted RPS data to state
 */
export const updateMountedRPS = data => ({
  type: actionConstant.UPDATE_MOUNTED_RPS,
  data
});
/**
 * get selected recovered vm by id
 */
export const getSelectedRecoveredVM = data => ({
  type: actionConstant.GET_SELECTED_RECOVERED_VM,
  data
});

/**
 * get selected recovered vm by id using source id
 */
export const getSelectedRecoveredVMUsingSourceId = data => ({
  type: actionConstant.GET_SELECTED_RECOVERED_VM_USING_SOURCE_ID,
  data
});

/**
 * get selected recovered vm by id
 */
export const updateSelectedRecoveredVM = data => ({
  type: actionConstant.UPDATE_SELECTED_RECOVERED_VM,
  data
});

/**
 * handle contextual actions for a recovered VM
 */
export const actionForRecoveredVM = (id, actionType, name) => ({
  type: actionConstant.ACTION_FOR_RECOVERED_VM,
  id,
  actionType,
  name
});

/**
 * handle contextual actions for a recovered VM
 */
export const actionForRecoveredVMDetails = (id, actionType) => ({
  type: actionConstant.ACTION_FOR_RECOVERED_VM_DETAILS,
  id,
  actionType
});

/**
 * to update customers list in state
 */
export const updateCustomersToAssign = data => ({
  type: actionConstant.UPDATE_CUSTOMERS_TO_ASSIGN,
  data
});

/**
 * update file browser node data
 */
export const updateFileBrowserNodeData = data => ({
  type: actionConstant.UPDATE_FILE_BROWSER_NODE_DATA,
  data
});

/**
 * update file browser required node data for pagination
 */
export const updateFileBrowserRequiredData = data => ({
  type: actionConstant.UPDATE_FILE_BROWSER_NODE_DATA_FOR_PAGINATION,
  data
});

/**
 * set machine details
 */
export const setMachineDetails = data => ({
  type: actionConstant.SET_MACHINE_DETAILS,
  data
});

/**
 * get image formats data
 */
export const getRecoveryImageFormats = data => ({
  type: actionConstant.GET_RECOVERY_IMAGE_FORMATS,
  data
});

/**
 * update image formats data
 */
export const updateRecoveryImageFormats = data => ({
  type: actionConstant.UPDATE_RECOVERY_IMAGE_FORMATS,
  data
});

/**
 * update single recovery points details data
 */
export const updateSingleRecoveryPointsDetailsData = data => ({
  type: actionConstant.UPDATE_PARTICULAR_RECOVERY_POINT_DETAILS_DATA,
  data
});

/**
 * get recovery targets
 */
export const getRecoveryTargetsData = payload => ({
  type: actionConstant.GET_RECOVERY_TARGETS_DATA,
  payload
});

/**
 * update recovery targets
 */
export const updateRecoveryTargetsData = data => ({
  type: actionConstant.UPDATE_RECOVERY_TARGETS_DATA,
  data
});

/**
 * update selected recovery image format
 */
export const updateselectedRecoveryImageFormat = data => ({
  type: actionConstant.UPDATE_SELECTED_RECOVERY_IMAGE_FORMAT_DATA,
  data
});

/**
 * update restore recover path
 */
export const updateRestoreRecoverPath = data => ({
  type: actionConstant.UPDATE_RESTORE_RECOVER_PATH,
  data
});

/**
 * update zft file browser required data
 */
export const updateRestoreRecoverZftData = data => ({
  type: actionConstant.UPDATE_RESTORE_RECOVER_ZFT_DATA,
  data
});

/**
 * get download links for Download Agent option
 */
export const getDownloadLinks = () => ({
  type: actionConstant.GET_DOWNLOAD_LINKS
});

/**
 * get chart data for destination details
 */
export const getDestinationCD = (
  data,
  filter,
  startTs,
  endTs,
  timeResolution
) => ({
  type: actionConstant.GET_DESTINATION_CD,
  data,
  filter,
  startTs,
  endTs,
  timeResolution
});

/**
 * update target source id in reducer
 */
export const updateRecoverTargetSourceId = data => ({
  type: actionConstant.UPDATE_RECOVER_TARGET_SOURCE_ID,
  data
});

/**
 * update target VMware Datastore in reducer
 */
export const updateRecoverTargetVMwareDatastoreName = data => ({
  type: actionConstant.UPDATE_RECOVER_TARGET_VMWARE_DATASTORE_NAME,
  data
});

/**
 * update target VMware VM in reducer
 */
export const updateRecoverTargetVMwareVMName = data => ({
  type: actionConstant.UPDATE_RECOVER_TARGET_VMWARE_VM_NAME,
  data
});

/**
 * Post Recover data
 */
export const postRecoverData = (data, dgId) => ({
  type: actionConstant.POST_RECOVER_DATA,
  data,
  dgId
});

/**
 * update chart data for destination details in state
 */
export const updateDestinationCD = data => ({
  type: actionConstant.UPDATE_DESTINATION_CD,
  data
});

/**
 * Switch recovery points
 */
export const recoveryPointsSwitch = data => ({
  type: actionConstant.SWITCH_RECOVERY_POINTS_GROUP,
  data
});

/**
 * set Selected Dates from Date filter in Destination CD chart
 */
export const setSelectedDates = data => ({
  type: actionConstant.SET_SELECTED_DATES,
  data
});

/**
 * get Customer Accounts CD Volumes data for chart
 */
export const getCustomerAccountCD = (data, filter, startTs, endTs) => ({
  type: actionConstant.GET_CUSTOMER_ACCOUNT_CD,
  data,
  filter,
  startTs,
  endTs
});

/**
 * update chart data for customer accounts CD data details in state
 */
export const updateCustomerAccountCD = data => ({
  type: actionConstant.UPDATE_CUSTOMER_ACCOUNT_CD,
  data
});

/*
 * Switch target machine
 */
export const switchTargetMachines = data => ({
  type: actionConstant.SWITCH_RECOVERY_TARGET_MACHINE,
  data
});

/**
 * clear Selected Dates from Date filter in Destination CD chart
 */
export const clearSelectedDates = () => ({
  type: actionConstant.CLEAR_SELECTED_DATES
});

/**
 * clear Selected Dates from Date filter in Destination CH chart
 */
export const clearSelectedDatesCH = () => ({
  type: actionConstant.CLEAR_SELECTED_DATES_CH
});

export const clearSelectedDatesCU = () => ({
  type: actionConstant.CLEAR_SELECTED_DATES_CU
});
/*
 * Clear recovery points state
 */
export const clearRecoveryPointsState = () => ({
  type: actionConstant.CLEAR_RECOVERY_POINTS_STATE
});

/**
 * fetch policy data
 */
export const assignPolicySource = data => ({
  type: actionConstant.ASSIGN_POLICY_SOURCE,
  data
});

export const unAssignPolicyToSource = data => ({
  type: actionConstant.UNASSIGN_POLICY_SOURCE,
  data
});

export const updatePolicySource = data => ({
  type: actionConstant.UPDATE_ASSIGN_POLICY_SOURCE,
  data
});

export const assignPolicyToSource = payload => ({
  type: actionConstant.UPDATE_ASSIGN_TO_POLICY_SOURCE,
  payload
});

export const updateSelectedPolicyforAssignPolicy = data => ({
  type: actionConstant.UPDATE_SELECTED_ASSIGN_POLICY_SOURCE,
  data
});

/**
 * set duration for cloud volume - details
 */
export const setRetentionDuration = data => ({
  type: actionConstant.SET_RETENTION_DURATION,
  data
});

export const setSelectedHypervisor = data => ({
  type: actionConstant.SET_SELECTED_HYPERVISOR,
  data
});

export const getChRPDestinationDetails = data => ({
  type: actionConstant.GET_CH_RECOVERY_POINTS_DESTINATION_DETAILS,
  data
});

/**
 * update new path array in reducer
 */
export const updateNewTreePath = data => ({
  type: actionConstant.UPDATE_NEW_TREE_BROWSER_PATH,
  data
});

/**
 * get file browser grid data
 */
export const getFileBrowserGridData = data => ({
  type: actionConstant.GET_FILE_BROWSER_GRID_DATA,
  data
});

/**
 * unmount file browser data from reducer
 */
export const unMountFileBrowserData = data => ({
  type: actionConstant.UNMOUNT_FILE_BROWSER_DATA,
  data
});

/**
 * generate post data link array in reducer
 */
export const generateAndDownloadLinkForCh = data => ({
  type: actionConstant.GENERATE_DOWNLOAD_LINK_FOR_RP,
  data
});

/**
 * Cancel recovery by source id
 * @param data
 * @returns {{type: *, data: *}}
 */
export const cancelRecoveryBySourceId = (data, gridInfo) => ({
  type: actionConstant.CANCEL_RECOVERY,
  data,
  gridInfo
});

/**
 * get chart data for CH destination
 */
// TODO cleanup
// export const getDestinationCH = (
//   data,
//   startTs,
//   endTs,
//   timeResolution,
//   timeZone
// ) => ({
//   type: actionConstant.GET_DESTINATION_CH,
//   data,
//   startTs,
//   endTs,
//   timeResolution,
//   timeZone
// });

/**
 * update chart data for destination details in state
 */
export const updateDestinationCH = data => ({
  type: actionConstant.UPDATE_DESTINATION_CH,
  data
});

/**
 * get Customer Accounts CD Volumes data for chart
 */
export const getCustomerAccountCH = (
  data,
  startTs,
  endTs,
  timeResolution,
  timeZone
) => ({
  type: actionConstant.GET_CUSTOMER_ACCOUNT_CH,
  data,
  startTs,
  endTs,
  timeResolution,
  timeZone
});

/**
 * update chart data for customer accounts CD data details in state
 */
export const updateCustomerAccountCH = data => ({
  type: actionConstant.UPDATE_CUSTOMER_ACCOUNT_CH,
  data
});

/**
 * show/hide protected password modal in state
 */
export const showHideProtectedPasswordModalForm = data => ({
  type: actionConstant.SHOW_HIDE_PROTECTED_PASSWORD_FORM,
  data
});

/**
 * Post Protected Password Data for saga
 */
export const postProtectedPasswordData = data => ({
  type: actionConstant.POST_PROTECTED_PASSWORD_DATA,
  data
});

/**
 * update Restore Recover Source To Path
 */
export const updateRestoreRecoverSourceToPath = data => ({
  type: actionConstant.UPDATE_RESTORE_RECOVER_SOURCE_TO_PATH,
  data
});

/**
 * provision action for sources
 */
export const sourceProvision = data => ({
  type: actionConstant.SOURCE_PROVISION,
  data
});

/**
 * provision action for sources
 */
export const sourceCancelReplication = data => ({
  type: actionConstant.SOURCE_CANCEL_REPLICATION,
  data
});

/**
 * set Selected Dates from Date filter in Destination CH chart
 */
export const setSelectedDatesCH = data => ({
  type: actionConstant.SET_SELECTED_DATES_CH,
  data
});
export const setSelectedDatesCU = data => ({
  type: actionConstant.SET_SELECTED_DATES_CU,
  data
});
export const getConfigLicenses = data => ({
  type: actionConstant.GET_LICENSES,
  data
});

export const updateConfigurationLicenses = data => ({
  type: actionConstant.UPDATE_LICENSES,
  data
});

export const updateSelectedPolicyType = data => ({
  type: actionConstant.UPDATE_SELECTED_POLICY_TYPE,
  data
});

export const fetchPolicy = policyId => ({
  type: actionConstant.FETCH_POLICY_REQUEST,
  payload: { policyId }
});

export const fetchAgentlessVMPolicy = () => ({
  type: actionConstant.FETCH_AGENTLESS_VM_REQUEST
});

export const fetchPolicySuccess = data => ({
  type: actionConstant.FETCH_POLICY_SUCCESS,
  payload: { ...data }
});

export const fetchPolicyFailure = errors => ({
  type: actionConstant.FETCH_POLICY_FAILURE,
  payload: { errors }
});

export const fetchHypervisor = hypervisorId => ({
  type: actionConstant.FETCH_HYPERVISOR_REQUEST,
  payload: { hypervisorId }
});

export const fetchHypervisorSuccess = data => ({
  type: actionConstant.FETCH_HYPERVISOR_SUCCESS,
  payload: { ...data }
});

export const fetchHypervisorFailure = errors => ({
  type: actionConstant.FETCH_HYPERVISOR_FAILURE,
  payload: { errors }
});

export const updateHypervisor = hypervisor => ({
  type: actionConstant.UPDATE_HYPERVISOR_REQUEST,
  payload: hypervisor
});

export const updateHypervisorSuccess = data => ({
  type: actionConstant.UPDATE_HYPERVISOR_SUCCESS,
  payload: { ...data }
});

export const updateHypervisorFailure = errors => ({
  type: actionConstant.UPDATE_HYPERVISOR_FAILURE,
  payload: { errors }
});

export const hypervisorUpgradeAgent = hypervisorId => ({
  type: actionConstant.HYPERVISOR_UPGRADE_AGENT_REQUEST,
  payload: { hypervisorId }
});

export const hypervisorRefreshVms = hypervisorId => ({
  type: actionConstant.HYPERVISOR_REFRESH_VMS_REQUEST,
  payload: { hypervisorId }
});

export const clearRemotePolicies = () => ({
  type: actionConstant.FETCH_REMOTE_POLICIES_CLEAR
});

export const fetchRemotePolicies = payload => ({
  type: actionConstant.FETCH_REMOTE_POLICIES_REQUEST,
  payload
});

export const fetchRemotePoliciesSuccess = payload => ({
  type: actionConstant.FETCH_REMOTE_POLICIES_SUCCESS,
  payload
});

export const fetchRemotePoliciesFailure = errors => ({
  type: actionConstant.FETCH_REMOTE_POLICIES_FAILURE,
  payload: { errors }
});

export const updatePolicy = policy => ({
  type: actionConstant.UPDATE_POLICY_REQUEST,
  payload: { ...policy }
});

export const updatePolicySuccess = data => ({
  type: actionConstant.UPDATE_POLICY_SUCCESS,
  payload: { ...data }
});

export const updatePolicyFailure = errors => ({
  type: actionConstant.UPDATE_POLICY_FAILURE,
  payload: { errors }
});

export const getRemotePolicies = (data = {}) => ({
  type: actionConstant.FETCH_REMOTE_POLICIES,
  payload: data
});

export const updateRemotePoliciesData = data => ({
  type: actionConstant.FETCH_REMOTE_POLICIES_SUCCESSFUL,
  payload: data
});

export const fetchFailbackDestinations = recoveredResourceId => ({
  type: actionConstant.FETCH_FAILBACK_DESTINATIONS_REQUEST,
  payload: { recoveredResourceId }
});

export const fetchFailbackDestinationsSuccess = data => ({
  type: actionConstant.FETCH_FAILBACK_DESTINATIONS_SUCCESS,
  payload: data
});

export const fetchFailbackDestinationsFailure = () => ({
  type: actionConstant.FETCH_FAILBACK_DESTINATIONS_FAILURE,
  payload: {}
});

export const fetchAllDestinations = () => ({
  type: actionConstant.FETCH_ALL_DESTINATIONS_REQUEST,
  payload: {}
});

export const fetchAllDestinationsSuccess = data => ({
  type: actionConstant.FETCH_ALL_DESTINATIONS_SUCCESS,
  payload: data
});

export const fetchAllDestinationsFailure = errors => ({
  type: actionConstant.FETCH_ALL_DESTINATIONS_FAILURE,
  payload: { errors }
});

export const updateParticularStateInRecoveredVms = data => ({
  type: actionConstant.UPDATE_RECOVERED_VMS_STATE,
  data
});

/**
 * post remote policies action creator for saga
 */
export const postRemotePolicies = data => ({
  type: actionConstant.POST_REMOTE_POLICIES,
  data
});

/**
 * update remote policies in reducer
 */
export const updateRemotePolicies = data => ({
  type: actionConstant.UPDATE_REMOTE_POLICIES,
  data
});

/**
 * update remote policies in reducer
 */
export const updateRemotePolicyError = data => ({
  type: actionConstant.UPDATE_REMOTE_POLICIES_ERROR,
  data
});

/**
 * update remote policies in reducer
 */
export const clearRemotePolicyError = data => ({
  type: actionConstant.CLEAR_REMOTE_POLICIES_ERROR,
  data
});

/**
 * update remote policies in reducer
 */
export const clearRemotePolicy = data => ({
  type: actionConstant.CLEAR_REMOTE_POLICIES,
  data
});

/**
 * cancel replication out action creator
 */
export const cancelReplicationOutAction = (data, gridInfo) => ({
  type: actionConstant.CANCEL_REPLICATION_OUT,
  data,
  gridInfo
});

/**
 * start replication out action creator
 */
export const startReplicationOutAction = (data, gridInfo) => ({
  type: actionConstant.START_REPLICATION_OUT,
  data,
  gridInfo
});

export const replicationAction = (data, gridInfo) => ({
  type: actionConstant.START_REPLICATION,
  data,
  gridInfo
});
export const heartbeatPauseAction = (data, gridInfo) => ({
  type: actionConstant.HEARTBEAT_PAUSE,
  data,
  gridInfo
});
export const heartbeatResumeAction = (data, gridInfo) => ({
  type: actionConstant.HEARTBEAT_RESUME,
  data,
  gridInfo
});
export const standbyVMSnapshot = data => ({
  type: actionConstant.STANDBY_VM_SNAPSHOT,
  data
});
export const standbyVMNetworkConfiguration = data => ({
  type: actionConstant.SNAPSHOT_STANDBY_VM_NETWORK_CONFIGURATION,
  data
});
export const virtualStandbyPauseAction = (data, gridInfo) => ({
  type: actionConstant.VIRTUAL_STANDBY_PAUSE,
  data,
  gridInfo
});
export const virtualStandbyResumeAction = (data, actionKey, gridInfo) => ({
  type: actionConstant.VIRTUAL_STANDBY_RESUME,
  data,
  actionKey,
  gridInfo
});
export const standbyVMNetworkConfigurationAction = data => ({
  type: actionConstant.STANDBY_VM_NETWORK_CONFIGURATION,
  data
});

export const backupVMsAction = (data, gridInfo) => ({
  type: actionConstant.BACKUP_VMS,
  data,
  gridInfo
});

export const deployAction = (data, gridInfo) => ({
  type: actionConstant.DEPLOY,
  data,
  gridInfo
});

export const updateSource = (id, data, gridInfo) => ({
  type: actionConstant.UPDATE_SOURCE,
  id,
  data,
  gridInfo
});

export const updateSourceDetailsInStore = (id, data) => ({
  type: actionConstant.UPDATE_SOURCE_DETAILS_IN_STORE,
  id,
  data
});

export const connectSourcePopupShow = (username, password) => ({
  type: actionConstant.CONNECT_O365,
  username,
  password
});
export const addO365Source = sourceType => ({
  type: actionConstant.ADDO365,
  sourceType
});
export const windowsSourceSaveToList = windowsConfigObj => ({
  type: actionConstant.SAVE_TO_LIST_WINDOWS_SOURCE,
  windowsConfigObj
});
export const oracleWindowsSourceSaveToList = oracleWindowsConfigObj => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_WINDOWS_SOURCE,
  oracleWindowsConfigObj
});
export const oracleLinuxSourceSaveToList = oracleLinuxConfigObj => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_LINUX_SOURCE,
  oracleLinuxConfigObj
});

// Oracle windows DataBase

export const orcaleWindowsDatabaseOptions = sourceId => ({
  type: actionConstant.GET_ORACLE_WINDOWS_DB_OPTIONS,
  sourceId
});

export const orcaleWindowsDatabaseOptionsSuccess = data => ({
  type: actionConstant.GET_ORACLE_WINDOWS_DB_OPTIONS_SUCCESS,
  data
});

export const orcaleWindowsDatabaseOptionsError = () => ({
  type: actionConstant.GET_ORACLE_WINDOWS_DB_OPTIONS_FAILURE
});

// save to list

export const oracleWindowsDBSaveToList = (
  oracleWindowsConfigObj,
  selectedSource,
  DbSettings
) => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE,
  oracleWindowsConfigObj,
  selectedSource,
  DbSettings
});

export const oracleWindowsDBSaveToListSuccess = data => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE_SUCCESS,
  data
});

export const oracleWindowsDBSourceSaveToListError = data => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_DB_WINDOWS_SOURCE_ERROR,
  data
});

export const clearOracleDBWindowsSourcesList = () => ({
  type: actionConstant.CLEAR_ORACLE_WINDOWS_DB_SOURCE_LIST
});

export const clearOracleDBWindowsSourcesError = () => ({
  type: actionConstant.CLEAR_ORACLE_WINDOWS_DB_SOURCE_ERROR
});

export const updateADDetails = editedAdDetails => ({
  type: actionConstant.UPDATE_AD,
  editedAdDetails
});
export const addSources = sourcesList => ({
  type: actionConstant.ADD_SOURCES,
  sourcesList
});
export const addOracleSources = sourcesList => ({
  type: actionConstant.ADD_ORACLE_SOURCES,
  sourcesList
});
export const updateOracleWindowSourcesPage = data => ({
  type: actionConstant.ADD_ORACLE_WINDOW_SOUCE_PAGE,
  data
});

export const addOracleDB = sourcesList => ({
  type: actionConstant.ADD_ORACLE_DB,
  sourcesList
});

export const addADSaveSource = sourcesList => ({
  type: actionConstant.ADD_AD_SAVE_SOURCES,
  sourcesList
});

export const addLinuxSources = sourcesList => ({
  type: actionConstant.ADD_LINUX_SOURCES,
  sourcesList
});
export const deleteSourceFromSavedList = source => ({
  type: actionConstant.DELETE_SOURCE_FROM_LIST_WINDOWS_SOURCE,
  source
});

export const deleteOracleSourceFromSavedList = source => ({
  type: actionConstant.DELETE_SOURCE_FROM_LIST_ORACLE_SOURCE,
  source
});

export const deleteOracleDBwindowSourceFromSavedList = source => ({
  type: actionConstant.DELETE_SOURCE_FROM_LIST_ORACLE_WINDOWS_DB_SOURCE,
  source
});

export const deleteLinuxSourceFromSavedList = source => ({
  type: actionConstant.DELETE_SOURCE_FROM_LIST_LINUX_SOURCE,
  source
});
export const windowsSourceAddSource = data => ({
  type: actionConstant.ADD_SOURCES_WINDOWS_SOURCE,
  data
});

export const clearWindowsSourcesList = () => ({
  type: actionConstant.CLEAR_WINDOWS_SOURCE_LIST
});

export const clearOracleSourcesList = () => ({
  type: actionConstant.CLEAR_ORACLE_SOURCE_LIST
});

export const clearlinuxSourcesList = () => ({
  type: actionConstant.CLEAR_LINUX_SOURCE_LIST
});

export const clearWindowsSourcesError = () => ({
  type: actionConstant.CLEAR_WINDOWS_SOURCE_ERROR
});

export const clearOracleSourcesError = () => ({
  type: actionConstant.CLEAR_ORACLE_SOURCE_ERROR
});

export const clearlinuxSourcesError = () => ({
  type: actionConstant.CLEAR_LINUX_SOURCE_ERROR
});

export const linuxSourcesSaveToList = linuxConfigObj => ({
  type: actionConstant.SAVE_TO_LIST_LINUX_SOURCE,
  linuxConfigObj
});
export const linuxSourceAddSource = data => ({
  type: actionConstant.ADD_SOURCES_LINUX_SOURCE,
  data
});
export const getSitesAction = sites => ({
  type: actionConstant.GET_SITE_NAME,
  payload: sites
});
export const updateSiteName = sitesConfig => ({
  type: actionConstant.UPDATE_SITE_NAME,
  sitesConfig
});
export const deleteSite = siteId => ({
  type: actionConstant.DELETE_SITE,
  siteId
});
export const setSelectedSite = data => ({
  type: actionConstant.SET_SELECTED_SITE,
  data
});

export const linuxBackupServerSourceSaveToList = data => ({
  type: actionConstant.SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE,
  data
});

export const linuxBackupServerSourceRemoveFromList = uuid => ({
  type: actionConstant.REMOVE_FROM_LIST_LINUX_BACKUP_SERVER_SOURCE,
  uuid
});

export const linuxBackupServerSourceSaveToListError = data => ({
  type: actionConstant.SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_ERROR,
  data
});

export const linuxBackupServerSourceSaveToListSuccess = data => ({
  type: actionConstant.SAVE_TO_LIST_LINUX_BACKUP_SERVER_SOURCE_SUCCESS,
  data
});

export const linuxBackupServerSourceAddSource = data => ({
  type: actionConstant.ADD_SOURCES_LINUX_BACKUP_SERVER_SOURCE,
  data
});

export const windowsSourceSaveToListSuccess = data => ({
  type: actionConstant.SAVE_TO_LIST_WINDOWS_SOURCE_SUCCESS,
  data
});

export const oracleSourceSaveToListSuccess = data => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_SOURCE_SUCCESS,
  data
});

export const linuxSourceSaveToListSuccess = data => ({
  type: actionConstant.SAVE_TO_LIST_LINUX_SOURCE_SUCCESS,
  data
});

export const windowsSourceSaveToListError = data => ({
  type: actionConstant.SAVE_TO_LIST_WINDOWS_SOURCE_ERROR,
  data
});

export const oracleSourceSaveToListError = data => ({
  type: actionConstant.SAVE_TO_LIST_ORACLE_SOURCE_ERROR,
  data
});

export const linuxSourceSaveToListError = data => ({
  type: actionConstant.SAVE_TO_LIST_LINUX_SOURCE_ERROR,
  data
});

export const discoverSourcesAdd = activeDirectory => ({
  type: actionConstant.ADD_DISCOVER_SOURCES,
  activeDirectory
});

export const authenticateADWindowsSources = (
  selectedList,
  authConfig,
  siteId
) => ({
  type: actionConstant.AUTHENTICATE_AD_WINDOWS_SOURCES,
  selectedList,
  authConfig,
  siteId
});

export const browseActiveDirectories = activeDirectories => ({
  type: actionConstant.BROWSE_ACTIVE_DIRECTORIES,
  activeDirectories
});

export const discoverSourcesAddSuccess = data => ({
  type: actionConstant.ADD_DISCOVER_SOURCES_SUCCESS,
  data
});

export const addSourcesFromActiveDirectory = data => ({
  type: actionConstant.ADD_SOURCES_FROM_ACTIVE_DIRECTORIES,
  data
});

export const changeActiveDirectoryPanel = actionType => ({
  type: actionType
});

export const deleteActiveDirectory = data => ({
  type: actionConstant.DELETE_ACTIVE_DIRECTORY,
  data
});

export const showActiveDirectoryAuthenticationModal = () => ({
  type: actionConstant.SHOW_AUTH_MODAL
});

export const getActiveDirectories = () => ({
  type: actionConstant.GET_ACTIVE_DIRECTORIES
});

export const hideActiveDirectoryAuthenticationModal = () => ({
  type: actionConstant.CLOSE_AUTH_MODAL
});

export const clearAdAuthResponse = () => ({
  type: actionConstant.CLEAR_AD_AUTH_RESPONSE
});

export const clearAdData = () => ({
  type: actionConstant.CLEAR_AD_DATA
});

export const clearSuccessfulAuthenticatedADSource = () => ({
  type: actionConstant.CLEAR_SUCCESSFULL_AUTHENTICATED_AD_SOURCES
});

export const discoverSourcesAddFailure = errors => ({
  type: actionConstant.ADD_DISCOVER_SOURCES_FAILURE,
  errors
});

export const updateADSourcesSuccessfullyAuthenticated = data => ({
  type: actionConstant.AD_SOURCES_SUCCESSFULLY_AUTHENTICATED,
  data
});
export const updateADSourcesfaileduthenticated = data => ({
  type: actionConstant.AD_SOURCES_FAILED_AUTHENTIATION,
  data
});

export const clearFailedSourceFromAD = data => ({
  type: actionConstant.CLEAR_AD_SOURCES_FAILED_AUTHENTIATION,
  data
});

export const clearAdSourcesList = () => ({
  type: actionConstant.CLEAR_AD_SOURCES_LIST
});

export const clearADError = () => ({
  type: actionConstant.CLEAR_AD_ERROR
});

export const clearADFetchSourceError = () => ({
  type: actionConstant.CLEAR_AD_SOURCES_ERROR
});

export const addFetchADSourcesError = data => ({
  type: actionConstant.FETCH_SOURCES_FROM_ACTIVE_DIRECTORIES_ERROR,
  data
});

export const adSourcesAuthResponse = (result, dataWithCredentials) => ({
  type: actionConstant.AD_SOURCES_AUTH_RESPONSE,
  result,
  dataWithCredentials
});

export const updateADSourcesFailedAuthenticated = data => ({
  type: actionConstant.AD_SOURCES_FAILED_AUTHENTICATED,
  data
});

export const deleteAuthenticatedSourceFromList = data => ({
  type: actionConstant.DELETE_AUTHENTICATED_SOURCE,
  data
});

export const selectAdForBrowse = (entryId, selected) => ({
  type: actionConstant.SELECT_AD_FOR_BROWSE,
  entryId,
  selected
});

export const errorMessageForModal = data => ({
  type: actionConstant.ERROR_MESSAGE_CONNECT,
  data
});
export const discoverSourcesSourceNames = data => ({
  type: actionConstant.DISCOVER_SOURCE_NAME,
  data
});
export const getDisoverSourceName = data => ({
  type: actionConstant.GET_DISCOVER_SOURCE_NAME,
  data
});
export const verifyCredential = data => ({
  type: actionConstant.DISCOVER_SOURCES_AUTHENTICATION_CREDENTIAL,
  data
});
export const discoverSourceAddSource = data => ({
  type: actionConstant.DISCOVER_SOURCES_ADD_SOURCES,
  data
});

export const adWinSourceAuthSuccess = source => ({
  type: actionConstant.AD_WIN_SOURCE_AUTH_SUCCESS,
  source
});

export const adWinSourceAuthFailure = ({ source, error_message }) => ({
  type: actionConstant.AD_WIN_SOURCE_AUTH_FAILURE,
  source,
  error_message
});

export const adInitAuthCount = total => ({
  type: actionConstant.AD_INITIALIZE_AUTH_COUNT,
  total
});

export const adIncrementAuthCount = () => ({
  type: actionConstant.AD_INCREMENT_AUTH_COUNT
});

export const addSourcesHypervisorVmwareConnect = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_VMWARE_CONNECT,
  data
});

export const clearErrorMessage = () => ({
  type: actionConstant.CLEAR_ERROR_MESSAGE
});

export const addSourcesHypervisorVmwareConnectSuccess = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_VMWARE_CONNECT_SUCCESS,
  data
});

export const addSourcesHypervisorConnectSuccess = data => ({
  type: actionConstant.ADD_SOURCES_HYPERVISOR_CONNECT_SUCCESS,
  data
});

export const addNewSite = data => ({
  type: actionConstant.ADD_NEW_SITE,
  data
});

export const switchAddSiteTab = data => ({
  type: actionConstant.SWITCH_ADD_SITE_TAB,
  data
});

export const createNewSite = siteobj => ({
  type: actionConstant.CREATE_NEW_SITE,
  siteobj
});

export const restoreNewSiteValue = () => ({
  type: actionConstant.RESTORE_NEW_SITE_VALUE
});

export const updateSitesData = () => ({
  type: actionConstant.UPDATE_SITES_DATA
});

export const addSourcesHypervisorConnectError = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_CONNECT_ERROR,
  data
});

export const addSourcesHypervisorHyperVConnect = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_HYPERV_CONNECT,
  data
});

export const addSourcesHypervisorHyperVConnectSuccess = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_HYPERV_CONNECT_SUCCESS,
  data
});
export const addSourcesHypervisorNutanixConnect = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_NUTANIX_CONNECT,
  data
});

export const addSourcesHypervisorNutanixConnectSuccess = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_NUTANIX_CONNECT_SUCCESS,
  data
});

export const clearHyperVisorForm = data => ({
  type: actionConstant.CLEAR_HYPER_FORM,
  data
});
export const resetAddSourcesHypervisor = () => ({
  type: actionConstant.RESET_ADD_SOURCES_HYPER
});

export const addSourcesHyperSaveToList = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_SAVE_TO_LIST,
  data
});

export const addSourcesHyperSaveToListVmware = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_SAVE_TO_LIST_VMWARE,
  data
});

export const addSourcesHyperValidationSourcePolicyEsx = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_ESX,
  data
});

export const addSourcesHyperValidationSourcePolicyHV = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_VALIDATION_SOURCE_POLICY_HV,
  data
});

export const addSourcesHyperSaveToListHN = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_SAVE_TO_LIST_HN,
  data
});

export const addSourcesHypervisorSaveToList = data => ({
  type: actionConstant.ADD_SOURCES_HYPERVISOR_SAVE_TO_LIST,
  data
});

export const addSourcesHyperDeleteSelection = uuid => ({
  type: actionConstant.ADD_SOURCES_HYPER_DELETE_SELECTION,
  uuid
});

export const clearAddSourcesHyperSavedList = () => ({
  type: actionConstant.CLEAR_ADD_SOURCES_HYPER_SAVED_LIST
});

export const addSourcesHyperSelectSite = (id, name) => ({
  type: actionConstant.ADD_SOURCES_HYPER_SELECT_SITE,
  id,
  name
});

export const addSourcesHypervisorAddSource = () => ({
  type: actionConstant.ADD_SOURCES_HYPER_ADD_SOURCE
});

export const addSourcesHypervisorAddSourcePolicyEsx = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_ESX,
  data
});

export const addSourcesHypervisorAddSourcePolicyHV = data => ({
  type: actionConstant.ADD_SOURCES_HYPER_ADD_SOURCE_POLICY_HV,
  data
});

export const addHypervisorSources = () => ({
  type: actionConstant.ADD_HYPERVISOR_SOURCES
});

export const addSourcesSetHypervisorId = id => ({
  type: actionConstant.ADD_SOURCES_SET_HYPERVISOR_ID,
  id
});

export const getVmwareServerType = () => ({
  type: actionConstant.GET_VMWARE_SERVER_TYPE
});

export const vmwareConnSettingsChanged = () => ({
  type: actionConstant.VMWARE_CONNECTION_DETAILS_CHANGED
});

export const vmwareServerTypeSuccess = serverType => ({
  type: actionConstant.VMWARE_SERVER_TYPE_SUCCESS,
  serverType
});

/**
 * Recovery Point Servers actions
 */

export const loadRpsList = (
  page = 1,
  pageSize = 25,
  searchText = "",
  advancedFilter = []
) => ({
  type: actionConstant.LOAD_RPS_LIST,
  page,
  pageSize,
  searchText,
  advancedFilter
});

export const loadAllRpsList = (searchText = "", advancedFilter = []) => ({
  type: actionConstant.LOAD_ALL_RPS_LIST,
  searchText,
  advancedFilter
});

export const loadAllDataStores = (rpsId = null) => ({
  type: actionConstant.LOAD_ALL_DATA_STORES,
  rpsId
});

export const loadRpsListSuccess = data => ({
  type: actionConstant.LOAD_RPS_LIST_SUCCESS,
  data
});

export const stopLoadRpsList = () => ({
  type: actionConstant.STOP_LOAD_RPS_LIST
});

export const clearRpsList = () => ({ type: actionConstant.CLEAR_RPS_LIST });

export const setCurrentRps = data => ({
  type: actionConstant.SET_CURRENT_RPS,
  data
});

export const loadCurrentRps = id => ({
  type: actionConstant.LOAD_CURRENT_RPS,
  id
});

export const setCurrentRpsId = id => ({
  type: actionConstant.SET_CURRENT_RPS_ID,
  id
});

export const deleteRps = rpsId => ({
  type: actionConstant.DELETE_RPS,
  id: rpsId
});

export const updateRps = data => ({
  type: actionConstant.UPDATE_RPS,
  data
});

export const bulkRefreshRps = rpsIdArray => ({
  type: actionConstant.REFRESH_RPS,
  idArray: rpsIdArray
});

export const refreshRpsSuccess = data => ({
  type: actionConstant.REFRESH_RPS_SUCCESS,
  data
});

/**
 * Data Stores actions
 */
export const refreshDataStoreSuccess = data => ({
  type: actionConstant.REFRESH_DATA_STORE_SUCCESS,
  data
});

export const clearDataStores = () => ({
  type: actionConstant.CLEAR_DATA_STORES
});

export const loadDataStores = (
  page = 1,
  pageSize = 25,
  searchText = "",
  advancedFilter = [],
  sort = null,
  rpsId = null
) => ({
  type: actionConstant.LOAD_DATA_STORES,
  page,
  pageSize,
  searchText,
  advancedFilter,
  sort,
  rpsId
});

export const updateSelectedOption = (searchText = null) => ({
  type: actionConstant.UPDATE_SELECTED_OPTION,
  searchText
});

export const loadDataStoresSearch = (rpsServerId = null, filter = null) => ({
  type: actionConstant.LOAD_DATA_STORES_SEARCH,
  rpsServerId,
  filter
});

export const loadDataStoresSuccess = data => ({
  type: actionConstant.LOAD_DATA_STORES_SUCCESS,
  data
});

export const setCurrentDataStore = data => ({
  type: actionConstant.SET_CURRENT_DATA_STORE,
  data
});
export const setDataStoreEncryptionEnabled = id => ({
  type: actionConstant.SET_DATA_STORE_ENCRYPTION_ENABLED,
  id
});

export const setSQLServerWriter = id => ({
  type: actionConstant.SET_RESTORE_VOLUME,
  id
});

export const loadCurrentDataStore = id => ({
  type: actionConstant.LOAD_CURRENT_DATA_STORE,
  id
});

export const loadCurrentDataStoreForSourceRecovery = id => ({
  type: actionConstant.LOAD_CURRENT_DATA_STORE_SOURCE_RECOVERY,
  id
});

export const setCurrentDataStoreId = id => ({
  type: actionConstant.SET_CURRENT_DATA_STORE_ID,
  id
});

/**
 * Sites Actions
 */

export const loadSites = (page = 1, pageSize = 25) => ({
  type: actionConstant.LOAD_SITES,
  page,
  pageSize
});

export const loadSitesSuccess = data => ({
  type: actionConstant.LOAD_SITES_SUCCESS,
  data
});

/**
 * Protect > Destination > Remote Console
 */

export const remoteConsoleList = (page = 1, pageSize = 25) => ({
  type: actionConstant.LOAD_REMOTE_CONSOLE_LIST,
  page,
  pageSize
});

export const remoteConsoleListSuccess = (data, pagination) => ({
  type: actionConstant.LOAD_REMOTE_CONSOLE_LIST_SUCCESS,
  data,
  pagination
});

export const deleteRemoteConsole = id => ({
  type: actionConstant.DELETE_REMOTE_CONSOLE,
  id
});

export const deleteRemoteConsoleSuccess = id => ({
  type: actionConstant.DELETE_REMOTE_CONSOLE_SUCCESS,
  id
});

export const addRemoteConsole = addRemoteConsoleObj => ({
  type: actionConstant.ADD_REMOTE_CONSOLE,
  addRemoteConsoleObj
});

export const updateRemoteConsole = addRemoteConsoleObj => ({
  type: actionConstant.UPDATE_REMOTE_CONSOLE,
  addRemoteConsoleObj
});

export const addDataStore = (dataStoreObj, history, samePaths) => ({
  type: actionConstant.ADD_DATA_STORE_TO_RPS,
  payload: { dataStoreObj, history, samePaths }
});

export const addCloudDataStore = (dataStoreObj, history, samePaths) => ({
  type: actionConstant.ADD_CLOUD_DATA_STORE_TO_RPS,
  payload: { dataStoreObj, history, samePaths }
});

export const updateDataStoreRPS = (dataStoreObj, loadingId) => ({
  type: actionConstant.UPDATE_DATA_STORE_RPS,
  dataStoreObj,
  loadingId
});

export const updateCloudDataStore = (dataStoreObj, loadingId) => ({
  type: actionConstant.UPDATE_CLOUD_DATA_STORE,
  dataStoreObj,
  loadingId
});
/**
 * Protect > Destination > Cloud Account
 */
export const cloudAccountList = () => ({
  type: actionConstant.LOAD_CLOUD_ACCOUNT_LIST
});

export const cloudAccountListByRPSID = (rps = null) => ({
  type: actionConstant.LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_ID,
  data: { site_id: rps }
});

export const endPointsAccountList = data => ({
  type: actionConstant.LOAD_END_POINT_LIST,
  data
});

export const shadowXafeList = (page = 1, pageSize = 25) => ({
  type: actionConstant.LOAD_SHADOW_XAFE_LIST,
  page,
  pageSize
});

export const deleteCloudAccount = data => ({
  type: actionConstant.DELETE_CLOUD_ACCOUNT,
  data
});

export const cloudAccountListSuccess = (data, pagination) => ({
  type: actionConstant.LOAD_CLOUD_ACCOUNT_LIST_SUCCESS,
  data,
  pagination
});

export const cloudAccountListByRPSIdSuccess = (data, pagination) => ({
  type: actionConstant.LOAD_CLOUD_ACCOUNT_LIST_BY_RPS_SUCCESS,
  data,
  pagination
});

export const endPointAccountListSuccess = data => ({
  type: actionConstant.LOAD_END_POINT_LIST_SUCCESS,
  data
});

export const shadowXafeListSuccess = (data, pagination) => ({
  type: actionConstant.LOAD_SHADOW_XAFE_LIST_SUCCESS,
  data,
  pagination
});

export const deleteCloudAccountSuccess = id => ({
  type: actionConstant.DELETE_CLOUD_ACCOUNT_SUCCESS,
  id
});

export const testCloudAccountConnection = id => ({
  type: actionConstant.TEST_CLOUD_ACCOUNT_CONNECTION,
  id
});

export const testCloudAccountConnectionSuccess = id => ({
  type: actionConstant.TEST_CLOUD_ACCOUNT_CONNECTION_SUCCESS,
  id
});

/**
 * Protect > Destination > Shared Folder
 */
export const sharedFolderList = (page = 1, pageSize = 25) => ({
  type: actionConstant.LOAD_SHARED_FOLDER_LIST,
  page,
  pageSize
});

export const sharedFolderListSuccess = (data, pagination) => ({
  type: actionConstant.LOAD_SHARED_FOLDER_LIST_SUCCESS,
  data,
  pagination
});

export const storesharedFolderLocalData = data => ({
  type: actionConstant.LOAD_SHARED_FOLDER_LIST_LOCAL_DATA_SUCCESS,
  data
});

export const fetchLightWeightProxies = () => ({
  type: actionConstant.FETCH_LIGHTWEIGHT_PROXIES
});

export const fetchLightWeightProxiesSuccess = data => ({
  type: actionConstant.FETCH_LIGHTWEIGHT_PROXIES_SUCCESS,
  data
});

export const deleteSharedFolder = id => ({
  type: actionConstant.DELETE_SHARED_FOLDER,
  id
});

export const deleteSharedFolderSuccess = id => ({
  type: actionConstant.DELETE_SHARED_FOLDER_SUCCESS,
  id
});

export const loadCurrentSharedFolder = id => ({
  type: actionConstant.LOAD_CURRENT_SHARED_FOLDER,
  id
});

export const updateSharedFolder = data => ({
  type: actionConstant.UPDATE_SHARED_FOLDER,
  data
});

export const rpsDataStoreListSuccess = data => ({
  type: actionConstant.RPS_DATA_STORE_LIST_SUCCESS,
  data
});

export const rpsDataStoreSourceListSuccess = data => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_SUCCESS,
  data
});
export const rpsDataStoreSourceListFailure = data => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_FAILURE,
  data
});

export const rpsDataStoreList = (id, page = 1, pageSize = 25) => ({
  type: actionConstant.RPS_DATA_STORE_LIST,
  id,
  page,
  pageSize
});

export const rpsDataStoreSourceList = (id, data) => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST,
  id,
  data
});

export const getPolicyListForJumpstart = id => ({
  type: actionConstant.GET_POLICY_LIST,
  id
});
export const clearPolicyListForJumpstart = () => ({
  type: actionConstant.CLEAR_POLICY_LIST
});

export const getPolicyListSuccess = data => ({
  type: actionConstant.GET_POLICY_LIST_SUCCESS,
  data
});

export const onSearchJumpstart = data => ({
  type: actionConstant.ON_SEARCH_FILTER,
  data
});

export const onPolicyChange = data => ({
  type: actionConstant.ON_POLICY_CHANFE_JUMPSTART,
  data
});
export const onClearSorce = () => ({
  type: actionConstant.ON_CLEAR_SOURCE_LIST
});
export const onClearForm = () => ({
  type: actionConstant.ON_CLEAR_FORM
});
export const targetDataStore = selectedDataStore => ({
  type: actionConstant.TARGETED_DATA_STORE_LIST,
  selectedDataStore
});

export const setDataStore = data => ({
  type: actionConstant.SET_DATA_STORE_LIST,
  data
});
export const targetRspList = rspList => ({
  type: actionConstant.TARGETED_RPS_LIST,
  rspList
});

export const connectSharedFolder = data => ({
  type: actionConstant.CONNECT_SHARED_FOLDER,
  data
});
export const saveSharedFolderDetails = data => ({
  type: actionConstant.SAVE_SHARED_FOLDER_DETAILS,
  data
});

export const saveJumpStart = (id, data) => ({
  type: actionConstant.SAVE_JUMP_START,
  id,
  data
});

export const saveJumpStartError = data => ({
  type: actionConstant.SAVE_JUMP_START_FAILURE,
  data
});

export const onSessionPasswordApply = data => ({
  type: actionConstant.ON_SESSION_PASSWORD_APPLY,
  data
});

export const onSessionPasswordApplySuccess = (data, resData) => ({
  type: actionConstant.ON_SESSION_PASSWORD_APPLY_SUCCESS,
  data,
  resData
});

export const onSessionPasswordApplyFailure = data => ({
  type: actionConstant.ON_SESSION_PASSWORD_APPLY_FAILURE,
  data
});

export const connectSharedFolderSuccess = data => ({
  type: actionConstant.CONNECT_SHARED_FOLDER_SUCCESS,
  data
});
export const connectSharedFolderFailure = data => ({
  type: actionConstant.CONNECT_SHARED_FOLDER_FAILURE,
  data
});

/**
 * Protect > Destination > Cloud Account
 */

export const createCloudAccount = dataObj => ({
  type: actionConstant.CREATE_CLOUD_ACCOUNT,
  dataObj
});

export const updateCloudAccount = updatedDataObj => ({
  type: actionConstant.UPDATE_CLOUD_ACCOUNT,
  updatedDataObj
});

export const addRecoveryPointServer = addRPSObj => ({
  type: actionConstant.ADD_NEW_RPS,
  addRPSObj
});
export const importDataStoreTemp = data => ({
  type: actionConstant.IMPORT_DATA_STORE_TEMP,
  data
});
export const importDataStoreTempClear = data => ({
  type: actionConstant.IMPORT_DATA_STORE_TEMP_CLEAR,
  data
});
export const importDataStoreStepOne = importDataStoreObj => ({
  type: actionConstant.IMPORT_DATA_STORE_STEP_ONE,
  importDataStoreObj
});
export const importDataStoreStepTwo = (importDataStoreObj, history) => ({
  type: actionConstant.IMPORT_DATA_STORE_STEP_TWO,
  data: { importDataStoreObj, history }
});

export const importCloudDataStoreStepOne = importCloudDataStoreObj => ({
  type: actionConstant.IMPORT_CLOUD_DATA_STORE_STEP_ONE,
  importCloudDataStoreObj
});

export const importCloudDataStoreStepTwo = (
  importCloudDataStoreObj,
  history
) => ({
  type: actionConstant.IMPORT_CLOUD_DATA_STORE_STEP_TWO,
  data: { importCloudDataStoreObj, history }
});

export const importCloudDataStoreTemp = data => ({
  type: actionConstant.IMPORT_CLOUD_DATA_STORE_TEMP,
  data
});
export const importCloudDataStoreTempClear = data => ({
  type: actionConstant.IMPORT_CLOUD_DATA_STORE_TEMP_CLEAR,
  data
});

export const refreshDataStoreData = data => ({
  type: actionConstant.REFRESH_DATA_STORE_DATA,
  data
});

export const updateAddCloudAccountFlag = data => ({
  type: actionConstant.UPDATE_ADD_CLOUD_ACCOUNT_FLAG,
  data
});

export const loadRegisteredSites = siteType => ({
  type: actionConstant.LOAD_REGISTERED_SITES,
  siteType
});

export const loadRegisteredSitesSuccess = data => ({
  type: actionConstant.LOAD_REGISTERED_SITES_SUCCESS,
  data
});

export const deploySingleRPS = data => ({
  type: actionConstant.INSTALL_DEPLOY_RPS,
  data
});

export const deployBulkRPS = data => ({
  type: actionConstant.INSTALL_DEPLOY_RPS_BULK,
  data
});
export const cancelScheduledDeploy = rpsId => ({
  type: actionConstant.CANCEL_SCHEDULED_DEPLOY_RPS,
  id: rpsId
});

export const upgradeAgent = (data, id, gridInfo) => ({
  type: actionConstant.INSTALL_UPDATE_UPGRADE_AGENT,
  data,
  id,
  gridInfo
});
export const upgradeAgentBulk = (data, id, gridInfo) => ({
  type: actionConstant.INSTALL_UPDATE_UPGRADE_AGENT_BULK,
  data,
  id,
  gridInfo
});

export const cancelSourceScheduledDeploy = data => ({
  type: actionConstant.CANCEL_SOURCE_SCHEDULED_DEPLOY,
  data
});
export const getSharedPolicy = id => ({
  type: actionConstant.GET_SHARED_POLICY_DETAILS,
  id
});
export const getSharedPolicySuccess = data => ({
  type: actionConstant.GET_SHARED_POLICY_DETAILS_SUCCESS,
  data
});
export const clearSharedPolicy = () => ({
  type: actionConstant.CLEAR_SHARED_POLICY_DETAILS
});

export const sharedPolicyDetails = (data, id) => ({
  type: actionConstant.SHARE_POLICY,
  data,
  id
});

export const runAssuredRecoveryTest = (data, id) => ({
  type: actionConstant.RUN_ASSURED_RECOVERY_TEST,
  data,
  id
});

export const sourceRunAssuredRecoveryTest = (data, id) => ({
  type: actionConstant.SOURCE_RUN_ASSURED_RECOVERY_TEST,
  data,
  id
});

export const runAssuredSecurityScan = (data, id) => ({
  type: actionConstant.RUN_ASSURED_SECURITY_SCAN,
  data,
  id
});

export const sourceRunAssuredSecurityScan = (data, id) => ({
  type: actionConstant.SOURCE_RUN_ASSURED_SECURITY_SCAN,
  data,
  id
});

export const unSharedPolicyDetails = (data, id, onlyUnshare) => ({
  type: actionConstant.UN_SHARE_POLICY,
  data,
  id,
  onlyUnshare
});

export const getBrowseRecoveryPointsDS = datastoreID => ({
  type: actionConstant.GET_BROWSE_RECOVERY_POINT_DS,
  datastoreID
});

export const getBrowseRecoveryPointDSListSuccess = data => ({
  type: actionConstant.GET_BROWSE_RECOVERY_POINT_DS_SUCCESS,
  data
});

export const getBrowseRecoveryPointSharedFolder = (
  shareFolderId,
  serverID,
  serverType
) => ({
  type: actionConstant.GET_BROWSE_RECOVERY_POINT_SF,
  shareFolderId,
  serverID,
  serverType
});

export const getBrowseRecoveryPointSharedFolderListSuccess = data => ({
  type: actionConstant.GET_BROWSE_RECOVERY_POINT_SF_SUCCESS,
  data
});

export const setCurrentSharedFolder = data => ({
  type: actionConstant.SET_CURRENT_SHARED_FOLDER,
  data
});

export const authenticateSharedFolder = (data, selectConnectType = "") => ({
  type: actionConstant.AUTHENTICATE_SHARED_FOLDER,
  data,
  selectConnectType
});

export const storeSharedPathCredentials = data => ({
  type: actionConstant.STORE_SHARED_PATH_CREDENTIALS,
  data
});

export const authenticationConnectSuccess = data => ({
  type: actionConstant.CONNECT_AUTH_SUCCESS,
  data
});

export const authenticationConnectFailure = data => ({
  type: actionConstant.CONNECT_AUTH_FAILURE,
  data
});

export const clearAuthConnection = () => ({
  type: actionConstant.CLEAR_CONNECT_AUTH
});

export const deleteSource = id => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_ITEM_DELETE,
  id
});

export const refreshSource = id => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_ITEM_REFRESH,
  id
});

export const refreshRecoveryPoint = (sourceId, dataStoreId) => ({
  type: actionConstant.RPS_DATA_STORE_REFRESH_RECOVERY_POINT,
  sourceId,
  dataStoreId
});

export const refreshRecoveryPointSharedFolder = (
  sourceId,
  shareFolderId,
  serverID,
  serverType
) => ({
  type: actionConstant.SHARED_FOLDER_REFRESH_RECOVERY_POINT,
  sourceId,
  shareFolderId,
  serverID,
  serverType
});

export const mergeSource = id => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_ITEM_MERGE,
  id
});

export const getRetention = data => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_GET_RETENTION,
  data
});

export const setRetention = data => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_SET_RETENTION,
  data
});

export const mergeNow = data => ({
  type: actionConstant.RPS_DATA_STORE_SOURCE_LIST_RP_MERGE_NOW,
  data
});

export const loadRecoveryPointServers = () => ({
  type: actionConstant.LOAD_RECOVERY_POINT_SERVERS
});

export const clearBackupTrafficRPS = () => ({
  type: actionConstant.CLEAR_BACKUP_TRAFFIC_USING_RPS
});

export const loadNetworkForBackupTrafficRPSID = rpsId => ({
  type: actionConstant.LOAD_BACKUP_TRAFFIC_USING_RPS,
  rpsId
});

export const loadNetworkForRestoreTrafficRPSID = rpsId => ({
  type: actionConstant.LOAD_RESTORE_TRAFFIC_USING_RPS,
  rpsId
});

export const loadNetworkForRestoreTrafficProxyID = proxyId => ({
  type: actionConstant.LOAD_RESTORE_TRAFFIC_USING_PROXY,
  proxyId
});

export const loadRecoveryPointServersSuccess = data => ({
  type: actionConstant.LOAD_RECOVERY_POINT_SERVERS_SUCCESS,
  data
});

export const loadNetworkForBackupTrafficRPSIDSuccess = data => ({
  type: actionConstant.LOAD_BACKUP_TRAFFIC_USING_RPS_SUCCESS,
  data
});

export const loadNetworkForRestoreTrafficRPSIDSuccess = data => ({
  type: actionConstant.LOAD_RESTORE_TRAFFIC_USING_RPS_SUCCESS,
  data
});

export const loadNetworkForRestoreTrafficProxyIDSuccess = data => ({
  type: actionConstant.LOAD_RESTORE_TRAFFIC_USING_PROXY_SUCCESS,
  data
});

export const loadRPSReadOnlyDataStores = rpsId => ({
  type: actionConstant.LOAD_RPS_DATA_STORES,
  rpsId
});

export const loadRPSDataStoresSuccess = (rpsId, data) => ({
  type: actionConstant.LOAD_RPS_DATA_STORES_SUCCESS,
  rpsId,
  data
});

export const loadRPSDataStoresForAdhocAS = rpsId => ({
  type: actionConstant.LOAD_RPS_DATA_STORES_FOR_ADHOC_AS,
  rpsId
});

export const loadRPSDataStoresForAdhocASSuccess = (rpsId, data) => ({
  type: actionConstant.LOAD_RPS_DATA_STORES_FOR_ADHOC_AS_SUCCESS,
  rpsId,
  data
});

export const clearRPSDatastoresForAdhocAS = () => ({
  type: actionConstant.CLEAR_RPS_DATA_STORES_FOR_ADHOC_AS
});

export const loadBackupProxy = () => ({
  type: actionConstant.FETCH_BACKUP_PROXY
});

export const loadLBS = data => ({
  type: actionConstant.FETCH_LBS,
  data
});
export const loadLinuxLBS = () => ({
  type: actionConstant.FETCH_LINUX_LBS
});

export const loadLinux = () => ({
  type: actionConstant.FETCH_LINUX
});

export const getSelectedProxy = data => ({
  type: actionConstant.GET_SELECTED_PROXY,
  data
});
export const getSelectedLBS = data => ({
  type: actionConstant.GET_SELECTED_LBS,
  data
});
export const updateSelectedLBS = data => ({
  type: actionConstant.UPDATE_SELECTED_LBS,
  data
});

export const loadSelectedLBSNetworks = data => ({
  type: actionConstant.FETCH_SELECTED_LBS_NETWORKS,
  data
});

export const loadSelectedNetworkForBackupTraffic = data => ({
  type: actionConstant.FETCH_SELECTED_NETWORK_BACKUP_PROXY,
  data
});

export const clearSelectedNetworkForBackupTraffic = () => ({
  type: actionConstant.CLEAR_SELECTED_NETWORK_BACKUP_PROXY
});

export const loadSNBTrafficSuccess = data => ({
  type: actionConstant.FETCH_SELECTED_NETWORK_BACKUP_PROXY_SUCCESS,
  data
});
export const loadSelectedLBSNetworkSuccess = data => ({
  type: actionConstant.LOAD_SELECTED_LBS_NETWORK_SUCCESS,
  data
});
export const loadBackupProxySuccess = data => ({
  type: actionConstant.LOAD_BACKUP_PROXY_SUCCESS,
  data
});
export const loadLBSSuccess = data => ({
  type: actionConstant.LOAD_LBS_SUCCESS,
  data
});
export const clearLBSList = () => ({
  type: actionConstant.CLEAR_LBS_LIST
});
export const loadLinuxLBSSuccess = data => ({
  type: actionConstant.LOAD_LINUX_LBS_SUCCESS,
  data
});

export const loadLinuxSuccess = data => ({
  type: actionConstant.LOAD_LINUX_SUCCESS,
  data
});

export const loadVsbMonitorSuccess = data => ({
  type: actionConstant.LOAD_VSB_MONITOR_SUCCESS,
  data
});

export const getAvailableDriveLetters = data => ({
  type: actionConstant.GET_AVAILABLE_DRIVE_LETTERS,
  data
});

export const mountRecoveryPoint = data => ({
  type: actionConstant.MOUNT_RECOVERY_POINT,
  data
});

export const mountSQLDatabase = data => ({
  type: actionConstant.MOUNT_SQL_DATABASE,
  data
});

export const updateAvailableDriveLetters = data => ({
  type: actionConstant.UPDATE_AVAILABLE_DRIVE_LETTER,
  data
});

export const clearAvailableDriveLetters = () => ({
  type: actionConstant.CLEAR_AVAILABLE_DRIVE_LETTER
});

export const getSourcesWithWindowsFilter = () => ({
  type: actionConstant.GET_SOURCES_WITH_WINDOWS_FILTER
});

export const getSQLDBInstances = data => ({
  type: actionConstant.GET_SQL_DB_INSTANCES,
  data
});

export const resetSQLDBInstances = () => ({
  type: actionConstant.RESET_SQL_INSTANCE_DATA
});

export const updateAgentMachineSourcesList = data => ({
  type: actionConstant.UPDATE_AGENT_MACHINE_SOURCES_LIST,
  data
});

export const clearAgentMachineSourcesList = () => ({
  type: actionConstant.CLEAR_AGENT_MACHINE_SOURCES_LIST
});

export const addBackupProxyFlag = data => ({
  type: actionConstant.ADD_BACKUP_PROXY_FLAG,
  data
});

export const addLinuxBackupFlag = data => ({
  type: actionConstant.ADD_LINUX_BACKUP_FLAG,
  data
});

export const createBackupProxy = data => ({
  type: actionConstant.CREATE_BACKUP_PROXY,
  data
});

export const createLinuxBackupProxy = data => ({
  type: actionConstant.CREATE_LINUX_BACKUP_PROXY,
  data
});

export const createLinuxBackupServer = data => ({
  type: actionConstant.CREATE_LINUX_BACKUP_SERVER,
  data
});

export const addUncOrNfsPathSource = data => ({
  type: actionConstant.ADD_UNC_OR_NFS_PATH_SOURCE,
  data
});

export const UncOrNfsPathSaveToList = UncOrNfsConfigObj => ({
  type: actionConstant.UNC_OR_NFS_PATH_SAVE_TO_LIST,
  UncOrNfsConfigObj
});

export const addPaths = pathsList => ({
  type: actionConstant.ADD_PATHS,
  pathsList
});

export const UncOrNfsPathSaveToListSuccess = data => ({
  type: actionConstant.UNC_OR_NFS_PATH_SAVE_TO_LIST_SUCCESS,
  data
});

export const UncOrNfsPathSaveToListError = data => ({
  type: actionConstant.UNC_OR_NFS_PATH_SAVE_TO_LIST_ERROR,
  data
});

export const UncOrNfsDeletePath = source => ({
  type: actionConstant.UNC_OR_NFS_DELETE_PATH,
  source
});

export const UncOrNfsClearPathList = () => ({
  type: actionConstant.UNC_OR_NFS_CLEAR_PATH_LIST
});

export const UncOrNfsClearPathListError = () => ({
  type: actionConstant.UNC_OR_NFS_CLEAR_PATH_LIST_ERROR
});

export const getPolicyDataById = policyId => ({
  type: actionConstant.GET_POLICY_DATA_BY_ID,
  policyId
});

export const getSourceDataById = sourceId => ({
  type: actionConstant.GET_SOURCE_DATA_BY_ID,
  sourceId
});

export const getSourceDataForAssuredSecurityScanById = sourceId => ({
  type: actionConstant.GET_SOURCE_DATA_FOR_ASSURED_SECURITY_SCAN_BY_ID,
  sourceId
});

export const onPolicyDataGetSuccess = data => ({
  type: actionConstant.POLICY_DATA_GET_SUCCESS,
  data
});

export const onSourceARTDataGetSuccess = data => ({
  type: actionConstant.SOURCE_ART_DATA_GET_SUCCESS,
  data
});

export const onSourceASSDataGetSuccess = data => ({
  type: actionConstant.SOURCE_ASS_DATA_GET_SUCCESS,
  data
});

export const fetchAvailableVolumes = (source, selectedDestination) => ({
  type: actionConstant.FETCH_AVAILABLE_VOLUMES,
  source,
  selectedDestination
});

export const fetchAvailableVolumesSuccess = data => ({
  type: actionConstant.FETCH_AVAILABLE_VOLUMES_SUCCESS,
  data
});

export const fetchMountedVolumes = sourceId => ({
  type: actionConstant.FETCH_MOUNTED_VOLUMES,
  sourceId
});

export const fetchMountedDatabases = sourceId => ({
  type: actionConstant.FETCH_MOUNTED_DATABASES,
  sourceId
});

export const fetchMountedVolumesSuccess = data => ({
  type: actionConstant.FETCH_MOUNTED_VOLUMES_SUCCESS,
  data
});

export const fetchMountedDatabasesSuccess = data => ({
  type: actionConstant.FETCH_MOUNTED_DATABASES_SUCCESS,
  data
});

export const fetchMountedRPS = () => ({
  type: actionConstant.FETCH_MOUNTED_RPS
});

export const mountVolumesSuccess = data => ({
  type: actionConstant.MOUNT_VOLUME_SUCCESS,
  data
});

export const mountDatabasesSuccess = data => ({
  type: actionConstant.MOUNT_DATABASE_SUCCESS,
  data
});

export const dismountVolume = (
  sourceId,
  mountPath,
  mountDiskSignature,
  mountedSourceID,
  setisFinishEnabled
) => ({
  type: actionConstant.DISMOUNT_VOLUME,
  sourceId,
  mountPath,
  mountDiskSignature,
  mountedSourceID,
  setisFinishEnabled
});

export const dismountDatabase = (sourceId, data, setisFinishEnabled) => ({
  type: actionConstant.DISMOUNT_DATABASE,
  sourceId,
  data,
  setisFinishEnabled
});

export const getRecoveryPointsByDestinationPath = data => ({
  type: actionConstant.GET_RECOVERY_POINTS_BY_DESTINATION_PATH,
  data
});

export const getRecoveryOracleVersion = sourceId => ({
  type: actionConstant.GET_RECOVERY_ORACLE_VERSION,
  sourceId
});

export const storeOracleRecoveryVersionSuccess = data => ({
  type: actionConstant.STORE_RECOVERY_ORACLE_VERSION,
  data
});

export const clearOracleRecoveryVersion = () => ({
  type: actionConstant.CLEAR_RECOVERY_ORACLE_VERSION
});

export const getRecoveryOracleDBConfig = (data, openDialog = true) => ({
  type: actionConstant.GET_RECOVERY_ORACLE_DB_CONFIG,
  data,
  openDialog
});

export const storeRecoveryOracleDBSettings = data => ({
  type: actionConstant.STORE_RECOVERY_ORACLE_DB_SETTING,
  data
});

export const clearRecoveryOracleDBSettings = () => ({
  type: actionConstant.CLEAR_RECOVERY_ORACLE_DB_SETTING
});

export const storeRecoveryOracleDBTableSettings = data => ({
  type: actionConstant.STORE_RECOVERY_ORACLE_DB_TABLE_SETTING,
  data
});

export const clearRecoveryOracleDBTableSettings = () => ({
  type: actionConstant.CLEAR_RECOVERY_ORACLE_DB_TABLE_SETTING
});

export const storeOraclePDBList = data => ({
  type: actionConstant.STORE_RECOVERY_ORACLE_PDB_LIST,
  data
});

export const clearOraclePDBList = data => ({
  type: actionConstant.CLEAR_RECOVERY_ORACLE_PDB_LIST,
  data
});

export const onGetRecoveryPointsByDestinationPath = data => ({
  type: actionConstant.RECOVERY_POINTS_BY_DESTINATION_PATH_GET_SUCCESS,
  data
});

export const clearRecoveryPointDataForDestinationPath = () => ({
  type: actionConstant.CLEAR_RECOVERY_POINTS_DATA_FOR_DESTINATION_PATH
});

export const oracleRecoverDBConfigDataSuccess = data => ({
  type: actionConstant.SAVE_RECOVERY_DB_CONFIG_DATA,
  data
});

export const clearOracleRecoverDBConfigFailure = () => ({
  type: actionConstant.CLEAR_RECOVERY_DB_CONFIG_DATA
});

export const clearOracleRecoverDBStaticTables = () => ({
  type: actionConstant.CLEAR_RECOVERY_DB_STATIC_TABLE
});

export const getRecoveryPointResponse = data => ({
  type: actionConstant.GET_RECOVERY_POINT_RESPONSE,
  data
});

export const getDestinationsBySourceId = data => ({
  type: actionConstant.GET_DESTINATIONS_BY_SOURCE_ID,
  data
});

export const onGetDestinationsBySourceId = data => ({
  type: actionConstant.DESTINATIONS_DATA_GET_SUCCESS,
  data
});

export const clearSourceARTTaskDetails = () => ({
  type: actionConstant.CLEAR_SOURCE_ART_DETAILS
});

export const clearSourceASSTaskDetails = () => ({
  type: actionConstant.CLEAR_SOURCE_ASS_DETAILS
});

export const clearRecoveryRestoreMountData = () => ({
  type: actionConstant.CLEAR_RECOVERY_MOUNT_DATA
});

export const clearRestoreRecoveryPoints = () => ({
  type: actionConstant.CLEAR_RESTORE_RECOVERY_POINTS
});

export const clearDestinationData = () => ({
  type: actionConstant.CLEAR_DESINATIONS_DATA
});

export const clearMountedVolumes = () => ({
  type: actionConstant.FETCH_MOUNTED_VOLUMES
});

export const getAllHypervisors = () => ({
  type: actionConstant.GET_ALL_HYPERVISORS
});

export const getHypervisorConfigurationByID = hypervisorID => ({
  type: actionConstant.GET_HYPERVISOR_CONFIGURATION,
  data: hypervisorID
});

export const getHypervisorVMConfigurationByID = data => ({
  type: actionConstant.GET_VMWARE_CONFIGURATION_FOR_POLICY,
  data
});

export const getEsxServersList = data => ({
  type: actionConstant.GET_ESX_SERVERS,
  data
});

export const fetchEsxServersSuccess = data => ({
  type: actionConstant.GET_ESX_SERVERS_SUCCESS,
  data
});

export const getAllProxiesForPolicy = () => ({
  type: actionConstant.GET_ALL_POLICY_PROXIES
});

export const getAllHypervisorsSuccess = data => ({
  type: actionConstant.GET_ALL_HYPERVISORS_SUCCESS,
  data
});

export const getHypervisorConfigurationByIDSuccess = data => ({
  type: actionConstant.GET_HYPERVISOR_CONFIGURATION_SUCCESS,
  data
});

export const clearHypervisorConfigurationByIDSuccess = () => ({
  type: actionConstant.CLEAR_HYPERVISOR_CONFIGURATION_DATA
});

export const getAllProxiesForPolicySuccess = data => ({
  type: actionConstant.GET_ALL_PROXIES_FOR_POLICY_SUCCESS,
  data
});

export const getCurrentActionCalledDS = (
  rpsServerId,
  filter,
  pollingAction = true,
  loadOnStart = false
) => ({
  type: actionConstant.GET_CURRENT_ACTION_CALLED_DATA_STORE,
  rpsServerId,
  filter,
  pollingAction,
  loadOnStart
});

export const saveDestinatioHost = data => ({
  type: actionConstant.SAVE_DESTINATION_HOST,
  data
});

export const getDestinationHosts = () => ({
  type: actionConstant.GET_DESTINATION_HOSTS
});

export const getDestinationHostsSuccess = data => ({
  type: actionConstant.GET_DESTINATION_HOSTS_SUCCESS,
  data
});

export const addDestinationHost = data => ({
  type: actionConstant.ADD_DESTINATION_HOST,
  data
});

export const addDestinationHostLinux = data => ({
  type: actionConstant.ADD_DESTINATION_HOST_LINUX,
  data
});

export const startRecovery = () => ({
  type: actionConstant.START_RECOVERY
});

export const setRecoveryType = data => ({
  type: actionConstant.SET_RECOVERY_TYPE,
  data
});

export const resetRecovery = () => ({
  type: actionConstant.RESET_RECOVERY
});
export const resetRecoveryOnConnect = () => ({
  type: actionConstant.RESET_RECOVERY_ON_CONNECT
});

export const fetchInitialTreeData = () => ({
  type: actionConstant.FETCH_INITIAL_TREE_DATA
});

export const fetchRecursiveTreeData = () => ({
  type: actionConstant.FETCH_RECURSIVE_TREE_DATA
});

export const setTreeSelection = data => ({
  type: actionConstant.SET_TREE_SELECTION,
  data
});

export const fetchInitialTreeDataSuccess = data => ({
  type: actionConstant.FETCH_INITIAL_TREE_DATA_SUCCESS,
  data
});

export const fetchRecursiveTreeDataSuccess = data => ({
  type: actionConstant.FETCH_RECURSIVE_TREE_DATA_SUCCESS,
  data
});

export const storeSQLSummaryData = data => ({
  type: actionConstant.SET_SQL_SUMMARY_DATA,
  data
});

export const storeSQLInstanceData = data => ({
  type: actionConstant.SET_SQL_INSTANCE_DATA,
  data
});

export const storeOracleSummaryData = data => ({
  type: actionConstant.SET_ORACLE_SUMMARY_DATA,
  data
});

export const storeSQLDbDetails = data => ({
  type: actionConstant.SET_SQL_DB_DETAILS,
  data
});

export const clearSQLDbDetails = data => ({
  type: actionConstant.CLEAR_SQL_DB_DETAILS,
  data
});

export const storeSQLClickedConfigureButtonId = data => ({
  type: actionConstant.SET_SQL_CLICKED_CONFIGURATION_BUTTON_ID,
  data
});

export const storeNewIVMRecoveryPoint = data => ({
  type: actionConstant.STORE_NEW_IVM_RP,
  data
});

export const backupPasswordsData = data => ({
  type: actionConstant.BACKUP_PASSWORDS_DATA,
  data
});

export const collectDiagnosticData = data => ({
  type: actionConstant.COLLECT_DIAGNOSTIC_DATA,
  data
});

export const storeValidateConfigdata = data => ({
  type: actionConstant.STORE_VALIDATE_CONFIG_DATA,
  data
});

export const validateRecoveryPoint = data => ({
  type: actionConstant.VALIDATE_RP,
  data
});

export const switchAddIVMTab = data => ({
  type: actionConstant.SWITCH_ADD_IVM_TAB,
  data
});

export const restoreNewIVMValue = () => ({
  type: actionConstant.RESTORE_NEW_IVM_VALUE
});

/**
 * IVM Action Creators
 */
export const fetchProxiesIVM = (
  data,
  srcId = "",
  srcType = "",
  destinationType = ""
) => ({
  type: actionConstant.FETCH_PROXIES_IVM,
  data,
  srcId,
  srcType,
  destinationType
});

export const fetchProxiesIVMSuccess = data => ({
  type: actionConstant.FETCH_PROXIES_IVM_SUCCESS,
  data
});

export const clearIVM = () => ({
  type: actionConstant.CLEAR_IVM
});

export const clearBackupPasswords = () => ({
  type: actionConstant.CLEAR_BACKUP_PASSWORDS
});

export const setSelectedProxy = data => ({
  type: actionConstant.SET_SELECTED_PROXY,
  data
});

export const validateNfs = data => ({
  type: actionConstant.IVM_VALIDATE_NFS,
  data
});

export const validateIVMSuccess = data => ({
  type: actionConstant.VALIDATE_IVM_SUCCESS,
  data
});
export const validateHyperV = data => ({
  type: actionConstant.IVM_VALIDATE_HYPERV,
  data
});

export const submitIVM = data => ({
  type: actionConstant.SUBMIT_IVM,
  data
});

export const storeFnFSummaryData = data => ({
  type: actionConstant.SET_FnF_SUMMARY_DATA,
  data
});

export const storeVolumeSummaryData = data => ({
  type: actionConstant.SET_VOLUME_SUMMARY_DATA,
  data
});

export const storeVMSummaryData = data => ({
  type: actionConstant.SET_VM_SUMMARY_DATA,
  data
});

export const volumeRecoveryConfirmSubmit = data => ({
  type: actionConstant.VOLUME_RECOVERY_CONFIRM_SUBMIT,
  password: data
});

/**
 * Policy Shared Folder Connect Action Creator
 */
export const sharedFolderDestinationConnect = (data, formName, namespace) => ({
  type: actionConstant.POLICY_SHARED_FOLDER_DESTINATION_CONNECT,
  data,
  formName,
  namespace
});

export const fetchDestinationPath = payload => ({
  type: actionConstant.FETCH_DESTINATION_PATH_REQUEST,
  payload
});

export const fetchDestinationPathSuccess = payload => ({
  type: actionConstant.FETCH_DESTINATION_PATH_SUCCESS,
  payload
});

export const fetchDestinationPathFailure = errors => ({
  type: actionConstant.FETCH_DESTINATION_PATH_FAILURE,
  payload: {
    errors
  }
});

export const setSharedFolderId = data => ({
  type: actionConstant.SET_SHARED_FOLDER_ID,
  data
});

/**
 * Policy Shared Folder Authenticate Action Creator
 */
export const sharedFolderDestinationAuthenticate = (
  data,
  formName,
  namespace
) => ({
  type: actionConstant.POLICY_SHARED_FOLDER_DESTINATION_AUTHENTICATE,
  data,
  formName,
  namespace
});

/**
 * Policy VSB Action Creators
 */

export const policyVsbHyperVConnect = (data, formName, namespace) => ({
  type: actionConstant.POLICY_VSB_CONNECT_HYPERV,
  data,
  formName,
  namespace
});

export const policyVsbVmwareConnect = (data, formName, namespace) => ({
  type: actionConstant.POLICY_VSB_CONNECT_VMWARE,
  data,
  formName,
  namespace
});

export const policyVsbVmwareMonitorConnect = (data, formName, namespace) => ({
  type: actionConstant.POLICY_VSB_CONNECT_VMWARE_MONITOR,
  data,
  formName,
  namespace
});

export const policyVsbVmwareMonitorConnectSuccess = data => ({
  type: actionConstant.POLICY_VSB_FETCH_VMWARES_MONITOR_CONNECT_SUCCESS,
  data
});

export const policyVsbFetchVmwares = (siteId, hypervisor_type) => ({
  type: actionConstant.POLICY_VSB_FETCH_VMWARES,
  siteId,
  hypervisor_type
});

export const policyVsbFetchMonitors = siteId => ({
  type: actionConstant.LOAD_VSB_MONITOR,
  siteId
});

export const policyVsbFetchVmwaresSuccess = data => ({
  type: actionConstant.POLICY_VSB_FETCH_VMWARES_SUCCESS,
  data
});

export const policyVsbFetchVmwareConnectSuccess = data => ({
  type: actionConstant.POLICY_VSB_FETCH_VMWARES_CONNECT_SUCCESS,
  data
});

export const policyVsbFetchVmwareNodesSuccess = data => ({
  type: actionConstant.POLICY_VSB_FETCH_VMWARES_NODES_SUCCESS,
  data
});

export const policyVsbFetchVmwareNodes = data => ({
  type: actionConstant.POLICY_VSB_FETCH_VMWARES_NODES,
  data
});

export const getHypervConfigurations = data => ({
  type: actionConstant.GET_HYPERV_CONFIGURATION,
  data
});

export const getVmwareConfigurations = data => ({
  type: actionConstant.GET_VMWARE_CONFIGURATION,
  data
});

export const getNutanixConfigurations = data => ({
  type: actionConstant.GET_NUTANIX_CONFIGURATION,
  data
});

export const getProxyDetailsByID = data => ({
  type: actionConstant.GET_PROXY_BY_ID,
  data
});

export const getAllVSBHypervisors = data => ({
  type: actionConstant.GET_ALL_VSB_HYPERVISORS,
  data
});
export const getVSBHypervisorsSuccess = data => ({
  type: actionConstant.GET_VSB_SUCCESS_HYPERVISORS,
  data
});
export const getVSBHypervisorsFailed = () => ({
  type: actionConstant.GET_VSB_FAILED_HYPERVISORS
});

export const getHypervConfigurationsSuccess = data => ({
  type: actionConstant.GET_HYPERV_CONFIGURATION_SUCCESS,
  data
});

export const getVmwareConfigurationsSuccess = data => ({
  type: actionConstant.GET_VMWARE_CONFIGURATION_SUCCESS,
  data
});

export const getNutanixConfigurationsSuccess = data => ({
  type: actionConstant.GET_NUTANIX_CONFIGURATION_SUCCESS,
  data
});

export const setHypervConnectForm = data => ({
  type: actionConstant.SET_HYPERV_CONNECT_FORM,
  data
});

export const setVmwareConnectForm = data => ({
  type: actionConstant.SET_VMWARE_CONNECT_FORM,
  data
});

export const clearVSBPolicyData = () => ({
  type: actionConstant.CLEAR_VSB_POLICY_DATA
});
export const storeFnFLinuxSummaryData = data => ({
  type: actionConstant.SET_FnF_LINUX_SUMMARY_DATA,
  data
});

export const updateSelectedRpsWithPassword = data => ({
  type: actionConstant.UPDATED_SELECTED_RPs_WITH_PASSWORD,
  data
});

export const resetSelectedRpsWithPassword = () => ({
  type: actionConstant.RESET_SELECTED_RPs_WITH_PASSWORD
});

export const validateScheduleFnFLinux = data => ({
  type: actionConstant.FnF_LINUX_VALIDATE_SCHEDULE,
  data
});

export const getLinuxScriptsSuccess = data => ({
  type: actionConstant.GET_LINUX_SCRIPTS_SUCCESS,
  data
});
/**
 * updates recovered resources data to state
 */
export const updateRecoveredResources = data => ({
  type: actionConstant.UPDATE_RECOVERED_RESOURCES,
  data
});

export const fetchVolumesForRP = source => ({
  type: actionConstant.FnF_FETCH_VOLUMES_FOR_RP,
  source
});

export const fetchVolumesForRecoveryPointsSuccess = data => ({
  type: actionConstant.FnF_FETCH_VOLUMES_FOR_RP_SUCCESS,
  data
});

export const fetchItemsForRP = data => ({
  type: actionConstant.FnF_FETCH_ITEMS_FOR_RP,
  data
});

export const fetchItemsDataForRPLinux = data => ({
  type: actionConstant.FnF_FETCH_ITEMS_DATA_FOR_RP_LINUX,
  data
});

export const fetchFnFTableDataPagination = (gridCurrentPage, gridPageSize) => ({
  type: actionConstant.FnF_FETCH_TABLE_PAGINATION,
  gridCurrentPage,
  gridPageSize
});

export const fetchItemsForRPLinux = data => ({
  type: actionConstant.FnF_FETCH_ITEMS_FOR_RP_LINUX,
  data
});

export const fetchItemsForRecoveryPointsSuccess = data => ({
  type: actionConstant.FnF_FETCH_ITEMS_FOR_RP_SUCCESS,
  data
});

export const fetchDownloadLink = data => ({
  type: actionConstant.FnF_GENERATE_DOWNLOAD_LINK,
  data
});

export const storeSelectedSource = data => ({
  type: actionConstant.STORE_SOURCE_DATA,
  data
});

export const storeSelectedDestinationType = data => ({
  type: actionConstant.STORE_DESTINATION_TYPE,
  data
});

export const storeRecoveryPoint = data => ({
  type: actionConstant.STORE_RECOVERY_POINT,
  data
});

export const storeSelectedItems = data => ({
  type: actionConstant.STORE_SELECTED_ITEM_FOR_RECOVERY,
  data
});

export const storeSelectedItemsFromAllPages = data => ({
  type: actionConstant.STORE_SELECTED_ITEM_FROM_ALL_PAGES,
  data
});

export const getPendingSessions = data => ({
  type: actionConstant.GET_PENDING_SESSIONS,
  data
});

export const getStandbyVMSnapshot = data => ({
  type: actionConstant.GET_STANDBY_VM_SNAPSHOT,
  data
});

export const getIsNetworkConfigured = data => ({
  type: actionConstant.GET_IS_NETWORK_CONFIG,
  data
});

export const shutDownVMClicked = (source_id, data, gridInfo) => ({
  type: actionConstant.SHUTDOWN_VM_CLICKED,
  source_id,
  data,
  gridInfo
});

export const powerOnVMClicked = (source_id, data, gridInfo) => ({
  type: actionConstant.POWERON_VM_CLICKED,
  source_id,
  data,
  gridInfo
});

export const virtualStandByResumeSmartCopy = (source_id, data) => ({
  type: actionConstant.VIRTUAL_STANDBY_RESUME_SMART_COPY,
  source_id,
  data
});

/**
 * updates instant VMs data to state
 */
export const updateInstantVMs = data => ({
  type: actionConstant.UPDATE_INSTANT_VMS,
  data
});

/**
 * Single Action Menthod For Instant VM
 */
export const singleActionOnVM = data => ({
  type: actionConstant.SINGLE_ACTION_ON_IVM,
  data
});

/**
 * Bulk Action Menthod For Instant VM
 */
export const bulkActionOnVM = data => ({
  type: actionConstant.BULK_ACTION_ON_IVM,
  data
});

export const getBackupVmdisks = (id, data, payloadForGetVmNetworksList) => ({
  type: actionConstant.GET_BACKUP_VM_DISKS,
  id,
  data,
  payloadForGetVmNetworksList
});

export const getBackupVmdisksSuccess = data => ({
  type: actionConstant.GET_BACKUP_VM_DISKS_SUCCESS,
  data
});

export const showWarningSuccess = data => ({
  type: actionConstant.SHOW_WARNING_SUCCESS,
  data
});

export const getHypervList = (data, productType) => ({
  type: actionConstant.GET_HYPERV_LIST,
  typeData: data,
  productType
});

export const getEsxVcenterList = data => ({
  type: actionConstant.GET_ESX_VCENTER_LIST,
  data
});

export const getHypervListSuccess = data => ({
  type: actionConstant.GET_HYPERV_LIST_SUCCESS,
  data
});

export const getEsxVcenterListSuccess = data => ({
  type: actionConstant.GET_ESX_VCENTER_LIST_SUCCESS,
  data
});

export const connectToHyperV = data => ({
  type: actionConstant.CONNECT_TO_HYPERV_VM_DEFAULT_PATH,
  data
});

export const connectToHyperVSuccess = data => ({
  type: actionConstant.CONNECT_TO_HYPERV_VM_DEFAULT_PATH_SUCCESS,
  data
});

export const getNutanixStorage = data => ({
  type: actionConstant.GET_NUTANIX_STORAGE,
  data
});
export const getNutanixStorageSuccess = data => ({
  type: actionConstant.GET_NUTANIX_STORAGE_SUCCESS,
  data
});
export const getNutanixNetworks = data => ({
  type: actionConstant.GET_NUTANIX_NETWORKS,
  data
});
export const getNutanixNetworksSuccess = data => ({
  type: actionConstant.GET_NUTANIX_NETWORKS_SUCCESS,
  data
});

export const getNetworkAdapters = (id, data) => ({
  type: actionConstant.GET_NETWORK_ADAPTERS,
  id,
  data
});

export const getNetworkAdaptersSuccess = data => ({
  type: actionConstant.GET_NETWORK_ADAPTERS_SUCCESS,
  data
});

export const fetchHypervisorInfoSuccess = data => ({
  type: actionConstant.STORE_HYPERVISOR_DATA,
  data
});

export const validateRecoveryPointVMSuccess = data => ({
  type: actionConstant.STORE_VALIDATE_VM_RESPONSE,
  data
});

export const validateRecoveryPointLinuxVMSuccess = data => ({
  type: actionConstant.STORE_VALIDATE_LINUX_VM_RESPONSE,
  data
});

export const validateVM = data => ({
  type: actionConstant.VALIDATE_VM,
  data
});

export const validateLinuxVM = data => ({
  type: actionConstant.VALIDATE_LINUX_VM,
  data
});

export const verifyPassword = (data, callConfigureAPI) => ({
  type: actionConstant.VERIFY_ENCRYPTION_PASSWORD,
  data,
  callConfigureAPI
});

export const saveRPPassword = data => ({
  type: actionConstant.SAVE_RP_PASSWORD,
  data
});

export const resetRPPassword = data => ({
  type: actionConstant.RESET_RP_PASSWORD,
  data
});

export const noRecoveryPoints = () => ({
  type: actionConstant.NO_RECOVERY_POINTS
});

export const startFullVMRecovery = () => ({
  type: actionConstant.START_FULL_VM_RECOVERY
});

export const getSelectedSourceHypervisorType = (data, sourceData) => ({
  type: actionConstant.GET_SELECTED_SOURCE_HYPERVISOR_TYPE,
  data,
  sourceData
});

export const fetchVmwareStoragepolicies = data => ({
  type: actionConstant.FETCH_VMWARE_STORAGE_POLCIES,
  data
});

export const fetchVmwareStoragepoliciesSuccess = data => ({
  type: actionConstant.FETCH_VMWARE_STORAGE_POLCIES_SUCCESS,
  data
});

export const fetchVmwareDatastores = data => ({
  type: actionConstant.FETCH_VMWARE_DATASTORES,
  data
});

export const fetchVmwareDatastoresSuccess = data => ({
  type: actionConstant.FETCH_VMWARE_DATASTORES_SUCCESS,
  data
});

export const connectToEsxVcenter = data => ({
  type: actionConstant.CONNECT_TO_ESX_VCENTER,
  data
});

export const connectToEsxVcenterSuccess = data => ({
  type: actionConstant.CONNECT_TO_ESX_VCENTER_SUCCESS,
  data
});

export const createFileBrowseFolder = (data, Props) => ({
  type: actionConstant.CREATE_FILE_BROWSE_FOLDER,
  data,
  props: Props
});
export const createFileBrowseFolderHyperV = (data, Props) => ({
  type: actionConstant.CREATE_FILE_BROWSE_FOLDER_HYPERV,
  data,
  props: Props
});

export const createFileBrowseFolderLinux = (data, Props) => ({
  type: actionConstant.CREATE_FILE_BROWSE_FOLDER_LINUX,
  data,
  props: Props
});

export const browseSharedFolders = (data, namespace, formName) => ({
  type: actionConstant.SHARED_FOLDERS_BROWSE,
  data,
  namespace,
  formName
});

export const clearVSBEsxNodes = () => ({
  type: actionConstant.CLEAR_ESX_NODES
});

export const clearVSBVmwareConfiguration = () => ({
  type: actionConstant.CLEAR_VMWARE_CONFIGURATION
});

export const clearVSBNutanixConfiguration = () => ({
  type: actionConstant.CLEAR_NUTANIX_CONFIGURATION
});

export const loadNetworkData = data => ({
  type: actionConstant.LOAD_NETWORK_DATA,
  data
});

export const postNetworkData = (id, data, gridInfo) => ({
  type: actionConstant.POST_NETWORK_DATA,
  id,
  data,
  gridInfo
});

export const loadBackupPasswordsData = data => ({
  type: actionConstant.LOAD_BACKUP_PASSWORDS_DATA,
  data
});

export const loadDNSData = data => ({
  type: actionConstant.LOAD_DNS_DATA,
  data
});

export const getNetworkData = data => ({
  type: actionConstant.GET_NETWORK_DATA,
  data
});

export const setBackupPasswordsData = data => ({
  type: actionConstant.SET_BACKUP_PASSWORDS_DATA,
  data
});

export const validateHyperVysorsHyperV = data => ({
  type: actionConstant.VALIDATE_HYPERVISOR_HYPERV,
  data
});

export const validateHyperVysorsVMware = data => ({
  type: actionConstant.VALIDATE_HYPERVISOR_VMWARE,
  data
});
export const validateNutanixHyperVysor = data => ({
  type: actionConstant.VALIDATE_HYPERVISOR_NUTANIX,
  data
});

export const setNewVMInstanceUID = id => ({
  type: actionConstant.SET_NEW_VM_ID,
  data: id
});

export const validateDisk = data => ({
  type: actionConstant.VALIDATE_DISK,
  data
});
export const verifyPasswordAndServer = (
  password,
  verifyServerCallback,
  verifyServerPayload
) => ({
  type: actionConstant.VERIFY_PASSWORD_AND_SERVER,
  password,
  verifyServerCallback,
  verifyServerPayload
});

export const mountPointModal = data => ({
  type: actionConstant.MOUNTPOINT_MODAL,
  data
});

export const getMountPointInfo = data => ({
  type: actionConstant.GET_MOUNTPOINT_INFO,
  data
});

export const storeMountPointInfo = data => ({
  type: actionConstant.STORE_MOUNTPOINT_INFO,
  data
});

export const getCloudConfigurations = data => ({
  type: actionConstant.FETCH_CLOUD_CONFIGURATIONS,
  data
});

export const storeCloudConfigurations = data => ({
  type: actionConstant.STORE_CLOUD_CONFIGURATIONS,
  data
});

export const getCloudSubnet = data => ({
  type: actionConstant.FETCH_CLOUD_SUBNET,
  data
});

export const storeCloudSubnet = data => ({
  type: actionConstant.STORE_CLOUD_SUBNET,
  data
});
// export const getCloudAccountListByType = data => ({
//   type: actionConstant.GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE,
//   data
// });

export const getRegionsForCloudAccount = data => ({
  type: actionConstant.GET_REGIONS_FOR_CLOUD_ACCOUNT,
  data
});

export const getComputeRegionsForGCPCloudAccount = data => ({
  type: actionConstant.GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT,
  data
});

export const getComputeZonesForGCPCloudAccount = data => ({
  type: actionConstant.GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT,
  data
});

// v3 for rps cloud account data store
export const getRegionsForCloudAccountV3 = data => ({
  type: actionConstant.GET_REGIONS_FOR_CLOUD_ACCOUNT_V3,
  data
});

// v3 for rps cloud account data store
export const getRegionsForCloudAccountSuccessV3 = data => ({
  type: actionConstant.GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS_V3,
  data
});

// v3 for rps cloud account data store
export const getBucketsForCloudAccount = data => ({
  type: actionConstant.GET_BUCKETS_FOR_CLOUD_ACCOUNT,
  data
});

// v3 for rps cloud account data store
export const getBucketsForCloudAccountSuccess = data => ({
  type: actionConstant.GET_BUCKETS_FOR_CLOUD_ACCOUNT_SUCCESS,
  data
});

export const getResourceGroupForCloudAccount = data => ({
  type: actionConstant.GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT,
  data
});

export const clearCloudAccBuckets = () => ({
  type: actionConstant.CLEAR_CLOUD_ACCOUNTS_BUCKETS
});

export const getCloudAccountListByTypeSuccess = data => ({
  type: actionConstant.GET_LOAD_CLOUD_ACCOUNT_LIST_BY_TYPE_SUCCESS,
  data
});

export const clearCloudAccounts = () => ({
  type: actionConstant.CLEAR_CLOUD_ACCOUNTS_BY_TYPE
});

export const clearCloudAccRegions = () => ({
  type: actionConstant.CLEAR_CLOUD_ACCOUNTS_REGIONS
});

export const clearGCPCloudAccComputeRegions = () => ({
  type: actionConstant.CLEAR_GCP_CLOUD_ACCOUNTS_COMPUTE_REGIONS
});

export const clearGCPCloudAccComputeZones = () => ({
  type: actionConstant.CLEAR_GCP_CLOUD_ACCOUNTS_COMPUTE_ZONES
});

export const clearCloudAccRegionsV3 = () => ({
  type: actionConstant.CLEAR_CLOUD_ACCOUNTS_REGIONS_V3
});

export const getRegionsForCloudAccountSuccess = data => ({
  type: actionConstant.GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS,
  data
});

export const getComputeRegionsForGCPCloudAccountSuccess = data => ({
  type: actionConstant.GET_COMPUTE_REGIONS_FOR_GCP_CLOUD_ACCOUNT_SUCCESS,
  data
});

export const getComputeZonesForGCPCloudAccountSuccess = data => ({
  type: actionConstant.GET_COMPUTE_ZONES_FOR_GCP_CLOUD_ACCOUNT_SUCCESS,
  data
});

export const clearGCPSecurityGroups = () => ({
  type: actionConstant.CLEAR_GCP_SECURITY_GROUPS
});

export const getRegionsForCloudAccountSuccess_V3 = data => ({
  type: actionConstant.GET_REGIONS_FOR_CLOUD_ACCOUNT_SUCCESS_V3,
  data
});

export const getResourceGroupForCloudAccountSuccess = data => ({
  type: actionConstant.GET_RESOURCE_GROUPS_FOR_CLOUD_ACCOUNT_SUCCESS,
  data
});

export const storeHypervNetworksList = data => ({
  type: actionConstant.STORE_HYPERV_NETWORK_LIST,
  data
});

export const storeVmwareNetworksList = data => ({
  type: actionConstant.STORE_VMWARE_NETWORK_LIST,
  data
});

export const storeDefaultVmPath = data => ({
  type: actionConstant.STORE_HYPERV_DEFAULT_VM_PATH,
  data
});

export const storeDefaultVhdPath = data => ({
  type: actionConstant.STORE_HYPERV_DEFAULT_VHD_PATH,
  data
});

export const storeSelectedDestination = data => ({
  type: actionConstant.SAVE_RECOVERY_SELECTED_DESTINATION,
  data
});

export const fetchVmwareNetworks = data => ({
  type: actionConstant.FETCH_VMWARE_NETWORK_LIST,
  data
});

export const showFreeSizeWarning = data => ({
  type: actionConstant.SHOW_FREE_SIZE_WARNING,
  data
});

export const policyVsbValidateCloudProxy = data => ({
  type: actionConstant.VSB_VALIDATE_CLOUD_PROXY,
  data
});

export const policyVsbValidateCloudProxyForGCP = data => ({
  type: actionConstant.VSB_VALIDATE_GCP_CLOUD_PROXY,
  data
});

export const ec2CloudProxyValidationResponse = data => ({
  type: actionConstant.EC2_VALIDATE_CLOUD_PROXY_RESPONSE,
  data
});

export const GCPCloudProxyValidationResponse = data => ({
  type: actionConstant.GCP_VALIDATE_CLOUD_PROXY_RESPONSE,
  data
});

export const getGCPSecurityGroupsForSelectedNetwork = data => ({
  type: actionConstant.GET_GCP_SECURITY_GROUPS_FOR_SELECTED_NETWORK,
  data
});

export const GCPSecurityGroupsFetchSuccess = data => ({
  type: actionConstant.GCP_SECURITY_GROUPS_FETCH_SUCCESS,
  data
});

export const getCHDataStores = site_id => ({
  type: actionConstant.GET_CH_DATASTORES,
  site_id
});

export const storeSelectedDestinationData = data => ({
  type: actionConstant.SAVE_RECOVERY_SELECTED_DESTINATION_DATA,
  data
});

export const storeSelectedDestinationDataIVM = data => ({
  type: actionConstant.SAVE_RECOVERY_SELECTED_DESTINATION_DATA_IVM,
  data
});
export const clearHyperVPaths = () => ({
  type: actionConstant.CLEAR_HYPERV_PATHS
});

export const storeSelectedRecoveryPoint = data => ({
  type: actionConstant.STORE_SELECTED_RECOVERY_POINT,
  data
});

export const getHypervisorsListByType = data => ({
  type: actionConstant.GET_HYPERVISORS_LIST_BY_TYPE,
  data
});

export const getIVMHypervListSuccess = data => ({
  type: actionConstant.GET_IVM_HYPERV_LIST,
  data
});

export const createVmwareForIVM = (data, formName) => ({
  type: actionConstant.CREATE_VMWARE_FOR_IVM,
  data,
  formName
});

export const createVmwareForPolicy = (data, formName) => ({
  type: actionConstant.CREATE_VMWARE_FOR_POLICY,
  data,
  formName
});

export const createVmwareForRecovery = (data, formName) => ({
  type: actionConstant.CREATE_VMWARE_FOR_RECOVERY,
  data,
  formName
});

export const createHyperVForIVM = (data, formName) => ({
  type: actionConstant.CREATE_HYPERV_FOR_IVM,
  data,
  formName
});

export const createNutanixForIVM = (data, formName) => ({
  type: actionConstant.CREATE_NUTANIX_FOR_IVM,
  data,
  formName
});

export const createHyperVForPolicy = (data, formName) => ({
  type: actionConstant.CREATE_HYPERV_FOR_POLICY,
  data,
  formName
});

export const createHyperVForIVMSuccess = data => ({
  type: actionConstant.CREATE_HYPERV_FOR_IVM_SUCCESS,
  data
});

export const createNutanixForIVMSuccess = data => ({
  type: actionConstant.CREATE_NUTANIX_FOR_IVM_SUCCESS,
  data
});

export const createHyperVForPolicySuccess = data => ({
  type: actionConstant.CREATE_HYPERV_FOR_POLICY_SUCCESS,
  data
});

export const getHypervListOfTypeSuccess = data => ({
  type: actionConstant.GET_HYPERV_OF_TYPE_SUCCESS,
  data
});

export const resetHypervList = () => ({
  type: actionConstant.RESET_HYPERV_LIST
});

export const getHypervListOfTypeFailed = () => ({
  type: actionConstant.GET_HYPERV_OF_TYPE_FAILED
});

export const clearCreateHyperVForIVMResp = data => ({
  type: actionConstant.CLEAR_CREATE_HYPERV_FOR_IVM_SUCCESS,
  data
});

export const clearCreateNutanixForIVMResp = data => ({
  type: actionConstant.CLEAR_CREATE_NUTANIX_FOR_IVM_SUCCESS,
  data
});

export const clearHyperVForPolicy = () => ({
  type: actionConstant.CLEAR_HYPERV_FOR_POLICY
});

export const createVmwareForIVMSuccess = data => ({
  type: actionConstant.CREATE_VMWARE_FOR_IVM_SUCCESS,
  data
});

export const hypervTypeARChanged = () => ({
  type: actionConstant.HYPERV_TYPE_AR_CHANGED
});

export const ResethypervTypeARChangedVal = () => ({
  type: actionConstant.RESET_HYPERV_TYPE_AR_CHANGED_VAL
});

export const createVmwareForPolicySuccess = data => ({
  type: actionConstant.CREATE_VMWARE_FOR_POLICY_SUCCESS,
  data
});

export const clearVmwareForPolicy = () => ({
  type: actionConstant.CLEAR_VMWARE_FOR_POLICY
});

export const clearCreateVmwareForIVMResp = () => ({
  type: actionConstant.CLEAR_CREATE_VMWARE_FOR_IVM_SUCCESS
});

export const clearIVMVmLocation = () => ({
  type: actionConstant.CLEAR_VM_LOCATION_DETAILS
});

export const savePayloadForVMwareConfigAPI = data => ({
  type: actionConstant.SAVE_VMWARE_CONFIG_PAYLOAD,
  data
});

export const clearVMwareConfigResp = () => ({
  type: actionConstant.CLEAR_VMWARE_CONFIG_RESP
});

export const saveVmWareFinalPayloadInfo = data => ({
  type: actionConstant.SAVE_VMWARE_FINAL_PAYLOAD_INFO,
  data
});

export const clearVmWareFinalPayloadInfo = () => ({
  type: actionConstant.CLEAR_VMWARE_FINAL_PAYLOAD_INFO
});

export const setSelectedTypeInVMTree = data => ({
  type: actionConstant.SET_SELECTED_TYPE_IN_VM_TREE,
  data
});

// Fetch only agentless vms sources for filtring the data from policy add sources from hypervior tree.
export const fetchAgentlessVMSources = data => ({
  type: actionConstant.FETCH_AGENTLESS_VM_SOURECES_REQUEST,
  payload: data
});

export const saveContainerPoliciesdata = data => ({
  type: actionConstant.SET_CONTAINER_POLICIES_DATA,
  payload: data
});

export const getRpsHashMemory = data => ({
  type: actionConstant.GET_RPS_HASHMEMORY,
  data
});

export const setRpsHashMemory = data => ({
  type: actionConstant.SET_RPS_HASHMEMORY,
  data
});

export const setRpsHashMemoryLoader = data => ({
  type: actionConstant.SET_RPS_HASHMEMORY_LOADER,
  data
});

export const setRpsHashMemoryError = data => ({
  type: actionConstant.SET_RPS_HASHMEMORY_ERROR,
  data
});

export const purgeDatastoreRecoveryPoints = (id, data) => ({
  type: actionConstant.PURGE_DATASTORE_RECOVERY_POINTS,
  id,
  data
});

export const getLinuxScripts = data => ({
  type: actionConstant.FnF_LINUX_FETCH_SCRIPTS,
  data
});

export const transactionDetailsSucceess = data => ({
  type: actionConstant.GET_TRANSACTION_DETAILS_SUCCESS,
  data
});

export const fetchTransactionDetails = data => ({
  type: actionConstant.GET_TRANSACTION_DETAILS,
  data
});

export const updateOracleDBList = data => ({
  type: actionConstant.ADDED_ORACLE_DB_LIST,
  data
});

export const clearOracleDBList = () => ({
  type: actionConstant.CLEAR_ORACLE_DB_LIST
});

export const nutanixClusterConfigurationForPolicy = (data, formName) => ({
  type: actionConstant.GET_NUTANIX_CLUSTER_DETAILS_FOR_POLICY,
  data,
  formName
});
export const nutanixClusterConfigurationForPolicySuccess = data => ({
  type: actionConstant.GET_NUTANIX_CLUSTER_DETAILS_FOR_POLICY_SUCCESS,
  data
});

export const getAntiVirusDetailsForAssuredSecurityScan = data => ({
  type: actionConstant.GET_ANTIVIRUS_DETAILS_FOR_ASSURED_SECURITY_SCAN,
  data
});

export const getAntiVirusDetailsForAssuredSecurityScanSuccess = data => ({
  type: actionConstant.GET_ANTIVIRUS_DETAILS_FOR_ASSURED_SECURITY_SCAN_SUCCESS,
  data
});

export const clearAntiVirusDetailsForAssuredSecurityScan = () => ({
  type: actionConstant.CLEAR_ANTIVIRUS_DETAILS_FOR_ASSURED_SECURITY_SCAN
});

export const terminateStandbyVM = id => ({
  type: actionConstant.TERMINATE_STANDBY_VM_INSTANCE,
  id
});

export const getReplicationTasksForPolicy = id => ({
  type: actionConstant.GET_REPLICATION_TASKS_FOR_POLICY,
  id
});

export const getReplicationTasksForPolicySuccess = data => ({
  type: actionConstant.GET_REPLICATION_TASKS_FOR_POLICY_SUCCESS,
  data
});

export const clearReplicationTasksForPolicy = () => ({
  type: actionConstant.CLEAR_REPLICATION_TASKS_FOR_POLICY
});

export const getReplicationTasksForSource = id => ({
  type: actionConstant.GET_REPLICATION_TASKS_FOR_SOURCE,
  id
});

export const getReplicationTasksForSourceSuccess = data => ({
  type: actionConstant.GET_REPLICATION_TASKS_FOR_SOURCE_SUCCESS,
  data
});

export const clearReplicationTasksForSource = () => ({
  type: actionConstant.CLEAR_REPLICATION_TASKS_FOR_SOURCE
});

export const runAdhocReplicationForPolicy = (id, payload) => ({
  type: actionConstant.RUN_ADHOC_REPLICATION_FOR_POLICY,
  id,
  payload
});

export const runAdhocReplicationForSource = (id, payload) => ({
  type: actionConstant.RUN_ADHOC_REPLICATION_FOR_SOURCE,
  id,
  payload
});

export const downloadAssuredSecurityScanDetails = data => ({
  type: actionConstant.DOWNLOAD_ASSURED_SECURITY_SCAN_DETAILS,
  data
});

export const getDestinationInfo = (dataStoreId, sourceId) => ({
  type: actionConstant.GET_DESTINATION_INFO,
  dataStoreId,
  sourceId
});

export const getDestinationInfoSuccess = data => ({
  type: actionConstant.GET_DESTINATION_INFO_SUCCESS,
  data
});

export const clearDestinationInfoForAdhocASTask = () => ({
  type: actionConstant.CLEAR_DESTINATION_INFO_FOR_ADHOC_AS_TASK
});
