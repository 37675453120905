import * as C from "state/constants";

const initialState = {
  searchValues: {
    organizationId: "",
    userId: "",
    auditCodes: [],
    from: 0,
    to: 0
  },
  sortString: ""
};

const auditTrail = (state = initialState, action) => {
  switch (action.type) {
    case C.SET_AUDIT_TRAIL_SORT_STRING:
      return Object.assign({}, state, {
        sortString: action.payload.sortString
      });

    case C.SET_AUDIT_TRAIL_SEARCH_VALUES:
      return Object.assign({}, state, {
        searchValues: {
          organizationId: action.payload.organizationId,
          userId: action.payload.userId,
          auditCodes: action.payload.auditCodes,
          from: action.payload.from,
          to: action.payload.to
        },
        sortString: ""
      });

    default:
      return state;
  }
};

export default auditTrail;
