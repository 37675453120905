/* eslint-disable import/prefer-default-export */
import { MUI_INITIAL_THEME } from "utils/theme";
export const getDialogVisibility = (state, name) =>
  (state.ui.dialogs && state.ui.dialogs[name]) || false;
export const getLoadingStatus = (state, name) =>
  state.ui.loading && state.ui.loading[name];
export const handleLinkColor = store => {
  let theme = store?.reducer?.theme;
  const { secondary } =
    theme && theme.palette ? theme.palette : MUI_INITIAL_THEME().palette;
  const { main: linkSecondary } = secondary;
  return linkSecondary;
};
