import { combineReducers } from "redux";

import remoteConsole from "./remoteConsole";
import sharedFolder from "./sharedFolder";
import jumpstart from "./jumpstart";
import browseAuthentication from "./browseAuthentication";

export default combineReducers({
  remoteConsole,
  sharedFolder,
  jumpstart,
  browseAuthentication
});
