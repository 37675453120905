export const combineReducersWithNamespace = (reducers, getNamespace) => {
  return (state = {}, action) => {
    const namespace = getNamespace(action);
    let stateWithNamespace = state;

    if (namespace === undefined) {
      return state;
    }

    if (stateWithNamespace[namespace] === undefined) {
      stateWithNamespace = {
        ...state,
        [namespace]: {}
      };
    }

    return Object.keys(reducers).reduce(
      (nextState, key) => {
        nextState[namespace][key] = reducers[key](
          stateWithNamespace[namespace][key],
          action,
          namespace
        );
        return nextState;
      },
      {
        ...state,
        [namespace]: {}
      }
    );
  };
};

export default combineReducersWithNamespace;
