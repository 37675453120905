import * as C from "state/constants";

const initialState = {
  isLoading: true,
  sites: []
};

const sites = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_SITES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        sites: []
      });
    case C.FETCH_SITES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        sites: action.payload
      });
    case C.FETCH_SITES_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        sites: []
      });
    default:
      return state;
  }
};

export default sites;
